import React from "react";
import PropTypes from "prop-types";
import { Popup, Search, Divider, Button, Label } from "semantic-ui-react";

import { getSelectionAsGlossaryTerm, linkGlossaryTerm, unlinkGlossaryTerm } from "./glossaryUtils";
import { getContextPropsForPopup } from "../utils/domUtils";
import ActionLink from "../../../ActionLink";
import CreateTermDialog from "../../../../KitBuilder/Glossary/component/CreateTermDialog";
import { stopPropagation } from "../utils/utils";

class GlossaryPopup extends React.Component {

    static propTypes = {
        contextNode: PropTypes.instanceOf(Element).isRequired,
        editorState: PropTypes.object.isRequired,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onAddTerm: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        suppressPopup: PropTypes.bool,
        searchResult: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isSearching: PropTypes.bool,
        onSearchTerm: PropTypes.func,
        searchFilter: PropTypes.string,
        rootEntityKey: PropTypes.string,
    };

    /**
     * When `isOpened` is not handled manually and `<Popup>` component has `open={true}`,
     * then `defaultSearchQuery={currentText}` is set to undefined by the first rendering (before componentDidMount)
     * and its value is not set even the `currentText` property is changed.
     */
    state = {
        isOpened: false,
        contextProps: {},
    };

    componentDidMount = () => {
        const { editorState, contextNode, disableParentEditor, onSearchTerm, rootEntityKey } = this.props;
        const { currentEntityKey, currentText } =  getSelectionAsGlossaryTerm(editorState);
        const contextProps = getContextPropsForPopup(contextNode);
        disableParentEditor();
        onSearchTerm({ value: currentText.trim(), rootEntityKey });

        this.setState({
            isOpened: true,
            contextProps,
            currentEntityKey,
            currentText,
        });
    };

    setGlossaryTerm = (glossaryTerm) => {
        const { editorState } = this.props;
        const newEditorState = linkGlossaryTerm(editorState, glossaryTerm);
        this.applyChangesAndClose(newEditorState);
    };

    handleLinkGlossaryTerm = (e,  {result: { key, title }}) => {
        e.stopPropagation();
        if (!key) {
            e.preventDefault();
            return;
        }
        this.setGlossaryTerm({ key: key, name: title });
    }

    handleUnlinkGlossaryTerm = (e) => {
        e.stopPropagation();
        const { editorState } = this.props;
        const newEditorState = unlinkGlossaryTerm(editorState, this.state.currentEntityKey);
        this.applyChangesAndClose(newEditorState);
    };

    handleAddNewGlossaryTerm = (newTermKey, name) => {
        this.setGlossaryTerm({ key: newTermKey, name });
    };

    handleClickNewTerm = () => {
        this.props.onAddTerm();
    };

    applyChangesAndClose = (editorState) => {
        const { setEditorState } = this.props;
        setEditorState(editorState);
        this.handleClose();
    };

    handleClose = () => {
        if (this.props.suppressPopup) {
            return;
        }
        const { onClose, enableParentEditor, onSearchTerm } = this.props;
        this.setState({ isOpened: false });
        enableParentEditor();
        onSearchTerm({ value: "" });
        onClose();
    };

    handleSearchChange = (e, { value }) => {
        const { onSearchTerm, rootEntityKey } = this.props;
        onSearchTerm({ value, rootEntityKey });
    }

    searchResultRenderer = ({ html, kitlabel}) => {
        if (kitlabel) {
            return (
                <React.Fragment>
                    <Label horizontal color="blue">{kitlabel}</Label>
                    <div dangerouslySetInnerHTML={{__html: html}} />
                </React.Fragment>
            );
        } else {
            return <div dangerouslySetInnerHTML={{__html: html}} />;
        }
    }

    render() {
        const { suppressPopup, searchResult, searchFilter, isSearching } = this.props;
        const { isOpened, currentEntityKey, currentText, contextProps } = this.state;
        const { node, pos, hOffset, vOffset } = contextProps;

        if (!isOpened) {
            return null;
        }

        return (
            <Popup
                context={node}
                position={pos}
                horizontalOffset={hOffset}
                verticalOffset={vOffset}
                open={true}
                wide="very"
                onClose={this.handleClose}
                onMouseDown={stopPropagation}
                {...suppressPopup && { style: { zIndex: "100"}}}
            >
                <CreateTermDialog onCreated={this.handleAddNewGlossaryTerm} initName={currentText}/>
                {currentEntityKey ? (
                    <ActionLink horizontal icon="unlink" onClick={this.handleUnlinkGlossaryTerm} content="Remove link to Glossary" />
                ) : (
                    <React.Fragment>
                        <Button fluid onClick={this.handleClickNewTerm}>New glossary term</Button>
                        <Divider horizontal>Or</Divider>
                        <Search
                            fluid
                            category
                            loading={isSearching}
                            onResultSelect={this.handleLinkGlossaryTerm}
                            onSearchChange={this.handleSearchChange}
                            results={searchResult}
                            autoFocus
                            resultRenderer={this.searchResultRenderer}
                            placeholder="Search for an existing term ..."
                            input={{fluid: true}}
                            value={searchFilter}
                            noResultsMessage="No term found"
                            noResultsDescription="Try to change searched word or you can define a new one."
                        />
                    </React.Fragment>
                )}
            </Popup>
        );
    }
}

export default GlossaryPopup;
