import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Icon } from "semantic-ui-react";

import { LessonVersionProps } from "./PublisherProps";

export default class PublishDropdown extends React.PureComponent {
    static propTypes = {
        // IDs will be in Redux state problably
        // lessonId: PropTypes.string,
        // outlineId: PropTypes.string,
        preview: LessonVersionProps,
        release: LessonVersionProps,
        onAction: PropTypes.func.isRequired,
        onView: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        running: PropTypes.oneOf(["preview", "release", "publish"]),
        display: PropTypes.oneOf([ "button", "item"]),
        hasUnprocessedReviews: PropTypes.bool,
    };

    handleActionClick = (e, { value }) => {
        const { onAction } = this.props;
        onAction && onAction(value);
    };

    render() {
        const { preview, release, loading, running, display, hasUnprocessedReviews } = this.props;
        const canRelease = (preview && !(release && release.version_id === preview.version_id));
        const canPublish = !hasUnprocessedReviews;
        const displayAs = display && {
            [display]: true,
        };

        return (
            <Dropdown
                {...displayAs}
                text="Publish"
                loading={!!running}
                disabled={loading || !!running}
                direction="left"
                value={false} // to disable selection hilite
                style={{ zIndex: 600 }}  // to be drawn over shared toolbar of BookEditor
            >
                <Dropdown.Menu>
                    <Dropdown.Item
                        value="preview"
                        disabled={!canPublish}
                        onClick={canPublish ? this.handleActionClick : null}
                    >
                        Prepublish for review
                        {!canPublish && (<span
                            title="There are unprocessed reviews. Accept/Reject them first."
                            style={{
                                cursor: "pointer",
                                marginLeft: "1em",
                                marginRight: "-0.75em",  /* reset right margin for icon */
                                pointerEvents: "auto",   /* to allow showing title on mouse over disabled item */
                            }}
                        >
                            <Icon name="warning sign" color="orange" />
                        </span>)}
                    </Dropdown.Item>
                    <Dropdown.Item
                        text="Publish to all"
                        value="release"
                        disabled={!canRelease}
                        onClick={canRelease ? this.handleActionClick : null}
                    />
                    {preview && <Dropdown.Divider />}
                    {preview && (
                        <Dropdown.Item
                            text="View published files"
                            onClick={this.props.onView}
                        />
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

