import { all, takeEvery } from "redux-saga/effects";
import { TYPES } from "./WidgetTopicsActions";
import { widgetLibrary } from "../../dataSource";
import { toastError, DB_ERROR } from "../../../component/toast";

export function* addTopic({ payload: { widgetId, topicId }}) {
    const [ err ] = yield widgetLibrary.addTopic(widgetId, topicId);
    if (err) {
        toastError({ code: err.code, header: "Failed to add Topic to the Widget", message: DB_ERROR });
    }
}

export function* removeTopic({ payload: { widgetId, topicId }}) {
    const [ err ] = yield widgetLibrary.removeTopic(widgetId, topicId);
    if (err) {
        toastError({ code: err.code, header: "Failed to remove Topic from the Widget", message: DB_ERROR });
    }
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.WIDGET_TOPICS_ADD_TOPIC, addTopic),
        takeEvery(TYPES.WIDGET_TOPICS_REMOVE_TOPIC, removeTopic),
    ]);
}
