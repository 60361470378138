import React from "react";
import PropTypes from "prop-types";
import { Divider, Form } from "semantic-ui-react";
import BlockStyle from "../BlockStyle";

export default class BlockStyleConfig extends React.PureComponent {
    static propTypes = {
        config: PropTypes.object,
        onChange: PropTypes.func.isRequired
    };

    handleStyleChange = (name, value) => {
        const { onChange } = this.props;
        onChange(name, value);
    }

    render() {
        const { config } = this.props;
        return (
            <React.Fragment>
                <Divider horizontal>Style</Divider>
                <Form.Field>
                    <BlockStyle.StyleToolbar style={"object" === typeof config.styles ? config.styles : {}} onStyleChange={this.handleStyleChange}  />
                </Form.Field>
            </React.Fragment>
        );
    }
}
