const TYPES = {
    CREATE_AKIT_OPEN: "AKits/Create/onOpen",
    CREATE_AKIT_CLOSE: "AKits/Create/onClose",
    CREATE_AKIT_CREATE: "AKits/Create/onCreate",
};

const onOpen = () => ({
    type: TYPES.CREATE_AKIT_OPEN,
});

const onClose = () => ({
    type: TYPES.CREATE_AKIT_CLOSE,
});

const onCreate = (payload) => ({
    type: TYPES.CREATE_AKIT_CREATE,
    payload
});

export {
    TYPES,
    onOpen,
    onClose,
    onCreate
};
