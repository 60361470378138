import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Popup, Form } from "semantic-ui-react";
import * as Utils from "./utils";
import ActionLink from "../../../component/ActionLink";
import BlockTitleConfig from "./BlockTitleConfig";
import BlockStyleConfig from "./BlockStyleConfig";
import PopupCloseIcon from "../../../component/PopupCloseIcon";

export default class WidgetPopup extends PureComponent {
    static propTypes = {
        setup: PropTypes.object,
        onUpdate: PropTypes.func
    };

    state = {
        open: false
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleTitleChanged = (changes) => {
        const { setup, onUpdate } = this.props;
        onUpdate && onUpdate(Utils.updateItemConfig(setup, "header", changes));
    };

    handlePropertyChanged = (name, value) => {
        const { setup, onUpdate } = this.props;
        onUpdate && onUpdate(Utils.updateItemConfig(setup, name, value));
    };

    render() {
        const { open } = this.state;
        const { setup } = this.props;
        return (
            <Popup
                open={open}
                onOpen={this.handleOpen}
                on="click"
                position="bottom right"
                basic
                wide="very"
                style={{ width: "550px" }}
                trigger={
                    <ActionLink
                        horizontal
                        floated="right"
                        icon="cogs"
                        className="widget-settings"
                        onClick={this.handleOpen}
                        content="Template style"
                    />
                }
            >
                <Popup.Content>
                    <PopupCloseIcon onClose={this.handleClose} />
                    <Form autoComplete="off">
                        <BlockTitleConfig
                            config={setup.header}
                            onChange={this.handleTitleChanged}
                            subject="widget"
                        />
                    </Form>
                    <BlockStyleConfig
                        config={setup}
                        onChange={this.handlePropertyChanged}
                    />
                </Popup.Content>
            </Popup>
        );
    }
}
