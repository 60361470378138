import { combineReducers } from "redux";
import akits from "./AKits/AKitsReducer";
import outlines from "./Outlines/outlinesReducer";
import publisher from "./Publisher/PublisherReducer";
import WidgetTemplatesReducer from "./WidgetTemplates/WidgetTemplatesReducer";
import WidgetLibraryReducer from "./WidgetLibrary/WidgetLibraryReducer";
import widgetLessons from "./Widget/WidgetLessons/WidgetLessonsReducer";
import SnippetsManagerReducer from "./Snippets/SnippetsManagerReducer";
import LessonContentReducer from "./LessonContent/LessonContentReducer";
import CreateWidgetDialogReducer from "./WidgetLibrary/CreateWidgetDialog/CreateWidgetDialogReducer";
import GlossaryReducer from "./Glossary/GlossaryReducer";
import CommentsReducer from "./Comments/commentsReducer";
import CreateAKitReducer from "./AKits/CreateKit/CreateKitReducer";
import EditAKitReduces from "./AKits/EditKitProperties/EditKitReducer";
import LessonProblemSetReducer from "./LessonContent/LessonProblemSet/LessonProblemSetReducer";
import LessonExportsReducer from "./Publisher/LessonExports/LessonExportsReducer";
import LessonLinkReducer from "./LessonLink/LessonLinkReducer";
import ModulerReducer from "./Modules/ModulesReducer";

export default combineReducers({
    akits,
    outlines,
    publisher,
    widgetTemplates: WidgetTemplatesReducer,
    widgetLibrary: WidgetLibraryReducer,
    snippets: SnippetsManagerReducer,
    lessonContent: LessonContentReducer,
    createWidget: CreateWidgetDialogReducer,
    glossary: GlossaryReducer,
    widgetLessons,
    comments: CommentsReducer,
    createAkit: CreateAKitReducer,
    editAkit: EditAKitReduces,
    lessonProblemSets: LessonProblemSetReducer,
    lessonExports: LessonExportsReducer,
    lessonLink: LessonLinkReducer,
    modules: ModulerReducer,
});
