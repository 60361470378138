const TYPES = {
    RESOURCE_ADVANCED_SEARCH_SET_RESULT: "RESOURCE_ADVANCED_SEARCH_SET_RESULT",
    RESOURCE_ADVANCED_SEARCH_ADVANCED_SEARCH: "RESOURCE_ADVANCED_SEARCH_ADVANCED_SEARCH",
    RESOURCE_ADVANCED_SEARCH_OPEN: "RESOURCE_ADVANCED_SEARCH_OPEN",
    RESOURCE_ADVANCED_SEARCH_CLOSE: "RESOURCE_ADVANCED_SEARCH_CLOSE",
    RESOURCE_ADVANCED_SEARCH_LOAD_USED_TAGS: "RESOURCE_ADVANCED_SEARCH_LOAD_USED_TAGS",
    RESOURCE_ADVANCED_SEARCH_SET_USED_TAGS: "RESOURCE_ADVANCED_SEARCH_SET_USED_TAGS",
    RESOURCE_ADVANCED_SEARCH_FETCH_COURSE_VARIANTS: "RESOURCE_ADVANCED_SEARCH_FETCH_COURSE_VARIANTS",
    RESOURCE_ADVANCED_SEARCH_SET_COURSE_VARIANTS: "RESOURCE_ADVANCED_SEARCH_SET_COURSE_VARIANTS",
    RESOURCE_ADVANCED_SEARCH_LOAD_RESOURCE_TYPES: "RESOURCE_ADVANCED_SEARCH_LOAD_RESOURCE_TYPES",
    RESOURCE_ADVANCED_SEARCH_SET_RESOURCE_TYPES: "RESOURCE_ADVANCED_SEARCH_SET_RESOURCE_TYPES",
    RESOURCE_ADVANCED_SEARCH_SET_OWNERS: "RESOURCE_ADVANCED_SEARCH_SET_OWNERS",
    RESOURCE_ADVANCED_SEARCH_FETCH_OWNERS: "RESOURCE_ADVANCED_SEARCH_FETCH_OWNERS",

};

const onAdvancedSearch = (payload) => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_ADVANCED_SEARCH,
    payload
});

const onSetSearchResult = (payload) => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_SET_RESULT,
    payload
});

const onOpen = () => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_OPEN
});

const onClose = () => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_CLOSE
});

const onLoadUsedTags = () => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_LOAD_USED_TAGS
});

const onSetUsedTags = (payload) => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_SET_USED_TAGS,
    payload
});

const onFetchUsedCourseVariants = () => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_FETCH_COURSE_VARIANTS
});

const onSetUsedCourseVariants = (cvs) => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_SET_COURSE_VARIANTS,
    payload: cvs
});

const onLoadResourceTypes = () => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_LOAD_RESOURCE_TYPES
});

const onSetResourceTypes = (payload) => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_SET_RESOURCE_TYPES,
    payload
});

const onFetchOwners = () => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_FETCH_OWNERS
});

const onSetOwners = (payload) => ({
    type: TYPES.RESOURCE_ADVANCED_SEARCH_SET_OWNERS,
    payload
});

export {
    TYPES,
    onAdvancedSearch,
    onSetSearchResult,
    onOpen,
    onClose,
    onLoadUsedTags,
    onSetUsedTags,
    onSetUsedCourseVariants,
    onFetchUsedCourseVariants,
    onSetResourceTypes,
    onLoadResourceTypes,
    onSetOwners,
    onFetchOwners,
};
