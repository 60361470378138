import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Label, Icon, Menu } from "semantic-ui-react";
import { teacherFilter, schoolFilter, getTeacherOptions } from "../SyllabiListSelectors";
import { onChangeTeacherFilter, onChangeSchoolFilter } from "../SyllabiListActions";
import { getSchoolsById } from "../../../External/SchoolList/schoolSelectors";

class SyllabiAdvancedFilterView extends PureComponent {
    static propTypes = {
        onChangeTeacherFilter: PropTypes.func,
        onChangeSchoolFilter: PropTypes.func,
        teacherFilter: PropTypes.string,
        schoolFilter: PropTypes.string,
        schools: PropTypes.object,
        teacherOptions: PropTypes.array,
    };

    state = {
        teacherById: {}
    }
    componentDidMount() {
        const teacherById = {};
        this.props.teacherOptions.map(item => teacherById[item.value] = item.text);
        this.setState({ teacherById });
    }

    handleResetTeacherFilter = () => {
        this.props.onChangeTeacherFilter("");
    }

    handleResetSchoolFilter = () => {
        this.props.onChangeSchoolFilter("");
    }

    render() {
        const { schoolFilter, teacherFilter, schools } = this.props;
        return (
            <React.Fragment>
                <Menu text style={{ display: "inline-block", margin: "0" }}>
                    <Menu.Item header>FILTERED BY: </Menu.Item>
                </Menu>
                {!!schoolFilter &&
                    <Label
                        as="a"
                    >
                        School: {schools[schoolFilter].name}
                        <RemoveIcon onClick={this.handleResetSchoolFilter} />
                    </Label>
                }
                {!!teacherFilter &&
                    <Label
                        as="a"
                    >
                        Teacher: {this.state.teacherById[teacherFilter]}
                        <RemoveIcon onClick={this.handleResetTeacherFilter} />
                    </Label>
                }
            </React.Fragment>
        );
    }
}


class RemoveIcon extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
    };

    render() {
        return <Icon link name="close" onClick={this.props.onClick} />;
    }
}


const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onChangeTeacherFilter,
            onChangeSchoolFilter,
        },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        teacherFilter: teacherFilter(state),
        schoolFilter: schoolFilter(state),
        schools: getSchoolsById(state),
        teacherOptions: getTeacherOptions(state),
    }),
    dispatchToProps
)(SyllabiAdvancedFilterView);