import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getSelectedProblems, restrictToAuthorId } from "../problemManagementSelectors";
import { onSaveRemoveTagsTopics } from "./removeTagsTopicsActions";
import { getProblemsTags, getProblemsTopics } from "./removeTagsTopicsUtils";
import RemoveTagsTopics from "./RemoveTagsTopics";

const RemoveTagsTopicsContainer = connect(
    (state, props) => {
        const problems = getSelectedProblems(state, props);
        const topicIds = getProblemsTopics(problems);
        return {
            problems,
            topicIds,
            tagNames: getProblemsTags(problems),
            authorId: restrictToAuthorId(state, props),
        };
    },
    {
        onSave: onSaveRemoveTagsTopics
    },
)(RemoveTagsTopics);

RemoveTagsTopicsContainer.displayName = "RemoveTagsTopicsContainer";
RemoveTagsTopicsContainer.propTypes = {
    fbCollection: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default RemoveTagsTopicsContainer;