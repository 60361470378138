import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import { DragHandle } from "../../component/DragHandle";

const labelStyle = {
    verticalAlign: "middle",
    background: "rgba(0,0,0,0.04)",
    display: "grid",
    gridTemplateColumns: "35% 30% 35%",
    margin: "-7px -7px 0 -7px",
    padding: "0.5em",
};
const scopeStyle = {
    textTransform: "capitalize",
    fontWeight: "normal",
    justifySelf: "center",
    color: "gray"
};

export default class ItemLabel extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
        item: PropTypes.object,
        draggable: PropTypes.bool,
    };

    render() {
        const { children, item, draggable } = this.props;
        return (
            <Segment basic style={labelStyle}>
                <span>
                    {draggable && <DragHandle float="none"/>}
                    {item.name}
                </span>
                {item.scope && "all" !== item.scope && <span style={scopeStyle}>Visible only for {item.scope}s</span> || <span />}
                {children}
            </Segment>
        );
    }
}
