import React from "react";
import PropTypes from "prop-types";
import { Tab, Menu } from "semantic-ui-react";
import Comments from "./index";
import CommentsHeaderContainer from "./CommentsHeaderContainer";

export default class CommentsMenu extends React.PureComponent {
    static propTypes = {
        commentsOptions: PropTypes.array,
    }

    render() {
        const { commentsOptions } = this.props;
        if (!commentsOptions) {
            return null;
        }
        // This is pure-componennt it should be rendered only when
        // commentOptions has changed. It's safe to build panes here. 
        const panes = commentsOptions.map(({ title, threadId, threadType, notification }) => ({
            menuItem: <Menu.Item key={threadId}><CommentsHeaderContainer title={title} threadId={threadId} /></Menu.Item>,
            render: () => <Comments hideHeader threadId={threadId} threadType={threadType} notification={notification} />,
        }));

        return (
            <Tab
                style={{marginTop: "1.5em"}}
                menu={{ secondary: true, pointing: true }}
                panes={panes} renderActiveOnly
            />
        );
    }
}
