import { TYPES } from "./dashboardActions";

const initialState = {
    // TODO: temporary workaround before the dashboard definition will be stored in DB
    id: 1,
    tiles: [
        { id: 1, componentName: "ReportTile", x: 1, y: 1, width: 2, height: 2, notification: 456 },
        { id: 4, componentName: "ReportTile", x: 4, y: 1, width: 1, height: 1, completeness: 50 },
        { id: 99, componentName: "DesignTile", x: 2, y: 3, width: 2, height: 2 },
    ]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.DASHBOARD_SET:
            return { ...state, ...action.dashboard };
        default:
            return state;
    }
};

export default reducer;
