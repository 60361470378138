import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";

const COLORS = {
    error: "red",
    warning: "orange",
    info: "blue",
};

export default class ErrorMessage extends PureComponent {

    static propTypes = {
        message: PropTypes.shape({
            type: PropTypes.oneOf(["error", "warning", "info"]),  // default "info"
            header: PropTypes.string,
            content: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.string),
                PropTypes.string,
            ]).isRequired,
        }),
        onDismiss: PropTypes.func.isRequired,
    }

    render() {
        const { message, onDismiss } = this.props;
        const { type, header, content } = message;
        let list;
        let stringContent;

        if (Array.isArray(content)) {
            list = content;
        }
        else {
            stringContent = content;
        }

        return (<Message floating color={COLORS[type]} header={header} content={stringContent} list={list} onDismiss={onDismiss} />);
    }
}
