import { TYPES } from "./GeneratesDataForiPlannerActions";

const initialState = {
    inProgress: false,
    progress: { outline: false, template: false, syllabus: false, syllabusProblem: false },
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.GENERATES_IPLANNER_DATA:
            return { ...state, inProgress: true, progress: { outline: false, template: false, syllabus: false, syllabusProblem: false } };
        case TYPES.GENERATES_IPLANNER_DATA_FINISHED:
            return { ...state, inProgress: false };
        case TYPES.GENERATES_IPLANNER_DATA_PROGRESS:
            return { ...state, progress: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
