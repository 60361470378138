import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

export const CountIndicator = ({ counter, value }) => {
    let color = "grey";

    switch (counter) {
        case "lesson":
            color = "orange";
            break;
        case "widget":
            color = "teal";
            break;
        case "problem":
            color = "brown";
            break;
    }
    return value ? (
        <Label circular color={color} size="tiny" basic title={counter}>
            {value}
        </Label>
    ) : null;
};

CountIndicator.propTypes = {
    counter: PropTypes.string,
    value: PropTypes.number,
};
