import { push } from "connected-react-router";
import { all, takeLatest, put, takeEvery } from "redux-saga/effects";
import { delay } from "redux-saga";
import { lessonContent } from "../../dataSource";
import { TYPES, onSelectProblemSet, onCheckProblemSet, onCheckedProblemSet, onShuffleFinished } from "./LessonProblemSetActions";
import { checkProblemSetCompatibility } from "./utils";
import firebase from "../../../firebase";
import { onTabChange } from "../LessonContentActions";
import { outlineManager } from "../../Outlines/DataSource";
import { REVIEW_STATS_COLLECTION } from "../../../component/seamlessEditor/bookEditor/constants";
import { toastError } from "../../../component/toast";
import { shuffle } from "../../component/problemUtils";
import { renderProblemSet } from "./renderProblemSet";

const REMOVE_PROBLEM_SET_FROM_LESSON = {};

function* editProblemSet({ payload: { id, lessonId, outlineId, tabIndex } }) {
    yield put(push(`/qu/problem-set-creator/${id}?lesson=${lessonId}&outline=${outlineId}&tab=${tabIndex}`));
}

const collectionToReviewStatsCollection = {
    homework: REVIEW_STATS_COLLECTION.HOMEWORK,
    problem_sets: REVIEW_STATS_COLLECTION.PROBLEM_SETS,
};

function* updateProblemSet(outlineId, lessonId, problemSetId, collection, data) {
    const reviewStatsCollection = collectionToReviewStatsCollection[collection];
    let psData;

    if (data === REMOVE_PROBLEM_SET_FROM_LESSON) {
        yield outlineManager.outlineLessonUpdateReviewStats(reviewStatsCollection, outlineId, lessonId, problemSetId);
        data = null;
    }
    else {
        psData = (yield firebase.getFirebaseData(`problem_sets_data/${problemSetId}`).once("value")).val();

        if (!psData) {
            toastError({
                code: -1,
                header: "Inconsistent data for Problem Set",
                message: `There is no generated content for the current problem set (${problemSetId}).`
                    + " Try to re-save this problem set (e.g.: add and remove space into any problem)",
            });
            return;
        }

        yield outlineManager.outlineLessonUpdateReviewStats(reviewStatsCollection, outlineId, lessonId, problemSetId, psData.reviewsCount);
        data = {
            title: psData.title,
            ...data,
        };
    }

    yield lessonContent.updateProblemSet(outlineId, lessonId, problemSetId, collection, data);
}

function* shuffleAnswers({ payload: { outlineId, lessonId, collection, problemSetId } }) {
    const problemList = (yield firebase.getFirebaseData(`problemSet/${problemSetId}/problems`).once("value")).val();

    if (!problemList) {
        yield put(onShuffleFinished());
        return;
    }

    const shuffledProblemTypes = ["mc", "mx", "ma"];
    const dataRef = firebase.getFirebaseData(`outlines_data/${outlineId}/lessons/${lessonId}/${collection}/${problemSetId}`);
    const data = (yield dataRef.once("value")).val();

    const snapshots = yield Promise.all(problemList.map((problemId) =>
        firebase.getFirebaseData(`problem/${problemId}`).once("value")
    ));

    const shuffledProblems = snapshots.reduce((acc, problemSnapshot, index) => {
        const problem = problemSnapshot.val();
        const { problemType, answer } = problem;

        if (shuffledProblemTypes.includes(problemType)) {
            const problemId = problemList[index];
            const indexList = [];
            const locked = [];

            answer.forEach((a, i) => {
                indexList.push(i);
                a.isLocked && locked.push(i);
            });
            const currentOrder = data["shuffled_problems"] && data["shuffled_problems"][problemId];
            acc[problemId] = shuffle(indexList, { currentOrder, locked }).join(",");
        }
        return acc;
    }, {});

    data["shuffled_problems"] = Object.keys(shuffledProblems).length ? shuffledProblems : null;
    yield lessonContent.updateProblemSet(outlineId, lessonId, problemSetId, collection, data);
    yield put(onShuffleFinished());
}

function* setProblemSet({ payload: { id, outlineId, lessonId, tabIndex, collection, ordering, redirectToEditor = true, checkProblemSet = false, callback } }) {
    if (checkProblemSet) {
        yield put(onCheckProblemSet());
        const isCompatible = yield checkProblemSetCompatibility(id, outlineId);
        if (isCompatible) {
            yield put(onCheckedProblemSet(null));
        } else {
            yield put(onCheckedProblemSet({ error: true, problemSetId: id }));
            return;
        }
    } else {
        yield put(onCheckedProblemSet(null));
    }
    yield updateProblemSet(outlineId, lessonId, id, collection, { ordering });
    yield shuffleAnswers({ payload: { outlineId, lessonId, collection, problemSetId: id }});
    yield put(onSelectProblemSet(id));
    yield put(onTabChange(collection));
    if (redirectToEditor) {
        yield editProblemSet({ payload: { id, lessonId, outlineId, tabIndex }});
    }
    callback && callback();
}

function* removeProblemSet({ payload: { id, outlineId, lessonId, collection, deleteSet, deleteSetProblems }}) {
    yield updateProblemSet(outlineId, lessonId, id, collection, REMOVE_PROBLEM_SET_FROM_LESSON);
    if (deleteSet){
        const problemSet = (yield firebase.getFirebaseData("problemSet").child(id).once("value")).val();
        const proms = [];
        for (const problemId of (problemSet.problems || [])) {
            const problem = (yield firebase.getFirebaseData("problem").child(problemId).once("value")).val();
            if (
                deleteSetProblems &&
                problem &&
                !problem.widgets &&
                ((problem.problemSets &&
                    problem.problemSets[id] &&
                    1 === Object.keys(problem.problemSets).length) ||
                    !problem.problemSets)
            ) {
                // remove only problems used only in this problem set
                proms.push(
                    firebase.getFirebaseData("problem").child(problemId).remove()
                );
            } else {
                // remove reference to this problem set
                proms.push(
                    firebase.getFirebaseData("problem").child(problemId).child("problemSets").child(id).remove()
                );
            }
        }
        yield Promise.all(proms);
        yield firebase.getFirebaseData("problemSet").child(id).remove();
        yield firebase.getFirebaseData("problem_sets_data").child(id).remove();
    }
}

function* isCloneDone(problemSetId) {
    return !(yield firebase.getFirebaseData(`problemSet/${problemSetId}/makeDeepClone`).once("value")).val();
}

/**
 * Simplified version of `redux-saga/effects/retry` which is accessible since @v1.
 * Can be removed after upgrading `redux-saga`.
 */
function* retry(maxTries, timeout, fn, arg) {
    let done;

    for (let i = 0; i < maxTries; i++) {
        done = yield fn(arg);

        if (done) {
            return;
        }

        yield delay(timeout);
    }
    throw new Error(`Cloning Problem Set ${arg} timed-out after ${maxTries * timeout} ms.`);
}

function* cloneProblemSet({ payload: { problemSetId, outlineId, lessonId, collection, ordering } }) {
    const MAX_TRIES = 10;
    const DELAY = 400;
    const problemSetRef = firebase.getFirebaseData("problemSet");
    const problemSet = (yield problemSetRef.child(problemSetId).once("value")).val();
    if (problemSet) {
        const newProblemSet = { ...problemSet, lessons_homework: null, lessons_problem_sets: null, makeDeepClone: true, outlineId };
        const { key } = yield problemSetRef.push(newProblemSet);

        try {
            // Wait for removing makeDeepClone property (firebase trigger).
            yield retry(MAX_TRIES, DELAY, isCloneDone, key);
            const rendered = yield renderProblemSet(key);
            firebase.getFirebaseData(`problem_sets_data/${key}`).set(rendered);
        }
        catch (e) {
            toastError({
                code: -1,
                header: "Cloning Problem Set failed",
                message: e.message,
            });
        }
        yield updateProblemSet(outlineId, lessonId, key, collection, { ordering });
        yield put(onSelectProblemSet(key));
        yield put(onTabChange(collection));
    }
}

export default function* lessonProblemSetSaga() {
    yield all([
        takeLatest(TYPES.LESSON_PROBLEM_SET_EDIT_PROBLEM_SET, editProblemSet),
        takeEvery(TYPES.LESSON_PROBLEM_SET_SET_PROBLEM_SET, setProblemSet),
        takeEvery(TYPES.LESSON_PROBLEM_SET_SHUFFLE , shuffleAnswers),
        takeEvery(TYPES.LESSON_PROBLEM_SET_REMOVE_PROBLEM_SET, removeProblemSet),
        takeEvery(TYPES.LESSON_PROBLEM_SET_CLONE_PROBLEM_SET, cloneProblemSet),
    ]);
}
