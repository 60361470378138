import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import firebase from "../../firebase";
import { humanFileSize } from "../../utils/conversionUtils";
import styles from "./FileItemContent.module.scss";

export default class FileItemContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { href, name, storagePath, size } = content || {};
        const title = (content && content.title) || (name && `Download ${name}`) || "Download file";
        return (
            <div className={content && content.name ? "contains file" : "missing file ui warning message"}>
                {content && content.name ? (
                    <div className={styles.item}>
                        <a
                            className={styles.name}
                            href={href ? href : firebase.getFileURL(storagePath)}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={title}
                        >
                            <Icon name="download" />
                            {name}
                        </a>
                        {size && <span className={styles.size}>{humanFileSize(size)}</span>}
                    </div>
                ) : (
                    "No file has been uploaded."
                )}
            </div>
        );
    }
}
