import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Icon, Dimmer, Loader, Menu, Segment, Message, Tab } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { onCreateResource, onLoadTags, onAddTag } from "./ResourceLink/ResourceLinkActions";
import { isCreating, getTagsOptions, isTagsLoading } from "./ResourceLink/ResourceLinkSelectors";
import ActionLink from "../../component/ActionLink";
import { humanFileSize } from "../../utils/conversionUtils";
import { authUser } from "../../auth/authSelectors";
import { onFetchSourceTypes } from "../Resources/ResourcesActions";
import { getSourceTypesOptions, isLoadingSourceTypes } from "../Resources/ResourcesSelectors";
import RenderPublicFlag from "../Resources/components/ResourcePublicFlag/RenderPublicFlag";
import { KnownSourceTypeOptions, SourceTypeUnspecified } from "../Resources/components/ResourceSourceType/SourceTypeUtils";
import ExistingLinkResourceConfirm from "./ExistingLinkResourceConfirm";
import ResourceLink from "./ResourceLink";
import memoizeOne from "memoize-one";
import TopicTreeSelector from "../../TopicCollection/TopicTreeSelector";
import { withFirebase } from "react-redux-firebase";

const validator = require("validator");

const options = [{ key: "file", value: "file", text: "File" }, { key: "link", value: "link", text: "Link" }];

class CreateResourceDialog extends PureComponent {
    static propTypes = {
        onCreate: PropTypes.func,
        onCreateResource: PropTypes.func,
        isCreatingResource: PropTypes.bool,
        onAddTag: PropTypes.func,
        onLoadTags: PropTypes.func,
        tagsOptions: PropTypes.array,
        isTagsLoading: PropTypes.bool,
        onFetchSourceTypes: PropTypes.func,
        sourceTypesOptions: PropTypes.array,
        isSourceTypesLoading: PropTypes.bool,
        renderTriggerAsMenu: PropTypes.bool,
        renderTriggerAsLink: PropTypes.bool,
        authUser: PropTypes.object,
        firebase: PropTypes.object.isRequired,
    };

    state = {
        isOpen: false,
        type: null,
        name: "",
        href: "",
        description: "",
        file: null,
        errors: [],
        tags: [],
        isPublic: false,
        sourceType: SourceTypeUnspecified,
        isOpenConfirm: false,
        existingResourceId: null,
        selectedTopic: {},
        activeTab: 0,
    };

    handleSelectTopic = topic => this.setState({ selectedTopic: { ...topic } });

    componentDidMount() {
        const { onLoadTags, onFetchSourceTypes, firebase } = this.props;
        onLoadTags && onLoadTags();
        onFetchSourceTypes && onFetchSourceTypes();
        firebase.watchEvent("value", "/topic_collections/list");
        firebase.watchEvent("value", "/topic_collections/data");
    }

    handleOpen = e => {
        e.preventDefault();
        this.setState({
            isOpen: true,
            name: "",
            type: null,
            file: null,
            description: "",
            href: "",
            errors: [],
            tags: [],
            isPublic: false,
            sourceType: SourceTypeUnspecified,
            isOpenConfirm: false,
            existingResourceId: null
        });
    };

    handleClose = () => this.setState({ isOpen: false });
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value, errors: this.state.errors.filter(error => error != name) });
    };

    handleDragOver = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    handleDragIn = e => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true, file: null });
        }
    };

    handleDragOut = e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ dragging: false });
    };

    // file selected by drag and drop
    handleFileDrop = e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ dragging: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            this.setState({ file, name: file.name, errors: this.state.errors.filter(error => error != "file") });
            e.dataTransfer.clearData();
        }
    };
    validateData = () => {
        const errors = [];
        const { name, href, type, file } = this.state;
        let result = true;
        if (0 == name.trim().length) {
            errors.push("name");
            result = false;
        }
        if (null == type) {
            errors.push("type");
            result = false;
        } else if ("link" === type) {
            if (!validator.isURL(href, { protocols: ["http", "https"], require_protocol: true })) {
                errors.push("href");
                result = false;
            }
        } else if (!file) {
            errors.push("file");
            result = false;
        }
        if (!result) {
            this.setState({ errors, activeTab: 0 });
        } else {
            this.setState({ errors });
        }
        return result;
    };

    handleSelectFile = e => {
        const file = e.target.files[0];
        this.setState({ file, name: file.name, errors: this.state.errors.filter(error => error != "file") });
    };

    handleFileRemove = () => {
        this.setState({ file: null, name: "", errors: [] });
    };
    handleCloseConfirmDialog = () => this.setState({ isOpenConfirm: false });

    handleResourceCreated = (resource_id, alreadyExists = false) => {
        if (!alreadyExists) {
            this.handleClose();
            this.props.onCreate(resource_id);
        } else {
            this.setState({ isOpenConfirm: true, existingResourceId: resource_id });
        }
    };

    handleUseExisting = () => {
        this.handleClose();
        this.props.onCreate(this.state.existingResourceId);
    }

    handleSave = () => {
        const { name, type, href, description, file, tags, isPublic, sourceType, selectedTopic } = this.state;
        const { onCreateResource } = this.props;

        if (this.validateData()) {
            const payload = { name, type, description, tags, isPublic, sourceType, selectedTopic, callback: this.handleResourceCreated };
            if ("link" === type) {
                onCreateResource({ ...payload, href });
            } else {
                onCreateResource({ ...payload, file });
            }
        }
    };

    handleAddTag = (e, { value }) => {
        this.props.onAddTag(value);
        e.stopPropagation();
    };

    handleToggle = (e, { name, checked }) => {
        const changes = { [name]: checked };
        this.setState(changes);
    }

    generatePanes = memoizeOne((
        errors, type, tags, file, href, tagsOptions, dragging, sourceTypeOptions,
        sourceType, isSourceTypeLoading, description, isPublic, isTagsLoading, name,
        selectedTopic, authUser) => {
        return [
            {
                menuItem: "General",
                pane: (
                    <Tab.Pane key="genPane">
                        <Form error={0 !== errors.length}>
                            <Form.Select
                                value={type}
                                required
                                onChange={this.handleChange}
                                name="type"
                                label="Type"
                                placeholder="Choose resource type"
                                options={options}
                                {...errors.indexOf("type") != -1 && {
                                    error: true,
                                    placeholder: "Please choose resource type.",
                                }}
                            />
                            {type &&
                                ("link" === type ? (
                                    <React.Fragment><Form.Input
                                        required
                                        label="URL"
                                        name="href"
                                        value={href}
                                        placeholder="Fill in the URL of a link that starts with http:// or https://"
                                        onChange={this.handleChange}
                                        {...errors.indexOf("href") != -1 && {
                                            error: true,
                                            placeholder: "A filled-in link is not a valid URL.",
                                        }}
                                    />
                                    {errors.indexOf("href") != -1 && <Message error >A filled-in link is not a valid URL.
                                    Please Fill in the URL of a link that starts with http:// or https://.</Message>}
                                    </React.Fragment>
                                ) : (
                                    <Form.Field inline required>
                                        <Icon name="upload" color="grey" />
                                        <label>File to upload</label>
                                        {(!file && (
                                            <React.Fragment>
                                                <input
                                                    style={{ border: 0 }}
                                                    type="file"
                                                    id="file"
                                                    onChange={this.handleSelectFile}
                                                />
                                                <Segment
                                                    textAlign="center"
                                                    style={{
                                                        border: dragging ? "solid grey 1px" : "dashed grey 1px",
                                                        fontSize: "1.5em",
                                                        color: "grey",
                                                    }}
                                                    onDragOver={this.handleDragOver}
                                                    onDragEnter={this.handleDragIn}
                                                    onDragLeave={this.handleDragOut}
                                                    onDrop={this.handleFileDrop}
                                                >
                                                    or drop a file here
                                                </Segment>
                                            </React.Fragment>
                                        )) || (
                                            <span style={{ marginLeft: "1em", color: "grey" }}>
                                                <span>File: {file.name}</span>
                                                <span style={{ marginLeft: "5em" }}>
                                                    Size: {humanFileSize(file.size)}
                                                </span>
                                                <Icon
                                                    name="close"
                                                    color="grey"
                                                    style={{ marginLeft: "3em" }}
                                                    onClick={this.handleFileRemove}
                                                />
                                            </span>
                                        )}
                                        {errors.indexOf("file") != -1 && (
                                            <span style={{ color: "red" }}>Please select a file to upload.</span>
                                        )}
                                    </Form.Field>
                                ))}
                            <Form.Group>
                                <Form.Input
                                    required
                                    label="Name"
                                    onChange={this.handleChange}
                                    value={name}
                                    name="name"
                                    placeholder="Fill resource name"
                                    {...errors.indexOf("name") != -1 && {
                                        error: true,
                                        placeholder: "Please fill resource name",
                                    }}
                                    width={12}
                                />
                                <Form.Dropdown
                                    options={sourceTypeOptions || KnownSourceTypeOptions}
                                    selection
                                    fluid
                                    loading={isSourceTypeLoading}
                                    label="Source Type"
                                    name="sourceType"
                                    placeholder="Please, choose the source type"
                                    onChange={this.handleChange}
                                    value={sourceType}
                                    closeOnChange
                                    width={4}
                                />
                            </Form.Group>
                            <Form.TextArea
                                label="Description"
                                name="description"
                                value={description}
                                onChange={this.handleChange}
                                placeholder="Here you can fill resource description"
                            />
                            {authUser.tenant && (0 === authUser.tenant.is_isolated || false === authUser.tenant.is_isolated) &&
                            <Form.Group grouped>
                                <label>Sharing options</label>
                                <RenderPublicFlag isPublic={isPublic} onChange={this.handleToggle} />
                            </Form.Group>}
                        </Form>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "Tags & Topics",
                pane: (
                    <Tab.Pane key="settingsPane">
                        <Form>
                            <Form.Dropdown
                                options={tagsOptions}
                                multiple
                                selection
                                fluid
                                search
                                lazyLoad
                                allowAdditions
                                loading={isTagsLoading}
                                label="Tags"
                                name="tags"
                                additionLabel="Add tag "
                                onAddItem={this.handleAddTag}
                                placeholder="Here you can add tag"
                                onChange={this.handleChange}
                                value={tags}
                                closeOnChange
                            />
                            <Form.Field label="Topics" />
                        </Form>
                        <TopicTreeSelector
                            onChange={this.handleSelectTopic}
                            selectedTopics={selectedTopic}
                        />
                    </Tab.Pane>
                ),
            }
        ];
    });

    handleTabChange = (e, { activeIndex }) => this.setState({ activeTab: activeIndex });

    render() {
        const { isOpen, type, name, description, href, file, errors, tags, dragging, isPublic,
            sourceType, isOpenConfirm, existingResourceId, selectedTopic, activeTab } = this.state;
        const {
            isCreatingResource,
            tagsOptions,
            isTagsLoading,
            sourceTypeOptions,
            isSourceTypeLoading,
            renderTriggerAsMenu,
            renderTriggerAsLink,
            triggerLabel = "Create New",
            authUser,
        } = this.props;
        return (
            <Modal
                open={isOpen}
                onClose={this.handleClose}
                closeOnDimmerClick={false}
                trigger={
                    renderTriggerAsMenu ? (
                        <Menu.Item link title="Click here to add new resource." onClick={this.handleOpen}>
                            <Icon name="plus" /> {triggerLabel}
                        </Menu.Item>
                    ) : renderTriggerAsLink ? (
                        <ActionLink
                            horizontal
                            icon="plus"
                            title={triggerLabel}
                            content={triggerLabel}
                            onClick={this.handleOpen}
                        />
                    ) : (
                        <Button onClick={this.handleOpen} style={{ marginRight: "0.5em" }} content={triggerLabel} />
                    )
                }
            >
                <Modal.Header>Create New Resource</Modal.Header>
                <Modal.Content style={{ minHeight: "460px" }}>
                    <ExistingLinkResourceConfirm
                        isOpen={isOpenConfirm}
                        onClose={this.handleCloseConfirmDialog}
                        onUse={this.handleUseExisting}
                        title="Duplicate resource"
                    >
                        <p>
                            Resource with the same URL already exists, do you want to use it?
                        </p>
                        <ResourceLink sipi={{ resource_id: existingResourceId }} renderAsLink />
                    </ExistingLinkResourceConfirm>
                    <Dimmer.Dimmable>
                        <Dimmer active={isCreatingResource} inverted>
                            <Loader inverted>Creating resource</Loader>
                        </Dimmer>
                        <Tab
                            activeIndex={activeTab}
                            renderActiveOnly={false}
                            onTabChange={this.handleTabChange}
                            panes={this.generatePanes(
                                errors,
                                type,
                                tags,
                                file,
                                href,
                                tagsOptions,
                                dragging,
                                sourceTypeOptions,
                                sourceType,
                                isSourceTypeLoading,
                                description,
                                isPublic,
                                isTagsLoading,
                                name,
                                selectedTopic,
                                authUser,
                            )}
                        />
                        {0 < errors.length && <Message error content="There are some validation errors. Please fill all required values." />}
                    </Dimmer.Dimmable>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        saveDisabled={isCreatingResource}
                        onCancel={this.handleClose}
                        onSave={this.handleSave}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

const dispatchToProps = (dispatch) => bindActionCreators({
    onCreateResource,
    onLoadTags,
    onAddTag,
    onFetchSourceTypes,
}, dispatch);

const mapStateToProps = (state) => ({
    isCreatingResource: isCreating(state),
    tagsOptions: getTagsOptions(state),
    isTagsLoading: isTagsLoading(state),
    sourceTypesOptions: getSourceTypesOptions(state),
    isSourceTypesLoading: isLoadingSourceTypes(state),
    authUser: authUser(state),
});

export default compose(
    withFirebase,
    connect(mapStateToProps, dispatchToProps)
)(CreateResourceDialog);
