import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button, Icon, Message } from "semantic-ui-react";

export default class CreateProblemSetsData extends PureComponent {
    static propTypes = {
        onGenerateProblemSetData: PropTypes.func.isRequired,
        progress: PropTypes.object,
    };

    renderProgressIcon = (check, when = true) => {
        const { inProgress, done } = this.props.progress;

        if ((inProgress || done) && when) {
            return (<React.Fragment>
                {" "}
                {check ? <Icon name="check" color="green" /> : <Icon name="circle notched" loading />}
            </React.Fragment>);
        }
    }

    render() {
        const { progress, onGenerateProblemSetData } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    {"Problem Sets: store generated HTML and reviews count"}
                </Header>
                <Segment secondary>
                    This page generates an HTML content for each Problem Set based on its related problems
                    and adds aggregated count of reviews (per reviewType).
                    <br />
                    It creates collection <b>problem_sets_data</b> as a replacement of collections <b>lesson_problem_sets </b>
                    and <b>lesson_homework</b>.
                    <br />
                    <span style={{ color: "red" }}>These two collections were removed in 1.0.2.908 release </span>
                    (see <a href="https://bmdcon.easyredmine.com/issues/21466" target="_blank" rel="noopener noreferrer">
                        #21466
                    </a> for more details).
                    <br />
                    <br />
                    ProblemSet/Homework title is stored in duplicate in
                    <b> outlines_data/[outlineId]/lessons/[lessonId]/[PS-HW]/[problemSetId] </b>
                    collection (PS-HW means problem_sets/homework).
                    Because of bug <a href="https://bmdcon.easyredmine.com/issues/21987" target="_blank" rel="noopener noreferrer">
                    #21987</a> it was not updated when a Problem Set was renamed. It will be fixed now.
                </Segment>
                <Message info>
                    <Message.Header>Creates / updates collection <i>problem_sets_data</i></Message.Header>
                    <Message.List>
                        <Message.Item>
                            Removes collections <b>lesson_problem_sets</b> and <b>lesson_homework</b> (if exist)
                            {this.renderProgressIcon(progress.oldRemoved)}
                        </Message.Item>
                        <Message.Item>
                            Downloads collection <b>problemSets </b>{this.renderProgressIcon(progress.fetched, progress.oldRemoved)}
                        </Message.Item>
                        <Message.Item>
                            Creates <b>data</b> (HTML content, reviews count) for {progress.total} <b>problem sets</b>
                            {progress.count ? ` (${progress.count} done) ` : ""}
                            {this.renderProgressIcon(progress.dataCreated, progress.fetched)}
                        </Message.Item>
                        <Message.Item>
                            Updates <b>title</b> property in <b>outlines_data </b>
                            (checked: {progress.checkedTitles || 0}, fixed: {progress.fixedTitles || 0})
                            {this.renderProgressIcon(progress.done, progress.dataCreated)}
                        </Message.Item>
                    </Message.List>
                </Message>
                {!progress.inProgress && (
                    <Button primary onClick={onGenerateProblemSetData}>
                        Generate
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
