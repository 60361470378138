const TYPES = {
    RESOURCE_OWNER_SAVE: "RESOURCE_OWNER_SAVE",
    RESOURCE_OWNER_SAVED: "RESOURCE_OWNER_SAVED",
};

const onSave = (payload) => ({
    type: TYPES.RESOURCE_OWNER_SAVE,
    payload,
});

const onSaved = (payload) => ({
    type: TYPES.RESOURCE_OWNER_SAVED,
    payload,
});

export {
    TYPES,
    onSave,
    onSaved,
};
