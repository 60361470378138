const TYPES = {
    TOPIC_TREE_SELECTOR_SET_LESSON_FILTER: "TOPIC_TREE_SELECTOR_SET_LESSON_FILTER",
    TOPIC_TREE_SELECTOR_SEARCH_LESSON: "TOPIC_TREE_SELECTOR_SEARCH_LESSON",
    TOPIC_TREE_SELECTOR_SET_SEARCH_RESULT: "TOPIC_TREE_SELECTOR_SET_SEARCH_RESULT",
    TOPIC_TREE_SELECTOR_SET_AKIT: "TOPIC_TREE_SELECTOR_SET_AKIT",
};

const onSetLessonFilter = (payload) => ({
    type: TYPES.TOPIC_TREE_SELECTOR_SET_LESSON_FILTER,
    payload
});

const onSetSearchResult = (payload) => ({
    type: TYPES.TOPIC_TREE_SELECTOR_SET_SEARCH_RESULT,
    payload
});

const onSearchLesson = (payload) => ({
    type: TYPES.TOPIC_TREE_SELECTOR_SEARCH_LESSON,
    payload
});

const onSetAKit = (payload) => ({
    type: TYPES.TOPIC_TREE_SELECTOR_SET_AKIT,
    payload
});

export {
    TYPES,
    onSetLessonFilter,
    onSetSearchResult,
    onSearchLesson,
    onSetAKit
};