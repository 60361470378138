import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const SideBarMenuLink = ({to, text}) => (
    <NavLink to={to} className="item" activeClassName="active" >{text}</NavLink>
);

SideBarMenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired
};