import React from "react";
import PropTypes from "prop-types";
import { Menu, Message } from "semantic-ui-react";
import { getSnippetClassName } from "./SnippetUtils";

import "./SnippetMenu.scss";

class SnippetMenuItem extends React.PureComponent {
    static propTypes = {
        snippet: PropTypes.object.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    handleClick = (e) => {
        const { snippet, onClick } = this.props;
        e.stopPropagation();
        onClick(snippet.key, snippet.snippetCfg);
    };

    render() {
        const { key, text, snippetCfg } = this.props.snippet;
        return (
            <Menu.Item key={key} onClick={this.handleClick} className="snippet-demo">
                <span className="name">{text}</span>
                {snippetCfg && snippetCfg.hideable && <em className="flag">Hideable</em>}
                <div className={getSnippetClassName(key, "block preview")} />
            </Menu.Item>
        );
    }
}

class SnippetDemo extends React.PureComponent {
    static propTypes = {
        snippet: PropTypes.object.isRequired,
    };

    render() {
        const { key, text, snippetCfg } = this.props.snippet;
        return (
            <div className="snippet-demo">
                <span className="name">{text}</span>
                {snippetCfg && snippetCfg.hideable && <em className="flag">Hideable</em>}
                <div className={getSnippetClassName(key, "block preview")} />
            </div>
        );
    }
}

export default class SnippetMenu extends React.PureComponent {
    static propTypes = {
        snippets: PropTypes.array,
        onSelect: PropTypes.func,
    };

    static Item = SnippetMenuItem;
    static Demo = SnippetDemo;

    render() {
        const { snippets, onSelect } = this.props;
        return (
            <Menu secondary vertical className="scrollable">
                {0 < snippets.length ? (
                    snippets.map((snippet) => (
                        <SnippetMenuItem key={snippet.key} snippet={snippet} onClick={onSelect} />
                    ))
                ) : (
                    <Message warning content="No Styled Blocks found." />
                )}
            </Menu>
        );
    }
}
