import {
    renderTrueFalseOptions,
    renderTrueFalseAnswer,
    renderMultiChoiceAnswer,
    renderMatchingQuestion,
} from "../../component/problemUtils";
import firebase from "../../dataSource";
import { REVIEW_TYPE } from "../../../component/seamlessEditor/bookEditor/constants";

const getProblemSetProblems = async (problemIds, fbCollection) => {
    const problemCollection = "problemSet" === fbCollection ? "problem" : "comptests_cvault";
    const problemSnapshots = await Promise.all(
        problemIds.map((problemId) => firebase.getFirebaseData(`${problemCollection}/${problemId}`).once("value"))
    );
    const problems = {};
    const childProblemsToFetch = [];

    problemSnapshots.forEach((snapshot, index) => {
        const problemId = problemIds[index];
        const problem = snapshot.val();
        problems[problemId] = problem;
        const { children } = problem;

        if (children) {
            children.forEach((childProblemId) => {
                if (!problemIds.includes(childProblemId) && !childProblemsToFetch.includes(childProblemId)) {
                    childProblemsToFetch.push(childProblemId);
                }
            });
        }
    });

    if (childProblemsToFetch.length) {
        const childProblemsDataSnapshot = await Promise.all(
            childProblemsToFetch.map((problemId) => firebase.getFirebaseData(`${problemCollection}/${problemId}`).once("value"))
        );

        childProblemsDataSnapshot.forEach((snapshot, index) => {
            const problemId = childProblemsToFetch[index];
            const problem = snapshot.val();
            problems[problemId] = problem;
        });
    }

    return problems;
};

const getAnswerToggleButton = () =>
    "<button data-key=\"answer\" title=\"Toggle answer\" alt=\"Toggle answer\" class=\"toggle no-print isHidden\" onClick=\"return spork.events.onToggleHideable(event);\"></button>";

const understandingDiv = problemId =>
    `<div id="u${problemId}" class="understanding no_answer no-print" data-problem-id="${problemId}" onclick="return spork.events.onUnderstandingClick(event);">&emsp;</div>`;
const renderAnswerDiv = (content, showButton) => (showButton ? getAnswerToggleButton() : "") + `<div class="answer hidden">${content}</div>`;
const renderCompReportDiv = problemId =>
    `<div id="c${problemId}" class="no-print comprehension"><div>0</div><div>0</div><div>0</div><div>0</div></div>`;
const renderMenu =
    "<div class=\"menu no-print\">" +
    "<a href=\"javascript:void(0)\" onclick=\"return spork.events.toggleComprehensionMode(event)\">Show comprehension</a>" +
    "<a class=\"action\" href=\"javascript:void(0)\" onclick=\"return toggleCompactMode(event)\">Compact mode</a>" +
    "<a class=\"action\" href=\"javascript:void(0)\" onclick=\"return toggleNames(event)\">Student names</a></div>";
const renderHeader =
    "<div class=\"header no-print\"><div>Problems</div><div class=\"no-print comprehension\">" +
    "<div class=\"understand_plus\">&emsp;</div>" +
    "<div class=\"understand\">&emsp;</div>" +
    "<div class=\"dont_understand\">&emsp;</div>" +
    "<div class=\"no_answer\">&emsp;</div>" +
    "</div></div>";

export const formatProblemDivForWidget = (content, problemId) => [
    "<div style=\"display: flex\">",
    `<div id="${problemId}" class="problem">`,  // ID is necessary for shuffled multi-choice answers at least
    content.question,
    "</div>",
    "</div>",
].join("\n");

const formatProblemDiv = (content, problemId) => [
    "<div style=\"display: flex\">",
    `<div id="${problemId}" class="problem">`,  // ID is necessary for shuffled multi-choice answers at least
    understandingDiv(problemId),
    content.question,
    content.answer,
    "</div>",
    renderCompReportDiv(problemId),
    "</div>",
].join("\n");

const getNonMatchingProblemHtml = (question, options, answer, currIndex, showButton) => ({
    question: `<div class="question">
    <div><b>${currIndex})</b></div>
    <div class="block">${question}${options || ""}</div>
    </div>`,
    answer: renderAnswerDiv(answer, showButton),
    nextIndex: currIndex + 1,
});

const getMatchingAnswerProblemHtml = (problem, currIndex, showButton) => {
    const [options, answer, nextIndex] = renderMatchingQuestion(problem.answer, currIndex);
    return {
        question: `<div>${problem.question.content}</div><div>${options}</div>`,
        answer: renderAnswerDiv(answer, showButton),
        nextIndex,
    };
};

const getMultiChoiceProblemHtml = ({ question, answer }, currIndex, showButton = true) => {
    const multichoice = renderMultiChoiceAnswer(answer);
    return getNonMatchingProblemHtml(question.content, multichoice.options, multichoice.correct, currIndex, showButton);
};

const getTrueFalseProblemHtml = (problem, currIndex, showButton = true) => {
    const question = problem.question;
    const answer = renderTrueFalseAnswer(problem);
    return getNonMatchingProblemHtml(question.content, renderTrueFalseOptions, answer, currIndex, showButton);
};

const getOpenAnswerProblemHtml = ({ question, answer }, currIndex, showButton = true) =>
    getNonMatchingProblemHtml(question.content, "", answer.content, currIndex, showButton);

export const ProblemHtmlRenderers = {
    ma: getMatchingAnswerProblemHtml,
    mc: getMultiChoiceProblemHtml,
    mx: getMultiChoiceProblemHtml,
    tf: getTrueFalseProblemHtml,
    oa: getOpenAnswerProblemHtml,
};

const getParentProblemHtml = ({ question, children }, currIndex, problemsData) => {
    if (children) {
        const problemsHtml = children.map((problemId) => {
            const problem = problemsData[problemId];

            if (!problem) {
                return null;
            }

            const convert = ProblemHtmlRenderers[problem.problemType || problem.type];
            const content = convert(problem, currIndex);
            currIndex = content.nextIndex;

            return formatProblemDiv(content, problemId);
        });
        return {
            html: `<div>${question.content}<div class="parent">${problemsHtml.join("<hr />\n")}</div></div>`,
            nextIndex: currIndex,
        };
    } else {
        return {
            html: `<div>${question.content}</div>`,
            nextIndex: currIndex,
        };
    }
};

export const mergeReviewStats = (rsA, rsB) => {
    const rsKeysA = Object.keys(rsA || {});
    const rsBKeys = Object.keys(rsB || {});

    if (rsKeysA.length + rsBKeys.length === 0) {
        return null;
    }

    const reviewStats = { ...rsA };

    rsBKeys.forEach((reviewType) => {
        if (reviewStats[reviewType]) {
            reviewStats[reviewType] = reviewStats[reviewType].concat(rsB[reviewType]);
        }
        else {
            reviewStats[reviewType] = rsB[reviewType];
        }
    });

    return reviewStats;
};

const getProblemSetReviewsCount = (problemSet, problemsData) => {
    let { problems } = problemSet;
    let reviewStats = {};

    const reviewsCount = {
        [REVIEW_TYPE.REPLACE]: 0,
        [REVIEW_TYPE.INSERT]: 0,
        [REVIEW_TYPE.COMMENT_ONLY]: 0,
    };

    if (problems) {
        problems = [ ...problems ];

        // scan for child problems first and add them into the list
        for (let i = 0, cnt = problems.length; i < cnt; i++) {
            const problem = problemsData[problems[i]];

            if (problem && problem.children) {
                problem.children.forEach((childProblemId) => problems.push(childProblemId));
            }
        }

        problems = [ ...new Set(problems) ];

        problems.forEach((problemId) => {
            const problem = problemsData[problemId];

            if (problem) {
                const { question, answer } = problem;
                const problemReviewStats = mergeReviewStats(question && question.reviewStats, answer && answer.reviewStats);

                if (problemReviewStats) {
                    reviewStats = mergeReviewStats(reviewStats, problemReviewStats);
                }
            }
        });
    }

    let totalReviews = 0;
    Object.entries(reviewStats).forEach(([ reviewType, entries ]) => {
        reviewsCount[reviewType] += entries.length;
        totalReviews += entries.length;
    });

    return totalReviews ? reviewsCount : null;
};


export const renderProblemSet = async (id, fbCollection = "problemSet") => {
    const entityName = fbCollection === "problemSet" ? "Problem Set" : "Comprehensive Test";
    const problemSetRef = firebase.getFirebaseData(`${fbCollection}/${id}`);
    const problemSet = (await problemSetRef.once("value")).val();
    const problemsData = problemSet.problems ? await getProblemSetProblems(problemSet.problems, fbCollection) : {};

    if (!problemSet) {
        return {
            html: `<em>${entityName} not found.</em>`,
            title: null,
        };
    }
    if (!problemSet.problems) {
        return {
            html: `<em>${entityName} ${problemSet.title} is empty.</em>`,
            title: problemSet.title,
        };
    }
    if (problemSet.makeDeepClone) {
        return {
            html: null,
            title: problemSet.title,
            //  ?? reviewsCount
        };
    }

    let position = 1;
    const problemsHtml = [];
    for (const problemId of problemSet.problems) {
        const problem = problemsData[problemId];

        if (!problem) {
            continue;
        }

        if ("pa" === problem.problemType || "pa" === problem.type) {
            const content = getParentProblemHtml(problem, position, problemsData);
            position = content.nextIndex;
            problemsHtml.push([`<div id="${problemId}" class="problem">`, content.html, "</div>"].join("\n"));
        } else {
            const convert = ProblemHtmlRenderers[problem.problemType || problem.type];
            const content = convert(problem, position);
            position = content.nextIndex;

            problemsHtml.push(formatProblemDiv(content, problemId));
        }
    }

    const reviewsCount = getProblemSetReviewsCount(problemSet, problemsData);
    const unprocessedReviews = reviewsCount && (reviewsCount[REVIEW_TYPE.REPLACE] + reviewsCount[REVIEW_TYPE.INSERT]);

    return {
        html: `${renderMenu}${renderHeader}<div class="problems">${problemsHtml.join(
            "<hr class=\"no-print\"/>\n"
        )}</div>`,
        title: problemSet.title,
        reviewsCount: getProblemSetReviewsCount(problemSet, problemsData),
        unprocessedReviews: unprocessedReviews || null,
    };
};
