import React from "react";
import PropTypes from "prop-types";
import { Segment, Label } from "semantic-ui-react";
import Widget from "./Widget";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import ActionLinks from "../../component/ActionLinks";

import "./WidgetViewer.css";

const createScopePredicate = (viewScope) => ({ value }) => {
    const itemScope = value && value.scope ? value.scope.toLowerCase() : "all";
    return itemScope === "all" || itemScope === viewScope;
};

export default class WidgetViewer extends React.PureComponent {
    static propTypes = {
        /** Definition of widget structure template */
        template: PropTypes.object.isRequired,
        /** Content of widget based on given template*/
        content: PropTypes.object,
        /** Ordered items from given widget template */
        items: PropTypes.array,
        /** Message to be shown when template doesn't have any item */
        noItemsText: PropTypes.string,
        customTitle: PropTypes.string,
        hiddenTitle: PropTypes.bool,
    };

    static defaultProps = {
        noItemsText: "Empty widget (no items)"
    };

    state = {
        viewScope: null
    };

    handleScopeAction = (e, data) => {
        const { name } = data;
        this.setState({
            viewScope: name === "teacher" || name === "student" ? name : null
        });
    };

    render() {
        const { template, items, content, noItemsText, customTitle, hiddenTitle } = this.props;
        const { viewScope } = this.state;
        const isEmpty = !(items ? items.length : Object.keys(template.items || {}).length);
        const actions = [
            { name: "preview", content: "All Items", active: !viewScope },
            { name: "teacher", content: "As Teacher", active: viewScope === "teacher" },
            { name: "student", content: "As Student", active: viewScope === "student" }
        ];
        return (
            <Segment basic color="blue" className="spork widget-viewer">
                <Label attached="top" style={{ display: "flex" }}>
                    <ActionLinks
                        floated="left"
                        horizontal
                        caption="VIEW"
                        actions={actions}
                        onClick={this.handleScopeAction}
                        disabled={isEmpty}
                    />
                </Label>
                <div style={{overflowY: "auto", maxHeight: "70vh"}}>
                    {isEmpty ? (
                        <Widget template={template} customTitle={customTitle} hiddenTitle={hiddenTitle}>
                            <NoDataPlaceholder text={noItemsText} />
                        </Widget>
                    ) : (
                        <Widget
                            template={template}
                            content={content}
                            customTitle={customTitle}
                            hiddenTitle={hiddenTitle}
                            filtered={viewScope && createScopePredicate(viewScope)}
                        />
                    )}
                </div>
            </Segment>
        );
    }
}
