import React from "react";
import PropTypes from "prop-types";
import { InfiniteTable } from "bmd-react";
import { Header, Icon, Dimmer, Loader } from "semantic-ui-react";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";

export default class CourseVariantList extends React.PureComponent {
    static propTypes = {
        courseVariants: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                rec_schedule: PropTypes.string,
                minutes_per_week: PropTypes.number,
                programs: PropTypes.string,
                intensity: PropTypes.string,
                regulator: PropTypes.string,
                min_grade: PropTypes.string,
                max_grade: PropTypes.atring,
                kit: PropTypes.shape({
                    kitId: PropTypes.string,
                    kitName: PropTypes.string,
                }),
            })
        ),
        onRedirectToKit: PropTypes.func.isRequired,
        onCourseVariantLoad: PropTypes.func.isRequired,
        selectedYear: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        isLoading: PropTypes.bool,
    };

    // load data upon startup
    componentDidMount() {
        this.props.onCourseVariantLoad();
    }

    // reload data if selected year changes
    componentDidUpdate(prevProps) {
        if (prevProps.selectedYear !== this.props.selectedYear) {
            this.props.onCourseVariantLoad();
        }
    }

    _columns = [
        {
            label: "Course Name",
            dataKey: "name",
            width: 320,
            visible: true,
        },
        {
            label: "Required Schedule",
            dataKey: "rec_schedule",
            width: 100,
            visible: true,
        },
        {
            label: "Minutes Per Week",
            dataKey: "minutes_per_week",
            width: 100,
            visible: true,
        },
        {
            label: "Programs",
            dataKey: "programs",
            width: 100,
            visible: true,
        },
        {
            label: "Usage",
            dataKey: "intensity",
            width: 80,
            visible: true,
        },
        {
            label: "Designated For",
            dataKey: "regulator",
            width: 150,
            visible: true,
        },
        {
            label: "Min Grade",
            dataKey: "min_grade",
            width: 50,
            visible: true,
        },
        {
            label: "Max Grade",
            dataKey: "max_grade",
            width: 50,
            visible: true,
        },
        {
            label: "",
            dataKey: "kit",
            width: 250,
            visible: true,
            disableSort: true,
            cellRenderer: ({ cellData }) => cellData && <KitLink kit={cellData} onClick={this.props.onRedirectToKit} />,
        },
    ];

    render() {
        const { courseVariants, isLoading, selectedYear } = this.props;
        return (
            <React.Fragment>
                <Dimmer active={isLoading} inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>

                <Header as="h2" className="pageHeader">
                    Courses
                    {selectedYear && <Header.Subheader>for {selectedYear.name}</Header.Subheader>}
                </Header>
                {!selectedYear ? (
                    <NoDataPlaceholder icon="file alternate outline" text="No school year selected">
                        <p>Please select a school year to display courses.</p>
                    </NoDataPlaceholder>
                ) : (
                    <div style={{ minHeight: "350px" }}>
                        <InfiniteTable
                            data={courseVariants}
                            columns={this._columns}
                            tableName="courseVariants"
                            defaultSortBy="name"
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

class KitLink extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        kit: PropTypes.shape({
            kitId: PropTypes.string,
            kitName: PropTypes.string,
        }),
    };

    handleClick = () => {
        const { kit, onClick } = this.props;
        onClick(kit.kitId);
    };

    render() {
        const { kit } = this.props;

        return (
            <a onClick={this.handleClick} style={{ cursor: "pointer" }} title={"Go to Author Kit " + kit.kitName}>
                <Icon name="book" />
                {kit.kitName}
            </a>
        );
    }
}
