import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, setTenantList, onEndCreateDemoTenant, setNewTenantInfo } from "./tenantActions";
import { toastError } from "../../component/toast";
import { convertListToDataset } from "../../utils/selectorUtils";

function* loadTenantList() {
    try {
        let tenants = yield firebase.tabletProxy({ method: "GET", uri: "/tenant?$sort[name]=1" });
        yield put(setTenantList(tenants.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of tenant list failed.", message: err.details.message });
        yield put(setTenantList([]));
    }
}

function* createDemoTenant() {
    try {
        let newTenant = yield firebase.tabletProxy({ method: "POST", uri: "/demo-tenant" });
        yield put(setNewTenantInfo(newTenant.data));
        yield loadTenantList();
    } catch (err) {
        toastError({ code: err.code, header: "Creating of demo tenant failed.", message: err.details.message });
    }
    yield put(onEndCreateDemoTenant());
}

function* updateTenantModules({ payload: { tenantId, options, modules }}) {
    try {
        const modulesCollection = convertListToDataset(modules);
        const packageTenantResponse = yield firebase.tabletProxy({
            method: "GET",
            uri: "/package-tenant?tenant_id=" + tenantId,
        });
        for (const moduleId of Object.keys(options)) {
            if (options[moduleId]) {
                if (modulesCollection[moduleId] && modulesCollection[moduleId].id) {
                    const package_id = modulesCollection[moduleId].id;
                    if (0 === packageTenantResponse.data.filter(i => i.package_id === package_id).length) {
                        yield firebase.tabletProxy({
                            method: "POST",
                            uri: "/package-tenant",
                            body: {
                                tenant_id: tenantId,
                                package_id 
                            }
                        });
                    }
                }
            } else {
                if (modulesCollection[moduleId] && modulesCollection[moduleId].id) {
                    const package_id = modulesCollection[moduleId].id;
                    const packageSubs = packageTenantResponse.data.filter(i => i.package_id === package_id);
                    if (1 === packageSubs.length) {
                        yield firebase.tabletProxy({
                            method: "DELETE",
                            uri: `/package-tenant/${packageSubs[0].id}`,
                        });
                    }
                }
            }
        }
        const changes = {
            [`tenant_modules/t${tenantId}`]: options
        };
        for (const moduleId of Object.keys(options)) {
            changes[`modules/list/${moduleId}/subscribers/t${tenantId}`] = options[moduleId];
        }
        yield firebase.getFirebaseData("/").update(changes);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to assigning packages to tenant.", message: err.details && err.details.message || err.message });
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.TENANT_LOAD_LIST, loadTenantList),
        takeEvery(TYPES.TENANT_CREATE_DEMO, createDemoTenant),
        takeEvery(TYPES.TENANT_UPDATE_MODULES, updateTenantModules),
    ]);
}
