import { createSelector } from "reselect";
import _ from "lodash";
import {
    mapCollectionToList,
    getSearchByNamePredicate,
    compareByNameCI,
    getSearchByUnusedPredicate,
    getSearchByTagsArrayPredicate,
    getSearchByOptionalIndexPredicate
} from "../../utils/selectorUtils";
import { getEnabledModules } from "../../External/Tenants/tenantSelectors";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { selectedWidgetModules } from "../WidgetLibrary/WidgetLibrarySelectors";

export const glossaryListCollection = state => state.firebase.data.glossary && state.firebase.data.glossary.list;
export const glossaryDataCollection = state => state.firebase.data.glossary && state.firebase.data.glossary.data;
export const getGlossaryModules = state => state.firebase.data.glossary && state.firebase.data.glossary.m;
const glossaryId = (state, props) => props.glossaryId || props.match && props.match.params && props.match.params.glossaryId;
export const moduleFilter = (state) => state.kitBuilder.glossary.moduleFilter;
export const selectedTags = (state) => state.kitBuilder.glossary.selectedTags;
export const unusedFilter = (state) => state.kitBuilder.glossary.unusedFilter;
export const tagFilter = (state) => state.kitBuilder.glossary.tagFilter;
export const isDirty = (state) => state.kitBuilder.glossary.isDirty;


export const getFilter = state => state.kitBuilder.glossary.filter;
export const getAddTerm = state => state.kitBuilder.glossary.addTerm;

export const getFilteredGlossary = createSelector(
    glossaryListCollection,
    getFilter,
    selectedTags,
    unusedFilter,
    tenantId,
    getEnabledModules,
    getGlossaryModules,
    moduleFilter,
    (glossary, searchFilter, selectedTags, unusedFilter, tenant, enabledModules, glossaryModules, modFilter) => {
        if (glossary) {
            let filteredData = mapCollectionToList(glossary).sort(compareByNameCI);
            const unusedPredicate = getSearchByUnusedPredicate(unusedFilter);
            if (unusedPredicate) {
                filteredData = filteredData.filter(unusedPredicate);
            }
            const tagsPredicate = getSearchByTagsArrayPredicate(selectedTags);
            if (tagsPredicate) {
                filteredData = filteredData.filter(tagsPredicate);
            }
            const namePredicate = getSearchByNamePredicate(searchFilter);
            if (namePredicate) {
                filteredData = filteredData.filter(namePredicate);
            }
            const modulePredicate = getSearchByOptionalIndexPredicate(modFilter, glossaryModules, tenant, enabledModules, false);
            filteredData = filteredData.filter(modulePredicate);
            return filteredData;
        } else {
            return [];
        }
    }
);

export const getSelectedTerm = createSelector(
    glossaryListCollection,
    glossaryDataCollection,
    getGlossaryModules,
    glossaryId,
    tenantId,
    getEnabledModules,
    (glossary, glossaryData, glossaryModules, glossaryId, tenantId, enabledModules) => {
        if (glossary && glossary[glossaryId] && glossaryData[glossaryId] && enabledModules) { 
            const entityTenant = glossary[glossaryId].t || 1;
            if (tenantId === entityTenant || glossaryModules[glossaryId] && enabledModules.some(j => !!glossaryModules[glossaryId][j])) {
                return {
                    ...glossary[glossaryId],
                    ...glossaryData[glossaryId],
                    modules: glossaryModules && glossaryModules[glossaryId] ? Object.keys(glossaryModules[glossaryId]) : undefined
                };
            } else {
                return { noAccess: true, key: glossaryId };
            }
        } else {
            return null;
        }
    }
);

export const getSearchFilter = state => state.kitBuilder.glossary.searchFilter;
export const getSearchResult = state => state.kitBuilder.glossary.searchResult;
export const isSearching = state => state.kitBuilder.glossary.searching;
export const getOutlinePreference = state => state.kitBuilder.glossary.outlinePreference;

export const usedTags = createSelector(
    glossaryListCollection,
    (glossary) => {
        const tags = [];
        if (glossary) {
            mapCollectionToList(glossary).map(term => {
                if (term.tags) {
                    Object.keys(term.tags).map(tag => tags.push({ key: tag, value: tag, text: tag }));
                }
            });
            return _.uniqBy(_.sortBy(tags, ["key"]), "key");
        }
        return [];
    }
);

export const getFilteredGlossaryList = createSelector(
    glossaryListCollection,
    tenantId,
    getEnabledModules,
    getGlossaryModules,
    selectedWidgetModules,
    (glossary, tenant, enabledModules, glossaryModules, widgetModules) => {
        if (glossary) {
            let filteredData = mapCollectionToList(glossary);
            const modulePredicate = getSearchByOptionalIndexPredicate(widgetModules, glossaryModules, tenant, enabledModules, true);
            filteredData = filteredData.filter(modulePredicate);
            return filteredData;
        } else {
            return [];
        }
    }
);
