/**
 * Check if given question or answer content is empty.
 *
 * @param {string} qac content of questin or answer or choice (of multi-answers)
 */
const isContentEmpty = (content) => {
    // Empty when missing or null
    if (content === undefined || content === null) {
        return true;
    }
    // Empty string or HTML
    const str = String(content).trim();
    return (str.length === 0 || str === "<p></p>");
};

/**
 * Check if question is valid and problem can be saved.
 * 
 * @param {object} problem problem to be tested before save
 * @returns {string} error if question is invalid (empty).
 */
export const validateQuestionSave = (problem) => {
    const question = problem && problem.question;
    if (question && !isContentEmpty(question.content)) {
        return null;
    }
    return "Please enter a question before saving.";
};

/**
 * Check if answer is valid and problem can be saved.
 * 
 * @param {object} problem problem to be tested before save
 * @returns {string} error if answer/choices is/are invalid (empty).
 */
export const validateAnswerSave = (problem) => {
    const answer = problem && problem.answer;
    const type = problem && (problem.problemType || problem.type);

    // If it is not "Parent Problem" then we should have answer.
    if (!answer && type !== "pa") {
        return "Answer can't be empty.";
    }
    // Open Answer problems should have one answer with HTML content.
    if (type === "oa") {
        return isContentEmpty(answer.content) ? "Please enter an answer before saving." : null;
    }
    // Multi-Choice problems should have array of choices with non-empty HTML content.
    if (type === "mc" || type === "mx") {
        if (!Array.isArray(answer) || answer.length < 2) {
            return "Answer must have at least two choices.";
        }
        // There should be some correct choices
        const correctChoices = answer.filter(choice => choice.isCorrect == true).length;
        if (type === "mc" && correctChoices !== 1) {
            return "Multiple choice problem must have a correct answer selected.";
        }
        if (type === "mx" && correctChoices < 2) {
            return "Multiple answer correct problem must have at least 2 correct answers.";
        }
        // All choices should have some content
        const foundEmpty = answer.findIndex(choice => isContentEmpty(choice.content)) !== -1;

        return foundEmpty ? "All multiple choice questions must have a value." : null;
    }
    // Mathing Answer problems should have array of matching pairs with non-empty HTML content.
    if (type === "ma") {
        if (!Array.isArray(answer) || answer.length < 2) {
            return "Answer must have at least two matches.";
        }
        // All matches should have some content.
        const foundEmpty = answer.findIndex(choice => (
            isContentEmpty(choice.content) || isContentEmpty(choice.matchContent)
        )) !== -1;

        return foundEmpty ? "All matching options must have a value." : null;
    }
};