import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List, Icon } from "semantic-ui-react";
import { AddItemButton } from "bmd-react";
import AddCollectionPopup from "../AddCollectionPopup";
import { ActionIconsContainer } from "bmd-react/dist/ActionIconsContainer";

export default class CollectionList extends PureComponent {
    static propTypes = {
        collections: PropTypes.object,
        onSelectCollection: PropTypes.func.isRequired,
        selectedCollection: PropTypes.string,
        onAddCollection: PropTypes.func.isRequired,
        onUpdateCollection: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
        match: PropTypes.object,
    };

    state = {
        popupContext: undefined,
        errorMessage: undefined,
    };

    componentDidMount = () => {
        const { match } = this.props;

        if (match.params.collectionId) {
            this.props.onSelectCollection(match.params.collectionId);
        }
    };

    componentWillUnmount = () => {
        this.props.onSelectCollection(null);
    };

    handleSelectCollection = (e, { id }) => {
        this.props.onSelectCollection(id);
    };

    showPopup = ({ target }, newState) => {
        const { popupContext } = this.state;

        if (popupContext) {
            this.setState(
                {
                    popupContext: null,
                    nodeToEdit: undefined,
                    nodeToAdd: undefined,
                },
                target === popupContext ? null : () => this.setState({ popupContext: target, ...newState })
            );
        } else {
            this.setState({ popupContext: target, ...newState });
        }
    };

    hidePopup = () => {
        this.setState({
            popupContext: undefined,
            nodeToEdit: undefined,
            nodeToAdd: undefined,
            errorMessage: undefined,
        });
    };

    handleAdd = collection => {
        this.props.onAddCollection(collection);
        this.hidePopup();
    };

    showEditConfirm = e => {
        e.stopPropagation();
        this.showPopup(e, { nodeToEdit: e.target.dataset.key });
    };

    showAddNew = e => {
        this.showPopup(e, { nodeToAdd: true });
    };

    handleEdit = data => {
        const { nodeToEdit } = this.state;
        this.props.onUpdateCollection({ path: nodeToEdit, data });
        this.hidePopup();
    };

    setClearError = () => {
        this.setState({ errorMessage: undefined });
    };

    render() {
        const { collections, selectedCollection, canEdit } = this.props;
        const { popupContext, nodeToAdd, nodeToEdit, errorMessage } = this.state;

        return (
            <React.Fragment>
                <List selection>
                    {Object.keys(collections || {}).map(collectionKey => (
                        <List.Item
                            key={collectionKey}
                            onClick={this.handleSelectCollection}
                            id={collectionKey}
                            className="editable"
                            active={collectionKey === selectedCollection}
                        >
                            {canEdit && (
                                <ActionIconsContainer floatRight>
                                    <Icon
                                        name="write"
                                        data-key={collectionKey}
                                        onClick={this.showEditConfirm}
                                    />
                                </ActionIconsContainer>
                            )}
                            {collections[collectionKey].name}
                        </List.Item>
                    ))}
                </List>
                {canEdit && (
                    <React.Fragment>
                        <AddItemButton
                            buttonText="Add collection"
                            title="Click here to create a new topic collection."
                            onClick={this.showAddNew}
                        />
                        {nodeToAdd && (
                            <AddCollectionPopup
                                context={popupContext}
                                onCancel={this.hidePopup}
                                onAdd={this.handleAdd}
                                errorMessage={errorMessage}
                                onChange={this.setClearError}
                            />
                        )}
                        {nodeToEdit && (
                            <AddCollectionPopup
                                context={popupContext}
                                onCancel={this.hidePopup}
                                onAdd={this.handleEdit}
                                defaultValues={collections[nodeToEdit]}
                                collectionId={nodeToEdit}
                                errorMessage={errorMessage}
                                onChange={this.setClearError}
                            />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
