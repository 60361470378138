
// TODO: Replace this with some nice function from NPM package (e.g. he)
const HtmlUnsafePattern = /[\u00A0-\u9999<>&'"\\/]/gim;
const HtmlEntityEncoder = (c) => "&#" + c.charCodeAt(0) + ";";
const encodeHtmlEntity = (str) => str.replace(HtmlUnsafePattern, HtmlEntityEncoder);

/**
 * Converts snippet data into the configuration to be stored in DraftJS content state.
 *
 * @param {object} snippet 
 */
export const getSnippetConfig = (snippet) => {
    const { header, hideable = null } = snippet;
    return {
        hideable,
        hasTitle: !!header || null,
        hasDivider: (header && header.divider) || null,
        blockData: null,   // { title: string, noline: boolean }
        toggleData: null,  // { show: string, hide: string }
    };
};

/**
 * Formats the snippet className using the key and given type.
 * 
 * @param {string} key identifier of snippet
 * @param {string} type one of "block", "block preview", "hideable block" and "toggle"
 * @returns {string} className
 */
export const getSnippetClassName = (key, type) => `spork built-in ${type} ${key}`;

/**
 * Formats the snippet className for block using the key and block configuration.
 *
 * @param {string} key identifier of snippet
 * @param {object|null} cfg optional configuration of the snippet
 * @returns {string} className
 */
export const getSnippetBlockClassName = (key, cfg) => getSnippetClassName(
    key, cfg && cfg.hideable ? "hideable block hidden" : "block"
);

const getSnippetToggleDataAttribs = (key, cfg) => {
    const attr = [ `data-key="${key}"` ]; // Firebase key should be HTML-safe
    const data = cfg && cfg.toggleData;
    if (data) {
        if (data.show) {
            attr.push(`data-show="${encodeHtmlEntity(data.show)}"`);
        }
        if (data.hide) {
            attr.push(`data-hide="${encodeHtmlEntity(data.hide)}"`);
        }
    }
    return attr.join(" ");
};

export const getSnippetToggleButtonHTML = (key, cfg) => (
    cfg && cfg.hideable ? (
        `<button ${getSnippetToggleDataAttribs(key, cfg)}
        class="${getSnippetClassName(key, "toggle")} isHidden"
        onClick="return spork.events.onToggleHideable(event);"
        ></button>`
    ) : ""
);

export const getSnippetBlockContentHTML = (key, cfg, html) => {
    const data = cfg && cfg.blockData;
    const customTitle = cfg && cfg.hiddenTitle ? "" : (
        data && data.title ? "" + data.title : null
    );

    const attr = [
        `class="${getSnippetBlockClassName(key, cfg)}"`,
        customTitle !== null && `data-title="${customTitle && encodeHtmlEntity(customTitle)}"`,
        // data && data.noline && "data-noline", // not supported at the moment
    ].filter(Boolean).join(" ");
    return `<div ${attr}>${html || ""}</div>`;
};

export const getSnippetBlockDataAsProps = (cfg) => {
    const data = cfg && cfg.blockData || {};
    const customTitle = cfg && cfg.hiddenTitle ? "" : (
        data && data.title ? "" + data.title : null
    );
    const props = {};
    if (customTitle !== null) {
        props["data-title"] = customTitle;
    }
    // not supported at the moment
    // if (data && data.noline) {
    //     props["data-noline"] = true;
    // }
    return props;
};


