import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Header, Tab, Message, Dropdown, TextArea, Icon } from "semantic-ui-react";
import { SaveCancelButtons, DropdownWithNodes, AddItemButton } from "bmd-react";
import Objective from "../components/Objective";
import AddObjective from "./AddObjective";
import memoize from "memoize-one";
export default class AddTopic extends PureComponent {
    static propTypes = {
        concepts: Dropdown.propTypes.options,
        keywords: Dropdown.propTypes.options,
        /* list of objective sets */
        objectiveSets: Dropdown.propTypes.options,
        /** list of objectives based on selected objective set */
        selectedObjective: PropTypes.object,
        /** list of OII objectives */
        oii_objectives: Dropdown.propTypes.options,
        /** list of headinds */
        headings: Dropdown.propTypes.options,
        subheadings: Dropdown.propTypes.options,
        onAddConcept: PropTypes.func.isRequired,
        onAddKeyword: PropTypes.func.isRequired,
        onAddObjectiveSet: PropTypes.func.isRequired,
        onSelectObjectiveSet: PropTypes.func.isRequired,
        onAddObjective: PropTypes.func.isRequired,
        onAddTopic: PropTypes.func.isRequired,
        onUpdateTopic: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        onAdd: PropTypes.func,
        onAddOiiObjective: PropTypes.func.isRequired,
        onAddHeading: PropTypes.func.isRequired,
        onAddSubHeading: PropTypes.func.isRequired,
        error: PropTypes.object,
        isProcessing: PropTypes.bool,
        onClearAddTopicError: PropTypes.func.isRequired,
        belaTopic: PropTypes.object,
        /* if true, it allows to add new objective sets and objectives */
        allowEditObjectives: PropTypes.bool,
        /* these values initializes the state of form items */
        defaultValues: PropTypes.shape({
            conceptValue: PropTypes.string,
            keywordValues: PropTypes.array,
            objectiveSetKey: PropTypes.string,
            objectiveKey: PropTypes.string,
            oiiObjectiveValue: PropTypes.string,
            headingValue: PropTypes.string,
            descriptionValue: PropTypes.string,
            hashKey: PropTypes.string,
            key: PropTypes.string
        })
    };

    static defaultProps = {
        allowEditObjectives: false,
        header: "Create a new topic"
    };

    state = { keywordValues: [], currentTab: 0, initializedFromProps: false, ...this.props.defaultValues };

    handleAddition = (e, { value }) => {
        this.props.onAddConcept(value);
    };

    handleAdditionKeyword = (e, { value }) => {
        // check if keyword already exists in the list. If so, do nothing.
        let newValue = value.toLowerCase().trim();
        if (this.props.keywords.every(keyword => keyword.text != newValue)) {
            this.props.onAddKeyword(value);
        }
    };

    handleAdditionObjectiveSet = (e, { value }) => {
        this.props.onAddObjectiveSet(value);
    };

    handleAddOiiObjective = (e, { value }) => {
        this.props.onAddOiiObjective(value);
    };

    handleAddHeading = (e, { value }) => {
        this.props.onAddHeading(value);
    };

    handleAddSubHeading = (e, { value }) => {
        this.props.onAddSubHeading(value);
    };

    callbackAddObjective = newObjectiveId => {
        this.setState({ objectiveKey: newObjectiveId, enableAddObjective: false });
    };

    // filter array to have only unique values based on lowercase
    uniques(arr) {
        return arr.filter(function(value, key) {
            return !this.has((key = value.toLowerCase().trim())) && this.add(key);
        }, new Set());
    }

    handleChange = (e, { value }) => this.setState({ conceptValue: value });
    handleChangeKeyword = (e, { value }) => {
        this.setState({ keywordValues: this.uniques(value) });
    };
    handleChangeObjectiveSet = (e, { value }) => {
        this.setState({ objectiveSetValue: value });
        let objSetId = 0;
        // translate value to key
        this.props.objectiveSets.map(set => {
            if (set.value == value) objSetId = set.key;
        });
        this.props.onSelectObjectiveSet(objSetId);
        this.setState({ objectiveKey: undefined });
    };
    handleChangeObjective = (e, { value }) => this.setState({ objectiveKey: value });
    handleChangeOiiObjective = (e, { value }) => this.setState({ oiiObjectiveValue: value });
    handleChangeHeading = (e, { value }) => this.setState({ headingValue: value });
    handleChangeSubHeading = (e, { value }) => this.setState({ subheadingValue: value });
    handleChangeDescription = (e, { value }) => this.setState({ descriptionValue: value });

    handleAddObjective = objective => {
        const { onAddObjective } = this.props;

        let objSetId = 0;
        // translate value to key
        this.props.objectiveSets.map(set => {
            if (set.value == this.state.objectiveSetValue) objSetId = set.key;
        });
        onAddObjective(objSetId, objective, this.callbackAddObjective);
    };

    handleAddTopic = () => {
        const {
            conceptValue,
            keywordValues,
            objectiveSetValue,
            objectiveKey,
            currentTab,
            oiiObjectiveValue,
            headingValue,
            subheadingValue,
            descriptionValue
        } = this.state;
        const {
            selectedObjective,
            onAdd,
            oii_objectives,
            objectiveSets,
            defaultValues,
            concepts,
            headings,
            subheadings,
            belaTopic
        } = this.props;

        var topic = {
            objective_set: {},
            objective: {},
            concept: {},
            keywords: {},
            heading: {},
            subheading: {},
            description: descriptionValue || null
        };

        this.handleClearError();

        concepts.map(concept => {
            if (concept.value == conceptValue) topic.concept = { id: concept.key, name: concept.value };
        });

        headings.map(heading => {
            if (heading.value == headingValue) topic.heading = { id: heading.key, name: heading.value };
        });

        subheadings.map(subheading => {
            if (subheading.value == subheadingValue) topic.subheading = { id: subheading.key, name: subheading.value };
        });

        // set correct objective based on selected tab (Knowledge & Skill or OII)
        switch (currentTab) {
            case 0: // Knowledge & Skill
                if (selectedObjective.objectives[objectiveKey]) {
                    topic.objective = {
                        id: objectiveKey,
                        name: selectedObjective.objectives[objectiveKey].name,
                        type: selectedObjective.objectives[objectiveKey].type
                    };
                }
                // convert objective set ID to {id, name}
                objectiveSets.map(option => {
                    if (option.value == objectiveSetValue) topic.objective_set = { id: option.key, name: option.value };
                });
                break;
            case 1: // OII
                // convert OII objective to {id, name}
                oii_objectives.map(option => {
                    if (option.value == oiiObjectiveValue)
                        topic.objective = { id: option.key, name: option.value, type: "OII" };
                });
                break;
        }

        keywordValues.map(keyword => (topic.keywords[keyword] = true));
        if (defaultValues && defaultValues.key) {
            this.props.onUpdateTopic(defaultValues.key, defaultValues.hashKey, topic);
        } else {
            this.props.onAddTopic(topic, belaTopic);
        }
        if (onAdd) {
            this.clearState();
            onAdd(topic);
        }
    };

    clearState = () => {
        this.setState({
            conceptValue: undefined,
            keywordValues: [],
            objectiveSetValue: undefined,
            objectiveKey: undefined,
            oiiObjectiveValue: undefined,
            headingValue: undefined,
            subheadingValue: undefined
        });
    };

    handleClearError = () => {
        if (this.props.error) {
            this.props.onClearAddTopicError();
        }
    };

    handleClose = () => {
        this.clearState();
        this.handleClearError();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    handleCancelAddObjective = () => {
        this.setState({ enableAddObjective: false });
    };

    setAddObjective = () => {
        const { enableAddObjective } = this.state;
        this.setState({ enableAddObjective: !enableAddObjective });
    };

    handleTabChange = (e, { activeIndex }) => {
        this.setState({ currentTab: activeIndex });
    };

    // initiate state with pre-set values
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.initializedFromProps === false && nextProps.defaultValues) {
            let objectiveSetValue = undefined;
            nextProps.objectiveSets.map(set => {
                if (set.key === nextProps.defaultValues.objectiveSetKey) {
                    objectiveSetValue = set.value;
                }
            });

            return {
                ...prevState,
                ...nextProps.defaultValues,
                currentTab: nextProps.defaultValues.oiiObjectiveValue ? 1 : 0,
                objectiveSetValue,
                initializedFromProps: true
            };
        }
        return null;
    }

    getObjectiveOptions = memoize(objectives => {
        let options = [];
        if (!objectives) return options;
        Object.keys(objectives).map(type =>
            objectives[type].map(objective =>
                options.push({
                    key: objective.id,
                    content: <Objective data={objective} />,
                    value: objective.id,
                    searchText: objective.name + " " + objective.type
                })
            )
        );
        return options;
    });

    componentWillUnmount = () => {
        this.props.onSelectObjectiveSet(null);
    };

    render() {
        let editing = this.props.defaultValues && this.props.defaultValues.key;

        const {
            conceptValue,
            keywordValues,
            objectiveSetValue,
            objectiveKey,
            enableAddObjective,
            oiiObjectiveValue,
            currentTab,
            headingValue,
            subheadingValue,
            descriptionValue
        } = this.state;
        const {
            concepts,
            keywords,
            objectiveSets,
            selectedObjective,
            oii_objectives,
            error,
            allowEditObjectives,
            headings,
            subheadings,
            isProcessing,
            belaTopic
        } = this.props;
        const objectiveOptions = this.getObjectiveOptions(selectedObjective.objectivesObject);

        return (
            <React.Fragment>
                <Header>
                    {editing ? "Edit a topic" : "Add new topic"}
                    {belaTopic && <Header.Subheader>BeLA topic: {belaTopic.topic_name}</Header.Subheader>}
                </Header>
                <Form>
                    <Form.Group widths="equal">
                        <HeadingDropdown
                            options={headings}
                            value={headingValue}
                            onAddItem={this.handleAddHeading}
                            onChange={this.handleChangeHeading}
                        />
                        <HeadingDropdown
                            options={subheadings}
                            value={subheadingValue}
                            onAddItem={this.handleAddSubHeading}
                            onChange={this.handleChangeSubHeading}
                            label="Subheading"
                            placeholder="Choose subheading"
                        />
                    </Form.Group>
                    <ConceptDropdown
                        options={concepts}
                        value={conceptValue}
                        onAddItem={this.handleAddition}
                        onChange={this.handleChange}
                    />
                    <Tab
                        menu={{ secondary: true, pointing: true, widths: 2 }}
                        activeIndex={currentTab}
                        onTabChange={this.handleTabChange}
                        panes={[
                            {
                                menuItem: "Skill & Knowledge",
                                render: () => (
                                    <Tab.Pane attached={false} basic style={{ backgroundColor: "#f3f4f5" }}>
                                        <ObjectiveSetDropdown
                                            options={objectiveSets}
                                            allowAdditions={allowEditObjectives}
                                            value={objectiveSetValue}
                                            onAddItem={this.handleAdditionObjectiveSet}
                                            onChange={this.handleChangeObjectiveSet}
                                        />
                                        {!enableAddObjective ? (
                                            <React.Fragment>
                                                <Form.Field required>
                                                    <label>Objective</label>
                                                    <DropdownWithNodes
                                                        options={objectiveOptions}
                                                        placeholder="Choose Objective"
                                                        width={8}
                                                        disabled={objectiveSetValue === undefined}
                                                        value={objectiveKey}
                                                        onChange={this.handleChangeObjective}
                                                    />
                                                </Form.Field>
                                                {allowEditObjectives && (
                                                    <AddItemButton
                                                        buttonText="Add a new objective"
                                                        onClick={this.setAddObjective}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <AddObjective
                                                onSave={this.handleAddObjective}
                                                objectives={selectedObjective.objectives}
                                                onCancel={this.handleCancelAddObjective}
                                            />
                                        )}
                                    </Tab.Pane>
                                )
                            },
                            {
                                menuItem: "Other Instructional Instrument",
                                render: () => (
                                    <Tab.Pane attached={false} basic style={{ backgroundColor: "#f3f4f5" }}>
                                        <OiiDropdown
                                            options={oii_objectives}
                                            value={oiiObjectiveValue}
                                            onAddItem={this.handleAddOiiObjective}
                                            onChange={this.handleChangeOiiObjective}
                                        />
                                    </Tab.Pane>
                                )
                            }
                        ]}
                    />
                    <Form.Dropdown
                        label="Keywords"
                        options={keywords}
                        placeholder="Choose Keywords"
                        search
                        selection
                        fluid
                        allowAdditions
                        multiple
                        value={keywordValues || []}
                        onAddItem={this.handleAdditionKeyword}
                        onChange={this.handleChangeKeyword}
                    />
                    <Form.Field>
                        <label>Description</label>
                        <TextArea
                            autoHeight
                            style={{ maxHeight: "250px" }}
                            placeholder="Topic Description"
                            value={descriptionValue}
                            onChange={this.handleChangeDescription}
                        />
                    </Form.Field>
                </Form>
                <SaveCancelButtons
                    onSave={this.handleAddTopic}
                    onCancel={this.handleClose}
                    saveDisabled={
                        enableAddObjective ||
                        !conceptValue ||
                        (currentTab === 0 && (!objectiveSetValue || !objectiveKey)) ||
                        (currentTab === 1 && !oiiObjectiveValue)
                    }
                    submitText={editing ? "Update" : "Create"}
                    padded
                />
                {error && error.code === "PERMISSION_DENIED" && (
                    <Message content="This topic already exists." error onDismiss={this.handleClearError} />
                )}
                <ProcessingMessage isProcessing={isProcessing} />
            </React.Fragment>
        );
    }
}

const ProcessingMessage = ({ isProcessing }) => {
    return isProcessing ? (
        <Message icon positive>
            <Icon name="circle notched" loading />
            <Message.Content>Creating new SPORK topic ...</Message.Content>
        </Message>
    ) : null;
};

ProcessingMessage.propTypes = {
    isProcessing: PropTypes.bool
};

const HeadingDropdown = props => (
    <Form.Dropdown
        clearable
        label="Heading"
        placeholder="Choose Heading"
        search
        selection
        fluid
        allowAdditions
        {...props}
    />
);

const ConceptDropdown = props => (
    <Form.Dropdown
        required
        label="Concept"
        placeholder="Choose Concept"
        search
        selection
        fluid
        allowAdditions
        {...props}
    />
);

const ObjectiveSetDropdown = props => (
    <Form.Dropdown
        required
        label="Objective Set"
        placeholder="Choose Objective set"
        search
        selection
        fluid
        {...props}
    />
);

const OiiDropdown = props => (
    <Form.Dropdown
        required
        label="OII Objective"
        placeholder="Choose OII Objective"
        search
        selection
        fluid
        allowAdditions
        {...props}
    />
);
