import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Popup, Segment, Form, List, Message, Button, Header, Icon, Accordion } from "semantic-ui-react";
import ActionLink from "../component/ActionLink";
import { SHEETS, SchoolToolWorkbook } from "./SchoolToolWorkbook";
import { swallowEvent } from "../component/seamlessEditor/bookEditor/utils/utils";
import { onImportYears } from "./YearsActions";
import { isImporting, getAllYears, getApiCallsByExistence } from "./YearsSelectors";

export class ImportExcel extends React.PureComponent {
    static propTypes = {
        allYears: PropTypes.array,
        onImportYears: PropTypes.func.isRequired,
        isImporting: PropTypes.bool.isRequired,
    };

    state = {
        dragging: false,
        selectedFile: null,
        errorMessages: null,
        okMessage: null,
        rowsToImport: 0,
    };

    xlsContent = {};

    componentDidUpdate = (prevState) => {
        if (prevState.isImporting && !this.state.isImporting) {
            this.handleClose();
        }
    };

    maxSelectFile = (event) => {
        let files = event.target.files;
        if (files.length > 1) {
            const msg = "Only 1 file can imported at a time";
            event.target.value = null; // discard selected file
            this.setState({ errorMessages: msg, okMessage: null });
            return false;
        }
        return true;
    };

    checkFileExtension = (file) => {
        const name = file.name.toLowerCase();
        if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
            const err = [{ message: "The selected file is not in a supported format (XLS/XLSX)." }];
            this.setState({ errorMessages: err, okMessage: null });
            return false;
        }
        return true;
    };

    handleDragIn = (e) => {
        swallowEvent(e);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true, errorMessages: null, okMessage: null, selectedFile: null });
        }
    };

    handleDragOut = (e) => {
        swallowEvent(e);
        this.setState({ dragging: false });
    };

    // file selected by drag and drop
    handleFileDrop = (e) => {
        swallowEvent(e);
        this.setState({ dragging: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.processFile(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    // file selected by input dialog
    handleChange = (e) => {
        this.setState({ errorMessages: null });
        if (this.maxSelectFile(event) && e.target.files[0]) {
            this.processFile(e.target.files[0]);
        }
    };

    setOkMessage = (totalRows, rowsToImport) => {
        const okMessage = (<div>
            The imported file contains {totalRows} records.
            {rowsToImport
                ? <ul>
                    <li>new records: {rowsToImport} (will be imported)</li>
                    <li>existing records: {totalRows - rowsToImport} (will be ignored)</li>
                </ul>
                : <p>Nothing to import. All records already exist.</p>
            }
        </div>);

        this.setState({ okMessage, rowsToImport });
    };

    // download the file to browser and do some data consistency checks and verifications
    processFile = (file) => {

        if (!this.checkFileExtension(file)) {
            this.xlsContent = {};
            this.setState({ selectedFile: null });
            return;
        }

        this.setState({
            selectedFile: file,
            okMessage: null,
            errorMessages: null,
        });

        const reader = new FileReader();
        reader.onload = (event) => {
            const workbook = new SchoolToolWorkbook(SHEETS.YEARS);
            workbook.loadData(event.target.result);

            if (!workbook.isValid()) {
                this.setState({ errorMessages: workbook.getErrors() });
                return;
            }

            const { years } = workbook.getData(); // TODO: can be simplified if only one sheet will be always imported
            const dataToImport = getApiCallsByExistence(this.props.allYears, years);
            this.setOkMessage(years.length, dataToImport.length);
            this.xlsContent = { years };
        };

        reader.readAsArrayBuffer(file);
    };

    handleClose = () => {
        this.setState({
            selectedFile: null,
            okMessage: null,
            errorMessages: null,
            isOpen: false,
        });
        this.xlsContent = {};
    };

    handleOpen = () => {
        this.setState({
            selectedFile: null,
            okMessage: null,
            errorMessages: null,
            isOpen: true,
        });
        this.xlsContent = {};
    };

    handleImport = () => {
        const { years } = this.xlsContent;
        this.props.onImportYears(years);
    };

    render() {
        const { isImporting } = this.props;
        const { dragging, selectedFile, errorMessages, okMessage, isOpen, rowsToImport } = this.state;

        return (
            <Popup
                on="click"
                trigger={<ActionLink horizontal icon="upload" content="Import XLS" />}
                position="bottom right"
                open={isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                wide="very"
            >
                <Segment basic>
                    <Header as="h3">Import from Excel</Header>
                    You can upload data about years in XLS/XLSX format.
                    <Popup
                        trigger={<Icon name="question circle outline" color="grey" />}
                        content={
                            <Segment basic>
                                Download the XLSX template for providing data in expected format.
                                <br />
                                Imported Excel workbook has to contain sheet <b>Years</b> with columns:
                                <ul>
                                    <li>name</li>
                                    <li>valid</li>
                                    <li>default</li>
                                </ul>
                            </Segment>
                        }
                    />
                </Segment>
                <Form>
                    <Form.Field>
                        <input type="file" id="file" onChange={this.handleChange} />
                    </Form.Field>
                </Form>
                <Segment
                    textAlign="center"
                    {...(dragging
                        ? { style: { border: "solid grey 1px", fontSize: "1.5em", color: "grey" } }
                        : { style: { border: "dashed grey 1px", fontSize: "1.5em", color: "grey" } })}
                    onDragOver={swallowEvent}
                    onDragEnter={this.handleDragIn}
                    onDragLeave={this.handleDragOut}
                    onDrop={this.handleFileDrop}
                >
                    or drop a file here
                </Segment>
                {selectedFile && (
                    <List>
                        <List.Item>
                            <List.Header>Selected file:</List.Header>
                            {selectedFile.name}
                        </List.Item>
                    </List>
                )}
                {errorMessages && <Message negative>
                    <Message.Header>Invalid Import</Message.Header>
                    <Accordion
                        exclusive={false}
                        panels={errorMessages.map(
                            ({ message, description}, index) => ({ key: index, title: message, content: description })
                        )}
                        fluid
                    />
                </Message>}
                {okMessage && <Message positive>{okMessage}</Message>}
                <Button
                    floated="right"
                    disabled={!selectedFile || !!errorMessages || rowsToImport === 0}
                    primary
                    onClick={this.handleImport}
                    loading={isImporting}
                >
                    Import
                </Button>
            </Popup>
        );
    }
}

export default connect(
    (state) => ({
        isImporting: isImporting(state),
        allYears: getAllYears(state),
    }),
    { onImportYears }
)(ImportExcel);
