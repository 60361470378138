const TYPES = {
    IMPORT_GLOSSARY: "IMPORT_GLOSSARY",
    IMPORT_GLOSSARY_START: "IMPORT_GLOSSARY_START",
    IMPORT_GLOSSARY_STOP: "IMPORT_GLOSSARY_STOP",
    IMPORT_GLOSSARY_PROGRESS: "IMPORT_GLOSSARY_PROGRESS",
    IMPORT_GLOSSARY_ERROR: "IMPORT_GLOSSARY_ERROR",
};

const onImportGlossary = payload => {
    return {
        type: TYPES.IMPORT_GLOSSARY,
        payload,
    };
};

const onImportGlossaryStart = payload => {
    return {
        type: TYPES.IMPORT_GLOSSARY_START,
        payload,
    };
};

const onImportGlossaryStop = payload => {
    return {
        type: TYPES.IMPORT_GLOSSARY_STOP,
        payload,
    };
};

const onImportGlossaryProgress = (processed, total) => {
    return {
        type: TYPES.IMPORT_GLOSSARY_PROGRESS,
        payload: { processed, total },
    };
};

const onImportGlossaryError = error => {
    return {
        type: TYPES.IMPORT_GLOSSARY_ERROR,
        payload: error,
    };
};

export {
    TYPES,
    onImportGlossary,
    onImportGlossaryStart,
    onImportGlossaryStop,
    onImportGlossaryProgress,
    onImportGlossaryError,
};
