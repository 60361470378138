exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._25K58cnzOPitdOjJToc3Z0 {\n  color: rgba(0, 0, 0, 0.9); }\n  ._25K58cnzOPitdOjJToc3Z0 kbd {\n    padding: 1px 4px;\n    font-weight: bold;\n    color: white;\n    background-color: #2185d0;\n    border-radius: 2px; }\n\n._1n-hYdngR3R3aJ2q4j-CHv {\n  text-align: right;\n  color: #bcbec0; }\n", ""]);

// exports
exports.locals = {
	"explanation": "_25K58cnzOPitdOjJToc3Z0",
	"popupIcon": "_1n-hYdngR3R3aJ2q4j-CHv"
};