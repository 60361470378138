import { put, takeLatest, select } from "redux-saga/effects";
import {
    TYPES,
    onTemplateiPlannerSuccess,
    onTemplateiPlannerFailure,
} from "./TemplateiPlannersActions";
import { tenantId } from "../../../../Users/UserProfile/UserProfileSelectors";
import { sipiData } from "../../../dataSource";

function* resolveTemplateUsedBy (usedBy) {
    const tenant = yield select(tenantId);
    const links = [];
    yield * Object.keys(usedBy).map(async (iPlannerId) => {
        const iPlannerTenant = usedBy[iPlannerId];
        if (tenant === iPlannerTenant) {
            const name = await sipiData.getSyllabusName(iPlannerId);
            links.push({
                iPlannerId,
                name,
            });
        }
    });
    return links.sort((l, r) => (
        (l.name || "").localeCompare(r.name || "")
    ));
}

function* requestTemplateiPlanners({ payload: { templateId, usedBy }}) {
    if (templateId && usedBy) {
        const iPlanners = yield resolveTemplateUsedBy(usedBy);
        if (iPlanners) {
            const others = Object.keys(usedBy).length - iPlanners.length;
            yield put(onTemplateiPlannerSuccess({ templateId, iPlanners, others: others || undefined }));
        } else {
            yield put(onTemplateiPlannerFailure({ templateId, failure: "Failed to get template iPlanners." }));
        }
    } else if (templateId) {
        yield put(onTemplateiPlannerFailure({ templateId, failure: "Template is not used in any iPlanner." }));
    }
}

export default function* saga() {
    yield takeLatest(TYPES.TEMPLATE_IPLANNER_REQUEST, requestTemplateiPlanners);
}