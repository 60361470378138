import { createSelector } from "reselect";
import { getCanEditKit } from "../AKitsList/AKitsListSelectors";
import { userClaims, loggedUserId } from "../../../Users/UserProfile/UserProfileSelectors";
import { getCvGuid } from "../../../utils/selectorUtils";
import { getSelectedOutline } from "../../Outlines/outlinesSelectors";
import { getSelectedAKitId } from "../AKitsSelectors";

export const getSyncData = ({ firebase }) => firebase.data.sync_cv;

export const getAllCourseVariants = state => state.kitBuilder.akits.aKitEditor.courseVariants;
export const syncStatus = state => state.kitBuilder.akits.aKitEditor.syncStatus;

export const fetchSelectedKitData = (state, ownProps) => {
    const kitId = getSelectedAKitId(state, ownProps);
    if (ownProps.accessible) {
        return kitId && state.firebase.data.kits_data && state.firebase.data.kits_data[kitId];
    } else {
        return null;
    }
};

const getAKitObject = (kitData, userClaims, loggedUserId) => {
    /* courseVariantData could be empty; there is a possiblity we will allow aKit without course ... */
    return kitData && {
        ...kitData,
        canEdit: getCanEditKit(userClaims, loggedUserId, kitData),
    };
};

export const enableSyncForCvs = createSelector(
    getAllCourseVariants,
    fetchSelectedKitData,
    (courseVariants, kitData) => {
        const kitCvs = kitData && kitData.course_variants;
        if (!(kitCvs && courseVariants.length)) {
            return null;
        }
        const cvsToSync = courseVariants
            .filter((cv) => kitCvs[getCvGuid(cv)])
            .reduce((toSync, cv) => {
                if (toSync[cv.tenant_id]) {
                    toSync[cv.tenant_id].course_variant_ids.push(cv.id);
                } else {
                    toSync[cv.tenant_id] = {
                        tenant_id: cv.tenant_id,
                        year_id: cv.year_id,
                        course_variant_ids: [ cv.id ],
                    };
                }
                return toSync;
            }, {});
        return Object.keys(cvsToSync).length ? cvsToSync : null;
    }
);

export const enableSyncForOutline = createSelector(
    getSelectedOutline, 
    (outline) => {
        return outline && outline.lesson_units && (outline.lesson_units.reduce((prev, current) => prev + current.lessons.length, 0) > 0);
    }
);

export const getSelectedAKitData = createSelector(
    fetchSelectedKitData,
    userClaims,
    loggedUserId,
    getAKitObject);
