import firebase from "../firebase";

/**
 * Id as used by a database to identify entities. For Firebase it is a string.
 * @typedef {string} DbId
 */

/**
 * Loads data from Firebase located at ${path}.
 * @param { string } path
 * @return object representing the data at the ${path}
 * @throws Error
 */
export const loadData = async (path) => {

    const ref = firebase.getFirebaseData(path);

    let data = null;
    await ref.once("value",
        snapshot => {
            data = snapshot.val();
        }
        ,
        error => {
            throw error;
        }
    );

    if (data == null) {
        console.log(`No data loaded for ${path} which is unusual`); // eslint-disable-line no-console
    }

    return data;
};

/**
 * Adds a value of id as attribute to the object.
 * It is used for converting a Firebase's object from a collection indexed by objects' ids to full object that contains id as its property.
 * @param {object} object
 * @param {DbId} id
 * @param {object} object
 */
const enrichById = (object, id) => { return { ...object, id }; };

/**
 * Converts Firebase's object representing a list to an array. Each element of the list is supposed to be an object and is enriched by id property with value of index.
 * E.g. { "O1": { "name": "Mark" }, "O2": { "name": "Frank" } } is converted to
 * [{ "id": "O1", "name": "Mark" }, { "id": "O2", "name": "Frank" }]
 * @param { object|null } fb_list_object
 * @return array
 */
export const fb_list_object = (fb_list_object) => {
    if (!fb_list_object) {
        return [];
    }

    return Object.keys(fb_list_object).map( key => { return enrichById(fb_list_object[key], key); });
};

/**
 * Converts a list (array) of Firebase paths to an object whose attributes are values of the list. The object could be used for Firebase's update() method.
 * Value of all atrributes is set to value. The value could be null which Firebase's update  interprets as a request for removal of the path.
 *
 * @param { array } paths
 * @param {?*} value
 * @return { object }
 */
export function getUpdateObjectForPaths(paths, value) {
    return paths.reduce( (obj, path) => { return { ...obj, [path]: value }; }, {});
}

export function applyUpdateObject(updateObject, rootPathReference, onComplete ) {
    return rootPathReference.update(updateObject, onComplete);
}