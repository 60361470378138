import React from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

const ResourceChanges = ({ created_at, updated_at }) => {
    const createdAt = created_at && new Date(created_at);
    const updatedAt = updated_at && new Date(updated_at);
    var options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };

    return createdAt || updatedAt ? (
        <React.Fragment>
            <Header as="h4">Date</Header>
            <span style={{color: "grey"}}>Created:</span> {createdAt.toLocaleString("en-US", options)}
            {updatedAt && (<React.Fragment>
                <br/><span style={{color: "grey"}}>Updated:</span> {updatedAt.toLocaleString("en-US", options)}
            </React.Fragment>
            
            )}
        </React.Fragment>
    ) : null;
};
ResourceChanges.propTypes = {
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
};

export default ResourceChanges;