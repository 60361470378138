import { all, takeEvery, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onConvertProblemDataMessage, onConvertProblemDataFinished } from "./UpdateProblemTagsActions";

function* convertProblemData() {
    const BATCH_SIZE = 1000;
    yield put(onConvertProblemDataMessage("Downloading problem data …"));
    const db = firebase.defaultApp.database().ref();
    // remove search index
    yield db.child("tags_problems").set(null);

    let lastProcessed = "";
    const progress = { processedProblems: 0 };
    let problemData = null;

    do {
        const updates = {};
        const problemsRef = firebase
            .getFirebaseData("problem")
            .orderByKey()
            .startAt(lastProcessed)
            .limitToFirst(BATCH_SIZE);
        const snapshot = yield problemsRef.once("value");
        problemData = snapshot.val();

        if (problemData !== null) {
            for (const problemId of Object.keys(problemData)) {
                const tags = problemData[problemId]["tags"];
                for (const i in tags) {
                    updates[`${tags[i]}/${problemId}`] = true;
                }
                progress.processedProblems++;
                lastProcessed = problemId;
            }
            yield put(onConvertProblemDataMessage(`Problems reindexed: ${progress.processedProblems}. Please wait…`));
            yield db.child("tags_problems").update(updates);
        }
    } while (Object.keys(problemData || {}).length === BATCH_SIZE);
    yield put(onConvertProblemDataMessage(`All problems reindexed: (${progress.processedProblems})`));
    yield put(onConvertProblemDataFinished());
}

export default function* saga() {
    yield all([takeEvery(TYPES.CONVERT_PROBLEM_DATA_START, convertProblemData)]);
}
