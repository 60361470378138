import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import {
    getSelectedTerm,
    isDirty,
    moduleFilter,
    getFilteredGlossary,
    getFilter,
    glossaryListCollection,
    glossaryDataCollection,
    unusedFilter,
    selectedTags,
    usedTags
} from "./GlossarySelectors";
import Glossary from "./Glossary";
import * as GlossaryActions from "./GlossaryActions";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading";
import { firebaseConnect } from "react-redux-firebase";
import { withAuth } from "../../component/withAuth";

const mapStateToProps = (state, props) => ({
    glossaryId: props.match.params.glossaryId,
    selectedTerm: getSelectedTerm(state, props),
    glossary: getFilteredGlossary(state),
    glossaryListCollection: glossaryListCollection(state),
    glossaryDataCollection: glossaryDataCollection(state),
    searchFilter: getFilter(state),
    _glossary: state.firebase.data.glossary,  // only for a spinner
    unusedFilter: unusedFilter(state),
    selectedTags: selectedTags(state),
    usedTags: usedTags(state),
    isDirty: isDirty(state),
    moduleFilter: moduleFilter(state),
});

const dispatchToProps = (dispatch) => bindActionCreators(
    GlossaryActions,
    dispatch
);

export default compose(
    firebaseConnect(["glossary", "subjects"]),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    withAuth,
    spinnerWhileLoading([ "_glossary" ])
)(Glossary);
