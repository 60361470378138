import React from "react";
import PropTypes from "prop-types";
import { Header, Dimmer, Loader, Message, Icon, List, ListItem, Card } from "semantic-ui-react";
import CreateNewCard from "../../component/CreateNewCard";
import TenantCard from "./component/TenantCard";
import { Env } from "../../Version";
import TenantModuleEditor from "./component/TenantModuleEditor";

export default class TenantList extends React.PureComponent {
    static propTypes = {
        tenants: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                is_isolated: PropTypes.boolean,
                auth_url: PropTypes.string,
            })
        ),
        isLoading: PropTypes.bool,
        isCreating: PropTypes.bool,
        onTenantLoad: PropTypes.func.isRequired,
        onCreateDemoTenant: PropTypes.func.isRequired,
        newTenantInfo: PropTypes.shape({
            name: PropTypes.string,
            teacherEmail: PropTypes.string,
            teacherPassword: PropTypes.string,
            users: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                    first_name: PropTypes.string,
                    last_name: PropTypes.string,
                    secret: PropTypes.string,
                    scope: PropTypes.string,
                })
            ),
        }),
        onUpdateModules: PropTypes.func,
    };

    state = {
        isOpenModuleEditor: false,
        editedTenantId: null,
        editedTenant: null,
    }

    handleOpenModuleEditor = (editedTenantId, editedTenant) => this.setState({ isOpenModuleEditor: true, editedTenantId, editedTenant });
    handleCloseModuleEditor = () => this.setState({ isOpenModuleEditor: false, editedTenantId: null, editedTenant: null });

    handleSaveModules = (options, modules) => {
        this.props.onUpdateModules({ tenantId: this.state.editedTenantId, options, modules });
        this.handleCloseModuleEditor();
    }

    componentDidMount() {
        this.props.onTenantLoad();
    }

    render() {
        const { tenants, isLoading, onCreateDemoTenant, isCreating, newTenantInfo } = this.props;
        const { isOpenModuleEditor, editedTenantId, editedTenant } = this.state;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Tenants
                </Header>
                <Dimmer active={isLoading} inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
                {isOpenModuleEditor && (
                    <TenantModuleEditor
                        tenantId={editedTenantId}
                        onClose={this.handleCloseModuleEditor}
                        tenant={editedTenant}
                        onSave={this.handleSaveModules}
                    />
                )}
                <Card.Group>
                    {tenants && tenants.map(t => <TenantCard key={t.id} {...t} tenantId={t.id} onEditModules={this.handleOpenModuleEditor}/>)}
                    {("dev" === Env || "demo" === Env) && (
                        <CreateNewCard onClick={onCreateDemoTenant} disabled={isCreating} />
                    )}
                </Card.Group>
                {isCreating && (
                    <Message icon positive>
                        <Icon name="circle notched" loading />
                        <Message.Content>Creating a new tenant ...</Message.Content>
                    </Message>
                )}
                {!!newTenantInfo && (
                    <Message icon positive>
                        <Icon name="check circle outline" />

                        <Message.Content>
                            <Message.Header>New demo tenant {newTenantInfo.name} has been created.</Message.Header>
                            <p>Write down these SPORK login credentials:</p>
                            <p>
                                Teacher login: {newTenantInfo.teacherEmail}
                                <br />
                                Teacher password: {newTenantInfo.teacherPassword}
                            </p>
                            <Header as="h4">Provisioning codes:</Header>
                            <List>
                                {newTenantInfo.users.map(u => (
                                    <ListItem key={u.id}>
                                        {u.first_name} {u.last_name}, {u.scope}, code: {u.id}/{u.secret}
                                    </ListItem>
                                ))}
                            </List>
                            <p>
                                <strong>IMPORTANT:</strong> This is the only time when these credentials are displayed.
                            </p>
                        </Message.Content>
                    </Message>
                )}
            </React.Fragment>
        );
    }
}
