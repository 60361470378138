import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Popup, Segment, Form, List, Message, Button, Header, Icon } from "semantic-ui-react";
import ActionLink from "../../component/ActionLink";
import uuid from "uuid/v4";

export default class UploadFile extends PureComponent {
    static propTypes = {
        schools: PropTypes.array,
        onSchoolLoad: PropTypes.func.isRequired,
        onImportDevices: PropTypes.func.isRequired,
    };

    state = {
        dragging: false,
        selectedFile: null,
        errorMessage: null,
        okMessage: null,
    };

    componentDidMount() {
        if (this.props.schoolNames === undefined) {
            this.props.onSchoolLoad();
        }
    }

    csvContent = [];

    // make sure user has selected only one file
    maxSelectFile = event => {
        let files = event.target.files; // create file object
        if (files.length > 1) {
            const msg = "Only 1 file can uploaded at a time";
            event.target.value = null; // discard selected file
            this.setState({ errorMessage: msg, okMessage: null });
            return false;
        }
        return true;
    };

    // verify that we have CSV file. We have to rely on extension.
    // MIME type is not reliable - items which are D&Dropped have it sometimes empty
    checkFileExtension = file => {
        if (!file.name.endsWith(".csv")) {
            let err = "File is not in a supported format CSV.";
            this.setState({ errorMessage: err, okMessage: null });
            return false;
        }
        return true;
    };

    handleDragOver = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    handleDragIn = e => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true, errorMessage: null, okMessage: null, selectedFile: null });
        }
    };

    handleDragOut = e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ dragging: false });
    };

    // file selected by drag and drop
    handleFileDrop = e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ dragging: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.processFile(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    // file selected by input dialog
    handleChange = e => {
        this.setState({ errorMessage: null });
        if (this.maxSelectFile(event) && e.target.files[0]) {
            // if return true allow to setState
            this.processFile(e.target.files[0]);
        }
    };

    // download the file to browser and do some data consistency checks and verifications
    processFile = file => {
        const { schools } = this.props;
        var BreakException = {};

        if (this.checkFileExtension(file)) {
            this.setState({
                selectedFile: file,
                okMessage: null,
                errorMessage: null,
            });
            var reader = new FileReader();
            reader.onload = event => {
                const file = event.target.result;
                const allLines = file.split(/\r\n|\n/);
                // Reading line by line
                try {
                    allLines.forEach((line, i) => {
                        if (line.trim().length > 0) {
                            // process only non-empty lines (ignore empty)
                            const match = line.match(/^(.*),(.*)$/);
                            if (!match) {
                                this.setState({
                                    errorMessage: (
                                        <React.Fragment>
                                            <Message.Header>Error on line {i}</Message.Header>
                                            {line}
                                        </React.Fragment>
                                    ),
                                });
                                throw BreakException;
                            }
                            const school = schools.filter(s => s.name === match[2].trim());
                            if (school.length === 0) {
                                this.setState({
                                    errorMessage: (
                                        <React.Fragment>
                                            <Message.Header>Error on line {i + 1}</Message.Header>
                                            School &quot;{match[2]}&quot; does not exist in this tenant.
                                        </React.Fragment>
                                    ),
                                });
                                throw BreakException;
                            }
                            this.csvContent.push({
                                client_id: uuid(),
                                client_secret: uuid(),
                                serial_number: match[1].trim(),
                                school_id: school[0].id,
                            });
                        }
                    });
                    this.setState({ okMessage: "Items to import: " + this.csvContent.length });
                } catch (e) {
                    this.csvContent = [];
                    if (e !== BreakException) throw e;
                }
            };

            reader.readAsText(file);
        } else {
            this.csvContent = [];
            this.setState({
                selectedFile: null,
            });
        }
    };

    handleClose = () => {
        this.setState({
            selectedFile: null,
            okMessage: null,
            errorMessage: null,
            isOpen: false,
        });
        this.csvContent = [];
    };

    handleOpen = () => {
        this.setState({
            selectedFile: null,
            okMessage: null,
            errorMessage: null,
            isOpen: true,
        });
        this.csvContent = [];
    };

    handleImport = () => {
        this.props.onImportDevices(this.csvContent);
        this.handleClose();
    };

    render() {
        const { dragging, selectedFile, errorMessage, okMessage, isOpen } = this.state;

        return (
            <Popup
                on="click"
                trigger={<ActionLink horizontal icon="upload" content="Import CSV" />}
                position="bottom right"
                open={isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                wide
            >
                <Segment basic>
                    <Header as="h3">Tablet import</Header>
                    You can upload data about tablets in CSV format.
                    <Popup
                        trigger={<Icon name="question circle outline" color="grey" />}
                        content={
                            <Segment basic>
                                Currently, only values <b>serial number</b> and <b>school name</b> are supported.
                                <br />
                                CSV file should have following format:
                                <pre>serial number,school name</pre>
                                <ul>
                                    <li>
                                        <b>serial number</b> - can be any string
                                    </li>
                                    <li>
                                        <b>school name</b> - must be exactly the name of school in SPORK
                                    </li>
                                </ul>
                            </Segment>
                        }
                    />
                </Segment>
                <Form>
                    <Form.Field>
                        <input type="file" id="file" onChange={this.handleChange} />
                    </Form.Field>
                </Form>
                <Segment
                    textAlign="center"
                    {...(dragging
                        ? { style: { border: "solid grey 1px", fontSize: "1.5em", color: "grey" } }
                        : { style: { border: "dashed grey 1px", fontSize: "1.5em", color: "grey" } })}
                    onDragOver={this.handleDragOver}
                    onDragEnter={this.handleDragIn}
                    onDragLeave={this.handleDragOut}
                    onDrop={this.handleFileDrop}
                >
                    or drop a file here
                </Segment>
                {selectedFile && (
                    <List>
                        <List.Item>
                            <List.Header>Selected file:</List.Header>
                            {selectedFile.name}
                        </List.Item>
                    </List>
                )}
                {errorMessage && <Message negative>{errorMessage}</Message>}
                {okMessage && <Message positive>{okMessage}</Message>}
                <Button floated="right" disabled={!selectedFile || !!errorMessage} primary onClick={this.handleImport}>
                    Import
                </Button>
            </Popup>
        );
    }
}
