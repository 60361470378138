import { createSelector } from "reselect";
import { fetchKitsData } from "../AKitsList/AKitsListSelectors";
import { getUsers, getEditorId, getEditorTimestamp, getFullName, getAuthorId, getCreatedTimestamp } from "../../../Users/UserList/UserListSelectors";

const getKitId = (state, props) => props.itemId;

export const editorId = createSelector(
    fetchKitsData,
    getKitId, 
    getEditorId
);

export const editor = createSelector(
    getUsers,
    editorId,
    getFullName
);

export const editorTimestamp = createSelector(
    fetchKitsData,
    getKitId, 
    getEditorTimestamp
);

export const authorId = createSelector(
    fetchKitsData,
    getKitId, 
    getAuthorId
);

export const author = createSelector(
    getUsers,
    authorId,
    getFullName
);

export const authorTimestamp = createSelector(
    fetchKitsData,
    getKitId, 
    getCreatedTimestamp
);
