import AddTopic from "./AddTopic";
import { firebaseConnect } from "react-redux-firebase";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading";
import {
    onAddConcept,
    onAddKeyword,
    onAddObjectiveSet,
    onSelectObjectiveSet,
    onAddObjective,
    onAddTopic,
    onAddOiiObjective,
    onClearAddTopicError,
    onUpdateTopic,
    onAddHeading,
    onAddSubHeading
} from "../topicsActions";
import {
    getConceptsAsDropdown,
    getKeywordsAsDropdown,
    getObjectiveSetsAsDropdown,
    getOiiObjectivesAsDropdown,
    getHeadingsAsDropdown,
    isProcessing,
    getSubHeadingsAsDropdown
} from "../topicsSelectors";
import { getSelectedObjectiveSet } from "../ObjectiveSetsManager/ObjectiveSet/objectiveSetSelector";

const mapStateToProps = function(state, props) {
    return {
        concepts: getConceptsAsDropdown(state),
        keywords: getKeywordsAsDropdown(state),
        objectiveSets: getObjectiveSetsAsDropdown(state),
        selectedObjective: getSelectedObjectiveSet(
            state,
            // load objectives for selected objective set from Redux store or for pre-set objective set from props
            state.topics.selectedObjectiveSet || (props.defaultValues && props.defaultValues.objectiveSetKey)
        ) || {},
        oii_objectives: getOiiObjectivesAsDropdown(state),
        error: state.topics.error,
        headings: getHeadingsAsDropdown(state),
        subheadings: getSubHeadingsAsDropdown(state),
        isProcessing: isProcessing(state),
        // following props are only for spinner detection
        _concepts: state.firebase.data.concepts,
        _keywords: state.firebase.data.keywords,
        _headings: state.firebase.data.headings,
        _oii_objectives: state.firebase.data.oii_objectives,
        _objective_sets: state.firebase.data.objective_sets,
        _subheadings: state.firebase.data.subheadings,
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onAddConcept,
            onAddKeyword,
            onAddObjectiveSet,
            onSelectObjectiveSet,
            onAddObjective,
            onAddTopic,
            onAddOiiObjective,
            onClearAddTopicError,
            onUpdateTopic,
            onAddHeading,
            onAddSubHeading
        },
        dispatch
    );
};

export default compose(
    firebaseConnect(() => {
        return [
            "concepts",
            "keywords",
            { path: "objective_sets", queryParams: ["orderByChild=name"] },
            "oii_objectives",
            "headings",
            "subheadings"
        ];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["_concepts", "_keywords", "_oii_objectives", "_headings", "_objective_sets", "_subheadings"])
)(AddTopic);
