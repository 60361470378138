import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import memoizeOne from "memoize-one";
import TopicTreeSelector from "../../../TopicCollection/TopicTreeSelector";

const arrayToObject = memoizeOne(
    (arr) => arr && arr.reduce((obj, id) => { obj[id] = true; return obj; }, {})
);

const getTopicTooltip = (isUsed, notAvailable, topicTitle) => (
    isUsed ? "This topic can't be removed." :
        notAvailable ? "This topic is not assigned to any of the selected problems." :
            topicTitle 
);

export default class TopicsToRemove extends React.PureComponent {
    static propTypes = {
        topics: PropTypes.array,
        selected: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const { topics, selected, onChange } = this.props;
        return (
            <Form.Field className="topicsToRemove">
                <label>Topics</label>
                {topics && topics.length ? (
                    <TopicTreeSelector
                        onChange={onChange}
                        selectedTopics={selected}
                        filterOnly={arrayToObject(topics)}
                        enableLessonFilter={false}
                        getTopicTooltip={getTopicTooltip}
                    />
                ) : (
                    <div className="noData">No topics assigned to the selected problems.</div>
                )}
            </Form.Field>
        );
    }
}