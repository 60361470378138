import React from "react";
import PropTypes from "prop-types";
import { Divider, Segment, Header, Message } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";

import { DragHandle } from "../../../component/DragHandle";
import CreateWidgetDialog from "../../WidgetLibrary/CreateWidgetDialog";
import DeleteItem from "../../component/DeleteItem";
import WidgetTopics from "../../Widget/WidgetTopics/WidgetTopicsContainer";
import AddExistingWidget from "../AddExistingWidget";
import cx from "classnames";

import "./LessonWidgetsList.scss";
import { ActionIconsContainer } from "bmd-react";
import ReviewComments from "../LessonReviews/ReviewComments";

class LessonWidgetsEntry extends React.PureComponent {
    static propTypes = {
        selectedLesson: PropTypes.object.isRequired,
        widgetId: PropTypes.string.isRequired,
        widget: PropTypes.object.isRequired,
        canEdit: PropTypes.bool,
        onRemove: PropTypes.func.isRequired,
        isFocused: PropTypes.bool,
        onSetFocus: PropTypes.func,
    };

    handleOnClick = () => {
        const { onSetFocus, widgetId } = this.props;
        onSetFocus && onSetFocus(widgetId, true);
    };

    handleStopOnClick = e => {
        e.stopPropagation();
    };

    render() {
        const { widget, widgetId, selectedLesson, canEdit, onRemove, isFocused } = this.props;
        return (
            <div className={cx("editable lessonWidget", {"focused" : isFocused})}
                title="Click on the icon in top left corner and use drag&amp;drop to re-arrange widgets in the lesson"
                onMouseDown={this.handleOnClick}
            >
                <div className="lessonWidgetsEntryToolbar">
                    
                    <div className="lessonWidgetsEntryName" title={widget.name}>
                        {widget.name}
                    </div>
                    {canEdit && (
                        <div onMouseDown={this.handleStopOnClick}>
                            <ActionIconsContainer floatRight>
                                <DragHandle entityName="widget" />
                                <DeleteItem itemId={widgetId} onRemoveItem={onRemove} deleteText="widget" />                                
                            </ActionIconsContainer>
                        </div>
                    )}
                </div>
                <a style={{ cursor: "pointer" }} onMouseDown={this.handleStopOnClick}>
                    <WidgetTopics
                        widget={widget}
                        id={widgetId}
                        lessonTopics={selectedLesson.topics}
                        lessonName={selectedLesson.name}
                        canEdit={canEdit}
                    />
                </a>
            </div>
        );
    }
}

const SortableItem = SortableElement(({ ...props }) => <LessonWidgetsEntry {...props} />);

const createWidgetMapper = props => ({ key, value }, index) =>
    value && (
        <SortableItem
            key={key}
            index={index}
            widgetId={key}
            widget={value}
            {...props}
            isFocused={key === props.focusedItem.widgetId}
        />
    );

const SortableList = SortableContainer(({ widgets, ...props }) => (
    <div className="sortableContainer" style={{ maxHeight: "70vh", overflowY: "auto", marginBottom: "1em" }}>
        {widgets && widgets.map(createWidgetMapper(props)).filter(Boolean)}
    </div>
));

class LessonWidgetsList extends React.PureComponent {
    static propTypes = {
        widgets: PropTypes.array,
        outlineId: PropTypes.string,
        lessonId: PropTypes.string,
        selectedLesson: PropTypes.object.isRequired,
        onAddNewWidget: PropTypes.func,
        onAddExistingWidget: PropTypes.func,
        onUpdateWidgets: PropTypes.func,
        highestPosition: PropTypes.number,
        uncoveredTopicsCount: PropTypes.number,
        uncoveredTopics: PropTypes.object,
        lessonTopicOptions: PropTypes.array,
        canEdit: PropTypes.bool,
        onCloneToNewWidget: PropTypes.func,
        reviews: PropTypes.object,
        // which widget ID is focused and should be highlighted
        focusedItem: PropTypes.shape({
            widgetId: PropTypes.string,
            scroll: PropTypes.bool,
        }),
        onSetFocus: PropTypes.func.isRequired,
    };

    handleAddNewWidget = data => {
        const { outlineId, lessonId, onAddNewWidget, highestPosition } = this.props;
        onAddNewWidget({ ...data, outlineId, lessonId, highestPosition });
    };

    handleAddExistingWidget = ({ widgetId }) => {
        const { outlineId, lessonId, onAddExistingWidget, highestPosition } = this.props;
        onAddExistingWidget({ outlineId, lessonId, widgetId, highestPosition });
    };

    handleRemoveWidget = widgetId => {
        const { outlineId, lessonId, onUpdateWidgets, selectedLesson, onSetFocus, focusedItem } = this.props;
        const widgets = { ...selectedLesson.widgets, [widgetId]: null };
        // if we're removing focused widget, reset focus
        if (focusedItem && focusedItem.widgetId === widgetId) {
            onSetFocus({});
        }
        onUpdateWidgets({ outlineId, lessonId, widgets, removedWidgetId: widgetId });
    };

    handleCloneWidget = widgetId => {
        const { onCloneToNewWidget, outlineId, lessonId, highestPosition } = this.props;
        onCloneToNewWidget({ widgetId, outlineId, lessonId, highestPosition });
    };

    handleChangeOrdering = ({ oldIndex, newIndex }) => {
        const { onUpdateWidgets, widgets, lessonId, outlineId } = this.props;
        let orderedWidgets = arrayMove(widgets, oldIndex, newIndex);

        const widgetsCollection = {};
        orderedWidgets.map((item, index) => {
            widgetsCollection[item.key] = index + 1;
            //return item.key;
        });
        onUpdateWidgets({ outlineId, lessonId, widgets: widgetsCollection });
    };

    render() {
        const {
            widgets,
            selectedLesson,
            uncoveredTopicsCount,
            uncoveredTopics,
            canEdit,
            outlineId,
            reviews,
            focusedItem,
            onSetFocus,
            lessonId,
        } = this.props;
        const hasWidgets = widgets && widgets.length > 0;
        return (
            <Segment basic className="lessonWidgetList">
                <Header as="h4">Widgets</Header>
                {hasWidgets ? (
                    <SortableList
                        className="sortableList"
                        onSortEnd={this.handleChangeOrdering}
                        helperClass="sortableHelper lessonWidgetList"
                        useDragHandle
                        selectedLesson={selectedLesson}
                        widgets={widgets}
                        onRemove={this.handleRemoveWidget}
                        canEdit={canEdit}
                        focusedItem={focusedItem}
                        onSetFocus={onSetFocus}
                    />
                ) : (
                    <React.Fragment />
                )}
                {hasWidgets && uncoveredTopicsCount > 0 && (
                    <Message
                        error
                        title="Free lesson topics are automatically offered when selecting existing widget or creating new widget."
                    >
                        <p>Some of lesson topics are not covered by any widget.</p>
                        <p>Add another widget covering the topics or assign the topics to one of the widgets above.</p>
                    </Message>
                )}
                {canEdit && (
                    <div>
                        <CreateWidgetDialog
                            selectedLesson={selectedLesson}
                            onCreate={this.handleAddNewWidget}
                            preselectTopics={uncoveredTopics}
                            inLessonContext
                        />
                        <Divider hidden fitted />
                        <AddExistingWidget
                            selectedLesson={selectedLesson}
                            lessonTopicOptions={this.props.lessonTopicOptions}
                            initTopicFilter={uncoveredTopics}
                            onAdd={this.handleAddExistingWidget}
                            outlineId={outlineId}
                            onCloneToNewWidget={this.handleCloneWidget}
                            lessonId={lessonId}
                        />
                    </div>
                )}
                <ReviewComments comments={(reviews && reviews.COMMENT_ONLY) || []} />
            </Segment>
        );
    }
}

export default LessonWidgetsList;
