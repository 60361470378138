import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Dropdown, Tab, Dimmer, Loader } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import memoizeOne from "memoize-one";

import { FilterParamsShape } from "./utils";
import TopicTreeSelector from "../../../TopicCollection/TopicTreeSelector";

export default class FilterByDialog extends React.PureComponent {
    static propTypes = {
        resourceTags: PropTypes.array,
        filterParams: FilterParamsShape,
        onApplyFilterBy: PropTypes.func.isRequired,
        onCloseFilterBy: PropTypes.func.isRequired,
        onFetchUsedTags: PropTypes.func.isRequired,
        isLoadingTags: PropTypes.bool,
        onFetchUsedCourseVariants: PropTypes.func.isRequired,
        isLoadingVariants: PropTypes.bool,
        courseVariantOptions: PropTypes.array,
        onLoadResourceTypes: PropTypes.func,
        isLoadingResourceTypes: PropTypes.bool,
        resourceTypesOptions: PropTypes.array,
        ownersOptions: PropTypes.array,
        isLoadingOwners: PropTypes.bool,
        onFetchOwners: PropTypes.func,
        onFetchUsedTopics: PropTypes.func,
        isLoadingTopics: PropTypes.bool,
        usedTopics: PropTypes.object,
    };

    state = {
        tags: null,
        courseVariant: null,
        resourceType: null,
        owner: null,
        topic: null,
    };

    handleSelectTopic = topic => {
        this.setState({ topic: topic !== this.state.topic ? topic : null });
    }

    componentDidMount() {
        const { onFetchUsedTags, onFetchUsedCourseVariants, filterParams, onLoadResourceTypes, onFetchOwners, onFetchUsedTopics } = this.props;
        onFetchUsedTags();
        onFetchUsedCourseVariants();
        onLoadResourceTypes();
        onFetchOwners();
        onFetchUsedTopics();
        this.setState(filterParams) ;
    }
    
    handleChangeTags = (e, { value }) => {
        this.setState({ tags: value.filter(Boolean) });
    }

    handleChangeVariant = (e, { value }) => {
        const selected = (value && this.props.courseVariantOptions.find(item => item.value === value));
        const courseVariant = selected ? { id: selected.value, name: selected.text } : null;
        this.setState({ courseVariant });
    }

    handleChangeOwner = (e, { value }) => {
        const selected = (value && this.props.ownersOptions.find(item => item.value === value));
        const owner = selected ? { id: selected.value, name: selected.text } : null;
        this.setState({ owner });
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    handleClose = () => {
        this.props.onCloseFilterBy();
    }

    handleSave = () => {
        const { tags, courseVariant, resourceType, owner, topic } = this.state;
        this.props.onApplyFilterBy({ tags, courseVariant, resourceType, owner, topic });
        this.handleClose();
    }

    generatePanes = memoizeOne((ownersOptions, courseVariantOptions, resourceTypesOptions, resourceTags, owner,
        courseVariant, resourceType, tags, selectedTopic, usedTopics) => {
        const { isLoadingOwners, isLoadingResourceTypes, isLoadingTags, isLoadingVariants, isLoadingTopics } = this.props;
        return [
            {
                menuItem: "General",
                pane: (
                    <Tab.Pane key="generalPane">
                        <Form onSubmit={this.handleSave}>
                            <Form.Dropdown
                                options={ownersOptions}
                                selection
                                fluid
                                search
                                loading={isLoadingOwners}
                                label="Owner"
                                name="userId"
                                clearable
                                placeholder="Select an owner... "
                                onChange={this.handleChangeOwner}
                                value={owner && owner.id || null}
                                closeOnChange
                            />
                            <Form.Dropdown
                                options={resourceTypesOptions}
                                selection
                                fluid
                                search
                                loading={isLoadingResourceTypes}
                                label="Resource Type"
                                name="resourceType"
                                clearable
                                placeholder="Select a resource type... "
                                onChange={this.handleChange}
                                value={resourceType}
                                closeOnChange
                            />
                            <Form.Dropdown
                                options={courseVariantOptions}
                                selection
                                fluid
                                search
                                loading={isLoadingVariants}
                                label="Course"
                                name="selectedCourseVariant"
                                clearable
                                placeholder="Here you can select course"
                                onChange={this.handleChangeVariant}
                                value={courseVariant && courseVariant.id || null}
                                closeOnChange
                            />
                            <Form.Field>
                                <label>Select tags to filter resources:</label>
                                <Dropdown
                                    name="selectedTags"
                                    fluid
                                    search
                                    multiple
                                    clearable
                                    selection
                                    lazyLoad
                                    loading={isLoadingTags}
                                    options={resourceTags || []}
                                    value={tags || []}
                                    onChange={this.handleChangeTags}
                                    placeholder="Here you select tags"
                                    closeOnChange
                                />
                            </Form.Field>
                        </Form>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Topics",
                pane: (
                    <Tab.Pane key="topicPane">
                        <Dimmer.Dimmable dimmed={isLoadingTopics}>
                            <Dimmer active={isLoadingTopics} inverted>
                                <Loader>Loading topics used by resources...</Loader>
                            </Dimmer>
                            <TopicTreeSelector
                                single
                                onChange={this.handleSelectTopic}
                                selectedTopics={selectedTopic}
                                filterOnly={usedTopics}
                            />
                        </Dimmer.Dimmable>
                    </Tab.Pane>
                )
            }
        ];
    })

    render() {
        const { resourceTags, courseVariantOptions, resourceTypesOptions, ownersOptions, usedTopics } = this.props;
        const { courseVariant, tags, resourceType, owner, topic } = this.state;
        return (
            <Modal open onClose={this.handleClose} size="small">
                <Modal.Header>Advanced Filter</Modal.Header>
                <Modal.Content>
                    <Tab
                        renderActiveOnly={false}
                        panes={this.generatePanes(
                            ownersOptions,
                            courseVariantOptions,
                            resourceTypesOptions,
                            resourceTags,
                            owner,
                            courseVariant,
                            resourceType,
                            tags,
                            topic,
                            usedTopics,
                        )}
                        style={{ height: "415px"}}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onCancel={this.handleClose}
                        onSave={this.handleSave}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
