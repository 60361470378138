const TYPES = {
    RESOURCE_PUBLIC_UPDATE: "RESOURCE_PUBLIC_UPDATE",
    RESOURCE_PUBLIC_UPDATED: "RESOURCE_PUBLIC_UPDATED",
};

const onUpdate = (payload) => ({
    type: TYPES.RESOURCE_PUBLIC_UPDATE,
    payload,
});

const onUpdated = (payload) => ({
    type: TYPES.RESOURCE_PUBLIC_UPDATED,
    payload,
});

export {
    TYPES,
    onUpdate,
    onUpdated,
};
