import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onLoadResource } from "./ResourceLinkActions";
import { getResource } from "./ResourceLinkSelectors";
import ResourceLink from "./ResourceLink";

const mapStateToProps = function(state, props) {
    return {
        resource: getResource(state, props),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onLoadResource,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(ResourceLink);
