import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Segment, Table, Icon, Message } from "semantic-ui-react";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import AccessControl from "../../auth/AccessControl";
import ResourceLink from "./ResourceLink/ResourceLinkContainer";
import RemoveIcon from "../../component/RemoveIcon";
import CreateResourceDialog from "./CreateResourceDialog";
import ActionLink from "../../component/ActionLink";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import AdvancedResourceSearch from "./AdvancedResourceSearch/AdvancedResourceSearchContainer";
import AssetEditorDialog from "./AssetEditorDialog";
import EditActionIcon from "bmd-react/dist/EditActionIcon";

const descriptionStyle = {
    color: "grey"
};

export default class AssetsEditor extends PureComponent {
    static propTypes = {
        editValidator: PropTypes.object,
        onSave: PropTypes.func,
        onRemove: PropTypes.func,
        assets: PropTypes.object,
        showCompletePlaceHolder: PropTypes.bool,
    }

    state = {
        removeContext: null,
        removePayload: null,
        isOpenAssetEditor: false,
        resource_id: null,
        asset: null
    };

    handleRemove = (e, payload) => {
        const { removePayload } = this.state;
        if (!removePayload) {
            this.setState({
                removeContext: e.currentTarget,
                removePayload: payload
            });
        }
    };

    handleFoundResource = (e, { result: { resource_id } }) => this.setState({ isOpenAssetEditor: true, resource_id, asset: null });
    handleNewResource = (resource_id) => this.setState({ isOpenAssetEditor: true, resource_id, asset: null });
    handleCancelRemove = () => this.setState({ removeContext: null, removePayload: null });
    handleRemoveConfirm = () => {
        const { onRemove } = this.props;
        onRemove(this.state.removePayload);
        this.handleCancelRemove();
    }
    handleCloseAssetEditor = () => this.setState({ isOpenAssetEditor: false, resource_id: null, asset: null });
    handleSaveAsset = (data) => {
        const { onSave } = this.props;
        onSave(data);
        this.handleCloseAssetEditor();
    }
    handleEdit = ({ asset }) => this.setState({ isOpenAssetEditor: true, resource_id: asset.resource_id, asset });

    renderPlaceHolderContent = (assets, editValidator) => (
        <React.Fragment>
            <Message attached="top">
                <Message.Header>Assets</Message.Header>
                The assets are additional materials (usually files) for a school course but they are not related to a specific lesson. Teacher can control sharing of assets with students using a tablet.
                <p>Files, presentations and other teaching materials related to a lesson should be added as an iPlanner Instrument to the lesson.</p>
            </Message>
            <NoDataPlaceholder
                attached
                text="No assets listed for this iPlanner"
                icon="paperclip outline"
            >
                <AccessControl {...editValidator}>
                    <Segment.Inline style={{ display: "inline-flex"}}>
                        <CreateResourceDialog
                            onCreate={this.handleNewResource}
                            triggerLabel="Create New"
                        />
                        <AdvancedResourceSearch
                            onResultSelect={this.handleFoundResource}
                            excludeInResults={assets && assets.used}
                        />
                    </Segment.Inline>
                </AccessControl>
            </NoDataPlaceholder>
        </React.Fragment>
    )

    render() {
        const { editValidator, assets } = this.props;
        const { removeContext, isOpenAssetEditor, asset, resource_id } = this.state;
        return (
            <React.Fragment>
                {isOpenAssetEditor && (
                    <AssetEditorDialog
                        resource_id={resource_id}
                        asset={asset}
                        onSave={this.handleSaveAsset}
                        onClose={this.handleCloseAssetEditor}
                    />
                )}
                {assets && 0 < assets.list.length ? (
                    <React.Fragment>
                        <Message attached="top" header="Assets" />
                        <Segment attached>
                            <ConfirmDeleteWithContext
                                context={removeContext}
                                confirmText="Remove asset"
                                onConfirm={this.handleRemoveConfirm}
                                whatText="Asset"
                                onCancel={this.handleCancelRemove}
                            />
                            
                            <div style={{maxHeight: "70vh", overflowY: "auto", marginBottom: "1.5em"}}>
                                <Table basic="very">
                                    <Table.Body>
                                        {assets.list.map((item) => (
                                            <Table.Row
                                                key={item.resource.id}
                                                style={{ display: "flex" }}
                                                id={item.resource.id}
                                                columns="2"
                                                className="editable"
                                            >
                                                <Table.Cell width="7">
                                                    <ResourceLink sipi={{ resource_id: item.resource.id }} resource={item.resource} disableAutoLoad renderAsLink />
                                                </Table.Cell>
                                                <Table.Cell width="1">
                                                    {item.asset.student_view && <Icon name="tablet alternate" color="green" title="Available for students with a tablet." />}
                                                    {item.asset.on_approval && <Icon name="history" flipped="horizontally" color="orange" title="Display only after the teacher approval." />}
                                                </Table.Cell>
                                                <Table.Cell width="7" style={descriptionStyle}>
                                                    {item.resource.description}
                                                </Table.Cell>
                                                <Table.Cell width="1" textAlign="right" style={{display: "flex"}}>
                                                    <AccessControl {...editValidator}>
                                                        <EditActionIcon data={item} onClick={this.handleEdit} />
                                                        <RemoveIcon onRemove={this.handleRemove} id={`asset_${item.resource.id}`} entityName="asset" className="actionIconClass" />
                                                    </AccessControl>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                        )}
                                    </Table.Body>
                                </Table>
                            </div>
                            <AccessControl {...editValidator}>
                                <CreateResourceDialog
                                    renderTriggerAsLink
                                    onCreate={this.handleNewResource}
                                    triggerLabel="Create New"
                                />
                                <AdvancedResourceSearch
                                    trigger={<ActionLink
                                        style={{marginLeft: "3vw"}}
                                        horizontal
                                        icon="search"
                                        title="Find existing"
                                        content="Find Existing"
                                    />}
                                    onResultSelect={this.handleFoundResource}
                                    excludeInResults={assets && assets.used}
                                />
                                
                            </AccessControl>
                        </Segment>
                    </React.Fragment>
                ) : (
                    this.renderPlaceHolderContent(assets, editValidator)
                )}
            </React.Fragment>
        );
        
    }
}
