import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { DraggableWrapper } from "../Dnd";
import LessonTopic from "./LessonTopic";
import LessonTopicContainer from "./LessonTopicContainer";

export default class LessonTopicDraggable extends PureComponent {
    static propTypes = {
        topicData: LessonTopic.propTypes.topicData,
        lessonId: PropTypes.string.isRequired,
        index: PropTypes.number,
        canEdit: PropTypes.bool,
        outlineId: PropTypes.string,
    };

    render() {
        const { topicData: topic, lessonId, index, canEdit, outlineId } = this.props;
        return canEdit ? (
            <Draggable key={topic.key} draggableId={[lessonId, topic.key]} index={index}>
                {(provided, snapshot) => <DraggableWrapper provided={provided} innerRef={provided.innerRef}>
                    <LessonTopicCont
                        topic={topic}
                        lessonId={lessonId}
                        canEdit={canEdit}
                        provided={provided}
                        snapshot={snapshot}
                        outlineId={outlineId}
                    />
                </DraggableWrapper>}
            </Draggable>) 
            : (<LessonTopicCont
                topic={topic}
                lessonId={lessonId}
                canEdit={canEdit}
                provided={{}}
                snapshot={{}}
                outlineId={outlineId}
            />
            );
    }
}

const LessonTopicCont = ({ topic, lessonId, canEdit, provided, snapshot, outlineId }) => (
    <LessonTopicContainer
        topicData={topic}
        lessonId={lessonId}
        canEdit={canEdit}
        dragHandle={<Icon
            name="bars"
            link
            color="grey"
            {...provided.dragHandleProps}
            style={{ cursor: "move" }} 
            title="Change topic order or move topic to another lesson"
        />}
        outlineId={outlineId}
        snapshot={snapshot}
    />
);

LessonTopicCont.propTypes = {
    provided: PropTypes.object,
    snapshot: PropTypes.object,
    topic: PropTypes.object,
    canEdit: PropTypes.bool,
    lessonId: PropTypes.string,
    outlineId: PropTypes.string,
};