import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Modal, Button, Checkbox, Header, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import TopicTreeSelector from "../../../TopicCollection/TopicTreeSelector";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import Topic from "../../../TopicCollection/Topic";
import "./EditLessonTopics.css";
import ConfirmDeleteWithContext from "../../../component/ConfirmDeleteWithContext";
import RemoveIcon from "../../../component/RemoveIcon";
import { onMarkTopicAsReview } from "../outlinesActions";
import { getReviewTopics } from "../outlinesSelectors";

class EditLessonTopics extends PureComponent {
    static propTypes = {
        usedTopics: PropTypes.object,
        topicsList: PropTypes.array,
        onLessonAddTopic: PropTypes.func.isRequired,
        onLessonRemoveTopic: PropTypes.func,
        lessonId: PropTypes.string,
        outlinesData: PropTypes.object,
        outlineId: PropTypes.string,
        onMarkTopicAsReview: PropTypes.func,
        reviewTopics: PropTypes.object,
    };

    state = {
        selectedTopics: {},
        isOpen: false,
    };

    handleOpen = () => {
        this.setState({ isOpen: true });
    };

    handleChange = selectedTopics => {
        this.setState({ selectedTopics: { ...selectedTopics } });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    // find highest ordering number
    getMaxOrdering(topicList) {
        return topicList.map(topic => topic.order).reduce((max, cur) => Math.max(max, cur), 0);
    }

    handleAssign = () => {
        const { selectedTopics } = this.state;
        const { outlineId, lessonId, onLessonAddTopic, topicsList } = this.props;
        const nextOrdering = this.getMaxOrdering(topicsList) + 1;
        onLessonAddTopic(lessonId, outlineId, Object.keys(selectedTopics), nextOrdering);
        this.setState({ selectedTopics: {} });
    };

    handleRemove = (e, id) => {
        const { removeId } = this.state;
        if (!removeId) {
            this.setState({
                removeContext: e.currentTarget,
                removeId: id,
            });
        }
    };

    handleRemoveConfirm = () => {
        const { lessonId, outlineId, onLessonRemoveTopic } = this.props;
        const { removeId } = this.state;
        if (removeId && onLessonRemoveTopic) {
            onLessonRemoveTopic(lessonId, outlineId, [removeId]);
        }
        this.handleCancelRemove();
    }

    handleCancelRemove = () => {
        this.setState({ removeId: null, removeContext: null });
    };

    handleToggleReview = (e, { id, checked }) => {
        const { onMarkTopicAsReview, lessonId, outlineId } = this.props;
        onMarkTopicAsReview && onMarkTopicAsReview({ lessonId, outlineId, topicId: id, checked });
    }

    render() {
        const { usedTopics, topicsList, outlinesData, lessonId, outlineId, reviewTopics } = this.props;
        const { isOpen, selectedTopics, removeContext } = this.state;
        const lessonName =
            outlinesData &&
            outlinesData[outlineId] &&
            outlinesData[outlineId].lessons[lessonId] &&
            outlinesData[outlineId].lessons[lessonId].name;
        let count = topicsList ? topicsList.length : 0;
        return (
            <Modal
                trigger={<Button
                    content="Edit topics..."
                    style={{ float: "right", marginLeft: 0 }}
                    compact
                    size="small"
                    title="Click here to edit topics."
                />}
                open={isOpen}
                onOpen={this.handleOpen}
            >
                <Modal.Header>Lesson topic assignment</Modal.Header>
                <Modal.Content>
                    <ConfirmDeleteWithContext
                        context={removeContext}
                        confirmText="Unassign"
                        onConfirm={this.handleRemoveConfirm}
                        whatText="topic from this lesson"
                        onCancel={this.handleCancelRemove}
                    />
                    <TopicTreeSelector
                        onChange={this.handleChange}
                        selectedTopics={selectedTopics}
                        usedTopicIds={usedTopics}
                    />
                    <div style={{ textAlign: "center", marginBottom: "2em" }}>
                        <Button
                            icon="arrow down"
                            labelPosition="left"
                            content="Assign selected topics to lesson"
                            disabled={!selectedTopics || 0 == Object.keys(selectedTopics).length}
                            onClick={this.handleAssign}
                        />
                    </div>
                    <span style={{float: "right"}}><b>{count}</b> <span style={{color: "grey"}}> assigned topic
                        {(0 == count || 1 < count) && "s"}
                    </span></span>
                    <Header style={{marginTop: 0}}>{lessonName && <span className="sporkPrimaryColorCharcoalGray">{lessonName}</span>}</Header>
                    {topicsList && topicsList.length ? (
                        <Segment style={{ minHeight: "150px", height: "150px", overflowY: "auto"}}>
                            {topicsList.map(topic => (
                                <div key={topic.key} className="simpleTopicList actionIcons">
                                    <Topic topic={topic} />
                                    <Checkbox
                                        label="Review in lesson"
                                        title="Mark topic for review at the beginning of the lesson."
                                        id={topic.key}
                                        onChange={this.handleToggleReview}
                                        checked={reviewTopics && reviewTopics[topic.key] || false}
                                    />
                                    <RemoveIcon
                                        onRemove={this.handleRemove}
                                        id={topic.key}
                                        entityName={`topic ${topic.name}`}
                                    />
                                </div>
                            ))}
                        </Segment>
                    ) : (
                        <NoDataPlaceholder
                            text="Lesson has no topics."
                            style={{ minHeight: "150px", height: "150px" }}
                        />
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.handleClose}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const dispatchToProps = dispatch => {
    return bindActionCreators({ onMarkTopicAsReview }, dispatch);
};

export default compose(
    connect(
        ((state, props) => ({
            reviewTopics: getReviewTopics(state, props),
        })), dispatchToProps)
)(EditLessonTopics);
