import React from "react";
import PropTypes from "prop-types";
import TagsEditor from "../../component/TagsEditor";
import TagsPopupList from "./TagsPopupList";
import { Icon } from "semantic-ui-react";
import TagsList from "./TagsList";

export default class Tags extends React.PureComponent {
    static propTypes = {
        tags: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        onSave: PropTypes.func.isRequired,
        onExternalSave: PropTypes.func,
        verbose: PropTypes.bool,
        canEdit: PropTypes.bool,
        showButton: PropTypes.bool,
        subjects: PropTypes.array,
    };

    state = {
        editation: false
    };

    handleEditation = e => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ editation: !this.state.editation });
    };

    handleSave = value => {
        const { onSave, onExternalSave, path } = this.props;
        onSave({ path, value: value });
        onExternalSave && onExternalSave(value);
        this.handleEditation();
    };

    getTagTitle = count => {
        return (
            <React.Fragment>
                <Icon name="tag" />
                {count ? count : "None"}
            </React.Fragment>
        );
    };

    render() {
        const { editation } = this.state;
        const { tags, verbose, canEdit = true, showButton, subjects } = this.props;
        const tagNames = Object.keys(tags);
        const trigger = <span>{this.getTagTitle(tagNames.length)}</span>;

        return (
            <span>
                {canEdit && editation && <TagsEditor
                    tagsList={subjects}
                    open={editation}
                    onSave={this.handleSave}
                    onCancel={this.handleEditation}
                    tagNames={tagNames}
                />}
                {editation ? (
                    trigger
                ) : verbose ? <TagsList tags={tags} onEdit={this.handleEditation} canEdit={canEdit} showButton={showButton}/> : (
                    <TagsPopupList
                        trigger={trigger}
                        onEdit={this.handleEditation}
                        tags={tags}
                        canEdit={canEdit}
                    />
                )}
            </span>
        );
    }
}

