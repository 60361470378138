import React from "react";
import PropTypes from "prop-types";
import { Segment, Button } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import AddNewProblemSet from "./AddNewProblemSet";
import AddExistingProblemSet from "./AddExistingProblemSet";
import ProblemSetLabel from "./component/ProblemSetLabel";
import ProblemSetContent from "./component/ProblemSetContent";

export default class LessonProblemSet extends React.PureComponent {
    static propTypes = {
        problemSet: PropTypes.object,
        lessonProblemSets: PropTypes.array,
        selectedLesson: PropTypes.object,
        createNewProblemSet: PropTypes.func,
        onSetProblemSet: PropTypes.func,
        outlineId: PropTypes.string,
        lessonId: PropTypes.string,
        collection: PropTypes.string,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        userRights: PropTypes.object,
        onShuffle: PropTypes.func,
        onEditProblemSet: PropTypes.func,
        onRemoveProblemSet: PropTypes.func,
        isShuffleFinished: PropTypes.bool,
        isAuthorAtLeast: PropTypes.bool,
        isSuperAuthorAtLeast: PropTypes.bool,
    };

    handleEditProblemSet = id => {
        const { onEditProblemSet, outlineId, lessonId, tabIndex } = this.props;
        onEditProblemSet({ id, outlineId, lessonId, tabIndex });
    };

    handleShuffle = () => {
        const { outlineId, lessonId, onShuffle, collection, problemSet: { id: problemSetId } } = this.props;
        onShuffle({ outlineId, lessonId, collection, problemSetId });
    };

    handleRemove = (id, deleteSet, deleteSetProblems) => {
        const { onRemoveProblemSet, outlineId, lessonId, collection } = this.props;
        onRemoveProblemSet({ id, outlineId, lessonId, collection, deleteSet, deleteSetProblems });
    };

    render() {
        const {
            problemSet,
            lessonProblemSets,
            selectedLesson,
            userRights,
            createNewProblemSet,
            outlineId,
            lessonId,
            tabIndex,
            onSetProblemSet,
            collection,
            isShuffleFinished,
            isAuthorAtLeast,
            isSuperAuthorAtLeast
        } = this.props;
        const entityName = collection === "homework" ? "Homework" : "Problem set";

        if (problemSet) {
            return (
                <Segment.Group style={{ overflowY: "auto", maxHeight: "70vh", minHeight: "20vh" }}>
                    <ProblemSetLabel
                        problemSet={problemSet}
                        userRights={userRights}
                        entityName={entityName}
                        onShuffle={this.handleShuffle}
                        onEditProblemSet={this.handleEditProblemSet}
                        onRemove={this.handleRemove}
                        isAuthorAtLeast={isAuthorAtLeast}
                        isSuperAuthorAtLeast={isSuperAuthorAtLeast}
                    />
                    <ProblemSetContent
                        cloning={problemSet.cloningInProgress === true}
                        collection={collection}
                        html={problemSet.html}
                        updating={isShuffleFinished}
                    />
                </Segment.Group>
            );
        }
        return (
            <NoDataPlaceholder icon="file outline" text={`Here we show preview of selected lesson ${entityName}`}>
                {!lessonProblemSets || 0 === lessonProblemSets.length ? (
                    <React.Fragment>
                        <p>Hurry up to select one of existing or create new problem set for this lesson.</p>
                        {(userRights && userRights.canEdit) && (
                            <Segment.Inline>
                                <AddExistingProblemSet
                                    entity={entityName}
                                    collection={collection}
                                    lessonId={lessonId}
                                    outlineId={outlineId}
                                    tabIndex={tabIndex}
                                    trigger={<Button primary content="Select existing" />}
                                />
                                <AddNewProblemSet
                                    selectedLesson={selectedLesson}
                                    entityName={entityName}
                                    createNewProblemSet={createNewProblemSet}
                                    onSetProblemSet={onSetProblemSet}
                                    outlineId={outlineId}
                                    lessonId={lessonId}
                                    collection={collection}
                                    tabIndex={tabIndex}
                                    lessonProblemSets={lessonProblemSets}
                                    showAsButton
                                />
                            </Segment.Inline>
                        )}
                    </React.Fragment>
                ) : (
                    <p>You can select one of current linked {entityName} or add the new one.</p>
                )}
            </NoDataPlaceholder>
        );
    }
}
