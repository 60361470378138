import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { firebaseConnect, withFirebase } from "react-redux-firebase";
import { onGenerateData } from "./GeneratesDataForiPlannerActions";
import { inProgress, getProgress, getAKitsOptions, getUsersOptions } from "./GeneratesDataForiPlannerSelectors";
import GeneratesDataForiPlanner from "./GeneratesDataForiPlanner";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { onSchoolLoad } from "../../External/SchoolList/schoolActions";
import { getSchoolAsOptions } from "../../External/SchoolList/schoolSelectors";
import { onCourseVariantLoad } from "../../External/CourseVariantList/CourseVariantsActions";
import { getAllCourseVariantsAsDropdownWithSporkId } from "../../External/CourseVariantList/courseVariantsSelectors";

const mapStateToProps = function(state) {
    return {
        inProgress: inProgress(state),
        progress: getProgress(state),
        kitOptions: getAKitsOptions(state),
        courseVariants: getAllCourseVariantsAsDropdownWithSporkId(state),
        selectedYearId: getSelectedYearId(state),
        schoolOptions: getSchoolAsOptions(state),
        usersOptions: getUsersOptions(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onCourseVariantLoad, onGenerateData, onSchoolLoad }, dispatch);
};

export default compose(
    withFirebase,
    firebaseConnect(["kits_data"]),
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(GeneratesDataForiPlanner);
