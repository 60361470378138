import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onConvertLessonData } from "./ConvertLessonDataActions";
import { inProgress, getProgress } from "./ConvertLessonDataSelectors";
import ConvertLessonData from "./ConvertLessonData";

const mapStateToProps = function(state) {
    return {
        inProgress: inProgress(state),
        progress: getProgress(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onConvertLessonData }, dispatch);
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(ConvertLessonData);
