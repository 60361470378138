import React from "react";
import PropTypes from "prop-types";
import UsedCount from "../../component/UsedCount";

const TextStyle = {
    padding: "0 1em"
};

export default class WidgetLessonsCount extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        usedBy: PropTypes.object,
        widgetId: PropTypes.string,
    };

    handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { usedBy, onClick, widgetId } = this.props;
        onClick && onClick(e, { usedBy, widgetId });
    }

    render() {
        const { usedBy, onClick } = this.props;
        const usedCount = usedBy ? Object.keys(usedBy).length : 0;
        return <UsedCount
            style={TextStyle}
            entityName="lesson"
            usedCount={usedCount}
            onClick={onClick && usedCount ? this.handleClick : null}
        />;
    }
}
