import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Select, Label, Tab, Message, Dropdown } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import { setTypes, allSetTypes } from "./problemSetTypes";
import memoizeOne from "memoize-one";
import TopicTreeSelector from "../../TopicCollection/TopicTreeSelector";

class ProblemSetWizard extends React.PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onEditSave: PropTypes.func,
        selectedProblems: PropTypes.object,
        problemSet: PropTypes.shape({
            title: PropTypes.string,
            notes: PropTypes.string,
            setType: PropTypes.string,
            tags: PropTypes.array,
            topics: PropTypes.object,
        }),
        editMode: PropTypes.bool,
        readOnlySetType: PropTypes.bool,
        tagOptions: PropTypes.array,
        onLessonEditSave: PropTypes.func,
        inLessonContext: PropTypes.bool,
        fbCollection: PropTypes.string.isRequired,
    };

    state = {
        title: this.props.problemSet && this.props.problemSet.title,
        notes: this.props.problemSet && this.props.problemSet.notes,
        setType: this.props.problemSet && this.props.problemSet.setType,
        tags: (this.props.problemSet && this.props.problemSet.tags) || [],
        topics: (this.props.problemSet && this.props.problemSet.topics) || {},
        errorMessage: null,
        tagOptions: undefined,
        entityName: this.props.fbCollection === "problemSet" ? "Problem Set" : "Comprehensive Test",
    };

    static getDerivedStateFromProps(props, state) {
        if (undefined === state.tagOptions && props.tagOptions) {
            return { tagOptions: props.tagOptions };
        }
        return null;
    }

    handleSave = e => {
        e.stopPropagation();
        const { onSave, onLessonEditSave, selectedProblems, onEditSave, editMode, problemSet } = this.props;
        const { tags, topics, title, notes, setType } = this.state;

        if (tags.length == 0 && Object.keys(topics || {}).length == 0) {
            //no topics or tags
            this.setState({ errorMessage: "Assign at least one tag or topic to the problem set." });
            return;
        } else {
            this.setState({ errorMessage: null });
        }

        const problemSetToSave = {
            ...problemSet,
            title,
            notes,
            tags,
            topics,
            setType,
            problems: Object.keys(selectedProblems || {}),
        };

        if (editMode === false) {
            onLessonEditSave
                ? onLessonEditSave({ ...problemSetToSave, createdDate: new Date(Date.now()).toISOString() })
                : onSave && onSave({ ...problemSetToSave, createdDate: new Date(Date.now()).toISOString() });
        } else if (editMode === true) onEditSave && onEditSave(problemSetToSave);

        this.setState({ tagOptions: undefined });
        this.props.onClose(true);
    };

    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectionChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    handleCancel = e => {
        e.stopPropagation();
        this.setState({ errorMessage: null, title: "", notes: "", setType: "hw", tags: [], tagOptions: undefined });
        this.props.onClose(false);
    };

    handleTagChange = (e, { value }) => {
        this.setState({ tags: value, errorMessage: null });
    };

    handleTagAdd = (e, { value }) => {
        const { tagOptions } = this.state;
        let tags = [...tagOptions, { key: value, value, text: value }];
        this.setState({ tagOptions: tags, errorMessage: null });
    };

    handleTopicChange = topics => {
        this.setState({ topics: { ...topics }, errorMessage: null });
    };

    generatePanes = () => {
        const { selectedProblems, editMode, readOnlySetType, onLessonEditSave, inLessonContext, fbCollection } = this.props;
        const { entityName, tags, topics, title, notes, setType, tagOptions } = this.state;

        return [
            this.firstPane(entityName, title, notes, setType, selectedProblems, readOnlySetType, editMode, inLessonContext || !!onLessonEditSave),
            this.secondPane(tags, topics, tagOptions, fbCollection !== "problemSet"),
        ];
    };

    firstPane = memoizeOne((entityName, title, notes, setType, selectedProblems, readOnlySetType, editMode, inLessonContext) => {
        return {
            menuItem: entityName,
            pane: (
                <Tab.Pane key="psPane">
                    <Form>
                        <Form.Field required>
                            <label>Title</label>
                            <input
                                name="title"
                                autoFocus
                                placeholder="Title"
                                value={title}
                                onChange={this.handleFieldChange}
                                disabled={this.props.fbCollection !== "problemSet"}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Notes</label>
                            <textarea
                                rows="5"
                                name="notes"
                                placeholder="Notes"
                                value={notes}
                                onChange={this.handleFieldChange}
                            />
                        </Form.Field>
                        {this.props.fbCollection === "problemSet" && <Form.Field required>
                            <label>Set Type</label>
                            <Select
                                name="setType"
                                placeholder="Set Type"
                                options={inLessonContext ? setTypes : allSetTypes}
                                // ESLint false positive: `setType` is not handler but data value
                                // eslint-disable-next-line react/jsx-handler-names
                                value={setType}
                                disabled={readOnlySetType}
                                onChange={this.handleSelectionChange}
                            />
                        </Form.Field>}
                        {!editMode && (
                            <Form.Field>
                                <Label>
                                    {Object.keys(selectedProblems || {}).length} problems will be included in this set.
                                </Label>
                            </Form.Field>
                        )}
                    </Form>
                </Tab.Pane>
            ),
        };
    });

    secondPane = memoizeOne((tags, topics, tagOptions, readOnlyTopics) => {
        return {
            menuItem: "Tags & Topics",
            pane: (
                <Tab.Pane key="settingsPane">
                    <Form>
                        <Form.Field>
                            <label>Tags</label>
                            <Dropdown
                                placeholder="Select a tag"
                                fluid
                                multiple
                                search
                                selection
                                options={tagOptions || []}
                                loading={tagOptions === undefined}
                                onChange={this.handleTagChange}
                                onAddItem={this.handleTagAdd}
                                value={tags || []}
                                allowAdditions
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Topics</label>
                            {readOnlyTopics ?
                                <TopicTreeSelector
                                    selectedTopics={topics || {}}
                                    single
                                    filterOnly={topics || {}}
                                /> :
                                <TopicTreeSelector
                                    onChange={this.handleTopicChange}
                                    selectedTopics={topics || {}}
                                    enableLessonFilter
                                />}
                        </Form.Field>
                    </Form>
                </Tab.Pane>
            ),
        };
    });

    render() {
        const { editMode, open } = this.props;
        const { title, errorMessage, entityName } = this.state;

        return (
            <React.Fragment>
                <Modal open={open} centered={false}>
                    <Modal.Header>{editMode ? "Edit" : "Create New"} {entityName}</Modal.Header>
                    <Modal.Content>
                        <Tab renderActiveOnly={false} panes={this.generatePanes()} />
                        {errorMessage && <Message error content={errorMessage} />}
                    </Modal.Content>
                    <Modal.Actions>
                        <SaveCancelButtons
                            saveDisabled={!title || title.trim().length == 0}
                            onSave={this.handleSave}
                            onCancel={this.handleCancel}
                        />
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}

ProblemSetWizard.defaultProps = {
    problemSet: {
        title: "",
        notes: "",
        setType: "hw",
        tags: [],
    },
    editMode: false,
    readOnlySetType: false,
};

export default ProblemSetWizard;
