import { handleDraftEditorPastedText } from "draftjs-conductor";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState, SelectionState, Modifier } from "draft-js";

import { ENTITY_TYPE, IMAGE_PLACEHOLDER_CHAR, MATHML_SOURCE_ATTRIBUTE } from "./constants";
import { getNodePositionInBlock, BLOCK_POSITION } from "./utils/domUtils";
import { preprocessHtml } from "./utils/htmlPreProcessor";
import FormulaRenderer from "../export/FormulaRenderer";
import { getFilenameForStorage } from "./utils/utils";
import firebase from "../../../firebase";


const getFormulaEntity = (formula, node) => {
    return {
        type: ENTITY_TYPE.MATH,
        mutability: "IMMUTABLE",
        data: {
            formula,
            _position: getNodePositionInBlock(node),
        },
    };
};

const customChunkRenderer = (nodeName, node) => {

    if (nodeName === "img" || nodeName === "svg") {
        const { alt, dataset, attributes: { width, height } } = node;
        const formula = dataset && dataset.mathml;
        let src = node.src;

        if (formula) {
            return getFormulaEntity(formula, node);
        }

        if (nodeName === "svg") {
            // https://stackoverflow.com/questions/24337271/base64-svg-html-image-is-not-displayed
            node.setAttribute("xmlns", "http://www.w3.org/2000/svg");
            src = "data:image/svg+xml," + encodeURIComponent(node.outerHTML);
        } else {
            if (src.startsWith("data:image")) {
                const suffix  = (src.replace(/data:image\//, "")).replace(/;base64.*/, "");
                const storageName = getFilenameForStorage(`embedded.${suffix}`);
                const storagePath = `widgetLibrary/images/${storageName}`;
                const fileRef = firebase.getFirebaseFile(storagePath);
                fileRef.putString(src.replace(/.*base64,/, ""), "base64");
                return {
                    type: ENTITY_TYPE.IMAGE,
                    mutability: "MUTABLE",
                    data: { src: { storagePath } , alt, imported: true, maxWidth: "100%", height: height ? height.value : "auto", width: width ? width.value : "auto" },
                };
            }
        }

        return {
            type: ENTITY_TYPE.IMAGE,
            mutability: "MUTABLE",
            data: { src, alt, maxWidth: "100%", height: height ? height.value : "auto", width: width ? width.value : "auto" },
        };
    }
    if (nodeName === "math") {
        const formula = node.getAttribute(MATHML_SOURCE_ATTRIBUTE) || node.outerHTML;
        return getFormulaEntity(formula, node);
    }
    if (nodeName === "table") {
        const tableContent = node.outerHTML;
        return {
            type: ENTITY_TYPE.TABLE_READONLY,
            mutability: "IMMUTABLE",
            data: {
                tableContent,
            },
        };
    }
};

const getFormulasRanges = (contentBlock, contentState) => {
    const ranges = [];

    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.MATH;
    }, (start, end) => ranges.push({ start, end }));

    return ranges;
};

/**
 * Sets IMAGE_PLACEHOLDER_CHAR instead of the current character for `formula` entity.
 */
const normalizeBlockWithFormulas = (block, contentState) => {
    const blockKey = block.getKey();
    let blockContentState = ContentState.createFromBlockArray([block], contentState.getEntityMap());
    let selectionState = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: 0,
        focusKey: blockKey,
        focusOffset: block.getLength(),
    });

    const formulaRanges = getFormulasRanges(block, blockContentState);

    // ContentBlock with formula is created as "atomic" by htmlToDraft.
    blockContentState = Modifier.setBlockType(blockContentState, selectionState, "unstyled");

    formulaRanges.forEach(({ start, end }) => {
        const entityKey = block.getEntityAt(start);
        blockContentState = Modifier.replaceText(
            blockContentState,
            selectionState.merge({
                anchorOffset: start,
                focusOffset: end,
            }),
            IMAGE_PLACEHOLDER_CHAR,
            null,
            entityKey
        );
    });

    return blockContentState.getBlockForKey(blockKey);
};

const normalizeContentBlocks = (contentBlocks, entityMap) => {
    const normalizedBlocks = [];
    let newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    contentBlocks.forEach((block) => {
        const formulaRanges = getFormulasRanges(block, newContentState);

        if (formulaRanges.length > 0) {
            block = normalizeBlockWithFormulas(block, newContentState);

            if (formulaRanges.length > 1) {
                // eslint-disable-next-line no-console
                console.error("There should be max. one formula in the ContentBlock created by customChunkRenderer! Currently", formulaRanges.length);
            }

            const { start } = formulaRanges[0];
            const formulaEntity = newContentState.getEntity( block.getEntityAt(start) );

            if ([BLOCK_POSITION.STANDALONE, BLOCK_POSITION.AT_BEGIN].includes(formulaEntity.getData()._position)) {
                // Here could be an additional empty block before the formula when the last character before
                // the formula was NEW_LINE or formula is encapsulated with <p> (in the original source). Such block will be removed.
                const count = normalizedBlocks.length;
                let previousBlock = count && normalizedBlocks[count - 1];

                // remove all previous empty blocks
                // solves issue with <li><p><mathml/></p></li> which results in three blocks
                // while we only need two
                while (previousBlock && previousBlock.getLength() === 0 && previousBlock.getType() === "unstyled") {
                    normalizedBlocks.length = normalizedBlocks.length - 1;
                    previousBlock = normalizedBlocks[normalizedBlocks.length - 1];
                }
            }
        }

        normalizedBlocks.push( block );
    });

    return normalizedBlocks;
};

/**
 * `customChunkRenderer` for "html-to-draftjs" package currently supports atomic blocks only.
 * When MathML (`formula`) is detected in our `pastedTextConvertor` function, a new atomic block is created
 * with the `formula` at the beginning of the block, followed by text (if exists) till the next `formula`.
 * Than each formula would be rendered at standalone line and the related entity would be tied
 * (internally in contentState) with SPACE character instead of required IMAGE_PLACEHOLDER_CHAR.
 *
 * Additionally, it also handled <ul> and <ol> blocks which contains another block. This result in two blocks
 * (first one empty with zero length and second one with actual data) rendering on two lines. This function detect
 * and fix such situation by merging following block into empty "(un)ordered-list-item" previous block.
 *
 * This helper merges such ContentBlocks to a minimal amount of blocks.
 * Internal property from entity data `_position` (set in `customChunkRenderer`) is used for controlling
 * blocks boundaries.
 */
const mergeContentBlocks = (contentBlocks, entityMap) => {
    let [targetBlock, ...otherBlocks] = contentBlocks;
    let targetBlockKey = targetBlock.getKey();
    let newContentState = ContentState.createFromBlockArray([targetBlock], entityMap);

    let mergeNextBlock = false;

    otherBlocks.forEach((block) => {
        let blockContentState = ContentState.createFromBlockArray([block], entityMap);
        const formulaRanges = getFormulasRanges(block, newContentState);
        const blockLength = newContentState.getBlockForKey(targetBlockKey).getLength();
        const selectionState = new SelectionState({
            anchorKey: targetBlockKey,
            anchorOffset: blockLength,
            focusKey: targetBlockKey,
            focusOffset: blockLength,
        });

        let appendBlock = true;

        // process formula blocks
        if (formulaRanges.length) {
            const { start } = formulaRanges[0];
            const entityKey = block.getEntityAt(start);
            const formulaEntity = blockContentState.getEntity(entityKey);
            const entityData = formulaEntity.getData();

            if ([BLOCK_POSITION.SURROUNDED, BLOCK_POSITION.AT_END].includes(entityData._position)) {
                const fragment = blockContentState.getBlockMap();
                // Merge the current block with the previous one
                newContentState = Modifier.replaceWithFragment(
                    newContentState,
                    selectionState,
                    fragment
                );
                appendBlock = false;
            }

            delete entityData._position;  // removes helper property
            newContentState = newContentState.replaceEntityData(entityKey, entityData);
        }

        // when the previous block was empty (un)ordered list, this block should merge
        if (mergeNextBlock && block.getType() === "unstyled") {
            const fragment = blockContentState.getBlockMap();
            // Merge the current block with the previous one
            newContentState = Modifier.replaceWithFragment(
                newContentState,
                selectionState,
                fragment
            );
            appendBlock = false;
            mergeNextBlock = false;
        }

        if (appendBlock) {
            // if the block we're adding is empty (un)ordered list, the next block should be merged with this one
            if (block.getLength() === 0 && (block.getType() === "ordered-list-item" || block.getType() === "unordered-list-item")) {
                mergeNextBlock = true;
            }

            const newBlocks = newContentState.getBlocksAsArray();
            newBlocks.push(block);
            newContentState = ContentState.createFromBlockArray(newBlocks, entityMap);
            targetBlockKey = block.getKey();
        }
    });

    return newContentState.getBlocksAsArray();
};

const generateSvgForFormulas = async (contentState) => {
    const formulaEntities = [];

    contentState.getBlocksAsArray().forEach((block) => {
        block.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            if (entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.MATH) {
                formulaEntities.push({
                    entityKey,
                    formula: contentState.getEntity(entityKey).getData().formula,
                });
            }
            return false;  // no callback for findEntityRanges is required
        });
    });

    if (formulaEntities.length) {
        // C&P from external source doesn't currently support nested blocks (SNIPPET, TABLE),
        // so processing only top level formula entities is enough.
        const formulas = formulaEntities.map(({ formula }) => formula);
        const formulaRenderer = new FormulaRenderer([...new Set(formulas)]);
        const processedFormulas = await formulaRenderer.processFormulas();

        formulaEntities.forEach(({ entityKey, formula }) => {
            contentState = contentState.mergeEntityData(entityKey, { svg: processedFormulas[formula] });
        });
    }

    return contentState;
};

const pastedTextConvertor = (onChange) => async (text, html, editorState) => {
    // `await` at this point is ugly hotfix - `handleDraftEditorPastedText` itself is not async,
    // but it allows (because it "defers" execution) to store editorState into React component state
    // (see registerGetContentOnCopy) which is used by `handleDraftEditorPastedText`.
    //
    // TODO pbenes: try to find more straightforward solution.
    let newState = await handleDraftEditorPastedText(html, editorState);
    if (newState) {
        // C&P inside this editor or between two instances of the same editor.
        onChange(newState);
        return true;
    }

    // Paste from external source
    const blocksFromHtml = htmlToDraft(preprocessHtml(html || text), customChunkRenderer);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const normalizedBlocks = normalizeContentBlocks(contentBlocks, entityMap);
    const mergedBlocks = mergeContentBlocks(normalizedBlocks, entityMap);
    let contentState = ContentState.createFromBlockArray(mergedBlocks, entityMap);
    contentState = await generateSvgForFormulas(contentState);
    const fragment = contentState.getBlockMap();
    const content = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        fragment,
    );

    newState = EditorState.push(editorState, content, "insert-fragment");
    onChange(newState);
    return true;
};

export default pastedTextConvertor;

export const registerGetContentOnCopy = (ref, getContent) => {
    // see comment in pastedTextConvertor
    // inspired by https://github.com/thibaudcolas/draftjs-conductor/blob/1e7763652701a13e61c088dedf3273f868a1e10d/src/lib/api/copypaste.js#L57
    const editorElt = ref.editor;
    const onCopy = () => getContent();

    editorElt.addEventListener("copy", onCopy);
    editorElt.addEventListener("cut", onCopy);

    return {
        unregister() {
            editorElt.removeEventListener("copy", onCopy);
            editorElt.removeEventListener("cut", onCopy);
        },
    };
};
