const TYPES = {
    GENERATE_PROBLEM_SET_DATA: "GENERATE_PROBLEM_SET_DATA",
    GENERATE_PROBLEM_SET_DATA_FINISHED: "GENERATE_PROBLEM_SET_DATA_FINISHED",
    GENERATE_PROBLEM_SET_DATA_PROGRESS: "GENERATE_PROBLEM_SET_DATA_PROGRESS",
};

const onGenerateProblemSetData = () => ({
    type: TYPES.GENERATE_PROBLEM_SET_DATA,
});

const onUpdateProgress = payload => ({
    type: TYPES.GENERATE_PROBLEM_SET_DATA_PROGRESS,
    payload,
});

const onFinished = () => ({
    type: TYPES.GENERATE_PROBLEM_SET_DATA_FINISHED,
});

export {
    TYPES,
    onGenerateProblemSetData,
    onFinished,
    onUpdateProgress,
};
