import { all, put, takeEvery } from "redux-saga/effects";
import firebase from "../../../../firebase";
import { TYPES, onSetSection } from "./SectionListItemActions";
import { toastError } from "../../../../component/toast";

function* loadSection({ payload }) {
    try {
        const section = yield firebase.tabletProxy({
            method: "GET",
            uri: `/section/${payload}?with_details=1`,
        });
        yield put(onSetSection(section.data));
    } catch (err) {
        toastError({ code: err.details.name, header: "Failed to load section details for section ID " + payload, message: err.details.message });
    }
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.SECTION_LIST_LOAD_SECTION, loadSection),
    ]);
}
