import React from "react";
import PropTypes from "prop-types";

import ColorSelection from "../../component/ColorSelection";
import { buildBackgroundColors } from "./options";

export default class Background extends React.PureComponent {
    static propTypes = {
        backgroundColor: PropTypes.string,
        onChange: PropTypes.func.isRequired
    };

    handleColorSelect = (e, { value }) => {
        const { onChange } = this.props;
        onChange && onChange("color", value );
    };

    render() {
        const { backgroundColor } = this.props;
        return (
            <ColorSelection
                colors={buildBackgroundColors(backgroundColor)}
                defaultValue={backgroundColor}
                label=""
                placeholder="No color"
                onChange={this.handleColorSelect}
            />
        );
    }
}
