import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onSaved } from "./ResourceNameActions";
import { toastError, DB_ERROR } from "../../../../component/toast";
import { onPatchResourceData } from "../../ResourcesActions";
import { updateResource } from "../../ResourcesApi";

function* saveName ({ payload: { resourceId, name } }) {
    try {
        const resource = yield updateResource(resourceId, { name });
        yield put(onPatchResourceData({
            resourceId,
            newData: resource.data,
            fieldsToUpdate: ["name"]
        }));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update name of the resource", message: DB_ERROR });
    }
    yield put(onSaved());
}


export default function* resourceNameSaga() {
    yield all([
        takeLatest(TYPES.RESOURCES_NAME_SAVE, saveName),
    ]);
}
