import React, { Component } from "react";
import PropTypes from "prop-types";

import FormulaSource from "./FormulaSource";
import { normalizeSvg, waitForSvgRendererReady } from "../utils/domUtils";

class FormulaDecorator extends Component {
    static propTypes = {
        contentState: PropTypes.object.isRequired,
        entityKey: PropTypes.string.isRequired,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        isParentReadOnly: PropTypes.func.isRequired,
        getEditorState: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        children: PropTypes.arrayOf(PropTypes.node).isRequired,
    };

    state = {
        openSourceEditor: false,
        isReady: false,
    };

    rendererRef = React.createRef();

    componentDidMount = async () => {
        await waitForSvgRendererReady();
        this.setState({ isReady: true });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { contentState, entityKey } = this.props;
        const { isReady, openSourceEditor } = this.state;
        const { contentState: nextContentState, entityKey: nextEntityKey } = nextProps;
        const { formula } = contentState.getEntity(entityKey).getData();
        const { formula: nextFormula } = nextContentState.getEntity(nextEntityKey).getData();

        return formula !== nextFormula || openSourceEditor !== nextState.openSourceEditor || isReady !== nextState.isReady;
    }

    handleOpenFormulaEditor = (e) => {
        const { isParentReadOnly, reviewMode } = this.props;

        if (isParentReadOnly() || reviewMode) {
            return;
        }

        e.preventDefault();
        this.setState({ openSourceEditor: true });
    };

    handleClose = () => {
        this.setState({ openSourceEditor: false });
    };

    renderEditor = () => {
        const { contentState, entityKey, getEditorState, setEditorState, enableParentEditor, disableParentEditor, onDirty } = this.props;
        return (<FormulaSource
            entityKey={entityKey}
            entity={contentState.getEntity(entityKey)}
            editorState={getEditorState()}
            enableParentEditor={enableParentEditor}
            disableParentEditor={disableParentEditor}
            setEditorState={setEditorState}
            onDirty={onDirty}
            onClose={this.handleClose}
        />);
    };

    /**
     * Failed designs:
     *   1. render SVG directly through dangerouslySetInnerHTML
     *      - no space for caret, at least space after image hast to be added programmatically
     *      - caret cannot be place before image
     *      - image cannot be selected
     *   2. render SVG directly through dangerouslySetInnerHTML, surrounded by ZWS char
     *      - a little bit better than above, caret can be placed both before and after the img (not in Firefox)
     *   3. keep Draft rendering `<span data-offset-key=...><span  data-text="true">` and then `<svg ...>`
     *      - img can be selected
     *      - caret can be placed before img (but only with special effort)
     *      - even though the caret is after img, newly entered chars are written before
     *   4. render SVG as background image (the current approach, but there are mentioned failed variants)
     *      - because it is not possible to set width to inline element (span), some indirect hack can be used
     *      - padding - caret jumps on the placeholder character (which is usually narrower then background image)
     *      - ::before {padding} - caret jumps correctly, but selection is wrong (the placeholder only, because content
     *        of pseudo-element is not in DOM)
     *
     * Solution:
     *   Formula is rendered as `EM SPACE` char (\u2003) - its width is equal to font-size => caret and selection behave correctly.
     *   SVG representing the formula is used as a background image for this char (it has to be styled according to image dimension,
     *   see related CSS).
     */
    render() {
        const { contentState, entityKey } = this.props;
        const { svg } = contentState.getEntity(entityKey).getData();

        if (!this.state.isReady) {
            return null;
        }

        if (!svg) {
            // TODO pbenes: for backward compatibility during development. It should be removed before production release.
            return (<span
                onDoubleClick={this.handleOpenFormulaEditor}
                style={{ color: "white", backgroundColor: "red", padding: "4px", borderRadius: "3px", fontWeight: "bold" }}
            >
                old format: edit-save
                {this.state.openSourceEditor && this.renderEditor()}
            </span>);
        }

        const { width, height, svgHtml } = normalizeSvg(svg);

        return (<span
            className="formulaDecorator inlineImage"
            onDoubleClick={this.handleOpenFormulaEditor}
            ref={this.rendererRef}
            style={{
                "--backgroundImage": `url("data:image/svg+xml,${encodeURIComponent(svgHtml)}")`,
                "--width": width,
                "--height": height,
            }}
        >
            {this.props.children}
            {/**
              * Following block should be an equivalent of rendering `this.props.children` if we don't want to convert existing data,
              * but native rendering of children is safer and recommended (https://reactrocket.com/post/draft-js-best-practices/).
              */}
            {/* <span data-offset-key={this.props.children[0].props.offsetKey}>
                <span data-text="true">
                    {IMAGE_PLACEHOLDER_CHAR}
                </span>
            </span> */}
            {this.state.openSourceEditor && this.renderEditor()}
        </span>);
    }
}

export default FormulaDecorator;
