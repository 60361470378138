import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BookEditor from "../../component/seamlessEditor/bookEditor";
import { Segment } from "semantic-ui-react";
import { WeakDefinitionError } from "./component/WeakDefinitionError";
import SharedToolbar from "../../component/seamlessEditor/bookEditor/SharedToolbar";
import SaveCancelInToolbar from "../WidgetLibrary/WidgetEditor/SaveCancelInToolbar";

export default class GlossaryEditor extends PureComponent {
    static propTypes = {
        glossaryId: PropTypes.string,
        selectedTerm: PropTypes.object,
        onUpdateTerm: PropTypes.func,
        onClose: PropTypes.func,
        /** the following two props are for cases when we want to use the editor, but handle Save ownselves */
        registerGetContentFn: PropTypes.func,
        showSaveCancel: PropTypes.bool,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
    };

    state = {
        weakDefinitionError: false,
        toolbarRef: null,
    };

    toolbarRef = React.createRef();

    componentDidMount = () => {
        this.props.onSetDirty(false);
        if (this.toolbarRef && this.toolbarRef.current) {
            const sharedToolBar = new SharedToolbar();
            sharedToolBar.mount(this.toolbarRef.current);
            this.setState({ toolbarRef: sharedToolBar });
        }
    };

    componentWillUnmount () {
        this.props.onSetDirty(false);
    }

    registerGetContentFn = fn => {
        this.getEditorContentFn = fn;
        this.props.registerGetContentFn && this.props.registerGetContentFn(fn);
    };

    handleDirty = () => {
        this.props.onSetDirty(true);
    };

    handleClose = () => {
        const { onClose, onSetDirty } = this.props;
        onSetDirty(false);
        onClose();
    }

    handleSave = async () => {
        const { glossaryId, onUpdateTerm, onClose, onSetDirty } = this.props;
        const content = this.getEditorContentFn();
        if (content.html && 20 < content.html.length) {
            onUpdateTerm({ glossaryId, content });
            onSetDirty(false);
            onClose();
        } else {
            this.setState({ weakDefinitionError: true });
        }
    };

    render() {
        const { selectedTerm, showSaveCancel, isDirty } = this.props;
        const { weakDefinitionError, toolbarRef } = this.state;
        return (
            <React.Fragment>
                <div className="toolbarArea">
                    <div className="widgetEditorToolbar" ref={this.toolbarRef} />
                    {showSaveCancel && toolbarRef && isDirty ? (
                        <SaveCancelInToolbar onSave={this.handleSave} onCancel={this.handleClose} />
                    ) : <div />}
                </div>
                {toolbarRef && (
                    <Segment style={{ minHeight: "250px", maxHeight: "60vh", overflowY: "auto" }}>
                        <BookEditor
                            features={["BOLD", "ITALIC", "NORMAL", "H1", "H2", "H3", "UL", "OL", "MATH", "MATHTEXT", "HELP"]}
                            onDirty={this.handleDirty}
                            registerGetContentFn={this.registerGetContentFn}
                            sharedToolbar={toolbarRef}
                            initialContentState={selectedTerm && selectedTerm.content && selectedTerm.content.raw}
                            initialHtml={selectedTerm && selectedTerm.content && selectedTerm.content.html}
                        />
                    </Segment>
                )}
                {weakDefinitionError && <WeakDefinitionError />}
            </React.Fragment>
        );
    }
}
