import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, Form, Message, Grid, Label } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import TemplatePreview from "./TemplatePreview";
import { onSelectTopic } from "./CreateWidgetDialogActions";
import { selectedTopics } from "./CreateWidgetDialogSelectors";
import { getTemplatesAsOptions, getTemplatesAsOptions4Lesson } from "../../WidgetTemplates/WidgetTemplatesSelectors";
import TopicTreeSelector from "../../../TopicCollection/TopicTreeSelector";

import "./CreateWidgetDialog.css";
import ActionLink from "../../../component/ActionLink";

class CreateWidgetDialog extends React.PureComponent {
    static propTypes = {
        onCreate: PropTypes.func,
        templateOptions: PropTypes.array,
        selectedLesson: PropTypes.object,
        onSelectTopic: PropTypes.func,
        preselectTopics: PropTypes.object,
        trigger: PropTypes.node,
        selectedTopics: PropTypes.object,
    };

    state = {
        open: false,
        templateId: null,
        name: "",
        errorMessage: null,
    };

    errors = [];

    validateForm = () => {
        this.errors = [];
        const { name, templateId } = this.state;

        if (!name || !name.trim()) {
            this.errors.push("name");
        }
        if (!templateId) {
            this.errors.push("templateId");
        }
    };

    handleOpen = () => {
        const { onSelectTopic, preselectTopics = {} } = this.props;
        this.setState({ open: true, templateId: null, name: "", errorMessage: null });
        onSelectTopic(preselectTopics);
        this.errors = [];
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSave = () => {
        const { templateId, name } = this.state;
        const { selectedTopics } = this.props;
        this.validateForm();
        //there are some errors in the form
        if (this.errors.length !== 0) {
            this.setState({ errorMessage: "There are some validation errors. Please fill all required values." });
            return;
        }
        this.props.onCreate({ name, templateId, topics: selectedTopics });
        this.handleClose();
    };

    handleChange = (e, { name, value }) => {
        this.errors = this.errors.filter(error => error != name);
        this.setState({ [name]: value });
        if ("templateId" === name) {
            this.setState({
                name: this.props.templateOptions.filter(i => {
                    return i.value === value;
                })[0].text,
            });
        }
    };

    render() {
        const { open, templateId, errorMessage, name } = this.state;
        const { templateOptions, selectedLesson, trigger, onSelectTopic, selectedTopics, preselectTopics } = this.props;

        return (
            <Modal
                size="large"
                trigger={
                    trigger ? trigger
                        : (
                            <ActionLink
                                horizontal
                                icon="plus"
                                content="Create new widget"
                                title="Click here to add a new widget based on selected template."
                            />

                        )
                }
                open={open}
                onOpen={this.handleOpen}
            >
                <Modal.Header>
                    {selectedLesson && selectedLesson.name
                        ? `Add new widget to ${selectedLesson.name}`
                        : "Create new widget"}
                </Modal.Header>
                <Modal.Content>
                    <Grid columns={2}>
                        <Grid.Column width={6}>
                            <div style={{ display: "flex", alignItems: "center", paddingBottom: "1em" }}>
                                <Label style={{ float: "left", margin: "0.3em" }} size="big" circular>
                                    1
                                </Label>
                                <p>Select a template for your new widget and give the widget a nice name.</p>
                            </div>
                            <Form
                                onSubmit={this.handleSave}
                                {...(this.errors.length > 0 || errorMessage) && { error: true }}
                            >
                                <Form.Dropdown
                                    name="templateId"
                                    label="Template"
                                    required
                                    placeholder="Select widget template"
                                    selection
                                    search
                                    options={templateOptions}
                                    onChange={this.handleChange}
                                    {...this.errors.indexOf("templateId") != -1 && {
                                        error: true,
                                        placeholder: "Please select a template",
                                    }}
                                />
                                <Form.Input
                                    name="name"
                                    label="Name"
                                    required
                                    placeholder="Fill widget name"
                                    value={name}
                                    disabled={!templateId}
                                    onChange={this.handleChange}
                                    {...this.errors.indexOf("name") != -1 && {
                                        error: true,
                                        placeholder: "Please enter a name",
                                    }}
                                    autoFocus
                                />
                                <Message error>{errorMessage}</Message>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Form>
                                <Form.Field>
                                    <label>Preview</label>
                                    <TemplatePreview templateId={templateId} />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Label style={{ float: "left", margin: "0.3em" }} size="big" circular>
                            2
                        </Label>
                        <p>Assign selected topics to the widget.</p>
                    </div>
                    <TopicTreeSelector
                        onChange={onSelectTopic}
                        selectedTopics={preselectTopics || selectedTopics}
                        {...selectedLesson && { filterOnly: selectedLesson.topics } || { enableLessonFilter: true }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons submitText="Create" onSave={this.handleSave} onCancel={this.handleClose} />
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({
    selectedTopics: selectedTopics(state),
    templateOptions: props.inLessonContext ? getTemplatesAsOptions4Lesson(state) : getTemplatesAsOptions(state),
});

const dispatchToProps = dispatch => bindActionCreators({
    onSelectTopic
}, dispatch);

export default connect(
    mapStateToProps,
    dispatchToProps
)(CreateWidgetDialog);
