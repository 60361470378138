import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Segment, Label, Icon } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";

export default class FileItemEditor extends PureComponent {
    static propTypes = {
        itemId: PropTypes.string.isRequired,
        widgetId: PropTypes.string.isRequired,
        content: PropTypes.object,
        isSaving: PropTypes.bool.isRequired,
        onUpdateFileItem: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    state = {
        content: this.props.content,
        newFile: null,
        isDirty: false,
    };

    handleCancel = () => {
        const { widgetId, itemId, onCancel } = this.props;
        onCancel(widgetId, itemId);
    };

    handleChange = (e, { name, value }) => {
        const { content } = this.state;
        const changes = {
            content: { ...content, [name]: value },
            isDirty: true,
        };
        this.setState(changes);
    };

    handleSelectFile = (e) => {
        const { content } = this.state;
        const newFile = e.target.files[0];
        const changes = {
            content: { ...content, name: newFile.name }, // use the file name for link
            newFile,
            isDirty: true,
        };
        // We should set the link name only once instead of every file upload.
        // if (!content || !content.name) {
        //     // Initialize name of link if it has not been set yet
        //     changes.content = { ...content, name: newFile.name };
        // }
        this.setState(changes);
    };

    handleSave = () => {
        const { content, newFile } = this.state;
        const { itemId, widgetId, onUpdateFileItem } = this.props;
        if (newFile) {
            // ensure upload of the new file and remove of the old one
            onUpdateFileItem({ widgetId, itemId, content, newFile });
        } else {
            onUpdateFileItem({ widgetId, itemId, content });
        }
    };

    render() {
        const { isSaving } = this.props;
        const { content, newFile, isDirty } = this.state;
        return (
            <Segment loading={isSaving}>
                <Label attached="top">File Item Editor</Label>
                <Form onSubmit={this.handleSave}>
                    {content && content.fileName && (
                        <span>
                            <b>Current file </b>: {content.fileName}
                        </span>
                    )}
                    <Form.Field />
                    <label htmlFor="attachmentName" style={{ cursor: "pointer" }}>
                        <Icon name="upload" color="grey" />
                        <b>{content && content.fileName ? "New version to upload" : "File to upload"}</b>
                        <input
                            type="file"
                            onChange={this.handleSelectFile}
                            className="inputfile"
                            id="attachmentName"
                            name="attachmentName"
                            style={{ display: "none" }}
                        />
                    </label>
                    &nbsp;
                    {newFile && `: ${newFile.name}`}
                    <Form.Field />
                    <Form.Input
                        label="File Link Text"
                        name="name"
                        placeholder="Fill name of file link"
                        disabled={!(newFile) && !(content && content.href)}
                        value={content && content.name ? content.name : ""}
                        onChange={this.handleChange}
                    />
                    {/* <Form.Input
                        label="Tooltip"
                        name="title"
                        placeholder="Optionally fill link tooltip, if should be different than file link name"
                        disabled={!(newFile) && !(content && content.href)}
                        value={content && content.title ? content.title : ""}
                        onChange={this.handleChange}
                    /> */}
                </Form>
                <SaveCancelButtons
                    onSave={this.handleSave}
                    saveDisabled={!isDirty}
                    onCancel={this.handleCancel}
                    size="mini"
                    padded
                />
            </Segment>
        );
    }
}
