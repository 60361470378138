import React from "react";
import PropTypes from "prop-types";
import { List, Icon, Popup } from "semantic-ui-react";
import { ConfirmDeletePopup } from "bmd-react";
import UsedCount from "../component/UsedCount";
import TagsContainer from "../Tags/TagsContainer";
import "./WidgetTemplateList.css";

export default class WidgetTemplateList extends React.PureComponent {
    static propTypes = {
        widgetTemplates: PropTypes.array,
        onDeleteTemplate: PropTypes.func.isRequired,
        isSuperAuthorAtLeast: PropTypes.bool,
        onSelectTemplate: PropTypes.func.isRequired
    };

    render() {
        const { widgetTemplates, onDeleteTemplate, isSuperAuthorAtLeast: canEdit, onSelectTemplate } = this.props;

        return (
            <React.Fragment>
                <List divided>
                    {widgetTemplates && widgetTemplates.map(t =>
                        <TemplateListEntry
                            key={t.key}
                            template={t}
                            onClick={onSelectTemplate}
                            onDelete={onDeleteTemplate}
                            canEdit={canEdit}
                        />
                    )}
                </List>
            </React.Fragment>
        );
    }
}

class TemplateListEntry extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        template: PropTypes.object.isRequired,
        onDelete: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
    };

    handleDelete = (callback) => {
        callback();
        this.props.onDelete({key: this.props.template.key});
    }

    render() {
        const { template, onClick, canEdit } = this.props;
        return (
            <List.Item onClick={onClick} data={template.key} className="editable">
                <List.Header as="a" className="widgetList library headerItem widgetLabel">
                    <span className="limitedTextWidth" title={template.name}>{template.name}</span>

                    {canEdit ? !template.used ? <ConfirmDeletePopup
                        position="right center"
                        trigger={<Icon name="trash alternate outline" link className="actionIconClass right" title="Remove the widget template"/>}
                        whatText="widget template"
                        onConfirm={this.handleDelete} /> :
                        <Popup 
                            trigger={<Icon.Group className="actionIconClass right">
                                <Icon name="dont" size="large" disabled fitted/>
                                <Icon name="trash alternate outline" disabled />
                            </Icon.Group>}
                        >
                            <Icon name="info circle" color="grey"/>The template cannot be removed because it is already used in some widget.
                        </Popup> : <span />
                    }
                </List.Header>
                <List.Content className="widgetTemplateListContent">
                    <UsedCount entityName="widget" usedCount={template.used ? Object.keys(template.used).length : 0} />
                    <TagsContainer tags={template.tags || {}} canEdit={canEdit} verbose path={`widgetTemplates/list/${template.key}/tags`} />
                </List.Content>
            </List.Item>
        );
    }
}