import { all, takeEvery, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onConvertWidgetDataMessage, onConvertWidgetDataFinished } from "./UpdateLessonReviewStatsActions";
import { REVIEW_STATS_COLLECTION } from "../../component/seamlessEditor/bookEditor/constants";

function* showProgress(updates) {
    yield put(onConvertWidgetDataMessage(`Found ${Object.keys(updates).length} widget/problemSet items with review stats...`));
}

function* convertWidgetReviewData() {

    yield put(onConvertWidgetDataMessage("Downloading widget review data …"));
    const db = firebase.defaultApp.database().ref();
    const outlinesRef = firebase.getFirebaseData("outlines_data");
    const outlinesData = (yield outlinesRef.once("value")).val();

    const totalLessons = Object.keys(outlinesData || {}).reduce((accum, current) => {
        if (outlinesData[current] && outlinesData[current].lessons)
            return Object.keys(outlinesData[current].lessons).length + accum;
        else
            return accum;
    }, 0);

    const resets = {};
    const updates = {};

    yield all(Object.keys(outlinesData || {}).map(function* (outlineId) {
        const lessons = outlinesData[outlineId]["lessons"];
        yield all(Object.keys(lessons || {}).map(function* (lessonId) {
            // reset existing reviewStats property
            resets[`/${outlineId}/lessons/${lessonId}/reviewStats`] = null;

            const lesson = lessons[lessonId];
            // Create array of unique problemSet keys to gather `problem_sets_data/${problemSetKey}` only once.
            // Both properties lesson.homework and lesson.problem_sets can overlap each other (even only partially) and refer to the same problemSet.
            const problemSetKeys = [ ... new Set( Object.keys(lesson.homework || {}).concat(Object.keys(lesson.problem_sets || {})) ) ];

            yield all(problemSetKeys.map(function* (problemSetKey) {
                const reviewsCountSnapshot = yield firebase.getFirebaseData(`problem_sets_data/${problemSetKey}/reviewsCount`).once("value");
                const reviewsCount = reviewsCountSnapshot.val();

                if (reviewsCount) {

                    if (lesson.problem_sets && lesson.problem_sets[problemSetKey]) {
                        updates[`/${outlineId}/lessons/${lessonId}/reviewStats/${REVIEW_STATS_COLLECTION.PROBLEM_SETS}/${problemSetKey}`] = reviewsCount;
                    }

                    if (lesson.homework && lesson.homework[problemSetKey]) {
                        updates[`/${outlineId}/lessons/${lessonId}/reviewStats/${REVIEW_STATS_COLLECTION.HOMEWORK}/${problemSetKey}`] = reviewsCount;
                    }

                    showProgress(updates);
                }
            }));

            yield all(Object.keys(lesson.widgets || {}).map(function* (widgetKey) {
                const widgetData = yield firebase.getFirebaseData(`widgetLibrary/data/${widgetKey}`).once("value");
                const widgetDataSnapShot = widgetData.val();
                yield all(Object.keys(widgetDataSnapShot.items || {}).map(function* (itemId) {
                    const reviewStats = widgetDataSnapShot.items[itemId].reviewStats;
                    if (reviewStats) {
                        updates[`/${outlineId}/lessons/${lessonId}/reviewStats/${REVIEW_STATS_COLLECTION.WIDGETS}/${widgetKey}/${itemId}`] = reviewStats;
                        yield showProgress(updates);
                    }
                }));
            }));
        }));
    }));

    yield put(onConvertWidgetDataMessage(`Found ${Object.keys(updates).length} widget items with review stats in ${totalLessons} lessons. Saving updates...`));
    yield db.child("outlines_data").update(resets);
    yield db.child("outlines_data").update(updates);
    yield put(onConvertWidgetDataFinished());
}

export default function* saga() {
    yield all([takeEvery(TYPES.CONVERT_WIDGET_DATA_START, convertWidgetReviewData)]);
}
