import { TYPES } from "./LessonLinkActions";

const initialState = {
    searchFilter: "",
    searching: false,
    searchResult: [],
    outlineId: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.LESSON_LINK_SEARCH_LESSON:
            return { ...state, searchFilter: payload, searching: true };
        case TYPES.LESSON_LINK_SET_SEARCH_RESULT:
            return { ...state, searchResult: payload, searching: false };
        case TYPES.LESSON_LINK_SET_OUTLINE:
            return { ...state, outlineId: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
