import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import firebase from "../../dataSource";
import styles from "./ResourceLink.module.css";

const replaceRelLink = (href) => {
    if (href && href.startsWith("spork://")) {
        return firebase.getFileURL(href.replace(/spork:\/\//, ""));
    } else {
        return href;
    }
};

export const mapIconColor = (icon) => {
    switch (icon) {
        case "linkify":
        case "file word":
        case "file word outline":
        case "work":
        case "file image outline":
        case "image outline":
        case "file video":
        case "file video outline":
        case "file audio":
        case "file audio outline":
        case "list alternate outline":
            return "blue";
        case "youtube":
        case "file powerpoint":
        case "file powerpoint outline":
        case "file pdf outline":
        case "file pdf":
            return "red";

        case "file excel":
        case "file excel outline":
            return "green";
        case "file outline":
        default: 
            return null;
    }
};

export default class ResourceLink extends PureComponent {
    static propTypes = {
        sipi: PropTypes.object,
        onLoadResource: PropTypes.func,
        resource: PropTypes.object,
        disableAutoLoad: PropTypes.bool,
        renderAsLink: PropTypes.bool,
        noIcon: PropTypes.bool,
    };

    componentDidMount() {
        const { sipi, onLoadResource, resource, disableAutoLoad } = this.props;
        if (!disableAutoLoad && sipi.resource_id && !resource) {
            onLoadResource(sipi.resource_id);
        }
    }

    render() {
        const { sipi, resource, renderAsLink = false, noIcon = false } = this.props;
        return sipi.resource_id ? (
            resource ? (
                renderAsLink ? (
                    <span className={styles.wrapper}>
                        {(resource.resourceType && !noIcon) && <ResourceLinkIcon resource={resource} />}
                        <a  target="_blank" rel="noopener noreferrer" href={replaceRelLink(resource.href)} title={resource.description || replaceRelLink(resource.href)}>
                            {resource.name}
                        </a>
                    </span>
                ) : (
                    <span className={styles.wrapper} title={resource.description || resource.name}>
                        {(resource.resourceType && !noIcon) && <ResourceLinkIcon resource={resource} />}{resource.name}
                    </span>
                )
            ) : <em className={`${styles.wrapper} ${styles.loading}`}>Loading ...</em>
        ) : (
            <span className={`${styles.wrapper} ${styles.missing}`}>No resource assigned</span>
        );
    }
}

export class ResourceLinkIcon extends PureComponent {
    static propTypes = {
        resource: PropTypes.object,
        size: PropTypes.string,
    };

    render() {
        const { resource, size } = this.props;
        const color = resource && resource.resourceType && mapIconColor(resource.resourceType.icon) || null;
        return resource && resource.resourceType ? <Icon {...color && {color }} {...size && {size }} name={resource.resourceType.icon} title={resource.resourceType.name} />
            : null;
    }
}