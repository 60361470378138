import React from "react";
import PropTypes from "prop-types";
import { List, Button, Header } from "semantic-ui-react";
import Topic from "../../../../TopicCollection/Topic";

export const WidgetTopicsView = ({canEdit, topics, onHandleOpen, divider}) => {

    let count = topics ? topics.length : 0;
    
    return <React.Fragment>
        {divider == false && (
            <Header floated="left" as="h5">
                Topics
            </Header>
        )}
        <div>
            {(canEdit && (
                <Button
                    onClick={onHandleOpen}
                    content="Edit..."
                    style={{ float: "right" }}
                    compact
                    title="Click here to edit topics."
                />
            )) || <div />}
            {count ? <React.Fragment>
                {divider && <Header as="h4">Topics</Header>}
                <List as="ul" style={{ display: "table" }}>
                    {topics.map(topic => (
                        <List.Item as="li" key={topic.key}>
                            <Topic topic={topic.value} />
                        </List.Item>
                    ))}
                </List>
            </React.Fragment>
                : (
                    <div style={{ fontStyle: "italic", color: "grey", marginTop: "2em" }}>Not assigned to any topic.</div>
                )}
        </div>
    </React.Fragment>;
};

WidgetTopicsView.propTypes = {
    canEdit: PropTypes.bool,
    topics: PropTypes.array,
    onHandleOpen: PropTypes.func,
    divider: PropTypes.bool,
};