import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { itemTypes, initialData } from "../../Widget";
import ActionLink from "../../../component/ActionLink";

const menuOptions = (onClick) =>
    ["text", "file", "link", "question"]
        .map((key) => {
            const itemType = itemTypes[key];
            return (
                itemType && (
                    <Dropdown.Item
                        icon={itemType.icon}
                        value={key}
                        key={key}
                        content={`Add ${itemType.title.toLowerCase()} item`}
                        onClick={onClick}
                    />
                )
            );
        })
        .filter(Boolean);

const menuTrigger = (
    <ActionLink
        horizontal
        icon="plus"
        className="create-item"
        content="Add new item"
        title="Add a new item to widget template"
    />
);

export default class TemplateCreateItem extends React.PureComponent {
    static propTypes = {
        onCreateItem: PropTypes.func.isRequired
    };

    handleChoice = (e, { value }) => {
        const { onCreateItem } = this.props;
        onCreateItem && onCreateItem(initialData[value]);
    };

    render() {
        return (
            <Dropdown className="create-item" item trigger={menuTrigger} icon={false} floating>
                <Dropdown.Menu>{menuOptions(this.handleChoice)}</Dropdown.Menu>
            </Dropdown>
        );
    }
}
