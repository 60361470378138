import { createSelector } from "reselect";
import { getTopicCollectionData } from "../../../TopicCollection/topicCollectionSelectors";
import { denormalizeTopicKey } from "../../../utils/selectorUtils";

const getWidgetTopicsIds = (state, props) => (state.firebase.data.widgetLibrary && state.firebase.data.widgetLibrary.list && state.firebase.data.widgetLibrary.list[props.id] && state.firebase.data.widgetLibrary.list[props.id].topics);

const getLessonTopicsIds = (state, props) => (props.lessonTopics);

export const getWidgetTopics = createSelector(
    getTopicCollectionData,
    getWidgetTopicsIds,
    getLessonTopicsIds,
    (topicsData, topicsIds, lessonTopicsIds) => {
        const topics = [];
        topicsIds && topicsData && Object.keys(topicsIds).map(id => {
            let [collection, topic] = denormalizeTopicKey(id);
            topics.push({ key: id, value: topicsData[collection] ? topicsData[collection][topic] : null, lessonTopic: lessonTopicsIds && lessonTopicsIds[id] || false });
        });
        return topics;
    }
);

