import React, { PureComponent } from "react";
import { Header } from "semantic-ui-react";
import ConceptList from "./ConceptList";

export default class Concepts extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <Header as="h1">SPORK Concepts</Header>
                <ConceptList {...this.props} />
            </React.Fragment>
        );
    }
}