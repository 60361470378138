import paragraph from "./paragraph.png";

export const TextPreview = {
    html: `<div style="vertical-align: middle; text-align: center;">
<img class="ui image" src='${paragraph}' style='width: 75%; height: auto;' />
</div>`,
    raw: null
};

const LinkPreview = {
    href: "javascript:void(0)",
    name: "Lorem ipsum dolor sit amet",
    title: "A sample of link for preview"
};

const FilePreview = {
    href: "javascript:void(0)",
    name: "lorem-ipsum-dolor-sit-amet.dat",
    title: "A sample of file download"
};

const QuestionPreview = {
};

const PreviewSamples = {
    text: TextPreview,
    link: LinkPreview,
    file: FilePreview,
    question: QuestionPreview,
};

const generateContent = ({ items }) =>
    items &&
    Object.keys(items).reduce((content, key) => {
        const item = items[key];
        const preview = item.content || (item.type && PreviewSamples[item.type]);
        content[key] = preview || null;
        return content;
    }, {});

export default generateContent;
