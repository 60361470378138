import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

export default class SectionListItem extends PureComponent {
    static propTypes = {
        id: PropTypes.number,
        section: PropTypes.object,
        onLoadSection: PropTypes.func,
        onSelectCourse: PropTypes.func,
        onSelectYear: PropTypes.func,
        selectedId: PropTypes.number,
        onSelectSection: PropTypes.func,
        schools: PropTypes.object,
        onSelectSchoolCourseSection: PropTypes.func,
        readOnly: PropTypes.bool,
    }

    componentDidMount() {
        const { id, onLoadSection } = this.props;
        onLoadSection(id);
    }

    handleSelectCourse = (e) => {
        const { onSelectYear, onSelectCourse, onSelectSchoolCourseSection, id } = this.props;
        onSelectSchoolCourseSection(id);
        onSelectCourse(e.currentTarget.id);
        onSelectYear(parseInt(e.currentTarget.dataset.year));
    }

    handleSelectSection = () => {
        const { id, onSelectSection } = this.props;
        onSelectSection(id);
    }

    render() {
        const { section, id, selectedId, schools, readOnly } = this.props;
        const schoolCourses = section && section.schoolCourses || null;
        const firstCourse = schoolCourses && schoolCourses[0] || null;
        return (
            <Table.Row
                onClick={this.handleSelectSection}
                active={id === selectedId}
                style={{ cursor: "pointer"}}
                title="Click to display class roster"
            >
                <Table.Cell>
                    {section && section.description}
                </Table.Cell>
                <Table.Cell>
                    {schoolCourses && schoolCourses.map(schoolCourse => (
                        <div key={schoolCourse.id}>
                            {readOnly ? schoolCourse.name
                                : <a
                                    style={{cursor: "pointer"}}
                                    id={schoolCourse.id}
                                    data-year={schoolCourse.year_id}
                                    onClick={this.handleSelectCourse}
                                    title="Click view school course details"
                                >
                                    {schoolCourse.name}
                                </a>}
                        </div>
                    ))}
                </Table.Cell>
                <Table.Cell>
                    {firstCourse && schools && schools[firstCourse.school_id] && schools[firstCourse.school_id].name || null}
                </Table.Cell>
            </Table.Row>
        );
    }
}
