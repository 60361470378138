import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Segment, Input, Button, Header, Form, Message } from "semantic-ui-react";
import firebase from "../../firebase";
import { Logo } from "../../component/logo";
import BrowserDetector from "../../component/BrowserDetector";
import EnvLabel from "../../component/EnvLabel";
export default class Login extends PureComponent {
    state = {
        username: "",
        password: "",
        error: "",
        processing: false,
        multipleTenants: null,
        tenantId: null,
        ignoreInfoMessage: false,
    };

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value, ignoreInfoMessage: true, error: "" });
    };

    handleSubmit = e => {
        const { username, password, tenantId } = this.state;
        e.preventDefault(); // do not actually send the form to the server

        this.setState({ processing: true });
        let authenticateUser = firebase.getFirebaseFunctions().httpsCallable("authenticateUser");
        authenticateUser({ username, password, tenant_id: tenantId })
            .then(result => {
                if (result.data.error) {
                    this.setState({ error: result.data.error.message, processing: false });
                } else if (result.data.tenants) {
                    this.setState({
                        multipleTenants: result.data.tenants.map(t => ({ key: t.id, text: t.name, value: t.id })),
                        processing: false,
                        error: "",
                    });
                } else {
                    firebase
                        .getFirebaseAuth()
                        .signInWithCustomToken(result.data.token)
                        .catch(error => {
                            this.setState({ error: error.message, processing: false });
                        })
                        .then(() => {
                            // when login form is submitted from URLs /app/login or /app/logout, redirect to /app to avoid cyclic login screen
                            if (
                                this.props.location.pathname.toLowerCase() === "/app/login" ||
                                this.props.location.pathname.toLowerCase() === "/app/logout"
                            ) {
                                this.props.history.push("/");
                            }
                        });
                }
            })
            .catch(error => {
                let message = "Failed to establish connection to authentication endpoint, please try it again.";
                if (error.message !== "internal") {
                    message = error.message;
                }
                this.setState({ error: message, processing: false });
            });
    };

    componentDidMount() {
        this.setState({ error: "", processing: false });
    }

    render() {
        const { processing, error, multipleTenants, tenantId, ignoreInfoMessage } = this.state;
        const { infoMessage } = this.props;

        return (
            <React.Fragment>
                <Grid centered verticalAlign="middle">
                    <Grid.Column textAlign="center" width="five">
                        <Logo className="loginLogo" />
                        <Header style={{ clear: "both" }} as="h2" color="grey">
                            Log-in to your account
                        </Header>
                        <Form loading={processing} onSubmit={this.handleSubmit} {...(error && { error: true })}>
                            <Segment raised>
                                <EnvLabel className="envLabel" style={{ marginBottom: "1em" }} />
                                <Form.Field>
                                    <Input
                                        fluid
                                        icon="user"
                                        name="username"
                                        iconPosition="left"
                                        placeholder="Username"
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input
                                        fluid
                                        icon="lock"
                                        name="password"
                                        iconPosition="left"
                                        placeholder="Password"
                                        type="password"
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                                {multipleTenants && (
                                    <Form.Dropdown
                                        options={multipleTenants}
                                        value={tenantId}
                                        name="tenantId"
                                        selection
                                        placeholder="Select a tenant"
                                        onChange={this.handleChange}
                                    />
                                )}
                                <Button fluid color="grey" size="large" type="submit">
                                    LOGIN
                                </Button>
                            </Segment>
                            {infoMessage && !ignoreInfoMessage && <Message positive>{infoMessage}</Message>}
                            <Message error content={error} />
                            {multipleTenants && !tenantId && (
                                <Message info content="User is member of multiple tenants. Please select a tenant." />
                            )}
                        </Form>
                    </Grid.Column>
                </Grid>
                <BrowserDetector />
            </React.Fragment>
        );
    }
}

Login.propTypes = {
    /* callback when user is sucessfully authenticated */
    onLogin: PropTypes.func.isRequired,
    /* informational message to be displayed below the login form */
    infoMessage: PropTypes.string,
};
