import React from "react";
import PropTypes from "prop-types";
import { EditActionIcon } from "bmd-react";

import { LockIcon } from "../../../Lock";
import AccessControl from "../../../auth";

const AccessRights = {
    problemSet: {
        edit: [ "problemSet:manage", "problemSet:edit" ],
        view: [ "problemSet:view", "problemSet:review" ],
    },
    comptests_common: {
        edit: [ "compTests:manage", "compTests:edit" ],
        view: [ "compTests:view" ],
    }
};

const EditorRoutes = {
    problemSet: "/qu/problem-set-creator/",
    comptests_common: "/qu/comp-test-editor/",
};

export default class ProblemSetRowIcons extends React.PureComponent {

    static propTypes = {
        dataSet: PropTypes.oneOf(["problemSet", "comptests_common"]).isRequired,
        dataKey: PropTypes.string.isRequired,
        locked: PropTypes.bool,
        userId: PropTypes.string,
        authorId: PropTypes.string,
        onDetail: PropTypes.func.isRequired,
    }

    render() {
        const { dataKey, dataSet, locked, userId, authorId, onDetail } = this.props;
        const accessDefs = AccessRights[dataSet];
        const accessData = { userId, authorId };

        return (
            <div className="actionIconClass right">
                {locked && <LockIcon />}
                <AccessControl actionArray={accessDefs.edit} data={accessData} renderNoAccess={
                    <AccessControl actionArray={accessDefs.view} data={accessData}>
                        <EditActionIcon iconName="eye"
                            data={EditorRoutes[dataSet] + dataKey}
                            onClick={onDetail}
                            title="Review problem set"
                        />
                    </AccessControl>
                }>
                    <EditActionIcon
                        data={EditorRoutes[dataSet] + dataKey}
                        onClick={onDetail}
                        title="Edit problem set"
                    />
                </AccessControl>
            </div>
        );
    }
}
