import EditKit from "./EditKit";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
    isOpen,
    isSaving,
    selectedKit,
    isDirty,
    getAuthorsCandidates,
    getReviewersCandidates,
    getReviewers,
    getAuthors,
    getCommentRecipients,
    getCommentRecipientCandidates,
} from "./EditKitSelectors";
import * as EditKitActions from "./EditKitActions";
import { withAuth } from "../../../component/withAuth";
import { getUsers } from "../../../Users/UserList/UserListSelectors";
import { onChangeRole } from "../../../Users/UserList/UserListActions";
import { getSelectedYear } from "../../../Year/YearsSelectors";
import { authUser } from "../../../auth/authSelectors";
import { withFirebase } from "react-redux-firebase";

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...EditKitActions,
            onChangeRole,
        },
        dispatch
    );

export default compose(
    withFirebase,
    connect(
        state => ({
            open: isOpen(state),
            saving: isSaving(state),
            selectedKit: selectedKit(state),
            selectedYear: getSelectedYear(state),
            currentTenant: authUser(state).tenant.name,
            isDirty: isDirty(state),
            allUsers: getUsers(state),
            authorOptions: getAuthorsCandidates(state),
            reviewerOptions: getReviewersCandidates(state),
            reviewers: getReviewers(state),
            authors: getAuthors(state),
            commentRecipients: getCommentRecipients(state),
            commentRecipientOptions: getCommentRecipientCandidates(state),
        }),
        dispatchToProps
    ),
    withAuth
)(EditKit);
