import { TYPES } from "./ResourceSourceTypeActions";
const initialState = {
    isSaving: false
};

export default (state = initialState, { type }) => {
    switch (type) {
        case TYPES.RESOURCES_SOURCE_TYPE_SAVE:
            return { ...state, isSaving: true };
        case TYPES.RESOURCES_SOURCE_TYPE_SAVED:
            return { ...state, isSaving: false };
        default:
            return state;
    }
};
