import { createSelector } from "reselect";
import { appendIfNEmpty, fetchKitsData } from "../AKitsList/AKitsListSelectors";
import { nullsLast, compareByEntityAttribute } from "../../../utils/compareFunctions";
import { getAllCourseVariants } from "../AKitEditor/AKitEditorSelectors";
import { getCvGuid } from "../../../utils/selectorUtils";

/* For a given set of kits_data_object it returns a function that finds out if there exists a kit for the given course */
const courseVariantDoesNotHaveAKitAlready = kits_data_object => courseVariant => {
    return !(
        kits_data_object &&
        Object.keys(kits_data_object).some(
            key =>
                kits_data_object[key] &&
                kits_data_object[key].course_variants &&
                kits_data_object[key].course_variants[getCvGuid(courseVariant)]
        )
    );
};

export const getCourseVariantDetails = (courseVariant, withoutTenant = false) => {
    const regulatorName = courseVariant.regulator ? courseVariant.regulator : "";
    const minGradeName = courseVariant.min_grade ? courseVariant.min_grade : "";
    const maxGradeName = courseVariant.max_grade ? courseVariant.max_grade : "";
    const gradesInterval = minGradeName === maxGradeName ? minGradeName : minGradeName + "-" + maxGradeName;
    const isSingleGrade = minGradeName == maxGradeName;
    const recSchedule = courseVariant.rec_schedule ? courseVariant.rec_schedule : "";
    const programs = courseVariant.programs ? courseVariant.programs : "";
    const isSingleProgram = courseVariant.programs ? courseVariant.programs.split(",").length === 1 : 1;
    const tenant = courseVariant.tenant ? "["+courseVariant.tenant.name+"]" : "";

    return (
        appendIfNEmpty(programs, "", (isSingleProgram ? "Program" : "Programs") + ": ") +
        appendIfNEmpty(gradesInterval, " ", (isSingleGrade ? "Grade" : "Grades") + ": ") +
        appendIfNEmpty(recSchedule, " ", "Time: ") +
        appendIfNEmpty(regulatorName, " ") +
        (withoutTenant ? "" : appendIfNEmpty(tenant," "))
    );
};

export const getCourseVariantsAsDropdown = createSelector(
    fetchKitsData,
    getAllCourseVariants,
    (kits_data_object, course_variants) => {
        const courseVariants = course_variants
            .filter(courseVariantDoesNotHaveAKitAlready(kits_data_object))
            .sort(compareByEntityAttribute("name", nullsLast, value => value.toLocaleLowerCase().trim()));
        return courseVariants.map(courseVariant => ({
            key: courseVariant.id,
            value: getCvGuid(courseVariant),
            text: courseVariant.name,
            description: getCourseVariantDetails(courseVariant),
        }));
    }
);

export const isOpen = state => state.kitBuilder.createAkit && state.kitBuilder.createAkit.open;
export const isCreating = state => state.kitBuilder.createAkit && state.kitBuilder.createAkit.creating;
