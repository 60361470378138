import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LessonWidgetsList from "./LessonWidgetsList";
import {
    onAddNewWidget,
    onAddExistingWidget,
    onUpdateWidgets,
    onCloneToNewWidget,
    onSetFocus,
} from "../LessonContentActions";
import {
    getLessonWidgetsData,
    getHighestWidgetPosition,
    getLessonTopicOptions,
    uncoveredTopicsCount,
    uncoveredTopics,
    getFocusedWidget,
} from "../LessonContentSelectors";
import { getWidgetsInfo } from "../../WidgetLibrary/WidgetLibrarySelectors";

const mapStateToProps = (state, props) => ({
    widgets: getLessonWidgetsData(state, props),
    widgetCollection: getWidgetsInfo(state),
    highestPosition: getHighestWidgetPosition(state, props),
    lessonTopicOptions: getLessonTopicOptions(state, props),
    uncoveredTopicsCount: uncoveredTopicsCount(state, props),
    uncoveredTopics: uncoveredTopics(state, props),
    focusedItem: getFocusedWidget(state),
});

const dispatchToProps = dispatch => bindActionCreators({
    onAddNewWidget,
    onAddExistingWidget,
    onUpdateWidgets,
    onCloneToNewWidget,
    onSetFocus,
}, dispatch);

const LessonWidgetsContainer = connect(
    mapStateToProps,
    dispatchToProps
)(LessonWidgetsList);

LessonWidgetsContainer.displayName = "LessonWidgetsContainer";

export default LessonWidgetsContainer;