exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._n7dLQkuzesed1P-EMi3s {\n  font-weight: bold;\n  color: #2c662d; }\n\n.oddRow._3FYVQMCPRFcSWWLLcdpCAV, .evenRow._3FYVQMCPRFcSWWLLcdpCAV {\n  color: #9f3a38;\n  background-color: #fff6f6;\n  border: 1px solid #e0b4b4;\n  border-radius: 6px; }\n\n.oddRow._3RbQgImt6MpPyBW2_rnJ2L, .evenRow._3RbQgImt6MpPyBW2_rnJ2L {\n  color: #2c662d;\n  background-color: #fcfff5;\n  border: 1px solid #a3c293;\n  border-radius: 6px; }\n", ""]);

// exports
exports.locals = {
	"default": "_n7dLQkuzesed1P-EMi3s",
	"deactivatedRow": "_3FYVQMCPRFcSWWLLcdpCAV",
	"activatedRow": "_3RbQgImt6MpPyBW2_rnJ2L"
};