import { all, takeLatest, put, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import { TYPES, onCancelAddTerm, onSetSearchResult } from "./GlossaryActions";
import { glossary, aKitManager } from "../dataSource";
import { push } from "connected-react-router";
import { getFilteredGlossaryList, getOutlinePreference } from "./GlossarySelectors";
import {  getSearchByNamePredicate } from "../../utils/selectorUtils";
import { toastError, DB_ERROR } from "../../component/toast";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";

const tooManyResults = {
    name: "Too many results",
    results: [{
        title: "",
        html: "<p>If you don't see the term you want, so the word is too general, try to be more specific.</p>"
    }]
};

function* createTerm({ payload }) {
    const { name, callback, switchToTerm, ...data } = payload;
    try {
        const t = yield select(tenantId);
        const key = yield glossary.createTerm({ name, ...data, t });
        yield put(onCancelAddTerm());
        callback && callback(key, name);
        if (switchToTerm) {
            yield put(push("/widgets/glossary/" + key));
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to create a new Glossary term", message: DB_ERROR });
    }
}

function* renameTerm({ payload }) {
    const { glossaryId, name } = payload;
    try {
        yield glossary.renameTerm(glossaryId, name);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to rename Glossary term", message: DB_ERROR });
    }
}

function* closeDetail() {
    yield put(push("/widgets/glossary"));
}

function* removeTerm({ payload }) {
    try {
        yield glossary.removeTerm(payload);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to remove Glossary term", message: DB_ERROR });
    }
    yield closeDetail();
}

function* updateTerm({ payload }) {
    const { glossaryId, content } = payload;
    try {
        yield glossary.updateTerm(glossaryId, { content });
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update Glossary term", message: DB_ERROR });
    }
}

const nameLengthPredicate = (a, b) => (a.name.length - b.name.length);

function* getTermsWithData(foundTerms) {
    const termObjects = [];
    yield* foundTerms.map(async (term) => {
        const html = await glossary.getTermDefinition(term.key);
        termObjects.push({
            title: term.name,
            name: term.name,
            key: term.key,
            html: html || "<em>Empty definiton.</em>"
        });
    });
    return termObjects.sort(nameLengthPredicate);
}
const sortByLenAndOutlineUsage = (glossaryUsedInOutline) => {
    return (a, b) => {
        const diff = a.name.length - b.name.length;
        if (0 == diff && !glossaryUsedInOutline[b.key] && glossaryUsedInOutline[a.key]) {
            return -1;
        }
        return diff;
    };
};

function* searchTerm({ payload: { value, rootEntityKey } }) {
    if (0 === value.length) {
        yield put(onSetSearchResult(null));
        return;
    }
    yield delay(500);
    let foundTerms = yield select(getFilteredGlossaryList, { widgetId: rootEntityKey });
    if (foundTerms) {
        const outlineId = yield select(getOutlinePreference);
        let kitName = "";
        let glossaryUsedInOutline = {};
        if (outlineId) {
            kitName = yield aKitManager.getKitNameByOutline(outlineId);
            glossaryUsedInOutline = yield glossary.getOutlineGlossaryUsage(outlineId);
            if (!glossaryUsedInOutline) {
                glossaryUsedInOutline = {};
            }
        }
        const namePredicate = getSearchByNamePredicate(value);
        if (namePredicate) {
            foundTerms = foundTerms.filter(namePredicate);
        }
        foundTerms = foundTerms.sort(sortByLenAndOutlineUsage(glossaryUsedInOutline));
        let termObjects = yield getTermsWithData(foundTerms.slice(0, 5));
        termObjects = termObjects.sort(sortByLenAndOutlineUsage(glossaryUsedInOutline));
        const result = {};
        termObjects.map(term => {
            if (result[term.title]) {
                result[term.title].results.push({ ...term, kitlabel: glossaryUsedInOutline[term.key] ? kitName : undefined});
            } else {
                result[term.title] = {
                    name: term.title,
                    results: [{ ...term, kitlabel: glossaryUsedInOutline[term.key] ? kitName : undefined}]
                };
            }
        });
        if (5 < foundTerms.length) {
            result[""]= tooManyResults;
        }
        yield put(onSetSearchResult(result));
    } else {
        yield put(onSetSearchResult(null));
    }
}

export default function* glossarySaga() {
    yield all([
        takeLatest(TYPES.WIDGETS_GLOSSARY_CREATE_TERM, createTerm),
        takeLatest(TYPES.WIDGETS_GLOSSARY_CHANGE_NAME, renameTerm),
        takeLatest(TYPES.WIDGETS_GLOSSARY_REMOVE_TERM, removeTerm),
        takeLatest(TYPES.WIDGETS_GLOSSARY_UPDATE_TERM, updateTerm),
        takeLatest(TYPES.WIDGETS_GLOSSARY_CLOSE_DETAIL, closeDetail),
        takeLatest(TYPES.WIDGETS_GLOSSARY_SEARCH_TERM, searchTerm),
    ]);
}
