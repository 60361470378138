import { TYPES } from "./conceptsActions";

// The initial state of the ExampleSimple
const initialState = {
    error: undefined,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.CONC_ON_ERROR:
            return { ...state, error: action.value };
        case TYPES.CONC_CLEAR_ERROR:
            return { ...state, error: undefined };

        default:
            // console.log("Reducer(state):", state); // eslint-disable-line no-console
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;