exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "i.icon._1X-IyHKUl01uIOeyL-cgNU {\n  opacity: 0.5;\n  cursor: pointer; }\n  i.icon._1X-IyHKUl01uIOeyL-cgNU._3WdRNgP3fSlKYbePD7Lju5:hover {\n    opacity: 1; }\n\n._2R4Y5WG9D0gWd1GjXUU90M {\n  width: 600px;\n  height: 300px;\n  border: 1px solid #e0e0e0;\n  border-radius: 6px;\n  margin-top: 8px; }\n  ._2R4Y5WG9D0gWd1GjXUU90M > div {\n    width: 580px;\n    height: 270px;\n    overflow: auto; }\n", ""]);

// exports
exports.locals = {
	"icon": "_1X-IyHKUl01uIOeyL-cgNU",
	"detailIcon": "_3WdRNgP3fSlKYbePD7Lju5",
	"callStack": "_2R4Y5WG9D0gWd1GjXUU90M"
};