import { createSelector } from "reselect";
import { getCourseVariantsData } from "../../External/CourseVariantList/CourseVariantsDataSelectors";
import { userClaims } from "../UserProfile/UserProfileSelectors";
import { isAdmin } from "../roleUtils";
import { roles } from "../../auth/isPermitted";
import _ from "lodash";

export const getUsers = state => state.firebase.data.user;
export const getTenants = state => state.firebase.data.tenants;

// these use users loaded from SQL
export const isLoading = state => state.user.list.isLoading;
export const getUsersSql = state => state.user.list.userList;
export const getSimpleUsersSql = state => state.user.list.userSimpleList;
export const getUsersSqlAsOptions = createSelector(
    getUsersSql,
    users => {
        if (users == null) return users;
        const options = [{ key: "NULL", text: "Not assigned", value: "NULL" }];
        return options.concat(
            users.map(user => ({
                key: user.id,
                text: `${user.first_name} ${user.last_name} - ${user.scope}`,
                value: user.id,
            }))
        );
    }
);

export const getSimpleUsersAsOptions = createSelector(
    getSimpleUsersSql,
    users => {
        if (users == null) return users;
        return users.map(user => ({
            key: user.id,
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
            description: user.email,
        }));
    }
);

export const getSimpleUsersAsCollection = createSelector(
    getSimpleUsersSql,
    users => {
        if (users) {
            const result = {};
            for (const u of users) {
                result[u.id] = u;
            }
            return result;
        }
    }
);

const getEditedUserId = state => state.user.list.editedUserId;
export const variantsLoading = state => state.user.list.variantsLoading;
export const variants = state => state.user.list.variants;

export const isClaimsUpdating = state => state.user.list.isClaimsUpdating;

const getRoles = () => roles;

export const getUsersAsArray = createSelector(
    getUsers,
    users => {
        return Object.keys(users || {}).map(uid => {
            const roleArray = [];
            const user = users[uid];
            if (user && user.roles) {
                const roles = user.roles;
                Object.keys(roles).map(role => {
                    if (roles[role]) {
                        roleArray.push(role);
                    }
                });
            }
            roleArray.sort();
            return { ...user, uid, roleArray, roleString: roleArray.length ? roleArray.join(" ") : "zzz" }; // workaround to fix sorting user by roles
        });
    }
);

export const getEditedUser = createSelector(
    getUsers,
    getEditedUserId,
    (users, uid) => {
        if (users && uid) {
            let editedUser = users[uid];
            editedUser.job_titles = _.uniqBy(editedUser.job_titles, "name");
            editedUser.uid = uid;
            return editedUser;
        }
    }
);

export const getAuthorVariantsForEditedUser = createSelector(
    getEditedUserId,
    getCourseVariantsData,
    (userId, data) => {
        const variantForUser = {};
        if (userId && data) {
            Object.keys(data).map(variantId => {
                if (data[variantId] && data[variantId].authors && data[variantId].authors[userId]) {
                    variantForUser[variantId] = true;
                }
            });
        }
        return variantForUser;
    }
);

export const getReviwerVariantsForEditedUser = createSelector(
    getEditedUserId,
    getCourseVariantsData,
    (userId, data) => {
        const variantForUser = {};
        if (userId && data) {
            Object.keys(data).map(variantId => {
                if (data[variantId] && data[variantId].reviewers && data[variantId].reviewers[userId]) {
                    variantForUser[variantId] = true;
                }
            });
        }
        return variantForUser;
    }
);

const getRolesAsOptions = createSelector(
    getRoles,
    roles => Object.keys(roles).map(roleKey => ({ key: roleKey, value: roleKey, text: roles[roleKey].name }))
);

export const getUnusedRoleOptions = createSelector(
    getEditedUserId,
    getUsers,
    userClaims,
    getRolesAsOptions,
    (userId, users, userClaims, options) => {
        if (userId) {
            const roles = (users && users[userId] && users[userId].roles) || {};
            return options.filter(o => !(("ROLE_ADMIN" === o.value && !isAdmin(userClaims)) || roles[o.value]));
        }
    }
);

export const getFullName = (users, uid) => {
    if (users && users[uid]) {
        return `${users[uid].first_name} ${users[uid].last_name}`;
    }
};

export const getEditorTimestamp = (collection, itemId) => {
    if (collection && collection[itemId]) {
        return collection[itemId].last_edit;
    }
};

export const getEditorId = (collection, itemId) => {
    if (collection && collection[itemId]) {
        return collection[itemId].last_editor;
    }
};

export const getAuthorId = (collection, itemId) => {
    if (collection && collection[itemId]) {
        return collection[itemId].author;
    }
};

export const getCreatedTimestamp = (collection, itemId) => {
    if (collection && collection[itemId]) {
        return collection[itemId].created;
    }
};
