import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Checkbox } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";

import styles from "./Image.module.scss";
import { swallowEvent } from "../../utils/utils";
import { ENTITY_TYPE } from "../../constants";

export default class ImageResize extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([ENTITY_TYPE.IMAGE, ENTITY_TYPE.INLINE_IMAGE]),
        width: PropTypes.string,
        height: PropTypes.string,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    state = {
        height: this.props.height,
        width: this.props.width,
        isHeightValid: true,
        isWidthValid: true,
        convertType: false,
    };

    handleMouseClick = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleMouseDown = e => {
        if (e.target.tagName !== "INPUT") {
            e.preventDefault();
        }
        e.stopPropagation();
    };

    handleResize = () => {
        const { height, width, convertType } = this.state;
        const { type, onSave } = this.props;
        let newType;

        if (convertType) {
            newType = type === ENTITY_TYPE.IMAGE ? ENTITY_TYPE.INLINE_IMAGE : ENTITY_TYPE.IMAGE;
        }
        onSave({ width, height, type: newType });
    };

    handleValueChange = ({ target }) => {
        let isWidthValid = false;
        let isHeightValid = false;

        switch (target.name) {
            case "width":
                isWidthValid = target.value.match(/^([\d]*px)$|^([\d]*%)$|^(auto)$/);
                this.setState({ isWidthValid });
                break;
            case "height":
                isHeightValid = target.value.match(/^([\d]*px)$|^(100%)$|^(auto)$/);
                this.setState({ isHeightValid });
                break;
        }
        this.setState({ [target.name]: target.value });
    };

    handleKeyDown = (e) => {
        e.stopPropagation();

        if (e.keyCode === 13) {
            swallowEvent(e);
            this.handleResize();
        }
    };

    handleImageType = () => this.setState((state) => ({ convertType: !state.convertType }));

    render() {
        const { onCancel, type } = this.props;
        const { height, width, isHeightValid, isWidthValid, convertType } = this.state;

        return (
            <div className={styles.resizePopup} onClick={this.handleMouseClick} onMouseDown={this.handleMouseDown} onKeyDown={this.handleKeyDown}>
                <Form error={!isWidthValid || !isHeightValid}>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            name="width"
                            label="width"
                            onChange={this.handleValueChange}
                            value={width}
                            error={!isWidthValid}
                        />
                        <Form.Input
                            fluid
                            name="height"
                            label="height"
                            onChange={this.handleValueChange}
                            value={height}
                            error={!isHeightValid}
                        />
                    </Form.Group>
                </Form>
                <div className={styles.info}>Width: use values - px, % or auto</div>
                <div className={styles.info}>Height: use values - px, 100% or auto</div>
                <div style={{ padding: "16px 0"}}>
                    <Checkbox
                        label={`Convert to ${type === ENTITY_TYPE.IMAGE ? "inline" : "block"} image`}
                        checked={convertType}
                        onChange={this.handleImageType}
                    />
                </div>
                <SaveCancelButtons
                    size="medium"
                    onSave={this.handleResize}
                    onCancel={onCancel}
                    saveDisabled={!isWidthValid || !isHeightValid}
                    submitText="Apply"
                />
            </div>
        );
    }
}
