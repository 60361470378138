import PropTypes from "prop-types";

const TimestampPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const ProblemSetPropTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    notes: PropTypes.string,
    setType: PropTypes.string,
    tags: PropTypes.array,
    problems: PropTypes.array,
    status: PropTypes.string,
    topics: PropTypes.object,
    lessons: PropTypes.array,
    units: PropTypes.array,
    courses: PropTypes.array,
    createdDate: TimestampPropType,
    lastUpdated: TimestampPropType,
};

export const ProblemSetPropShape = PropTypes.shape(ProblemSetPropTypes);
