import firebase from "../../firebase";
import { formatQueryParam } from "../../utils/requestUtils";

const SelectFieldsForList = formatQueryParam("$select", [
    "id", "name", "href", "resource_type_id",
    "created_at", "created_by", "updated_at", "updated_by",
    "tenant_id", // required, otherwise "$limit" doesn't work
]);

export const fetchResourceList = (options) => {
    const args = [ "/resources?$sort[created_at]=-1" ];
    if (options && options.tags) {
        args.push(formatQueryParam("tags", options.tags));
    }
    if (options && options.courseVariant) {
        args.push(formatQueryParam("course_variant_id", options.courseVariant.id));
    }
    if (options && options.resourceType) {
        args.push(formatQueryParam("resource_type", options.resourceType));
    }
    if (options && options.owner) {
        args.push(formatQueryParam("created_by", options.owner.id));
    }
    if (options && options.topic) {
        args.push(formatQueryParam("topic", options.topic));
    }
    args.push(SelectFieldsForList);

    return firebase.tabletProxy({
        method: "GET",
        uri: args.filter(Boolean).join("&"),
    });
};

export const fetchResourceById = (id)  => firebase.tabletProxy({
    method: "GET",
    uri: `/resources/${id}`,
});

export const fetchResourceByHref = (href)  => firebase.tabletProxy({
    method: "GET",
    uri: `/resources?${formatQueryParam("href", href)}`,
});

export const fetchUsingResourceInYear = (id, yearId) => firebase.tabletProxy({
    method: "GET",
    uri: `/sipi-resource?resource_id=${id}&year_id=${yearId}`,
});

export const fetchResourceTopics = (id) => firebase.tabletProxy({
    method: "GET",
    uri: `/topic-resource?resource_id=${id}`,
});

export const fetchResourcesTopics = () => firebase.tabletProxy({
    method: "GET",
    uri: "/topic-resource",
});

export const addResourceTopic = (body) => firebase.tabletProxy({
    method: "POST",
    uri: "/topic-resource",
    body
});

export const fetchResourceTopic = ({ resource_id, collection_id, topic_id}) => firebase.tabletProxy({
    method: "GET",
    uri: `/topic-resource?resource_id=${resource_id}&collection_id=${collection_id}&topic_id=${topic_id}`,
});

export const removeResourceTopic = (id) => firebase.tabletProxy({
    method: "DELETE",
    uri: "/topic-resource/" + id
});

export const createResource = (toCreate) => {
    const { file, href, file_name } = toCreate;
    if (file && typeof file === "object") {
        toCreate.file = file_name;
    }
    if (href) { // legacy
        toCreate.link = toCreate.link || href;
        toCreate.href = undefined;
    }
    return firebase.tabletProxy({
        method: "POST",
        uri: "/resources",
        body: toCreate,
    });
};

export const updateResource = (resourceId, changes) => firebase.tabletProxy({
    method: "PATCH",
    uri: `/resources/${resourceId}`,
    body: changes
});

export const markFileAsUploaded = (resourceId, uploadPath) => updateResource(
    resourceId,
    { uploaded: uploadPath }
);

export const searchResourcesByName = (name, limit = 5) => {
    const likePattern = encodeURIComponent(`%${name}%`);
    return firebase.tabletProxy({
        method: "GET",
        uri: `/resources?name[$like]=${likePattern}&$sort[name]=1&$sort[created_at]=-1&$limit=${limit}`,
    });
};

const SelectAllKnownTags = `/tag?$sort[name]=1&${formatQueryParam("$select", [ "id", "name" ])}`;
const SelectOnlyUsedTags = `${SelectAllKnownTags}&onlyUsed=1`;

export const fetchTags = (usedOnly = false) => firebase.tabletProxy({
    method: "GET",
    uri: usedOnly ? SelectOnlyUsedTags : SelectAllKnownTags,
});

export const fetchCourseVariants = () => firebase.tabletProxy({
    method: "GET",
    uri: "/course-variant?onlyUsedByResources=1",
});

export const fetchSourceTypes = () => firebase.tabletProxy({
    method: "GET",
    uri: "/source-type",
});
