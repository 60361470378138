// TODO (pbenes) - add Styleguide docs
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Popup, Divider, Button } from "semantic-ui-react";

export default class ConfirmWithContext extends PureComponent {
    static propTypes = {
        context: PropTypes.any,
        className: PropTypes.string,
        header: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        confirmText: PropTypes.string,
        cancelText: PropTypes.string,
        confirmIconName: PropTypes.string,
        isConfirmDestructive: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        children: PropTypes.node,
    };

    render() {
        const {
            context,
            className,
            header,
            message,
            confirmText,
            cancelText,
            confirmIconName,
            isConfirmDestructive,
            onConfirm,
            onCancel,
            children,
        } = this.props;
        return (
            <Popup wide context={context} open={context ? true : false} className={className || ""}>
                <Popup.Header>{header}</Popup.Header>
                <Divider hidden />
                {message}
                <br />Please confirm this action.
                {children}
                <Divider hidden />
                <Button
                    size="small"
                    floated="right"
                    onClick={onCancel}
                    content={cancelText || "Cancel"}
                    style={{ marginLeft: "1em" }}
                />
                <Button
                    primary
                    size="small"
                    negative={isConfirmDestructive}
                    floated="right"
                    onClick={onConfirm}
                    content={confirmText || "OK"}
                    icon={confirmIconName || "trash alternate outline"}
                />
            </Popup>
        );
    }
}
