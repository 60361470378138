import { createSelector } from "reselect";
import { sipiTemplates, getSearchByValueYearIdPredicate, getSearchByOptionalPackagePredicate } from "../../SiPiTemplatesList/SiPiTemplatesListSelectors";
import { getSelectedYearId } from "../../../Year/YearsSelectors";
import { convertDatasetToList, compareValueByNameCI } from "../../../utils/selectorUtils";
import { templateData } from "../../SiPiTemplate/SiPiTemplateSelectors";
import { tenantId } from "../../../Users/UserProfile/UserProfileSelectors";
import { getEnabledModules } from "../../../External/Tenants/tenantSelectors";

export const selectedTemplateId = state => state.lup.cloner.selectedTemplateId;

export const getSipiTemplatesAsOptions = createSelector(
    sipiTemplates,
    getSelectedYearId,
    tenantId,
    getEnabledModules,
    (sipiTemplates, selectedYearId, tenantId, enabledModules) => {
        if (sipiTemplates) {
            let filteredData = convertDatasetToList(sipiTemplates).sort(compareValueByNameCI);
            const yearIdPredicate = getSearchByValueYearIdPredicate(selectedYearId);
            filteredData = filteredData.filter(yearIdPredicate);
            const modulePredicate = getSearchByOptionalPackagePredicate(null, tenantId, enabledModules);
            filteredData = filteredData.filter(modulePredicate);
            return filteredData.map(t => ({ key: t.key, value: t.key, text: t.value.name }));
        }
    }
);

export const getTemplateSiPiAsCollection = createSelector(
    selectedTemplateId,
    templateData,
    (templateId, data) => {
        if (templateId && data) {
            if (data[templateId]) {
                const rawSyllabusData = data[templateId];
                let sipis = {};
                Object.keys(rawSyllabusData).map((lu) => {
                    sipis = {...sipis, ...rawSyllabusData[lu]};
                });
                return sipis;
            } else if (null === data[templateId]) {
                return null;
            }
        }
    }
);