import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import Topics from "./Topics";
import { firebaseConnect } from "react-redux-firebase";
import { spinnerWhileLoading } from "../component/spinnerWhileLoading.jsx";
import { getTopics, getTopicsAsArray, isDialogOpen } from "./topicsSelectors";
import { onDeleteTopic, onTopicShowDialog } from "./topicsActions";

const mapStateToProps = function(state) {
    return {
        topics: getTopicsAsArray(state),
        showDialog: isDialogOpen(state),
        _topics: getTopics(state) // only for a spinner
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onDelete: onDeleteTopic, onTopicShowDialog }, dispatch);
};

export default compose(
    firebaseConnect(() => {
        return [
            {
                path: "topics/"
            } // create listener
        ];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["_topics"])
)(Topics);
