exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Pm8dcnDdTKu2O-hCJUAVs {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 100%;\n  white-space: nowrap; }\n\n/** Unused because has too low specificity */\n.pDTELy9MNyQja1JuHwP-e {\n  margin: 0 .25rem 0 0;\n  padding: inherit; }\n\n._2yWmOhgdtGz9WlX3Cpqe5o {\n  margin-right: 0.3em; }\n\n.KBi5wzXi32ZNGs4TnmEcA {\n  font-style: italic;\n  color: grey;\n  margin-right: 1em; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "_2Pm8dcnDdTKu2O-hCJUAVs",
	"typeIcon": "pDTELy9MNyQja1JuHwP-e",
	"loading": "_2yWmOhgdtGz9WlX3Cpqe5o",
	"missing": "KBi5wzXi32ZNGs4TnmEcA"
};