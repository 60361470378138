import { TYPES } from "./ResourceLinkActions";

const initialState = {
    resources: {},
    isCreating: false,
    searchFilter: "",
    isSearching: false,
    searchResult: [],
    isTagsLoading: false,
    tags: [],
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCE:
            return { ...state, resources: {...state.resources, [payload.id]: payload } };
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCES:
            return { ...state, resources: {...state.resources, ...payload } };
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_CREATE_RESOURCE:
            return { ...state, isCreating: true };
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_RESOURCE_CREATED:
            return { ...state, isCreating: false };
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_SEARCH_RESOURCE:
            return { ...state, searchFilter: payload, isSearching: true };
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_SET_SEARCH_RESULT:
            return { ...state, searchResult: payload, isSearching: false };
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_LOAD_TAGS:
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_ADD_TAG:
            return { ...state, isTagsLoading: true };
        case TYPES.SYLLABUS_OUTLINES_RESOURCE_SET_TAGS:
            return { ...state, tags: [...payload ], isTagsLoading: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
