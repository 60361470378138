/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { reactReduxFirebase } from "react-redux-firebase";
import { createBrowserHistory } from "history";
import createSagaMiddleware, { END } from "redux-saga";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import catchPromiseErrors from "./catch-promise-errors";

import firebase from "../firebase";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import configDevTools from "./configDevTools";

const sagaMiddleware = createSagaMiddleware();

// react-redux-firebase config
const reactReduxFbconfig = {
    enableLogging: false
};

const createStoreWithFirebase = compose(
    reactReduxFirebase(firebase.defaultApp, reactReduxFbconfig), // firebase instance as first argument
)(createStore);

function configureStore(initialState = {}, history) {
    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    // 3. thunk: allows actions to be functions rather than objects
    // 4. catchPromiseErrors: catches errors in Promises and process them (ie. display toast)
    // 5. promise: allows actions to be a Promise
    // 3. and 5. are required for feather-redux
    const middlewares = [
        sagaMiddleware,
        routerMiddleware(history),
        thunk,
        catchPromiseErrors,
        promise,
    ];

    const enhancers = [applyMiddleware(...middlewares)];

    const composeEnhancers = configDevTools(compose);

    const store = createStoreWithFirebase(
        connectRouter(history)(rootReducer),
        initialState,
        composeEnhancers(...enhancers),
    );

    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        const currentTime = new Date().toTimeString().split(" ")[0];

        module.hot.accept("./rootReducer", () => {
            // eslint-disable-next-line no-console
            console.log("%c*rootReducer hot-reload fired @ %s", "font-size: 16px; color: rgb(95, 143, 225);", currentTime);
            const newRootReducer = require("./rootReducer").default;
            store.replaceReducer(connectRouter(history)(newRootReducer));
        });

        module.hot.accept("./rootSaga", () => {
            // eslint-disable-next-line no-console
            console.log("%c*rootSaga hot-reload fired @ %s", "font-size: 16px; color: rgb(95, 225, 143);", currentTime);
            store.dispatch(END);  // Ends all current sagas. Without it new sagas would be added and all of them (including the old ones) would be fired.
            const newRootSaga = require("./rootSaga").default;
            sagaMiddleware.run(newRootSaga);
        });
    }

    return store;
}

// Create redux store with history
const initialState = {};
export const history = createBrowserHistory();
export const store = configureStore(initialState, history);
// the store is exported in order to be accessible from regular JS functions, not only from React components
// (via react-redux - it would be enough to create the store in index.js and use it in <Provider />).
// This creates a singleton and won't work if we ever go with SSR (Server Side Rendering).
