import { all, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import { TYPES, onGoBack } from "./SiPiTemplateActions";
import { push } from "connected-react-router";
import firebase, { sipiData } from "../dataSource";
import { convertListToDataset } from "../../utils/selectorUtils";
import { toastError, DB_ERROR, sagaToastError } from "../../component/toast";
import { onSetResources } from "../component/ResourceLink/ResourceLinkActions";
import { templateLessonUnits } from "./SiPiTemplateSelectors";

const basePath = "sipi_templates";
function* goBack () {
    yield put(push("/iplanner/templates"));
}

function* updateSiPiOrder ({ payload: { lessonUnitId, orderedSiPi, iPlannerId }}) {
    try {
        const data = convertListToDataset(orderedSiPi);
        yield sipiData.updateSiPiContent(basePath, iPlannerId, lessonUnitId, data);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update order of SiPi content", message: DB_ERROR });
    }
}

function* updateSiPi ({ payload: {iPlannerId, lessonUnitId, sipiId, data }}) {
    try {
        yield sipiData.updateSiPi(basePath, iPlannerId, lessonUnitId, sipiId, data);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update SiPi", message: DB_ERROR });
    }
}

function* addSiPi ({ payload: {iPlannerId, lessonUnitId, data }}) {
    try {
        yield sipiData.addSiPi(basePath, iPlannerId, lessonUnitId, data);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to add new SiPi", message: DB_ERROR });
    }
}

function* removeSiPi ({ payload: {iPlannerId, lessonUnitId, sipiId }}) {
    try {
        yield sipiData.removeSiPi(basePath, iPlannerId, lessonUnitId, sipiId);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to remove SiPi", message: DB_ERROR });
    }
}

export function* loadResourcesForIPlanner (entityId, entityType) {
    const sipiResources= yield firebase.tabletProxy({
        method: "GET",
        uri: `/resources?entity_id=${entityId}&entity_type=${entityType}`,
    });
    if (sipiResources.data && 0 < sipiResources.data.length) {
        const resources = {};
        for (const resource of sipiResources.data) {
            resources[resource.id] = resource;
        }
        yield put(onSetResources(resources));
    }
}

function* loadTemplateResources ({ payload: { iPlannerId } }) {
    try {
        yield loadResourcesForIPlanner(iPlannerId, "template");
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load resources for this Template", message: DB_ERROR });
    }
}

function* updateLesson({ payload: { name, unitName, ...data} }) {
    try {
        yield sipiData.updateLesson(basePath, { ...data, name: `${unitName} :: ${name}`});
    } catch (error) {
        sagaToastError("Failed to update template lesson.", error);
    }
}

function* createLesson({ payload: { name, unitName, iPlannerId, ...data} }) {
    try {
        const lessonUnits = yield select(templateLessonUnits);
        let index = 0;
        if (lessonUnits[iPlannerId]) {
            const indexes = Object.keys(lessonUnits[iPlannerId]);
            index = Number.parseFloat(indexes[indexes.length - 1]) + 1;
        }
        yield sipiData.createLesson(basePath, { ...data, name: `${unitName} :: ${name}`, index, iPlannerId });
    } catch (error) {
        sagaToastError("Failed to create template lesson.", error);
    }
}

function* removeLesson({ payload: {iPlannerId, lessonId }}) {
    try {
        const lessonUnits = yield select(templateLessonUnits);
        if (lessonUnits[iPlannerId]) {
            const remainingLessons = lessonUnits[iPlannerId].filter(l => l.cv_lu_id !== lessonId);
            yield sipiData.updateLessonsList(basePath, iPlannerId, remainingLessons);
        }
        
    } catch (error) {
        sagaToastError("Failed to template lesson.", error);
    }
}

function* updateLessonOrder({ payload: { iPlannerId, lessonUnits }}) {
    try {
        yield sipiData.updateLessonsList(basePath, iPlannerId, lessonUnits);
    } catch (error) {
        sagaToastError("Failed to update lesson order.", error);
    }
}

function* updateName({ payload: { iPlannerId, name }}) {
    try {
        yield sipiData.updateName(basePath, iPlannerId, { name });
    } catch (error) {
        sagaToastError("Failed to update iPlanner template name.", error);
    }
}

function* removeTemplate({ payload }) {
    try {
        yield sipiData.removeiPlanner(basePath, payload);
        yield put(onGoBack());
    } catch (error) {
        sagaToastError("Failed to remove iPlanner template.", error);
    }
}

export default function* syllabusOutline() {
    yield all([
        takeLatest(TYPES.SIPI_TEMPLATE_GO_BACK, goBack),
        takeLatest(TYPES.SIPI_TEMPLATE_UPDATE_ACTIVITIES_ORDER, updateSiPiOrder),
        takeEvery(TYPES.SIPI_TEMPLATE_UPDATE_ACTIVITY, updateSiPi),
        takeEvery(TYPES.SIPI_TEMPLATE_ADD_ACTIVITY, addSiPi),
        takeLatest(TYPES.SIPI_TEMPLATE_REMOVE_ACTIVITY, removeSiPi),
        takeLatest(TYPES.SIPI_TEMPLATE_LOAD_IPLANNER_RESOURCES, loadTemplateResources),
        takeEvery(TYPES.SIPI_TEMPLATE_UPDATE_LESSON, updateLesson),
        takeEvery(TYPES.SIPI_TEMPLATE_CREATE_LESSON, createLesson),
        takeEvery(TYPES.SIPI_TEMPLATE_REMOVE_LESSON, removeLesson),
        takeLatest(TYPES.SIPI_TEMPLATE_UPDATE_LESSON_ORDER, updateLessonOrder),
        takeLatest(TYPES.SIPI_TEMPLATE_UPDATE_NAME, updateName),
        takeEvery(TYPES.SIPI_TEMPLATE_REMOVE, removeTemplate),
    ]);
}
