import { EditorState, Modifier } from "draft-js";
import { getSelectionEntity, getSelectionText, getEntityRange } from "draftjs-utils";

import { expandEntitySelection } from "../utils/utils";
import { ENTITY_TYPE } from "../constants";

const getSelectionAsLessonLink = (editorState) => {
    const currentEntityKey = getSelectionEntity(editorState);

    if (currentEntityKey) {
        const entity = editorState.getCurrentContent().getEntity(currentEntityKey);

        if (entity.getType() === ENTITY_TYPE.LESSON_LINK) {
            const data = entity.getData();

            return {
                currentEntityKey,
                currentText: data.name,
                currentTermKey: data.key,
            };
        }
    }

    return {
        currentText: getSelectionText(editorState),
    };
};

// Inspired by https://github.com/jpuri/react-draft-wysiwyg/blob/7bade41044c62045f1a1d86d8cfd5b455e6003bd/src/controls/Link/index.js
const linkLesson = (editorState, lessonLinkInfo) => {
    const { name } = lessonLinkInfo;
    const selection = editorState.getSelection();
    const linkText = getSelectionText(editorState) || name;  // when no text is currently selected, the lesson name will inserted as a text of a new entity.

    const entityKey = editorState
        .getCurrentContent()
        .createEntity(ENTITY_TYPE.LESSON_LINK, "MUTABLE", lessonLinkInfo)
        .getLastCreatedEntityKey();

    const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        selection,
        `${linkText}`,
        editorState.getCurrentInlineStyle(),
        entityKey,
    );

    let newEditorState = EditorState.push(editorState, contentState, "insert-characters");
    return EditorState.acceptSelection(newEditorState, contentState.getSelectionAfter());
};

const unlinkLessonLink = (editorState, entityKey) => {
    const entityRange = getEntityRange(editorState, entityKey);
    let selection = editorState.getSelection();
    selection = expandEntitySelection(entityRange, selection);
    const contentState = Modifier.applyEntity(editorState.getCurrentContent(), selection, null);
    return EditorState.push(editorState, contentState, "apply-entity");
};

export {
    getSelectionAsLessonLink,
    linkLesson,
    unlinkLessonLink,
};
