import { TYPES } from "./topicSelectorActions";

const initialState = {
    searchFilter: "",
    showOnlySelected: false,
    selectedCollection: localStorage.getItem("selectedCollection"),
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.TS_SEARCH_CHANGED:
            return { ...state, searchFilter: payload };
        case TYPES.TS_SHOW_ONLY_SELECTED:
            return { ...state, showOnlySelected: payload };
        case TYPES.TS_SELECT_COLLECTION:
            return { ...state, selectedCollection: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
