import firebase from "../firebase";

export const getItemCollectionByParent = (fbCollection) => {
    switch (fbCollection) {
        case "problemSet": return "problem";
        case "comptests_common": return "comptests_cvault";
        default:
            // eslint-disable-next-line no-console
            console.error(`Unsupported collection "${fbCollection}" in problemManagementSelectors`);
    }
};

export const getItemLockCollectionByParent = (fbCollection) => {
    switch (fbCollection) {
        case "problemSet": return "p";
        case "comptests_common": return "ctq";
        default:
            // eslint-disable-next-line no-console
            console.error(`Unsupported collection "${fbCollection}" in problemManagementSelectors`);
    }
};

export const getTagCollectionByParent = (fbCollection) => {
    switch (fbCollection) {
        case "problemSet": return "tags_problems";
        case "comptests_common": return "tags_questions";
        default:
            // eslint-disable-next-line no-console
            console.error(`Unsupported collection "${fbCollection}" in problemManagementSelectors`);
    }
};

/**
 * Updates the children of problem/question by moving a specified child in desired direction.
 *
 * @param {string} fbItemCollection one of the "problem" or "comptests_cvault"
 * @param {string} parentId ID of the parent problem/question
 * @param {string} childId ID of the child to be moved by one
 * @param {string} direction where to move - "up" or "down"
 * @returns {string[]|null} list of affected problem sets or null if not modified
 */
export async function moveChildQuestionOf(fbItemCollection, parentId, childId, direction) {
    // Get reference parent problem/question in Firebase
    const parentRef = firebase.getFirebaseData(`${fbItemCollection}/${parentId}`);
    const question = (await parentRef.once("value")).val();
    const children = question && [ ...question.children ]; // Clone children to allow changes
    if (!(children && children.length)) {
        return null; // No children, nothing to change
    }

    // Find indexes of entries to swap
    const source = children.indexOf(childId);
    const target = source < 0 ? source : (direction === "up" ? source - 1 : source + 1);
    if (target < 0 || target >= children.length) {
        return null; // Don't let indexes to fall out of bounds
    }

    // Swap entries & write modified record
    const temp = children[target];
    children[target] = children[source];
    children[source] = temp;
    await parentRef.child("children").set(children);

    return question.problemSets ? Object.keys(question.problemSets) : null;
}


/**
 * Change position of given entry within the problem set or comprehensive test.
 * 
 * Important: This function has been extracted from saga to share code between
 * comp.tests and problem sets. It's still a very ugly piece of code.
 * Array.splice could be used probably to do the same thing.
 * 
 * @param {string} fbPath Firebase path to the problem set or comprehensive test
 * @param {string[]} entries original list of entries
 * @param {string} entryId ID of entry to by moved
 * @param {number} source source position
 * @param {number} target target position
 * @returns {string[]} re-ordered list of entries
 */
export async function setQuestionPosition(fbPath, entries, entryId, source, target) {
    if (source === target || !Array.isArray(entries)) {
        return entries;
    }
    const upwards = target < source;
    // Place the entry ID to new position
    const ordered = [];
    ordered[target] = entryId;
    // Place other entries from around it
    let found = false;
    entries.forEach((id, index) => {
        if (id === entryId) {
            found = true;
            return;
        }
        if (upwards) {
            // If the item was moved up then all elements below it should have
            // their position index increased by 1 starting at target position.
            if (index >= target && !found) {
                ordered[index + 1]  = id;
            } else {
                ordered[index]  = id;
            }
        } else {
            // If the item was moved down then all elements between source and
            // destination should be shifted up (index position decreased by 1).
            if (found && index <= target) {
                ordered[index - 1]  = id;
            } else {
                ordered[index]  = id;
            }
        }
    });

    // Write as entries of the given problem set/comprehensive test
    if (fbPath) {
        const dataRef = firebase.getFirebaseData(fbPath);
        await dataRef.child("problems").set(ordered);
    }
    return ordered;
}

async function getProblemSetRefsForEntry(db, fbItemCollection, targetId, entryId) {
    const children = (
        await db.ref(`${fbItemCollection}/${entryId}/children`).once("value")
    ).val() || [];
    children.push(entryId);

    return children.map((childId) => `${fbItemCollection}/${childId}/problemSets/${targetId}`);
}

/**
 * Attach question to the given problem set or comprehensive test.
 * 
 * @param {string} fbCollection one of the "problemSet" or "comptests_common"
 * @param {string} targetId ID of the problem set or comprehensive test
 * @param {string[]} toAttach list of IDs (problems/questions) to attach
 * @returns {boolean} true if target was modfied (entries added to it)
 */
export async function attachQuestionsTo(fbCollection, targetId, toAttach) {
    if (!(fbCollection && targetId)) {
        throw new Error("Invalid parameters - collection and ID expected.");
    }
    const db = firebase.defaultApp.database();
    const fbEntries = `${fbCollection}/${targetId}/problems`;

    // Query the current comp.test/problem set entries
    const questions = (await db.ref(fbEntries).once("value")).val();
    const existing = new Set(questions);
    const incoming = Array.isArray(toAttach) ? toAttach : (
        toAttach && typeof(toAttach) === "string" ? [ toAttach ] : []
    );
    if (!incoming.length) {
        return false;
    }

    // Prepare the Firebase record changes
    const fbItems = getItemCollectionByParent(fbCollection);
    const changes = await incoming.reduce(async (accAsync, questionId) => {
        const accChanges = await accAsync;
        if (!existing.has(questionId)) {
            const fbSetRefs = await getProblemSetRefsForEntry(db, fbItems, targetId, questionId);
            accChanges.refs = fbSetRefs.reduce((acc, ref) => (acc[ref] = true, acc), accChanges.refs);
            accChanges.list.push(questionId);
        }
        return accChanges;
    }, {
        list: (questions || []),
        refs: {},
    });
    changes.refs[fbEntries] = changes.list;

    await db.ref().update(changes.refs);

    return true;
}

export async function detachQuestionFrom(fbCollection, targetId, entryId, entries) {
    if (!(fbCollection && targetId)) {
        throw new Error("Invalid parameters - collection and ID expected.");
    }
    if (!entryId) {
        return false;
    }
    const db = firebase.defaultApp.database();
    const fbEntries = `${fbCollection}/${targetId}/problems`;
    const fbSetRefs = await getProblemSetRefsForEntry(db, getItemCollectionByParent(fbCollection), targetId, entryId);

    // Get the collection entries (problems/questions)
    const entryList = Array.isArray(entries) ? entries : (
        (await db.ref(fbEntries).once("value")).val()
    ) || [];

    // Get the changes (new entry list & null of entry-to-collection refs)
    const changes = fbSetRefs.reduce((acc, ref) => (acc[ref] = null, acc), {
        [fbEntries]: entryList.filter((v) => v !== entryId),
    });

    await db.ref().update(changes);

    return true;
}

/**
 * Remove the given problem set or comprehensive test (and detach its entries).
 * 
 * @param {string} fbCollection one of the "problemSet" or "comptests_common"
 * @param {string} targetId ID of the problem set or comprehensive test
 * @param {string[]} entries list of entry IDs (will be loaded if undefined)
 */
export async function removeQuestionCollection(fbCollection, targetId, entries) {
    if (!(fbCollection && targetId)) {
        throw new Error("Invalid parameters - collection and ID expected.");
    }
    const db = firebase.defaultApp.database();
    const fbTarget = `${fbCollection}/${targetId}`;
    const fbItems = getItemCollectionByParent(fbCollection);

    // Get the collection entries (problems/questions)
    const entryList = Array.isArray(entries) ? entries : (
        (await db.ref(fbTarget).child("problems").once("value")).val()
    ) || [];

    // Get the changes (null collection & entry-to-collection refs)
    const changes = entryList.reduce((acc, entryId) => {
        acc[`${fbItems}/${entryId}/problemSets/${targetId}`] = null;
        return acc;
    }, {
        [fbTarget]: null,
    });
    await db.ref().update(changes);

    return true;
}

/**
 * Remove the child entry from given parent (problem or comprehensive question).
 *
 * @param {string} fbCollection Firebase collection - "problemSet" or "comptests_common"
 * @param {string} targetId the collection (ID of problem set or comprehensive test)
 * @param {string} parentId the parent entry (ID of problem or question)
 * @param {string} entryId the entry being removed
 * @returns {boolean} true if the entry was removed
 */
export async function removeChildQuestion(fbCollection, targetId, parentId, entryId) {
    if (!(fbCollection && targetId && parentId)) {
        throw new Error("Invalid parameters - collection and ID expected.");
    }
    if (!entryId) {
        return false;
    }
    const db = firebase.defaultApp.database();
    const fbItemCollection = getItemCollectionByParent(fbCollection);
    const fbChildren = `${fbItemCollection}/${parentId}/children`;
    const fbRemoving = `${fbItemCollection}/${entryId}`;

    // Remove problem from the parent and remove the entry-to-collection ref
    const children = (await db.ref(fbChildren).once("value")).val() || [];
    const changes = {
        [fbChildren]: children.filter((v) => v !== entryId),
        [fbRemoving]: null,
    };
    await db.ref().update(changes);

    return true;
}

/**
 * Create a new child withing the given parent (problem or comprehensive question).
 *
 * @param {string} fbItemCollection either "problem" or "comptests_cvault"
 * @param {string} parentId ID of the parent entry
 * @param {object} itemData data of item being created
 * @returns {string[]} list of collections (p.sets, comp.tests) affected by the change
 */
export async function insertChildQuestion(fbItemCollection, parentId, itemData) {
    if (!(fbItemCollection && parentId)) {
        throw new Error("Invalid parameters - collection and ID expected.");
    }
    if (!itemData) {
        return null;
    }
    const db = firebase.defaultApp.database();
    // Insert data of new entry
    const { key:entryId } = await db.ref(fbItemCollection).push(itemData);
    const fbParent = `${fbItemCollection}/${parentId}`; // we will use "children" & "problemSets"
    // Attach to the parent one
    const parent = (await db.ref(fbParent).once("value")).val();
    const children = parent.children || [];
    children.push(entryId);
    // Update the parent entry & track usage
    const changes = {
        [`${fbParent}/children`]: children,
        [`${fbItemCollection}/${entryId}/problemSets`]: parent.problemSets,
    };
    await db.ref().update(changes);

    return parent.problemSets && Object.keys(parent.problemSets);
}

/**
 * Create a new question and attach it to the end of given collection.
 *
 * @param {string} fbCollection Firebase collection - "problemSet" or "comptests_common"
 * @param {string} targetId ID of the collection (problem set or comprehensive test)
 * @param {object} itemData data of item being created
 * @returns {string} ID of created item, null if no data to insert
 */
export async function insertQuestionTo(fbCollection, targetId, itemData) {
    if (!(fbCollection && targetId)) {
        throw new Error("Invalid parameters - collection and ID expected.");
    }
    if (!itemData) {
        return null;
    }
    const db = firebase.defaultApp.database();
    const fbItemCollection = getItemCollectionByParent(fbCollection);
    const fbEntries = `${fbCollection}/${targetId}/problems`;
    // Insert data of new entry
    const { key:entryId } = await db.ref(fbItemCollection).push(itemData);
    // Append to the entry list
    const entries = (await db.ref(fbEntries).once("value")).val() || [];
    entries.push(entryId);
    await db.ref(fbEntries).set(entries);

    return entryId;
}

/**
 * Remove question together with its children (they should not be used).
 *
 * @param {string} fbItemCollection Firebase collection - "problem" or "comptests_cvault"
 * @param {string} entryId ID of question/problem to be remove
 * @returns {string[]} list of removed entry IDs (entryId + children)
 */
export async function deleteQuestionById(fbItemCollection, entryId) {
    if (!(fbItemCollection && entryId)) {
        throw new Error("Invalid parameters - collection and ID expected.");
    }
    const db = firebase.defaultApp.database();
    const fbChildren = `${fbItemCollection}/${entryId}/children`;
    // Collect the Firebase references to be removed
    const entries = (await db.ref(fbChildren).once("value")).val() || [];
    entries.push(entryId);
    // Remove the given entry and its children (if any)
    const changes = entries.reduce((acc, id) => (acc[id] = null, acc), {});
    await db.ref(fbItemCollection).update(changes);

    return entries;
}

/**
 * Changes the parent problem answer to null (i.e. to be removed in database).
 *
 * @param {object} entry question or problem to be modified
 * @returns {object} modified input to allow chaining (not a clone)
 */
export const clearAnswerIfParent = (entry) => {
    const type = entry && (entry.problemType || entry.type);
    if (type === "pa") {
        delete entry.answer;
    }
    return entry;
};
