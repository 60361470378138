import React from "react";
import PropTypes from "prop-types";
import { Header, Label, Segment } from "semantic-ui-react";
import cx from "classnames";

import "./dashboardTile.css";

export default class DashboardTile extends React.PureComponent {
  static propTypes = {
      title: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      notification: PropTypes.number,
      completeness: PropTypes.number,
      className: PropTypes.string,
      isLoading: PropTypes.bool,
      design: PropTypes.object,
      children: PropTypes.any,
  };

  renderNotification = notification => {
      const { labelNotification, labelNotificationStyle } = this.props.design;
      const attached = labelNotificationStyle === "attached" ? "top right" : null;

      return labelNotification ? (
          <Label
              color="orange"
              attached={attached}
              className={this.props.design.labelNotificationStyle}
          >
              {notification}
          </Label>
      ) : (
          <div className="notification">{notification}</div>
      );
  };

  renderIncomplete = () => (
      <div className="incomplete">
          <div className="curved topEdge" />
          <div className="curved leftEdge" />
      </div>
  );

  getAreaStyle = (x, y, width, height) => {
      return document.documentMode
          ? // MSIE 11 - grid-gap is emulate by margin right/bottom
          {
              msGridRow: y,
              msGridColumn: x,
              msGridRowSpan: height,
              msGridColumnSpan: width,
              marginRight: "16pt",
              marginBottom: "16pt"
          }
          : // modern browsers
          { gridArea: `${y} / ${x} / ${y + height} / ${x + width}` };
  };

  render() {
      const { title, className, x, y, width, height, notification, completeness, isLoading, children, design } = this.props;
      const isIncomplete = completeness && completeness < 100;
      const { incomplete: designIncomplete, ...designCls } = design;
      const cls = cx("dashboardTile", className, { incomplete: isIncomplete }, designCls, designIncomplete);

      return <Segment className={cls} loading={isLoading} style={this.getAreaStyle(x, y, width, height)}>
          {notification && notification > 0 ? this.renderNotification(notification) : null}
          <div className="content">
              <Header>{title}</Header>
              {children}
              {isIncomplete ? this.renderIncomplete() : null}
          </div>
      </Segment>;
  }
}
