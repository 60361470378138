import React, { Component } from "react";

import PropTypes from "prop-types";

class LessonLinkDecorator extends Component {
    static propTypes = {
        entityKey: PropTypes.string.isRequired,
        getEditorState: PropTypes.func.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { getEditorState, entityKey } = this.props;
        const entity = getEditorState().getCurrentContent().getEntity(entityKey);
        const data = entity.getData();

        return (
            <a  style={{cursor: "pointer"}} title={`Link to ${data.name}`}>
                {this.props.children}
            </a>
        );
    }
}

export default LessonLinkDecorator;
