import React, { PureComponent } from "react";
import { Modal, Form, Message, Icon, Grid, Button } from "semantic-ui-react";

import PropTypes from "prop-types";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";
import WorkerList from "./component/WorkerList";
import "./EditKit.css";
import CourseVariantAssignment from "./component/CourseVariantAssignment";
import EditKitMenu from "./component/EditKitMenu";
import KitTags from "./component/KitTags";

export default class EditKit extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        open: PropTypes.bool,
        onUpdate: PropTypes.func,
        saving: PropTypes.bool,
        isSuperAuthorAtLeast: PropTypes.bool,
        selectedKit: PropTypes.object,
        selectedYear: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        currentTenant: PropTypes.string,
        isDirty: PropTypes.bool,
        onSetDirtyFlag: PropTypes.func,
        allUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        authorOptions: PropTypes.array,
        reviewerOptions: PropTypes.array,
        onChangeRole: PropTypes.func,
        reviewers: PropTypes.array,
        authors: PropTypes.array,
        commentRecipients: PropTypes.array,
        commentRecipientOptions: PropTypes.array,
        firebase: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.props.firebase.watchEvent("value", "user", null, {isQuery: true, queryParams: [ "orderByChild=valid", "equalTo=true" ]});
        this.props.firebase.watchEvent("value", "subjects");
    }

    componentDidUpdate(prevProps) {
        const { open } = this.props;
        if (!prevProps.open && open) {
            this.handleReset();
        }
    }
    state = {
        name: "",
        error: false,
        activeItem: "general",
    };

    handleChangeName = (e, { value }) => {
        const { selectedKit, onSetDirtyFlag } = this.props;
        this.setState({ name: value, error: false });
        onSetDirtyFlag(selectedKit.name !== value.trim());
    };

    handleItemClick = (e, { name }) => this.setState({ activeItem: name });

    handleSave = () => {
        const { name } = this.state;
        const { onUpdate, selectedKit, isDirty } = this.props;
        if (isDirty && name && 0 < name.trim().length) {
            onUpdate({ id: selectedKit.id, name });
        }
    };

    handleReset = () => {
        const { selectedKit, onSetDirtyFlag } = this.props;
        this.setState({ name: selectedKit.name || "", error: false, activeItem: "general" });
        onSetDirtyFlag(false);
    }

    handleAddAuthor = (uid) => {
        const { onChangeRole, onUpdate, selectedKit } = this.props;
        onChangeRole({ uid, roles: { ROLE_AUTHOR: true} });
        onUpdate({ id: selectedKit.id, authors: { ...selectedKit.authors, [uid]: true }});
    }

    handleAddReviewer = (uid) => {
        const { onChangeRole, onUpdate, selectedKit } = this.props;
        onChangeRole({ uid, roles: { ROLE_REVIEWER: true} });
        onUpdate({ id: selectedKit.id, reviewers: { ...selectedKit.reviewers, [uid]: true }});
    }

    handleRemoveAuthor = (userId) => {
        const { onUpdate, selectedKit } = this.props;
        onUpdate({ id: selectedKit.id, authors: { ...selectedKit.authors, [userId]: null }});
    }

    handleRemoveReviewer = (userId) => {
        const { onUpdate, selectedKit } = this.props;
        onUpdate({ id: selectedKit.id, reviewers: { ...selectedKit.reviewers, [userId]: null }});
    }

    handleAddRecipient = (uid) => {
        const { onUpdate, selectedKit } = this.props;
        onUpdate({ id: selectedKit.id, comment_recipients: { ...selectedKit.comment_recipients, [uid]: true }});
    }
    handleRemoveRecipient = (uid) => {
        const { onUpdate, selectedKit } = this.props;
        onUpdate({ id: selectedKit.id, comment_recipients: { ...selectedKit.comment_recipients, [uid]: null }});
    }
    handleSaveTags = (tags) => {
        const { onUpdate, selectedKit } = this.props;
        onUpdate({ id: selectedKit.id, tags });
    }

    render() {
        const {
            open,
            onClose,
            saving,
            isSuperAuthorAtLeast,
            selectedKit,
            isDirty,
            allUsers,
            authorOptions,
            reviewerOptions,
            reviewers,
            authors,
            commentRecipients,
            commentRecipientOptions,
            selectedYear,
            currentTenant,
        } = this.props;
        const { name, error, activeItem } = this.state;
        const emptyKitName = !name || !name.trim().length;
        return selectedKit ? (
            <Modal open={open} size="large" style={{width: "1140px"}}>
                <Modal.Header>{isSuperAuthorAtLeast ? "Update properties of " : "Properties of "}<span className="sporkSecondaryColor">{selectedKit.name || "Unnamed Author Kit"}</span></Modal.Header>
                <Modal.Content style={{minHeight: "350px"}}>
                    <Grid>
                        <Grid.Column width={3}>
                            <EditKitMenu activeItem={activeItem} onItemClick={this.handleItemClick} isDirty={isDirty}/>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            {"general" === activeItem ? (
                                <React.Fragment>
                                    {isSuperAuthorAtLeast && <Message info icon><Icon name="pointing right" />Here you can edit the general properties of this author kit.</Message>}
                                    <Form name="name" onSubmit={this.handleSave}>
                                        <Form.Input
                                            required
                                            readOnly={!isSuperAuthorAtLeast}
                                            label="Name"
                                            placeholder="Fill name of new Author Kit"
                                            value={name || ""}
                                            error={error && emptyKitName}
                                            onChange={this.handleChangeName}
                                        />
                                        {isDirty && <SaveCancelButtons  onCancel={this.handleReset} onSave={this.handleSave} saveDisabled={emptyKitName} />}
                                    </Form>
                                </React.Fragment>
                            ) : "courses" === activeItem ? (
                                <CourseVariantAssignment
                                    defaultYear={selectedYear && selectedYear.name}
                                    defaultTenant={currentTenant}
                                />
                            ) : "authors" === activeItem ? (
                                <WorkerList
                                    allUsers={allUsers}
                                    entityName="author"
                                    userList={authors || []}
                                    options={authorOptions}
                                    onAdd={this.handleAddAuthor}
                                    onRemove={this.handleRemoveAuthor}
                                    canEdit={isSuperAuthorAtLeast}
                                />
                            ) : "reviewers" === activeItem ? (
                                <WorkerList
                                    allUsers={allUsers}
                                    entityName="reviewer"
                                    userList={reviewers || []}
                                    options={reviewerOptions}
                                    onAdd={this.handleAddReviewer}
                                    onRemove={this.handleRemoveReviewer}
                                    canEdit={isSuperAuthorAtLeast}
                                />
                            ) : "tags" === activeItem ? (
                                <KitTags selectedKit={selectedKit} canEdit={isSuperAuthorAtLeast} onSaveTags={this.handleSaveTags}/>
                            ) : <WorkerList
                                allUsers={allUsers}
                                entityName="comment recipient"
                                userList={commentRecipients || []}
                                options={commentRecipientOptions}
                                onAdd={this.handleAddRecipient}
                                onRemove={this.handleRemoveRecipient}
                                canEdit={isSuperAuthorAtLeast}
                            />}
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onClose} disabled={isDirty}>Close</Button>
                </Modal.Actions>
                {saving && (
                    <Message icon positive>
                        <Icon name="circle notched" loading />
                        <Message.Content>Saving changes ...</Message.Content>
                    </Message>
                )}
            </Modal>
        ) : null;
    }
}
