import React from "react";
import { Route, withRouter } from "react-router-dom";
import decorate from "decorate-component-with-props";
import { connect } from "react-redux";

import AccessControl from "../auth";
import { tenantId } from "../Users/UserProfile/UserProfileSelectors";
import ProblemSetCreator from "./ProblemSetCreator/ProblemSetCreatorContainer";
import ProblemManagement from "./ProblemManagement/ProblemManagmentContainer";
import ProblemSetManager from "./ProblemSetManager/problemSetManagerContainer";

const problemSetProps = { fbCollection: "problemSet" };
const compTestProps = { fbCollection: "comptests_common" };

const TaskToolPriviliges = [ "problemSet:visit", "compTests:charter" ];

const Routes = (props) => (
    <AccessControl actionArray={TaskToolPriviliges} data={props}>
        <AccessControl action="problemSet:visit">
            <Route path="/qu/problem-set-creator/:id?" component={decorate(ProblemSetCreator, problemSetProps)} />
            <Route exact path="/qu/problem-management" component={decorate(ProblemManagement, problemSetProps)} />
            <Route exact path="/qu/problem-set-manager" component={decorate(ProblemSetManager, problemSetProps)} />
        </AccessControl>
        <AccessControl action="compTests:charter" data={props}>
            <Route path="/qu/comp-test-editor/:id?" component={decorate(ProblemSetCreator, compTestProps)} />
            <Route exact path="/qu/question-management" component={decorate(ProblemManagement, compTestProps)} />
            <Route exact path="/qu/comp-test-manager" component={decorate(ProblemSetManager, compTestProps)} />
        </AccessControl>
    </AccessControl>
);

const mapStateToProps = (state, props) => ({
    tenantId: tenantId(state, props)
});
const dispatchToProps = {};

export default withRouter(connect(mapStateToProps, dispatchToProps)(Routes));
