const TYPES = {
    BTC_SELECT_COLLECTION: "BTC_SELECT_COLLECTION",
    BTC_TOPIC_STATUS_CHANGE: "BTC_TOPIC_STATUS_CHANGE",
    BTC_TOPIC_LINK_TO_BELA: "BTC_TOPIC_LINK_TO_BELA",
    BTC_SET_REC_OBJECTIVE_SET: "BTC_SET_REC_OBJECTIVE_SET",
    BTC_TOPIC_CREATE_MODE: "BTC_TOPIC_CREATE_MODE"
};

const onSelectCollection = value => {
    return {
        type: TYPES.BTC_SELECT_COLLECTION,
        value
    };
};

const onTopicStatusChange = (path, value) => {
    return {
        type: TYPES.BTC_TOPIC_STATUS_CHANGE,
        value,
        path
    };
};

const onTopicLinkToBela = (sporkTopicId, belaTopic) => {
    return {
        type: TYPES.BTC_TOPIC_LINK_TO_BELA,
        sporkTopicId,
        belaTopic
    };
};

const onSetRecObjectiveSet = (collection, objectiveSetId) => {
    return {
        type: TYPES.BTC_SET_REC_OBJECTIVE_SET,
        collection,
        objectiveSetId
    };
};

const onSetTopicCreateMode = value => {
    return {
        type: TYPES.BTC_TOPIC_CREATE_MODE,
        value
    };
};

export {
    TYPES,
    onSelectCollection,
    onTopicStatusChange,
    onTopicLinkToBela,
    onSetRecObjectiveSet,
    onSetTopicCreateMode
};
