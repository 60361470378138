import React from "react";
import PropTypes from "prop-types";
import SnippetCustomizer from "../../component/seamlessEditor/bookEditor/snippetEntity/SnippetCustomizer";

class HiddenTitle extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string
    };

    render() {
        const title = this.props.type
            ? `${this.props.type.toLowerCase()} item title`
            : "widget title";
        return (
            <span><i style={{color: "grey"}}>This {title} is hidden. You can click on the icon to customize it.</i></span>
        );
    }
}

const buildHeaderProps = (type, header, customTitle, onChangeTitle, hiddenTitle) => {
    const { title = false, level = 0, divider = false } = typeof header === "object" ? header : { title: "" + header };
    if (title === false || (hiddenTitle && !onChangeTitle)) {
        return null;
    }
    const currentTitle = customTitle ? customTitle : title;

    const Component = hiddenTitle ? "div" : (level > 0 && level < 7 ? `h${level}` : (type ? "h3" : "h2"));
    const className = divider && !hiddenTitle
        ? "header divider actionIcons editable"
        : "header actionIcons editable";

    return (
        <Component className={className} {...!hiddenTitle && { title: currentTitle}} >
            {hiddenTitle ? <HiddenTitle type={type} /> : currentTitle}
            {onChangeTitle && (
                <SnippetCustomizer inline blockData={{title: currentTitle}} onChange={onChangeTitle} hiddenTitle={hiddenTitle} />
            )}
        </Component>
    );
};

const appendStyleClasses = (classNames, style) => (
    style && "object" === typeof style
        ? Object.keys(style).reduce((classes, item) => {
            switch (item) {
                case "border":
                    classes.push("border");
                    classes.push(style[item]);
                    break;
                case "color":
                    classes.push(style[item]);
                    break;
                case "padded":
                case "fitted":
                case "sharp":
                case "shadow":
                    classes.push(item);
                    break;
            }
            return classes;
        }, classNames) : classNames
);

const buildBlockLayout = ({ type, header, styles, onChangeTitle, customTitle = null, hiddenTitle = false }) => {
    let outerClasses = ["spork", type ? "item" : "widget", "segment"];
    let innerClasses = ["content"];
    // If header should be separated then modify content
    if ((onChangeTitle || !hiddenTitle) && header && header.title && header.separated) {
        innerClasses = appendStyleClasses(innerClasses, styles);
    } else {
        outerClasses = appendStyleClasses(outerClasses, styles);
    }
    return {
        element: {
            className: outerClasses.join(" "),
        },
        caption: header && buildHeaderProps(type, header, customTitle, onChangeTitle, hiddenTitle),
        content: {
            className: innerClasses.join(" "),
        }
    };
};

export default class WidgetBlock extends React.PureComponent {
    static propTypes = {
        /** Fully-customized content of widget body */
        children: PropTypes.node,
        placeholder: PropTypes.string,
        header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        styles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        name: PropTypes.string,
        type: PropTypes.string,
        id: PropTypes.string,
        onChangeTitle: PropTypes.func,
        customTitle: PropTypes.string,
        hiddenTitle: PropTypes.bool,
    };

    render() {
        const { children, placeholder, id } = this.props;
        const layout = buildBlockLayout(this.props);

        return (
            <div {...layout.element} id={`item-${id}`} >
                {layout.caption}
                <div {...layout.content}>{children || placeholder}</div>
            </div>
        );
    }
}
