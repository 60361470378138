import { connect } from "react-redux";
import ProblemSetInfo from "./ProblemSetInfo";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router";

//selector (move to own file later)
const getProblemSet = (state, props) => state.firebase.data[props.fbCollection] && state.firebase.data[props.fbCollection][props.setId];

const mapStateToProps = function(state, props) {
    const problemSet = getProblemSet(state,props);
    return {
        problemSet,
    };
};

function mapDispatchToProps() {
    return {};
}

export default withRouter(compose(
    firebaseConnect(props => [`${props.fbCollection}/${props.setId}`]),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ProblemSetInfo));