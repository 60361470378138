/**
 * Lock types. The type defines the path in Firebase DB - /locks/<TYPE>
 */
export const TYPE = {
    LESSON: "l",
    WIDGET: "w",
    PROBLEM_SET: "ps",
    PROBLEM: "p",
    COMP_TEST: "ct",
    COMP_TEST_CVAULT: "ctq",
};
