import React from "react";
import { SideBarMenuLink } from "../component/SidebarMenuLink";
import { Menu } from "semantic-ui-react";
import AccessControl from "../auth/AccessControl";
import withFeatureFlags from "../component/withFeatureFlags";

const CourseVariantMenuItem = () => (
    <AccessControl action="courseVariant:manage">
        <SideBarMenuLink to="/external/courseVariants/list" text="Courses" />
    </AccessControl>
);

// eslint-disable-next-line react/prop-types
const YearsMenuItem = ({ features }) => (
    <AccessControl action="tablet:manage">
        {features && features.school_tool
            ? <SideBarMenuLink to="/external/years/list" text="Years" />
            : null
        }
    </AccessControl>
);
const YearsMenuItemWithFlags = withFeatureFlags(YearsMenuItem);

const CatalogSchoolsMenuItem = () => (
    <AccessControl action="tablet:manage">
        <SideBarMenuLink to="/external/schools/list" text="Schools" />
    </AccessControl>
);

const CatalogCoursesMenuItem = () => (
    <AccessControl action="tablet:manage">
        <SideBarMenuLink to="/section/courses" text="School Courses" />
    </AccessControl>
);

const CatalogUsersMenuItem = () => (
    <AccessControl action="tablet:manage">
        <SideBarMenuLink to="/section/users" text="Schedules" />
    </AccessControl>
);

const ExternalMenuItem = () => (
    <Menu.Item>
        <Menu.Header>School info</Menu.Header>
        <Menu.Menu>
            <YearsMenuItemWithFlags />
            <CourseVariantMenuItem />
            <CatalogSchoolsMenuItem />
            <CatalogCoursesMenuItem />
            <CatalogUsersMenuItem />
        </Menu.Menu>
    </Menu.Item>
);

const SidebarMenu = () => (
    <AccessControl actionArray={["tablet:manage", "courseVariant:manage"]}>
        <ExternalMenuItem />
    </AccessControl>
);

export default SidebarMenu;
