import React from "react";
import PropTypes from "prop-types";
import { Container, Button } from "semantic-ui-react";

import "./ColorSelection.scss";

class ColorSwatch extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        active: PropTypes.bool,
    }

    handleClick = (e) => {
        this.props.onClick(e, { value: this.props.value });
    }
    render() {
        const { value, active } = this.props;
        return <span className={`color-swatch ${value}`}  title={value || "No color"} onClick={this.handleClick} style={{ textAlign: "center"}} >
            <span style={{visibility: active ? "inherit" : "hidden"}}>&bull;</span>
        </span>;
    }
}

export default class ColorSelection extends React.PureComponent {
    static propTypes = {
        colors: PropTypes.arrayOf(PropTypes.string),
        defaultValue: PropTypes.string,
        onChange: PropTypes.func,
    };

    handleNoColor = (e) => {
        this.props.onChange(e, { value: null });
    }

    render() {
        const { colors, defaultValue, onChange } = this.props;
        return (
            <React.Fragment>
                <Button basic className="color-button" onClick={this.handleNoColor} content="No Color" size="mini" />
                <Container fluid className="color-select">
                    {colors.map((color) => <ColorSwatch key={color} value={color} onClick={onChange} active={defaultValue === color} />)}
                </Container>
            </React.Fragment>
        );
    }
}
