import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import { TYPES, onSelectOutline } from "./outlinesActions";
import /*firebase,*/ { outlineManager, lessonUnitManager, lessonManager } from "./DataSource";
import { toastError, DB_ERROR } from "../../component/toast";

/**
 * Id as used by a database to identify entities. For Firebase it is a string.
 * @typedef {string} DbId
 */

export function* outlineCreate( { payload: { name } } ) {

    try {
        if (!name || !name.trim()) {
            throw new Error("An outline name cound not be empty.");
        }

        const outlineId = yield outlineManager.createOutlineRecord(name);
        const lessonUnitId = yield lessonUnitManager.createLessonUnit(outlineId, "New lesson unit");
        yield lessonManager.createLesson(outlineId, lessonUnitId, "New lesson");

        yield put(onSelectOutline(outlineId));
        /* if you would like to select the lesson unit or even lesson, you can do it here too */

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Creating outline failed", message: DB_ERROR });
    }
}

export function* outlineRename( { payload: { id, name } } ) {

    try {
        if (!name || !name.trim())
            throw new Error("An outline name cound not be empty.");

        yield outlineManager.renameOutline(id, name);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Renaming outline failed", message: DB_ERROR });
    }
}

export function* outlineRemove( { payload: { id } } ) {

    try {
        if (!id) {
            // console.log("No outline id provided for remove.");
            return;
        }

        yield outlineManager.removeOutline(id);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Removing outline failed", message: DB_ERROR });
    }
}

// eslint-disable-next-line no-unused-vars
export function* lessonUnitCreate( { payload: { parentId: outlineId, name } } ) {

    try {
        if (!outlineId) {
            // console.log("No outline id provided for lesson unit create.");
            return;
        }

        const lessonUnitId = yield lessonUnitManager.createLessonUnit(outlineId, name);
        yield lessonManager.createLesson(outlineId, lessonUnitId, "New lesson");

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Creating lesson unit failed", message: DB_ERROR });
    }
}

export function* lessonUnitRename( { payload: { id, name } } ) {
    try {
        if (!id) {
            // console.log("No lesson unit id provided for renaming.");
            return;
        }

        yield lessonUnitManager.renameLessonUnit(id, name);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Renaming lesson unit failed", message: DB_ERROR });
    }
}

export function* lessonUnitUpdateDescription( { payload: { id, description } } ) {
    try {
        if (!id) {
            // console.log("No lesson unit id provided for description update.");
            return;
        }

        yield lessonUnitManager.changeDescriptionLessonUnit(id, description);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Updating lesson unit description failed", message: DB_ERROR });
    }
}

export function* lessonUnitMove( { payload: { id, targetContainerId, targetIndex } } ) {

    // console.log("Saga lesson unit move", id, targetContainerId, targetIndex);
    try {
        if (!id) {
            // console.log("No lesson unit id provided for moving.");
            return;
        }

        yield lessonUnitManager.moveLessonUnit(id, targetContainerId, targetIndex);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Moving lesson unit failed", message: DB_ERROR });
    }
}

export function* lessonUnitRemove( { payload: { id } } ) {
    try {
        if (!id) {
            // console.log("No lesson unit id provided for removal.");
            return;
        }

        yield lessonUnitManager.removeLessonUnit(id);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Removing lesson unit failed", message: DB_ERROR });
    }
}

export function* lessonRemove( { payload: { id } } ) {
    try {
        if (!id) {
            return;
        }

        yield lessonManager.removeLesson(id);

    } catch (error) {
        toastError({ code: error.code, header: "Removing lesson failed", message: DB_ERROR });
    }
}

export function* lessonAddTopics({ payload: { lessonId, outlineId, topicIds, nextOrder } }) {
    try {
        yield lessonManager.addTopicsToLesson(lessonId, outlineId, topicIds, nextOrder);
    } catch (error) {
        toastError({ code: error.code, header: "Adding topic(s) failed", message: DB_ERROR });
    }
}

export function* lessonRemoveTopics({ payload: { lessonId, outlineId, topicIds } }) {
    try {
        if (!lessonId) { return; }
        yield lessonManager.removeTopicsFromLesson(lessonId, outlineId, topicIds);

    } catch (error) {
        toastError({ code: error.code, header: "Removing topic(s) failed", message: DB_ERROR });
    }
}

export function* lessonMove( { payload: { id, targetContainerId, targetIndex } } ) {

    // console.log("Saga lesson move", id, targetContainerId, targetIndex);
    try {
        if (!id) {
            // console.log("No lesson id provided for moving.");
            return;
        }

        yield lessonManager.moveLesson(id, targetContainerId, targetIndex);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Moving lesson failed", message: DB_ERROR });
    }
}

export function* lessonMoveTopic( { payload: { id, targetContainerId, targetIndex, sourceContainerId } } ) {

    // console.log("Saga topic move in lesson", id, targetContainerId, targetIndex, sourceContainerId);
    try {
        if (!id) {
            // console.log("No topic id provided for moving.");
            return;
        }

        yield lessonManager.moveLessonTopic(id, targetContainerId, targetIndex, sourceContainerId);

    } catch (error) {
        // console.log(error);
        toastError({ code: error.code, header: "Moving topic failed", message: DB_ERROR });
    }
}

export function* lessonUpdateProperties({ payload }) {
    const { outlineId, lessonUnitId, lessonId, name, type, classes, objectives, length, startWeek, ordering, onFinish } = payload;
    try {
        yield lessonManager.updateLessonProperties(outlineId, lessonUnitId, lessonId, name, type, classes, length, startWeek, objectives, ordering);
        if (onFinish) {
            yield onFinish();
        }
    }
    catch (error) {
        if (onFinish) {
            yield onFinish({ code: error.code, header: "Updating lesson properties failed", message: DB_ERROR });
        }
    }
}

export function* lessonUpdateDescription({ payload }) {
    const { outlineId, lessonId, objectives, onFinish } = payload;
    try {
        yield lessonManager.updateLessonDescription(outlineId, lessonId, objectives);
        if (onFinish) {
            yield onFinish();
        }
    }
    catch (error) {
        if (onFinish) {
            yield onFinish({ code: error.code, header: "Updating lesson description failed", message: DB_ERROR });
        }
    }
}

export function* lessonUpdateName({ payload }) {
    const { outlineId, lessonId, name, onFinish } = payload;
    try {
        yield lessonManager.updateLessonName(outlineId, lessonId, name);
        if (onFinish) {
            yield onFinish();
        }
    }
    catch (error) {
        if (onFinish) {
            yield onFinish({ code: error.code, header: "Updating lesson name failed", message: DB_ERROR });
        }
    }
}

function* markTopicAsReview({ payload: { lessonId, outlineId, topicId, checked }}) {
    try {
        yield lessonManager.updateReviewTopic(outlineId, lessonId, topicId, checked);
    } catch (error) {
        toastError({ code: error.code, header: "Update topic properties failed", message: DB_ERROR });
    }
}

function* outlinePublish({ payload: { outlineId, lessons, callback } }){
    
    try {
        let completedCount = 0;
        yield Object.keys(lessons).map(function* (lessonId) {
          
            //update process started for lessonId
            yield put({ 
                type: TYPES.OUTLINE_MASS_OPERATION_UPDATE_PROGRESS, 
                payload: { 
                    lessonId,
                    status: "started",
                    totalCompleted: completedCount,
                    totalCount: Object.keys(lessons).length,
                } 
            });

            //process (empty is publish for preview, release to release, or publish to publish and release)
            let mode = "";
            switch (lessons[lessonId]){
                case "publish":
                    break;
                case "release":
                    mode = "release";
                    break;
                case "both":
                    mode = "publish";
                    break;
                default:
                    break;
            }

            const response = yield outlineManager.outlineLessonPublish(outlineId, lessonId, mode);
            if (!response){
                yield put({ 
                    type: TYPES.OUTLINE_MASS_OPERATION_UPDATE_PROGRESS, 
                    payload: { 
                        lessonId,
                        status: "error",
                        totalCompleted: completedCount,
                        totalCount: Object.keys(lessons).length,
                    } 
                });
            }else{
                //update process completed for lessonId
                yield put({ 
                    type: TYPES.OUTLINE_MASS_OPERATION_UPDATE_PROGRESS, 
                    payload: { 
                        lessonId,
                        status: "completed",
                        totalCompleted: ++completedCount,
                        totalCount: Object.keys(lessons).length,
                    } 
                });
            }
        });

        yield callback && callback({header: "Publishing done", message: "Publishing/releasing has completed."});

    } catch (error) {
        toastError({ code: error.code, header: "Publishing lessons failed", message: DB_ERROR });
    }
}



export default function* saga() {
    yield all([
        takeLatest(TYPES.OUTLINES_OUTLINE_CREATE, outlineCreate),
        takeLatest(TYPES.OUTLINES_OUTLINE_RENAME, outlineRename),
        takeLatest(TYPES.OUTLINES_OUTLINE_REMOVE, outlineRemove),
        takeLatest(TYPES.OUTLINES_LESSON_UNIT_CREATE, lessonUnitCreate),
        takeLatest(TYPES.OUTLINES_LESSON_UNIT_RENAME, lessonUnitRename),
        takeLatest(TYPES.OUTLINES_LESSON_UNIT_UPDATE_DESCRIPTION, lessonUnitUpdateDescription),
        takeLatest(TYPES.OUTLINES_LESSON_UNIT_MOVE, lessonUnitMove),
        takeLatest(TYPES.OUTLINES_LESSON_UNIT_REMOVE, lessonUnitRemove),
        takeLatest(TYPES.OUTLINES_LESSON_MOVE, lessonMove),
        takeLatest(TYPES.OUTLINES_LESSON_REMOVE, lessonRemove),
        takeLatest(TYPES.OUTLINES_LESSON_ADD_TOPICS, lessonAddTopics),
        takeLatest(TYPES.OUTLINES_LESSON_MOVE_TOPIC, lessonMoveTopic),
        takeLatest(TYPES.OUTLINES_LESSON_REMOVE_TOPICS, lessonRemoveTopics),
        takeLatest(TYPES.OUTLINES_LESSON_UPDATE_PROPERTIES, lessonUpdateProperties),
        takeEvery(TYPES.OUTLINE_LESSON_MARK_TOPIC_AS_REVIEW, markTopicAsReview),
        takeLatest(TYPES.OUTLINES_LESSON_UPDATE_DESCRIPTION, lessonUpdateDescription),
        takeLatest(TYPES.OUTLINES_LESSON_UPDATE_NAME, lessonUpdateName),
        takeLatest(TYPES.OUTLINE_PUBLISH_LESSONS, outlinePublish),
    ]);
}