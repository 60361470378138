import { combineReducers } from "redux";
import services from "./services";

export const getReducers = () =>
    combineReducers({
        years: services.year.reducer,
        kits: services.kit.reducer,
        package: services.package.reducer,
        packageTenant: services["package-tenant"].reducer,
        users: services.user.reducer,
        tablets: services.oauthclient.reducer,
    });

export default getReducers();
