import React from "react";
import PropTypes from "prop-types";
import { Input, Label, Button, Icon } from "semantic-ui-react";
import classNames from "classnames/bind";

import RemoveIcon from "../../../component/RemoveIcon";
import QuestionItemEditor from "../QuestionItemEditor";
import styles from "./MultipleChoice.module.scss";

let cx = classNames.bind(styles);

class MultipleChoiceAnswer extends React.PureComponent {
    static propTypes = {
        answerOptions: PropTypes.arrayOf(
            PropTypes.shape({
                option: PropTypes.string,
                raw: PropTypes.object,
                content: PropTypes.string,
                isCorrect: PropTypes.bool,
            })
        ),
        onUpdateAnswer: PropTypes.func.isRequired,
        sharedBar: PropTypes.object.isRequired,
        regContentFn: PropTypes.func,
        unregContentFn: PropTypes.func,
        updateOptionEditor: PropTypes.func,
        multipleCorrectAnswers: PropTypes.bool,
        onDirty: PropTypes.func,
        mc4Mode: PropTypes.bool,
    };

    state = {
        answerOptions: this.props.answerOptions,
        editorKeys: {}
    };

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        const { answerOptions } = this.props;
        this.props.onUpdateAnswer(answerOptions);
    }

    handleAddOption = () => {
        const { onUpdateAnswer } = this.props;
        const { answerOptions } = this.state;
        const nextOption = String.fromCharCode(97 + Object.keys(answerOptions).length);
        const updatedOptions = [...answerOptions, { option: nextOption.toUpperCase(), isCorrect: false }];
        this.setState({ answerOptions: updatedOptions }, onUpdateAnswer(updatedOptions));
    };

    handleDeleteOption = (e, id) => {
        const { onUpdateAnswer, updateOptionEditor } = this.props;
        const { answerOptions, editorKeys } = this.state;

        const updatedOptions = answerOptions
            .filter(item => item.option !== id)
            .map((o, index) => ({ ...o, option: String.fromCharCode(97 + index).toUpperCase() }));

        //update the editor keys to keep them in sync with the MC options
        delete editorKeys[id];
        const updatedEditorKeys = {};
        Object.keys(editorKeys).forEach((k, i) => {
            updatedEditorKeys[String.fromCharCode(97 + i).toUpperCase()] = editorKeys[k];
            updateOptionEditor(editorKeys[k], String.fromCharCode(97 + i).toUpperCase());
        });

        this.setState({ answerOptions: updatedOptions, editorKeys: updatedEditorKeys }, onUpdateAnswer(updatedOptions));
    };

    handleMarkAsAnswer = (e, { id }) => {
        const { onUpdateAnswer, multipleCorrectAnswers } = this.props;
        const { answerOptions } = this.state;
        let updatedOptions = [];

        if (multipleCorrectAnswers == true){
            updatedOptions = answerOptions.map(o => ({ ...o, isCorrect: o.option === id ? !o.isCorrect : o.isCorrect }));
        }else{
            updatedOptions = answerOptions.map(o => ({ ...o, isCorrect: o.option === id ? true : false }));
        }

        this.setState({ answerOptions: updatedOptions }, onUpdateAnswer(updatedOptions));
    };

    handleLockToggle = ({currentTarget}) => {
        const id = currentTarget.getAttribute("data");
        const { onUpdateAnswer } = this.props;
        const { answerOptions } = this.state;
        const updatedOptions = answerOptions.map(o => ({ ...o, isLocked: o.option === id ? !o.isLocked : undefined == o.isLocked ? false : o.isLocked }));
        this.setState({ answerOptions: updatedOptions }, onUpdateAnswer(updatedOptions));
    };

    handleSetEditorRef = (editorId, editorKey) => {
        const { editorKeys } = this.state;
        editorKeys[editorKey] = editorId;
        this.setState({ editorKeys });
    };

    getRandomKey = () => Math.random().toString(36).substring(7);

    render() {
        const { answerOptions, editorKeys } = this.state;
        const { sharedBar, regContentFn, unregContentFn, onDirty, mc4Mode } = this.props;

        const choices = answerOptions.map((opt) => {
            const editorKey = editorKeys[opt.option] || this.getRandomKey();
            return (
                <div key={editorKey} className={cx({answer: true, isCorrect: opt.isCorrect})}>
                    <Input labelPosition="right" type="text">
                        <Label
                            className={styles.optionLabel}
                            {...opt.isCorrect && { color: "green" }}
                            id={opt.option}
                            onClick={this.handleMarkAsAnswer}
                        >
                            {opt.option}
                        </Label>
                        <div className={styles.mcEditor}>
                            <QuestionItemEditor
                                sharedBar={sharedBar}
                                editorId={editorKey}
                                optionId={opt.option}
                                initialContent={opt.raw}
                                initialHtml={opt.content}
                                focusOnMount={false}
                                onSetRef={this.handleSetEditorRef}
                                regContentFn={regContentFn}
                                unregContentFn={unregContentFn}
                                onDirty={onDirty}
                            />
                        </div>
                        
                        {!mc4Mode && (
                            <Label data={opt.option} basic className={styles.innerAction} onClick={this.handleLockToggle}>
                                <Icon color="grey" name={opt.isLocked ? "lock" : "lock open"} />
                            </Label>
                        )}
                        <Label basic className={styles.action}>
                            {!mc4Mode && <RemoveIcon
                                onRemove={this.handleDeleteOption}
                                id={opt.option}
                                entityName={`answer ${opt.option}`}
                            />}
                        </Label>
                    </Input>
                </div>
            );
        });
        if (!mc4Mode) {
            const addChoice = (
                <Button key="_new" size="small" onClick={this.handleAddOption}>
                    <Icon name="plus" />Add Option
                </Button>
            );
            choices.push(addChoice);
        }
        return choices;
    }
}

MultipleChoiceAnswer.defaultProps = {
    answerOptions: [
        {
            option: "A",
            isCorrect: true,
            isLocked: false,
        },
        {
            option: "B",
            isCorrect: false,
            isLocked: false,
        },
        {
            option: "C",
            isCorrect: false,
            isLocked: false,
        },
        {
            option: "D",
            isCorrect: false,
            isLocked: false,
        },
    ],
    multipleCorrectAnswers: false,
};

export default MultipleChoiceAnswer;
