import React from "react";
import { List } from "semantic-ui-react";
export const DefaultPadding = "0.5em";

export const PaddingOptions = [
    { key: "fitted", text: "Fitted", value: "fitted" },
    { key: "padded", text: "Padded", value: "padded" }
];

export const buildPaddingOptions = (currentValue) => {
    if (!currentValue || PaddingOptions.find((o) => o.value === currentValue)) {
        return PaddingOptions;
    }
    return [
        { key: "custom", text: `Padding (custom: ${currentValue})`, currentValue, disabled: true },
        ...PaddingOptions
    ];
};

export const DefaultBorderRadius = "0.5em";

const RadiusOptions = [
    { key: "none", text: "Not rounded", value: "" },
    { key: "mini", text: "Box Corners (mini)", value: "0.25em" },
    { key: "tiny", text: "Box Corners (tiny)", value: "0.35em" },
    { key: "small", text: "Box Corners (small)", value: "0.5em" },
    { key: "large", text: "Box Corners (large)", value: "1em" },
    { key: "big", text: "Box Corners (big)", value: "2em" },
    { key: "huge", text: "Box Corners (huge)", value: "3em" },
    { key: "massive", text: "Box Corners (massive)", value: "5em" },
];

export const buildBorderRadiusOptions = (value) => {
    if (!value || RadiusOptions.find((o) => o.value === value)) {
        return RadiusOptions;
    }
    return [
        { key: "custom", text: `Box Corners (custom: ${value})`, value, disabled: true },
        ...RadiusOptions
    ];
};

export const buildBorderRadiusItems = (value, disabled, onClick) => {
    return RadiusOptions.map(item => 
        <List.Item key={item.key} {...item} active={value === item.value} disabled={disabled} onClick={onClick}>{item.text}</List.Item>
    );
};

const BackgroundColors = [
    "blue",
    "green",
    "orange",
    "violet",
    "red",
    "darkgrey",
    "grey",
    "greyalternate",
    "white",
];

export const buildBackgroundColors = () => BackgroundColors;


const BorderColors = [
    "blue",
    "green",
    "orange",
    "violet",
    "red",
    "darkgrey",
    "grey",
    "greyalternate",
    "white",
];

export const buildBorderColors = () => BorderColors;

export const DefaultBorderStyle = "solid";
export const DefaultBorderWidth = "1pt";
