import React, { PureComponent } from "react";
import { Label, Button, Icon, Header } from "semantic-ui-react";
import PropTypes from "prop-types";

export default class TagsList extends PureComponent {
  static propTypes = {
      tags: PropTypes.object.isRequired,
      onEdit: PropTypes.func.isRequired,
      canEdit: PropTypes.bool,
      showButton: PropTypes.bool,
  }

  handleStopPropagation = (e) => {
      e.stopPropagation();
  }

  render() {
      const { tags, onEdit, canEdit, showButton } = this.props;
      return (
          <div className="editable actionIcons" onClick={this.handleStopPropagation}>
              {Object.keys(tags).length ? 
                  <React.Fragment>
                      {showButton && <div><Header as="h4">Tags</Header></div>}
                      {Object.keys(tags).map((tag) => <Label key={tag} title={tag} style={{margin: "0.3em"}}>{tag}</Label>)}
                  </React.Fragment>
                  : 
                  <div style={{display: "inline", color: "grey", fontStyle: "italic"}} title={showButton ? "Use Manage button to manage tags" : "Click to pencil icon to add first tag."}>No tags </div>
              }
              {canEdit && (
                  showButton ? <Button style={{float: "right"}} compact title="Click here to edit tags." content="Edit..." onClick={onEdit} />
                      : <Icon style={{marginLeft: "0.2em", cursor: "pointer"}} title="Click here to edit tags." name="pencil" onClick={onEdit} />)
              }
          </div>
      );
  }
}
