const TYPES = {
    CONVERT_WIDGET_DATA_START: "CONVERT_WIDGET_DATA_START",
    CONVERT_WIDGET_DATA_FINISHED: "CONVERT_WIDGET_DATA_FINISHED",
    CONVERT_WIDGET_DATA_MESSAGE: "CONVERT_WIDGET_DATA_MESSAGE",
};

const onConvertWidgetDataStart = () => ({
    type: TYPES.CONVERT_WIDGET_DATA_START,
});

const onConvertWidgetDataMessage = payload => {
    return{
        type: TYPES.CONVERT_WIDGET_DATA_MESSAGE,
        payload,
    };
};



const onConvertWidgetDataFinished = () => ({
    type: TYPES.CONVERT_WIDGET_DATA_FINISHED,
});


export {
    TYPES,
    onConvertWidgetDataStart,
    onConvertWidgetDataMessage,
    onConvertWidgetDataFinished,
};
