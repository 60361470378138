import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import {
    filteredTemplates,
    getSelectedTemplate,
    editing,
    searchFilter,
    usedTags,
    selectedTemplateId,
    isNew,
    selectedTags,
    getModuleFilter,
} from "./WidgetTemplatesSelectors";
import { getSortedSubjectNames } from "../../store/selectors/SubjectSelectors";
import {
    onSelectTemplate,
    onCreateTemplate,
    onUpdateName,
    onCloneTemplate,
    onCloseNameEditor,
    onSearchChanged,
    onSelectTag,
    onDeleteTemplate,
    onSetModuleFilter,
} from "./WidgetTemplatesActions";

import WidgetTemplates from "./WidgetTemplates";
import { withAuth } from "../../component/withAuth.js";

const mapStateToProps = state => ({
    widgetTemplates: filteredTemplates(state),
    selectedTemplate: getSelectedTemplate(state),
    subjects: getSortedSubjectNames(state),
    editing: editing(state),
    searchFilter: searchFilter(state),
    usedTags: usedTags(state),
    selectedTemplateId: selectedTemplateId(state),
    isNew: isNew(state),
    selectedTags: selectedTags(state),
    moduleFilter: getModuleFilter(state),
});

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            onSelectTemplate,
            onCreateTemplate,
            onUpdateName,
            onCloneTemplate,
            onCloseNameEditor,
            onSearchChanged,
            onSelectTag,
            onDeleteTemplate,
            onSetModuleFilter,
        },
        dispatch
    );

export default compose(
    firebaseConnect(["widgetTemplates/list", "widgetTemplates/m", "subjects", "tenant_modules"]),
    connect(
        mapStateToProps,
        dispatchToProps
    ), 
    spinnerWhileLoading(["widgetTemplates", "subjects"]),
    withAuth
)(WidgetTemplates);
