import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";

import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { authUser } from "../../auth/authSelectors";
import { onAddComment, onEditComment, onOpenReply, onMarkResolved, onRemove } from "./commentsActions";
import { replyToAnchor, populates, getComments, getOriginalComments, getUnresolvedCommentsCount } from "./commentsSelectors";
import Comments from "./Comments";

const mapStateToProps = (state, props) => ({
    replyToAnchor: replyToAnchor(state),
    comments: getComments(state, props),
    originalComments: getOriginalComments(state, props),
    unresolvedCommentsCount: getUnresolvedCommentsCount(state, props),
    authUser: authUser(state),
});

const dispatchToProps = (dispatch) => bindActionCreators({
    onAddComment,
    onEditComment,
    onOpenReply,
    onMarkResolved,
    onRemove
}, dispatch);

export default compose(
    firebaseConnect((props) => ([
        { path: "/comments/" + props.threadId, populates }
    ])),
    connect(mapStateToProps, dispatchToProps),
    // show loading spinner while data are loading
    spinnerWhileLoading(["comments"]),
)(Comments);
