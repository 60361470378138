import { createSelector } from "reselect";

const getTemplateiPlannersData = (state) => state.lup && state.lup.templateiPlanners;
const getDisplayedTemplateId = (state, props) => props.templateId;

export const getTemplateiPlanners = createSelector(
    getDisplayedTemplateId,
    getTemplateiPlannersData,
    (templateId, data) => data[templateId]
);

