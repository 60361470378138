exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39IrYoq6nqnbvsRtdZFluA, .ui.menu .item > ._39IrYoq6nqnbvsRtdZFluA.label, ._39IrYoq6nqnbvsRtdZFluA.ui.label {\n  color: white;\n  background-color: #00b5ad;\n  white-space: nowrap;\n  padding: 0.3em 1em;\n  margin: -0.3em 0; }\n  ._1rQ7DJeShbrI80ZSs_-td-._39IrYoq6nqnbvsRtdZFluA, .ui.menu .item > ._1rQ7DJeShbrI80ZSs_-td-._39IrYoq6nqnbvsRtdZFluA.label, ._1rQ7DJeShbrI80ZSs_-td-._39IrYoq6nqnbvsRtdZFluA.ui.label {\n    background-color: #f2711c; }\n  ._3PhNKVSUg2UNOfsI7e1SsI._39IrYoq6nqnbvsRtdZFluA, .ui.menu .item > ._3PhNKVSUg2UNOfsI7e1SsI._39IrYoq6nqnbvsRtdZFluA.label, ._3PhNKVSUg2UNOfsI7e1SsI._39IrYoq6nqnbvsRtdZFluA.ui.label {\n    padding-left: 0.5em;\n    padding-right: 0.5em; }\n  ._1LqzqMmLtdkJPteyoCpdGw._39IrYoq6nqnbvsRtdZFluA, .ui.menu .item > ._1LqzqMmLtdkJPteyoCpdGw._39IrYoq6nqnbvsRtdZFluA.label, ._1LqzqMmLtdkJPteyoCpdGw._39IrYoq6nqnbvsRtdZFluA.ui.label {\n    top: 0; }\n  ._28dNxZ0aRI2je-crljbsRP._39IrYoq6nqnbvsRtdZFluA, .ui.menu .item > ._28dNxZ0aRI2je-crljbsRP._39IrYoq6nqnbvsRtdZFluA.label, ._28dNxZ0aRI2je-crljbsRP._39IrYoq6nqnbvsRtdZFluA.ui.label {\n    margin-left: 0.5em; }\n", ""]);

// exports
exports.locals = {
	"reviewsCount": "_39IrYoq6nqnbvsRtdZFluA",
	"hasReviews": "_1rQ7DJeShbrI80ZSs_-td-",
	"narrow": "_3PhNKVSUg2UNOfsI7e1SsI",
	"keepInLine": "_1LqzqMmLtdkJPteyoCpdGw",
	"marginLeft": "_28dNxZ0aRI2je-crljbsRP"
};