import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose, bindActionCreators } from "redux";
import * as SyllabusOutlineActions from "./SyllabusOutlineActions";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { getSyllabus, getSyllabusId, getSyllabusSiPi, getSyllabusSiPiByScope, getAssets, getTemplateSiPi } from "./SyllabusOutlineSelectors";
import IPlannerOutline from "../component/IPlannerOutline";
import { loggedUserId } from "../../Users/UserProfile/UserProfileSelectors";
import { getYearsAsObject } from "../../Year/YearsSelectors";
import { onLoadCourseVariant } from "../../External/CourseVariantList/CourseVariantsActions";
import { getCourseVariant, isLoading } from "../../External/CourseVariantList/courseVariantsSelectors";
import { onSingleSchoolLoad } from "../../External/SchoolList/schoolActions";
import { isLoading as isLoadingSchool, getSchool } from "../../External/SchoolList/schoolSelectors";
import { populates } from "../SyllabiList/SyllabiListSelectors";

const mapStateToProps = function(state, props) {
    return {
        iPlanner: getSyllabus(state, props),
        iPlannerSiPi: getSyllabusSiPi(state, props),
        iPlannerReadOnlySiPi: getTemplateSiPi(state, props),
        iPlannerSiPiByScope: getSyllabusSiPiByScope(state, props),
        userId: loggedUserId(state),
        years: getYearsAsObject(state),
        courseVariant: getCourseVariant(state),
        cvIsLoading: isLoading(state),
        schoolIsLoading: isLoadingSchool(state),
        school: getSchool(state),
        assets: getAssets(state, props),
        iPlannerId: getSyllabusId(state, props),
        ruleBaseName: "syllabi",
        entitiesName: "iPlanners",
        enableSyllabusFeatures: true,
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...SyllabusOutlineActions,
            onLoadCourseVariant,
            onSingleSchoolLoad,
        },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    firebaseConnect((props) => {
        const listeners = [
            {path: `/syllabi/info/${props.iPlannerId}`, populates},
            `/syllabi/data/${props.iPlannerId}`,
            `/syllabi/lesson_units/${props.iPlannerId}`
        ];
        if (props.iPlanner && props.iPlanner.template_id) {
            const templateId = props.iPlanner.template_id;
            for (const key of ["data", "info", "lesson_units"]) {
                listeners.push(`/sipi_templates/${key}/${templateId}`);
            }
        }
        return listeners;
    }),
    spinnerWhileLoading(["iPlanner"]),
)(IPlannerOutline);
