import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import ImportHomework from "./ImportHomework";
import { firebaseConnect } from "react-redux-firebase";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { onImportHomework, onImportHomeworkStop } from "./importHomeworkActions";
import { getHomeworkToImport, inProgress, getProgress, getError } from "./importHomeworkSelector";

const mapStateToProps = function(state) {
    return {
        homeworkToImport: getHomeworkToImport(state),
        inProgress: inProgress(state),
        progress: getProgress(state),
        error: getError(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onImportHomework, onImportHomeworkStop }, dispatch);
};

export default compose(
    firebaseConnect(() => {
        return [
            { path: "/homework_to_import" }, // create listener
        ];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["homeworkToImport"])
)(ImportHomework);
