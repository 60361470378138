import { put } from "redux-saga/effects";
import firebase from "../../firebase";
import { onAdminSuccess, onAdminFailure, onLogWrite, onLogClear } from "./AdminFunctionsActions";
import { toastError, FUNCTION_ERROR } from "../../component/toast";

function* readProblemSets(fbCollection) {
    const rawData = (yield firebase.getFirebaseData(fbCollection).once("value")).val();
    return Object.entries(rawData).reduce((acc, [id, raw]) => (
        acc.set(id, {
            id,
            type: raw.type || raw.setType,
            problems: raw.problems || [],
        })
    ), new Map());
}

function* readProblems(fbCollection, partitionSize = 2000) {
    const results = new Map();

    let batchNext = "";
    let batchKeys = null;
    do {
        if (batchNext) {
            yield put(onLogWrite(`Reading problems... (${results.size})`));
        }
        const dataRef = firebase.getFirebaseData(fbCollection)
            .orderByKey()
            .startAt(batchNext)
            .limitToFirst(partitionSize + 1);
        const batchData = (yield dataRef.once("value")).val() || {};
        batchKeys = Object.keys(batchData);
        batchNext = (batchKeys.length > partitionSize) && batchKeys.pop();
        for (const id of batchKeys) {
            const raw = batchData[id];
            results.set(id, {
                id,
                type: raw.type || raw.problemType,
                used: Object.keys(raw.problemSets || {}),
                parentId: raw.parentProblemId,
                children: raw.children,
            });
        }
    } while (batchNext);

    return results;
}

function getCorrectProblemSets(problemSets, problem, parent) {
    const validIds = (problem.used || []).filter((psetId) => {
        const pset = problemSets.get(psetId);
        return pset && pset.problems.includes(problem.id);
    }).concat((parent && parent.used || []).filter((psetId) => {
        const pset = problemSets.get(psetId);
        return pset && pset.problems.includes(parent.id);
    }));

    return validIds.length ? validIds.reduce((acc, id) => (acc[id] = true, acc), {}) : null;
}

function* detectBrokenProblems(problemSets, problems) {
    const nestedProblems = [...problems.values()].filter((p) => p.parentId);
    yield put(onLogWrite(`Problems with parent problem: ${nestedProblems.length}`));

    const brokenProblems = nestedProblems.map((problem) => {
        const parent = problems.get(problem.parentId);
        const parentUsedBy = (parent && parent.used) || [];
        const fixParentRef = !parent;
        const fixUsageRefs = !!(
            parentUsedBy.some((id) => !problem.used.includes(id)) ||
            problem.used.some((id) => !parentUsedBy.includes(id))
        );
        return (fixParentRef || fixUsageRefs) && {
            ...problem,
            fixParentRef,
            fixUsageRefs,
            parentUsedBy,
            problemSets: getCorrectProblemSets(problemSets, problem, parent),
        };
    }).filter(Boolean);
    yield put(onLogWrite(`Problems with bad references: ${brokenProblems.length}`));

    return brokenProblems;
}

function* processData(fbCollection, fbItemCollection) {
    const problemSets = yield readProblemSets(fbCollection);
    yield put(onLogWrite.Message(`Problem sets found: ${problemSets.size}`));

    const problems = yield readProblems(fbItemCollection);
    yield put(onLogWrite.Message(`Problems found: ${problems.size}`));

    const detected = yield detectBrokenProblems(problemSets, problems);
    if (detected.length) {
        yield put(onLogWrite.Failure(`Broken problems detected: ${detected.length} (see console for more).`, detected));
    } else {
        yield put(onLogWrite.Success("No problems with invalid refs to parent or set."));
    }
}

export default function* orphanedProblems(config) {
    try {
        yield put(onLogClear());

        yield put(onLogWrite.Message("Processing problem sets & problems..."));
        yield processData("problemSet", "problem", config || {});

        yield put(onLogWrite.Message("Processing comprehensive tests & questions..."));
        yield processData("comptests_common", "comptests_cvault", config || {});

        yield put(onAdminSuccess("Orphaned problems detection completed."));
    } catch(ex) {
        const { code, message } = ex;
        yield put(onAdminFailure(message));
        toastError({ code: FUNCTION_ERROR, header: "Failed to execute", message: `${message} (code: ${code})` });
    }
}

