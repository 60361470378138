import React from "react";
import PropTypes from "prop-types";
import { Divider, Form, Modal } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";

export const EDIT_MODE = {
    NONE: "NONE",
    EDIT: "EDIT",
    ADD: "ADD",
};

export default class YearEditor extends React.PureComponent {
    static propTypes = {
        yearId: (props, propName, componentName) => {
            const value = props[propName];
            const valueType = typeof value;
            if ((props.editMode === EDIT_MODE.EDIT && (value === undefined || value === null || valueType !== "number"))) {
                return new Error(`The prop "${propName}" of type "number" is marked as required in ${componentName}`
                    + ` for editMode === "${EDIT_MODE.EDIT}"\n`
                    + `Passed type: "${valueType}" / value: "${value}"`);
            }
        },
        allYears: PropTypes.array.isRequired,
        editMode: PropTypes.oneOf(Object.values(EDIT_MODE)).isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    state = {
        name: "",
        valid: true,
        isDefault: false,
    };

    componentDidMount = () => {
        const { editMode, yearId, allYears } = this.props;

        if (allYears.length === 0 || editMode === EDIT_MODE.ADD) {
            return;
        }

        const year = allYears.find(({ id }) => id === yearId);
        const { name, valid, "default": isDefault } = year;
        this.setState({ name, valid: Boolean(valid), isDefault: Boolean(isDefault) });
    };

    handleChange = (e, { name, value, checked }) => {
        this.setState({ [name]: checked === undefined ? value.trim() : checked });
    };

    handleSave = () => {
        const { name, valid, isDefault } = this.state;
        this.props.onSave({ name, valid, isDefault });
    }

    render() {
        const { editMode, onCancel } = this.props;
        const { name, valid, isDefault } = this.state;

        if (editMode === EDIT_MODE.NONE) {
            return null;
        }

        return (
            <Modal open size="small">
                <Modal.Header>{editMode === EDIT_MODE.EDIT ? "Edit" : "Add new"} Year</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            placeholder="School year name, e.g.: 2022/2023 ..."
                            name="name"
                            label="Name"
                            value={name || ""}
                            onChange={this.handleChange}
                            autoFocus
                            required
                        />
                        <Form.Checkbox
                            name="isDefault"
                            label="Current"
                            checked={isDefault}
                            onChange={this.handleChange}
                        />
                        <Form.Checkbox
                            name="valid"
                            label="Valid"
                            checked={valid}
                            onChange={this.handleChange}
                        />
                    </Form>
                    <Divider hidden />
                    <SaveCancelButtons onSave={this.handleSave} onCancel={onCancel} />
                </Modal.Content>
            </Modal>
        );
    }
}
