import { TYPES } from "./commentsActions";

const initialState = {
    replyToAnchor: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.COMMENT_OPEN_REPLY:
            return { ...state, replyToAnchor: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
