import React from "react";
import PropTypes from "prop-types";
import { Icon, Popup } from "semantic-ui-react";
import cx from "classnames";

import { setCaretToEntity, swallowEvent } from "../utils/utils";
import { ENTITY_TYPE, REVIEW_OPTIONS } from "../constants";

class ReviewDecorator extends React.Component {
    static propTypes = {
        entityKey: PropTypes.string.isRequired,
        isParentReadOnly: PropTypes.func.isRequired,
        getEditorState: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        showCustomComponent: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.node).isRequired,
    };

    componentType = ENTITY_TYPE.REVIEW;

    handleOpenReviewEditor = (e) => {
        const { isParentReadOnly, showCustomComponent, entityKey } = this.props;

        if (isParentReadOnly()) {
            return;
        }

        swallowEvent(e);
        showCustomComponent(this.componentType, null, entityKey );
    };

    handleSetCaretToEntity = (e) => {
        e.preventDefault();
        const { entityKey, getEditorState, setEditorState, children } = this.props;
        const { block } = children[0].props;
        const blockKey = block.getKey();
        const newEditorState = setCaretToEntity(getEditorState(), blockKey, entityKey);
        setEditorState(newEditorState);
    };

    render() {
        const { entityKey, getEditorState } = this.props;
        const entity = getEditorState().getCurrentContent().getEntity(entityKey);
        const data = entity.getData();
        const { originalHtml, html, comment, reviewMetaData } = data;
        const insertAsBlock = reviewMetaData && reviewMetaData[REVIEW_OPTIONS.INSERT_AS_BLOCK];
        const joinWithNext = reviewMetaData && reviewMetaData[REVIEW_OPTIONS.JOIN_WITH_NEXT];

        return (
            <span
                className="review"
                contentEditable={false}
                onClick ={this.handleSetCaretToEntity}
                onDoubleClick={this.handleOpenReviewEditor}
                title={"Double-click here to open the review change"}
            >
                <span className="review-remove" dangerouslySetInnerHTML={{ __html: originalHtml }} />
                {(html || insertAsBlock) && <span className={cx("review-insert", { "block": insertAsBlock })} dangerouslySetInnerHTML={{ __html: html }} />}
                {joinWithNext && <span className="review-remove-new-line">&crarr;</span>}
                {(comment)
                    ? <Popup trigger={<Icon name="sticky note" color="blue" style={{ verticalAlign: "top" }} />} wide="very">
                        {comment}
                    </Popup>
                    : <Icon name="sticky note" color="orange" style={{ verticalAlign: "top" }} />
                }
            </span>
        );
    }
}

export default ReviewDecorator;
