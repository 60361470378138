import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Icon, Message } from "semantic-ui-react";
import ActionLink from "../../../../component/ActionLink";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styles from "./reorderProblemSets.module.scss";



export default class ReorderProblemSets extends PureComponent {

    static propTypes = {
        entity: PropTypes.string,
        trigger: PropTypes.node,
        problemSets: PropTypes.array,
        outlineId: PropTypes.string,
        lessonId: PropTypes.string,
        onMoveSet: PropTypes.func.isRequired,
    };

    state = {
        isOpen: false,
        error: null,
    };

    handleOpen = () => {
        this.setState({ isOpen: true });
    };

    handleClose = (e) => {
        e && e.stopPropagation();
        this.setState({ isOpen: false });
    };

    handleDragStart = () => this.setState({ error: null });

    handleDragEnd = (e) => {
        const { onMoveSet, entity, outlineId, lessonId, problemSets } = this.props;
        const { destination, source, reason, draggableId } = e;
        const { handleMoveError } = this;

        if (destination == null || destination == source)
            return;

        if (reason == "DROP"){
            onMoveSet({ 
                outlineId,
                lessonId,
                setId: draggableId, 
                source: source.droppableId, 
                destination: destination.droppableId,
                entity,
                problemSets,
                callbackOnError: handleMoveError
            });
        }
    };

    handleMoveError = (error) => this.setState({ error });

    render() {
        const { isOpen, error } = this.state;
        const { entity, trigger, problemSets } = this.props;
        return (
            <Modal
                open={isOpen}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                trigger={trigger || <ActionLink icon="sort amount down" content="Reorder" />}
                size="small"
            >
                <Modal.Header>{`Reorder ${entity}s to lesson`}</Modal.Header>
                <Modal.Content>
                    {
                        error && 
                        <Message negative>
                            <Message.Header>Error</Message.Header>
                            <p>{error}</p>
                        </Message>
                    }
                    <DragDropContext onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}>
                        {
                            problemSets && problemSets.length > 0 && problemSets.map((set) => {
                                return(
                                    <Droppable key={set.id + "-" + set.ordering} droppableId={set.ordering.toString()} type="SET">
                                        {(dropProvided, dropSnapshot) => (
                                            <div
                                                ref={dropProvided.innerRef}
                                                className={dropSnapshot.isDraggingOver ? styles["drag-over"] : styles["no-drag-over"] }
                                                {...dropProvided.droppableProps}
                                            >
                                                <Draggable key={set.id + "-" + set.ordering} draggableId={set.id} index={set.ordering}>
                                                    {(dragProvided) => (
                                                        <div 
                                                            key={set.id} 
                                                            ref={dragProvided.innerRef} 
                                                            {...dragProvided.draggableProps}
                                                            className={styles["set-reorder-block"]}
                                                        >
                                                            <Icon 
                                                                name="bars" 
                                                                color="grey" 
                                                                {...dragProvided.dragHandleProps} 
                                                                title="Change order" />
                                                            <span>{set.name}</span>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </div>
                                        )}
                                    </Droppable>
                                );
                            })
                        }
                    </DragDropContext>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose} content="Close" />
                </Modal.Actions>
            </Modal>
        );
    }
}
