import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button } from "semantic-ui-react";

export default class FixiPlannerResources extends PureComponent {
    static propTypes = {
        inProgress: PropTypes.bool,
        onFixData: PropTypes.func,
    };

    render() {
        const { inProgress, onFixData } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Fix unavailable iPlanner resources
                </Header>
                <Segment secondary>
                    This action will fix the resources availability in iPlanner templates and syllabi that contain resources the never load.
                </Segment>
                <Button disabled={inProgress} loading={inProgress} primary floated="right" onClick={onFixData} >Fix iPlanner data</Button>
            </React.Fragment>
        );
    }
}
