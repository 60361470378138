import { populate } from "react-redux-firebase";
import { createSelector } from "reselect";
import { convertDatasetToList } from "../../utils/selectorUtils";

export const populates = [
    { child: "author", root: "user", childAlias: "authorObj" }, // replace owner with user object
];

export const replyToAnchor = state => state.kitBuilder.comments.replyToAnchor;
export const getComments = ({ firebase }, props) => populate(firebase, "comments/" + props.threadId, populates);
export const getOriginalComments = ({ firebase }, props) => firebase.data.comments && firebase.data.comments[props.threadId];
export const getUnresolvedCommentsCount = createSelector(
    getComments,
    (comments) => {
        if (comments) {
            
            const topLevelComents = convertDatasetToList(comments).filter(({value}) => (!value.isReply && !value.resolved));
            return topLevelComents.length;
        }
    }
);
