import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import SidebarMenu from "./SidebarMenu";
import { onMenuClick } from "./SidebarMenuActions";
import { activeIndex } from "./SidebarMenuSelectors";
import { withRouter } from "react-router";

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onMenuClick,
        },
        dispatch
    );
};

export default compose(
    withRouter,
    connect(
        (state,) => ({
            activeIndex: activeIndex(state),
        }),
        dispatchToProps
    )
)(SidebarMenu);