import React from "react";
import PropTypes from "prop-types";
import { Segment, Label } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import Widget, { previewContent } from "../../Widget";
import TemplateEditorList from "./TemplateEditorList";
import TemplateCreateItem from "./TemplateCreateItem";
import WidgetPopup from "./WidgetPopup";
import UsedTemplateWarning from "./UsedTemplateWarning";
import SharedToolbar from "../../../component/seamlessEditor/bookEditor/SharedToolbar";

import "./TemplateEditor.css";

export default class TemplateEditor extends React.PureComponent {
    state = {};

    toolbarRef = React.createRef();

    componentDidMount = () => {
        if (this.toolbarRef && this.toolbarRef.current) {
            const sharedToolbar = new SharedToolbar();
            sharedToolbar.mount(this.toolbarRef.current);
            this.setState({ sharedToolbar });
        }
    };

    handleUpdateSetup = setup => {
        const { template, onUpdateSetup } = this.props;
        onUpdateSetup && onUpdateSetup({ id: template.key, setup });
    };

    render() {
        const { template, onCreateItem, usedByCount } = this.props;
        const { sharedToolbar } = this.state;
        const content = previewContent(template);
        return (
            <React.Fragment>
                <div className="toolbarArea">
                    <div className="widgetEditorToolbar" ref={this.toolbarRef} />
                    <div /> {/* INFO: This is placeholded for SaveCancelButtons from <TemplateEditorList /> */}
                </div>
                <Segment className="spork template-editor" style={{ marginTop: "0.60em" }}>
                    <Label attached="top">
                        {usedByCount ? <UsedTemplateWarning /> : <TemplateCreateItem onCreateItem={onCreateItem} />}
                        <WidgetPopup setup={template.setup || {}} onUpdate={this.handleUpdateSetup} />
                    </Label>
                    <Widget template={template} content={content}>
                        {Object.keys(template.items || {}).length === 0 ? (
                            <NoDataPlaceholder text="Empty widget template (no items)" />
                        ) : (
                            <TemplateEditorList
                                enableRemove={!usedByCount}
                                template={template}
                                content={content}
                                sharedToolbar={sharedToolbar}
                            />
                        )}
                    </Widget>
                </Segment>
            </React.Fragment>
        );
    }
}

TemplateEditor.propTypes = {
    template: PropTypes.object,
    onCreateItem: PropTypes.func.isRequired,
    usedByCount: PropTypes.number,
    onUpdateSetup: PropTypes.func.isRequired,
};
