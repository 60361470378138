import React from "react";
import PropTypes from "prop-types";
import { InfiniteTable } from "bmd-react";
import { Header, Dimmer, Loader } from "semantic-ui-react";
import EditDevice from "../EditDevice/EditDeviceContainer";
import EditActionIcon from "bmd-react/dist/EditActionIcon";
import { ActionIconsContainer } from "bmd-react/dist/ActionIconsContainer";
import RemoveIcon from "../../component/RemoveIcon";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import UploadFile from "./UploadFile";
import ActionLink from "../../component/ActionLink";
import { CSVLink } from "react-csv";

const headers = [
    { label: "Device ID", key: "id" },
    { label: "Last Provisioned", key: "last_provisioned" },
    { label: "Manufacturer", key: "manufacturer" },
    { label: "Model", key: "model" },
    { label: "S/N", key: "serial_number" },
    { label: "School", key: "school" },
    { label: "Tenant", key: "tenant" },
    { label: "First name", key: "first_name" },
    { label: "Last name", key: "last_name" },
    { label: "Scope", key: "scope" },
    { label: "User UUID", key: "uuid" },
];
export default class DeviceList extends React.PureComponent {
    static propTypes = {
        isLoadingDevices: PropTypes.bool,
        devices: PropTypes.arrayOf(
            PropTypes.shape({
                client_id: PropTypes.string,
                serial_number: PropTypes.string,
                id: PropTypes.number,
                user_id: PropTypes.number,
            })
        ),
        onOpenAddPopup: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        onEditDevice: PropTypes.func.isRequired,
        onDeleteDevice: PropTypes.func.isRequired,
        schools: PropTypes.array,
        onSchoolLoad: PropTypes.func.isRequired,
        onImportDevices: PropTypes.func.isRequired,
        onSelectUser: PropTypes.func,
    };

    state = { removeContext: null, dataToDownload: [] };

    handleOpen = () => {
        this.props.onOpenAddPopup(true);
    };

    handleEdit = device => {
        this.props.onEditDevice(device);
    };

    handleDelete = device => {
        this.props.onDeleteDevice(device.id);
    };

    handleRemoveRole = (e, deviceId) => {
        const { removeContext } = this.state;
        if (!removeContext) {
            this.setState({ removedDevice: deviceId, removeContext: e.currentTarget });
        }
    };

    handleRemoveConfirm = () => {
        const { onDeleteDevice } = this.props;
        const { removedDevice } = this.state;
        onDeleteDevice(removedDevice);
        this.handleCancelRemove();
    };

    handleCancelRemove = () => {
        this.setState({ removeContext: null, removedDevice: "" });
    };

    handleDownload = () => {
        const currentRecords = this.props.devices;
        this.setState(
            {
                dataToDownload: currentRecords.map(device => ({
                    ...device,
                    school: device.school && device.school.name,
                    tenant: device.tenant && device.tenant.name,
                    first_name: device.user && device.user.first_name,
                    last_name: device.user && device.user.last_name,
                    scope: device.user && device.user.scope,
                    uuid: device.user && device.user.uuid,
                })),
            },
            () => {
                this.csvLink.link.click();
            }
        );
    };

    handleSelectUser = (e) => {
        this.props.onSelectUser(e.currentTarget.id);
    }

    _renderActionIcons = ({ rowData }) => (
        <ActionIconsContainer floatRight>
            <EditActionIcon data={rowData} onClick={this.handleEdit} />
            {!rowData.user && (
                <RemoveIcon id={rowData.id + ""} entityName="tablet device" onRemove={this.handleRemoveRole} />
            )}
        </ActionIconsContainer>
    );

    renderName = ({ cellData, rowData }) => (
        <a
            style={{cursor: "pointer"}}
            id={rowData.user && rowData.user.id}
            onClick={this.handleSelectUser}
        >
            {cellData}
        </a>
    );

    _columns = [
        {
            label: "Serial number",
            dataKey: "serial_number",
            width: 200,
            visible: true,
        },
        {
            label: "First name",
            dataKey: "user.first_name",
            width: 150,
            visible: true,
            cellRenderer: this.renderName,
        },
        {
            label: "Last name",
            dataKey: "user.last_name",
            width: 150,
            visible: true,
            cellRenderer: this.renderName,
        },
        {
            label: "type",
            dataKey: "user.scope",
            width: 80,
            visible: true,
        },
        {
            label: "School",
            dataKey: "school.name",
            width: 150,
            visible: true,
        },
        {
            label: "Tenant",
            dataKey: "tenant.name",
            width: 150,
            visible: false,
        },
        {
            label: "Model",
            dataKey: "model",
            width: 100,
            visible: true,
        },
        {
            label: "Manufacturer",
            dataKey: "manufacturer",
            width: 100,
            visible: true,
        },
        {
            label: "Provisioned",
            dataKey: "last_provisioned",
            width: 230,
            visible: true,
        },
        {
            dataKey: "",
            width: 80,
            flexGrow: 1,
            visible: true,
            cellRenderer: this._renderActionIcons,
        },
    ];

    render() {
        const { isLoadingDevices, devices, isOpen } = this.props;
        const { removeContext, dataToDownload } = this.state;

        return (
            <div style={{ minHeight: "350px" }}>
                <Dimmer active={isLoadingDevices} inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
                <Header as="h2" className="pageHeader">
                    Devices
                </Header>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <ActionLink horizontal icon="plus" onClick={this.handleOpen} content="Add new device" />
                    <div style={{ flexBasis: "15em", display: "flex", justifyContent: "space-between" }}>
                        <UploadFile
                            schools={this.props.schools}
                            onSchoolLoad={this.props.onSchoolLoad}
                            onImportDevices={this.props.onImportDevices}
                        />
                        <ActionLink horizontal icon="download" onClick={this.handleDownload} content="Export CSV" />
                    </div>
                </div>
                {/* hide the default link this component provides and use own link and onClick to trigger the download */}
                <CSVLink
                    style={{ display: "hidden" }}
                    data={dataToDownload}
                    headers={headers}
                    // eslint-disable-next-line react/jsx-no-bind
                    ref={r => (this.csvLink = r)}
                    filename="devices.csv"
                />
                <ConfirmDeleteWithContext
                    context={removeContext}
                    confirmText="Remove tablet"
                    onConfirm={this.handleRemoveConfirm}
                    whatText={"tablet device"}
                    onCancel={this.handleCancelRemove}
                />
                {isOpen === true && <EditDevice />}
                <InfiniteTable
                    data={devices}
                    columns={this._columns}
                    tableName="tablets"
                    defaultSortBy="serial_number"
                />
            </div>
        );
    }
}
