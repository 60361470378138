import { put } from "redux-saga/effects";
import firebase from "../../../firebase";
import { onAdminSuccess, onAdminFailure } from "../AdminFunctionsActions";
import { toastError, FUNCTION_ERROR } from "../../../component/toast";
import { client } from "../../../feathersjs/client";

const kitService = client.service("kit");
const userService = client.service("user");

export default function* migrateKits() {
    try {
        const kitsData = (yield firebase.getFirebaseData("/kits_data").once("value")).val();
        for (const kitId of Object.keys(kitsData)) {
            const kit = kitsData[kitId];
            let payload = {
                fb_id: kitId,
                name: kit.name,
                outline_id: kit.outline_id,
                tags: kit.tags && Object.keys(kit.tags) || [],
                tenant_id: kit.t || 1,
                authors: kit.authors && Object.keys(kit.authors) || [],
                reviewers: kit.reviewers && Object.keys(kit.reviewers) || [],
            };
            if (kit.author) {
                const found = kit.author.match(/u(.*)@t(\d)/);
                if (found && 3 === found.length) {
                    const uuid = found[1];
                    const tenant_id = parseInt(found[2]);
                    const foundUsers = yield userService.find({ query: { uuid, tenant_id }});
                    if (1 === foundUsers.length) {
                        payload.created_by = foundUsers[0].id;
                        payload.tenant_id = tenant_id;
                    }
                }
            }
            if (kit.id) {
                if (kit.m) {
                    payload.new_packages = Object.keys(kit.m);
                }
                if (kit.course_variants) {
                    payload.new_course_variants = Object.keys(kit.course_variants);
                }
                yield kitService.patch(kit.id, payload);
            } else {
                if (kit.m) {
                    payload.packages = Object.keys(kit.m);
                }
                if (kit.course_variants) {
                    payload.course_variants = Object.keys(kit.course_variants);
                }
                const createdKit = yield kitService.create(payload);
                yield firebase.getFirebaseData(`/kits_data/${kitId}`).update({ id: createdKit.id });
            }
        }
        yield put(onAdminSuccess("Kits were successfully migrated to SPORK SQL db."));
    } catch(ex) {
        const { code, message } = ex;
        yield put(onAdminFailure(message));
        toastError({ code: FUNCTION_ERROR, header: "Failed to migrate kits properties", message: `${message} (code: ${code})` });
    }
}

