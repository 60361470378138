import { TYPES } from "./ResourcesActions";

const initialState = {
    // Resource data
    isLoading: false,
    data: undefined,    // ... or { key: value}
    // keys: [],        // ... list of IDs/keys
    selectedItem: null, // ... or selected ID
    // Resource filters
    isFilterOpen: false,
    filterParams: {},
    filterByName: null,
    // Resource tags
    tags: undefined,
    isLoadingDetail: false,
    isLoadingTags: false,
    courseVariants: [],
    isLoadingVariants: false,
    sourceTypes: undefined,
    isLoadingSourceTypes: false,
    isUpdatingTopics: false,
    usedTopics: null,
    isLoadingTopics: false
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.RESOURCES_CLEAR_PAGE:
            return { ...state, data: undefined, keys: [], tags: undefined };
        case TYPES.RESOURCES_FETCH_LIST:
            return { ...state, isLoading: true };
        case TYPES.RESOURCES_SET_LIST:
            return {
                ...state,
                isLoading: false,
                data: payload.data,
                // keys: payload.keys,
            };
        case TYPES.RESOURCES_SET_DATA:
            return {
                ...state,
                data: { ...state.data, [payload.id]: payload.data },
                isUpdatingTopics: false,
                isLoadingDetail: false,
            };
        case TYPES.RESOURCES_ENTRY_DETAILS:
            return { ...state, selectedItem: payload };
        case TYPES.RESOURCES_CLOSE_DETAILS:
            return { ...state, selectedItem: null };
        case TYPES.RESOURCES_FILTER_BY_NAME:
            return { ...state, filterByName: payload };
        case TYPES.RESOURCES_FILTER_BY_TAGS:
            return { ...state, filterByTags: payload };
        case TYPES.RESOURCES_FILTER_BY_OPEN:
            return { ...state, isFilterOpen: true };
        case TYPES.RESOURCES_FILTER_BY_CLOSE:
            return { ...state, isFilterOpen: false };
        case TYPES.RESOURCES_FILTER_BY_APPLY: {
            if (payload) { // apply filter changes
                const filterParams = { ...state.filterParams, ...payload };
                return { ...state, isFilterOpen: false, filterParams };
            }
            return state;
        }
        case TYPES.RESOURCES_FETCH_TAGS:
            return { ...state, isLoadingTags: true };
        case TYPES.RESOURCES_SET_TAGS:
            return { ...state, isLoadingTags: false, tags: payload };
        case TYPES.RESOURCE_FETCH_COURSE_VARIANTS:
            return { ...state, isLoadingVariants: true };
        case TYPES.RESOURCE_SET_COURSE_VARIANTS:
            return { ...state, isLoadingVariants: false, courseVariants: payload };
        case TYPES.RESOURCE_FETCH_SOURCE_TYPES:
            return { ...state, isLoadingSourceTypes: true };
        case TYPES.RESOURCE_SET_SOURCE_TYPES:
            return { ...state, isLoadingSourceTypes: false, sourceTypes: payload };
        case TYPES.RESOURCE_REMOVE_RESOURCE_TOPIC: 
            return { ...state, isUpdatingTopics: true };
        case TYPES.RESOURCE_ADD_RESOURCE_TOPICS:
            return { ...state, isUpdatingTopics: true };
        case TYPES.RESOURCES_FETCH_DATA:
            return { ...state, isLoadingDetail: true };
        case TYPES.RESOURCE_SET_USED_TOPICS:
            return { ...state, usedTopics: payload, isLoadingTopics: false };
        case TYPES.RESOURCE_FETCH_USED_TOPICS:
            return { ...state, isLoadingTopics: true };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
