import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onSaved } from "./ResourceDescriptionActions";
import { toastError, DB_ERROR } from "../../../../component/toast";
import { onPatchResourceData } from "../../ResourcesActions";
import { updateResource } from "../../ResourcesApi";

function* saveDescription ({ payload: { resourceId, description } }) {
    try {
        const resource = yield updateResource(resourceId, { description });
        yield put(onPatchResourceData({resourceId, newData: resource.data, fieldsToUpdate: ["description"]}));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update resource description", message: DB_ERROR });
    }
    yield put(onSaved());
}


export default function* resourceDescriptionSaga() {
    yield all([
        takeLatest(TYPES.RESOURCE_DESCRIPTION_SAVE, saveDescription),
    ]);
}
