import firebase from "../../firebase";
import { replaceRelLinks } from "../../utils/conversionUtils";

const basePath = "glossary";
const dataPath = `${basePath}/data`;
const listPath = `${basePath}/list`;

export const paths = {
    basePath,
    dataPath,
    listPath,
    toDataEntry: (glossaryId) => `${dataPath}/${glossaryId}`,
    toListEntry: (glossaryId) => `${listPath}/${glossaryId}`,
    toDefinition: (glossaryId) => `${dataPath}/${glossaryId}/content/html`,
};

/**
 * Get both database references to glossary
 *
 * @param {string} glossaryId glossary identifier
 * @returns {object} Firebase references, {dataRef}
 */
export const getReferences = (glossaryId) => {
    const dataRef = firebase.getFirebaseData(paths.toGlossary(glossaryId));
    return { dataRef };
};

/**
 * Create a new glossary record.
 *
 * @param {object} config attributes of the new glossary
 * @return {string} key/identifier of created glossary
 */
export const createTerm = async (config) => {
    const { name = "New term", t, content } = config || {};
    const dataRef = firebase.getFirebaseData(dataPath);
    const { key } = await dataRef.push({ name, content });
    const listRef = firebase.getFirebaseData(listPath);
    await listRef.update({ [key]: { name, t } });

    return key;
};

/**
 * Remove the glossary record.
 * 
 * @param {string} glossaryId glossary identifier
 */
export const removeTerm = async (glossaryId) => {
    if (!glossaryId) {
        return false;
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`list/${glossaryId}`] : null,
        [`data/${glossaryId}`] : null,
    };
    await baseRef.update(changes);
    return true;
};

/**
 * Change name of the glossary.
 *
 * @param {string} glossaryId glossary identifier
 * @param {string} name non-empty display name of glossary
 */
export const renameTerm = async (glossaryId, name) => {
    if (!glossaryId || !name) {
        return false;
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`data/${glossaryId}/name`] : name,
        [`list/${glossaryId}/name`] : name,
    };
    await baseRef.update(changes);
    return true;
};


export const updateTerm = async (glossaryId, data) => {
    if (!glossaryId) {
        return false;
    }
    const dataRef = firebase.getFirebaseData(paths.toDataEntry(glossaryId));
    const changes = { ...data };
    await dataRef.update(changes);
    return true;
};

export const getTermDefinition = async (glossaryId) => {
    if (!glossaryId) {
        return null;
    }
    const definitionRef = firebase.getFirebaseData(paths.toDefinition(glossaryId));
    return replaceRelLinks(await definitionRef.once("value").then((sc) => sc.val()));
};

export const getOutlineGlossaryUsage = async (outlineId) => {
    if (!outlineId) {
        return null;
    }
    const outlineGlossaryUsageRef = firebase.getFirebaseData(`${basePath}_outline_usage/${outlineId}`);
    return await outlineGlossaryUsageRef.once("value").then((sc) => sc.val());
};
