exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2GRq6HXOqPxhknQcjN-RX3 {\n  display: flex; }\n  ._2GRq6HXOqPxhknQcjN-RX3 ._3yJYXnh8Jv-IcQ-ltHut1f {\n    flex-basis: 90%; }\n  ._2GRq6HXOqPxhknQcjN-RX3 ._2BtTRS7M_33km7MzF0IppD {\n    font-size: 0.9rem;\n    color: #0000007a; }\n", ""]);

// exports
exports.locals = {
	"item": "_2GRq6HXOqPxhknQcjN-RX3",
	"name": "_3yJYXnh8Jv-IcQ-ltHut1f",
	"size": "_2BtTRS7M_33km7MzF0IppD"
};