import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import SectionListItem from "./SectionListItem";
import { onLoadSection, onSelectSection } from "./SectionListItemActions";
import { selectedSectionId } from "./SectionListItemSelectors";
import { onSelectCourse } from "../../../SchoolCourses/SchoolCoursesActions";
import { onSelectYear } from "../../../../Year/YearsActions";
import { schools } from "../SchoolListItem/SchoolListItemSelectors";
import { onSelectSection as onSelectSchoolCourseSection } from "../../../SchoolCourses/SchoolCoursesActions";

const mapStateToProps = function(state) {
    return {
        selectedId: selectedSectionId(state),
        schools: schools(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { 
            onLoadSection,
            onSelectCourse,
            onSelectYear,
            onSelectSection,
            onSelectSchoolCourseSection
        },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(SectionListItem);
