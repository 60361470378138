
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cx from "classnames";

const checkType = (props, propName, componentName, type) => {
    if (props[propName].constructor !== type) {
        return new Error(
            "Invalid prop `" + propName + "` supplied to" + " `" + componentName + "`. Validation failed."
        );
    }
};

const iconNameOrClassName = (props, propName, componentName) => {
    if (Object.prototype.hasOwnProperty.call(props, "iconName")) {
        return checkType(props, "iconName", componentName, String);
    }
    else if (Object.prototype.hasOwnProperty.call(props, "iconClassName")) {
        return checkType(props, "iconClassName", componentName, String);
    }
    else {
        return new Error("Either `iconName` or `iconClassName` is required for `" + componentName + "`. Validation failed.");
    }
};

class CustomToolbarActionButton extends React.Component {
    static propTypes = {
        editorState: PropTypes.object,
        setEditorState: PropTypes.func.isRequired,
        iconName: iconNameOrClassName,       // for ordinary SemanticUI icons
        iconClassName: iconNameOrClassName,  // for FontAwesome icons
        iconModifier: PropTypes.object,
        title: PropTypes.string.isRequired,
        editorStateAction: PropTypes.func.isRequired,
        enableWhenEditorState: PropTypes.func,
    };

    // Currently there is no need generalize setEditorState for stack processing, but if the need appears,
    // this should be moved to BookEditor:setEditorState().
    setEditorStateFromStack = (editorStateStack) => {
        const { setEditorState } = this.props;
        if (editorStateStack.length) {
            setTimeout(() => {
                const editorState = editorStateStack.shift();
                setEditorState(editorState);
                this.setEditorStateFromStack(editorStateStack);
            }, 0);
        }
    };

    handleClick = () => {
        const { editorState, setEditorState, editorStateAction } = this.props;
        const newEditorState = editorStateAction(editorState);

        if (!Array.isArray(newEditorState)) {
            setEditorState(newEditorState);
            return;
        }

        setEditorState(newEditorState.shift());
        this.setEditorStateFromStack(newEditorState);
    };

    render() {
        const { iconName, iconClassName, iconModifier, title, editorState, enableWhenEditorState } = this.props;
        const isEnabled = enableWhenEditorState ? enableWhenEditorState(editorState) : true;

        return (
            <div onClick={isEnabled ? this.handleClick : null} className="rdw-inline-wrapper" title={title}>
                <div className={cx("rdw-option-wrapper", { "rdw-option-disabled": !isEnabled, hasModifier: !!iconModifier })}>
                    <Icon name={iconName}  className={iconClassName} />
                    {iconModifier && <Icon size="small" name={iconModifier.icon} style={{ color: iconModifier.color }}
                        className={cx("iconModifier", iconModifier.className)}
                    />}
                </div>
            </div>
        );
    }
}

export default CustomToolbarActionButton;

