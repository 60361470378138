import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose, bindActionCreators } from "redux";
import { Segment } from "semantic-ui-react";

import { withAuth } from "../../component/withAuth";
import PublishStatus from "./PublishStatus";
import PublishDropdown from "./PublishDropdown";
import { onLessonPublish } from "./PublisherActions";
import {
    mapLessonPublishedStatus,
    getLessonPublishedStatus,
    getLessonPublisherRunning,
} from "./PublisherSelectors";
import { PublishedStatusProps } from "./PublisherProps";
import LessonFilesViewer from "./LessonFilesViewer";

class LessonPublisher extends React.PureComponent {
    static propTypes = {
        lessonId: PropTypes.string,
        outlineId: PropTypes.string,
        /** Content of "/outlines_published/{outlineId}/lessons/{lessonId}"" */
        published: PublishedStatusProps,
        loading: PropTypes.bool,
        /** Actions */
        onLessonPublish: PropTypes.func.isRequired,
        /** In progress: preview, release, publish (preview & release) */
        running: PropTypes.oneOf(["preview", "release", "publish"]),
        /** We wan't to allow publishing to super authors */
        isSuperAuthorAtLeast: PropTypes.bool,
        style: PropTypes.object,
        className: PropTypes.string,
    };

    state = {
        isViewing: false,
    };

    handlePublish = (mode) => {
        const { outlineId, lessonId, onLessonPublish } = this.props;
        onLessonPublish && onLessonPublish(outlineId, lessonId, mode);
    }

    handleViewFiles = () => {
        this.setState({ isViewing: true });
    }

    handleViewClose = () => {
        this.setState({ isViewing: false });
    }

    render() {
        const {
            style,
            className,
            published,
            outlineId,
            lessonId,
            running,
            isSuperAuthorAtLeast
        } = this.props;
        return (
            <Segment basic floated="right"
                style={{...style, display: "flex", flexDirection: "row-reverse", alignItems: "center", margin: 0, padding: 0 }}
                className={className}
            >
                {this.state.isViewing && published && <LessonFilesViewer
                    {...published}
                    outlineId={outlineId}
                    lessonId={lessonId}
                    onClose={this.handleViewClose}
                />}
                {isSuperAuthorAtLeast && (
                    <PublishDropdown {...published}
                        display="button"
                        running={running}
                        loading={published === undefined}
                        onAction={this.handlePublish}
                        onView={this.handleViewFiles}
                    />
                )}
                <PublishStatus {...published}
                    style={{ marginRight: "1em" }}
                    labelStyle={{ textAlign: "right" }}
                />
            </Segment>
        );
    }
}

const mapStateToProps = (state, props) => ({
    published: getLessonPublishedStatus(state, props),
    running: getLessonPublisherRunning(state, props),
});

const dispatchToProps = dispatch => bindActionCreators({
    onLessonPublish,
}, dispatch);

export default compose(
    firebaseConnect(mapLessonPublishedStatus),
    connect(
        mapStateToProps,
        dispatchToProps,
    ),
    withAuth,
)(LessonPublisher);
