import { createSelector } from "reselect";
import { compareByNameCI, getSearchByNamePredicate } from "../../utils/selectorUtils";
import { getSchoolsById } from "../SchoolList/schoolSelectors";

export const isLoadingCourses = state => state.external.courses.isLoadingCourses;
export const isLoadingDetails = state => state.external.courses.isLoadingDetails;
export const courseDetails = state => state.external.courses.courseDetails;
export const allCourses = state => state.external.courses.courses;
export const selectedCourseId = (state, props) =>  props.selectedCourseId || props.match && props.match.params  && props.match.params.courseId && parseInt(props.match.params.courseId) || null;
export const searchFilter = state => state.external.courses.searchFilter;
export const selectedSectionId = state => state.external.courses.selectedSectionId;
export const schoolFilter = state => state.external.courses.schoolFilter;

export const filterBySchoolPredicate = (schoolId) => (entity) => (entity.school_id === schoolId);
export const getCourses = createSelector(
    allCourses,
    searchFilter,
    schoolFilter,
    (courses, filter, schoolFilter) => {
        if (courses && courses.length) {
            let filteredCourses = courses.sort(compareByNameCI);
            const namePredicate = getSearchByNamePredicate(filter);
            if (namePredicate) {
                filteredCourses = filteredCourses.filter(namePredicate);
            }
            if (schoolFilter) {
                const schoolFilterPredicate = filterBySchoolPredicate(schoolFilter);
                filteredCourses = filteredCourses.filter(schoolFilterPredicate);
            }
            return filteredCourses;
        } else {
            return [];
        }
    }
);

export const sectionOptions = createSelector(
    courseDetails,
    (sections) => {
        if (sections) {
            return sections.map((s) => ({ key: s.id, value: s.id, text: s.description }));
        }
    }
);

export const compareByUserNamesCI = (a, b) => {
    const av1 = (a.last_name || "").toLocaleLowerCase();
    const av2 = (a.first_name || "").toLocaleLowerCase();
    const bv1 = (b.last_name || "").toLocaleLowerCase();
    const bv2 = (b.first_name || "").toLocaleLowerCase();

    return av1 === bv1 ? av2 === bv2 ? 0 : (av2 < bv2 ? -1 : 1) : (av1 < bv1 ? -1 : 1);
};

const filterUsers = (sections, selectedSectionId, scope) => {
    if (sections && selectedSectionId) {
        const filteredSections = sections.filter(s => s.id === selectedSectionId);
        if (1 === filteredSections.length) {
            let filteredUsers = filteredSections[0].users.filter(u => scope === u.scope);
            return filteredUsers.sort(compareByUserNamesCI);
        }
    }
};

export const teachers = createSelector(
    courseDetails,
    selectedSectionId,
    (sections, selectedSectionId) => filterUsers(sections, selectedSectionId, "teacher")
);

export const students = createSelector(
    courseDetails,
    selectedSectionId,
    (sections, selectedSectionId) => filterUsers(sections, selectedSectionId, "student")

);

export const selectedCourse = createSelector(
    selectedCourseId,
    allCourses,
    courseDetails,
    (courseId, coursesList, details) => {
        if (coursesList && courseId) {
            const filtered = coursesList.filter(c => c.id === courseId);
            if (1 === filtered.length) {
                return filtered[0];
            }
        }
        if (details && 0 < details.length && 0 < details[0].schoolCourses.length) {
            return details[0].schoolCourses[0];
        }
    }
);

export const getSchoolForSelectedCourse = createSelector(
    selectedCourse,
    getSchoolsById,
    (course, schoolArray) => {
        if (course && schoolArray) {
            return schoolArray[course.school_id];
        }
        return null;

    }
);
