import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { List, Message, Icon } from "semantic-ui-react";
import { onWidgetLessonsRequest } from "./WidgetLessonsActions";
import { getWidgetLessons } from "./WidgetLessonsSelectors";

class LessonLink extends React.PureComponent {
    static propTypes = {
        lessonId: PropTypes.string,
        lessonName: PropTypes.string,
        outlineId: PropTypes.string,
        kitName: PropTypes.string,
    };
    render() {
        const { lessonId, lessonName, outlineId, kitName } = this.props;
        return (
            <Link to={`/lesson/content/${outlineId}/${lessonId}`}>
                <b>{lessonName}</b>{` (in ${kitName})`}
            </Link>
        );
    }
}

export class WidgetLessons extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        onWidgetLessonsRequest: PropTypes.func.isRequired,
        widgetId: PropTypes.string.isRequired,
        usedBy: PropTypes.object,
        lessons: PropTypes.array,
        loading: PropTypes.bool,
        failure: PropTypes.string,
        unresolved: PropTypes.number,
    };

    componentDidMount() {
        const { widgetId, usedBy, onWidgetLessonsRequest } = this.props;
        onWidgetLessonsRequest(widgetId, usedBy);
    }

    render() {
        const { failure, lessons, unresolved } = this.props;
        const hasLessons = lessons && lessons.length;
        const oldLessons = unresolved ? `${unresolved} unresolved lesson reference${unresolved > 1 ? "s" : ""}` : null;
        return (
            <React.Fragment>
                {failure && <Message size="small" error content={failure} />}
                {hasLessons ? (
                    <List>
                        {lessons.map(lesson => (
                            <List.Item key={lesson.lessonId}>
                                <LessonLink {...lesson} />
                            </List.Item>
                        ))}
                    </List>
                ) : null}
                {oldLessons && (
                    <div title="This widget has legacy data. Please, ask developers for more details.">
                        <Icon name="warning sign" />{oldLessons}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default connect(
    (state, props) => {
        const data = getWidgetLessons(state, props);
        const { loading, lessons, failure, unresolved } = data || {};
        return { loading, lessons, failure, unresolved };
    }, // just flatten the returned data
    (dispatch) => bindActionCreators({
        onWidgetLessonsRequest
    }, dispatch)
)(WidgetLessons);