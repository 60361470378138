import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cx from "classnames";
import { ENTITY_TYPE } from "./constants";

class CustomToolbarButton extends React.Component {
    static propTypes = {
        editorState: PropTypes.object,
        entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)).isRequired,
        showCustomComponent: PropTypes.func.isRequired,
        iconName: PropTypes.string,
        title: PropTypes.string.isRequired,
        useAsContext: PropTypes.bool.isRequired,
        enableWhenEditorState: PropTypes.func,
        iconImage: PropTypes.node,
    };

    buttonRef = React.createRef();

    handleClick = () => {
        const { entityType, showCustomComponent, useAsContext } = this.props;
        showCustomComponent(entityType, useAsContext && this.buttonRef.current);
    };

    render() {
        const { iconName, title, entityType, editorState, enableWhenEditorState, iconImage } = this.props;
        const isEnabled = enableWhenEditorState ? enableWhenEditorState(editorState) : true;

        return (
            <div onClick={isEnabled ? this.handleClick : null} className="rdw-inline-wrapper" title={title} ref={this.buttonRef}>
                <div className={cx("rdw-option-wrapper", { "helpButton": entityType == ENTITY_TYPE.HELP, "rdw-option-disabled": !isEnabled })}>
                    { iconImage ? <img src={iconImage} alt="" /> : <Icon name={iconName} />}
                </div>
            </div>
        );
    }
}

export default CustomToolbarButton;

