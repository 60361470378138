import { all, fork } from "redux-saga/effects";

import CourseVariantsSaga from "./CourseVariantList/CourseVariantsSaga";
import SchoolSaga from "./SchoolList/schoolSaga";
import SchoolCourseSaga from "./SchoolCourse/schoolCourseSaga";
import SchoolCoursesSaga from "./SchoolCourses/SchoolCoursesSaga";
import UserCoursesSaga from "./UserCourses/UserCoursesSaga";
import TenantsSaga from "./Tenants/tenantSaga";

function* rootSaga() {
    yield all([
        fork(CourseVariantsSaga), 
        fork(SchoolSaga), 
        fork(SchoolCourseSaga),
        fork(SchoolCoursesSaga),
        fork(UserCoursesSaga),
        fork(TenantsSaga),
    ]);
}

export default rootSaga;
