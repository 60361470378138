import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import DeviceList from "./DeviceList";
import { onOpenAddPopup, onEditDevice } from "../tabletActions";
import { isLoadingDevices, getDeviceList, isTabletEditOpen } from "../tabletSelectors";
import { getSchools } from "../../External/SchoolList/schoolSelectors";
import { onSchoolLoad } from "../../External/SchoolList/schoolActions";
import { onSelectUser } from "../../External/UserCourses/UserCoursesActions";
import services from "../../feathersjs/services";
import { feathersjsConnect } from "../../feathersjs/feathersjsConnect";

const mapStateToProps = state => ({
    isLoadingDevices: isLoadingDevices(state),
    devices: getDeviceList(state),
    isOpen: isTabletEditOpen(state),
    schools: getSchools(state),
});

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onOpenAddPopup,
            onEditDevice,
            onDeleteDevice: services.oauthclient.remove,
            onSchoolLoad,
            onImportDevices: services.oauthclient.create,
            onSelectUser,
        },
        dispatch
    );
};

export default compose(connect(mapStateToProps, dispatchToProps), feathersjsConnect("oauthclient"))(DeviceList);
