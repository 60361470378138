import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";

import { getTenants, isLoading, isCreating, newTenantInfo } from "./tenantSelectors";
import { onTenantLoad, onCreateDemoTenant, onUpdateModules } from "./tenantActions";
import TenantsList from "./TenantsList";

const mapStateToProps = function(state) {
    return {
        tenants: getTenants(state),
        isLoading: isLoading(state),
        isCreating: isCreating(state),
        newTenantInfo: newTenantInfo(state),
    };
};

const dispatchToProps = { 
    onTenantLoad,
    onCreateDemoTenant,
    onUpdateModules,
};

export default compose(
    firebaseConnect(["user", "modules/list", "tenant_modules"]),
    connect(mapStateToProps, dispatchToProps)
)(TenantsList);
