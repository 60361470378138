import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

export default class RemoveIcon extends PureComponent {
  static propTypes = {
      id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
      ]),
      onRemove: PropTypes.func,
      entityName: PropTypes.string,
      bordered: PropTypes.bool,
      disabled: PropTypes.bool,
      className: PropTypes.string,
  };

  handleRemove = e => {
      const { id, onRemove } = this.props;
      e.stopPropagation();
      onRemove(e, id);
  };

  render() {
      const { entityName, bordered, disabled = false, className = "" } = this.props;

      return (
          <Icon
              bordered={bordered}
              className={className}
              {...(disabled ? { disabled: true } : { onClick: this.handleRemove })}
              name="trash alternate outline"
              title={`Remove ${entityName}`}
              link
          />
      );
  }
}
