exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2v2UwsqfGUf9RJZnA_GGA2 {\n  max-height: 30vh;\n  overflow-y: auto;\n  min-height: 75px; }\n", ""]);

// exports
exports.locals = {
	"editor-wrapper": "_2v2UwsqfGUf9RJZnA_GGA2"
};