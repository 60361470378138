import React from "react";
import { Menu, Accordion } from "semantic-ui-react";
import PropTypes from "prop-types";

import AccessControl from "../../auth";
import { SideBarMenuLink } from "../../component/SidebarMenuLink";
import { withAuth } from "../../component/withAuth";
import { SidebarMenu as TCMenu } from "../../TopicCollection";

const menuIndex = "curriculum";

class CurriculumMenu extends React.Component {
    static propTypes = {
        activeIndex: PropTypes.object,
        onClick: PropTypes.func,
        isSuperAuthorAtLeast: PropTypes.bool,
        isMoreThanUser: PropTypes.bool,
    };

    renderCommonWidgetItems = () => {
        return (
            <React.Fragment>
                <SideBarMenuLink to="/widgets/library" text="Widgets" />
                <SideBarMenuLink to="/widgets/glossary" text="Glossary" />
            </React.Fragment>
        );
    };

    render() {
        const { activeIndex, onClick, isSuperAuthorAtLeast, isMoreThanUser } = this.props;
        return (
            <React.Fragment>
                <Menu.Item>
                    <Accordion.Title
                        active={activeIndex[menuIndex]}
                        index={menuIndex}
                        content="Curriculum"
                        onClick={onClick}
                    />
                    <Accordion.Content active={activeIndex[menuIndex]}>
                        <Menu.Menu>
                            <AccessControl action="modules:visit">
                                <SideBarMenuLink to="/modules" text="Packages" />
                            </AccessControl>
                            <TCMenu />
                            <SideBarMenuLink to="/authorkits" text="Kits" />
                            {isMoreThanUser && (
                                isSuperAuthorAtLeast ? (
                                    <React.Fragment>
                                        <SideBarMenuLink to="/widgets/templates" text="Widget Templates" />
                                        {this.renderCommonWidgetItems()}
                                        <SideBarMenuLink to="/snippets" text="Styled Blocks" />
                                    </React.Fragment>
                                ) : (  
                                    <React.Fragment>{this.renderCommonWidgetItems()}</React.Fragment>
                                )
                            )}
                        </Menu.Menu>
                    </Accordion.Content>
                </Menu.Item>
            </React.Fragment>
        );
    }
}

export default withAuth(CurriculumMenu);
