import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Message, Header } from "semantic-ui-react";
// import { GroupedList } from "bmd-react";
import ObjectiveSetList from "./ObjectiveSetList";
import ObjectiveSetView from "./ObjectiveSetView";
import OiiView from "./OiiView";

export default class ObjectiveSet extends PureComponent {

    static propTypes = {
        objectiveSets: PropTypes.array,
        oiiObjectives: PropTypes.array,
        selectedObjectiveSet: PropTypes.object,
        onSelectObjectiveSet: PropTypes.func,
        onUpdateObjectiveSetName: PropTypes.func,
        onUpdateObjectiveName: PropTypes.func,
        onAddObjective: PropTypes.func,
        onAddOiiObjectives: PropTypes.func,
        onUpdateOiiObjectiveName: PropTypes.func,
        onRemoveOiiObjectiveName: PropTypes.func,
        onRemoveObjective: PropTypes.func,
        onRemoveObjectiveSet: PropTypes.func,
        error: PropTypes.object,
        onObjectiveSetError: PropTypes.func,
        onClearObjectiveSetError: PropTypes.func,
    };

    state = {
        textEditId: "",
    }

    setTextEditId = id => {
        this.setState({ textEditId: id });
    }

    getAndResetTextEditId = () => {
        const { textEditId } = this.state;
        this.setState({ textEditId: "" });
        return textEditId;
    }

    componentDidMount() {
        // workaround to prevent loosing Firebase listeners when switching from component using the same resources
        this.props.firebase.watchEvent("value", "/objective_sets");
        this.props.firebase.watchEvent("value", "/oii_objectives");
    }

    handleDismissError = () => {
        if (this.props.error)
            this.props.onClearObjectiveSetError();
    }

    render() {
        const {
            objectiveSets,
            oiiObjectives,
            onSelectObjectiveSet,
            selectedObjectiveSet,
            selectedObjectiveSetId,
            onUpdateObjectiveSetName,
            onAddObjectiveSet,
            onUpdateObjectiveName,
            onAddObjective,
            onChangeOrdering,
            onRemoveObjective,
            onRemoveObjectiveSet,
            onAddOiiObjective,
            onUpdateOiiObjectiveName,
            onRemoveOiiObjective,
            error,
            onObjectiveSetError,
            onClearObjectiveSetError,
        } = this.props;

        return (
            <React.Fragment>
                <Header as="h1">SPORK Objective Sets</Header>
                {error && error.message && <Message error floating content={error.message} onDismiss={this.handleDismissError} />}
                <Grid columns={2}>
                    <Grid.Column width={4}>
                        <ObjectiveSetList
                            objectiveSets={objectiveSets}
                            oiiObjectives={oiiObjectives}
                            onSelect={onSelectObjectiveSet}
                            onAddObjectiveSet={onAddObjectiveSet}
                            onRemoveObjectiveSet={onRemoveObjectiveSet}
                            setTextEditId={this.setTextEditId}
                            {...this.props} />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {selectedObjectiveSet ?
                            <ObjectiveSetView
                                key={selectedObjectiveSet.id}
                                selectedObjectiveSet={selectedObjectiveSet}
                                onUpdateName={onUpdateObjectiveSetName}
                                onUpdateObjectiveName={onUpdateObjectiveName}
                                onAddObjective={onAddObjective}
                                onChangeOrdering={onChangeOrdering}
                                onRemoveObjective={onRemoveObjective}
                                setTextEditId={this.setTextEditId}
                                getTextEditId={this.getAndResetTextEditId}
                                {...this.props} /> :
                            ("OII" === selectedObjectiveSetId) ?
                                <OiiView
                                    key={selectedObjectiveSetId}
                                    oiiObjectives={oiiObjectives}
                                    onAddObjective={onAddOiiObjective}
                                    onUpdateObjectiveName={onUpdateOiiObjectiveName}
                                    onRemoveObjective={onRemoveOiiObjective}
                                    onObjectiveSetError={onObjectiveSetError}
                                    onClearObjectiveSetError={onClearObjectiveSetError}
                                    error={error}
                                /> :
                                <Message info>Please select Objective Set in left column.</Message>}
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );


        // let data = [];

        // collections.map((keyValue) => {
        //     let collection = keyValue.value;
        //     data.push({
        //         item: (
        //             <List.Item
        //                 key={collection.collection_id}
        //                 collection={collection.collection_id}
        //                 content={collection.collection_name}
        //                 onClick={this.handleClick}
        //             />
        //         ),
        //         groupKey: collection.subject_name
        //     });
        // });

        // return <GroupedList itemList={data} groupingThreshold={4} selectable={false} />;
    }
}

// CollectionList.propTypes = {
//     collections: PropTypes.arrayOf(
//         PropTypes.shape({
//             key: PropTypes.string,
//             value: PropTypes.shape({ collection_name: PropTypes.string, collection_id: PropTypes.number })
//         })
//     ),
//     onSelect: PropTypes.func
// };