import {createSelector} from "reselect";
import { sipiTemplates } from "../SiPiTemplatesList/SiPiTemplatesListSelectors";
import { convertDatasetToList, compareValueByOrdering } from "../../utils/selectorUtils";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getEnabledModules } from "../../External/Tenants/tenantSelectors";

export const filterSiPiByScope = (scope) => {
    return ({value}) => {
        if ("teacher" === scope || value[scope]) {
            return true;
        }
        return false;
    };
};

export const getTemplateId = (state, props) => props.selectedTemplateId || props.match && props.match.params  && props.match.params.templateId;
export const templateData = (state) => state.firebase.data.sipi_templates && state.firebase.data.sipi_templates.data;
export const templateLessonUnits = (state) => state.firebase.data.sipi_templates && state.firebase.data.sipi_templates.lesson_units;

export const getTemplate = createSelector(
    getTemplateId,
    sipiTemplates,
    templateLessonUnits,
    tenantId,
    getEnabledModules,
    (templateId, templates, units, tenantId, enabledModules) => {
        if (templateId && templates && templates[templateId] && units && enabledModules) {
            const template = templates[templateId];
            if (tenantId === template.tenant_id || template.m && enabledModules.some(j => template.m && template.m[j])) {
                let outline = undefined;
                if (units[templateId]) {
                    outline = {};
                    units[templateId].map((lu, index) => {
                        if (lu.spork_cv_lu_id) {
                            const [unit, lesson] = lu.name.split(" :: ");
                            const newLesson = {...lu, name: lesson, index};
                            if (outline[unit]) {
                                outline[unit].push(newLesson);
                            } else {
                                outline[unit] = [newLesson];
                            }
                        } else {
                            if (outline.General) {
                                outline.General.push({ ...lu, index });
                            } else {
                                outline["General"] = [{ ...lu, index }];
                            }
                        }
                    });
                } if ( null === units[templateId]) {
                    outline = null;
                }
                return {...template, outline };
            } else {
                return { noAccess: true };
            }
        }
        if (templateId && templates && null === templates[templateId]) {
            return null;
        }
    }
);

export const getTemplateSiPi = createSelector(
    getTemplateId,
    templateData,
    (templateId, data) => {
        if (templateId && data) {
            if (data[templateId]) {
                const rawSyllabusData = data[templateId];
                const syllabusData = {};
                Object.keys(rawSyllabusData).map((lu) => {
                    let activities = convertDatasetToList(rawSyllabusData[lu]);
                    activities = activities.sort(compareValueByOrdering);
                    syllabusData[lu] = activities;
                });
                return syllabusData;
            } else if (null === data[templateId]) {
                return null;
            }
        }
    }
);

export const getTemplateSiPiByScope = createSelector(
    getTemplateId,
    templateData,
    (templateId, data) => {
        if (templateId && data) {
            if (data[templateId]) {
                const rawSyllabusData = data[templateId];
                const syllabusData = {};
                Object.keys(rawSyllabusData).map((lu) => {
                    let activities = convertDatasetToList(rawSyllabusData[lu]);
                    const teacherFilter = filterSiPiByScope("teacher");
                    const classroomFilter = filterSiPiByScope("classroom_view");
                    const studentFilter = filterSiPiByScope("student_view");
                    const teacher = activities.sort(compareValueByOrdering).filter(teacherFilter);
                    const student_view = activities.sort(compareValueByOrdering).filter(studentFilter);
                    const classroom_view = activities.sort(compareValueByOrdering).filter(classroomFilter);
                    syllabusData[lu] = { teacher, student_view, classroom_view };
                });
                return syllabusData;
            } else if (null === data[templateId]) {
                return null;
            }
        }
    }
);