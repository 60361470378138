import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import EditDevice from "./EditDevice";
import { onOpenAddPopup } from "../tabletActions";
import { editedDevice } from "../tabletSelectors";
import { getSchoolAsOptions, isLoading as isLoadingSchools } from "../../External/SchoolList/schoolSelectors";
import { onSchoolLoad } from "../../External/SchoolList/schoolActions";
import { getUsersSqlAsOptions, isLoading as isLoadingUsers } from "../../Users/UserList/UserListSelectors";
import { onUserListLoad } from "../../Users/UserList/UserListActions";
import { onSchoolCourseLoad } from "../../External/SchoolCourse/schoolCourseActions";
import { getSchoolCoursesAsOptions, isLoading as isLoadingSc } from "../../External/SchoolCourse/schoolCourseSelectors";
import { getSelectedYear } from "../../Year/YearsSelectors";
import services from "../../feathersjs/services";

const mapStateToProps = state => ({
    editedDevice: editedDevice(state),
    schools: getSchoolAsOptions(state),
    users: getUsersSqlAsOptions(state),
    schoolCourses: getSchoolCoursesAsOptions(state),
    isLoadingUsers: isLoadingUsers(state),
    isLoadingSchools: isLoadingSchools(state),
    isLoadingSc: isLoadingSc(state),
    selectedYear: getSelectedYear(state),
});

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onClose: onOpenAddPopup,
            onSave: services.oauthclient.create,
            onSchoolLoad,
            onUserListLoad,
            onUpdateDevice: services.oauthclient.patch,
            onSchoolCourseLoad,
        },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(EditDevice);
