import React from "react";
import PropTypes from "prop-types";
import { List, Icon } from "semantic-ui-react";

import "./ActionLinks.css";

const floatedPropType = PropTypes.oneOf(["left", "right"]);
const valignPropType = PropTypes.oneOf(["bottom", "middle", "top"]);
const sizePropType = PropTypes.oneOf(["mini", "tiny", "small", "medium", "large", "big", "huge", "massive"]);
const contentPropType = PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]);

const actionPropType = PropTypes.shape({
    /** Internal name of action (to differentiate what action was clicked) */
    name: PropTypes.string.isRequired,
    /** Optional name of icon to be shown (either icon, text or both must be specified) */
    icon: PropTypes.string,
    /** Optional icon size */
    iconSize: List.propTypes.size || sizePropType,
    /** Optional name of icon to be shown (either icon, text or both must be specified) */
    content: contentPropType,
});

export default class ActionLinks extends React.PureComponent {
    static propTypes = {
        /** Semantic UI list propTypes */
        celled: PropTypes.bool,
        divided: PropTypes.bool,
        floated: List.propTypes.floated || floatedPropType,
        horizontal: PropTypes.bool,
        size: List.propTypes.size || sizePropType,
        verticalAlign: List.propTypes.verticalAlign || valignPropType,
        /** Caption to be displayed before/above the links. */
        caption: contentPropType,
        /** Callback used when the action link is clicked. */
        onClick: PropTypes.func,
        /** Callback used when the action link is clicked. */
        actions: PropTypes.arrayOf(actionPropType).isRequired,
        /** Style used by list of links */
        style: PropTypes.object,
    };

    render() {
        const { caption, actions, onClick, ...listProps } = this.props;
        return listProps.horizontal ? (
            <List link {...listProps} className="bmd actions">
                {caption && <List.Item className="caption">{caption}</List.Item>}
                {actions.map((action) => {
                    const { name, icon, content, iconSize, ...itemProps } = action;
                    return (
                        <List.Item key={name} {...itemProps.active || { as:"a" }} name={name} {...onClick && {onClick: onClick}} {...itemProps} >
                            {/* content and icon must be rendered differently than icon without content (text) due to allignment in horizontal menu */}
                            { content ? <List.Content>
                                {icon && <Icon className={icon} />}
                                {content}
                            </List.Content> :
                                icon && <Icon className={icon} {...iconSize && {size: iconSize}} />
                            }
                        </List.Item>
                    );
                })}
            </List>
        ) : (
            <List link {...listProps} className="bmd actions">
                {caption && <List.Item className="caption">{caption}</List.Item>}
                {actions.map((action) => {
                    const { name, ...itemProps } = action;
                    return <List.Item key={name} {...itemProps.active || { as:"a" }} name={name} {...onClick && {onClick: onClick}} {...itemProps} />;
                })}
            </List>
        );
    }
}
