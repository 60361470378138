import React, { Component } from "react";
import { Menu, Dropdown, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import UserAvatar from "react-user-avatar";
import UserProfileDebugContainer from "../UserProfileDebug/UserProfileDebugContainer";
import UserInfo from "../UserList/component/UserInfo";
import RolesLabels from "../UserList/component/RolesLabels";
import { AboutSporkMenuItem } from "../../component/AboutSpork";

export default class UserProfile extends Component {
    static propTypes = {
        authUser: PropTypes.object,
    };

    _username = () => {
        const { authUser } = this.props;
        return (
            authUser && (
                <React.Fragment>
                    {authUser.name ? (
                        <UserAvatar
                            size="32"
                            name={authUser.name}
                            style={{ float: "left", margin: "4px" }}
                        />
                    ) : (
                        <Icon name="user circle outline" style={{ marginRight: "0.3em" }} />
                    )}
                    <div>{authUser.name}</div>
                </React.Fragment>
            )
        );
    };

    render() {
        return (
            <React.Fragment>
                <Menu.Menu position="right">
                    <Dropdown
                        lazyLoad
                        item
                        trigger={this._username()}
                        {...(this.props.authUser && this.props.authUser.name ? { style: { paddingTop: 0, paddingBottom: 0 } } : {})}
                    >
                        <Dropdown.Menu>
                            {// eslint-disable-next-line no-undef
                                process.env.NODE_ENV !== "production" && <UserProfileDebugContainer />}
                            {(this.props.authUser && this.props.authUser.name) && <UserInfo 
                                userName={this.props.authUser.name} 
                                meta={this.props.authUser.tenant.name} 
                                description={<RolesLabels roles={this.props.authUser.roles} vertical/>}
                            />}
                            <Dropdown.Divider />
                            <Link to={"/section/users/"+this.props.authUser.sporkUid} className="item">
                                My classes
                            </Link>                            
                            <AboutSporkMenuItem />
                            <Dropdown.Divider />
                            <Link to="/app/logout" className="item">
                                <Icon name="log out" />
                                Logout
                            </Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </React.Fragment>
        );
    }
}
