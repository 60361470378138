import React from "react";
import PropTypes from "prop-types";
import { SortDirection } from "react-virtualized";
import { InfiniteTable } from "bmd-react";
import { Header, Icon, Segment, Loader, Dimmer } from "semantic-ui-react";
import ErrorDetail from "./ErrorDetailContainer";

import styles from "./errorLogViewer.module.scss";

export default class ErrorLogViewer extends React.PureComponent {
    static propTypes = {
        errors: PropTypes.arrayOf(
            PropTypes.shape({
                ts: PropTypes.string.isRequired,
                uid: PropTypes.string.isRequired,
                msg: PropTypes.string.isRequired,
            })
        ),
        isLoading: PropTypes.bool,
    };

    state = {
        currentCell: null,
    }

    _columns = [{
        label: "Timestamp (UTC)",
        dataKey: "ts",
        width: 180,
        visible: true,
    }, {
        label: "User ID",
        dataKey: "uid",
        width: 150,
        visible: true,
    }, {
        label: "Message",
        dataKey: "msg",
        width: 600,
        visible: true,
    }, {
        label: "Runtime",
        dataKey: "run",
        width: 100,
        visible: true,
    }, {
        label: "#",
        dataKey: "cnt",
        width: 60,
        visible: true,
        style: { textAlign: "right" },
    }, {
        label: "",
        dataKey: "unl",
        width: 30,
        visible: true,
        disableSort: true,
        cellRenderer: ({ cellData }) => (
            cellData && <Icon name="sync" className={styles.icon} title="on unload" />
        ),
    }, {
        label: "",
        dataKey: "key",
        width: 30,
        visible: true,
        disableSort: true,
        cellRenderer: ({ cellData }) => (
            <Icon name="eye" className={styles.icon + " " + styles.detailIcon} onClick={this.showDetails} data-error-key={cellData} />
        ),
    }];

    infiniteTableRef = React.createRef();

    componentDidMount = () => {
        this.infiniteTableRef.current.setState({ sortDirection: SortDirection.DESC });
    };

    showDetails = (event) => {
        const cellNode = event.target;
        const errorKey = cellNode.dataset.errorKey;
        this.setState({ currentCell: cellNode.parentNode, errorKey });
    };

    hideDetails = () => {
        this.setState({ currentCell: null });
    };

    render() {
        const { errors, isLoading } = this.props;
        const { currentCell, errorKey } = this.state;
        return (
            <React.Fragment>
                <Header as="h2">
                    Errors
                </Header>
                <Segment basic style={{ minHeight: "350px" }}>
                    <Dimmer active={isLoading} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <InfiniteTable
                        ref={this.infiniteTableRef}
                        data={errors}
                        columns={this._columns}
                        tableName="errors"
                        defaultSortBy="ts"
                        rowStyle={{ outline: "none" }}
                    />
                    {currentCell && <ErrorDetail context={currentCell} errorKey={errorKey} onClose={this.hideDetails} />}
                </Segment>
            </React.Fragment>
        );
    }
}
