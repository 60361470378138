import React from "react";
import PropTypes from "prop-types";
import { Header, Divider, Tab, Segment, Icon, Dimmer, Loader } from "semantic-ui-react";
import EditableText from "../../component/EditableText";
import { WidgetViewer } from "../Widget";
import WidgetEditor from "./WidgetEditor";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import RemoveIcon from "../../component/RemoveIcon";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import TagsContainer from "../Tags/TagsContainer";
import WidgetTopics from "../Widget/WidgetTopics";
import Comments from "../Comments";
import WidgetLastEditor from "./WidgetLastEditor";
import { confirmUnsave } from "../LessonContent/utils";
import { TYPE as LOCK_TYPE } from "../../Lock";
import LockableBlock from "../../Lock/LockableBlock";
import EntityModuleList from "../../component/EntityModuleList";
import { AccessDeniedMessage } from "../../component/AccessDeniedMessage";

export default class WidgetDetail extends React.PureComponent {
    static propTypes = {
        selectedWidget: PropTypes.object,
        selectedWidgetId: PropTypes.string,
        onUpdateName: PropTypes.func,
        orderedItemList: PropTypes.array,
        widgetsInfo: PropTypes.object,
        templatesInfo: PropTypes.object,
        onDeleteWidget: PropTypes.func,
        isAuthorAtLeast: PropTypes.bool,
        isReviewerAtLeast: PropTypes.bool,
        onCloseDetail: PropTypes.func,
        onCloseItemEditor: PropTypes.func,
        onUpdateItem: PropTypes.func,
        onSetTagFilter: PropTypes.func,
        onSetGlossaryPreferencesByOutline: PropTypes.func,
        onSetOutlineForLessonLinks: PropTypes.func,
        loadingWidgetData: PropTypes.bool,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
        potentiallyUnknownWidgets: PropTypes.object,
        onSetSnippetModuleFilter: PropTypes.func,
    };

    state = {
        removeId: null,
        removeContext: null,
        activeIndex: 0,
    };

    handleRemove = (e, id) => {
        const { removeId } = this.state;
        if (!removeId) {
            this.setState({
                removeContext: e.currentTarget,
                removeId: id,
            });
        }
    };

    handleCancelRemove = () => {
        this.setState({ removeId: null, removeContext: null });
    };

    handleRemoveConfirm = () => {
        const { onDeleteWidget } = this.props;
        const { removeId } = this.state;
        if (onDeleteWidget && removeId) {
            onDeleteWidget({ key: removeId });
        }
        this.handleCancelRemove();
    };

    handleUpdateName = name => {
        this.props.onUpdateName({ name, key: this.props.selectedWidget.key });
    };

    handleSetTagFilter = widget => {
        const { onSetTagFilter } = this.props;
        if (widget) {
            onSetTagFilter(widget.tags || {});
        }
    };

    componentDidMount() {
        const {
            selectedWidget,
            onSetGlossaryPreferencesByOutline,
            onSetOutlineForLessonLinks,
            onSetDirty,
            onSetSnippetModuleFilter
        } = this.props;
        onSetDirty(false);
        onSetGlossaryPreferencesByOutline(null);
        onSetOutlineForLessonLinks(null);
        this.handleSetTagFilter(selectedWidget);
        onSetSnippetModuleFilter(selectedWidget && selectedWidget.modules || []);
    }

    componentDidUpdate(prevProps) {
        const { selectedWidget } = this.props;
        if ((!prevProps.selectedWidget && selectedWidget) || prevProps.selectedWidget != selectedWidget) {
            this.handleSetTagFilter(selectedWidget);
        }
    }

    handleGetItemLockId = () => ({ lockType: LOCK_TYPE.PROBLEM, itemId: this.props.selectedWidgetId });

    renderEditor = asReview => {
        const {
            selectedWidget,
            orderedItemList,
            isAuthorAtLeast,
            isReviewerAtLeast,
            onCloseItemEditor,
            onUpdateItem,
            selectedWidgetId,
        } = this.props;
        const lockType = { lockType: LOCK_TYPE.WIDGET, itemId: selectedWidgetId };

        return (
            <LockableBlock lockType={lockType} hideLockButton={true}>
                <WidgetEditor
                    key={asReview ? "review" : "editor"}
                    asReview={asReview}
                    widget={selectedWidget}
                    items={orderedItemList}
                    canEdit={isAuthorAtLeast}
                    canReview={isReviewerAtLeast}
                    onUpdateItem={onUpdateItem}
                    onCloseItemEditor={onCloseItemEditor}
                />
            </LockableBlock>
        );
    };

    renderComposeEditor = () => {
        return this.renderEditor(false);
    };

    renderReviewEditor = () => {
        return this.renderEditor(true);
    };

    renderPreview = () => {
        const { selectedWidget } = this.props;
        return (
            <WidgetViewer
                template={{ ...selectedWidget.template, key: selectedWidget.templateId }}
                content={selectedWidget.items}
                loading={!selectedWidget}
                customTitle={selectedWidget.custom_title}
                hiddenTitle={selectedWidget.hidden_title}
            />
        );
    };

    handleCloseDetail = () => {
        const { isDirty, onSetDirty, onCloseDetail } = this.props;
        if (!isDirty || confirmUnsave("When you now close the widget editor", onSetDirty)) {
            onCloseDetail();
        }
    };

    handleTabChange = (e, { activeIndex }) => {
        const { isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you switch to another tab", onSetDirty)) {
            this.setState({ activeIndex });
        }
    };

    getTabPanes = () => {
        const { isAuthorAtLeast, isReviewerAtLeast } = this.props;
        const panes = [];
        if (isAuthorAtLeast) {
            panes.push({ menuItem: "Compose", render: this.renderComposeEditor });
        }
        panes.push({ menuItem: "Preview", render: this.renderPreview });
        if (isReviewerAtLeast) {
            panes.push({ menuItem: "Review", render: this.renderReviewEditor });
        }
        return panes;
    };

    render() {
        const {
            selectedWidget,
            selectedWidgetId,
            widgetsInfo,
            templatesInfo,
            isAuthorAtLeast,
            isReviewerAtLeast,
            loadingWidgetData,
            potentiallyUnknownWidgets,
        } = this.props;
        const { removeContext, removeId, activeIndex } = this.state;
        if (!selectedWidget || !widgetsInfo[selectedWidget.key]) {
            return (
                <Dimmer.Dimmable dimmed={loadingWidgetData}>
                    <Dimmer active={loadingWidgetData} inverted>
                        <Loader>Loading widget data...</Loader>
                    </Dimmer>
                    {selectedWidgetId &&
                    potentiallyUnknownWidgets &&
                    null === potentiallyUnknownWidgets[selectedWidgetId] ? (
                            <NoDataPlaceholder text="Oh, snap. It is not there.">
                                <p>We did our best but we cannot find the widget.</p>
                            </NoDataPlaceholder>
                        ) : (
                            <Segment placeholder />
                        )}
                </Dimmer.Dimmable>
            );
        }
        const widgetInfo = widgetsInfo[selectedWidget.key];
        return  selectedWidget.noAccess ? <AccessDeniedMessage /> : (
            <React.Fragment>
                {isAuthorAtLeast && (
                    <ConfirmDeleteWithContext
                        context={removeContext}
                        confirmText="Remove widget"
                        onConfirm={this.handleRemoveConfirm}
                        whatText={`widget ${widgetsInfo && widgetsInfo[removeId] && widgetsInfo[removeId].name}`}
                        onCancel={this.handleCancelRemove}
                    />
                )}
                <div style={{ display: "grid", gridTemplateColumns: "90% 10%" }}>
                    <Header as="h3" style={{ fontWeight: "lighter" }}>
                        <EditableText
                            text={widgetInfo.name}
                            onUpdate={this.handleUpdateName}
                            canEdit={isAuthorAtLeast}
                        />
                        <Header.Subheader>
                            Styled by template <em>{templatesInfo && templatesInfo[widgetInfo.templateId].name}</em>.
                        </Header.Subheader>
                    </Header>
                    <span style={{ textAlign: "right" }}>
                        {isAuthorAtLeast &&
                            (!widgetInfo.used ? (
                                <RemoveIcon
                                    bordered
                                    onRemove={this.handleRemove}
                                    id={selectedWidget.key}
                                    entityName={`widget ${widgetInfo.name}`}
                                />
                            ) : (
                                <Icon
                                    bordered
                                    name="trash alternate outline"
                                    disabled
                                    title="This widget cannot be removed, because is already used in some lesson."
                                />
                            ))}
                        <Icon name="close" bordered title="Close widget detail" link onClick={this.handleCloseDetail} />
                    </span>
                </div>
                <div style={{ float: "right" }}>
                    <WidgetLastEditor itemId={selectedWidget.key} />
                </div>
                <Divider clearing fitted hidden />
                {loadingWidgetData ? (
                    <Segment placeholder loading />
                ) : isAuthorAtLeast || isReviewerAtLeast ? (
                    <Tab
                        menu={{ compact: true }}
                        key={selectedWidget.key}
                        activeIndex={activeIndex}
                        onTabChange={this.handleTabChange}
                        panes={this.getTabPanes()}
                    />
                ) : (
                    this.renderPreview()
                )}
                <Divider horizontal section hidden />
                <TagsContainer
                    tags={widgetInfo.tags || {}}
                    canEdit={isAuthorAtLeast}
                    showButton
                    verbose
                    path={`widgetLibrary/list/${selectedWidget.key}/tags`}
                />
                <WidgetTopics
                    widget={widgetInfo}
                    id={selectedWidget.key}
                    viewOnly
                    libraryMode
                    canEdit={isAuthorAtLeast}
                    divider
                />
                {selectedWidget.modules && <EntityModuleList list={selectedWidget.modules} showHeader />}
                <Comments threadId={selectedWidget.key} />
            </React.Fragment>
        );
    }
}
