import { TYPES } from "./LessonExportsActions";

const initState = {
    isExportInProgress: false,
};

const lessonExportsReducer = (state = initState, { type }) => {
    switch (type) {
        case TYPES.LESSON_EXPORTS_EXPORT_TO_PDF: {
            return { ...state, isExportInProgress: true };
        }
        case TYPES.LESSON_EXPORTS_EXPORT_DONE: {
            return { ...state, isExportInProgress: false };
        }
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default lessonExportsReducer;
