import { createSelector } from "reselect";

export const getAllYears = state => state.feathers.years.queryResult;
const selectedYearId = state => state.years.selectedYearId;
const getShowValid = state => state.years.showValid;
export const isLoading = state => state.feathers.years.isLoading;
export const isImporting = state => state.years.isImporting;

export const getValidYears = createSelector(getAllYears, (years) => {
    return years.filter(({ valid }) => valid);
});

export const getYearsAsObject = createSelector(getValidYears, year => {
    let years = {};
    year.map( y => years[y.id] = y);
    return years;
});

export const getSelectedYear = createSelector(
    getValidYears,
    selectedYearId,
    (years, selectedId) => {
        return selectedId && years.find(year => year.id === selectedId);
    }
);

export const getSelectedYearId = createSelector(selectedYearId, (selectedId) => { return selectedId; });

export const getYearOptions = createSelector(getValidYears, (years) => (
    years.map(year => ({ key: year.id, value: year.id, text: year.name }))
));

export const getYearList = createSelector(getAllYears, getShowValid, (years, showValid) => {
    return years.filter(({ valid }) => Boolean(valid) === showValid);
});

export const getApiCallsByExistence = (currentData, importedData) => {
    const yearIdMap = currentData.reduce((acc, { name, id }) => {
        acc[name] = id;
        return acc;
    }, {});

    const updates = [];
    importedData.forEach((year) => {
        const existingYearId = yearIdMap[year.name];
        if (existingYearId) {
            // Currently we don't want to patch existing data
            // updates.push({ method: "PATCH", uri: `/year/${existingYearId}`, body: { valid: year.valid, default: year.default } });
        }
        else {
            updates.push(year);
        }
    });

    return updates;
};