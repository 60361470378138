import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { PureComponent } from "react";
import { Modal, Menu, Icon, Form, Dropdown, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { isLoading as schoolIsLoading, getSchoolOnlyAsOptions } from "../../SchoolList/schoolSelectors";
import { onSchoolLoad } from "../../SchoolList/schoolActions";
import { schoolFilter } from "../SchoolCoursesSelectors";
import { onChangeSchoolFilter } from "../SchoolCoursesActions";

class AdvancedFilter extends PureComponent {
    static propTypes = {
        onChangeSchoolFilter: PropTypes.func,
        schoolOptions: PropTypes.array,
        schoolIsLoading: PropTypes.bool,
        schoolFilter: PropTypes.any,
        onSchoolLoad: PropTypes.func,
    };

    state = {
        open: false
    };

    componentDidMount() {
        this.props.onSchoolLoad();
    }

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    composeFilterLabel = (filter) => {
        if (!filter) {
            return "Filter";
        } else {
            return "Filtered (by 1)";
        }
    }

    handleChangeSchool = (e, {value}) => {
        this.props.onChangeSchoolFilter(value);
    }

    render() {
        const { schoolOptions, schoolIsLoading, schoolFilter } = this.props;
        const { open } = this.state;
        return (
            <Modal
                open={open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                trigger={
                    <Menu.Item link title="Click here to set advanced filter.">
                        <Icon name="filter" />
                        {this.composeFilterLabel(!!schoolFilter)}
                    </Menu.Item>
                }
                size="small"
            >
                <Modal.Header>Advanced school courses filter</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSave}>
                        <Form.Field>
                            <label>
                                School
                            </label>
                            <Dropdown
                                fluid
                                selection
                                search
                                options={schoolOptions || []}
                                clearable
                                loading={schoolIsLoading}
                                value={schoolFilter}
                                onChange={this.handleChangeSchool}
                                placeholder="Select school to filter school courses"
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onSchoolLoad,
            onChangeSchoolFilter,
        },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        schoolOptions: getSchoolOnlyAsOptions(state),
        schoolIsLoading: schoolIsLoading(state),
        schoolFilter: schoolFilter(state),
    }),
    dispatchToProps
)(AdvancedFilter);