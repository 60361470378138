import { TYPES } from "./authActions";

const initialState = { user: { roles: [], tenant: {} } };

const createUser = payload => {
    let user = { roles: [] };
    Object.keys(payload || {}).map(key => {
        if (key.match(/^ROLE_/)) {
            user.roles.push(key);
        } else {
            user[key] = payload[key];
        }
    });
    user.uid = user.sub;
    return user;
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.SET_AUTH:
            return { ...state, user: createUser(payload) };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
