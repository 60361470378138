import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { onLessonShowProperties } from "../../outlinesActions";
import LessonsList from "./LessonsList";


const dispatchToProps = (dispatch) => {
    return bindActionCreators({ onLessonShowProperties }, dispatch);
};

export default compose(
    connect(null, dispatchToProps)
)(LessonsList);