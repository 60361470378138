import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FilterByDialog from "./FilterByDialog";
import FilterByButton from "./FilterByButton";
import FilterByView from "./FilterByView";
import {
    FilterParamsProps,
    FilterParamsShape,
    isFiltered
} from "./utils";

import {
    onApplyFilterBy,
    onCloseFilterBy,
    onFetchUsedTags,
    onFetchUsedCourseVariants,
    onSetCourseVariantFilter,
    onFetchUsedTopics,
} from "../ResourcesActions";
import {
    isLoadingTags,
    isLoadingVariants,
    getFilterParams,
    getFilterByTagsOptions,
    getCourseVariantsOptions,
    isLoadingTopics,
    usedTopics,
} from "../ResourcesSelectors";
import { getResourceTypesOptions, isLoadingResourceTypes, isLoadingOwners, getOwnersOptions } from "../../component/AdvancedResourceSearch/AdvancedResourceSearchSelectors";
import { onLoadResourceTypes, onFetchOwners } from "../../component/AdvancedResourceSearch/AdvancedResourceSearchActions";

const mapStateToProps = (state) => ({
    filterParams: getFilterParams(state),
    isLoadingTags: isLoadingTags(state),
    isLoadingVariants: isLoadingVariants(state),
    resourceTags: getFilterByTagsOptions(state),
    courseVariantOptions: getCourseVariantsOptions(state),
    isLoadingResourceTypes: isLoadingResourceTypes(state),
    resourceTypesOptions: getResourceTypesOptions(state),
    isLoadingOwners: isLoadingOwners(state),
    ownersOptions: getOwnersOptions(state),
    isLoadingTopics: isLoadingTopics(state),
    usedTopics: usedTopics(state),
});

const dispatchToProps = dispatch => bindActionCreators({
    onApplyFilterBy,
    onCloseFilterBy,
    onFetchUsedTags,
    onFetchUsedCourseVariants,
    onSetCourseVariantFilter,
    onLoadResourceTypes,
    onFetchOwners,
    onFetchUsedTopics,
}, dispatch);

const FilterBy = connect(mapStateToProps, dispatchToProps)(FilterByDialog);

FilterBy.displayName = "FilterByContainer";
FilterBy.Button = FilterByButton;
FilterBy.View = FilterByView;
FilterBy.propTypes = FilterParamsProps;
FilterBy.propShape = FilterParamsShape;
FilterBy.isFiltered = isFiltered;

export {
    FilterBy as default,
    FilterByButton,
    FilterByView,
    FilterParamsShape
};
