import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import {
    onLessonUnitRename,
    onLessonUnitUpdateDescription,
    onLessonUnitRemove,
    onEditingText,
} from "../../outlinesActions";
import { getOpenedTextEditorId } from "../../outlinesSelectors";
import LessonUnit from "./LessonUnit";


const mapStateToProps = function(state) {
    return {
        openEditorId: getOpenedTextEditorId(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onLessonUnitRename,
            onLessonUnitUpdateDescription,
            onLessonUnitRemove,
            onEditingText,
        },
        dispatch
    );
};

export default compose(
    connect(mapStateToProps, dispatchToProps)
)(LessonUnit);
