import { all, put, takeEvery } from "redux-saga/effects";
import firebase from "../../../../firebase";
import { TYPES, onSetSchool } from "./SchoolListItemActions";
import { toastError } from "../../../../component/toast";

function* loadSchool({ payload }) {
    try {
        const school = yield firebase.tabletProxy({
            method: "GET",
            uri: `/school/${payload}`,
        });
        
        yield put(onSetSchool(school.data));
    } catch (err) {
        toastError({ code: err.details.name, header: "Failed to load school with ID " + payload, message: err.details.message });
    }
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.SECTION_LOAD_SCHOOL, loadSchool),
    ]);
}
