import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isSaving } from "./ResourcePublicFlagSelectors";
import { onUpdate } from "./ResourcePublicFlagActions";
import ResourcePublicFlag from "./ResourcePublicFlag";

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onUpdate,
        },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        isSaving: isSaving(state),
    }),
    dispatchToProps
)(ResourcePublicFlag);