const TYPES = {
    FIX_IPLANNER_RESOURCES_FIX_DATA: "FIX_IPLANNER_RESOURCES_FIX_DATA",
    FIX_IPLANNER_RESOURCES_FIX_DATA_FINISHED: "FIX_IPLANNER_RESOURCES_FIX_DATA_FINISHED",
};

const onFixData = (payload) => ({
    type: TYPES.FIX_IPLANNER_RESOURCES_FIX_DATA,
    payload
});

const onFixDataFinished = () => ({
    type: TYPES.FIX_IPLANNER_RESOURCES_FIX_DATA_FINISHED,

});

export {
    TYPES,
    onFixData,
    onFixDataFinished,
};
