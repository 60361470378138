import { connect } from "react-redux";
import SiPiTestCreator from "./SiPiTestCreator";
import { bindActionCreators, compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { getProblemSets, getProblemSetsAsOptions } from "../../../QuestionUtilizator/ProblemSetManager/problemSetManagerSelectors";
import { onSelectProblemSet, onCreateTests } from "./SiPiTestCreatorActions";
import { selectedProblemSetId, isGeneratingHtml, problemSetsHtml, isCreatingTests } from "./SiPiTestCreatorSelectors";


const mapStateToProps = (state, props) => ({
    isProblemSetLoading: undefined === getProblemSets(state, props),
    problemSetsOptions: getProblemSetsAsOptions(state, props),
    selectedProblemSetId: selectedProblemSetId(state),
    isGeneratingHtml: isGeneratingHtml(state),
    problemSetsHtml: problemSetsHtml(state),
    isCreatingTests: isCreatingTests(state),
});

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onSelectProblemSet,
            onCreateTests,
        },
        dispatch
    );
};

export default compose(
    firebaseConnect(["problemSet"]),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(SiPiTestCreator);