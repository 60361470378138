import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Icon } from "semantic-ui-react";

import "./NoDataPlaceholder.scss";

export default class NoDataPlaceholder extends React.PureComponent {
    static propTypes = {
        icon: PropTypes.string,
        text: PropTypes.string,
        children: PropTypes.node,
    }

    render() {
        const { icon, text, children, ...otherProps } = this.props;
        return (
            <Segment placeholder {...otherProps} className="noDataPlaceholderStyle">
                <Header icon >
                    <Icon className={icon || "file outline"} />
                    {text || "No data"}
                </Header>
                {children}
            </Segment>
        );
    }
}
