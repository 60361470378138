import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class WidgetTopicsTrigger extends PureComponent {
    static propTypes = {
        topicsCount: PropTypes.number,
        onClick: PropTypes.func,
    };

    getTitle = count => {
        if (0 < count) {
            return "Click here to see details or edit widget topics.";
        } else {
            return "Click here to assign first topic to this widget.";
        }
    };

    getShortTitle = count => {
        if (0 < count) {
            let title = `${count} topic`;
            if (1 < count) {
                title += "s";
            }
            return title;
        } else {
            return "No topics ...";
        }
    };

    render() {
        const { topicsCount, onClick } = this.props;
        return (
            <span title={this.getTitle(topicsCount)} onClick={onClick} >
                {this.getShortTitle(topicsCount)}
            </span>
        );
    }
}
