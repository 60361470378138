import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Form, Radio, Message, Icon } from "semantic-ui-react";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";
import UserList from "../../../SchoolCourses/component/UserList";
import "./UserSearch.scss";

export default class UserSearch extends PureComponent {
    static propTypes = {
        schoolOptions: PropTypes.array,
        onSchoolLoad: PropTypes.func,
        onSearch: PropTypes.func,
        isSearching: PropTypes.bool,
        foundPeople: PropTypes.array,
        onSelectUser: PropTypes.func,
        onSetFieldValue: PropTypes.func,
        schoolId: PropTypes.any,
        name: PropTypes.string,
        userScope: PropTypes.string,
    }

    componentDidMount() { 
        this.props.onSchoolLoad();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSearching && !this.props.isSearching) {
            if (0 === this.props.foundPeople.length) {
                this.setState({ notFound: true });
            } else {
                this.setState({ notFound: false });
            }
        }
    }

    state = { 
        errors: [],
        notFound: false,
    }

    handleChange = (e, { name, value }) => {
        this.props.onSetFieldValue({ name, value });
        this.setState({ errors: this.state.errors.filter(error => error != name) });
    }

    handleSearch = () => {
        const errors = [];
        const { onSearch, userScope, schoolId, name } = this.props;
        if (0 == name.trim().length) {
            errors.push("name");
        }
        this.setState({ errors });
        if (0 === errors.length) {
            onSearch && onSearch({ userScope, name: name.trim(), schoolId });
        }
    }


    render() {
        const { schoolOptions, foundPeople, isSearching, onSelectUser, schoolId, userScope, name } = this.props;
        const { errors, notFound } = this.state;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Search for teachers or students
                </Header>
                <Form error={0 !== errors.length} className="userPersonSearch">
                    <Form.Group style={{alignItems: "center"}} >
                        <Form.Input
                            disabled={isSearching}
                            width="6"
                            name="name"
                            icon="search"
                            placeholder="Search..."
                            className="search"
                            onChange={this.handleChange}
                            autoFocus
                            value={name}
                            {...errors.indexOf("name") != -1 && {
                                error: true,
                                placeholder: "Please fill at least a part of the name of person you search for",
                            }}
                        />
                        <Form.Field width="3" >
                            <Radio
                                disabled={isSearching}
                                label="Teacher"
                                name="userScope"
                                value="teacher"
                                checked={userScope === "teacher"}
                                onChange={this.handleChange}
                            />
                            <Radio
                                disabled={isSearching}
                                style={{paddingLeft: "0.5em"}}
                                label="Student"
                                name="userScope"
                                value="student"
                                checked={userScope === "student"}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>School</Form.Field>
                        <Form.Select
                            width="7"
                            search
                            disabled={isSearching}
                            name="schoolId"
                            {...errors.indexOf("schoolId") != -1 && {
                                error: true,
                                placeholder: "Please select school",
                            }}
                            placeholder="Select School"
                            clearable
                            options={schoolOptions || []}
                            onChange={this.handleChange}
                            value={schoolId}
                        />
                    </Form.Group>
                    <Form.Button disabled={isSearching} primary icon="search" onClick={this.handleSearch} content="Search" />
                </Form>
                {isSearching ? <Message info>
                    Searching {userScope}s ... <Icon name="circle notched" loading />
                </Message>
                    : notFound ? (
                        <NoDataPlaceholder
                            icon="search"
                            text="Oh, snap. No result."
                        >
                            <p>We cannot find any person matching your search criteria.</p>
                        </NoDataPlaceholder>
                    ) : 0 < foundPeople.length && (
                        <div style={{maxHeight: "70vh", overflowY: "auto"}}>
                            <UserList userList={foundPeople} onSelectUser={onSelectUser} />
                        </div>
                    )}
            </React.Fragment>
        );
    }
}
