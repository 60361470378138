import { createSelector } from "reselect";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";

const tenantModules = ({ firebase }) => firebase.data.tenant_modules;
export const editedTenantId = (state, props) => props && props.tenantId || tenantId(state);
export const getSubscribedModules = createSelector(
    tenantModules,
    editedTenantId,
    (tenantModules, tenantId) => {
        if (undefined !== tenantModules) {
            return tenantModules && tenantModules[`t${tenantId}`] || {};
        }
    }
);