import React from "react";
import { Route } from "react-router-dom";
import ErrorLogViewer from "./logs/ErrorLogViewerContainer";
import ImportGlossaryContainer from "./ImportGlossary";
import ImportHomeworkContainer from "./ImportHomework";
import AccessControl from "../auth/AccessControl";
import ConvertLessonDataContainer from "./ConvertLessonData";
import UpdateProblemTagsContainer from "./UpdateProblemTags/UpdateProblemTagsContainer";
import updateLessonReviewStats from "./UpdateLessonReviewStats/UpdateLessonReviewStatsContainer";
import GeneratesDataForiPlanner from "./GeneratesDataForiPlanner";
import FixRefs4ProblemsAndProblemSets from "./FixRefs4ProblemsAndProblemSets";
import CreateProblemSetsData from "./CreateProblemSetsData";
import AdminFunctions from "./AdminFunctions";
import FixiPlannerResources from "./FixiPlannerResources";

const Routes = () => (
    <AccessControl action="maintenance:visit">
        <Route path="/maintenance/logs" component={ErrorLogViewer} />
        <Route path="/maintenance/adminFunctions" component={AdminFunctions} />
        <Route path="/maintenance/importGlossary" component={ImportGlossaryContainer} />
        <Route path="/maintenance/importHomework" component={ImportHomeworkContainer} />
        <Route path="/maintenance/convertLessonData" component={ConvertLessonDataContainer} />
        <Route path="/maintenance/updateProblemTags" component={UpdateProblemTagsContainer} />
        <Route path="/maintenance/updateLessonReviewStats" component={updateLessonReviewStats} />
        <Route path="/maintenance/generatesiPlannerData" component={GeneratesDataForiPlanner} />
        <Route path="/maintenance/fixProblemsAndProblemSetsRefs" component={FixRefs4ProblemsAndProblemSets} />
        <Route path="/maintenance/createProblemSetsData" component={CreateProblemSetsData} />
        <Route path="/maintenance/fixiPlannerResources" component={FixiPlannerResources} />
    </AccessControl>
);

export default Routes;
