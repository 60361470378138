import { createSelector } from "reselect";
import { fetchKitsData } from "../../KitBuilder/AKits/AKitsList/AKitsListSelectors";

export const kitId = state => state.topicTreeSelector.kitId;
export const isSearching = state => state.topicTreeSelector.isSearching;
export const lessonSearchFilter = state => state.topicTreeSelector.lessonSearchFilter;
export const lessonFilter = state => state.topicTreeSelector.lessonFilter;
export const searchResult = state => state.topicTreeSelector.searchResult;
export const getOutlineId = createSelector(
    kitId,
    fetchKitsData,
    (kitId, kitsData) => {
        if (kitId && kitsData && kitsData[kitId]) {
            return kitsData[kitId].outline_id;
        }
    }
);
