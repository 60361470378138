import { combineReducers } from "redux";

import { firebaseReducer } from "react-redux-firebase";
import dashboardReducer from "../dashboard/dashboardReducer";
import designReducer from "../dashboard/tiles/design/designTileReducer";
import { Reducer as TTTReducer } from "../TopicTransformationTool";
import topicsReducer from "../Topics/topicsReducer";
import { Reducer as OSMTReducer } from "../Topics/ObjectiveSetsManager";
import { Reducer as ConceptsReducer } from "../Topics/Concepts";
import YearsReducer from "../Year/YearsReducer";
import KitBuilderReducer from "../KitBuilder/Reducer";
import QuReducer from "../QuestionUtilizator/quReducer";
import TCReducer from "../TopicCollection/topicCollectionReducer";
import UsersReducer from "../Users/Reducer";
import ExternalReducer from "../External/Reducer";
import TSReducer from "../TopicCollection/TopicSelector/topicSelectorReducer";
import TTreeReducer from "../TopicCollection/TopicTreeSelector/TopicTreeSelectorReducer";
import MaintenanceReducer from "../Maintenance/Reducer";
import AuthReducer from "../auth/authReducer";
import SidebarMenuReducer from "../SidebarMenu/SidebarMenuReducer";
import TabletReducer from "../Tablet/Reducer";
import LUPReducer from "../LessonUnitPlanner/Reducer";
import FeatherReducers from "../feathersjs/reducers";

export default combineReducers({
    firebase: firebaseReducer,
    dashboard: dashboardReducer,
    dashboardDesign: designReducer,
    ttt: TTTReducer,
    topics: topicsReducer,
    osmt: OSMTReducer,
    concepts: ConceptsReducer,
    years: YearsReducer,
    kitBuilder: KitBuilderReducer,
    qu: QuReducer,
    user: UsersReducer,
    tc: TCReducer,
    external: ExternalReducer,
    topicSelector: TSReducer,
    topicTreeSelector: TTreeReducer,
    maintenance: MaintenanceReducer,
    auth: AuthReducer,
    sidebarMenu: SidebarMenuReducer,
    tablet: TabletReducer,
    lup: LUPReducer,
    feathers: FeatherReducers,
});
