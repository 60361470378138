import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import WidgetEditorBody from "../../WidgetLibrary/WidgetEditor/WidgetEditorBody";
import "../../WidgetLibrary/WidgetEditor/WidgetEditor.css";
import Widget from "../../Widget";
import "./LessonEditor.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onChangeWidgetTitle } from "../../WidgetLibrary/WidgetLibraryActions.js";

class LessonEditorItem extends PureComponent {
    static propTypes = {
        id: PropTypes.string,
        templatesData: PropTypes.object,
        value: PropTypes.object,
        selectedLesson: PropTypes.object,
        onUpdateName: PropTypes.func,
        canEdit: PropTypes.bool,
        canReview: PropTypes.bool,
        onDirty: PropTypes.func,
        asReview: PropTypes.bool,
        onChangeWidgetTitle: PropTypes.func,
        checkFirstText: PropTypes.func.isRequired,
        onSetFocus: PropTypes.func,
        isFocused: PropTypes.bool,
    };

    debounceFocusId = null;

    handleUpdateWidgetName = name => {
        const { id, onUpdateName } = this.props;
        onUpdateName({ name, key: id });
    };

    handleChangeWidgetTitle = ({ title, hiddenTitle }) => {
        const { onChangeWidgetTitle, id } = this.props;
        onChangeWidgetTitle({ widgetId: id, title, hiddenTitle });
    };

    /**
     * This handler is all other items.
     */
    handleMouseDown = () => {
        // set focus without scrolling
        this.props.onSetFocus(this.props.id, false);
    };

    /**
     * This handler is for text items using BookEditor. It debounces multiple onFocus calls.
     * we need to use this because BookEditor does not propagate onMouseDown event and
     * handleMouseDown function won't trigger in such case
     */
    handleOnFocus = widgetId => {
        if (this.debounceFocusId !== widgetId) {
            // set focus without scrolling
            this.props.onSetFocus(widgetId, false);
            this.debounceFocusId = widgetId;
        }
    };
    render() {
        const { id, value, templatesData, asReview, checkFirstText, isFocused } = this.props;
        return (
            <div
                className={"spork lesson-editor" + (isFocused ? " focused" : "")}
                onMouseDown={this.handleMouseDown}
            >
                <div className="widgetName">
                    <span>{value.name}</span>
                </div>
                {templatesData && templatesData[value.templateId] && (
                    <Widget
                        template={{ ...templatesData[value.templateId], key: value.templateId }}
                        customTitle={value.custom_title}
                        hiddenTitle={value.hidden_title}
                        onChangeTitle={!asReview ? this.handleChangeWidgetTitle : null}
                    >
                        <WidgetEditorBody
                            widget={{
                                ...value,
                                key: id,
                                template: { ...templatesData[value.templateId], key: value.templateId },
                            }}
                            items={[]}
                            asReview={asReview}
                            {...this.props}
                            checkFirstText={checkFirstText}
                            onFocus={this.handleMouseDown}
                        />
                    </Widget>
                )}
            </div>
        );
    }
}

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            onChangeWidgetTitle,
        },
        dispatch
    );

export default connect(
    null,
    dispatchToProps
)(LessonEditorItem);
