import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Radio, Segment } from "semantic-ui-react";

import DashboardTile from "../../dashboardTile/DashboardTile";

class DesignTile extends React.PureComponent {
  static propTypes = {
      setDesign: PropTypes.func.isRequired,
      design: PropTypes.object
  };

  handleCheckboxChange = (event, data) => {
      const { name, checked, value, type } = data;
      const propertyValue = type === "checkbox" ? checked : value;
      this.props.setDesign({ [name]: propertyValue });
  };

  render() {
      const { design } = this.props;
      return <DashboardTile title="Design Config" {...this.props}>
          <Segment.Group horizontal style={{ lineHeight: "22pt" }}>
              <Segment textAlign="left">
                  <Checkbox toggle name="border" onChange={this.handleCheckboxChange} checked={design.border} label="Border" />
                  <Checkbox toggle name="background" onChange={this.handleCheckboxChange} checked={design.background} label="Background" />
                  <Checkbox toggle name="shadow" onChange={this.handleCheckboxChange} checked={design.shadow} label="Shadow" />
              </Segment>
              <Segment textAlign="left">
                  <Checkbox toggle name="hover" onChange={this.handleCheckboxChange} checked={design.hover} label="Mouseover" />
                  <Checkbox toggle name="rounded" onChange={this.handleCheckboxChange} checked={design.rounded} label="Rounded corners" />
                  <Checkbox toggle name="labelNotification" onChange={this.handleCheckboxChange} checked={design.labelNotification} label="Semantic Label notification" />
                  <Segment basic style={{ margin: 0, padding: 0, paddingLeft: "24pt", lineHeight: "16pt" }}>
                      <Radio name="labelNotificationStyle" value="attached" onChange={this.handleCheckboxChange} checked={design.labelNotificationStyle === "attached"} disabled={!design.labelNotification} label="attached" />
                      <br />
                      <Radio name="labelNotificationStyle" value="floating" onChange={this.handleCheckboxChange} checked={design.labelNotificationStyle === "floating"} disabled={!design.labelNotification} label="floating" />
                      <br />
                      <Radio name="labelNotificationStyle" value="ribbon" onChange={this.handleCheckboxChange} checked={design.labelNotificationStyle === "ribbon"} disabled={!design.labelNotification} label="ribbon" />
                      <br />
                  </Segment>
              </Segment>
          </Segment.Group>
          <Segment textAlign="left">
        Incomplete indication
              <br />
              <Radio name="incomplete" value="none" onChange={this.handleCheckboxChange} checked={design.incomplete === "none"} label="none" />
              <br />
              <Radio name="incomplete" value="color" onChange={this.handleCheckboxChange} checked={design.incomplete === "color"} label="color" />
              <br />
              <Radio name="incomplete" value="bgcolor" onChange={this.handleCheckboxChange} checked={design.incomplete === "bgcolor"} label="background color" />
              <br />
              <Radio name="incomplete" value="shadowcolor" onChange={this.handleCheckboxChange} checked={design.incomplete === "shadowcolor"} label="shadow color" />
              <br />
              <Radio name="incomplete" value="corner" onChange={this.handleCheckboxChange} checked={design.incomplete === "corner"} label="corner" />
              <br />
              <Radio name="incomplete" value="paperFold" onChange={this.handleCheckboxChange} checked={design.incomplete === "paperFold"} label="corner &amp; paper fold" />
          </Segment>
      </DashboardTile>;
  }
}

export default DesignTile;
