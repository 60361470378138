import ReadOnlyTable from "./ReadOnlyTable";
import { ENTITY_TYPE } from "../constants";
import Image from "./Image";

/**
 * Note: the commented-out parameter `getEditorState` is `react-draft-wysiwyg` method, NOT BookEditor.
 * We don't want to used react-draft-wysiwyg Editor::onChange, because it behaves differently when the editor
 * is in readOnly mode, use `BookEditor::setEditorState` instead.
 * Our `getEditorState` is used for consistency.
 */
const customBlockRenderer = (setReadOnly, setFocus, onDirty, getEditorState, setEditorState) => (block, config/*, getEditorState*/) => {
    if (block.getType() === "atomic") {
        const contentState = getEditorState().getCurrentContent();
        const entity = contentState.getEntity(block.getEntityAt(0));
        if (entity && entity.type === ENTITY_TYPE.IMAGE) {
            return {
                component: Image,
                editable: false,
                props: { config, setReadOnly, setFocus, onDirty, getEditorState, setEditorState },
            };
        }
        if (entity && entity.type === ENTITY_TYPE.TABLE_READONLY) {
            return {
                component: ReadOnlyTable,
                editable: false,
                props: { config, setFocus },
            };
        }
    }
    return undefined;
};

export default customBlockRenderer;
