
import firebase from "../../firebase";

const basePath = "modules";
const dataPath = `${basePath}/data`;
const listPath = `${basePath}/list`;

export const paths = {
    basePath,
    dataPath,
    listPath,
    toDataEntry: (moduleId) => `${dataPath}/${moduleId}`,
    toListEntry: (moduleId) => `${listPath}/${moduleId}`,
};

/**
 * Get both database references to modules
 *
 * @param {string} moduleId module identifier
 * @returns {object} Firebase references, {dataRef}
 */
export const getReferences = (moduleId) => {
    const listRef = firebase.getFirebaseData(paths.toListEntry(moduleId));
    const dataRef = firebase.getFirebaseData(paths.toDataEntry(moduleId));
    return { dataRef, listRef };
};

/**
 * Create a new module record.
 *
 * @param {object} config attributes of the new module
 * @return {string} key/identifier of created module
 */
export const createModule = async (config) => {
    const listRef = firebase.getFirebaseData(listPath);
    const { key } = await listRef.push(config);
    return key;
};


/**
 * Update module record.
 *
 * @param {object} config attributes of the new module
 * @return {string} key/identifier of created module
 */
export const updateModule = async (key, config) => {
    const listRef = firebase.getFirebaseData(paths.toListEntry(key));
    await listRef.update(config);
    return key;
};

/**
 * Remove the module record.
 * 
 * @param {string} moduleId module identifier
 */
export const removeModule = async (moduleId) => {
    if (!moduleId) {
        return false;
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`list/${moduleId}`] : null,
        [`data/${moduleId}`] : null,
    };
    await baseRef.update(changes);
    return true;
};
