import { position } from "caret-pos";

const MATH_JAX_RENDERER_ID = "MATH_JAX_RENDERER_ID";
const SVG_RENDERER_ID = "SVG_RENDERER_ID";

const BLOCK_LEVEL_ELEMENTS = [
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Block-level_elements
    "ADDRESS", "ARTICLE", "ASIDE", "BLOCKQUOTE", "DETAILS", "DIALOG", "DD",
    "DIV", "DL", "DT", "FIELDSET",  "FIGCAPTION", "FIGURE", "FOOTER", "FORM",
    "H1", "H2", "H3", "H4", "H5", "H6", "HEADER", "HGROUP", "HR", "LI",
    "MAIN", "NAV", "OL", "P", "PRE", "SECTION", "TABLE", "UL",
];

const VOID_ELEMENTS = [
    // https://www.w3.org/TR/2011/WD-html-markup-20110113/syntax.html#void-elements
    "AREA", "BASE", "BR", "COL", "COMMAND", "EMBED", "HR", "IMG", "INPUT", "KEYGEN", "LINK", "META", "PARAM", "SOURCE", "TRACK", "WBR",
];

const ORDINARY_ELEMENTS = [
    Node.ELEMENT_NODE,
    Node.TEXT_NODE,
];

const OUT_OF_WINDOW = {
    position: "absolute",
    left: "-1000px",
    top: "-1000px",
    width: "100px",
    height: "100px",
};

export const getMathJaxRenderer = () => {
    let mathJaxRenderer = document.getElementById(MATH_JAX_RENDERER_ID);

    if (!mathJaxRenderer) {
        mathJaxRenderer = document.createElement("iframe");
        mathJaxRenderer.id = MATH_JAX_RENDERER_ID;
        mathJaxRenderer.src = "formulaRenderer.html";
        mathJaxRenderer.onload = () => {mathJaxRenderer.isLoaded = true;};
        Object.assign(mathJaxRenderer.style, OUT_OF_WINDOW);
        document.body.appendChild(mathJaxRenderer);
    }

    return mathJaxRenderer;
};

const getSvgRenderer = () => {
    let svgRenderer = document.getElementById(SVG_RENDERER_ID);

    if (!svgRenderer) {
        svgRenderer = document.createElement("div");
        svgRenderer.id = SVG_RENDERER_ID;
        svgRenderer.contentEditable = true;
        Object.assign(svgRenderer.style, {
            ...OUT_OF_WINDOW,
            overflow: "visible",
            fontSize: "16px",  // default font-size in Wiris editor
            fontFamily: "Stix",
        });
        document.body.appendChild(svgRenderer);
    }

    return svgRenderer;
};


export const waitForSvgRendererReady = () => {
    const DELAY = 100;
    const TIMEOUT = 30000;
    const svgRenderer = getSvgRenderer();
    const { fontSize, fontFamily } = svgRenderer.style;

    if (!document.fonts || !document.fonts.check) {
        // eslint-disable-next-line no-console
        console.error("[SvgRenderer] Development warning: document.fonts.check() is not supported.",
            "The initial rendering of Math Formulas can have wrong dimension.");
        Promise.resolve();
        return;
    }

    if (document.fonts.check(`${fontSize} ${fontFamily}`)) {
        Promise.resolve();
        return;
    }

    svgRenderer.innerHTML = `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
        style="font-family: ${fontFamily}; font-size: ${fontSize}"><text x="8" y="8">x</text></svg>`;

    return new Promise((resolve) => {
        const start = Date.now();
        const checkFont = () => {
            if (document.fonts.check(`${fontSize} ${fontFamily}`)) {
                resolve();
                return;
            }
            if (Date.now() - start > TIMEOUT) {
                // eslint-disable-next-line no-console
                console.error(`[SvgRenderer] Error: font "${fontFamily}" is not loaded (timeout ${TIMEOUT})`);
            } else {
                setTimeout(checkFont, DELAY);
            }
        };

        setTimeout(checkFont, DELAY);
    });
};

export const BLOCK_POSITION = {
    STANDALONE: "STANDALONE",
    AT_BEGIN: "AT_BEGIN",
    AT_END: "AT_END",
    SURROUNDED: "SURROUNDED",
};

export const findParentByTagName = (node, tagName, rootNode) => {

    if (node.nodeType === Node.TEXT_NODE) {
        node = node.parentNode;
    }

    while (node && node.tagName !== tagName) {
        node = node.parentNode;
    }

    if (node && rootNode) {
        const closestRoot = findParentByTagName(node, rootNode.tagName);

        if (closestRoot === rootNode) {
            return node;
        }
    }

    return node;
};

export const getCellCoordinates = (cell) => {
    const row = cell.parentNode;
    return { rowIndex: row.rowIndex, colIndex: cell.cellIndex };
};

export const isBlockLevelNode = (node) => !!(node.tagName && BLOCK_LEVEL_ELEMENTS.includes(node.tagName.toUpperCase()));

export const isVoidElement = (node) => !!(node.tagName && VOID_ELEMENTS.includes(node.tagName.toUpperCase()));

export const isOrdinaryElement = (node) => ORDINARY_ELEMENTS.includes(node.nodeType);

export const getTextContent = (node) => {
    const text = node.nodeType === Node.TEXT_NODE ? node.textContent : node.innerText;
    return text ? text.trim() : undefined;
    // return text ? text : undefined;
};

export const getSiblingsTextContent = (node, siblingType, stopWhenAny) => {
    let text = null;
    // let text = "";
    node = node[siblingType];

    while (node) {
        let nodeContent = getTextContent(node);
        // this is to avoid conversion of null + string to "null"
        if (nodeContent) {
            text += getTextContent(node);
        }

        if (stopWhenAny && text) {
            return text;
        }

        node = node[siblingType];
    }

    return text;
};

export const getNodePositionInBlock = (node) => {
    let textBefore;
    let textAfter;

    while (node && node.tagName !== "BODY" && isOrdinaryElement(node) && !isBlockLevelNode(node)) {
        textBefore = textBefore || getSiblingsTextContent(node, "previousSibling", true);
        textAfter = textAfter || getSiblingsTextContent(node, "nextSibling", true);

        if (textBefore && textAfter) {
            return BLOCK_POSITION.SURROUNDED;
        }

        node = node.parentNode;
    }

    if (textBefore || textAfter) {
        return textBefore ? BLOCK_POSITION.AT_END : BLOCK_POSITION.AT_BEGIN;
    }
    return BLOCK_POSITION.STANDALONE;
};

export const getContextPropsForPopup = (refElement) => {
    const arrowOffset = 24;
    let pos = "bottom center";
    let vOffset = 0;
    let hOffset = 0;

    if (refElement.classList.contains("public-DraftEditor-content")) {
        // Popup will be positioned according to the current caret position (instead of toolbar button).
        const editorWidth = refElement.clientWidth;
        const editorHeight = refElement.clientHeight;
        const caretPos = position(refElement);
        let left = caretPos.left;

        if (left < editorWidth / 3) {
            pos = "bottom left";
            left = arrowOffset - left;
        }
        else if (left < 2 * editorWidth / 3) {
            pos = "bottom center";
            left = editorWidth / 2 - left;
        }
        else {
            pos = "bottom right";
            left = left - editorWidth + arrowOffset;
        }
        hOffset = left;
        vOffset = caretPos.top + caretPos.height - editorHeight;
    }

    return {
        node: refElement,
        pos,
        vOffset,
        hOffset,
    };
};

/**
 * Get the closest ancestor that matches to a given predicate.
 *
 * @param {HTMLElement} element to start search for ancestor (this one is tested too)
 * @param {(el: HTMLElement) => boolean} matches predicate to test the ancestor match
 * @returns {HTMLElement|null} matching ancestor element, null if not found
 */
export const getClosestMatching = (element, matches) => {
    let el = element;
    do {
        if (matches(el)) {
            return el;
        }
        el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
};

const isPositionAbsolute = (element) => {
    const pos = element.style && element.style.position;
    if (pos) {
        return pos === "absolute";
    }
    const css = window.getComputedStyle(element);
    return css.position === "absolute";
};

/**
 * Get the closest ancestor that is positioned absolutely.
 *
 * @param {HTMLElement} element to start search for ancestor (this one is tested too)
 * @returns {HTMLElement|null} absolutely positioned element, null if not found any
 */
export const getAbsoluteAncestor = (element) => getClosestMatching(element, isPositionAbsolute);

/**
 * Adds/removes some attributes for proper rendering of SVG and obtains its size.
 *
 * @param {string} svgHtml SVG html markup.
 * @returns {Object} width, height, and normalized SVG markup.
 */
export const normalizeSvg = (svgHtml) => {
    const svgRenderer = getSvgRenderer();
    svgRenderer.innerHTML = svgHtml;
    const svgEl = svgRenderer.firstChild;
    /**
     * If `xmlns` attribute is missing, svg is not rendered.
     * https://stackoverflow.com/questions/24337271/base64-svg-html-image-is-not-displayed
     */
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    /**
     * If `data-mathml` attribute is present, svg is not rendered as a background image.
     */
    svgEl.removeAttribute("data-mathml");

    /**
     * This probably forces the browser to really render the svg.
     * Without it, the width / height gathered from `baseVal` are about 14% higher.
     */
    svgEl.getBoundingClientRect();

    const width = svgEl.width.baseVal.value;
    const height = svgEl.height.baseVal.value;

    return {
        svgHtml: svgEl.outerHTML,
        width: width + "px",
        height: height + "px",
    };
};

/**
 * When a modal is opened, its Dimmer is the last one dimmer in document.body by default.
 * Maybe, it could be broken by manually created Dimmer, but then it could be excluded from dimmers list
 * by additional className, e.g.: "noModalDimmer".
 *
 * @param {Integer} indexFromLast When not the last, but n-th before the last dimmer is required.
 * @returns {DOMElement} Semantic-UI Dimmer element or undefined when not found.
 */
export const getDimmer = (indexFromLast = 0) => {
    const dimmers = document.querySelectorAll("div.ui.dimmer.modals.page");
    const backwardIndex = dimmers.length - 1 - indexFromLast;
    return dimmers[backwardIndex];  // returns undefined for backwardIndex < 0
};

export const observeBodyForClassName = (className, callbackOn, callbackOff) => {
    const body = document.body;
    const config = { attributes: true, childList: false, subtree: false };
    let formerlyHadClassName = body.classList.contains(className);

    const domChanged = (mutationsList /*, observer*/) => {
        for (const mutation of mutationsList) {

            if (mutation.attributeName === "class") {
                const hasClassName = body.classList.contains(className);
                hasClassName && !formerlyHadClassName && callbackOn();
                !hasClassName && formerlyHadClassName && callbackOff();
                formerlyHadClassName = hasClassName;
            }
        }
    };

    const observer = new MutationObserver(domChanged);
    observer.observe(body, config);
    return observer;
};

/**
 * Helper for highlightSelection to avoid overlapped elements - getClientRects() can return
 * duplicated/overlapped rectangles because of CSS styles.
 * Then the overlapped regions have darker background because of opacity.
 *
 * It is not perfect, some rects can still overlap others because of e.g. inline images.
 *
 * @param {Range} range Currently selected range.
 * @returns {Array} Array of objects { top, left, bottom, right }.
 */
const getCoveringRects = (range) => {
    const rectangles = [ ...range.getClientRects() ];
    rectangles.sort((a, b) => (a.top < b.top) ? -1 : (a.top > b.top) ? 1 : 0);

    const linesRect = rectangles.reduce((acc, rect) => {
        const currentLineRect = acc[acc.length - 1];
        const { top, left, bottom, right } = rect;

        if (!currentLineRect || (top > currentLineRect.top && bottom > currentLineRect.bottom)) {
            acc.push({ top, left, bottom, right });
            return acc;
        }

        currentLineRect.left = Math.min(currentLineRect.left, rect.left);
        currentLineRect.right = Math.max(currentLineRect.right, rect.right);
        return acc;
    }, []);

    return linesRect;
};

/**
 * Creates absolutely positioned elements overlapped the current browser's selection as children of the `parentNode`.
 * Such "selection" persists even if another selection is created in browser.
 * Their positions respect origin of the `parentNode`.
 *
 * @param {Element} parentNode Positioned (absolute/relative) element.
 * @returns {Array} Created elements.
 */
export const highlightSelection = (parentNode) => {
    const selection = window.getSelection();
    const range = selection && !selection.isCollapsed && selection.getRangeAt(0);

    if (!range) {
        return null;
    }

    const { top, left } = parentNode.getBoundingClientRect();
    const rects = getCoveringRects(range);

    return rects.map((rect) => {
        const dummySelection = document.createElement("div");
        dummySelection.className = "dummySelection";
        Object.assign(dummySelection.style, {
            left: (rect.left - left) + "px",
            top: (rect.top - top) + "px",
            width: (rect.right - rect.left) + "px",
            height: (rect.bottom - rect.top) + "px",
        });
        return parentNode.appendChild(dummySelection);
    });
};
