const TYPES = {
    COURSE_VARIANTS_REDIRECT_TO_KIT: "CourseVariants/REDIRECT_TO_KIT",
    CV_LOAD_LIST: "CourseVariants/LOAD",
    CV_SET_LIST: "CourseVariants/SET",
    ALL_CV_LOAD_LIST: "CourseVariants/ALL_LOAD",
    ALL_CV_SET_LIST: "CourseVariants/ALL_SET",
    CV_LOAD: "CourseVariants/CV_LOAD",
    CV_SET: "CourseVariants/CV_SET",
};

const onRedirectToKit = payload => ({
    type: TYPES.COURSE_VARIANTS_REDIRECT_TO_KIT,
    payload,
});

const onCourseVariantLoad = payload => ({
    type: TYPES.CV_LOAD_LIST,
    payload,
});

const onCourseVariantSet = payload => ({
    type: TYPES.CV_SET_LIST,
    payload,
});

const onAllCourseVariantLoad = payload => ({
    type: TYPES.ALL_CV_LOAD_LIST,
    payload,
});

const onAllCourseVariantSet = payload => ({
    type: TYPES.ALL_CV_SET_LIST,
    payload,
});

const onLoadCourseVariant = payload => ({
    type: TYPES.CV_LOAD,
    payload
});

const onSetCourseVariant = payload => ({
    type: TYPES.CV_SET,
    payload
});

export {
    TYPES,
    onRedirectToKit,
    onCourseVariantLoad,
    onCourseVariantSet,
    onAllCourseVariantLoad,
    onAllCourseVariantSet,
    onLoadCourseVariant,
    onSetCourseVariant,
};
