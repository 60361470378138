const TYPES = {
    SIDEBAR_MENU_CLICK: "SIDEBAR_MENU_CLICK",
};

const onMenuClick = (payload) => {
    return {
        type: TYPES.SIDEBAR_MENU_CLICK,
        payload,
    };
};

export {
    TYPES,
    onMenuClick,
};
