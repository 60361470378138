import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Label, Form, Segment, Button, Divider, Grid, Dimmer, Loader } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import { replaceRelLinks } from "../../../utils/conversionUtils";

export default class SiPiTestCreator extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        isProblemSetLoading: PropTypes.bool,
        problemSetsOptions: PropTypes.array,
        onSelectProblemSet: PropTypes.func,
        selectedProblemSetId : PropTypes.string,
        isGeneratingHtml: PropTypes.bool,
        problemSetsHtml: PropTypes.object,
        isCreatingTests: PropTypes.bool,
        onCreateTests: PropTypes.func,
        onTestCreated: PropTypes.func,
    }

    componentDidMount() {
        this.props.onSelectProblemSet({ id: null });
    }

    handleSelectProblemSet = (e, { value }) => {
        const { onSelectProblemSet } = this.props;
        onSelectProblemSet({ id: value });
    }

    handleShuffleAnswers = () => {
        const { onSelectProblemSet, selectedProblemSetId } = this.props;
        onSelectProblemSet({ id: selectedProblemSetId, refresh: true });
    }

    handleTestCreated = (data) => {
        const { onTestCreated, onClose } = this.props;
        onTestCreated(data);
        onClose();
    }

    handleCreateTests = () => {
        const { onCreateTests, selectedProblemSetId } = this.props;
        onCreateTests({ id: selectedProblemSetId, callback: this.handleTestCreated });
    }

    render() {
        const { onClose, isProblemSetLoading, problemSetsOptions, selectedProblemSetId, isGeneratingHtml, problemSetsHtml, isCreatingTests } = this.props;
        return (
            <Modal
                open={true}
                size={selectedProblemSetId ? "large" : "small"}
            >
                <Modal.Header>Create iPlanner instrument tests</Modal.Header>
                <Modal.Content>
                    <Dimmer.Dimmable>
                        <Dimmer active={isCreatingTests} inverted>
                            <Loader inverted>Creating iPlanner instrument tests...</Loader>
                        </Dimmer>
                        <Grid columns={selectedProblemSetId ? 2 : 1}>
                            <Grid.Column width={selectedProblemSetId ? 6 : 16}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: "1em" }}>
                                    <Label style={{ float: "left", margin: "0.3em" }} size="big" circular>1</Label>
                                    <p>Select a problem set from which you want to create iPlanner instrument tests.</p>
                                </div>
                                <Form>
                                    <Form.Select
                                        search
                                        required
                                        loading={isProblemSetLoading}
                                        label="Problem Set"
                                        options={problemSetsOptions || []}
                                        value={selectedProblemSetId}
                                        onChange={this.handleSelectProblemSet}
                                        placeholder="Select problem set"
                                    />
                                </Form>
                            </Grid.Column>
                            {selectedProblemSetId && (
                                <Grid.Column width="10">
                                    <div style={{ display: "flex", alignItems: "center", paddingTop: "1em"}}>
                                        <Label style={{ float: "left", margin: "0.3em" }} size="big" circular>2</Label>
                                        <p>Shuffle answers in selected problem set.</p>
                                    </div>
                                    <Button compact style={{ marginTop: "-2.4em"}} floated="right" icon="refresh" onClick={this.handleShuffleAnswers} content="Shuffle answers" />
                                    <Divider fitted hidden clearing />
                                    <Segment
                                        style={{maxHeight: "60vh", overflowY: "auto"}}
                                        loading={isGeneratingHtml}
                                        className="spork problemSet preview"
                                        {...problemSetsHtml[selectedProblemSetId] && { dangerouslySetInnerHTML: {__html: replaceRelLinks(problemSetsHtml[selectedProblemSetId].html)}} || { placeholder: true }}
                                    />
                                </Grid.Column>
                            )}
                        </Grid>
                    </Dimmer.Dimmable>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onSave={this.handleCreateTests}
                        submitText="Create"
                        onCancel={onClose}
                        saveDisabled={!selectedProblemSetId || isGeneratingHtml || isCreatingTests}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
