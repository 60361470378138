import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table, Icon } from "semantic-ui-react";
import "./SiPiContent.css";
import ResourceLink from "./ResourceLink";

export default class SiPiListPreview extends PureComponent {
    static propTypes = {
        lessonUnit: PropTypes.object,
        iPlannerSiPiByScope: PropTypes.object,
        viewScope: PropTypes.string,
    };

    render() {
        const { iPlannerSiPiByScope, lessonUnit, viewScope } = this.props;
        const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
        const lessonUnitSiPi = iPlannerSiPiByScope && iPlannerSiPiByScope[`lu_${lessonUnitId}`];
        const lessonSiPi = lessonUnitSiPi && lessonUnitSiPi[viewScope] || [];
        return (
            <div style={{paddingTop: "1em"}}>
                <React.Fragment>
                    {lessonSiPi.length ? 
                        <React.Fragment>
                            
                            <Table fixed style={{marginTop: "0"}}>
                                <Table.Header><Table.Row><Table.HeaderCell colSpan="3">
                                List of instruments
                                </Table.HeaderCell></Table.Row></Table.Header>
                                <Table.Body>
                                    {lessonSiPi.map(({key, value}) => {
                                        if (value) {
                                            let instructions;
                                            if (value.teacher && "teacher" === viewScope) {
                                                instructions = value.teacher;
                                            } else if (value.student && "student_view" === viewScope) {
                                                instructions = value.student;
                                            }
                                            return (
                                                <Table.Row key={key}>
                                                    <Table.Cell width="4" title={value.name}>
                                                        {value.name}
                                                        {value.student_view && "student_view" === viewScope && value.on_approval && (
                                                            <Icon style={{ marginLeft: "1em" }} name="history" flipped="horizontally" title="Display only after the teacher approval." />
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell width="4">
                                                        {value.resource_id ? <ResourceLink sipi={value} disableAutoLoad renderAsLink /> :
                                                            <span style={{fontStyle: "italic", marginRight: "0.3em", color: "grey"}}>Text only</span>}
                                                    </Table.Cell>
                                                    <Table.Cell width="8" title={instructions}>
                                                        {instructions}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        }
                                    })}
                                </Table.Body>
                            </Table>
                        </React.Fragment> 
                        : ("teacher" === viewScope ? <span style={{color: "grey", fontStyle: "italic"}}>Lesson has no instruments.</span>
                            : <span style={{color: "grey", fontStyle: "italic"}}>Lesson has no instruments visible in this view.</span>)
                    }
                </React.Fragment>
            </div>
        );
    }
}

