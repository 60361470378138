import firebase from "../../../firebase";
import * as lessonUnitManager from "./lessonUnitManager";
import * as lessonManager from "./lessonManager";
import { toastError, FUNCTION_ERROR } from "../../../component/toast";
import { REVIEW_STATS_COLLECTION } from "../../../component/seamlessEditor/bookEditor/constants";

/**
 * Id as used by a database to identify entities. For Firebase it is a string.
 * @typedef {string} DbId
 */

const dataPath = "outlines_data";

export const createOutlineRecord = async (kit_id) => {

    if (kit_id) {
        const dataRef = firebase.getFirebaseData(dataPath);
        const { key } = await dataRef.push({ kit_id });

        if (!key) {
            throw new Error("Outline could not be created.");
        }
        return key;
    } else {
        throw new Error("Outline could not be created, no kit provided.");
    }
};

export const removeOutline = async (id) => {
    if (id) {
        const dataRef = firebase.getFirebaseData(dataPath);
        await dataRef.update({[id] : null});
    }
    return;
};

export const createOutline = async (kitId) => {

    let outlineId;
    try {
        outlineId = await createOutlineRecord(kitId);
        const lessonUnitId = await lessonUnitManager.createLessonUnit(outlineId, "New lesson unit");
        await lessonManager.createLesson(outlineId, lessonUnitId, "New lesson");
        return outlineId;
    } catch (error) {
        await outlineId && await removeOutline(outlineId);

        throw error;
    }
};

export const getKitId = async (outlineId) => {
    if (!outlineId) {
        return null;
    }
    const outlineKitIdRef = firebase.getFirebaseData(`${dataPath}/${outlineId}/kit_id`);
    return await outlineKitIdRef.once("value").then((sc) => sc.val());
};

export const getOutline = async (outlineId) => {
    if (!outlineId) {
        return null;
    }
    const outlineKitIdRef = firebase.getFirebaseData(`${dataPath}/${outlineId}`);
    return await outlineKitIdRef.once("value").then((sc) => sc.val());
};

export const outlineLessonPublish = function* (outlineId, lessonId, mode){
    try {
        const publishLesson = firebase.getFirebaseFunctions().httpsCallable("publishLesson");
        const response = yield publishLesson({ outlineId, lessonId, mode });
        return response;
    } catch (ex) {
        const message = ex && ex.message || "";
        toastError({ code: FUNCTION_ERROR , header: "Unable to publish lesson from outline", message });
    }
};

/**
 *
 * @param {string} reviewStatsCollection widgets / problem_sets / homework
 * @param {string} outlineId
 * @param {string} lessonId
 * @param {string} entityId widgetId or problemSetId according to `reviewStatsCollection`
 * @param {string} itemId For widgets only. For problem_sets and homework it is omitted, because problem_sets_data
 *                        contains already aggregated reviewsCount.
 * @param {object} reviewStats For widgets, reviewsCount for problem_sets and homework.
 */
export const outlineLessonUpdateReviewStats = async function(reviewStatsCollection, outlineId, lessonId, entityId, itemId, reviewStats){

    if (reviewStatsCollection === REVIEW_STATS_COLLECTION.WIDGETS && !itemId) {
        // no itemId = removed widget from lesson
        await firebase.getFirebaseData(`outlines_data/${outlineId}/lessons/${lessonId}/reviewStats/${reviewStatsCollection}/${entityId}`).remove();
        return;
    }

    if (reviewStatsCollection === REVIEW_STATS_COLLECTION.PROBLEM_SETS || reviewStatsCollection === REVIEW_STATS_COLLECTION.HOMEWORK) {
        reviewStats = itemId;  // arguments shift
    }

    if (reviewStats && Object.keys(reviewStats).length > 0){
        const lessonRef = firebase.getFirebaseData(`outlines_data/${outlineId}/lessons/${lessonId}/reviewStats/${reviewStatsCollection}/${entityId}`);
        if (reviewStatsCollection === REVIEW_STATS_COLLECTION.WIDGETS) {
            await lessonRef.update({ [itemId]: reviewStats });
        }
        else {
            await lessonRef.update({ ...reviewStats });
        }
    } else {
        if (reviewStatsCollection === REVIEW_STATS_COLLECTION.WIDGETS) {
            await firebase.getFirebaseData(`outlines_data/${outlineId}/lessons/${lessonId}/reviewStats/${reviewStatsCollection}/${entityId}/${itemId}`).remove();
        }
        else {
            await firebase.getFirebaseData(`outlines_data/${outlineId}/lessons/${lessonId}/reviewStats/${reviewStatsCollection}/${entityId}`).remove();
        }
    }
};
