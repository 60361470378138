import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";
import classNames from "classnames/bind";
import styles from "./Image.module.scss";
import { deleteBlockEntity } from "../../utils/utils";
import { Icon, Popup } from "semantic-ui-react";
import ImageResize from "./ImageResize";
import { swallowEvent } from "../../utils/utils";
import firebase from "../../../../../firebase";
import { getEditorComponentKey } from "../../../../../KitBuilder/WidgetLibrary/WidgetEditor";
import { ENTITY_TYPE } from "../../constants";
import { convertImageType, getImageName, getRenderedImageSize } from "../../imageEntity/imageUtils";

let cx = classNames.bind(styles);
export default class Image extends PureComponent {
    static propTypes = {
        block: PropTypes.object,
        contentState: PropTypes.object,
        blockProps: PropTypes.shape({
            config: PropTypes.object.isRequired,
            setReadOnly: PropTypes.func.isRequired,
            setFocus: PropTypes.func.isRequired,
            onDirty: PropTypes.func.isRequired,
            getEditorState: PropTypes.func.isRequired,
            setEditorState: PropTypes.func.isRequired,
        }),
    };

    state = {
        isOpen: false,
        componentKey: getEditorComponentKey(),
    };

    handleAlignmentLeft = () => {
        this.updateEntityData({ alignment: "left" });
    };

    handleAlignmentRight = () => {
        this.updateEntityData({ alignment: "right" });
    };

    handleAlignmentCenter = () => {
        this.updateEntityData({ alignment: "none" });
    };

    updateEntityData = newData => {
        const {
            block,
            contentState,
            blockProps: { getEditorState, setEditorState, onDirty },
        } = this.props;
        const entityKey = block.getEntityAt(0);
        contentState.mergeEntityData(entityKey, { ...newData });
        setEditorState(EditorState.push(getEditorState(), contentState, "change-block-data"));
        onDirty();
        this.forceUpdate();
    };

    handleDeleteEntity = () => {
        const {
            block,
            contentState,
            blockProps: { getEditorState, setEditorState },
        } = this.props;
        setEditorState(deleteBlockEntity(block, contentState, getEditorState()));
    };

    handleSave = ({ width, height, type }) => {
        const { block, contentState, blockProps: { getEditorState, setEditorState, onDirty } } = this.props;

        if (type) {
            const entityKey = block.getEntityAt(0);

            if (type === ENTITY_TYPE.INLINE_IMAGE) {
                // Fallback for images uploaded before storing of the original size was implemented (#21073)
                let { src } = contentState.getEntity(entityKey).getData();

                if ( !src.originalWidth || !src.originalHeight) {
                    const { width: originalWidth, height: originalHeight } = getRenderedImageSize(firebase.getFileURL(src.storagePath));
                    src = { ...src, originalWidth, originalHeight };
                    contentState.mergeEntityData(entityKey, { src });
                }
            }

            contentState.mergeEntityData(entityKey, { width, height });
            const newEditorState = convertImageType(type, { width, height }, block.getKey(), entityKey, getEditorState());
            setEditorState(newEditorState);
            onDirty();
        }
        else {
            this.updateEntityData({ width, height });
        }

        this.handleClose();
    };

    renderAlignmentOptions() {
        return (
            <ul className={cx({ image_options: true, topItems: true })}>
                <li className={styles.left} onClick={this.handleAlignmentLeft}>
                    <Icon name="align left" title="Align the image to the left" />
                </li>
                <li className={styles.center} onClick={this.handleAlignmentCenter}>
                    <Icon name="align center" title="Align the image to the center" />
                </li>
                <li className={styles.right} onClick={this.handleAlignmentRight}>
                    <Icon name="align right" title="Align the image to the right" />
                </li>
            </ul>
        );
    }

    handleOpen = () => {
        const {
            blockProps: { setReadOnly },
        } = this.props;
        this.setState({ isOpen: true });
        setReadOnly(true);
    };

    handleClose = () => {
        const {
            blockProps: { setReadOnly },
        } = this.props;
        this.setState({ isOpen: false });
        setReadOnly(false);
    };

    handleMouseDown = e => {
        if (this.props.blockProps.config.isReadOnly()) {
            return;  // propagate event to outer component (e.g. cell selection in TABLE multi cell mode)
        }
        const {
            blockProps: { setFocus },
        } = this.props;
        swallowEvent(e);

        if (e.detail === 1) {
            setFocus(e);
        }
    };

    handleReloadImage = () => {
        this.setState({ componentKey: getEditorComponentKey()});
    }

    renderActionOptions(width, height) {
        return (
            <ul className={cx({ image_options: true, bottomItems: true })}>
                <li className={styles.left}>
                    <Popup
                        trigger={
                            <Icon
                                name="expand arrows alternate"
                                title="Change image size"
                                onMouseDown={this.handleMouseEvent}
                            />
                        }
                        content={<ImageResize type={ENTITY_TYPE.IMAGE} width={width} height={height} onSave={this.handleSave} onCancel={this.handleClose} />}
                        on="click"
                        open={this.state.isOpen}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                        position="top left"
                    />
                </li>
                <li className={styles.center}>
                    <Icon name="refresh" title="Reload the image" onClick={this.handleReloadImage} />
                </li>
                <li className={styles.right} onClick={this.handleDeleteEntity}>
                    <Icon name="trash alternate outline" title="Remove the image" />
                </li>
            </ul>
        );
    }

    isRelativeLink(link) {
        return link instanceof Object;
    }

    render() {
        const { block, contentState, blockProps } = this.props;
        const { isReadOnly, isImageAlignmentEnabled } = blockProps.config;
        const entity = contentState.getEntity(block.getEntityAt(0));
        const { src, alignment, height, width, alt, imported } = entity.getData();
        const { isOpen, componentKey } = this.state;

        return (
            <div
                className={cx({
                    image: true,
                    left: alignment === "left",
                    right: alignment === "right",
                    center: !alignment || alignment === "none",
                })}
                onMouseDown={this.handleMouseDown}
            >
                <div className={styles.imagewrapper} style={{ flexBasis: width }}>
                    {this.isRelativeLink(src) && <div className={`limitedTextWidth ${styles.fileInfo}`} title={getImageName(src.storagePath)}>
                        {getImageName(src.storagePath)}
                    </div>}
                    <img
                        src={this.isRelativeLink(src) ? firebase.getFileURL(src.storagePath) : src}
                        key={componentKey}
                        alt={alt || imported && "Try reload the image manually."}

                        style={{
                            height,
                            width: width === "100%" || width === "auto" ? "auto" : "100%",
                            maxWidth: "100%",
                        }}
                    />
                    {!isReadOnly() && isImageAlignmentEnabled() && this.renderAlignmentOptions()}
                    {!this.isRelativeLink(src) && <div className={styles.linkInfo}>External source image.</div>}
                    {(!isReadOnly() || isOpen) && this.renderActionOptions(width, height)}
                </div>
            </div>
        );
    }
}
