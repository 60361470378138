import React from "react";
import PropTypes from "prop-types";
import WidgetItem from "../../Widget/WidgetItem";
import { Segment } from "semantic-ui-react";
import BookEditor from "../../../component/seamlessEditor/bookEditor";
import ItemLabel from "../../component/ItemLabel";
import { withAuth } from "../../../component/withAuth";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { isItemSaving } from "../WidgetLibrarySelectors";
import { onEditItem, onChangeItemTitle } from "../WidgetLibraryActions";

const WidgetTextFeatures = [
    "BOLD", "ITALIC", "UNDERLINE", "NORMAL",
    "H1", "H2", "H3", "H4", "H5", "H6", "QUOTE", "CODE", "UL", "OL",
    "MATH", "MATHTEXT", "SNIPPET", "TABLE",
    "TEXTALIGN", "FONT", "FONTSIZE",
    "IMG", "GLOSSARY", "COLOR", "LESSON_LINK",
    "REVIEW", "HELP",
];

class WidgetTextItemEditor extends React.PureComponent {
    static propTypes = {
        template: PropTypes.object,
        itemId: PropTypes.string,
        widgetId: PropTypes.string.isRequired,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        toolbarRef: PropTypes.object,
        isAuthorAtLeast: PropTypes.bool,
        isReviewerAtLeast: PropTypes.bool,
        reviewMode: PropTypes.bool,
        canEdit: PropTypes.bool,
        canReview: PropTypes.bool,
        registerGetContentFn: PropTypes.func.isRequired,
        isItemSaving: PropTypes.bool.isRequired,
        onDirty: PropTypes.func.isRequired,
        onChangeItemTitle: PropTypes.func.isRequired,
        focusOnMount: PropTypes.bool,
        onFocus: PropTypes.func,
    };

    state = {};

    registerGetContentFn = fn => {
        const { widgetId, itemId, registerGetContentFn } = this.props;
        registerGetContentFn(widgetId, itemId, fn);
    };

    handleDirty = () => {
        const { widgetId, itemId, onDirty } = this.props;
        onDirty(widgetId, itemId);
    };

    componentWillUnmount = () => {
        // unregister callback
        this.registerGetContentFn(null);
    }

    handleChangeTitle = ({ title, hiddenTitle }) => {
        const { widgetId, itemId, onChangeItemTitle } = this.props;
        onChangeItemTitle({ widgetId, itemId, title, hiddenTitle });
    }

    handleOnFocus = () => {
        const { onFocus, widgetId} = this.props;
        onFocus && onFocus(widgetId);
    }

    render() {
        const {
            template,
            content,
            toolbarRef,
            reviewMode,
            isAuthorAtLeast,
            isReviewerAtLeast,
            canEdit,
            canReview,
            isItemSaving,
            focusOnMount = false,
            widgetId,
        } = this.props;

        const canOpenInEdit = isAuthorAtLeast || isReviewerAtLeast || canEdit || canReview;

        return (
            <Segment style={{ backgroundColor: "inherit" }} loading={isItemSaving}>
                <ItemLabel item={template} />
                {canOpenInEdit && toolbarRef ? (
                    <React.Fragment>
                        <WidgetItem template={template} content={content} onChangeTitle={!reviewMode ? this.handleChangeTitle : null}>
                            <BookEditor
                                reviewMode={reviewMode}
                                features={WidgetTextFeatures}
                                onDirty={this.handleDirty}
                                placeholder="Fill default content"
                                initialContentState={content && content.raw}
                                initialHtml={content && content.html}
                                sharedToolbar={toolbarRef}
                                registerGetContentFn={this.registerGetContentFn}
                                focusOnMount={focusOnMount}
                                onFocus={this.handleOnFocus}
                                rootEntityKey={widgetId}
                            />
                        </WidgetItem>
                    </React.Fragment>
                ) : (
                    <WidgetItem template={template} content={content} />
                )}
            </Segment>
        );
    }
}

const dispatchToProps = dispatch => bindActionCreators({
    onEditItem,
    onChangeItemTitle,
}, dispatch);


export default compose(
    connect(
        (state, props) => ({
            isItemSaving: isItemSaving(state, props),
        }),
        dispatchToProps
    ),
    withAuth
)(WidgetTextItemEditor);
