import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./styles.scss";
import { Loader, Dimmer, Message, Form } from "semantic-ui-react";
import { humanFileSize } from "../../../../../utils/conversionUtils";
import { SaveCancelButtons } from "bmd-react";
import { insertInlineImage } from "../../imageEntity/imageUtils";

class LayoutComponent extends Component {
    static propTypes = {
        expanded: PropTypes.bool,
        onExpandEvent: PropTypes.func,
        doCollapse: PropTypes.func,
        onChange: PropTypes.func,
        config: PropTypes.object,
        translations: PropTypes.object,
        getEditorState: PropTypes.func,
        setEditorState: PropTypes.func,
    };

    state = {
        imgSrc: "",
        image: null,
        dragEnter: false,
        uploadHighlighted: this.props.config.uploadEnabled && !!this.props.config.uploadCallback,
        showImageLoading: false,
        height: this.props.config.defaultSize.height,
        width: this.props.config.defaultSize.width,
        alt: "",
        isHeightValid: true,
        isWidthValid: true,
        isInlineImage: false,
    };

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(props) {
        if (this.props.expanded && !props.expanded) {
            this.setState({
                imgSrc: "",
                image: null,
                dragEnter: false,
                uploadHighlighted: this.props.config.uploadEnabled && !!this.props.config.uploadCallback,
                showImageLoading: false,
                height: this.props.config.defaultSize.height,
                width: this.props.config.defaultSize.width,
                alt: "",
                error: null,
            });
            this.imageData = null;
        } else if (
            props.config.uploadCallback !== this.props.config.uploadCallback ||
            props.config.uploadEnabled !== this.props.config.uploadEnabled
        ) {
            this.setState({
                uploadHighlighted: props.config.uploadEnabled && !!props.config.uploadCallback,
            });
        }
    }

    handleDragEnter = event => {
        this.handleStopPropagation(event);
        this.setState({
            dragEnter: true,
        });
    };

    handleImageDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            dragEnter: false,
        });

        // Check if property name is files or items
        // IE uses 'files' instead of 'items'
        let data;
        let dataIsItems;
        if (event.dataTransfer.items) {
            data = event.dataTransfer.items;
            dataIsItems = true;
        } else {
            data = event.dataTransfer.files;
            dataIsItems = false;
        }
        for (let i = 0; i < data.length; i += 1) {
            if ((!dataIsItems || data[i].kind === "file") && data[i].type.match("^image/")) {
                const file = dataIsItems ? data[i].getAsFile() : data[i];
                this.previewImage(file);
            }
        }
    };

    handleShowImageUploadOption = () => {
        this.setState({
            uploadHighlighted: true,
        });
    };

    handleShowImageURLOption = () => {
        this.setState({
            uploadHighlighted: false,
        });
    };

    toggleShowImageLoading = () => {
        this.setState((state) => ({ showImageLoading: !state.showImageLoading }));
    };

    handleUpdateValue = ({ target }) => {
        let isWidthValid = false;
        let isHeightValid = false;

        switch (target.name) {
            case "width":
                isWidthValid = target.value.match(/^([\d]*px)$|^([\d]*%)$|^(auto)$/);
                this.setState({ isWidthValid });
                break;
            case "height":
                isHeightValid = target.value.match(/^([\d]*px)$|^([\d]*%)$|^(auto)$/);
                this.setState({ isHeightValid });
                break;
        }
        this.setState({ [target.name]: target.value });
    };

    previewImage = file => {
        if (file.size > 1024 * 1024) {
            this.setState({ error: "Image size is too big. Max image size is 1MB.", image: null });
        } else {
            this.toggleShowImageLoading();

            this.setState({ error: null });
            var reader = new FileReader();
            reader.onload = (theFile => () => {
                var img = new Image();
                img.onload = () => {
                    this.imageData = reader.result;
                    this.fileUpload = false;
                    this.setState({
                        image: {
                            file: theFile,
                            width: img.width,
                            height: img.height,
                            isSVG: file.name.match("\\.svg$") !== null,
                        },
                        showImageLoading: false,
                    });
                };
                img.src = reader.result; // is the data URL because called with readAsDataURL
            })(file);

            // Read in the image file as a data URL.
            reader.readAsDataURL(file);
        }
    };

    handleSelectImage = event => {
        const files = event.target.files; // FileList object
        if (files && files.length > 0) {
            // Loop through the FileList and render image files as thumbnails.
            for (var i = 0, f; (f = files[i]); i++) {
                // Only process image files.
                if (!f.type.match("image.*")) {
                    continue;
                }
                this.previewImage(f);
            }
        }
    };

    saveImage = (imageData) => {
        const { getEditorState, setEditorState, onChange, doCollapse } = this.props;
        const { data, width, height, alt } = imageData;

        if (this.state.isInlineImage) {
            const inlineImageData = { ...imageData, src: data };
            delete inlineImageData.data;
            setEditorState( insertInlineImage(inlineImageData, getEditorState()) );
            doCollapse();
        }
        else {
            onChange(data, height, width, alt);
        }
    };

    handleUploadImage = () => {
        const { image, alt, imgSrc } = this.state;
        let { height, width } = this.state;
        const { onChange } = this.props;

        if (imgSrc) {
            // image entered as URL link - not supported, we want to have all images in firebase storage.
            onChange(imgSrc, height, width, alt);
        } else {
            // image uploaded or D&D. Need to upload the image to the storage first.
            this.toggleShowImageLoading();
            const { uploadCallback } = this.props.config;

            uploadCallback(image.file)
                .then(({ data }) => {
                    this.setState({
                        showImageLoading: false,
                        dragEnter: false,
                    });

                    data.originalWidth = image.width;
                    data.originalHeight = image.height;

                    this.saveImage({
                        data,
                        width,
                        height,
                        alt,
                    });
                })
                .catch(e => {
                    this.setState({
                        showImageLoading: false,
                        dragEnter: false,
                        error: `Can't upload image. Received error: ${e.serverResponse || e}`,
                    });
                });
        }
    };

    handleFileUploadClick = event => {
        this.fileUpload = true;
        event.stopPropagation();
    };

    handleStopPropagation = event => {
        if (!this.fileUpload) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this.fileUpload = false;
        }
    };

    handleInlineImage = () => this.setState((state) => ({ isInlineImage: !state.isInlineImage }));

    renderAddImageModal() {
        const {
            image,
            imgSrc,
            uploadHighlighted,
            showImageLoading,
            dragEnter,
            height,
            width,
            alt,
            error,
            isWidthValid,
            isHeightValid,
            isInlineImage,
        } = this.state;
        const {
            config: {
                popupClassName,
                uploadCallback,
                uploadEnabled,
                urlEnabled,
                previewImage,
                inputAccept,
                alt: altConf,
            },
            doCollapse,
            translations,
        } = this.props;
        return (
            <div className={classNames("rdw-image-modal", popupClassName)} onClick={this.handleStopPropagation}>
                <div className="rdw-image-modal-header">
                    {uploadEnabled && uploadCallback && (
                        <span onClick={this.handleShowImageUploadOption} className="rdw-image-modal-header-option">
                            {translations["components.controls.image.fileUpload"]}
                            <span
                                className={classNames("rdw-image-modal-header-label", {
                                    "rdw-image-modal-header-label-highlighted": uploadHighlighted,
                                })}
                            />
                        </span>
                    )}
                    {urlEnabled && (
                        <span onClick={this.handleShowImageURLOption} className="rdw-image-modal-header-option">
                            {translations["components.controls.image.byURL"]}
                            <span
                                className={classNames("rdw-image-modal-header-label", {
                                    "rdw-image-modal-header-label-highlighted": !uploadHighlighted,
                                })}
                            />
                        </span>
                    )}
                </div>
                {uploadHighlighted ? (
                    <div onClick={this.handleFileUploadClick} className="rdw-image-modal-upload-preview">
                        <div
                            onDragEnter={this.handleDragEnter}
                            onDragOver={this.handleStopPropagation}
                            onDrop={this.handleImageDrop}
                            className={classNames("rdw-image-modal-upload-option", {
                                "rdw-image-modal-upload-option-highlighted": dragEnter,
                            })}
                        >
                            <label htmlFor="file" className="rdw-image-modal-upload-option-label">
                                {previewImage && image ? (
                                    <img
                                        src={this.imageData}
                                        alt={image.file.title}
                                        title={image.file.title}
                                        className="rdw-image-modal-upload-option-image-preview"
                                    />
                                ) : (
                                    (image && image.file.title) ||
                                    translations["components.controls.image.dropFileText"]
                                )}
                            </label>
                        </div>
                        {image && (
                            <div className="sizeInfo">
                                {image.width}x{image.height}, {humanFileSize(image.file.size)}
                            </div>
                        )}
                        <input
                            type="file"
                            id="file"
                            accept={inputAccept}
                            onChange={this.handleSelectImage}
                            className="rdw-image-modal-upload-option-input"
                        />
                    </div>
                ) : (
                    <div className="rdw-image-modal-url-section">
                        <input
                            className="rdw-image-modal-url-input"
                            placeholder={translations["components.controls.image.enterlink"]}
                            name="imgSrc"
                            onChange={this.handleUpdateValue}
                            onBlur={this.handleUpdateValue}
                            value={imgSrc}
                        />
                        <span className="rdw-image-mandatory-sign">*</span>
                    </div>
                )}
                {altConf.present && (
                    <div className="rdw-image-modal-size">
                        <span className="rdw-image-modal-alt-lbl">Alt Text</span>
                        <input
                            onChange={this.handleUpdateValue}
                            onBlur={this.handleUpdateValue}
                            value={alt}
                            name="alt"
                            className="rdw-image-modal-alt-input"
                            placeholder="alt"
                        />
                        <span className="rdw-image-mandatory-sign">{altConf.mandatory && "*"}</span>
                    </div>
                )}
                {image && (
                    <React.Fragment>
                        <Form error={!isWidthValid || !isHeightValid}>
                            <Form.Group widths="equal">
                                <React.Fragment>
                                    &#8596;&nbsp;
                                    <Form.Input
                                        fluid
                                        name="width"
                                        label="width"
                                        onChange={this.handleUpdateValue}
                                        value={width}
                                        error={!isWidthValid}
                                    />
                                </React.Fragment>
                                &nbsp;&#8597;&nbsp;
                                {!image.isSVG && (
                                    <Form.Input
                                        fluid
                                        name="height"
                                        label="height"
                                        onChange={this.handleUpdateValue}
                                        value={height}
                                        error={!isHeightValid}
                                    />
                                )}
                            </Form.Group>
                            <div style={{ margin: "-8px 0 4px 16px", fontSize: "smaller" }}>Use values - px, % or auto</div>
                            <Form.Checkbox label="Insert as Inline Image" checked={isInlineImage} onChange={this.handleInlineImage} />
                        </Form>
                        {image.isSVG && width === "auto" && (
                            <Message warning size="tiny">
                                You have selected SVG image and &quot;auto&quot; width. SVG images must have some
                                dimensions. You can set width to % of the page width or fixed pixels.
                            </Message>
                        )}
                    </React.Fragment>
                )}
                <br />
                <SaveCancelButtons
                    size="medium"
                    onSave={this.handleUploadImage}
                    onCancel={doCollapse}
                    saveDisabled={!image || !height || !width || (altConf.mandatory && !alt)}
                    submitText="Add"
                />
                {showImageLoading && (
                    <Dimmer active inverted style={{ boxSizing: "border-box" }}>
                        <Loader inverted>Saving</Loader>
                    </Dimmer>
                )}
                {error && (
                    <Message error size="small">
                        {error}
                    </Message>
                )}
            </div>
        );
    }

    render() {
        const {
            config: { icon, className, title },
            expanded,
            onExpandEvent,
            translations,
        } = this.props;
        return (
            <div className="rdw-image-wrapper">
                <div
                    className={classNames("rdw-option-wrapper", className)}
                    onClick={onExpandEvent}
                    title={title || translations["components.controls.image.image"]}
                >
                    <img src={icon} alt="" />
                </div>
                {expanded ? this.renderAddImageModal() : undefined}
            </div>
        );
    }
}

export default LayoutComponent;
