import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import { DroppableWrapper } from "../Dnd";
import LessonsListContainer from "./LessonsListContainer";


export default class LessonsListDroppable extends PureComponent {
    static propTypes = {
        lessonUnitId: PropTypes.string.isRequired,
        lessonsList: PropTypes.array.isRequired,
        canEdit: PropTypes.bool,
        maxOrdering: PropTypes.number,
    }

    render() {
        const { lessonUnitId, lessonsList, canEdit, maxOrdering } = this.props;
        return canEdit ? (
            <Droppable droppableId={lessonUnitId} type="LESSON">
                {provided => (
                    <DroppableWrapper provided={provided} innerRef={provided.innerRef}>
                        <LessonListCont
                            lessonUnitId={lessonUnitId}
                            lessonsList={lessonsList}
                            provided={provided}
                            canEdit={canEdit}
                            maxOrdering={maxOrdering}
                        />
                    </DroppableWrapper>
                )}
            </Droppable>
        ) : (
            <LessonListCont
                lessonUnitId={lessonUnitId}
                lessonsList={lessonsList}
                provided={{}}
                canEdit={canEdit}
            />
        );
    }
}

const LessonListCont = ({lessonUnitId, lessonsList, canEdit, provided, maxOrdering}) => (
    <LessonsListContainer
        lessonUnitId={lessonUnitId}
        lessonsList={lessonsList}
        droppablePlaceholder={provided.placeholder}
        canEdit={canEdit}
        maxOrdering={maxOrdering}
    />
);

LessonListCont.propTypes = {
    lessonUnitId: PropTypes.string.isRequired,
    lessonsList: PropTypes.array.isRequired,
    canEdit: PropTypes.bool,
    provided: PropTypes.object,
    maxOrdering: PropTypes.number,
};
