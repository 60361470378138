import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import ImportGlossary from "./ImportGlossary";
import { firebaseConnect } from "react-redux-firebase";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { onImportGlossary, onImportGlossaryStop } from "./importGlossaryActions";
import { getGlossaryToImport, inProgress, getProgress } from "./importGlossarySelector";

const mapStateToProps = function(state) {
    return {
        glossaryToImport: getGlossaryToImport(state),
        inProgress: inProgress(state),
        progress: getProgress(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onImportGlossary, onImportGlossaryStop }, dispatch);
};

export default compose(
    firebaseConnect(() => {
        return [
            { path: "/glossary_to_import" }, // create listener
        ];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["glossaryToImport"])
)(ImportGlossary);
