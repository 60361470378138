import React, { PureComponent } from "react";
import { Env } from "../Version";
import { Label } from "semantic-ui-react";

export default class EnvLabel extends PureComponent {
    render() {
        return "prod" !== Env ? (
            <Label color="red" size="large" {...this.props}>
                {Env.toUpperCase()} SERVER
            </Label>
        ) : null;
    }
}
