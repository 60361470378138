import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";

import { buildBorderRadiusItems } from "./options";

export default class BoxRounded extends React.PureComponent {
    static propTypes = {
        borderRadius: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    handleChange = (e, { value }) => {
        const { onChange } = this.props;
        onChange && onChange({ borderRadius: value });
    }

    render() {
        const { borderRadius, disabled } = this.props;
        return (
            <List selection style={{width: "14em"}} onChange={this.handleChange}>
                {buildBorderRadiusItems(borderRadius, disabled, this.handleChange)}
            </List>
        );
    }
}
