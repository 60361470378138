import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AccessControl from "../../../../auth/AccessControl";
import { Form, Header } from "semantic-ui-react";
import RenderPublicFlag from "./RenderPublicFlag";

export default class ResourcePublicFlag extends PureComponent {
    static propTypes = {
        tenant: PropTypes.object,
        isPublic: PropTypes.bool,
        editValidator: PropTypes.object,
        isSaving: PropTypes.bool,
        onUpdate: PropTypes.func,
        resourceId: PropTypes.number,
    }

    renderReadOnly = () => {
        const { isPublic } = this.props;
        return (
            <RenderPublicFlag isPublic={isPublic} disabled />
        );
    }

    handleToggle = (e, { checked }) => {
        const { resourceId, onUpdate } = this.props;
        onUpdate({ resourceId, is_public: checked });
    }

    render() {
        const { tenant, isPublic, editValidator, isSaving } = this.props;
        if (false === tenant.is_isolated) {
            return (
                <React.Fragment>
                    <Header as="h4">Sharing options</Header>
                    <AccessControl
                        renderNoAccess={this.renderReadOnly}
                        {...editValidator}
                    >
                        <Form>
                            <RenderPublicFlag isPublic={isPublic} onChange={this.handleToggle} isSaving={isSaving} />
                        </Form>
                    </AccessControl>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}
