import React from "react";
import PropTypes from "prop-types";

import { PublishedStatusProps } from "../Publisher";
import PublishStatus from "../Publisher/PublishStatus";
import ChangeInfoLabel, { getUserDisplayName } from "../../component/ChangeInfoLabel";

export default class LessonStatus extends React.PureComponent {

    static propTypes = {
        showOnlyReleased: PropTypes.bool,
        publishedStatus: PublishedStatusProps,
    };

    render() {
        const { publishedStatus, showOnlyReleased } = this.props;

        if (showOnlyReleased) {
            const release = publishedStatus && publishedStatus.release;
            return <div style={{textAlign: "right", color: "grey"}}>
                <ChangeInfoLabel
                    prefix="Published"
                    doneAt={release && release.created_at}
                    doneBy={getUserDisplayName(release && release.createdBy)}
                    unchanged="Unpublished"
                    style={{ textAlign: "right" }}
                />
            </div>;
        }
        else {
            return <div style={{float: "right", display: "flex"}}>
                <PublishStatus {...publishedStatus}
                    style={{ color: "grey" }}
                />
            </div>;
        }
    }
}
