const TYPES = {
    LOCK_ACQUIRE: "Lock/ACQUIRE",
    LOCK_RELEASE: "Lock/RELEASE",
};

const onAcquireLock = payload => ({
    type: TYPES.LOCK_ACQUIRE,
    payload,
});

const onReleaseLock = payload => ({
    type: TYPES.LOCK_RELEASE,
    payload,
});

export { TYPES, onAcquireLock, onReleaseLock };
