import { all, put, takeEvery, select } from "redux-saga/effects";
import { TYPES, onSelectTemplate, onCloseNameEditor, onSavingItem } from "./WidgetTemplatesActions";
import { selectedTemplateId, getHighestItemPosition } from "./WidgetTemplatesSelectors";
import { widgetTemplates } from "../dataSource";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { push } from "connected-react-router";
import { toastError, DB_ERROR } from "../../component/toast";

function* saveTemplateChanges({ payload }) {
    // Extract only useful information from super-object.
    const {
        key,
        tags, // eslint-disable-line no-unused-vars
        used, // eslint-disable-line no-unused-vars
        name,
        ...data
    } = payload;
    const [err] = yield widgetTemplates.updateTemplate(key, name, data);
    if (err) {
        toastError({ code: err.code, header: "Failed to update Widget Template configuration", message: DB_ERROR });
    }
}

function* createNewTemplate() {
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);
    const [err, templateId] = yield widgetTemplates.createTemplate(
        {
            author: userId,
            created: new Date().toISOString(),
            tenant
        }
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to create a new Widget Template", message: DB_ERROR });
    } else {
        yield put(onSelectTemplate(templateId, true));
        yield put(push("/widgets/templates/" + templateId)); 
    }
}

function* deleteTemplate({ payload }) {
    yield put(onSelectTemplate(null));
    const [err] = yield widgetTemplates.removeTemplate(payload.key);
    if (err) {
        toastError({ code: err.code, header: "Failed to remove Widget Template", message: DB_ERROR });
    }
}

function* updateName({ payload }) {
    const templateId = yield select(selectedTemplateId);
    const [err] = yield widgetTemplates.renameTemplate(templateId, payload);
    if (err) {
        toastError({ code: err.code, header: "Failed to rename Widget Template", message: DB_ERROR });
    } else {
        yield put(onCloseNameEditor());
    }
}

function* createTemplateItem({ payload }) {
    const templateId = yield select(selectedTemplateId);
    const highestPosition = yield select(getHighestItemPosition);

    const itemData = { ...payload, position: highestPosition + 1 };
    const [err] = yield widgetTemplates.createTemplateItem(templateId, itemData);
    if (err) {
        toastError({ code: err.code, header: "Failed to add a new Widget template item", message: DB_ERROR });
    }
}

function* updateTemplateItem({ payload: { itemId, itemData } }) {
    const templateId = yield select(selectedTemplateId);
    yield put(onSavingItem({ templateId, itemId, isSaving: true }));
    const [err] = yield widgetTemplates.updateTemplateItem(templateId, itemId, {...itemData});
    yield put(onSavingItem({ templateId, itemId, isSaving: false }));
    if (err) {
        toastError({ code: err.code, header: "Failed to update Widget Template item", message: DB_ERROR });
    }
}

function* updateSetup({payload: {id, setup}}) {
    const [err] = yield widgetTemplates.updateTemplateSetup(id, { setup: { ...setup}});
    if (err) {
        toastError({ code: err.code, header: "Failed to edit Widget Template settings", message: DB_ERROR });
    }
}

function* removeTemplateItem({ payload: itemId }) {
    const templateId = yield select(selectedTemplateId);
    const [err] = yield widgetTemplates.removeTemplateItem(templateId, itemId);
    if (err) {
        toastError({ code: err.code, header: "Failed to remove Widget Template item", message: DB_ERROR });
    }
}

function* orderTemplateItems({ payload }) {
    const templateId = yield select(selectedTemplateId);
    const [err] = yield widgetTemplates.updateItemPositions(templateId, payload);
    if (err) {
        toastError({ code: err.code, header: "Failed to re-order Widget Template items", message: DB_ERROR });
    }
}

function* cloneTemplate({ payload }) {
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);
    const [err, templateId] =  yield widgetTemplates.cloneTemplate(
        payload.template,
        payload.name,
        {
            author: userId,
            created: new Date().toISOString(),
            tenant
        }
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to clone Widget Template", message: DB_ERROR });
    } else {
        yield put(onSelectTemplate(templateId));
        yield put(push("/widgets/templates/" + templateId)); 
    }
}

export function* selectTemplate({ payload: { selectedItem, pushUrl} }) {
    if (pushUrl) {
        if (selectedItem) {
            yield put(push("/widgets/templates/" + selectedItem));
        } else {
            yield put(push("/widgets/templates"));
        }
    }
}

export default function* widgetTemplatesSaga() {
    yield all([
        takeEvery(TYPES.WIDGET_TEMPLATES_SAVE_TEMPLATE, saveTemplateChanges),
        takeEvery(TYPES.WIDGET_TEMPLATES_CREATE_NEW, createNewTemplate),
        takeEvery(TYPES.WIDGET_TEMPLATES_DELETE_TEMPLATE, deleteTemplate),
        takeEvery(TYPES.WIDGET_TEMPLATES_UPDATE_NAME, updateName),
        takeEvery(TYPES.WIDGET_TEMPLATES_CREATE_ITEM, createTemplateItem),
        takeEvery(TYPES.WIDGET_TEMPLATES_UPDATE_ITEM, updateTemplateItem),
        takeEvery(TYPES.WIDGET_TEMPLATES_REMOVE_ITEM, removeTemplateItem),
        takeEvery(TYPES.WIDGET_TEMPLATES_CHANGE_ORDER, orderTemplateItems),
        takeEvery(TYPES.WIDGET_TEMPLATES_CLONE_TEMPLATE, cloneTemplate),
        takeEvery(TYPES.WIDGET_TEMPLATES_UPDATE_SETUP, updateSetup),
        takeEvery(TYPES.WIDGET_TEMPLATES_ITEM_SELECTED, selectTemplate),
    ]);
}
