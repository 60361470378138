import firebase from "../../firebase";
import to from "await-to-js";

const basePath = "widgetLibrary";
const dataPath = "widgetLibrary/data";
const listPath = "widgetLibrary/list";
const changesPath = "widgetLibrary/changes";

export const paths = {
    basePath,
    dataPath,
    listPath,
    toDataEntry: widgetId => `${dataPath}/${widgetId}`,
    toListEntry: widgetId => `${listPath}/${widgetId}`,
    toChangesEntry: widgetId => `${changesPath}/${widgetId}`,
    toItemsData: (widgetId, itemId) =>
        itemId ? `${dataPath}/${widgetId}/items/${itemId}` : `${dataPath}/${widgetId}/items`,
};

/**
 * Get both database references to widget.
 *
 * @param {string} widgetId widget identifier
 * @returns {object} Firebase references, {dataRef, listRef}
 */
export const getReferences = widgetId => {
    const dataRef = firebase.getFirebaseData(paths.toDataEntry(widgetId));
    const listRef = firebase.getFirebaseData(paths.toListEntry(widgetId));
    return { dataRef, listRef };
};

/**
 * Create a new widget record.
 *
 * @param {object} config attributes of the new template, required { name, templateId }
 * @return {string} key/identifier of created widget
 */
export const createWidget = async config => {
    if (config && config.templateId) {
        const { name, templateId, topics, author, created, tenant, ...data } = config;

        const dataRef = firebase.getFirebaseData(dataPath);
        let [err, result] = await to(dataRef.push({ templateId, ...data }));
        if (err) {
            return [err];
        }
        const key = result.key;
        const listRef = firebase.getFirebaseData(listPath);
        [err, result] = await to(listRef.update({ [key]: { name, templateId, topics, author: author + "", created, t: tenant  } }));

        return [err, key];
    }
    return [];
};

const deleteFile = async storagePath => {
    const fileRef = firebase.getFirebaseFile(storagePath);
    await fileRef.delete();
};

const deleteFilesFromItems = async widgetId => {
    const itemsRef = firebase.getFirebaseData(paths.toItemsData(widgetId));
    const itemsData = await itemsRef.once("value").then(sc => sc.val());
    if (itemsData) {
        await Promise.all(
            Object.keys(itemsData).map(async key => {
                const item = itemsData[key];
                if (item && item.storagePath) {
                    await deleteFile(item.storagePath);
                }
            })
        );
    }
};

/**
 * Remove the widget record.
 *
 * @param {string} widgetId widget identifier
 */
export const removeWidget = async widgetId => {
    if (!widgetId) {
        return false;
    }
    try {
        await deleteFilesFromItems(widgetId); // Remove files
    } catch (err) {
        return [err];
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`data/${widgetId}`]: null,
        [`list/${widgetId}`]: null,
        [`changes/${widgetId}`]: null,
        [`m/${widgetId}`]: null,
    };
    return await to(baseRef.update(changes));
};

/**
 * Change name of the widget.
 *
 * @param {string} widgetId widget identifier
 * @param {string} name non-empty display name of widget
 */
export const renameWidget = async (widgetId, name) => {
    if (!widgetId || !name) {
        return [];
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`list/${widgetId}/name`]: name,
    };
    return await to(baseRef.update(changes));
};

const updateWidgetChanges = async (widgetId) => {
    const changesRef = firebase.getFirebaseData(paths.toChangesEntry(widgetId));
    return changesRef.update({ timestamp: new Date().toISOString() });
};

/**
 * Update content of one widget item.
 *
 * @param {string} widgetId widget identifier
 * @param {string} itemId nested item identifier
 * @param {object} changes
 */
export const updateWidgetItem = async (widgetId, itemId, changes) => {
    if (widgetId && itemId && changes) {
        const itemRef = firebase.getFirebaseData(paths.toItemsData(widgetId, itemId));
        await updateWidgetChanges(widgetId);
        return await to(itemRef.update(changes));
    }
    return [];
};

const widgetUpdateTopic = (widgetId, topicId, value) => {
    const topicRef = firebase.getFirebaseData(`${paths.toListEntry(widgetId)}/topics`);
    return to(topicRef.update({ [topicId]: value }));
};

export const addTopic = (widgetId, topicId) => widgetUpdateTopic(widgetId, topicId, true);
export const removeTopic = (widgetId, topicId) => widgetUpdateTopic(widgetId, topicId, null);


export const cloneWidget = async config => {
    if (config && config.templateId) {
        const { name, templateId, topics, author, created, items, srcWidgetId, outlineId, tenant } = config;

        const dataRef = firebase.getFirebaseData(dataPath);
        const { key } = dataRef.push();
        const baseRef = firebase.getFirebaseData(basePath);
        const changes = {
            [`data/${key}`]: { templateId, items },
            [`list/${key}`]: { name, templateId, topics, author: author + "", created, makeDeepClone: srcWidgetId, outlineId, t: tenant },
        };
        const [ err ] = await to(baseRef.update(changes));
        return [ err, key ];
    }
    return [];
};

export const changeWidgetTitle = async (widgetId, title, hiddenTitle) => {
    const dataRef = firebase.getFirebaseData(`${paths.toDataEntry(widgetId)}`);
    await updateWidgetChanges(widgetId);
    return to(dataRef.update({ custom_title: title, hidden_title: hiddenTitle || null }));
};

export const changeItemTitle = async (widgetId, itemId, title, hiddenTitle) => {
    const dataRef = firebase.getFirebaseData(`${paths.toItemsData(widgetId, itemId)}`);
    await updateWidgetChanges(widgetId);
    return to(dataRef.update({ custom_title: title, hidden_title: hiddenTitle || null}));
};
