import { TYPES } from "./AdminFunctionsActions";

const initialState = {
    running: null,
    failure: null,
    message: null,
    logData: [],
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.ADMIN_LOG_WRITE:
            return { ...state, logData: state.logData.concat(payload) };
        case TYPES.ADMIN_LOG_CLEAR:
            return { ...state, logData: [] };
        case TYPES.ADMIN_EXECUTE:
            return { ...state, running: payload, failure: null, message: `Executing ${payload}...` };
        case TYPES.ADMIN_SUCCESS:
            return { ...state, running: false, failure: null, message: payload || null };
        case TYPES.ADMIN_FAILURE:
            return { ...state, running: false, failure: payload || null, message: null };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
