import { all, takeEvery, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onUpdateProgress, onFinished } from "./FixRefs4ProblemsAndProblemSetsActions";

function* fixRefsFromLessons(outlineId, collection, lessonId, lesson, problemSets) {
    if (lesson[collection]) {
        for (const id of Object.keys(lesson[collection])) {
            if (problemSets && !problemSets[id]) {
                // eslint-disable-next-line no-console
                yield console.log("Remove", `outlines_data/${outlineId}/lessons/${lessonId}/${collection}/${id}`);
                yield firebase.getFirebaseData(`outlines_data/${outlineId}/lessons/${lessonId}/${collection}/${id}`).remove();
            }
        }
    }
}

function* fixRefsToLessons(problemSetId, problemSet, collection, lessonIdCollection) {
    if (problemSet[collection]) {
        for (const id of Object.keys(problemSet[collection])) {
            if (lessonIdCollection && !lessonIdCollection[id]) {
                // eslint-disable-next-line no-console
                yield console.log("Remove", `problemSet/${problemSetId}/${collection}/${id}`);
                yield firebase.getFirebaseData(`problemSet/${problemSetId}/${collection}/${id}`).remove();
            }
        }
    }
}

function* fixRefsToProblems(problemSetId, problemSet, problems) {
    if (problemSet.problems) {
        const filteredProblems = problemSet.problems.filter(p => problems[p]);
        if (filteredProblems.length !== problemSet.problems.length) {
            // eslint-disable-next-line no-console
            yield console.log("Update", `problemSet/${problemSetId}/problems`, filteredProblems, problemSet.problems);
            yield firebase.getFirebaseData(`problemSet/${problemSetId}/problems`).set(filteredProblems);
        }
        for (const problemId of filteredProblems) {
            const problem = problems[problemId];
            if (problem && (!problem.problemSets || !problem.problemSets[problemSetId])) {
                // eslint-disable-next-line no-console
                yield console.log("Missing reference from problem to problem set", problemId, problemSetId);
                yield firebase.getFirebaseData(`problem/${problemId}/problemSets`).update({ [problemSetId]: true });
            }
        }
    }
}

function* fixRefsFromProblems(problemId, problem, problemSets) {
    if (problem.problemSets) {
        for (const id of Object.keys(problem.problemSets)) {
            let markToRemove = false;
            if (problemSets && !problemSets[id]) {
                markToRemove = true;
            } else {
                const foundProblems = problemSets[id].problems.filter(p => p === problemId);
                if (0 === foundProblems.length) {
                    markToRemove = true;
                }
            }
            if (markToRemove) {
                // eslint-disable-next-line no-console
                yield console.log("Remove", `problem/${problemId}/problemSets/${id}`);
                yield firebase.getFirebaseData(`problem/${problemId}/problemSets/${id}`).remove();
            }
        }
    }
}

function* fixReferences() {
    const kitsRef = firebase.getFirebaseData("kits_data");
    const kitsData = (yield kitsRef.once("value")).val();
    let progress = { lessons: { done: false, count: 0},  problemSets: { done: false, count: 0}, problems: { done: false, count: 0} };
    const problemSetRef = firebase.getFirebaseData("problemSet");
    const problemSets = (yield problemSetRef.once("value")).val();
    const lessonIdCollection = {};
    for (const kitId of Object.keys(kitsData)) {
        const outlineId = kitsData[kitId]["outline_id"];
        const lessonsRef = firebase.getFirebaseData(`outlines_data/${outlineId}/lessons`);
        const lessonsData = (yield lessonsRef.once("value")).val();
        for (const lessonId of Object.keys(lessonsData)) {
            lessonIdCollection[lessonId] = true;
            const lesson = lessonsData[lessonId];
            yield fixRefsFromLessons(outlineId, "homework", lessonId, lesson, problemSets);
            yield fixRefsFromLessons(outlineId, "problem_sets", lessonId, lesson, problemSets);
            progress.lessons.count++;
            yield put(onUpdateProgress(progress));
        }
    }
    progress.lessons.done = true;
    yield put(onUpdateProgress(progress));
    const problemRef = firebase.getFirebaseData("problem");
    const problems = (yield problemRef.once("value")).val();
    for (const problemSetId of Object.keys(problemSets)) {
        const problemSet = problemSets[problemSetId];
        yield fixRefsToLessons(problemSetId, problemSet, "lessons_problem_sets", lessonIdCollection);
        yield fixRefsToLessons(problemSetId, problemSet, "lessons_homework", lessonIdCollection);
        yield fixRefsToProblems(problemSetId, problemSet, problems);
        progress.problemSets.count++;
        yield put(onUpdateProgress(progress));
    }
    progress.problemSets.done = true;
    yield put(onUpdateProgress(progress));
    for (const problemId of Object.keys(problems)) {
        const problem = problems[problemId];
        yield fixRefsFromProblems(problemId, problem, problemSets);
        progress.problems.count++;
        yield put(onUpdateProgress(progress));
    }
    progress.problems.done = true;
    yield put(onUpdateProgress(progress));
    yield put(onFinished());
}

export default function* saga() {
    yield all([takeEvery(TYPES.FIX_PROBLEMS_REFS, fixReferences)]);
}