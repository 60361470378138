
import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, Header } from "semantic-ui-react";
import EditableText from "../../../component/EditableText";
import memoize from "memoize-one";

export default class LessonBreadcrumb extends React.PureComponent {
    
    static propTypes = {
        kitName: PropTypes.string,
        lessonName: PropTypes.string.isRequired,
        onGoBack: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onClick: PropTypes.func,
        canEdit: PropTypes.bool,
        onGoToAuthorKits: PropTypes.func,
    };

    generateSections = memoize((kitName, onGoToAuthorKits, onGoBack) => [
        { key: "KitList", content: "Author Kits", link: onGoToAuthorKits !== undefined, onClick: onGoToAuthorKits },
        { key: "Kit", content: kitName, link: true, onClick: onGoBack },
    ]);
    
    render() {
        const { kitName, lessonName, onGoBack, onUpdate, onClick, canEdit, onGoToAuthorKits } = this.props;
        const sections = this.generateSections(kitName, onGoToAuthorKits, onGoBack);
        return <React.Fragment>
            <Breadcrumb icon="right angle" sections={sections} />
            <Header as="h2" className="pageHeader">
                <EditableText 
                    text={lessonName} 
                    onUpdate={onUpdate}
                    onEditation={onClick}
                    width="500px"
                    canEdit={canEdit}
                />
            </Header>
        </React.Fragment>;
    }
}
