import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Segment, Header } from "semantic-ui-react";
import { formatReview } from "../../../component/seamlessEditor/export/exportToHtml";

export default class ReviewComments extends PureComponent {
    static propTypes = {
        comments: PropTypes.array,
    };

    render() {
        const { comments } = this.props;
        return comments && 0 < comments.length && (
            <React.Fragment>
                <Header as="h4">Review comments</Header>
                <Segment.Group>
                    {comments.map((review, l) => (
                        <Segment key={l} dangerouslySetInnerHTML={{ __html: formatReview(review, true) }} />
                    ))}
                </Segment.Group>
            </React.Fragment>
        );
    }
}
