import React from "react";
import { Route } from "react-router-dom";

import BeLATopicCollectionContainer from "./BeLATopicCollection/BeLATopicCollectionContainer";

const Routes = () => [
    <Route key="route-ttt-1" path="/ttt/topicCollections" component={BeLATopicCollectionContainer} />
];

export default Routes;
