import React from "react";
import PropTypes from "prop-types";
import { Popup, Divider } from "semantic-ui-react";
import TableStyleConfigurator from "./TableStyleConfigurator";
import { insertSegment } from "../utils/utils";
import { getContextPropsForPopup } from "../utils/domUtils";
import { ENTITY_TYPE } from "../constants";
import { entityToHTML } from "../../export/exportToHtml";

import TableLayoutGrid from "./TableLayoutGrid";
import TableLayoutForm from "./TableLayoutForm";

const CELL_DATA = { contentState: null, html: "" };

const initializeEntityData = (rows, cols, tableStyle) => {
    // Initialize 2D array of empty cells (object).
    // https://stackoverflow.com/a/35578536/2629460
    const emptyRows = Array(rows).fill(0);
    const emptyCols = Array(cols).fill(0);
    const tableData = emptyRows.map(() => emptyCols.map(() => ({ ...CELL_DATA })));
    // Convert entity to HTML
    const entityData = { tableData, tableStyle };
    entityData.html = entityToHTML({ type: ENTITY_TYPE.TABLE, data: entityData });
    return entityData;
};

class TableCreatePopup extends React.Component {
    static propTypes = {
        contextNode: PropTypes.instanceOf(Element).isRequired,
        editorState: PropTypes.object.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onClose: PropTypes.func,
    };

    state = {
        tableStyle: "simple",
        contextProps: {},
    };

    componentDidMount = () => {
        const { contextNode } = this.props;
        const contextProps = getContextPropsForPopup(contextNode);
        this.setState({ contextProps });
    };

    handlePopupClose = () => {
        this.props.onClose();
    };

    handleCreateTable = (rows, cols) => {
        const { editorState, setEditorState } = this.props;
        const { tableStyle } = this.state;
        const entityData = initializeEntityData(rows, cols, tableStyle);
        const newEditorState = insertSegment(editorState, ENTITY_TYPE.TABLE, entityData);
        setEditorState(newEditorState);
        // Close the popup when table is created
        this.props.onClose();
    };

    handleChangeStyle = (e, { value }) => {
        this.setState({ tableStyle: value });
    }

    render() {
        const { tableStyle, contextProps } = this.state;
        const { node, pos, hOffset, vOffset } = contextProps;

        return (
            <Popup
                className="tableConfigurator"
                context={node}
                position={pos}
                horizontalOffset={hOffset}
                verticalOffset={vOffset}
                open={true}
                onClose={this.handlePopupClose}
                onUnmount={this.handlePopupClose}
                wide="very"
            >
                <TableLayoutGrid rows={6} cols={8} onCreate={this.handleCreateTable} />
                <Divider />
                <TableLayoutForm onCreate={this.handleCreateTable} />
                <TableStyleConfigurator tableStyle={tableStyle} onChange={this.handleChangeStyle} />
            </Popup>
        );
    }
}

export default TableCreatePopup;
