import firebase from "../../firebase";
import { outlineManager } from "../Outlines/DataSource";
import { loadData } from "../../utils/sagaForFBUtils";

/**
 * Id as used by a database to identify entities. For Firebase it is a string.
 * @typedef {string} DbId
 */

const getDataPath = () => "kits_data";

export const createKit = async (name, author, created, courseVariantId = null, tenant) => {
    const dataPath = getDataPath();

    const dataRef = firebase.getFirebaseData(dataPath);
    const { key } = await dataRef.push( { name, course_variants: courseVariantId ? { [courseVariantId]: true } : null, author, created, t: tenant } );
    const outlineId = await outlineManager.createOutline(key);
    await dataRef.update( { [`${key}/outline_id`]: outlineId } );
    return { key, outlineId };
};

export const removeAKit = async (aKitId) => {

    const dataPath = getDataPath();

    const outline_id = await loadData(`${dataPath}/${aKitId}/outline_id`);

    outline_id && await outlineManager.removeOutline(outline_id);
    const dataRef = firebase.getFirebaseData(dataPath);
    await dataRef.update( { [aKitId]: null } );
};

export const assignVariant = async (kitId, variants) => {
    const cvRef = firebase.getFirebaseData(`${getDataPath()}/${kitId}/course_variants`);
    await cvRef.update( variants );
};

export const unassignVariant = async (kitId, variants) => {
    const cvRef = firebase.getFirebaseData(`${getDataPath()}/${kitId}/course_variants`);
    const changes = {};
    Object.keys(variants).map(cvId => changes[cvId] = null);
    await cvRef.update( changes );
};

export const updateKit = async (kitId, config) => {
    const kitRef = firebase.getFirebaseData(`${getDataPath()}/${kitId}`);
    await kitRef.update( config );
};

export const getKitName = async (kitId) => {
    const kitRef = firebase.getFirebaseData(`${getDataPath()}/${kitId}/name`);
    return (await kitRef.once("value")).val();
};

export const getKitDbId = async (kitId) => {
    const kitRef = firebase.getFirebaseData(`${getDataPath()}/${kitId}/id`);
    return (await kitRef.once("value")).val();
};

export const getKitPackages = async (kitId) => {
    const kitRef = firebase.getFirebaseData(`${getDataPath()}/${kitId}/m`);
    return (await kitRef.once("value")).val();
};

export const getOutlineIdByKit = async (kitId) => {
    const kitRef = firebase.getFirebaseData(`${getDataPath()}/${kitId}/outline_id`);
    return (await kitRef.once("value")).val();
};

export const getKitNameByOutline = async (outlineId) => {
    return getKitName(await outlineManager.getKitId(outlineId));
};
