import { TYPES } from "./ResourceOwnerActions";
const initialState = {
    isSaving: false
};

export default (state = initialState, { type }) => {
    switch (type) {
        case TYPES.RESOURCE_OWNER_SAVE:
            return { ...state, isSaving: true };
        case TYPES.RESOURCE_OWNER_SAVED:
            return { ...state, isSaving: false };
        default:
            return state;
    }
};
