exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3hrRGH5anaHMoJEmK2QAmE {\n  background: transparent !important;\n  padding: 0 !important; }\n", ""]);

// exports
exports.locals = {
	"toast_transparent": "_3hrRGH5anaHMoJEmK2QAmE"
};