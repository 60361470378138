import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const getFilterBtnLabel = (tags) => {
    const count = Array.isArray(tags) ? tags.length : (
        typeof tags === "number" ? tags : 0
    );
    if (0 == count) {
        return "Filter";
    } else if (1 === count) {
        return `Filtered (by ${count} tag)`;
    } else {
        return `Filtered (by ${count} tags)`;
    }
};


const FilterByButton = ({ selectedTags, onClick }) => (
    <Menu.Item link title="Click here to set advanced filter." onClick={onClick}>
        <Icon name="filter" /> {getFilterBtnLabel(selectedTags)}
    </Menu.Item>
);
FilterByButton.propTypes = {
    selectedTags: PropTypes.array,
    onClick: PropTypes.func,
};

export default FilterByButton;