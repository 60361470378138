import { combineReducers } from "redux";
import SyllabiListReducer from "./SyllabiList/SyllabiListReducer";
import ResourceLinkReduces from "./component/ResourceLink/ResourceLinkReducer";
import AdvancedResourceSearchReducer from "./component/AdvancedResourceSearch/AdvancedResourceSearchReducer";
import SiPiTemplatesListReducer from "./SiPiTemplatesList/SiPiTemplatesListReducer";
import ResourcesReducer from "./Resources/ResourcesReducer";
import SiPiClonerReducer from "./component/SiPiCloner/SiPiClonerReducer";
import SiPiTestCreatorReducer from "./component/SiPiTestCreator/SiPiTestCreatorReducer";
import SyllabusOutlineReducer from "./SyllabusOutline/SyllabusOutlineReducer";
import resourceName from "./Resources/components/ResourceName/ResourceNameReducer";
import resourceTagsEditor from "./Resources/components/ResourceTagsEditor/ResourceTagsEditorReducer";
import resourceDescription from "./Resources/components/ResourceDescription/ResourceDescriptionReducer";
import resourceOwner from "./component/ResourceOwner/ResourceOwnerReducer";
import resourcePublicFlag from "./Resources/components/ResourcePublicFlag/ResourcePublicFlagReducer";
import resourceSourceType from "./Resources/components/ResourceSourceType/ResourceSourceTypeReducer";
import TemplateiPlannersReducer from "./SiPiTemplatesList/component/TemplateiPlanners/TemplateiPlannersReducer";

export default combineReducers({
    syllabiList: SyllabiListReducer,
    sipiTemplatesList: SiPiTemplatesListReducer,
    resource: ResourceLinkReduces,
    resourceAdvancedSearch: AdvancedResourceSearchReducer,
    resources: ResourcesReducer,
    cloner: SiPiClonerReducer,
    testCreator: SiPiTestCreatorReducer,
    syllabusOutline: SyllabusOutlineReducer,
    resourceName,
    resourceTagsEditor,
    resourceDescription,
    resourceOwner,
    resourcePublicFlag,
    resourceSourceType,
    templateiPlanners: TemplateiPlannersReducer,
});
