import { TYPES } from "./SidebarMenuActions";

const initialState = {
    activeIndex: JSON.parse(localStorage.getItem("sidebarMenuActiveIndex")) || {},
};

const reducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case TYPES.SIDEBAR_MENU_CLICK: {
            return { ...state, activeIndex: payload };
        }
        default:
            return state;
    }
};

export default reducer;
