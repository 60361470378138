import firebase from "../../firebase";
import * as widgetTemplates from "./widgetTemplates";
import * as widgetLibrary from "./widgetLibrary";
import * as snippetManager from "./snippetsManager";
import * as lessonContent from "./lessonContent";
import * as glossary from "./glossary";
import * as aKitManager from "./aKitManager";
import * as modulesManager from "./modulesManager";

export {
    firebase as default,
    widgetTemplates,
    widgetLibrary,
    snippetManager,
    lessonContent,
    glossary,
    aKitManager,
    modulesManager,
};