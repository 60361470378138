const TYPES = {
    SNIPPETS_MANAGER_CREATE_NEW: "Authoring/SnippetsManager/CREATE_NEW",
    SNIPPETS_MANAGER_CHANGE_NAME: "Authoring/SnippetsManager/CHANGE_NAME",
    SNIPPETS_MANAGER_REMOVE_SNIPPET: "Authoring/SnippetsManager/REMOVE_SNIPPET",
    SNIPPETS_MANAGER_CHANGE_FILTER: "Authoring/SnippetsManager/CHANGE_FILTER",
    SNIPPETS_MANAGER_SAVE_SNIPPET: "Authoring/SnippetsManager/SAVE_SNIPPET",
    SNIPPETS_MANAGER_CHANGE_UNUSED_FILTER: "Authoring/SnippetsManager/CHANGE_UNUSED_FILTER",
    SNIPPETS_MANAGER_SELECT_TAG: "Authoring/SnippetsManager/SELECT_TAG",
    SNIPPETS_MANAGER_SET_TAG_FILTER: "Authoring/SnippetsManager/SET_TAG_FILTER",
    SNIPPETS_MANAGER_SET_MODULE_FILTER: "Authoring/SnippetManager/SET_MODULE_FILTER",
};

const onCreate = () => ({
    type: TYPES.SNIPPETS_MANAGER_CREATE_NEW
    
});

const onRename = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_CHANGE_NAME,
    payload
});

const onRemove = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_REMOVE_SNIPPET,
    payload
});

const onChangeFilter = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_CHANGE_FILTER,
    payload
});

const onSaveSnippet = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_SAVE_SNIPPET,
    payload
});

const onChangeUnusedFilter = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_CHANGE_UNUSED_FILTER,
    payload
});

const onSelectTag = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_SELECT_TAG,
    payload
});

const onSetTagFilter = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_SET_TAG_FILTER,
    payload
});

const onSetModuleFilter = (payload) => ({
    type: TYPES.SNIPPETS_MANAGER_SET_MODULE_FILTER,
    payload
});

export {
    TYPES,
    onCreate,
    onRename,
    onRemove,
    onChangeFilter,
    onSaveSnippet,
    onChangeUnusedFilter,
    onSelectTag,
    onSetTagFilter,
    onSetModuleFilter,
};
