import firebase from "../../firebase";

const SporkUriPattern = /^spork:\/\/(.*)/;

export const convertToLinkOrFileURL = (href = "javascript:void(0)") => {
    if (typeof href !== "string") {
        throw new Error(`Invalid URL provided for conversion (${typeof href}, string expected).`);
    }
    const matches = href.match(SporkUriPattern);
    if (matches) {
        return firebase.getFileURL(matches[1]);
    }
    return href;
};
