import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Message, Icon, Grid } from "semantic-ui-react";

import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import SharedToolbar from "../../../component/seamlessEditor/bookEditor/SharedToolbar";
import { prepareTextItemContent, getEditorComponentKey } from "../../WidgetLibrary/WidgetEditor";
import SaveCancelInToolbar from "../../WidgetLibrary/WidgetEditor/SaveCancelInToolbar";
import LessonEditorItem from "./LessonEditorItem";
import LessonWidgetsList from "../LessonWidgetsList";

import "../../WidgetLibrary/WidgetEditor/WidgetEditor.css";
import "./LessonEditor.css";

let textEditorOrder = 0;
const resetFirstTextEditor = () => (textEditorOrder = 0);
const checkFirstTextEditor = () => 1 === (textEditorOrder += 1);

export default class LessonEditor extends PureComponent {
    static propTypes = {
        lessonId: PropTypes.string,
        outlineId: PropTypes.string,
        widgets: PropTypes.array,
        templatesData: PropTypes.object,
        canEdit: PropTypes.bool,
        canReview: PropTypes.bool,
        asReview: PropTypes.bool,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
        onCloseItemEditor: PropTypes.func.isRequired,
        onUpdateItem: PropTypes.func.isRequired,
        onUpdateName: PropTypes.func.isRequired,
        focusedItem: PropTypes.shape({
            widgetId: PropTypes.string,
            scroll: PropTypes.bool,
        }),
        onSetFocus: PropTypes.func.isRequired,
        selectedLesson: PropTypes.object,
        reviews: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.getEditorContentFns = {};
    }

    refList = [];

    state = {
        toolbarRef: null,
        componentKey: getEditorComponentKey(),
        isReMounting: false,
    };

    toolbarRef = React.createRef();

    componentDidMount = () => {
        if (this.toolbarRef && this.toolbarRef.current) {
            const sharedToolBar = new SharedToolbar();
            sharedToolBar.mount(this.toolbarRef.current);
            this.setState({ toolbarRef: sharedToolBar });
        }
    };

    componentDidUpdate = () => {
        const { focusedItem } = this.props;
        if (!this.state.toolbarRef) {
            this.componentDidMount();
        }
        if (this.state.isReMounting) {
            this.setState({ isReMounting: false });
        }
        // when we receive focused widget ID and it does not match our local value
        // it means outside component did that and we should scroll widget into view
        if (
            focusedItem &&
            focusedItem.scroll &&
            this.refList[focusedItem.widgetId] &&
            this.refList[focusedItem.widgetId].current
        ) {
            this.refList[focusedItem.widgetId].current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    };

    componentWillUnmount = () => {
        this.state.toolbarRef && this.state.toolbarRef.unmount();
    };

    registerGetContentFn = (widgetId, itemId, fn) => {
        const { isReMounting } = this.state;
        const key = widgetId + itemId;
        if (fn === null) {
            // ignore unsubscribe when all components are being re-mounted on Cancel
            if (!isReMounting) {
                delete this.getEditorContentFns[key];
            }
        } else {
            this.getEditorContentFns[key] = { widgetId, itemId, fn };
        }
    };

    // add isDirty flag to list of registered editor callbacks
    handleDirty = (widgetId, itemId) => {
        const key = widgetId + itemId;
        this.getEditorContentFns[key] = { ...this.getEditorContentFns[key], isDirty: true };
        this.props.onSetDirty(true);
    };

    // iterate over all opened and registered editors and save their data if the data changed (isDirty flag)
    handleSave = () => {
        const { onUpdateItem, onSetDirty, outlineId, lessonId } = this.props;

        for (let key in this.getEditorContentFns) {
            const { widgetId, itemId, fn, isDirty } = this.getEditorContentFns[key];
            if (isDirty) {
                const content = prepareTextItemContent(fn());
                onUpdateItem({ widgetId, itemId, content, outlineId, lessonId });
                this.getEditorContentFns[key] = { ...this.getEditorContentFns[key], isDirty: false };
            }
        }
        onSetDirty(false);
    };

    // generate new random key to force whole page re-mount
    handleCancel = () => {
        this.getEditorContentFns = {};
        this.setState({ componentKey: getEditorComponentKey(), isReMounting: true });
        this.props.onSetDirty(false);
    };

    render() {
        const { widgets, asReview, isDirty, focusedItem, outlineId, lessonId, canEdit, selectedLesson, reviews } = this.props;
        const { toolbarRef, componentKey } = this.state;
        const hasWidgets = !!(widgets && widgets.length);

        if (hasWidgets) {

            return (
                <React.Fragment>
                    <div className="toolbarArea">
                        <div className="lessonEditorToolbar" ref={this.toolbarRef} />
                        {toolbarRef && isDirty ? (
                            <SaveCancelInToolbar onSave={this.handleSave} onCancel={this.handleCancel} />
                        ) : (
                            <div />
                        )}
                    </div>
                    {asReview && (
                        <div className="editorStatusBar">
                            You are in a Review mode. Use Review icon in toolbar to make changes to selected text.
                        </div>
                    )}
                    <Grid columns={2}>
                        <Grid.Column width={12}>
                            <div className="lessonEditorWidgetGroupSegment" key={componentKey}>
                                {widgets.map(({ key, value }, i) => {
                                    this.refList[key] = React.createRef();
                                    0 === i && resetFirstTextEditor();
                                    return (
                                        value &&
                                (undefined !== value.makeDeepClone ? (
                                    <Message key={key} icon info>
                                        <Icon name="circle notched" loading />
                                        <Message.Content>Cloning widget...</Message.Content>
                                    </Message>
                                ) : value.loading ? (
                                    <Message key={key} icon info>
                                        <Icon name="circle notched" loading />
                                        <Message.Content>Loading widget...</Message.Content>
                                    </Message>
                                ) : (
                                    <div ref={this.refList[key]} key={key}>
                                        <LessonEditorItem
                                            id={key}
                                            value={value}
                                            asReview={asReview}
                                            {...this.props}
                                            toolbarRef={toolbarRef}
                                            registerGetContentFn={this.registerGetContentFn}
                                            onDirty={this.handleDirty}
                                            checkFirstText={checkFirstTextEditor}
                                            isFocused={key === focusedItem.widgetId && focusedItem.scroll}
                                        />
                                    </div>
                                ))
                                    );
                                })}
                            </div>
                        </Grid.Column> 
                        <Grid.Column width={4}>
                            
                            <LessonWidgetsList
                                outlineId={outlineId}
                                lessonId={lessonId}
                                canEdit={canEdit}
                                selectedLesson={selectedLesson}
                                reviews={reviews}
                            />
                        </Grid.Column>
                    </Grid>
                </React.Fragment>
            );}
        else {
            return (
                <Grid columns={2}>
                    <Grid.Column width={12}>
                        <NoDataPlaceholder text="Lesson has no content." />
                    </Grid.Column> 
                    <Grid.Column width={4}>
                        
                        <LessonWidgetsList
                            outlineId={outlineId}
                            lessonId={lessonId}
                            canEdit={canEdit}
                            selectedLesson={selectedLesson}
                            reviews={reviews}
                        />
                    </Grid.Column>
                </Grid>
            );
        }
    }
}
