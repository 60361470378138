import { createSelector } from "reselect";

const getWidgetLessonsData = (state) => state.kitBuilder && state.kitBuilder.widgetLessons;
const getDisplayedWidgetId = (state, props) => props.widgetId;

export const getWidgetLessons = createSelector(
    getDisplayedWidgetId,
    getWidgetLessonsData,
    (widgetId, data) => data[widgetId]
);

