import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form, Dimmer, Loader, Header, Segment } from "semantic-ui-react";
import AccessControl from "../../../auth/AccessControl";
import { SaveCancelButtons } from "bmd-react";
import UserAvatar from "react-user-avatar";
import "./ResourceOwner.css";
import styles from "../../Resources/resources.module.scss";

export default class ResourceOwner extends PureComponent {
    static propTypes = {
        author: PropTypes.string,
        usersOptions: PropTypes.array,
        authorId: PropTypes.string,
        email: PropTypes.string,
        onSave: PropTypes.func,
        resourceId: PropTypes.number,
        isSaving: PropTypes.bool,
        tenantId: PropTypes.number,
        resourceTenantId: PropTypes.number,
        firebase: PropTypes.object.isRequired,
    }

    state = {
        editation: false,
        userId: null,
    }

    componentDidMount() {
        this.props.firebase.watchEvent("value", "user", null, {isQuery: true, queryParams: [ "orderByChild=valid", "equalTo=true" ]});
    }

    handleEditation = () => this.setState({ editation: true, userId: null });
    handleCancelEditation = () => this.setState({ editation: false });
    handleChange = (e, { value }) => this.setState({ userId: value });
    handleSave = () => {
        const { resourceId, onSave } = this.props;
        onSave({ resourceId, userId: this.state.userId });
        this.handleCancelEditation();
    }

    renderOwner = () => {
        const { author, email } = this.props;
        return author ? <React.Fragment>
            <UserAvatar name={author} size="32" style={{float: "left", fontSize: "14px", display: "inline-flex", marginRight: "5px"}}/>
            <div style={{flexGrow: 2}}>{author} <br/><span style={{color: "grey"}}>{email}</span></div></React.Fragment>
            : <i className={styles.noDataMsg}>Not set</i>;
    }

    render() {
        const { author, usersOptions, authorId, isSaving, tenantId, resourceTenantId } = this.props;
        const { editation, userId } = this.state;
        const action = author ? "Change" : "Set";

        return (
            <Dimmer.Dimmable dimmed={isSaving}>
                <Dimmer active={isSaving} inverted>
                    <Loader >Updating resource owner...</Loader>
                </Dimmer>
                <Header as="h4">Owner</Header>
                <div className="resource owner">{this.renderOwner()}
                    <AccessControl action="resource:setOwner" data={{ tenantId, resourceTenantId }}>
                        {editation && (
                            <Modal
                                open={true}
                                onClose={this.handleCancelEditation}
                                closeOnDimmerClick={false}
                                size="tiny"
                            >
                                <Modal.Header>{action} Owner</Modal.Header>
                                <Modal.Content>
                                    <Form>
                                        <Form.Field>
                                            <label>Current owner:</label>
                                            <Segment secondary style={{marginTop: 0, color: "rgba(0,0,0,87)"}}>{this.renderOwner()}</Segment>
                                        </Form.Field>
                                        <Form.Field />
                                        <Form.Select
                                            name="userId"
                                            label="New owner"
                                            placeholder="Select user as owner of future data"
                                            options={usersOptions || []}
                                            onChange={this.handleChange}
                                            value={userId}
                                            required
                                            search
                                            lazyLoad
                                        />
                                    </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <SaveCancelButtons
                                        onSave={this.handleSave}
                                        saveDisabled={!userId || userId === authorId}
                                        onCancel={this.handleCancelEditation}
                                    />
                                </Modal.Actions>
                            </Modal>
                        )}
                        <Button floated="right" compact onClick={this.handleEditation}>{action} owner...</Button>
                    </AccessControl>
                </div>

            </Dimmer.Dimmable>
        );
    }
}
