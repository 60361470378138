import { createSelector } from "reselect";
import { compareByUserNamesCI } from "../../../SchoolCourses/SchoolCoursesSelectors";

export const getSection = (state, props) => state.external.sections.sections && state.external.sections.sections[props.id];
export const selectedSectionId = state => state.external.sections.selectedSectionId;
export const getSelectedSection = ({ external }) => external.sections.selectedSectionId && external.sections.sections && external.sections.sections[external.sections.selectedSectionId];

const filterUsers = (section, scope) => {
    if (section && section.users) {
        let filteredUsers = section.users.filter(u => scope === u.scope);
        return filteredUsers.sort(compareByUserNamesCI);
    }
};

export const teachers = createSelector(
    getSelectedSection,
    (section) => filterUsers(section, "teacher")
);



export const students = createSelector(
    getSelectedSection,
    selectedSectionId,
    (section) => filterUsers(section, "student")

);