const TYPES = {
    DESIGN_SET: "DESIGN_SET"
};

const setDesign = data => ({ type: TYPES.DESIGN_SET, data });

export {
    TYPES,
    setDesign
};
