import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, List, Popup, Message, Dimmer, Loader, Header, Icon } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import AddRole from "./AddRole";
import RemoveIcon from "../../../component/RemoveIcon";
import "./UserEditDialog.scss";
import ConfirmDeleteWithContext from "../../../component/ConfirmDeleteWithContext";
import { roles as roleDefinitions } from "../../../auth";
import UserInfo from "./UserInfo";
import { ActionIconsContainer } from "bmd-react";

export default class UserEditDialog extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        user: PropTypes.object,
        onChangeRole: PropTypes.func,
        roleOptions: PropTypes.array,
        isClaimsUpdating: PropTypes.bool,
        authUser: PropTypes.shape({
            uid: PropTypes.string,
        }),
    };

    state = {
        selectedRole: "",
        removeContext: null,
        removedRole: "",
        openAddRole: false,
        showNote: false,
    };

    isEditingCurrentUser = () => {
        const { user, authUser } = this.props;
        return user.uid === authUser.uid;
    }

    handleAddRole = role => {
        const { user, onChangeRole } = this.props;
        const roles = user.roles || {};
        roles[role] = true;
        this.setState({ selectedRole: role, showNote: true });
        onChangeRole({ uid: user.uid, roles });
        this.handleCloseAddRole();
    };

    handleRemoveRole = (e, role) => {
        const { removeContext } = this.state;
        if (!removeContext) {
            this.setState({ removedRole: role, removeContext: e.currentTarget, showNote: true });
        }
    };

    handleRemoveConfirm = () => {
        const { user, onChangeRole } = this.props;
        const { removedRole, selectedRole } = this.state;
        const roles = user.roles || {};
        roles[removedRole] = null;
        onChangeRole({ uid: user.uid, roles });
        if (selectedRole === removedRole) {
            this.setState({ selectedRole: null });
        }
        this.handleCancelRemove();
    };

    handleCancelRemove = () => {
        this.setState({ removeContext: null, removedRole: "" });
    };

    renderAddRoleComponent = (center = false) => {
        const { roleOptions } = this.props;
        return <AddRole roleOptions={roleOptions} onAddRole={this.handleAddRole} centerForm={center} />;
    };

    handleSelectRole = (e, { name }) => {
        this.setState({ selectedRole: name });
    };

    handleOpenAddRole = () => {
        this.setState({ openAddRole: true });
    };

    handleCloseAddRole = () => {
        this.setState({ openAddRole: false });
    };

    render() {
        const { onClose, user, roleOptions, isClaimsUpdating } = this.props;
        const { selectedRole, removeContext, openAddRole } = this.state;
        const isEmpty = !user.roles;
        return (
            <Modal open={true} onClose={onClose} size="tiny">


                <Modal.Content>
                    <UserInfo
                        userName={user.first_name + " " + user.last_name}
                        meta={<React.Fragment><Icon name="envelope" /> {user.email}</React.Fragment>}
                        description={<List>{user.job_titles.map(t => <List.Item key={t.name+t.place_id}>{t.name}</List.Item>)}</List>}
                    />
                    {(isEmpty && !isClaimsUpdating) ? (
                        <NoDataPlaceholder text="Here we list the user access roles." icon="address card outline">
                            <p>The access roles determine what the user can do in the application.</p>
                            {this.renderAddRoleComponent(true)}
                        </NoDataPlaceholder>
                    ) : (
                        <React.Fragment>
                            <Header as="h3">User roles</Header>
                            <Dimmer active={isClaimsUpdating} inverted>
                                <Loader inverted>Updating user claims</Loader>
                            </Dimmer>

                            <ConfirmDeleteWithContext
                                context={removeContext}
                                confirmText="Remove access role"
                                onConfirm={this.handleRemoveConfirm}
                                whatText={"user access role"}
                                onCancel={this.handleCancelRemove}
                            />
                            <List>
                                {Object.keys(user.roles || {}).map(r => {
                                    const active = r === selectedRole;
                                    return (
                                        <List.Item key={r} name={r} onClick={this.handleSelectRole} active={active} className="accessRoleItem">
                                            {!(this.isEditingCurrentUser() && (r === "ROLE_ADMIN" || r === "ROLE_SUPERAUTHOR")) &&
                                                    <ActionIconsContainer floatRight ><RemoveIcon
                                                        id={r}
                                                        entityName="access role"
                                                        onRemove={this.handleRemoveRole}
                                                    />
                                                    </ActionIconsContainer>
                                            }
                                            {active ? (
                                                <List.Header>{roleDefinitions[r].name}</List.Header>
                                            ) : (
                                                <span>{roleDefinitions[r].name}</span>
                                            )}

                                        </List.Item>
                                    );
                                })}
                            </List>
                            {0 < roleOptions.length && (
                                <Popup
                                    open={openAddRole}
                                    onOpen={this.handleOpenAddRole}
                                    onClose={this.handleCloseAddRole}
                                    on="click"
                                    wide="very"
                                    position="bottom left"
                                    trigger={<Button content="Add role" />}
                                >
                                    {this.renderAddRoleComponent()}
                                </Popup>
                            )}
                            {(!this.isEditingCurrentUser() && this.state.showNote) && (
                                <Message warning>
                                    <Message.Header>Note:</Message.Header>
                                    <Message.Content>
                                        If the user is currently logged in, the role change will be effective within an
                                        hour or upon next user login.
                                    </Message.Content>
                                </Message>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
