import { TYPES } from "./UpdateLessonReviewStatsActions";

const initialState = {
    inProgress: false,
    message: null,
};

const reducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case TYPES.CONVERT_WIDGET_DATA_START:
            return { ...state, inProgress: true };
        case TYPES.CONVERT_WIDGET_DATA_FINISHED:
            return { ...state, inProgress: false };
        case TYPES.CONVERT_WIDGET_DATA_MESSAGE:
            return { ...state, message: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
