import { connect } from "react-redux";
import PropTypes from "prop-types";

import AssignTagsTopics from "./AssignTagsTopics";
import { assignTagsTopicsSave } from "./assignTagsTopicsActions";
import { usedTags, getSelectedProblems, restrictToAuthorId } from "../problemManagementSelectors";

const AssignTagsTopicsContainer = connect(
    (state, props) => ({
        tagOptions: usedTags(state, props),
        problems: getSelectedProblems(state, props),
        authorId: restrictToAuthorId(state, props),
    }),
    {
        onSave: assignTagsTopicsSave
    }
)(AssignTagsTopics);

AssignTagsTopicsContainer.displayName = "AssignTagsTopicsContainer";
AssignTagsTopicsContainer.propTypes = {
    fbCollection: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default AssignTagsTopicsContainer;
