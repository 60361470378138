import { all, takeLatest, put, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import { TYPES, onSetSearchResult } from "./LessonLinkActions";
// import { push } from "connected-react-router";
import { getOutlineId } from "./LessonLinkSelectors";
import { outlineManager } from "../Outlines/DataSource";
import { mapCollectionToList, getSearchByNamePredicate, compareByOrdering } from "../../utils/selectorUtils";

function* searchLesson({ payload }) {
    if (0 === payload.length) {
        yield put(onSetSearchResult(null));
        return;
    }
    yield delay(500);
    const outlineId = yield select(getOutlineId);
    if (outlineId) {
        const outline = yield outlineManager.getOutline(outlineId);
        const units = outline.lesson_units;
        const unitList = mapCollectionToList(units).sort(compareByOrdering);
        const lessons = outline.lessons;
        const namePredicate = getSearchByNamePredicate(payload);
        const lessonList = mapCollectionToList(lessons).sort(compareByOrdering).filter(namePredicate);
        const result = {};
        unitList.map(unit => (
            result[unit.key] = {
                name: unit.name,
                results: []
            }
        ));
        lessonList.map(lesson => result[lesson.lesson_unit_id].results.push({ title: lesson.name, lessonid: lesson.key }));
        Object.keys(result).map(unitId => {
            const unit = result[unitId];
            if (0 === unit.results.length) {
                delete result[unitId];
            }
        });
        yield put(onSetSearchResult(result));
    } else {
        yield put(onSetSearchResult(null));
    }
}

export default function* lessonLinkSaga() {
    yield all([
        takeLatest(TYPES.LESSON_LINK_SEARCH_LESSON, searchLesson),
    ]);
}
