import reduxifyServices from "./feathers-redux";
import { initialize, client } from "./client";

/**
 * List of services (from NodeJS server) which are configured to
 * use with local feathersjs. For each service is created redux reducer
 * and actions and setup listeners for real-time changes.
 */
const serviceList = [
    "year",
    "kit",
    "package",
    "package-tenant",
    "user",
    "oauthclient",
];

/**
 * Contains generated actions and reducers for defined services.
 */
let rawServices = null;
export const getServices = () => {
    // feathers client was not initialized yet - initialize with default socket
    if (!client.defaultService) {
        initialize();
    }
    if (!rawServices) {
        rawServices = reduxifyServices(client, serviceList);
    }
    return rawServices;
};

// Define real-time message update handlers for services
const setupListeners = (dispatch, serviceName) => {
    const service = client.service(serviceName);
    service.on("created", data => {
        dispatch(rawServices[serviceName].onCreated(data));
    });
    service.on("updated", data => {
        dispatch(rawServices[serviceName].onUpdated(data));
    });
    service.on("patched", data => {
        dispatch(rawServices[serviceName].onPatched(data));
    });
    service.on("removed", data => {
        dispatch(rawServices[serviceName].onRemoved(data));
    });
};

export const configureListeners = dispatch => {
    serviceList.forEach(service => setupListeners(dispatch, service));
};

export default getServices();
