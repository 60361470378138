import { TYPES } from "./SectionListItemActions";

const initialState = {
    sections: {},
    selectedSectionId: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case TYPES.SECTION_LIST_SET_SECTION:
            return { ...state, sections: {...state.sections, [payload.id]: payload } };
        case TYPES.SECTION_LIST_SELECT_SECTION:
            return { ...state, selectedSectionId: payload };
        default:
            return state;
    }
};
