import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import PageHeader from "../../component/PageHeader";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import { List, Menu, Checkbox, Icon, Popup } from "semantic-ui-react";
import AccessControl from "../../auth/AccessControl";
import CreateTemplateDialog from "./component/CreateTemplateDialog";
import EntityModuleList from "../../component/EntityModuleList";
import AdvancedFilter from "../../KitBuilder/component/AdvancedFilter";
import FilteredByOptionsView from "../../KitBuilder/component/FilteredByOptionsView";
import { TemplateiPlannersCount, TemplateiPlannersPopup } from "./component/TemplateiPlanners";

export default class SiPiTemplatesList extends PureComponent {
  static propTypes = {
      sipiTemplates: PropTypes.object,
      searchFilter: PropTypes.string,
      onChangeFilter: PropTypes.func,
      onSelectSiPiTemplate: PropTypes.func,
      selectedYear: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
      }),
      courseVariants: PropTypes.object,
      onAllCourseVariantLoad: PropTypes.func,
      selectedYearId: PropTypes.number,
      cvIsLoading: PropTypes.bool,
      moduleFilter: PropTypes.array,
      onSetModuleFilter: PropTypes.func,
  };
  
  componentDidMount() {
      this.props.onAllCourseVariantLoad();
  }
  componentDidUpdate(prevProps) {
      if (prevProps.selectedYearId !== this.props.selectedYearId) {
          this.props.onAllCourseVariantLoad();
      }
  }

  state = {
      showOnlyMyTemplates: false,
      isCreatorOpen: false,
      viewUsedBy: null,
  }
  
    handleShowUsedBy = (e, { templateId, usedBy }) => {
        e.stopPropagation();
        if (this.state.viewUsedBy) {
            this.setState({ viewUsedBy: null });
            return;
        }

        const context = e.target || e.currentTarget;
        if (context && usedBy && Object.keys(usedBy).length) {
            const viewUsedBy = { context, usedBy, templateId };
            this.setState({ viewUsedBy });
        }
    }

    handleHideUsedBy = () => {
        if (this.state.viewUsedBy) {
            this.setState({ viewUsedBy: null });
            return;
        }
    }

  handleOnClickListItem = (e, { id }) => {
      const { onSelectSiPiTemplate } = this.props;
      onSelectSiPiTemplate({ id });
  }

  handleToggle = (e, { checked }) => this.setState({ showOnlyMyTemplates: checked });

  handleOpenCreator = () => this.setState({ isCreatorOpen: true });
  handleCloseCreator = () => this.setState({ isCreatorOpen: false });

  render() {
      const { sipiTemplates, searchFilter, onChangeFilter, selectedYear, courseVariants, cvIsLoading, onSetModuleFilter, moduleFilter } = this.props;
      const { showOnlyMyTemplates, isCreatorOpen, viewUsedBy } = this.state;
      let list = [];
      if (sipiTemplates) {
          if (showOnlyMyTemplates) {
              list = sipiTemplates.myTemplates;
          } else {
              list = sipiTemplates.allTemplates;
          }
      }
      let isListFiltered = searchFilter != "" || showOnlyMyTemplates;

      return (
          <React.Fragment>
              <PageHeader
                  searchFilter={searchFilter}
                  headerText="iPlanner Templates"
                  entityName="template"
                  list={list}
                  {...selectedYear && { subHeaderText: `for ${selectedYear.name}`}}
                  onChangeFilter={onChangeFilter}
              >
                  <AdvancedFilter
                      entityName="iPlanner template"
                      onSetModuleFilter={onSetModuleFilter}
                      moduleFilter={moduleFilter}
                      singleModule
                  />
                  <AccessControl action="sipiTemplates:create" >
                      <Menu.Item icon="plus" content="New" onClick={this.handleOpenCreator} title="Add new iPlanner Template"/>
                  </AccessControl>
                  <Menu.Item style={{ padding: 0}} position="right"><Checkbox onChange={this.handleToggle} label="Show only my templates" toggle /></Menu.Item>
              </PageHeader>
              {moduleFilter && 0 < moduleFilter.length && 
                    <FilteredByOptionsView
                        onSetModuleFilter={onSetModuleFilter}
                        moduleFilter={moduleFilter}
                    />
              }
              {isCreatorOpen && <CreateTemplateDialog onClose={this.handleCloseCreator} /> }
              {viewUsedBy && <TemplateiPlannersPopup {...viewUsedBy} onClose={this.handleHideUsedBy} />}
              {list.length == 0 ? (isListFiltered ? 
                  <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
                      <p>
                  We cannot find any iPlanner Template matching your filtering criteria.{" "}
                      </p>
                  </NoDataPlaceholder> 
                  : <NoDataPlaceholder
                      text="Here we list iPlanner Templates" 
                      icon="newspaper outline"
                  >
                      <p>
                      The template can be created for Courses in selected school year.
                      </p>
                  </NoDataPlaceholder>
              ) : (

                  <List selection >
                      {list.map(({key, value}) => (
                          <List.Item  id={key} key={key} onClick={this.handleOnClickListItem} >
                              <List.Icon className="drafting compass sporkPrimaryColorCharcoalGray"  />
                              <List.Content className="editable">
                                  <List.Header as="a" title={value.name}>
                                      {value.name}
                                      {value.m && <EntityModuleList rightFloated list={Object.keys(value.m)} />}
                                  </List.Header>
                                  {(courseVariants || value.used) && (
                                      <List.Description >
                                          {courseVariants && courseVariants[value.course_variant_id] ||
                                           cvIsLoading && <Icon title="Loading Courses" loading name="circle notched" />}
                                          {value.used && <TemplateiPlannersCount templateId={key} usedBy={value.used} onClick={this.handleShowUsedBy} />}
                                      </List.Description>
                                  )}
                              </List.Content>
                          </List.Item>
                      ))}
                  </List>
              )}
              {/* Display only for Charter and Spring schools - until they stop using BeLA */}
              {( selectedYear && (selectedYear.tenant_id === 1 || selectedYear.tenant_id === 2) ) && <Popup 
                  inverted
                  wide="very"
                  trigger={<span style={{fontSize: "smaller", fontStyle: "italic", color: "grey"}}>Can&apos;t find your iPlanner Template here?</span>}
              >
                  Give it few more minutes. The synchronization of iPlanner Template from BeLA could take up to 60 minutes. Also make sure the iPlanner Template has &quot;Ready for Cloning&quot; status and &quot;Ready for iPlanner&quot; option enabled.
              </Popup>
              }
          </React.Fragment>
      );
  }
}
