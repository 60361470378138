import { createSelector } from "reselect";
//import { mapCollectionToList } from "../../utils/selectorUtils";
//import { getSelectedYearId } from "../../Year/YearsSelectors";

export const getSchools = state => state.external.school.schoolList;
export const isLoading = state => state.external.school.isLoading;
export const getSchool = state => state.external.school.school;

export const getSchoolAsOptions = createSelector(
    getSchools,
    schools => {
        if (schools === undefined) return schools;
        const options = [{ key: "NULL", text: "Not assigned", value: "NULL" }];
        return options.concat(schools.map(school => ({ key: school.id, text: school.name, value: school.id })));
    }
);

export const getSchoolOnlyAsOptions = createSelector(
    getSchools,
    schools => {
        if (schools === undefined) return schools;
        const options = [];
        return options.concat(schools.map(school => ({ key: school.id, text: school.name, value: school.id })));
    }
);

export const getSchoolsById = createSelector(
    getSchools,
    (schools) => {
        const data = {};
        if (schools) {
            schools.map(s => data[s.id] = s);
        }
        return data;
    }
);
