import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Dimmer, Loader, Form, Segment, Pagination, List, Tab, Divider, Header } from "semantic-ui-react";
import ResourceLink from "../ResourceLink/ResourceLink";
import { ResourceLinkIcon } from "../ResourceLink/ResourceLink";
import LinkLabel from "../../Resources/ResourceDetail/ResourceLink";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import "./AdvancedResourceSearch.scss";
import TopicTreeSelector from "../../../TopicCollection/TopicTreeSelector";
import memoizeOne from "memoize-one";
import { FilterByView } from "../../Resources/FilterBy";
import { denormalizeTopicKey } from "../../../utils/selectorUtils";

export default class AdvancedResourceSearch extends PureComponent {
    static propTypes = {
        isSearching: PropTypes.bool,
        isOpen: PropTypes.bool,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        onLoadUsedTags: PropTypes.func,
        tagsOptions: PropTypes.array,
        isLoadingTags: PropTypes.bool,
        onAdvancedSearch: PropTypes.func,
        result: PropTypes.array,
        onResultSelect: PropTypes.func,
        onFetchUsedCourseVariants: PropTypes.func,
        courseVariantOptions: PropTypes.array,
        isLoadingCourseVariants: PropTypes.bool,
        onLoadResourceTypes: PropTypes.func,
        isLoadingResourceTypes: PropTypes.bool,
        resourceTypesOptions: PropTypes.array,
        trigger: PropTypes.node,
        excludeInResults: PropTypes.object,
        onFetchOwners: PropTypes.func,
        isLoadingOwners: PropTypes.bool,
        ownersOptions: PropTypes.array,
        firebase: PropTypes.object,
        topicsData: PropTypes.object,
    };

    state = {
        tags: [],
        name: "",
        currentPage: 1,
        selectedResourceId: null,
        courseVariantId: null,
        resourceType: null,
        userId: null,
        selectedTopic: null,
        owner: null,
        topicFilterName: null,
    };

    handleSelectTopic = topicId => {
        const { selectedTopic } = this.state;
        const { topicsData } = this.props;
        let topicFilterName = null;
        if (topicsData && topicId) {
            const  [collection, topic] = denormalizeTopicKey(topicId);
            if (topicsData[collection] && topicsData[collection][topic]) {
                topicFilterName = topicsData[collection][topic].name;
            }
        }
        this.setState({ selectedTopic: topicId !== selectedTopic ? topicId : null, topicFilterName });
    }
    handleOpen = () => {
        const { onOpen, onLoadUsedTags, onFetchUsedCourseVariants, onLoadResourceTypes, onFetchOwners, firebase } = this.props;
        onLoadUsedTags();
        onFetchUsedCourseVariants();
        onLoadResourceTypes();
        onFetchOwners();
        onOpen();
        this.setState({ selectedResourceId: null });
        firebase.watchEvent("value", "/topic_collections/list");
        firebase.watchEvent("value", "/topic_collections/data");
    };

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };

    handleChangeOwner = (e, data) => {
        if (data) {
            const { value } = data;
            const selected = (value && this.props.ownersOptions.find(item => item.value === value));
            const owner = selected ? { id: selected.value, name: selected.text } : null;
            this.setState({ owner, userId: value });
        } else {
            this.setState({ owner: null, userId: null });
        }
    }
    handleChangeVariant = (e, data) => {
        if (data) {
            const { value } = data;
            const selected = (value && this.props.courseVariantOptions.find(item => item.value === value));
            const courseVariant = selected ? { id: selected.value, name: selected.text } : null;
            this.setState({ courseVariant, courseVariantId: value });
        } else {
            this.setState({ courseVariant: null, courseVariantId: null });
        }
    }

    handleSearch = e => {
        const { onAdvancedSearch } = this.props;
        const { name, resourceType, tags, courseVariantId, userId, selectedTopic } = this.state;
        e.preventDefault();
        e.stopPropagation();
        this.setState({ currentPage: 1, selectedResourceId: null });
        onAdvancedSearch({ name, resourceType, tags, courseVariantId, userId, topic: selectedTopic });
    };

    handlePageChangeSearchResults = (e, { activePage }) => {
        this.setState({ currentPage: activePage });
    };

    handleAssign = (e, {id}) => {
        const { onClose, onResultSelect } = this.props;
        onResultSelect(e, { result: { resource_id: id } });
        onClose();
    };

    handleSelect = (e, { id }) => this.setState({ selectedResourceId: id });
    handleRemoveTag = (tags) => this.setState({ tags });
    handleCleanName = () => this.setState({ name: "" });
    handleCleanType = () => this.setState({ resourceType: "" });

    generatePanes = memoizeOne((ownersOptions, courseVariantOptions, resourceTypesOptions, tagsOptions, userId,
        courseVariantId, resourceType, tags, name, selectedTopic) => {
        const { isLoadingOwners, isLoadingResourceTypes, isLoadingTags, isLoadingCourseVariants } = this.props;
        return [
            {
                menuItem: "General",
                pane: (
                    <Tab.Pane key="generalPane">
                        <Form onSubmit={this.handleSearch} autoComplete="off">
                            <Form.Input
                                label="Name"
                                autoFocus
                                name="name"
                                value={name}
                                placeholder="Name of the resource for search..."
                                onChange={this.handleChange}
                            />
                            <Form.Group>
                                <Form.Dropdown
                                    width="8"
                                    options={ownersOptions}
                                    selection
                                    fluid
                                    search
                                    loading={isLoadingOwners}
                                    label="Owner"
                                    name="userId"
                                    clearable
                                    placeholder="Select an owner... "
                                    onChange={this.handleChangeOwner}
                                    value={userId}
                                    closeOnChange
                                />
                                <Form.Dropdown
                                    width="8"
                                    options={resourceTypesOptions}
                                    selection
                                    fluid
                                    search
                                    loading={isLoadingResourceTypes}
                                    label="Resource Type"
                                    name="resourceType"
                                    clearable
                                    placeholder="Select a resource type... "
                                    onChange={this.handleChange}
                                    value={resourceType}
                                    closeOnChange
                                />
                            </Form.Group>
                            <Form.Dropdown
                                options={courseVariantOptions}
                                selection
                                fluid
                                search
                                loading={isLoadingCourseVariants}
                                label="Course"
                                name="courseVariantId"
                                clearable
                                placeholder="Select a Course... "
                                onChange={this.handleChangeVariant}
                                value={courseVariantId}
                                closeOnChange
                            />
                            <Form.Dropdown
                                options={tagsOptions}
                                multiple
                                selection
                                fluid
                                search
                                lazyLoad
                                loading={isLoadingTags}
                                label="Tags"
                                name="tags"
                                clearable
                                placeholder="Select tag(s)..."
                                onChange={this.handleChange}
                                value={tags}
                                closeOnChange
                            />
                        </Form>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Topics",
                pane: (
                    <Tab.Pane key="topicPane" >
                        <TopicTreeSelector single onChange={this.handleSelectTopic} selectedTopics={selectedTopic} />
                    </Tab.Pane>
                )
            }
        ];
    })

    render() {
        const {
            isSearching,
            isOpen,
            onClose,
            tagsOptions,
            result,
            courseVariantOptions,
            resourceTypesOptions,
            trigger,
            excludeInResults,
            ownersOptions,
        } = this.props;
        const { resourceType, tags, name, currentPage, selectedResourceId, courseVariantId, userId, selectedTopic, owner, courseVariant, topicFilterName } = this.state;
        const filteredResult = result && result.filter(i => !excludeInResults || !excludeInResults[i.id]);
        const showSearchParams = name || owner || courseVariant || resourceType || selectedTopic || tags.length ? true : false;
        return (
            <Modal
                closeOnDimmerClick={false}
                trigger={trigger || <Button content="Find Existing" />}
                onOpen={this.handleOpen}
                onClose={onClose}
                open={isOpen}
                size="large"
            >
                <Modal.Header>Advanced Resource Search</Modal.Header>
                <Modal.Content style={{ minHeight: "840px" }}>
                    <Dimmer.Dimmable>
                        <Dimmer active={isSearching} inverted>
                            <Loader inverted>Searching resources...</Loader>
                        </Dimmer>
                        <Tab
                            renderActiveOnly={false}
                            panes={this.generatePanes(
                                ownersOptions,
                                courseVariantOptions,
                                resourceTypesOptions,
                                tagsOptions,
                                userId,
                                courseVariantId,
                                resourceType,
                                tags,
                                name,
                                selectedTopic,
                            )}
                            style={{marginBottom: "1rem", height: "415px"}}
                        />
                        { showSearchParams ? <FilterByView
                            header="SEARCH BY"
                            filterParams={{
                                tags,
                                name,
                                owner,
                                courseVariant,
                                resourceType,
                                topic: selectedTopic,
                            }}
                            topicFilterName={topicFilterName}
                            onFilterByTags={this.handleRemoveTag}
                            onCleanNameFilter={this.handleCleanName}
                            onFilterByResourceType={this.handleCleanType}
                            onFilterByCourseVariant={this.handleChangeVariant}
                            onFilterByOwner={this.handleChangeOwner}
                            onFilterByTopic={this.handleSelectTopic}
                        /> : <div style={{height: "43px"}}/>}
                        <Divider hidden fitted clearing />
                        <Button
                            style={{ textAlign: "center" }}
                            color="green"
                            icon="search"
                            content="Search"
                            onClick={this.handleSearch}
                        />
                        {result ? (0 < filteredResult.length ? <SearchResults 
                            results={filteredResult} 
                            currentPage={currentPage} 
                            selectedResourceId={selectedResourceId} 
                            onSelect={this.handleSelect}
                            onAssign={this.handleAssign}
                        /> : <NoSearchResults />) 
                            : <SearchResultPlaceholder />
                        }
                        {filteredResult && 5 < filteredResult.length && (
                            <Pagination
                                floated="right"
                                onPageChange={this.handlePageChangeSearchResults}
                                defaultActivePage={currentPage || 1}
                                totalPages={Math.ceil(filteredResult.length / 5)}
                            />
                        )}
                        <Divider clearing fitted hidden />
                    </Dimmer.Dimmable>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const SearchResults = ({results, currentPage, selectedResourceId, onSelect, onAssign}) => {
    return <React.Fragment>
        <Header attached="top">Search results ({results.length})</Header>
        <Segment attached style={{ maxHeight: "300px" }}>
            <List divided>
                {results.map((resource, i) => {
                    const limit = (currentPage - 1) * 4;
                    if (i >= limit && i < limit + 4) {
                        return <SearchResultLine key={resource.id} resource={resource} selectedResourceId={selectedResourceId} onSelect={onSelect} onAssign={onAssign} />;
                    }
                })}
            </List>
        </Segment>
    </React.Fragment>;
};

SearchResults.propTypes = {
    results: PropTypes.array,
    currentPage: PropTypes.number,
    selectedResourceId: PropTypes.number,
    onSelect: PropTypes.func,
    onAssign: PropTypes.func,
};

const SearchResultLine = ({resource, selectedResourceId, onSelect, onAssign}) => {
    return <List.Item
        key={resource.id}
        id={resource.id}
        onClick={onSelect}
        active={selectedResourceId === resource.id}
        className="advanced search result"
    >
        <ResourceLinkIcon resource={resource} size="big"/>
        <List.Content>
            <div>
                <List.Header >
                    <ResourceLink
                        sipi={{ resource_id: resource.id }}
                        resource={resource}
                        noIcon
                    />
                    <span style={{fontWeight: "normal", marginLeft: "1em"}}>(<LinkLabel short type={ resource.resourceType } href={resource.href} />)</span>
                </List.Header>
                <List.Description
                    title={resource.description}
                    className="limitedTextWidth"
                >{resource.description}</List.Description>
            </div>
            <Button
                id={resource.id}
                onClick={onAssign}
                primary compact
                size="small"
                title="Use this resource"
                content="Use this resource"
            />
        </List.Content>

    </List.Item>
    ;
};

SearchResultLine.propTypes = {
    resource: PropTypes.object,
    selectedResourceId: PropTypes.number,
    onSelect: PropTypes.func,
    onAssign: PropTypes.func,
};

const NoSearchResults = () => <NoDataPlaceholder
    style={{ height: "250px" }}
    icon="search"
    text="Oh, snap. No result."
>
    <p>We cannot find any resources matching your criteria.</p>
</NoDataPlaceholder>;

const SearchResultPlaceholder = () => <NoDataPlaceholder
    style={{ height: "250px" }}
    icon="search"
    text="Resources matching the criteria will be listed here."
>
    <p>Enter your search criteria and let&apos;s start searching.</p>
</NoDataPlaceholder>;
