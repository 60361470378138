import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Label } from "semantic-ui-react";
import { Logo } from "../component/logo";
import { VersionNumber, BuildNumber } from "../Version";
import { SidebarMenu as LeftSideBarMenu } from "bmd-react";
/* import { SideBarMenuLink } from "../SidebarMenuLink"; */

import { CurriculumMenu } from "./Curriculum";
import { SidebarMenu as TopicsMenu } from "../Topics";
import { SidebarMenu as SchoolInfoMenu } from "../External";
import { SidebarMenu as TaskToolMenu } from "../QuestionUtilizator";
import { SidebarMenu as UsersMenu } from "../Users";
import { SidebarMenu as LUPMenu } from "../LessonUnitPlanner";
import { MaintenanceMenu } from "./Maintenance";
import { SidebarMenu as TabletsMenu } from "../Tablet";
import { SidebarMenu as AdminMenu } from "../Maintenance";

import "./SidebarMenu.scss";
import EnvLabel from "../component/EnvLabel";
import AccessControl from "../auth";

export default class SidebarMenu extends Component {
    static propTypes = {
        activeIndex: PropTypes.object,
        onMenuClick: PropTypes.func.isRequired,
    };

    handleMenuClick = (e, titleProps) => {
        const { index, active } = titleProps;

        let activeIndex = { ...this.props.activeIndex };
        const { onMenuClick } = this.props;
        activeIndex[index] = active ? false : true;
        onMenuClick(activeIndex);
    };

    render() {
        const { activeIndex } = this.props;
        return (
            <LeftSideBarMenu inverted={false}>
                <EnvLabel style={{ display: "table", marginTop: "-2.6em" }} />

                <Menu.Item style={{ padding: 0 }}>
                    <Logo className="sporkMenuLogo" />
                </Menu.Item>

                {/* {process.env.NODE_ENV !== "production" && ( // eslint-disable-line
                    <React.Fragment>
                        <SideBarMenuLink to="/dashboard" text="Dashboard" />
                    </React.Fragment>
                )} */}
                <CurriculumMenu onClick={this.handleMenuClick} activeIndex={activeIndex} />
                <TaskToolMenu />
                <LUPMenu />
                <SchoolInfoMenu onClick={this.handleMenuClick} activeIndex={activeIndex} />
                <UsersMenu />
                <AccessControl action="ttt:manage" >
                    <TopicsMenu onClick={this.handleMenuClick} activeIndex={activeIndex} />
                </AccessControl>

                <MaintenanceMenu />
                <TabletsMenu />
                {process.env.NODE_ENV !== "production" && ( // eslint-disable-line
                    <AdminMenu onClick={this.handleMenuClick} activeIndex={activeIndex} />
                )}

                <Label className="appVersion">
                    v{VersionNumber}#{BuildNumber}
                </Label>
            </LeftSideBarMenu>
        );
    }
}
