import { all, takeLatest } from "redux-saga/effects";
import { TYPES } from "./topicSelectorActions";

function storeSelectedCollection({ payload }) {
    localStorage.setItem("selectedCollection", payload);
}

export default function* YearsSaga() {
    yield all([
        takeLatest(TYPES.TS_SELECT_COLLECTION, storeSelectedCollection),
    ]);
}
