import React from "react";
import PropTypes from "prop-types";
import LessonStatus from "./LessonStatus";
import { PublishedStatusProps } from "../Publisher";
import LessonContentMenu from "./LessonContentMenu";
import { confirmUnsave, UserRightsPropType } from "./utils";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import LessonComments from "./LessonComments";
import LessonContentEditor from "./LessonContentEditor";
import LessonContentViewer from "./LessonContentViewer";
import LessonObjective from "./components/LessonObjective";
import LessonBreadcrumb from "./components/LessonBreadcrumb";
import LessonLastEditor from "./LessonLastEditor";

const hasUserOrRoleChanged = (authUser, prevUser) => {
    if (!prevUser || prevUser.uid !== authUser.uid) {
        return true;
    }
    if (authUser === prevUser || authUser.roles === prevUser.roles) {
        return false;
    }

    const authRoles = authUser.roles ? authUser.roles.sort().join(":") : "";
    const prevRoles = prevUser.roles ? prevUser.roles.sort().join(":") : "";

    return authRoles !== prevRoles;
};

const hasReadOnlyAccess = userRights => !userRights || !(userRights.canEdit || userRights.canReview);

export default class LessonContent extends React.PureComponent {
    static propTypes = {
        selectedLesson: PropTypes.object,
        lessonId: PropTypes.string,
        outlineId: PropTypes.string,
        reviewsCount: PropTypes.object,
        onGoBack: PropTypes.func.isRequired,
        userRights: UserRightsPropType,
        onCheckUserRights: PropTypes.func.isRequired,
        onSetFilterByKit: PropTypes.func.isRequired,
        query: PropTypes.object,
        publishedStatus: PublishedStatusProps,
        onSetGlossaryPreferencesByOutline: PropTypes.func,
        onSetOutlineForLessonLinks: PropTypes.func,
        reviews: PropTypes.object,
        tabIndex: PropTypes.string,
        onTabChange: PropTypes.func,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
        authUser: PropTypes.object,
        commentRecipients: PropTypes.array,
        outlineName: PropTypes.string,
        onLessonUpdateDescription: PropTypes.func,
        onLessonUpdateName: PropTypes.func,
        onGoToAuthorKits: PropTypes.func,
    };

    handleTabChange = (e, { name }) => {
        const { tabIndex, onTabChange, isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you switch to another tab", onSetDirty)) {
            if (tabIndex !== name) {
                onTabChange(name);
            }
        }
    };

    handleGoBack = () => {
        const { onGoBack, outlineId, isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you go back", onSetDirty)) {
            onGoBack({ outlineId });
        }
    };

    handleGoToAuthorKits = () => {
        const { onGoToAuthorKits, isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you go back", onSetDirty)) {
            onGoToAuthorKits();
        }
    };

    handleToolbarRerender = () => {

        let event = new Event("rerender");
        setTimeout(() => requestAnimationFrame(() => window.dispatchEvent(event)));
    }

    handleObjectiveUpdate = (objective) => {
        this.props.onLessonUpdateDescription(this.props.outlineId, this.props.lessonId, objective);
    }
    handleLessonNameUpdate = (name) => {
        this.props.onLessonUpdateName(this.props.outlineId, this.props.lessonId, name);
    }

    componentDidMount() {
        const {
            outlineId,
            query,
            authUser,
            onCheckUserRights,
            onSetFilterByKit,
            onSetGlossaryPreferencesByOutline,
            onSetOutlineForLessonLinks,
            onTabChange,
        } = this.props;
        onTabChange((query && query.tab) || "compose");
        if (authUser.uid !== undefined) {
            onCheckUserRights(outlineId);
        }
        onSetFilterByKit(outlineId);
        onSetGlossaryPreferencesByOutline(outlineId);
        onSetOutlineForLessonLinks(outlineId);
    }

    componentDidUpdate(prevProps) {
        const { authUser, userRights, publishedStatus } = this.props;
        if (authUser && hasUserOrRoleChanged(authUser, prevProps && prevProps.authUser)) {
            this.props.onCheckUserRights(this.props.outlineId);
        }
        if (prevProps.userRights && userRights !== prevProps.userRights) {
            const currReadOnly = hasReadOnlyAccess(userRights);
            const prevReadOnly = hasReadOnlyAccess(prevProps.userRights);
            if (currReadOnly !== prevReadOnly && this.props.onTabChange) {
                this.props.onTabChange(currReadOnly ? "content" : "compose");
            }
        }
        if (prevProps.publishedStatus !== publishedStatus) {
            this.handleToolbarRerender();
        }
    }

    render() {
        if (!this.props.selectedLesson) {
            return (
                this.props.selectedLesson === null && (
                    <NoDataPlaceholder
                        style={{ minHeight: "150px", height: "150px" }}
                        icon="file outline"
                        text="No lesson content found"
                    >
                        <p>Requested page does not exist</p>
                    </NoDataPlaceholder>
                )
            );
        }

        const {
            outlineId,
            outlineName,
            selectedLesson,
            lessonId,
            userRights,
            publishedStatus,
            reviewsCount,
            reviews,
            tabIndex,
            isDirty,
            onSetDirty,
        } = this.props;
        const isReadOnly = hasReadOnlyAccess(userRights);

        return (
            <div style={{ cursor: "default" }} key={outlineId}>
                <div style={{float: "right", textAlign: "right"}}>
                    <LessonLastEditor outlineId={outlineId} lessonId={lessonId} />
                </div>
                <LessonBreadcrumb 
                    lessonName={selectedLesson.name} 
                    kitName={outlineName} 
                    onGoBack={this.handleGoBack} 
                    onClick={this.handleToolbarRerender} 
                    canEdit={!isReadOnly}
                    onUpdate={this.handleLessonNameUpdate}
                    onGoToAuthorKits={this.handleGoToAuthorKits}
                />
                <LessonStatus
                    showOnlyReleased={isReadOnly}
                    lessonId={lessonId}
                    outlineId={outlineId}
                    publishedStatus={publishedStatus}
                />
                {(selectedLesson.description || !isReadOnly) && <LessonObjective 
                    objectiveText={selectedLesson.description} 
                    onClick={this.handleToolbarRerender} 
                    canEdit={!isReadOnly}
                    onUpdate={this.handleObjectiveUpdate}
                />}

                <LessonContentMenu
                    outlineId={outlineId}
                    lessonId={lessonId}
                    activeView={tabIndex}
                    onChangeView={this.handleTabChange}
                    userRights={userRights}
                    reviewsCount={reviewsCount}
                />
                {isReadOnly ? (
                    <LessonContentViewer
                        activeView={tabIndex}
                        lessonId={lessonId}
                        outlineId={outlineId}
                        outlineName={outlineName}
                        lessonName={selectedLesson.name}
                        release={publishedStatus && publishedStatus.release}
                    />
                ) : (
                    <LessonContentEditor
                        activeView={tabIndex}
                        lessonId={lessonId}
                        outlineId={outlineId}
                        outlineName={outlineName}
                        selectedLesson={selectedLesson}
                        userRights={userRights}
                        reviews={reviews}
                        isDirty={isDirty}
                        onSetDirty={onSetDirty}
                    />
                )}
                <LessonComments
                    lessonId={lessonId}
                    outlineId={outlineId}
                    outlineName={outlineName}
                    lessonName={selectedLesson.name}
                    notifyUsers={this.props.commentRecipients}
                    teacherOnly={isReadOnly}
                />
            </div>
        );
    }
}


