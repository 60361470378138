import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { DraggableWrapper } from "../Dnd";
import LessonUnit from "./LessonUnit";
import LessonUnitContainer from "./LessonUnitContainer";

export default class LessonUnitDraggable extends PureComponent {
    static propTypes = {
        lessonUnitData: LessonUnit.propTypes.lessonUnitData,
        index: PropTypes.number,
        canEdit: PropTypes.bool,
    };

    render() {
        const { lessonUnitData: lessonUnit, index, canEdit } = this.props;
        return canEdit ? (<Draggable key={lessonUnit.key} draggableId={lessonUnit.key} index={index}>
            {provided => (<DraggableWrapper provided={provided} innerRef={provided.innerRef}>
                <LessonUnitCont
                    lessonUnit={lessonUnit}
                    canEdit={canEdit}
                    provided={provided}
                    // collapsible     // uncomment this to make LessonUnit collapsible
                />
            </DraggableWrapper>)}
        </Draggable>) : (
            <LessonUnitCont
                lessonUnit={lessonUnit}
                canEdit={canEdit}
                provided={{}}
                // collapsible     // uncomment this to make LessonUnit collapsible
            />
        );
    }
}


const LessonUnitCont = ({lessonUnit, canEdit, provided}) => (
    <LessonUnitContainer
        lessonUnitData={lessonUnit}
        canEdit={canEdit}
        dragHandle={<Icon
            name="bars"
            color="grey"
            {...provided.dragHandleProps}
            title="Change order"
            style={{ cursor: "move", marginLeft: "-1.2em", marginRight: "0.5em" }}
            className="actionIconClass"
        />}
        // collapsible     // uncomment this to make LessonUnit collapsible
    />
);

LessonUnitCont.propTypes = {
    canEdit: PropTypes.bool,
    lessonUnit: PropTypes.object,
    provided: PropTypes.object,
};