import { TYPES } from "./LessonProblemSetActions";

const initialState = {
    selectedProblemSetId: "",
    titleFilter: "",
    isChecking: false,
    checkResult: null,
    isShuffleFinished: false,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.LESSON_PROBLEM_SET_SELECT_PROBLEM_SET:
            return { ...state, selectedProblemSetId: payload };
        case TYPES.LESSON_PROBLEM_SET_SET_TITLE_FILTER:
            return { ...state, titleFilter: payload };
        case TYPES.LESSON_PROBLEM_SET_CHECK_PROBLEM_SET:
            return { ...state, isChecking: true, checkResult: null };
        case TYPES.LESSON_PROBLEM_SET_CHECKED_PROBLEM_SET:
            return { ...state, isChecking: false, checkResult: payload };
        case TYPES.LESSON_PROBLEM_SET_SHUFFLE:
            return { ...state, isShuffleFinished: true };
        case TYPES.LESSON_PROBLEM_SET_SHUFFLE_FINISHED:
            return { ...state, isShuffleFinished: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
