const TYPES = {
    LESSON_CONTENT_UPDATE_WIDGETS: "Authoring/LessonContent/UPDATE_WIDGETS",
    LESSON_CONTENT_ADD_NEW_WIDGET: "Authoring/LessonContent/ADD_NEW_WIDGET",
    LESSON_CONTENT_ADD_EXISTING_WIDGET: "Authoring/LessonContent/ADD_EXISTING_WIDGET",
    LESSON_CONTENT_SET_SEARCH_FILTER: "Authoring/LessonContent/SET_SEARCH_FILTER",
    LESSON_CONTENT_SET_TOPIC_FILTER: "Authoring/LessonContent/SET_TOPIC_FILTER",
    LESSON_CONTENT_GO_BACK: "Authoring/LessonContent/GO_BACK",
    LESSON_CONTENT_CHECK_USER_RIGHTS: "Authoring/LessonContent/CHECK_USER_RIGHTS",
    LESSON_CONTENT_STORE_USER_RIGHTS: "Authoring/LessonContent/STORE_USER_RIGHTS",
    LESSON_CONTENT_SET_FILTER_BY_KIT: "Authoring/LessonContent/SET_FILTER_BY_KIT",
    LESSON_CONTENT_CLONE_TO_NEW_WIDGET: "Authoring/LessonContent/CLONE_TO_NEW_WIDGET",
    LESSON_CONTENT_TAB_CHANGE: "Authoring/LessonContent/TAB_CHANGE",
    LESSON_CONTENT_SET_DIRTY: "Authoring/LessonContent/SET_DIRTY",
    LESSON_CONTENT_SET_FOCUS: "Authoring/LessonContent/SET_FOCUS",
    LESSON_CONTENT_GO_TO_AUTHOR_KITS: "Authoring/LessonContent/GO_TO_AUTHOR_KITS",
};

const onUpdateWidgets = (payload) => ({
    type: TYPES.LESSON_CONTENT_UPDATE_WIDGETS,
    payload
});

const onAddNewWidget = (payload) => ({
    type: TYPES.LESSON_CONTENT_ADD_NEW_WIDGET,
    payload
});

const onAddExistingWidget = (payload) => ({
    type: TYPES.LESSON_CONTENT_ADD_EXISTING_WIDGET,
    payload
});

const onSetSearchFilter = (payload) => ({
    type: TYPES.LESSON_CONTENT_SET_SEARCH_FILTER,
    payload
});

const onSetTopicFilter = (payload) => ({
    type: TYPES.LESSON_CONTENT_SET_TOPIC_FILTER,
    payload
});

const onGoBack = (payload) => ({
    type: TYPES.LESSON_CONTENT_GO_BACK,
    payload
});

const onGoToAuthorKits = (payload) => ({
    type: TYPES.LESSON_CONTENT_GO_TO_AUTHOR_KITS,
    payload
});

const onCheckUserRights = (outlineId) => ({
    type: TYPES.LESSON_CONTENT_CHECK_USER_RIGHTS,
    payload: outlineId,
});

const onStoreUserRights = (userRights) => ({
    type: TYPES.LESSON_CONTENT_STORE_USER_RIGHTS,
    payload: userRights,
});

const onSetFilterByKit = (payload) => ({
    type: TYPES.LESSON_CONTENT_SET_FILTER_BY_KIT,
    payload
});

const onCloneToNewWidget = (payload) => ({
    type: TYPES.LESSON_CONTENT_CLONE_TO_NEW_WIDGET,
    payload
});

const onTabChange = (payload) => ({
    type: TYPES.LESSON_CONTENT_TAB_CHANGE,
    payload
});

const onSetDirty = (payload) => ({
    type: TYPES.LESSON_CONTENT_SET_DIRTY,
    payload
});

const onSetFocus = (widgetId, scroll) => ({
    type: TYPES.LESSON_CONTENT_SET_FOCUS,
    payload: {widgetId, scroll}
});

export {
    TYPES,
    onUpdateWidgets,
    onAddNewWidget,
    onAddExistingWidget,
    onSetSearchFilter,
    onSetTopicFilter,
    onGoBack,
    onSetFilterByKit,
    onCheckUserRights,
    onStoreUserRights,
    onCloneToNewWidget,
    onTabChange,
    onSetDirty,
    onSetFocus,
    onGoToAuthorKits,
};
