import { createSelector } from "reselect";
import {
    mapCollectionToList,
    compareByNameCI,
    compareByOrdering,
    convertDatasetToList,
    denormalizeTopicKey,
} from "../../utils/selectorUtils";
import { getTopicCollectionData } from "../../TopicCollection/topicCollectionSelectors";
import { lessonDefaults } from "./OutlineEditor/LessonProperties/LessonProperties";
import { selectedLessonId, selectedOutlineId } from "../LessonContent/LessonContentSelectors";
import { REVIEW_TYPE, REVIEW_STATS_COLLECTION } from "../../component/seamlessEditor/bookEditor/constants";
import { getOutlineLessonsPublished } from "./OutlineEditor/OutlinePublisher/outlinePublisherSelectors";

const _debugMessages = process.env.NODE_ENV !== "production" // eslint-disable-line

export const getSelectedOutlineId = (state, props) => (
    (props && props.outlineId) ||
    (state.kitBuilder.outlines && state.kitBuilder.outlines.selectedOutlineId)
);

const extendOutlineSetIdFromKey = outline => Object.defineProperty(outline, "id", { value: outline.key });
const transformAndOrderOutlines = data => (
    data && mapCollectionToList(data).map(extendOutlineSetIdFromKey).sort(compareByNameCI)
);

const extendAndSortLessonTopics = (lesson, topicsList) => {
    // console.log("extendAndSortLessonTopics", topicsList);
    const extendedLessonTopics = {};
    Object.keys(lesson.topics || {}).forEach(combinedTopicId => {
        let [collectionId, topicId] = denormalizeTopicKey(combinedTopicId);
        if (topicsList && topicsList[collectionId]) {
            extendedLessonTopics[combinedTopicId] = { ...topicsList[collectionId][topicId], ordering: lesson.topics[combinedTopicId] };
        } else {
            extendedLessonTopics[combinedTopicId] = { ordering: lesson.topics[combinedTopicId], name: "...", invalid: true };
        }
    });
    return mapCollectionToList(extendedLessonTopics).sort(compareByOrdering);
};

const getReviewsCount = (lessonReviewStats) => {
    const reviewsCount = {
        [REVIEW_TYPE.REPLACE]: 0,
        [REVIEW_TYPE.INSERT]: 0,
        [REVIEW_TYPE.COMMENT_ONLY]: 0,
    };

    Object.entries(lessonReviewStats).forEach(([collectionKey, reviewStatsForCollection]) => {
        Object.values(reviewStatsForCollection).forEach((entity) => {  // entity: widgets / problemSets / homework

            if (collectionKey === REVIEW_STATS_COLLECTION.WIDGETS) {
                Object.values(entity).forEach((itemReviewStats) => {
                    Object.entries(itemReviewStats).forEach(([ reviewType, entries ]) => {
                        reviewsCount[reviewType] += entries.length;
                    });
                });
            }
            else {
                Object.entries(entity).forEach(([ reviewType, count ]) => {
                    reviewsCount[reviewType] += count;
                });
            }
        });
    });

    return reviewsCount;
};

// for keeping the same reference for getOutlineLessonsPublished (it requires `outlineId` property in `props` object)
const memoizedProps = {};

const getLessonsPublished = (state) => {
    const outlineId = getSelectedOutlineId(state);

    if (!memoizedProps[outlineId]) {
        memoizedProps[outlineId] = { outlineId };
    }

    return getOutlineLessonsPublished(state, memoizedProps[outlineId]);
};

/**
 * Collects and adds additional properties into lesson object:
 *  - reviewsCount (to highlight that the lesson has to be reviewed)
 *  - release (if the lesson is already published and when)
 *
 * Lesson can have property `reviewStats` in form { widgetId: {itemId: { reviewType: [reviews] } } }.
 * Here is created a new props `reviewsCount`: { reviewTypeA: countA, reviewTypeB: countB }, e.g. { INSERT: 2, COMMENTS_ONLY: 3 }.
 *
 * @param {Array<LessonUnit>} lessonUnitList
 */
const addInfoForLesson = (lessonUnitList, lessonsPublished) => {
    return lessonUnitList.map((lessonUnit) => {
        const { lessons } = lessonUnit;
        lessons.forEach((lesson) => {
            if (lesson.reviewStats) {
                lesson.reviewsCount = getReviewsCount(lesson.reviewStats);
            }

            const published = lessonsPublished && lessonsPublished[lesson.key];

            if (published && published.release) {
                lesson.release = published.release;
            }
        });
        return lessonUnit;
    });
};

/**
 *  Transforms stored data in "flat" form
 *      Outline: { Lesson_units: {},  lessons: {}}
 *  to tree like structure suitable for displaying
 *      Outline: { Lesson_units: { lessons: {}}}
 */
const transformOutlineData = (key, outlines, topicsData, lessonsPublished) => {

    // @TODO: Technical debt - this selector is fired many times, e.g.:
    // - Browser A:
    //      * Author Kit - Arithmetic B (stay on the list, set logPoint at "return transformed")
    // - Browser B:
    //      * Author Kit - Arithmetic B - Lesson 1
    //      * Compose - make any change in editor and save
    // - Browser A:
    //      * DevTools - console - logged 20 times for the same `key`

    // console.log("transformOutlineData()@1 - outline/topics", outlines, topicsData);
    let outline =  key && outlines && outlines[key] && { key, ...outlines[key] };
    // No outline given or already transformed
    if (!outline || Array.isArray(outline.lesson_units)) {
        return outline;
    }

    const { lessons = {}, lesson_units = {}, ...outlineData } = outline;
    // Organize outline lessons into units; creates a clone of lesson with sorted topics
    const lessonsByUnit = convertDatasetToList(lessons).reduce((output, { key, value }) => {
        const unitId = value && value.lesson_unit_id;
        if (unitId && lesson_units[unitId]) {
            const classes = value.classes || lessonDefaults.lessonClasses;
            const length = value.length || lessonDefaults.lessonLength;
            const startWeek = value.start_week || lessonDefaults.lessonStartWeek;

            const type = value.type || lessonDefaults.lessonType;
            const entry = { key, ...value, topics: extendAndSortLessonTopics(value, topicsData), type, classes, length, startWeek, endWeek: startWeek + length - 1 };
            if (output[unitId]) {
                output[unitId].push(entry);
            } else {
                output[unitId] = [ entry ];
            }
        } else if (_debugMessages && outline.lesson_units !== undefined) { // only when outline/units are loaded
            console.warn(`Lesson "${key}" references non-existing lesson unit "${unitId}".`); // eslint-disable-line no-console
        }
        return output; // reducer has to return the aggregation
    }, {});

    // Build a sort list of lesson units with sorted lessons
    const units = convertDatasetToList(lesson_units).map(({ key, value }) => {
        const unitLessons = lessonsByUnit[key] || [];
        const lessons = unitLessons.sort(compareByOrdering);
        let maxLessonOrdering = 1;
        lessons.map(lesson => {
            if (lesson.ordering && maxLessonOrdering < lesson.ordering) {
                maxLessonOrdering = lesson.ordering;
            }
        });
        return { key, ...value, lessons, maxLessonOrdering };
    }).sort(compareByOrdering);

    // Transformed outline with key and id (both are the same),
    // original data (except lessons) and lesson units (with lessons)
    const transformed = extendOutlineSetIdFromKey(outlineData);
    transformed.lesson_units = addInfoForLesson(units, lessonsPublished);

    return transformed;
};

const transformOutlineLessons = (key, outlines) => key && outlines && outlines[key] && outlines[key].lessons;

export const getOpenedTextEditorId = state => state.kitBuilder.outlines.openedEditorId;

export const getOutlinesData = state => state.firebase.data && state.firebase.data.outlines_data;
const getOutlinesList = state => state.firebase.data && state.firebase.data.outlines;

export const getOutlinesListAsArray = createSelector(getOutlinesList, transformAndOrderOutlines);

export const getSelectedOutline = createSelector(getSelectedOutlineId, getOutlinesData, getTopicCollectionData, getLessonsPublished, transformOutlineData);

export const isLessonPropertiesDialogOpen = state => state.kitBuilder.outlines.showLessonProperties;
export const getLessonDataToEdit = state => state.kitBuilder.outlines.lessonDataToEdit;

export const getOutlineLessonsRaw = createSelector(getSelectedOutlineId, getOutlinesData, transformOutlineLessons);

export const getReviewTopics = createSelector(
    getOutlinesData,
    selectedLessonId,
    selectedOutlineId,
    (data, lessonId, outlineId) => {
        if (data && lessonId && outlineId) {
            if (data[outlineId] && data[outlineId].lessons && data[outlineId].lessons[lessonId]) {
                const lesson = data[outlineId].lessons[lessonId];
                return lesson.review_topics || {};
            }
        }
    }
);

export const publishingProgress = state => state.kitBuilder.outlines.publishingProgress;
export const publishingMessage = state => state.kitBuilder.outlines.publishingMessage;
export const getPublishingProgress = createSelector(publishingProgress, (progress) => {
    return progress;
});
export const getPublishingMessage = createSelector(publishingMessage, (message) => {
    return message;
});
