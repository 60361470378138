import { createSelector } from "reselect";
import { getSearchByTopicPredicate, mapCollectionToList, normalizeTopicKey } from "../../utils/selectorUtils";
import { getTopicCollectionData, getTopicCollectionList, getTopicExpansionStatus, convertTopics } from "../topicCollectionSelectors";
import { getTreeFromFlatData } from "react-sortable-tree";

export const searchFilter = state => state.topicSelector.searchFilter;
export const showOnlySelected = (state, props) => state.topicSelector.showOnlySelected && !props.single;
export const selectedTopics = (state, props) => props.selectedTopics;
export const selectedCollection = state => state.topicSelector.selectedCollection;

const getTopicsFromCollection = createSelector(
    selectedCollection,
    getTopicCollectionData,
    (collectionId, collectionData) => collectionData[collectionId]
);

const getFilterOut = (state, props) => props.filterOut;
const getFilterOnly = (state, props) => props.filterOnly;

const getTopicsAsArray = createSelector(
    getTopicsFromCollection,
    mapCollectionToList
);

const preFilterTopicsOut = createSelector(
    getTopicsAsArray,
    getFilterOut,
    selectedCollection,
    (topics, filterOut, collectionId) => {
        return filterOut ? topics.filter(topic => filterOut[normalizeTopicKey(collectionId,topic.key)] === undefined) : topics;
    }
);

const preFilterTopics = createSelector(
    preFilterTopicsOut,
    getFilterOnly,
    selectedCollection,
    (topics, filterOnly, collectionId) => {
        return filterOnly ? topics.filter(topic => filterOnly[normalizeTopicKey(collectionId,topic.key)] !== undefined) : topics;
    }
);

export const filteredTopics = createSelector(
    preFilterTopics,
    showOnlySelected,
    selectedTopics,
    searchFilter,
    selectedCollection,
    (topics, showOnlySelected, selectedTopics, searchFilter, selectedCollection) => {
        let filteredTopics = topics;
        const topicPredicate = getSearchByTopicPredicate(searchFilter);
        if (topicPredicate) {
            filteredTopics = filteredTopics.filter(topicPredicate);
        }
        if (showOnlySelected) {
            return filteredTopics.filter(t => {
                return selectedTopics && selectedTopics[normalizeTopicKey(selectedCollection, t.key)];
            });
        } else {
            return filteredTopics;
        }
    }
);

const markTopicAsRequired = (data, topic, filteredTopics) => {
    if (!filteredTopics[topic.key] && topic.key) {
        filteredTopics[topic.key] = topic;
        if (topic.parent && !filteredTopics[topic.parent]) {
            filteredTopics = markTopicAsRequired(data, {...data[topic.parent], key: topic.parent }, filteredTopics);
        }
    }
    return filteredTopics;
};

/**
 * Get selected Topic Collection in react-sortable-tree format { title: , subtitle:, children: []}
 */
export const getTopicCollectionTree = createSelector(
    selectedCollection,
    getTopicCollectionList,
    getTopicCollectionData,
    getTopicExpansionStatus,
    searchFilter,
    filteredTopics,
    showOnlySelected,
    getFilterOnly,
    (selectedCollection, collectionList, collectionData, expansionStatus, searchFilter, filteredTopicsList, showOnlySelected, filterdOnly) => {
        let root = collectionList && collectionList[selectedCollection] || {};
        let rootData = collectionData[selectedCollection] || {};
        if (searchFilter && 0 < searchFilter.length || showOnlySelected || filterdOnly) {
            let filteredTopics = {};
            filteredTopicsList.forEach(topic => {
                filteredTopics = markTopicAsRequired(rootData, topic, filteredTopics);
            });
            rootData = filteredTopics;
        }
        let filteredTree = {
            title: root.name,
            children: getTreeFromFlatData({
                flatData: convertTopics(rootData, expansionStatus),
                getKey: node => node.key, // resolve a node's key
                getParentKey: node => node.parent, // resolve a node's parent's key
                rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
            }),
            expanded: true,
            key: selectedCollection,
            rootNode: true,
        };
        return filteredTree;
    }
);
