import React from "react";
import PropTypes from "prop-types";
import ObjectiveType from "./ObjectiveType";

const objectiveStyle = { display: "flex", alignItems: "center", justifyContent: "space-between" };

const Objective = ({ data }) => (
    <div style={objectiveStyle}>
        <span style={{ marginRight: "1em" }}>{data.name}</span>
        <ObjectiveType text={data.type} />
    </div>
);

Objective.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string
    })
};

export default Objective;
