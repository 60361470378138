import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Label, Icon, Menu } from "semantic-ui-react";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { modulesList } from "../Modules/ModulesSelectors";

class FilteredByOptionsView extends PureComponent {
    static propTypes = {
        selectedTags: PropTypes.array,
        onSelectTag: PropTypes.func,
        unusedFilter: PropTypes.bool,
        onChangeUnusedFilter: PropTypes.func,
        topicFilterName: PropTypes.string,
        onSelectTopic: PropTypes.func,
        onSetModuleFilter: PropTypes.func,
        moduleFilter: PropTypes.array,
        modulesList: PropTypes.object,
    };

    handleRemove = (id) => {
        const { onSelectTag, selectedTags } = this.props;
        onSelectTag(selectedTags.filter((t) => { return t !== id; }));
    }

    handleCancelUnused = () => {
        this.props.onChangeUnusedFilter(false);
    }

    handleCancelTopicFilter = () => {
        this.props.onSelectTopic(null);
    }

    handleCancelModuleFilter = (id) => {
        const { onSetModuleFilter, moduleFilter } = this.props;
        onSetModuleFilter(moduleFilter.filter((t) => { return t !== id; }));
    }

    render() {
        const { selectedTags, unusedFilter, topicFilterName, moduleFilter, modulesList } = this.props;
        const isModuleFilterActive = moduleFilter && moduleFilter.length && modulesList;
        return (
            <React.Fragment>
                <Menu text style={{ display: "inline-block", margin: "0" }}>
                    <Menu.Item header>FILTERED BY: </Menu.Item>
                </Menu>
                {selectedTags && selectedTags.map(tag => (
                    <Label
                        key={tag}
                        as="a"
                        data={tag}
                    >
                        {tag}
                        <RemoveIcon id={tag} onClick={this.handleRemove} />
                    </Label>
                ))}
                {unusedFilter && <Label as="a" >Show unused only<RemoveIcon onClick={this.handleCancelUnused} /></Label>}
                {topicFilterName && <Label as="a" >Topic: {topicFilterName}<RemoveIcon onClick={this.handleCancelTopicFilter} /></Label>}
                {isModuleFilterActive && moduleFilter.map(mId => (
                    modulesList[mId] && <Label key={mId} as="a" >Package: {modulesList[mId].name}<RemoveIcon id={mId} onClick={this.handleCancelModuleFilter} /></Label>
                )) || null}
            </React.Fragment>
        );
    }
}


class RemoveIcon extends PureComponent {
    static propTypes = {
        id: PropTypes.string,
        onClick: PropTypes.func,
    };

    handleRemove = () => {
        const { id, onClick} = this.props;
        onClick(id);
    }

    render() {
        return <Icon link name="close" onClick={this.handleRemove} />;
    }
}

const mapStateToProps = function(state) {
    return {
        modulesList: modulesList(state),
    };
};

export default compose(
    firebaseConnect(["modules/list"]),
    connect(
        mapStateToProps,
    )
)(FilteredByOptionsView);
