import { TYPES } from "./SiPiClonerActions";

const initialState = {
    selectedTemplateId: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.SIPI_CLONER_SELECT_TEMPLATE:
            return { ...state, selectedTemplateId: payload };
        default:
            return state;
    }
};
