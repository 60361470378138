import io from "socket.io-client";
import AppConfigs from "../../config/firebase.config.json";

const config = AppConfigs["[DEFAULT]"];
if (!config) {
    throw new Error("No such Firebase application configured: [DEFAULT]");
}
// URL to NodeJS server. Can be overriden in firebase.config.json. Defaults to project_name+.appspot.com
const appEngineUrl = config.nodeServerUrl ? config.nodeServerUrl : `https://${config.projectId}.appspot.com`;

// return as a function so it can be later stubbed in tests
export default () => io(appEngineUrl);
