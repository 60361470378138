import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { getSelectedBlocksType } from "draftjs-utils";
import { swallowEvent } from "../utils/utils";
import { ENTITY_TYPE, LIST_NUMBERING_TYPE, LIST_PROPERTIES } from "../constants";

export default class OrderedListItem extends React.Component {
    static propTypes = {
        getEditorState: PropTypes.func.isRequired,
        showCustomComponent: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        bulletValue: PropTypes.string.isRequired,
        orderNumber: PropTypes.number.isRequired,
        dataBlock: PropTypes.bool.isRequired,
        dataEditor: PropTypes.string.isRequired,
        dataOffsetKey: PropTypes.string.isRequired,
        content: PropTypes.node.isRequired,
    };

    contextRef = React.createRef();

    handleContextMenu = (e) => {
        const { getEditorState, reviewMode } = this.props;

        if (reviewMode) {
            return;
        }

        const selectedBlocksType = getSelectedBlocksType(getEditorState());

        if (!["ordered-list-item", "unordered-list-item"].includes(selectedBlocksType)) {
            return;  // inconsistent selection
        }

        swallowEvent(e);
        this.props.showCustomComponent(ENTITY_TYPE.OL, this.contextRef.current);
    };

    render() {
        const { dataBlock, dataEditor, dataOffsetKey, content, bulletValue, orderNumber, reviewMode } = this.props;
        const { block } = content.props;
        const depth = block.getDepth();
        const blockData = block.getData();
        const blockAlignment = blockData.get("text-align") || "left";
        const numberingType = blockData.get(LIST_PROPERTIES.NUMBERING_TYPE);

        // https://github.com/facebook/draft-js/blob/7b9a7e116ab6dd639ebba28736629dfc04cbc7b0/src/component/contents/DraftEditorContents-core.react.js
        return (<li
            className={cx("sporkNumberedListItem", `rdw-${blockAlignment}-aligned-block`, {
                depth,
                nestedParagraph: numberingType === LIST_NUMBERING_TYPE.PARAGRAPH,
                reviewMode,
            })}
            data-block={dataBlock}
            data-editor={dataEditor}
            data-offset-key={dataOffsetKey}
            depth={depth}
            data-bullet-value={bulletValue}
            data-order-number={orderNumber}
            onContextMenu={orderNumber === 1 ? this.handleContextMenu : null}
            ref={this.contextRef}
        >
            {content}
        </li>);
    }
}
