import { all, put, takeEvery, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { TYPES } from "./problemSetWizardActions";
import firebase from "../../firebase";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { sagaToastError } from "../../component/toast";
import { renderProblemSet } from "../../KitBuilder/LessonContent/LessonProblemSet/renderProblemSet";

const fbCollection = "problemSet";

function* regenerateProblemSetHtml(id) {
    const data = yield renderProblemSet(id);
    yield firebase.getFirebaseData(`/problem_sets_data/${id}`).set(data);
}

function* updateProblemSetTitleInLessons({ id, title, lessons_problem_sets, lessons_homework }) {
    const dataRef = firebase.getFirebaseData("/outlines_data");
    const updates = {};

    Object.entries(lessons_problem_sets || {}).forEach(([lessonId, outlineId]) => {
        updates[`${outlineId}/lessons/${lessonId}/problem_sets/${id}/title`] = title;
    });
    Object.entries(lessons_homework || {}).forEach(([lessonId, outlineId]) => {
        updates[`${outlineId}/lessons/${lessonId}/homework/${id}/title`] = title;
    });

    yield dataRef.update(updates);
}

export function* createProblemSet({ problemSet, callback }) {
    const db = firebase.defaultApp.database().ref();
    try {
        // request only new key for future object
        const { key } = yield db.child(fbCollection).push();

        // Get author & tenant info
        const author = yield select(loggedUserId);
        const tenant = yield select(tenantId);

        // Make all updates atomic
        const updates = problemSet.problems.reduce((acc, problemId) => {
            updates[`problem/${problemId}/problemSets/${key}`] = true;
            return updates;
        }, {
            [`${fbCollection}/${key}`]: { ...problemSet, author: String(author), t: tenant }
        });

        yield db.update(updates);
        yield regenerateProblemSetHtml(key);

        if (callback) {
            callback(key);
        } else {
            // redirect to creator-set
            yield put(push("/qu/problem-set-creator/" + key));
        }
    } catch (error) {
        sagaToastError("Failed to create problem set.", error);
    }
}

export function* editProblemSet(action) {
    const db = firebase.defaultApp.database().ref();
    const { problemSet } = action;
    try {
        // make a copy, but remove some items we don't want to update
        const updatedProblemSet = { ...problemSet };
        delete updatedProblemSet["id"];
        delete updatedProblemSet["problems"];
        delete updatedProblemSet["author"];

        //get author info
        const editor = yield select(loggedUserId);
        updatedProblemSet["editor"] = editor.toString();

        yield db
            .child(fbCollection)
            .child(problemSet.id)
            .update(updatedProblemSet);

        const currentTitle = (yield firebase.getFirebaseData(`problem_sets_data/${problemSet.id}/title`).once("value")).val();

        if (currentTitle !== updatedProblemSet.title) {
            // When Problem Set title is changed, its HTML content has to be regenerated,
            // because it could contain text "Problem set [title] is empty."
            yield regenerateProblemSetHtml(problemSet.id);
            yield updateProblemSetTitleInLessons(problemSet);
        }
    } catch (error) {
        sagaToastError("Failed to save problem set.", error);
    }
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.CREATE_NEW_PROBLEM_SET, createProblemSet),
        takeEvery(TYPES.EDIT_SAVE_PROBLEM_SET, editProblemSet),
    ]);
}
