import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Popup, Button, Divider } from "semantic-ui-react";
import PopupCloseIcon from "../../component/PopupCloseIcon";

export default class StylePopup extends PureComponent {
    static propTypes = {
        icon: PropTypes.node,
        children: PropTypes.node,
        header: PropTypes.string,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        open: PropTypes.bool,
        popupId: PropTypes.number,
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    handleOpen = () => {
        this.props.onOpen(this.props.popupId);
    }

    render() {
        const { icon, children, header, open, onClose, active, disabled } = this.props;

        return (
            <Popup on="click" open={open} onOpen={this.handleOpen} position={"bottom left"} wide="very" trigger={<Button disabled={disabled} active={active} icon={icon} onClick={open ? onClose : this.handleOpen}/>} hoverable >
                <span><b style={{float: "left", paddingRight: "1em", marginTop: "-0.5em"}}>{header}</b><PopupCloseIcon onClose={onClose} /></span>
                <Divider hidden />
                {children}
            </Popup>
        );
    }
}
