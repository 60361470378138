import React, { PureComponent, createRef } from "react";
import PropTypes from "prop-types";
import { Button, Segment, Dropdown } from "semantic-ui-react";
import ConfirmDeleteWithContext from "../../../../component/ConfirmDeleteWithContext";


export default class ProblemSetLabel extends PureComponent {
    static propTypes = {
        problemSet: PropTypes.object,
        userRights: PropTypes.object,
        onShuffle: PropTypes.func,
        onEditProblemSet: PropTypes.func,
        entityName: PropTypes.string,
        onRemove: PropTypes.func,
        isAuthorAtLeast: PropTypes.bool,
        isSuperAuthorAtLeast: PropTypes.bool,
    };

    state = {
        confirmDelete: null,
        withProblems: false
    };

    contextRef = createRef();

    handleEditHomework = () => {
        const {
            onEditProblemSet,
            problemSet: { id },
        } = this.props;
        onEditProblemSet(id);
    };

    handleRemove = () => {
        const {
            problemSet: { id },
            onRemove,
        } = this.props;
        onRemove(id, false, false);
    };

    handleConfirmRemoveAndDelete = () => {
        const { withProblems } = this.state;
        const {
            problemSet: { id },
            onRemove,
        } = this.props;
        onRemove(id, true, withProblems);
    };

    handleRemoveAndDelete = () => {
        const { contextRef } = this;
        this.setState({
            confirmDelete: contextRef.current,
            withProblems: false
        });
    };

    handleRemoveAndDeleteWithProblems = () => {
        const { contextRef } = this;
        this.setState({
            confirmDelete: contextRef.current,
            withProblems: true
        });
    };

    handleCancelRemove = () => {
        this.setState({ confirmDelete: null, withProblems: false });
    };

    render() {
        const { problemSet, userRights, entityName, isAuthorAtLeast, isSuperAuthorAtLeast, onShuffle } = this.props;
        const { confirmDelete } = this.state;
        return (
            <Segment
                basic
                style={{ verticalAlign: "middle", background: "rgba(0,0,0,0.04)", padding: "0.4em 0.4em 0.4em 1em"}}
            >
                <div ref={this.contextRef} style={{ float: "left", height: "30px", alignItems: "center", display: "flex" }}>{problemSet.name}</div>
                {userRights && (
                    <Dropdown
                        pointing="top right"
                        icon={null}
                        trigger={<Button compact basic icon="ellipsis vertical" />}
                        style={{float: "right"}}
                    >
                        <Dropdown.Menu >
                            <Dropdown.Item onClick={this.handleEditHomework} text={userRights.canEdit ? `Edit ${entityName}` : `Review ${entityName}` }
                                icon={userRights.canEdit ? "edit outline" : "eye"} />
                            {userRights.canEdit && <React.Fragment>
                                <Dropdown.Item onClick={onShuffle} text="Shuffle multichoice answers" icon="sync alternate"/>
                                <Dropdown.Item onClick={this.handleRemove} text="Remove from the lesson" icon="unlink" />
                                {!problemSet.usedInDifferentEntity && <React.Fragment>
                                    {isAuthorAtLeast && <Dropdown.Item onClick={this.handleRemoveAndDelete} text="Remove and delete set" icon="trash alternate outline" /> }
                                    {isSuperAuthorAtLeast && <Dropdown.Item onClick={this.handleRemoveAndDeleteWithProblems} text="Remove and delete set and problems" icon="trash alternate outline" />}
                                </React.Fragment>}
                            </React.Fragment>}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                <ConfirmDeleteWithContext
                    context={confirmDelete}
                    confirmText={`Delete ${entityName}`}
                    onConfirm={this.handleConfirmRemoveAndDelete}
                    whatText={`this ${entityName}`}
                    onCancel={this.handleCancelRemove}
                />
                <div style={{clear: "both", backgroundColor:"red"}}/>
            </Segment>
        );
    }
}
