import {
    getUsedSnippets,
    getUsedGlossary,
    getLinkedOutlinesFromLessonLinks,
    getReviewsStats
} from "../../../component/seamlessEditor/bookEditor/utils/utils";

export const prepareTextItemContent = (content) => {
    if (content && content.raw) {
        const { html, raw } = content;
        const snippets = getUsedSnippets(raw, true);
        const glossary = getUsedGlossary(raw, true);
        const linked_outlines = getLinkedOutlinesFromLessonLinks(raw, true);
        const reviewStats = getReviewsStats(raw);
        return { html, raw, snippets, glossary, linked_outlines, reviewStats };
    }
    return null;
};

/**
 * Create a new random key for component to enforce re-mounting.
 * 
 * @returns {string} value of component key
 */
export const getEditorComponentKey = () => Math.random().toString(36).substring(7);
