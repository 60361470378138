import { convertDatasetToList } from "../../utils/selectorUtils";
import { createSelector } from "reselect";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getSubscribedModules } from "../../External/Tenants/sharedTenantSelectors";

export const modulesList = ({ firebase }) => firebase.data.modules && firebase.data.modules.list;
export const modulesData = ({ firebase }) => firebase.data.modules && firebase.data.modules.data;
export const isSaving = ({ kitBuilder }) => kitBuilder.modules.isSaving;
export const isRemoving = ({ kitBuilder }) => kitBuilder.modules.isRemoving;
export const isUpdating = ({ kitBuilder }) => kitBuilder.modules.isUpdating;
export const isLoading = ({ kitBuilder }) => kitBuilder.modules.isLoading;
export const updatingModuleId = ({ kitBuilder }) => kitBuilder.modules.updatingModuleId;
const packages = ({ kitBuilder }) => kitBuilder.modules.packages;


export const getModules = createSelector(
    modulesList,
    tenantId,
    modulesData,
    getSubscribedModules,
    (list, tenant, data, subscribedModules) => {
        if (list) {
            const allModules = convertDatasetToList(list);
            const tenantModules = allModules.filter(({ key, value }) => (value.t === tenant || subscribedModules && subscribedModules[key]));
            const result = {authored: [], subscribed: []};
            for (let {key, value } of tenantModules) {
                if (subscribedModules && subscribedModules[key]) {
                    result.subscribed.push({
                        key,
                        value: {
                            ...value,
                            widgets: data && data[key] && data[key].w && Object.keys(data[key].w).length || 0,
                            templates: data && data[key] && data[key].t && Object.keys(data[key].t).length || 0,
                            glossary: data && data[key] && data[key].g && Object.keys(data[key].g).length || 0,
                            iPlannerTemps: data && data[key] && data[key].it && Object.keys(data[key].it).length || 0,
                            styledBlocks: data && data[key] && data[key].sb && Object.keys(data[key].sb).length || 0,
                            subscribed: subscribedModules && subscribedModules[key] || false
                        }});
                } else {
                    result.authored.push({
                        key,
                        value: {
                            ...value,
                            widgets: data && data[key] && data[key].w && Object.keys(data[key].w).length || 0,
                            templates: data && data[key] && data[key].t && Object.keys(data[key].t).length || 0,
                            glossary: data && data[key] && data[key].g && Object.keys(data[key].g).length || 0,
                            iPlannerTemps: data && data[key] && data[key].it && Object.keys(data[key].it).length || 0,
                            styledBlocks: data && data[key] && data[key].sb && Object.keys(data[key].sb).length || 0,
                            subscribed: subscribedModules && subscribedModules[key] || false
                        }});
                }
            }
            return result;
        }
    }
);

export const getPackages = createSelector(
    tenantId,
    packages,
    (tenantId, packages) => {
        const result = {authored: [], subscribed: []};
        for (let pkg of packages) {
            if (pkg.tenant_id !== tenantId) {
                result.subscribed.push({
                    key: pkg.spork_id,
                    value: {
                        ...pkg,
                        subscribed: true
                    }});
            } else {
                result.authored.push({
                    key: pkg.spork_id,
                    value: {
                        ...pkg,
                        subscribed: false
                    }});
            }
        }
        return result;
    }
);

export const getModulesByTenant = createSelector(
    modulesList,
    (modules) => {
        if (undefined !== modules) {
            const modulesByTenant = {};
            if (modules) {
                for (const moduleId of Object.keys(modules)) {
                    const m = modules[moduleId];
                    if (modulesByTenant[m.t]) {
                        modulesByTenant[m.t][moduleId] = m;
                    } else {
                        modulesByTenant[m.t] = { [moduleId]: m };
                    }
                }
            }
            return modulesByTenant;
        }
    }
);

export const getPublicModules = createSelector(
    modulesList,
    (list) => {
        if (list) {
            const allModules = convertDatasetToList(list);
            const publicModules = allModules.filter(({ value }) => value.is_public);
            return publicModules;
        }
    }
);

export const getTenantModulesOptions = createSelector(
    modulesList,
    tenantId,
    (modules, tenant) => {
        if (modules) {
            const allModules = convertDatasetToList(modules);
            const tenantModules = allModules.filter(({ value }) => (value.t === tenant));
            const result = [];
            for (let {key, value } of tenantModules) {
                result.push({
                    key,
                    value: key,
                    text: value.name
                });
            }
            return result;
        }
    }
);


