import { all, put, takeLatest } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, setSchooList, onSetSingleSchool } from "./schoolActions";
import { toastError } from "../../component/toast";

function* loadSchoolList() {
    try {
        let schools = yield firebase.tabletProxy({ method: "GET", uri: "/school?$sort[name]=1" });
        yield put(setSchooList(schools.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of school list failed.", message: err.message });
        yield put(setSchooList([]));
    }
}

function* loadSchool({ payload }) {
    try {
        let school = yield firebase.tabletProxy({ method: "GET", uri: "/school/" + payload });
        yield put(onSetSingleSchool(school.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of school failed.", message: err.message });
        yield put(onSetSingleSchool(null));
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.LOAD_SCHOOL_LIST, loadSchoolList),
        takeLatest(TYPES.LOAD_SCHOOL, loadSchool),
    ]);
}
