const TYPES = {
    GENERATES_IPLANNER_DATA: "GENERATES_IPLANNER_DATA",
    GENERATES_IPLANNER_DATA_FINISHED: "GENERATES_IPLANNER_DATA_FINISHED",
    GENERATES_IPLANNER_DATA_PROGRESS: "GENERATES_IPLANNER_DATA_PROGRESS",
};

const onGenerateData = (payload) => ({
    type: TYPES.GENERATES_IPLANNER_DATA,
    payload
});

const onGeneratesDataProgress = payload => ({
    type: TYPES.GENERATES_IPLANNER_DATA_PROGRESS,
    payload,
});

const onGeneratesDataFinished = () => ({
    type: TYPES.GENERATES_IPLANNER_DATA_FINISHED,

});

export {
    TYPES,
    onGenerateData,
    onGeneratesDataFinished,
    onGeneratesDataProgress,
};
