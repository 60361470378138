export const TYPES = {
    PUBLISH_LESSON_REQUEST: "Publisher/Lesson/PUBLISH_LESSON_REQUEST",
    PUBLISH_LESSON_SUCCESS: "Publisher/Lesson/PUBLISH_LESSON_SUCCESS",
    PUBLISH_LESSON_FAILURE: "Publisher/Lesson/PUBLISH_LESSON_FAILURE",
};

const PublishModes = ["preview", "release", "publish"];

export const onLessonPublish = (outlineId, lessonId, mode = "publish") => {
    if (!PublishModes.includes(mode)) {
        // eslint-disable-next-line no-console
        console.warn(`Invalid publisher mode used (${mode}), falling-back to "publish".`);
        mode = "publish";
    }
    return {
        type: TYPES.PUBLISH_LESSON_REQUEST,
        payload: {
            outlineId,
            lessonId,
            mode,
        },
    };
};

export const onLessonPublishSuccess = (outlineId, lessonId, response) => ({
    type: TYPES.PUBLISH_LESSON_SUCCESS,
    payload: {
        outlineId,
        lessonId,
        response,
    },
});

export const onLessonPublishFailure = (outlineId, lessonId, failure) => {
    const message = typeof failure === "string" ? failure : (
        (typeof failure === "object" && failure.message) || "Unrecognized failure occurred."
    );
    return {
        type: TYPES.PUBLISH_LESSON_FAILURE,
        payload: {
            outlineId,
            lessonId,
            message,
        },
        error: true,
    };
};
