import { all, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import firebase from "../../firebase";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { TYPES } from "./problemSetCreatorActions";
import { sagaToastError } from "../../component/toast";
import { renderProblemSet } from "../../KitBuilder/LessonContent/LessonProblemSet/renderProblemSet";
import { getProblemSets } from "../ProblemSetManager/problemSetManagerSelectors";
import { REVIEW_STATS_COLLECTION } from "../../component/seamlessEditor/bookEditor/constants";
import { outlineManager } from "../../KitBuilder/Outlines/DataSource";
import {
    clearAnswerIfParent,
    insertChildQuestion,
    removeChildQuestion,
    moveChildQuestionOf,
    setQuestionPosition,
    attachQuestionsTo,
    detachQuestionFrom,
    removeQuestionCollection,
    insertQuestionTo,
} from "../utils";

const fbCollection = "problemSet";
const fbItemCollection = "problem";

function* updateOutlineReviewStats(problemSetIds, problemSetsData, isRemoved = false) {

    if (isRemoved) {
        return;
    }
    // FIXME: Replace the use of Redux selector with query to Firebase.
    // We may not have loaded data of all problem sets
    // (e.g. when updating all related sets of problem).
    const problemSets = yield select(getProblemSets, { fbCollection });
    const updates = problemSetIds.reduce((acc, problemSetId, index) => {
        const problemSet = problemSets[problemSetId];
        if (!problemSet) {
            return acc; // We don't have problem set data in Redux (see FIXME above)
        }
        // Generate updates of review stats
        const reviewsCount = isRemoved ? null : problemSetsData[index].reviewsCount;
        if (problemSet.lessons_problem_sets) {
            Object.entries(problemSet.lessons_problem_sets).forEach(([lessonId, outlineId]) => {
                acc.push(outlineManager.outlineLessonUpdateReviewStats(REVIEW_STATS_COLLECTION.PROBLEM_SETS, outlineId, lessonId, problemSetId, reviewsCount));
            });
        }
        if (problemSet.lessons_homework) {
            Object.entries(problemSet.lessons_homework).forEach(([lessonId, outlineId]) => {
                acc.push(outlineManager.outlineLessonUpdateReviewStats(REVIEW_STATS_COLLECTION.HOMEWORK, outlineId, lessonId, problemSetId, reviewsCount));
            });
        }

        return acc;
    }, []);

    try {
        if (updates.length) {
            yield all(updates);
        }
    }
    catch (error) {
        sagaToastError("Updating review stats in a lesson failed.", error);
    }
}

function getObjectKeysFromSnapshot(s) {
    const v = s && s.val();
    return v ? Object.keys(v) : [];
}
/**
 * Get the list of problem sets that the given problem(s) is/are used in.
 * 
 * @param {string|string[]} problemId single problem ID or array of them
 * @returns {string[]} array of related problem set IDs
 */
export function* getRelatedProblemSets(problemId) {
    const dataRef = firebase.getFirebaseData(fbItemCollection);
    const dataIds = problemId && (
        Array.isArray(problemId) ? [...new Set(problemId) ] : [ problemId ]
    );
    if (!(dataIds && dataIds.length)) {
        return null;
    }
    // Collect the problem sets for all given problems { [psId]: true }
    const results = yield all(
        dataIds.map((id) => dataRef.child(`${id}/problemSets`).once("value"))
    );
    // ... and extract the keys to a single array
    const problemSets = results.flatMap(getObjectKeysFromSnapshot);

    return problemSets.length ? [...new Set(problemSets)] : [];
}

/**
 * Render & save HTML content of given problem sets.
 *
 * @param {string|string[]} target problem set ID(s)
 */
export function *rebuildProblemSetData(target) {
    const problemSets = (Array.isArray(target) ? target : [ target ]).filter(Boolean);
    if (!(problemSets && problemSets.length)) {
        return;
    }

    const htmlContent = yield all(problemSets.map((problemSetId) => renderProblemSet(problemSetId)));
    const dataUpdates = problemSets.map((problemSetId, index) => {
        return firebase.getFirebaseData(`problem_sets_data/${problemSetId}`).set(htmlContent[index]);
    });

    try {
        yield all(dataUpdates);
    }
    catch (error) {
        sagaToastError("Updating Problem Set data failed.", error);
    }

    yield updateOutlineReviewStats(problemSets, htmlContent);
    // this could be the right place for updating shuffled_problems (outlines_data) if necessary
    // (i.e. problem has different count of multichoice answers then is stored in shuffled_problems property).
}

function* removeProblemSetData(problemSetId) {
    yield updateOutlineReviewStats([problemSetId], null, true);
    yield firebase.getFirebaseData(`problem_sets_data/${problemSetId}`).remove();
}

function* addProblemToActiveSet({ payload: { problem, activeSetId } }) {
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);
    try {
        // Prepare the data to insert
        const toInsert = clearAnswerIfParent({
            ...problem,
            problemSets: { [activeSetId]: true },
            author: String(userId),
            t: tenant,
        });
        // Insert as a child problem or a standalone problem
        if (problem.parentProblemId) {
            const usedIn = yield insertChildQuestion(fbItemCollection, problem.parentProblemId, toInsert);
            // Refresh all sets where the parent is used
            if (usedIn) {
                yield fork(rebuildProblemSetData, usedIn);
            }
        } else {
            const created = yield insertQuestionTo(fbCollection, activeSetId, toInsert);
            // Refresh the current problem set only
            if (created) {
                yield fork(rebuildProblemSetData, activeSetId);
            }
        }
    } catch (error) {
        sagaToastError("Failed to add a problem to a set.", error);
    }
}

function* saveEditProblem({ problem }) {
    const dataRef = firebase.getFirebaseData(fbItemCollection);
    try {
        // Don't save back de-normalized data
        const toUpdate = clearAnswerIfParent({ ...problem, id: null, type: null, });
        delete toUpdate.displayTopics;
        delete toUpdate.displayProblemSets;

        yield dataRef.child(problem.id).update(toUpdate);
        const usedIn = yield getRelatedProblemSets(problem.id);
        if (usedIn && usedIn.length) {
            yield fork(rebuildProblemSetData, usedIn);
        }
    } catch (error) {
        sagaToastError("Failed to save a problem.", error);
    }
}

function* deleteProblemFromSet({ payload: { problemId, activeProblemSet, parentProblemId } }){
    const problemSetId = activeProblemSet.id;
    const { problems } = activeProblemSet;

    const usedIn = yield getRelatedProblemSets(parentProblemId || problemId);
    if (parentProblemId) {
        yield removeChildQuestion(fbCollection, problemSetId, parentProblemId, problemId);
    } else {
        yield detachQuestionFrom(fbCollection, problemSetId, problemId, problems);
    }
    // Reflect the removal in problem set pre-rendered HTML
    if (usedIn && usedIn.length) {
        yield fork(rebuildProblemSetData, usedIn);
    }
}

function* deleteProblemSet(action) {
    const { problemSet } = action;
    try {
        // Remove problem set & references from problems as single atomic operation
        yield removeProblemSetData(problemSet.id);
        yield removeQuestionCollection(fbCollection, problemSet.id);
    } catch (error) {
        sagaToastError("Failed to delete problem set.", error);
    }
}

function* moveChildProblem({ payload: { parentProblemId, problemId, direction } }) {
    try {
        const modified = yield moveChildQuestionOf(fbItemCollection, parentProblemId, problemId, direction);
        if (modified && modified.length) {
            yield fork(rebuildProblemSetData, modified);
        }
    } catch (error) {
        sagaToastError("Failed to move items.", error);
    }
}

function* moveProblem({ payload: { activeProblemSet, problemId, position, destination } }) {
    try {
        yield setQuestionPosition(
            `${fbCollection}/${activeProblemSet.id}`,
            activeProblemSet.problems,
            problemId,
            position,
            destination,
        );
        yield fork(rebuildProblemSetData, activeProblemSet.id);
    } catch (error) {
        sagaToastError("Failed to move items.", error);
    }
}

function* backToLesson({ payload: { lesson, outline, tab, /* isProblemSetChanged, id */}}) {
    yield put(push(`/lesson/content/${outline}/${lesson}?tab=${tab}`));
}

function* assignProblemsToSet({ payload: { problemIds, activeSetId }}) {
    try {
        const toAttach = Array.isArray(problemIds) ? problemIds : (
            Object.keys(problemIds || {})
        );
        yield attachQuestionsTo(fbCollection, activeSetId, toAttach);
        yield fork(rebuildProblemSetData, activeSetId);
    } catch (error) {
        sagaToastError("Failed to assign problems to a set.", error);
    }
}

function* exportToPdf({ payload: { id, fbCollection, exportWindow }}) {
    const rendered = yield renderProblemSet(id, fbCollection);
    const printProblemSetHtmlToPdf = firebase.getFirebaseFunctions().httpsCallable("printProblemSetHtmlToPdf");
    try {
        const result = yield printProblemSetHtmlToPdf({
            html: rendered.html,
            problemSetName: rendered.title,
            path: `pdfexports/${fbCollection}/${rendered.title}-${id}.pdf`,
            storageUrl: firebase.getStorageURL()
        });
        exportWindow.location.href = result.data.url;
        exportWindow.focus();
    } catch (error) {
        exportWindow.close();
        sagaToastError("Unable to export problem set to PDF", error);
    }
}

export default function* sagaProblemSetCreator() {
    yield all([
        takeEvery(TYPES.ADD_PROBLEM_TO_PS, addProblemToActiveSet),
        takeEvery(TYPES.EDIT_PROBLEM_TO_PS, saveEditProblem),
        takeEvery(TYPES.REMOVE_PROBLEM_FROM_PS, deleteProblemFromSet),
        takeEvery(TYPES.DELETE_PROBLEM_SET, deleteProblemSet),
        takeEvery(TYPES.CHANGE_PROBLEM_POSITION, moveProblem),
        takeEvery(TYPES.CHANGE_CHILD_PROBLEM_POSITION, moveChildProblem),
        takeEvery(TYPES.PROBLEM_SET_BACK_TO_LESSON, backToLesson),
        takeEvery(TYPES.ASSIGN_PROBLEMS_TO_SET, assignProblemsToSet),
        takeLatest(TYPES.PROBLEM_SET_PDF_EXPORT, exportToPdf),
    ]);
}
