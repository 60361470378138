import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import { getSelectedCollection } from "../tttSelectors";
import BeLATopicCollection from "./BeLATopicCollection";

const mapStateToProps = function(state) {
    return {
        selectedCollection: getSelectedCollection(state)
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(BeLATopicCollection);
