const TYPES = {
    SAVE_COMP_TEST: "SAVE_COMP_TEST",
    ADD_QUESTION_TO_COMP_TEST: "ADD_QUESTION_TO_COMP_TEST",
    ASSIGN_QUESTIONS_TO_COMP_TEST: "ASSIGN_QUESTIONS_TO_COMP_TEST",
    REMOVE_QUESTION_FROM_COMP_TEST: "REMOVE_QUESTION_FROM_COMP_TEST",
    CHANGE_QUESTION_POSITION: "CHANGE_QUESTION_POSITION",
    CHANGE_CHILD_QUESTION_POSITION: "CHANGE_CHILD_QUESTION_POSITION",

    CREATE_QUESTION: "CompTests/CREATE_QUESTION",
    DELETE_QUESTION: "CompTests/DELETE_QUESTION",
    EDIT_QUESTION: "CompTests/EDIT_QUESTION",
    GET_QUESTION_COUNT: "CompTests/GET_QUESTION_COUNT",
    FILTER_QUESTIONS: "CompTests/FILTER_QUESTIONS",
};

const onSaveCompTest = (compTest) => ({ type: TYPES.SAVE_COMP_TEST, compTest });

const onAddQuestionToCompTest = ({ problem, position, activeSetId }) => ({
    type: TYPES.ADD_QUESTION_TO_COMP_TEST,
    payload: {
        question: problem,
        position,
        compTestId: activeSetId
    }
});

const onAssignQuestionsToCompTest = ({ problemIds, activeSetId }) => ({
    type: TYPES.ASSIGN_QUESTIONS_TO_COMP_TEST,
    payload: {
        questionIds: problemIds,
        compTestId: activeSetId,
    }
});

const onRemoveQuestionFromCompTest = ({ problemId, itemIndex, activeProblemSet, parentProblemId }) => ({
    type: TYPES.REMOVE_QUESTION_FROM_COMP_TEST,
    payload: {
        questionId: problemId,
        itemIndex,
        compTest: activeProblemSet,
        parentQuestionId: parentProblemId,
    }
});

const onChangeQuestionPosition = ({ activeProblemSet, problemId, position, destination }) => ({
    type: TYPES.CHANGE_QUESTION_POSITION,
    payload: {
        compTest: activeProblemSet,
        questionId: problemId,
        position,
        destination,
    }
});

const onChangeChildQuestionPosition = ({ parentProblemId, problemId, direction }) => ({
    type: TYPES.CHANGE_CHILD_QUESTION_POSITION,
    payload: {
        parentQuestionId: parentProblemId,
        questionId: problemId,
        direction,
    }
});

//---
const onCreateQuestion = (question) => ({ type: TYPES.CREATE_QUESTION, question });
const onDeleteQuestion = (questionId) => ({ type: TYPES.DELETE_QUESTION, questionId });
const onEditQuestion = (question) => ({ type: TYPES.EDIT_QUESTION, question });

const onGetQuestionCount = () => ({ type: TYPES.GET_QUESTION_COUNT });
const onApplyFilterBy = (payload, currentPage, itemsPerPage) => ({
    type: TYPES.FILTER_QUESTIONS,
    payload,
    currentPage,
    itemsPerPage
});

export {
    TYPES,
    // Used in ProblemSetCreator
    onSaveCompTest,
    onAddQuestionToCompTest,
    onAssignQuestionsToCompTest,
    onRemoveQuestionFromCompTest,
    onChangeQuestionPosition,
    onChangeChildQuestionPosition,
    // Used in ProblemManagament (via CompTestBindings.js)
    onCreateQuestion,
    onDeleteQuestion,
    onEditQuestion,
    onGetQuestionCount,
    onApplyFilterBy,
};
