import React from "react";
import PropTypes from "prop-types";
import UsedCount from "../../../../KitBuilder/component/UsedCount";

export default class TemplateiPlannersCount extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        usedBy: PropTypes.object,
        templateId: PropTypes.string,
    };

    handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { usedBy, onClick, templateId } = this.props;
        onClick && onClick(e, { usedBy, templateId });
    }

    render() {
        const { usedBy, onClick } = this.props;
        const usedCount = usedBy ? Object.keys(usedBy).length : 0;
        return <UsedCount
            entityName="iPlanner"
            usedCount={usedCount}
            onClick={onClick && usedCount ? this.handleClick : null}
        />;
    }
}
