import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import YearComponent from "./YearsComponent";
import { getYearOptions, getSelectedYear, isLoading } from "./YearsSelectors";
import { onSelectYear } from "./YearsActions";
import services from "../feathersjs/services";

const mapStateToProps = function(state) {
    return {
        yearOptions: getYearOptions(state),
        selectedYear: getSelectedYear(state),
        isLoading: isLoading(state),
    };
};

const dispatchToProps = dispatch => bindActionCreators({ onLoadYears: services.year.find, onSelectYear }, dispatch);

export default compose(connect(mapStateToProps, dispatchToProps))(YearComponent);
