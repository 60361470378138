import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import LastEditorLabel from "../../../component/LastEditorLabel";
import { firebaseConnect } from "react-redux-firebase";
import { editor, timestamp, editorId } from "./LessonLastEditorSelectors";

const mapStateToProps = (state, props) => ({
    editor: editor(state, props),
    editorTimestamp: timestamp(state, props),
});

const dispatchToProps = dispatch => {
    return bindActionCreators({ }, dispatch);
};

export default compose(
    connect((state, props) => ({
        editorId: editorId(state, props),
    })),
    firebaseConnect(props => {
        return [{ path: "/user/" + props.editorId, type: "once" }];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(LastEditorLabel);
