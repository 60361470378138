import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { firebaseConnect } from "react-redux-firebase";
import ProblemSetWizard from "./ProblemSetWizard";
import { createNewProblemSet, editSaveProblemSet } from "./problemSetWizardActions";
import { getProblemSetTagsAsOptions } from "./ProblemSetWizardSelectors";
import { onSaveCompTest } from "../CompTest/CompTestActions";
import { getTagCollectionByParent } from "../utils";

const mapStateToProps = (state, props) => ({
    tagOptions: getProblemSetTagsAsOptions(state, props),
});

function mapDispatchToProps(dispatch, { fbCollection }) {
    switch (fbCollection) {
        case "problemSet":
            return {
                onSave: (args) => dispatch(createNewProblemSet(args)),
                onEditSave: (args) => dispatch(editSaveProblemSet(args)),
            };
        case "comptests_common":
            return {
                onSave: () => {},
                onEditSave: (args) => dispatch(onSaveCompTest(args)),
            };
        default:
            // eslint-disable-next-line no-console
            console.error(`Unsupported collection "${fbCollection}" in ProblemSetWizard`);
    }
}

export default withRouter(compose(
    firebaseConnect(({ fbCollection }) => [getTagCollectionByParent(fbCollection)]),
    connect(mapStateToProps, mapDispatchToProps)
)(ProblemSetWizard));

