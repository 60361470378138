import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { Loader, Sidebar, Dimmer } from "semantic-ui-react";
import { MainContainer } from "bmd-react";
import { hot } from "react-hot-loader";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { authenticate } from "./feathersjs/client";
import { configureListeners } from "./feathersjs/services";

import firebase from "./firebase";
import SidebarMenu from "./SidebarMenu";
import Header from "./Header";
import Login from "./Users/Login/Login";
import Logout from "./Users/Logout/Logout";
import SnippetsStylesheet from "./KitBuilder/Snippets/SnippetsStylesheet";

import { Routes as TopicsRoutes } from "./Topics";
import { Routes as TTTRoutes } from "./TopicTransformationTool";
import { Routes as OSMTRoutes } from "./Topics/ObjectiveSetsManager";
import { Routes as ConceptsRoutes } from "./Topics/Concepts";
import { Routes as ExternalRoutes } from "./External";
import { Routes as KitBuilderRoutes } from "./KitBuilder";
import { Routes as QuRoutes } from "./QuestionUtilizator";
import { Routes as TCRoutes } from "./TopicCollection";
import { Routes as UserRoutes } from "./Users";
import { Routes as MaintenanceRoutes } from "./Maintenance";
import { Routes as TabletRoutes } from "./Tablet";
import { Routes as LUPRoutes } from "./LessonUnitPlanner";

import Dashboard from "./dashboard";
import Welcome from "./Welcome";
import SandboxPage from "./SandboxPage";

import "./App.scss";
import GlobalErrorBoundary from "./GlobalErrorBoundary";
import VersionCheckContainer from "./component/VersionCheck";
import { toastError } from "./component/toast";
import { onSetAuth } from "./auth/authActions";
import { compose } from "redux";

// Call it once in your app. At the root of your app is the best place
toast.configure({ autoClose: 5000, style: { width: "450px" } });

class App extends React.Component {
    state = {
        isAppInitialized: false,
        isUserLoggedIn: false,
        loadingMessage: "Loading application",
    };

    static contextTypes = {
        store: PropTypes.object.isRequired,
    };
    store = this.context["store"];

    handleLogin = () => {
        this.setState({ isUserLoggedIn: true, isAppInitialized: true });
    };

    handleLogout = () => {
        this.loginInfoMessage = "You have been logged out.";
        this.setState({
            isUserLoggedIn: false,
            isAppInitialized: true,
        });
    };

    componentDidMount() {
        configureListeners(this.store.dispatch);
        firebase.getFirebaseAuth().onIdTokenChanged(user => {
            if (user) {
                this.setState({ loadingMessage: "Authenticating user" });
                user.getIdTokenResult().then(idTokenResult => {
                    // load tenant details from Firebase
                    firebase.defaultApp
                        .database()
                        .ref(`/tenants/t${idTokenResult.claims.tenantId}`)
                        .once("value")
                        .then(snapshot => {
                            let tenant = snapshot.val();
                            // store currently logged in user details (claims and tenant) in Redux
                            this.props.onSetAuth({ ...idTokenResult.claims, tenant });
                            // authenticate user to FeathersJS server with Firebase token
                            authenticate(idTokenResult.token)
                                // all done - switch app to authenticated state
                                .then(() => this.handleLogin())
                                .catch(error =>
                                    toastError({
                                        code: error.name,
                                        header: "Authentication failed.",
                                        message: error.message,
                                    })
                                );
                        });
                });
            } else {
                this.setState({
                    isUserLoggedIn: false,
                    isAppInitialized: true,
                });
            }
        });
    }

    logoutComponent = () => <Logout onLogout={this.handleLogout} />;
    loginComponent = () => <Login onLogin={this.handleLogin} {...this.props} />;

    renderLoadingDimmer = loadingMessage => (
        <Dimmer active page>
            <Loader active inline="centered" size="big">
                {loadingMessage}...
            </Loader>
        </Dimmer>
    );

    renderUserLoginForm = () => (
        <MainContainer>
            {/* eslint-disable react/no-children-prop, react/jsx-no-bind */}
            <Route
                children={props => <Login onLogin={this.handleLogin} infoMessage={this.loginInfoMessage} {...props} />}
            />
            {/* eslint-enable react/no-children-prop, react/jsx-no-bind */}
        </MainContainer>
    );

    renderMainContainer = () => (
        <Sidebar.Pushable style={{ zIndex: 101 }}>
            <Header />
            <SidebarMenu />
            <VersionCheckContainer />
            <Sidebar.Pusher style={{ zIndex: 0, minHeight: "80vh" }}>
                <SnippetsStylesheet />
                <MainContainer>
                    <Route path="/" exact component={Welcome} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/app/login" render={this.loginComponent} />
                    <Route path="/app/logout" render={this.logoutComponent} />
                    <TopicsRoutes />
                    <TTTRoutes />
                    <OSMTRoutes />
                    <ConceptsRoutes />
                    <ExternalRoutes />
                    <KitBuilderRoutes />
                    <LUPRoutes />
                    <QuRoutes />
                    <TCRoutes />
                    <UserRoutes />
                    <MaintenanceRoutes />
                    <TabletRoutes />
                    {process.env.NODE_ENV !== "production" && ( // eslint-disable-line no-undef
                        <Route path="/sandbox/:path/:component" component={SandboxPage} />
                    )}
                </MainContainer>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );

    render() {
        const { isAppInitialized, isUserLoggedIn, loadingMessage } = this.state;
        return (
            <GlobalErrorBoundary>
                <Helmet
                    link={[
                        {
                            href: firebase.getFileURL("appFiles/content/fonts.css"),
                            rel: "stylesheet",
                            type: "text/css",
                        },
                        // Presence of `Stix` font is necessary for proper calculation of formula SVG size as a background image
                        // (see `getSvgRenderer`).
                        // URL for `href` attribute has to correspond to `@font-face {font-family: "Stix"}` definition in fonts.scss file.
                        //
                        // Cited: "Not all browsers support <link rel="preload">, and in those browsers, <link rel="preload">
                        //        will just be ignored. But every browser that supports preloading also supports WOFF2..."
                        // (https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/webfont-optimization)
                        {
                            href: firebase.getFileURL("appFiles/content/fonts/Stix/STIX2Text-Regular.woff2"),
                            rel: "preload",
                            as: "font",
                            type: "font/woff2",
                            crossorigin: "anonymous",
                        },
                    ]}
                />
                {isAppInitialized
                    ? isUserLoggedIn
                        ? this.renderMainContainer()
                        : this.renderUserLoginForm()
                    : this.renderLoadingDimmer(loadingMessage)}
            </GlobalErrorBoundary>
        );
    }
}

const mapStateToProps = () => ({});
const dispatchToProps = {
    onSetAuth,
};

export default hot(module)(compose(withRouter,connect(mapStateToProps, dispatchToProps))(App));
