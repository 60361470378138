import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Header, Loader } from "semantic-ui-react";

import EditableText from "../../../../component/EditableText";
import AccessControl from "../../../../auth/AccessControl";

class ResourceName extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        resourceId: PropTypes.string,
        editValidator: PropTypes.object,
        isSaving: PropTypes.bool,
        onUpdate: PropTypes.func,
    };

    handleNameUpdate = (value) => {
        const { resourceId, onUpdate } = this.props;
        onUpdate && onUpdate(resourceId, value);
    }

    render() {
        const { name, resourceId, editValidator, isSaving } = this.props;
        return resourceId && editValidator ? (
            <Dimmer.Dimmable dimmed={isSaving}>
                <Header as="h3">
                    <AccessControl  {...editValidator} renderNoAccess={name}>
                        <EditableText
                            text={name}
                            size="large"
                            onUpdate={this.handleNameUpdate}
                            canEdit={true}
                            width="400px"
                            id={resourceId}
                        />
                    </AccessControl>
                </Header>
                <Dimmer active={isSaving} inverted>
                    <Loader active={isSaving} inline="centered" size="small" />
                </Dimmer>
            </Dimmer.Dimmable>
        ) : (
            <Header as="h3">{name}</Header>
        );
    }
}

export default ResourceName;