import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getLessonWidgetsData } from "../LessonContentSelectors";
import { getWidgetLibraryData } from "../../WidgetLibrary/WidgetLibrarySelectors";
import { getWidgetTemplatesData } from "../../WidgetTemplates/WidgetTemplatesSelectors";

import LessonPreview from "./LessonPreview";

const LessonPreviewContainer = connect(
    (state, props) => ({
        widgets: getLessonWidgetsData(state, props),
        templatesData: getWidgetTemplatesData(state),
        widgetsData: getWidgetLibraryData(state),
    })
)(LessonPreview);

LessonPreviewContainer.displayName = "LessonPreviewContainer";
LessonPreviewContainer.propTypes = {
    lessonId: PropTypes.string.isRequired,
    outlineId: PropTypes.string.isRequired,
};

export default LessonPreviewContainer;