import { createSelector } from "reselect";
import { selectedLesson, problemSetCollection } from "../../LessonContentSelectors";
import { getProblemSetsAsArrayWithoutPreComp } from "../../../../QuestionUtilizator/ProblemSetManager/problemSetManagerSelectors";

export const titleFilter = state => state.kitBuilder.lessonProblemSets.titleFilter;
export const isChecking = state => state.kitBuilder.lessonProblemSets.isChecking;
export const checkResult = state => state.kitBuilder.lessonProblemSets.checkResult;

const getSearchByTitlePredicate = (searchFilter) => {
    if (searchFilter && searchFilter.length) {
        const pattern = searchFilter.toLowerCase();
        return (entity) => (entity.title && entity.title.toLowerCase().indexOf(pattern) !== -1);
    }
    return null;
};

export const getFilteredProblemSets = createSelector(
    selectedLesson,
    problemSetCollection,
    getProblemSetsAsArrayWithoutPreComp,
    titleFilter,
    (lesson, collection, problemSets, titleFilterName) => {
        if (problemSets && lesson) {
            let filteredPS = problemSets;
            if (lesson[collection]) {
                filteredPS = filteredPS.filter((ps) => {
                    if (lesson[collection][ps.key]) {
                        return false;
                    } else {
                        return true;
                    }
                });
            }
            if (titleFilterName.length) {
                const titleFilter = getSearchByTitlePredicate(titleFilterName);
                filteredPS = filteredPS.filter(titleFilter);
            }
            return filteredPS;
        }
    }
);
