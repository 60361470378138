const TYPES = {
    TEMPLATE_IPLANNER_REQUEST: "TEMPLATE_IPLANNER_REQUEST",
    TEMPLATE_IPLANNER_SUCCESS: "TEMPLATE_IPLANNER_SUCCESS",
    TEMPLATE_IPLANNER_FAILURE: "TEMPLATE_IPLANNER_FAILURE",
};

const onTemplateiPlannerRequest = (payload) => ({
    type: TYPES.TEMPLATE_IPLANNER_REQUEST,
    payload
});

const onTemplateiPlannerSuccess = (payload) => ({
    type: TYPES.TEMPLATE_IPLANNER_SUCCESS,
    payload
});

const onTemplateiPlannerFailure = (payload) => ({
    type: TYPES.TEMPLATE_IPLANNER_FAILURE,
    payload
});

export {
    TYPES,
    onTemplateiPlannerRequest,
    onTemplateiPlannerSuccess,
    onTemplateiPlannerFailure,
};
