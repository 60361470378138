import React from "react";
import PropTypes from "prop-types";
import { Icon, Popup, Form, Divider } from "semantic-ui-react";
import { swallowEvent } from "../utils/utils";

import "./SnippetCustomizer.scss";
import { SaveCancelButtons } from "bmd-react";

const getTitleFromData = (data) => data && data.title;

export class SnippetCustomizer extends React.PureComponent {
    static propTypes = {
        blockData: PropTypes.object,
        inline: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        hiddenTitle: PropTypes.bool,
    };

    state = {
        isOpen: false,
        title: null,
        hiddenTitle: false,
    }

    handleOpen = () => {
        this.setState({ isOpen: true, title: getTitleFromData(this.props.blockData), hiddenTitle: this.props.hiddenTitle || false });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    handleApply = () => {
        const { title, hiddenTitle } = this.state;
        const trimmed = (title || "").trim();
        this.props.onChange({ title: trimmed || null, hiddenTitle });
        this.handleClose();
    }

    handleTitleChange = (e, { value }) => {
        this.setState({ title: value.trim() });
    };

    handleTitleKeyDown = (e) => {
        if (e.keyCode === 13) { // on "Enter"
            this.handleApply();
        } else if (e.keyCode === 27) { // on "Esc"
            this.handleClose();
        }
    };

    handleMouseClick = e => swallowEvent(e);

    handleMouseDown = e => {
        if (e.target.tagName !== "INPUT") {
            e.preventDefault();
        }
        e.stopPropagation();
    };

    handleHideTitle = (e, { checked }) => this.setState({ hiddenTitle: checked });

    render() {
        const { isOpen, hiddenTitle } = this.state;
        const { inline } = this.props;

        return (
            <div
                className={isOpen ? "customizer isOpen" : "customizer"}
                {...inline && { style: { display: "inline" }}}
            >
                <Popup
                    trigger={<Icon link name="edit outline" style={{float: "right", fontSize: "initial"}} className="actionIconClass" title="Customize the block title" />}
                    on="click"
                    open={isOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    position="bottom right"
                    wide
                >
                    <div className="customizerPopup">
                        <Form
                            onClick={this.handleMouseClick}
                            onMouseDown={this.handleMouseDown}
                        >
                            <Form.Input
                                autoFocus
                                fluid
                                disabled={hiddenTitle}
                                name="title"
                                label="Custom title"
                                defaultValue={this.state.title}
                                placeholder="Fill title (empty to use default one)"
                                onChange={this.handleTitleChange}
                                onKeyDown={this.handleTitleKeyDown}
                                ref={this.setInputReference}
                            />
                            <Divider horizontal>OR</Divider>
                            <Form.Checkbox
                                label="Hide title"
                                checked={hiddenTitle}
                                onChange={this.handleHideTitle}
                            />
                        </Form>
                        <SaveCancelButtons size="mini" onSave={this.handleApply} onCancel={this.handleClose} />
                    </div>
                </Popup>
            </div>
        );
    }
}

export default SnippetCustomizer;
