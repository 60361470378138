const TYPES = {
    LESSON_PROBLEM_SET_EDIT_PROBLEM_SET: "KitBuilder/LessonProblemSet/EDIT_PROBLEM_SET",
    LESSON_PROBLEM_SET_SET_PROBLEM_SET: "KitBuilder/LessonProblemSet/SET_PROBLEM_SET",
    LESSON_PROBLEM_SET_SHUFFLE: "KitBuilder/LessonProblemSet/SHUFFLE",
    LESSON_PROBLEM_SET_SELECT_PROBLEM_SET: "KitBuilder/LessonProblemSet/SELECT_PROBLEM_SET",
    LESSON_PROBLEM_SET_REMOVE_PROBLEM_SET: "KitBuilder/LessonProblemSet/REMOVE_PROBLEM_SET",
    LESSON_PROBLEM_SET_SET_TITLE_FILTER: "KitBuilder/LessonProblemSet/SET_TITLE_FILTER",
    LESSON_PROBLEM_SET_CHECK_PROBLEM_SET: "KitBuilder/LessonProblemSet/CHECK_PROBLEM_SET",
    LESSON_PROBLEM_SET_CHECKED_PROBLEM_SET: "KitBuilder/LessonProblemSet/CHECKED_PROBLEM_SET",
    LESSON_PROBLEM_SET_CLONE_PROBLEM_SET: "KitBuilder/LessonProblemSet/CLONE_PROBLEM_SET",
    LESSON_PROBLEM_SET_SHUFFLE_FINISHED: "KitBuilder/LessonProblemSet/SHUFFLE_FINISHED",
};

const onEditProblemSet = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_EDIT_PROBLEM_SET,
    payload
});

const onSetProblemSet = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_SET_PROBLEM_SET,
    payload
});

const onShuffle = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_SHUFFLE,
    payload
});

const onSelectProblemSet = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_SELECT_PROBLEM_SET,
    payload
});

const onRemoveProblemSet = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_REMOVE_PROBLEM_SET,
    payload
});

const onSetTitleFilter = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_SET_TITLE_FILTER,
    payload
});

const onCheckProblemSet = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_CHECK_PROBLEM_SET,
    payload
});

const onCheckedProblemSet = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_CHECKED_PROBLEM_SET,
    payload
});

const onCloneProblemSet = (payload) => ({
    type: TYPES.LESSON_PROBLEM_SET_CLONE_PROBLEM_SET,
    payload
});

const onShuffleFinished = () => ({
    type: TYPES.LESSON_PROBLEM_SET_SHUFFLE_FINISHED
});

export {
    TYPES,
    onEditProblemSet,
    onSetProblemSet,
    onShuffle,
    onSelectProblemSet,
    onRemoveProblemSet,
    onSetTitleFilter,
    onCheckProblemSet,
    onCheckedProblemSet,
    onCloneProblemSet,
    onShuffleFinished,
};
