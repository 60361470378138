import React from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import EnvLabel from "../component/EnvLabel";
export default class YearComponent extends React.PureComponent {
    static propTypes = {
        selectedYear: PropTypes.object,
        yearOptions: PropTypes.array,
        onSelectYear: PropTypes.func.isRequired,
        onLoadYears: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    componentDidMount() {
        this.props.onLoadYears({ query: { $sort: { name: 1 } }});
    }

    handleChange = (e, { value }) => {
        const { onSelectYear } = this.props;
        onSelectYear(value);
    };

    render() {
        const { selectedYear, yearOptions, isLoading } = this.props;

        let trigger = (
            <span>
                <Icon name="calendar outline" /> {selectedYear && selectedYear.name}
            </span>
        );

        return (
            <div className="right menu">
                <Menu.Item>
                    <EnvLabel />
                </Menu.Item>
                <Menu.Menu position="right">
                    <Dropdown
                        loading={isLoading}
                        item
                        trigger={trigger}
                        options={yearOptions}
                        value={selectedYear ? selectedYear.id : ""}
                        onChange={this.handleChange}
                        scrolling
                    />
                </Menu.Menu>
            </div>
        );
    }
}
