import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Header, List, Checkbox } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import { getModulesForSubscribe } from "../tenantSelectors";
import {getSubscribedModules } from "../sharedTenantSelectors";

class TenantModuleEditor extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        tenant: PropTypes.object,
        onSave: PropTypes.func,
        modulesForSubscibe: PropTypes.array,
        subscribedModules: PropTypes.object,
    }

    state = {
        subscribedModules: {}
    }

    componentDidMount() {
        const { subscribedModules } = this.props;
        if (subscribedModules) {
            this.setState({ subscribedModules });
        }
    }

    handleToggleModule = (e, { name, checked }) => {
        const subscribedModules = { ...this.state.subscribedModules };
        subscribedModules[name] = checked || null;
        this.setState({ subscribedModules });
    }

    handleSave = () => {
        this.props.onSave(this.state.subscribedModules, this.props.modulesForSubscibe.filter(m => undefined !== this.state.subscribedModules[m.key]));
    }

    render() {
        const { onClose, tenant, modulesForSubscibe } = this.props;
        const { modules } = tenant;
        const { subscribedModules } = this.state;

        return (
            <Modal
                size="small"
                closeOnDimmerClick={false}
                open
            >
                <Modal.Header>Tenant packages management</Modal.Header>
                <Modal.Content>

                    <Header as="h4">Own packages:</Header>
                    {modules && 0 < Object.keys(modules).length && (
                        <List bulleted style={{paddingLeft: "1em"}}>
                            {Object.keys(modules).map(mId =>
                                <List.Item key={mId}>
                                    <List.Header>{modules[mId].name}</List.Header>
                                    <List.Description>{modules[mId].desc}</List.Description>
                                </List.Item>)}
                        </List>
                    ) || <em>No own packages found.</em>}
                    <Header as="h4">Available packages:</Header>
                    {modulesForSubscibe && 0 < modulesForSubscibe.length && (
                        <List style={{paddingLeft: "1em"}}>
                            {modulesForSubscibe.map(({ key, value}) => (
                                <List.Item key={key} title={value.desc}>
                                    <Checkbox
                                        name={key}
                                        label={value.name}
                                        checked={subscribedModules[key] || false}
                                        onChange={this.handleToggleModule}
                                    />
                                </List.Item>
                            ))}
                        </List>
                    ) || <em>No packages for subscription found.</em>}
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onSave={this.handleSave}
                        onCancel={onClose}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({
    modulesForSubscibe: getModulesForSubscribe(state, props),
    subscribedModules: getSubscribedModules(state, props),
});

export default connect(
    mapStateToProps,
)(TenantModuleEditor);
