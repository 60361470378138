import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { spinnerWhileLoading } from "../../../component/spinnerWhileLoading.jsx";
import {
    onSelectOutline,
    onOutlineRename,
    onLessonUnitMove,
    onLessonMove,
    onLessonTopicMove,
    onEditingText,
    onPublishLessons,
    onPublishReset,
}  from "../outlinesActions";
import {
    getSelectedOutlineId,
    getSelectedOutline,
    getOutlinesData,
    isLessonPropertiesDialogOpen,
    getOpenedTextEditorId,
    getPublishingProgress,
    getPublishingMessage,
} from "../outlinesSelectors";
import OutlineEditor from "./OutlineEditor";


const subscribeOutlineData = ({ selectedOutlineId }) => (
    selectedOutlineId ? [ `/outlines_data/${selectedOutlineId}/` ] : []
);

export default compose(
    connect((state, props) => ({
        selectedOutlineId: getSelectedOutlineId(state, props),
    })),
    firebaseConnect(subscribeOutlineData),
    connect((state, props) => ({
        selectedOutlineData: getSelectedOutline(state, props),
        showLessonProperties: isLessonPropertiesDialogOpen(state, props),
        openEditorId: getOpenedTextEditorId(state, props),
        _outlinesData: getOutlinesData(state, props),      // just for loading spinner
        publishingProgress: getPublishingProgress(state, props),
        publishingMessage: getPublishingMessage(state, props),
    }), {
        onSelectOutline,
        onOutlineRename,
        onLessonUnitMove,
        onLessonMove,
        onLessonTopicMove,
        onEditingText,
        onPublishLessons,
        onPublishReset,
    }),
    // show loading spinner while data are loading
    spinnerWhileLoading([ "_outlinesData" ])
)(OutlineEditor);