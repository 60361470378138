import React from "react";
import PropTypes from "prop-types";
import { replaceRelLinks } from "../../utils/conversionUtils";

export default class TextItemContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.shape({
            raw: PropTypes.object,
            html: PropTypes.string,
            custom_title: PropTypes.string,
        }),
    };
    render() {
        const { content } = this.props;
        return content && content.html ? (
            <div className="contains text" dangerouslySetInnerHTML={{ __html: content && content.html && replaceRelLinks(content.html) }} />
        ) : (
            <div className="missing text ui warning message">No text has been authored.</div>
        );
    }
}
