import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";

import LessonReleasedContentView from "./LessonReleasedContentView";
import { LessonVersionProps } from "../PublisherProps";
import { selectedProblemSetId } from "../../LessonContent/LessonContentSelectors";

const getContentPaths = (props) => {
    const { outlineId, lessonId } = props;
    return outlineId && lessonId && {
        releaseContent: `outlines_published/${outlineId}/release/${lessonId}`,
    };
};

const mapStateToProps = (state, props) => {
    const { firebase } = state;
    const paths = getContentPaths(props);
    
    return paths && {
        releaseContent: getVal(firebase, "data/" + paths.releaseContent),
        loading: (
            firebase.requesting[paths.releaseContent]
        ),
        selectedProblemSetId: selectedProblemSetId(state, props),
    };
};

const LessonReleasedContentViewContainer = compose(
    firebaseConnect((props) => {
        const paths = getContentPaths(props);
        return paths ? [ paths.releaseContent ] : [];
    }),
    connect(
        mapStateToProps,
    ),
)(LessonReleasedContentView);

LessonReleasedContentViewContainer.displayName = "LessonReleasedContentViewContainer";
LessonReleasedContentViewContainer.propTypes = {
    lessonId: PropTypes.string,
    outlineId: PropTypes.string,
    release: LessonVersionProps,
    loading: PropTypes.bool,
    entity: PropTypes.string,
};

export const ReleasePropType = LessonVersionProps;

export default LessonReleasedContentViewContainer;