import { TYPES } from "./ProblemManagementActions";
import { TYPES as CT_TYPES } from "../CompTest/CompTestActions";

const removeFromSearchResults = (state, id) => {
    if (state.allResults[id]) {
        const allResults = { ...state.allResults };
        delete allResults[id];

        const newState = { ...state, allResults };
        if (newState.searchResults[id]) {
            newState.searchResults = { ...newState.searchResults };
            delete newState.searchResults[id];
        }
        return newState;
    }
    return state;
};

const isMatchingToFilter = (problem, { selectedTopic, selectedTags }) => {
    if (selectedTopic || (selectedTags && selectedTags.length)) {
        if (selectedTopic) {
            const topic = selectedTopic && problem.topics && problem.topics[selectedTopic];
            if (!topic) {
                return false;
            }
        }
        if (selectedTags && selectedTags.length) {
            if (!problem.tags || selectedTags.some((tag) => !problem.tags.includes(tag))) {
                return false;
            }
        }
        return problem.id; // matching to filters
    }
    return false; // no filter configured => no need to update search/allResult
};

const initialState = {
    startAt: "",
    reverseFetch: false,
    searchResults: {},
    totalPages: 0,
    itemsPerPage: 50,
    totalRecords: 0,
    refreshKey: "",
    allResults: {},
    selectedTags: [],
    selectedTopic: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.QUESTION_BANK_NEXT_PAGE:
            return { ...state, startAt: action.last, reverseFetch: false };
        case TYPES.QUESTION_BANK_PREV_PAGE:
            return { ...state, startAt: action.last, reverseFetch: true };
        case TYPES.QUESTION_BANK_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.results,
                totalPages: action.totalPages,
                totalRecords: action.totalRecords,
                allResults: action.allResults,
                isLoading: false,
            };
        case TYPES.QUESTION_BANK_UPDATE_COUNT:
            return { ...state, totalRecords: action.count, totalPages: Math.ceil(action.count / state.itemsPerPage) };
        case TYPES.QUESTION_BANK_RESET_FILTER:
            return { ...state, ...initialState };

        case TYPES.QUESTION_BANK_APPLY_FILTER:
        case CT_TYPES.FILTER_QUESTIONS:
            return { ...state, ...action.payload, isLoading: true };

        case TYPES.QUESTION_BANK_ENTRY_CREATED:
            if (isMatchingToFilter(action.problem, state)) {
                const id = action.problem.id;
                return {
                    ...state,
                    searchResults: { ...state.searchResults, [id]: true },
                    allResults: { ...state.allResults, [id]: true }
                };
            }
            return state;
        case TYPES.QUESTION_BANK_DELETE_QUESTION:
        case CT_TYPES.DELETE_QUESTION:
            return {
                ...removeFromSearchResults(state, action.questionId),
                totalRecords: state.totalRecords - 1,
            };
        case "ASSIGN_TAGS_TOPICS":
            return {...state, refreshKey: Date.now() };
        default:
            return state;
    }
};

export default reducer;
