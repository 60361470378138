import React from "react";
import PropTypes from "prop-types";
import { Card, Icon } from "semantic-ui-react";

import TenantModules from "./TenantModules";
import TenantUserStats from "./TenantUserStats";
import "./TenantCard.scss";

export default class TenantCard extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number,
        name: PropTypes.string,
        is_isolated: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        auth_url: PropTypes.string,
        users: PropTypes.object,
        modules: PropTypes.object,
        onEditModules: PropTypes.func,
    }

    render() {
        const { id, name, is_isolated, auth_url, users, modules, onEditModules } = this.props;
        return (
            <Card color={!is_isolated ? "grey" : "blue"}>
                <Card.Content>
                    <Card.Header>{name}</Card.Header>
                    <Card.Meta><Icon className={!is_isolated ? "unlock alternate" : "lock alternate"} /> {!is_isolated ? "Non-isolated tenant": "Isolated tenant"}</Card.Meta>
                    <Card.Meta title={"Auth URL: " + auth_url} className="limitedTextWidth"><Icon name="sign in" /> {auth_url}</Card.Meta>
                </Card.Content>
                <TenantModules tenantId={id} tenantName={name} modules={modules} onEditModules={onEditModules} />
                <TenantUserStats usersByRole={users} />
            </Card>
        );
    }
}
