import React from "react";
import PropTypes from "prop-types";
import { Modifier, EditorState } from "draft-js";

import { ENTITY_TYPE, IMAGE_PLACEHOLDER_CHAR } from "../constants";
import FormulaEditor from "../../formulaEditor/FormulaEditor";
import { MATH_DEFAULTS } from "../../formulaEditor/formulaUtils";

const EMPTY_MATH = `<math ${MATH_DEFAULTS}></math>`;


const createFormulaFromText = (text) => {
    // Transform the selected text into the simplest MathML
    const stringParts = text.split(/(\s+)/);
    const mathParts = stringParts.map((part) => {
        if (part) {
            const maybeNumber = parseFloat(part);
            if (!isNaN(maybeNumber) && maybeNumber.toString() === part) {
                return "<mn>" + part + "</mn>";
            }
            else {
                if (part.trim()) {  // text
                    return "<mi>" + [...part].join("</mi><mi>") + "</mi>";
                }
                else {  // white-spaces only
                    const whiteSpaces = [...part].fill("&nbsp;");
                    return "<mo>" + whiteSpaces.join("</mo><mo>") + "</mo>";
                }
            }
        }
        return "";
    });

    return `<math ${MATH_DEFAULTS}>${mathParts.join("")}</math>`;
};

const getInitialFormula = (editorState, entity) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const { formula, svg } = (entity && entity.getData()) || {};

    if (formula) {
        // Existing formula is being edited
        return { formula, svg };
    }

    let initialFormula = "";

    if (selection.isCollapsed()) {
        // No selected text - create a new empty formula
        return { formula: EMPTY_MATH };
    }

    const blockKey = selection.getStartKey();

    if (blockKey === selection.getEndKey()) {
        initialFormula = contentState
            .getBlockForKey(blockKey)
            .getText()
            .slice(selection.getStartOffset(), selection.getEndOffset());
    }

    if (/\S+/.test(initialFormula)) {
        initialFormula = createFormulaFromText(initialFormula);
    } else {
        // Only whitespace characters in the current selection
        initialFormula = EMPTY_MATH;
    }

    return { formula: initialFormula };
};

const insertInlineFormula = (formulaData, editorState) => {
    const { formula, svg } = formulaData;
    let contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    contentState = contentState.createEntity(ENTITY_TYPE.MATH, "IMMUTABLE", { formula, svg });

    const entityKey = contentState.getLastCreatedEntityKey();
    contentState = Modifier.replaceText(contentState, selection, IMAGE_PLACEHOLDER_CHAR, null, entityKey);
    const newEditorState = EditorState.push(editorState, contentState, "insert-characters");
    return EditorState.forceSelection(newEditorState, contentState.getSelectionAfter());
};

const updateFormula = (formulaData, editorState, entityKey) => {
    const { formula, svg } = formulaData;
    let contentState = editorState.getCurrentContent();
    contentState = contentState.replaceEntityData(entityKey, { formula, svg });
    return EditorState.push(editorState, contentState, "apply-entity");
};

class FormulaSource extends React.Component {
    static propTypes = {
        editorState: PropTypes.object.isRequired,
        entity: PropTypes.object,
        entityKey: PropTypes.string,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
    };

    state = {};

    componentDidMount = () => {
        this.props.disableParentEditor();
        const { editorState, entity } = this.props;
        const { formula, svg } = getInitialFormula(editorState, entity);
        this.setState({ formula, svg });
    };

    handleSave = (formula) => {
        const { entityKey, editorState, setEditorState } = this.props;
        const newEditorState = entityKey
            ? updateFormula(formula, editorState, entityKey)
            : insertInlineFormula(formula, editorState, entityKey);

        setEditorState(newEditorState);
        this.handleClose();
    };

    handleClose = () => {
        const { enableParentEditor, onClose } = this.props;
        enableParentEditor();
        onClose();
    };

    render() {
        const { onDirty } = this.props;
        const { formula, svg } = this.state;

        return (formula
            ? (<FormulaEditor mathML={formula} svg={svg} onSave={this.handleSave} onCancel={this.handleClose} onDirty={onDirty} />)
            : null
        );
    }
}

export default FormulaSource;
