import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Menu, Icon } from "semantic-ui-react";

import PublishDropdown from "./PublishDropdown";
import { onLessonPublish } from "./PublisherActions";
import {
    getLessonPublishedStatus,
    getLessonPublisherRunning,
} from "./PublisherSelectors";
import { PublishedStatusProps } from "./PublisherProps";
import LessonFilesViewer from "./LessonFilesViewer";

class LessonPublisherMenu extends React.PureComponent {
    static propTypes = {
        lessonId: PropTypes.string,
        outlineId: PropTypes.string,
        /** Content of "/outlines_published/{outlineId}/lessons/{lessonId}"" */
        published: PublishedStatusProps,
        /** Actions */
        onLessonPublish: PropTypes.func.isRequired,
        /** In progress: preview, release, publish (preview & release) */
        running: PropTypes.oneOf(["preview", "release", "publish"]),
        hasUnprocessedReviews: PropTypes.bool.isRequired,
        activeView: PropTypes.string,
        onSelectTab: PropTypes.func,
        canPublish: PropTypes.bool,
    };

    state = {
        isViewing: false,
    };

    handlePublish = (mode) => {
        const { outlineId, lessonId, onLessonPublish } = this.props;
        onLessonPublish && onLessonPublish(outlineId, lessonId, mode);
    }

    handleViewFiles = () => {
        this.setState({ isViewing: true });
    }

    handleViewClose = () => {
        this.setState({ isViewing: false });
    }

    render() {
        const {
            outlineId,
            lessonId,
            published,
            running,
            hasUnprocessedReviews,
            onSelectTab,
            activeView,
            canPublish,
        } = this.props;

        return (
            <Menu.Menu position="right">
                <Menu.Item
                    active={activeView && activeView === "prints"}
                    key="prints"
                    name="prints"
                    disabled={!published || !published.release}
                    onClick={onSelectTab}
                    icon
                >
                    <Icon name="file pdf outline" style={{marginRight: "0.5em"}} />
                    Exports
                </Menu.Item>
                {this.state.isViewing && published && <LessonFilesViewer
                    {...published}
                    outlineId={outlineId}
                    lessonId={lessonId}
                    onClose={this.handleViewClose}
                />}
                {canPublish && <PublishDropdown {...published}
                    display="item"
                    running={running}
                    loading={published === undefined}
                    onAction={this.handlePublish}
                    onView={this.handleViewFiles}
                    hasUnprocessedReviews={hasUnprocessedReviews}
                />}
            </Menu.Menu>
        );
    }
}

const mapStateToProps = (state, props) => ({
    // depends on firebaseConnect(mapLessonPublishedStatus)
    // ...but it is used by LessonMainContainer
    published: getLessonPublishedStatus(state, props),
    running: getLessonPublisherRunning(state, props),
});

const dispatchToProps = dispatch => bindActionCreators({
    onLessonPublish,
}, dispatch);

export default connect(
    mapStateToProps,
    dispatchToProps,
)(LessonPublisherMenu);
