import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import { mapIconColor } from "../../component/ResourceLink/ResourceLink";
import ResourceName from "../components/ResourceName";

const HeaderStyle = { display: "grid", gridTemplateColumns: "85% 15%" };
const IconStyle = { textAlign: "right" };

class ResourceHeader extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        type: PropTypes.object,
        onClose: PropTypes.func,
        resourceId: PropTypes.string,
        editValidator: PropTypes.object,
    };

    render() {
        const { name, type, onClose, resourceId, editValidator } = this.props;
        const color = mapIconColor(type.icon);

        return (
            <div style={HeaderStyle}>
                <div>
                    <ResourceName name={name} resourceId={resourceId} editValidator={editValidator} />
                    <p><Icon {...color && { color }} name={type.icon} />{type.name}</p>
                </div>
                <div style={IconStyle}>
                    <Icon name="close" bordered title="Close resource detail" link onClick={onClose} />
                </div>
            </div>
        );
    }
}

export default ResourceHeader;