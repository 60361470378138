import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import LastEditorLabel from "../../../component/LastEditorLabel";
import { firebaseConnect } from "react-redux-firebase";
import { editor, editorTimestamp, editorId, author, authorId, authorTimestamp } from "./TemplateLastEditorSelectors";

const mapStateToProps = (state, props) => ({
    editor: editor(state, props),
    editorTimestamp: editorTimestamp(state, props),
    author: author(state, props),
    authorTimestamp: authorTimestamp(state, props),
});

const dispatchToProps = dispatch => {
    return bindActionCreators({ }, dispatch);
};

export default compose(
    connect((state, props) => ({
        editorId: editorId(state, props),
        authorId: authorId(state, props),
    })),
    firebaseConnect(({ editorId, authorId }) => {
        const watchArray = [];

        if (editorId) {
            watchArray.push({ path: "/user/" + editorId, type: "once" });
        }

        if (authorId) {
            watchArray.push({ path: "/user/" + authorId, type: "once" });
        }

        return watchArray;
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(LastEditorLabel);
