import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import { withFirebase } from "react-redux-firebase";
import { author, authorEmail, isSaving } from "./ResourceOwnerSelectors";
import ResourceOwner from "./ResourceOwner";
import { onSave } from "./ResourceOwnerActions";
import { getUsersOptions } from "../../../Maintenance/GeneratesDataForiPlanner/GeneratesDataForiPlannerSelectors";
import { tenantId } from "../../../Users/UserProfile/UserProfileSelectors";

const mapStateToProps = (state, props) => ({
    author: author(state, props),
    email: authorEmail(state, props),
    usersOptions: getUsersOptions(state),
    isSaving: isSaving(state),
    tenantId: tenantId(state),
});

const dispatchToProps = dispatch => {
    return bindActionCreators({ onSave }, dispatch);
};

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(ResourceOwner);
