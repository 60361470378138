import React from "react";
import PropTypes from "prop-types";
import { InfiniteTable } from "bmd-react";
import { Header, Segment, Loader, Dimmer } from "semantic-ui-react";
import { EditActionIcon } from "bmd-react";
import UserEditDialog from "./component/UserEditDialog";
import RolesLabels from "./component/RolesLabels";
import AccessControl from "../../auth/AccessControl";

export default class UserList extends React.PureComponent {
    static propTypes = {
        users: PropTypes.arrayOf(
            PropTypes.shape({
                first_name: PropTypes.string,
                last_name: PropTypes.string,
                email: PropTypes.string,
                roles: PropTypes.object,
                uid: PropTypes.string,
            })
        ),
        onEditation: PropTypes.func,
        onCloseEditation: PropTypes.func,
        editedUser: PropTypes.object,
        tenants: PropTypes.object,
        isLoading: PropTypes.bool,
    };

    handleEditation = uid => {
        this.props.onEditation(uid);
    };

    _columns = [
        {
            label: "First Name",
            dataKey: "first_name",
            width: 150,
            visible: true,
        },
        {
            label: "Last Name",
            dataKey: "last_name",
            width: 150,
            visible: true,
        },
        {
            label: "Email",
            dataKey: "email",
            width: 300,
            visible: true,
        },
        {
            label: "Roles",
            dataKey: "roleString",
            width: 400,
            visible: true,
            cellRenderer: ({ rowData }) => <RolesLabels roles={rowData.roleArray} />,
            filterMethod: ({ filter, cellData }) =>
                cellData &&
                cellData
                    .toLowerCase()
                    .replace("role_", "")
                    .indexOf(filter.toLowerCase()) !== -1,
        },
        {
            label: "Tenant",
            dataKey: "tenant_id",
            width: 150,
            visible: true,
            cellRenderer: ({ cellData }) =>
                this.props.tenants[`t${cellData}`] ? this.props.tenants[`t${cellData}`].name : "N/A",
            filterMethod: ({ filter, cellData }) =>
                cellData &&
                this.props.tenants[`t${cellData}`] &&
                this.props.tenants[`t${cellData}`].name.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
        },
        {
            label: "",
            dataKey: "uid",
            width: 50,
            visible: true,
            disableSort: true,
            cellRenderer: ({ cellData }) => (
                <AccessControl action="users:manage">
                    <EditActionIcon onClick={this.handleEditation} data={cellData} />
                </AccessControl>
            ),
        },
    ];

    render() {
        const { users, onCloseEditation, editedUser, isLoading } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Roles
                </Header>
                {editedUser && (
                    <AccessControl action="users:manage">
                        <UserEditDialog user={editedUser} onClose={onCloseEditation} {...this.props} />
                    </AccessControl>
                )}
                <Segment basic style={{ minHeight: "350px" }}>
                    <Dimmer active={isLoading} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <InfiniteTable data={users} columns={this._columns} tableName="users" defaultSortBy="last_name" />
                </Segment>
            </React.Fragment>
        );
    }
}
