import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button, Icon, Message } from "semantic-ui-react";

export default class FixRefs4ProblemsAndProblemSets extends PureComponent {
    static propTypes = {
        onFixProblemsRefs: PropTypes.func,
        inProgress: PropTypes.bool,
        progress: PropTypes.object,
    };

    render() {
        const { inProgress, progress, onFixProblemsRefs } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    {"Problems <=> Problem Sets and Problem Sets <=> Lessons references corrector"}
                </Header>
                <Segment secondary>
                    This page fix references between problems and problems sets and between problem sets and lessons (lesson homework and lesson problem sets).
                </Segment>
                {progress && (
                    <Message info>
                        <Message.Header></Message.Header>
                        <Message.List>
                            <Message.Item>Fixing refs from lessons to problem sets ...{progress.lessons.count} {progress.lessons.done ? <Icon name="check" color="green" /> : <Icon name="circle notched" loading />}</Message.Item>
                            {progress.lessons.done && <Message.Item>Fixing refs from problem sets to problems ...{progress.problemSets.count} {progress.problemSets.done ? <Icon name="check" color="green" /> : <Icon name="circle notched" loading />}</Message.Item>}
                            {progress.problemSets.done && <Message.Item>Fixing refs from problems to problem sets ...{progress.problems.count} {progress.problems.done ? <Icon name="check" color="green" /> : progress.syllabusProblem  ? <Icon name="cancel" title="School Course not found" color="red" /> : <Icon name="circle notched" loading />}</Message.Item>}
                        </Message.List>
                    </Message>
                )}
                {!inProgress && (
                    <Button primary onClick={onFixProblemsRefs}>
                        Fix references
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
