const TYPES = {
    LOAD_SCHOOL_COURSE_LIST: "SchoolCourse/LOAD_LIST",
    SET_SCHOOL_COURSE_LIST: "SchoolCourse/SET_SCHOOL_LIST",
};

const onSchoolCourseLoad = payload => {
    return {
        type: TYPES.LOAD_SCHOOL_COURSE_LIST,
        payload,
    };
};

const setSchooCourseList = payload => {
    return {
        type: TYPES.SET_SCHOOL_COURSE_LIST,
        payload,
    };
};

export { TYPES, onSchoolCourseLoad, setSchooCourseList };
