import firebase from "../../firebase";
import to from "await-to-js";

const basePath = "snippets";

export const paths = {
    basePath,
    toSnippet: (snippetId) => `${basePath}/${snippetId}`
};

/**
 * Get both database references to snippet
 *
 * @param {string} snippetId snippet identifier
 * @returns {object} Firebase references, {dataRef}
 */
export const getReferences = (snippetId) => {
    const dataRef = firebase.getFirebaseData(paths.toSnippet(snippetId));
    return { dataRef };
};

/**
 * Create a new snippet record.
 *
 * @param {object} config attributes of the new snippet
 * @return {string} key/identifier of created snippet
 */
export const createSnippet = async (config) => {
    const { name = "New block", ...data } = config || {};
    const dataRef = firebase.getFirebaseData(basePath);
    return await to(dataRef.push({ name, ...data }));
};

/**
 * Remove the snippet record.
 * 
 * @param {string} snippetId snippet identifier
 */
export const removeSnippet = async (snippetId) => {
    if (!snippetId) {
        return false;
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [snippetId] : null
    };
    return await to(baseRef.update(changes));
};

/**
 * Change name of the snippet.
 *
 * @param {string} snippetId snippet identifier
 * @param {string} name non-empty display name of snippet
 */
export const renameSnippet = async (snippetId, name) => {
    if (!snippetId || !name) {
        return false;
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`${snippetId}/name`] : name
    };
    return await to(baseRef.update(changes));
};


export const updateSnippet = async (snippetId, data) => {
    if (!snippetId) {
        return false;
    }
    const baseRef = firebase.getFirebaseData(paths.toSnippet(snippetId));
    const changes = { ...data };
    return await to(baseRef.update(changes));
};
