import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon, Dimmer, Loader, Message } from "semantic-ui-react";
import SortableTree, { getNodeAtPath, walk } from "react-sortable-tree";
import AddTopicPopup from "../AddTopicPopup";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import Theme from "./theme";
export default class TopicTree extends PureComponent {
    static propTypes = {
        collectionTree: PropTypes.array,
        onAddTopic: PropTypes.func.isRequired,
        onDeleteTopic: PropTypes.func.isRequired,
        onMoveTopic: PropTypes.func.isRequired,
        onUpdateTopic: PropTypes.func.isRequired,
        onExpandTopic: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
        _collection: PropTypes.object,
    };

    state = {
        popupContext: undefined,
    };

    showPopup = ({ target }, newState) => {
        const { popupContext } = this.state;

        if (popupContext) {
            this.setState(
                {
                    popupContext: null,
                    pathToAdd: undefined,
                    pathToDelete: undefined,
                    nodeToEdit: undefined,
                    errorMessage: undefined,
                },
                target === popupContext ? null : () => this.setState({ popupContext: target, ...newState })
            );
        } else {
            this.setState({ popupContext: target, ...newState });
        }
    };

    hidePopup = () => {
        this.setState({
            popupContext: null,
            pathToAdd: undefined,
            pathToDelete: undefined,
            nodeToEdit: undefined,
            errorMessage: undefined,
        });
    };

    handleAddNodeToPath = node => {
        const { pathToAdd } = this.state;
        if (this.verifyName(node.name)) {
            let parentNode = getNodeAtPath({
                treeData: this.props.collectionTree,
                path: pathToAdd,
                getNodeKey: this.getNodeKey,
            });
            let order = parentNode.node.children ? parentNode.node.children.length + 1 : 1;
            this.props.onAddTopic({ ...node, order, parent: parentNode.node.rootNode ? null : parentNode.node.key });
            this.setState({ pathToAdd: undefined, popupContext: null, errorMessage: undefined });
        }
    };

    handleRemoveNodeFromPath = () => {
        const { pathToDelete } = this.state;
        let nodeToDelete = getNodeAtPath({
            treeData: this.props.collectionTree,
            path: pathToDelete,
            getNodeKey: this.getNodeKey,
        });
        let keyToDelete = {};
        keyToDelete[nodeToDelete.node.key] = null;

        this.deleteChildRecursively(nodeToDelete.node.children, keyToDelete);
        this.props.onDeleteTopic({ keys: keyToDelete });
        this.setState({ pathToDelete: undefined, popupContext: null, errorMessage: undefined });
    };

    deleteChildRecursively(children, result) {
        if (children) {
            children.map(child => {
                result[child.key] = null;
                this.deleteChildRecursively(child.children, result);
            });
        }
    }

    handleMoveNode = data => {
        this.props.onMoveTopic(data);
    };

    handleChangeNodeAtPath = node => {
        const { nodeToEdit } = this.state;
        if (this.verifyName(node.name, nodeToEdit.key)) {
            this.props.onUpdateTopic({ key: nodeToEdit.key, node });
            this.setState({
                nodeToEdit: undefined,
                popupContext: null,
                errorMessage: undefined,
            });
        }
    };

    toggleVisibility = ({ node, expanded }) => {
        this.props.onExpandTopic({ [node.key]: expanded });
    };

    getNodeKey = ({ node }) => {
        return node.key;
    };

    canDrop = ({ nextParent }) => {
        return nextParent !== null;
    };

    /**
     * Check the tree for topic name uniqueness
     */
    verifyName = (name, key) => {
        let result = true;
        walk({
            treeData: this.props.collectionTree,
            getNodeKey: this.getNodeKey,
            ignoreCollapsed: false,
            callback: ({ node }) => {
                if (node.title === name && node.key !== key) {
                    this.setState({
                        errorMessage:
                            "Topic with the same name already exists in the collection. Topic names must be unique.",
                    });
                    result = false;
                }
            },
        });
        return result;
    };

    handleResetErrorMessage = () => {
        this.setState({ errorMessage: undefined });
    };

    isUsed = (node) => {
        if (node.isUsed) {
            return true;
        } else if (node.children) {
            for (const child of node.children) {
                if (this.isUsed(child)) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    generateNodeProps = ({ node, path }) => {
        if (!this.props.canEdit) {
            return { buttons: [] };
        }
        if (node.rootNode) {
            return {
                buttons: [
                    <Icon
                        key="add"
                        name="add"
                        color="grey"
                        data-treeindex={node.key}
                        onClick={e => this.showPopup(e, { pathToAdd: path })} // eslint-disable-line
                        title="Add new topic to the collection"
                    />,
                ],
            };
        } else {
            const buttons = [
                <Icon
                    key="edit"
                    name="write"
                    color="grey"
                    onClick={e => this.showPopup(e, { nodeToEdit: node })} // eslint-disable-line
                    title="Edit the topic name and objective"
                />,
                <Icon
                    key="add"
                    name="add"
                    color="grey"
                    data-treeindex={node.key}
                    onClick={e => this.showPopup(e, { pathToAdd: path })} // eslint-disable-line
                    title="Insert new topic under this one"
                />,
            ];
            if (!this.isUsed(node)) {
                buttons.push(
                    <Icon
                        key="delete"
                        name="trash alternate outline"
                        color="grey"
                        onClick={e => this.showPopup(e, { pathToDelete: path })} // eslint-disable-line
                        title="Delete the topic"
                    />
                );
            }
            return { buttons };
        }
    };

    findChild = (node, id) => {
        if (node && node.children) {
            const filteredNode = node.children.filter(n => n.key === id);
            if (filteredNode && 1 === filteredNode.length) {
                return filteredNode[0];
            }
        }
        return node;
    }

    isBranch = () => {
        const { pathToDelete } = this.state;
        const { collectionTree } = this.props;
        let pathList = [...pathToDelete];
        pathList.shift();
        let currentNode = collectionTree[0];
        for (const id of pathList) {
            currentNode = this.findChild(currentNode, id);
        }
        return currentNode && currentNode.children ? true : false;
    }

    render() {
        const { pathToDelete, pathToAdd, nodeToEdit, popupContext, errorMessage } = this.state;
        const { collectionTree, _collection } = this.props;
        const loading = undefined === _collection;

        return (
            <Dimmer.Dimmable dimmed={loading}>
                <Dimmer active={loading} inverted>
                    <Loader>Loading topic collection data...</Loader>
                </Dimmer>
                <div style={{ height: "80vh" }}>
                    {!loading && <React.Fragment>
                        <SortableTree
                            treeData={collectionTree}
                            onChange={() => {}} // eslint-disable-line
                            generateNodeProps={this.generateNodeProps}
                            onMoveNode={this.handleMoveNode}
                            getNodeKey={this.getNodeKey}
                            canDrop={this.canDrop}
                            onVisibilityToggle={this.toggleVisibility}
                            theme={Theme}
                        />
                        {pathToAdd && (
                            <AddTopicPopup
                                context={popupContext}
                                onCancel={this.hidePopup}
                                onAdd={this.handleAddNodeToPath}
                                errorMessage={errorMessage}
                                onChange={this.handleResetErrorMessage}
                            />
                        )}
                        {nodeToEdit && (
                            <AddTopicPopup
                                context={popupContext}
                                onCancel={this.hidePopup}
                                onAdd={this.handleChangeNodeAtPath}
                                defaultValues={nodeToEdit}
                                errorMessage={errorMessage}
                                onChange={this.handleResetErrorMessage}
                            />
                        )}
                        {pathToDelete && (
                            <ConfirmDeleteWithContext
                                context={popupContext}
                                onCancel={this.hidePopup}
                                onConfirm={this.handleRemoveNodeFromPath}
                                whatText="this topic"
                                confirmText="Delete"
                            >
                                {this.isBranch() && <Message warning content="You are removing tree branch, all subtopics will be removed too."/>}
                            </ConfirmDeleteWithContext>
                        )}
                    </React.Fragment>}
                </div>
            </Dimmer.Dimmable>
        );
    }
}
