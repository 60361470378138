import { createSelector } from "reselect";
import { getUsers, getFullName } from "../../../Users/UserList/UserListSelectors";
import { selectedLesson } from "../LessonContentSelectors";


export const editorId = createSelector(selectedLesson, (lesson) => (lesson && lesson.last_editor || null));

export const editor = createSelector(
    getUsers,
    editorId,
    getFullName
);

export const timestamp = createSelector(selectedLesson, (lesson) => (lesson && lesson.last_edit || null));
