import { TYPES } from "./tabletActions";
import services from "../feathersjs/services";

// The initial state of the ExampleSimple
const initialState = {
    isTabletEditOpen: false,
    editedDevice: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.OPEN_ADD_POPUP:
            return { ...state, isTabletEditOpen: action.payload, editedDevice: null };
        case TYPES.EDIT_DEVICE:
            return { ...state, isTabletEditOpen: true, editedDevice: action.payload };
        case services.oauthclient.types.SERVICES_OAUTHCLIENT_PATCH_FULFILLED:
            return { ...state, isTabletEditOpen: false, editedDevice: null };
        case services.oauthclient.types.SERVICES_OAUTHCLIENT_CREATE_FULFILLED:
            return { ...state, isTabletEditOpen: false, editedDevice: null };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
