import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { SaveCancelButtons } from "bmd-react";
import { Form, Modal, TextArea, Icon, Message } from "semantic-ui-react";
import { getErrorDetailsByCode } from "../../../../component/toast";

export const lessonTypes = [
    { key: "Standard", text: "Standard" , value: "Standard" },
    { key: "Review", text: "Review", value: "Review"},
    { key: "Assessment", text: "Assessment", value: "Assessment" }
];

export const lessonDefaults = {
    lessonName: "",
    lessonType: "Standard",
    lessonClasses: 1,
    lessonObjectives: "",
    lessonStartWeek: 1,
    lessonLength: 1,
};

export default class LessonProperties extends PureComponent {
    static propTypes = {
        lessonDataToEdit: PropTypes.object,
        editing: PropTypes.bool,
        outlineId: PropTypes.string,

        onLessonShowProperties: PropTypes.func,
        onLessonUpdateProperties: PropTypes.func,
    }

    static defaultProps = {
        lessonDataToEdit: {},
        editing: false,
    }

    state = {
        lessonName: null,
        lessonType: null,
        lessonClasses: null,
        lessonObjectives: null,
        // ESLint false positive: `updatedField` is used in getDerivedStateFromProps
        // eslint-disable-next-line react/no-unused-state
        updatedField: null,
        isProcessing: false,
        error: null,
    }

    static getDerivedStateFromProps(props, state) {
        const { name, type, description, classes, length, startWeek } = props.lessonDataToEdit;
        if (state.updatedField) {
            return null;
        }
        return {
            lessonName: name || lessonDefaults.lessonName,
            lessonType: type || lessonDefaults.lessonType,
            lessonClasses: classes || lessonDefaults.lessonClasses,
            lessonObjectives: description || lessonDefaults.lessonObjectives,
            lessonLength: length || lessonDefaults.lessonLength,
            lessonStartWeek: startWeek || lessonDefaults.lessonStartWeek,
            updatedField: false,
        };
    }

    handleCancel = () => {
        this.props.onLessonShowProperties(false);
    }

    handleSave = () => {
        const { key: lessonId, lesson_unit_id: lessonUnitId, ordering } = this.props.lessonDataToEdit;
        const { outlineId, onLessonUpdateProperties } = this.props;
        const { lessonName, lessonType, lessonClasses, lessonObjectives, lessonLength, lessonStartWeek } = this.state;
        this.setState({ isProcessing: true, error: null });
        onLessonUpdateProperties(
            outlineId,
            lessonUnitId,
            lessonId,
            lessonName,
            lessonType,
            lessonClasses,
            lessonObjectives,
            lessonLength,
            lessonStartWeek,
            ordering,
            this.handleFinish
        );
    }

    handleFinish = (error) => {
        if (!error) {
            this.props.onLessonShowProperties(false);
        }
        else {
            this.setState({ isProcessing: false, error });
        }
    }

    handleFieldChange = (e, data) => {
        this.setState({
            [data.id]: "lessonClasses" == data.id || "lessonLength" == data.id || "lessonStartWeek" == data.id ? Number.parseFloat(data.value) : data.value,
            // eslint-disable-next-line react/no-unused-state
            updatedField: data.id,
        });
    }

    render() {
        const { lessonDataToEdit, editing } = this.props;
        const { isProcessing, error, lessonName, lessonType, lessonClasses, lessonObjectives, lessonLength, lessonStartWeek } = this.state;
        const { name, type, description, classes, length, startWeek } = lessonDataToEdit;
        const saveDisabled = 
            (name === lessonName && type === lessonType && description === lessonObjectives && classes === lessonClasses && length === lessonLength
                && startWeek === lessonStartWeek)
            || ! lessonName || ! lessonClasses || lessonClasses <= 0 || ! Number.isSafeInteger(+lessonClasses) || isProcessing
            || ! lessonLength || lessonLength <= 0 || ! Number.isSafeInteger(+lessonLength)
            || ! lessonStartWeek || lessonStartWeek <= 0 || ! Number.isSafeInteger(+lessonStartWeek);
        return (
            <React.Fragment>
                <Modal.Header>{editing ? "Update Lesson Details" : "Provide Details For New Lesson"}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field
                            id="lessonName"
                            label="Name"
                            control={Form.Input}
                            placeholder="Write a nice lesson name here..."
                            required
                            defaultValue={lessonDataToEdit.name || lessonDefaults.lessonName}
                            onChange={this.handleFieldChange}
                            autoFocus
                        />
                        <Form.Group widths="equal">
                            <Form.Field
                                id="lessonType"
                                label="Lesson Type"
                                control={Form.Dropdown}
                                placeholder="Choose lesson type"
                                options={lessonTypes}
                                defaultValue={lessonDataToEdit.type || lessonDefaults.lessonType}
                                selection
                                fluid
                                onChange={this.handleFieldChange}
                            />
                            <Form.Field
                                id="lessonClasses"
                                label="Number of Classes"
                                control={Form.Input}
                                type="number"
                                min="1"
                                defaultValue={lessonDataToEdit.classes || lessonDefaults.lessonClasses}
                                placeholder="Enter number of lesson classes"
                                required
                                onChange={this.handleFieldChange}
                            />
                            <Form.Field
                                id="lessonStartWeek"
                                label="Start Week"
                                control={Form.Input}
                                type="number"
                                min="1"
                                defaultValue={lessonDataToEdit.startWeek || lessonDefaults.lessonStartWeek}
                                placeholder="Enter number of week, when the lesson starts"
                                required
                                onChange={this.handleFieldChange}
                            />
                            <Form.Field
                                id="lessonLength"
                                label="Length"
                                control={Form.Input}
                                type="number"
                                min="1"
                                defaultValue={lessonDataToEdit.length || lessonDefaults.lessonLength}
                                placeholder="Enter length of lesson in weeks"
                                required
                                onChange={this.handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Field
                            id="lessonObjectives"
                            label="Lesson Objective"
                            control={TextArea}
                            placeholder="Describe the objective of the lesson ..."
                            defaultValue={lessonDataToEdit.description || lessonDefaults.lessonObjectives}
                            onChange={this.handleFieldChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onCancel={this.handleCancel}
                        onSave={this.handleSave}
                        submitText={editing ? "Update" : "Create"}
                        saveDisabled={saveDisabled}
                    />
                </Modal.Actions>
                {error && <Message error icon>
                    <Icon name="times circle outline" />
                    <Message.Content>
                        <Message.Header>{error.header}</Message.Header>
                        <p />
                        {error.message}
                        <p><em>Reason: {getErrorDetailsByCode(error.code)}</em></p>
                    </Message.Content>
                </Message>}
                <ProcessingMessage isProcessing={isProcessing} editing={editing} />
            </React.Fragment>
        );
    }
}

const ProcessingMessage = ({ isProcessing, editing }) => {
    return isProcessing ? (
        <Message icon positive>
            <Icon name="circle notched" loading />
            <Message.Content>{editing ? "Updating lesson properties" : "Creating new lesson"} ...</Message.Content>
        </Message>
    ) : null;
};

ProcessingMessage.propTypes = {
    isProcessing: PropTypes.bool,
    editing: PropTypes.bool,
};
