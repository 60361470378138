import { all, select, takeEvery, takeLatest } from "redux-saga/effects";
import firebase from "../firebase";
import { loggedUserId } from "../Users/UserProfile/UserProfileSelectors";
import { toastError, DB_ERROR } from "../component/toast";
import { TYPES } from "./lockActions";

function* acquireLock({ payload: { lockType, itemId } }) {
    const userId = yield select(loggedUserId);
    try {
        const locksRef = firebase.getFirebaseData("locks");
        const lockId = yield locksRef.push().key;
        const updates = {};
        updates[`list/${lockId}`] = { ts: Date.now(), by: userId, type: lockType, itemId: itemId, inProgress: true };
        updates[`${lockType}/${itemId}`] = { id: lockId, by: userId };
        yield locksRef.update(updates);
    } catch (ex) {
        toastError({
            code: DB_ERROR,
            header: "Error while acquiring a lock for this item.",
            message: "You are not authorized to do so or someone has locked the lesson before you.",
        });
    }
}

function* releaseLock({ payload: { lockId } }) {
    try {
        const locksRef = firebase.getFirebaseData("locks");
        const lockDataSnap = yield locksRef
            .child("list")
            .child(lockId)
            .once("value");
        const lockData = lockDataSnap.val();
        const updates = {};
        updates[`list/${lockId}`] = null;
        updates[`${lockData.type}/${lockData.itemId}`] = null;
        yield locksRef.update(updates);
    } catch (err) {
        toastError({
            code: DB_ERROR,
            header: "Error while removing a lock.",
            message: err.message ? err.message : err,
        });
    }
}

export default function* lockSaga() {
    yield all([takeLatest(TYPES.LOCK_ACQUIRE, acquireLock), takeEvery(TYPES.LOCK_RELEASE, releaseLock)]);
}
