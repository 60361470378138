import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onSaved } from "./ResourceOwnerActions";
import { toastError, DB_ERROR } from "../../../component/toast";
import firebase from "../../../firebase";
import { onPatchResourceData } from "../../Resources/ResourcesActions";

function* saveOwner ({ payload: { resourceId, userId } }) {
    try {
        const matches = userId.match(/u(.*)@t(.*)/);
        const userUuid = matches[1];
        const userTenant = matches[2];
        const userResponse = yield firebase.tabletProxy({
            method: "GET",
            uri: `/user?uuid=${userUuid}&tenant_id=${userTenant}`,
            cancelRestrictToTenant: true,
        });
        const user = userResponse.data[0];
        const resource = yield firebase.tabletProxy({
            method: "PATCH",
            uri: `/resources/${resourceId}`,
            body: { created_by: user.id }
        });
        yield put(onPatchResourceData({resourceId, newData: resource.data, fieldsToUpdate: ["created_by", "createdBy"]}));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update resource owner", message: DB_ERROR });
    }
    yield put(onSaved());
}


export default function* resourceOwnerSaga() {
    yield all([
        takeLatest(TYPES.RESOURCE_OWNER_SAVE, saveOwner),
    ]);
}
