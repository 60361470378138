const TYPES = {
    CREATE_NEW_PROBLEM_SET: "CREATE_NEW_PROBLEM_SET",
    EDIT_SAVE_PROBLEM_SET: "EDIT_SAVE_PROBLEM_SET",
};

const createNewProblemSet = (problemSet, callback) => {
    return {
        type: TYPES.CREATE_NEW_PROBLEM_SET,
        problemSet,
        callback
    };
};

const editSaveProblemSet = problemSet => ({ type: TYPES.EDIT_SAVE_PROBLEM_SET, problemSet });

export { TYPES, createNewProblemSet, editSaveProblemSet };
