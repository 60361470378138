exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3GZ25CbxWb0vEiVIEk8egK {\n  display: block;\n  padding: 5px;\n  border: solid 1px lightblue;\n  border-radius: 5px;\n  min-width: 200px;\n  min-height: 120px;\n  margin-bottom: 10px;\n  background-color: #E1ECF4; }\n\n._1ykk3oeUFg4x6bibGYejh3 {\n  color: darkslateblue;\n  margin-left: 5px;\n  font-size: larger; }\n  ._1ykk3oeUFg4x6bibGYejh3._2DyvoA70jvfe6mTBEKY9K1 {\n    visibility: hidden;\n    color: rgba(0, 0, 0, 0.4); }\n\n._2GBGktrgFnzOg2g4emJbFs,\n._2GBGktrgFnzOg2g4emJbFs.ui.header {\n  display: block;\n  color: rgba(0, 0, 0, 0.4);\n  margin: .35em 0; }\n\n._3DMjgp9YJXP9cdRbCnBisP, ._2ZIqPPWAHPsm1hEfe_dWto {\n  display: block;\n  padding: 5px;\n  min-width: 200px; }\n  ._3DMjgp9YJXP9cdRbCnBisP p, ._2ZIqPPWAHPsm1hEfe_dWto p {\n    line-height: normal; }\n\n._3DMjgp9YJXP9cdRbCnBisP {\n  margin-bottom: 10px; }\n\n._3m0uR_bPyWbdw1elnnNURz {\n  padding: 1px; }\n  ._3m0uR_bPyWbdw1elnnNURz ._3n83VnO1w1eYwLxe9NkimQ {\n    font-weight: bold;\n    padding: 5px; }\n    ._3m0uR_bPyWbdw1elnnNURz ._3n83VnO1w1eYwLxe9NkimQ._2_c3PlOYpvg6sbMr72xh8K {\n      padding-left: 0px !important; }\n      ._3m0uR_bPyWbdw1elnnNURz ._3n83VnO1w1eYwLxe9NkimQ._2_c3PlOYpvg6sbMr72xh8K:before {\n        content: \"*\"; }\n\n._1pHTwpEWhJLkVoxGlbKhS5 {\n  border: 1px dashed gray;\n  padding: 0; }\n  ._1pHTwpEWhJLkVoxGlbKhS5 .droppable {\n    background-color: whitesmoke;\n    margin: 0;\n    padding: .5em 0; }\n    ._1pHTwpEWhJLkVoxGlbKhS5 .droppable.dragOver {\n      background-color: lightgoldenrodyellow; }\n  ._1pHTwpEWhJLkVoxGlbKhS5 ._3GZ25CbxWb0vEiVIEk8egK {\n    margin-left: 10px;\n    margin-right: 10px;\n    border-style: dashed;\n    min-height: unset;\n    max-height: 120px;\n    overflow: hidden; }\n\n._3e3zjA6XD3MRAAsKvKi0Im {\n  background-color: #2185d0 !important;\n  color: white !important; }\n", ""]);

// exports
exports.locals = {
	"qna": "_3GZ25CbxWb0vEiVIEk8egK",
	"qnaTitle": "_1ykk3oeUFg4x6bibGYejh3",
	"isParent": "_2DyvoA70jvfe6mTBEKY9K1",
	"qnaLabel": "_2GBGktrgFnzOg2g4emJbFs",
	"question": "_3DMjgp9YJXP9cdRbCnBisP",
	"answer": "_2ZIqPPWAHPsm1hEfe_dWto",
	"mcAnswer": "_3m0uR_bPyWbdw1elnnNURz",
	"choice": "_3n83VnO1w1eYwLxe9NkimQ",
	"isCorrect": "_2_c3PlOYpvg6sbMr72xh8K",
	"reorderZone": "_1pHTwpEWhJLkVoxGlbKhS5",
	"reorderActive": "_3e3zjA6XD3MRAAsKvKi0Im"
};