import {createSelector} from "reselect";
import { compareByNameCI } from "../../../utils/selectorUtils";

export const getResources = (state) => state.lup.resource.resources;
export const getResourceId = (state, props) => props.sipi && props.sipi.resource_id;
export const isCreating = (state) => state.lup.resource.isCreating;
export const isSearching = (state) => state.lup.resource.isSearching;
export const getSearchFilter = (state) => state.lup.resource.searchFilter;
export const getSearchResult = (state) => state.lup.resource.searchResult;
export const tags = (state) => state.lup.resource.tags;
export const isTagsLoading = (state) => state.lup.resource.isTagsLoading;

export const getResource = createSelector(
    getResources,
    getResourceId,
    (resources, resourceId) => {
        if (resources && resourceId && resources[resourceId]) {
            return resources[resourceId];
        }
    }
);

export const getTagsOptions = createSelector(tags, (tags) => {
    if (tags) {
        const sortedTags = tags.sort(compareByNameCI);
        return sortedTags.map(t => ({ key: t.name, value: t.name, text: t.name, description: t.description || null }));
    }
});
