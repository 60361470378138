import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Dropdown, Icon, Menu, Segment } from "semantic-ui-react";
import TopicTreeNode from "./TopicTreeNode";
import FilterMenu from "../../component/FilterMenu";
import { isObject } from "util";
import AdvancedFilterDialog from "./AdvancedFilterDialog";
import { RemovableTagLabel } from "../../LessonUnitPlanner/Resources/FilterBy/FilterByView";

export default class TopicTreeSelector extends PureComponent {
    static propTypes = {
        firebase: PropTypes.object,
        onSelectCollection: PropTypes.func.isRequired,
        selectedCollection: PropTypes.string,
        collections: PropTypes.array,
        collectionTree: PropTypes.object,
        onExpandTopic: PropTypes.func,
        onSearchChanged: PropTypes.func,
        searchFilter: PropTypes.string,
        onShowOnlySelected: PropTypes.func,
        showOnlySelected: PropTypes.bool,
        selectedTopics: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        onChange: PropTypes.func,
        single: PropTypes.bool,
        usedTopicIds: PropTypes.object,
        filterOnly: PropTypes.object,
        loading: PropTypes.bool,
        enableLessonFilter: PropTypes.bool,
        lessonFilter: PropTypes.object,
        onSetLessonFilter: PropTypes.func,
        getTopicTooltip: PropTypes.func,
    }

    state = {
        isOpenAdvancedFilter: false,
    };

    componentDidMount() {
        this.props.firebase.watchEvent("value", "/topic_collections/list");
        this.props.firebase.watchEvent("value", "/topic_collections/data");
    }

    handleSelectCollection = (e, { value }) => {
        this.props.onSelectCollection(value);
    };

    toggleVisibility = ({ node, expanded }) => {
        this.props.onExpandTopic({ [node]: expanded });
    };

    handleSearchFilter = (e, { value }) => {
        this.props.onSearchChanged(value);
    };

    handleSelectedOnly = (e, { checked }) => {
        this.props.onShowOnlySelected(checked);
    };

    handleOpenAdvancedFilter = () => this.setState({ isOpenAdvancedFilter: true });
    handleCloseAdvancedFilter = () => this.setState({ isOpenAdvancedFilter: false });

    getFilterBtnLabel = (showOnlySelected, lessonFilter) => {
        let count = 0;
        if (showOnlySelected) count++;
        if (lessonFilter) count++;

        if (0 == count) {
            return "Filter";
        } else {
            return `Filtered (by ${count})`;
        }
    };

    handleResetLessonFilter = () => {
        this.props.onSetLessonFilter(null);
    }
    handleResetShowSelectedOnly = (e) => {
        this.handleSelectedOnly(e, { checked: false });
    }

    render() {
        const {
            collections, selectedCollection, collectionTree, searchFilter, showOnlySelected,
            single, selectedTopics, onChange, usedTopicIds = {}, loading,
            filterOnly, enableLessonFilter, lessonFilter
        } = this.props;
        const { isOpenAdvancedFilter } = this.state;
        return (
            <React.Fragment>
                { isOpenAdvancedFilter && (
                    <AdvancedFilterDialog
                        onClose={this.handleCloseAdvancedFilter}
                        showOnlySelected={showOnlySelected}
                        onChangeSelectedOnly={this.handleSelectedOnly}
                        single={single}
                        enableLessonFilter={enableLessonFilter}
                    />
                )}
                <div style={{margin: "0.35em"}} >
                    <FilterMenu
                        entityName="selected topic"
                        {...isObject(selectedTopics) && { count: Object.keys(selectedTopics).length }}
                        onChange={this.handleSearchFilter}
                        filterName={searchFilter}
                    >
                        {(enableLessonFilter || !single) && <Menu.Item link title="Click here to set advanced filter." onClick={this.handleOpenAdvancedFilter}>
                            <Icon name="filter" /> {this.getFilterBtnLabel(showOnlySelected, enableLessonFilter && lessonFilter)}
                        </Menu.Item>}
                        <Menu.Item>
                            <Dropdown
                                selection
                                basic
                                icon={<Icon name="dropdown"  />}
                                placeholder="Select a collection"
                                options={collections}
                                onChange={this.handleSelectCollection}
                                value={selectedCollection}
                            />
                        </Menu.Item>
                    </FilterMenu>
                </div>
                {((lessonFilter && enableLessonFilter) || (!single && showOnlySelected)) && (
                    <React.Fragment>
                        <Menu text style={{ display: "inline-block", margin: "0" }}>
                            <Menu.Item header>FILTER BY: </Menu.Item>
                        </Menu>
                        {enableLessonFilter && lessonFilter && <RemovableTagLabel tag={lessonFilter.title} onRemove={this.handleResetLessonFilter} />}
                        {showOnlySelected && <RemovableTagLabel tag="Show selected only" onRemove={this.handleResetShowSelectedOnly} />}
                    </React.Fragment>
                )}
                <Segment style={{overflowY: "auto", height: "250px"}} loading={loading}>
                    <TopicTreeNode
                        treeNode={collectionTree}
                        onExpand={this.toggleVisibility}
                        onChange={onChange}
                        selectedTopics={selectedTopics}
                        selectedCollection={selectedCollection}
                        single={single}
                        usedTopicIds={usedTopicIds}
                        filterOnly={filterOnly}
                        getTooltip={this.props.getTopicTooltip}
                    />
                </Segment>
            </React.Fragment>
        );
    }
}
