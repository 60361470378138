import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Checkbox, Icon } from "semantic-ui-react";
import HelpPopup from "bmd-react/dist/HelpPopup";

export default class RenderPublicFlag extends PureComponent {
    static propTypes = {
        isPublic: PropTypes.bool,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        isSaving: PropTypes.bool,
    }

    render() {
        const { onChange, isPublic, disabled = false, isSaving = false } = this.props;
        return (
            <Form.Field style={{ marginLeft: "1em" }}>
                <Checkbox label="Public" onChange={onChange} checked={isPublic} disabled={disabled} name="isPublic"/>
                {isSaving ? <span style={{ marginLeft: "0.5em" }}><Icon name="circle notched" loading title="Updating..." /></span> : <HelpPopup content="If enabled, the resource will be shared to use for all SPORK users."/>}
            </Form.Field>
        );
    }
}
