import React, { Component } from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import ImageResize from "../renderer/Image/ImageResize";
import firebase from "../../../../firebase";
import { updateInlineImageSize, convertImageType, getImageName } from "./imageUtils";
import { ENTITY_TYPE } from "../constants";

class InlineImageDecorator extends Component {
    static propTypes = {
        contentState: PropTypes.object.isRequired,
        entityKey: PropTypes.string.isRequired,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        isParentReadOnly: PropTypes.func.isRequired,
        getEditorState: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        children: PropTypes.arrayOf(PropTypes.node).isRequired,
    };

    state = {
        isResizeOpen: false,
    };

    handleOpen = (e) => {
        if (!this.props.isParentReadOnly()) {
            this.props.disableParentEditor();
            this.setState({
                context: e.target,
                isResizeOpen: true,
            });
        }
    };

    handleClose = () => {
        this.props.enableParentEditor();
        this.setState({ isResizeOpen: false });
    };

    updateSize = (size) => {
        const { entityKey, getEditorState, setEditorState, onDirty } = this.props;
        setEditorState( updateInlineImageSize(getEditorState(), entityKey, size) );
        onDirty();
        this.forceUpdate();
    };

    handleSave = ({ width, height, type }) => {

        if (type) {
            const { entityKey, children, getEditorState, setEditorState, onDirty } = this.props;
            const blockKey = children[0].props.block.getKey();
            const newEditorState = convertImageType(type, { width, height }, blockKey, entityKey, getEditorState());
            setEditorState(newEditorState);
            onDirty();
        }
        else {
            this.updateSize({ width, height });
        }

        this.handleClose();
    };

    renderActionOptions = (width, height) => (
        <Popup
            context={this.state.context}
            content={<ImageResize type={ENTITY_TYPE.INLINE_IMAGE} width={width} height={height} onSave={this.handleSave} onCancel={this.handleClose} />}
            open={this.state.isResizeOpen}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            position="top left"
        />
    );

    render() {
        const { contentState, entityKey, reviewMode } = this.props;
        const { src, displayWidth, displayHeight, width, height } = contentState.getEntity(entityKey).getData();

        return (<span
            className="inlineImageDecorator inlineImage"
            onDoubleClick={this.handleOpen}
            style={{
                "--backgroundImage": `url("${firebase.getFileURL(src.storagePath)}")`,
                "--width": displayWidth,
                "--height": displayHeight,
            }}
            title={getImageName(src.storagePath)}
        >
            {this.props.children}
            {!reviewMode && this.renderActionOptions(width, height)}
        </span>);
    }
}

export default InlineImageDecorator;
