import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";

import SnippetPopup from "./SnippetPopup";
import { getSnippetsAsOptions } from "../../../../KitBuilder/Snippets/SnippetsManagerSelectors";

const mapStateToProps = function(state) {
    return {
        snippets: getSnippetsAsOptions(state),
    };
};

export default compose(
    firebaseConnect([ "snippets" ]),
    connect(mapStateToProps)
)(SnippetPopup);
