import React from "react";
import PropTypes from "prop-types";
import { Loader, Dimmer, Message } from "semantic-ui-react";

export default class SandboxPage extends React.Component {
    static propTypes = {
        match: PropTypes.object,
    };

    state = {
        sandboxComponents: null,
    };

    componentDidMount = () => {
        import(/* webpackMode: "weak" */ "../sandbox/components")
            .then((importedComponents) => {
                this.setState({ sandboxComponents: importedComponents });
            })
            // eslint-disable-next-line no-console
            .catch((e) => console.log("Sandbox components loading failed.",
                "This page was probably required accidentally outside Sandbox:", e
            ));
    };

    render() {
        const { sandboxComponents } = this.state;
        const { path, component } = this.props.match.params;

        if (!sandboxComponents) {
            return (<Dimmer active page>
                <Loader active inline="centered" size="big">
                    Loading application...
                </Loader>
            </Dimmer>);
        }

        const SandboxComponent = sandboxComponents[path] && sandboxComponents[path][component];

        if (!SandboxComponent) {
            return (<Message warning>
                <Message.Header>Unknown Sandbox route</Message.Header>
                <p>No component was found for <b>{path} / {component}</b> route.</p>
            </Message>);
        }
        return (<SandboxComponent />);
    }
}
