import React from "react";
import { Route } from "react-router-dom";
import UserListContainer from "./UserList";
import AccessControl from "../auth";

const Routes = () => (
    <AccessControl action="users:manage">
        <Route key="route-usr-mgmt-list" path="/user/list" component={UserListContainer} />
    </AccessControl>
);

export default Routes;
