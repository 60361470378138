import { all, takeEvery, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onFixDataFinished } from "./FixiPlannerResourcesActions";
import { toastError, DB_ERROR } from "../../component/toast";
import { delay } from "redux-saga";

function* fixediPlannerResources() {
    try {
        const resourcesIds = { 1: {}, 2: {}};
        const resourcesSourceRepoIds = { 1: {}, 2: {}};
        for (const tenant of [1, 2]) {
            const response = yield firebase.tabletProxy({
                method: "GET",
                uri: `/resources?tenant_id=${tenant}`,
                cancelRestrictToTenant: true,
            });
            for (const resource of response.data) {
                if (resource.source_repo_id) {
                    resourcesIds[tenant][resource.id] = resource.source_repo_id;
                    resourcesSourceRepoIds[tenant][resource.source_repo_id] = resource.id;
                }
            }
        }
        let correct = 0;
        let wrong = 0;
        let notFound = 0;
        for (const tenant of [1, 2]) {
            let secondTenant = 1;
            if (1 === tenant) {
                secondTenant = 2;
            }
            for (const collection of ["syllabi", "sipi_templates"]) { // Goes through iPlanners and templates collections
                const syllabi = (yield firebase.getFirebaseData(`/${collection}/info`).orderByChild("tenant_id").equalTo(tenant).once("value")).val();
                const syllabusIds = Object.keys(syllabi);
                for (const syllabusId of syllabusIds) { // Goes through particular iPlanner and templates
                    const syllabusData = (yield firebase.getFirebaseData(`/${collection}/data/${syllabusId}`).once("value")).val();
                    if (syllabusData) { // Syllabus/template has data
                        for (const lessonId of Object.keys(syllabusData)) { // Goes through particular lessons
                            const lessonData = syllabusData[lessonId];
                            const changes = {};
                            for (const sipiId of Object.keys(lessonData)) { // Goes through particular SiPi
                                const sipi = lessonData[sipiId];
                                if (sipi.resource_id) { // SiPi with resources
                                    const resourceId = sipi.resource_id;
                                    if (resourcesIds[secondTenant][resourceId]) { // Resources is from second tenant
                                        const correctResourceId = resourcesSourceRepoIds[tenant][resourcesIds[secondTenant][resourceId]];
                                        if (correctResourceId) { // Found correct resource
                                            changes[`${sipiId}/resource_id`] = correctResourceId;
                                        } else {
                                            // SiPi with wrong resource for which doesn't exist equivalent with the right tenant
                                            // console.log(tenant, sipi);
                                            notFound++;
                                        }
                                        wrong++;
                                    } else {
                                        correct++;
                                    }
                                }
                            }
                            if (0 < Object.keys(changes).length) {
                                yield firebase.getFirebaseData(`/${collection}/data/${syllabusId}/${lessonId}`).update(changes);
                                yield delay(50);
                            }
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line no-console
        console.log(`Correct: ${correct}, Wrong: ${wrong}, Not found: ${notFound}`);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to fix iPlanner resources", message: DB_ERROR });
    }
    yield put(onFixDataFinished());
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.FIX_IPLANNER_RESOURCES_FIX_DATA, fixediPlannerResources),
    ]);

}