import React from "react";
import { withRouter } from "react-router-dom";
import { Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import AccessControl from "../auth";
import { tenantId } from "../Users/UserProfile/UserProfileSelectors";
import { SideBarMenuLink } from "../component/SidebarMenuLink";

// const TaskToolPriviliges = [ "problemSet:visit", "compTests:visit" ];
const TaskToolPriviliges = [ "problemSet:visit", "compTests:charter" ];

const TaskToolMenu = (props) => (
    <AccessControl actionArray={TaskToolPriviliges} data={props}>
        <Menu.Item>
            <Menu.Header>Task Tool</Menu.Header>
            <Menu.Menu>
                <AccessControl action="problemSet:visit">
                    <SideBarMenuLink to="/qu/problem-management" text="Problems" />
                    <SideBarMenuLink to="/qu/problem-set-manager" text="Problem Sets" />
                </AccessControl>
                <AccessControl action="compTests:charter" data={props}>
                    <SideBarMenuLink to="/qu/question-management" text={<React.Fragment><Icon name="shield" />Comprehensive Questions</React.Fragment>} />
                    <SideBarMenuLink to="/qu/comp-test-manager" text={<React.Fragment><Icon name="shield" />Comprehensive Tests</React.Fragment>} />
                </AccessControl>
            </Menu.Menu>
        </Menu.Item>
    </AccessControl>
);
TaskToolMenu.displayName = "TaskToolMenu";

const mapStateToProps = (state, props) => ({
    tenantId: tenantId(state, props)
});
const dispatchToProps = {};

export default withRouter(connect(mapStateToProps, dispatchToProps)(TaskToolMenu));