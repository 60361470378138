const TYPES = {
    RESOURCES_CLEAR_PAGE: "Resources/CLEAR_PAGE",
    RESOURCES_FETCH_LIST: "Resources/FETCH_LIST",
    RESOURCES_SET_LIST: "Resources/SET_LIST",
    RESOURCES_FETCH_DATA: "Resources/FETCH_DATA",
    RESOURCES_SET_DATA: "Resources/SET_DATA",
    RESOURCES_ENTRY_DETAILS: "Resources/ENTRY_DETAILS",
    RESOURCES_CLOSE_DETAILS: "Resources/CLOSE_DETAILS",
    RESOURCES_FILTER_OPTIONS: "Resources/FILTER_OPTIONS",
    RESOURCES_FILTER_BY_NAME: "Resources/FILTER_BY_NAME",
    RESOURCES_FILTER_BY_OPEN: "Resources/FILTER_BY_OPEN",
    RESOURCES_FILTER_BY_CLOSE: "Resources/FILTER_BY_CLOSE",
    RESOURCES_FILTER_BY_APPLY: "Resources/FILTER_BY_APPLY",
    RESOURCES_FETCH_TAGS: "Resources/FETCH_TAGS",
    RESOURCES_SET_TAGS: "Resources/SET_TAGS",
    RESOURCE_FETCH_COURSE_VARIANTS: "Resources/FETCH_COURSE_VARIANTS",
    RESOURCE_SET_COURSE_VARIANTS: "Resources/SET_COURSE_VARIANTS",
    RESOURCE_FETCH_SOURCE_TYPES: "Resources/FETCH_SOURCE_TYPES",
    RESOURCE_SET_SOURCE_TYPES: "Resources/SET_SOURCE_TYPES",
    RESOURCE_ADD_RESOURCE_TOPICS: "Resources/ADD_TOPICS",
    RESOURCE_REMOVE_RESOURCE_TOPIC: "Resources/REMOVE_TOPIC",
    RESOURCE_PATCH_RESOURCE_DATA: "Resources/PATCH_RESOURCE_DATA",
    RESOURCE_FETCH_USED_TOPICS: "Resources/FETCH_USED_TOPICS",
    RESOURCE_SET_USED_TOPICS: "Resources/SET_USED_TOPICS",
};

const onResourcesPageLeft = () => ({
    type: TYPES.RESOURCES_CLEAR_PAGE,
});

const onFetchAllResources = (selectedItem) => ({
    type: TYPES.RESOURCES_FETCH_LIST,
    payload: selectedItem,
});

const onSetResourcesList = (resources) => ({
    type: TYPES.RESOURCES_SET_LIST,
    payload: resources,
});

const onFetchResourceData = (id) => ({
    type: TYPES.RESOURCES_FETCH_DATA,
    payload: +id
});

const onSetResourceData = (id, data) => ({
    type: TYPES.RESOURCES_SET_DATA,
    payload: { id, data },
});

const onViewDetails = (id) => ({
    type: TYPES.RESOURCES_ENTRY_DETAILS,
    payload: +id,
});

const onCloseDetails = () => ({
    type: TYPES.RESOURCES_CLOSE_DETAILS,
});

const onOpenFilterBy = () => ({
    type: TYPES.RESOURCES_FILTER_BY_OPEN,
});

const onCloseFilterBy = (changes) => ({
    type: TYPES.RESOURCES_FILTER_BY_CLOSE,
    payload: changes,
});

const onApplyFilterBy = (changes) => ({
    type: TYPES.RESOURCES_FILTER_BY_APPLY,
    payload: changes,
});

const onFilterByName = (pattern) => {
    const trimmed = pattern && pattern.trim();
    return {
        type: TYPES.RESOURCES_FILTER_BY_NAME,
        payload: trimmed && trimmed.length ? pattern : null,
    };
};

const onFilterByTags = (tags) => {
    const truthy = Array.isArray(tags) ? tags.filter(Boolean) : [];
    return onApplyFilterBy({
        tags: truthy.length ? truthy : null,
    });
};

const onFilterByCourseVariant = (courseVariant = null) => onApplyFilterBy({ courseVariant });
const onFilterByResourceType = (resourceType = null) => onApplyFilterBy({ resourceType });
const onFilterByOwner = (owner = null) => onApplyFilterBy({ owner });
const onFilterByTopic = (topic = null) => onApplyFilterBy({ topic });

const onFetchUsedTags = () => ({
    type: TYPES.RESOURCES_FETCH_TAGS
});

const onSetUsedTags = (tags) => ({
    type: TYPES.RESOURCES_SET_TAGS,
    payload: tags
});

const onFetchUsedCourseVariants = () => ({
    type: TYPES.RESOURCE_FETCH_COURSE_VARIANTS
});

const onSetUsedCourseVariants = (payload) => ({
    type: TYPES.RESOURCE_SET_COURSE_VARIANTS,
    payload
});

const onFetchSourceTypes = () => ({
    type: TYPES.RESOURCE_FETCH_SOURCE_TYPES
});

const onSetSourceTypes = (payload) => ({
    type: TYPES.RESOURCE_SET_SOURCE_TYPES,
    payload
});

const onRemoveTopic = (payload) => ({
    type: TYPES.RESOURCE_REMOVE_RESOURCE_TOPIC,
    payload
});

const onAddTopics = (payload) => ({
    type: TYPES.RESOURCE_ADD_RESOURCE_TOPICS,
    payload
});

const onPatchResourceData = (payload) => ({
    type: TYPES.RESOURCE_PATCH_RESOURCE_DATA,
    payload
});

const onFetchUsedTopics = () => ({
    type: TYPES.RESOURCE_FETCH_USED_TOPICS
});

const onSetUsedTopics = (payload) => ({
    type: TYPES.RESOURCE_SET_USED_TOPICS,
    payload
});

export {
    TYPES,
    onResourcesPageLeft,
    onFetchAllResources,
    onSetResourcesList,
    onFetchResourceData,
    onSetResourceData,
    onViewDetails,
    onCloseDetails,
    onOpenFilterBy,
    onCloseFilterBy,
    onApplyFilterBy,
    onFilterByName,
    onFilterByTags,
    onFetchUsedTags,
    onSetUsedTags,
    onFilterByCourseVariant,
    onSetUsedCourseVariants,
    onFetchUsedCourseVariants,
    onFilterByResourceType,
    onFetchSourceTypes,
    onSetSourceTypes,
    onFilterByOwner,
    onRemoveTopic,
    onAddTopics,
    onPatchResourceData,
    onFetchUsedTopics,
    onSetUsedTopics,
    onFilterByTopic,
};
