import { all, takeLatest, put } from "redux-saga/effects";
import firebase from "../firebase";
import { onSelectObjectiveSet, onAddTopicError, onTopicShowDialog } from "./topicsActions";
import md5 from "md5";
import { TYPES } from "./topicsActions";
import { lessonManager } from "../KitBuilder/Outlines/DataSource";
import { onTopicLinkToBela } from "../TopicTransformationTool/BeLATopicCollection/belaTopicCollectionActions";

function getTopicHash(topic) {
    return md5(topic.concept.name + topic.objective.name + topic.objective.type);
}

export function* addConcept({ value, callbackOnError }) {
    if (value) {
        try {
            yield firebase.defaultApp
                .database()
                .ref("/concepts")
                .push({ name: value });
        } catch (error) {
            if (callbackOnError) yield put(callbackOnError(error));
        }
    }
}

export function* addKeyword({ value }) {
    if (value) {
        yield firebase.defaultApp
            .database()
            .ref("/keywords")
            .push({ name: value });
    }
}

// add new objective set and then update state with a new key
export function* addObjectiveSet({ value }) {
    if (value) {
        var newSetId = yield firebase.defaultApp
            .database()
            .ref("/objective_sets")
            .push({ name: value });
        yield put(onSelectObjectiveSet(newSetId.key));
    }
}

export function* addObjective({ objectiveSetId, value, callback }) {
    if (value) {
        let newObjectiveId = yield firebase.defaultApp
            .database()
            .ref("/objective_sets/" + objectiveSetId + "/objectives")
            .push(value);
        yield callback(newObjectiveId.key);
    }
}

export function* addTopic({ value, belaTopic }) {
    if (value) {
        try {
            let hash = getTopicHash(value);
            /* save new topic hash to index. If it fails (duplicity) then the rest won't be executed too. */
            yield firebase.defaultApp
                .database()
                .ref("topics_hash_index")
                .update({ [hash]: true });
            /* save new SPORK topic (with possible link to BeLA topic) */
            let newTopic = yield firebase.defaultApp
                .database()
                .ref("/topics/")
                .push({ ...value, hashKey: hash });
            /* link BeLA and SPORK topics together */
            if (belaTopic) {
                yield put(onTopicLinkToBela(newTopic.key, belaTopic));
            }
            /* close Add Topic dialog */
            yield put(onTopicShowDialog(false));
        } catch (error) {
            yield put(onAddTopicError(error));
        }
    }
}

export function* addOiiObjective({ value, onError }) {
    try {
        if (value) {
            yield firebase.defaultApp
                .database()
                .ref("/oii_objectives/")
                .push({ name: value });
        }
    } catch (error) {
        onError(error);
    }
}

export function* deleteTopic({ topic, callback }) {
    try {
        /* Remove the topic reference from list of topics associated with lessons */
        yield lessonManager.removeTopicFromAllLessons(topic.key);

        yield firebase.defaultApp
            .database()
            .ref("/topics/" + topic.key)
            .remove();
        yield firebase.defaultApp
            .database()
            .ref("/topics_hash_index/" + topic.hashKey)
            .remove();
        /* delete all references to this topic from linked BeLA topics */
        if (topic.bela_topic_id) {
            let updates = {};
            Object.keys(topic.bela_topic_id).map(
                belaId =>
                    (updates["topicCollectionsFromBeLA_data/" + topic.bela_topic_id[belaId] + "/spork_topics/" + topic.key] = null)
            );
            yield firebase.defaultApp
                .database()
                .ref()
                .update(updates);
        }
    } catch (error) {
        callback(error);
    }
}

export function* updateTopic({ key, hashKey, value }) {
    try {
        let hash = getTopicHash(value);
        // if unique parts of topic has changed, verify via hash key
        if (hash !== hashKey) {
            // try to save under new hash key
            yield firebase.defaultApp
                .database()
                .ref("topics_hash_index")
                .update({ [hash]: true });
            // remove old hash key from index
            yield firebase.defaultApp
                .database()
                .ref("topics_hash_index" + hashKey)
                .remove();
        }
        // update existing topic
        yield firebase.defaultApp
            .database()
            .ref("/topics/" + key)
            .update({ ...value, hashKey: hash });
        yield put(onTopicShowDialog(false));
    } catch (error) {
        yield put(onAddTopicError(error));
    }
}

export function* addHeading({ value }) {
    if (value) {
        yield firebase.defaultApp
            .database()
            .ref("/headings")
            .push({ name: value });
    }
}

export function* addSubHeading({ value }) {
    if (value) {
        yield firebase.defaultApp
            .database()
            .ref("/subheadings")
            .push({ name: value });
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.TOPIC_ADD_CONCEPT, addConcept),
        takeLatest(TYPES.TOPIC_ADD_KEYWORD, addKeyword),
        takeLatest(TYPES.TOPIC_ADD_OBJECTIVE_SET, addObjectiveSet),
        takeLatest(TYPES.TOPIC_ADD_OBJECTIVE, addObjective),
        takeLatest(TYPES.TOPIC_ADD_TOPIC, addTopic),
        takeLatest(TYPES.TOPIC_ADD_OII_OBJECTIVE, addOiiObjective),
        takeLatest(TYPES.TOPIC_DELETE_TOPIC, deleteTopic),
        takeLatest(TYPES.TOPIC_UPDATE_TOPIC, updateTopic),
        takeLatest(TYPES.TOPIC_ADD_HEADING, addHeading),
        takeLatest(TYPES.TOPIC_ADD_SUBHEADING, addSubHeading)
    ]);
}
