import React, { PureComponent } from "react";
import { Label } from "semantic-ui-react";
import PropTypes from "prop-types";

export default class ItemsFoundLabel extends PureComponent {
    static propTypes = {
        count: PropTypes.number,
        entityName: PropTypes.string,
        suffix: PropTypes.string,
    };

    composeLabel = () => {
        const { count, entityName = "item", suffix = "found." } = this.props;
        let label = "";
        if (0 == count) {
            label = `No ${entityName}s ${suffix}`;
        } else if (1 == count) {
            label = `One ${entityName} ${suffix}`;
        } else {
            label = `${count} ${entityName}s ${suffix}`;
        }
        return label;
    }

    render() {
        return (
            <Label style={{ width: "100%", textAlign: "center", marginTop: "1em", marginBottom: "1em"}}>
                {this.composeLabel()}
            </Label>
        );
    }
}
