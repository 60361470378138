import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LessonProblemSet from "./LessonProblemSet";
import { getSelectedProblemSet, makeGetLessonProblemSets, selectedLesson, isShuffleFinished } from "../LessonContentSelectors";
import { onSetProblemSet, onRemoveProblemSet, onShuffle, onEditProblemSet } from "./LessonProblemSetActions";
import { createNewProblemSet } from "../../../QuestionUtilizator/ProblemSetWizard/problemSetWizardActions";

import { isAuthorAtLeast, isSuperAuthorAtLeast } from "../../../Users/roleUtils";
import { userClaims } from "../../../Users/UserProfile/UserProfileSelectors";

const mapStateToPropsFactory = () => {
    const getLessonProblemSets = makeGetLessonProblemSets();
    return (state, props) => ({
        problemSet: getSelectedProblemSet(state, props),
        lessonProblemSets: getLessonProblemSets(state, props),
        selectedLesson: selectedLesson(state, props),
        isShuffleFinished: isShuffleFinished(state),
        isAuthorAtLeast: isAuthorAtLeast(userClaims(state)),
        isSuperAuthorAtLeast: isSuperAuthorAtLeast(userClaims(state)),
    });
};

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            onSetProblemSet,
            createNewProblemSet,
            onRemoveProblemSet,
            onEditProblemSet,
            onShuffle,
        },
        dispatch
    );

export default connect(mapStateToPropsFactory, dispatchToProps)(LessonProblemSet);
