import { all, takeLatest } from "redux-saga/effects";
import { TYPES } from "./TagsActions";
import firebase from "../../firebase";
import to from "await-to-js";
import { toastError, DB_ERROR } from "../../component/toast";

export function* saveTemplate({value}) {
    const tagsRef = firebase.getFirebaseData(value.path);
    let tags = {};
    value.value.map(t => tags[t] = true);
    const [ err ] = yield to(tagsRef.set({...tags}));
    if (err) {
        toastError({ code: err.code, header: "Failed to update Tags", message: DB_ERROR });
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.TAGS_SAVE, saveTemplate),
    ]);
}
