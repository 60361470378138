import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Icon, Message, Form, Divider } from "semantic-ui-react";

export default class GeneratesDataForiPlanner extends PureComponent {
    static propTypes = {
        inProgress: PropTypes.bool,
        kitOptions: PropTypes.array,
        progress: PropTypes.shape({
            template: PropTypes.bool,
            syllabus: PropTypes.bool,
        }),
        selectedYearId: PropTypes.number,
        onCourseVariantLoad: PropTypes.func,
        courseVariants: PropTypes.array,
        onGenerateData: PropTypes.func,
        onSchoolLoad: PropTypes.func,
        schoolOptions: PropTypes.array,
        usersOptions: PropTypes.array,
        firebase: PropTypes.object.isRequired,
    };

    componentDidMount() { 
        this.props.onCourseVariantLoad();
        this.props.onSchoolLoad();
        this.props.firebase.watchEvent("value", "user", null, {isQuery: true, queryParams: [ "orderByChild=valid", "equalTo=true" ]});
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedYearId !== this.props.selectedYearId) {
            this.props.onCourseVariantLoad();
        }
    }

    state = { 
        kitId: null,
        variantId: null,
        schoolId: null,
        userId: null,
        error: null,
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleGenerate = () => {
        const { kitId, variantId, schoolId, userId } = this.state;
        if (kitId && variantId && schoolId && userId) {
            this.setState({ error: null });
            this.props.onGenerateData({ kitId, variantId, schoolId, userId });
        } else {
            this.setState({ error: "Validation error, some of the required fields is empty."});
        }
    }

    render() {
        const { inProgress, progress, kitOptions, courseVariants, schoolOptions, usersOptions } = this.props;
        const { kitId, variantId, error, schoolId, userId } = this.state;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Generates testing data for iPlanner
                </Header>
                <Segment secondary>
                    On this page, you can convert your Author Kit outlines to testing data for iPlanner.
                </Segment>
                <Form>
                    <Form.Group>
                        <Form.Select
                            disabled={inProgress}
                            width="8"
                            name="kitId"
                            label="Author Kit"
                            placeholder="Select Author Kit as source of outline data"
                            options={kitOptions || []}
                            onChange={this.handleChange}
                            value={kitId}
                            required
                        />
                        <Form.Select
                            disabled={inProgress}
                            width="8"
                            name="variantId"
                            label="Course"
                            placeholder="Select Course"
                            options={courseVariants || []}
                            onChange={this.handleChange}
                            value={variantId}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select
                            disabled={inProgress}
                            width="8"
                            name="schoolId"
                            label="School"
                            placeholder="Select School"
                            options={schoolOptions || []}
                            onChange={this.handleChange}
                            value={schoolId}
                            required
                        />
                        <Form.Select
                            disabled={inProgress}
                            width="8"
                            name="userId"
                            label="Owner"
                            placeholder="Select user as owner of future data"
                            options={usersOptions || []}
                            onChange={this.handleChange}
                            value={userId}
                            required
                            search
                        />

                    </Form.Group>
                    <Form.Button disabled={inProgress} primary floated="right" onClick={this.handleGenerate} >Generate iPlanner data</Form.Button>
                </Form>
                <Divider fitted hidden clearing />
                {error && <Message error content={error} />}
                {(inProgress || progress.outline) && (
                    <Message info>
                        <Message.Header>Generating testing data for iPlanner</Message.Header>
                        <Message.List>
                            <Message.Item>Preparing outline data ...{progress.outline ? <Icon name="check" color="green" /> : <Icon name="circle notched" loading />}</Message.Item>
                            {progress.outline && <Message.Item>iPlanner Template ...{progress.template ? <Icon name="check" color="green" /> : <Icon name="circle notched" loading />}</Message.Item>}
                            {progress.template && <Message.Item>iPlanner ...{progress.syllabus ? <Icon name="check" color="green" /> : progress.syllabusProblem  ? <Icon name="cancel" title="School Course not found" color="red" /> : <Icon name="circle notched" loading />}</Message.Item>}
                        </Message.List>
                    </Message>
                )}
            </React.Fragment>
        );
    }
}
