import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { onLessonShowProperties, onLessonUpdateProperties } from "../../outlinesActions";
import { getLessonDataToEdit, getSelectedOutlineId } from "../../outlinesSelectors";
import LessonProperties from "./LessonProperties";


const mapStateToProps = function(state) {
    return {
        lessonDataToEdit: getLessonDataToEdit(state),
        outlineId: getSelectedOutlineId(state),
        editing: state.kitBuilder.outlines.editing,
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onLessonShowProperties,
            onLessonUpdateProperties,
        },
        dispatch
    );
};

export default compose(
    connect(mapStateToProps, dispatchToProps)
)(LessonProperties);