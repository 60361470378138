import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { Helmet } from "react-helmet";
import memoize from "memoize-one";

import { getSnippetsStylesheet } from "./SnippetsManagerSelectors";

const makeStylesheetLink = (href) => ({ href, rel: "stylesheet", type: "text/css" });
const getStylesheetLinks = memoize(
    (cssLoaded, cssToLoad) => [ cssLoaded, cssToLoad ].filter(Boolean).map(makeStylesheetLink)
);

class SnippetsStylesheet extends React.PureComponent {
    static propTypes = {
        href: PropTypes.string
    };

    static mapStateToProps = (state) => ({
        href: getSnippetsStylesheet(state) || null
    });

    state = {
        cssLoaded: null,
        cssToLoad: null,
    };

    static getDerivedStateFromProps({ href }, state) {
        const latest = state.cssToLoad || state.cssLoaded;
        if (href !== latest) {
            return { cssToLoad: href };
        }
        return null;
    }

    handleStylesheetLoaded = ({ target }) => {
        const { cssToLoad } = this.state;
        if (cssToLoad && cssToLoad === target.href) {
            this.setState({ cssLoaded: cssToLoad, cssToLoad: null });
        }
    }

    handleChangeClientState= (newState, { linkTags:created }) => {
        const currentHref = this.props.href;
        const currentLink = created && created.find((link) => link.href === currentHref);
        if (currentLink) {
            currentLink.addEventListener("load", this.handleStylesheetLoaded);
        }
    };

    render() {
        const { cssLoaded, cssToLoad } = this.state;
        const styles = getStylesheetLinks(cssLoaded, cssToLoad);
        return styles.length
            ? <Helmet link={styles} onChangeClientState={this.handleChangeClientState} />
            : null;
    }
}

/**
 * Link to CSS for snippets, stylesheet URL is loaded from Firebase.
 * 
 * It should be rendered only when user is authenticated as the access to
 * Firebase is protected and requires use of the valid authentication tokens.
 */
export default compose(
    firebaseConnect([ "fileLinks/snippetsCss" ]),
    connect(SnippetsStylesheet.mapStateToProps)
)(SnippetsStylesheet);
