import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import AddTopic from "../../../Topics/AddTopic";
import h2p from "html2plaintext";

export default class AddTopicModal extends PureComponent {
    static propTypes = {
        isDialogOpen: PropTypes.bool,
        selectedTopic: PropTypes.shape({
            topic_id: PropTypes.number,
            topic_description: PropTypes.string
        }),
        selectedCollection: PropTypes.object,
        showAddDialog: PropTypes.func.isRequired,
        onSetRecObjectiveSet: PropTypes.func.isRequired
    };

    state = {
        topic_description: ""
    };

    static getDerivedStateFromProps(nextProps) {
        return {
            topic_description: nextProps.selectedTopic && nextProps.selectedTopic.topic_description
                ? h2p(nextProps.selectedTopic.topic_description)
                : undefined
        };
    }

    handleClose = () => {
        this.props.showAddDialog(false);
    };

    handleAdd = topic => {
        const { selectedCollection, onSetRecObjectiveSet } = this.props;
        if (!selectedCollection.recommended_obj_set) {
            onSetRecObjectiveSet(selectedCollection, topic.objective_set.id);
        }
    };

    render() {
        const { selectedTopic, selectedCollection, isDialogOpen } = this.props;

        return (
            <Modal open={isDialogOpen} onClose={this.handleClose}>
                <Modal.Content>
                    <AddTopic
                        key={selectedTopic.topic_id}
                        onCancel={this.handleClose}
                        onAdd={this.handleAdd}
                        allowEditObjectives={true}
                        defaultValues={{
                            objectiveSetKey: selectedCollection.recommended_obj_set,
                            descriptionValue: this.state.topic_description
                        }}
                        belaTopic={selectedTopic}
                    />
                </Modal.Content>
            </Modal>
        );
    }
}
