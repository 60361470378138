import React from "react";
import { Message, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { serializeError } from "serialize-error";
import { reportToastError } from "../onError";
import styles from "./toast.module.scss";

export const DB_ERROR = "Database error occurred.";
export const FUNCTION_ERROR = "Remote function call failed.";
export const INTERACTION_ERROR = "Collision with another user action.";
export const SAGA_ERROR = "Data error occurred (Saga).";

export const getErrorDetailsByCode = code => {
    switch (code) {
        case "PERMISSION_DENIED":
            return "You do not have sufficient rights.";
        case "storage/unauthorized":
            return "You do not have sufficient permissions to Firebase Storage.";
        case "storage/object-not-found":
            return "The file does not exist in the file storage.";
        default:
            return code ? code : "Unknown error.";
    }
};

export const toastError = ({ code, header, message, options }) => {
    reportToastError(`[${code || ""}]${header}:${message}`);
    toast.error(
        ({ closeToast }) => (
            <Message icon error onDismiss={closeToast}>
                <Icon name="times circle outline" />
                <Message.Content>
                    <Message.Header>{header}</Message.Header>
                    <p />
                    {message}
                    <p>
                        <em>Reason: {getErrorDetailsByCode(code)}</em>
                    </p>
                </Message.Content>
            </Message>
        ),
        { closeButton: false, className: styles.toast_transparent, hideProgressBar: true, ...options }
    );
};

export const toastSuccess = ({ header, message, options, icon }) => {
    toast.success(
        ({ closeToast }) => (
            <Message icon success onDismiss={closeToast}>
                <Icon className={icon ? icon : "check circle outline"} />
                <Message.Content>
                    <Message.Header>{header}</Message.Header>
                    <p />
                    {message}
                </Message.Content>
            </Message>
        ),
        { closeButton: false, className: styles.toast_transparent, hideProgressBar: true, ...options }
    );
};

export const toastWarning = ({ header, message, options, icon }) => {
    toast.warn(
        ({ closeToast }) => (
            <Message icon warning onDismiss={closeToast}>
                <Icon className={icon || "warning sign"} />
                <Message.Content>
                    <Message.Header>{header || "Warning"}</Message.Header>
                    <p />
                    {message}
                </Message.Content>
            </Message>
        ),
        { closeButton: false, className: styles.toast_transparent, hideProgressBar: true, ...options }
    );
};

export const sagaToastError = (header, error) => {
    const code = error.code || SAGA_ERROR;
    const message = typeof error === "object" ? serializeError(error).message : error;
    toastError({ code, header, message });
};
