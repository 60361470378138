import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { Segment, Menu, Icon, Popup } from "semantic-ui-react";

import QuestionItemContent from "../../Widget/QuestionItemContent";
import { getSnippetsAsOptions } from "../../Snippets/SnippetsManagerSelectors";
import TemplateQuestionStyleDialog from "../../WidgetTemplates/TemplateEditor/TemplateQuestionStyleDialog";
import { onUpdateQuestionItem, onChangeItemTitle } from "../WidgetLibraryActions";
import { WidgetItem } from "../../Widget";
import ItemLabel from "../../component/ItemLabel";
import QuestionEditor from "../../../QuestionUtilizator/QuestionEditor";
import { onCreateQuestion, onEditQuestion } from "../../../QuestionUtilizator/ProblemManagement/ProblemManagementActions";
import { getQuestionBank } from "../../../QuestionUtilizator/ProblemManagement/problemManagementSelectors";

class QuestionItemEditor extends PureComponent {
    static propTypes = {
        itemId: PropTypes.string.isRequired,
        widgetId: PropTypes.string.isRequired,
        content: PropTypes.object,
        onUpdateQuestionItem: PropTypes.func.isRequired,
        snippets: PropTypes.array,
        template: PropTypes.object,
        canEdit: PropTypes.bool,
        onCreateQuestion: PropTypes.func,
        onEditQuestion: PropTypes.func,
        problems: PropTypes.array,
        tags: PropTypes.array,
        onChangeItemTitle: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool,
        orderNum: PropTypes.number.isRequired,
    };

    state = {
        isEditorOpen: false,
        isPopupOpen: false,
        isStyleOpen: false,
        editItem: null,
    }

    handleSetProblem = (problem_id) => {
        const { content, snippets } = this.props;
        if (content) {
            const { snippet_id, title } = content;
            this.handleUpdate(snippet_id, snippets, title, problem_id);
        } else {
            this.handleUpdate(null, snippets, null, problem_id);
        }
        this.handleClose();
    }

    handleOpen = () => this.setState({ isPopupOpen: true });
    handleClose = () => {
        if (this.state.isEditorOpen) {
            return;
        }
        this.setState({ isPopupOpen: false});
    }
    handleOpenStyleEditor = () => this.setState({ isStyleOpen: true, isPopupOpen: false });
    handleOpenEditor = () => this.setState({ isEditorOpen: true, isPopupOpen: false, editItem: null });
    handleCloseEditor = () => this.setState({ isEditorOpen: false });
    handleCloseStyleEditor = () => this.setState({ isStyleOpen: false });

    handleAddNewQuestion = (questionRecord) => {
        const { onAddNewQuestion, tags } = this.props;
        if (tags.length) {
            if (questionRecord.tags) {
                questionRecord.tags = tags.concat(questionRecord.tags);
            } else {
                questionRecord[tags] = tags;
            }
        }
        onAddNewQuestion(questionRecord, this.handleSetProblem);
        this.handleCloseEditor();
    }

    handleEditQuestion = (questionRecord) => {
        const { onEditQuestion } = this.props;
        onEditQuestion(questionRecord, this.handleUpdateData);
        this.handleCloseEditor();
    }


    handleOpenEditQuestion = () => {
        const { content, problems } = this.props;
        if (content.problem_id && problems) {
            const result = problems.filter(p => content.problem_id === p.id);
            if (1 == result.length) {
                this.setState({  isEditorOpen: true, isPopupOpen: false, editItem: result[0] });
            }
        }
    }

    handleUpdateQuestion = (snippet, title, problem_id) => {
        const { onUpdateQuestionItem, itemId, widgetId, orderNum } = this.props;
        onUpdateQuestionItem({
            widgetId,
            itemId,
            snippet,
            title,
            problem_id,
            orderNum,
        });
    }

    handleUpdateStyle = (snippet, title) => {
        const { content } = this.props;
        const { problem_id } = content;
        this.handleUpdateQuestion(snippet, title, problem_id);
        this.handleClose();
    }

    handleUpdate = (snippet_id, snippets, title, problem_id) => {
        if (snippet_id) {
            const snippet = snippets.filter(s => s.key === snippet_id)[0];
            this.handleUpdateQuestion(snippet, title, problem_id);
        } else {
            this.handleUpdateQuestion(null, title, problem_id);
        }
    }

    handleUpdateData = () => {
        const { content, snippets } = this.props;
        if (content) {
            const { snippet_id, title, problem_id } = content;
            this.handleUpdate(snippet_id, snippets, title, problem_id);
        } else {
            this.handleUpdate(null, snippets, null, null);
        }
        this.handleClose();
    }

    handleChangeTitle = ({ title, hiddenTitle }) => {
        const { widgetId, itemId, onChangeItemTitle } = this.props;
        onChangeItemTitle({ widgetId, itemId, title, hiddenTitle });
    }

    render() {
        const { snippets, content, template, canEdit, reviewMode } = this.props;
        const { name } = template;
        const { isEditorOpen, isPopupOpen, isStyleOpen, editItem } = this.state;
        return (
            <Segment style={{ backgroundColor: "inherit" }}>
                {isEditorOpen && (
                    <QuestionEditor
                        onAdd={this.handleAddNewQuestion}
                        isOpen={isEditorOpen}
                        onClose={this.handleCloseEditor}
                        onEdit={this.handleEditQuestion}
                        editMode={editItem ? true : false}
                        forbidParentQuestion={true}
                        {...editItem && { question: editItem }}
                    />)}
                {isStyleOpen && (
                    <TemplateQuestionStyleDialog
                        name={name}
                        content={content}
                        snippets={snippets}
                        onUpdate={this.handleUpdateStyle}
                        onClose={this.handleCloseStyleEditor}
                    />)}
                <ItemLabel item={template} >
                    {canEdit && !reviewMode && <div style={{textAlign: "right"}}>
                        <Popup
                            on="click"
                            trigger={<Icon link name="ellipsis vertical" />}
                            onOpen={this.handleOpen}
                            onClose={this.handleClose}
                            open={isPopupOpen}
                            {...isEditorOpen && { style: { zIndex: "100"}}}
                            position="bottom right"
                        >
                            <Popup.Content >
                                <Menu secondary vertical >
                                    {/* <Menu.Item>
                                        <Search
                                            fluid
                                            category
                                            loading={false}
                                            autoFocus
                                            title="Search for an existing question."
                                            placeholder="Search for an existing ..."
                                            input={{fluid: true}}
                                            noResultsMessage="No question found"
                                            noResultsDescription="Try to change search query or you can define a new one."
                                        />
                                    </Menu.Item> */}
                                    <Menu.Item onClick={this.handleOpenEditor} content="Create new question" />
                                    {content && content.problem_id && <Menu.Item as="a" onClick={this.handleOpenEditQuestion}>Edit current question</Menu.Item>}
                                    {/*
                                        Currently we don't want to shuffle answers in problems tied with widget
                                        <Menu.Item onClick={this.handleUpdateData} content="Refresh preview" />
                                    */}
                                    <Menu.Item onClick={this.handleOpenStyleEditor}>Style</Menu.Item>
                                </Menu>
                            </Popup.Content>
                        </Popup>
                    </div>}
                </ItemLabel>
                <WidgetItem template={template} content={content} onChangeTitle={!reviewMode ? this.handleChangeTitle : null}>
                    {content && <QuestionItemContent content={content} />}
                </WidgetItem>
            </Segment>
        );
    }
}

export default compose(
    firebaseConnect((props) => {
        if (props.content && props.content.problem_id) {
            return ["snippets", `problem/${props.content.problem_id}`];
        } else {
            return ["snippets"];
        }
    }),
    connect(
        (state) => ({
            snippets: getSnippetsAsOptions(state),
            problems: getQuestionBank(state),
        }),
        {
            onUpdateQuestionItem,
            onCreateQuestion,
            onEditQuestion,
            onChangeItemTitle,
        }
    )
)(QuestionItemEditor);
