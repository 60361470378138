import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getLessonWidgetsData, userRights, getFocusedWidget } from "../LessonContentSelectors";
import { getWidgetLibraryData } from "../../WidgetLibrary/WidgetLibrarySelectors";
import { getWidgetTemplatesData } from "../../WidgetTemplates/WidgetTemplatesSelectors";
import {
    onCloseItemEditor,
    onUpdateItem,
    onUpdateName,
} from "../../WidgetLibrary/WidgetLibraryActions";
import { onSetFocus } from "../LessonContentActions";

import LessonEditor from "./LessonEditor";

const LessonEditorContainer = connect(
    (state, props) => ({
        widgets: getLessonWidgetsData(state, props),
        templatesData: getWidgetTemplatesData(state),
        widgetsData: getWidgetLibraryData(state),
        userRights: userRights(state),
        focusedItem: getFocusedWidget(state),
    }), {
        onCloseItemEditor,
        onUpdateItem,
        onUpdateName,
        onSetFocus,
    },
)(LessonEditor);

LessonEditorContainer.displayName = "LessonEditorContainer";
LessonEditorContainer.propTypes = {
    lessonId: PropTypes.string.isRequired,
    outlineId: PropTypes.string.isRequired,
    canEdit: PropTypes.bool,
    canReview: PropTypes.bool,
    asReview: PropTypes.bool,
    isDirty: PropTypes.bool,
    onSetDirty: PropTypes.func,
};

export default LessonEditorContainer;