import { createSelector } from "reselect";
import { convertDatasetToList, getSearchByNamePredicate, denormalizeTopicKey, compareByOrdering, getSearchByOptionalIndexPredicate } from "../../utils/selectorUtils";
import { getSortedWidgets, moduleFilter, getWidgetsModules } from "../WidgetLibrary/WidgetLibrarySelectors";
import { getTopicCollectionData } from "../../TopicCollection/topicCollectionSelectors";
import { getProblemSets } from "../../QuestionUtilizator/ProblemSetManager/problemSetManagerSelectors";
import { REVIEW_TYPE, REVIEW_STATS_COLLECTION } from "../../component/seamlessEditor/bookEditor/constants";
import { fetchKitsData } from "../AKits/AKitsList/AKitsListSelectors";
import { createShuffledCss } from "../../../functions/src/shared/createShuffledCss";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";

export const selectedOutlineId = (state, props) => props.outlineId || props.match && props.match.params.outlineId;
export const selectedLessonId = (state, props) => props.lessonId || props.match && props.match.params.lessonId;
export const selectedLesson = (state, props) => state.firebase.data.outlines_data && state.firebase.data.outlines_data[props.outlineId] && state.firebase.data.outlines_data[props.outlineId].lessons && state.firebase.data.outlines_data[props.outlineId].lessons[props.lessonId];
export const getKitId = (state, props) => state.firebase.data.outlines_data && state.firebase.data.outlines_data[props.outlineId] && state.firebase.data.outlines_data[props.outlineId].kit_id;
export const searchFilter = (state) => state.kitBuilder.lessonContent.searchFilter;
export const tabIndex = (state) => state.kitBuilder.lessonContent.tabIndex;
export const isDirty = (state) => state.kitBuilder.lessonContent.isDirty;
export const getFocusedWidget = state => state.kitBuilder.lessonContent.focusedItem;

const topicFilter = (state) => state.kitBuilder.lessonContent.topicFilter;


export const getProblemSetsData = ({firebase}) => firebase.data.problem_sets_data;
export const getLessonHomework = createSelector(selectedLesson, (lesson) => lesson.homework || null);
export const getTopicFilter = createSelector(topicFilter, topicFilter => topicFilter);

const compareByKey = (l, r) => (l.key < r.key ? -1 : l.key > r.key ? 1 : 0);
const compareByNumber = (l, r) => {
    const lv = l.value || -1;
    const rv = r.value || -1;
    return lv - rv || compareByKey(l, r);
};

const getLessonWidgetsOrdered = (lesson) =>
    lesson && lesson.widgets
        ? convertDatasetToList(lesson.widgets).sort(compareByNumber)
        : [];

const getWidgetLibraryData = (state) => state.firebase.data.widgetLibrary && state.firebase.data.widgetLibrary.data;
const getWidgetLibraryInfo = (state) => state.firebase.data.widgetLibrary && state.firebase.data.widgetLibrary.list;

export const getLessonWidgetsData = createSelector(
    selectedLesson,
    getWidgetLibraryData,
    getWidgetLibraryInfo,
    (lesson, widgetsData, widgetsInfo) => getLessonWidgetsOrdered(lesson).map(({ key }) => {
        const widget = {
            key,
            value: widgetsInfo && { ...widgetsInfo[key] }
        };
        if (!widgetsData || undefined === widgetsData[key]) {
            widget.value = { ...widget.value, loading: true };
        } else {
            widget.value = { ...widget.value, ...widgetsData[key] };
        }
        return widget;
    })
);

export const getHighestWidgetPosition = createSelector(selectedLesson, (lesson) => {
    if (lesson && lesson.widgets) {
        let highestPosition = 0;
        const widgets = lesson.widgets;
        Object.keys(widgets).map(key => {
            if (widgets[key] > highestPosition) {
                highestPosition = widgets[key];
            }
        });
        return highestPosition;
    } else {
        return 0;
    }
});

export const filteredWidgetsAsOptions = createSelector(
    getSortedWidgets,
    selectedLesson,
    searchFilter,
    getTopicFilter,
    moduleFilter,
    getWidgetsModules,
    tenantId,
    (widgetsList, lesson, searchFilter, topicFilter, modFilter, widgetModules, tenant) => {
        if (widgetsList) {
            const widgets = lesson && lesson.widgets;
            const namePredicate = getSearchByNamePredicate(searchFilter);
            if (namePredicate) {
                widgetsList = widgetsList.filter(namePredicate);
            }
            const modulePredicate = getSearchByOptionalIndexPredicate(modFilter, widgetModules, tenant, [], true);
            widgetsList = widgetsList.filter(modulePredicate);
            const topicList = topicFilter && Object.keys(topicFilter) || [];
            return widgetsList.filter(widget => {
                if (widgets && widgets[widget.key]) {
                    return false;
                } else if (topicList.length) {
                    return widget.topics ? topicList.some(t => widget.topics[t]) : false;
                } else {
                    return true;
                }
            });
        }
        return [];
    }
);

export const getLessonTopicOptions = createSelector(
    getTopicCollectionData,
    selectedLesson,
    (topicsData, selectedLesson) => {
        const topics = [];
        selectedLesson && selectedLesson.topics && topicsData && Object.keys(selectedLesson.topics).map(id => {
            const [ collectionId, topicId ] = denormalizeTopicKey(id);
            const collection = topicsData[collectionId];
            if (collection && collection[topicId]) {
                topics.push({ key: id, value: collection[topicId] });
            }
        });
        return topics;
    }
);

const convertIntoKeyWithTrue = (output, v) => { output[v] = true; return output; };

export const uncoveredTopics = createSelector(
    getWidgetLibraryInfo,
    selectedLesson,
    (widgetsInfo, lesson) => {
        const lessonTopics = lesson && lesson.topics && Object.keys(lesson.topics);
        if (widgetsInfo && lessonTopics && lessonTopics.length) {
            const widgetTopics = lesson && lesson.widgets && (
                Object.keys(lesson.widgets)
                    .map(wid => widgetsInfo[wid] && widgetsInfo[wid].topics)
                    .filter(Boolean).flatMap(topics => Object.keys(topics))
                    .reduce(convertIntoKeyWithTrue, {})
            );
            const uncoveredTopics = widgetTopics ? (
                lessonTopics.filter(topic => !widgetTopics[topic]).reduce(convertIntoKeyWithTrue, {})
            ) : (
                lessonTopics.reduce(convertIntoKeyWithTrue, {})
            );
            return uncoveredTopics;
        }
        return {};
    }
);

export const uncoveredTopicsCount = createSelector(uncoveredTopics, (uncoveredTopics) => Object.keys(uncoveredTopics).length);

export const userRights = state => state.kitBuilder.lessonContent.userRights || {};
export const problemSetCollection = (state, props) => props.collection;

export const makeGetLessonProblemSets = () => createSelector(
    selectedLesson,
    getProblemSets,
    getProblemSetsData,
    problemSetCollection,
    (lesson, problemSets, problemSetsData, collection) => {
        const psetsIndex = lesson && collection && lesson[collection];
        const psetsArray = psetsIndex && problemSets && problemSetsData ? (
            Object.keys(psetsIndex)
                .filter((id) => problemSets[id])
                .map((id) => {
                    const pset = problemSets[id];
                    const { ordering } = psetsIndex[id];
                    const { reviewsCount } = problemSetsData[id] || {};

                    return { id, ordering, name: pset.title, tags: pset.tags, type: pset.setType, reviewsCount };
                })
                .sort(compareByOrdering)
        ) : undefined;
        return psetsArray;
    }
);

export const selectedProblemSetId = (state) => state.kitBuilder.lessonProblemSets.selectedProblemSetId;
export const isShuffleFinished = (state) => state.kitBuilder.lessonProblemSets.isShuffleFinished;
export const getSelectedProblemSet = createSelector(
    selectedProblemSetId,
    selectedLesson,
    problemSetCollection,
    getProblemSets,
    getProblemSetsData,
    (psId, lesson, collection, problemSets, problemSetsData) => {
        if (lesson && psId && collection && lesson[collection] && lesson[collection][psId] && problemSets && problemSets[psId]) {
            const problemSet = problemSets[psId];
            const { title, makeDeepClone, problems } = problemSet;
            const result = {...lesson[collection][psId], name: title, id: psId, cloningInProgress: makeDeepClone, problems };
            let usedInDifferentEntity = false;
            if ("homework" === collection) {
                if (problemSet.lessons_problem_sets || ( problemSet.lessons_homework && 1 < Object.keys(problemSet.lessons_homework).length)) {
                    usedInDifferentEntity = true;
                }
            } else {
                if (problemSet.lessons_homework || (problemSet.lessons_problem_sets && 1 < Object.keys(problemSet.lessons_problem_sets).length)) {
                    usedInDifferentEntity = true;
                }
            }

            let problemSetHtml = problemSetsData && problemSetsData[psId] && problemSetsData[psId].html;

            if (problemSetHtml) {
                const { shuffled_problems } = lesson[collection][psId];

                if (shuffled_problems) {
                    problemSetHtml = createShuffledCss(shuffled_problems) + problemSetHtml;
                }
            }
            result["html"] = problemSetHtml || "";
            result["usedInDifferentEntity"] = usedInDifferentEntity;
            return result;
        }
    }
);

export const getReviewsCount = createSelector(selectedLesson, getLessonWidgetsData, getProblemSetsData, (lesson, widgets, problemSetsData) => {
    const count = {
        [REVIEW_TYPE.REPLACE]: 0,
        [REVIEW_TYPE.INSERT]: 0,
        [REVIEW_TYPE.COMMENT_ONLY]: 0,
    };

    const countPerType = {
        [REVIEW_STATS_COLLECTION.WIDGETS]: { ...count},
        [REVIEW_STATS_COLLECTION.PROBLEM_SETS]: { ...count},
        [REVIEW_STATS_COLLECTION.HOMEWORK]: { ...count},
    };

    widgets && widgets.forEach(({ value }) => {
        if (value && value.items) {
            Object.values(value.items).forEach(({ reviewStats }) => {
                if (reviewStats) {
                    Object.entries(reviewStats).forEach(([ reviewType, entries ]) => countPerType[REVIEW_STATS_COLLECTION.WIDGETS][reviewType] += entries.length);
                }
            });
        }
    });

    if (lesson && problemSetsData && (lesson.problem_sets || lesson.homework)) {

        Object.keys(lesson.problem_sets || {}).forEach((problemSetId) => {
            const { reviewsCount } = problemSetsData[problemSetId] || {};

            if (reviewsCount) {
                Object.entries(reviewsCount).forEach(([ reviewType, count ]) => countPerType[REVIEW_STATS_COLLECTION.PROBLEM_SETS][reviewType] += count);
            }
        });

        Object.keys(lesson.homework || {}).forEach((problemSetId) => {
            const { reviewsCount } = problemSetsData[problemSetId] || {};

            if (reviewsCount) {
                Object.entries(reviewsCount).forEach(([ reviewType, count ]) => countPerType[REVIEW_STATS_COLLECTION.HOMEWORK][reviewType] += count);
            }
        });
    }

    return countPerType;
});

export const getReviews = createSelector(getLessonWidgetsData, (widgets) => {
    const reviews = {
        [REVIEW_TYPE.REPLACE]: [],
        [REVIEW_TYPE.INSERT]: [],
        [REVIEW_TYPE.COMMENT_ONLY]: [],
    };
    let empty = true;

    widgets && widgets.forEach(({ value }) => {
        if (value && value.items) {
            Object.values(value.items).forEach(({ reviewStats }) => {
                if (reviewStats) {
                    Object.entries(reviewStats).forEach(([ reviewType, entries ]) => {
                        empty = false;
                        reviews[reviewType] = reviews[reviewType].concat(entries);
                    });
                }
            });
        }
    });

    return empty ? null : reviews;
});

export const getUserCommentsProps = createSelector(
    getKitId,
    fetchKitsData,
    (kitId, kitsData) => {
        const kit = kitId && kitsData && kitsData[kitId];
        const commentRecipients = kit && kit.comment_recipients ? Object.keys(kit.comment_recipients) : [];
        const outlineName = kit && kit.name || null;

        return { commentRecipients, outlineName };
    }
);
