import React from "react";
import PropTypes from "prop-types";
import { Label, Icon, Menu } from "semantic-ui-react";

import { FilterParamsShape } from "./utils";

export class RemovableTagLabel extends React.PureComponent {
    static propTypes = {
        tag: PropTypes.string,
        onRemove: PropTypes.func,
    };

    handleRemove = () => {
        const { tag, onRemove } = this.props;
        onRemove && onRemove(tag);
    }

    render() {
        const { tag } = this.props;
        return (
            <Label key={tag} as="a" data={tag} >
                {tag}
                <Icon link name="close" onClick={this.handleRemove} />
            </Label>
        );
    }
}

const removeTagFrom = (tags, toRemove) => {
    const result = Array.isArray(tags) && tags.filter((tag) => tag !== toRemove);
    return result && result.length ? result : [];
};

export default class FilterByView extends React.PureComponent {
    static propTypes = {
        filterParams: FilterParamsShape,
        onFilterByCourseVariant: PropTypes.func,
        onFilterByTags: PropTypes.func,
        onFilterByResourceType: PropTypes.func,
        onFilterByOwner: PropTypes.func,
        onFilterByTopic: PropTypes.func,
        onCleanNameFilter: PropTypes.func,
        topicFilterName: PropTypes.string,
        header: PropTypes.string,
    };

    handleRemoveTag = (toRemove) => {
        const { onFilterByTags, filterParams } = this.props;
        onFilterByTags && onFilterByTags(removeTagFrom(filterParams.tags, toRemove));
    }

    handleRemoveVariant = () => {
        const { onFilterByCourseVariant } = this.props;
        onFilterByCourseVariant && onFilterByCourseVariant(null);
    }

    handleRemoveOwner = () => {
        const { onFilterByOwner } = this.props;
        onFilterByOwner && onFilterByOwner(null);
    }

    handleRemoveResourceType = () => {
        const { onFilterByResourceType } = this.props;
        onFilterByResourceType && onFilterByResourceType(null);
    }

    handleRemoveTopic = () => {
        const { onFilterByTopic } = this.props;
        onFilterByTopic && onFilterByTopic(null);
    }

    render() {
        const { filterParams, topicFilterName, header = "FILTERED BY", onCleanNameFilter } = this.props;
        const { tags, courseVariant, resourceType, owner, topic, name } = filterParams;
        return (
            <React.Fragment>
                <Menu text style={{ display: "inline-block", margin: "0" }}>
                    <Menu.Item header>{header}: </Menu.Item>
                </Menu>
                {tags && tags.map(tag => (
                    <RemovableTagLabel key={tag} tag={tag} onRemove={this.handleRemoveTag} />
                ))}
                {courseVariant && (
                    <RemovableTagLabel
                        key={`cv:${courseVariant.id}`}
                        tag={"Course: " + courseVariant.name}
                        onRemove={this.handleRemoveVariant}
                    />
                )}
                {resourceType && (
                    <RemovableTagLabel
                        key={`rt:${resourceType}`}
                        tag={"Resource type: " + resourceType}
                        onRemove={this.handleRemoveResourceType}
                    />
                )}
                {owner && (
                    <RemovableTagLabel
                        key={`owner:${owner.id}`}
                        tag={"Owner: " + owner.name}
                        onRemove={this.handleRemoveOwner}
                    />
                )}
                {topic && (
                    <RemovableTagLabel
                        key={`topic:${topic}`}
                        tag={"Topic: " + topicFilterName}
                        onRemove={this.handleRemoveTopic}
                    />
                )}
                {name && (
                    <RemovableTagLabel
                        key={`name:${name}`}
                        tag={"Name: " + name}
                        onRemove={onCleanNameFilter}
                    />
                )}
            </React.Fragment>
        );
    }
}
