import { createSelector } from "reselect";
import { mapCollectionToList, compareByNameCI } from "../../../utils/selectorUtils";

const transformConcept = data => {

    if (!data) {
        return data;
    }

    return { id: data.key, ...data };
};

const transformAndOrderConcepts = data => data && mapCollectionToList(data).map(item => transformConcept(item)).sort(compareByNameCI);
const getAllConcepts = state => state.firebase.data.concepts;
export const getOrderedConceptsAsArray = createSelector(getAllConcepts, transformAndOrderConcepts);
export const getConcepts = createSelector(getAllConcepts);