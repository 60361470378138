import { all, takeEvery, takeLatest, select, put } from "redux-saga/effects";

import { TYPES, onLogClear, onAdminSuccess } from "./AdminFunctionsActions";
import { isAdminRunning } from "./AdminFunctionsSelectors";

import resetStyledBlocks from "./StyledBlocksResetSaga";
import orphanedProblems from "./OrphanedProblemsSaga";
import migrateKits from "./component/MigrateKitsSaga";

function* adminExecute({ payload }) {
    const action = typeof payload === "string" ? payload : payload.action;
    const config = payload.config || undefined;
    if (action === "StyledBlocksReset") {
        yield resetStyledBlocks(config);
    } else if (action === "OrphanedProblems") {
        yield orphanedProblems(config);
    } else if ("KitMigration" === action) {
        yield migrateKits(config);
    }
}

function *adminCleanup() {
    const isRunning = yield select(isAdminRunning);
    if (!isRunning) {
        yield put(onAdminSuccess(null)),
        yield put(onLogClear());
    }
}

const LogLevels = {
    debug: "debug",
    message: "info",
    failure: "error",
    warning: "warn",
    success: "info",
};

function consoleLog({ payload:{ type, text, data, time } }) {
    const level = LogLevels[type] || "log";
    // eslint-disable-next-line no-console
    const write = console[level];
    if (write) {
        const stamp = (time && new Date(time).toISOString().split("T")[1]);
        data === undefined
            ? write(`[AF] ${stamp || ""}: ${text}`)
            : write(`[AF] ${stamp || ""}: ${text}`, data);
    }
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.ADMIN_LOG_WRITE, consoleLog),
        takeEvery(TYPES.ADMIN_EXECUTE, adminExecute),
        takeLatest(TYPES.ADMIN_CLEANUP, adminCleanup)
    ]);
}
