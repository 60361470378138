import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";

import OutlinePublisher from "./OutlinePublisher";
import { getOutlineLessonsPublished, getLessons  } from "./outlinePublisherSelectors";


export default compose(
    firebaseConnect(props => {
        if (props.outlineId){
            return [`outlines_published/${props.outlineId}/lessons`];
        }
    }),
    connect((state, props) => ({
        outlineLessonsPublished: getOutlineLessonsPublished(state, props),
        tableData: getLessons(props),
    })),
)(OutlinePublisher);
