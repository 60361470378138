/* eslint-disable indent */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import PageHeader from "../../component/PageHeader";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import { List, Checkbox, Menu, Icon, Popup, Message } from "semantic-ui-react";
import AdvancedSyllabusFilter from "./component/AdvancedSyllabusFilter";
import SyllabiAdvancedFilterView from "./component/SyllabiAdvancedFilterView";
import AccessControl from "../../auth";
import CreateSyllabusDialog from "./component/CreateSyllabusDialog";

export default class SyllabiList extends PureComponent {
  static propTypes = {
    syllabi: PropTypes.object,
    searchFilter: PropTypes.string,
    onChangeFilter: PropTypes.func,
    onSelectSyllabus: PropTypes.func,
    selectedYear: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    onSchoolLoad: PropTypes.func,
    schools: PropTypes.object,
    courseVariants: PropTypes.object,
    onAllCourseVariantLoad: PropTypes.func,
    selectedYearId: PropTypes.number,
    cvIsLoading: PropTypes.bool,
    schoolIsLoading: PropTypes.bool,
    teacherFilter: PropTypes.string,
    schoolFilter: PropTypes.string
  };

  componentDidMount() {
    this.props.onSchoolLoad();
    this.props.onAllCourseVariantLoad();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedYearId !== this.props.selectedYearId) {
      this.props.onAllCourseVariantLoad();
    }
  }

  state = {
    showOnlyMySyllabi: false,
    isCreatorOpen: false
  };

  handleOnClickListItem = (e, { id }) => {
    const { onSelectSyllabus } = this.props;
    onSelectSyllabus({ id });
  };

  handleToggle = (e, { checked }) =>
    this.setState({ showOnlyMySyllabi: checked });
  handleOpenCreator = () => this.setState({ isCreatorOpen: true });
  handleCloseCreator = () => this.setState({ isCreatorOpen: false });

  render() {
    const {
      syllabi,
      searchFilter,
      onChangeFilter,
      selectedYear,
      schools,
      courseVariants,
      cvIsLoading,
      schoolIsLoading,
      schoolFilter,
      teacherFilter
    } = this.props;
    const { showOnlyMySyllabi, isCreatorOpen } = this.state;
    let list = [];
    if (syllabi) {
      if (showOnlyMySyllabi) {
        list = syllabi.mySyllabi;
      } else {
        list = syllabi.allSyllabi;
      }
    }
    let isListFiltered =
      searchFilter != "" ||
      showOnlyMySyllabi ||
      !!schoolFilter ||
      !!teacherFilter;

    return (
      <React.Fragment>
        <PageHeader
          searchFilter={searchFilter}
          headerText="iPlanner"
          entityName="item"
          list={list}
          onChangeFilter={onChangeFilter}
          {...(selectedYear && { subHeaderText: `for ${selectedYear.name}` })}
        >
          <AdvancedSyllabusFilter />
          <AccessControl action="syllabi:create">
            <Menu.Item
              icon="plus"
              content="New"
              onClick={this.handleOpenCreator}
              title="Add new iPlanner"
            />
          </AccessControl>
          <Menu.Item style={{ padding: 0 }} position="right">
            <Checkbox
              onChange={this.handleToggle}
              label="Show only my iPlanners"
              toggle
            />
          </Menu.Item>
        </PageHeader>

        {isCreatorOpen && (
          <CreateSyllabusDialog onClose={this.handleCloseCreator} />
        )}

        {(!!schoolFilter || !!teacherFilter) && <SyllabiAdvancedFilterView />}

        {list.length == 0 ? (
          isListFiltered ? (
            <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
              <p>
                We cannot find any iPlanner matching your filtering criteria.
              </p>
            </NoDataPlaceholder>
          ) : (
            <NoDataPlaceholder
              text="Here we list iPlanners."
              icon="newspaper outline"
            >
              <p>
                An iPlanner is an online course syllabus. Teachers can
                customize syllabus content by adding Syllabus iPlanner
                Instruments to the existing syllabus outline.
              </p>
            </NoDataPlaceholder>
          )
        ) : (
          <SyllabiListTable
            list={list}
            handleOnClickListItem={this.handleOnClickListItem}
            courseVariants={courseVariants}
            cvIsLoading={cvIsLoading}
            schools={schools}
            schoolIsLoading={schoolIsLoading}
          />
        )}
        {/* Display only for Charter and Spring schools - until they stop using BeLA */}
        {(selectedYear && (selectedYear.tenant_id === 1 || selectedYear.tenant_id === 2)) && (
          <BelaHelpMessage />
        )}
      </React.Fragment>
    );
  }
}

const SyllabiListTable = ({
  list,
  handleOnClickListItem,
  courseVariants,
  cvIsLoading,
  schools,
  schoolIsLoading
}) => (
  <List selection>
    {list.map(({ key, value }) => (
      <List.Item
        id={key}
        key={key}
        onClick={handleOnClickListItem}
        style={{ marginBottom: "0.5em" }}
      >
        <List.Icon className="file alternate sporkPrimaryColorCharcoalGray" />
        <List.Content className="editable">
          <List.Header as="a" title={value.syllabus_name}>
            {value.syllabus_name}
          </List.Header>
          <List.Description
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              {(courseVariants && courseVariants[value.course_variant_id]) ||
                (cvIsLoading && (
                  <Icon title="Loading Courses" loading name="circle notched" />
                ))}
            </div>
            <div>
              {value.teacher instanceof Object &&
                `${value.teacher.first_name} ${value.teacher.last_name}`}
              {(schools &&
                schools[value.school_id] &&
                ` - ${schools[value.school_id].name}`) ||
                (schoolIsLoading && (
                  <Icon title="Loading schools" loading name="circle notched" />
                ))}
            </div>
          </List.Description>
        </List.Content>
      </List.Item>
    ))}
  </List>
);
SyllabiListTable.propTypes = {
  list: PropTypes.array,
  handleOnClickListItem: PropTypes.func,
  courseVariants: PropTypes.object,
  schools: PropTypes.object,
  cvIsLoading: PropTypes.bool,
  schoolIsLoading: PropTypes.bool
};

const BelaHelpMessage = () => (
  <Popup
    wide="very"
    trigger={
      <span style={{ fontSize: "smaller", fontStyle: "italic", color: "grey" }}>
        Can&apos;t find your iPlanner here?
      </span>
    }
    style={{ padding: 0 }}
  >
    <Message info>
      <div style={{ display: "flex", marginTop: "1em" }}>
        <div>
          <Icon name="stopwatch" size="big" />
        </div>
        <div style={{ marginLeft: "1.5em" }}>
          Give it a few more minutes.
          <br /> The synchronization of Syllabus from BeLA could take up to one
          hour.
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "2em" }}>
        <div>
          <Icon name="check square outline" size="big" />
        </div>
        <div style={{ marginLeft: "1.5em" }}>
          Make sure the syllabus in BeLA does have:
          <ul>
            <li>
              status <b>Distributed</b>
            </li>
            <li>
              <b>Ready for iPlanner</b> option enabled
            </li>
            <li>
              initialized <b>Syllabus Revision Report</b>
            </li>
          </ul>
        </div>
      </div>
    </Message>
  </Popup>
);
