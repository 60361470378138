import React from "react";
import { SideBarMenuLink } from "../component/SidebarMenuLink";
import { Menu, Accordion } from "semantic-ui-react";
import PropTypes from "prop-types";
import AccessControl from "../auth/AccessControl";

const menuIndex = "maintenance";

const SidebarMenu = ({ activeIndex, onClick }) => (
    <AccessControl action="maintenance:visit">
        <Menu.Item>
            <Accordion.Title
                active={activeIndex[menuIndex]}
                index={menuIndex}
                content="Admin"
                onClick={onClick}
            />
            <Accordion.Content active={activeIndex[menuIndex]}>
                <Menu.Menu>
                    <SideBarMenuLink to="/maintenance/logs" text="Error Log" />
                    <SideBarMenuLink to="/maintenance/importGlossary" text="Import Glossary" />
                    <SideBarMenuLink to="/maintenance/importHomework" text="Import Homework" />
                    <SideBarMenuLink to="/maintenance/convertLessonData" text="Convert Lesson Data" />
                    <SideBarMenuLink to="/maintenance/updateProblemTags" text="Re-index tags" />
                    <SideBarMenuLink to="/maintenance/updateLessonReviewStats" text="Update Lesson Reviews" />
                    <SideBarMenuLink to="/maintenance/generatesiPlannerData" text="Generates data for iPlanner" />
                    <SideBarMenuLink to="/maintenance/fixProblemsAndProblemSetsRefs" text="Fix Problems &amp; Problem Sets" />
                    <SideBarMenuLink to="/maintenance/createProblemSetsData" text="Create Problem Sets data (html, reviews)" />
                    <SideBarMenuLink to="/maintenance/adminFunctions" text="Admin Functions" />
                    <SideBarMenuLink to="/maintenance/fixiPlannerResources" text="Fix iPlanner resources" />
                </Menu.Menu>
            </Accordion.Content>
        </Menu.Item>
    </AccessControl>
);

SidebarMenu.propTypes = {
    activeIndex: PropTypes.object,
    onClick: PropTypes.func,
};

export default SidebarMenu;
