import React from "react";
import PropTypes from "prop-types";
import Widget, { previewContent } from "../../Widget";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, getVal, isLoaded } from "react-redux-firebase";
import { Segment, Loader, Dimmer } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";

const previewSegmentStyle = { fontSize: "0.75rem", minHeight: 0, height: "15em", overflowY: "auto", marginTop: 0 };

class TemplatePreview extends React.PureComponent {
    static propTypes = {
        templateId: PropTypes.string,
        templateData: PropTypes.object,
    };

    render() {
        const { templateId, templateData } = this.props;
        const loading = !isLoaded(templateData);
        const failure = loading ? null : (
            !templateData ? "Widget template not available." : null
        );
        return (
            <Dimmer.Dimmable>
                <Dimmer active={loading} inverted>
                    <Loader inverted>Loading template</Loader>
                </Dimmer>
                {!templateId ? (// no template was selected
                    <NoDataPlaceholder text="Choose a template first." style={previewSegmentStyle} />
                ) : failure ? ( // template load has failed
                    <NoDataPlaceholder text={failure} style={previewSegmentStyle} />
                ) : ( // template is loaded or being loaded
                    <Segment style={previewSegmentStyle}>
                        {templateId && templateData && (
                            <Widget
                                template={{ ...templateData, key: templateId }}
                                content={previewContent(templateData)}
                            />
                        )}
                    </Segment>
                )}
            </Dimmer.Dimmable>
        );
    }
}

const withDataLoader = compose(
    firebaseConnect(({ templateId }) => {
        return templateId && [ `widgetTemplates/data/${templateId}` ];
    }),
    connect(({ firebase }, { templateId }) => ({
        templateData: templateId && getVal(firebase, `data/widgetTemplates/data/${templateId}`),
    }))
);

export default withDataLoader(TemplatePreview);
