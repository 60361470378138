export const ENTITY_TYPE = {
    GLOSSARY: "GLOSSARY",
    IMAGE: "IMAGE",
    INLINE_IMAGE: "INLINE_IMAGE",
    MATH: "INLINE_MATH",
    SNIPPET: "SNIPPET",
    TABLE: "TABLE",
    TABLE_READONLY: "TABLE_READONLY",
    REVIEW: "REVIEW",
    HELP: "HELP",
    LESSON_LINK: "LESSON_LINK",
    OL: "ORDERED_LIST_ITEM",
};

export const REVIEW_TYPE = {
    REPLACE: "REPLACE",
    INSERT: "INSERT",
    COMMENT_ONLY: "COMMENT_ONLY",
};

export const REVIEW_STATS_COLLECTION = {
    WIDGETS: "widgets",
    PROBLEM_SETS: "problem_sets",
    HOMEWORK: "homework",
};

export const REVIEW_OPTIONS = {
    REMOVED_LIST_ITEM: "removedListItem",
    INSERTED_LIST_ITEM: "insertedListItem",
    INSERT_AS_BLOCK: "insertAsBlock",
    JOIN_WITH_NEXT: "joinWithNext",
};

export const REVIEW_PLACEHOLDER_CHAR = "\u200B";  // Zero Width Space

/**
 * `EM SPACE` - space, which width is equal to font-size (http://jkorpela.fi/chars/spaces.html).
 * It is used for rendering entities like INLINE_MATH / INLINE_IMAGE, when the image is not displayed directly,
 * but as a background image of properly scaled space character.
 */
export const IMAGE_PLACEHOLDER_CHAR = "\u2003";

export const MATHML_SOURCE_ATTRIBUTE = "original_mathml";

/**
 * EMPTY_HANDLER should be used rarely.
 * It is primary intended for situation when some feature is not finished yet,
 * but you want to set corresponding `PropTypes.func.isRequired` in all related components.
 * After finishing the feature it is simpler to remove only ` || EMPTY_HANDLER` at the top level component
 * and leave other components untouched;
 */
export const EMPTY_HANDLER = () => {};

export const LIST_NUMBERING_TYPE = {
    // These values correspond to CSS list-style-type property
    // https://www.w3schools.com/cssref/pr_list-style-type.asp
    LOWER_ALPHA: "lower-alpha",
    DECIMAL: "decimal",  // default numbering type
    DISC: "disc",
    CIRCLE: "circle",
    SQUARE: "square",
    PARAGRAPH: "none",
};

export const LIST_BULLET_SYMBOL = {
    [LIST_NUMBERING_TYPE.DISC]:   "•",  // "&bullet;"
    [LIST_NUMBERING_TYPE.CIRCLE]: "○",  // "&cir;"
    [LIST_NUMBERING_TYPE.SQUARE]: "▪",  // "&blacksquare;"
};

export const LIST_PROPERTIES = {
    NUMBERING_TYPE: "numberingType",
};

/**
 * Sometimes we have to lie about `EditorChangeType` e.g. in case of batch changes for keep consistency
 * of `ordered-list-item`.
 * (https://github.com/facebook/draft-js/blob/master/src/model/immutable/EditorChangeType.js)
 *
 * Such changes cannot be undone step by step, because after the first undo
 * such list item would be in inconsistent state (wrong numbering/depth) and method for fixing it would
 * be fired immediately => undone step would be fixed again => since that moment any other previous change
 * could not be undone.
 *
 * As a workaround we use `spellcheck-change` to trick undo stack. Then it is necessary to press Ctrl+Z repeatedly
 * (according to internal steps),
 * https://github.com/facebook/draft-js/blob/585af35c3a8c31fefb64bc884d4001faa96544d3/src/component/handlers/edit/commands/keyCommandUndo.js#L27
 */
export const CHANGE_TYPE_FOR_UNDO = "spellcheck-change";

export const CHARACTER_TYPE = {
    ORDINARY: "ORDINARY",
    ENTITY: "ENTITY",
    ATOMIC: "ATOMIC",
    END_OF_BLOCK: "END_OF_BLOCK"
};
