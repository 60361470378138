import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { getSelectedAKitData, enableSyncForCvs, syncStatus, getSyncData, enableSyncForOutline } from "./AKitEditorSelectors";
import { onSelectOutline } from "../../Outlines/outlinesActions";
import { getSelectedYearId } from "../../../Year/YearsSelectors";
import { onSync, onAllCourseVariantsLoad, onGetSyncStatus } from "./AKitEditorActions";

/* just because of spinnerWhileLoading */
import { spinnerWhileLoading } from "../../../component/spinnerWhileLoading.jsx";
import { getSelectedAKitId } from "../AKitsSelectors";
import { fetchSelectedKitData } from "./AKitEditorSelectors";

import AKitEditor from "./AKitEditor";
import { getAccessibleKits } from "../AKitsList/AKitsListSelectors";

const subscribeAKit = (props) => {
    const kitId = getSelectedAKitId(null, props);
    return kitId && props.accessible ? [
        `kits_data/${kitId}`,
        `sync_cv#orderByChild=kit_id&equalTo=${kitId}`,
    ] : [];
};

const watchAKitAndOutlineId = (state, ownProps) => {
    // needed by spinner
    const rawAKitData = fetchSelectedKitData(state, ownProps);
    // needed by selector getSelectedOutlineId (outlineSelectors)
    const outlineId = rawAKitData && rawAKitData.outline_id; 
    return { rawAKitData,  outlineId };
};

export default compose(
    firebaseConnect(["modules/list", "tenant_modules"]),
    connect((state, props) => {
        const kitId = getSelectedAKitId(null, props);
        const accessibleKits = getAccessibleKits(state, props);
        return { accessible: (null === accessibleKits || accessibleKits && accessibleKits[kitId] ? true : false) };
    }),
    firebaseConnect(subscribeAKit),
    connect(watchAKitAndOutlineId),
    connect((state, ownProps) => ({
        aKitData: getSelectedAKitData(state, ownProps),
        enableSyncForCvs: enableSyncForCvs(state, ownProps),
        enableSyncForOutline: enableSyncForOutline(state, ownProps),
        selectedYearId: getSelectedYearId(state, ownProps),
        syncStatus: syncStatus(state, ownProps),
        rawSyncData: getSyncData(state, ownProps),
    }), {
        onSelectOutline,
        onAllCourseVariantsLoad,
        onSync,
        onGetSyncStatus,
    }),
    // show loading spinner while data are loading
    spinnerWhileLoading([ "rawAKitData" ])
)(AKitEditor);