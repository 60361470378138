import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import { DroppableWrapper } from "../Dnd";
import LessonTopicsListContainer from "./LessonTopicsListContainer";

export default class LessonTopicsListDroppable extends PureComponent {
    static propTypes = {
        lessonId: PropTypes.string.isRequired,
        topicsList: PropTypes.array,
        canEdit: PropTypes.bool,
    };

    render() {
        const { lessonId, topicsList, canEdit } = this.props;
        return canEdit ? (<Droppable droppableId={lessonId} type="TOPIC">
            {provided => (<DroppableWrapper provided={provided} innerRef={provided.innerRef}>
                <LesssonTopicsListCont
                    lessonId={lessonId}
                    topicsList={topicsList}
                    canEdit={canEdit}
                    provided={provided}
                />
            </DroppableWrapper>)}
        </Droppable>) : (
            <LesssonTopicsListCont
                lessonId={lessonId}
                topicsList={topicsList}
                canEdit={canEdit}
                provided={{}}
            />
        );
    }
}

const LesssonTopicsListCont = ({lessonId, topicsList, canEdit, provided}) => (
    <LessonTopicsListContainer
        lessonId={lessonId}
        topicsList={topicsList}
        canEdit={canEdit}
        droppablePlaceholder={provided.placeholder}
    />
);

LesssonTopicsListCont.propTypes = {
    lessonId: PropTypes.string,
    topicsList: PropTypes.array,
    canEdit: PropTypes.bool,
    provided: PropTypes.object,
};
