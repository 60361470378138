exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body.problem-editor-opened.review-editor-opened #root {\n  overflow-y: hidden; }\n\n._1eLjzNSe0r63FovDVDGK1r {\n  max-height: 95%;\n  overflow-y: auto; }\n  ._1eLjzNSe0r63FovDVDGK1r.ui.dimmer.modals.page.visible.active {\n    max-height: 100%; }\n    ._1eLjzNSe0r63FovDVDGK1r.ui.dimmer.modals.page.visible.active.rVzTaLga2ifyuAKH4qTnK {\n      position: absolute;\n      height: var(--dimmerSpace);\n      max-height: var(--dimmerSpace); }\n\n._1Sh1Lkke6lArBJjhfrbzXw {\n  margin-top: 10px; }\n\n.fVQgj4QOT3WjE4noGehbK {\n  margin-top: 7px; }\n\n._3GtByHL--WiuexJH8dN43a {\n  display: none !important; }\n", ""]);

// exports
exports.locals = {
	"questionEditorModal": "_1eLjzNSe0r63FovDVDGK1r",
	"withReviewPopup": "rVzTaLga2ifyuAKH4qTnK",
	"questionTabs": "_1Sh1Lkke6lArBJjhfrbzXw",
	"typeSelect": "fVQgj4QOT3WjE4noGehbK",
	"hideMe": "_3GtByHL--WiuexJH8dN43a"
};