const TYPES = {
    CONVERT_LESSON_DATA: "CONVERT_LESSON_DATA",
    CONVERT_LESSON_DATA_FINISHED: "CONVERT_LESSON_DATA_FINISHED",
    CONVERT_LESSON_DATA_PROGRESS: "CONVERT_LESSON_DATA_PROGRESS",
};

const onConvertLessonData = () => ({
    type: TYPES.CONVERT_LESSON_DATA,
});

const onUpdateLessonConvertProgress = payload => ({
    type: TYPES.CONVERT_LESSON_DATA_PROGRESS,
    payload,
});

const onLessonConversionFinished = () => ({
    type: TYPES.CONVERT_LESSON_DATA_FINISHED,

});


export {
    TYPES,
    onConvertLessonData,
    onLessonConversionFinished,
    onUpdateLessonConvertProgress,
};
