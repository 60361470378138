import { all, takeLatest, select, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { getSelectedCollection } from "../tttSelectors";
import { TYPES, onSelectCollection } from "./belaTopicCollectionActions";

export function* changeTopicStatus({ path, value }) {
    if (value && path) {
        let selectedCollection = yield select(getSelectedCollection);
        yield firebase.defaultApp
            .database()
            .ref("/topicCollectionsFromBeLA_data/" + selectedCollection.id + "/" + path)
            .update({ ttt_status: value });
    }
}

export function* linkTopicToBela({ sporkTopicId, belaTopic }) {
    if (sporkTopicId && belaTopic) {
        yield firebase.defaultApp
            .database()
            .ref("/topics/" + sporkTopicId + "/bela_topic_id")
            .update({ [belaTopic.topic_id]: belaTopic.path });
        /* add SPORK topic ID to selected BeLA topic */
        yield firebase.defaultApp
            .database()
            .ref("topicCollectionsFromBeLA_data/" + belaTopic.path + "/spork_topics")
            .update({ [sporkTopicId]: true });
    }
}

/**
 * Set Recommended Objective set for a BeLA collection
 * @param {*} param0
 */
export function* setRecObjectiveSet({ collection, objectiveSetId }) {
    if (collection && objectiveSetId) {
        yield firebase.defaultApp
            .database()
            .ref("/topicCollectionsFromBeLA/" + collection.id)
            .update({ recommended_obj_set: objectiveSetId });
        yield put(onSelectCollection({ ...collection, recommended_obj_set: objectiveSetId }));
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.BTC_TOPIC_STATUS_CHANGE, changeTopicStatus),
        takeLatest(TYPES.BTC_TOPIC_LINK_TO_BELA, linkTopicToBela),
        takeLatest(TYPES.BTC_SET_REC_OBJECTIVE_SET, setRecObjectiveSet)
    ]);
}
