import React from "react";
import PropTypes from "prop-types";

export default class TenantCardItem extends React.PureComponent {
    static propTypes = {
        count: PropTypes.number,
        name: PropTypes.string,
        children: PropTypes.node,
    }

    render() {
        const { count, name, children } = this.props;
        return (
            <React.Fragment>
                <div className="formatCount">{count}</div>
                <div>{name}</div>
                <div>{children}</div>
            </React.Fragment>
        );
    }
}
