import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import ProblemManagement from "../ProblemManagement/ProblemManagmentContainer";

export default class AddExistingProblemModal extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onAddToProblemSet: PropTypes.func.isRequired,
        problemSetId: PropTypes.string,
        fbCollection: PropTypes.string.isRequired,
    };

    state = { selectedItems: {} };

    handleOnSelectedItems = selectedItems => {
        this.setState({ selectedItems });
    };

    handleAddToProblemSet = () => {
        this.props.onAddToProblemSet(this.state.selectedItems);
    };

    render() {
        const { fbCollection, problemSetId, onClose } = this.props;
        return (
            <Modal open={true} size={"large"}>
                <Modal.Header>Add selected problems to the set</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <ProblemManagement
                            fbCollection={fbCollection}
                            onSelectedItems={this.handleOnSelectedItems}
                            itemsPerPage={10}
                            showAsSelector={true}
                            filterProblemSetId={problemSetId}
                        />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.handleAddToProblemSet}>
                        Add to problem set
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
