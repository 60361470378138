import Background from "./Background";
import BoxOutline from "./BoxOutline";
import BoxPadding from "./BoxPadding";
import BoxRounded from "./BoxRounded";
import StyleToolbar from "./StyleToolbar";

export default {
    Background,
    BoxOutline,
    BoxPadding,
    BoxRounded,
    StyleToolbar
};
