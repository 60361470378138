import React from "react";
import PropTypes from "prop-types";
import { Input, Button, Icon, Grid } from "semantic-ui-react";

import RemoveIcon from "../../../component/RemoveIcon";
import QuestionItemEditor from "../QuestionItemEditor";
import styles from "./MatchingChoice.module.scss";

class MatchingChoiceAnswer extends React.PureComponent{

    static propTypes = {
        answer: PropTypes.arrayOf(
            PropTypes.shape({
                raw: PropTypes.object,
                content: PropTypes.string,
                matchRaw: PropTypes.object,
                matchContent: PropTypes.string,
            })
        ),
        sharedBar: PropTypes.object.isRequired,
        regContentFn: PropTypes.func.isRequired,
        unregContentFn: PropTypes.func.isRequired,
        onUpdateAnswer: PropTypes.func.isRequired,
        updateOptionEditor: PropTypes.func.isRequired,
        onDirty: PropTypes.func,
    }

    state = {
        answer: this.props.answer,
        editorKeys:{}
    }

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        const { answer } = this.props;
        this.props.onUpdateAnswer(answer);
    }

    handleAdd = () => {
        const { answer } = this.state;
        const { onUpdateAnswer } = this.props;
        const updatedAnswer = answer.slice();
        updatedAnswer.push({
            raw: null,
            content: "",
            matchRaw: null,
            matchContent: "",
        });
        this.setState({ answer: updatedAnswer }, onUpdateAnswer(updatedAnswer));
    };

    handleRemove = (e, id) => {
        const { answer, editorKeys } = this.state;
        const { onUpdateAnswer, updateOptionEditor } = this.props;
        const updatedAnswer = answer.slice();
        updatedAnswer.splice(parseInt(id), 1);

        //update the editor keys to keep them in sync with the MA options
        delete editorKeys["answer-" + id];
        delete editorKeys["match-" + id];

        const updatedEditorKeys = {};

        Object.keys(editorKeys).filter((fKey) => fKey.indexOf("answer-") > -1 ).forEach((k, i) => {
            const keys = k.split("-");
            const newKey = keys[0] + "-" + i;
            updatedEditorKeys[newKey] = editorKeys[k];
            updateOptionEditor(editorKeys[k], newKey);
        });

        Object.keys(editorKeys).filter((fKey) => fKey.indexOf("match-") > -1 ).forEach((k, i) => {
            const keys = k.split("-");
            const newKey = keys[0] + "-" + i;
            updatedEditorKeys[newKey] = editorKeys[k];
            updateOptionEditor(editorKeys[k], newKey);
        });

        this.setState({ answer: updatedAnswer, editorKeys: updatedEditorKeys }, onUpdateAnswer(updatedAnswer));
    };

    handleSetEditorRef = (editorId, optionId) => {
        const { editorKeys } = this.state;
        editorKeys[optionId] = editorId;
        this.setState({ editorKeys });
    };

    getRandomKey = () => Math.random().toString(36).substring(7);

    render(){
        const { answer, editorKeys } = this.state;
        const { sharedBar, regContentFn, unregContentFn, onDirty } = this.props;

        const choices = answer.map((answer, index) => {
            const {raw, matchRaw} = answer;
            const editorKeyAnswer = editorKeys["answer-" + index] || this.getRandomKey();
            const editorKeyMatch = editorKeys["match-" + index] || this.getRandomKey();

            return (
                <div key={editorKeyAnswer+editorKeyMatch} className={styles.answer}>
                    <Input labelPosition="right" type="text">
                        <div className={styles.maEditor}>
                            <Grid>
                                <Grid.Column width={7}>
                                    <QuestionItemEditor
                                        sharedBar={sharedBar}
                                        initialContent={raw}
                                        editorId={editorKeyAnswer}
                                        optionId={"answer-" + index}
                                        focusOnMount={false}
                                        regContentFn={regContentFn}
                                        onSetRef={this.handleSetEditorRef}
                                        unregContentFn={unregContentFn}
                                        onDirty={onDirty}
                                    />
                                </Grid.Column>
                                <Grid.Column width={1} verticalAlign="middle">
                                    <Icon name="arrow right" />
                                </Grid.Column>
                                <Grid.Column width={7}>
                                    <QuestionItemEditor
                                        sharedBar={sharedBar}
                                        initialContent={matchRaw}
                                        editorId={editorKeyMatch}
                                        optionId={"match-" + index}
                                        focusOnMount={false}
                                        regContentFn={regContentFn}
                                        onSetRef={this.handleSetEditorRef}
                                        unregContentFn={unregContentFn}
                                        onDirty={onDirty}
                                    />
                                </Grid.Column>
                                <Grid.Column width={1} verticalAlign="middle">
                                    <RemoveIcon onRemove={this.handleRemove} id={index.toString()} entityName={"Problem Match"} />
                                </Grid.Column>
                            </Grid>
                        </div>
                    </Input>
                </div>
            );
        });

        const addChoice = (
            <Button key="_new" size="small" onClick={this.handleAdd}>
                <Icon name="plus" />Add Option
            </Button>
        );
        choices.push(addChoice);

        return choices;
    }
}


MatchingChoiceAnswer.defaultProps = {
    answer: [{
        raw: null,
        content: "",
        matchRaw: null,
        matchContent: "",
    },{
        raw: null,
        content: "",
        matchRaw: null,
        matchContent: "",
    }]
};

export default MatchingChoiceAnswer;
