const TYPES = {
    SYLLABI_LIST_SET_FILTER: "SYLLABI_LIST_SET_FILTER",
    SYLLABI_LIST_SELECT_SYLLABUS: "SYLLABI_LIST_SELECT_SYLLABUS",
    SYLLABI_LIST_SET_SCHOOL_FILTER: "SYLLABI_LIST_SET_SCHOOL_FILTER",
    SYLLABI_LIST_SET_TEACHER_FILTER: "SYLLABI_LIST_SET_TEACHER_FILTER",
    SYLLABI_LIST_CREATE_SYLLABUS: "SYLLABI_LIST_CREATE_SYLLABUS",
};

const onChangeFilter = (payload) => ({
    type: TYPES.SYLLABI_LIST_SET_FILTER,
    payload
});

const onSelectSyllabus = (payload) => ({
    type: TYPES.SYLLABI_LIST_SELECT_SYLLABUS,
    payload
});

const onChangeSchoolFilter = (payload) => ({
    type: TYPES.SYLLABI_LIST_SET_SCHOOL_FILTER,
    payload
});

const onChangeTeacherFilter = (payload) => ({
    type: TYPES.SYLLABI_LIST_SET_TEACHER_FILTER,
    payload
});

const onCreateSyllabus = (payload) => ({
    type: TYPES.SYLLABI_LIST_CREATE_SYLLABUS,
    payload
});

export {
    TYPES,
    onChangeFilter,
    onSelectSyllabus,
    onChangeTeacherFilter,
    onChangeSchoolFilter,
    onCreateSyllabus,
};
