import React from "react";
import Logo from "./Spork_Logo_Face.svg";
import { Container, Header, Icon } from "semantic-ui-react";
import { AboutSporkButton } from "./component/AboutSpork";

export default class Welcome extends React.PureComponent {

    render() {

        return (
            <Container textAlign="center">
                <Header style={{opacity: "0.85", fontSize: "3em", fontWeight: "200", marginTop: "2em"}}>Welcome to SPORK</Header>
                <div><img src={Logo} style={{maxWidth:"70vh", opacity: "0.1", padding: "50px", width: "50%", height: "auto"}}/></div>
                <AboutSporkButton/>
                <div style={{opacity: "0.5", fontSize: "1em", marginTop: "2em"}}>
                    Click <span style={{border: "1px solid grey", borderRadius: "3px", padding: "5px", margin: "5px"}}><Icon name="bars" /> Menu</span> in top left corner to open the main application menu.
                </div>
            </Container>
        );
    }
}
