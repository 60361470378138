import { TYPES } from "./AdvancedResourceSearchActions";

const initialState = {
    isSearching: false,
    isLoadingTags: false,
    searchResults: null,
    isOpen: false,
    usedTags: [],
    usedCourseVariants: [],
    isLoadingCourseVariants: false,
    isLoadingResourceTypes: false,
    isLoadingOwners: false,
    owners: [],
    resourceTypes: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case TYPES.RESOURCE_ADVANCED_SEARCH_ADVANCED_SEARCH:
            return { ...state, isSearching: true };
        case TYPES.RESOURCE_ADVANCED_SEARCH_SET_RESULT:
            return { ...state, isSearching: false, searchResults: payload };
        case TYPES.RESOURCE_ADVANCED_SEARCH_OPEN:
            return { ...state, isOpen: true, searchResults: null };
        case TYPES.RESOURCE_ADVANCED_SEARCH_CLOSE:
            return { ...state, isOpen: false };
        case TYPES.RESOURCE_ADVANCED_SEARCH_LOAD_USED_TAGS:
            return { ...state, isLoadingTags: true };
        case TYPES.RESOURCE_ADVANCED_SEARCH_SET_USED_TAGS:
            return { ...state, usedTags: payload, isLoadingTags: false };
        case TYPES.RESOURCE_ADVANCED_SEARCH_FETCH_COURSE_VARIANTS:
            return { ...state, isLoadingCourseVariants: true };
        case TYPES.RESOURCE_ADVANCED_SEARCH_SET_COURSE_VARIANTS:
            return { ...state, usedCourseVariants: payload, isLoadingCourseVariants: false };
        case TYPES.RESOURCE_ADVANCED_SEARCH_LOAD_RESOURCE_TYPES:
            return { ...state, isLoadingResourceTypes: true };
        case TYPES.RESOURCE_ADVANCED_SEARCH_SET_RESOURCE_TYPES:
            return { ...state, isLoadingResourceTypes: false, resourceTypes: payload };
        case TYPES.RESOURCE_ADVANCED_SEARCH_FETCH_OWNERS:
            return { ...state, isLoadingOwners: true };
        case TYPES.RESOURCE_ADVANCED_SEARCH_SET_OWNERS:
            return { ...state, owners: payload, isLoadingOwners: false };
        default:
            return state;
    }
};
