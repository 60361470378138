import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TagsEditor from "../../../../component/TagsEditor";
import { Button } from "semantic-ui-react";
import AccessControl from "../../../../auth/AccessControl";

export default class ResourceTagsEditor extends PureComponent {
    static propTypes = {
        tagsOptions: PropTypes.array,
        tagNames: PropTypes.array,
        editValidator: PropTypes.object,
        resourceId: PropTypes.number,
    }

    state = {
        isOpen: false,
    }

    handleEdit = () => this.setState({ isOpen: true });
    handleClose = () => this.setState({ isOpen: false });
    handleSave = (tags) => {
        const { onSaveTags, resourceId } = this.props;
        onSaveTags({ tags, resourceId });
        this.handleClose();
    }

    render() {
        const { tagsOptions, tagNames, editValidator } = this.props;
        const { isOpen } = this.state;
        return (
            <React.Fragment>
                {isOpen && <TagsEditor
                    tagsOptions={tagsOptions}
                    open={isOpen}
                    onSave={this.handleSave}
                    onCancel={this.handleClose}
                    tagNames={tagNames}
                />}
                <AccessControl  {...editValidator}>
                    <Button compact floated="right" onClick={this.handleEdit}>Edit...</Button>
                </AccessControl>
            </React.Fragment>
        );
    }
}
