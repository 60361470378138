import { all, fork } from "redux-saga/effects";

import outlinesSaga from "./Outlines/outlinesSaga";
import WidgetTemplatesSaga from "./WidgetTemplates/WidgetTemplatesSaga";
import WidgetLibrarySaga from "./WidgetLibrary/WidgetLibrarySaga";
import WidgetLessonsSaga from "./Widget/WidgetLessons/WidgetsLessonsSaga";
import TagsSaga from "./Tags/TagsSaga";
import SnippetsManagerSaga from "./Snippets/SnippetsManagerSaga";
import LessonContentSaga from "./LessonContent/LessonContentSaga";
import WidgetTopicsSaga from "./Widget/WidgetTopics/WidgetTopicsSaga";
import GlossarySaga from "./Glossary/GlossarySaga";
import AKitsSaga from "./AKits/AKitsSaga";
import CommentsSaga from "./Comments/commentsSaga";
import publisherSaga from "./Publisher/PublisherSaga";
import LessonLinkSaga from "./LessonLink/LessonLinkSaga";
import ReorderProblemSetsSaga from "./LessonContent/LessonProblemSet/ReorderProblemSets/reorderProblemSetsSaga";
import ModulesSaga from "./Modules/ModulesSaga";

function* rootSaga() {
    yield all([
        fork(outlinesSaga),
        fork(publisherSaga),
        fork(WidgetTemplatesSaga),
        fork(TagsSaga),
        fork(WidgetLibrarySaga),
        fork(SnippetsManagerSaga),
        fork(LessonContentSaga),
        fork(WidgetTopicsSaga),
        fork(GlossarySaga),
        fork(AKitsSaga),
        fork(WidgetLessonsSaga),
        fork(CommentsSaga),
        fork(LessonLinkSaga),
        fork(ReorderProblemSetsSaga),
        fork(ModulesSaga),
    ]);
}

export default rootSaga;
