import { all, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import { TYPES, onSelectSyllabus } from "./SyllabiListActions";
import { push } from "connected-react-router";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { sipiData } from "../dataSource";
import { sagaToastError } from "../../component/toast";
const basePath = "syllabi";

function* selectSyllabus ({ payload: { id }}) {
    yield put(push(`/iplanner/syllabi/${id}`));
}

function* createSyllabus ({ payload: { name, templateId, isLinked }}) {
    try {
        const userId = yield select(loggedUserId);
        const tenant_id = yield select(tenantId);
        const year_id = yield select(getSelectedYearId);
        const { key } = yield sipiData.createIPlanner(
            basePath,
            {
                syllabus_name: name,
                teacher: userId,
                created: new Date().toISOString(),
                tenant_id,
                year_id,
                template_id: templateId,
                linked: templateId ? isLinked : null,
            }
        );
        if (templateId && !isLinked) {
            yield sipiData.cloneOutline(basePath, key, { templateId });
        }
        yield put(onSelectSyllabus({ id: key }));
    } catch (error) {
        sagaToastError("Creating iPlanner failed.", error);
    }
}

export default function* syllabiList() {
    yield all([
        takeLatest(TYPES.SYLLABI_LIST_SELECT_SYLLABUS, selectSyllabus),
        takeEvery(TYPES.SYLLABI_LIST_CREATE_SYLLABUS, createSyllabus),
    ]);
}
