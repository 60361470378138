export const MESSAGES = {
    ADD_LIST_ITEM: {
        header: "Do not add a new list item in this dialog.",
        content: [
            "review is possible for single list item only",
            "if you want to add a new list item, leave this dialog",
            "as a reviewer use appropriate toolbar button for it",
            "as an author edit the list directly"
        ],
        type: "info",
    },
    REMOVE_LIST_ITEM: {
        header: "Do not remove the list item in this dialog.",
        content: [
            "if you want to remove a list item, leave this dialog",
            "as a reviewer use appropriate toolbar button for it",
            "as an author edit the list directly"
        ],
        type: "info",
    }
};