import React from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

import ProblemSetGrid from "./ProblemSetGrid";

export default class ProblemSetManager extends React.PureComponent {
    static propTypes = {
        fbCollection: PropTypes.string,
        problemSetLocks: PropTypes.object,
        problemSets: PropTypes.array,
    };

    render() {
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    {this.props.fbCollection === "problemSet" ? "Problem Sets" : "Comprehensive Tests"}
                </Header>
                <ProblemSetGrid {...this.props}/>
            </React.Fragment>
        );
    }
}
