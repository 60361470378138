const TYPES = {
    COMMENT_ADD: "Comment/Add",
    COMMENT_EDIT: "Comment/Edit",
    COMMENT_OPEN_REPLY: "Comment/OpenReply",
    COMMENT_MARK_RESOLVED: "Comment/MarkResolved",
    COMMENT_REMOVE: "Comment/Remove",
};

const onAddComment = (payload) =>  ({
    type: TYPES.COMMENT_ADD,
    payload,
});

const onEditComment = (payload) => ({
    type: TYPES.COMMENT_EDIT,
    payload,
});

const onOpenReply = (payload) => ({
    type: TYPES.COMMENT_OPEN_REPLY,
    payload,
});

const onMarkResolved = (payload) => ({
    type: TYPES.COMMENT_MARK_RESOLVED,
    payload,
});

const onRemove = (payload) => ({
    type: TYPES.COMMENT_REMOVE,
    payload
});

export { TYPES, onAddComment, onEditComment, onOpenReply, onMarkResolved, onRemove };
