import React, { PureComponent } from "react";
import { Popup, List } from "semantic-ui-react";
import PropTypes from "prop-types";
import { convertDateIsoToLocal } from "../utils/dateUtils";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import UserAvatar from "react-user-avatar";
import "./LastEditorLabel.scss";

export default class LastEditorLabel extends PureComponent {
    static propTypes = {
        editor: PropTypes.string,
        editorTimestamp: PropTypes.string,
        author: PropTypes.string,
        authorTimestamp: PropTypes.string,
    };

    constructor(props) {
        super(props);
        TimeAgo.addLocale(en);
        this.state = { 
            editorTimeAgo: null, 
            editorTimestamp: null, 
            authorTimeAgo: null, 
            authorTimestamp: null, 
            timeAgo: new TimeAgo("en-US") 
        };
    }

    state = { 
        editorTimeAgo: null, 
        editorTimestamp: null,
        authorTimeAgo: null, 
        authorTimestamp: null,
        timeAgo: undefined
    };
    
    static renderTimeWithAvatar(timeAgo, name) {
        return <span>{name ? <UserAvatar name={name} size="24" /> : " "}{timeAgo}</span>;
    }

    static renderListItem(timestamp, name) {
        return <List.Content>
            <div className="lastEditPopupName">{name && <UserAvatar name={name} size="32" />}<div className="lastEditPopupTime">{name}<br/><span>{timestamp}</span></div></div>
                    
        </List.Content>;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let editorTimeAgo = null != nextProps.editorTimestamp ? prevState.timeAgo.format(new Date(nextProps.editorTimestamp)) : null;
        let authorTimeAgo = null != nextProps.authorTimestamp ? prevState.timeAgo.format(new Date(nextProps.authorTimestamp)) : null;
        return {
            editorTimeAgo, 
            authorTimeAgo, 
            editorTimestamp: nextProps.editorTimestamp ? convertDateIsoToLocal(nextProps.editorTimestamp) : null, 
            authorTimestamp: nextProps.authorTimestamp ? convertDateIsoToLocal(nextProps.authorTimestamp) : null, 
            timeAgo: prevState.timeAgo
        };
    }
    

    render() {
        const { editorTimeAgo, authorTimeAgo, editorTimestamp, authorTimestamp } = this.state;
        const { editor, author } = this.props;

        if (editorTimestamp || authorTimestamp) {
            return (<Popup 
                wide="very" 
                trigger={
                    <div className="lastEditTimestamp">
                        Updated
                        {this.props.editorTimestamp 
                            ? LastEditorLabel.renderTimeWithAvatar(editorTimeAgo, editor)
                            : LastEditorLabel.renderTimeWithAvatar(authorTimeAgo, author)
                        }
                    </div>}>
                <Popup.Content className="lastEditPopupContent">
                    <List divided>
                        {(author || this.props.authorTimestamp) && <List.Item>
                            <List.Header as="h4">Created</List.Header>
                            {LastEditorLabel.renderListItem(authorTimestamp, author)}                    
                        </List.Item>
                        }
                        {(editor || this.props.editorTimestamp) && <List.Item>
                            <List.Header as="h4">Last update</List.Header>
                            {LastEditorLabel.renderListItem(editorTimestamp, editor)}
                        </List.Item>
                        }
                    </List>
                </Popup.Content>
            </Popup> );}
        else 
            return null;
    }
}
