import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import Modules from "./Modules";
import { getPackages, isSaving, isRemoving, isLoading } from "./ModulesSelectors";
import { onCreate, onEdit, onUpdateModuleKits, onLoad } from "./ModulesActions";

const mapStateToProps = function(state) {
    return {
        modules: getPackages(state),
        isSaving: isSaving(state),
        isLoading: isLoading(state),
        isRemoving: isRemoving(state),
    };
};

const dispatchToProps = {
    onCreate,
    onEdit,
    onUpdateModuleKits,
    onLoad,
};

export default compose(
    firebaseConnect(["tenants", "kits_data"]),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(Modules);
