import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import SchoolCourses from "./SchoolCourses";
import { onLoadSchoolCourses, onChangeFilter, onSelectCourse } from "./SchoolCoursesActions";
import { isLoadingCourses, getCourses, searchFilter, allCourses, selectedCourseId } from "./SchoolCoursesSelectors";
import { onSchoolLoad } from "../SchoolList/schoolActions";
import { getSchoolsById, isLoading as schoolIsLoading } from "../SchoolList/schoolSelectors";
import { getSelectedYearId } from "../../Year/YearsSelectors";


const mapStateToProps = function(state, props) {
    return {
        isLoading: isLoadingCourses(state) || schoolIsLoading(state),
        courses: getCourses(state),
        searchFilter: searchFilter(state),
        schools: getSchoolsById(state),
        selectedYearId: getSelectedYearId(state),
        allCourses: allCourses(state),
        selectedCourseId: selectedCourseId(state, props),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { 
            onLoadSchoolCourses,
            onChangeFilter,
            onSchoolLoad,
            onSelectCourse,
        },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(SchoolCourses);
