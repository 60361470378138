import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import UserList from "./UserList";
import { firebaseConnect } from "react-redux-firebase";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { getUsers, getUsersAsArray, getEditedUser, getUnusedRoleOptions, isClaimsUpdating, getTenants } from "./UserListSelectors";
import { authUser }  from "../../auth/authSelectors";
import * as UserListActions from "./UserListActions";

const mapStateToProps = function(state) {
    return {
        users: getUsersAsArray(state),
        _users: getUsers(state), // only for a spinner 
        editedUser: getEditedUser(state),
        roleOptions: getUnusedRoleOptions(state),
        isClaimsUpdating: isClaimsUpdating(state),
        authUser: authUser(state),
        tenants: getTenants(state),
    };
};

const dispatchToProps = (dispatch) => bindActionCreators({
    ...UserListActions,
}, dispatch );

export default compose(
    firebaseConnect(["user#orderByChild=valid&equalTo=true", "course_variants_data", "tenants"]),
    connect(mapStateToProps, dispatchToProps),
    spinnerWhileLoading(["_users", "tenants"]),
)(UserList);
