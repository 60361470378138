import React from "react";
import PropTypes from "prop-types";
import ChangeInfoLabel, { getUserDisplayName } from "../../component/ChangeInfoLabel";
import { LessonVersionProps } from "./PublisherProps";
import { getVersionString } from "../../utils/conversionUtils";

const OldReleaseStyle = {
    color: "#9f3a38", // Taken from Semantic UI error message
};
const getReleaseStyle = (preview, release, labelStyle) => {
    if (preview && release && preview.version_id !== release.version_id) {
        return labelStyle
            ? {
                ...labelStyle,
                ...OldReleaseStyle,
            }
            : OldReleaseStyle;
    }
    return labelStyle;
};

export default class PublishStatus extends React.PureComponent {
    static propTypes = {
        preview: LessonVersionProps,
        release: LessonVersionProps,
        // Other properties
        style: PropTypes.object,
        className: PropTypes.string,
        labelStyle: PropTypes.object,
        twoLines: PropTypes.bool,
    };

    render() {
        const { preview, release, style, labelStyle, twoLines } = this.props;
        return (
            <div style={(style && { ...style }) || { display: "flex" }} className={this.props.className}>
                <span style={twoLines && { display: "flex", flexDirection: "column" }}>
                    <ChangeInfoLabel
                        prefix="Prepublished"
                        doneAt={preview && preview.created_at}
                        doneBy={getUserDisplayName(preview && preview.createdBy)}
                        unchanged="Unpublished"
                        style={labelStyle && { ...labelStyle }}
                    />
                    {preview && (
                        <span style={{ fontSize: "0.88em", marginLeft: "0.3em" }}>
                            ({getVersionString(preview.created_at)})
                        </span>
                    )}
                </span>
                {preview && (
                    <React.Fragment>
                        <span style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}>|</span>
                        <span style={twoLines && { display: "flex", flexDirection: "column" }}>
                            <ChangeInfoLabel
                                prefix={
                                    release && preview.version_id !== release.version_id
                                        ? "Previously published"
                                        : "Published"
                                }
                                doneAt={release && release.created_at}
                                doneBy={getUserDisplayName(release && release.createdBy)}
                                unchanged="Unpublished"
                                style={getReleaseStyle(preview, release, labelStyle)}
                            />
                            {release && (
                                <span style={{ fontSize: "0.88em", marginLeft: "0.3em" }}>
                                    ({getVersionString(release.created_at)})
                                </span>
                            )}
                        </span>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
