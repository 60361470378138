import { TYPES } from "./WidgetLessonsActions";

const reducer = (state = {}, action) => {
    switch (action.type) {
        case TYPES.WIDGET_LESSONS_REQUEST: {
            const { widgetId } = action.payload;
            const { lessons } = state[widgetId] || {};
            const widgetState = { loading: true, lessons };
            return { ...state, [widgetId]: widgetState };
        }
        case TYPES.WIDGET_LESSONS_SUCCESS: {
            const { widgetId, lessons, unresolved } = action.payload;
            const widgetState = { lessons, unresolved };
            return { ...state, [widgetId]: widgetState };
        }
        case TYPES.WIDGET_LESSONS_FAILURE: {
            const { widgetId, failure } = action.payload;
            const { lessons } = state[widgetId] || {};
            const widgetState = { lessons, failure };
            return { ...state, [widgetId]: widgetState };
        }
        default:
            // console.log("Reducer(state):", state); // eslint-disable-line no-console
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;