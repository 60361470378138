import { all, takeEvery, select, put, fork } from "redux-saga/effects";
import { TYPES, onStoreUserRights, onTabChange } from "./LessonContentActions";
import { lessonContent, widgetTemplates, widgetLibrary } from "../dataSource";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { push } from "connected-react-router";
import { userClaims } from "../../Users/UserProfile/UserProfileSelectors";
import { getAuthorKit } from "../dataSource/lessonContent";
import { getWidgetLibraryData, getWidgetsInfo } from "../WidgetLibrary/WidgetLibrarySelectors";
import { getUserRightsAnyAuthorKit, getUserRightsForAuthorKit } from "../AKits/AKitsList/AKitsListSelectors";
import { tabIndex } from "./LessonContentSelectors";
import { onSetTagFilter, onSetModuleFilter as onSetSnippetModuleFilter  } from "../Snippets/SnippetsManagerActions";
import LessonProblemSetSaga from "./LessonProblemSet/LessonProblemSetSaga";
import { toastError, DB_ERROR } from "../../component/toast";
import { outlineManager } from "../Outlines/DataSource";
import { onSetModuleFilter } from "../WidgetLibrary/WidgetLibraryActions";
import { onSetModuleFilter as onSetTemplateModuleFilter } from "../WidgetTemplates/WidgetTemplatesActions";
import { REVIEW_STATS_COLLECTION } from "../../component/seamlessEditor/bookEditor/constants";

function* updateWidgets({ payload }) {
    const { outlineId, lessonId, widgets, removedWidgetId } = payload;

    if (removedWidgetId) {
        yield outlineManager.outlineLessonUpdateReviewStats(REVIEW_STATS_COLLECTION.WIDGETS, outlineId, lessonId, removedWidgetId);
    }
    yield lessonContent.updateLesson(outlineId, lessonId, { widgets: { ...widgets }});
}

function* updateReviewStats(outlineId, lessonId, widgetId) {
    const widgetsData = yield select(getWidgetLibraryData);
    const { items } = widgetsData[widgetId];
    if (items) {
        yield all(Object.keys(items).map((itemId) => {
            return outlineManager.outlineLessonUpdateReviewStats(REVIEW_STATS_COLLECTION.WIDGETS, outlineId, lessonId, widgetId, itemId, items[itemId].reviewStats || {});
        }));
    }
}

function* addNewWidget({ payload }) {
    const { outlineId, lessonId, name, templateId, highestPosition, topics } = payload;
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);
    const items = yield widgetTemplates.getInitialContent(templateId);
    const [err, widgetId ] = yield widgetLibrary.createWidget({ name, templateId, items, topics, author: userId, created: new Date().toISOString(), tenant });
    if (err) {
        toastError({ code: err.code, header: "Failed to create a new Widget", message: DB_ERROR });
    } else {
        yield lessonContent.addWidget(outlineId, lessonId, widgetId, highestPosition + 1);
    }
}

function* addExistingWidget({ payload }) {
    const { outlineId, lessonId, widgetId, highestPosition } = payload;
    yield lessonContent.addWidget(outlineId, lessonId, widgetId, highestPosition + 1);
    yield updateReviewStats(outlineId, lessonId, widgetId);
}

function* goBack({ payload }) {
    const { outlineId } = payload;
    const authorKitId = yield lessonContent.getAuthorKitId(outlineId);
    if (authorKitId) {
        yield put(push("/authorkits/" + authorKitId));
    }
}

function* checkUserRights ({ payload:outlineId }) {
    const claims = yield select(userClaims);
    const userId = yield select(loggedUserId);
    yield put(onStoreUserRights(getUserRightsAnyAuthorKit(claims)));
    if (outlineId && claims) {
        // eslint-disable-next-line no-unused-vars
        const [akitId, akit] = yield getAuthorKit(outlineId);
        if (akit) {
            const rights = getUserRightsForAuthorKit(claims, userId, akit);
            yield put(onStoreUserRights(rights));
            const currentTabIndex = yield select(tabIndex);
            if ("compose" === currentTabIndex && !rights.canEdit) {
                if (rights.canReview) {
                    yield put(onTabChange("review"));
                } else {
                    yield put(onTabChange("content"));
                }
            } else if ("review" === currentTabIndex && !rights.canReview) {
                if (rights.canEdit) {
                    yield put(onTabChange("compose"));
                } else {
                    yield put(onTabChange("content"));
                }
            }

        }
    }
}

function* setFilterByKit ({ payload }) {
    // eslint-disable-next-line no-unused-vars
    const [akitId, akit] = yield getAuthorKit(payload);
    yield put(onSetTagFilter(akit.tags || {}));
    yield put(onSetSnippetModuleFilter(Object.keys(akit.m || {})));
    yield put(onSetModuleFilter(Object.keys(akit.m || {})));
    yield put(onSetTemplateModuleFilter(Object.keys(akit.m || {})));
}

function* cloneToNewWidget ({ payload }) {
    const { outlineId, lessonId, highestPosition, widgetId } = payload;
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);
    const widgetsData = yield select(getWidgetLibraryData);
    const widgetsList = yield select(getWidgetsInfo);
    const widgetData = widgetsData[widgetId];
    const widgetInfo = widgetsList[widgetId];
    const [err, newWidgetId ] = yield widgetLibrary.cloneWidget({
        name: widgetInfo.name,
        templateId: widgetInfo.templateId,
        items: widgetData.items || null,
        topics: widgetInfo.topics || null,
        author: userId,
        created: new Date().toISOString(),
        srcWidgetId: widgetId,
        outlineId,
        tenant: widgetInfo.t || tenant,
    });
    if (err) {
        toastError({ code: err.code, header: "Failed to create a new Widget", message: DB_ERROR });
    } else {
        yield lessonContent.addWidget(outlineId, lessonId, newWidgetId, highestPosition + 1);
    }
}

function* goToAuthoKits() {
    yield put(push("/authorkits"));
}

export default function* widgetTemplatesSaga() {
    yield all([
        takeEvery(TYPES.LESSON_CONTENT_UPDATE_WIDGETS, updateWidgets),
        takeEvery(TYPES.LESSON_CONTENT_ADD_NEW_WIDGET, addNewWidget),
        takeEvery(TYPES.LESSON_CONTENT_ADD_EXISTING_WIDGET, addExistingWidget),
        takeEvery(TYPES.LESSON_CONTENT_GO_BACK, goBack),
        takeEvery(TYPES.LESSON_CONTENT_CHECK_USER_RIGHTS, checkUserRights),
        takeEvery(TYPES.LESSON_CONTENT_SET_FILTER_BY_KIT, setFilterByKit),
        takeEvery(TYPES.LESSON_CONTENT_CLONE_TO_NEW_WIDGET, cloneToNewWidget),
        takeEvery(TYPES.LESSON_CONTENT_GO_TO_AUTHOR_KITS, goToAuthoKits),
        fork(LessonProblemSetSaga),
    ]);
}
