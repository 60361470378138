const TYPES = {
    QUESTION_BANK_NEXT_PAGE: "QUESTION_BANK_NEXT_PAGE",
    QUESTION_BANK_PREV_PAGE: "QUESTION_BANK_PREV_PAGE",
    QUESTION_BANK_SEARCH_RESULTS: "QUESTION_BANK_SEARCH_RESULTS",

    QUESTION_BANK_GET_ALL_COUNT: "QUESTION_BANK_GET_ALL_COUNT",
    QUESTION_BANK_UPDATE_COUNT: "QUESTION_BANK_UPDATE_COUNT",
    QUESTION_BANK_APPLY_FILTER: "QUESTION_BANK_APPLY_FILTER",
    QUESTION_BANK_RESET_FILTER: "QUESTION_BANK_RESET_FILTER",

    QUESTION_BANK_CREATE_QUESTION: "QUESTION_BANK_CREATE_QUESTION",
    QUESTION_BANK_MODIFY_QUESTION: "QUESTION_BANK_MODIFY_QUESTION",
    QUESTION_BANK_DELETE_QUESTION: "QUESTION_BANK_DELETE_QUESTION",
    QUESTION_BANK_DELETE_MULTIPLE: "QUESTION_BANK_DELETE_MULTIPLE",
    QUESTION_BANK_ENTRY_CREATED: "QUESTION_BANK_ENTRY_CREATED",

};

const onNextPage = last => ({ type: TYPES.QUESTION_BANK_NEXT_PAGE, last });
const onPrevPage = last => ({ type: TYPES.QUESTION_BANK_PREV_PAGE, last });
const onSearchResults = (results, totalPages, totalRecords, allResults) => ({type: TYPES.QUESTION_BANK_SEARCH_RESULTS, results, totalPages, totalRecords, allResults });
const onGetAllCount = () => ({ type: TYPES.QUESTION_BANK_GET_ALL_COUNT });
const onUpdateTotalCount = (count) => ({ type: TYPES.QUESTION_BANK_UPDATE_COUNT, count });

const onApplyFilterBy = (payload, currentPage, itemsPerPage) => ({
    type: TYPES.QUESTION_BANK_APPLY_FILTER,
    payload,
    currentPage,
    itemsPerPage
});
const onResetFilterBy = () => ({ type: TYPES.QUESTION_BANK_RESET_FILTER });

const onCreateQuestion = (question, callback) => ({
    type: TYPES.QUESTION_BANK_CREATE_QUESTION,
    question,
    callback,
});
const onDeleteQuestion = (questionId) => ({
    type: TYPES.QUESTION_BANK_DELETE_QUESTION,
    questionId,
});
const onDeleteMultiple = (questions) => ({
    type: TYPES.QUESTION_BANK_DELETE_MULTIPLE,
    questions,
});
const onEditQuestion = (question, callback) => ({
    type: TYPES.QUESTION_BANK_MODIFY_QUESTION,
    question,
    callback
});
const onQuestionAddSuccess = problem => ({ type: TYPES.QUESTION_BANK_ENTRY_CREATED, problem });

export {
    TYPES,
    onCreateQuestion,
    onDeleteQuestion,
    onDeleteMultiple,
    onEditQuestion,
    onQuestionAddSuccess,
    onNextPage,
    onPrevPage,
    onSearchResults,
    onGetAllCount,
    onUpdateTotalCount,
    onApplyFilterBy,
    onResetFilterBy,
};
