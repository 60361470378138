/**
 * Converts Firebase dataset into list of entries.
 *
 * @param {object} data Firebase data collection, i.e. { [key]: value }
 * @return {array} list/array of data entries, i.e. [ { key, value } ]
 */
export const convertDatasetToList = (data) => {
    if (data && typeof data === "object" && !Array.isArray(data)) {
        return Object.keys(data).map(key => ({ key, value: data[key] }));
    }
    throw new Error("Invalid data provided - object expected");
};

/**
 * Converts list of entries back to Firebase dataset.
 *
 * @param {array} data list/array of data entries, i.e. [ { key, value } ]
 * @param {function} entryToValue optional conversion function, i.e. entryToValue({ key, value }, index): value
 * @return {object} Firebase data collection, i.e. { [key]: value }
 */
export const convertListToDataset = (data, entryToValue) => {
    if (data && Array.isArray(data)) {
        const reducer = entryToValue && typeof entryToValue === "function"
            ? (output, entry, index) => {
                output[entry.key] = entryToValue(entry, index);
                return output;
            } : (output, entry) => {
                output[entry.key] = entry.value;
                return output;
            };
        return data.reduce(reducer, {});
    }
    throw new Error("Invalid data provided - array of { key, value } expected");
};

/**
 * Map Firebase object with properties to an array of objects.
 *
 * { key: obj, key: obj} to [{...obj, key}, {...obj, key}]
 * 
 * Important: This conversion is not suitable for selectors for values
 * that will be passed to React because it will always create a new object.
 * So, eventhough original entry value was not modified, it will create
 * a new object from it causing the component to re-render.
 * 
 * @param {} entries
 * @deprecated Please, use convertDatasetToList especially in selectors.
 */
export const mapCollectionToList = entries =>
    entries ? Object.keys(entries).map(key => ({ ...entries[key], key })) : [];

/**
 * Map Firebase object with properties to an array of options usable in
 * Dropdown component.
 * [{ name: , value:, text:}]
 * @param {*} obj
 */
export const objectToDropdownOptions = obj => {
    var options = [];
    if (obj) {
        Object.keys(obj).map(key => {
            let item = obj[key];
            options.push({ key: key, text: item.name, value: item.name });
        });
    }
    return options;
};

/**
 * Map Firebase object with properties to an array of options usable in
 * Dropdown component.
 * [{ name: , value:, text:}]
 * @param {*} obj
 */
export const objectToDropdownOptionsWithKeyValue = obj => {
    var options = [];
    if (obj) {
        Object.keys(obj).map(key => {
            let item = obj[key];
            options.push({ key: key, text: item.name, value: key });
        });
    }
    return options;
};

/**
 * Map list of objects (containing key property) to an array of options usable in
 * Dropdown component.
 * [{ name: , value:, text:}]
 * @param {array<*>} objects
 *
 * Notice: contrary to objectToDropdownOptions() object's key property is used for value, not text property!
 */
export const arrayToDropdownOptions = objects => {

    if (objects) {
        return objects.map(object => ({ key: object.key, text: object.name, value: object.key }));
    } else {
        return [];
    }
};

/** Compare function to be used within Array.sort() to sort data by "ordering" property */
// export const compareByOrdering = (a, b) => (a.ordering === b.ordering ? 0 : (a.ordering < b.ordering ? -1 : 1));
export const compareByOrdering = (a, b) =>
    (a.ordering === b.ordering ? 0 : /* equals also when both a.ordering and b.ordering are not defined */
        (!a.ordering) ? 1 : /* a > b; if a.ordering is not provided then it is at the end, i.e. it is greater than anything */
            (!b.ordering) ? -1 : /* a < b if b.ordering is not provided then it is at the end, i.e. it is greater than anything */
                (a.ordering < b.ordering ? -1 : 1));

/** Compare function to be used within Array.sort() to sort data by "type" property */
export const compareByType = (a, b) => (a.type === b.type ? 0 : (a.type < b.type ? -1 : 1));

/** Compare function to be used within Array.sort() to sort data case insensitively by "name" property */
export const compareByNameCI = (a, b) => {
    const av = (a.name || "").toLocaleLowerCase();
    const bv = (b.name || "").toLocaleLowerCase();
    return av === bv ? 0 : (av < bv ? -1 : 1);
};

export const compareByValueNumber = (l, r) => {
    const lv = (l && l.value) || -1;
    const rv = (r && r.value) || -1;
    return lv - rv;
};

export const compareValueByNameCI = (l, r) => {
    const lv = ((l.value && l.value.name) || "").toLocaleLowerCase();
    const rv = ((r.value && r.value.name) || "").toLocaleLowerCase();
    return lv === rv ? 0 : (lv < rv ? -1 : 1);
};
export const compareValueByOrdering = (l, r) => {
    const lv = (l.value && l.value.ordering) || Number.MAX_SAFE_INTEGER;
    const rv = (r.value && r.value.ordering) || Number.MAX_SAFE_INTEGER;
    return lv < rv ? -1 : lv > rv ? 1 : 0;
};
export const compareValueByPosition = (l, r) => {
    const lv = (l.value && l.value.position) || Number.MAX_SAFE_INTEGER;
    const rv = (r.value && r.value.position) || Number.MAX_SAFE_INTEGER;
    return lv < rv ? -1 : lv > rv ? 1 : 0;
};

/** Add ID property to object */
export const enrichById = (object, id) => { return { ...object, id }; };

/** Extract Firebase ID (string identifier starting w/ "-") from given string (e.g. ID prefixed w/ another string) */
export const extractId = (prefixedId) => /-.*/.exec(prefixedId);

export const getSearchByNamePredicate = (searchFilter) => {
    if (searchFilter && searchFilter.length) {
        const pattern = searchFilter.toLowerCase();
        return (entity) => (entity.name && entity.name.toLowerCase().indexOf(pattern) !== -1);
    }
    return null;
};

export const getSearchByUnusedPredicate = (unusedFilter) => {
    if (unusedFilter) {
        return (entity) => (!entity.used && !entity.isUsed);
    }
    return null;
};

export const getSearchByTopicPredicate = (searchFilter) => {
    if (searchFilter && searchFilter.length) {
        const pattern = searchFilter.toLowerCase();
        //        return (topic) => (`${topic.value.concept.name} ${topic.value.objective.name} ${topic.value.objective.type}`.toLowerCase().indexOf(pattern) !== -1);
        return (topic) => (topic.name.toLowerCase().indexOf(pattern) !== -1);
    }
    return null;
};

export const getSearchByTagsPredicate = (selectedTags) => {
    const tagNames = selectedTags && Object.keys(selectedTags);
    if (tagNames && tagNames.length) {
        return (entity) => (
            entity.tags &&
            Object.keys(entity.tags).filter(tag => -1 !== tagNames.indexOf(tag)).length
        );
    }
    return null;
};

export const getSearchByTagsArrayPredicate = (selectedTags) => {
    if (selectedTags && selectedTags.length) {
        return (entity) => (
            entity.tags &&
            Object.keys(entity.tags).filter(tag => -1 !== selectedTags.indexOf(tag)).length
        );
    }
    return null;
};

export const getSearchByOptionalIndexPredicate = (filter, index, tenant, defaultFilter, includeMissedInIndex) => {
    return (entity) => {
        const entityTenant = entity.t || 1;
        // Entity is not in index
        if (((index && !index[entity.key]) || null === index) && entityTenant === tenant && (includeMissedInIndex || !filter.length)) {
            return true;
        } else if (index && index[entity.key]) { // Or entity has all filter items indexed
            if (filter.length) {
                return Object.keys(index[entity.key]).filter(i => -1 !== filter.indexOf(i)).length === filter.length;
            } else {
                return defaultFilter && defaultFilter.some(j => !!index[entity.key][j]);
            }
        }
    };
};

/**
 * Create composed topic key
 * @param {string} collectionId 
 * @param {string} topicId 
 */
export const normalizeTopicKey = (collectionId, topicId) => collectionId + "@" + topicId;

/**
 * Denormalize composed topic key. Returns an arrray of components
 * [collectionId, topicId]
 * @param {string} topicKey 
 */
export const denormalizeTopicKey = (topicKey) => topicKey.split("@");

/**
 * Return GUID for Course. GUID is combination of UUID and TenantID
 * @param {*} courseVariant 
 */
export const getCvGuid = (courseVariant) => `cv${courseVariant.uuid}@t${courseVariant.tenant_id}`;

/**
 * Returns the URL match parameter by name.
 * 
 * @param {object} props properties passed to Redux selector
 * @param {string} name Redux router parameter name
 * @returns {string} value of parameter if found, undefined otherwise
 */
export const getMatchParam = (props, name) => {
    const params = props && props.match && props.match.params;
    return params ? params[name] : undefined;
};

/**
 * Remove null props from the input object
 * @param {object} obj input object
 */
export const removeEmpty = obj => {
    Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
};

export const getSearchByValueTenantPredicate = (tenantId) => {
    return (entity) => {
        const entityTenant = entity.value.t || 1;
        return entityTenant === tenantId;
    };
};

export const getSearchByTenantPredicate = (tenantId) => {
    return (entity) => {
        const entityTenant = entity.t || 1;
        return entityTenant === tenantId;
    };
};
