import React from "react";
import PropTypes from "prop-types";
import { Segment, Button, Label, Icon } from "semantic-ui-react";

export default class StyledBlocksReset extends React.PureComponent {
    static propTypes = {
        onExecute: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    }

    render() {
        const { disabled, onExecute } = this.props;

        return (
            <Segment>
                <Label attached="top">Migrate Kits</Label>
                <p>This migrates Kits properties to SPORK SQL db for using in SPORK App server.</p>
                <Button icon size="small" primary labelPosition="left"
                    name="KitMigration" title="Migrate Kits to SQL db"
                    onClick={onExecute}
                    disabled={disabled}
                ><Icon name="play" />Run</Button>
            </Segment>
        );
    }
}
