import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { modulesList } from "../KitBuilder/Modules/ModulesSelectors";
import "./EntityModuleList.scss";
import cx from "classnames";

class EntityModuleList extends PureComponent {
    static propTypes = {
        showHeader: PropTypes.bool,
        modulesCollection: PropTypes.object,
        list: PropTypes.array,
        rightFloated: PropTypes.bool,
    }

    render() {
        const { showHeader, modulesCollection, list, rightFloated = false } = this.props;
        return (
            <React.Fragment>
                {showHeader && <Header as="h4">Packages</Header>}
                {list && list.map(m => (
                    modulesCollection && modulesCollection[m] && (
                        <div key={m} title={`Package "${modulesCollection[m].name}"`} className={cx("moduleLabels", 
                            { rightFloated, 
                                "subscribed": modulesCollection[m].subscribers !== undefined, 
                                "public": modulesCollection[m].is_public && modulesCollection[m].subscribers === undefined, 
                                "private": modulesCollection[m].subscribers === undefined && modulesCollection[m].is_public == false 
                            })}>
                            <Icon className="books" />
                            {modulesCollection[m].name}
                        </div>
                    ))
                )}
            </React.Fragment>
        );
    }
}

export default compose(
    firebaseConnect(["modules/list"]),
    connect(
        state => ({
            modulesCollection: modulesList(state),
        })
    ),
)(EntityModuleList);
