import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Header, Label, Grid, Button, Segment } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";
import { getTenantsKits } from "../../AKits/AKitsList/AKitsListSelectors";
import { connect } from "react-redux";
import cx from "classnames";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import "./ModuleKitManager.scss";

class ModuleKitManager extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        availableKits: PropTypes.array,
        editedModule: PropTypes.object,
        editedModuleId: PropTypes.string,
        onSave: PropTypes.func,
    }

    state = {
        modulesKits: [],
        unassignedKits: [],
        unsaved: false,
        selectedKit: null,
    }

    componentDidMount() {
        const { availableKits, editedModuleId } = this.props;
        const modulesKits = [];
        const unassignedKits = [];
        if (availableKits && availableKits.length) {
            for (const kit of availableKits) {
                if (!kit.value.m || !kit.value.m[editedModuleId]) {
                    unassignedKits.push(kit);
                } else if (kit.value.m[editedModuleId]) {
                    modulesKits.push(kit);
                }
            }
        }
        this.setState({
            modulesKits,
            unassignedKits,
            unsaved: false,
        });
    }

    handleAssingKit = () => {
        const { modulesKits, unassignedKits, selectedKit } = this.state;
        const newKit = unassignedKits.filter(({key}) => key === selectedKit)[0];
        modulesKits.push({ key: newKit.key, value: { ...newKit.value, new: !newKit.value.unassigned }});
        const newUnassigned = unassignedKits.filter(({key}) => key !== selectedKit);
        this.setState({ modulesKits, unassignedKits: newUnassigned, unsaved: true, selectedKit: null });
    }

    handleSave = () => {
        const { modulesKits, unassignedKits } = this.state;
        this.props.onSave({ 
            modulesKits: modulesKits.filter(({value}) => value.new).map(({key}) => key),
            removeKits: unassignedKits.filter(({value}) => value.unassigned).map(({key}) => key),
        });
    }

    handleCancelAdding = () => {
        const { modulesKits, unassignedKits, selectedKit } = this.state;
        const newKit = modulesKits.filter(({key}) => key === selectedKit)[0];
        let changes = { modulesKits: modulesKits.filter(({key}) => key !== selectedKit) };
        unassignedKits.push({ key: newKit.key, value: { ...newKit.value, unassigned: !newKit.value.new  }});
        changes = {...changes, unassignedKits };
        this.setState({ ...changes, unsaved: true, selectedKit: null });
    }

    handleSelect = (e) => {
        const selectedKit = e.currentTarget.id;
        // const modules = this.state.modulesKits.filter(({key}) => key === selectedKit);
        // if (0 === modules.length || modules[0].value.new || (1 < Object.keys(modules[0].value.m).length)) {
        this.setState({ selectedKit });
        // }
    }

    render() {
        const { onClose, editedModule } = this.props;
        const { modulesKits, unassignedKits, unsaved, selectedKit } = this.state;
        return (
            <Modal
                closeOnDimmerClick={false}
                open
            >
                <Modal.Header>Author Kits in package <i>{editedModule.name}</i></Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column className="width percent48">
                            <Header as="h4">Content of package {editedModule.name}</Header>
                            {0 < modulesKits.length && (
                                <Segment className="limitedHeight" color="blue">
                                    {modulesKits.map(({key, value}) =>
                                        <div className={cx("selectableItem", { active: selectedKit === key})} key={key} id={key} onClick={this.handleSelect}>
                                            {value.name}&nbsp;
                                            {value.new && (
                                                <Label
                                                    basic
                                                    size="tiny"
                                                    id={key}
                                                    title="Newly assigned to this package"
                                                    circular
                                                    color="green"
                                                    content="NEW"
                                                />
                                            ) || 1 < Object.keys(value.m).length && (
                                                <Label
                                                    basic
                                                    size="tiny"
                                                    id={key}
                                                    title="Author Kits is assigned to multiple packages"
                                                    circular
                                                    color="blue"
                                                    content="IN MULTIPLE"
                                                />
                                            )}
                                        </div>
                                    )}
                                </Segment>
                            ) || (
                                <NoDataPlaceholder
                                    text="Here we show Author Kits assigned to this package"
                                    icon="book"
                                >
                                    <p>This package has no Author Kits assigned.</p>
                                </NoDataPlaceholder>
                            )}
                        </Grid.Column>
                        <Grid.Column className="flex center width percent4">
                            <Button.Group vertical>
                                <Button
                                    icon="arrow left"
                                    disabled={0 == unassignedKits.filter(({key}) => key === selectedKit).length}
                                    onClick={this.handleAssingKit}
                                    title="Assign Author Kit to package"
                                />
                                <Button
                                    icon="arrow right"
                                    disabled={0 == modulesKits.filter(({key}) => key === selectedKit).length}
                                    onClick={this.handleCancelAdding}
                                    title="Remove Author Kit from package"
                                />
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column className="width percent48">
                            <Header as="h4">Available Author Kits</Header>
                            {0 < unassignedKits.length &&
                                <Segment className="limitedHeight">
                                    {unassignedKits.map(({key, value}) =>
                                        <div className={cx("selectableItem", { active: selectedKit === key})} key={key} id={key} onClick={this.handleSelect}>
                                            {value.name}&nbsp;
                                            {value.unassigned && <Label title="Unassigned from this package" basic size="tiny" circular color="orange">UNASSIGNED</Label>}
                                        </div>
                                    )}
                                </Segment>
                            ||
                                <NoDataPlaceholder
                                    text="Here we show Author Kits available for assigning"
                                    icon="book"
                                >
                                    <p>No available kits found.</p>
                                </NoDataPlaceholder>
                            }
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        saveDisabled={!unsaved}
                        onSave={this.handleSave}
                        onCancel={onClose}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}


const mapStateToProps = function(state) {
    return {
        availableKits: getTenantsKits(state)
    };
};

export default connect(
    mapStateToProps,
)(ModuleKitManager);
