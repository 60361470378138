import React from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

import { replaceRelLinks } from "../../../utils/conversionUtils";
import styles from "../ProblemSetCreator.module.scss";

export default class ProblemQuestion extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired,
    };

    render() {
        const { content } = this.props;
        const html = typeof content === "string" ? content : "";
        return (
            <div className={styles.question}>
                <Header as="h4" className={styles.qnaLabel}>Question:</Header>
                <span dangerouslySetInnerHTML={{__html: replaceRelLinks(html)}}></span>
            </div>
        );
    }
}
