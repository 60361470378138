import { toastError } from "../component/toast";

const isPromise = value =>
    value && value.promise !== null && typeof value.promise === "object" && typeof value.promise.then === "function";
/**
 * This middleware catches rejected promises in actions. Must be before redux-promise-middleware as
 * it encapsulates promises before they are processed by that.
 * 
 * If the actions contains meta.localError flag, the error handling with this middleware is skipped as it assumes
 * that the promise rejection is handled locally in action creator.
 */
export default function errorMiddleware() {
    return next => action => {
        // If not a promise, continue on
        if (!isPromise(action.payload)) {
            return next(action);
        }

        /**
         * include a property in `meta and evaluate that property to check if this error will be handled locally
         *
         * if (!action.meta.localError) {
         *   // handle error
         * }
         *
         * The error middleware serves to dispatch the initial pending promise to
         * the promise middleware, but adds a `catch`.
         *
         */
        if (!action.meta || !action.meta.localError) {
            // Dispatch initial pending promise, but catch any errors
            return next(action).catch(error => {
                toastError({
                    code: `${action.type} - ${error.name}`,
                    header: error.type,
                    message: error.message,
                });

                // eslint-disable-next-line no-console
                console.error(
                    `${action.type} unhandled rejection caught at middleware with reason: ${JSON.stringify(
                        error.message
                    )}, arguments: ${JSON.stringify(error.hook ? error.hook.arguments : "")}.`
                );
                return error;
            });
        }

        return next(action);
    };
}
