import {createSelector} from "reselect";
import { convertDatasetToList, compareValueByNameCI } from "../../utils/selectorUtils";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { tenantId, loggedUserId } from "../../Users/UserProfile/UserProfileSelectors";
import { getEnabledModules } from "../../External/Tenants/tenantSelectors";

export const searchFilter = state => state.lup.sipiTemplatesList.searchFilter;
export const getModuleFilter = (state) => state.lup.sipiTemplatesList.moduleFilter;
export const sipiTemplates = (state) => state.firebase.data.sipi_templates && state.firebase.data.sipi_templates.info;

export const getSearchByValueNamePredicate = (searchFilter) => {
    if (searchFilter && searchFilter.length) {
        const pattern = searchFilter.toLowerCase();
        return (entity) => (entity.value && entity.value.name && entity.value.name.toLowerCase().indexOf(pattern) !== -1);
    }
    return null;
};

export const getSearchByValueYearIdPredicate = (yearId) => {
    return (entity) => (entity.value && (!entity.value.year_id || entity.value.year_id && ((entity.value.year_id - yearId) === 0)));
};

const filterMyTemplatesPredicate = (userId) => {
    if (userId) {
        return (entity) => (entity.value && entity.value.cv_ccas && !!entity.value.cv_ccas[userId]);
    }
    return null;
};

export const getSearchByOptionalPackagePredicate = (filter, tenant, defaultFilter) => {
    return (entity) => {
        if (filter) {
            return entity.value &&  entity.value.m && entity.value.m[filter];
        } else {
            if (entity.value && !entity.value.m)  {
                return entity.value.tenant_id === tenant;
            } else {
                return defaultFilter && defaultFilter.some(j => entity.value && entity.value.m[j]);
            }
        }
    };
};

export const getSipiTemplates = createSelector(
    sipiTemplates,
    searchFilter,
    getSelectedYearId,
    tenantId,
    loggedUserId,
    getModuleFilter,
    getEnabledModules,
    (sipiTemplates, filter, selectedYearId, tenantId, userId, moduleFilter, enabledModules) => {
        if (sipiTemplates) {
            let filteredData = convertDatasetToList(sipiTemplates).sort(compareValueByNameCI);
            const namePredicate = getSearchByValueNamePredicate(filter);
            const yearIdPredicate = getSearchByValueYearIdPredicate(selectedYearId);
            const myOwnTemplatesredicate = filterMyTemplatesPredicate(userId);
            const modulePredicate = getSearchByOptionalPackagePredicate(0 === moduleFilter.length ? null : moduleFilter[0], tenantId, enabledModules);
            filteredData = filteredData.filter(modulePredicate);
            filteredData = filteredData.filter(yearIdPredicate);
            if (namePredicate) {
                filteredData = filteredData.filter(namePredicate);
            }
            return {
                myTemplates: filteredData.filter(myOwnTemplatesredicate),
                allTemplates: filteredData
            };
            
        }
    }
);

