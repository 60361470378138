import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES } from "./conceptsActions";
import firebase from "../../../firebase";
import { getUpdateObjectForPaths, applyUpdateObject } from "../../../utils/sagaForFBUtils";

const enrichById = (object, id) => { return { ...object, id }; };

/**
 * Loads data from Firebase located at ${path}.
 * @param { string } path
 * @return object representimg the data at the ${path}
 * @throws Error
 */
const loadData = async (path) => {

    const ref = firebase.getFirebaseData(path);

    let data = null;
    await ref.once("value",
        snapshot => {
            data = snapshot.val();
        }
        ,
        error => {
            throw error;
        }
    );

    if (data == null) {
        console.log(`No data loaded for ${path} which is unusual`); // eslint-disable-line no-console
    }

    return data;
};

/**
 * Converts Firebase's object representing a list to an array. Each element of the list is supposed to be an object and is enriched by id property with value of index.
 * E.g. { "O1": { "name": "Mark" }, "O2": { "name": "Frank" } } is converted to
 * [{ "id": "O1", "name": "Mark" }, { "id": "O2", "name": "Frank" }]
 * @param { object|null } fb_list_object
 * @return array
 */
const fb_list_object = (fb_list_object) => {
    if (!fb_list_object) {
        return [];
    }

    return Object.keys(fb_list_object).map( key => { return enrichById(fb_list_object[key], key); });
};


const getConceptReferences = async(concept_id) => {
    const conceptData = await loadData("concepts");
    const concepts = fb_list_object(conceptData);

    /* Create an index of objectives whose id is matching to payload.id.
    The index is list of objective_ids [ ]
    In this case there should be exactly one element to be updated but as a proof of concept of updating all occurences of an objective I am iterating over all the data.
    */
    const conceptIndex = concepts
        .filter( concept => { return (concept.id == concept_id); } )
        .map(concept => { return concept.id; } );
    return conceptIndex;
};

const getConceptReferencesInTopics = async(concept_id, topics) => {

    if (topics == null) {
        const topicsData = await loadData("topics");
        topics = fb_list_object(topicsData); // always returns array
    }

    const topicsIndex = topics
        .filter( (topic) => { return (topic.concept && topic.concept.id && (topic.concept.id == concept_id)); } )
        .map( (topic) => topic.id );

    return topicsIndex;
};

const isConceptReferenced = async(id, topics = null) => {

    const conceptsReferenced = await getConceptReferencesInTopics(id, topics);
    const isReferenced = (conceptsReferenced.length > 0); // non-empty list of topics referencing the objective

    return isReferenced;
};

function* removeConcept({ payload } ) {

    const {
        id: concept_id,
        onSuccess: onSuccessCallback,
        onFailure: onFailureCallback,
    } = payload;

    const topicsData = yield loadData("topics");
    const topics = fb_list_object(topicsData); // always returns array

    const isReferenced = yield isConceptReferenced(concept_id, topics);
    if (isReferenced) {
        // display error and close popup
        onFailureCallback({ message: "Cannot remove, concept in use." });
        onSuccessCallback();
        return;
    }

    /* concept is not referenced, could be removed */
    const conceptReferences = yield getConceptReferences(concept_id);

    /* generate paths for remove - list of paths to objectives to be removed */
    let target_paths = [];
    conceptReferences.forEach( (concept_id) => {
        target_paths.push(`/${concept_id}`);
    });

    const conceptPaths = getUpdateObjectForPaths(target_paths, null);
    try {
        yield applyUpdateObject(conceptPaths, firebase.getFirebaseData("concepts"));
        onSuccessCallback();
    }
    catch (error) {
        onFailureCallback(error);
    }
}

function* updateConceptNameInTopics( payload ) {

    const {
        id: concept_id,
        update_name: updated_name
    } = payload;

    const topicsIndex = yield getConceptReferencesInTopics(concept_id);

    let target_paths = topicsIndex.map( (topic_id) => {
        return `${topic_id}/concept/name`;
    });

    if (target_paths.length == 0) return; // No data for update

    const conceptPaths = getUpdateObjectForPaths(target_paths, updated_name);
    yield applyUpdateObject(conceptPaths, firebase.getFirebaseData("topics"));
}

export function* renameConcept({ newName, id, callbackOnError }) {
    try {
        // just an example of passing error into concepts list but empty newName should never arrive here
        if (!newName || !newName.trim())
            throw new Error("Concept name cannot be empty");

        yield all([
            firebase.defaultApp
                .database()
                .ref(`/concepts/${id}`)
                .update({ name: newName }),
            updateConceptNameInTopics( {id: id, update_name: newName } ),
        ]);
    }
    catch (error) {
        if (callbackOnError)
            yield put(callbackOnError(error));
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.CONC_REMOVE_CONCEPT, removeConcept),
        takeLatest(TYPES.CONC_RENAME, renameConcept),
    ]);
}