import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import WidgetTopicsTrigger from "./WidgetTopicsTrigger";
import { WidgetTopicsView } from "./component/WidgetTopicsView";
import { Modal, Button } from "semantic-ui-react";
import "./WidgetTopics.css";

import WidgetTopicsEdit from "./component/WidgetTopicsEdit";

export default class WidgetTopics extends PureComponent {
    static propTypes = {
        widget: PropTypes.object,
        id: PropTypes.string,
        topics: PropTypes.array,
        onAddTopic: PropTypes.func,
        onRemoveTopic: PropTypes.func,
        viewOnly: PropTypes.bool,
        libraryMode: PropTypes.bool,
        lessonTopics: PropTypes.object,
        lessonName: PropTypes.string,
        canEdit: PropTypes.bool,
        divider: PropTypes.bool,
        loadingTopics: PropTypes.bool,
    };

    state = {
        open: false,
    };

    handleStopPropagation = e => {
        e.stopPropagation();
    };
    handleOpen = () => this.setState({ open: true });
    handleClose = () => this.setState({ open: false });

    render() {
        const { widget, topics, viewOnly, libraryMode, lessonTopics, canEdit, lessonName, loadingTopics, divider, onAddTopic, id, onRemoveTopic } = this.props;
        const { selectedTopic, open } = this.state;
        const topicsCount = widget && widget.topics ? Object.keys(widget.topics).length : 0;
        

        if (viewOnly && !open) {
            return (
                <WidgetTopicsView 
                    divider={divider}
                    canEdit={canEdit}
                    topics={topics}
                    onHandleOpen={this.handleOpen}
                />
            );
        } else {
            return (
                <span onClick={this.handleStopPropagation}>
                    {open ? <Modal
                        onOpen={this.handleOpen}
                        onClose={this.handleClose}
                        open={open}
                        closeOnDimmerClick
                        closeOnEscape
                    >
                        <Modal.Header>Widget topic assignment</Modal.Header>
                        <Modal.Content>
                            <WidgetTopicsEdit 
                                libraryMode={libraryMode}
                                lessonName={lessonName}
                                canEdit={canEdit}
                                loadingTopics={loadingTopics}
                                topics={topics}
                                id={id}
                                onAddTopic={onAddTopic}
                                onRemoveTopic={onRemoveTopic}
                                widget={widget}
                                lessonTopics={lessonTopics}
                                selectedTopic={selectedTopic}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button primary onClick={this.handleClose} content="Close" />
                        </Modal.Actions>
                    </Modal>
                        : <WidgetTopicsTrigger topicsCount={topicsCount} onClick={this.handleOpen} />
                    }
                </span>
            );
        }
    }
}

