import React, { PureComponent } from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";
import Topic from "../../../../TopicCollection/Topic";
import cx from "classnames";

export default class LessonTopic extends PureComponent {
    static propTypes = {
        topicData: PropTypes.object.isRequired,
        lessonId: PropTypes.string,
        dragHandle: PropTypes.node,

        canEdit: PropTypes.bool,
        snapshot: PropTypes.object,
        outlineLessonsRaw: PropTypes.object,
        reviewTopics: PropTypes.object,
    }

    render() {
        const { topicData: topic, dragHandle, lessonId, canEdit, snapshot, outlineLessonsRaw, reviewTopics } = this.props;
        const dropWillRemove = snapshot.draggingOver
            && snapshot.draggingOver !== lessonId
            && outlineLessonsRaw
            && outlineLessonsRaw[snapshot.draggingOver]
            && outlineLessonsRaw[snapshot.draggingOver].topics
            && outlineLessonsRaw[snapshot.draggingOver].topics[topic.key];
        //console.log(dropWillRemove+`%cLessonTopic::render() topicId/topicName=${topic.key}/${topic.name}/snapshot =>`, "background-color: rgb(255, 242, 230)", snapshot);
        return (
            <List.Item key={topic.key} className={dropWillRemove ? "dropWillRemove" : ""}>
                <List.Header as={canEdit ? "h5" : "div"} className={cx("", {"editable": canEdit})}>
                    {canEdit && <span className="actionIcons">{dragHandle}</span>}
                    <div style={{ display: "inline" }}>
                        {reviewTopics && reviewTopics[topic.key] && <span className="topicReviewLabel" title="This topic is for review at the beginning of the lesson.">Review</span>}
                        <Topic topic={topic} />
                    </div>
                </List.Header>
            </List.Item>
        );
    }
}
