import React from "react";
import { Grid } from "semantic-ui-react";

import LastEditorLabel from "../../component/LastEditorLabel";
import { ProblemSetPropTypes } from "./ProblemSetUtils";

const formatUser = (user) => user && (
    user["first_name"] + " " + user["last_name"]
) || null;

const formatDate = (date) => date && (
    new Date(date).toISOString()
) || null;

export default class ProblemSetNameHeader extends React.PureComponent {
    static propTypes = ProblemSetPropTypes;

    render() {
        const {
            title, problems, author, editor, createdDate, lastUpdated,
            lessons_homework, lessons_problem_sets,
        } = this.props;
        if (!title) {
            return null;
        }
        return (
            <Grid style={{ marginTop: "10px", marginBottom: "10px" }} columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <h3>{title}</h3>
                        {problems ? problems.length : "0"} Question(s)
                    </Grid.Column>
                    <Grid.Column>
                        {/* This strange test was taken from ProblemSetCreator when refactoring out */}
                        {!lessons_homework && !lessons_problem_sets && (
                            <div style={{ float: "right", textAlign: "right" }}>
                                <LastEditorLabel
                                    author={formatUser(author)} authorTimestamp={formatDate(createdDate)}
                                    editor={formatUser(editor)} editorTimestamp={formatDate(lastUpdated)}
                                />
                            </div>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
