import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Checkbox } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import ResourceLink from "./ResourceLink";
import HelpPopup from "bmd-react/dist/HelpPopup";

export default class AssetEditorDialog extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        asset: PropTypes.object,
        resource_id: PropTypes.number,
    }
    state = {
        student_view: false,
        on_approval: false,
    }

    handleToggle = (e, { name, checked }) => {
        const changes = { [name]: checked };
        if ("student_view" == name && !checked) {
            changes["on_approval"] = false;
        }
        this.setState(changes);
    }

    componentDidMount() {
        const { asset } = this.props;
        if (asset) {
            this.setState({
                student_view: asset.student_view || false,
                on_approval: asset.on_approval || false,
            });
        } else {
            this.setState({
                student_view: false,
                on_approval: false
            });
        }
    }

    handleSave = () => {
        const { resource_id, onSave } = this.props;
        onSave({ ...this.state,  resource_id });
    }

    render() {
        const { onClose, resource_id, asset } = this.props;
        const { student_view, on_approval } = this.state;
        return (
            <Modal
                open={true}
                size="tiny"
            >
                <Modal.Header>{asset ? "Edit" : "Create new"} asset</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSave}>
                        <Form.Field>
                            <label>Based on resource</label>
                            <ResourceLink sipi={{ resource_id }} renderAsLink />
                        </Form.Field>
                        <Form.Group grouped>
                            <label>Display the asset</label>
                            <Form.Field style={{ marginLeft: "1em" }}>
                                <Checkbox label="On student tablets" onChange={this.handleToggle} checked={student_view} name="student_view"/>
                                <HelpPopup content="If enabled, the asset will be distributed also to student tablets."/>
                            </Form.Field>
                            <Form.Field style={{ marginLeft: "3em" }}>
                                <Checkbox disabled={!student_view} label="Only after the teacher approval" onChange={this.handleToggle} checked={on_approval} name="on_approval"/>
                                <HelpPopup content="If enabled, the asset will become visible only after allowing from teacher tablet."/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onSave={this.handleSave}
                        onCancel={onClose}
                        submitText={asset ? "Save" : "Create"}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
