import { createSelector } from "reselect";
import { getWidgetTemplatesInfo } from "../WidgetTemplatesSelectors";
import { getUsers, getFullName, getEditorTimestamp, getCreatedTimestamp, getEditorId, getAuthorId } from "../../../Users/UserList/UserListSelectors";

const getTemplateId = (state, props) => props.itemId;

export const editorId = createSelector(
    getWidgetTemplatesInfo,
    getTemplateId, 
    getEditorId
);

export const editor = createSelector(
    getUsers,
    editorId,
    getFullName
);

export const editorTimestamp = createSelector(
    getWidgetTemplatesInfo,
    getTemplateId, 
    getEditorTimestamp
);

export const authorId = createSelector(
    getWidgetTemplatesInfo,
    getTemplateId, 
    getAuthorId
);

export const author = createSelector(
    getUsers,
    authorId,
    getFullName
);

export const authorTimestamp = createSelector(
    getWidgetTemplatesInfo,
    getTemplateId, 
    getCreatedTimestamp
);