import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { convertToLinkOrFileURL } from "../ResourcesUtils";

import "./ResourcePreview.scss";

const SupportedCodePattern = /(?:link|file):(image|youtube)/;
const validateResourceType = (type) => {
    const matches = type && type.code && type.code.match(SupportedCodePattern);
    if (matches) {
        return matches[1];
    }
    return null;
};

const DefaultPlayerConfig = {
    youtube: { playerVars: { rel: 0 } },
};

const ResourcePreview = ({ name, type, href }) => {
    const subtype = validateResourceType(type);
    if (subtype === "image") {
        const src = convertToLinkOrFileURL(href);
        return (
            <div className="resource preview">
                <img title={name} src={src} />
            </div>
        );
    } else if (subtype === "youtube") {
        // Preview uses 16:9 aspect ratio (set in CSS with !important).
        // The react-player dimension attributes are based on the same 
        // aspect ratio to fit into Details column (half-width of Grid).
        return (
            <div className="resource preview">
                <ReactPlayer
                    title={name}
                    url={href}
                    playing={false}
                    width={500}
                    height={282}
                    controls
                    light
                    config={DefaultPlayerConfig}
                />
            </div>
        );
    }
    return null;
};
ResourcePreview.propTypes = {
    name: PropTypes.string,
    type: PropTypes.object,
    href: PropTypes.string,
};

export default ResourcePreview;
