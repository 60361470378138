import { all, put, takeEvery, takeLatest, fork, select } from "redux-saga/effects";
import { push, getLocation } from "connected-react-router";
import { toastError, DB_ERROR} from "../../component/toast";
import * as Api from "./ResourcesApi";
import {
    TYPES,
    onSetResourcesList,
    onSetResourceData,
    onFetchResourceData,
    onSetUsedTags,
    onSetUsedCourseVariants,
    onFetchAllResources,
    onSetSourceTypes,
    onSetUsedTopics
} from "./ResourcesActions";
import ResourceTagsEditorSaga from "./components/ResourceTagsEditor/ResourceTagsEditorSaga";
import ResourceDescriptionSaga from "./components/ResourceDescription/ResourceDescriptionSaga";
import ResourceSourceTypeSaga from "./components/ResourceSourceType/ResourceSourceTypeSaga";
import ResourceNameSaga from "./components/ResourceName/ResourceNameSaga";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { getFilterParams, getSelectedResourceData, getResourcesData } from "./ResourcesSelectors";
import { denormalizeTopicKey, normalizeTopicKey } from "../../utils/selectorUtils";

const exceptionToError = ({ code, message }, header) => {
    const text = message || "Unknown error.";
    return {
        code: code ? `(${code}) ${text}` : text,
        header: header || "Failed",
        message: DB_ERROR,
    } ;
};

const reduceListToDataMapAndKeys = (output, res) => {
    output.data[res.id] = res;
    output.keys.push(res.id);
    return output;
};

function* fetchResourceData({ payload:id }) {
    try {
        const { data } = yield Api.fetchResourceById(id);
        const yearId = yield select(getSelectedYearId);
        const usedResponse = yield Api.fetchUsingResourceInYear(id, yearId);
        if (0 < usedResponse.data.length) {
            const cv = {};
            usedResponse.data.map(sr => {
                if (sr.courseVariant) {
                    cv[sr.courseVariant.id] = sr.courseVariant;
                }
            });
            if (0 < Object.keys(cv).length) {
                const used = [];
                Object.keys(cv).map(cvId => used.push(cv[cvId]));
                data["used"] = used;
            }
        }
        const topicsResponse = yield Api.fetchResourceTopics(id);
        const topics = {};
        if (0 < topicsResponse.data.length) {
            for (const t of topicsResponse.data) {
                topics[`${t.collection_id}@${t.topic_id}`] = true;
            }
        }
        data["topicIds"] = topics;
        yield put(onSetResourceData(id, data));
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to refresh resource."));
    }
}

function* fetchAllResources() {
    const initialData = {
        data: {},
        keys: [],
    };
    try {
        const filterParams = yield select(getFilterParams);
        const { data } = yield Api.fetchResourceList(filterParams);
        const fetchedData = (data || [])
            .reduce(reduceListToDataMapAndKeys, initialData);
        // Ensure that selected item has full scale data (with description etc.)
        const selected = yield select(getSelectedResourceData);
        if (selected) {
            // Combine existing resource data with the fresh data from list.
            // Note on tags: The list filtered by tags contains only those tags
            // that match to filter. So, we are reusing tags from old details.
            const id = selected.id;
            const received = fetchedData.data[id];
            fetchedData.data[id] = { ...selected, ...received, tags: selected.tags };
        }
        yield put(onSetResourcesList(fetchedData));
    } catch (ex) {
        if (!window.unloadFired) {
            toastError(exceptionToError(ex, "Loading of resource list failed."));
            yield put(onSetResourcesList(initialData));
        }
    }
}

function* fetchResourceTags() {
    try {
        const { data } = yield Api.fetchTags(true);
        yield put(onSetUsedTags(data));
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to load used tags"));
    }
}

function* closeResourceDetails() {
    yield put(push("/iplanner/resources"));
}

function* viewResourceDetails({ payload:id }) {
    yield put(onFetchResourceData(id));
    const path = `/iplanner/resources/${id}`;
    const location = yield select(getLocation);
    if (path !== location.pathname) {
        yield put(push(path));
    }
}

function* fetchCourseVariants() {
    try {
        const { data } = yield Api.fetchCourseVariants();
        yield put(onSetUsedCourseVariants(data));
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to load used courses"));
    }
}

function* fetchSourceTypes() {
    try {
        const { data } = yield Api.fetchSourceTypes();
        yield put(onSetSourceTypes(data));
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to load source types of resources"));
    }
}

function* applyResourceFilter({ payload: changes }) {
    if (typeof changes === "object" && Object.keys(changes).length) {
        yield put(onFetchAllResources());
    }
}

function* patchResourceData({ payload: { resourceId, newData, fieldsToUpdate}}) {
    const resourcesData = yield select(getResourcesData);
    if (resourcesData && resourcesData[resourceId]) {
        const data = {...resourcesData[resourceId]};
        for (const fieldName of [...fieldsToUpdate, "updated_at"]) {
            data[fieldName] = newData[fieldName];
        }
        yield put(onSetResourceData(resourceId, data));
    }
}

function* addTopics({ payload: { resourceId, topics }}) {
    try {
        const topicsList = Object.keys(topics);
        for (const topicId of topicsList) {
            const [collection_id, topic_id] = denormalizeTopicKey(topicId);
            yield Api.addResourceTopic({ resource_id: resourceId, collection_id, topic_id });
        }
        const resourcesData = yield select(getResourcesData);
        if (resourcesData && resourcesData[resourceId]) {
            const data = {...resourcesData[resourceId]};
            const topicIds = {...data.topicIds || {}};
            for (const topicId of topicsList) {
                topicIds[topicId] = true;
            }
            data["topicIds"] = topicIds;
            yield put(onSetResourceData(resourceId, data));
        }
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to add resource topic."));
    }
}

function* removeTopic({ payload: { resourceId, topicId }}) {
    try {
        const [collection_id, topic_id] = denormalizeTopicKey(topicId);
        const resTopicResponse = yield Api.fetchResourceTopic({ resource_id: resourceId, collection_id, topic_id });
        if (resTopicResponse.data && 1 === resTopicResponse.data.length) {
            yield Api.removeResourceTopic(resTopicResponse.data[0].id);
        }
        const resourcesData = yield select(getResourcesData);
        if (resourcesData && resourcesData[resourceId] && resourcesData[resourceId]["topicIds"]) {
            const data = {...resourcesData[resourceId]};
            const topicIds = {...data.topicIds || {}};
            delete topicIds[topicId];
            data["topicIds"] = topicIds;
            yield put(onSetResourceData(resourceId, data));
        }
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to add resource topic."));
    }
}

function* fetchResourcesTopics() {
    try {
        const topicsResponse = yield Api.fetchResourcesTopics();
        const topics = {};
        if (0 < topicsResponse.data.length) {
            for (const t of topicsResponse.data) {
                topics[normalizeTopicKey(t.collection_id, t.topic_id)] = true;
            }
        }
        yield put(onSetUsedTopics(topics));
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to load topics used by resources."));
    }
}

export default function* courseVariantsSaga() {
    yield all([
        takeLatest(TYPES.RESOURCES_FETCH_LIST, fetchAllResources),
        takeLatest(TYPES.RESOURCES_FETCH_DATA, fetchResourceData),
        takeLatest(TYPES.RESOURCES_FETCH_TAGS, fetchResourceTags),
        takeLatest(TYPES.RESOURCES_ENTRY_DETAILS, viewResourceDetails),
        takeEvery(TYPES.RESOURCES_CLOSE_DETAILS, closeResourceDetails),
        takeLatest(TYPES.RESOURCE_FETCH_COURSE_VARIANTS, fetchCourseVariants),
        takeLatest(TYPES.RESOURCE_FETCH_SOURCE_TYPES, fetchSourceTypes),
        takeLatest(TYPES.RESOURCES_FILTER_BY_APPLY, applyResourceFilter),
        takeEvery(TYPES.RESOURCE_ADD_RESOURCE_TOPICS, addTopics),
        takeEvery(TYPES.RESOURCE_REMOVE_RESOURCE_TOPIC, removeTopic),
        takeEvery(TYPES.RESOURCE_PATCH_RESOURCE_DATA, patchResourceData),
        takeLatest(TYPES.RESOURCE_FETCH_USED_TOPICS, fetchResourcesTopics),
        fork(ResourceTagsEditorSaga),
        fork(ResourceDescriptionSaga),
        fork(ResourceSourceTypeSaga),
        fork(ResourceNameSaga),
    ]);
}
