import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List, AutoSizer } from "react-virtualized";
import { Checkbox, Input, Segment } from "semantic-ui-react";
import { getCvGuid } from "../../../../utils/selectorUtils";

import styles from "./courseVariantInfiniteList.module.scss";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";

export default class CourseVariantInfiniteList extends PureComponent {
    static propTypes = {
        variants: PropTypes.array,
        selectedVariants: PropTypes.object,
        onToggle: PropTypes.func,
        canEdit: PropTypes.bool,
    };

    state = {
        filter: {},
    }

    listRef = React.createRef();

    columnsDef = [
        { title: "Name", dataKey: "name", width: "200px", tip: true },
        { title: "Description", dataKey: "description", width: "286px", tip: true, className: styles.italic },
    ];

    componentDidMount() {
        this.applyFilter();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.variants !== this.props.variants) {
            this.applyFilter();
        }
    }

    createFilterInput = (dataKey, width) => {
        const name = "filter_" + dataKey;
        return (<Input key={name} name={name} data-key={name} style={{ width }} onChange={this.handleFilter} />);
    };

    renderHeader = () => {
        return (
            <div className={styles.header}>
                <div className={styles.title}>
                    {this.columnsDef.map((def) => (<span key={def.title} style={{ width: def.width }}>{def.title}</span>))}
                </div>
                <div className={styles.filter}>
                    {this.columnsDef.map((def) => this.createFilterInput(def.dataKey, def.width))}
                </div>
            </div>
        );
    };

    applyFilter = () => {
        const { variants } = this.props;
        const { filter } = this.state;
        const filteredVariants = variants.filter((item) => {
            return Object.keys(filter).every((key) => {

                if (!filter[key]) {
                    return true;
                }

                const value = item[key];
                const normalizedValue = (typeof value === "string") ? value.toLowerCase() : String(value);
                return normalizedValue.indexOf(filter[key]) !== -1;
            });
        });

        this.setState({ filteredVariants });
    }

    handleFilter = (e, { name, value }) => {
        const fieldName = name.substr(7);  // prefixed by "filter_"
        this.setState( { filter: {
            ...this.state.filter,
            [fieldName]: value.toLowerCase(),
        }}, this.applyFilter);
    };

    handleChecked = (e, data) => {
        this.props.onToggle(e, data);
        this.listRef.current.forceUpdateGrid();
    };

    rowRenderer = ({ index, key, style }) => {
        const { canEdit, selectedVariants } = this.props;
        const cv = this.state.filteredVariants[index];

        return (
            <div
                key={key}
                style={style}
                className={styles.listItem}
            >
                {canEdit && <Checkbox
                    id={getCvGuid(cv)}
                    onChange={this.handleChecked}
                    checked={(selectedVariants && selectedVariants[getCvGuid(cv)]) || false}
                />}
                {this.columnsDef.map((def) => {
                    const { dataKey, width, tip, className } = def;
                    return (
                        <span
                            key={key + "_" + dataKey}
                            data-key={dataKey}
                            style={{ width: width }}
                            title={tip && cv[dataKey]}
                            className={className}
                        >
                            {cv[dataKey]}
                        </span>
                    );
                })}
            </div>
        );
    }

    NoRowsRenderer = () => (
        <NoDataPlaceholder icon="search" text="Oh, snap. No result." style={{ minHeight: "12em", marginRight: "1em" }}>
            <p>We cannot find any Course matching your filtering criteria.</p>
        </NoDataPlaceholder>
    );

    render() {
        const { filteredVariants } = this.state;

        return (
            <Segment style={{ height: "18em", paddingRight: 0, paddingBottom: "1px" }}>
                {this.renderHeader()}
                <div style={{ position: "relative", width: "100%", height: "13em", overflowY: "auto" }}>
                    {filteredVariants && (
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    ref={this.listRef}
                                    height={height - 1}
                                    width={width - 1}  // - 1px because of unwanted scrollbar
                                    rowCount={filteredVariants.length}
                                    rowRenderer={this.rowRenderer}
                                    rowHeight={26}
                                    noRowsRenderer={this.NoRowsRenderer}
                                />
                            )}
                        </AutoSizer>
                    )}
                </div>
            </Segment>
        );
    }
}
