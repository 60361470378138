import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSortedSubjectNames } from "../../store/selectors/SubjectSelectors";
import { onSave } from "./TagsActions";

import Tags from "./Tags";

const mapStateToProps = function(state) {
    return {
        subjects: getSortedSubjectNames(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators({
        onSave,
    }, dispatch);
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(Tags);
