import { TYPES } from "./gridOptionsActions";


const initialState = {
    options:[],
    columns:[
        {name: "Problem Sets", dataKey:"displayProblemSets", value: true},
        {name: "Topics", dataKey:"displayTopics", value: true},
        {name: "Tags", dataKey: "tags", value: true},
        {name: "Problem Type", dataKey: "problemType", value: true},
        {name: "Status", dataKey: "status", value: false},
        {name: "Author", dataKey: "author", value: false},
        {name: "Owner", dataKey: "owner", value: false},
        {name: "Rating", dataKey: "rating", value: false}
    ]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GRID_COLUMNS_UPDATED:
            return { ...state, columns: [...action.columns] };
        case TYPES.GRID_OPTIONS_UPDATED:
            return { ...state, options: [...action.options] };
        default:
            return state;
    }
};

export default reducer;
