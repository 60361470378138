import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, List, Icon, Label } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import UserAvatar from "react-user-avatar";

export default class UserList extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        userList: PropTypes.array,
        onSelectUser: PropTypes.func,
        iconClassName: PropTypes.string,
        isLoading: PropTypes.bool,
        readOnly: PropTypes.bool,
    }

    handleSelectUser = (e, { id }) => {
        this.props.onSelectUser(id);
    }

    render() {
        const { title, userList, iconClassName, isLoading, readOnly } = this.props;
        if (userList && userList.length) {
            return (
                <React.Fragment>
                    {!!title && <Header size="small" style={{marginBottom: "1.5em"}}>
                        {!!iconClassName && <Icon className={iconClassName} size="large" color="grey" />}
                        {userList.length > 1 && `${userList.length} - `}
                        {title}
                    </Header>}
                    <List>
                        {userList.map(u => (
                            <React.Fragment key={u.id}>
                                
                                <List.Item
                                    as={ readOnly ? "span" : "a"}
                                    key={u.id}
                                    id={u.id}
                                    onClick={readOnly ? null : this.handleSelectUser}
                                    title={readOnly ? "" : "Click to open person details"}
                                    style={{ display: "flex", alignItems: "center"}}
                                >
                                    {u.scope == "teacher" ? <UserAvatar name={`${u.first_name} ${u.last_name}`} size="24" style={{float: "left", fontSize: "12px", marginRight: "5px"}}/>
                                        : <List.Icon name="user" color="grey" />}
                                    <List.Content title={readOnly ? "" : "Click to see person details"}>
                                        {`${u.first_name} ${u.last_name}`}
                                        {u.valid == false && <Label size="small" horizontal style={{marginLeft: "1em"}}>INVALID</Label>}
                                    </List.Content>
                                </List.Item>
                            </React.Fragment>
                        ))}
                    </List>
                </React.Fragment>
            );
        } else {
            return isLoading ? null : <NoDataPlaceholder
                icon="user"
                text={`Class roster has no ${title.toLowerCase()} defined.`}
            />;
        }
    }
}
