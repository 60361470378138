import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { roles as roleDefinitions } from "../../../auth";
import "./RolesLabels.scss";

export default class RolesLabels extends PureComponent {
    static propTypes = {
        roles: PropTypes.array,
        vertical: PropTypes.bool,
    };

    render() {
        const { roles, vertical } = this.props;
        if (roles && roles.length) {
            return roles.map(role => {
                return <span key={role} className={"rolesLabels "+role}>
                    {roleDefinitions[role] ? roleDefinitions[role].name : role}
                    {vertical && <br />}
                </span>;
            });
        } else {
            return null;
        }
    }
}
