import _ from "lodash";
import { createSelector } from "reselect";
import {
    mapCollectionToList,
    getSearchByNamePredicate,
    compareByNameCI,
    getSearchByTagsArrayPredicate,
    getSearchByUnusedPredicate
} from "../../utils/selectorUtils";
import { getSnippetConfig } from "./SnippetUtils";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getEnabledModules } from "../../External/Tenants/tenantSelectors";

const snippets = state => state.firebase.data.snippets;
export const getSearchFilter = state => state.kitBuilder.snippets.searchFilter;

export const selectedTags = (state) => state.kitBuilder.snippets.selectedTags;
export const unusedFilter = (state) => state.kitBuilder.snippets.unusedFilter;
export const getModuleFilter = (state) => state.kitBuilder.snippets.moduleFilter;
export const tagFilter = (state) => state.kitBuilder.snippets.tagFilter;
export const getSnippetsStylesheet = state =>
    state.firebase.data.fileLinks && state.firebase.data.fileLinks.snippetsCss;

const getSearchByOptionalModulePredicate = (filter, tenant, defaultFilter, includeNonModule) => {
    return (entity) => {
        const entityTenant = entity.t || 1;
        // Entity is not in module
        if (!entity.m && entityTenant === tenant && (includeNonModule || !filter.length)) {
            return true;
        } else if (entity.m) {
            if (filter.length) {
                return Object.keys(entity.m).filter(i => -1 !== filter.indexOf(i)).length === filter.length;
            } else {
                return defaultFilter && defaultFilter.some(j => !!entity.m[j]);
            }
        }
    };
};

export const getFilteredSnippets = createSelector(
    snippets,
    getSearchFilter,
    selectedTags,
    unusedFilter,
    getModuleFilter,
    tenantId,
    getEnabledModules,
    (snippets, searchFilter, selectedTags, unusedFilter, moduleFilter, tenant, enabledModules) => {
        if (snippets) {
            let filteredData = mapCollectionToList(snippets).reverse();
            const unusedPredicate = getSearchByUnusedPredicate(unusedFilter);
            if (unusedPredicate) {
                filteredData = filteredData.filter(unusedPredicate);
            }
            const tagsPredicate = getSearchByTagsArrayPredicate(selectedTags);
            if (tagsPredicate) {
                filteredData = filteredData.filter(tagsPredicate);
            }
            const namePredicate = getSearchByNamePredicate(searchFilter);
            if (namePredicate) {
                filteredData = filteredData.filter(namePredicate);
            }
            const modulePredicate = getSearchByOptionalModulePredicate(moduleFilter, tenant, enabledModules, false);
            filteredData = filteredData.filter(modulePredicate);
            return filteredData;
        } else {
            return [];
        }
    }
);

export const getSnippetsAsOptions = createSelector(
    snippets,
    tagFilter,
    getModuleFilter,
    tenantId,
    getEnabledModules,
    (snippets, tagFilter, moduleFilter, tenant, enabledModules) => {
        if (snippets) {
            let snippetsList = mapCollectionToList(snippets).sort(
                compareByNameCI
            );
            const tagsArray = Object.keys(tagFilter);
            if (0 < tagsArray.length) {
                const tagsPredicate = getSearchByTagsArrayPredicate(tagsArray);
                snippetsList = snippetsList.filter(tagsPredicate);
            }
            const modulePredicate = getSearchByOptionalModulePredicate(moduleFilter, tenant, enabledModules, true);
            snippetsList = snippetsList.filter(modulePredicate);
            return snippetsList.map(snippet => ({
                key: snippet.key,
                value: snippet.key,
                text: snippet.name,
                snippetCfg: getSnippetConfig(snippet),
            }));
        }
        return [];
    }
);

export const usedTags = createSelector(
    snippets,
    (snippets) => {
        const tags = [];
        if (snippets) {
            mapCollectionToList(snippets).map(snippet => {
                if (snippet.tags) {
                    Object.keys(snippet.tags).map(tag => tags.push({ key: tag, value: tag, text: tag }));
                }
            });
            return _.uniqBy(_.sortBy(tags, ["key"]), "key");
        }
        return [];
    }
);
