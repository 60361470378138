import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AKitsListContainer from "./AKitsList/AKitsListContainer";
import ErrorMessage from "../../component/ErrorMessage";
import AKitEditorContainer from "./AKitEditor/AKitEditorContainer";

export default class AKits extends PureComponent {
    static propTypes = {
        error: ErrorMessage.propTypes.error,
        onClearAKitsError: PropTypes.func,
        onAKitsError: PropTypes.func,

        selectedAKitId: PropTypes.string,
        onSelectAKitForEdit: PropTypes.func,
    }

    handleGoBack = () => {
        this.props.onSelectAKitForEdit(null);
    }

    render() {
        const { error, selectedAKitId, onAKitsError, onClearAKitsError } = this.props;

        return (
            <React.Fragment>
                <ErrorMessage error={error} onDismiss={onClearAKitsError} />

                {selectedAKitId
                    ? <AKitEditorContainer
                        aKitId={selectedAKitId}
                        onGoBack={this.handleGoBack}
                        onAKitsError={onAKitsError}
                        onClearAKitsError={onClearAKitsError}
                    />
                    : <AKitsListContainer {...this.props}
                        onClearAKitsError={onClearAKitsError}
                    />
                }

            </React.Fragment>
        );
    }
}