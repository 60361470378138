
const PredefinedTypes = [
    { id: 1, name: "Teacher Written", icon: "user circle outline", color: "green" },
    { id: 2, name: "Public Domain", icon: "check circle outline", color: "green" },
    { id: 3, name: "Copyrighted", icon: "copyright outline", color: "red" },
    { id: 255, name: "Unspecified", icon: "question circle outline", color: "red" }
];

const TypeNotAvailable = {
    icon: "circle outline",
    color: "grey",
};

export const KnownSourceTypes = PredefinedTypes.reduce((types, type) => { types[type.id] = type; return types; }, {});
export const KnownSourceTypeOptions = PredefinedTypes.map(({ id, icon, name }) => ({ key: id, icon, text: name, value: id }));
export const SourceTypeUnspecified = 255;

const ExtractColorPattern = /(?:^|(.*)\s+)(red|orange|yellow|olive|green|teal|blue|violet|purple|pink|brown|grey|black)(?:\s+(.*)|$)/i;
// HACK: This should split the icon specification to icon name and color.
// Because, source_type.icon contains color, Semantic UI doesn't accept that in property
export const checkSourceTypeIcon = (icon) => {
    const matches = icon && icon.match(ExtractColorPattern);
    return matches ? matches[1] || matches[3] : icon;
};

// HACK: This should split the icon specification to icon name and color.
export const buildSafeSourceType = ({ id, icon, name }) => {
    const matches = icon && icon.match(ExtractColorPattern);
    const typeCfg = KnownSourceTypes[id] ? { ...KnownSourceTypes[id] } : { ...TypeNotAvailable };
    if (matches) {
        typeCfg.icon = matches[1] || matches[3];
        typeCfg.color = matches[2];
    } else if (icon) {
        typeCfg.icon = icon;
    }
    if (name) {
        typeCfg.name = name;
    }
    return typeCfg;
};

/**
 * Converts the source type definion into option suitable for dropdown or menu.
 *
 * @param {object} sourceType typed as { id: number, icon: string, name: string }
 * @returns {object} with Semantic UI option { key: id, icon, text: name, value: id }
 */
export const mapSourceTypeToOption = ({ id, icon, name }) => id && ({
    key: id,
    text: name,
    icon: checkSourceTypeIcon(icon),
    value: id,
});