import React from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";

const TablePreviewStyle = { width: "100%" };

const renderTableBody = memoizeOne((rows, cols) => {
    const html = [];
    for (let row = 0; row < rows; row++) {
        const cells = [];
        for (let col = 0; col < cols; col++) {
            cells.push(<td key={col} />);
        }
        html.push(<tr key={row}>{cells}</tr>);
    }
    return <tbody>{html}</tbody>;
});


export default class TableStyleSample extends React.PureComponent {
    static propTypes = {
        cols: PropTypes.number.isRequired,
        rows: PropTypes.number.isRequired,
        tableStyle: PropTypes.string,
    };

    static defaultProps = {
        cols: 5,
        rows: 4,
    }

    render() {
        const { cols, rows, tableStyle } = this.props;
        const tableBody = renderTableBody(rows, cols);
        return (
            <table className={`sporkTable ${tableStyle}`} style={TablePreviewStyle}>
                {tableBody}
            </table>
        );
    }
}
