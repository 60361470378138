import { TYPES } from "./FixiPlannerResourcesActions";

const initialState = {
    inProgress: false,
};

const reducer = (state = initialState, { type }) => {
    switch (type) {
        case TYPES.FIX_IPLANNER_RESOURCES_FIX_DATA:
            return { ...state, inProgress: true };
        case TYPES.FIX_IPLANNER_RESOURCES_FIX_DATA_FINISHED:
            return { ...state, inProgress: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
