import React from "react";
import PropTypes from "prop-types";
import { Form, Button } from "semantic-ui-react";

export default class TableLayoutForm extends React.PureComponent {
    static propTypes = {
        onCreate: PropTypes.func.isRequired,
    };

    state = {
        rows: null,
        cols: null,
        errors: {
            rows: false,
            cols: false,
        },
    };

    handleChange = (e, data) => {
        const { name, value } = data;
        const numericValue = Number(value);
        this.setState((state) => {
            const errors = {
                ...state.errors,
                [name]: !Number.isInteger(numericValue) || numericValue <= 0,
            };
            return { [name]: numericValue, errors };
        });
    };

    handleSubmit = (e) => {
        e.stopPropagation();
        const { rows, cols } = this.state;
        this.props.onCreate(rows, cols);
    };

    render() {
        const { rows, cols, errors } = this.state;
        const isSubmitDisabled = rows === null || cols === null || errors.rows || errors.cols;
        return (
            <Form size="mini">
                <Form.Group className="rowsColsCfg">
                    <Form.Input
                        label="Rows"
                        name="rows"
                        width={7}
                        onChange={this.handleChange}
                        error={errors.rows}
                    />
                    <Form.Input
                        label="Columns"
                        name="cols"
                        width={7}
                        onChange={this.handleChange}
                        error={errors.cols}
                    />
                    <Button type="button" icon="check" compact size="mini" disabled={isSubmitDisabled} onClick={this.handleSubmit} />
                </Form.Group>
            </Form>
        );
    }
}
