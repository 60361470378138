import { all, put, select, takeLatest } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onSetSchoolCourses, onSetDetails, onSelectSection } from "./SchoolCoursesActions";
import { toastError } from "../../component/toast";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { push } from "connected-react-router";
import { selectedSectionId } from "./SchoolCoursesSelectors";


function* loadSchoolCourses() {
    try {
        const selectedYearId = yield select(getSelectedYearId);
        const schoolCourses = yield firebase.tabletProxy({
            method: "GET",
            uri: `/school-course?year_id=${selectedYearId}`,
        });
        yield put(onSetSchoolCourses(schoolCourses.data));
    } catch (err) {
        toastError({ code: err.details.name, header: "Failed to load School Courses.", message: err.details.message });
    }
}

function* selectCourse({ payload }) {
    yield put(push(`/section/courses/${payload}`));
    try {
        const courseDetails = yield firebase.tabletProxy({
            method: "GET",
            uri: `/section?school_course_id=${payload}`,
        });
        const sections = courseDetails.data;
        yield put(onSetDetails(sections));
        const sectionId = yield select(selectedSectionId);
        if (0 < sections.length) {
            if (sections.some(s => s.id === sectionId)) {
                yield put(onSelectSection(sectionId));
            } else {
                yield put(onSelectSection(sections[0].id));
            }
        } else {
            yield put(onSelectSection(null));
        }
    } catch (err) {
        toastError({ code: err.details.name, header: "Failed to load School Course details.", message: err.details.message });
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.SECTION_GET_SCHOOL_COURSES, loadSchoolCourses),
        takeLatest(TYPES.SECTION_SELECT_COURSE, selectCourse)
    ]);
}
