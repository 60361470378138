// Needed for redux-saga es6 generator support
import "babel-polyfill";
import "core-js/shim";
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import App from "./App";
import AppExports from "./AppExports";

import { store, history } from "./store/configureStore";
import "../semantic/dist/semantic.min.css";
import "react-virtualized/styles.css"; // important for InfiniteTable from bmd-react
import "react-sortable-tree/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { initGlobalErrorHandler } from "./onError";

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
);

// The exports into global namespaces as "window.spork.*" and "window.sporkApp.*"
//
// Redux store is exported to allow the "exposed" functions to detect
// if they are running within application. The tablet HTML/JS won't have
// access the store. For example, glossary terms definition are loaded
// using a function that branch code according to presence of reduxStore.
if (window) {
    const functions = AppExports(store);
    window.spork = {
        ...window.spork,
        ...functions.spork,
    };
    window.sporkApp = {
        ...window.sporkApp,
        ...functions.sporkApp,
    };

    initGlobalErrorHandler(store);
}
