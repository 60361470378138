const TYPES = {
    WIDGET_LIBRARY_ITEM_SELECTED: "Authoring/WidgetLibrary/ITEM_SELECTED",
    WIDGET_LIBRARY_CREATE_NEW: "Authoring/WidgetLibrary/CREATE_NEW",
    WIDGET_LIBRARY_SEARCH_CHANGED: "Authoring/WidgetLibrary/SEARCH_CHANGED",
    WIDGET_LIBRARY_SELECT_TAG: "Authoring/WidgetLibrary/SELECT_TAG",
    WIDGET_LIBRARY_SELECT_TOPIC: "Authoring/WidgetLibrary/SELECT_TOPIC",
    WIDGET_LIBRARY_DELETE: "Authoring/WidgetLibrary/DELETE",
    WIDGET_LIBRARY_UPDATE_NAME: "Authoring/WidgetLibrary/UPDATE_NAME",
    WIDGET_LIBRARY_UPDATE_ITEM: "Authoring/WidgetLibrary/UPDATE_ITEM",
    WIDGET_LIBRARY_UPDATE_FILE_ITEM: "Authoring/WidgetLibrary/UPDATE_FILE_ITEM",
    WIDGET_LIBRARY_EDIT_ITEM: "Authoring/WidgetLibrary/EDIT_ITEM",
    WIDGET_LIBRARY_CLOSE_ITEM_EDITOR: "Authoring/WidgetLibrary/CLOSE_ITEM_EDITOR",
    WIDGET_LIBRARY_SAVING_ITEM:  "Authoring/WidgetLibrary/SAVING_ITEM",
    WIDGET_LIBRARY_CHANGE_UNUSED_FILTER:  "Authoring/WidgetLibrary/CHANGE_UNUSED_FILTER",
    WIDGET_LIBRARY_CLOSE_DETAIL: "Authoring/WidgetLibrary/CLOSE_DETAIL",
    WIDGET_LIBRARY_UPDATE_QUESTION_ITEM: "Authoring/WidgetLibrary/UPDATE_QUESTION_ITEM",
    WIDGET_LIBRARY_CHANGE_WIDGET_TITLE: "Authoring/WidgetLibrary/CHANGE_WIDGET_TITLE",
    WIDGET_LIBRARY_CHANGE_ITEM_TITLE: "Authoring/WidgetLibrary/CHANGE_ITEM_TITLE",
    WIDGET_LIBRARY_SET_DIRTY: "Authoring/WidgetLibrary/SET_DIRTY",
    WIDGET_LIBRARY_SET_MODULE_FILTER: "Authoring/WidgetLibrary/SET_MODULE_FILTER",
};

const onSelectWidget = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_ITEM_SELECTED,
    payload
});

const onCreate = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_CREATE_NEW,
    payload
});

const onUpdateName = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_UPDATE_NAME,
    payload
});

const onChangeFilter = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_SEARCH_CHANGED,
    payload
});

const onSelectTag = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_SELECT_TAG,
    payload
});

const onSelectTopic = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_SELECT_TOPIC,
    payload
});

const onDeleteWidget = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_DELETE,
    payload
});

const onUpdateItem = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_UPDATE_ITEM,
    payload
});

const onUpdateFileItem = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_UPDATE_FILE_ITEM,
    payload
});

const onUpdateQuestionItem = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_UPDATE_QUESTION_ITEM,
    payload
});

const onEditItem = (widgetId, itemId, isReview) => ({
    type: TYPES.WIDGET_LIBRARY_EDIT_ITEM,
    payload: { widgetId, itemId, isReview}
});

const onCloseItemEditor = (widgetId, itemId) => ({
    type: TYPES.WIDGET_LIBRARY_CLOSE_ITEM_EDITOR,
    payload: { widgetId, itemId }
});

const onSavingItem = (widgetId, itemId) => ({
    type: TYPES.WIDGET_LIBRARY_SAVING_ITEM,
    payload: { widgetId, itemId }
});

const onChangeUnusedFilter = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_CHANGE_UNUSED_FILTER,
    payload
});

const onCloseDetail = () => ({
    type: TYPES.WIDGET_LIBRARY_CLOSE_DETAIL
});

const onChangeWidgetTitle = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_CHANGE_WIDGET_TITLE,
    payload
});

const onChangeItemTitle = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_CHANGE_ITEM_TITLE,
    payload
});

const onSetDirty = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_SET_DIRTY,
    payload
});

const onSetModuleFilter = (payload) => ({
    type: TYPES.WIDGET_LIBRARY_SET_MODULE_FILTER,
    payload
});

export {
    TYPES,
    onCreate,
    onSelectWidget,
    onChangeFilter,
    onSelectTag,
    onDeleteWidget,
    onUpdateName,
    onUpdateItem,
    onUpdateFileItem,
    onEditItem,
    onCloseItemEditor,
    onSavingItem,
    onChangeUnusedFilter,
    onCloseDetail,
    onUpdateQuestionItem,
    onChangeWidgetTitle,
    onChangeItemTitle,
    onSetDirty,
    onSelectTopic,
    onSetModuleFilter,
};
