import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button, Icon, Message } from "semantic-ui-react";


export default class UpdateLessonReviewStats extends PureComponent {
    static propTypes = {
        onConvertWidgetDataStart: PropTypes.func,
        inProgress: PropTypes.bool,
        message: PropTypes.string,
    };

    render() {
        const { inProgress, message, onConvertWidgetDataStart } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Update Lesson Review Stats from both Widgets and ProblemSets/Homework
                </Header>
                <Segment secondary>
                    This page flattens review stats under outline lessons for validation during mass publishing
                </Segment>
                {inProgress && (
                    <Message icon positive>
                        <Icon name="circle notched" loading />
                        <Message.Content>
                            {message}
                        </Message.Content>
                    </Message>
                )}
                {!inProgress && (
                    <Button primary onClick={onConvertWidgetDataStart}>
                        Start data conversion
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
