import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./ReadOnlyTable.module.scss";
import { Segment, Label } from "semantic-ui-react";
import { deleteBlockEntity, swallowEvent } from "../../utils/utils";

export default class ReadOnlyTable extends Component {
    static propTypes = {
        block: PropTypes.object,
        contentState: PropTypes.object,
        blockProps: PropTypes.shape({
            config: PropTypes.object.isRequired,
            setFocus: PropTypes.func.isRequired,
        }),
    };

    handleDeleteEntity = () => {
        const {
            block,
            contentState,
            blockProps: { config },
        } = this.props;
        config.onChange(deleteBlockEntity(block, contentState, config.getEditorState()));
    };

    handleMouseDown = (e) => {
        if (this.props.blockProps.config.isReadOnly()) {
            return;  // propagate event to outer component
        }
        const { blockProps: { setFocus } } = this.props;
        swallowEvent(e);

        if (e.detail === 1) {
            setFocus(e);
        }
    };

    render() {
        const { block, contentState } = this.props;
        const entity = contentState.getEntity(block.getEntityAt(0));
        const { tableContent } = entity.getData();

        return (
            <Segment className={styles.tablewrapper} title="Imported tables can't be edited in this editor."
                onMouseDown={this.handleMouseDown}
            >
                <Label attached="top right">Imported table</Label>
                <div className={styles.disableAction} dangerouslySetInnerHTML={{ __html: tableContent }} />
                <div className={styles.table_options}>
                    <span className={styles.link} onClick={this.handleDeleteEntity}>
                        Delete
                    </span>
                </div>
            </Segment>
        );
    }
}
