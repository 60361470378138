import React from "react";
import PropTypes from "prop-types";
import { Segment, Icon, Label } from "semantic-ui-react";
import { ConfirmDeletePopup } from "bmd-react";
import { Helmet } from "react-helmet";
import md5 from "md5";
import TagsContainer from "../Tags/TagsContainer";

import EditableText from "../../component/EditableText";
import { TextPreview } from "../Widget/previewContent";
import SnippetEditor from "./SnippetEditor";
import EntityModuleList from "../../component/EntityModuleList";

const labelStyle = {
    //padding: "5px",
    verticalAlign: "middle",
    background: "rgba(0,0,0,0.04)",
    display: "grid",
    gridTemplateColumns: "40% 20% 40%",
};
const hideableStyle = {
    fontWeight: "normal",
    justifySelf: "center",
};

class SnippetStyle extends React.PureComponent {
    static propTypes = {
        css: PropTypes.string,
    };

    state = {
        hash: null,
        modifiedCss: null,
        // eslint-disable-next-line react/no-unused-state
        originalCss: null,
    };

    static getDerivedStateFromProps(props, state) {
        const originalCss = props.css || null;
        if (originalCss !== state.originalCss) {
            return {
                hash: md5(originalCss),
                modifiedCss: originalCss.replace(/\.built-in/g, ".snippet"),
                originalCss,
            };
        }
        return null;
    }

    render() {
        const { hash, modifiedCss } = this.state;
        return <Helmet key={hash}><style>{modifiedCss}</style></Helmet>;
    }
}

class SnippetBlock extends React.PureComponent {
    static propTypes = {
        snippetKey: PropTypes.string.isRequired,
    }
    render() {
        return <div
            className={`spork snippet block ${this.props.snippetKey}`}
            style={{height: "10em", overflow: "hidden"}}
            dangerouslySetInnerHTML={{__html: TextPreview.html}}
        />;
    }
}

export default class Snippet extends React.PureComponent {
    static propTypes = {
        snippet: PropTypes.object,
        onRename: PropTypes.func,
        onRemove: PropTypes.func,
        onSave: PropTypes.func,
        canEdit: PropTypes.bool,
    };

    handleRemove = (callback) => {
        const { snippet, onRemove } = this.props;
        callback();
        onRemove(snippet.key);
    }

    handleRename = (value) => {
        const { snippet, onRename } = this.props;
        onRename({ snippetId: snippet.key, name: value });
    }

    handleSaveSnippet = (data) => {
        const { snippet, onSave } = this.props;
        onSave({ id: snippet.key, data });
    }

    render() {
        const { snippet, canEdit } = this.props;
        return (
            <Segment >
                <Label attached="top" style={labelStyle}>
                    <EditableText text={snippet.name} onUpdate={this.handleRename} floated="left" canEdit={canEdit} />
                    <span style={hideableStyle}>{snippet.hideable && "Hideable content"}</span>
                    <span>{!snippet.isUsed && canEdit && <ConfirmDeletePopup
                        position="right center"
                        trigger={<Icon name="trash alternate outline" link className="actionIconClass right" />}
                        whatText="the styled block"
                        onConfirm={this.handleRemove} />}
                    {canEdit && <SnippetEditor snippet={snippet} onSave={this.handleSaveSnippet} />}
                    </span>
                </Label>
                {snippet.styleClass && <SnippetStyle css={snippet.styleClass} />}
                <SnippetBlock snippetKey={snippet.key} />
                <TagsContainer tags={snippet.tags || {}} canEdit={canEdit} showButton verbose path={`snippets/${snippet.key}/tags`} />
                {snippet.m && <EntityModuleList list={Object.keys(snippet.m)} showHeader />}
            </Segment>
        );
    }
}
