import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Search, Divider, Menu } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getAKitsOptions } from "../../Maintenance/GeneratesDataForiPlanner/GeneratesDataForiPlannerSelectors";
import { withFirebase } from "react-redux-firebase";
import { kitId, isSearching, lessonSearchFilter, searchResult, lessonFilter } from "./TopicTreeSelectorSelectors";
import { onSearchLesson, onSetAKit, onSetLessonFilter } from "./TopicTreeSelectorActions";
import { RemovableTagLabel } from "../../LessonUnitPlanner/Resources/FilterBy/FilterByView";

class AdvancedFilterDialog extends PureComponent {
    static propTypes = {
        showOnlySelected: PropTypes.bool,
        onChangeSelectedOnly: PropTypes.func,
        onClose: PropTypes.func,
        single: PropTypes.bool,
        kitOptions: PropTypes.array,
        firebase: PropTypes.object,
        searchFilter: PropTypes.string,
        kitId: PropTypes.string,
        isSearching: PropTypes.bool,
        onSearchLesson: PropTypes.func,
        onSetAKit: PropTypes.func,
        searchResult: PropTypes.object,
        onSetLessonFilter: PropTypes.func,
        enableLessonFilter: PropTypes.bool,
        lessonFilter: PropTypes.object,
    }

    componentDidMount() {
        this.props.firebase.watchEvent("value", "/kits_data");
    }

    handleChange = (e, { value }) => {
        this.props.onSetAKit(value);
    }

    handleSearchChange = (e, { value }) => {
        const { onSearchLesson } = this.props;
        onSearchLesson(value);
    }

    searchResultRenderer = ({ title }) => <div>{title}</div>;

    handleSelectLesson = (e, {result}) => {
        this.props.onSetLessonFilter(result);
    }

    handleResetLessonFilter = () => {
        this.props.onSetLessonFilter(null);
    }

    render() {
        const {
            showOnlySelected,
            onChangeSelectedOnly,
            onClose,
            single,
            kitOptions,
            searchFilter,
            kitId,
            isSearching,
            searchResult,
            enableLessonFilter,
            lessonFilter,
        } = this.props;
        return (
            <Modal
                open
                size="tiny"
                closeOnDimmerClick={false}
            >
                <Modal.Header>Advanced Filter</Modal.Header>
                <Modal.Content>
                    <Form>
                        {!single && <Form.Checkbox
                            style={{float: "right", marginBottom: "0.5em"}}
                            checked={showOnlySelected}
                            toggle
                            label="Show selected only"
                            onChange={onChangeSelectedOnly}
                        />}
                        {enableLessonFilter && <Form.Select
                            name="kitId"
                            label="Author Kit for lesson filter"
                            placeholder="Select Author Kit for filter topics by lesson"
                            options={kitOptions || []}
                            onChange={this.handleChange}
                            value={kitId}
                        />}
                    </Form>
                    {enableLessonFilter && <Search
                        style={{margin: "0.5em 0"}}
                        fluid
                        category
                        loading={isSearching}
                        onResultSelect={this.handleSelectLesson}
                        onSearchChange={this.handleSearchChange}
                        results={searchResult}
                        autoFocus
                        disabled={kitId === null}
                        resultRenderer={this.searchResultRenderer}
                        placeholder="Search for lesson ..."
                        input={{fluid: true}}
                        value={searchFilter}
                        noResultsMessage="No lesson found"
                        noResultsDescription="Try to change searched word."
                    />}
                    {lessonFilter && enableLessonFilter && (
                        <React.Fragment>
                            <Menu text style={{ display: "inline-block", margin: "0" }}>
                                <Menu.Item header>FILTER BY: </Menu.Item>
                            </Menu>
                            <RemovableTagLabel tag={lessonFilter.title} onRemove={this.handleResetLessonFilter} />
                        </React.Fragment>
                    )}
                    <Divider clearing fitted hidden />
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={onClose} content="Close" />
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = function(state) {
    return {
        kitOptions: getAKitsOptions(state),
        kitId: kitId(state),
        isSearching: isSearching(state),
        searchFilter: lessonSearchFilter(state),
        searchResult: searchResult(state),
        lessonFilter: lessonFilter(state),
    };
};

const dispatchToProps = {
    onSetAKit,
    onSearchLesson,
    onSetLessonFilter,
};

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(AdvancedFilterDialog);

