import React from "react";
import { SideBarMenuLink } from "../component/SidebarMenuLink";
import { Menu, Accordion } from "semantic-ui-react";
import { SidebarMenu as OSMTMenu } from "./ObjectiveSetsManager";
import { SidebarMenu as ConceptsMenu } from "./Concepts";
import { SidebarMenu as TTTMenu } from "../TopicTransformationTool";
import PropTypes from "prop-types";

const menuIndex = "topics";

const SidebarMenu = ({activeIndex, onClick}) => (
    <React.Fragment>
        <Menu.Item>
            <Accordion.Title 
                active={activeIndex[menuIndex]} 
                index={menuIndex} 
                content="Topic management" 
                onClick={onClick} />
            <Accordion.Content active={activeIndex[menuIndex]}>
                <Menu.Menu>
                    <SideBarMenuLink to="/topics/list" text="Topics" />
                    <ConceptsMenu />
                    <OSMTMenu />
                    <TTTMenu />
                </Menu.Menu>
            </Accordion.Content>
        </Menu.Item>
    </React.Fragment>
);

SidebarMenu.propTypes = {
    activeIndex: PropTypes.object,
    onClick: PropTypes.func,
};

export default SidebarMenu;
