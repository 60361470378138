import { all, takeLatest, put, select } from "redux-saga/effects";
import { TYPES, onChangeFilter } from "./SnippetsManagerActions";
import { snippetManager } from "../dataSource";
import firebase from "../../firebase";
import { toastError, toastSuccess, FUNCTION_ERROR, DB_ERROR } from "../../component/toast";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";

function* createSnippet() {
    const t = yield select(tenantId);
    const [err] = yield snippetManager.createSnippet({ t });
    if (err) {
        toastError({ code: err.code, header: "Failed to create a new Styled Block", message: DB_ERROR });
    } else {
        yield put(onChangeFilter(""));
        toastSuccess({ header: "New styled block added", message: "Styled block \"New Block\" has been added to the list." });
    }
}

function* renameSnippet({payload}) {
    const [err] = yield snippetManager.renameSnippet(payload.snippetId, payload.name);
    if (err) {
        toastError({ code: err.code, header: "Failed to rename Styled Block", message: DB_ERROR });
    }
}

function* removeSnippet({payload}) {
    const [err] = yield snippetManager.removeSnippet(payload);
    if (err) {
        toastError({ code: err.code, header: "Failed to remove Styled Block", message: DB_ERROR });
    }
}

function* saveSnippet({ payload }) {
    try {
        const { id, data } = payload;
        const generateBlockStyles = firebase.getFirebaseFunctions().httpsCallable("generateSnippetStyles");
        const content  = yield generateBlockStyles({ id, data: { ...data, styleClass: undefined, key: undefined }});
        const [err] = yield snippetManager.updateSnippet(id, { ...data, styleClass: content.data });
        if (err) {
            toastError({ code: err.code, header: "Failed to change Styled Block", message: DB_ERROR });
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to change Styled Block", message: FUNCTION_ERROR });
    }
}

export default function* widgetTemplatesSaga() {
    yield all([
        takeLatest(TYPES.SNIPPETS_MANAGER_CREATE_NEW, createSnippet),
        takeLatest(TYPES.SNIPPETS_MANAGER_CHANGE_NAME, renameSnippet),
        takeLatest(TYPES.SNIPPETS_MANAGER_REMOVE_SNIPPET, removeSnippet),
        takeLatest(TYPES.SNIPPETS_MANAGER_SAVE_SNIPPET, saveSnippet),
    ]);
}