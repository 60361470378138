const TYPES = {
    EDIT_AKIT_EDIT: "AKits/Edit/onEdit",
    EDIT_AKIT_CLOSE: "AKits/Edit/onClose",
    EDIT_AKIT_UPDATE: "AKits/Edit/onUpdate",
    EDIT_AKIT_SET_VARIANTS: "AKits/Edit/onSetVariants",
    EDIT_AKIT_ASSIGN_VARIANT: "AKits/Edit/onAssignVariant",
    EDIT_AKIT_UNASSIGN_VARIANT: "AKits/Edit/onUnassignVariant",
    EDIT_AKIT_SAVED:  "AKits/Edit/onSaved",
    EDIT_AKIT_SET_DIRTY_FLAG:  "AKits/Edit/onSetDirtyFlag",
};

const onEditKit = (payload) => ({
    type: TYPES.EDIT_AKIT_EDIT,
    payload
});

const onClose = () => ({
    type: TYPES.EDIT_AKIT_CLOSE,
});

const onUpdate = (payload) => ({
    type: TYPES.EDIT_AKIT_UPDATE,
    payload
});

const onSaved = () => ({
    type: TYPES.EDIT_AKIT_SAVED
});

const onSetDirtyFlag = (payload) => ({
    type: TYPES.EDIT_AKIT_SET_DIRTY_FLAG,
    payload
});

const onSetVariants = (payload) => ({
    type: TYPES.EDIT_AKIT_SET_VARIANTS,
    payload
});

const onAssignVariant = (payload) => ({
    type: TYPES.EDIT_AKIT_ASSIGN_VARIANT,
    payload
});

const onUnassignVariant = (payload) => ({
    type: TYPES.EDIT_AKIT_UNASSIGN_VARIANT,
    payload
});

export {
    TYPES,
    onEditKit,
    onClose,
    onUpdate,
    onSetVariants,
    onUnassignVariant,
    onAssignVariant,
    onSaved,
    onSetDirtyFlag,
};
