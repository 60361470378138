import { takeEvery } from "redux-saga/effects";
import firebase from "../../../firebase";
import { sagaToastError, toastWarning } from "../../../component/toast";
import { TYPES } from "./removeTagsTopicsActions";
import { getProblemTags } from "./removeTagsTopicsUtils";
import { getItemCollectionByParent } from "../../utils";
import { getProblemEntityName, getProblemAuthorId } from "../ProblemUtils";

const writeNullOnPath = (updates, path) => {
    updates[path] = null;
    return updates;
};

const getProblemTagsCleaner = (tagNames) => {
    if (!tagNames || !tagNames.length) {
        return null;
    }
    const toRemove = new Set(tagNames);
    const filterFn = (tag) => !toRemove.has(tag);

    return (problem) => {
        const tags = getProblemTags(problem);
        const filtered = tags && tags.filter(filterFn);
        return filtered && filtered.length ? filtered : null;
    };
};

const buildProblemUpdates = (problems, topics, tags, authorId) => {
    const topicIds = Object.keys(topics || {});
    const clearProblemTags = getProblemTagsCleaner(Object.keys(tags || {}));

    return (acc, problemId) => {
        const problem = problems[problemId];
        if (problem) {
            if (authorId && authorId !== getProblemAuthorId(problem)) {
                acc.warning = true;
                return acc;
            }
            if (topicIds.length) {
                acc.changes = topicIds
                    .map(topicId => `${problemId}/topics/${topicId}`)
                    .reduce(writeNullOnPath, acc.changes);
            }
            if (clearProblemTags) {
                acc.changes[`${problemId}/tags`] = clearProblemTags(problem);
            }
        }
        return acc;
    };
};

export function* removeTagsTopics({
    payload: { fbCollection, problems, tags, topics, authorId } 
}) {
    const problemsRef = firebase.defaultApp.database().ref(getItemCollectionByParent(fbCollection));
    try {
        const ids = problems && Object.keys(problems);
        if (!ids || !ids.length) {
            return;
        }
        // If the mass-update of all selected problem with given topics & tags grows
        // too much then we could split the update by problem ID or partition of IDs.
        const { changes, warning } = ids.reduce(
            buildProblemUpdates(problems, topics, tags, authorId),
            { changes: {}, warning: null },
        );
        if (Object.keys(changes).length) {
            yield problemsRef.update(changes);
        }
        if (warning) {
            const entityName = getProblemEntityName(fbCollection);
            toastWarning({
                header: `Unable to update all selected ${entityName}(s)`,
                message: `You have to be author of ${entityName} to remove Tags/Topics.`,
                icon: "ban",
            });
        }
    } catch (error) {
        const entityName = getProblemEntityName(fbCollection);
        sagaToastError(`Failed to remove Tags/Topics from ${entityName}(s)`, error);
    }
}

export default function* saga() {
    yield takeEvery(TYPES.REMOVE_TAGS_TOPICS, removeTagsTopics);
}
