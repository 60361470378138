import React from "react";
import PropTypes from "prop-types";
import { Segment, Button, Label, Icon } from "semantic-ui-react";

export default class OrphanedProblems extends React.PureComponent {
    static propTypes = {
        onExecute: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        // progress: PropTypes.number,
    }

    render() {
        const { disabled, onExecute } = this.props;

        return (
            <Segment>
                <Label attached="top">Orphaned Problems</Label>
                <p>This allows to detect and optionally fix the problems and questions
                that were nested as children in another problem. They could have invalid
                reference to <b title="parentProblemId attribute">their parent</b> that
                doesn&apos;t exist anymore or they could have invalid references to 
                the <b title="problemSets attribute">problem sets</b> they have been 
                assigned.</p>
                <Button icon size="small" primary labelPosition="left"
                    name="OrphanedProblems"
                    title="Detect orphaned child problems now"
                    onClick={onExecute}
                    disabled={disabled}
                ><Icon name="play" />Run</Button>
            </Segment>
        );
    }
}
