import React from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";

import "./RemoveTagsTopics.scss";
import TagsToRemove from "./TagsToRemove";
import TopicsToRemove from "./TopicsToRemove";

const setTagSelected = (key, selected) => (state) => {
    const tags = state.tags || {};
    if (selected && tags[key] !== true) {
        const modified = {...tags, [key]: true };
        return { tags: modified }; // change to state
    } else if (!selected && tags[key]) {
        const modified = { ...tags };
        delete modified[key];
        return { tags: modified };
    }
    return null;
};

export default class RemoveTagsTopics extends React.PureComponent {

    static propTypes = {
        fbCollection: PropTypes.string.isRequired,
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        problems: PropTypes.object,
        topicIds: PropTypes.array,
        tagNames: PropTypes.array,
        authorId: PropTypes.string,
    }

    state ={
        topics: {},
        tags: {},
    }

    handleSave = () => {
        const { tags, topics } = this.state;
        const { fbCollection, problems, authorId, onSave, onClose } = this.props;
        onSave && onSave({ fbCollection, problems, tags, topics, authorId });
        onClose();
    }

    handleCancel = () => {
        const { onClose } = this.props;
        onClose();
    }

    handleTagToggle = (e, { name, checked }) => this.setState(setTagSelected(name, checked));

    handleTopicsSelection = (topics) => this.setState({ topics: { ...topics } });

    render() {
        const { tagNames, topicIds, fbCollection } = this.props;
        const { tags, topics } = this.state;
        const entityName = fbCollection === "problemSet" ? "Problems" : "Questions";

        return (
            <Modal centered={false} open={true} className="removeTagsTopics">
                <Modal.Header>Remove Tags and Topics</Modal.Header>
                <Modal.Content>
                    <Form>
                        <div className="guidelines">
                            Please, <b>check</b> the tags or topics <b>to remove</b> them from the selected {entityName}.
                        </div>
                        <TagsToRemove tagNames={tagNames} selected={tags} onChange={this.handleTagToggle} />
                        <TopicsToRemove topics={topicIds} selected={topics} onChange={this.handleTopicsSelection} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons onSave={this.handleSave} onCancel={this.handleCancel} />
                </Modal.Actions>
            </Modal>
        );
    }
}
