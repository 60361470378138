import React from "react";
import PropTypes from "prop-types";
import { Popup, Label, Divider } from "semantic-ui-react";
import { EditActionLink } from "bmd-react";

export default class TagsPopupList extends React.PureComponent {

    render() {
        const { tags, onEdit, trigger, canEdit } = this.props;

        return (
            <Popup  trigger={trigger} hoverable basic >
                <Popup.Header>Used tags</Popup.Header>
                <Popup.Content>
                    {Object.keys(tags).length ? Object.keys(tags).map((tag) => 
                        <Label key={tag}
                            style={{margin: "0.3em"}}
                        >{tag}</Label>
                    ) : 
                        <div style={{display: "inline", color: "grey", fontStyle: "italic"}}>No tags </div>
                    }
                    {canEdit && <React.Fragment>
                        <Divider />
                        <EditActionLink floated="right" buttonText="Modify tags" onClick={onEdit} />
                    </React.Fragment>
                    }
                </Popup.Content>
            </Popup>
        );
    }
}


TagsPopupList.propTypes = {
    tags: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    trigger: PropTypes.node.isRequired,
    canEdit: PropTypes.bool,
};