import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Segment, Label, Message } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
const validator = require("validator");

export default class LinkItemEditor extends PureComponent {
    static propTypes = {
        itemId: PropTypes.string.isRequired,
        widgetId: PropTypes.string.isRequired,
        content: PropTypes.object,
        onUpdateItem: PropTypes.func.isRequired,
        isSaving: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    state = { content: this.props.content, isDirty: false };

    handleChange = (e, { name, value }) => {
        const { content } = this.state;
        this.setState({ content: { ...content, [name]: value }, error: null, isDirty: true });
    };

    handleCancel = () => {
        const { widgetId, itemId, onCancel } = this.props;
        onCancel(widgetId, itemId);
    };

    handleSave = () => {
        const { itemId, widgetId, onUpdateItem } = this.props;
        const { content } = this.state;

        if (validator.isURL(content.href, { protocols: ["http", "https"], require_protocol: true })) {
            onUpdateItem({ widgetId, itemId, content });
        } else {
            this.setState({ error: "A filled-in link is not a valid URL." });
        }
    };

    render() {
        const { isSaving } = this.props;
        const { content, error, isDirty } = this.state;

        return (
            <Segment loading={isSaving}>
                <Label attached="top">Link Item Editor</Label>
                <Form onSubmit={this.handleSave}>
                    <Form.Input
                        label="Link Text"
                        name="name"
                        placeholder="Fill text of link"
                        value={content && content.name ? content.name : ""}
                        onChange={this.handleChange}
                    />
                    <Form.Input
                        label="URL"
                        name="href"
                        placeholder="Fill in the URL of a link that starts with http:// or https://"
                        value={content && content.href ? content.href : ""}
                        onChange={this.handleChange}
                    />
                    {/* <Form.Input
                    label="Tooltip"
                    name="title"
                    placeholder="Optionally fill link tooltip, if should be different than link name"
                    value={content && content.title ? content.title : ""}
                    onChange={this.handleChange}
                /> */}
                </Form>
                <SaveCancelButtons
                    onSave={this.handleSave}
                    saveDisabled={!isDirty}
                    onCancel={this.handleCancel}
                    size="mini"
                    padded
                />
                {error && <Message error content={error} />}
            </Segment>
        );
    }
}
