import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import SchoolList from "./SchoolList";
import { getSchools, isLoading } from "./schoolSelectors";
import { onSchoolLoad } from "./schoolActions";

const mapStateToProps = function(state) {
    return {
        schools: getSchools(state),
        isLoading: isLoading(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onSchoolLoad }, dispatch);
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(SchoolList);
