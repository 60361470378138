const TYPES = {
    DASHBOARD_SET: "DASHBOARD_SET",
};

const setCurrentDashboard = (dashboard) => ({ type: TYPES.DASHBOARD_SET, dashboard });

export {
    TYPES,
    setCurrentDashboard,
};
