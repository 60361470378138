import { createSelector } from "reselect";
import { mapCollectionToList, convertDatasetToList } from "../../utils/selectorUtils";
import { getVal } from "react-redux-firebase";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";

export const getProblemSets = (state, { fbCollection = "problemSet" }) => {
    // fbCollection default is important for Lessons, SiPi, ...
    return state.firebase && state.firebase.data && state.firebase.data[fbCollection];
};

export const problemSetLockPath = (fbCollection) => (fbCollection === "problemSet" ? "locks/ps" : "locks/ct");
export const problemSetLocks = (state, props) => getVal(state.firebase, `data/${problemSetLockPath(props.fbCollection)}`);
const convertProblemSetsToArray = (problemSets, excludePreCompTest) => {
    const ps = mapCollectionToList(problemSets);
    const psWithCount = [];
    ps.forEach( p => {
        p.problems_count = p.problems ? p.problems.length : 0;

        if (p.type && !p.setType) {  // different prop-name in comptests_common
            p.setType = p.type;
        }
        if (!excludePreCompTest || "pc" !== p.setType) {
            psWithCount.push(p);
        }
    });
    return psWithCount;
};

export const getProblemSetsAsArray = createSelector(
    getProblemSets,
    (problemSets) => convertProblemSetsToArray(problemSets, false)
);

export const getProblemSetsAsArrayWithoutPreComp = createSelector(
    getProblemSets,
    (problemSets) => convertProblemSetsToArray(problemSets, true)
);

const compareValueTitleCI = (l, r) => {
    const lv = ((l.value && l.value.title) || "").toLocaleLowerCase();
    const rv = ((r.value && r.value.title) || "").toLocaleLowerCase();
    return lv === rv ? 0 : (lv < rv ? -1 : 1);
};

export const getProblemSetsAsOptions = createSelector(
    getProblemSets,
    (problemSets) => {
        if (problemSets) {
            const psList = convertDatasetToList(problemSets).sort(compareValueTitleCI);
            const options = [];
            for (const { key, value } of psList) {
                if ("pc" !== value.setType) {
                    options.push({ key, value: key, text: value.title, description: `Type: ${value.setType} Question#: ${(value.problems && value.problems.length) || "0"}` });
                }
            }
            return options;
        }
    }
);

const isCompTestsCollection = (state, { fbCollection }) => (fbCollection === "comptests_common");
export const getProblemSetsQueryParams = createSelector(
    [ isCompTestsCollection, getSelectedYearId, tenantId ],
    (isCompTests, yearId, tenantId) => {
        if (isCompTests) {
            const equalTo = (yearId && tenantId) ? `equalTo=${yearId}@t${tenantId}` : "equalTo=-tyear-";
            return [ "orderByChild=_tyear", equalTo ];
        }
        return null;
    }
);