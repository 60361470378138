import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import { getNotLinkedTopics, getSelectedCollection } from "../../tttSelectors";
import { onTopicLinkToBela } from "../../BeLATopicCollection/belaTopicCollectionActions";
import LinkToExistingTopic from "./LinkToExistingTopic";

const mapStateToProps = function(state, props) {
    return {
        topics: getNotLinkedTopics(state, props),
        selectedCollection: getSelectedCollection(state)
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onTopicLinkToBela }, dispatch);
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(LinkToExistingTopic);
