import { createSelector } from "reselect";
import { mapCollectionToList } from "../../utils/selectorUtils";

const getSubjects = (state) => state.firebase.data.subjects;

export const getSortedSubjectNames = createSelector(
    getSubjects,
    (subjects) => {
        const validSubjects = [];
        if (subjects) {
            mapCollectionToList(subjects).map(s => {
                if (s.valid) {
                    validSubjects.push(s.name);
                }
            });
        }
        return validSubjects.sort();
    }
);
