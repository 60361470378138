import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { spinnerWhileLoading } from "../../../component/spinnerWhileLoading.jsx";
import * as ObjectiveActions from "./objectiveSetActions";
import { onAddOiiObjective } from "../../topicsActions";
import { getObjectiveSetsAsArray, getSelectedObjectiveSet, getOiiObjectivesAsArray, getSelectedObjectiveSetId } from "./objectiveSetSelector";

import ObjectiveSet from "./ObjectiveSet";

const mapStateToProps = function(state) {

    return {
        objectiveSets: getObjectiveSetsAsArray(state),
        selectedObjectiveSet: getSelectedObjectiveSet(state, getSelectedObjectiveSetId(state)),
        selectedObjectiveSetId: getSelectedObjectiveSetId(state),
        oiiObjectives: getOiiObjectivesAsArray(state),
        error: state.osmt.objectiveSet.error,
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...ObjectiveActions,
            onAddOiiObjective,
        },
        dispatch
    );
};

export default compose(
    // notice ObjectiveSet.componentDidMount() - workaround to re-register Firebase listeners 
    // (to prevent unsetting when switching from component using the same resources)
    firebaseConnect([ "objective_sets", "oii_objectives"]),
    connect(mapStateToProps, dispatchToProps),
    // show loading spinner while data are loading
    spinnerWhileLoading([ "objectiveSets", "oiiObjectives" ])
)(ObjectiveSet);