import {createSelector} from "reselect";
import { getSyllabiList } from "../SyllabiList/SyllabiListSelectors";
import { convertDatasetToList, compareValueByOrdering } from "../../utils/selectorUtils";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getResources } from "../component/ResourceLink/ResourceLinkSelectors";
import { templateLessonUnits, filterSiPiByScope, templateData } from "../SiPiTemplate/SiPiTemplateSelectors";
import { sipiTemplates } from "../SiPiTemplatesList/SiPiTemplatesListSelectors";

export const getSyllabusId = (state, props) => props.match && props.match.params  && props.match.params.syllabusId;
const syllabiData = (state) => state.firebase.data.syllabi && state.firebase.data.syllabi.data;
export const syllabiLessonUnits = (state) => state.firebase.data.syllabi && state.firebase.data.syllabi.lesson_units;

export const getSyllabus = createSelector(
    getSyllabusId,
    getSyllabiList,
    syllabiLessonUnits,
    sipiTemplates,
    tenantId,
    templateLessonUnits,
    (syllabusId, syllabi, iPlannerUnits, templates, tenantId, templateUnits) => {
        if (syllabusId && syllabi && syllabi[syllabusId] && iPlannerUnits && tenantId === syllabi[syllabusId].tenant_id) {
            let outline = undefined;
            let units = null;
            if (!syllabi[syllabusId].template_id || !syllabi[syllabusId].linked) {
                if (iPlannerUnits[syllabusId]) {
                    units = iPlannerUnits[syllabusId];
                }
            } else if (templateUnits && templateUnits[syllabi[syllabusId].template_id]) {
                units = templateUnits[syllabi[syllabusId].template_id];
            }
            if (units) {
                outline = {};
                units.map((lu, index) => {
                    if (lu.spork_cv_lu_id) {
                        const [unit, lesson] = lu.name.split(" :: ");
                        const newLesson = {...lu, name: lesson, index};
                        if (outline[unit]) {
                            outline[unit].push(newLesson);
                        } else {
                            outline[unit] = [newLesson];
                        }
                    } else {
                        if (outline.General) {
                            outline.General.push({ ...lu, index });
                        } else {
                            outline["General"] = [{ ...lu, index }];
                        }
                    }
                });
            } else if (null === units) {
                outline = null;
            }
            const syllabus = syllabi[syllabusId];
            let templateName = null;
            if (syllabus.template_id && templates && templates[syllabus.template_id]) {
                templateName = templates[syllabus.template_id].name;
            }
            return {...syllabus, outline, templateName };
        }
        if(syllabusId && syllabi && null === syllabi[syllabusId]) {
            return null;
        }
    }
);

export const getSyllabusSiPi = createSelector(
    getSyllabusId,
    syllabiData,
    (syllabusId, syllabusData) => {
        if (syllabusId && syllabusData) {
            if (syllabusData[syllabusId]) {
                const rawSyllabusData = syllabusData[syllabusId];
                const data = {};
                for (const lu of Object.keys(rawSyllabusData)) {
                    let activities = convertDatasetToList(rawSyllabusData[lu]);
                    activities = activities.sort(compareValueByOrdering);
                    data[lu] = activities;
                }
                return data;
            } else if (null === syllabusData[syllabusId]) {
                return null;
            }
        }
    }
);

export const getTemplateSiPi = createSelector(
    getSyllabusId,
    getSyllabiList,
    templateData,
    (syllabusId, syllabiInfo, templateData) => {
        if (syllabusId && syllabiInfo && syllabiInfo[syllabusId]) {
            const syllabus = syllabiInfo[syllabusId];
            if (syllabus && syllabus.template_id && syllabus.linked && templateData && templateData[syllabus.template_id]){
                const rawSyllabusData = templateData[syllabus.template_id];
                const data = {};
                for (const lu of Object.keys(rawSyllabusData)) {
                    let activities = convertDatasetToList(rawSyllabusData[lu]);
                    activities = activities.sort(compareValueByOrdering);
                    data[lu] = activities;
                }
                return data;
            } else if (null === templateData || templateData && null === templateData[syllabus.template_id]) {
                return null;
            }
        }
    }
);

const processDataByScope = (rawData, currentData) => {
    for (const lu of Object.keys(rawData)) {
        let activities = convertDatasetToList(rawData[lu]);
        const teacherFilter = filterSiPiByScope("teacher");
        const classroomFilter = filterSiPiByScope("classroom_view");
        const studentFilter = filterSiPiByScope("student_view");
        const teacher = activities.sort(compareValueByOrdering).filter(teacherFilter);
        const student_view = activities.sort(compareValueByOrdering).filter(studentFilter);
        const classroom_view = activities.sort(compareValueByOrdering).filter(classroomFilter);
        if (currentData[lu]) {
            currentData[lu] = {
                teacher: [...currentData[lu].teacher, ...teacher],
                student_view: [...currentData[lu].student_view, ...student_view],
                classroom_view: [...currentData[lu].classroom_view, ...classroom_view],
            };
        } else {
            currentData[lu] = { teacher, student_view, classroom_view };
        }
    }
    return currentData;
};

export const getSyllabusSiPiByScope = createSelector(
    getSyllabusId,
    syllabiData,
    getSyllabiList,
    templateData,
    (syllabusId, syllabusData, syllabiInfo, templateData) => {
        if (syllabusId && syllabusData && syllabiInfo && syllabiInfo[syllabusId]) {
            const syllabus = syllabiInfo[syllabusId];
            let data = {};
            if (syllabus.template_id && syllabus.linked && templateData && templateData[syllabus.template_id]){
                data = processDataByScope(templateData[syllabus.template_id], data);
            }
            if (syllabusData[syllabusId]) {
                data = processDataByScope(syllabusData[syllabusId], data);
            }
            if (0 < Object.keys(data).length) {
                return data;
            } else if (null === syllabusData[syllabusId] && (!templateData || null === templateData[syllabus.template_id])) {
                return null;
            }
        }
    }
);

const compareResourceByNameCI = (l, r) => {
    const lv = ((l.resource && l.resource.name) || "").toLocaleLowerCase();
    const rv = ((r.resource && r.resource.name) || "").toLocaleLowerCase();
    return lv === rv ? 0 : (lv < rv ? -1 : 1);
};

export const getAssets= createSelector(
    getSyllabusId,
    syllabiData,
    getResources,
    (syllabusId, data, resources) => {
        if (syllabusId && data) {
            if (data[syllabusId] && data[syllabusId]["lu_assets"]) {
                const assetsData = data[syllabusId]["lu_assets"];
                const result = { used: {}, list: []};
                let assets = convertDatasetToList(assetsData);
                assets.map(({ value }) => {
                    result.used[value.resource_id] = true;
                    if (resources[value.resource_id]) {
                        result.list.push({ asset: value, resource: resources[value.resource_id] });
                    }
                });
                result.list = result.list.sort(compareResourceByNameCI);
                return result;
            } else if (null === data[syllabusId] || data[syllabusId] && !data[syllabusId]["lu_assets"]) {
                return null;
            }
        }
    }
);
