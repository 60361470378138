import { all, put, takeLatest, takeEvery, select, fork } from "redux-saga/effects";
import { TYPES, onSelectSiPiTemplate } from "./SiPiTemplatesListActions";
import { push } from "connected-react-router";
import firebase, { sipiData } from "../dataSource";
import { aKitManager } from "../../KitBuilder/dataSource";
import { sagaToastError } from "../../component/toast";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { onUpdateLessonOrder } from "../SiPiTemplate/SiPiTemplateActions";
import TemplateiPlannersSaga from "./component/TemplateiPlanners/TemplateiPlannersSaga";
const basePath = "sipi_templates";

function* selectSiPiTemplate ({ payload: { id }}) {
    yield put(push(`/iplanner/templates/${id}`));
}

function* createTemplate ({ payload: { name, modules, kit_id }}) {
    try {
        const userId = yield select(loggedUserId);
        const tenant_id = yield select(tenantId);
        let outlineId = null;
        if (kit_id) {
            outlineId = yield aKitManager.getOutlineIdByKit(kit_id);
        }
        const m = {};
        for (const moduleId of modules) {
            m[moduleId] = true;
        }
        const { key } = yield sipiData.createIPlanner(
            basePath,
            {
                author_kit_outline_id: outlineId,
                name,
                m,
                cv_ccas: { [userId]: true },
                created: new Date().toISOString(),
                tenant_id,
            }
        );
        if (kit_id) {
            const response = yield firebase.tabletProxy({
                method: "GET",
                uri: "/author-kit-outline/" + kit_id,
            });
            const lessonUnits = response.data.cvLUs.map((lu) => ({
                cv_lu_id: lu.id,
                spork_cv_lu_id: lu.id,
                length: lu.length,
                start_week: lu.startWeek,
                name: lu.name,
                lu_objectives: lu.luObjectives || null
            }));
            yield put(onUpdateLessonOrder({ iPlannerId: key, lessonUnits }));
        }
        yield put(onSelectSiPiTemplate({ id: key }));
    } catch (error) {
        sagaToastError("Creating iPlanner template failed.", error);
    }
}

export default function* sipiTemplatesList() {
    yield all([
        takeLatest(TYPES.SIPI_TEMPLATES_LIST_SELECT_TEMPLATE, selectSiPiTemplate),
        takeEvery(TYPES.SIPI_TEMPLATES_LIST_CREATE_TEMPLATE, createTemplate),
        fork(TemplateiPlannersSaga),
    ]);
}
