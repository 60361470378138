import { TYPES } from "./UserListActions";

const initialState = {
    editedUserId: "",
    variantsLoading: false,
    variants: {},
    isClaimsUpdating: false,
    userList: undefined,
    isLoading: false,
    userSimpleList: undefined,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.USER_LIST_EDITATION:
            return { ...state, editedUserId: payload };
        case TYPES.USER_LIST_CLOSE_EDITATION:
            return { ...state, editedUserId: "" };
        case TYPES.USER_LIST_VARIANTS_LOADING:
            return { ...state, variantsLoading: true, variants: {} };
        case TYPES.USER_LIST_SET_VARIANTS:
            return { ...state, variantsLoading: false, variants: payload };
        case TYPES.USER_CLAIMS_UPDATING:
            return { ...state, isClaimsUpdating: payload };
        case TYPES.USER_LIST_SET:
            return { ...state, userList: payload, isLoading: false };
        case TYPES.USER_SIMPLE_LIST_SET:
            return { ...state, userSimpleList: payload, isLoading: false };
        case TYPES.USER_LIST_LOAD:
        case TYPES.USER_SIMPLE_LIST_LOAD:
            return { ...state, isLoading: true };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
