const TYPES = {
    SYLLABUS_OUTLINES_GO_BACK: "SYLLABUS_OUTLINES_GO_BACK",
    SYLLABUS_OUTLINES_UPDATE_ACTIVITIES_ORDER: "SYLLABUS_OUTLINES_UPDATE_ACTIVITIES_ORDER",
    SYLLABUS_OUTLINES_UPDATE_ACTIVITY: "SYLLABUS_OUTLINES_UPDATE_ACTIVITY",
    SYLLABUS_OUTLINES_ADD_ACTIVITY: "SYLLABUS_OUTLINES_ADD_ACTIVITY",
    SYLLABUS_OUTLINES_REMOVE_ACTIVITY: "SYLLABUS_OUTLINES_REMOVE_ACTIVITY",
    SYLLABUS_OUTLINES_LOAD_IPLANNER_RESOURCES: "SYLLABUS_OUTLINES_LOAD_IPLANNER_RESOURCES",
    SYLLABUS_OUTLINES_MASS_ADD_SIPIS: "SYLLABUS_OUTLINES_MASS_ADD_SIPIS",
    SYLLABUS_OUTLINES_LOAD_CV_TEMPLATE_BY_CV: "SYLLABUS_OUTLINES_LOAD_CV_TEMPLATE_BY_CV",
    SYLLABUS_OUTLINES_SET_CV_TEMPLATE_BY_CV: "SYLLABUS_OUTLINES_SET_CV_TEMPLATE_BY_CV",
    SYLLABUS_OUTLINES_UPDATE_LESSON: "SYLLABUS_OUTLINES_UPDATE_LESSON",
    SYLLABUS_OUTLINES_CREATE_LESSON: "SYLLABUS_OUTLINES_CREATE_LESSON",
    SYLLABUS_OUTLINES_REMOVE_LESSON: "SYLLABUS_OUTLINES_REMOVE_LESSON",
    SYLLABUS_OUTLINES_UPDATE_LESSON_ORDER: "SYLLABUS_OUTLINES_UPDATE_LESSON_ORDER",
    SYLLABUS_OUTLINES_UPDATE_NAME: "SYLLABUS_OUTLINES_UPDATE_NAME",
    SYLLABUS_OUTLINES_REMOVE: "SYLLABUS_OUTLINES_REMOVE",
};

const onGoBack = () => ({
    type: TYPES.SYLLABUS_OUTLINES_GO_BACK
});

const onUpdateSiPiOrder = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_UPDATE_ACTIVITIES_ORDER,
    payload
});

const onUpdateSiPi = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_UPDATE_ACTIVITY,
    payload
});

const onAddSiPi = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_ADD_ACTIVITY,
    payload
});

const onRemoveSiPi = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_REMOVE_ACTIVITY,
    payload
});

const onLoadIplannerResources = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_LOAD_IPLANNER_RESOURCES,
    payload
});

const onMassAddSiPis = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_MASS_ADD_SIPIS,
    payload
});

const onLoadCVTemplateByCV = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_LOAD_CV_TEMPLATE_BY_CV,
    payload
});

const onSetCvtTemplate = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_SET_CV_TEMPLATE_BY_CV,
    payload
});

const onUpdateLesson = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_UPDATE_LESSON,
    payload
});

const onCreateLesson = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_CREATE_LESSON,
    payload
});

const onRemoveLesson = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_REMOVE_LESSON,
    payload
});

const onUpdateLessonOrder = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_UPDATE_LESSON_ORDER,
    payload
});

const onUpdateName = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_UPDATE_NAME,
    payload
});

const onRemove = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_REMOVE,
    payload
});

export {
    TYPES,
    onGoBack,
    onUpdateSiPiOrder,
    onUpdateSiPi,
    onAddSiPi,
    onRemoveSiPi,
    onLoadIplannerResources,
    onMassAddSiPis,
    onLoadCVTemplateByCV,
    onSetCvtTemplate,
    onUpdateLesson,
    onCreateLesson,
    onRemoveLesson,
    onUpdateLessonOrder,
    onUpdateName,
    onRemove,
};
