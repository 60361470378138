import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Dropdown, Icon, Message } from "semantic-ui-react";
import AddExistingProblemSet from "../AddExistingProblemSet";
import AddNewProblemSet from "../AddNewProblemSet";
import styles from "./LessonProblemSetMenu.module.scss";
import { confirmUnsave } from "../../utils";
import ReorderProblemSets from "../ReorderProblemSets";
import ReviewsCount from "../../../component/ReviewsCount";
import { REVIEW_TYPE } from "../../../../component/seamlessEditor/bookEditor/constants";


export default class LessonProblemSetMenu extends PureComponent {
    static propTypes = {
        entity: PropTypes.object,
        onSelectTab: PropTypes.func,
        lessonProblemSets: PropTypes.array,
        problemSet: PropTypes.object,
        onSelectProblemSet: PropTypes.func,
        hasEditRights: PropTypes.bool,
        selectedLesson: PropTypes.object,
        collection: PropTypes.string,
        lessonId: PropTypes.string,
        outlineId: PropTypes.string,
        createNewProblemSet: PropTypes.func,
        onSetProblemSet: PropTypes.func,
        onSetDirty: PropTypes.func,
        isDirty: PropTypes.bool,
    };

    state = {
        isOpen: false,
    }

    handleOpen = () => {
        const { isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you switch to another tab", onSetDirty)) {
            this.setState({ isOpen: true });
        }
    }
    handleClose = () => this.setState({ isOpen: false });

    handleSelectPs = (e, { value }) => {
        const {entity, onSelectTab } = this.props;
        onSelectTab(e, { name: entity.tabName});
        this.props.onSelectProblemSet(value);
        e.stopPropagation();
        this.handleClose();
    }

    renderReviewsCount = (reviewsCount) => {
        const reviews = (reviewsCount[REVIEW_TYPE.REPLACE] || 0) + (reviewsCount[REVIEW_TYPE.INSERT] || 0);
        const comments = reviewsCount[REVIEW_TYPE.COMMENT_ONLY];

        return (<ReviewsCount reviews={reviews} comments={comments} right={true} marginLeft={true} />);
    };

    render() {
        const { entity, lessonProblemSets, problemSet, hasEditRights, selectedLesson, collection, lessonId, outlineId, createNewProblemSet, onSetProblemSet } = this.props;
        const { isOpen } = this.state;
        const problemSetsCount = lessonProblemSets && lessonProblemSets.length || 0;

        return (
            <Dropdown
                trigger={<span>{entity.name} {problemSetsCount > 0 && (
                    <span className={styles.numberOfProblemSets}>{problemSetsCount}</span> || ""
                )}</span>}
                item
                open={isOpen}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                style={{ zIndex: 600 }}
                // direction="left"
                value={false} // to disable selection hilite
            >
                <Dropdown.Menu>
                    {problemSetsCount > 0 && <Dropdown.Header>Sets</Dropdown.Header>}
                    {problemSetsCount > 0 ? lessonProblemSets.map(ps => (
                        <Dropdown.Item className={styles.dropdownItem} active={problemSet && problemSet.id === ps.id} key={ps.id}  value={ps.id} onClick={this.handleSelectPs}>
                            {ps.name}
                            {ps.reviewsCount && this.renderReviewsCount(ps.reviewsCount)}
                        </Dropdown.Item>
                    )) : (hasEditRights ? <React.Fragment /> : <Message >Lesson has no {entity.name}.</Message>)}

                    {hasEditRights &&  (
                        <React.Fragment>
                            <Dropdown.Divider />
                            <Dropdown.Header>Actions</Dropdown.Header>
                            <AddExistingProblemSet
                                onSelect={this.handleClose}
                                entity={entity.singular}
                                trigger={<Dropdown.Item icon={<Icon name="plus" color="grey" />} text="Select existing" />}
                                collection={collection}
                                lessonId={lessonId}
                                outlineId={outlineId}
                                tabIndex={entity.tabName}
                            />
                            <AddNewProblemSet
                                onSelect={this.handleClose}
                                selectedLesson={selectedLesson}
                                entityName={entity.singular}
                                createNewProblemSet={createNewProblemSet}
                                onSetProblemSet={onSetProblemSet}
                                outlineId={outlineId}
                                lessonId={lessonId}
                                collection={collection}
                                tabIndex={entity.tabName}
                                lessonProblemSets={lessonProblemSets}
                            />
                            {problemSetsCount > 1 &&
                                <ReorderProblemSets
                                    entity={entity.singular}
                                    problemSets={lessonProblemSets}
                                    outlineId={outlineId}
                                    lessonId={lessonId}
                                    trigger={<Dropdown.Item icon={<Icon name="sort amount down" color="grey" />} text="Reorder" />}
                                />
                            }
                        </React.Fragment>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
