import { TYPES } from "./SchoolListItemActions";

const initialState = {
    schools: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case TYPES.SECTION_SET_SCHOOL:
            return { ...state, schools: {...state.schools, [payload.id]: payload } };
        default:
            return state;
    }
};
