const rules = require("./rbac-rules");

/**
 * Check if single role has permission for action.
 *
 * @param {string} role user role (RBAC rules key)
 * @param {string} action which action permission to check
 * @param {object} data security context (like { userId, authorId })
 */
const check = (role, action, data) => {
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;
    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;
    if (dynamicPermissions) {
        // Evaluate dynamic rule if provided, otherwise false
        const predicate = dynamicPermissions[action];
        return !!(predicate && predicate(data || {}));
    }
    return false;
};

/**
 * Check is any of user's roles have permission to do action
 * @param {string[]} roles user roles
 * @param {string} action which action permission to check
 * @param {object} data security context (like { userId, authorId })
 */
const checkRoles = (roles, action, data) => roles.findIndex(role => check(role, action, data)) !== -1;

const isPermitted = ({ userRoles, action, actionArray, data }) => {
    // If action is non-empty string then do a single test
    if (action && typeof action === "string") {
        return checkRoles(userRoles, action, data);
    }
    // If action is falsy then fallback to actionArray (both should be arrays here)
    const actions = action || actionArray;
    if (Array.isArray(actions) && actions.length) {
        return actions.findIndex(action => checkRoles(userRoles, action, data)) !== -1;
    }
    return false;
};

module.exports.isPermitted = isPermitted;
module.exports.roles = rules;
