import { connect } from "react-redux";
import { onAdminExecute, onAdminCleanup } from "./AdminFunctionsActions";
import { getAdminStatus, isAdminRunning, isAdminFailure, getAdminLog } from "./AdminFunctionsSelectors";
import AdminFunctions from "./AdminFunctions";

export default connect(
    (state) => ({
        isRunning: isAdminRunning(state),
        isFailure: isAdminFailure(state),
        message: getAdminStatus(state),
        logData: getAdminLog(state),
    }),
    {
        onExecute: onAdminExecute,
        onCleanup: onAdminCleanup,
    }
)(AdminFunctions);
