import { TYPES } from "./TopicTreeSelectorActions";

// The initial state
const initialState = {
    kitId: null,
    isSearching: false,
    lessonSearchFilter: "",
    searchResult: null,
    lessonFilter: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.TOPIC_TREE_SELECTOR_SEARCH_LESSON:
            return { ...state, isSearching: true, lessonSearchFilter: payload };
        case TYPES.TOPIC_TREE_SELECTOR_SET_SEARCH_RESULT:
            return { ...state, isSearching: false, searchResult: payload };
        case TYPES.TOPIC_TREE_SELECTOR_SET_AKIT:
            return {...state, kitId: payload };
        case TYPES.TOPIC_TREE_SELECTOR_SET_LESSON_FILTER:
            return {...state, lessonFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
