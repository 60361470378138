import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { List, Message } from "semantic-ui-react";
import { onTemplateiPlannerRequest } from "./TemplateiPlannersActions";
import { getTemplateiPlanners } from "./TemplateiPlannersSelectors";

class IPlannerLink extends React.PureComponent {
    static propTypes = {
        iPlannerId: PropTypes.string,
        name: PropTypes.string,
    };
    render() {
        const { iPlannerId, name } = this.props;
        return (
            <Link to={`/iplanner/syllabi/${iPlannerId}`}>
                <b>{name}</b>
            </Link>
        );
    }
}

export class TemplateiPlanners extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        onTemplateiPlannerRequest: PropTypes.func.isRequired,
        templateId: PropTypes.string.isRequired,
        usedBy: PropTypes.object,
        iPlanners: PropTypes.array,
        loading: PropTypes.bool,
        failure: PropTypes.string,
        others: PropTypes.number,
    };

    componentDidMount() {
        const { templateId, usedBy, onTemplateiPlannerRequest } = this.props;
        onTemplateiPlannerRequest({ templateId, usedBy });
    }

    render() {
        const { failure, iPlanners, others } = this.props;
        const hasiPlanners = iPlanners && iPlanners.length ? true : false;
        return (
            <React.Fragment>
                {failure && <Message size="small" error content={failure} />}
                {hasiPlanners ? (
                    <List bulleted>
                        {iPlanners.map(iP => (
                            <List.Item key={iP.iPlannerId}>
                                <IPlannerLink {...iP} />
                            </List.Item>
                        ))}
                    </List>
                ) : null}
                {others &&  <em>{hasiPlanners && "And "}3rd party iPlanner{1 < others && "s"}</em>}
            </React.Fragment>
        );
    }
}

export default connect(
    (state, props) => {
        const data = getTemplateiPlanners(state, props);
        const { loading, iPlanners, failure, others } = data || {};
        return { loading, iPlanners, failure, others };
    }, // just flatten the returned data
    {
        onTemplateiPlannerRequest
    }
)(TemplateiPlanners);