/**
 * Based on CSS Grid Layout (https://css-tricks.com/snippets/css/complete-guide-grid/).
 *
 * If we want to allow D&D and resizing tiles, we should consider https://www.npmjs.com/package/react-grid-layout.
 */
import React from "react";
import PropTypes from "prop-types";

import "./dashboard.css";

import Tiles from "./tiles";

export default class Dashboard extends React.PureComponent {
  static propTypes = {
      id: PropTypes.number.isRequired,
      tiles: PropTypes.arrayOf(
          PropTypes.shape({
              id: PropTypes.number.isRequired,
              x: PropTypes.number.isRequired,
              y: PropTypes.number.isRequired,
              width: PropTypes.number.isRequired,
              height: PropTypes.number.isRequired,
              componentName: PropTypes.string.isRequired
          })
      ).isRequired,
      design: PropTypes.object
  };

  render() {
      const { tiles, design } = this.props;

      return (
          <div className="dashboardGrid">
              {tiles.map(tile => {
                  const { componentName, ...props } = tile;
                  const TileComponent = Tiles[componentName];
                  return <TileComponent key={tile.id} {...props} design={design} />;
              })}
          </div>
      );
  }
}
