import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { bindActionCreators, compose } from "redux";
import * as LessonContentActions from "./LessonContentActions";
import {
    selectedLesson,
    selectedOutlineId,
    selectedLessonId,
    getKitId,
    getUserCommentsProps,
} from "./LessonContentSelectors";
import { getWidgetsInfo } from "../WidgetLibrary/WidgetLibrarySelectors";
import { getTemplatesAsOptions } from "../WidgetTemplates/WidgetTemplatesSelectors";
import { spinnerWhileLoading, renderWhile } from "../../component/spinnerWhileLoading";
import LessonContentContainer from "./LessonContentContainer";
import {
    getLessonPublishedStatus,
    mapLessonPublishedStatus,
} from "../Publisher/PublisherSelectors";
import { getAccessibleKits } from "../AKits/AKitsList/AKitsListSelectors";
import { AccessDeniedMessage } from "../../component/AccessDeniedMessage";

const LessonMainContainer = compose(
    connect((state, props) => ({
        lessonId: selectedLessonId(state, props),
        outlineId: selectedOutlineId(state, props),
    })),
    firebaseConnect((props) => ([
        "modules/list",
        "tenant_modules",
        `outlines_data/${props.outlineId}/kit_id`,
        "kits_data",
    ])),
    connect((state, props) => {
        const kitId = getKitId(state, props);
        const accessibleKits = getAccessibleKits(state, props);
        return { kitId, accessible: (null === accessibleKits || accessibleKits && accessibleKits[kitId] ? true : false) };
    }),
    renderWhile(props => !props.accessible, AccessDeniedMessage),
    firebaseConnect((props) => (
        props.accessible && [
            "widgetLibrary/list",
            "widgetLibrary/m",
            "widgetTemplates/list",
            "widgetTemplates/m",
            `outlines_data/${props.outlineId}/lessons/${props.lessonId}`
        ] || []
    ).concat(mapLessonPublishedStatus(props))),
    connect((state, props) => ({
        selectedLesson: selectedLesson(state, props),
        widgetCollection: getWidgetsInfo(state),
        templateAsOptions: getTemplatesAsOptions(state),
        publishedStatus: getLessonPublishedStatus(state, props),
    }), (dispatch) => bindActionCreators(LessonContentActions, dispatch)),
    firebaseConnect((props) => (props.kitId && props.accessible ? [
        `kits_data/${props.kitId}/comment_recipients`,
        `kits_data/${props.kitId}/name`,
    ] : [])),
    connect(getUserCommentsProps),
    spinnerWhileLoading(["selectedLesson", "templateAsOptions", "commentRecipients"]),
)(LessonContentContainer);

LessonMainContainer.displayName = "LessonMainContainer";

export default LessonMainContainer;