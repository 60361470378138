import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox, Table, Label } from "semantic-ui-react";
import memoizeOne from "memoize-one";

import { loggedUserId } from "../../../Users/UserProfile/UserProfileSelectors";
import { problemTypes } from "../../QuestionEditor/problemTypes";
import { replaceRelLinks } from "../../../utils/conversionUtils";
import { LockIcon } from "../../../Lock";
import { isProblemUnused, getProblemAuthorId, isProblemInSet } from "../ProblemUtils";
import ProblemRowIcons from "./ProblemRowIcons";
import styles from "./ProblemList.module.scss";

const getProblemsToDisplay = memoizeOne(
    (problems, selected, onlySelected) => (
        onlySelected ? problems.filter(problem => selected[problem.id]) : problems
    )
);

class ProblemList extends React.PureComponent {
    static propTypes = {
        fbCollection: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        problems: PropTypes.array,
        gridOptions: PropTypes.shape({
            options: PropTypes.array,
            columns: PropTypes.array,
        }),
        showOnlySelected: PropTypes.bool,
        selectedItems: PropTypes.object,
        onToggleSelected: PropTypes.func,
        onEditQuestion: PropTypes.func,
        onReviewQuestion: PropTypes.func,
        onRemove: PropTypes.func,
        filterProblemSetId: PropTypes.string,
    };

    formatField = (fieldName, fieldValue) => {
        if (!fieldValue) return fieldValue;
        if (fieldValue && fieldValue.constructor === Array) return fieldValue.join(", ");
        switch (fieldName){
            case "author":
            case "owner":
                return `${fieldValue["first_name"]} ${fieldValue["last_name"]}`;
            case "type":
            case "problemType":
                return problemTypes.find(pt => pt.key === fieldValue).text;
            case "displayTopics":
                return Object.keys(fieldValue).join(", ");
            case "displayProblemSets":
                return Object.keys(fieldValue).map(key => {
                    return (<li style={{listStyleType: "circle"}} key={key}>{fieldValue[key].title}</li>);
                });
            default:
                return fieldValue;
        }
    };

    handleToggleSelected = (e, { name, checked }) => {
        this.props.onToggleSelected(e, { id: name, checked});
    }

    renderProblemRow = (problem) => {
        const {
            filterProblemSetId,
            gridOptions,
            selectedItems,
            fbCollection,
        } = this.props;
        const problemId = problem.id;
        const isLocked = !!problem.lock;
        const isNested = !!problem.parentProblemId;

        const isChecked = selectedItems[problemId] ? true : false;
        const columns = gridOptions.columns.filter(col => col.value === true);
        const entityName = fbCollection === "problemSet" ? "problem" : "question";

        return <Table.Row key={problemId}>
            <Table.Cell width={1}>
                {isNested ? (
                    <Label horizontal basic color="grey" title={`Nested as a child of another ${entityName}`}>Child</Label>
                ) : isProblemInSet(problem, filterProblemSetId) ? (
                    <Label horizontal basic color="teal" title={`Already used in this set of ${entityName}s`}>In set</Label>
                ) : isLocked ? (
                    <LockIcon />
                ) : (
                    <Checkbox name={problemId} onChange={this.handleToggleSelected} checked={isChecked} />
                )}
            </Table.Cell>
            <Table.Cell width={2} singleLine={false} className={styles.question}>
                <div className={styles.preview} dangerouslySetInnerHTML={{__html: problem.question && replaceRelLinks(problem.question.content)}}></div>
            </Table.Cell>
            {columns.map(col => (
                <Table.Cell width={2} key={col.name}>
                    {this.formatField(col.dataKey, problem[col.dataKey])}
                </Table.Cell>
            ))}
            <Table.Cell width={1}>
                <ProblemRowIcons
                    fbCollection={fbCollection}
                    locked={isLocked}
                    unused={!isNested && isProblemUnused(problem)}
                    entityName={entityName}
                    entityId={problemId}
                    authorId={getProblemAuthorId(problem)}
                    userId={this.props.userId}
                    onEdit={this.props.onEditQuestion}
                    onReview={this.props.onReviewQuestion}
                    onRemove={this.props.onRemove}
                />
            </Table.Cell>
        </Table.Row>;
    }

    render() {
        const { problems, selectedItems, showOnlySelected, gridOptions } = this.props;

        return (
            <Table className={styles.problemTable}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Select</Table.HeaderCell>
                        <Table.HeaderCell>Question</Table.HeaderCell>
                        {gridOptions.columns.map(function(column){
                            if (column.value === true)
                                return <Table.HeaderCell key={column.dataKey}>{column.name}</Table.HeaderCell>;
                        })}
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {problems && problems.length ? (
                        getProblemsToDisplay(problems, selectedItems, showOnlySelected).map(this.renderProblemRow)
                    ) : (
                        <Table.Row>
                            <Table.Cell>No Rows</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        );
    }
}

export default connect((state, props) => ({
    userId: loggedUserId(state, props),
}), {})(ProblemList);