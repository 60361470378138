import { TYPES } from "./CreateProblemSetsDataActions";

const initialState = {
    inProgress: false,
    oldRemoved: false,
    fetched: false,
    dataCreated: false,
    done: false,
    count: 0,
    checkedTitles: 0,
    fixedTitles: 0,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.GENERATE_PROBLEM_SET_DATA:
            return { ...state, ...initialState, inProgress: true };
        case TYPES.GENERATE_PROBLEM_SET_DATA_FINISHED:
            return { ...state, inProgress: false };
        case TYPES.GENERATE_PROBLEM_SET_DATA_PROGRESS:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default reducer;
