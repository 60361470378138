import React from "react";
import PropTypes from "prop-types";
import { actionTypes as firebaseActions } from "react-redux-firebase";
import { Container, Menu, Icon, Modal, Dimmer, Loader, Header, Button } from "semantic-ui-react";

import QuestionEditor from "../QuestionEditor";
import ProblemSetWizard from "../ProblemSetWizard";
import ActionLinks from "../../component/ActionLinks";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import styles from "./ProblemSetCreator.module.scss";
import AccessControl from "../../auth/AccessControl";
import AddExistingProblemModal from "./AddExistingProblemModal";
import { LockPropType, LockTypeShape } from "../../Lock";
import LockableBlock from "../../Lock/LockableBlock";
import { htmlPlaceholder } from "../../KitBuilder/Publisher/LessonExports/LessonExportLinks";
import { ProblemSetPropShape } from "./ProblemSetUtils";
import ProblemSetNameHeader from "./ProblemSetNameHeader";
import ProblemSetReorder from "./ProblemSetReorder";
import ProblemSetContent from "./ProblemSetContent";

const getTextsByCollection = (fbCollection) => {
    switch (fbCollection) {
        case "problemSet": return {
            entityName: "Problem Set",
            shortEntityName: "set",
            itemName: "problem",
        };
        case "comptests_common": return {
            entityName: "Comprehensive Test",
            shortEntityName: "test",
            itemName: "question",
        };
    }
};

const AccessRights = {
    problemSet: {
        edit: [ "problemSet:manage", "problemSet:edit" ],
        view: [ "problemSet:view", "problemSet:review" ],
    },
    compTests: {
        edit: [ "compTests:manage", "compTests:edit" ],
        view: [ "compTests:view" ],
    }
};

class ProblemSetCreator extends React.PureComponent {
    static propTypes = {
        activeProblemSet: ProblemSetPropShape,
        problemDirectory: PropTypes.object,
        addProblem: PropTypes.func,
        editProblem: PropTypes.func,
        deleteProblem: PropTypes.func,
        deleteProblemSet: PropTypes.func,
        moveProblem: PropTypes.func,
        moveChildProblem: PropTypes.func,
        isLoading: PropTypes.bool,
        query: PropTypes.object,
        accessContext: PropTypes.object,
        fbCollection: PropTypes.string.isRequired,
        onBackToLesson: PropTypes.func,
        onSetOutlineForLessonLinks: PropTypes.func,
        assignProblems: PropTypes.func.isRequired,
        isLocked: PropTypes.bool,
        isLockedByMe: PropTypes.bool,
        lock: LockPropType,
        lockType: LockTypeShape,
        dispatch: PropTypes.func.isRequired,
        onPdfExport: PropTypes.func,
        history: PropTypes.object,
    };

    state = {
        editItem: null,
        showConfirm: null,
        alertMessage: "",
        showProblemSetDialog: false,
        showProblemEditDialog: false,
        parentProblemId: null,
        isReordering: false,
        lastUpdatedOnOpen: null,
        entityName: "",
        shortEntityName: "",
        itemName: "",
    };

    componentDidMount() {
        const { onSetOutlineForLessonLinks, query, activeProblemSet, fbCollection } = this.props;
        if (query && query.outline) {
            this.setState({ lastUpdatedOnOpen: activeProblemSet.lastUpdated });
            onSetOutlineForLessonLinks(query.outline);
        } else {
            onSetOutlineForLessonLinks(null);
        }

        this.setState(getTextsByCollection(fbCollection));
    }

    componentWillUnmount() {
        // remove downloaded data from Redux to avoid memory exhaustion
        if (this.props.dispatch) {
            this.props.dispatch({ type: firebaseActions.SET, path: "problemSetItems" });
        }
    }

    handleAddChild = parentProblemId => {
        this.setState({ parentProblemId, showProblemEditDialog: true });
    };

    handleMoveChild = (parentProblemId, problemId, direction) => {
        const { moveChildProblem } = this.props;
        moveChildProblem && moveChildProblem({ parentProblemId, problemId, direction });
    };

    handleProblemDelete = (problemId, parentProblemId) => {
        const { deleteProblem, activeProblemSet } = this.props;
        deleteProblem && deleteProblem({ problemId, activeProblemSet, parentProblemId });
    };

    handleAddProblemToSet = problem => {
        const { activeProblemSet, addProblem } = this.props;
        const position = undefined === activeProblemSet.problems ? 0 : activeProblemSet.problems.length;
        addProblem && addProblem({
            problem,
            position,
            activeSetId: activeProblemSet.id,
        });
    };

    handleProblemEdit = (id) => {
        const { problemDirectory } = this.props;
        const editItem = Object.assign({}, problemDirectory[id], { id: id });
        this.setState({ editItem, showProblemEditDialog: true });
    };

    handleEditSave = (problem) => {
        const { editProblem } = this.props;
        editProblem && editProblem(problem);
    };

    handleDeleteSet = () => {
        const { deleteProblemSet, activeProblemSet, history } = this.props;
        deleteProblemSet && deleteProblemSet(activeProblemSet);

        this.setState({ showConfirm: false }, history.push("/qu/problem-set-manager"));
    };

    handleEditCallback = () => this.setState({ editItem: null, showProblemEditDialog: false, parentProblemId: null });
    handleProblemSetEdit = () => this.setState({ showProblemSetDialog: true });
    handleProblemSetClose = () => this.setState({ showProblemSetDialog: false });
    handleClearAlert = () => this.setState({ alertMessage: "" });
    handleShowConfirm = e => this.setState({ showConfirm: e.currentTarget });
    handleHideConfirm = () => this.setState({ showConfirm: null });
    // eslint-disable-next-line react/no-access-state-in-setstate
    handleReOrderSet = () => this.setState({ isReordering: !this.state.isReordering });

    handleRedirectBack = () => {
        const listUrl = this.props.fbCollection === "problemSet" ? "/qu/problem-set-manager" : "/qu/comp-test-manager";
        this.props.history.push(listUrl);
    };

    handleCreateNewProblem = () => {
        this.setState({ showProblemEditDialog: true });
    };

    handleAddProblem = () => {
        this.setState({ showAddExistingProblem: true });
    };

    handleCloseAddProblem = () => {
        this.setState({ showAddExistingProblem: false });
    };

    handleAssignProblemsToSet = selectedItems => {
        const { assignProblems, activeProblemSet } = this.props;
        assignProblems({ problemIds: selectedItems, activeSetId: activeProblemSet.id });
        this.handleCloseAddProblem();
    };

    handleBackToLesson = () => {
        const { query, onBackToLesson, activeProblemSet } = this.props;
        const { lastUpdatedOnOpen } = this.state;
        onBackToLesson({
            ...query,
            isProblemSetChanged: activeProblemSet.lastUpdated != lastUpdatedOnOpen,
            id: activeProblemSet.id,
        });
    };

    // detect if any problem in this set has already a lock
    cantBeLocked = () => {
        const { problemDirectory, isLocked, activeProblemSet } = this.props;
        const problemList = activeProblemSet && activeProblemSet.problems ? activeProblemSet.problems : [];
        return (
            isLocked ||
            problemList
                .map(id => (problemDirectory[id] ? problemDirectory[id].lock : null))
                .reduce((acc, lock) => acc || !!lock, false)
        );
    };

    handlePdfExport = () => {
        const { onPdfExport, fbCollection, activeProblemSet } = this.props;
        const exportWindow = window.open("", "_blank");
        exportWindow.blur();
        exportWindow.document.body.innerHTML = htmlPlaceholder;
        onPdfExport({ id: activeProblemSet.id, fbCollection, exportWindow });
    }

    render() {
        const {
            editItem,
            alertMessage,
            showProblemEditDialog,
            showProblemSetDialog,
            parentProblemId,
            isReordering,
            showAddExistingProblem,
            entityName,
            shortEntityName,
            itemName,
        } = this.state;
        const {
            activeProblemSet,
            isLoading,
            query,
            lock,
            isLocked,
            isLockedByMe,
            problemDirectory,
            fbCollection,
            lockType,
            accessContext,
            moveProblem,
        } = this.props;
        const disabledByLock = isLocked && !isLockedByMe;
        const hasDelete = fbCollection === "problemSet";
        const needRights = fbCollection === "problemSet" ? AccessRights.problemSet : AccessRights.compTests;

        const isUsed = !!(activeProblemSet && (activeProblemSet.lessons_homework || activeProblemSet.lessons_problem_sets));
        const inLessonContext = query && query.lesson && query.outline ? true : false;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    {entityName}
                </Header>
                {activeProblemSet != null && (
                    <div>
                        {inLessonContext ? (
                            <ActionLinks
                                horizontal
                                actions={[
                                    {
                                        name: "back",
                                        content: "Back to Lesson",
                                        icon: "arrow left",
                                        onClick: this.handleBackToLesson,
                                    },
                                ]}
                            />
                        ) : (
                            <ActionLinks
                                horizontal
                                actions={[
                                    {
                                        name: "back",
                                        content: `Back to ${entityName}s`,
                                        icon: "arrow left",
                                        onClick: this.handleRedirectBack,
                                    },
                                ]}
                            />
                        )}
                    </div>
                )}
                <Dimmer active={isLoading} inverted page={false}>
                    <Loader>Loading</Loader>
                </Dimmer>
                {showProblemEditDialog && (
                    <QuestionEditor
                        fbCollection={fbCollection}
                        onAdd={this.handleAddProblemToSet}
                        onEdit={this.handleEditSave}
                        onClose={this.handleEditCallback}
                        editMode={editItem ? true : false}
                        {...(editItem
                            ? { question: editItem }
                            : { question: { tags: activeProblemSet.tags || [], problemType: "oa", status: "New" } })}
                        {...(parentProblemId && {
                            question: {
                                parentProblemId,
                                problemType: "oa",
                                // child problem should inherit the tags and topics from parent problem
                                tags: problemDirectory[parentProblemId].tags || [],
                                topics: problemDirectory[parentProblemId].topics || {},
                            },
                        })}
                    />
                )}
                {showAddExistingProblem && (
                    <AddExistingProblemModal
                        fbCollection={fbCollection}
                        onClose={this.handleCloseAddProblem}
                        onAddToProblemSet={this.handleAssignProblemsToSet}
                        problemSetId={activeProblemSet.id}
                    />
                )}
                <Container>
                    {activeProblemSet != null ? (
                        <React.Fragment>
                            <AccessControl action={needRights.edit} data={accessContext}>
                                <Menu>
                                    <Menu.Item
                                        name="edit"
                                        onClick={this.handleProblemSetEdit}
                                        disabled={isReordering || disabledByLock}
                                    >
                                        <Icon name="edit" />
                                        Edit
                                    </Menu.Item>
                                    <Menu.Item
                                        name="addQuestion"
                                        onClick={this.handleCreateNewProblem}
                                        disabled={isReordering || disabledByLock}
                                    >
                                        <Icon name="plus" />
                                        Create new {itemName}
                                    </Menu.Item>
                                    <Menu.Item
                                        name="addExistingQuestion"
                                        onClick={this.handleAddProblem}
                                        disabled={isReordering || disabledByLock}
                                    >
                                        <Icon name="plus" />
                                        Add existing {itemName}
                                    </Menu.Item>
                                    <Menu.Item
                                        className={isReordering ? styles.reorderActive : null}
                                        name="reorderSet"
                                        onClick={this.handleReOrderSet}
                                        disabled={disabledByLock}
                                    >
                                        <Icon name="sort amount down" />
                                        {isReordering ? "Done reordering" : "Reorder set"}
                                    </Menu.Item>
                                    <Menu.Menu position="right">
                                        <Menu.Item
                                            name="pdfExport"
                                            onClick={this.handlePdfExport}
                                        >
                                            <Icon name="pdf file outline" />
                                            Export
                                        </Menu.Item>
                                        {hasDelete && <React.Fragment>
                                            <Menu.Item
                                                disabled={isReordering || isUsed || isLocked}
                                                title={
                                                    isLocked
                                                        ? "Locked item can't be deleted"
                                                        : isUsed
                                                            ? "Problem set cannot be deleted, because is already used."
                                                            : "Delete problem set"
                                                }
                                                onClick={this.handleShowConfirm}
                                            >
                                                <Icon name="trash alternate outline" /> Delete
                                            </Menu.Item>
                                            <ConfirmDeleteWithContext
                                                // eslint-disable-next-line react/jsx-handler-names
                                                context={this.state.showConfirm}
                                                confirmText={"Remove this problem set"}
                                                onConfirm={this.handleDeleteSet}
                                                whatText={"this problem set"}
                                                onCancel={this.handleHideConfirm}
                                            />
                                        </React.Fragment>}
                                    </Menu.Menu>
                                </Menu>
                            </AccessControl>
                            {activeProblemSet && <ProblemSetNameHeader {...activeProblemSet} />}
                        </React.Fragment>
                    ) : (
                        <NoDataPlaceholder
                            style={{ minHeight: "150px" }}
                            icon="file outline"
                            text={`This ${entityName} does not exist.`}
                        >
                            <p>Please return to the {entityName}s to edit an existing {shortEntityName}
                                {fbCollection === "problemSet" ? "or create new set" : "" /* Other collections are created outside SPORK */}.
                            </p>
                            <Button primary content={`Go to ${entityName} list`} onClick={this.handleRedirectBack} />
                        </NoDataPlaceholder>
                    )}
                    <LockableBlock 
                        lock={lock}
                        lockType={lockType}
                        cantBeLocked={this.cantBeLocked()}
                        accessContext={accessContext}
                    />
                    {isReordering ? (
                        <ProblemSetReorder
                            problemSet={activeProblemSet}
                            problemData={problemDirectory}
                            moveProblem={moveProblem}
                        />
                    ) : (
                        <ProblemSetContent {...accessContext}
                            fbCollection={fbCollection}
                            problemSet={activeProblemSet}
                            problemData={problemDirectory}
                            onEdit={this.handleProblemEdit}
                            onDelete={this.handleProblemDelete}
                            onMoveChild={this.handleMoveChild}
                            onAddChild={this.handleAddChild}
                        />
                    )}
                </Container>
                {showProblemSetDialog && (
                    <ProblemSetWizard
                        open={showProblemSetDialog}
                        onClose={this.handleProblemSetClose}
                        fbCollection={fbCollection}
                        inLessonContext={inLessonContext}
                        problemSet={activeProblemSet}
                        problems={activeProblemSet && activeProblemSet.problems || []}
                        editMode={showProblemSetDialog}
                    />
                )}
                <Modal open={alertMessage.length > 0} closeIcon onClose={this.handleClearAlert}>
                    <Modal.Content>
                        <p>{alertMessage}</p>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

ProblemSetCreator.defaultProps = {
    activeProblemSet: {
        title: "",
        notes: "",
        setType: "",
        problems: [],
    },
};

export default ProblemSetCreator;
