import {createSelector} from "reselect";
import _ from "lodash";
import { getWidgetTemplatesData } from "../WidgetTemplates/WidgetTemplatesSelectors";
import {
    mapCollectionToList,
    getSearchByNamePredicate,
    compareByNameCI,
    convertDatasetToList,
    compareValueByPosition,
    getSearchByUnusedPredicate,
    getSearchByTagsArrayPredicate,
    denormalizeTopicKey,
    getSearchByOptionalIndexPredicate,
} from "../../utils/selectorUtils";
import { getTopics } from "../../Topics/topicsSelectors";
import { formatWidgetItemUid } from "./WidgetLibraryUtils";
import { getVal } from "react-redux-firebase";
import { getTopicCollectionData } from "../../TopicCollection/topicCollectionSelectors";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getEnabledModules } from "../../External/Tenants/tenantSelectors";

const getWidgetLibrary = (state) => state.firebase.data.widgetLibrary;

export const widgetLibrary = createSelector(getWidgetLibrary, (widgets) => widgets);

export const widgetLocks = state => getVal(state.firebase, "data/locks/w");
export const selectedWidgetId = (state, props) => props.widgetId || props.match && props.match.params  && props.match.params.widgetId;
export const reviewMode = (state) => state.kitBuilder.widgetLibrary.reviewMode;
export const isItemSaving = (state, props) => state.kitBuilder.widgetLibrary.isSaving[formatWidgetItemUid(props)] === true;
export const isItemEditing = (state, props) => state.kitBuilder.widgetLibrary.isEditing[formatWidgetItemUid(props)] === true;
export const unusedFilter = (state) => state.kitBuilder.widgetLibrary.unusedFilter;
export const moduleFilter = (state) => state.kitBuilder.widgetLibrary.moduleFilter;
export const isDirty = (state) => state.kitBuilder.widgetLibrary.isDirty;
export const getWidgetLibraryData = (state) => (
    state.firebase.data.widgetLibrary && state.firebase.data.widgetLibrary.data
);
export const getWidgetsInfo = (state) => (
    state.firebase.data.widgetLibrary && state.firebase.data.widgetLibrary.list
);
export const getWidgetsModules = (state) => state.firebase.data.widgetLibrary && state.firebase.data.widgetLibrary.m;
export const selectedWidgetModules = (state, props) => getWidgetsModules(state) && getWidgetsModules(state)[props.widgetId] && Object.keys(getWidgetsModules(state)[props.widgetId]) || [];
export const getPotentiallyUnknownWidgets = (state) => state.firebase.data.potentiallyUnknownWidgets;
export const loadingWidgetData = createSelector(
    selectedWidgetId,
    getPotentiallyUnknownWidgets,
    getWidgetLibraryData,
    getWidgetTemplatesData,
    getWidgetsModules,
    (widgetId, potentiallyUnknownWidgets, widgetsData, templateData, widgetModules) => {
        if (potentiallyUnknownWidgets && null === potentiallyUnknownWidgets[widgetId]) {
            return false;
        }
        if (undefined === widgetId
            || undefined === widgetsData
            || undefined === widgetsData[widgetId]
            || null === widgetsData[widgetId]
            || undefined === templateData
            || undefined === templateData[widgetsData[widgetId].templateId]
            || undefined === widgetModules
        ) {
            return true;
        }
        return false;
    }
);

export const getSelectedWidget = createSelector(
    selectedWidgetId,
    getWidgetsInfo,
    getWidgetLibraryData,
    getWidgetTemplatesData,
    widgetLocks,
    getWidgetsModules,
    tenantId,
    getEnabledModules,
    (key, widgetInfo, widgetData, templateData, widgetLocks, widgetModules, tenantId, enabledModules) => {
        const info = key && widgetInfo && widgetInfo[key];
        const data = widgetData && widgetData[key];
        if (data && info && templateData && templateData[info.templateId] && enabledModules) {
            const entityTenant = info.t || 1;
            if (tenantId === entityTenant || widgetModules[key] && enabledModules.some(j => !!widgetModules[key][j])) {
                return {
                    key,
                    ...info,
                    ...data,
                    template: templateData[info.templateId],
                    lock: widgetLocks ? widgetLocks[key] : undefined,
                    modules: widgetModules && widgetModules[key] ? Object.keys(widgetModules[key]) : undefined
                };
            } else {
                return { noAccess: true, key };
            }
        }
        return null;
    }
);

export const getOrderedItemsList = createSelector(getSelectedWidget, (widget) => (
    widget && widget.template && widget.template.items
        ? convertDatasetToList(widget.template.items).sort(compareValueByPosition)
        : []
));

export const getSortedWidgets = createSelector(
    getWidgetsInfo,
    (widgets) => (widgets && mapCollectionToList(widgets).sort(compareByNameCI))
);

export const editing = (state) => state.kitBuilder.widgetLibrary.editing;

export const searchFilter = (state) => state.kitBuilder.widgetLibrary.searchFilter;

export const selectedTags = (state) => state.kitBuilder.widgetLibrary.selectedTags;
export const selectedTopic = (state) => state.kitBuilder.widgetLibrary.selectedTopic;

export const usedTags = createSelector(
    getSortedWidgets,
    (widgets) => {
        const tags = [];
        if (widgets) {
            widgets.map(widget => {
                if (widget.tags) {
                    Object.keys(widget.tags).map(tag => tags.push({ key: tag, value: tag, text: tag }));
                }
            });
            return _.uniqBy(_.sortBy(tags, ["key"]), "key");
        }
    }
);

export const usedTopics = createSelector(
    getSortedWidgets,
    (widgets) => {
        let topics = {};
        if (widgets) {
            widgets.forEach(widget => {
                if (widget.topics) {
                    topics = {...widget.topics, ...topics};
                }
            });
            return topics;
        }
    }
);

export const filteredWidgets = createSelector(
    getSortedWidgets,
    selectedTags,
    searchFilter,
    unusedFilter,
    selectedTopic,
    moduleFilter,
    getWidgetsModules,
    tenantId,
    getEnabledModules,
    (widgets, selectedTags, searchFilter, unusedFilter, selectedTopic, modFilter, widgetModules, tenant, enabledModules) => {
        let filteredData = widgets || [];
        const unusedPredicate = getSearchByUnusedPredicate(unusedFilter);
        if (unusedPredicate) {
            filteredData = filteredData.filter(unusedPredicate);
        }
        const tagsPredicate = getSearchByTagsArrayPredicate(selectedTags);
        if (tagsPredicate) {
            filteredData = filteredData.filter(tagsPredicate);
        }
        const namePredicate = getSearchByNamePredicate(searchFilter);
        if (namePredicate) {
            filteredData = filteredData.filter(namePredicate);
        }
        if (selectedTopic) {
            filteredData = filteredData.filter(w => w.topics && w.topics[selectedTopic]);
        }
        const modulePredicate = getSearchByOptionalIndexPredicate(modFilter, widgetModules, tenant, enabledModules, false);
        filteredData = filteredData.filter(modulePredicate);
        return filteredData.sort(compareByNameCI);
    }
);

export const getWidgetsAsOptions = createSelector(getSortedWidgets, (widgets) => {
    const options = [];
    if (widgets) {
        widgets.map(t =>
            options.push({ key: t.key, value: t.key, text: t.name })
        );
        return options;
    }
});

const getAllWidgetsTopics = createSelector(
    getSortedWidgets,
    (widgets) => {
        if (widgets) {
            const topics = {};
            widgets.map(w => w.topics && Object.keys(w.topics).map(t => topics[t] = true ));
            return topics;
        }
    }
);

export const getUsedTopicOptions = createSelector(
    getTopics,
    getAllWidgetsTopics,
    (topicsData, usedTopics) => {
        const topics = [];
        usedTopics && topicsData && Object.keys(usedTopics).map(id => {
            if (topicsData[id]) {
                topics.push({ key: id, value: topicsData[id] });
            }
        });
        return topics;
    }
);

export const getTopicFilterName = createSelector(
    selectedTopic,
    getTopicCollectionData,
    (topicId, topicsData) => {
        if (topicsData && topicId) {
            const  [collection, topic] = denormalizeTopicKey(topicId);
            if (topicsData[collection] && topicsData[collection][topic]) {
                return topicsData[collection][topic].name;
            }
        }
    }
);
