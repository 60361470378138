import React from "react";
import { SideBarMenuLink } from "../component/SidebarMenuLink";
import { Menu } from "semantic-ui-react";
import AccessControl from "../auth/AccessControl";

const SidebarMenu = () => (
    <AccessControl action="tablet:manage">
        <Menu.Item>
            <Menu.Header>Tablets</Menu.Header>
            <Menu.Menu>
                <SideBarMenuLink to="/tablet/devices" text="Devices" />
            </Menu.Menu>
        </Menu.Item>
    </AccessControl>
);

export default SidebarMenu;
