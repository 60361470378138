import { convertDatasetToList } from "../../utils/selectorUtils";

const compareByPosition = (l, r) => {
    const lpos = (l.value && l.value.position) || -1;
    const rpos = (r.value && r.value.position) || -1;
    return lpos - rpos || (l.key < r.key ? -1 : l.key > r.key ? 1 : 0);
};

const orderedByPosition = (entries, predicate) => {
    const list = entries ? convertDatasetToList(entries) : [];
    return predicate
        ? list.filter(predicate).sort(compareByPosition)
        : list.sort(compareByPosition);
};

export default orderedByPosition;