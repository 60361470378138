import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Segment, Button } from "semantic-ui-react";
import ItemPopup from "./ItemPopup";
import DeleteItem from "../../component/DeleteItem";
import WidgetItemTextEditor from "./WidgetTextItemEditor";
import ItemLabel from "../../component/ItemLabel";
import WidgetItem from "../../Widget/WidgetItem";
import TemplateQuestionItemEditor from "./TemplateQuestionItemEditor";
import { isItemSaving } from "../WidgetTemplatesSelectors";
import { connect } from "react-redux";
import { compose } from "redux";

class TemplateEditorItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        content: PropTypes.object,
        onUpdateItem: PropTypes.func,
        onRemoveItem: PropTypes.func,
        id: PropTypes.string,
        editorKey: PropTypes.string,
        enableRemove: PropTypes.bool,
        sharedToolbar: PropTypes.object,
        registerGetContentFn: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
        isItemSaving: PropTypes.bool,
    };

    handleRegisterGetContentFn = fn => {
        const { id, editorKey, registerGetContentFn } = this.props;
        registerGetContentFn(id, editorKey, fn);
    };

    handleDirty = () => {
        const { id, editorKey, onDirty } = this.props;
        onDirty(id, editorKey);
    };

    componentWillUnmount = () => {
        // unregister this editor for parent list
        this.handleRegisterGetContentFn(null);
    };

    render() {
        const {
            id,
            item,
            content,
            onUpdateItem,
            onRemoveItem,
            enableRemove,
            sharedToolbar,
            isItemSaving,
        } = this.props;
        return (
            <Segment
                className="sortableItem"
                style={{
                    position: "relative",
                    background: "inherit",
                    zIndex: "auto",
                    overflow: "hidden",
                }}
                loading={isItemSaving}
            >
                <ItemLabel item={item} draggable>
                    <span>
                        {enableRemove && (
                            <DeleteItem
                                itemId={id}
                                onRemoveItem={onRemoveItem}
                                deleteText="the widget item"
                                trigger={
                                    <Button
                                        compact
                                        basic
                                        size="tiny"
                                        className="actionIcons right"
                                        icon="trash alternate outline"
                                        title="Remove the widget item"
                                    />
                                }
                            />
                        )}
                        <ItemPopup value={item} onUpdateItem={onUpdateItem} itemId={id} />
                    </span>
                </ItemLabel>
                <WidgetItem template={{ ...item, id: id }} content={content}>
                    {item.type === "text" ? (
                        <WidgetItemTextEditor
                            content={content}
                            onRegisterGetContentFn={this.handleRegisterGetContentFn}
                            sharedToolbar={sharedToolbar}
                            onDirty={this.handleDirty}
                        />
                    ) : "question" === item.type ? (
                        <TemplateQuestionItemEditor id={id} item={item} />
                    ) : null}
                </WidgetItem>
            </Segment>
        );
    }
}

export default compose(
    connect((state, props) => ({
        isItemSaving: isItemSaving(state, props),
    }))
)(TemplateEditorItem);
