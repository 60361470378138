import { createSelector } from "reselect";

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp).toISOString();
    return date.replace("T", " ").substr(0, 23);
};

const integerWithLeadingZero = (num, len = 2) => String(parseInt(num, 10)).padStart(len, "0");

const formatRuntime = (time) => {
    const timeInSec = parseInt(time / 1000, 10);
    const msec = integerWithLeadingZero(time % 1000, 3);
    const hours = integerWithLeadingZero(timeInSec / 3600);
    const mins = integerWithLeadingZero((timeInSec % 3600) / 60);
    const sec = integerWithLeadingZero(timeInSec % 60);
    return `${(hours)}:${mins}:${sec}.${msec}`;
};

export const getErrorLog = state => state.firebase.data.error_log && state.firebase.data.error_log.list;

export const getErrorLogAsArray = createSelector(
    getErrorLog,
    errors => {
        return Object.keys(errors || {}).map((key) => {
            const error = { ...errors[key], key };
            error.ts = formatTimestamp(error.ts);
            error.run = formatRuntime(error.run);
            return error;
        });
    }
);
