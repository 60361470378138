import React from "react";
import PropTypes from "prop-types";
import { Icon, Label } from "semantic-ui-react";
import {
    CellMeasurer,
    CellMeasurerCache
} from "react-virtualized";
import AutoSizedList from "./components/AutoSizedList";
import { getTimeAgo } from "../../utils/dateUtils";
import cx from "classnames";
import {
    convertToLinkOrFileURL
} from "./ResourcesUtils";
import { mapIconColor } from "../component/ResourceLink/ResourceLink";
import "./ResourcesList.scss";

const noPropagation = (e) => e.stopPropagation();

const ResourceType = ({ name, icon, href, is_external }) => {
    const iconName = icon || "file code outline";
    const color = mapIconColor(iconName);
    if (href) {
        const attrs = is_external ? {
            title: "Click to open link",
            target: "_blank",
            rel: "external noopener noreferrer"
        } : {
            title: "Click to download file",
            target: "_blank",
            download: true,
        };
        return (
            <a className="link" href={convertToLinkOrFileURL(href)} {...attrs} onClick={noPropagation}>
                <Icon name={iconName} {...color && { color }} title={attrs.name} size="large" />
            </a>
        );
    }
    return <Icon name={iconName} title={name} size="large" />;
};
ResourceType.propTypes = {
    // id: PropTypes.number,
    // code: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
};

const ResourceInfo = ({ name, resourceType, created_at, timeAgo }) => {
    var options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
    const createdAt = created_at && new Date(created_at).toLocaleString("en-US", options);
    const createdAgo = createdAt && timeAgo && timeAgo.format(new Date(created_at));
    return (
        <div className="content">
            <div className="header" title={name}>{name}</div>
            <div className="description">
                <span>{resourceType && resourceType.name || ""}</span>
                {createdAgo && <span className="created">created <span title={createdAt}>{createdAgo}</span></span>}
                {createdAt && !createdAgo && <span className="created">created {createdAt}</span>}
            </div>
        </div>
    );
};
ResourceInfo.propTypes = {
    name: PropTypes.string,
    created_at: PropTypes.string,
    resourceType: PropTypes.object,
    timeAgo: PropTypes.object,
};

const ResourceTags = ({ tags }) => (
    <div className="tags">
        {tags.map((tag) => <Label key={tag.id} size="small">{tag.name}</Label>)}
    </div>
);
ResourceTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
};

export default class ResourcesList extends React.Component {
    static propTypes = {
        resourceList: PropTypes.array,
        selectedItem: PropTypes.number,
        onSelectItem: PropTypes.func,
        simple: PropTypes.bool,
    };

    state = { 
        resourceList: null, 
        timeAgo: undefined,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.resourceList !== nextProps.resourceList) {
            // if data has changed then recalculate timeAgo
            return {
                resourceList: nextProps.resourceList,
                timeAgo: getTimeAgo(),
            };
        }
        return null;
    }

    measurerCache = new CellMeasurerCache({
        defaultHeight: 50,
        fixedWidth: true,
    });

    handleOnEntryClick = ({ currentTarget }) => {
        const { onSelectItem } = this.props;
        onSelectItem && onSelectItem(currentTarget.id);
    }

    rowRenderer = ({
        index,  // Index of row
        key,    // Unique key within array of rendered rows
        parent, // Reference to the parent List (instance)
        style,  // Style object to be applied to row (to position it);
    }) => {
        const entry = this.props.resourceList[index];
        const active = entry.id === this.props.selectedItem;
        return (
            <CellMeasurer cache={this.measurerCache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                {({ measure }) => (
                    <div
                        style={style}
                        key={key}
                        onLoad={measure}
                        className={cx("resource item", {"active": active})}
                        id={entry.id}
                        onClick={this.handleOnEntryClick}
                    >
                        <ResourceType {...entry.resourceType} href={entry.href} />
                        <ResourceInfo {...entry} timeAgo={this.state.timeAgo} />
                        {/* <ResourceTags tags={entry.tags} /> */}
                    </div>
                )}
            </CellMeasurer>
        );
    };

    findIndexInOrderedList = () => {
        const { resourceList, selectedItem } = this.props;
        const selectedIndex = selectedItem ? resourceList.findIndex(r => r.id === selectedItem) : -1;
        return selectedIndex >= 0 ? selectedIndex : null;
    };

    render() {
        const { resourceList, selectedItem } = this.props;
        const scrollToIndex = this.findIndexInOrderedList();
        if (scrollToIndex !== null) {
            this.measurerCache.clearAll();
        }
        return (
            <AutoSizedList
                rowCount={resourceList.length}
                rowRenderer={this.rowRenderer}
                rowHeight={this.measurerCache.rowHeight}
                measurement={this.measurerCache}
                {...selectedItem && { scrollToIndex }}
            />
        );
    }
}
