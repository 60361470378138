import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onLessonAddTopic, onLessonRemoveTopic } from "../outlinesActions";
import EditLessonTopics from "./EditLessonTopics";
import { getSelectedOutlineId, getOutlinesData } from "../outlinesSelectors";

const dispatchToProps = dispatch => {
    return bindActionCreators({ onLessonAddTopic, onLessonRemoveTopic }, dispatch);
};

export default connect(
    state => ({
        outlineId: getSelectedOutlineId(state),
        outlinesData: getOutlinesData(state),
    }),
    dispatchToProps
)(EditLessonTopics);
