import firebase from "./firebase";
import { authUser } from "./auth/authSelectors";

const origConsoleError = (window && window.console && window.console.error) || function() {};
let globalErrorHandler = function() {};

const errorBuffer = [];
let isProcessing = false;
let lastError = {};
let lastErrorDbKey;
let repeatedCount = 1;

const STARTED_AT = Date.now();
const IGNORED_PROPERTIES = ["ts"];

const isDifferent = (prev, next) => Object.keys(next).find((key) => !IGNORED_PROPERTIES.includes(key) && prev[key] !== next[key]);

/**
 * Removes all URLs from call stack which are identical with `src` property.
 */
const minifyCallStack = (loggedError) => {
    const { src, stack } = loggedError;

    if (src && stack) {
        loggedError.stack = stack.split(src).join("");
    }

    return loggedError;
};

/**
 * Writes all buffered errors into firebase collections (list & data).
 */
const logError = async () => {

    if (isProcessing) {
        return;
    }

    const dataRef = firebase.getFirebaseData("error_log");
    isProcessing = true;  // Block re-entering this async process till the current buffer with errors is not drained out.

    while (errorBuffer.length) {
        let loggedError = errorBuffer.shift();
        const { ts, uid, msg, ...data } = loggedError;

        try {
            if (isDifferent(lastError, loggedError)) {
                const { key } = await dataRef.child("list").push({ ts, msg, uid, run: Date.now() - STARTED_AT, unl: window.unloadFired });
                lastErrorDbKey = key;
                lastError = loggedError;
                repeatedCount = 1;
                await dataRef.child("data").update({ [key]: minifyCallStack(data) });
            }
            else {
                repeatedCount++;
                await dataRef.child("list").child(lastErrorDbKey).update({ cnt: repeatedCount });
            }
        }
        catch (err) {
            origConsoleError("Error reporting failed:", err);
        }
    }

    isProcessing = false;
};

const createFakeErrorEvent = (msg, src, error) => {
    return {
        message: msg,
        filename: src,
        error,
    };
};

/**
 * Serialize arguments. If a particular argument is an instance of either ErrorEvent of Error object,
 * it is reported immediately and omitted from the serialized list.
 *
 * @param {Array-like} args
 */
const getSerializeArgumentsOrReportIt = (args) => {
    const textArgs = [];

    [ ...args ].forEach((a) => {
        try {
            switch (typeof a) {
                case "object":
                    if (a instanceof ErrorEvent) {
                        globalErrorHandler(a);
                    }
                    else if (a instanceof Error) {
                        // Note: JSON.stringify(new Error('Some error')) -> "{}"
                        const { name, message, stack } = a;
                        globalErrorHandler( createFakeErrorEvent(`${name}: ${message}`, "Console", { stack } ));
                    }
                    else {
                        textArgs.push(JSON.stringify(a));
                    }
                    break;
                case "array": textArgs.push(JSON.stringify(a)); break;
                case "undefined": textArgs.push("undefined"); break;
                default: textArgs.push(a);
            }
        }
        catch (err) {
            origConsoleError.apply(null, ["Overridden console.error failed.", a]);
        }
    });

    return textArgs;
};

/**
 * This overriding redirects all console.error (we use it intentionally is some parts of code)
 * to general OnError handler and then propagates it to the standard console.
 *
 * It catches React warnings too (e.g. propTypes checking), because they are reported
 * via console.error (suppressed in production).
 */
const overrideConsoleError = () => {
    window.console.error = function() {
        const args = getSerializeArgumentsOrReportIt(arguments);
        if (args.length) {
            // The first argument can contain more lines.
            // Here all stringified arguments are joined as lines. The first line is considered as an error message,
            // the rest will be stored as a call stack.
            const text = args.join("\n");
            const lines = text.split("\n");
            const msg = lines.shift();
            globalErrorHandler( createFakeErrorEvent(msg, "console", { stack: lines.join("\n") }) );
            origConsoleError.apply(null, arguments);
        }
    };
};

export const initGlobalErrorHandler = (reduxStore) => {
    window.unloadFired = null;
    window.addEventListener("beforeunload", () => window.unloadFired = true);
    overrideConsoleError();

    globalErrorHandler = (errorEvent) => {
        try {
            const { message: msg, filename: src, lineno: row = null, colno: col = null, error } = errorEvent;
            const user = authUser(reduxStore.getState());
            const uid = user.uid || "?";
            const stack = (error && error.stack) || null;
            const url = window.location.href;
            const ua = navigator.userAgent;
            const loggedError = { ts: Date.now(), uid, msg, src, row, col, stack, url, ua };
            errorBuffer.push(loggedError);
            logError();
        }
        catch (err) {
            origConsoleError("Global ErrorHandler failed:", err);
        }
        return false;  // When the function returns true, this prevents the firing of the default event handler.
    };

    window.addEventListener("error", globalErrorHandler);
};

export const reportErrorBoundary = (error, info) => {
    try {
        const { name, message, stack } = error;
        const mergedStack = stack + "\n--- Component stack ---\n" + info.componentStack;
        globalErrorHandler( createFakeErrorEvent(`${name}: ${message}`, "React", { stack: mergedStack }) );
    }
    catch (err) {
        origConsoleError.apply(null, ["reportErrorBoundary failed.", err]);
    }
};

export const reportToastError = (message) => {
    try {
        const error = new Error(message);  // to get stack
        globalErrorHandler( createFakeErrorEvent(message, "toastError", error) );
    }
    catch (err) {
        origConsoleError.apply(null, ["reportToastError failed.", err]);
    }
};
