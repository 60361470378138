import { buildGetGlossaryDefinition } from "./component/seamlessEditor/bookEditor/glossaryEntity/glossaryUtils";
import * as events from "./AppEvents";

const initializer = (reduxStore) => {
    return {
        // App functions that should be provided by the tablet application
        // and registered into namespace as "sporkApp.*".
        sporkApp: {
            // Allows to get up-to-data glossary definition from database.
            getGlossaryDefinition: buildGetGlossaryDefinition(reduxStore),
        },
        // Event handlers that are written to authored/published content.
        // and registered into namespace as "spork.*".
        spork: {
            events,
        }
    };
};

export default initializer;