import { connect } from "react-redux";
import AdvancedResourceSearch from "./AdvancedResourceSearch";
import {
    isSearching,
    isOpen,
    getTagsOptions,
    isLoadingTags,
    getResult,
    isLoadingCourseVariants,
    getCourseVariantsOptions,
    isLoadingResourceTypes,
    getResourceTypesOptions,
    isLoadingOwners,
    getOwnersOptions,
} from "./AdvancedResourceSearchSelectors";
import { bindActionCreators, compose } from "redux";
import {
    onAdvancedSearch,
    onOpen,
    onClose,
    onLoadUsedTags,
    onFetchUsedCourseVariants,
    onLoadResourceTypes,
    onFetchOwners,
} from "./AdvancedResourceSearchActions";
import { withFirebase } from "react-redux-firebase";
import { getTopicCollectionData } from "../../../TopicCollection/topicCollectionSelectors";

const mapStateToProps = state => ({
    isSearching: isSearching(state),
    isOpen: isOpen(state),
    isLoadingTags: isLoadingTags(state),
    tagsOptions: getTagsOptions(state),
    result: getResult(state),
    isLoadingCourseVariants: isLoadingCourseVariants(state),
    courseVariantOptions: getCourseVariantsOptions(state),
    isLoadingResourceTypes: isLoadingResourceTypes(state),
    resourceTypesOptions: getResourceTypesOptions(state),
    ownersOptions: getOwnersOptions(state),
    isLoadingOwners: isLoadingOwners(state),
    topicsData: getTopicCollectionData(state),
});

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onAdvancedSearch,
            onClose,
            onOpen,
            onLoadUsedTags,
            onFetchUsedCourseVariants,
            onLoadResourceTypes,
            onFetchOwners,
        },
        dispatch
    );
};

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(AdvancedResourceSearch);
