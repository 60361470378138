import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";

import BookEditor from "../BookEditor";
import { swallowEvent } from "../utils/utils";
import {
    getSnippetBlockClassName,
    getSnippetBlockDataAsProps
} from "../../../../KitBuilder/Snippets/SnippetUtils";
import SnippetCustomizer from "./SnippetCustomizer";

const getTitle = (data) => {
    const blockData = data.snippetCfg && data.snippetCfg.blockData;
    return [ blockData && blockData.title, data.snippetCfg && data.snippetCfg.hiddenTitle || false ];
};

class SnippetDecoratorEditor extends Component {
    static propTypes = {
        contentState: PropTypes.object.isRequired,
        entityKey: PropTypes.string.isRequired,
        getEditorState: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        sharedToolbar: PropTypes.object.isRequired,
        registerNestedEditor: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        onContentBoundary: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
        rootEntityKey: PropTypes.string,
    };

    state = { isDirty: false };

    handleSetEditorRef = (bookEditorRef) => {
        this.bookEditorRef = bookEditorRef;
        this.registerNestedEditor(bookEditorRef);
    };

    registerNestedEditor = (bookEditorRef) => {
        const entityEditor = {
            entityKey: this.props.entityKey,
            editorKey: bookEditorRef.editorKey,
            bookEditorRef,
            updateEditorState: this.updateEditorState,
        };
        this.parentUnregister = this.props.registerNestedEditor(entityEditor);
    };

    componentWillUnmount = () => {
        this.parentUnregister && this.parentUnregister();
    };

    registerGetContentFn = (fn) => {
        this.getEditorContent = fn;
    };

    handleMouseDown = (e) => {
        swallowEvent(e);

        if (e.detail === 1) {
            this.bookEditorRef.setFocus(e);
        }
    };

    handleTitleChange = ({ title, hiddenTitle }) => {
        const { contentState, entityKey } = this.props;
        const entity = contentState.getEntity(entityKey);
        const data = entity.getData();
        const [ initialTitle, initialHiddenTitle ] = getTitle(data);

        if (initialTitle === title && initialHiddenTitle === hiddenTitle) {
            return;
        }

        const snippetCfg = {
            ...data.snippetCfg,
            blockData: typeof title === "string" ? { title } : null,
            hiddenTitle
        };
        this.updateEntityData({ snippetCfg });
        this.forceUpdate();
    };

    handleDirty = () => {
        this.setState({ isDirty: true });
        this.props.onDirty();
    };

    updateEditorState = () => {
        if (this.state.isDirty) {
            const { raw, html } = this.getEditorContent();
            this.updateEntityData({ contentState: raw, html });  // merge keeps `snippetKey`
            this.setState({ isDirty: false });
        }
    };

    updateEntityData = (entityData) => {
        const { getEditorState, setEditorState, entityKey, onDirty } = this.props;

        const editorState = getEditorState();
        const contentState = editorState.getCurrentContent();
        const newContentState = contentState.mergeEntityData(entityKey, entityData);
        const newEditorState = EditorState.push(editorState, newContentState, "apply-entity");
        setEditorState(newEditorState);
        onDirty();
    };

    render() {
        const { contentState, entityKey, sharedToolbar, reviewMode, onContentBoundary, rootEntityKey } = this.props;
        const entity = contentState.getEntity(entityKey);
        const data = entity.getData();
        const className = `sporkSegment ${getSnippetBlockClassName(data.snippetKey)}`;
        const dataProps = getSnippetBlockDataAsProps(data.snippetCfg);
        const canConfig = !reviewMode && data.snippetCfg && data.snippetCfg.hasTitle;

        return (
            <div className={className} {...dataProps} contentEditable={false} onMouseDown={this.handleMouseDown}>
                {canConfig ? (
                    <SnippetCustomizer {...data.snippetCfg} onChange={this.handleTitleChange}  />
                ) : null}
                <BookEditor
                    // onMouseDown={swallowEvent}
                    features={[
                        "BOLD", "ITALIC", "NORMAL", "H1", "H2", "H3", "UL", "OL", "MATH", "MATHTEXT", "SNIPPET",
                        "TABLE", "TEXTALIGN", "FONT", "FONTSIZE", "IMG", "GLOSSARY", "COLOR", "LESSON_LINK", "REVIEW", "HELP",
                    ]}
                    initialContentState={data.contentState}
                    initialHtml={data.html}
                    registerGetContentFn={this.registerGetContentFn}
                    onSetEditorRef={this.handleSetEditorRef}
                    onContentBoundary={onContentBoundary}
                    onDirty={this.handleDirty}
                    sharedToolbar={sharedToolbar}
                    reviewMode={reviewMode}
                    focusOnMount={false}
                    rootEntityKey={rootEntityKey}
                />
            </div>
        );
    }
}

export default SnippetDecoratorEditor;
