import { connect } from "react-redux";
import YearsList from "./YearsList";
import { getYearList, isLoading } from "./YearsSelectors";
import { onCreateYear, onUpdateYear, onFilterValid } from "./YearsActions";
import services from "../feathersjs/services";
import { compose, bindActionCreators } from "redux";
import { feathersjsConnect } from "../feathersjs/feathersjsConnect";

const mapStateToProps = function(state) {
    return {
        years: getYearList(state),
        isLoading: isLoading(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onLoadYears: services.year.find,
            onFilterValid,
            onCreateYear,
            onUpdateYear,
            onDeactivateYear: services.year.patch,
        },
        dispatch
    );
};

export default compose(connect(mapStateToProps, dispatchToProps), feathersjsConnect("year"))(YearsList);
