import { TYPES } from "./objectiveSetActions";
// import { getObjectiveSetById, getSelectedObjectiveSet } from "./objectiveSetSelector";

// The initial state of the ExampleSimple
const initialState = {
    selectedObjectiveSet: null, // mozna je to tu zbytecne, pouziva se ted jen ID nize a vybrany OS se mapuje do props v kontejneru ze selectoru
    selectedObjectiveSetId: null,
    error: undefined,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.OSM_SELECT_OBJECTIVESET:
            return { ...state, selectedObjectiveSetId: action.value };
        case TYPES.OSM_ON_ERROR:
            return { ...state, error: action.value };
        case TYPES.OSM_CLEAR_ERROR:
            return { ...state, error: undefined };

        default:
            // console.log("Reducer(state):", state); // eslint-disable-line no-console
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;