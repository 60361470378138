import { connect } from "react-redux";
import { compose } from "redux";
import UserProfile from "./UserProfile";
import { firebaseConnect } from "react-redux-firebase";
import { authUser } from "../../auth/authSelectors";

const mapStateToProps = state => ({
    authUser: authUser(state),
});

const dispatchToProps = {};

export default compose(
    //FIXME - does this have to be HERE ?
    firebaseConnect(["course_variants_data"]),
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(UserProfile);
