import { createSelector } from "reselect";
import { fetchKitsData } from "../AKitsList/AKitsListSelectors";
import { getCourseVariantsData } from "../../../External/CourseVariantList/CourseVariantsDataSelectors";
import { getUsers, getUsersAsArray } from "../../../Users/UserList/UserListSelectors";
import { compareByNameCI, getCvGuid } from "../../../utils/selectorUtils";

export const isOpen = state => state.kitBuilder.editAkit && state.kitBuilder.editAkit.open;
export const isSaving = state => state.kitBuilder.editAkit && state.kitBuilder.editAkit.saving;
export const kitId = state => state.kitBuilder.editAkit && state.kitBuilder.editAkit.kitId;
export const isDirty = state => state.kitBuilder.editAkit && state.kitBuilder.editAkit.isDirty;

const variants = state => state.kitBuilder.editAkit && state.kitBuilder.editAkit.variants;
export const selectedKit = createSelector(
    fetchKitsData,
    kitId,
    (kitData, kitId) => (kitData && kitId && kitData[kitId] && { ...kitData[kitId], id: kitId }) || null
);

/**
 * Take a list of courses and return it as object grouped by Year
 */
const groupVariantsByYear = variants => {
    const grouped = {};
    variants.map(variant => {
        let year = variant.year;
        if (grouped[year]) {
            grouped[year].push(variant);
        } else {
            grouped[year] = [variant];
        }
    });
    return grouped;
};

const groupVariantsByTenantAndYear = variants => {
    return variants.reduce((acc, variant) => {
        const tenant = (variant.tenant && variant.tenant.name) || "unknown";  // "unknown" should never occur
        const year = variant.year;

        if (!acc[tenant]) {
            acc[tenant] = {};
        }

        if (!acc[tenant][year]) {
            acc[tenant][year] = [];
        }

        acc[tenant][year].push(variant);
        return acc;
    }, {});
};

export const assignedVariants = createSelector(
    variants,
    selectedKit,
    (courseVariants, kit) => {
        return groupVariantsByYear(
            courseVariants.filter(variant => kit && kit.course_variants && kit.course_variants[getCvGuid(variant)])
        );
    }
);

export const availableVariants = createSelector(
    variants,
    getCourseVariantsData,
    (courseVariants, cvData) => {
        return groupVariantsByTenantAndYear(
            courseVariants.filter(variant => !cvData || !cvData[getCvGuid(variant)] || !cvData[getCvGuid(variant)].isUsedInKit)
        );
    }
);

export const getAuthorsCandidates = createSelector(
    selectedKit,
    getUsersAsArray,
    (kit, users) => {
        if (users && kit) {
            const authors = kit.authors || {};
            const usersOptions = [];
            users.map(u => {
                if (!authors[u.uid] || !u.roles || !u.roles.ROLE_AUTHOR) {
                    usersOptions.push({
                        key: u.uid,
                        value: u.uid,
                        text: `${u.first_name} ${u.last_name}`,
                        description: u.email,
                    });
                }
            });
            return usersOptions;
        }
    }
);

export const getReviewersCandidates = createSelector(
    selectedKit,
    getUsersAsArray,
    (kit, users) => {
        if (users && kit) {
            const reviewers = kit.reviewers || {};
            const usersOptions = [];
            users.map(u => {
                if (!reviewers[u.uid] || !u.roles || !u.roles.ROLE_REVIEWER) {
                    usersOptions.push({
                        key: u.uid,
                        value: u.uid,
                        text: `${u.first_name} ${u.last_name}`,
                        description: u.email,
                    });
                }
            });
            return usersOptions;
        }
    }
);

export const getAuthors = createSelector(
    getUsers,
    selectedKit,
    (users, kit) => {
        if (kit && kit.authors) {
            const userObjList = [];
            Object.keys(kit.authors).map(uid => {
                if (users && users[uid] && users[uid].roles && users[uid].roles["ROLE_AUTHOR"]) {
                    userObjList.push({ id: uid, name: `${users[uid].first_name} ${users[uid].last_name}` });
                }
            });
            return userObjList.sort(compareByNameCI);
        }
    }
);

export const getReviewers = createSelector(
    getUsers,
    selectedKit,
    (users, kit) => {
        if (kit && kit.reviewers) {
            const userObjList = [];
            Object.keys(kit.reviewers).map(uid => {
                if (users && users[uid] && users[uid].roles && users[uid].roles["ROLE_REVIEWER"]) {
                    userObjList.push({ id: uid, name: `${users[uid].first_name} ${users[uid].last_name}` });
                }
            });
            return userObjList.sort(compareByNameCI);
        }
    }
);


export const getCommentRecipients = createSelector(
    getUsers,
    selectedKit,
    (users, kit) => {
        if (kit && kit.comment_recipients) {
            const userObjList = [];
            Object.keys(kit.comment_recipients).map(uid => {
                if (users && users[uid]) {
                    userObjList.push({ id: uid, name: `${users[uid].first_name} ${users[uid].last_name}` });
                }
            });
            return userObjList.sort(compareByNameCI);
        }
    }
);

export const getCommentRecipientCandidates = createSelector(
    selectedKit,
    getUsersAsArray,
    (kit, users) => {
        if (users && kit) {
            const comment_recipients = kit.comment_recipients || {};
            const usersOptions = [];
            users.map(u => {
                if (!comment_recipients[u.uid]) {
                    usersOptions.push({
                        key: u.uid,
                        value: u.uid,
                        text: `${u.first_name} ${u.last_name}`,
                        description: u.email,
                    });
                }
            });
            return usersOptions;
        }
    }
);
