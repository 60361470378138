import React from "react";
import PropTypes from "prop-types";
import { Icon, Form, Popup } from "semantic-ui-react";

import BlockTitleConfig from "../WidgetTemplates/TemplateEditor/BlockTitleConfig";
import PopupCloseIcon from "../../component/PopupCloseIcon";
import BlockStyleConfig from "../WidgetTemplates/TemplateEditor/BlockStyleConfig";
import HideableOptions, { validateLabelsOf } from "./HideableOptions";


export default class SnippetEditor extends React.PureComponent {
    static propTypes = {
        snippet: PropTypes.object,
        onSave: PropTypes.func,
    };

    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        const errors = validateLabelsOf(this.props.snippet);
        if (!errors) {
            this.setState({ open: false });
        }
    }

    handleChangeHeader = (header) => {
        const { snippet, onSave } = this.props;
        const data = { ...snippet, key: null, header: header };
        onSave(data);
    }

    handleToggle = (e, { name, checked }) => {
        const { snippet, onSave } = this.props;
        const data = { ...snippet, key: null, [name]: checked };
        onSave(data);
    }

    handleSelectColor = ({backgroundColor}) => {
        const { snippet, onSave } = this.props;
        const data = { ...snippet, key: null, color: backgroundColor };
        onSave(data);
    }

    handlePropertyChanged = (name, value) => {
        const { snippet, onSave } = this.props;
        const data = { ...snippet, key: null, [name]: value };
        onSave(data);
    }

    render() {
        const { open } = this.state;
        const { snippet } = this.props;

        return (
            <Popup
                on="click"
                open={open}
                trigger={
                    <Icon
                        link
                        name="setting"
                        className="actionIconClass right"
                        title="Edit block settings"
                        onClick={open ? this.handleClose : this.handleClose}
                    />
                }
                onOpen={this.handleOpen}
                position="bottom right"
                basic
                wide="very"
                style={{ width: "550px" }}
            >
                <Popup.Header>Configure block: {snippet.name}<PopupCloseIcon onClose={this.handleClose} /></Popup.Header>
                <Popup.Content>
                    <Form autoComplete="off">
                        <BlockTitleConfig builtIn config={snippet.header} onChange={this.handleChangeHeader}/>
                    </Form>
                    <BlockStyleConfig
                        config={snippet}
                        onChange={this.handlePropertyChanged}
                    />
                    {snippet.hideable || !snippet.isUsed ? (
                        <HideableOptions
                            config={snippet}
                            onChange={this.handlePropertyChanged}/>
                    ) : null}
                </Popup.Content>

            </Popup>
        );
    }
}

