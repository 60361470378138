import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onConvertProblemDataStart } from "./UpdateProblemTagsActions";
import { inProgress, getMessage } from "./UpdateProblemTagsSelectors";
import UpdateProblemTags from "./UpdateProblemTags";

const mapStateToProps = function(state) {
    return {
        inProgress: inProgress(state),
        message: getMessage(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onConvertProblemDataStart }, dispatch);
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(UpdateProblemTags);
