import { all, fork } from "redux-saga/effects";

import conceptsSaga from "./Concepts/conceptsSaga";

function* rootSaga() {
    yield all([
        fork(conceptsSaga),
    ]);
}

export default rootSaga;
