import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";
import { roles }  from "../../auth";

export default class UserProfileDebug extends Component {
    static propTypes = {
        authUser: PropTypes.object,
        onChangeRoleGodMode: PropTypes.func.isRequired,
    };

    handleRoleSelect = (e, { id }) => {
        const { authUser } = this.props;
        this.props.onChangeRoleGodMode({
            roles: { [id]: authUser.roles.includes(id) ? false : true },
        });
    };

    render() {
        const { authUser } = this.props;
        return (
            <React.Fragment>
                <Dropdown.Header style={{ color: "red" }}>God mode</Dropdown.Header>
                <Dropdown item text="Set roles" className="debug">
                    <Dropdown.Menu className="debug">
                        <Dropdown.Header>Currently active roles:</Dropdown.Header>
                        {Object.keys(roles).map(role => (
                            <Dropdown.Item
                                key={role}
                                id={role}
                                icon={authUser.roles.includes(role) ? "check square outline" : "square outline"}
                                text={roles[role].name}
                                onClick={this.handleRoleSelect}
                            />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown.Divider />
            </React.Fragment>
        );
    }
}
