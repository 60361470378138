import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose, bindActionCreators } from "redux";
import * as SyllabiListActions from "./SyllabiListActions";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { searchFilter, getSyllabi, syllabi, populates, teacherFilter, schoolFilter } from "./SyllabiListSelectors";
import SyllabiList from "./SyllabiList";
import { getSelectedYear, getSelectedYearId } from "../../Year/YearsSelectors";
import { onSchoolLoad } from "../../External/SchoolList/schoolActions";
import { onAllCourseVariantLoad } from "../../External/CourseVariantList/CourseVariantsActions";
import { getSchoolsById, isLoading as schoolIsLoading } from "../../External/SchoolList/schoolSelectors";
import { getCourseVariantsById, isLoading } from "../../External/CourseVariantList/courseVariantsSelectors";


const mapStateToProps = function(state, props) {
    return {
        searchFilter: searchFilter(state),
        syllabi: getSyllabi(state, props),
        _syllabi: syllabi(state),
        selectedYear: getSelectedYear(state),
        schools: getSchoolsById(state),
        courseVariants: getCourseVariantsById(state),
        selectedYearId: getSelectedYearId(state),
        cvIsLoading: isLoading(state),
        schoolIsLoading: schoolIsLoading(state),
        teacherFilter: teacherFilter(state),
        schoolFilter: schoolFilter(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { ...SyllabiListActions, onSchoolLoad, onAllCourseVariantLoad },
        dispatch
    );
};

export default compose(
    firebaseConnect([{ path: "/syllabi/info", populates }]),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["_syllabi"]),
)(SyllabiList);
