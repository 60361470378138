const TYPES = {
    RESOURCE_TAG_EDITOR_SAVE: "RESOURCE_TAG_EDITOR_SAVE",
    RESOURCE_TAG_EDITOR_SAVED: "RESOURCE_TAG_EDITOR_SAVED",
};

const onSaveTags = (payload) => ({
    type: TYPES.RESOURCE_TAG_EDITOR_SAVE,
    payload,
});

const onSaved = (payload) => ({
    type: TYPES.RESOURCE_TAG_EDITOR_SAVED,
    payload,
});

export {
    TYPES,
    onSaveTags,
    onSaved,
};
