import React from "react";
import PropTypes from "prop-types";

import LessonExportLinks from "./LessonExportLinks";
import { Segment, Table, Dimmer, Loader } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";

export default class LessonExports extends React.PureComponent {
    static propTypes = {
        release: PropTypes.object,
        releaseContent: PropTypes.object,
        loading: PropTypes.bool,
        onExportToPdf: PropTypes.func,
        isExportInProgress: PropTypes.bool,
        outlineName: PropTypes.string,
        lessonName: PropTypes.string,
    };

    render() {
        const { release, releaseContent, loading, onExportToPdf, isExportInProgress, outlineName, lessonName } = this.props;
        return (
            <Segment loading={loading} placeholder={loading}>
                <Dimmer.Dimmable dimmed={isExportInProgress}>
                    <Dimmer active={isExportInProgress} inverted>
                        <Loader>Exporting...</Loader>
                    </Dimmer>
                    <Table padded >
                        {release && releaseContent && ((releaseContent.content || releaseContent.hw || releaseContent.ps) ? (
                            <LessonExportLinks
                                name="PDF exports for the currently published lesson"
                                info={release}
                                data={releaseContent}
                                icon="print"
                                onExportToPdf={onExportToPdf}
                                outlineName={outlineName}
                                lessonName={lessonName}
                            />
                        ): (
                            <NoDataPlaceholder text="PDF export is not available" icon="file pdf outline">
                                <p>No content has been published for this lesson.</p>
                            </NoDataPlaceholder>
                        ))}
                    </Table>
                </Dimmer.Dimmable>
            </Segment>
        );
    }
}
