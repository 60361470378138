import { all, takeEvery, put, select } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onGeneratesDataFinished, onGeneratesDataProgress } from "./GeneratesDataForiPlannerActions";
import { toastError, DB_ERROR } from "../../component/toast";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getSelectedYearId } from "../../Year/YearsSelectors";

function* generatesData({ payload: { kitId, variantId, schoolId, userId }}) {
    let progress = { outline: true, template: false, syllabus: false, syllabusProblem: false };
    const tenant_id = yield select(tenantId);
    const year_id = yield select(getSelectedYearId);
    const userUuid = userId.replace("u", "").replace(/@.*/, "");
    const userTenant = userId.replace(/.*@t/, "");
    try {
        const userResponse = yield firebase.tabletProxy({
            method: "GET",
            uri: `/user?uuid=${userUuid}&tenant_id=${userTenant}`,
            cancelRestrictToTenant: true,
        });
        const user = userResponse.data[0];
        const response = yield firebase.tabletProxy({
            method: "GET",
            uri: "/author-kit-outline/" + kitId,
        });
        const cvLus = response.data.cvLUs;
        const sipiData = {
            tenant_id,
            year_id,
            cv_ccas: [ { user_id: user.id } ],
            uuid: `${kitId}@${variantId}`,
            ["course_variant_id"]: variantId,
            ["cv_lus"]: []
        };
        const schoolCourseResponse = yield firebase.tabletProxy({
            method: "GET",
            uri: `/school-course?course_variant_id=${variantId}&school_id=${schoolId}`,
        });
        let syllabusData = null;
        if (schoolCourseResponse.data.length) {
            const course = schoolCourseResponse.data[0];
            syllabusData = {
                tenant_id,
                year_id,
                teacher_id: user.id,
                uuid: `${kitId}@${variantId}@${schoolId}`,
                school_id: schoolId,
                school_course_id: course.id,
                syllabus_name: course.name,
                reported_lus: []
            };
        }
        cvLus.map((lu, i) => {
            sipiData.cv_lus.push({
                cv_lu_id: i + 1,
                spork_cv_lu_id: lu.id,
                length: lu.length,
                start_week: lu.startWeek,
                name: lu.name,
                lu_objectives: lu.luObjectives || null
            });
            if (syllabusData) {
                syllabusData.reported_lus.push({
                    lu_id: i + 1,
                    spork_cv_lu_id: lu.id,
                    length: lu.length,
                    start_week: lu.startWeek,
                    name: lu.name,
                    lu_objectives: lu.luObjectives || null
                });
            }
        });
        yield put(onGeneratesDataProgress(progress));
        yield firebase.tabletProxy({
            method: "POST",
            uri: "/sipi-template",
            body: sipiData
        });
        progress.template = true;
        yield put(onGeneratesDataProgress(progress));
        if (syllabusData) {
            yield firebase.tabletProxy({
                method: "POST",
                uri: "/syllabus",
                body: syllabusData
            });
            progress.syllabus = true;
            yield put(onGeneratesDataProgress(progress));
        } else {
            progress.syllabusProblem = true;
            yield put(onGeneratesDataProgress(progress));
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to generate data for iPlanner", message: DB_ERROR });
    }
    yield put(onGeneratesDataFinished());
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.GENERATES_IPLANNER_DATA, generatesData),
    ]);

}