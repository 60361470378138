import React, { PureComponent } from "react";
import { Header, Icon, Dropdown, Message, DropdownMenu, DropdownItem } from "semantic-ui-react";
import PropTypes from "prop-types";
import EditableText from "../../../../component/EditableText";
import { ConfirmDeletePopup } from "bmd-react";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import ObjectiveType from "../../../components/ObjectiveType";
import "./AddItemDropdown.css";
import { objectiveTypes } from "../../../objectiveTypes";

const DragHandle = SortableHandle(({ disabled }) => (
    <Icon
        name="bars"
        disabled={disabled}
        style={{ cursor: disabled ? "not-allowed" : "ns-resize", paddingRight: "1.5em" }}
    />
));

const SortableItem = SortableElement(
    ({
        key,
        type,
        name,
        id,
        onUpdateObjectiveName,
        onEditObjectiveName,
        canEditObjectiveName,
        onConfirm,
        openEditorId
    }) => {
        return (
            <div key={key} className="editable sortableItem withPadding">
                <DragHandle disabled={!!openEditorId} />
                <div style={{ display: "inline-block" }}>
                    <EditableText
                        key={key}
                        text={name}
                        onUpdate={onUpdateObjectiveName}
                        noPadding
                        onEditation={onEditObjectiveName}
                        canEdit={canEditObjectiveName()}
                        openEditorId={openEditorId}
                        placeholder="Enter Objective name"
                        // errorMessage="Objective name cannot be empty"
                        id={id}
                    />
                </div>
                <ConfirmDeletePopup
                    key={id}
                    floated
                    onConfirm={onConfirm}
                    whatText={`objective "${name}"`}
                    trigger={<Icon name="trash alternate outline" link className="actionIconClass right" />}
                    id={id}
                />
                <ObjectiveType text={type} style={{ float: "right", marginRight: "5%" }} />
            </div>
        );
    }
);

const ObjectiveTypeHeader = ({ type }) => <Header style={{ borderBottom: "2px solid black" }}>{type}</Header>;
ObjectiveTypeHeader.propTypes = {
    type: PropTypes.string
};

const SortableList = SortableContainer(({ objectivesObject, openEditorId, ...others }) => {
    return (
        <div className="sortableContainer" style={{ marginTop: "1em" }}>
            {Object.keys(objectivesObject).map(objectiveType =>
                [<ObjectiveTypeHeader key={objectiveType} type={objectiveType} />].concat(
                    objectivesObject[objectiveType].map((value, index) => (
                        <SortableItem
                            {...others}
                            collection={objectiveType}
                            key={`item-${index}`}
                            index={index}
                            name={value.name}
                            type={value.type}
                            id={value.id}
                            disabled={!!openEditorId}
                            openEditorId={openEditorId}
                        />
                    ))
                )
            ) || <Message info>No objectives</Message>}
        </div>
    );
});

class AddObjectiveButton extends PureComponent {
    static propTypes = {
        onSelectType: PropTypes.func,
        disabled: PropTypes.bool
    };

    render() {
        const { onSelectType, disabled } = this.props;

        return (
            <Dropdown
                text="Add Objective"
                basic
                className="addItemDropdown"
                icon=""
                disabled={disabled}
                selectOnBlur={false}
                selectOnNavigation={false}
            >
                <DropdownMenu>
                    {objectiveTypes.map(type => (
                        <DropdownItem key={type.value} text={type.text} value={type.value} onClick={onSelectType} />
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default class ObjectiveSetView extends PureComponent {
    static propTypes = {
        selectedObjectiveSet: PropTypes.object,
        onUpdateObjectiveSetName: PropTypes.func,
        onUpdateObjectiveName: PropTypes.func,
        onAddObjective: PropTypes.func,
        onRemoveObjective: PropTypes.func,
        onChangeOrdering: PropTypes.func,
        getTextEditId: PropTypes.func,
        error: PropTypes.object,
        onObjectiveSetError: PropTypes.func,
        onClearObjectiveSetError: PropTypes.func
    };

    // Wrapper for handling onRemoveObjective and passing proper onSuccess/onFailure callbacks
    handleRemoveObjective = (callback, event, props) => {
        // clear error message at start if visible
        if (this.props.error) this.props.onClearObjectiveSetError();

        const { onRemoveObjective, onObjectiveSetError } = this.props;

        onRemoveObjective(
            props.id,
            props,
            // onSuccess
            // Caller can pass a message, in the future we could show/log the message. Now we just call the callback function with "" which closes the confirm dialog.
            // Update: call of the callback with "" has been repaclaced with function doing nothing. Removal of the component causes closing the dialog by rendering the component with unique key attribute.
            () => {
                return;
            },
            // onFailure
            callback,
            onObjectiveSetError
        );
    };

    handleAddObjective = (e, { value }) => {
        // clear error message at start if visible
        if (this.props.error) this.props.onClearObjectiveSetError();

        const { onAddObjective, selectedObjectiveSet, onObjectiveSetError } = this.props;
        const objectives = [...(selectedObjectiveSet.objectivesObject[value] || [])];

        const newObjective = {
            name: `New ${value} Objective nr. ${objectives.length + 1}`,
            type: value,
            ordering: objectives.length + 1
        };
        // pass this.props.setTextEditId as 3rd parameter to edit Objective name just after creation
        onAddObjective(selectedObjectiveSet.id, newObjective, null, onObjectiveSetError);
    };

    state = {
        editingText: ""
    };

    static getDerivedStateFromProps(nextProps) {
        // eslint-disable-line no-unused-vars
        const editorId = nextProps.getTextEditId();
        if (editorId) return { editingText: editorId };
        return null;
    }

    canEditText = () => !this.state.editingText;

    handleEditText = id => {
        const { editingText } = this.state;
        if (editingText) this.setState({ editingText: "" });
        else this.setState({ editingText: id || true });
    };

    handleSortEnd = ({ oldIndex, newIndex, collection }) => {
        // clear error message at start if visible
        if (this.props.error) this.props.onClearObjectiveSetError();

        const { onChangeOrdering, selectedObjectiveSet, onObjectiveSetError } = this.props;
        const reorderedObjectives = arrayMove(selectedObjectiveSet.objectivesObject[collection], oldIndex, newIndex);
        const updateData = [];

        let i,
            newOrdering = reorderedObjectives[newIndex].ordering;
        if (oldIndex < newIndex)
            for (i = oldIndex; i <= newIndex; i++) {
                reorderedObjectives[i].ordering = newOrdering++;
                updateData.push({ id: reorderedObjectives[i].id, ordering: reorderedObjectives[i].ordering });
            }
        else
            for (i = oldIndex; i >= newIndex; i--) {
                reorderedObjectives[i].ordering = newOrdering--;
                updateData.push({ id: reorderedObjectives[i].id, ordering: reorderedObjectives[i].ordering });
            }
        onChangeOrdering(selectedObjectiveSet.id, updateData, onObjectiveSetError);
    };

    handleUpdateObjectiveSetName = (value, event, props) => {
        // clear error message at start if visible
        if (this.props.error) this.props.onClearObjectiveSetError();
        this.props.onUpdateObjectiveSetName(value, props, this.props.onObjectiveSetError);
    };

    handleUpdateObjectiveName = (value, event, props) => {
        // clear error message at start if visible
        if (this.props.error) this.props.onClearObjectiveSetError();
        this.props.onUpdateObjectiveName(value, props, this.props.onObjectiveSetError);
    };

    render() {
        const { selectedObjectiveSet, ...others } = this.props;
        const { editingText } = this.state;
        const canEdit = !editingText;

        return (
            <div>
                <Header>
                    <EditableText
                        text={selectedObjectiveSet && selectedObjectiveSet.name}
                        onUpdate={this.handleUpdateObjectiveSetName}
                        onEditation={this.handleEditText}
                        canEdit={canEdit}
                        size="huge"
                        openEditorId={editingText}
                        placeholder="Enter Objective Set name"
                        noPadding
                        // errorMessage="Objective Set name cannot be empty"
                        id={selectedObjectiveSet && selectedObjectiveSet.id}
                    />
                </Header>
                <AddObjectiveButton disabled={!canEdit} onSelectType={this.handleAddObjective} />
                <SortableList
                    {...others}
                    className="sortableList"
                    helperClass="sortableHelper"
                    lockAxis="y"
                    objectivesObject={selectedObjectiveSet.objectivesObject}
                    onSortEnd={this.handleSortEnd}
                    useDragHandle={true}
                    onConfirm={this.handleRemoveObjective}
                    onUpdateObjectiveName={this.handleUpdateObjectiveName}
                    onEditObjectiveName={this.handleEditText}
                    openEditorId={editingText}
                    canEditObjectiveName={this.canEditText}
                />
            </div>
        );
    }
}
