import { all, takeLatest, put } from "redux-saga/effects";
import { TYPES, onSaved, onSetVariants } from "./EditKitActions";
import { aKitManager } from "../../dataSource";
import { getCourseVariantDetails } from "../CreateKit/CreateKitSelectors";

import firebase from "../../../firebase";
import { toastError, DB_ERROR } from "../../../component/toast";
import { client } from "../../../feathersjs/client";

const kitService = client.service("kit");

export function* aKitUpdate({ payload: { id, ...config } }) {
    try {
        const dbId = yield aKitManager.getKitDbId(id);
        if (config.tags) {
            if (dbId) {
                yield kitService.patch(dbId, { tags: config.tags });
            }
        } else {
            if (dbId) {
                if (config.name) {
                    yield kitService.patch(dbId, { name: config.name });
                } else if (config.reviewers) {
                    const reviewers = Object.keys(config.reviewers).filter(r => config.reviewers[r]);
                    yield kitService.patch(dbId, { reviewers });
                } else if (config.authors) {
                    const authors = Object.keys(config.authors).filter(a => config.authors[a]);
                    yield kitService.patch(dbId, { authors });
                }
            }
            yield aKitManager.updateKit(id, config);
        }
        yield put(onSaved());
    } catch (error) {
        toastError({ code: error.code, header: "Failed to update author kit", message: DB_ERROR });
    }
}

function* getVariants() {
    try {
        let yearsList = yield firebase.tabletProxy({
            method: "GET",
            uri: "/year",
            cancelRestrictToTenant: true,
        });
        const years = {};
        yearsList.data.map(y => years[y.id] = y );
        let courseVariants = yield firebase.tabletProxy({
            method: "GET",
            uri: "/course-variant?$sort[name]=1",
            cancelRestrictToTenant: true,
        });
        const enriched = courseVariants.data.map(cv => ({
            ...cv,
            description: getCourseVariantDetails(cv, true),
            tenantDesc: cv.tenant ? "[" + cv.tenant.name + "]" : "",
            year: years[cv.year_id].name,
        }));
        yield put(onSetVariants(enriched));
    } catch (err) {
        toastError({ code: err.code, header: "Error while loading courses.", message: err.message });
    }
}

function* assignVariant({ payload: { kitId, variants } }) {
    try {
        const id = yield aKitManager.getKitDbId(kitId);
        if (id) {
            yield kitService.patch(id, { new_course_variants: Object.keys(variants) });
        }
        yield aKitManager.assignVariant(kitId, variants);
    } catch (error) {
        toastError({ code: error.code, header: "Failed to assign Course to author kit", message: DB_ERROR });
    }
}

function* unassignVariant({ payload: { kitId, variants } }) {
    try {
        const id = yield aKitManager.getKitDbId(kitId);
        if (id) {
            yield kitService.patch(id, { removed_course_variants: Object.keys(variants) });
        }
        yield aKitManager.unassignVariant(kitId, variants);
    } catch (error) {
        toastError({
            code: error.code,
            header: "Failed to unassign Course from author kit",
            message: DB_ERROR,
        });
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.EDIT_AKIT_UPDATE, aKitUpdate),
        takeLatest(TYPES.EDIT_AKIT_EDIT, getVariants),
        takeLatest(TYPES.EDIT_AKIT_ASSIGN_VARIANT, assignVariant),
        takeLatest(TYPES.EDIT_AKIT_UNASSIGN_VARIANT, unassignVariant),
    ]);
}
