import React from "react";
import { Icon, Header, Modal, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import EditableText from "../../../component/EditableText";
import LessonUnitsListDroppable from "./LessonUnit/LessonUnitsListDroppable";
import { DragDropContext } from "react-beautiful-dnd";
import _ from "lodash";
import { stateLessonMove, stateLessonTopicMove } from "../DataSource/lessonManager";
import { stateLessonUnitMove } from "../DataSource/lessonUnitManager";
import LessonPropertiesContainer from "./LessonProperties/LessonPropertiesContainer";

import "./OutlineEditor.scss";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import { toastError, INTERACTION_ERROR } from "../../../component/toast";
import OutlinePublisher from "./OutlinePublisher/";

export default class OutlineEditor extends React.Component {
    static propTypes = {
        selectedOutlineData: PropTypes.object,

        onSelectOutline: PropTypes.func,
        onOutlineRename: PropTypes.func,
        onLessonUnitMove: PropTypes.func,
        onLessonMove: PropTypes.func,
        onLessonTopicMove: PropTypes.func,
        showLessonProperties: PropTypes.bool,

        openEditorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        onEditingText: PropTypes.func,

        showOutlineHeader: PropTypes.bool,
        canEdit: PropTypes.bool,

        onPublishLessons: PropTypes.func,
        onPublishReset: PropTypes.func,
        publishingProgress: PropTypes.object,
        publishingMessage: PropTypes.string,

        setShowPublisher: PropTypes.bool,
        onHidePublisher: PropTypes.func,
        firebase: PropTypes.object.isRequired,
    };

    static defaultProps = {
        showOutlineHeader: false,
    };

    state = {
        outlineLocalCopy: null,
        stateUpdated: false,
    };

    componentDidMount() {
        this.props.firebase.watchEvent("value", "/topic_collections/list");
        this.props.firebase.watchEvent("value", "/topic_collections/data");
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.stateUpdated) {
            return { stateUpdated: false };
        }

        if (!_.isEqual(prevState.outlineLocalCopy, nextProps.selectedOutlineData)) {
            // console.log("OutlineEditor::getDerivedStateFromProps() - Outline updated from PROPS to STATE", nextProps.selectedOutlineData);
            return { outlineLocalCopy: nextProps.selectedOutlineData };
        }
        return null;
    }

    handleGoBack = () => {
        this.props.onSelectOutline(null);
        // e.stopPropagation();
    };

    handleRenameOutline = (newName, event, props) => {
        this.props.onOutlineRename(props.id, newName);
    };

    handleDragEnd = result => {
        // dropped outside of any droppable
        if (!result.destination) {
            return;
        }
        // no move - dropped to original position
        if (
            result.source.droppableId === result.destination.droppableId &&
            result.source.index === result.destination.index
        ) {
            return;
        }

        // console.log(`Moving ${result.type} ID="${result.draggableId}" from "${result.source.droppableId}" at index ${result.source.index} to index ${result.destination.index} of "${result.destination.droppableId}" ...`);
        switch (result.type) {
            case "LESSON_UNIT":
                this.setState({
                    outlineLocalCopy: stateLessonUnitMove(
                        this.state.outlineLocalCopy,
                        result.draggableId,
                        result.destination.droppableId,
                        result.destination.index
                    ),
                    stateUpdated: true,
                });
                this.props.onLessonUnitMove(
                    result.draggableId,
                    result.destination.droppableId,
                    result.destination.index
                );
                break;
            case "LESSON":
                this.setState({
                    outlineLocalCopy: stateLessonMove(
                        this.state.outlineLocalCopy,
                        result.draggableId,
                        result.destination.droppableId,
                        result.destination.index,
                        result.source.droppableId
                    ),
                    stateUpdated: true,
                });
                this.props.onLessonMove(result.draggableId, result.destination.droppableId, result.destination.index);
                break;
            case "TOPIC":
                this.setState({
                    outlineLocalCopy: stateLessonTopicMove(
                        this.state.outlineLocalCopy,
                        result.draggableId[1], // LessonTopic uses "composite" key [lessonId, topicId] to distinguish same Topics in different lessons
                        result.destination.droppableId,
                        result.destination.index,
                        result.source.droppableId
                    ),
                    stateUpdated: true,
                });
                this.props.onLessonTopicMove(
                    result.draggableId[1],
                    result.destination.droppableId,
                    result.destination.index,
                    result.source.droppableId
                ); // is source container param on right position???
                break;
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.selectedOutlineData && !this.props.selectedOutlineData) {
            toastError({
                code: 0,
                header: "Perhaps the outline you have been viewing has been removed by another user.",
                message: INTERACTION_ERROR,
            });
            this.props.onSelectOutline(null);
        }
    }

    render() {
        const { outlineLocalCopy: outline } = this.state;
        const {
            showLessonProperties,
            openEditorId,
            onEditingText,
            showOutlineHeader,
            canEdit = true,
            onPublishLessons,
            publishingProgress,
            publishingMessage,
            onPublishReset,
            setShowPublisher,
            onHidePublisher,
        } = this.props;

        return outline ? (
            <React.Fragment>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    {showOutlineHeader ? (
                        <React.Fragment>
                            <Button onClick={this.handleGoBack}>
                                <Icon name="left arrow" /> Back
                            </Button>
                            <Header as="h2">
                                {outline && (
                                    <EditableText
                                        id={outline.key}
                                        key={outline.key}
                                        text={outline.name}
                                        noPadding
                                        hideIcon={!!openEditorId}
                                        onEditation={onEditingText}
                                        size="huge"
                                        canEdit={canEdit}
                                        // openEditorId={openEditorId}
                                        onUpdate={this.handleRenameOutline}
                                    />
                                )}
                            </Header>
                        </React.Fragment>
                    ) : null}
                    <OutlinePublisher
                        open={setShowPublisher}
                        onClose={onHidePublisher}
                        outlineId={outline.key}
                        lessonUnitsList={outline.lesson_units}
                        onPublish={onPublishLessons}
                        onPublishReset={onPublishReset}
                        publishingProgress={publishingProgress}
                        publishingMessage={publishingMessage}
                    />
                    <LessonUnitsListDroppable
                        outlineId={outline.key}
                        lessonUnitsList={outline.lesson_units}
                        canEdit={canEdit}
                    />
                </DragDropContext>
                <Modal open={showLessonProperties}>
                    <LessonPropertiesContainer />
                </Modal>
            </React.Fragment>
        ) : (
            <NoDataPlaceholder text="There is no outline yet." icon="file outline">
                <p>Please wait until it is loaded ...</p>
            </NoDataPlaceholder>
        );
    }
}
