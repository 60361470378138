import PropTypes from "prop-types";

const StyleProperties = [
    "borderStyle", "borderWidth", "borderColor",
    "borderRadius", "backgroundColor", "padding"
];

export const stylePropType = PropTypes.shape(
    StyleProperties.reduce((types, field) => {
        types[field] = PropTypes.string;
        return types;
    }, {})
);

const copyKnownStyles = (source) => (
    StyleProperties.reduce((target, field) => {
        if (source[field]) {
            target[field] = source[field];
        }
        return target;
    }, {})
);

export const StyleValueNotSet = "#unset#";

export const updateStyleValue = (current, changes) => {
    if (!changes) {
        return current;
    }
    const merged = { ...current, ...changes };
    const output = copyKnownStyles(merged);

    return Object.keys(output).length ? output : null;
};

export const updateItemConfig = (data, field, value) => {
    const output = { ...data };
    if (!value) {
        if (output[field] !== undefined) {
            output[field] = null;
        }
    } else {
        output[field] = value;
    }
    return output;
};

export const headerPropType = PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]);