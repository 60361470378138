import { TYPES } from "./SyllabusOutlineActions";

const initialState = {
    cvTemplate: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case TYPES.SYLLABUS_OUTLINES_SET_CV_TEMPLATE_BY_CV:
            return { ...state, cvTemplate: payload };
        default:
            return state;
    }
};
