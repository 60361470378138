import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { convertToLinkOrFileURL } from "../ResourcesUtils";

const ResourceLink = ({ type, href, children, short }) => {
    const title = `Click to ${type.is_external ? "open external link" : "download file"}`;
    return (
        <a 
            href={convertToLinkOrFileURL(href)}
            target="_blank"
            rel="external noopener noreferrer"
            download={type.is_external}
            title={title}
        >{children || (
                <span style={{whiteSpace: "nowrap"}}>
                    <Icon name={type.is_external ? "external alternate" : "download"} />
                    {type.is_external ? short ? "External link" : "Link to external resource" : short ? "Download file" : "Download resource file"}
                </span>
            )}</a>
    );
};

const ResourceTypeProps = {
    id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    code: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    is_external: PropTypes.bool,
};

ResourceLink.propTypes = {
    type: PropTypes.shape(ResourceTypeProps),
    href: PropTypes.string,
    short: PropTypes.bool,
    children: PropTypes.node,
};

export default ResourceLink;