const TYPES = {
    REMOVE_TAGS_TOPICS: "REMOVE_TAGS_TOPICS"
};

const onSaveRemoveTagsTopics = ({ fbCollection, problems, tags, topics, authorId }) => ({
    type: TYPES.REMOVE_TAGS_TOPICS,
    payload: { fbCollection, problems, tags, topics, authorId },
});

export { TYPES, onSaveRemoveTagsTopics };
