import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Loader, Dimmer } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import { KeyBindingUtil } from "draft-js";
import { Helmet } from "react-helmet";

import { swallowEvent } from "../bookEditor/utils/utils";
import { convertTextColorToBackground, updateRowHighlighting, resetColors, resetFont } from "./formulaUtils";
import FormulaRenderer from "../export/FormulaRenderer";
import ErrorMessage from "../bookEditor/messages/Message";
import "./formulaEditor.scss";
import { getDimmer } from "../bookEditor/utils/domUtils";
import { toastError } from "../../../component/toast";

const WIRIS_URL = ((window && window.location.protocol) || "https:") + "//www.wiris.net/demo/editor/editor";

let wirisEditorInstance = null;

const isWirisLoaded = () => Boolean(window.com && window.com.wiris);

export default class FormulaEditor extends React.Component {
    static propTypes = {
        mathML: PropTypes.string.isRequired,
        svg: PropTypes.string,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
    };

    state = {
        isSaving: false,
        error: null,
    };

    formulaEditorElement = React.createRef();

    handleWirisLoadError = () => {
        toastError({
            code: "External URL problem",
            header: "Loading Formula Editor failed.",
            message: "There could be a network problem. Try it again, please."
        });
        this.props.onCancel();
    };

    handleWirisLoadSuccess = () => {
        wirisEditorInstance = window.com.wiris.jsEditor.JsEditor.newInstance({
            language: "en",
        });
        this.componentDidMount();
        this.forceUpdate();
    };

    handleHelmetOnChange = (newState, addedTags) => {
        // https://github.com/nfl/react-helmet/issues/146
        const wirisScriptTag = addedTags.scriptTags && addedTags.scriptTags.find(({ src }) => src === WIRIS_URL );

        if (wirisScriptTag) {
            wirisScriptTag.addEventListener("error", this.handleWirisLoadError);
            wirisScriptTag.addEventListener("load", this.handleWirisLoadSuccess);
        }
    };

    componentDidMount = () => {
        if (!isWirisLoaded()) {
            return;
        }

        const { mathML } = this.props;
        this.formulaEditor = wirisEditorInstance;
        this.formulaEditor.setMathML(mathML);
        this.formulaEditor.insertInto(this.formulaEditorElement.current);
        setTimeout(() => this.formulaEditor.focus(), 0);
    };

    updateDom = () => {
        const dimmer = getDimmer();
        dimmer.classList.add("formulaEditor");
    };

    handleSave = async () => {
        const { mathML: initialMathML, svg: initialSvg, onSave, onCancel, onDirty } = this.props;
        let mathML = this.formulaEditor.getMathML();
        let formula, svg;

        // TODO pbenes: initialSvg is used only for backward compatibility related to commit
        // https://bitbucket.org/bmdconsulting/spork-app/commits/a1c4656da0b77291dc122a255dde4f6f6e2f7545#chg-client/src/component/seamlessEditor/bookEditor/formulaEntity/FormulaDecorator.jsx
        // It should be removed ...
        if (mathML !== initialMathML || !initialSvg) {
            this.setState({ isSaving: true });
            mathML = updateRowHighlighting(mathML);
            const formulaRenderer = new FormulaRenderer([mathML]);

            const processedFormulas = await formulaRenderer.processFormulas()
                .catch((e) => this.setState({ isSaving: false, error: e }));  // avoid dead-lock

            if (processedFormulas) {
                formula = Object.keys(processedFormulas)[0];
                svg = processedFormulas[formula];
                onDirty();
                this.setState({ isSaving: false });
                onSave({ formula, svg });
            }
        }
        else {
            onCancel();
        }
    };

    /**
     * Keyboard short-cuts. Use [Command] instead [Ctrl] on Mac.
     *     [Ctrl] + [S] : save formula and close the editor
     *     [Esc]        : close the editor without saving
     */
    handleKeyDown = (e) => {
        e.stopPropagation();

        if (e.keyCode === 27) {
            this.props.onCancel();
        }
        else if (e.keyCode === 83 && KeyBindingUtil.hasCommandModifier(e)) {
            swallowEvent(e);
            this.handleSave();
        }
    };

    handleColors = () => {
        let mathML = this.formulaEditor.getMathML();
        mathML = convertTextColorToBackground(mathML);
        this.formulaEditor.setMathML(mathML);
    };

    handleResetColors = () => {
        let mathML = this.formulaEditor.getMathML();
        mathML = resetColors(mathML);
        this.formulaEditor.setMathML(mathML);
    };

    handleDismissError = () => {
        this.setState({ error: null });
    };

    handleResetFont = () => {
        let mathML = this.formulaEditor.getMathML();
        mathML = resetFont(mathML);
        this.formulaEditor.setMathML(mathML);
    }

    render() {
        const { isSaving, error } = this.state;
        return (
            <Modal open={true} className="formulaEditor" onClick={swallowEvent}
                onKeyDown={this.handleKeyDown} onMouseDown={swallowEvent} ref={this.updateDom}
            >
                <Modal.Header>
                    Formula Editor
                </Modal.Header>
                <Modal.Content>
                    {isWirisLoaded()
                        ? (<Dimmer active={isSaving} inverted>
                            <Loader inverted content="Generating Formula Image..." />
                        </Dimmer>)
                        : (<Dimmer active={true} inverted>
                            <Loader inverted content="Loading Formula Editor..." />
                            <Helmet onChangeClientState={this.handleHelmetOnChange}>
                                <script type="text/javascript" src={WIRIS_URL} async></script>
                            </Helmet>
                        </Dimmer>)
                    }
                    <div
                        ref={this.formulaEditorElement}
                        className="formulaEditorContent"
                    />
                    {error
                        ? <ErrorMessage onDismiss={this.handleDismissError} message={{
                            type: "error",
                            header: "Generating formula image failed",
                            content: error,
                        }} />
                        : <div className="footer">
                            <div>
                                <Button size="small" onClick={this.handleColors} style={{ marginRight: "1em" }}>Text color to background</Button>
                                <Button size="small" onClick={this.handleResetColors} style={{ marginRight: "1em" }}>Reset colors</Button>
                                <Button size="small" onClick={this.handleResetFont}>Reset font</Button>
                            </div>
                            <SaveCancelButtons
                                onSave={this.handleSave}
                                onCancel={this.props.onCancel}
                            />
                        </div>
                    }
                </Modal.Content>
            </Modal>
        );
    }
}
