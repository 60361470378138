const TYPES = {
    CREATE_WIDGET_SELECT_TOPIC: "KitBuilder/WidgetLibrary/CreateWidget/SELECT_TOPIC",
};

const onSelectTopic = payload => ({
    type: TYPES.CREATE_WIDGET_SELECT_TOPIC,
    payload,
});

export { TYPES, onSelectTopic };
