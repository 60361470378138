import { createSelector } from "reselect";

export const getLessons = (props) => {
    return props.lessonUnitsList.reduce((accum, current) => {
        return [...accum, ...current.lessons];
    },[]);
};

export const outlinePublishData = (state, props) => {
    if (state.firebase 
        && state.firebase.data 
        && state.firebase.data.outlines_published
    ) {
        const { outlines_published } = state.firebase.data;
        if (outlines_published[props.outlineId] && outlines_published[props.outlineId].lessons)
            return outlines_published[props.outlineId].lessons;
        else
            return {};
    }
};

export const getOutlineLessonsPublished = createSelector(outlinePublishData, (outlineLessonsPublished) => {
    return outlineLessonsPublished;
});