import { connect } from "react-redux";

import Dashboard from "./Dashboard";
import { getDashboard } from "./dashboardSelectors";
import { getDesign } from "./tiles/design/designTileSelectors";

const mapStateToProps = function(state) {
    return {
        ...getDashboard(state),
        design: getDesign(state)
    };
};

export default connect(mapStateToProps, null)(Dashboard);
