const TYPES = {
    SIPI_TEMPLATE_GO_BACK: "SIPI_TEMPLATE_GO_BACK",
    SIPI_TEMPLATE_UPDATE_ACTIVITIES_ORDER: "SIPI_TEMPLATE_UPDATE_ACTIVITIES_ORDER",
    SIPI_TEMPLATE_UPDATE_ACTIVITY: "SIPI_TEMPLATE_UPDATE_ACTIVITY",
    SIPI_TEMPLATE_ADD_ACTIVITY: "SIPI_TEMPLATE_ADD_ACTIVITY",
    SIPI_TEMPLATE_REMOVE_ACTIVITY: "SIPI_TEMPLATE_REMOVE_ACTIVITY",
    SIPI_TEMPLATE_LOAD_IPLANNER_RESOURCES: "SIPI_TEMPLATE_LOAD_IPLANNER_RESOURCES",
    SIPI_TEMPLATE_UPDATE_LESSON: "SIPI_TEMPLATE_UPDATE_LESSON",
    SIPI_TEMPLATE_CREATE_LESSON: "SIPI_TEMPLATE_CREATE_LESSON",
    SIPI_TEMPLATE_REMOVE_LESSON: "SIPI_TEMPLATE_REMOVE_LESSON",
    SIPI_TEMPLATE_UPDATE_LESSON_ORDER: "SIPI_TEMPLATE_UPDATE_LESSON_ORDER",
    SIPI_TEMPLATE_UPDATE_NAME: "SIPI_TEMPLATE_UPDATE_NAME",
    SIPI_TEMPLATE_REMOVE: "SIPI_TEMPLATE_REMOVE",
};

const onGoBack = () => ({
    type: TYPES.SIPI_TEMPLATE_GO_BACK
});

const onUpdateSiPiOrder = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_UPDATE_ACTIVITIES_ORDER,
    payload
});

const onUpdateSiPi = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_UPDATE_ACTIVITY,
    payload
});

const onAddSiPi = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_ADD_ACTIVITY,
    payload
});

const onRemoveSiPi = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_REMOVE_ACTIVITY,
    payload
});

const onLoadIplannerResources = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_LOAD_IPLANNER_RESOURCES,
    payload
});

const onUpdateLesson = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_UPDATE_LESSON,
    payload
});

const onCreateLesson = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_CREATE_LESSON,
    payload
});

const onRemoveLesson = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_REMOVE_LESSON,
    payload
});

const onUpdateLessonOrder = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_UPDATE_LESSON_ORDER,
    payload
});

const onUpdateName = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_UPDATE_NAME,
    payload
});

const onRemove = (payload) => ({
    type: TYPES.SIPI_TEMPLATE_REMOVE,
    payload
});

export {
    TYPES,
    onGoBack,
    onUpdateSiPiOrder,
    onUpdateSiPi,
    onAddSiPi,
    onRemoveSiPi,
    onLoadIplannerResources,
    onUpdateLesson,
    onCreateLesson,
    onRemoveLesson,
    onUpdateLessonOrder,
    onUpdateName,
    onRemove,
};
