import React from "react";
import PropTypes from "prop-types";
import { Header, Divider, Tab, Icon, Label } from "semantic-ui-react";
import { WidgetViewer, previewContent } from "../Widget";
import TemplateEditor from "./TemplateEditor";
import EditableText from "../../component/EditableText";
import TemplateLastEditor from "./TemplateLastEditor";
import CloneWindow from "../component/CloneWindow";
import ActionLinks from "../../component/ActionLinks";
import { confirmUnsave } from "../LessonContent/utils";
import EntityModuleList from "../../component/EntityModuleList";
import { AccessDeniedMessage } from "../../component/AccessDeniedMessage";

export default class WidgetTemplateDetail extends React.PureComponent {
    static propTypes = {
        selectedTemplate: PropTypes.object,
        onSelectTemplate: PropTypes.func.isRequired,
        onUpdateName: PropTypes.func.isRequired,
        canEdit: PropTypes.bool.isRequired,
        isNew: PropTypes.bool.isRequired,
        onCloseNameEditor: PropTypes.func.isRequired,
        orderedItemList: PropTypes.array,
        onCloneTemplate: PropTypes.func.isRequired,
        onSetTagFilter: PropTypes.func,
        usedByCount: PropTypes.number,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
    };

    state = {
        openCloner: false,
        activeIndex: 0,
    };

    handleTabChange = (e, { activeIndex }) => {
        const { isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you switch to another tab", onSetDirty)) {
            this.setState({ activeIndex });
        }
        
    }

    handleSetTagFilter = template => {
        const { onSetTagFilter } = this.props;
        if (template) {
            onSetTagFilter(template.tags || {});
        }
    };

    componentDidMount() {
        const { selectedTemplate } = this.props;
        this.props.onSetDirty(false);
        this.handleSetTagFilter(selectedTemplate);
    }

    componentDidUpdate(prevProps) {
        const { selectedTemplate, onSelectTemplate } = this.props;
        if (!selectedTemplate && prevProps.selectedTemplate) {
            onSelectTemplate && onSelectTemplate(null);
            this.handleCloseCloner();
        }
        if (
            undefined === prevProps.selectedTemplate ||
            undefined === this.props.selectedTemplate ||
            prevProps.selectedTemplate.key !== this.props.selectedTemplate.key
        ) {
            this.setState({ activeIndex: 0 });
        }
        if (prevProps.selectedTemplate != this.props.selectedTemplate) {
            this.handleSetTagFilter(this.props.selectedTemplate);
        }
    }

    handleOpenCloner = () => {
        const { isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you clone template", onSetDirty)) {
            this.setState({ openCloner: true });
        }
    };

    handleCloseCloner = () => {
        this.setState({ openCloner: false });
    };

    handleClone = (clonedObject, name) => {
        this.props.onCloneTemplate({ template: clonedObject, name });
        this.handleCloseCloner();
    };

    renderEditor = () => {
        const { selectedTemplate, onCreateItem, onUpdateSetup, usedByCount } = this.props;
        return (
            <TemplateEditor
                template={selectedTemplate}
                onCreateItem={onCreateItem}
                usedByCount={usedByCount}
                onUpdateSetup={onUpdateSetup}
            />
        );
    };

    renderPreview = () => {
        const { selectedTemplate, orderedItemList } = this.props;
        return (
            <WidgetViewer
                template={selectedTemplate}
                items={orderedItemList}
                content={previewContent(selectedTemplate)}
            />
        );
    };

    handleGoBack = () => {
        const { isDirty, onSetDirty } = this.props;
        if (!isDirty || confirmUnsave("When you go back to templates list", onSetDirty)) {
            this.props.onSelectTemplate(null);
        }
    }

    handleNameUpdate = value => {
        const { onUpdateName, onCloseNameEditor } = this.props;
        onUpdateName(value);
        onCloseNameEditor();
    };

    render() {
        const { selectedTemplate, canEdit, isNew, onCloseNameEditor } = this.props;
        const { activeIndex, openCloner } = this.state;

        return selectedTemplate.noAccess ? <AccessDeniedMessage /> : (
            <React.Fragment>
                <CloneWindow
                    clonedObject={selectedTemplate}
                    open={openCloner}
                    entityName="template"
                    onClose={this.handleCloseCloner}
                    onClone={this.handleClone}
                />

                <Header as="h2" className="pageHeader">
                    Widget Template
                </Header>
                <a onClick={this.handleGoBack} style={{ cursor: "pointer" }}>
                    <Icon name="left arrow" /> Back to Widget Templates list
                </a>
                {canEdit && (
                    <ActionLinks
                        floated="right"
                        horizontal
                        actions={[
                            { name: "clone", content: "Clone", icon: "clone outline", onClick: this.handleOpenCloner },
                        ]}
                    />
                )}
                <Divider clearing hidden />
                <div style={{height: "3em"}}>
                    <div style={{ float: "right" }}>
                        <TemplateLastEditor itemId={selectedTemplate.key} />
                    </div>

                    <Header as="h3" floated="left" style={{ display: "flex" }}>
                        <EditableText
                            text={selectedTemplate.name}
                            onUpdate={this.handleNameUpdate}
                            onCancel={onCloseNameEditor}
                            canEdit={canEdit}
                            size="huge"
                            placeholder="Template name..."
                            {...isNew && { id: selectedTemplate.key, openEditorId: selectedTemplate.key, text: "" }}
                        />
                        {isNew && (
                            <Label
                                pointing="left"
                                basic
                                color="green"
                                style={{ marginTop: "auto", marginBottom: "auto" }}
                            >
                                Write a nice name for the template here
                            </Label>
                        )}
                    </Header>
                </div>
                {!canEdit ? (
                    this.renderPreview()
                ) : (
                    <Tab
                        menu={{ compact: true }}
                        key={selectedTemplate && selectedTemplate.key}
                        onTabChange={this.handleTabChange}
                        activeIndex={activeIndex}
                        panes={[
                            { menuItem: "Compose", render: this.renderEditor },
                            { menuItem: "Preview", render: this.renderPreview },
                        ]}
                    />
                )}
                {selectedTemplate.modules && <EntityModuleList list={selectedTemplate.modules} showHeader />}
            </React.Fragment>
        );
    }
}
