import React from "react";
import PropTypes from "prop-types";
import { Segment, Label } from "semantic-ui-react";
import memoize from "memoize-one";

import ActionLinks from "../../../component/ActionLinks";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import Widget from "../../Widget";

const createScopePredicate = (viewScope) => ({ value }) => {
    const itemScope = value && value.scope ? value.scope.toLowerCase() : "all";
    return itemScope === "all" || itemScope === viewScope;
};

const isOtherTemplate = (prev, curr) => !(prev && curr && prev.templateId === curr.templateId);
const prepareContent = memoize(
    (entries, templates) => entries.reduce((output, entry) => {
        const { key:widgetId, value:widget } = entry;
        if (widget) {
            if (isOtherTemplate(output.lastOne, widget)) {
                const key = widget.templateId;
                output.lastOne = {
                    templateId: key,
                    template: {
                        key,
                        ...templates[key],
                    },
                    customTitle: widget.custom_title,
                    hiddenTitle: widget.hidden_title,
                    widgets: []
                };
                output.widgets.push(output.lastOne);
            }
            output.lastOne.widgets.push({ widgetId, items: widget.items });
        }
        return output;
    }, { widgets: [], lastOne: null }).widgets
);

export default class LessonPreview extends React.PureComponent {
    static propTypes = {
        widgets: PropTypes.array,
        templatesData: PropTypes.object,
        widgetsData: PropTypes.object,
    };

    state = {
        viewScope: null
    };

    handleScopeAction = (e, data) => {
        const { name } = data;
        this.setState({
            viewScope: name === "teacher" || name === "student" ? name : null
        });
    };

    render() {
        const { widgets, templatesData, widgetsData } = this.props;
        const { viewScope } = this.state;
        const content = widgets && templatesData && prepareContent(widgets, templatesData);
        const isEmpty = !(content && content.length);
        const actions = [
            { name: "preview", content: "All Items", active: !viewScope },
            { name: "teacher", content: "As Teacher", active: viewScope === "teacher" },
            { name: "student", content: "As Student", active: viewScope === "student" }
        ];
        const scopePredicate = !isEmpty && viewScope ? createScopePredicate(viewScope) : null;

        return (
            <Segment className="spork widget-viewer">
                <Label attached="top" style={{ display: "flex" }}>
                    <ActionLinks
                        floated="left"
                        horizontal
                        caption="VIEW"
                        actions={actions}
                        onClick={this.handleScopeAction}
                        disabled={isEmpty}
                    />
                </Label>
                <div style={{overflowY: "auto", maxHeight: "70vh"}}>
                    {content && content.length ? content.map((entry, index) =>
                        <Widget key={`${entry.templateId}:${index}`} template={entry.template} customTitle={entry.customTitle} hiddenTitle={entry.hiddenTitle}>
                            {entry.widgets.map(widget => 
                                <Widget.Items
                                    key={widget.widgetId}
                                    content={widget.items}
                                    template={entry.template.items}
                                    filtered={scopePredicate}
                                />
                            )}
                        </Widget>
                    ) : widgets && widgets.length && (!templatesData || !widgetsData) ? <Segment placeholder loading/> : <NoDataPlaceholder text="Lesson has no content." />}
                </div>
            </Segment>
        );
    }
}
