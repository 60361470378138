import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Comment, Icon } from "semantic-ui-react";
import { timeSince } from "../../utils/dateUtils";
import UserAvatar from "react-user-avatar";
import AccessControl from "../../auth/AccessControl";
import EditCommentBox from "./EditCommentBox";


export default class CommentItem extends PureComponent {
    static propTypes = {
        comment: PropTypes.any, // workaround for error Failed prop type: Invalid prop `comment.author` of type `string` supplied to `CommentItem`, expected `object`.
        commentId: PropTypes.string.isRequired,
        parentCommentId: PropTypes.string,
        children: PropTypes.node,
        onReplyTo: PropTypes.func,
        onMarkResolved: PropTypes.func,
        onRemove: PropTypes.func,
        onEdit: PropTypes.func,
        deleteValidator: PropTypes.shape({
            action: PropTypes.string,
            data: PropTypes.shape({
                userId: PropTypes.string,
                commentOwnerId: PropTypes.string,
            }),
        }),
        editValidator: PropTypes.shape({
            action: PropTypes.string,
            data: PropTypes.shape({
                userId: PropTypes.string,
                commentOwnerId: PropTypes.string,
            }),
        }),
    };

    state = {
        editMode: false
    }

    handleOnEdit = () => this.setState({ editMode: true });
    handleCancelEdit = () => this.setState({ editMode: false });

    handleSaveEdit = (comment) => {
        const { commentId, onEdit, parentCommentId } = this.props; 
        this.setState({ editMode: false }, () => {
            onEdit && onEdit(commentId, comment, parentCommentId);
        });
    }

    render() {
        const { commentId: key, comment, parentCommentId, children, onReplyTo, onMarkResolved, onRemove, deleteValidator, editValidator } = this.props;
        const { editMode } = this.state;

        return (
            <Comment key={key} className="compact">
                <Comment.Avatar as={UserAvatar} name={comment.authorObj.first_name + " " + comment.authorObj.last_name} size="32" /> 
                <Comment.Content>
                    <Comment.Author as="span">
                        {comment.authorObj && (comment.authorObj.first_name + " " + comment.authorObj.last_name)}
                    </Comment.Author>
                    <Comment.Metadata>
                        <div>
                            {timeSince(comment.timestamp)}{" "}
                            {comment.resolved && <Icon name="check circle outline" color="green" />}
                        </div>
                        { comment.modified && 
                            <div>
                                (edited {timeSince(comment.modified)}{" "})
                            </div>
                        }
                    </Comment.Metadata>
                    { editMode ? (
                        <EditCommentBox 
                            onCancel={this.handleCancelEdit} 
                            onSave={this.handleSaveEdit} 
                            comment={comment}
                        />
                    ) : (
                        <React.Fragment>
                            <Comment.Text>{comment.comment}</Comment.Text>
                            <Comment.Actions>
                                <Comment.Action id={parentCommentId || key} onClick={onReplyTo}>
                                    Reply
                                </Comment.Action>
                                {!comment.isReply && !comment.resolved && (
                                    <AccessControl action="comment:resolve">
                                        <Comment.Action id={key} onClick={onMarkResolved}>
                                            Mark as resolved
                                        </Comment.Action>
                                    </AccessControl>
                                )}
                                <AccessControl {...editValidator}>
                                    <Comment.Action data-id={key} data-parent-id={parentCommentId} onClick={this.handleOnEdit} title="Edit comment">
                                        Edit
                                    </Comment.Action>
                                </AccessControl>
                                <AccessControl {...deleteValidator}>
                                    <Comment.Action data-id={key} data-parent-id={parentCommentId} onClick={onRemove} title="Remove comment">
                                        Remove
                                    </Comment.Action>
                                </AccessControl>
                            </Comment.Actions>
                        </React.Fragment>
                    )}
                </Comment.Content>
                {children && <Comment.Group>{children}</Comment.Group>}
            </Comment>
        );
    }
}
