import { all, takeEvery, put, select } from "redux-saga/effects";
import firebase from "../../firebase";
import {
    TYPES,
    onImportGlossaryStop,
    onImportGlossaryStart,
    onImportGlossaryProgress,
    onImportGlossaryError,
} from "./importGlossaryActions";
import { convertToRawContentState } from "../../utils/conversionUtils";
import { getGlossaryToImport, inProgress } from "./importGlossarySelector";

export function* importGlossary() {
    yield put(onImportGlossaryStart());
    try {
        // get a list of glossary terms to import
        let terms = yield select(getGlossaryToImport);
        let total = Object.keys(terms || {}).length;
        let i = 1;
        for (let key in terms) {
            let stillInProgress = yield select(inProgress);
            if (stillInProgress) {
                let term = terms[key];
                // some terms does not have any definition, we have to skip these
                if (term.definition) {
                    let rawContentState = yield convertToRawContentState(term.definition || term.published_definition);
                    let newItem = {
                        name: term.term,
                        content: {
                            html: term.published_definition || term.definition,
                            raw: rawContentState,
                        },
                    };

                    let newKey = yield firebase.defaultApp
                        .database()
                        .ref()
                        .push().key;

                    let updates = {};
                    updates["glossary/data/" + newKey] = newItem;
                    updates["glossary/list/" + newKey] = { name: term.term };
                    updates["glossary_to_import/" + key] = {};
                    yield firebase.defaultApp
                        .database()
                        .ref()
                        .update(updates);
                    yield put(onImportGlossaryProgress(i, total));
                } else {
                    yield firebase.defaultApp
                        .database()
                        .ref("glossary_to_import/" + key)
                        .remove();
                }
                i++;
            }
        }
    } catch (error) {
        yield put(onImportGlossaryError(error));
    }
    yield put(onImportGlossaryStop());
}

export default function* saga() {
    yield all([takeEvery(TYPES.IMPORT_GLOSSARY, importGlossary)]);
}
