import React from "react";
import PropTypes from "prop-types";
import "./problemSetInfo.css";
import { allSetTypes, typesAsObject } from "../ProblemSetWizard/problemSetTypes";
import { convertDateIsoToLocal } from "../../utils/dateUtils";

export default class ProblemSetInfo extends React.PureComponent {

    static propTypes = {
        problemSet: PropTypes.object,
        history: PropTypes.object,
        setId: PropTypes.string,
        fbCollection: PropTypes.string,
    }

    handleClickSet = () => {
        const { setId, history, fbCollection } = this.props;
        if ("problemSet" === fbCollection) {
            history.push(`/qu/problem-set-creator/${setId}`);
        } else {
            history.push(`/qu/comp-test-editor/${setId}`);
        }
    }

    render() {
        const { problemSet } = this.props;

        if (!problemSet) return (
            <span>Data not found.</span>
        );
        const typeLocator = problemSet.setType || problemSet.type || "";
        const typeDescription = problemSet != null && typesAsObject(allSetTypes)[typeLocator.toLowerCase()] || "N/A";

        return (
            <div className="set-info"><a onClick={this.handleClickSet}>{problemSet.title}</a>&nbsp;<small>{typeDescription} created on: {convertDateIsoToLocal(problemSet.createdDate)}</small></div>
        );
    }
}
