import { all, select, takeEvery, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onUpdateProgress } from "./CreateProblemSetsDataActions";
import { renderProblemSet } from "../../KitBuilder/LessonContent/LessonProblemSet/renderProblemSet";
import { getProgress } from "./CreateProblemSetsDataSelectors";
import { toastError } from "../../component/toast";

const getProblemSetTitle = (problemSets, problemSetId) => {
    const title = problemSets[problemSetId].title;

    if (title) {
        return title;
    }

    toastError({ code: -1,
        header: "Inconsistent data",
        message: `Missing title for Problem Set "${problemSetId}"`,
    });

    return "unknown";
};

function* prepareTitleFix(updates, problemSets, outlineId, lessonId, lesson, collection) {
    const progress = yield select(getProgress);
    const path = `outlines_data/${outlineId}/lessons/${lessonId}/${collection}`;

    for (const problemSetId of Object.keys(lesson[collection] || {})) {
        const titlePath = `${path}/${problemSetId}/title`;
        const psTitleInLesson = (yield firebase.getFirebaseData(titlePath).once("value")).val();
        const psTitle = getProblemSetTitle(problemSets, problemSetId);
        progress.checkedTitles++;

        if (psTitleInLesson !== psTitle) {
            progress.fixedTitles++;
            updates[titlePath] = psTitle;
        }

        yield put(onUpdateProgress(progress));
    }
}

function* updateTitleProperty(problemSets) {
    const db = firebase.defaultApp.database().ref();
    const outlinesDataRef = firebase.getFirebaseData("outlines_data");
    const outlinesData = (yield outlinesDataRef.once("value")).val();

    for (const [outlineId, outline] of Object.entries(outlinesData)) {
        const updates = {};

        if (outline.lessons) {
            for (const [lessonId, lesson] of Object.entries(outline.lessons)) {
                yield prepareTitleFix(updates, problemSets, outlineId, lessonId, lesson, "homework");
                yield prepareTitleFix(updates, problemSets, outlineId, lessonId, lesson, "problem_sets");
            }
        }

        yield db.update(updates);
    }
}

function* generateProblemSetData() {
    let progress = yield select(getProgress);
    const BATCH_SIZE = 10;

    try {
        yield firebase.getFirebaseData("lesson_problem_sets").remove();
        yield firebase.getFirebaseData("lesson_homework").remove();
    }
    catch (e) {
        // These collection should be already removed => empty catch
    }
    progress.oldRemoved = true;

    const problemSetsRef = firebase.getFirebaseData("problemSet");
    const problemSets = (yield problemSetsRef.once("value")).val();
    const problemSetIds = Object.keys(problemSets);
    const totalProblemSets = problemSetIds.length;
    const batch = [];
    let offset = 0;

    progress.fetched = true;
    progress.total = totalProblemSets;
    yield put(onUpdateProgress(progress));

    // TODO [#21466 clean-up]: Clear already stored data when it runs again?

    for (const problemSetId of problemSetIds) {
        progress.count++;
        yield put(onUpdateProgress(progress));

        batch.push(renderProblemSet(problemSetId));

        if (batch.length >= BATCH_SIZE || offset + batch.length >= totalProblemSets) {
            const batchData = yield all(batch);

            yield all(batchData.map((data, index) => {
                const problemSetId = problemSetIds[offset + index];
                return firebase.getFirebaseData(`problem_sets_data/${problemSetId}`).set(data);
            }));

            offset += batch.length;
            batch.length = 0;
        }
    }

    progress.dataCreated = true;
    yield put(onUpdateProgress(progress));
    yield updateTitleProperty(problemSets);
    progress = yield select(getProgress);

    progress.done = true;
    progress.inProgress = false;
    yield put(onUpdateProgress(progress));
}

export default function* saga() {
    yield all([takeEvery(TYPES.GENERATE_PROBLEM_SET_DATA, generateProblemSetData)]);
}
