import React from "react";
import PropTypes from "prop-types";
import { Icon, Divider, Popup } from "semantic-ui-react";
import TemplateiPlanners from "./TemplateiPlanners";

class PopupHeaderWithCloseIcon extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
        style: PropTypes.object,
    };

    render() {
        const { children, style, onClose } = this.props;

        return (
            <Popup.Header style={{ ...style, marginTop: "-.45em" }}>
                {children && <div style={{ float: "left" }}>{children}</div>}
                <div style={{ float: "right", verticalAlign: "middle" }}>
                    <Icon link size="small" name="close" title="Close" onClick={onClose} style={{ margin: 0 }} />
                </div>
                <Divider hidden clearing style={{ margin: 0 }} />
            </Popup.Header>
        );
    }
}

export default class TemplateiPlannersPopup extends React.PureComponent {
    static propTypes = {
        context: Popup.propTypes.context,
        onClose: PropTypes.func,
        usedBy: PropTypes.object,
        templateId: PropTypes.string,
    };

    render() {
        const { context, onClose, templateId, usedBy } = this.props;
        return (
            <Popup
                context={context}
                on="click"
                open={!!context}
                position="bottom left"
                wide
                onClose={onClose}
            >
                <PopupHeaderWithCloseIcon onClose={onClose} style={{ minWidth: "12em" }}>Used by iPlanners</PopupHeaderWithCloseIcon>
                <Popup.Content>
                    <TemplateiPlanners templateId={templateId} usedBy={usedBy} />
                </Popup.Content>
            </Popup>
        );
    }
}
