import React from "react";
import PropTypes from "prop-types";
import { Modal, Tab, Select, Message, Header, Divider, Button, Dropdown, Form } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import memoizeOne from "memoize-one";
import classNames from "classnames/bind";

import { getLinkedOutlinesFromLessonLinks } from "../../component/seamlessEditor/bookEditor/utils/utils";
import { problemTypes } from "./problemTypes";
import SharedToolbar from "../../component/seamlessEditor/bookEditor/SharedToolbar";
import { getReviewsStats } from "../../component/seamlessEditor/bookEditor/utils/utils";

import ProblemSetInfo from "../ProblemSetInfo";

import MultipleChoiceAnswer from "./MultipleChoice/MultipleChoiceAnswer";
import TopicTreeSelector from "../../TopicCollection/TopicTreeSelector";
import TrueFalseChoice from "./TrueFalseChoice/TrueFalseChoice";
import QuestionItemEditor from "./QuestionItemEditor";

import Comments from "../../KitBuilder/Comments";

import MatchingChoiceAnswer from "./MatchingChoice/MatchingChoiceAnswer";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import { getDimmer, observeBodyForClassName } from "../../component/seamlessEditor/bookEditor/utils/domUtils";
import { LockPropType, TYPE as LOCK_TYPE } from "../../Lock";
import LockableBlock from "../../Lock/LockableBlock";
import { validateQuestionSave, validateAnswerSave } from "./QuestionEditorUtils";
import styles from "./QuestionEditor.module.scss";

let cx = classNames.bind(styles);

const PROBLEM_EDITOR_OPEN_CLASS = "problem-editor-opened";

/**
 * Change default problem type for comprehensive tests (oa => mc).
 */
const validateQuestionDefaults = (question, fbCollection) => {
    const oaCollision = "problemSet" !== fbCollection && "oa" === question.problemType;
    const problemType = oaCollision ? "mc" : (question.problemType || question.type);
    return {
        ...question,
        problemType,
        answer: (oaCollision ? undefined : question.answer),
        extra_lines: Number(question.extra_lines) || 0
    };
};

/**
 * Allows to save only "problemType" or "type" according to collection.
 * 
 * @param {object} question data changes to be saved
 * @param {string} fbCollection
 * @returns {object} original or corrected question data
 */
const correctQuestionTypeField = (question, fbCollection) => {
    const { problemType } = question;
    if (fbCollection === "problemSet") {
        if (!problemType) {
            throw new Error("Problem should have set type in 'problemType' field (when modified).");
        }
        if (question.type !== undefined) {
            return { ...question, type: null, problemType };
        }
    } else if (problemType !== undefined) {
        // Question from vault (modified type should be written as "type" but not "problemType")
        return { ...question, type: problemType, problemType: null };
    }
    return question;
};

const QuestionPropType = PropTypes.shape({
    question: PropTypes.shape({
        content: PropTypes.string,
        raw: PropTypes.object,
    }),
    answer: PropTypes.oneOfType([
        //open answer & true/false
        PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            raw: PropTypes.object,
        }),
        //multiple-choice or matching
        PropTypes.array,
    ]),
    note: PropTypes.shape({
        content: PropTypes.string,
        raw: PropTypes.object,
    }),
    tags: PropTypes.array,
    topics: PropTypes.object,
    author: PropTypes.object,
    status: PropTypes.string,
    problemType: PropTypes.string,
    createdDate: PropTypes.Date,
    updatedDate: PropTypes.Date,
    parentProblemId: PropTypes.string,
    lock: LockPropType,
});

class QuestionEditor extends React.PureComponent {
    static propTypes = {
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        editMode: PropTypes.bool.isRequired,
        question: QuestionPropType,
        onClose: PropTypes.func,
        tagOptions: PropTypes.array,
        forbidParentQuestion: PropTypes.bool,
        isUserReviewer: PropTypes.bool,
        reviewModeOnly: PropTypes.bool,
        fbCollection: PropTypes.string.isRequired,
    };

    state = {
        question: validateQuestionDefaults(this.props.question || {}, this.props.fbCollection),
        message: null,
        activeIndex: 0,
        editorRefs: {},
        reviewMode: !!this.props.reviewModeOnly,
        hasDirtyEditor: false,
        errorMessage: null,
        tagOptions: undefined,
    };

    constructor(props) {
        super(props);
        this.toolbarRef = React.createRef();
        this.getEditorContentFns = {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reviewModeOnly !== this.props.reviewModeOnly) {
            this.state({ reviewMode: this.props.reviewModeOnly });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (undefined === state.tagOptions) {
            return { tagOptions: props.tagOptions };
        }
        return null;
    }

    registerGetContentFn = (editorId, optionId, fn) => {
        this.getEditorContentFns[editorId] = { optionId, fn };
    };

    unregisterGetContentFn = editorId => {
        delete this.getEditorContentFns[editorId];
    };

    updateGetContentFn = (editorId, optionId) => {
        this.getEditorContentFns[editorId] = { ...this.getEditorContentFns[editorId], optionId };
    };

    reviewEditorOn = () => {
        // For scrolling Question Editor together with Review Popup when Review Popup is partially
        // out of bottom part of window.
        document.body.style.setProperty("--dimmerSpace", document.documentElement.scrollHeight + "px");
        this.dimmer.classList.add(styles.withReviewPopup);
    };

    reviewEditorOff = () => {
        this.dimmer.classList.remove(styles.withReviewPopup);
    };

    componentDidMount = () => {
        const sharedToolBar = new SharedToolbar();
        sharedToolBar.mount(this.toolbarRef.current);
        document.body.classList.add(PROBLEM_EDITOR_OPEN_CLASS);
        this.bodyObserver = observeBodyForClassName("review-editor-opened", this.reviewEditorOn, this.reviewEditorOff);
        this.setState({ sharedToolBar });
    };

    componentWillUnmount = () => {
        document.body.classList.remove(PROBLEM_EDITOR_OPEN_CLASS);
        document.body.style.removeProperty("--dimmerSpace");
        this.state.sharedToolBar && this.state.sharedToolBar.unmount();
        this.bodyObserver.disconnect();
    };

    updateDom = () => {
        const dimmer = getDimmer();
        dimmer.classList.add(styles.questionEditorModal);
        this.dimmer = dimmer;
    };

    handleTagChange = (e, { value }) => {
        const { question } = this.state;
        this.setState({ question: { ...question, tags: value }, errorMessage: null });
    };

    handleTagAdd = (e, { value }) => {
        const { tagOptions } = this.state;
        let tags = [...tagOptions, { key: value, value, text: value }];
        this.setState({ tagOptions: tags, errorMessage: null });
    };

    handleSave = (e, data) => {
        const { question } = this.state;
        const { onAdd, onEdit, editMode, onClose, fbCollection } = this.props;
        const stamp = Date.now();

        if (!Object.keys(question.tags || {}).length && !Object.keys(question.topics || {}).length) {
            // no topics or tags
            this.setState({ errorMessage: "Assign at least one tag or topic to the problem." });
            return;
        } else {
            this.setState({ errorMessage: null });
        }

        const questionRecord = { ...question };
        delete questionRecord.lock;
        Object.keys(this.getEditorContentFns).forEach(id => {
            const { raw, html } = this.getEditorContentFns[id].fn();
            const linked_outlines = getLinkedOutlinesFromLessonLinks(raw, true);
            const reviewStats = getReviewsStats(raw);

            switch (id) {
                case "question":
                    questionRecord["question"] = Object.assign({}, questionRecord.question, {
                        raw,
                        content: html,
                        linked_outlines,
                        reviewStats,
                    });
                    break;
                case "note":
                    questionRecord["note"] = Object.assign({}, questionRecord.note, { raw, content: html });
                    break;
                case "answer":
                    if (questionRecord.problemType === "oa") {
                        questionRecord["answer"] = Object.assign({}, questionRecord.answer, {
                            raw,
                            content: html,
                            linked_outlines,
                            reviewStats,
                        });
                    }
                    break;
                default:
                    if (questionRecord.problemType === "mc" || questionRecord.problemType === "mx") {
                        questionRecord["answer"] = questionRecord.answer.map(o => {
                            if (o.option == this.getEditorContentFns[id].optionId)
                                return Object.assign({}, o, { raw, content: html, reviewStats });
                            return o;
                        });
                    } else if (questionRecord.problemType === "ma") {
                        questionRecord["answer"] = questionRecord.answer.map((o, i) => {
                            const ids = this.getEditorContentFns[id].optionId.split("-");

                            if (ids[0] == "answer" && ids[1] == i)
                                return Object.assign({}, o, { raw, content: html, reviewStats });
                            else if (ids[0] == "match" && ids[1] == i) {
                                return Object.assign({}, o, { matchRaw: raw, matchContent: html, reviewStats });
                            }

                            return o;
                        });
                    }
                    break;
            }
        });

        const validationError = validateQuestionSave(questionRecord) || validateAnswerSave(questionRecord);
        if (validationError) {
            this.setState({ message: validationError });
            return;
        }

        if (editMode == true) {
            delete questionRecord["author"];
            questionRecord["updatedDate"] = stamp;
            onEdit && onEdit(correctQuestionTypeField(questionRecord, fbCollection));
        } else {
            questionRecord["createdDate"] = stamp;
            onAdd && onAdd(correctQuestionTypeField(questionRecord, fbCollection));
        }

        if (e && data && data.children == "Save") {
            onClose && onClose();
        } else if (!e && data.callback) {
            this.setState({ question: questionRecord, hasDirtyEditor: false }, data.callback());
        }
    };

    handleCancel = () => {
        const { onClose } = this.props;
        this.setState({ errorMessage: null });
        onClose && onClose();
    };

    handleProblemTypeChange = (e, data) => {
        const { question } = this.state;
        const currentType = question && (question.problemType || question.type);
        if (currentType === data.value) {
            return;
        }

        if (
            (data.value == "mc" || data.value == "mx") &&
            (currentType == "mc" || currentType == "mx")
        ) {
            // Don't reset editor if change type but still using multiple editors
            this.setState({ question: { ...question, [data.name]: data.value } });
        } else {
            // Otherwise unregister the answer content editors
            Object.keys(this.getEditorContentFns).forEach(id => {
                if (id === "question" || id === "note") return;
                this.unregisterGetContentFn(id);
            });
            // ...and erase the answer definition
            this.setState({ question: { ...question, [data.name]: data.value, answer: undefined } });
        }
    };

    handleChange = (e, { name, value }) => {
        const { question } = this.state;
        this.setState({ question: { ...question, [name]: value } });
    }

    handleTopicChange = topics => {
        const { question } = this.state;
        this.setState({
            question: { ...question, topics: {...topics} },
            errorMessage: null
        });

    };

    handleEditorChange = (rawContent, field) => {
        if (!rawContent || !rawContent.entityMap) return;
        const { question } = this.state;
        this.setState({ question: { ...question, [field]: { ...question[field], raw: rawContent } } });
    };

    getInitialContentState = element => {
        if (element === undefined || element.raw === undefined) return undefined;
        return element.raw;
    };

    handleTrueFalseToggle = value => {
        const { question } = this.state;
        this.setState({ question: { ...question, answer: { content: value } } });
    };

    handleUpdateAnswer = answer => {
        const { question } = this.state;
        this.setState({ question: { ...question, answer } });
    };

    getAnswerTemplate = problemType => {
        const { question, reviewMode, sharedToolBar } = this.state;
        const { fbCollection } = this.props;
        const answerData = (question && question.answer) || {};

        switch (problemType) {
            case "oa":
                return (
                    <QuestionItemEditor
                        key={"answer" + reviewMode.toString()}
                        editorId="answer"
                        initialContent={answerData.raw}
                        initialHtml={answerData.content}
                        sharedBar={sharedToolBar}
                        focusOnMount={false}
                        onSetRef={this.handleSetEditorRef}
                        regContentFn={this.registerGetContentFn}
                        reviewMode={reviewMode}
                        onDirty={this.handleOnDirtyEditor}
                    />
                );
            case "tf":
                return <TrueFalseChoice answer={answerData.content} onChange={this.handleTrueFalseToggle} />;
            case "mc":
            case "mx":
                return (
                    <MultipleChoiceAnswer
                        sharedBar={sharedToolBar}
                        answerOptions={question.answer}
                        onUpdateAnswer={this.handleUpdateAnswer}
                        regContentFn={this.registerGetContentFn}
                        unregContentFn={this.unregisterGetContentFn}
                        updateOptionEditor={this.updateGetContentFn}
                        multipleCorrectAnswers={problemType === "mx"}
                        onDirty={this.handleOnDirtyEditor}
                        mc4Mode={fbCollection !== "problemSet"}
                    />
                );
            case "ma":
                return (
                    <MatchingChoiceAnswer
                        sharedBar={sharedToolBar}
                        answer={question.answer}
                        onSetRef={this.handleSetEditorRef}
                        regContentFn={this.registerGetContentFn}
                        unregContentFn={this.unregisterGetContentFn}
                        onUpdateAnswer={this.handleUpdateAnswer}
                        updateOptionEditor={this.updateGetContentFn}
                        onDirty={this.handleOnDirtyEditor}
                    />
                );
            default:
                break;
        }
    };

    generatePanes = memoizeOne((question, problemType, tags, topics, reviewMode, tagOptions) => {
        const questionData = (question && question.question) || {};
        const noteData = (question && question.note) || {};
        const { fbCollection } = this.props;
        return [
            {
                menuItem: "Q & A",
                pane: (
                    <Tab.Pane key="qnaPane">
                        <Divider horizontal>
                            <Header as="h4">Question</Header>
                        </Divider>
                        <QuestionItemEditor
                            key={"question" + reviewMode.toString()}
                            editorId="question"
                            initialContent={questionData.raw}
                            initialHtml={questionData.content}
                            sharedBar={this.state.sharedToolBar}
                            focusOnMount={true}
                            onSetRef={this.handleSetEditorRef}
                            regContentFn={this.registerGetContentFn}
                            reviewMode={reviewMode}
                            onDirty={this.handleOnDirtyEditor}
                        />
                        {problemType !== "pa" && (
                            <Divider horizontal>
                                <Header as="h4">Answer</Header>
                            </Divider>
                        )}
                        {this.getAnswerTemplate(problemType)}
                        {problemType !== "pa" && (
                            <React.Fragment>
                                <Divider hidden={problemType !== "oa"} />
                                <Form>
                                    <Form.Input
                                        width="4"
                                        label="Extra Lines For Answer Print"
                                        name="extra_lines"
                                        placeholder="Fill extra lines for answer print"
                                        type="number"
                                        value={question.extra_lines}
                                        onChange={this.handleChange}
                                        disabled={this.props.reviewModeOnly}
                                    />
                                </Form>
                            </React.Fragment>
                        )}
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "Notes",
                pane: (
                    <Tab.Pane key="notesPane">
                        <QuestionItemEditor
                            editorId="note"
                            initialContent={noteData.raw}
                            initialHtml={noteData.content}
                            sharedBar={this.state.sharedToolBar}
                            focusOnMount={false}
                            onSetRef={this.handleSetEditorRef}
                            regContentFn={this.registerGetContentFn}
                        />
                        <br />
                        {!!question.id && <Comments threadId={question.id} /> }
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "Tags & Topics",
                pane: (
                    <Tab.Pane key="settingsPane">
                        <Form>
                            <Form.Field>
                                <label>Tags</label>
                                <Dropdown
                                    placeholder="Select a tag"
                                    fluid
                                    multiple
                                    search
                                    selection
                                    options={tagOptions || []}
                                    loading={tagOptions === undefined}
                                    onChange={this.handleTagChange}
                                    onAddItem={this.handleTagAdd}
                                    value={question.tags}
                                    allowAdditions
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Topics</label>
                                <TopicTreeSelector
                                    onChange={this.handleTopicChange}
                                    selectedTopics={topics || {}}
                                    enableLessonFilter
                                />
                            </Form.Field>
                        </Form>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "Usage",
                pane: (
                    <Tab.Pane key="usagePane">
                        <div>
                            <h5>{"problemSet" === fbCollection ? "Problem Sets" : "Comprehensive Tests"}</h5>
                            {question.problemSets &&
                                Object.keys(question.problemSets).map(id => {
                                    return <ProblemSetInfo key={id} setId={id} fbCollection={fbCollection}/>;
                                })}
                            {!question.problemSets || Object.keys(question.problemSets).length == 0 ? (
                                <NoDataPlaceholder
                                    style={{ minHeight: "150px", height: "150px" }}
                                    icon="file outline"
                                    text="No problem sets"
                                >
                                    <p>This problem has not been added to any problem sets yet.</p>
                                </NoDataPlaceholder>
                            ) : (
                                <div />
                            )}
                        </div>
                    </Tab.Pane>
                ),
            },
        ];
    });

    handleTabChange = (e, { activeIndex }) =>
        this.setState({ activeIndex }, () => {
            const { editorRefs } = this.state;
            if (activeIndex === 0) {
                editorRefs["question"].setFocus();
            } else if (activeIndex === 1) {
                editorRefs["note"].setFocus();
            }
        });

    handleSetEditorRef = (editorId, optionId, editorRef) => {
        const { editorRefs } = this.state;
        editorRefs[editorId] = editorRef;
        this.setState({ editorRefs });
    };

    handleReviewToggle = () => {
        const { hasDirtyEditor, reviewMode } = this.state;
        if (hasDirtyEditor && reviewMode == false) {
            if (confirm("You must save changes before entering review mode, would you like to save now?")) {
                this.handleSave(null, { callback: () => this.setState({ reviewMode: !reviewMode }) });
            }
        } else {
            this.setState({ reviewMode: !reviewMode });
        }
    };

    handleOnDirtyEditor = () => this.setState({ hasDirtyEditor: true });

    handleShowTopicSelection = () => this.setState({ displayTopicSelection: true });

    render() {
        const {
            question,
            message,
            sharedToolBar,
            activeIndex,
            reviewMode,
            errorMessage,
            tagOptions,
        } = this.state;
        const { forbidParentQuestion = false, isUserReviewer, editMode, reviewModeOnly, fbCollection } = this.props;

        let typeOptions = forbidParentQuestion || question.parentProblemId || (editMode && question.problemType !== "pa")
            ? problemTypes.filter(pt => pt.key !== "pa")
            : problemTypes;
        // Filter out other problem types for Comprehension tests
        if ("problemSet" !== fbCollection) {
            typeOptions = typeOptions.filter(pt => "pa" === pt.key || "mc" === pt.key);
        }

        return (
            <Modal open={true} className={styles.questionEditorModal} centered={false} ref={this.updateDom}>
                <Modal.Header>{question.parentProblemId ? "Child " : null}Question Editor</Modal.Header>
                <Modal.Content className={cx("modalEditorToolbar", { hideMe: activeIndex > 1 })}>
                    <div className="widgetEditorToolbar" ref={this.toolbarRef} />
                </Modal.Content>
                <Modal.Content scrolling={true}>
                    <LockableBlock
                        lockType={{ lockType: "problemSet" === fbCollection ? LOCK_TYPE.PROBLEM : LOCK_TYPE.COMP_TEST_CVAULT, itemId: question.id }}
                        hideLockButton={true}
                    >
                        {sharedToolBar && (
                            <React.Fragment>
                                <Select
                                    disabled={editMode && (
                                        question.problemType === "pa" || !(typeOptions && typeOptions.length > 1)
                                    )}
                                    className={styles.typeSelect}
                                    name="problemType"
                                    placeholder="Problem type"
                                    options={typeOptions}
                                    value={question.problemType}
                                    onChange={this.handleProblemTypeChange}
                                />
                                {question.id && activeIndex == 0 && isUserReviewer ? (
                                    <Button
                                        disabled={reviewModeOnly}
                                        style={{ marginTop: "5px" }}
                                        floated="right"
                                        onClick={this.handleReviewToggle}
                                    >
                                        {reviewMode ? "Compose" : "Review"}
                                    </Button>
                                ) : null}
                                {reviewMode == true && activeIndex == 0 && (
                                    <div className="editorStatusBar" style={{ marginTop: "5px" }}>
                                        You are in a Review mode. Use Review icon in toolbar to make changes to selected
                                        text.
                                    </div>
                                )}
                                {message != null && (
                                    <Message warning>
                                        <Message.Header>Invalid</Message.Header>
                                        <p>{message}</p>
                                    </Message>
                                )}
                                <Tab
                                    className={styles.questionTabs}
                                    renderActiveOnly={false}
                                    onTabChange={this.handleTabChange}
                                    panes={this.generatePanes(
                                        //this.props.question,
                                        question,
                                        question.problemType,
                                        question.tags,
                                        question.topics,
                                        reviewMode,
                                        tagOptions,
                                    )}
                                />
                            </React.Fragment>
                        )}
                        {errorMessage && <Message error content={errorMessage} />}
                    </LockableBlock>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons data="close" onSave={this.handleSave} onCancel={this.handleCancel} />
                </Modal.Actions>
            </Modal>
        );
    }
}

QuestionEditor.defaultProps = {
    question: {
        question: {
            content: "",
        },
        answer: {
            content: "",
        },
        note: {
            content: "",
        },
        tags: [],
        topics: {},
        problemType: "oa",
        status: "New",
        extra_lines: 0,
    },
    reviewModeOnly: false,
};

export default QuestionEditor;
