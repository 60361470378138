import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onSaved } from "./ResourceTagsEditorActions";
import { toastError, DB_ERROR } from "../../../../component/toast";
import { updateResource } from "../../ResourcesApi";
import { onPatchResourceData } from "../../ResourcesActions";

function* saveTags ({ payload: { resourceId, tags} }) {
    try {
        const resource = yield updateResource(resourceId, { tags });
        yield put(onPatchResourceData({resourceId, newData: resource.data, fieldsToUpdate: ["tags"]}));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update resource tags", message: DB_ERROR });
    }
    yield put(onSaved());
}


export default function* resourceTagEditor() {
    yield all([
        takeLatest(TYPES.RESOURCE_TAG_EDITOR_SAVE, saveTags),
    ]);
}
