
// Tags could be accidentally set as or nested arrays, we need to stringify them.
const convertTagName = (tag) => (typeof tag === "string" ? tag : (tag && tag.toString()));

export const getProblemTags = ({ tags }) => (
    // Problem tags are stored in FB { 0: "Alpha", 1: "Omega"}.
    // They should be de-serialized as an array. Otherwise we use fallback to Object.values().
    (Array.isArray(tags) ? tags : Object.values(tags || {})).map(convertTagName)
);

export const getProblemsTags = (problems) => {
    const tags = problems && Object.values(problems).flatMap(getProblemTags);
    if (tags && tags.length) {
        return [ ...new Set(tags)].sort();
    }
    return null;
};

const getProblemTopics = ({ topics }) => topics ? Object.keys(topics) : [];
export const getProblemsTopics = (problems) => {
    const topics = problems && Object.values(problems).flatMap(getProblemTopics);
    if (topics && topics.length) {
        return [ ...new Set(topics)].sort();
    }
    return null;
};
