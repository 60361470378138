import { all, takeLatest, select, put } from "redux-saga/effects";
import { TYPES, onAllCourseVariantsSet, onSetSyncStatus } from "./AKitEditorActions";
import firebase from "../../../firebase";
import { loggedUserId } from "../../../Users/UserProfile/UserProfileSelectors";
import { toastError, DB_ERROR } from "../../../component/toast";
import { getSelectedYearId } from "../../../Year/YearsSelectors";
import { mapCollectionToList } from "../../../utils/selectorUtils";

export function* aKitSync( { payload: { aKitId, enableSyncForCvs } } ) {
    try {
        const author = yield select(loggedUserId);
        let started_by = (yield firebase.getFirebaseData(`user/${author}/email`).once("value")).val();
        const syncCvRef = firebase.getFirebaseData("sync_cv");
        const created = new Date().toISOString();
        for (const item of Object.keys(enableSyncForCvs)) {
            yield syncCvRef.push({ ...enableSyncForCvs[item], kit_id: aKitId, sync_status: "QUEUED", author, created, started_by });
        }
    } catch (error) {
        toastError({ code: error.code, header: "Failed to sync author kit  outline to BeLA", message: DB_ERROR });
    }
}


function* loadAllCVList() {
    let selectedYearId = yield select(getSelectedYearId);
    try {
        const yearsList = yield firebase.tabletProxy({
            method: "GET",
            uri: "/year",
            cancelRestrictToTenant: true,
        });
        const selectedYear = yearsList.data.find(year => year.id === selectedYearId);
        let yearSelector = "";
        yearsList.data.map(y => {
            if (y.name === selectedYear.name) {
                yearSelector +="&year_id[$in][]=" + y.id;
            }
        });
        let courseVariants = yield firebase.tabletProxy({
            method: "GET",
            uri: `/course-variant?intensity=Spork${yearSelector}&$sort[name]=1`,
            cancelRestrictToTenant: true
        });
        yield put(onAllCourseVariantsSet(courseVariants.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of course list failed.", message: err.message });
        yield put(onAllCourseVariantsSet([]));
    }
}

function* getSyncStatus({ payload }) {
    let selectedYearId = yield select(getSelectedYearId);
    try {
        const tenants = yield firebase.tabletProxy({
            method: "GET",
            uri: "/tenant",
            cancelRestrictToTenant: true,
        });
        const tenantsById = {};
        tenants.data.map(t => tenantsById[t.id] = t.name);
        const yearsList = yield firebase.tabletProxy({
            method: "GET",
            uri: "/year",
            cancelRestrictToTenant: true,
        });
        const selectedYear = yearsList.data.find(year => year.id === selectedYearId);
        let sameYears = {};
        yearsList.data.map(y => {
            if (y.name === selectedYear.name) {
                sameYears[y.id] = y.name;
            }
        });
        let syncData = (yield firebase.getFirebaseData("sync_cv").orderByChild("kit_id").equalTo(payload).once("value")).val();
        if (syncData) {
            let filteredSyncRecords = mapCollectionToList(syncData).filter(item => {
                item["tenant"] = tenantsById[item.tenant_id];
                return !!sameYears[item.year_id];
            });
            if (0 < filteredSyncRecords.length) {
                filteredSyncRecords = filteredSyncRecords.sort((a, b) => {
                    const av = (a.updated || a.created).toLocaleLowerCase();
                    const bv = (b.updated || b.created).toLocaleLowerCase();
                    return av === bv ? 0 : (av < bv ? -1 : 1);
                });
                yield put(onSetSyncStatus(filteredSyncRecords.reverse()));
                return;
            }
        }
        yield put(onSetSyncStatus(null));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to get sync status.", message: err.message });
        yield put(onSetSyncStatus(null));
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.EDIT_AKIT_SYNC, aKitSync),
        takeLatest(TYPES.EDIT_AKIT_GET_ALL_COURSE_VARIANTS, loadAllCVList),
        takeLatest(TYPES.EDIT_AKIT_GET_SYNC_STATUS, getSyncStatus),
    ]);
}