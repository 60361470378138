import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, getVal } from "react-redux-firebase";

import { VersionNumber, BuildNumber, Env } from "../Version";
import { toastSuccess } from "./toast";

class VersionCheck extends React.PureComponent {
    static propTypes = {
        serverVersion: PropTypes.string,
    };

    handleReload = () => location.reload();

    notify = () => {
        const { serverVersion } = this.props;
        const clientVersion = `${VersionNumber}.${BuildNumber}`;
        if ("dev" !== Env && serverVersion && clientVersion !== serverVersion) {
            toastSuccess({
                header: "New version available",
                icon: "info circle thin",
                message: (
                    <React.Fragment>
                        There is a new SPORK version {serverVersion} available. Please{" "}
                        <a onClick={this.handleReload}>reload</a> your browser.
                    </React.Fragment>
                ),
                options: { autoClose: false },
            });
        }
    };

    componentDidMount() {
        this.notify();
    }

    componentDidUpdate() {
        this.notify();
    }

    render() {
        return null;
    }
}

const VersionCheckContainer = compose(
    firebaseConnect(["config"]),
    connect(state => ({
        serverVersion: getVal(state.firebase, "data/config/version"),
    }))
)(VersionCheck);

VersionCheckContainer.displayName = "VersionCheckContainer";
export default VersionCheckContainer;
