import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Menu, Input } from "semantic-ui-react";
import "./FilterMenu.scss";

export default class FilterMenu extends PureComponent {
    static propTypes = {
        entityName: PropTypes.string,
        count: PropTypes.number,
        onChange: PropTypes.func,
        filterName: PropTypes.string,
        children: PropTypes.node,
        className: PropTypes.string,
    };

    render() {
        const { entityName, count, onChange, filterName, children, className } = this.props;
        return (
            <Menu borderless secondary stackable className={`filterMenuBackground ${className || ""}`}>
                <Menu.Item fitted>
                    <Input
                        icon="search"
                        placeholder="Search..."
                        className="search"
                        onChange={onChange}
                        value={filterName}
                    />
                </Menu.Item>
                {children}
                {count != null && entityName && (
                    <Menu.Item position="right" fitted>
                        <span><b>{count}</b> <span style={{color: "grey"}}>{entityName}
                            {(0 == count || 1 < count) && "s"}
                        </span></span>
                    </Menu.Item>
                )}
            </Menu>
        );
    }
}
