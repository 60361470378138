const TYPES = {
    SYLLABUS_OUTLINES_RESOURCE_LINK_LOAD_RESOURCE: "SYLLABUS_OUTLINES_RESOURCE_LINK_LOAD_RESOURCE",
    SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCE: "SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCE",
    SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCES: "SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCES",
    SYLLABUS_OUTLINES_RESOURCE_CREATE_RESOURCE: "SYLLABUS_OUTLINES_RESOURCE_CREATE_RESOURCE",
    SYLLABUS_OUTLINES_RESOURCE_RESOURCE_CREATED: "SYLLABUS_OUTLINES_RESOURCE_RESOURCE_CREATED",
    SYLLABUS_OUTLINES_RESOURCE_SEARCH_RESOURCE: "SYLLABUS_OUTLINES_RESOURCE_SEARCH_RESOURCE",
    SYLLABUS_OUTLINES_RESOURCE_SET_SEARCH_RESULT: "SYLLABUS_OUTLINES_RESOURCE_SET_SEARCH_RESULT",
    SYLLABUS_OUTLINES_RESOURCE_LOAD_TAGS: "SYLLABUS_OUTLINES_RESOURCE_LOAD_TAGS",
    SYLLABUS_OUTLINES_RESOURCE_SET_TAGS: "SYLLABUS_OUTLINES_RESOURCE_SET_TAGS",
    SYLLABUS_OUTLINES_RESOURCE_ADD_TAG: "SYLLABUS_OUTLINES_RESOURCE_ADD_TAG",
};

const onLoadResource = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_LINK_LOAD_RESOURCE,
    payload
});

const onSetResource = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCE,
    payload
});

const onSetResources = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_LINK_SET_RESOURCES,
    payload
});

const onCreateResource = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_CREATE_RESOURCE,
    payload
});

const onRescourseCreated = () => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_RESOURCE_CREATED
});

const onSearchResource = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_SEARCH_RESOURCE,
    payload
});

const onSetSearchResult = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_SET_SEARCH_RESULT,
    payload
});

const onLoadTags = () => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_LOAD_TAGS
});

const onSetTags = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_SET_TAGS,
    payload
});

const onAddTag = (payload) => ({
    type: TYPES.SYLLABUS_OUTLINES_RESOURCE_ADD_TAG,
    payload
});

export {
    TYPES,
    onLoadResource,
    onSetResource,
    onSetResources,
    onRescourseCreated,
    onCreateResource,
    onSearchResource,
    onSetSearchResult,
    onLoadTags,
    onSetTags,
    onAddTag,
};
