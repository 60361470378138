import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider, Button, Menu } from "semantic-ui-react";
import WidgetList from "./WidgetList";
import PageHeader from "../../component/PageHeader";
import CreateWidgetDialog from "./CreateWidgetDialog";
import AdvancedFilter from "../component/AdvancedFilter";
import FilteredByOptionsView from "../component/FilteredByOptionsView";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import WidgetDetailContainer from "./WidgetDetailContainer";

export default class WidgetLibrary extends React.Component {
    static propTypes = {
        onSelectWidget: PropTypes.func,
        widgetsInfo: PropTypes.object,
        onCreate: PropTypes.func,
        unusedFilter: PropTypes.bool,
        onDeleteWidget: PropTypes.func,
        searchFilter: PropTypes.string,
        isAuthorAtLeast: PropTypes.bool,
        widgets: PropTypes.array,
        selectedTags: PropTypes.array,
        onSelectTag: PropTypes.func,
        onChangeUnusedFilter: PropTypes.func,
        usedTags: PropTypes.array,
        onChangeFilter: PropTypes.func,
        selectedWidgetId: PropTypes.string,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
        widgetLocks: PropTypes.object,
        selectedTopic: PropTypes.string,
        onSelectTopic: PropTypes.func,
        usedTopics: PropTypes.object,
        topicFilterName: PropTypes.string,
        onSetModuleFilter: PropTypes.func,
        moduleFilter: PropTypes.array,
        onSetTemplateModuleFilter: PropTypes.func,
    };

    state = {
        removeId: null,
        removeContext: null,
    };

    handleRemove = (e, id) => {
        const { removeId } = this.state;
        if (!removeId) {
            this.setState({
                removeContext: e.currentTarget,
                removeId: id
            });
        }
    };

    componentDidMount() {
        this.props.onSetModuleFilter([]);
        this.props.onSetTemplateModuleFilter([]);
    }

    handleCancelRemove = () => {
        this.setState({ removeId: null, removeContext: null });
    };

    handleRemoveConfirm = () => {
        const { onDeleteWidget } = this.props;
        const { removeId } = this.state;
        if (onDeleteWidget && removeId) {
            onDeleteWidget({ key: removeId });
        }
        this.handleCancelRemove();
    };

    handleAddWidget = (payload) => {
        const { onCreate } = this.props;
        onCreate({...payload, openWidget: true });
    }

    render() {
        const {
            widgets,
            selectedTags,
            unusedFilter,
            widgetsInfo,
            searchFilter,
            isAuthorAtLeast,
            onSelectTag,
            onChangeUnusedFilter,
            usedTags,
            onSelectWidget,
            onChangeFilter,
            selectedWidgetId,
            isDirty,
            onSetDirty,
            widgetLocks,
            onSelectTopic,
            selectedTopic,
            usedTopics,
            topicFilterName,
            onSetModuleFilter,
            moduleFilter,
        } = this.props;
        const { removeContext, removeId } = this.state;
        let columnsCount = 1;
        let firstColumnWidth = 16;
        let simple = false;
        if (selectedWidgetId) {
            columnsCount = 2;
            firstColumnWidth = 4;
            simple = true;
        }
        let isEmpty = (searchFilter === "" && !unusedFilter && (!widgets || 0 == widgets.length));
        return (
            <React.Fragment>
                {isAuthorAtLeast && <ConfirmDeleteWithContext
                    context={removeContext}
                    confirmText="Remove widget"
                    onConfirm={this.handleRemoveConfirm}
                    whatText={`widget ${widgetsInfo && widgetsInfo[removeId] && widgetsInfo[removeId].name}`}
                    onCancel={this.handleCancelRemove}
                />}
                <PageHeader
                    list={widgets}
                    headerText="Widgets"
                    entityName="widget"
                    onChangeFilter={onChangeFilter}
                    searchFilter={searchFilter}
                >
                    <AdvancedFilter
                        usedTags={usedTags}
                        entityName="widget"
                        selectedTags={selectedTags}
                        unusedFilter={unusedFilter}
                        onSelectTag={onSelectTag}
                        onChangeUnusedFilter={onChangeUnusedFilter}
                        selectedTopic={selectedTopic}
                        onSelectTopic={onSelectTopic}
                        usedTopics={usedTopics}
                        onSetModuleFilter={onSetModuleFilter}
                        moduleFilter={moduleFilter}
                    />
                    {!isEmpty && isAuthorAtLeast && <CreateWidgetDialog 
                        onCreate={this.handleAddWidget} 
                        trigger={<Menu.Item
                            icon="plus"
                            content="New"
                            title={!isDirty ? "Click here to add a new widget based on selected template." 
                                : "Adding new widget is not allowed, if you are in the middle of editation."}
                            disabled={isDirty}
                        />}
                    />}
                </PageHeader>
                {(selectedTags && 0 < selectedTags.length || unusedFilter || topicFilterName || (moduleFilter && 0 < moduleFilter.length)) && 
                    <FilteredByOptionsView
                        selectedTags={selectedTags}
                        unusedFilter={unusedFilter}
                        onSelectTag={onSelectTag}
                        onChangeUnusedFilter={onChangeUnusedFilter}
                        topicFilterName={topicFilterName}
                        onSelectTopic={onSelectTopic}
                        onSetModuleFilter={onSetModuleFilter}
                        moduleFilter={moduleFilter}
                    />
                }
                <Divider clearing fitted hidden />
                <Grid columns={columnsCount} divided style={{marginTop: 0}}>
                    <Grid.Column width={firstColumnWidth} style={{height: "75vh"}}>
                        { isEmpty ?
                            <NoDataPlaceholder text="Here we list the widgets in your library." icon="newspaper outline">
                                <p>The library contains Widget components that can be used for building the content of the lesson. Each Widget is created using a template defining its type and structure of the Widget.</p>
                                {isAuthorAtLeast && <CreateWidgetDialog onCreate={this.handleAddWidget} trigger={<Button primary content="Add widget"/>} />}
                            </NoDataPlaceholder>
                            : (widgets.length == 0 ? 
                                <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
                                    <p>We cannot find the widget matching your filtering criteria. </p>
                                </NoDataPlaceholder>
                                : (
                                    <WidgetList
                                        simple={simple}
                                        onSelectWidget={onSelectWidget}
                                        onDelete={this.handleRemove}
                                        widgets={widgets}
                                        selectedWidgetId={selectedWidgetId}
                                        widgetsInfo={widgetsInfo}
                                        isAuthorAtLeast={isAuthorAtLeast}
                                        isDirty={isDirty}
                                        onSetDirty={onSetDirty}
                                        widgetLocks={widgetLocks}
                                    />
                                )
                            )
                        }
                    </Grid.Column>
                    {simple && <Grid.Column width={12} style={{position: "inherit"}} >
                        <WidgetDetailContainer widgetId={selectedWidgetId}/>
                    </Grid.Column>}
                </Grid>
            </React.Fragment>
        );
    }
}
