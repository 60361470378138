import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, TextArea } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";

export default class EditCommentBox extends PureComponent {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        comment: PropTypes.object,
    };

    state = {
        value: this.props.comment.comment
    };

    handleChange = (e, { value }) => {
        this.setState({ value });
    };

    handleSave = () => {
        const { comment } = this.props;
        const { value } = this.state;
        this.props.onSave({...comment, comment: value});
    };

    render() {
        const { onCancel } = this.props;
        const { value } = this.state;

        return (
            <React.Fragment>
                <Form reply>
                    <TextArea 
                        autoFocus 
                        autoHeight 
                        value={value}
                        rows={1} 
                        onChange={this.handleChange} 
                    />
                </Form>
                <SaveCancelButtons
                    size="small"
                    saveDisabled={!value}
                    padded
                    submitText="Save"
                    onSave={this.handleSave}
                    onCancel={onCancel}
                />
            </React.Fragment>
        );
    }
}
