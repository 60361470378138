import React from "react";
import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";
import { DragDropContext } from "react-beautiful-dnd";

import ProblemDroppable from "./ProblemDroppable";
import ProblemDraggable from "./ProblemDraggable";
import styles from "../ProblemSetCreator.module.scss";

const getProblemNameFactory = () => {
    let nr = 1;
    return (problem) => {
        if (problem.children) {
            const children = Object.keys(problem.children).length || 0;
            if (children > 1) {
                return `#${nr}...${(nr += children) - 1}`;
            }
        }
        return `#${nr++}`;
    };
};

class ProblemSetReorder extends React.PureComponent {
    static propTypes = {
        problemSet: PropTypes.object.isRequired,
        problemData: PropTypes.object.isRequired,
        moveProblem: PropTypes.func.isRequired,
    };

    handleDragEnd = (e) => {
        if (e.reason !== "DROP") {
            return;
        }

        const { draggableId, destination, source } = e;
        if (destination === null || destination === source) {
            return; // drop canceled or no change
        }
        if (destination.droppableId !== source.droppableId) {
            return; // drop target change is not supported now
        }
        if (destination.index === source.index) {
            return; // position has not changed
        }

        const { moveProblem, problemSet } = this.props;
        moveProblem({
            activeProblemSet: problemSet,
            problemId: draggableId,
            position: source.index,
            destination: String(destination.index), // moveProblem saga expects string here
        });
    };

    render() {
        const { problemSet, problemData } = this.props;
        const problemIds = problemSet && problemSet.problems;
        if (!problemIds) {
            return null;
        }

        const getProblemName = getProblemNameFactory();
        return (
            <Container className={styles.reorderZone}>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <ProblemDroppable targetId={problemSet.id}>
                        {problemIds.map((problemId, index) => {
                            const problem = problemData && problemData[problemId];
                            return problem && (
                                <ProblemDraggable key={problemId}
                                    problemId={problemId}
                                    problemName={getProblemName(problem)}
                                    problemHtml={problem && problem.question && problem.question.content || ""}
                                    position={index}
                                />
                            );
                        }).filter(Boolean)}
                    </ProblemDroppable>
                </DragDropContext>
            </Container>
        );
    }
}

export default ProblemSetReorder;