import { all, takeEvery } from "redux-saga/effects";
import { TYPES } from "./SidebarMenuActions";

function* menuClick({ payload }) {
    yield localStorage.setItem("sidebarMenuActiveIndex", JSON.stringify(payload));
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.SIDEBAR_MENU_CLICK, menuClick),
    ]);
}