import PropTypes from "prop-types";

const isNotUsedBy = (usage, problem) => (
    !Object.prototype.hasOwnProperty.call(problem, usage) || Object.keys(problem[usage]).length === 0
);

export const isProblemUnused = (problem) => (
    isNotUsedBy("problemSets", problem) && isNotUsedBy("widgets", problem)
);

export const getProblemAuthorId = (problem) => {
    const author = problem && problem.author;
    if (typeof author === "string") {
        return author || null;
    }
    return author && author.id || null;
};

export const isProblemInSet = (problem, problemSetId) => (
    problemSetId && problem && problem.problemSets && problem.problemSets[problemSetId]
);

const AccessRights = {
    problemSet: {
        create: [ "problemSet:manage", "problemSet:create" ],
        modify: [ "problemSet:manage", "problemSet:edit" ],
        review: [ "problemSet:view", "problemSet:review" ],
        withSelected: [ "problemSet:manage", "problemSet:author" ],
    },
    comptests_common: {
        create: [ "compTests:manage" ],
        modify: [ "compTests:manage", "compTests:edit" ],
        review: [ "compTests:view", "compTests:review" ],
        withSelected: [ "compTests:manage" ],
    }
};

export const getAccessRights = (fbCollection) => {
    const rights = AccessRights[fbCollection];
    if (rights) {
        return rights;
    }
    // eslint-disable-next-line no-console
    console.error(`Unsupported collection "${fbCollection}" in problemManagement/getAccessRights.`);
};

export const ProblemPropTypes = {
    id: PropTypes.string.isRequired,
    lock: PropTypes.object,
    author: PropTypes.shape({
        id: PropTypes.string,
    }),
};

export const getProblemEntityName = (fbCollection) => (
    fbCollection === "problemSet" ? "problem" : "question"
);

export const getProblemParentName = (fbCollection) => (
    fbCollection === "problemSet" ? "problem set" : "comprehensive test"
);
