import { TYPES } from "./SyllabiListActions";

const initialState = {
    searchFilter: "",
    schoolFilter: "",
    teacherFilter: "",
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.SYLLABI_LIST_SET_FILTER:
            return { ...state, searchFilter: payload };
        case TYPES.SYLLABI_LIST_SET_SCHOOL_FILTER:
            return { ...state, schoolFilter: payload };
        case TYPES.SYLLABI_LIST_SET_TEACHER_FILTER:
            return { ...state, teacherFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
