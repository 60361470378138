import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";
import cx from "classnames";

import { REVIEW_TYPE } from "../../component/seamlessEditor/bookEditor/constants";

import styles from "./ReviewsCount.module.scss";

const ReviewsCount = ({ reviews, comments, floating, keepInLine,  marginLeft }) => {

    if (!reviews && !comments) {
        return null;
    }

    return (
        <Label
            floating={!!floating}
            className={cx(styles.reviewsCount, {
                [styles.narrow]: reviews && comments,
                [styles.hasReviews]: reviews,
                [styles.keepInLine]: keepInLine,
                [styles.marginLeft]: marginLeft,
            })}
            title={reviews ? (comments ? "Reviews required / comments only" : "Number of Review changes") : "Number of Review comments"}
        >
            {reviews !== 0 && reviews}
            {(reviews !== 0 && comments !== 0) && <span style={{fontSize: "x-small"}} > &bull; </span>}
            {comments !== 0 && comments}
        </Label>
    );
};

ReviewsCount.propTypes = {
    reviews: PropTypes.number,
    comments: PropTypes.number,
    floating: PropTypes.bool,
    keepInLine: PropTypes.bool,
    marginLeft: PropTypes.bool,
};

export default ReviewsCount;

export const makeReviewsCountProps = (reviewsCount, collection) => {
    reviewsCount = reviewsCount && (collection === undefined ? reviewsCount : reviewsCount[collection]);
    if (reviewsCount) {
        const reviews = 0 + reviewsCount[REVIEW_TYPE.REPLACE] + reviewsCount[REVIEW_TYPE.INSERT];
        const comments = 0 + reviewsCount[REVIEW_TYPE.COMMENT_ONLY];
        return { reviews, comments };
    }
    return null;
};

export const hasUnprocessedReviews = (reviewsCount) => {
    if (reviewsCount) {
        for (const countOf of Object.values(reviewsCount)) {
            if (countOf[REVIEW_TYPE.REPLACE] || countOf[REVIEW_TYPE.INSERT]) {
                return true;
            }
        }
    }
    return false;
};
