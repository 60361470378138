import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";

import { roles as Roles } from "../../../auth";
import TenantCardItem from "./TenantCardItem";

const RoleTypes = Object.keys(Roles);

export default class TenantUserStats extends React.PureComponent {
    static propTypes = {
        /** Count of users by role */
        usersByRole: PropTypes.object,
    };

    render() {
        const { usersByRole } = this.props;
        return (
            <Card.Content className="spork usersblock">
                <Card.Description className="spork tenantItem">
                    {usersByRole && (
                        RoleTypes.map((role) => {
                            const count = usersByRole[role];
                            const name = count && (count > 1 && Roles[role].plural) || Roles[role].name;
                            return count && <TenantCardItem key={role} count={count} name={name} />;
                        }).filter(Boolean)
                    )}
                </Card.Description>
            </Card.Content>
        );
    }
}
