import React from "react";
import PropTypes from "prop-types";
import { Header, Message, Grid, Icon, List } from "semantic-ui-react";
import StyledBlocksReset from "./StyledBlocksReset";
import OrphanedProblems from "./OrphanedProblems";
import MigrateKits from "./component/MigrateKits";

const ScrollableStyle = {
    maxHeight: "80vh",
    minHeight: "80vh",
    overflowY: "auto",
};

const IconClassNames = {
    debug: "angle right grey",
    message: "angle right blue",
    failure: "warning red",
    warning: "warning yellow",
    success: "info green",
};

class AdminLogRecord extends React.PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(["debug", "message", "failure", "success", "warning" ]),
        text: PropTypes.string,
        // data: PropTypes.object,
        time: PropTypes.number,
    };

    render() {
        const { type, text, time } = this.props;
        const ts = (time && new Date(time).toISOString().split("T")[1]);
        return (
            <List.Item>
                <List.Icon className={IconClassNames[type] || "info grey"} />
                <List.Content>
                    <span>{ts ? `${ts}: ` : "... "}</span><span>{text}</span>
                </List.Content>
            </List.Item>
        );
    }
}

export default class AdminFunctions extends React.PureComponent {
    static propTypes = {
        onCleanup: PropTypes.func.isRequired,
        onExecute: PropTypes.func.isRequired,
        isRunning: PropTypes.bool,
        isFailure: PropTypes.bool,
        message: PropTypes.string,
        logData: PropTypes.array,
    };

    handleExecute = (e, { name }) => {
        this.props.onExecute(name);
    }

    componentWillUnmount() {
        this.props.onCleanup();
    }

    render() {
        const { isRunning, isFailure, message, logData } = this.props;
        const messageIcon = isFailure ? "warning sign" : (
            isRunning ? "settings" : "info circle"
        );
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Administrative Functions
                </Header>
                {message && (
                    <Message negative={isFailure} info={!(isFailure || isRunning)}>
                        <Message.Content><Icon name={messageIcon} />{message}</Message.Content>
                    </Message>
                )}
                <Grid>
                    <Grid.Column width="8" style={ScrollableStyle}>
                        <StyledBlocksReset onExecute={this.handleExecute} disabled={isRunning} />
                        <OrphanedProblems onExecute={this.handleExecute} disabled={isRunning} />
                        <MigrateKits onExecute={this.handleExecute} disabled={isRunning} />
                    </Grid.Column>
                    <Grid.Column width="8" style={ScrollableStyle} className="ui list">
                        {logData.map(
                            // eslint-disable-next-line react/no-array-index-key
                            (entry, index) => <AdminLogRecord key={index} {...entry} />
                        )}
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );
    }
}
