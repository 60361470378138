import React from "react";
import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";

import Problem from "./Problem";

const getProblemNameFactory = () => {
    let nr = 1;
    return (problem) => {
        const type = problem.problemType || problem.type;
        if (type === "pa") {
            const nested = Object.keys(problem.children || {}).length;
            if (nested) {
                return `Parent of #${nr}...${nr + nested - 1}`;
            } else {
                return "Parent with no children";
                // return `#${nr++} (no children)`;
            }
        }
        return `#${nr++}`;
    };
};

const getProblemAuthorId = (problem) => (problem.author && (
    typeof problem.author === "string" ? problem.author : problem.author.id
));

class ProblemSetContent extends React.PureComponent {
    static propTypes = {
        fbCollection: PropTypes.string.isRequired,
        userId: PropTypes.string,
        authorId: PropTypes.string,
        isLocked: PropTypes.bool,
        isLockedByMe: PropTypes.bool,
        problemSet: PropTypes.object.isRequired,
        problemData: PropTypes.object.isRequired,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onMoveChild: PropTypes.func.isRequired,
        onAddChild: PropTypes.func.isRequired,
    };

    getProblemProps = (problemId, parentProblemId, getProblemName) => {
        const { problemData } = this.props;
        const problem = problemData && problemData[problemId];
        if (!problem) {
            return null;
        }

        const { fbCollection, isLocked, isLockedByMe, userId, authorId } = this.props;
        const problemType = problem.problemType || problem.type;
        const props = {
            fbCollection,
            userId,
            authorId: getProblemAuthorId(problem),
            parentAuthorId: authorId,
            problemId,
            problemType,
            problemName: getProblemName(problem),
            question: problem.question,
            answer: problem.answer,
            onEdit: this.props.onEdit,
            onDelete: this.props.onDelete,
            // disable edit when problem has lock and problem set is not locked or locked by somebody else
            isLockedForEdit: !!problem.lock && (!isLocked || (isLocked && !isLockedByMe)),
            // disable remove when whole problem set is locked by somebody else
            isLockedForRemove: isLocked && !isLockedByMe,
        };

        if (parentProblemId) {
            props.parentProblemId = parentProblemId;
            props.moveChild = this.props.onMoveChild;
        } else if (problemType === "pa") {
            const children = problemType === "pa" && problem.children || [];
            props.children = Array.isArray(children) && children.length ? children : null;
            props.onAddChild = this.props.onAddChild;
        }

        return props;
    }

    getProblem = (problemId, getProblemName) => {
        const props = this.getProblemProps(problemId, null, getProblemName);
        if (!props) {
            return null;
        }
        if (props.children) {
            const children = props.children;
            const parentAuthorId = props.authorId;
            delete props.children;

            return (
                <Problem key={problemId} {...props}>
                    {children.map((childId) => {
                        const childProps = this.getProblemProps(childId, problemId, getProblemName);
                        return childProps && (
                            <Problem key={childId} {...childProps} parentAuthorId={parentAuthorId} />
                        );
                    }).filter(Boolean)}
                </Problem>
            );
        } else {
            return <Problem key={problemId} {...props} />;
        }
    };

    render() {
        const { problemSet, fbCollection } = this.props;
        const problemIds = problemSet && problemSet.problems;
        if (!problemIds) {
            return null;
        }

        const getProblemName = getProblemNameFactory();
        return (
            <Container id={fbCollection}>
                {problemIds.map((problemId) => this.getProblem(problemId, getProblemName))}
            </Container>
        );
    }
}

export default ProblemSetContent;