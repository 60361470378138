import { TYPES } from "./CourseVariantsActions";

const initialState = {
    isLoading: false,
    cvList: [],
    allCvList: [],
    courseVariant: null
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.CV_LOAD_LIST:
        case TYPES.ALL_CV_LOAD_LIST:
            return { ...state, isLoading: true };
        case TYPES.CV_LOAD:
            return { ...state, isLoading: true, courseVariant: null };
        case TYPES.CV_SET_LIST:
            return { ...state, cvList: payload, isLoading: false };
        case TYPES.ALL_CV_SET_LIST:
            return { ...state, allCvList: payload, isLoading: false };
        case TYPES.CV_SET:
            return { ...state, courseVariant: payload, isLoading: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
