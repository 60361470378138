import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";
import TopicTreeContainer from "./TopicTree";
import CollectionListContainer from "./CollectionList";

export default class BeLATopicCollection extends PureComponent {
    render() {
        const { selectedCollection } = this.props;

        return selectedCollection ? (
            <TopicTreeContainer />
        ) : (
            <React.Fragment>
                <Header>Topic Collections</Header>
                <CollectionListContainer />
            </React.Fragment>
        );
    }
}

BeLATopicCollection.propTypes = {
    selectedCollection: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        recommended_obj_set: PropTypes.string
    })
};
