import XLSX from "xlsx";
import Ajv from "ajv";
import { serializeError } from "serialize-error";

const ajv = new Ajv(); // options can be passed, e.g. {allErrors: true}

const normalizeSheetName = (name) => name.trim().toLowerCase();
const quotedArray = (arr) => "\"" + arr.join("\", \"") + "\"";

export const SHEETS = {
    YEARS: "years",
    SCHOOLS: "schools",
};

export class SchoolToolWorkbook {

    constructor(requiredSheets) {
        this._requiredSheets = Array.isArray(requiredSheets) ? requiredSheets : [requiredSheets];
    }

    _validationSchema = {  // TODO: move it to standalone file?
        [SHEETS.YEARS]: {
            uniqueItems: true,
            items: {
                required: ["name", "valid", "default"],
                name: { type: "string" },
                valid: { type: "integer", minimum: 0, maximum: 1 },
                default: { type: "integer", minimum: 0, maximum: 1 },
            },
        },
        [SHEETS.SCHOOLS]: {
            // Not implemented yet
        },
    };

    _sampleData = [  // TODO: move it to standalone file?
        { name: "2021/2022", valid: 1, default: 1 },
        { name: "2022/2023", valid: 1, default: 0 },
        { name: "2023/2024", valid: 0, default: 0 },
    ];

    _getValidationSchema = () => Object.entries(this._validationSchema).reduce((acc, [ sheetName, schema ]) => {
        if (this._requiredSheets.includes(sheetName)) {
            acc[sheetName] = schema;
        }
        return acc;
    }, {});

    _errors = [];
    _sheetNamesMap = {};  // normalized name -> original name, e.g. "years" -> "Years  "

    loadData = (fileBuffer) => {
        const data = new Uint8Array(fileBuffer);
        this._workbook = XLSX.read(data, { type: "array" });

        this._isValid = true;
        this._checkSheets();
        this._data = {};

        for (const sheetName of this._requiredSheets) {
            switch (sheetName) {
                case SHEETS.YEARS:
                    this._data[sheetName] = this._getYearsData();
                    break;
                case SHEETS.SCHOOLS:
                    this._data[sheetName] = this._getSchoolsData();
                    break;
                default:
                    // eslint-disable-next-line no-console
                    console.error(`[XLS Error]: unsupported sheet name "${sheetName}"`);
            }
        }
    };

    isValid = () => this._isValid;

    getErrors = () => this._errors;

    getData = () => this._data;

    /**
     * @param {Object or Array of AJV errors} error
     */
    _logError = (error) => {
        this._isValid = false;

        if (!Array.isArray(error)) {
            this._errors.push(error);
            return;
        }

        error.forEach((ajvErr) => {
            const { message, ...err } = ajvErr;
            const capitalized = message.substr(0, 1).toUpperCase() + message.substr(1);
            this._errors.push({ message: capitalized, description: JSON.stringify(err) });
        });
    };

    /**
     * Check if the workbook contains sheets with expected names (according to `_validationSchema`)
     */
    _checkSheets = () => {
        const sheetNames = this._workbook.SheetNames;
        const expectedSheetNames = Object.keys(this._getValidationSchema);
        const expectedCount = expectedSheetNames.length;

        if (sheetNames.length >= expectedCount) {
            this._sheetNamesMap = sheetNames.reduce((acc, name) => {
                acc[normalizeSheetName(name)] = name;
                return acc;
            }, {});

            if (sheetNames.filter((name) => expectedSheetNames.includes( normalizeSheetName(name) )).length === expectedCount) {
                return;
            }
        }

        this._logError({
            message: "Missing sheet(s)",
            description: `The Excel workbook has to contain these sheets: ${quotedArray(expectedSheetNames)}.`,
        });
    };

    _getYearsData = () => {
        let data = [];

        try {
            const sheet = this._workbook.Sheets[this._sheetNamesMap[SHEETS.YEARS]];
            data = XLSX.utils.sheet_to_json(sheet);

            if (data.length) {
                const validate = ajv.compile(this._validationSchema[SHEETS.YEARS]);

                if (!validate(data)) {
                    this._logError(validate.errors);
                }

                const defaultYears = data.filter((row) => row.default);
                if (defaultYears.length > 1) {
                    this._logError({
                        message: "Only one year can be marked as default!",
                        description: `Currently marked as default (${defaultYears.length}): ${quotedArray(defaultYears)}.`
                    });
                }

            }
            else {
                this._logError({ message: "No years to import" });
            }
        }
        catch (err) {
            this._logError({ message: err.message, description: serializeError(err) });
        }

        return data;
    };

    _getSchoolsData = () => {
        let data = [];
        return data;
    };

    createXlsxTemplate = () => {
        this._workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(this._sampleData);
        XLSX.utils.book_append_sheet(this._workbook, sheet, SHEETS.YEARS);

        // Validate created workbook with sample data the same way as real imported file
        this._isValid = true;
        this._checkSheets();
        this._getYearsData();

        if (!this.isValid()) {
            // Development error!!!
            throw new Error(`Invalid sample data for "${SHEETS.YEARS}" sheet!`);
        }

        XLSX.writeFile(this._workbook, "years_template.xlsx");
    };
}
