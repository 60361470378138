import React, { PureComponent } from "react";
import { List, Button, Menu, Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import ConfirmDeleteWithContext from "../../../component/ConfirmDeleteWithContext";
import RemoveIcon from "../../../component/RemoveIcon";
import PageHeader from "../../../component/PageHeader";

import "./AKitsList.scss";
import CreateKit from "../CreateKit";
import EditKit from "../EditKitProperties";
import EntityModuleList from "../../../component/EntityModuleList";

export default class AKitsList extends PureComponent {
    static propTypes = {
        /* List of a-kits */
        aKits: PropTypes.array,
        searchFilter: PropTypes.string,
        onChangeFilter: PropTypes.func.isRequired,
        onAKitRemove: PropTypes.func.isRequired,
        onSelectAKitForEdit:PropTypes.func,
        isSuperAuthorAtLeast: PropTypes.bool,
        onEditKit: PropTypes.func,
        isRemoving: PropTypes.bool,
    }

    state = {
        removeId: null,
        removeContext: null,
    };

    aKitEmptyName = "Unnamed";
    courseVariantEmptyName = "no course";

    clearRemoveState = () => {
        this.setState({ removeId: null, removeContext: null });
    }

    handleOnClickListItem = (e, { id } ) => {
        this.props.onSelectAKitForEdit(id);
    }

    handleRemove = (e, id) => {
        const { removeId } = this.state;
        if (!removeId) {
            this.setState({
                removeContext: e.currentTarget,
                removeId: id
            });
        }
    };

    handleRemoveCancel = () => {
        this.clearRemoveState();
    };

    handleRemoveConfirm = () => {
        const { onAKitRemove } = this.props;
        const { removeId } = this.state;
        if (onAKitRemove && removeId) {
            onAKitRemove(removeId);
        }
        this.clearRemoveState();
    };

    handleEditKit = (id) => {
        const { onEditKit } = this.props;
        onEditKit(id);
    }

    render() {
        const { aKits, isSuperAuthorAtLeast/*, selectedYearId*/, isRemoving } = this.props;
        const { searchFilter, onChangeFilter } = this.props;
        const { removeContext, removeId } = this.state;

        let isEmpty = (searchFilter === "" && (!aKits || 0 == aKits.length));

        let removeName = null;
        if (null !== removeId) {
            const aKit = aKits.find( aKit => aKit.key == removeId );
            const aKitName = aKit && aKit.name;
            removeName = aKitName ? aKitName : this.aKitEmptyName;
        }

        return (
            <React.Fragment>
                <ConfirmDeleteWithContext
                    context={removeContext}
                    confirmText="Remove Author Kit"
                    onConfirm={this.handleRemoveConfirm}
                    whatText={`the Author Kit ${removeName}`}
                    onCancel={this.handleRemoveCancel}
                />

                <PageHeader {...this.props}
                    searchFilter={searchFilter}
                    list={aKits}
                    headerText="Author Kits "
                    entityName="kit"
                    onChangeFilter={onChangeFilter}
                >
                    {isEmpty == false && <CreateKit trigger={<Menu.Item icon="plus" content="New" title="Add new Author Kit"/>} />}
                </PageHeader>
                <EditKit />
                { isEmpty ?
                    <NoDataPlaceholder text="Here we list Author Kits." icon="newspaper outline">
                        <CreateKit trigger={<Button primary content="Create" />} />
                    </NoDataPlaceholder>
                    :
                    <Dimmer.Dimmable>
                        <Dimmer active={isRemoving} inverted>
                            <Loader inverted>Removing...</Loader>
                        </Dimmer>
                        { aKits.length == 0 ?
                            <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
                                <p>We cannot find any Author Kit matching your filtering criteria. </p>
                            </NoDataPlaceholder>
                            :
                            <List selection >
                                {aKits && aKits.map(aKit => {

                                    const aKitNameForDisplay = aKit.name ? aKit.name : this.aKitEmptyName;
                                    // const courseVariantNameInSelectedYear = (selectedYearId && aKit && aKit.course_variant && aKit.course_variant.yearId && (aKit.course_variant.yearId == selectedYearId)) && aKit.course_variant.name;
                                    // const courseVariantNameForDisplay = courseVariantNameInSelectedYear ? courseVariantNameInSelectedYear : this.courseVariantEmptyName;

                                    return (
                                        <List.Item  className="kitsListItem" id={aKit.key} key={aKit.key} onClick={this.handleOnClickListItem} >
                                            <List.Icon name="book" color="blue" size="large" verticalAlign="middle" />
                                            <List.Content>
                                                <a title={aKitNameForDisplay}><b>{aKitNameForDisplay}</b></a>
                                                <span>{aKit.m && <EntityModuleList list={Object.keys(aKit.m)} />}</span>
                                                <span style={{textAlign: "right"}}>
                                                    <EditIcon kitId={aKit.key} onClick={this.handleEditKit} canEdit={isSuperAuthorAtLeast} />
                                                    {(isSuperAuthorAtLeast && aKit.can_remove) ? <RemoveIcon onRemove={this.handleRemove} id={aKit.key} entityName={`Author Kit ${aKitNameForDisplay}`} /> : <span />}
                                                </span>
                                            </List.Content>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        }
                    </Dimmer.Dimmable>
                }

            </React.Fragment>
        );
    }
}

class EditIcon extends PureComponent {
    static propTypes = {
        kitId: PropTypes.string,
        onClick: PropTypes.func,
        canEdit: PropTypes.bool,
    };

    handleClick = (e) => {
        const { kitId, onClick } = this.props;
        e.stopPropagation();
        onClick(kitId);
    }

    render () {
        const { canEdit } = this.props;
        return <Button 
            compact 
            size="small" 
            basic 
            onClick={this.handleClick} 
            title={canEdit ? "Edit author kit properties" : "Show author kit properties"} 
            content={canEdit ? "Edit properties" : "Show properties"}
            style={{marginRight: "1em"}} 
        />;
    }
}

