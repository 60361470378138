import PropTypes from "prop-types";

export const FilterParamsProps = {
    tags: PropTypes.array,
    courseVariant: PropTypes.object,
};

export const FilterParamsShape = PropTypes.shape(FilterParamsProps);

export const isFiltered = (filterParams) => filterParams && (
    filterParams.resourceType ||
    filterParams.courseVariant ||
    (filterParams.tags && filterParams.tags.length) ||
    filterParams.owner || filterParams.topic
);
