import React from "react";
import PropTypes from "prop-types";
import { InfiniteTable } from "bmd-react";
import { Dimmer, Header, Icon, Label, Loader, Popup } from "semantic-ui-react";
import { ActionIconsContainer } from "bmd-react/dist/ActionIconsContainer";
import EditActionIcon from "bmd-react/dist/EditActionIcon";

import AccessControl from "../auth";
import ActionLink from "../component/ActionLink";
import ImportExcel from "./ImportExcel";
import RemoveIcon from "../component/RemoveIcon";
import YearEditor, { EDIT_MODE } from "./YearEditorContainer";
import ConfirmWithContext from "../component/ConfirmWithContext";

import styles from "./years.module.scss";
import { convertDateIsoToLocal } from "../utils/dateUtils";
import { SHEETS, SchoolToolWorkbook } from "./SchoolToolWorkbook";
export default class YearList extends React.PureComponent {
    static propTypes = {
        years: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                created: PropTypes.string,
                updated: PropTypes.string,
                valid: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
                default: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            })
        ),
        isLoading: PropTypes.bool,
        onCreateYear: PropTypes.func.isRequired,
        onUpdateYear: PropTypes.func.isRequired,
        onDeactivateYear: PropTypes.func.isRequired,
        onFilterValid: PropTypes.func.isRequired,
    };

    state = {
        showValidYears: true,
        selectedContext: null,
        selectedYearId: null,
        editMode: EDIT_MODE.NONE,
    };

    resetState = () =>
        this.setState({
            selectedContext: null,
            selectedYearId: null,
            editMode: EDIT_MODE.NONE,
        });

    renderActionIcons = ({ rowData }) => (
        <AccessControl action="years:manage">
            <ActionIconsContainer floatRight>
                {this.state.showValidYears ? (
                    <React.Fragment>
                        <EditActionIcon onClick={this.handleEdit} data={rowData.id} />{" "}
                        <RemoveIcon
                            onRemove={this.handleDeactivate}
                            id={String(rowData.id)}
                            entityName="year"
                            className="actionIconClass"
                            disabled={Boolean(rowData.default)}
                        />
                    </React.Fragment>)
                    : <Icon name="recycle" color="green" link onClick={this.handleActivate} data-year-id={rowData.id} data={"aaa"}/>
                }
                {rowData.default
                    ? (<Popup trigger={
                        <Icon name="dont" size="large" disabled fitted style={{ position: "relative", left: "-22px", width: 0 }} />
                    }>
                        <b>Current year cannot be deactivated.</b>
                        <p>Mark another year as the current first, then deactivate this one.</p>
                    </Popup>
                    ) : null}
            </ActionIconsContainer>
        </AccessControl>
    );

    renderDateCell = ({ cellData }) => convertDateIsoToLocal(cellData);

    _columns = [
        {
            label: "Year",
            dataKey: "name",
            width: 150,
            visible: true,
        },
        {
            label: "Created",
            dataKey: "created_at",
            width: 200,
            visible: true,
            cellRenderer: this.renderDateCell,
        },
        {
            label: "Updated",
            dataKey: "updated_at",
            width: 200,
            visible: true,
            cellRenderer: this.renderDateCell,
        },
        {
            label: "",
            dataKey: "default",
            width: 200,
            visible: true,
            cellRenderer: ({ cellData }) => {
                return cellData ? <Label tag color="olive" content="Current" /> : null;
            },
        },
        {
            label: "",
            dataKey: "id",
            width: 350, // for moving action icons to the line end
            visible: true,
            disableSort: true,
            cellRenderer: this.renderActionIcons,
        },
    ];

    highlightRow = (icon, className, highlight) => {
        try {
            const row = icon.parentNode.parentNode.parentNode;
            if (highlight) {
                row.classList.add(className);
            } else {
                row.classList.remove(className);
            }
        } catch (_) {
            // eslint-disable-next-line no-empty
        }
    };

    handleAdd = () => this.setState({ editMode: EDIT_MODE.ADD });

    handleEdit = yearId =>
        this.setState({
            editMode: EDIT_MODE.EDIT,
            selectedYearId: yearId,
        });

    handleDeactivate = (e, id) => {
        const { selectedYearId } = this.state;

        if (!selectedYearId) {
            const deactivateIcon = e.currentTarget;
            this.highlightRow(deactivateIcon, styles.deactivatedRow, true);
            this.setState({
                selectedContext: deactivateIcon,
                selectedYearId: id,
            });
        }
    };

    handleDeactivateConfirm = () => {
        this.highlightRow(this.state.selectedContext, styles.deactivatedRow, false);
        this.props.onDeactivateYear(this.state.selectedYearId, { valid: 0, default: 0 });
        this.handleDeactivateCancel();
    };

    handleDeactivateCancel = () => {
        this.highlightRow(this.state.selectedContext, styles.deactivatedRow, false);
        this.resetState();
    };

    handleSave = ({ name, valid, isDefault }) => {
        const { onCreateYear, onUpdateYear } = this.props;
        const { editMode, selectedYearId } = this.state;
        const data = {
            name,
            valid: valid ? 1 : 0,
            default: isDefault ? 1 : 0,
        };

        if (editMode === EDIT_MODE.ADD) {
            onCreateYear(data);
        } else {
            onUpdateYear(selectedYearId, data);
        }

        this.resetState();
    };

    handleCancel = () => this.resetState();

    handleActivate = e => {
        const { selectedYearId } = this.state;

        if (!selectedYearId) {
            const activateIcon = e.currentTarget;
            this.highlightRow(activateIcon, styles.activatedRow, true);
            this.setState({
                selectedContext: activateIcon,
                selectedYearId: activateIcon.dataset.yearId,
            });
        }
    };

    handleActivateConfirm = () => {
        this.highlightRow(this.state.selectedContext, styles.activatedRow, false);
        this.props.onUpdateYear(this.state.selectedYearId, { valid: 1 });
        this.handleDeactivateCancel();
    };

    handleActivateCancel = () => {
        this.highlightRow(this.state.selectedContext, styles.activatedRow, false);
        this.resetState();
    };

    handleValidityFilter = () => {
        const showValidYears = !this.state.showValidYears;
        this.setState((state) => ({ showValidYears: state.showValidYears }));
        this.props.onFilterValid(showValidYears);
    };

    handleDownload = () => {
        const workbook = new SchoolToolWorkbook(SHEETS.YEARS);
        workbook.createXlsxTemplate();
    };

    render() {
        const { years, isLoading } = this.props;
        const { showValidYears, selectedContext, selectedYearId, editMode } = this.state;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Years
                </Header>
                <Dimmer active={isLoading} inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
                <AccessControl action={"years:manage"}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <ActionLink
                                horizontal
                                icon="plus"
                                onClick={this.handleAdd}
                                content="Add new year"
                                style={{ marginRight: "32px" }}
                            />
                            <ActionLink
                                horizontal
                                icon="filter"
                                onClick={this.handleValidityFilter}
                                content={`Show ${showValidYears ? "inactive" : "active"} years`}
                            />
                        </div>
                        <div style={{ flexBasis: "21em", display: "flex", justifyContent: "space-between" }}>
                            <ImportExcel />
                            <ActionLink
                                horizontal
                                icon="download"
                                onClick={this.handleDownload}
                                content="Download XLSX template"
                            />
                        </div>
                    </div>
                    {showValidYears && (
                        <ConfirmWithContext
                            context={selectedContext}
                            header="Deactivate year"
                            message={"You are about to deactivate the selected year - it can be activated again later."}
                            confirmText="Dectivate"
                            confirmIconName="trash"
                            isConfirmDestructive={true}
                            onConfirm={this.handleDeactivateConfirm}
                            onCancel={this.handleDeactivateCancel}
                        />
                    )}
                    {!showValidYears && (
                        <ConfirmWithContext
                            context={selectedContext}
                            header="Activating year"
                            message={"You are about to activate the selected year. "}
                            confirmText="Activate"
                            confirmIconName="recycle"
                            onConfirm={this.handleActivateConfirm}
                            onCancel={this.handleActivateCancel}
                        />
                    )}
                    {editMode !== EDIT_MODE.NONE && (
                        <YearEditor
                            editMode={editMode}
                            yearId={selectedYearId}
                            onSave={this.handleSave}
                            onCancel={this.handleCancel}
                        />
                    )}
                </AccessControl>
                <div style={{ minHeight: "350px" }}>
                    <InfiniteTable data={years} columns={this._columns} tableName="years" defaultSortBy="name" />
                </div>
            </React.Fragment>
        );
    }
}
