import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { Draggable } from "react-beautiful-dnd";

import { replaceRelLinks } from "../../../utils/conversionUtils";
import styles from "../ProblemSetCreator.module.scss";

export default class ProblemDraggable extends React.PureComponent {
    static propTypes = {
        problemId: PropTypes.string,
        problemName: PropTypes.string,
        problemHtml: PropTypes.string,
        position: PropTypes.number,
    }

    render() {
        const { problemId, problemName, problemHtml, position } = this.props;
        return (
            <Draggable draggableId={problemId} index={position}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div id={problemId} className={styles.qna}>
                            <Icon name="bars" color="grey" {...provided.dragHandleProps} title="Change order" />
                            <span className={styles.qnaTitle}>{problemName}</span>
                            <div className={styles.question}>
                                <span dangerouslySetInnerHTML={{__html: replaceRelLinks(problemHtml)}}></span>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    }
}
