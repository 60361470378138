import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Icon, Segment, Button, Checkbox } from "semantic-ui-react";
import { SaveCancelButtons, ConfirmDeletePopup } from "bmd-react";
import ResourceLink from "./ResourceLink/ResourceLinkContainer";
import CreateResourceDialog from "./CreateResourceDialog";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onSearchResource } from "./ResourceLink/ResourceLinkActions";
import { isSearching, getSearchResult, getSearchFilter } from "./ResourceLink/ResourceLinkSelectors";
import AdvancedResourceSearch from "./AdvancedResourceSearch/AdvancedResourceSearchContainer";
import "./SiPiEditor.css";
import HelpPopup from "bmd-react/dist/HelpPopup";

class SiPiEditor extends PureComponent {
    static propTypes = {
        sipi: PropTypes.object,
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        onSearchResource: PropTypes.func,
    };

    state = {
        name: "",
        student: "",
        resource_id: null,
        teacher: "",
        student_view: false,
        classroom_view: false,
        on_approval: false,
        error: false,
        isOpenAddResourceForm: false,
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value});
        if ("name" === name) {
            this.setState({ error: false});
        }
    }

    handleToggle = (e, { name, checked }) => {
        const changes = { [name]: checked };
        if ("student_view" == name && !checked) {
            changes["on_approval"] = false;
        }
        this.setState(changes);
    }

    componentDidMount() {
        const { sipi } = this.props;
        if (sipi) {
            this.setState({
                name: sipi.name || "",
                student: sipi.student || "",
                resource_id: sipi.resource_id || null,
                teacher: sipi.teacher || "",
                student_view: sipi.student_view || false,
                classroom_view: sipi.classroom_view || false,
                on_approval: sipi.on_approval || false,
            });
        } else {
            this.setState({
                name: "",
                student: "",
                resource_id: null, 
                teacher: "",
                on_approval: false
            });
        }
    }

    validateData = () => {
        if (0 == this.state.name.trim().length) {
            this.setState({ error: true });
            return false;
        }
        this.setState({ error: false });
        return true;
    }

    handleSave = () => {
        const { onSave, sipi, onClose } = this.props;
        if (this.validateData()) {
            onSave({
                ...sipi,
                ...this.state,
                error: null,
                isOpenAddResourceForm: null,
            });
            onClose();
        }
    }
    handleSearchChange = (e, { value }) => {
        const { onSearchResource } = this.props;
        onSearchResource(value);
    }

    handleAddResource = (e) => {
        this.setState({ isOpenAddResourceForm: true});
        this.handleSearchChange(e, { value: ""});
    }
    handleUnassignResource = (callback) => {
        callback();
        this.setState({ resource_id: null });
    }
    handleCreateNewResource = (e) => {
        e.preventDefault();
    }
    handleCreateResource = (resource_id) => this.setState({ resource_id });
    handleSelectResource = (e,  {result: { resource_id }}) => {
        this.setState({ resource_id });
    }

    render() {
        const { sipi, onClose } = this.props;
        const { name, student, resource_id, teacher, error, isOpenAddResourceForm, classroom_view, student_view, on_approval } = this.state;
        return (
            <Modal
                open={true}
                onClose={onClose}
                closeOnDimmerClick={false}
            >
                <Modal.Header>{null === sipi ? "Add new iPlanner instrument" : "Edit iPlanner instrument " + sipi.name}</Modal.Header>
                <Modal.Content>
                    <Form autoComplete="off">
                        <Form.Input
                            required
                            autoFocus
                            label="Name"
                            name="name"
                            onChange={this.handleChange}
                            value={name}
                            placeholder={error ? "Please fill the instrument name": "Fill the instrument name"}
                            error={error}
                        />
                        <Form.TextArea label="Teacher Instructions" placeholder="This will be visible only to a teacher." onChange={this.handleChange} value={teacher} name="teacher"/>
                        <Form.TextArea label="Student Instructions" placeholder="Text visible to students." onChange={this.handleChange} value={student} name="student"/>
                        <Form.Group grouped>
                            <label>Display the instrument</label>
                            <Form.Checkbox style={{marginLeft: "1em"}} label="In classroom presentation" onChange={this.handleToggle} checked={classroom_view} name="classroom_view"/>
                            <Form.Field style={{ marginLeft: "1em" }}>
                                <Checkbox label="On student tablets" onChange={this.handleToggle} checked={student_view} name="student_view"/>
                                <HelpPopup content="If enabled, the instrument will be distributed also to student tablets."/>
                            </Form.Field>
                            <Form.Field style={{ marginLeft: "3em" }}>
                                <Checkbox disabled={!student_view} label="Only after the teacher approval" onChange={this.handleToggle} checked={on_approval} name="on_approval"/>
                                <HelpPopup content="If enabled, the instrument will become visible only after allowing from teacher tablet."/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field label="Resource" />
                    </Form>
                    <Segment className="ui field" style={{minHeight: "2.8em", display: resource_id ? "flex" : "inherit"}}>
                        {resource_id ? (
                            <React.Fragment>
                                <ResourceLink sipi={{ resource_id }} renderAsLink />
                                <ConfirmDeletePopup
                                    position="right center"
                                    trigger={<Icon name="trash alternate outline" link className="actionIconClass" title="Unassign resource"/>}
                                    whatText="resource"
                                    onConfirm={this.handleUnassignResource}/>
                            </React.Fragment>
                        ): (
                            isOpenAddResourceForm ? (
                                <div style={{display: "grid", gridTemplateColumns: "48% 4% 48%", alignItems: "center"}}>
                                    <AdvancedResourceSearch onResultSelect={this.handleSelectResource}/>
                                    <b style={{textAlign: "center"}}>OR</b>
                                    <CreateResourceDialog onCreate={this.handleCreateResource} />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <ResourceLink sipi={{ resource_id }}  />
                                    <Button content="Add Resource" compact size="small" onClick={this.handleAddResource} title="Assign resource from SPORK repository."/>
                                </React.Fragment>
                            )
                        )}
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onSave={this.handleSave}
                        onCancel={onClose}
                        saveDisabled={name == ""}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}


const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { onSearchResource },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        isSearching: isSearching(state),
        searchResult: getSearchResult(state),
        searchFilter: getSearchFilter(state),
    }),
    dispatchToProps
)(SiPiEditor);