const TYPES = {
    WIDGET_TEMPLATES_ITEM_SELECTED: "Authoring/WidgetTemplates/ITEM_SELECTED",
    WIDGET_TEMPLATES_SAVE_TEMPLATE: "Authoring/WidgetTemplates/SAVE_TEMPLATE",
    WIDGET_TEMPLATES_CREATE_NEW: "Authoring/WidgetTemplates/CREATE_NEW",
    WIDGET_TEMPLATES_SEARCH_CHANGED: "Authoring/WidgetTemplates/SEARCH_CHANGED",
    WIDGET_TEMPLATES_SELECT_TAG: "Authoring/WidgetTemplates/SELECT_TAG",
    WIDGET_TEMPLATES_DELETE_TEMPLATE: "Authoring/WidgetTemplates/DELETE_TEMPLATE",
    WIDGET_TEMPLATES_UPDATE_NAME: "Authoring/WidgetTemplates/UPDATE_NAME",
    WIDGET_TEMPLATES_CREATE_ITEM: "Authoring/WidgetTemplates/CREATE_ITEM",
    WIDGET_TEMPLATES_UPDATE_ITEM: "Authoring/WidgetTemplates/UPDATE_ITEM",
    WIDGET_TEMPLATES_REMOVE_ITEM: "Authoring/WidgetTemplates/REMOVE_ITEM",
    WIDGET_TEMPLATES_CHANGE_ORDER: "Authoring/WidgetTemplates/CHANGE_ORDER",
    WIDGET_TEMPLATES_CLONE_TEMPLATE: "Authoring/WidgetTemplates/CLONE_TEMPLATE",
    WIDGET_TEMPLATES_UPDATE_SETUP: "Authoring/WidgetTemplates/UPDATE_SETUP",
    WIDGET_TEMPLATES_CLOSE_NAME_EDITOR: "Authoring/WidgetTemplates/CLOSE_NAME_EDITOR",
    WIDGET_TEMPLATES_SET_DIRTY: "Authoring/WidgetTemplates/SET_DIRTY",
    WIDGET_TEMPLATES_SAVING: "Authoring/WidgetTemplates/SAVING",
    WIDGET_TEMPLATES_SET_MODULE_FILTER: "Authoring/WidgetTemplates/SET_MODULE_FILTER",
};

const onSelectTemplate = (selectedItem, isNew = false, pushUrl = true) => ({
    type: TYPES.WIDGET_TEMPLATES_ITEM_SELECTED,
    payload: { selectedItem, isNew, pushUrl}
});

const onSaveTemplate = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_SAVE_TEMPLATE,
    payload
});

const onCreateTemplate = () => ({
    type: TYPES.WIDGET_TEMPLATES_CREATE_NEW
});

const onSearchChanged = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_SEARCH_CHANGED,
    payload
});

const onSelectTag = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_SELECT_TAG,
    payload
});

const onDeleteTemplate = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_DELETE_TEMPLATE,
    payload
});

const onUpdateName = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_UPDATE_NAME,
    payload
});

const onCreateItem = (itemData) => ({
    type: TYPES.WIDGET_TEMPLATES_CREATE_ITEM,
    payload: itemData
});

const onUpdateItem = (itemId, itemData) => ({
    type: TYPES.WIDGET_TEMPLATES_UPDATE_ITEM,
    payload: { itemId, itemData }
});

const onRemoveItem = (itemId) => ({
    type: TYPES.WIDGET_TEMPLATES_REMOVE_ITEM,
    payload: itemId
});

const onChangeOrder = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_CHANGE_ORDER,
    payload
});

const onCloneTemplate = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_CLONE_TEMPLATE,
    payload
});

const onUpdateSetup = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_UPDATE_SETUP,
    payload
});

const onCloseNameEditor = () => ({
    type: TYPES.WIDGET_TEMPLATES_CLOSE_NAME_EDITOR,
});

const onSetDirty = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_SET_DIRTY,
    payload
});

const onSavingItem = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_SAVING,
    payload
});

const onSetModuleFilter = (payload) => ({
    type: TYPES.WIDGET_TEMPLATES_SET_MODULE_FILTER,
    payload
});

export {
    TYPES,
    onCreateTemplate,
    onSelectTemplate,
    onSaveTemplate,
    onSearchChanged,
    onSelectTag,
    onDeleteTemplate,
    onUpdateName,
    onCreateItem,
    onUpdateItem,
    onRemoveItem,
    onChangeOrder,
    onCloneTemplate,
    onUpdateSetup,
    onCloseNameEditor,
    onSetDirty,
    onSavingItem,
    onSetModuleFilter,
};
