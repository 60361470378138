import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon, Divider } from "semantic-ui-react";

export default class PopupCloseIcon extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const { onClose } = this.props;

        return (
            <React.Fragment>
                <span style={{ float: "right", marginTop: "-.5em", fontSize: "small" }}>
                    <Icon
                        name="close"
                        title="Close"
                        fitted
                        link
                        onClick={onClose}
                    />
                </span>
                <Divider hidden clearing style={{ margin: "-1.1em" }} />
            </React.Fragment>
        );
    }
}
