import React from "react";
import PropTypes from "prop-types";
import { Popup, Menu, Dropdown } from "semantic-ui-react";
import cx from "classnames";
import TableStyleConfigurator from "./TableStyleConfigurator";
import "./tableEditor.scss";

const ACTIONS = {
    INSERT_BEFORE: "Insert Before",
    INSERT_AFTER: "Insert After",
    DELETE: "Delete",
};

class TableChangePopup extends React.Component {

    static propTypes = {
        activeCell: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        insertRow: PropTypes.func.isRequired,
        deleteRow: PropTypes.func.isRequired,
        insertColumn: PropTypes.func.isRequired,
        deleteColumn: PropTypes.func.isRequired,
        tableStyle: PropTypes.string,
        onChangeStyle: PropTypes.func,
        isMultiCellMode: PropTypes.bool.isRequired,
        onMultiCellMode: PropTypes.func.isRequired,
    };

    handleClose = () => {
        this.props.onClose();
    };

    handleRowAction = (e, data) => {
        const { insertRow, deleteRow } = this.props;
        const action = data.children;

        switch (action) {
            case ACTIONS.INSERT_BEFORE:
                insertRow("before");
                return;
            case ACTIONS.INSERT_AFTER:
                insertRow("after");
                return;
            case ACTIONS.DELETE:
                deleteRow();
                return;
        }
    };

    handleColumnAction = (e, data) => {
        const { insertColumn, deleteColumn } = this.props;
        const action = data.children;

        switch (action) {
            case ACTIONS.INSERT_BEFORE:
                insertColumn("before");
                return;
            case ACTIONS.INSERT_AFTER:
                insertColumn("after");
                return;
            case ACTIONS.DELETE:
                deleteColumn();
                return;
        }
    };

    handleMultiCellMode = () => {
        this.props.onMultiCellMode();
        this.handleClose();
    };

    render() {
        const { activeCell, tableStyle, onChangeStyle, isMultiCellMode } = this.props;

        if (!activeCell) {
            return null;
        }

        return (
            <Popup context={activeCell} className="tableConfig" position="top center" open={true} onClose={this.handleClose} verticalOffset={-10}>
                <Menu secondary vertical>
                    <Dropdown item text="Row …" disabled={isMultiCellMode}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleRowAction}>{ACTIONS.INSERT_BEFORE}</Dropdown.Item>
                            <Dropdown.Item onClick={this.handleRowAction}>{ACTIONS.INSERT_AFTER}</Dropdown.Item>
                            <Dropdown.Item onClick={this.handleRowAction}>{ACTIONS.DELETE}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown item text="Column …" disabled={isMultiCellMode}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleColumnAction}>{ACTIONS.INSERT_BEFORE}</Dropdown.Item>
                            <Dropdown.Item onClick={this.handleColumnAction}>{ACTIONS.INSERT_AFTER}</Dropdown.Item>
                            <Dropdown.Item onClick={this.handleColumnAction}>{ACTIONS.DELETE}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item onClick={this.handleMultiCellMode} className={cx({ checked: isMultiCellMode })}>
                        Multi Cell Mode
                    </Menu.Item>
                </Menu>
                <TableStyleConfigurator tableStyle={tableStyle} onChange={onChangeStyle} />
            </Popup>
        );
    }
}

export default TableChangePopup;
