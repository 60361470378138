import React from "react";
import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import Widget from "../../Widget";
import SharedToolbar from "../../../component/seamlessEditor/bookEditor/SharedToolbar";
import SaveCancelInToolbar from "../../WidgetLibrary/WidgetEditor/SaveCancelInToolbar";
import { onChangeWidgetTitle, onSetDirty } from "../WidgetLibraryActions.js";
import { isDirty } from "../WidgetLibrarySelectors";
import WidgetEditorBody from "./WidgetEditorBody";
import { prepareTextItemContent, getEditorComponentKey } from "./WidgetEditorUtils";
import "./WidgetEditor.css";

let textEditorOrder = 0;
const resetFirstTextEditor = () => (textEditorOrder = 0);
const checkFirstTextEditor = () => (1 === (textEditorOrder += 1));

class WidgetEditor extends React.PureComponent {
    static propTypes = {
        widget: PropTypes.object,
        items: PropTypes.array,
        onCloseItemEditor: PropTypes.func,
        onUpdateItem: PropTypes.func,
        canEdit: PropTypes.bool,
        canReview: PropTypes.bool,
        onChangeWidgetTitle: PropTypes.func,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
        asReview: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.getEditorContentFns = {};
    }

    state = {
        toolbarRef: null,
        componentKey: getEditorComponentKey(),
        isReMounting: false,
    };

    toolbarRef = React.createRef();

    componentDidMount = () => {
        if (this.toolbarRef && this.toolbarRef.current) {
            const sharedToolBar = new SharedToolbar();
            sharedToolBar.mount(this.toolbarRef.current);
            this.setState({ toolbarRef: sharedToolBar });
        }
    };

    componentDidUpdate = () => {
        if (this.state.isReMounting) {
            this.setState({ isReMounting: false });
        }
    }

    componentWillUnmount = () => {
        this.state.toolbarRef && this.state.toolbarRef.unmount();
        this.props.onSetDirty(false);
    };

    registerGetContentFn = (widgetId, itemId, fn) => {
        const { isReMounting } = this.state;
        const editorKey = widgetId + itemId;
        if (fn === null) {
            // ignore unsubscribe when all components are being re-mounted on Cancel
            if (!isReMounting) {
                delete this.getEditorContentFns[editorKey];
            }
        } else {
            this.getEditorContentFns[editorKey] = { widgetId, itemId, fn};
        }
    };

    // add isDirty flag to list of registered editor callbacks
    handleDirty = (widgetId, itemId) => {
        const key = widgetId + itemId;
        this.getEditorContentFns[key] = {...this.getEditorContentFns[key], isDirty: true };
        this.props.onSetDirty(true);
    };

    // iterate over all opened and registered editors and save their data
    handleSave = () => {
        const { onUpdateItem, onSetDirty } = this.props;
        for (let key in this.getEditorContentFns) {
            const { widgetId, itemId, fn, isDirty } = this.getEditorContentFns[key];
            if (isDirty) {
                const content = prepareTextItemContent(fn());
                onUpdateItem({ widgetId, itemId, content });
                this.getEditorContentFns[key] = {...this.getEditorContentFns[key], isDirty: false };
            }
        }
        onSetDirty(false);
    };

    // generate new random key to force whole page re-mount
    handleCancel = () => {
        // Related BookEditor will be re-mounted => registerGetContentFn will be fired again => reset the current references
        this.getEditorContentFns = {};
        this.setState({ componentKey: getEditorComponentKey(), isReMounting: true });
        this.props.onSetDirty(false);
    };

    handleChangeWidgetTitle = ({ title, hiddenTitle }) => {
        const { onChangeWidgetTitle, widget } = this.props;
        onChangeWidgetTitle({ widgetId: widget.key, title, hiddenTitle });
    }

    render() {
        const { widget, items, onCloseItemEditor, canEdit, canReview, isDirty, asReview } = this.props;
        const { toolbarRef, componentKey } = this.state;

        resetFirstTextEditor();

        return (
            <div style={{ marginTop: "1em" }}>
                <div className="toolbarArea">
                    <div className="widgetEditorToolbar" ref={this.toolbarRef} />
                    {toolbarRef && isDirty ? (
                        <SaveCancelInToolbar onSave={this.handleSave} onCancel={this.handleCancel} />
                    ) : <div />}
                </div>
                <Container
                    className="spork widget-editor"
                    style={{ maxHeight: "63vh", overflowY: "auto", marginTop: "0.60em" }}
                >
                    <Widget
                        key={componentKey}
                        template={{ ...widget.template, key: widget.templateId }}
                        customTitle={widget.custom_title}
                        hiddenTitle={widget.hidden_title}
                        onChangeTitle={this.handleChangeWidgetTitle}
                    >
                        {!items.length ? (
                            <NoDataPlaceholder text="Empty widget" />
                        ) : (
                            <WidgetEditorBody
                                widget={widget}
                                onCloseItemEditor={onCloseItemEditor}
                                toolbarRef={toolbarRef}
                                canReview={canReview}
                                canEdit={canEdit}
                                asReview={asReview}
                                registerGetContentFn={this.registerGetContentFn}
                                onDirty={this.handleDirty}
                                checkFirstText={checkFirstTextEditor}
                            />
                        )}
                    </Widget>
                </Container>
            </div>
        );
    }
}


const dispatchToProps = dispatch => bindActionCreators({
    onChangeWidgetTitle,
    onSetDirty,
}, dispatch);

export default connect((state) => ({
    isDirty: isDirty(state),
}),dispatchToProps)(WidgetEditor);
