import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withFirebase } from "react-redux-firebase";

import ResourcesPage from "./ResourcesPage";
import {
    onResourcesPageLeft,
    onFetchAllResources,
    onViewDetails,
    onFilterByName,
    onFilterByTags,
    onFilterByCourseVariant,
    onFilterByOwner,
    onFilterByResourceType,
    onOpenFilterBy,
    onFilterByTopic,
} from "./ResourcesActions";
import {
    getResourcesList,
    isLoading,
    getSelectedResourceId,
    getFilterIsOpen,
    getFilterParams,
    getFilterByName,
    getTopicFilterName,
} from "./ResourcesSelectors";

const mapStateToProps = (state, props) => ({
    resourceList: getResourcesList(state),
    isLoading: isLoading(state),
    selectedItem: getSelectedResourceId(state, props),
    filterIsOpen: getFilterIsOpen(state),
    filterParams: getFilterParams(state),
    filterByName: getFilterByName(state),
    topicFilterName: getTopicFilterName(state),
});

const dispatchToProps = dispatch => bindActionCreators({
    onResourcesPageLeft,
    onFetchAllResources,
    onViewDetails,
    onFilterByName,
    onFilterByTags,
    onFilterByCourseVariant,
    onFilterByResourceType,
    onOpenFilterBy,
    onFilterByOwner,
    onFilterByTopic,
}, dispatch);

export default compose(
    withFirebase,
    connect(mapStateToProps, dispatchToProps)
)(ResourcesPage);
