import { all, takeLatest, put, select } from "redux-saga/effects";
import { TYPES, onClose } from "./CreateKitActions";
import { aKitManager } from "../../dataSource";
import { loggedUserId, tenantId } from "../../../Users/UserProfile/UserProfileSelectors";
import { onEditKit } from "../EditKitProperties/EditKitActions";
import { toastError, DB_ERROR } from "../../../component/toast";
import { client } from "../../../feathersjs/client";

const kitService = client.service("kit");

export function* aKitCreate( { payload: { course_variant, name } } ) {
    try {
        const userId = yield select(loggedUserId);
        const tenant = yield select(tenantId);
        const { key, outlineId } = yield aKitManager.createKit(name, userId, new Date().toISOString(), course_variant, tenant);
        const kit = yield kitService.create({
            fb_id: key,
            outline_id: outlineId,
            tenant_id: tenant,
            name
        });
        yield aKitManager.updateKit(key, { id: kit.id });
        yield put(onClose());
        yield put(onEditKit(key));
    } catch (error) {
        toastError({ code: error.code, header: "Failed to create author kit", message: DB_ERROR });
    }

}


export default function* saga() {
    yield all([
        takeLatest(TYPES.CREATE_AKIT_CREATE, aKitCreate),
    ]);
}