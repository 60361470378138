/* eslint-disable indent */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Divider, Button, Segment, Table } from "semantic-ui-react";
import "./SiPiContent.css";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import ActionLinks from "../../component/ActionLinks";
import SiPiListItem from "./SiPiListItem";

const SortableItem = SortableElement(
  ({ sipiId, sipi, onRemoveSiPi, onEdit }) => (
    <SiPiListItem
      sipiId={sipiId}
      sipi={sipi}
      onRemoveSiPi={onRemoveSiPi}
      onEdit={onEdit}
      draggable
    />
  )
);

const SortableList = SortableContainer(
  ({ sipiContent, onRemoveSiPi, onEdit }) => (
    <Table.Body>
      {sipiContent &&
        sipiContent.map(
          ({ key, value }, index) =>
            value && (
              <SortableItem
                key={key}
                index={index}
                sipiId={key}
                sipi={value}
                onRemoveSiPi={onRemoveSiPi}
                onEdit={onEdit}
              />
            )
        )}
    </Table.Body>
  )
);

export default class SiPiContent extends PureComponent {
  static propTypes = {
    iPlannerSiPi: PropTypes.object,
    lessonUnit: PropTypes.object,
    onUpdateSiPiOrder: PropTypes.func,
    onEdit: PropTypes.func,
    onRemoveSiPi: PropTypes.func,
    onAdd: PropTypes.func,
    enableSyllabusFeatures: PropTypes.bool,
    onOpenCloner: PropTypes.func,
    onOpenTestCreator: PropTypes.func,
    template: PropTypes.bool,
    hidePlaceholder: PropTypes.bool
  };

  handleChangeOrdering = ({ oldIndex, newIndex }) => {
    const { onUpdateSiPiOrder, iPlannerSiPi, lessonUnit } = this.props;
    const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
    let orderedSiPi = arrayMove(
      iPlannerSiPi[`lu_${lessonUnitId}`],
      oldIndex,
      newIndex
    );

    orderedSiPi.map((item, index) => {
      item.value.ordering = index + 1;
      return item.key;
    });
    onUpdateSiPiOrder({ lessonUnitId, orderedSiPi });
  };

  handleEdit = ({ sipiId, sipi }) => {
    const { onEdit, lessonUnit } = this.props;
    const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
    onEdit(lessonUnitId, sipi, sipiId);
  };

  handleRemove = (e, sipiId) => {
    const { lessonUnit, onRemoveSiPi } = this.props;
    const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
    onRemoveSiPi(e, { lessonUnitId, sipiId });
  };

  handleAddSiPi = () => {
    const { lessonUnit, onAdd } = this.props;
    const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
    onAdd(lessonUnitId);
  };

  handleOpenCloner = () => {
    const { onOpenCloner, lessonUnit } = this.props;
    const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
    onOpenCloner(lessonUnitId);
  };

  handleOpenTesCreator = () => {
    const { onOpenTestCreator, lessonUnit } = this.props;
    const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
    onOpenTestCreator(lessonUnitId);
  };

  render() {
    const {
      iPlannerSiPi,
      lessonUnit,
      enableSyllabusFeatures,
      template,
      hidePlaceholder
    } = this.props;
    const lessonUnitId = lessonUnit.lu_id || lessonUnit.cv_lu_id;
    const actions = [
      {
        name: "new",
        content: "Create instrument",
        icon: "plus",
        onClick: this.handleAddSiPi
      },
      {
        name: "test",
        content: "Create tests",
        icon: "ballot check outline",
        onClick: this.handleOpenTesCreator
      }
    ];
    enableSyllabusFeatures &&
      actions.push({
        name: "copy",
        content: "Copy from template",
        icon: "clone outline",
        onClick: this.handleOpenCloner
      });
    return (
      <div style={{ paddingTop: "1em" }}>
        <React.Fragment>
          {((!iPlannerSiPi ||
            !iPlannerSiPi[`lu_${lessonUnitId}`] ||
            0 >= iPlannerSiPi[`lu_${lessonUnitId}`].length) &&
            !template &&
            !hidePlaceholder && (
              <NoDataPlaceholder
                style={{ minHeight: 0 }}
                icon="file times outline"
                text="No iPlanner Instruments in the lesson"
              >
                <Segment.Inline style={{ textAlign: "center" }}>
                  <Button compact onClick={this.handleAddSiPi} primary>
                    Add Instrument
                  </Button>
                  <Button compact onClick={this.handleOpenTesCreator}>
                    Add Test
                  </Button>
                  {enableSyllabusFeatures && (
                    <React.Fragment>
                      <Divider horizontal>OR</Divider>
                      <Button compact onClick={this.handleOpenCloner}>
                        Copy from template
                      </Button>
                    </React.Fragment>
                  )}
                </Segment.Inline>
              </NoDataPlaceholder>
            )) ||
            null}
        </React.Fragment>
        <Divider clearing hidden fitted />
        {template ? (
          iPlannerSiPi &&
          iPlannerSiPi[`lu_${lessonUnitId}`] && (
            <ReadOnlySiPiList
              sipiContent={iPlannerSiPi[`lu_${lessonUnitId}`]}
            />
          )
        ) : (
          <EditSiPiList
            sipiContent={iPlannerSiPi && iPlannerSiPi[`lu_${lessonUnitId}`]}
            hidePlaceholder={hidePlaceholder}
            handleChangeOrdering={this.handleChangeOrdering}
            handleRemove={this.handleRemove}
            handleEdit={this.handleEdit}
            actions={actions}
          />
        )}
      </div>
    );
  }
}

const ReadOnlySiPiList = ({ sipiContent }) => (  

    sipiContent !== undefined && <Table style={{ marginTop: "0" }} className="sipiTableList" columns="2">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan="2">List of instruments</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
        {sipiContent.map(
          ({ key, value }) =>
            value && <SiPiListItem key={key} sipiId={key} sipi={value} />
        )}
    </Table.Body>
  </Table>
);

ReadOnlySiPiList.propTypes = {
  sipiContent: PropTypes.array
};

const EditSiPiList = ({
  sipiContent,
  hidePlaceholder,
  handleChangeOrdering,
  handleRemove,
  handleEdit,
  actions
}) => (
    (sipiContent || hidePlaceholder) && <Table
    style={{ marginTop: "0" }}
    className="sortableContainer sipiTableList"
    columns="2"
  >
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan="2">List of instruments</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    {sipiContent && (
      <SortableList
        className="sortableList"
        onSortEnd={handleChangeOrdering}
        helperClass="sortableHelper ui table sipiTableList"
        useDragHandle
        sipiContent={sipiContent}
        onRemoveSiPi={handleRemove}
        onEdit={handleEdit}
      />
    )}
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan="2">
            <ActionLinks horizontal actions={actions} />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  </Table>
);

EditSiPiList.propTypes = {
  sipiContent: PropTypes.array,
  hidePlaceholder: PropTypes.bool,
  handleChangeOrdering: PropTypes.func,
  handleRemove: PropTypes.func,
  handleEdit: PropTypes.func,
  actions: PropTypes.array
};
