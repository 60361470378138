/**
 * Example:
 *     ROLE_SUPERAUTHOR: {
 *         name: "Super Author",    // display name of role
 *         plural: "Super Authors", // display name in case of count > 1 (e.g. on tenant card)
 *         static: ["posts:list", "posts:create", "users:getSelf", "home-page:visit", "dashboard-page:visit"],
 *         dynamic: {
 *            "posts:edit": ({ userId, postOwnerId }) => {
 *                if (!userId || !postOwnerId) return false;
 *                return userId === postOwnerId;
 *             },
 *         },
 *     },
 */

const isCharterTenant = (context) => !!(context && context.tenantId === 1);

const isUserIdEqualTo = (matchTo) => {
    const field = typeof matchTo === "string" && matchTo.trim();
    if (!field) {
        throw new Error(`Invalid name of field to match (${String(matchTo)})`);
    }
    return (context) => {
        const userId = context && context.userId;
        return userId ? userId === context[field] : false;
    };
};

const isUserAuthor = isUserIdEqualTo("authorId");
const isUserLocker = isUserIdEqualTo("lockedBy");
const isUserCommentOwner = isUserIdEqualTo("commentOwnerId");
const isUserResourceOwner = isUserIdEqualTo("resourceOwnerId");

const isResourceFromSameTenant = ({ tenantId, resourceTenantId }) => !!(
    tenantId && resourceTenantId && tenantId === resourceTenantId
);

const isUserWithoutMiner = () => true;  // TODO (pbenes) solve it before features.flag "school_tool" will be removed

const reasourceEditRights =  {
    "resource:edit": isResourceFromSameTenant,
    "resource:setOwner": isResourceFromSameTenant,
};

const roles = {
    ROLE_ADMIN: {
        name: "Admin",
        plural: "Admins",
        static: [
            "maintenance:visit",
            "problemSet:manage",
            "problemSet:edit",
            "problemSet:review",
            "problemSet:visit",
            "compTests:manage",
            "compTests:edit",
            "compTests:review",
            // "compTests:visit",
            "users:manage",
            "comment:edit",
            "comment:delete",
            "comment:resolve",
            "courseVariant:manage",
            "tablet:manage",
            "iplanner:visit",
            "syllabi:visit",
            "syllabi:manage",
            "syllabi:create",
            "sipiTemplates:visit",
            "sipiTemplates:manage",
            "sipiTemplates:create",
            "aKit:create",
            "aKit:sync",
            "aKit:publish",
            "topicCollection:sync",
            "lock:lock",
            "lock:unlock",
            "tenant:manage",
            "modules:visit",
            "modules:manage",
            "modules:subscribe",
            "publishedContent:view",
            "ttt:manage",
        ],
        dynamic: {
            ...reasourceEditRights,
            "compTests:charter": isCharterTenant,
            "years:manage": isUserWithoutMiner,
            "schools:manage": isUserWithoutMiner,
        },
    },
    ROLE_SUPERAUTHOR: {
        name: "Super Author",
        plural: "Super Authors",
        static: [
            "maintenance:visit",
            "problemSet:manage",
            "problemSet:visit",
            "problemSet:edit",
            "problemSet:review",
            "users:manage",
            "comment:edit",
            "comment:delete",
            "comment:resolve",
            "courseVariant:manage",
            "iplanner:visit",
            "syllabi:visit",
            "sipiTemplates:visit",
            "aKit:create",
            "aKit:sync",
            "aKit:publish",
            "topicCollection:sync",
            "lock:lock",
            "lock:unlock",
            "modules:visit",
            "modules:manage",
            "publishedContent:view",
        ],
        dynamic: {
            ...reasourceEditRights
        },
    },
    ROLE_SUPERSAD: {
        name: "Super Mentor Advisor",
        plural: "Super Mentor Advisors",
        static: [
            "iplanner:visit",
            "syllabi:visit",
            "syllabi:manage",
            "syllabi:create",
            "sipiTemplates:visit",
            "sipiTemplates:manage",
            "sipiTemplates:create",
        ],
        dynamic: {
            ...reasourceEditRights
        },
    },
    ROLE_QAD: {
        name: "Question Advisor",
        plural: "Question Advisors",
        static: [
            "compTests:manage",
            "compTests:edit",
            "compTests:review",
            // "compTests:visit",
            "lock:lock",
        ],
        dynamic: {
            "lock:unlock": isUserLocker,
            "compTests:charter": isCharterTenant,
        },
    },
    ROLE_RESOURCE_ADMIN: {
        name: "Resource Admin",
        plural: "Resource Admins",
        static: [
            "iplanner:visit",
            "syllabi:visit",
            "sipiTemplates:visit",
        ],
        dynamic: {
            ...reasourceEditRights
        },
    },
    ROLE_AUTHOR: {
        name: "Author",
        plural: "Authors",
        static: [
            "problemSet:manage",
            "problemSet:visit",
            "problemSet:edit",
            "syllabi:visit",
            "sipiTemplates:visit",
            "iplanner:visit",
            "lock:lock",
        ],
        dynamic: {
            "comment:edit": isUserCommentOwner,
            "comment:delete": isUserCommentOwner,
            "lock:unlock": isUserLocker,
        },
    },
    ROLE_REVIEWER: {
        name: "Reviewer",
        plural: "Reviewers",
        static: [
            "syllabi:visit",
            "sipiTemplates:visit",
            "iplanner:visit",
            "problemSet:review",
            "problemSet:visit",
        ],
    },
    ROLE_USER: {
        name: "User",
        plural: "Users",
        static: [
            "syllabi:visit",
            "sipiTemplates:visit",
            "iplanner:visit",
            "syllabi:create",
            "problemSet:create",
            "problemSet:author",
            "problemSet:visit",
        ],
        dynamic: {
            "comment:delete": isUserCommentOwner,
            "syllabi:manage": ({ teacher, userId, ccas }) => {
                if ((!teacher && !ccas ) || !userId) return false;
                return teacher === userId || ccas && !!ccas[userId];
            },
            "sipiTemplates:manage": ({ ccas, userId }) => {
                if (!ccas || !userId) return false;
                return !!ccas[userId];
            },
            "resource:edit": isUserResourceOwner,
            "problemSet:edit": isUserAuthor,
            "lock:lock": isUserAuthor,
            "lock:unlock": isUserLocker,
        },
    },
    ROLE_TABLET_ADMIN: {
        name: "Tablet Administrator",
        plural: "Tablet Administrators",
        static: ["tablet:manage", "publishedContent:view"],
    },
};

module.exports = roles;
