exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1CE6puzu0P95G0qENuXYRz {\n  background-color: lightgoldenrodyellow; }\n\n._3mLnD2uZ_PG4W6iXXIgtLV {\n  background-color: whitesmoke; }\n\n.WN7n7He7bUAmF01G6NiFu {\n  border: 1px dashed gray; }\n\n.FBQAWJR2nFm0EiqfKZ-MA {\n  position: static !important;\n  padding: 10px; }\n", ""]);

// exports
exports.locals = {
	"drag-over": "_1CE6puzu0P95G0qENuXYRz",
	"no-drag-over": "_3mLnD2uZ_PG4W6iXXIgtLV",
	"order-mode": "WN7n7He7bUAmF01G6NiFu",
	"set-reorder-block": "FBQAWJR2nFm0EiqfKZ-MA"
};