import memoizeOne from "memoize-one";

export const setTypes = [
    { key: "hw", value: "hw", text: "Homework" },
    { key: "qz", value: "qz", text: "Quiz" },
    { key: "te", value: "te", text: "Test" },
    { key: "pp", value: "pp", text: "Practice Problems" },
    { key: "fp", value: "fp", text: "Fun Problems" },
];

export const allSetTypes = [
    ...setTypes,
    { key: "pc", value: "pc", text: "PreComp Test" },
];

export const typesAsObject = memoizeOne(types => {
    const typesObj = {};
    for (const type of types) {
        typesObj[type.value] = type.text;
    }
    return typesObj;
});
