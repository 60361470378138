import React from "react";
import PropTypes from "prop-types";

import WidgetBlock from "./WidgetBlock";
import FileItemContent from "./FileItemContent";
import LinkItemContent from "./LinkItemContent";
import TextItemContent from "./TextItemContent";
import QuestionItemContent from "./QuestionItemContent";

const ComponentsByType = {
    text: TextItemContent,
    link: LinkItemContent,
    file: FileItemContent,
    question: QuestionItemContent,
};

export default class WidgetItem extends React.PureComponent {
    static propTypes = {
        template: PropTypes.object.isRequired,
        content: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
        children: PropTypes.node,
        onChangeTitle: PropTypes.func,
    }

    render() {
        const { template, children, onChangeTitle, content } = this.props;
        const ItemContent = ComponentsByType[template.type];
        if (children) {
            return (
                <WidgetBlock {...template} onChangeTitle={onChangeTitle} customTitle={content && content.custom_title} hiddenTitle={content && content.hidden_title}>
                    {children}
                </WidgetBlock>
            );
        } else {
            return ItemContent ? (
                <WidgetBlock {...template} onChangeTitle={onChangeTitle} customTitle={content && content.custom_title} hiddenTitle={content && content.hidden_title}>
                    <ItemContent {...this.props} />
                </WidgetBlock>
            ) : null;
        }
    }
}
