export const TYPES = {
    OSM_SELECT_OBJECTIVESET: "OSM_SELECT_OBJECTIVESET",
    OSM_UPDATE_OBJECTIVESET_NAME: "OSM_UPDATE_OBJECTIVESET_NAME",
    OSM_NEW_OBJECTIVESET: "OSM_NEW_OBJECTIVESET",
    OSM_REMOVE_OBJECTIVESET: "OSM_REMOVE_OBJECTIVESET",
    OSM_UPDATE_OBJECTIVE_NAME: "OSM_UPDATE_OBJECTIVE_NAME",
    OSM_NEW_OBJECTIVE: "OSM_NEW_OBJECTIVE",
    OSM_REORDER_OBJECTIVES: "OSM_REORDER_OBJECTIVES",
    OSM_REMOVE_OBJECTIVE: "OSM_REMOVE_OBJECTIVE",
    OSM_REMOVE_OII_OBJECTIVE: "OSM_REMOVE_OII_OBJECTIVE",
    OSM_UPDATE_OII_OBJECTIVE_NAME: "OSM_UPDATE_OII_OBJECTIVE_NAME",
    OSM_ON_ERROR: "OSM_ON_ERROR",
    OSM_CLEAR_ERROR: "OSM_CLEAR_ERROR",
};

export const onSelectObjectiveSet = (value) => {
    return {
        type: TYPES.OSM_SELECT_OBJECTIVESET,
        value,
    };
};

export const onUpdateObjectiveSetName = (value, props, onError) => {
    // console.log("onUpdateObjectiveSetName:", props);
    return ({
        type: TYPES.OSM_UPDATE_OBJECTIVESET_NAME,
        payload: {
            updated_name: value,
            id: props.id,
            onError,
        },
    });
};

export const onAddObjectiveSet = (newName, setEditTextId, onError) => (
    {
        type: TYPES.OSM_NEW_OBJECTIVESET,
        payload: {
            newName,
            setEditTextId,
            onError,
        },
    }
);

export const onUpdateObjectiveName = (value, props, onError) => {
    // console.log("onUpdateObjectiveName:", props);
    return ({
        type: TYPES.OSM_UPDATE_OBJECTIVE_NAME,
        payload: {
            updated_name: value,
            id: props.id,
            objective_set_id: null,
            props, /* !! prozatim, naplnit smyslup[lne] */
            onError,
        },
    });
};

export const onUpdateOiiObjectiveName = (value, id, onError) => {
    return ({
        type: TYPES.OSM_UPDATE_OII_OBJECTIVE_NAME,
        payload: {
            updated_name: value,
            id,
            onError,
        },
    });
};

export const onAddObjective = (objectiveSetId, newObjective, setEditTextId, onError) => {
    // console.log(`onAddObjective: oSetId=${objectiveSetId}, newObjective:`, newObjective);
    return ({
        type: TYPES.OSM_NEW_OBJECTIVE,
        payload: {
            objectiveSetId,
            newObjective,
            setEditTextId,
            onError,
        },
    });
};

export const onChangeOrdering = (objectiveSetId, updatedOrdering, onError) => {
    return ({
        type: TYPES.OSM_REORDER_OBJECTIVES,
        payload: {
            objectiveSetId,
            updatedOrdering,
            onError,
        },
    });
};

export const onRemoveObjective = (objectiveId, props, onSuccess, onFailure, onError) => {
    // console.log("onUpdateObjectiveName:", props);
    return ({
        type: TYPES.OSM_REMOVE_OBJECTIVE,
        payload: {
            id: objectiveId,
            props,
            onSuccess,
            onFailure,
            onError,
        },
    });
};

export const onRemoveOiiObjective = (objectiveId, props, onSuccess, onFailure, onError) => {
    // console.log("onUpdateObjectiveName:", props);
    return ({
        type: TYPES.OSM_REMOVE_OII_OBJECTIVE,
        payload: {
            id: objectiveId,
            props,
            onSuccess,
            onFailure,
            onError,
        },
    });
};

export const onRemoveObjectiveSet = (objectiveSetId, props, onSuccess, onFailure, onError) => {

    return ({
        type: TYPES.OSM_REMOVE_OBJECTIVESET,
        payload: {
            id: objectiveSetId,
            props,
            onSuccess,
            onFailure,
            onError,
        },
    });
};

export const onObjectiveSetError = (value) => {
    return {
        type: TYPES.OSM_ON_ERROR,
        value,
    };
};

export const onClearObjectiveSetError = () => {
    return {
        type: TYPES.OSM_CLEAR_ERROR,
    };
};
