import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class PlaceholderText extends PureComponent {
    static propTypes = {
        text: PropTypes.string,
    }

    render() {
        return (
            <i style={{ color: "rgba(0,0,0,.4)" }}>{this.props.text}</i>
        );
    }
}