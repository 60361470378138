import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { spinnerWhileLoading } from "../../../component/spinnerWhileLoading.jsx";
import { onSelectCollection } from "../belaTopicCollectionActions";
import CollectionList from "./CollectionList";

const mapStateToProps = function(state) {
    return {
        collections: state.firebase.ordered.topicCollectionsFromBeLA
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onSelectCollection
        },
        dispatch
    );
};

export default compose(
    firebaseConnect(() => {
        return [ { path: "/topicCollectionsFromBeLA", queryParams: [ "orderByChild=collection_name" ] } ];
    }),
    connect(mapStateToProps, dispatchToProps),
    // show loading spinner while data are loading
    spinnerWhileLoading([ "collections" ])
)(CollectionList);
