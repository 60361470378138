import { put, takeLatest, all, fork } from "redux-saga/effects";
import firebase from "../../firebase";
import {
    TYPES,
    onLessonPublishSuccess,
    onLessonPublishFailure,
} from "./PublisherActions";
import LessonExportsSaga from "./LessonExports/LessonExportsSaga";
import { toastError, FUNCTION_ERROR } from "../../component/toast";

function* requestLessonPublish({ payload }) {
    const { outlineId, lessonId } = payload;
    try {
        const publishLesson = firebase.getFirebaseFunctions().httpsCallable("publishLesson");
        const response  = yield publishLesson(payload);
        yield put(onLessonPublishSuccess(outlineId, lessonId, response));
    } catch (ex) {
        const message = ex && ex.message || "";
        toastError({ code: FUNCTION_ERROR , header: "Unable to publish lesson content.", message });
        yield put(onLessonPublishFailure(outlineId, lessonId, ex));
    }
}

export default function* publisherSaga() {
    yield all([
        takeLatest(TYPES.PUBLISH_LESSON_REQUEST, requestLessonPublish),
        fork(LessonExportsSaga),
    ]);
}
