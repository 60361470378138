import React from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

import Comments from "../Comments";
import CommentsMenu from "../Comments/CommentsMenu";

const buildCommentsContext = memoize(
    (outlineId, lessonId, outlineName, lessonName, recipients) => ({
        recipients,
        context: {
            lessonPath: `lesson/content/${outlineId}/${lessonId}`,
            lessonName: lessonName,
            outlineName,
        }
    })
);

const buildCommentsOptions = memoize(
    (lessonId, commentsContext) => ([
        {
            title: "Comments",
            threadId: lessonId,
            threadType: "comments",
            notification: commentsContext,
        },
        {
            title: "User Comments",
            threadId: `user_comments_${lessonId}`,
            threadType: "user_comments",
            notification: commentsContext,
        }
    ])
);


export default class LessonComments extends React.PureComponent {
    static propTypes = {
        lessonId: PropTypes.string.isRequired,
        lessonName: PropTypes.string,
        outlineId: PropTypes.string.isRequired,
        outlineName: PropTypes.string,
        notifyUsers: PropTypes.array,
        teacherOnly: PropTypes.bool,
    };

    render() {
        const { lessonId, lessonName, outlineId, outlineName, notifyUsers, teacherOnly } = this.props;
        const commentsContext = buildCommentsContext(outlineId, lessonId, outlineName, lessonName, notifyUsers);
        // For teacherOnly/readOnly access to lesson content show only the user comments (user = teacher)
        return teacherOnly ? (
            <Comments threadId={`user_comments_${lessonId}`} threadType="user_comments" notification={commentsContext} />
        ) : (
            <CommentsMenu commentsOptions={buildCommentsOptions(lessonId, commentsContext)} />
        );
    }
}
