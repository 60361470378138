import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Menu, Table, Button } from "semantic-ui-react";
import { convertDateIsoToLocal } from "../../../utils/dateUtils";

export default class SyncLog extends PureComponent {
    static propTypes = {
        syncStatus: PropTypes.array
    }

    state = {
        isOpen: false,
    }
    handleOpen = () => this.setState({ isOpen: true });
    handleClose = () => this.setState({ isOpen: false });

    render() {
        const { isOpen } = this.state;
        const { syncStatus } = this.props;

        return (
            <Modal
                trigger={<Menu.Item>Show sync log</Menu.Item>}
                open={isOpen}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
            >
                <Modal.Header>Sync log</Modal.Header>
                <Modal.Content scrolling>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Creation</Table.HeaderCell>
                                <Table.HeaderCell>Last update</Table.HeaderCell>
                                <Table.HeaderCell>State</Table.HeaderCell>
                                <Table.HeaderCell>Schools</Table.HeaderCell>
                                <Table.HeaderCell>Message</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {syncStatus && syncStatus.map(status => (
                                <Table.Row key={status.key} error={"ERROR" === status.sync_status}>
                                    <Table.Cell>{convertDateIsoToLocal(status.created)}</Table.Cell>
                                    <Table.Cell>{status.updated ? convertDateIsoToLocal(status.updated) : ""}</Table.Cell>
                                    <Table.Cell>{status.sync_status}</Table.Cell>
                                    <Table.Cell>{status.tenant}</Table.Cell>
                                    <Table.Cell>{status.sync_error}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions><Button onClick={this.handleClose}>Close</Button></Modal.Actions>
            </Modal>
        );
    }
}
