import { EditorState, Modifier, SelectionState, RichUtils } from "draft-js";
import { getSelectedBlock, setBlockData } from "draftjs-utils";
import { CHANGE_TYPE_FOR_UNDO } from "../constants";

const isListItem = (editorState) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    return currentBlockType === "unordered-list-item" || currentBlockType === "ordered-list-item";
};

const isOrderedListItem = (editorState) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    return currentBlockType === "ordered-list-item";
};

/**
 * It is expected that the current selection is inside list item (<LI>) and it is collapsed.
 * When the selection (caret) is at position 0, the new list item will be inserted before the current one.
 * For any other caret position it will be inserted after the current list item.
 */
const insertListItem = (editorState) => {
    let insertAfter = false;
    let selection = editorState.getSelection();

    if (selection.getAnchorOffset() !== 0) {
        const blockLength = getSelectedBlock(editorState).getLength();
        selection = selection.merge({
            anchorOffset: blockLength,
            focusOffset: blockLength,
        });
        insertAfter = true;
    }

    const afterSplitContent = Modifier.splitBlock(
        editorState.getCurrentContent(),
        selection
    );

    editorState = EditorState.push(editorState, afterSplitContent, "split-block");

    if (insertAfter) {
        const afterSplitSelection = afterSplitContent.getSelectionAfter();
        const newBlockKey = afterSplitSelection.getFocusKey();
        selection = SelectionState.createEmpty(newBlockKey);
    }

    return EditorState.forceSelection(editorState, selection);
};

const removeListItem = (editorState) => {
    const selection = editorState.getSelection();
    const blockKey = selection.getAnchorKey();
    const contentState = editorState.getCurrentContent();
    let blockMap = contentState.getBlockMap();
    blockMap = blockMap.remove(blockKey);

    if (blockMap.count() === 0) {
        // the last ContentBlock was removed
        return EditorState.createEmpty();
    }

    const targetBlockKey = contentState.getKeyBefore(blockKey) || contentState.getKeyAfter(blockKey);
    const newSelection = new SelectionState({
        anchorKey: targetBlockKey,
        anchorOffset: 0,
        focusKey: targetBlockKey,
        focusOffset: 0,
        hasFocus: true,
    });
    const newContentState = contentState.merge({ blockMap, selectionAfter: newSelection });

    return EditorState.push(EditorState.acceptSelection(editorState, newSelection), newContentState, "remove-range");
};

const splitListItemWithData = (editorState) => {
    const data = getSelectedBlock(editorState).getData();
    const afterSplitContent = Modifier.splitBlock(
        editorState.getCurrentContent(),
        editorState.getSelection()
    );

    editorState = EditorState.push(editorState, afterSplitContent, "split-block");
    editorState = setBlockData(editorState, data);
    return editorState;
};

const updateListItemProperties = (editorState, blockKey, data) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const block = contentState.getBlockForKey(blockKey);

    const updatedBlock = block.merge({
        data: block.getData().merge(data),
    });

    const newContentState = contentState.merge({
        blockMap: blockMap.set(blockKey, updatedBlock),
    });

    return editorState = EditorState.push(editorState, newContentState, CHANGE_TYPE_FOR_UNDO);  // "change-block-data"
};

const updateListItemDepth = (editorState, blockKey, depth) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const block = contentState.getBlockForKey(blockKey);
    const updatedBlock = block.merge({ depth });

    const newContentState = contentState.merge({
        blockMap: blockMap.set(blockKey, updatedBlock),
    });

    return editorState = EditorState.push(editorState, newContentState, CHANGE_TYPE_FOR_UNDO);  // "adjust-depth"
};

export {
    isListItem,
    isOrderedListItem,
    insertListItem,
    removeListItem,
    splitListItemWithData,
    updateListItemProperties,
    updateListItemDepth,
};
