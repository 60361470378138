import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import UserProfileDebug from "./UserProfileDebug";
import { authUser } from "../../auth/authSelectors";

import { onChangeRoleGodMode } from "../UserList/UserListActions";

const mapStateToProps = function(state) {
    return {
        authUser: authUser(state),
    };
};

const dispatchToProps = dispatch => bindActionCreators({ onChangeRoleGodMode }, dispatch);

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(UserProfileDebug);
