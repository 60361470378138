import { TYPES } from "./GlossaryActions";

const initialState = {
    filter: "",
    addTerm: false,
    searchFilter: "",
    searching: false,
    searchResult: [],
    outlinePreference: null,
    unusedFilter: false,
    selectedTags: [],
    tagFilter: {},
    isDirty: false,
    moduleFilter: [],
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.WIDGETS_GLOSSARY_CHANGE_FILTER:
            return { ...state, filter: payload };
        case TYPES.WIDGETS_GLOSSARY_ADD_TERM:
            return { ...state, addTerm: true };
        case TYPES.WIDGETS_GLOSSARY_CANCEL_ADD_TERM:
            return { ...state, addTerm: false };
        case TYPES.WIDGETS_GLOSSARY_SEARCH_TERM:
            return { ...state, searchFilter: payload.value, searching: true };
        case TYPES.WIDGETS_GLOSSARY_SET_SEARCH_RESULT:
            return { ...state, searchResult: payload, searching: false };
        case TYPES.WIDGETS_GLOSSARY_SET_PREFERENCE_BY_OUTLINE:
            return { ...state, outlinePreference: payload };
        case TYPES.WIDGETS_GLOSSARY_SELECT_TAG:
            return { ...state, selectedTags: payload };
        case TYPES.WIDGETS_GLOSSARY_CHANGE_UNUSED_FILTER:
            return { ...state, unusedFilter: payload };
        case TYPES.WIDGETS_GLOSSARY_SET_TAG_FILTER:
            return { ...state, tagFilter: payload };
        case TYPES.WIDGETS_GLOSSARY_SET_DIRTY:
            return { ...state, isDirty: payload };
        case TYPES.WIDGETS_GLOSSARY_SET_MODULE_FILTER:
            return { ...state, moduleFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
