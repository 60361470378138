import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { spinnerWhileLoading } from "../../../component/spinnerWhileLoading.jsx";
import TopicTree from "./TopicTree";
import { onTopicStatusChange, onSelectCollection } from "../belaTopicCollectionActions";
import { getSelectedCollection } from "../../tttSelectors";
import { getTopics, isDialogOpen } from "../../../Topics/topicsSelectors";
import { onTopicShowDialog } from "../../../Topics/topicsActions";

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onTopicStatusChange,
            onBack: onSelectCollection,
            showAddDialog: onTopicShowDialog
        },
        dispatch
    );
};

const enhance = compose(
    connect(state => ({
        selectedCollection: getSelectedCollection(state)
    })),
    firebaseConnect(props => {
        return ["topicCollectionsFromBeLA_data/" + props.selectedCollection.id, "/topics"];
    }),
    connect(
        (state, props) => ({
            belaTopics: getVal(state.firebase, `data/topicCollectionsFromBeLA_data/${props.selectedCollection.id}`),
            sporkTopics: getTopics(state),
            isDialogOpen: isDialogOpen(state)
        }),
        dispatchToProps
    ),
    spinnerWhileLoading(["belaTopics", "sporkTopics"])
);

class TopicTreeContainer extends Component {
    render() {
        return <TopicTree {...this.props} />;
    }
}

// Export enhanced component
export default enhance(TopicTreeContainer);
