import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Icon, Message, Segment } from "semantic-ui-react";
import { reportErrorBoundary } from "./onError";

class GlobalErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        reportErrorBoundary(error, info);
    }

    handleGoHome = () => this.setState({ hasError: false });

    render() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <Segment style={{ padding: "6em"}}>
                    <Message size="massive" error icon>
                        <Icon name="exclamation circle" />
                        <Message.Content>
                            <Message.Header>
                                Sorry, something went wrong
                            </Message.Header>
                            <p>Click <Link to="/" onClick={this.handleGoHome}>here</Link> to restore the main screen.</p>
                        </Message.Content>
                    </Message>
                </Segment>
            );
        }

        return this.props.children;
    }
}

export default GlobalErrorBoundary;
