import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Message, Icon } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";


export default class CloneWindow extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        clonedObject: PropTypes.object,
        open: PropTypes.bool.isRequired,
        onClone: PropTypes.func.isRequired,
        entityName: PropTypes.string,
    };

    state = {
        name: "",
        error: "",
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({ name: this.props.clonedObject.name + " (copy)", error: undefined });
        }
    }

    handleClone = () => {
        const { clonedObject } = this.props;
        let { name } = this.state;
        name = name.trim();
        if (0 < name.length && clonedObject && name !== clonedObject.name) {
            this.props.onClone(clonedObject, name);
        } else {
            this.setState({ error: "The name must not be empty and must be different from the original name."});
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value, error: "" });
    }

    render() {
        const { clonedObject, onClose, open, entityName } = this.props;
        const { name, error } = this.state;

        return (
            <Modal open={open} size="tiny">
                <Modal.Header>Duplicate <span className="sporkSecondaryColor">{clonedObject && clonedObject.name}</span></Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleClone}>
                        <Message info icon>
                            <Icon name="clone outline" />
                            <Message.Content>
                        You are about to duplicate the {entityName}. Please enter the new name for the new copy.
                            </Message.Content>   
                        </Message>
                        <Form.Input
                            name="name"
                            required
                            autoFocus
                            label="New Name"
                            placeholder="Fill the name of new clone..."
                            onChange={this.handleChange}
                            value={name}
                        />
                    </Form>
                    {error && <Message error content={error} />}
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons submitText="Clone" onSave={this.handleClone} onCancel={onClose} />
                </Modal.Actions>
            </Modal>
        );
    }
}
