import { TYPES } from "./schoolCourseActions";

// The initial state of the ExampleSimple
const initialState = {
    isLoading: false,
    schoolCourseList: undefined,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.LOAD_SCHOOL_COURSE_LIST:
            return { ...state, isLoading: true };
        case TYPES.SET_SCHOOL_COURSE_LIST:
            return { ...state, schoolCourseList: action.payload, isLoading: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
