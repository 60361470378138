import { all, takeEvery, put, takeLatest, select } from "redux-saga/effects";
import { TYPES } from "./UserListActions";
import { onClaimsUpdating, onUserListSet, onUserSimpleListSet } from "./UserListActions";
import firebase from "../../firebase";
import { toastError } from "../../component/toast";
import { client } from "../../feathersjs/client";
import { tenantId } from "../UserProfile/UserProfileSelectors";

const userService = client.service("user");

function* changeRoles({ payload: { uid, roles } }) {
    let currentFbUser = firebase.getFirebaseAuth().currentUser;
    let updateClaims = firebase.defaultApp.functions().httpsCallable("updateUserClaim");
    try {
        yield put(onClaimsUpdating(true));
        yield updateClaims({ uid, claim: roles });
        // we're changing roles for currently authenticated user - let's renew token with new claims
        if (currentFbUser.uid === uid) {
            firebase.getFirebaseAuth().currentUser.getIdToken(true);
        }
    } catch (err) {
        toastError({ code: err.code, header: "Updating user roles failed", message: err.message });
    }
    yield put(onClaimsUpdating(false));
}

// this function is only for debugging - allows currently logged in user to assume any role.
// won't work in production because there is no "updateUserClaimGodMode" function there.
function* changeRolesGodMode({ payload: { roles } }) {
    let updateClaimsGodMode = firebase.defaultApp.functions().httpsCallable("updateUserClaimGodMode");
    try {
        yield updateClaimsGodMode({ roles });
        firebase.getFirebaseAuth().currentUser.getIdToken(true);
    } catch (err) {
        toastError({ code: err.code, header: "Updating user roles failed", message: err.message });
    }
}

function* loadUsersList({ payload }) {
    try {
        // payload can be either { school_id: } or {school_course_id: }
        const query = Object.keys(payload).map(q => `${q}=${payload[q]}`);
        let users = yield firebase.tabletProxy({ method: "GET", uri: "/user?" + query });
        yield put(onUserListSet(users.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of user list failed.", message: err.message });
        yield put(onUserListSet(undefined));
    }
}

function* loadSimpleUsersList() {
    try {
        const tenant_id = yield select(tenantId);
        let users = yield userService.find({ query: { tenant_id, no_details: true, valid: true, scope: "teacher", $sort: { last_name: 1, first_name: 1 } }});
        yield put(onUserSimpleListSet(users));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of user list failed.", message: err.message });
        yield put(onUserSimpleListSet(undefined));
    }
}

export default function* userListSaga() {
    yield all([
        takeEvery(TYPES.USER_LIST_CHANGE_ROLE, changeRoles),
        takeEvery(TYPES.USER_LIST_CHANGE_ROLE_GOD_MODE, changeRolesGodMode),
        takeLatest(TYPES.USER_LIST_LOAD, loadUsersList),
        takeLatest(TYPES.USER_SIMPLE_LIST_LOAD, loadSimpleUsersList),
    ]);
}
