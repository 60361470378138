import React from "react";
import { Segment, List } from "semantic-ui-react";
import styles from "../bookEditor.module.scss";

const KeyboardShortcuts = () => (<Segment secondary className={styles.explanation}>

    <p>Keyboard short-cuts (on Mac use ⌘ instead of Ctrl and Option instead of Alt):</p>
    <List bulleted as="ul">
        <List.Item as="li"><kbd>Ctrl</kbd>+<kbd>B</kbd> - Bold</List.Item>
        <List.Item as="li"><kbd>Ctrl</kbd>+<kbd>I</kbd> - Italic</List.Item>
        <List.Item as="li"><kbd>Ctrl</kbd>+<kbd>U</kbd> - Underline</List.Item>
        {//<List.Item as="li"><del><kbd>Ctrl</kbd>+<kbd>P</kbd> - image</del> (not implemented yet)</List.Item>
        }
        <List.Item as="li"><kbd>Ctrl</kbd>+<kbd>M</kbd> - Math formula</List.Item>
        <List.Item as="li"><kbd>Ctrl</kbd>+<kbd>G</kbd> - Glossary</List.Item>
        <List.Item as="li"><kbd>Alt</kbd>+<kbd>M</kbd> - Format text as Math formula</List.Item>
        <List.Item as="li"><kbd>Alt</kbd>+<kbd>S</kbd> - Styled block</List.Item>
        <List.Item as="li"><kbd>Alt</kbd>+<kbd>T</kbd> - Table (Ctrl+T is a new Tab in browser)</List.Item>
        <List.Item as="li"><kbd>Alt</kbd>+<kbd>L</kbd> - Link to lesson</List.Item>
        <List.Item as="li"><kbd>Alt</kbd>+<kbd>C</kbd> - Align text block or Math formula to center</List.Item>

    </List>
</Segment>);

export default KeyboardShortcuts;
