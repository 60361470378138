import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import {  getUnresolvedCommentsCount, populates } from "./commentsSelectors";
import CommentsHeader from "./CommentsHeader";

const mapStateToProps = (state, props) => ({
    unresolvedCommentsCount: getUnresolvedCommentsCount(state, props),
});

export default compose(
    firebaseConnect((props) => (
        props.threadId ? [{ path: `/comments/${props.threadId}`, populates }] : [])
    ),
    connect(mapStateToProps),
)(CommentsHeader);
