const TYPES = {
    IMPORT_HOMEWORK: "IMPORT_HOMEWORK",
    IMPORT_HOMEWORK_START: "IMPORT_HOMEWORK_START",
    IMPORT_HOMEWORK_STOP: "IMPORT_HOMEWORK_STOP",
    IMPORT_HOMEWORK_PROGRESS: "IMPORT_HOMEWORK_PROGRESS",
    IMPORT_HOMEWORK_ERROR: "IMPORT_HOMEWORK_ERROR",
};

const onImportHomework = payload => {
    return {
        type: TYPES.IMPORT_HOMEWORK,
        payload,
    };
};

const onImportHomeworkStart = payload => {
    return {
        type: TYPES.IMPORT_HOMEWORK_START,
        payload,
    };
};

const onImportHomeworkStop = payload => {
    return {
        type: TYPES.IMPORT_HOMEWORK_STOP,
        payload,
    };
};

const onImportHomeworkProgress = (processed, total) => {
    return {
        type: TYPES.IMPORT_HOMEWORK_PROGRESS,
        payload: { processed, total },
    };
};

const onImportHomeworkError = error => {
    return {
        type: TYPES.IMPORT_HOMEWORK_ERROR,
        payload: error,
    };
};

export {
    TYPES,
    onImportHomework,
    onImportHomeworkStart,
    onImportHomeworkStop,
    onImportHomeworkProgress,
    onImportHomeworkError,
};
