import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { DragHandle } from "../../component/DragHandle";
import { EditActionIcon } from "bmd-react";
import RemoveIcon from "../../component/RemoveIcon";
import SiPiItem from "./SiPiItem";
import "./SiPiListItem.css";

export default class SiPiListItem extends PureComponent {
    static propTypes = {
        sipiId: PropTypes.string,
        sipi: PropTypes.object,
        onRemoveSiPi: PropTypes.func,
        onEdit: PropTypes.func,
        draggable: PropTypes.bool,
    }

    render() {
        const  { sipiId, sipi, onRemoveSiPi, onEdit, draggable } = this.props;
        return (
            <Table.Row key={sipiId} className="editable sortableItem " >
                <Table.Cell width="15">
                    <div className="SiPiListItem">
                        {draggable && <DragHandle entityName="instrument" float="left" /> || <div />}
                        <SiPiItem sipi={sipi} />
                    </div>
                </Table.Cell>
                <Table.Cell width="1" textAlign="right">
                    <div style={{display: "inline-flex"}}>
                        {onEdit && <EditActionIcon data={{sipiId, sipi}} onClick={onEdit} />}
                        {onRemoveSiPi && <RemoveIcon onRemove={onRemoveSiPi} id={sipiId} entityName={`instrument ${sipi.name}`} className="actionIconClass" />}
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }
}
