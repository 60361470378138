import { populate } from "react-redux-firebase";

const PopulateLockOwner = [
    { child: "by", childAlias: "owner", root: "user" }, // replace owner with user object
];

const getLockPath = props => `locks/list/${props.lockId}`;

export const getLockDataListener = props => {
    return props.lockId ? [{ path: getLockPath(props), populates: PopulateLockOwner }] : [];
};

export const getLockData = (state, props) => {
    const path = getLockPath(props);
    return path && populate(state.firebase, path, PopulateLockOwner);
};
