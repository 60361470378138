import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LessonLinkPopup from "./LessonLinkPopup";
import { getSearchResult, isSearching, getSearchFilter, getOutlineId } from "../../../../KitBuilder/LessonLink/LessonLinkSelectors";
import { onSearchLesson } from "../../../../KitBuilder/LessonLink/LessonLinkActions";

const mapStateToProps = (state) => {
    return {
        isSearching: isSearching(state),
        searchResult: getSearchResult(state),
        searchFilter: getSearchFilter(state),
        outlineId: getOutlineId(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators({
        onSearchLesson,
    }, dispatch);
};

export default connect(mapStateToProps, dispatchToProps)(LessonLinkPopup);
