import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { widgetTemplates, getWidgetTemplatesInfo } from "../WidgetTemplates/WidgetTemplatesSelectors";
import * as WidgetLibraryActions from "./WidgetLibraryActions";
import {
    filteredWidgets,
    usedTags,
    selectedWidgetId,
    getWidgetsInfo,
    searchFilter,
    getUsedTopicOptions,
    unusedFilter,
    selectedTags,
    isDirty,
    widgetLocks,
    selectedTopic,
    usedTopics,
    getTopicFilterName,
    moduleFilter,
    getWidgetsModules,
} from "./WidgetLibrarySelectors";
import { onSetModuleFilter as onSetTemplateModuleFilter } from "../WidgetTemplates/WidgetTemplatesActions";
import WidgetLibrary from "./WidgetLibrary";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { getTopicAsList } from "../../Topics/topicsSelectors.js";
import { withAuth } from "../../component/withAuth";

const mapStateToProps = function(state, props) {
    return {
        widgetsInfo: getWidgetsInfo(state),
        templatesInfo: getWidgetTemplatesInfo(state),
        widgets: filteredWidgets(state),
        usedTags: usedTags(state),
        selectedWidgetId: selectedWidgetId(state, props),
        topics: getTopicAsList(state),
        widgetTemplates: widgetTemplates(state),
        searchFilter: searchFilter(state),
        usedTopicOptions: getUsedTopicOptions(state),
        unusedFilter: unusedFilter(state),
        selectedTags: selectedTags(state),
        isDirty: isDirty(state),
        widgetLocks: widgetLocks(state),
        selectedTopic: selectedTopic(state),
        usedTopics: usedTopics(state),
        topicFilterName: getTopicFilterName(state),
        moduleFilter: moduleFilter(state),
        widgetsModules: getWidgetsModules(state),
    };
};

const dispatchToProps = { ...WidgetLibraryActions, onSetTemplateModuleFilter };

export default compose(
    firebaseConnect(["widgetTemplates/list", "widgetLibrary/list", "widgetLibrary/m", "subjects", "locks/w/"]),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["widgetsInfo", "templatesInfo", "widgetsModules"]),
    firebaseConnect(({ selectedWidgetId, widgetsInfo }) => {
        if (selectedWidgetId && widgetsInfo) {
            if (widgetsInfo[selectedWidgetId]) {
                return [
                    `/widgetLibrary/data/${selectedWidgetId}`,
                    `/widgetTemplates/data/${widgetsInfo[selectedWidgetId].templateId}`,
                ];
            } else {
                return [
                    {
                        path: `/widgetLibrary/list/${selectedWidgetId}`,
                        storeAs: `potentiallyUnknownWidgets/${selectedWidgetId}`,
                    },
                ];
            }
        }
    }),
    withAuth,
)(WidgetLibrary);
