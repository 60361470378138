import React from "react";
import PropTypes from "prop-types";
import { Icon, Table } from "semantic-ui-react";
import { PublishedLessonProps, LessonVersionProps, LessonFileType } from "../PublisherProps";
import { compareByOrdering, convertDatasetToList } from "../../../utils/selectorUtils";
import { getFileDownloadUrl, getListDescription } from "../LessonFilesViewer/LessonFilesLinks";

export const htmlPlaceholder = `
    <div style="text-align: center; padding-top: 2em;"><h3>Exporting...</h3></div>
`;

class LessonExportFileEntry extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        info: PropTypes.string,
        onExportToPdf: PropTypes.func,
        path: PropTypes.string,
        /* Title of the PDF file */
        problemSetName: PropTypes.string,
    }

    handlePrint = () => {
        const { href, onExportToPdf, path, name, problemSetName } = this.props;
        const exportWindow = window.open("", "_blank");
        exportWindow.blur();
        exportWindow.document.body.innerHTML = htmlPlaceholder;
        onExportToPdf({ href, path, problemSetName, scope: name, exportWindow, fileName: `${problemSetName} - ${name}.pdf` });
    }

    render() {
        const { name, info } = this.props;
        return (
            <Table.Cell>
                <a style={{ cursor: "pointer" }} title={info} target="print" rel="noopener noreferrer" onClick={this.handlePrint}>
                    <Icon name="file pdf outline" />
                    {name}
                </a>
            </Table.Cell>
        );
    }
}

class LessonExportFileGroup extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        info: PropTypes.string,
        student: LessonFileType.isRequired,
        teacher: LessonFileType.isRequired,
        onExportToPdf: PropTypes.func,
    };

    render() {
        const { name, info, student, teacher, onExportToPdf } = this.props;
        return (
            <LessonExportLinksItem name={name} info={info}>
                {student && <LessonExportFileEntry
                    name="Student version"
                    info="PDF export for students"
                    href={getFileDownloadUrl(student)}
                    path={student}
                    onExportToPdf={onExportToPdf}
                    problemSetName={name}
                />}
                {teacher && <LessonExportFileEntry
                    name="Teacher version"
                    info="PDF export for teachers"
                    href={getFileDownloadUrl(teacher)}
                    path={teacher}
                    onExportToPdf={onExportToPdf}
                    problemSetName={name}
                />}
            </LessonExportLinksItem>
        );
    }
}
class LessonExportLinksItem extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        info: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { name, info, children } = this.props;
        return (
            <Table.Row>
                <Table.Cell>
                    {name}
                    {info && <div style={{fontStyle: "italic", color: "grey"}}>{info}</div>}
                </Table.Cell>
                {children}
            </Table.Row>
            
        );
    }
}
export default class LessonExportLinks extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        info: LessonVersionProps.isRequired,
        data: PublishedLessonProps.isRequired,
        onExportToPdf: PropTypes.func,
        outlineName: PropTypes.string,
        lessonName: PropTypes.string,
    }

    render() {
        const { name, info, data, onExportToPdf, outlineName, lessonName } = this.props;
        const { hw, ps, content } = data || {};
        return (
            <React.Fragment>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3">{name}
                            <div style={{fontWeight: "normal", fontSize: "smaller", float: "right"}}>Published {getListDescription(info.created_at, info.createdBy)}</div>
                        </Table.HeaderCell>
                        
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {content &&
                        <LessonExportFileGroup
                            {...content}
                            name={`${outlineName} - ${lessonName}`}
                            info="Lesson"
                            onExportToPdf={onExportToPdf}
                        />
                    }
                    {hw && convertDatasetToList(hw).sort(compareByOrdering).map(({ key, value }) => (
                        <LessonExportFileGroup
                            key={`hw.${key}`} // We may have same set ID in homework & problem set.
                            {...value}
                            info="Homework"
                            name={`${outlineName} - ${value.title}`}
                            onExportToPdf={onExportToPdf}
                        />
                    ))}
                    {ps && convertDatasetToList(ps).sort(compareByOrdering).map(({ key, value }) => (
                        <LessonExportFileGroup
                            key={`ps.${key}`} // We may have same set ID in homework & problem set.
                            {...value}
                            info="Problem Set"
                            name={`${outlineName} - ${value.title}`}
                            onExportToPdf={onExportToPdf}
                        />
                    ))}
                </Table.Body>
            </React.Fragment>
        );
    }
}
