import { createSelector } from "reselect";
import { mapCollectionToList, compareByNameCI, getSearchByValueTenantPredicate, getSearchByNamePredicate, convertDatasetToList, compareValueByNameCI } from "../../../utils/selectorUtils";
import { userClaims, loggedUserId, tenantId } from "../../../Users/UserProfile/UserProfileSelectors";
import { isAuthor, isSuperAuthorAtLeast, isReviewer } from "../../../Users/roleUtils";
import { getSubscribedModules } from "../../../External/Tenants/sharedTenantSelectors";
import { modulesList } from "../../Modules/ModulesSelectors";

const _debugMessages = process.env.NODE_ENV !== "production" // eslint-disable-line

export const getPathsForAKitsData = [ "kits_data", "modules/list", "tenant_modules" ];

export const getAKitsError = state => state.kitBuilder.akits.main.error;

export const fetchKitsData = state => state.firebase.data.kits_data;

export const getTenantsKits = createSelector(
    fetchKitsData,
    tenantId,
    (kits_data, tenant) => {
        if (kits_data && tenant) {
            const kitsData = convertDatasetToList(kits_data);
            const tenantPredicate = getSearchByValueTenantPredicate(tenant);
            return kitsData.filter(tenantPredicate).sort(compareValueByNameCI);
        }
    }
);

const isAuthorOfKit = (userClaims, loggedUserId, kit) => !!(
    kit && kit.authors && kit.authors[loggedUserId] && isAuthor(userClaims)

);

const isReviewerOfKit = (userClaims, loggedUserId, kit) => !!(
    kit && kit.reviewers && kit.reviewers[loggedUserId] && isReviewer(userClaims)
);

const NoUserRights = Object.freeze({
    canEdit: false,
    canReview: false,
    canPublish: false,
});

export const getUserRightsAnyAuthorKit = (userClaims) => {
    if (userClaims) {
        const isSuperAuthor = isSuperAuthorAtLeast(userClaims);
        return {
            canEdit: isSuperAuthor,
            canReview: isSuperAuthor,
            canPublish: isSuperAuthor,
        };
    }
    return NoUserRights;
};

export const getAccessibleKits = createSelector(
    getTenantsKits,
    getSubscribedModules,
    modulesList,
    (tenantKits, subscribedModules, allModules) => {
        let kits = {};
        if (tenantKits) {
            for (const { key } of tenantKits) {
                kits[key] = true;
            }
        }
        if (subscribedModules) {
            for (const moduleId of Object.keys(subscribedModules)) {
                if (allModules && allModules[moduleId]) {
                    kits = { ...kits, ...allModules[moduleId].akits };
                }
            }
        }
        return kits;
    }
);

export const getUserRightsForAuthorKit = (userClaims, loggedUserId, kit) => {
    const isSuperAuthor = !!(userClaims && isSuperAuthorAtLeast(userClaims));
    const canEdit = isSuperAuthor || isAuthorOfKit(userClaims, loggedUserId, kit);
    const canReview = isSuperAuthor || isReviewerOfKit(userClaims, loggedUserId, kit);

    return { canEdit, canReview, canPublish: isSuperAuthor };
};

export const getCanEditKit = (userClaims, loggedUserId, kit) => (
    isSuperAuthorAtLeast(userClaims) || isAuthorOfKit(userClaims, loggedUserId, kit)
);

export const getCanReviewKit = (userClaims, loggedUserId, kit) => (
    isSuperAuthorAtLeast(userClaims) || isReviewerOfKit(userClaims, loggedUserId, kit)
);

const getCanRemoveKit = (kit_data/*, yearId = null could be used for a lookup */) => {

    /** TODO: add logic for testing if a user can remove the kit_data. It could depend on user, kit type (aKit, sKit, tKit), number of references to the kit etc. */
    if (kit_data); // just to avoid eslint to complain for not used parameter

    /* just for development */
    return true;
};

export const appendIfNEmpty = (value, separator = "", prefix = "") => (value) ? separator + prefix + value : "";

const _getAKits = (kits_data_object, userClaims, loggedUserId, accessibleKits) => {
    let kits_data_combined = [];

    let kits_data = mapCollectionToList(kits_data_object);
    if (undefined === accessibleKits) {
        return [];
    } else if (null !== accessibleKits) {
        kits_data = kits_data.filter(({key}) => accessibleKits[key]);
    }

    if (kits_data) {
        kits_data.forEach((kit_data, index) => {
            const canEdit = getCanEditKit(userClaims, loggedUserId, kit_data);
            kits_data_combined[index] = {...kit_data,
                id: kit_data.key,
                key: kit_data.key,
                name: kit_data.name, // currently we allow aKit's name to be calculated from its course only
                can_edit: canEdit,
                can_remove: canEdit && getCanRemoveKit(kit_data), // user has rights to modify the kit and the kit is removable for example is not cloned.
            };

        } );
    }
    return kits_data_combined.sort(compareByNameCI);
};


export const getAKits = createSelector(fetchKitsData, userClaims, loggedUserId, getAccessibleKits, _getAKits);

export const  getAKitsAsOptions = createSelector(
    getAKits,
    (kits) => kits && kits.map(kit => ({ key: kit.key, value: kit.key, text: kit.name }))
);

export const searchFilter = (state) => state.kitBuilder.akits.aKitsList.searchFilter;

const applySearchFilter = (aKits, searchFilter) => {
    let filteredData = aKits || [];
    const namePredicate = getSearchByNamePredicate(searchFilter);

    if (namePredicate) {
        filteredData = filteredData
            .filter(entity => entity.name) // filter out those that are missing name
            .filter(namePredicate);
    }
    /* Add more filtering criteria when needed */
    return filteredData;
};

export const getAKitsFiltered = createSelector(
    getAKits,
    searchFilter,
    applySearchFilter
);
