import React, { PureComponent } from "react";
import { List, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import LessonUnitDraggable from "./LessonUnitDraggable";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";
import { PopupInput, AddItemButton } from "bmd-react";
import cx from "classnames";

export default class LessonUnitsList extends PureComponent {
    static propTypes = {
        selectedOutlineId: PropTypes.string,

        onLessonUnitCreate: PropTypes.func,

        lessonUnitsList: PropTypes.array.isRequired,
        droppablePlaceholder: PropTypes.object,
        canEdit: PropTypes.bool,
    };

    handleAddLessonUnit = (lessonUnitName, callbackOnFinish) => {
        const { selectedOutlineId, onLessonUnitCreate } = this.props;
        onLessonUnitCreate(selectedOutlineId, lessonUnitName);
        callbackOnFinish();
    }

    render() {
        const { lessonUnitsList, droppablePlaceholder, canEdit } = this.props;
        return (
            <React.Fragment>
                {lessonUnitsList && lessonUnitsList.length ?
                    <List className={cx("outlineElement lessonUnitsList", {"readOnly" : !canEdit})}>
                        {lessonUnitsList.map((unit, index) => <LessonUnitDraggable
                            key={unit.key}
                            index={index}
                            lessonUnitData={unit}
                            canEdit={canEdit}
                        />)}
                    </List>
                    :
                    <NoDataPlaceholder text="Here we list lesson units of your outline." icon="file outline">
                        <p>Add new lesson unit using the button below.</p>
                        {canEdit && <PopupInput
                            position="top center"
                            placeholder="Enter new lesson unit name"
                            trigger={<Button primary content="Add lesson unit" title="Add lesson unit" disabled={canEdit == false}/>}
                            onSubmit={this.handleAddLessonUnit}
                            defaultValue="" />
                        }
                    </NoDataPlaceholder>}
                {canEdit && droppablePlaceholder}
                {lessonUnitsList && lessonUnitsList.length ?
                    canEdit && <PopupInput
                        position="top left"
                        placeholder="Enter new lesson unit name"
                        trigger={<AddItemButton inline buttonText="Add lesson unit" title="Add lesson unit" />}
                        onSubmit={this.handleAddLessonUnit}
                        defaultValue=""
                        autoFocus
                        width="500px" 
                    />
                    : null}
            </React.Fragment>
        );
    }
}
