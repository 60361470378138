import { connect } from "react-redux";
import { compose } from "redux";
import ProblemSetManager from "./ProblemSetManager";
import {
    getProblemSetsQueryParams,
    getProblemSetsAsArray,
    getProblemSets,
    problemSetLocks,
    problemSetLockPath,
} from "./problemSetManagerSelectors";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading";
import { firebaseConnect } from "react-redux-firebase";

const mapStateToProps = (state, props) => ({
    queryParams: getProblemSetsQueryParams(state, props),
    problemSets: getProblemSetsAsArray(state, props),
    problemSetLocks: problemSetLocks(state, props),
    _problemSets: getProblemSets(state, props), // for spinner
});

export default compose(
    connect(mapStateToProps),
    firebaseConnect(({ fbCollection, queryParams }) => {
        const locks = problemSetLockPath(fbCollection);
        const dataQuery = queryParams ? ({ path: fbCollection, queryParams }): fbCollection;
        return [ dataQuery, locks ];
    }),
    spinnerWhileLoading(["_problemSets"]),
)(ProblemSetManager);

