import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import classNames from "classnames/bind";

import styles from "../ProblemSetCreator.module.scss";

const cx = classNames.bind(styles);

export default class ProblemDroppable extends React.PureComponent {
    static propTypes = {
        targetId: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { children, targetId } = this.props;
        return (
            <Droppable droppableId={targetId} type="PROBLEM">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}
                        className={cx({ droppable: true, dragOver: snapshot.isDraggingOver})}
                    >{children}</div>
                )}
            </Droppable>
        );
    }
}
