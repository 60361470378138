import { all, takeEvery, put, select, takeLatest } from "redux-saga/effects";
import { TYPES, onCloseItemEditor, onSelectWidget, onCloseDetail, onSavingItem } from "./WidgetLibraryActions";
import firebase, { widgetLibrary, widgetTemplates } from "../dataSource";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import to from "await-to-js";
import { push } from "connected-react-router";
import {
    getSnippetToggleButtonHTML,
    getSnippetBlockContentHTML
} from "../Snippets/SnippetUtils";
import { ProblemHtmlRenderers, formatProblemDivForWidget } from "../LessonContent/LessonProblemSet/renderProblemSet";
import { toastError, DB_ERROR } from "../../component/toast";
import { outlineManager } from "../Outlines/DataSource";
import { REVIEW_STATS_COLLECTION } from "../../component/seamlessEditor/bookEditor/constants";

function* createWidget({ payload:{ name, templateId, topics, openWidget= false } }) {
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);
    const items = yield widgetTemplates.getInitialContent(templateId);
    const [err, widgetId] = yield widgetLibrary.createWidget({ name, templateId, topics, items, author: userId, created: new Date().toISOString(), tenant });
    if (err) {
        toastError({ code: err.code, header: "Failed to create a new Widget", message: DB_ERROR });
    } else {
        if (openWidget) {
            yield put(onSelectWidget(widgetId));
        }
    }
}

function* deleteWidget({ payload }) {
    const widgetId = payload.key;
    if (widgetId) {
        const [ err ] = yield widgetLibrary.removeWidget(widgetId);
        if (err) {
            toastError({ code: err.code, header: "Failed to remove Widget", message: DB_ERROR });
        } else {
            yield put(onCloseDetail());
        }

    }
}

function* updateName({ payload }) {
    const [ err ] = yield widgetLibrary.renameWidget(payload.key, payload.name);
    if (err) {
        toastError({ code: err.code, header: "Failed to rename Widget", message: DB_ERROR });
    }
}

function* updateItem({ payload:{ widgetId, itemId, content, outlineId, lessonId } }) {
    yield(put(onSavingItem(widgetId, itemId)));
    const [ err ] = yield widgetLibrary.updateWidgetItem(widgetId, itemId, content);
    if (err) {
        toastError({ code: err.code, header: "Failed to update Widget", message: DB_ERROR });
    } else {
        yield outlineManager.outlineLessonUpdateReviewStats(
            REVIEW_STATS_COLLECTION.WIDGETS,
            outlineId,
            lessonId,
            widgetId,
            itemId,
            (content && content.reviewStats ? content.reviewStats : {})
        );
    }
    yield put(onCloseItemEditor(widgetId, itemId));
}

function* deleteFile(storagePath) {
    const fileRef = firebase.getFirebaseFile(storagePath);
    return yield to(fileRef.delete());
}

function* updateFileItem({ payload: { widgetId, itemId, content, newFile } }) {
    yield (put(onSavingItem(widgetId, itemId)));
    if (newFile && newFile.lastModified) {
        if (content && content.storagePath) {
            const [err] = yield deleteFile(content.storagePath);
            if (err && err.code !== "storage/object-not-found") { // if object is not found, somebody already removed it. That's OK.
                toastError({ code: err.code, header: "Failed to remove current file", message: DB_ERROR });
                yield put(onCloseItemEditor(widgetId, itemId));
                return;
            }
        }
        const storagePath = `widgetLibrary/${widgetId}/${itemId}/${newFile.name}`;
        const fileRef = firebase.getFirebaseFile(storagePath);
        let [err] = yield to(fileRef.put(newFile));
        if (err) {
            toastError({ code: err.code, header: "Failed to upload a new file", message: DB_ERROR });
            yield put(onCloseItemEditor(widgetId, itemId));
            return;
        }
        yield updateItem({ payload: { widgetId, itemId, content: { ...content, fileName: newFile.name, storagePath, size: newFile.size, href: null } } });
    } else {
        yield updateItem({ payload: { widgetId, itemId, content } });
    }
}

function* updateQuestionItem({ payload: { widgetId, itemId, snippet, title = null, problem_id = null, orderNum = null } }) {
    let question = "";
    let answer = "";
    let linked_outlines = null;
    if (problem_id) {
        const problemRef = firebase.getFirebaseData(`problem/${problem_id}`);
        const problem = (yield problemRef.once("value")).val();
        if (problem) {
            if (problem.question && problem.question.linked_outlines) {
                linked_outlines = problem.question.linked_outlines;
            }
            if ("oa" === problem.problemType && problem.answer && problem.answer.linked_outlines) {
                if (linked_outlines) {
                    linked_outlines = { ...problem.answer.linked_outlines, ...linked_outlines };
                } else {
                    linked_outlines = problem.answer.linked_outlines;
                }
            }

            const renderer = ProblemHtmlRenderers[problem.problemType];
            const content = renderer(problem, orderNum, false /*no Show Answer button*/);
            question = formatProblemDivForWidget(content, problem_id);
            answer = content.answer;
        }
    }
    if (snippet) {
        const { snippetCfg } = snippet;
        const snippet_id = snippet.key;
        if (0 < title.trim().length) {
            snippetCfg["blockData"] = { title: title.trim()};
        }

        let snippetHtml = "";
        if (snippetCfg.hideable) {
            snippetHtml = question + getSnippetToggleButtonHTML(snippet.key, snippetCfg)
                + getSnippetBlockContentHTML(snippet.key, snippetCfg, `<div class="problem">${answer}</div>`);
        } else {
            snippetHtml = getSnippetToggleButtonHTML(snippet.key, snippetCfg)
                + getSnippetBlockContentHTML(snippet.key, snippetCfg, question);
        }
        yield updateItem({ payload: {
            widgetId,
            itemId,
            content: { snippet_id, snippets: { [snippet_id]: true }, title, html: snippetHtml, problem_id, linked_outlines },
        }});
    } else {
        yield updateItem({ payload: { widgetId, itemId, content: { snippet_id: null, snippets: null, title: null, html: question, problem_id, linked_outlines }}});
    }
}

function* closeDetail() {
    yield put(push("/widgets/library"));
}

function* selectWidget({ payload }) {
    yield put(push(`/widgets/library/${payload}`));
}

function* changeWidgetTitle ({ payload: { widgetId, title, hiddenTitle }}) {
    const [err] = yield widgetLibrary.changeWidgetTitle(widgetId, title, hiddenTitle);
    if (err) {
        toastError({ code: err.code, header: "Failed to change widget title", message: DB_ERROR });
    }
}

function* changeItemTitle ({ payload: { widgetId, itemId, title, hiddenTitle }}) {
    const [err] = yield widgetLibrary.changeItemTitle(widgetId, itemId, title, hiddenTitle);
    if (err) {
        toastError({ code: err.code, header: "Failed to change widget item title", message: DB_ERROR });
    }
}

export default function* widgetTemplatesSaga() {
    yield all([
        takeEvery(TYPES.WIDGET_LIBRARY_CREATE_NEW, createWidget),
        takeEvery(TYPES.WIDGET_LIBRARY_DELETE, deleteWidget),
        takeEvery(TYPES.WIDGET_LIBRARY_UPDATE_NAME, updateName),
        takeEvery(TYPES.WIDGET_LIBRARY_UPDATE_ITEM, updateItem),
        takeEvery(TYPES.WIDGET_LIBRARY_UPDATE_FILE_ITEM, updateFileItem),
        takeEvery(TYPES.WIDGET_LIBRARY_CLOSE_DETAIL, closeDetail),
        takeEvery(TYPES.WIDGET_LIBRARY_ITEM_SELECTED, selectWidget),
        takeLatest(TYPES.WIDGET_LIBRARY_UPDATE_QUESTION_ITEM, updateQuestionItem),
        takeEvery(TYPES.WIDGET_LIBRARY_CHANGE_WIDGET_TITLE, changeWidgetTitle),
        takeEvery(TYPES.WIDGET_LIBRARY_CHANGE_ITEM_TITLE, changeItemTitle),
    ]);
}
