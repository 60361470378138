import firebase from "../../firebase";

export const paths = {
    toLesson: (basePath, iPlannerId, lessonId) => `${basePath}/data/${iPlannerId}/lu_${lessonId}`,
};


const getReferences = (basePath, iPlannerId, lessonId) => {
    const dataRef = firebase.getFirebaseData(paths.toLesson(basePath, iPlannerId, lessonId));
    return { dataRef };
};

export const updateSiPiContent = async (basePath, iPlannerId, lessonId, data) => {
    if (!iPlannerId || !lessonId) {
        return false;
    }
    const { dataRef } = getReferences(basePath, iPlannerId, lessonId);
    return dataRef.update(data);
};

export const removeSiPi = async (basePath, iPlannerId, lessonId, sipiId) => {
    if (!iPlannerId || !lessonId || !sipiId) {
        return false;
    }
    const { dataRef } = getReferences(basePath, iPlannerId, lessonId);
    return dataRef.update({[sipiId]: null});
};

export const updateSiPi = async (basePath, iPlannerId, lessonId, sipiId, data) => {
    if (!iPlannerId || !lessonId) {
        return false;
    }
    const { dataRef } = getReferences(basePath, iPlannerId, lessonId);
    return dataRef.update({ [sipiId]: data });
};

const getHighestSiPiOrdering = async ref => {
    let maxOrdering = 0;
    (await ref.once("value")).forEach(snapshot => {
        const sipi = snapshot.val();
        if (sipi.ordering && maxOrdering < sipi.ordering) {
            maxOrdering = sipi.ordering;
        }
    });
    return maxOrdering;
};

export const addSiPi = async (basePath, iPlannerId, lessonId, data) => {
    if (!iPlannerId || !lessonId) {
        return false;
    }
    const { dataRef } = getReferences(basePath, iPlannerId, lessonId);
    return dataRef.push({...data, ordering: await getHighestSiPiOrdering(dataRef) + 1});
};

export const massAddSiPis = async (basePath, iPlannerId, lessonId, data) => {
    if (!iPlannerId || !lessonId) {
        return false;
    }
    const { dataRef } = getReferences(basePath, iPlannerId, lessonId);
    const maxOrdering = await getHighestSiPiOrdering(dataRef);
    data.map((sipi, i) => dataRef.push({...sipi, ordering: maxOrdering + 1 + i}));
    return;
};

export const createIPlanner = async (basePath, data) => {
    const ref = firebase.getFirebaseData(`${basePath}/info`);
    return ref.push({ ...data });
};

export const updateLesson = async (basePath, { index, iPlannerId, ...data }) => {
    const ref = firebase.getFirebaseData(`${basePath}/lesson_units/${iPlannerId}/${index}`);
    return ref.update(data);
};

export const createLesson = async (basePath, data) => {
    const ref = firebase.getFirebaseData("/");
    return updateLesson(basePath, { ...data, ["syllabi" === basePath ? "lu_id" : "cv_lu_id"]: ref.push().key, spork_cv_lu_id: ref.push().key });
};

export const updateLessonsList = async (basePath, iPlannerId, lessons) => {
    const ref = firebase.getFirebaseData(`${basePath}/lesson_units/${iPlannerId}`);
    return ref.set(lessons);
};

export const updateName = async (basePath, iPlannerId, data) => {
    const ref = firebase.getFirebaseData(`${basePath}/info/${iPlannerId}`);
    return ref.update(data);
};

export const removeiPlanner = async (basePath, iPlannerId) => {
    const ref = firebase.getFirebaseData(basePath);
    return ref.update({
        [`info/${iPlannerId}`]: null,
        [`data/${iPlannerId}`]: null,
        [`lesson_units/${iPlannerId}`]: null,
    });
};

export const cloneOutline = async (basePath, id, { templateId, iPlannerId }) => {
    const ref = firebase.getFirebaseData(basePath);
    const sourceBasePath = templateId ? "sipi_templates" : "syllabi";
    const data = (await firebase.getFirebaseData(sourceBasePath).child("data").child(templateId || iPlannerId).once("value")).val();
    const lesson_units = (await firebase.getFirebaseData(sourceBasePath).child("lesson_units").child(templateId || iPlannerId).once("value")).val();
    return ref.update({
        [`data/${id}`]: data,
        [`lesson_units/${id}`]: lesson_units,
    });
};

export const getSyllabusName = async (iPlannerId) => {
    return (await firebase.getFirebaseData("syllabi").child("info").child(iPlannerId).child("syllabus_name").once("value")).val();
};
