import { createSelector } from "reselect";
//import { mapCollectionToList } from "../../utils/selectorUtils";
//import { getSelectedYearId } from "../../Year/YearsSelectors";

export const getSchoolCourses = state => state.external.sc.schoolCourseList;
export const isLoading = state => state.external.sc.isLoading;

export const getSchoolCoursesAsOptions = createSelector(
    getSchoolCourses,
    sc => {
        if (sc === undefined) return sc;
        const options = [{ key: "NULL", text: "Not selected", value: "NULL" }];
        return options.concat(sc.map(sc => ({ key: sc.id, text: sc.name, value: sc.id })));
    }
);
