import { connect } from "react-redux";

import { isSaving } from "./ResourceNameSelectors";
import { onSave } from "./ResourceNameActions";
import ResourceName from "./ResourceName";

const ResourceNameContainer = connect(
    (state) => ({
        isSaving: isSaving(state),
    }),
    {
        onUpdate: onSave,
    }
)(ResourceName);
ResourceNameContainer.displayName = "ResourceNameContainer";

export default ResourceNameContainer;