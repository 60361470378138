import PropTypes from "prop-types";
import { connect } from "react-redux";
import { onLessonUnitCreate } from "../../outlinesActions";
import { getSelectedOutlineId } from "../../outlinesSelectors";
import LessonUnitsList from "./LessonUnitsList";

const LessonUnitsListContainer = connect((state, props) => ({
    selectedOutlineId: getSelectedOutlineId(state, props),
}), {
    onLessonUnitCreate 
})(LessonUnitsList);

LessonUnitsListContainer.displayName = "LessonUnitsListContainer";
LessonUnitsListContainer.propTypes = {
    outlineId: PropTypes.string.isRequired,
    provided: PropTypes.object,
    canEdit: PropTypes.bool,
    lessonUnitsList: PropTypes.array,
};

export default LessonUnitsListContainer;