const TYPES = {
    RESOURCE_DESCRIPTION_SAVE: "RESOURCE_DESCRIPTION_SAVE",
    RESOURCE_DESCRIPTION_SAVED: "RESOURCE_DESCRIPTION_SAVED",
};

const onSave = (payload) => ({
    type: TYPES.RESOURCE_DESCRIPTION_SAVE,
    payload,
});

const onSaved = (payload) => ({
    type: TYPES.RESOURCE_DESCRIPTION_SAVED,
    payload,
});

export {
    TYPES,
    onSave,
    onSaved,
};
