import { TYPES } from "./tenantActions";

const initialState = {
    isLoading: false,
    tenantList: [],
    isCreating: false,
    newTenantInfo: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.TENANT_LOAD_LIST:
            return { ...state, isLoading: true, newTenantInfo: null };
        case TYPES.TENANT_SET_LIST:
            return { ...state, tenantList: action.payload, isLoading: false };
        case TYPES.TENANT_CREATE_DEMO:
            return { ...state, isCreating: true, newTenantInfo: null };
        case TYPES.TENANT_CREATE_DEMO_END:
            return { ...state, isCreating: false };
        case TYPES.TENANT_NEW_TENANT_INFO:
            return { ...state, newTenantInfo: action.payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
