import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isSaving } from "./ResourceDescriptionSelectors";
import { onSave } from "./ResourceDescriptionActions";
import ResourceDescription from "./ResourceDescription";

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onSave,
        },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        isSaving: isSaving(state),
    }),
    dispatchToProps
)(ResourceDescription);