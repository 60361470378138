import { createSelector } from "reselect";
import { getTreeFromFlatData } from "react-sortable-tree";
import { objectToDropdownOptionsWithKeyValue } from "../utils/selectorUtils";
import { populate } from "react-redux-firebase";

export const populates = [
    { child: "last_editor", root: "user" }, // replace last_editor with user object
];

export const getTopicCollectionList = ({ firebase }) => populate(firebase, "/topic_collections/list", populates);
export const getSelectedTopicCollection = state => state.tc.selectedCollection;
export const loadingTopics = state =>
    (state.firebase.data.topic_collections && state.firebase.data.topic_collections.data && undefined !== state.firebase.data.topic_collections.data ? false : true);
export const getTopicCollectionData = state =>
    (state.firebase.data.topic_collections && state.firebase.data.topic_collections.data) || {};
export const getTopicCollectionSyncLog = (state, props) =>
    state.firebase.data.topic_collections &&
    state.firebase.data.topic_collections.sync_log &&
    state.firebase.data.topic_collections.sync_log[props.selectedCollection];

export const getTopicExpansionStatus = state => state.tc.topicExpandStatus;

export const convertTopics = (topics, expansionStatus) => {
    let convertedTopics = Object.keys(topics || {}).map(topicId => {
        let topic = topics[topicId];
        return {
            title: topic.name,
            subtitle: topic.description,
            order: topic.order,
            expanded: expansionStatus[topicId] !== undefined ? expansionStatus[topicId] : true,
            key: topicId,
            parent: topic.parent !== undefined ? topic.parent : null,
            isUsed: topic.used
                ? {
                    lesson: topic.used.lesson && Object.keys(topic.used.lesson).length,
                    widget: topic.used.widget && Object.keys(topic.used.widget).length,
                    problem: topic.used.problem && Object.keys(topic.used.problem).length,
                }
                : null,
        };
    });
    return convertedTopics.sort((a, b) => a.order - b.order);
};

/**
 * Get selected Topic Collection in react-sortable-tree format { title: , subtitle:, children: []}
 */
export const getTopicCollectionTree = createSelector(
    getSelectedTopicCollection,
    getTopicCollectionList,
    getTopicCollectionData,
    getTopicExpansionStatus,
    (selectedCollection, collectionList, collectionData, expansionStatus) => {
        let root = collectionList[selectedCollection] || {};
        let rootData = collectionData[selectedCollection] || {};

        return [
            {
                title: root.name,
                children: getTreeFromFlatData({
                    flatData: convertTopics(rootData, expansionStatus),
                    getKey: node => node.key, // resolve a node's key
                    getParentKey: node => node.parent, // resolve a node's parent's key
                    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
                }),
                expanded: true,
                key: selectedCollection,
                rootNode: true,
            },
        ];
    }
);

export const isTopicCollectionRemovable = createSelector(
    getSelectedTopicCollection,
    getTopicCollectionData,
    (selectedCollection, collectionData) => {
        if (collectionData && selectedCollection) {
            const rootData = collectionData[selectedCollection];
            let notUsed = true;
            if (rootData) {
                for (const topicId of Object.keys(rootData)) {
                    const topic = rootData[topicId];
                    if (topic.used) {
                        notUsed = false;
                    }
                }
            }
            return notUsed;
        }
        return false;
    }
);

export const getTopicCollection = createSelector(
    getSelectedTopicCollection,
    getTopicCollectionList,
    (selectedCollection, collectionList) => {
        if (undefined !== collectionList[selectedCollection]) {
            return { ...collectionList[selectedCollection], key: selectedCollection };
        } else return null;
    }
);

export const getTopicCollectionListAsDropdown = createSelector(
    getTopicCollectionList,
    objectToDropdownOptionsWithKeyValue
);

export const tenants = ({ firebase }) => firebase.data.tenants;
export const collectionConfig = ({ firebase }, { collectionId }) => collectionId && firebase.data.config &&firebase.data.config.topic_collections && firebase.data.config.topic_collections[collectionId];