import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Message, Icon } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onCancelAddTerm, onCreateTerm, onSetDirty } from "../GlossaryActions";
import { getAddTerm, isDirty } from "../GlossarySelectors";
import cx from "classnames";
import { WeakDefinitionError } from "./WeakDefinitionError";
import GlossaryEditor from "../GlossaryEditor";

class CreateTermDialog extends PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        onCancelAddTerm: PropTypes.func,
        onCreateTerm: PropTypes.func,
        initName: PropTypes.string,
        redirectAfterCreate: PropTypes.bool,
        onCreated: PropTypes.func,
        onSetDirty: PropTypes.func,
        isDirty: PropTypes.bool,
    };

    state = {
        name: "",
        error: false,
        saving: false,
        weakDefinitionError: false,
    }

    inputRef = React.createRef();

    componentDidUpdate(prevProps) {
        const { initName, open } = this.props;
        if (!prevProps.open && open) {
            this.setState({ name: initName || "", saving: false, error: false, weakDefinitionError: false});
            this.focusTextInput();
            return true;
        }
    }

    focusTextInput = () => {
        setTimeout(() => {
            this.inputRef.current.focus();
        }, 200);
    }

    handleChangeName = (e ) => {
        this.setState({ name: e.target.value, error: false });
    }

    registerGetContentFn = fn => {
        this.getEditorContentFn = fn;
    };

    handleSave = async () => {
        const { name } = this.state;
        if (name.trim().length > 0) {
            const content = this.getEditorContentFn();
            const { onCreateTerm, onCreated, redirectAfterCreate = false } = this.props;
            if (content.html && 20 < content.html.length) {
                onCreateTerm({ name, switchToTerm: redirectAfterCreate, content: content, callback: onCreated });
                this.setState({ saving: true });
            } else {
                this.setState({ weakDefinitionError: true });
            }
        } else {
            this.setState({ error: true });
        }
    };

    render() {
        const { open, onCancelAddTerm, isDirty, onSetDirty } = this.props;
        const { name, error, saving, weakDefinitionError } = this.state;
        return (
            <Modal open={open} onOpen={this.handleOpen}>
                <Modal.Header>Create Glossary term</Modal.Header>
                <Modal.Content>
                    <Form>
                        <div className={cx("field required", {"error": error})}>
                            <label>Name</label>
                            <div className={cx("ui input", {"error": error})}>
                                <input
                                    ref={this.inputRef}
                                    onChange={this.handleChangeName}
                                    autoFocus
                                    placeholder={error ? "Please fill name of glossary term": "Fill name of glossary term"}
                                    value={name}
                                />
                            </div>
                        </div>
                        <div className="field required">
                            <label>Definition</label>
                        </div>
                    </Form>
                    <GlossaryEditor
                        onClose={this.handleCloseEditation}
                        registerGetContentFn={this.registerGetContentFn}
                        isDirty={isDirty}
                        onSetDirty={onSetDirty}
                    />
                    {weakDefinitionError && <WeakDefinitionError />}
                    {saving && <Message icon positive>
                        <Icon name="circle notched" loading />
                        <Message.Content>Creating new glossary term ...</Message.Content>
                    </Message>}
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        submitText="Create"
                        saveDisabled={error || saving}
                        onSave={this.handleSave}
                        onCancel={onCancelAddTerm}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    open: getAddTerm(state),
    isDirty: isDirty(state),
});

const dispatchToProps = (dispatch) => bindActionCreators(
    {
        onCancelAddTerm,
        onCreateTerm,
        onSetDirty,
    },
    dispatch
);

export default connect(
    mapStateToProps,
    dispatchToProps
)(CreateTermDialog);
