import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Grid, Popup } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";

import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import SnippetMenu from "../../Snippets/SnippetMenu";

export default class TemplateQuestionStyleDialog extends React.PureComponent {
    static propTypes = {
        content: PropTypes.shape({
            snippet_id: PropTypes.string,
            title: PropTypes.string,
        }),
        onUpdate: PropTypes.func,
        snippets: PropTypes.array,
        name: PropTypes.string,
        onClose: PropTypes.func,
    };

    state = {
        isDirty: false,
        snippet: {},
        title: "",
    }

    componentDidMount () {
        const { snippets, content } = this.props;
        this.setState({
            isDirty: false,
            snippet: content ? snippets.filter(s => s.key === content.snippet_id)[0] : null,
            title: content && content.title || ""
        });
    }

    handleSelect = (snippetId) => {
        const { snippets } = this.props;
        this.setState({ isDirty: true, snippet: snippets.filter(s => s.key === snippetId)[0], title: "" });
    }

    handleRemoveStyle = () => {
        this.setState({ snippet: null, title: "", isDirty: true });
    }

    handleSave = () => {
        const { onUpdate, onClose } = this.props;
        const { snippet, title } = this.state;
        onUpdate(snippet, title);
        onClose();
    }

    handleChange = (e, { value }) => {
        this.setState({ title: value, isDirty: true });
    }

    render() {
        const { name, snippets, onClose } = this.props;
        const { isDirty, snippet, title } = this.state;

        return (
            <Modal
                size="tiny"
                open={true}
                onClose={onClose}
            >
                <Modal.Header>Edit style in {name}</Modal.Header>
                <Modal.Content>
                    {snippet ? (
                        <React.Fragment>
                            {snippet.snippetCfg && snippet.snippetCfg.hasTitle && (
                                <Form onSubmit={this.handleSave} style={{marginBottom: "0.5em"}}>
                                    <Form.Input label="Custom title" onChange={this.handleChange} placeholder="Here you can fill custom block title" value={title}/>
                                </Form>
                            )}
                            <Grid>
                                <Grid.Column width="14">
                                    <SnippetMenu.Demo key={snippet.key} snippet={snippet} />
                                </Grid.Column>
                                <Grid.Column width="2">
                                    <Button title="Remove Styled Block" onClick={this.handleRemoveStyle} icon="trash alternate outline" basic compact style={{marginTop: "1.8em"}} />
                                </Grid.Column>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <NoDataPlaceholder
                            icon="window maximize outline"
                            text="This item is not styled"
                        >
                            <p>You can customize this question item by inserting Styled Block.</p>
                            <Popup
                                on="click"
                                trigger={<Button  content="Add Styled Block" primary />}
                            >
                                <SnippetMenu snippets={snippets} onSelect={this.handleSelect} />
                            </Popup>
                            
                        </NoDataPlaceholder>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons floated onCancel={onClose} saveDisabled={!isDirty} onSave={this.handleSave}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
