import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Segment, Dimmer, Loader, Button, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { getFilteredSnippets, getSearchFilter, unusedFilter, selectedTags, usedTags, getModuleFilter } from "./SnippetsManagerSelectors";
import * as SnippetsManagerActions from "./SnippetsManagerActions";
import Snippet from "./Snippet";
import PageHeader from "../../component/PageHeader";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import AdvancedFilter from "../component/AdvancedFilter";
import FilteredByOptionsView from "../component/FilteredByOptionsView";
import { withAuth } from "../../component/withAuth";

class SnippetsManager extends PureComponent {
    static propTypes = {
        loading: PropTypes.bool,
        onCreate: PropTypes.func,
        snippets: PropTypes.array,
        onRemove: PropTypes.func,
        onRename: PropTypes.func,
        searchFilter: PropTypes.string,
        onSaveSnippet: PropTypes.func,
        isSuperAuthorAtLeast: PropTypes.bool,
        onSelectTag: PropTypes.func,
        onChangeUnusedFilter: PropTypes.func,
        usedTags: PropTypes.array,
        onSetModuleFilter: PropTypes.func,
        moduleFilter: PropTypes.array,
    };

    componentDidMount() {
        this.props.onSetModuleFilter([]);
    }

    render() {
        const { loading, onCreate, snippets, onRename, onRemove, onSaveSnippet, searchFilter, isSuperAuthorAtLeast: canEdit,
            selectedTags, unusedFilter, onSelectTag, onChangeUnusedFilter, usedTags, onSetModuleFilter, moduleFilter } = this.props;
        const isAddAllowed = searchFilter === "" && selectedTags && 0 == selectedTags.length && moduleFilter && 0 == moduleFilter.length;
        const isEmpty = isAddAllowed && (!snippets || 0 == snippets.length);
        return (
            <React.Fragment>
                <PageHeader {...this.props} list={snippets} headerText="Styled Blocks" entityName="styled block" >
                    <AdvancedFilter
                        entityName="styled block"
                        onChangeUnusedFilter={onChangeUnusedFilter}
                        onSelectTag={onSelectTag}
                        unusedFilter={unusedFilter}
                        selectedTags={selectedTags}
                        usedTags={usedTags}
                        onSetModuleFilter={onSetModuleFilter}
                        moduleFilter={moduleFilter}
                    />
                    {!isEmpty && canEdit && <Menu.Item 
                        icon="plus" 
                        content="New" 
                        onClick={onCreate} 
                        title={isAddAllowed ? "Add new styled block" : "Disable filter to allow adding a new styled block" }
                        disabled={!isAddAllowed}
                    />}
                </PageHeader>
                {(selectedTags && 0 < selectedTags.length || unusedFilter || moduleFilter && 0 < moduleFilter.length) && 
                    <FilteredByOptionsView 
                        onChangeUnusedFilter={onChangeUnusedFilter}
                        onSelectTag={onSelectTag}
                        unusedFilter={unusedFilter}
                        selectedTags={selectedTags}
                        onSetModuleFilter={onSetModuleFilter}
                        moduleFilter={moduleFilter}
                    />}
                <Dimmer.Dimmable dimmed={loading}>
                    <Dimmer active={loading} inverted>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    
                    <Segment.Group style={{ maxHeight: "70vh", overflow: "auto" }}>
                        {isEmpty ? (
                            <NoDataPlaceholder
                                icon="window maximize outline"
                                text="Here we show the styles you've created."
                            >
                                <p>
                                    Styled blocks are the foundation stones of a beautiful design of your Kits. They can
                                    be used in Widgets to emphasize some paragraphs or to create a visual structure of
                                    the content.
                                </p>
                                {canEdit && <Button primary content="Add style" onClick={onCreate} />}
                            </NoDataPlaceholder>
                        ) : snippets.length == 0 ? (
                            <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
                                <p>We cannot find the item you are searching for.</p>
                            </NoDataPlaceholder>
                        ) : (
                            snippets.map((s) => (
                                <Snippet
                                    key={s.key}
                                    snippet={s}
                                    onRemove={onRemove}
                                    onRename={onRename}
                                    onSave={onSaveSnippet}
                                    canEdit={canEdit}
                                />
                            ))
                        )}
                    </Segment.Group>
                </Dimmer.Dimmable>
            </React.Fragment>
        );
    }
}

const dispatchToProps = (dispatch) => {
    return bindActionCreators(SnippetsManagerActions, dispatch);
};

export default compose(
    firebaseConnect([ "snippets", "subjects" ]),
    connect(
        (state) => ({
            loading: getVal(state.firebase, "requesting/snippets"),
            snippets: getFilteredSnippets(state),
            searchFilter: getSearchFilter(state),
            unusedFilter: unusedFilter(state),
            selectedTags: selectedTags(state),
            usedTags: usedTags(state),
            moduleFilter: getModuleFilter(state),
        }),
        dispatchToProps
    ),
    withAuth
)(SnippetsManager);
