import { TYPES } from "./YearsActions";

export const initialState = {
    selectedYearId: null,
    years: [],
    isLoading: false,
    showValid: true,
    isImporting: false,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.YEARS_YEAR_SELECTED:
            return { ...state, selectedYearId: payload };
        case TYPES.YEARS_LOAD:
            return { ...state, isLoading: true };
        case TYPES.YEARS_LOADING:
            return { ...state, isLoading: true };
        case TYPES.YEARS_SET:
            return { ...state, isLoading: false, years: payload };
        case TYPES.YEARS_FILTER_VALID:
            return { ...state, showValid: payload };
        case TYPES.YEARS_IMPORT:
            return { ...state, isImporting: true };
        case TYPES.YEARS_IMPORT_DONE:
            return { ...state, isImporting: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
