import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Divider, Form, Popup, Message } from "semantic-ui-react";
import { tenants, collectionConfig } from "./topicCollectionSelectors";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import { firebaseConnect } from "react-redux-firebase";

class AddCollectionPopup extends PureComponent {
    static propTypes = {
        context: PropTypes.object,
        onCancel: PropTypes.func.isRequired,
        onAdd: PropTypes.func.isRequired,
        defaultValues: PropTypes.object,
        errorMessage: PropTypes.string,
        onChange: PropTypes.func,
        tenants: PropTypes.object,
        collectionConfig: PropTypes.object,
    };

    state = {
        name: (this.props.defaultValues && this.props.defaultValues.name) || null,
        BeLA_id_t1: (this.props.defaultValues && this.props.defaultValues.BeLA_id) || null,
        BeLA_id_t2: (this.props.defaultValues && this.props.defaultValues.BeLA_id) || null
    };

    componentDidMount() {
        const { collectionConfig } = this.props;
        if (collectionConfig) {
            this.setState(collectionConfig);
        }
    }

    componentDidUpdate(prevProps) {
        const { collectionConfig } = this.props;
        if (prevProps.collectionConfig !== collectionConfig) {
            this.setState(collectionConfig);
        }
    }
    

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
        if (this.props.onChange) {
            this.props.onChange();
        }
    };

    handleYes = () => {
        const { name, BeLA_id_t1, BeLA_id_t2 } = this.state;
        let belaIdIntT1 = parseInt(BeLA_id_t1);
        let belaIdIntT2 = parseInt(BeLA_id_t2);
        this.props.onAdd({
            name,
            BeLA_id_t1: isNaN(belaIdIntT1) ? null : belaIdIntT1,
            BeLA_id_t2: isNaN(belaIdIntT2) ? null : belaIdIntT2,
        });
    };

    render() {
        const { context, onCancel, defaultValues, errorMessage, tenants } = this.props;
        const { name, BeLA_id_t1, BeLA_id_t2 } = this.state;

        return (
            <Popup context={context} open={!!context} wide="very" position="bottom left">
                <Popup.Header>{defaultValues ? "Edit" : "Create New"} Collection</Popup.Header>
                <Popup.Content style={{ width: "30em", marginTop: "1.5em"  }}>
                    <Form>
                        <Form.Input
                            label="Name"
                            required
                            placeholder="A collection name"
                            name="name"
                            value={name || ""}
                            onChange={this.handleChange}
                            autoFocus
                        />
                        <Form.Input
                            type="number"
                            label={"Collection ID in " + (tenants && tenants.t1 && tenants.t1.name || "BASIS") + " BeLA"}
                            placeholder="BeLA collection ID"
                            name="BeLA_id_t1"
                            value={BeLA_id_t1 || ""}
                            onChange={this.handleChange}
                        />
                        <Form.Input
                            type="number"
                            label={"Collection ID in " + (tenants && tenants.t2 && tenants.t2.name || "Spring") + " BeLA"}
                            placeholder="BeLA collection ID"
                            name="BeLA_id_t2"
                            value={BeLA_id_t2 || ""}
                            onChange={this.handleChange}
                        />
                    </Form>
                    {errorMessage && <Message error>{errorMessage}</Message>}
                    <Divider hidden />
                    <Button size="small" floated="right" onClick={onCancel} content="Cancel" />
                    <Button
                        primary
                        disabled={name == null || name.trim() === ""}
                        size="small"
                        floated="right"
                        onClick={this.handleYes}
                        content={defaultValues ? "Update" : "Add"}
                    />
                </Popup.Content>
            </Popup>
        );
    }
}


const dispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default compose(
    firebaseConnect(({ collectionId }) => {
        return collectionId && [ `config/topic_collections/${collectionId}` ] || [];
    }),
    connect(
        (state, props) => ({ 
            tenants: tenants(state),
            collectionConfig: collectionConfig(state, props),
        }),
        dispatchToProps
    )
)(AddCollectionPopup);

