import React from "react";
import PropTypes from "prop-types";
import { Form, Divider, Message } from "semantic-ui-react";
import memoize from "memoize-one";

const validateLabels = memoize((show_label, hide_label) => {
    const errors = {};
    if (!(show_label && show_label.trim())) {
        errors["show_label"] = "Fill label, e.g. \"Show Answer\"";
    }
    if (!(hide_label && hide_label.trim())) {
        errors["hide_label"] = "Fill label, e.g. \"Hide Answer\"";
    }
    return Object.keys(errors).length ? errors : null;
});

export const validateLabelsOf = (snippet) => (snippet && snippet.hideable
    ? validateLabels(snippet.show_label, snippet.hide_label)
    : null // no errors
);

export default class HideableOptions extends React.PureComponent {
    static propTypes = {
        config: PropTypes.object,
        onChange: PropTypes.func,
    };

    handleTitleBlur = ({ currentTarget: { name, value }}) => {
        this.props.onChange(name, value && value.trim());
    };

    handleTitleKeyDown = ({ keyCode, currentTarget: { name, value }}) => {
        if (keyCode === 13) { // on "Enter"
            this.props.onChange(name, value && value.trim());
        }
    };

    handleToggle = (e, { checked }) => {
        this.props.onChange("hideable", checked || null );
    }

    handleChange = (e, { name, value }) => {
        this.props.onChange(name, value && value.trim());
    }

    render() {
        const { config } = this.props;
        const errors = validateLabelsOf(config);
        return (
            <Form autoComplete="off" error={!!errors}>
                <Divider horizontal>Hideable Content</Divider>
                {config.isUsed ? (
                    <Message size="small"
                        style={{ padding: ".5em 1em" }}
                        content="This block has been used already. It's not allowed to turn off the hideable content."
                    />
                ) : (
                    <Form.Checkbox
                        toggle
                        width={8}
                        label="Hideable content"
                        checked={config.hideable}
                        onChange={this.handleToggle}
                        disabled={config.isUsed}
                    />
                )}
                <Form.Group>
                    <Form.Input
                        width={8}
                        name="show_label"
                        placeholder={"Fill label, e.g. \"Show Answer\""}
                        error={!!(errors && errors.show_label)}
                        label="Show button label"
                        defaultValue={config.show_label}
                        disabled={!config.hideable}
                        required={config.hideable}
                        onChange={this.handleChange}
                        onBlur={this.handleTitleBlur}
                        onFocus={this.handleTitleFocus}
                        onKeyDown={this.handleTitleKeyDown}
                    />
                    <Form.Input
                        width={8}
                        name="hide_label"
                        placeholder={"Fill label, e.g. \"Hide Answer\""}
                        error={!!(errors && errors.hide_label)}
                        label="Hide button label"
                        defaultValue={config.hide_label}
                        disabled={!config.hideable}
                        required={config.hideable}
                        onChange={this.handleChange}
                        onBlur={this.handleTitleBlur}
                        onFocus={this.handleTitleFocus}
                        onKeyDown={this.handleTitleKeyDown}
                    />
                </Form.Group>
            </Form>
        );
    }
}
