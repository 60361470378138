import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List, Header, Segment, Icon, Label } from "semantic-ui-react";
import { GroupedList } from "bmd-react";

const groupByFullKey = item => item.groupKey;
const filterByCustomText = (item, searchText) => item.filterText.toLowerCase().search(searchText.toLowerCase()) !== -1;

export default class CollectionList extends PureComponent {
    handleClick = (e, props) => {
        const { onSelectCollection } = this.props;
        onSelectCollection({
            id: parseInt(props["data-col-id"]),
            name: props["data-col-name"],
            recommended_obj_set: props["data-rec-obj_set"]
        });
        e.stopPropagation();
    };

    getItems = collections =>
        collections.map(keyValue => {
            let collection = keyValue.value;
            return {
                item: (
                    <List.Item
                        key={collection.collection_id}
                        data-col-id={collection.collection_id}
                        data-col-name={collection.collection_name}
                        data-rec-obj_set={collection.recommended_obj_set}
                        as="a"
                        onClick={this.handleClick}
                    >
                        <span>{collection.collection_name}</span>
                        <Label circular horizontal style={{ float: "right" }}>
                            {collection.topic_count}
                        </Label>
                    </List.Item>
                ),
                groupKey: collection.subject_name,
                filterText: collection.collection_name
            };
        });

    render() {
        const { collections } = this.props;

        if (!collections)
            return (
                <Segment placeholder>
                    <Header icon>
                        <Icon name="folder open outline" />
                        The collection list is empty.
                    </Header>
                </Segment>
            );

        return (
            <GroupedList
                itemList={this.getItems(collections)}
                groupingThreshold={4}
                selectable={false}
                groupingFunc={groupByFullKey}
                filteringFunc={filterByCustomText}
                link
            />
        );
    }
}

CollectionList.propTypes = {
    collections: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.shape({
                collection_name: PropTypes.string,
                collection_id: PropTypes.number,
                recommended_obj_set: PropTypes.string
            })
        })
    ),
    onSelectCollection: PropTypes.func
};
