import { all, takeLatest, put, select } from "redux-saga/effects";
import { TYPES, onCourseVariantSet, onAllCourseVariantSet, onSetCourseVariant } from "./CourseVariantsActions";
import { push } from "connected-react-router";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import firebase from "../../firebase";
import { toastError } from "../../component/toast";

function* redirectToKit({ payload }) {
    yield put(push(`/authorkits/${payload}`));
}

function* loadCVList() {
    let selectedYear = yield select(getSelectedYearId);
    try {
        let courseVariants = yield firebase.tabletProxy({
            method: "GET",
            uri: `/course-variant?intensity=Spork&year_id=${selectedYear}`,
        });
        yield put(onCourseVariantSet(courseVariants.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of course list failed.", message: err.message });
        yield put(onCourseVariantSet([]));
    }
}

function* loadAllCVList() {
    let selectedYear = yield select(getSelectedYearId);
    try {
        let courseVariants = yield firebase.tabletProxy({
            method: "GET",
            uri: `/course-variant?year_id=${selectedYear}`,
        });
        yield put(onAllCourseVariantSet(courseVariants.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of course list failed.", message: err.message });
        yield put(onAllCourseVariantSet([]));
    }
}

function* loadVariant({ payload }) {
    try {
        let courseVariant = yield firebase.tabletProxy({
            method: "GET",
            uri: `/course-variant/${payload}`,
        });
        yield put(onSetCourseVariant(courseVariant.data));
    } catch (err) {
        toastError({ code: err.code, header: "Loading of course failed.", message: err.message });
        yield put(onSetCourseVariant(null));
    }
}

export default function* courseVariantsSaga() {
    yield all([
        takeLatest(TYPES.COURSE_VARIANTS_REDIRECT_TO_KIT, redirectToKit),
        takeLatest(TYPES.CV_LOAD_LIST, loadCVList),
        takeLatest(TYPES.ALL_CV_LOAD_LIST, loadAllCVList),
        takeLatest(TYPES.CV_LOAD, loadVariant),
    ]);
}
