import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onFixProblemsRefs } from "./FixRefs4ProblemsAndProblemSetsActions";
import { inProgress, getProgress } from "./FixRefs4ProblemsAndProblemSetsSelectors";
import FixRefs4ProblemsAndProblemSets from "./FixRefs4ProblemsAndProblemSets";

const mapStateToProps = function(state) {
    return {
        inProgress: inProgress(state),
        progress: getProgress(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onFixProblemsRefs }, dispatch);
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(FixRefs4ProblemsAndProblemSets);
