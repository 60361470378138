const TYPES = {
    OPEN_ADD_POPUP: "Tablet/OPEN_ADD_POPUP",
    EDIT_DEVICE: "Tablet/EDIT_DEVICE",
};

const onOpenAddPopup = payload => {
    return {
        type: TYPES.OPEN_ADD_POPUP,
        payload,
    };
};

const onEditDevice = payload => {
    return {
        type: TYPES.EDIT_DEVICE,
        payload,
    };
};

export { TYPES, onOpenAddPopup, onEditDevice };
