import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isPermitted } from "./isPermitted";

const AccessControl = ({ userRoles, action, actionArray, children, renderNoAccess, data }) => {
    const permitted = isPermitted({ userRoles, action, actionArray, data });

    if (permitted) {
        return children;
    }
    return typeof renderNoAccess === "function" ? renderNoAccess() : renderNoAccess;
};

AccessControl.defaultProps = {
    action: "",
    renderNoAccess: null,
};

AccessControl.propTypes = {
    /** One or more permission to check, ie. "item:edit" or ["item:manage", "item:create"] */
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    /** DEPRECATED: use action property with either string or array of strings */
    actionArray: PropTypes.arrayOf(PropTypes.string),
    /** additional data which are used in dynamic role evaluation (see rbac-rules.js) */
    data: PropTypes.object,
    /** component or a function to render when user has no access */
    renderNoAccess: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default connect(state => ({
    userRoles: state.auth.user && state.auth.user.roles,
}))(AccessControl);
