import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withAuth } from "../component/withAuth";
import TopicCollectionContainer from "./TopicCollectionContainer";

const Routes = ({ isMoreThanUser }) => (
    isMoreThanUser && <React.Fragment>
        <Route path="/topicCollections" exact component={TopicCollectionContainer} />
        <Route path="/topicCollections/:collectionId" component={TopicCollectionContainer} />
    </React.Fragment>
);

Routes.propTypes = {
    isMoreThanUser: PropTypes.bool,
};

export default withAuth(Routes);
