import { connect } from "react-redux";
import { onAKitsError, onClearAKitsError, onSelectAKitForEdit } from "./AKitsActions";
import { getAKitsError, getSelectedAKitId } from "./AKitsSelectors";
import AKits from "./AKits";

export default connect((state, props) => ({
    error: getAKitsError(state),
    selectedAKitId: getSelectedAKitId(state, props),
}), {
    onAKitsError,
    onClearAKitsError,
    onSelectAKitForEdit,
})(AKits);
