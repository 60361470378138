import React from "react";
import PropTypes from "prop-types";
import { replaceRelLinks } from "../../utils/conversionUtils";
export default class QuestionItemContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object
    };

    render() {
        const { content } = this.props;
        return content && content.html ? (
            <div className="contains question" dangerouslySetInnerHTML={{ __html: content && content.html && replaceRelLinks(content.html) }} />
        ) : (
            <div className="missing question ui warning message">
                No question has been authored.
            </div>
        );
    }
}
