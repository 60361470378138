import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { onLessonRemove, onLessonShowProperties } from "../../outlinesActions";
import { getSelectedOutlineId } from "../../outlinesSelectors";
import Lesson from "./Lesson";


const mapStateToProps = function(state, ownProps) {
    return {
        selectedOutlineId: getSelectedOutlineId(state, ownProps),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onLessonRemove,
            onLessonShowProperties,
        },
        dispatch
    );
};

export default compose(
    connect(mapStateToProps, dispatchToProps)
)(Lesson);