import React, { PureComponent } from "react";
import { List, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { ConfirmDeletePopup, ActionIconsContainer } from "bmd-react";
import EditableText from "../../../../component/EditableText";
import { extractId } from "../../../../utils/selectorUtils";
import LessonsListDroppable from "../Lesson/LessonsListDroppable";

export default class LessonUnit extends PureComponent {
    static propTypes = {
        lessonUnitData: PropTypes.object,

        openEditorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        onEditingText: PropTypes.func,

        onLessonUnitRename: PropTypes.func,
        onLessonUnitUpdateDescription: PropTypes.func,
        onLessonUnitRemove: PropTypes.func,

        dragHandle: PropTypes.node,
        collapsible: PropTypes.bool,
        canEdit: PropTypes.bool,
    }

    static defaultProps = {
        collapsible: false,
    }

    state = { isCollapsed: false }

    handleRenameLessonUnit = (newName, event, props) => {
        this.props.onLessonUnitRename(extractId(props.id), newName);
    }

    handleChangeLessonUnitDescription = (newDesc, event, props) => {
        this.props.onLessonUnitUpdateDescription(extractId(props.id), newDesc);
    }

    handleRemoveLessonUnit = (callback, event, props) => {
        // @todo pass popup handling callback (param #1) although errors are showing in dedicated error box?
        this.props.onLessonUnitRemove(props.id);
        // close delete confirmation popup
        callback();
    }

    handleCollapse = (e) => {
        this.setState({ isCollapsed: !this.state.isCollapsed });
        e.preventDefault();
    }

    render() {
        const {
            lessonUnitData: lessonUnit,
            collapsible,
            openEditorId,
            onEditingText,
            canEdit
        } = this.props;
        const { isCollapsed } = this.state;
        // console.log(`%cLessonUnit::render() unitId/unitName=${lessonUnit.key}/${lessonUnit.name}`, "background-color: rgb(230, 255, 230)");
        return (
            <List.Item>
                <List.Header as="h3" className="editable">
                    <ActionIconsContainer floatRight>
                        {canEdit && <ConfirmDeletePopup
                            whatText={`lesson unit "${lessonUnit.name}"`}
                            id={lessonUnit.key}
                            key={lessonUnit.key}
                            position="right center"
                            trigger={<Icon
                                name="trash alternate outline"
                                link
                                className="actionIconClass right"
                                title="Remove lesson unit"
                            />}
                            onConfirm={this.handleRemoveLessonUnit}
                        />}
                    </ActionIconsContainer>
                    {canEdit && this.props.dragHandle}
                    {collapsible && <List.Icon
                        onClick={this.handleCollapse}
                        link
                        name={`caret ${isCollapsed ? "right" : "down"}`}
                        title={isCollapsed ? "Expand" : "Collapse"}
                    />}
                    <div style={{ display: "inline-block" }}>
                        <EditableText
                            id={"LUN" + lessonUnit.key}
                            key={`lu-name-${lessonUnit.key}`}
                            text={lessonUnit.name}
                            size="huge"
                            noPadding
                            canEdit={canEdit}
                            hideIcon={!!openEditorId}
                            // openEditorId={openEditorId}
                            onEditation={onEditingText}
                            onUpdate={this.handleRenameLessonUnit}
                            width="100%" 
                            title="Lesson unit name"
                        />
                            
                    </div>
                </List.Header>
                {!isCollapsed ? (<List.Content>
                    {(canEdit || lessonUnit.description) && <span title="Lesson unit objectives" style={{ whiteSpace: "pre-line" }}>
                        <EditableText
                            id={"LUD" + lessonUnit.key}
                            key={`lu-desc-${lessonUnit.key}`}
                            text={lessonUnit.description || ""}
                            noPadding
                            canEdit={canEdit}
                            hideIcon={!!openEditorId}
                            // openEditorId={openEditorId}
                            onEditation={onEditingText}
                            onUpdate={this.handleChangeLessonUnitDescription}
                            placeholder="Enter lesson unit objectives"
                            emptyPlaceholder="No objectives entered yet"
                            width="1000px"
                            allowEmpty
                            textarea
                            rows="5"
                        />
                    </span>
                    }
                    <LessonsListDroppable
                        lessonUnitId={lessonUnit.key}
                        lessonsList={lessonUnit.lessons}
                        maxOrdering={lessonUnit.maxLessonOrdering}
                        canEdit={canEdit}
                    />
                </List.Content>) : null}
            </List.Item>
        );
    }
}
