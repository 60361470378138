import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Label, Icon, Menu } from "semantic-ui-react";
import { getSchoolsById } from "../../SchoolList/schoolSelectors";
import { schoolFilter } from "../SchoolCoursesSelectors";
import { onChangeSchoolFilter } from "../SchoolCoursesActions";


class AdvancedFilterView extends PureComponent {
    static propTypes = {
        onChangeSchoolFilter: PropTypes.func,
        schoolFilter: PropTypes.any,
        schools: PropTypes.object,
    };

    handleResetSchoolFilter = () => {
        this.props.onChangeSchoolFilter("");
    }

    render() {
        const { schoolFilter, schools } = this.props;
        if (schoolFilter) {
            return (
                <React.Fragment>
                    <Menu text style={{ display: "inline-block", margin: "0" }}>
                        <Menu.Item header>FILTERED BY: </Menu.Item>
                    </Menu>
                    {!!schoolFilter &&
                        <Label
                            as="a"
                        >
                            School: {schools[schoolFilter].name}
                            <RemoveIcon onClick={this.handleResetSchoolFilter} />
                        </Label>
                    }
                </React.Fragment>
            );
        } else return null;
    }
}


class RemoveIcon extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
    };

    render() {
        return <Icon link name="close" onClick={this.props.onClick} />;
    }
}


const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onChangeSchoolFilter,
        },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        schoolFilter: schoolFilter(state),
        schools: getSchoolsById(state),
    }),
    dispatchToProps
)(AdvancedFilterView);