import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Icon, Divider, Menu, Segment, Loader, Button, Popup, Message, Breadcrumb } from "semantic-ui-react";
import AccessControl from "../../auth/AccessControl";
import IPlannerOutlineEditor from "./IPlannerOutlineEditor";
import ActionLinks from "../../component/ActionLinks";
import IPlannerOutlinePreview from "./IPlannerOutlinePreview";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import AssetsEditor from "./AssetsEditor";
import { AccessDeniedMessage } from "../../component/AccessDeniedMessage";

import "./IPlannerOutline.scss";
import LessonUnitEditor from "./LessonUnitEditor";
import EditableText from "../../component/EditableText";
import { ConfirmDeletePopup } from "bmd-react/dist/ConfirmPopup";
import memoize from "memoize-one";

export default class IPlannerOutline extends PureComponent {
    static propTypes = {
        iPlanner: PropTypes.object,
        onGoBack: PropTypes.func,
        iPlannerSiPi: PropTypes.object,
        iPlannerReadOnlySiPi: PropTypes.object,
        iPlannerSiPiByScope: PropTypes.object,
        onUpdateSiPiOrder: PropTypes.func,
        iPlannerId: PropTypes.string,
        onAddSiPi: PropTypes.func,
        onUpdateSiPi: PropTypes.func,
        onRemoveSiPi: PropTypes.func,
        ruleBaseName: PropTypes.string,
        entitiesName: PropTypes.string,
        userId: PropTypes.string,
        onLoadIplannerResources: PropTypes.func,
        enableSyllabusFeatures: PropTypes.bool,
        onMassAddSiPis: PropTypes.func,
        years: PropTypes.object,
        courseVariant: PropTypes.string,
        onLoadCourseVariant: PropTypes.func,
        cvIsLoading: PropTypes.bool,
        schoolIsLoading: PropTypes.bool,
        school: PropTypes.object,
        onSingleSchoolLoad: PropTypes.func,
        assets: PropTypes.object,
        onLoadCVTemplateByCV: PropTypes.func,
        onUpdateLesson: PropTypes.func,
        onCreateLesson: PropTypes.func,
        onRemoveLesson: PropTypes.func,
        onUpdateLessonOrder: PropTypes.func,
        onUpdateName: PropTypes.func,
        onRemove: PropTypes.func,
    };

    state = {
        activeView: "compose",
        viewScope: "teacher",
        isOpenLessonUnitEditor: false,
    }

    componentDidMount () {
        const { iPlannerId, onLoadIplannerResources, iPlanner, onLoadCourseVariant, onSingleSchoolLoad, onLoadCVTemplateByCV } = this.props;
        if (iPlanner) {
            onLoadIplannerResources({ iPlannerId, templateId: iPlanner.template_id ? iPlanner.template_id : null });
            if (iPlanner.course_variant_id) {
                onLoadCourseVariant(iPlanner.course_variant_id);
                if (onLoadCVTemplateByCV) {
                    onLoadCVTemplateByCV({ course_variant_id: iPlanner.course_variant_id, tenant_id: iPlanner.tenant_id });
                }
            }
            if (iPlanner.school_id) {
                onSingleSchoolLoad(iPlanner.school_id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { iPlanner, onLoadCourseVariant, onSingleSchoolLoad, onLoadCVTemplateByCV } = this.props;
        if (!prevProps.iPlanner && iPlanner) {
            if (iPlanner.course_variant_id) {
                onLoadCourseVariant(iPlanner.course_variant_id);
                if (onLoadCVTemplateByCV) {
                    onLoadCVTemplateByCV({ course_variant_id: iPlanner.course_variant_id, tenant_id: iPlanner.tenant_id });
                }
            }
            if (iPlanner.school_id) {
                onSingleSchoolLoad(iPlanner.school_id);
            }
        }
    }

    handleAddLesson = () => this.setState({ isOpenLessonUnitEditor: true });
    handleCloseLessonUnit = () => this.setState({ isOpenLessonUnitEditor: false });
    handleCreateLesson = (payload) => {
        const { onCreateLesson, iPlannerId } = this.props;
        onCreateLesson({ ...payload, iPlannerId });
    }

    handleChangeView = (e, { name }) => {
        this.setState({ activeView: name });
    }

    handleScopeAction = (e, data) => {
        const { name } = data;
        this.setState({
            viewScope: name === "teacher" || name === "student_view" || name === "classroom_view" ? name : null
        });
    };

    renderPreview = () => {
        const { viewScope } = this.state;
        const { iPlanner, iPlannerSiPiByScope } = this.props;
        const actions = [
            { name: "teacher", content: "As Teacher", active: viewScope === "teacher" },
            { name: "classroom_view", content: "As Classroom", active: viewScope === "classroom_view" },
            { name: "student_view", content: "As Student", active: viewScope === "student_view" }
        ];
        return (
            <React.Fragment>
                <Segment
                    tertiary
                    style={{
                        padding: "0.5em 1em 0.1em 1em",
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "#e8e8e8",
                        display: "flex",
                    }}
                >
                    <ActionLinks
                        floated="left"
                        horizontal
                        caption="VIEW"
                        actions={actions}
                        onClick={this.handleScopeAction}
                    />
                </Segment>
                <IPlannerOutlinePreview viewScope={viewScope} iPlanner={iPlanner} iPlannerSiPiByScope={iPlannerSiPiByScope} />
            </React.Fragment>);
    }

    handleSaveAsset = (data) => {
        const { onUpdateSiPi, iPlannerId } = this.props;
        onUpdateSiPi({ iPlannerId, lessonUnitId: "assets", sipiId: "asset_" + data.resource_id,  data });
    }

    handleRemoveAsset = (sipiId) => {
        const { onRemoveSiPi, iPlannerId } = this.props;
        onRemoveSiPi({ iPlannerId, lessonUnitId: "assets", sipiId });
    }

    renderNoOutlinePlaceholder = (iPlanner, editValidator) => (
        <React.Fragment>
            <Message attached="top">
                <Message.Header>Instruments</Message.Header>
                Here we display iPlanner lesson units with lessons and their instruments.
            </Message>
            <NoDataPlaceholder
                attached
                icon="stream outline"
                text="No lessons listed for this iPlanner."
            >
                {iPlanner && !iPlanner.uuid && !iPlanner.linked && <AccessControl {...editValidator}>
                    <Button primary content="Add Lesson" onClick={this.handleAddLesson} title="Create new lesson unit and lesson"/>
                </AccessControl>}
            </NoDataPlaceholder>
        </React.Fragment>
    )

    renderEditors = (editValidator) => {
        const { iPlanner, iPlannerId, iPlannerSiPi, iPlannerReadOnlySiPi, enableSyllabusFeatures,
            onMassAddSiPis, onUpdateSiPiOrder, onAddSiPi, onUpdateSiPi, onRemoveSiPi,
            assets, onUpdateLesson, onCreateLesson, onRemoveLesson, onUpdateLessonOrder } = this.props;
        const { activeView } = this.state;
        return (
            <React.Fragment>
                <Menu style={{clear: "both"}}>
                    <AccessControl
                        {...editValidator}
                        renderNoAccess={!iPlanner.outline && <Menu.Item
                            active={activeView === "compose"}
                            name="compose"
                            onClick={this.handleChangeView}
                            style={{ display: "block" }}
                        >
                            Compose
                        </Menu.Item> || null}
                    >
                        <Menu.Item
                            active={activeView === "compose"}
                            name="compose"
                            onClick={this.handleChangeView}
                            style={{ display: "block" }}
                        >
                            <Icon className="pen nib sporkPrimaryColorCharcoalGray" />
                            Compose
                        </Menu.Item>
                    </AccessControl>
                    {iPlanner.outline && <Menu.Item
                        active={activeView === "preview"}
                        name="preview"
                        onClick={this.handleChangeView}
                        style={{ display: "block" }}
                    >
                        <Icon className="eye sporkPrimaryColorCharcoalGray" />
                        Preview
                    </Menu.Item>}
                    {enableSyllabusFeatures && <Menu.Item
                        active={activeView === "assets"}
                        name="assets"
                        onClick={this.handleChangeView}
                        style={{ display: "block" }}
                    >
                        <Icon className="cabinet filing sporkPrimaryColorCharcoalGray" />
                        Assets
                    </Menu.Item>}
                </Menu>
                {"compose" === activeView ? (
                    <AccessControl {...editValidator}
                        renderNoAccess={iPlanner.outline ? this.renderPreview : this.renderNoOutlinePlaceholder(iPlanner, editValidator)}
                    >
                        {iPlanner.outline ?
                            <IPlannerOutlineEditor
                                iPlannerId={iPlannerId}
                                iPlannerSiPi={iPlannerSiPi}
                                iPlannerReadOnlySiPi={iPlannerReadOnlySiPi}
                                enableSyllabusFeatures={enableSyllabusFeatures}
                                iPlanner={iPlanner}
                                onUpdateSiPiOrder={onUpdateSiPiOrder}
                                onAddSiPi={onAddSiPi}
                                onUpdateSiPi={onUpdateSiPi}
                                onRemoveSiPi={onRemoveSiPi}
                                onMassAddSiPis={onMassAddSiPis}
                                onUpdateLesson={onUpdateLesson}
                                onCreateLesson={onCreateLesson}
                                onRemoveLesson={onRemoveLesson}
                                onUpdateLessonOrder={onUpdateLessonOrder}
                            /> : this.renderNoOutlinePlaceholder(iPlanner, editValidator)}
                    </AccessControl>
                ) : "preview" === activeView ? (
                    this.renderPreview()
                ) : (
                    <AssetsEditor
                        editValidator={editValidator}
                        onSave={this.handleSaveAsset}
                        onRemove={this.handleRemoveAsset}
                        assets={assets}
                    />
                )}
            </React.Fragment>
        );
    }

    renderNoOutlineFromBela = () => (
        <Popup
            inverted
            wide="very"
            trigger={<span style={{fontSize: "smaller", fontStyle: "italic", color: "grey"}}>Can&apos;t see iPlanner outline here?</span>}
        >
        iPlanner works with Syllabus Revision Report from BeLA. Make sure the Syllabus Report is properly initialized. Please note that synchronization of changes from BeLA can take up to 60 minutes.
        </Popup>
    )

    handleUpdateName = (name) => {
        const { iPlannerId, onUpdateName } = this.props;
        onUpdateName({ iPlannerId, name });
    }

    handleRemove = () => {
        const { iPlannerId, onRemove } = this.props;
        onRemove(iPlannerId);
    }

    render() {
        const { iPlanner, onGoBack, ruleBaseName, entitiesName, userId, enableSyllabusFeatures, years, courseVariant, cvIsLoading,
            schoolIsLoading, school, assets } = this.props;
        const { isOpenLessonUnitEditor } = this.state;
        
        if (!iPlanner) {
            return (<React.Fragment>
                <Header as="h2" className="pageHeader">{entitiesName}</Header>
                <NoDataPlaceholder icon="file outline" text="Unfortunately this item does not exist.">
                    <Button primary content={"Go to " + entitiesName + " list"} onClick={onGoBack} />
                </NoDataPlaceholder>
            </React.Fragment>);
        }

        const editValidator = {
            action: `${ruleBaseName}:manage`,
            data: {
                teacher: iPlanner.teacher ? iPlanner.teacher.user_id : null,
                userId,
                ccas: iPlanner.cv_ccas || iPlanner.syll_ccas 
            }
        };
        const iPlannerName = iPlanner && (iPlanner.syllabus_name || iPlanner.name) || "";
        return iPlanner.noAccess ? <AccessDeniedMessage />
            : (
                <React.Fragment>
                    <IPlannerBreadcrumb onGoBack={onGoBack} parentPageName={entitiesName} />
                    <Header as="h2" className="pageHeader">
                        {iPlanner && (iPlanner.uuid ? <React.Fragment>{iPlannerName}</React.Fragment> : (
                            <AccessControl
                                {...editValidator}
                                renderNoAccess={<React.Fragment>{iPlannerName}</React.Fragment>}
                            >
                                <EditableText
                                    text={iPlanner && (iPlanner.syllabus_name || iPlanner.name)}
                                    onUpdate={this.handleUpdateName}
                                />
                                {!iPlanner.used && <ConfirmDeletePopup
                                    floated
                                    trigger={<Icon
                                        name="trash alternate outline"
                                        className="actionIconClass right initialSize"
                                        title={`Remove ${iPlanner && (iPlanner.syllabus_name || iPlanner.name)}`}
                                        link
                                    />}
                                    headerText={`Remove ${iPlanner && (iPlanner.syllabus_name || iPlanner.name)}`}
                                    whatText={iPlanner && (iPlanner.syllabus_name || iPlanner.name)}
                                    onConfirm={this.handleRemove}
                                />}
                            </AccessControl>
                        ))}
                        {years && years[iPlanner.year_id] && (
                            <Header.Subheader>
                                for {years[iPlanner.year_id].name}
                                {iPlanner.template_id && iPlanner.templateName && ` based on template ${iPlanner.templateName}`}
                            </Header.Subheader>
                        )}
                    </Header>
                    {isOpenLessonUnitEditor && <LessonUnitEditor
                        lessonUnit={{}}
                        onClose={this.handleCloseLessonUnit}
                        onCreate={this.handleCreateLesson}
                        unitList={["Unit"]}
                    />}
                    {iPlanner !== null && (
                        <div style={{ float: "right", textAlign: "right" }}>
                            {((cvIsLoading || schoolIsLoading) && (
                                <Icon name="circle notched" loading title="Loading details" />
                            )) || (
                                <React.Fragment>
                                    {iPlanner.course_variant_id && courseVariant}
                                    <br />
                                    {iPlanner.teacher && iPlanner.teacher.first_name && iPlanner.teacher.last_name && `${iPlanner.teacher.first_name} ${iPlanner.teacher.last_name}`}{iPlanner.school_id && school &&  ` - ${school.name}`}
                                </React.Fragment>
                            )}
                        </div>
                    )}
                    
                    <Divider clearing hidden />
                    {iPlanner && (
                        undefined === iPlanner.outline
                            ? <Loader active>Loading...</Loader>
                            : (!enableSyllabusFeatures ? // iPlanner Template
                                null === iPlanner.outline ?
                                    this.renderNoOutlinePlaceholder(iPlanner, editValidator)
                                    : <AccessControl 
                                        {...editValidator}
                                        renderNoAccess={this.renderPreview}
                                    >
                                        {this.renderEditors(editValidator)}
                                    </AccessControl>
                                : ( // iPlanner
                                    iPlanner.uuid || iPlanner.linked ? // Synced from BeLA or linked
                                        null !== iPlanner.outline ? // Has outline
                                            this.renderEditors(editValidator)
                                            : <React.Fragment>
                                                <AssetsEditor
                                                    editValidator={editValidator}
                                                    onSave={this.handleSaveAsset}
                                                    onRemove={this.handleRemoveAsset}
                                                    assets={assets}
                                                />
                                                {this.renderNoOutlineFromBela()}
                                            </React.Fragment>
                                        : // Manually created
                                        null !== iPlanner.outline || assets && 0 < assets.list.length ? // Has outline or assets
                                            this.renderEditors(editValidator)
                                            : <React.Fragment>
                                                {this.renderNoOutlinePlaceholder(iPlanner, editValidator)}
                                                <AssetsEditor
                                                    editValidator={editValidator}
                                                    onSave={this.handleSaveAsset}
                                                    onRemove={this.handleRemoveAsset}
                                                    assets={assets}
                                                />
                                            </React.Fragment>
                                )
                            )
                    )}
                </React.Fragment>
            );
    }
}

/**
 * Prints a short Breadcrumb in iPlanners.
 *
 * @class IPlannerBreadcrumb
 * @extends {React.PureComponent}
 */
class IPlannerBreadcrumb extends React.PureComponent {
    static propTypes = {
        /** Callback to run */
        onGoBack: PropTypes.func,
        parentPageName: PropTypes.string,
    };

    getSections = memoize((onGoBack, parentPageName) => [
        { key: "iPlannerList", content: parentPageName, link: true, onClick: onGoBack }
    ]);
      
    render() {
        const { onGoBack, parentPageName } = this.props;
        const sections = this.getSections(onGoBack, parentPageName);
        return <Breadcrumb icon="right angle" sections={sections} />;
    }
}
