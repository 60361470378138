
import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Header, Dimmer, Loader, Segment } from "semantic-ui-react";
import TopicTreeSelector from "../../../../TopicCollection/TopicTreeSelector";
import ConfirmDeleteWithContext from "../../../../component/ConfirmDeleteWithContext";
import RemoveIcon from "../../../../component/RemoveIcon";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";
import Topic from "../../../../TopicCollection/Topic";

export default class WidgetTopicsEdit extends React.PureComponent {
    
    static propTypes = {
        loadingTopics: PropTypes.bool,
        canEdit: PropTypes.bool,
        libraryMode: PropTypes.bool,
        lessonName: PropTypes.string,
        topics: PropTypes.array,
        id: PropTypes.string,
        onAddTopic: PropTypes.func,
        onRemoveTopic: PropTypes.func,
        widget: PropTypes.object,
        lessonTopics: PropTypes.object,
    };

    state = {
        selectedTopic: {},
        removeContext: null,
    };

    handleAssignTopic = () => {
        const { id, onAddTopic } = this.props;
        const { selectedTopic } = this.state;
        Object.keys(selectedTopic).map(topic => onAddTopic({ widgetId: id, topicId: topic }));
        this.setState({ selectedTopic: {} });
    };
    handleSelectTopic = topic => this.setState({ selectedTopic: { ...topic } });

    handleRemove = (e, id) => {
        const { removeTopicId } = this.state;
        if (!removeTopicId) {
            this.setState({
                removeContext: e.currentTarget,
                removeTopicId: id,
            });
        }
    };

    handleRemoveConfirm = () => {
        const { id, onRemoveTopic } = this.props;
        const { removeTopicId } = this.state;
        onRemoveTopic({ widgetId: id, topicId: removeTopicId });
        this.handleCancelRemove();
    };

    handleCancelRemove = () => {
        this.setState({ removeTopicId: null, removeContext: null });
    };

    render() {
        const { loadingTopics, canEdit, libraryMode, lessonName, topics, widget, lessonTopics} = this.props;
        const { selectedTopic, removeContext } = this.state;

        let count = topics ? topics.length : 0;

        return (<Dimmer.Dimmable dimmed={loadingTopics}>
            <Dimmer active={loadingTopics} inverted>
                <Loader>Loading topics data...</Loader>
            </Dimmer>
            {canEdit && (
                <React.Fragment>
                    <ConfirmDeleteWithContext
                        context={removeContext}
                        confirmText="Unassign"
                        onConfirm={this.handleRemoveConfirm}
                        whatText="topic from this widget. Be careful, removing topic from the widget can break topic coverage in some lesson where this widget is used"
                        onCancel={this.handleCancelRemove}
                    />
                    {!libraryMode && <Header as="h4" >Topics in lesson {lessonName ? "\""+lessonName+"\"" : ""}</Header>}
                    <TopicTreeSelector
                        onChange={this.handleSelectTopic}
                        selectedTopics={selectedTopic}
                        usedTopicIds={widget.topics}
                        filterOnly={lessonTopics}
                        enableLessonFilter={libraryMode}
                        /* menu={!libraryMode ? <Header as="h4" >Topics in lesson {lessonName ? "\""+lessonName+"\"" : ""}</Header> : undefined} */
                
                    />
                    <div style={{ textAlign: "center" }}>
                        <Button
                            icon="arrow down"
                            labelPosition="left"
                            content="Assign selected topics to widget"
                            title="Assign topic to widget"
                            disabled={!selectedTopic || 0 == Object.keys(selectedTopic).length}
                            onClick={this.handleAssignTopic}
                        />
                    </div>
                </React.Fragment>
            )}
            <span style={{float: "right"}}><b>{count}</b> <span style={{color: "grey"}}> assigned topic
                {(0 == count || 1 < count) && "s"}
            </span></span>
            <Header style={{marginTop: 0}}>{widget && <span className="sporkPrimaryColorCharcoalGray">{widget.name}</span>}</Header>
            
            {topics && topics.length ? (
                <Segment style={{ minHeight: "150px", height: "150px", overflowY: "auto"}}>
                    {topics.map(topic => (
                        <div
                            key={topic.key}
                            className={"widgetTopicItem actionIcons " + (libraryMode && "libraryMode")}
                        >
                            {!libraryMode && (topic.lessonTopic ? <TopicAssociatedIcon />
                                : <TopicNotAssociatedIcon />)}
                                
                            <Topic topic={topic.value} />
                            {canEdit ? (
                                <RemoveIcon
                                    onRemove={this.handleRemove}
                                    id={topic.key}
                                    entityName={`topic ${topic.name}`}
                                />
                            ) : (
                                <span />
                            )}
                        </div>
                    ))}
                </Segment>
            ) : (
                <NoDataPlaceholder
                    text="Widget has no topics."
                    style={{ minHeight: "150px", height: "150px" }}
                />
            )}
        </Dimmer.Dimmable>
        );
    }
}

const TopicAssociatedIcon = () => {
    return <Icon
        name="linkify"
        color="blue"
        fitted
        title="The topic is associated with this lesson."
    />;
};

const TopicNotAssociatedIcon = () => {
    return <Icon
        name="unlinkify"
        color="red"
        fitted
        title="The topic isn't associated with this lesson."
    />;
};