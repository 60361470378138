/** A set of compare functions usefull for Array.sort() */

/** Simple compare by < operator
 * @param {*} a
 * @param {*} b
 * @return -1 when a < b
 * @return  0 when a = b
 * @return  1 when a > b
 */
export const compareLess = (a, b) =>  (a === b ? 0 : (a < b ? -1 : 1));

/** Compares values with respect to null value. Null value is treated as the lowest possible value.
 * Notice the function returns 0 when a and b are both not null (regardless of a == b). This allows to pass comparison of non-null values of a and b to another comparison function in chained comparison.
 * @param {*} a
 * @param {*} b
 * @return -1 when a is null anf b is not null
 * @return  0 when both a and b are null or both are not null
 * @return  1 when b is null anf a is not null
 */
export const nullsFirst = (a ,b) => {
    const val = (a === null && b === null
        ? 0
        : ( (a === null)
            ? -1
            :   (b === null)
                ?  1
                : 0) );
    return val;

};

/** Compares values with respect to null value. Null value is treated as the highest possible value.
 * It is the opposite function to nullsFirst().
 * Notice the function returns 0 when a and b are both not null (regardless of a == b). This allows to pass comparison of non-null values of a and b to another comparison function in chained comparison.
 * @param {*} a
 * @param {*} b
 * @return -1 when a is null anf b is not null
 * @return  0 when both a and b are null or both are not null
 * @return  1 when b is null anf a is not null
 */
export const nullsLast = (a, b) => {
    /* Originally it was -1*nullsFirst() but that returns "-0" instead of 0. So I use switch instead of some functional construct. */
    switch (nullsFirst(a,b)) {
        case -1: return 1;
        case  1: return -1;
        default: return 0;
    }
};

/** Creates a comparison function composed from a list of comparison functions.
 * It that takes first from list of comparison functions and applies it. If the result is 0 (equal) it takes the next comparison function and so on until an unequality is reached (-1 or 1 is returned) or until no comparison function remains (compared entities are equal and 0 is returned.)
 * @param comparisonFunction one comparison function is mandatory
 * @param comparisonFunctions list of comparison functions
 * @return comparison function
 *
 * Example:
 * const nullAwareCompareLess = chainedCompare(nullsLast, compareLess);
 * someArray.sort(nullAwareCompareLess);
 */
export const chainedCompare = (comparisonFunction, ...comparisonFunctions) => (a, b) => {

    const result = comparisonFunction(a, b);

    return ( (result === 0) && (comparisonFunctions.length > 0))
        ? chainedCompare(...comparisonFunctions)(a, b)
        : result;
};

/** Creates a comparison function that compares two entities by their attribute value.
 * The attribute is specified by attrName. For nested entities their names are separated by dot ".". For example "rank.type.name".
 * According to nullsComparison() parameter a missing values / attributes are treated as the lowest / highest values. nullsLast is used by default.
 * Optionally values could be transformed by transform() function before the comparison. This allows converting strings to lower case (for example) easily.
 * @param {string} attrName name of entity attribute or path to the attribute. E.g. "age" or "rank.type.name"
 * @param nullsComparison comparison function for comparing null or missing values. nullsLast() is used by default.
 * @param transform (optional) a function for transformation of the attribute's value
 * @return comparison function
 */
export const compareByEntityAttribute = (attrName, nullsComparison = nullsLast, transform = value => value) => (a, b) => {

    const getValue = givenEntity => attrName.split(".").reduce( (entity, simpleAttrName) => entity && entity[simpleAttrName], givenEntity);

    const valA = getValue(a);
    const valB = getValue(b);

    return chainedCompare(
        nullsComparison,
        compareLess
    ) (transform(valA), transform(valB));
};

export const compareByUserNamesCI = (a, b) => {
    const av1 = (a.last_name || "").toLocaleLowerCase();
    const av2 = (a.first_name || "").toLocaleLowerCase();
    const bv1 = (b.last_name || "").toLocaleLowerCase();
    const bv2 = (b.first_name || "").toLocaleLowerCase();

    return av1 === bv1 ? av2 === bv2 ? 0 : (av2 < bv2 ? -1 : 1) : (av1 < bv1 ? -1 : 1);
};
