import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { PureComponent } from "react";
import { Modal, Menu, Icon, Form, Dropdown, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { getSchoolOptions, getTeacherOptions, teacherFilter, schoolFilter } from "../SyllabiListSelectors";
import { isLoading as schoolIsLoading } from "../../../External/SchoolList/schoolSelectors";
import { onChangeTeacherFilter, onChangeSchoolFilter } from "../SyllabiListActions";

class AdvancedSyllabusFilter extends PureComponent {
    static propTypes = {
        onChangeSchoolFilter: PropTypes.func,
        onChangeTeacherFilter: PropTypes.func,
        schoolOptions: PropTypes.array,
        teacherOptions: PropTypes.array,
        schoolIsLoading: PropTypes.bool,
        teacherFilter: PropTypes.string,
        schoolFilter: PropTypes.string,
    };

    state = {
        open: false
    };

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    composeFilterLabel = (count) => {
        if (0 == count) {
            return "Filter";
        } else if (1 == count) {
            return "Filtered (by 1)";
        } else {
            return `Filtered (by ${count})`;
        }
    }

    handleChangeSchool = (e, {value}) => {
        this.props.onChangeSchoolFilter(value);
    }

    handleChangeTeacher = (e, {value}) => {
        this.props.onChangeTeacherFilter(value);
    }

    render() {
        const { schoolOptions, teacherOptions, schoolIsLoading, teacherFilter, schoolFilter } = this.props;
        const { open } = this.state;
        return (
            <Modal
                open={open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                trigger={
                    <Menu.Item link title="Click here to set advanced filter.">
                        <Icon name="filter" />
                        {this.composeFilterLabel(!!teacherFilter + !!schoolFilter)}
                    </Menu.Item>
                }
                size="small"
            >
                <Modal.Header>Advanced iPlanner filter</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSave}>
                        <Form.Field>
                            <label>
                                School
                            </label>
                            <Dropdown
                                fluid
                                selection
                                search
                                options={schoolOptions}
                                clearable
                                loading={schoolIsLoading}
                                value={schoolFilter}
                                onChange={this.handleChangeSchool}
                                placeholder="Select school to filter iPlanner"
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>
                                Teacher
                            </label>
                            <Dropdown
                                fluid
                                selection
                                search
                                clearable
                                options={teacherOptions}
                                value={teacherFilter}
                                onChange={this.handleChangeTeacher}
                                placeholder="Select teacher to filter iPlanner"
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onChangeTeacherFilter,
            onChangeSchoolFilter,
        },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        schoolOptions: getSchoolOptions(state),
        teacherOptions: getTeacherOptions(state),
        schoolIsLoading: schoolIsLoading(state),
        teacherFilter: teacherFilter(state),
        schoolFilter: schoolFilter(state),
    }),
    dispatchToProps
)(AdvancedSyllabusFilter);