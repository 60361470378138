const TYPES = {
    YEARS_YEAR_SELECTED: "Year/SELECT_YEAR",
    YEARS_SET: "Year/SET",
    YEARS_FILTER_VALID: "Years/FILTER_VALID",
    YEARS_IMPORT: "Years/FILTER_IMPORT",
    YEARS_IMPORT_DONE: "Years/YEARS_IMPORT_DONE",
    YEAR_CREATE: "Year/CREATE",
    YEAR_UPDATE: "Year/UPDATE",
};

const onSelectYear = payload => ({
    type: TYPES.YEARS_YEAR_SELECTED,
    payload,
});

const onSetYears = payload => ({
    type: TYPES.YEARS_SET,
    payload,
});

const onImportYears = payload => ({
    type: TYPES.YEARS_IMPORT,
    payload,
});

const onImportYearsDone = payload => ({
    type: TYPES.YEARS_IMPORT_DONE,
    payload,
});

const onCreateYear = payload => ({
    type: TYPES.YEAR_CREATE,
    payload,
});

const onUpdateYear = (yearId, payload) => ({
    type: TYPES.YEAR_UPDATE,
    yearId,
    payload,
});

const onFilterValid = payload => ({
    type: TYPES.YEARS_FILTER_VALID,
    payload,
});

export { TYPES, onSelectYear, onSetYears, onImportYears, onImportYearsDone, onFilterValid, onCreateYear, onUpdateYear };
