import React, { PureComponent } from "react";
import { List, Icon, Header, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import { AddItemButton, ConfirmDeletePopup } from "bmd-react";
import EditableText from "../../../../component/EditableText";

export default class OiiView extends PureComponent {

    static propTypes = {
        oiiObjectives: PropTypes.array,
        onAddObjective: PropTypes.func.isRequired,
        onUpdateObjectiveName: PropTypes.func.isRequired,
        onRemoveObjective: PropTypes.func,
        error: PropTypes.object,
        onObjectiveSetError: PropTypes.func,
        onClearObjectiveSetError: PropTypes.func,
    }

    state = {
        editingText: "",
    }

    handleEditText = (id) => {
        const { editingText } = this.state;
        if (editingText)
            this.setState({ editingText: "" });
        else
            this.setState({ editingText: id || true });
    }

    handleAddObjective = (/*e, i*/) => {
        // clear error message at start if visible
        if (this.props.error)
            this.props.onClearObjectiveSetError();

        const { oiiObjectives, onAddObjective, onObjectiveSetError } = this.props;
        onAddObjective(`New OII Objective nr. ${(oiiObjectives && oiiObjectives.length || 0) + 1}`, onObjectiveSetError);
    }

    handleUpdateObjectiveName = (value, e, props) => {
        // clear error message at start if visible
        if (this.props.error)
            this.props.onClearObjectiveSetError();

        const { onUpdateObjectiveName, onObjectiveSetError } = this.props;
        onUpdateObjectiveName(value, props.id, onObjectiveSetError);
    }

    handleRemoveObjective = (callback, event, props) => {
        // clear error message at start if visible
        if (this.props.error)
            this.props.onClearObjectiveSetError();

        const { onRemoveObjective, onObjectiveSetError } = this.props;

        onRemoveObjective(
            props.id,
            props,
            // onSuccess
            // Caller can pass a message, in the future we could show/log the message. Now we just call the callback function with "" which closes the confirm dialog.
            // Update: call of the callback with "" has been replaced with function doing nothing. Removal of the component causes closing the dialog by rendering the component with unique key attribute
            (/*msg = ""*/) => { return; },
            // onFailure
            callback,
            onObjectiveSetError
        );

    }

    render() {
        const { oiiObjectives } = this.props;
        const { editingText } = this.state;
        const canEdit = !editingText;
        return (
            <div>
                <Header>OII - Other Instruction Instruments</Header>
                <AddItemButton buttonText="Add Objective" title="Add new OII Objective" onClick={this.handleAddObjective} />
                <List selection className="sortableContainer">
                    {oiiObjectives && oiiObjectives.map(
                        (value, index) => (
                            <List.Item key={`item-${index}`} className="editable sortableItem">
                                <List.Header>
                                    <span style={{position: "absolute"}}>
                                        <EditableText
                                            key={value.id}
                                            text={value.name}
                                            onUpdate={this.handleUpdateObjectiveName}
                                            noPadding
                                            onEditation={this.handleEditText}
                                            canEdit={canEdit}
                                            openEditorId={editingText}
                                            placeholder="Enter Objective name"
                                            // errorMessage="Objective name cannot be empty"
                                            id={value.id} />
                                    </span>
                                    <ConfirmDeletePopup
                                        key={value.id}
                                        floated
                                        // onConfirm={onConfirm}
                                        onConfirm={this.handleRemoveObjective}
                                        whatText={`OII objective "${value.name}"`}
                                        trigger={<Icon name="trash alternate outline" link className="actionIconClass right" />}
                                        id={value.id} />
                                </List.Header>
                            </List.Item>
                        )
                    ) || <Message info>No objectives</Message>}
                </List>
            </div>
        );
    }
}