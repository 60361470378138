import React from "react";
import { Route } from "react-router-dom";
import DeviceList from "./DeviceList/DeviceListContainer";
import AccessControl from "../auth/AccessControl";

const Routes = () => (
    <AccessControl action="tablet:manage">
        <Route path="/tablet/devices" component={DeviceList} />
    </AccessControl>
);
export default Routes;
