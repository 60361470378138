import { TYPES } from "./ConvertLessonDataActions";

const initialState = {
    inProgress: false,
    progress: { processedHw: 0, processedPS: 0, processedLessons: 0 },
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.CONVERT_LESSON_DATA:
            return { ...state, inProgress: true };
        case TYPES.CONVERT_LESSON_DATA_FINISHED:
            return { ...state, inProgress: false, progress: { processedHw: 0, processedPS: 0, processedLessons: 0 } };
        case TYPES.CONVERT_LESSON_DATA_PROGRESS:
            return { ...state, progress: { processedHw: payload.processedHw, processedPS: payload.processedPS, processedLessons: payload.processedLessons } };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
