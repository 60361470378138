import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";

export default class ErrorMessage extends PureComponent {

    static propTypes = {
        error: PropTypes.shape({
            message: PropTypes.string.isRequired,
            code: PropTypes.string,
        }),
        onDismiss: PropTypes.func.isRequired,
    }

    handleDismissError = () => {
        if (this.props.error)
            this.props.onDismiss();
    }

    render() {
        const { error } = this.props;
        return (
            error && error.message && <Message error floating content={error.message} onDismiss={this.handleDismissError} /> || null
        );
    }

}