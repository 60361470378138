exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1D7pZRISs3_0OVphzwzPFP {\n  border-width: 0px !important; }\n  ._1D7pZRISs3_0OVphzwzPFP.ui.table thead th {\n    padding: .75em 0.5em; }\n  ._1D7pZRISs3_0OVphzwzPFP .yXdT9PyfQWQ0OJp4A1bj2 {\n    max-width: 250px;\n    overflow-x: hidden; }\n    ._1D7pZRISs3_0OVphzwzPFP .yXdT9PyfQWQ0OJp4A1bj2 ._1P7trPBuSHdpc0q7psmHEh {\n      zoom: .85; }\n  ._1D7pZRISs3_0OVphzwzPFP ._2aOuKFFMuen3F4dPKhjq6J {\n    width: 65px; }\n", ""]);

// exports
exports.locals = {
	"problemTable": "_1D7pZRISs3_0OVphzwzPFP",
	"question": "yXdT9PyfQWQ0OJp4A1bj2",
	"preview": "_1P7trPBuSHdpc0q7psmHEh",
	"actions": "_2aOuKFFMuen3F4dPKhjq6J"
};