import React from "react";
import { EditableInput, EditActionIcon } from "bmd-react";
import PropTypes from "prop-types";
import PlaceholderText from "./PlaceholderText";

export default class EditableText extends React.PureComponent {

    static propTypes = {
        text: PropTypes.string,
        size: PropTypes.oneOf([ "mini", "tiny", "small", "large", "big", "huge", "massive" ]),
        width: PropTypes.string,
        onUpdate: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        onEditation: PropTypes.func,
        canEdit: PropTypes.bool,
        hideIcon: PropTypes.bool,
        id: PropTypes.string,
        openEditorId: PropTypes.string,
        floated: PropTypes.string,
        emptyPlaceholder: PropTypes.string,
        title: PropTypes.string,
        allowEmpty: PropTypes.bool, // used by EditableInput from bmd-react
        textarea: PropTypes.bool, // used by EditableInput from bmd-react
    };

    state = { 
        editation: false,
    };

    static getDerivedStateFromProps(props, state) { // eslint-disable-line no-unused-vars
        const { id, openEditorId } = props;
        if (id && openEditorId && id === openEditorId) {
            return ({ editation: true });
        }
        return null;
    }

    handleEditation = () => {
        const { editation } = this.state;
        if (editation || false != this.props.canEdit) {
            this.setState({ editation: !editation });
            if (this.props.onEditation) {
                this.props.onEditation(this.props.id || null);
            }
        }
    }

    handleCancel = (event, props) => {
        const { onCancel } = this.props;
        onCancel && onCancel(event, props);
        this.handleEditation();
    }

    handleUpdate = (value, event, props) => {
        this.props.onUpdate(value, event, props);
        this.handleEditation();
    }

    render() {
        let { editation } = this.state;
        const { text, size, onEditation, hideIcon, canEdit = true, onUpdate, onCancel, openEditorId, floated, emptyPlaceholder, title, ...others } = this.props; // eslint-disable-line no-unused-vars
        
        return (
            <span {...floated && {style: { float: floated }}}>
                {!editation || !canEdit ?
                    <div title={title} className="editable" style={{display: "inline-block"}}>
                        {text || emptyPlaceholder && <PlaceholderText text={emptyPlaceholder}/>}
                        <EditActionIcon title="Click here to edit the text" onClick={this.handleEditation} hidden={!canEdit || hideIcon} />
                    </div> :
                    <EditableInput
                        {...others}
                        noPadding={!this.props.textarea}
                        defaultValue={text}
                        className={size}
                        onUpdate={this.handleUpdate} 
                        onCancel={this.handleCancel}
                    />}
            </span>
        );
    }
}