import React from "react";
import { Route } from "react-router-dom";
import TopicsContainer from "./TopicsContainer";

const Routes = () => (
    <React.Fragment>
        <Route path="/topics/list" component={TopicsContainer} />
    </React.Fragment>
);
export default Routes;
