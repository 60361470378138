import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onUpdated } from "./ResourcePublicFlagActions";
import { toastError, DB_ERROR } from "../../../../component/toast";
import firebase from "../../../../firebase";
import { onPatchResourceData } from "../../ResourcesActions";

function* updateFlag ({ payload: { resourceId, is_public } }) {
    try {
        const resource = yield firebase.tabletProxy({
            method: "PATCH",
            uri: `/resources/${resourceId}`,
            body: { is_public }
        });
        yield put(onPatchResourceData({resourceId, newData: resource.data, fieldsToUpdate: ["is_public"]}));
    
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update resource public flag", message: DB_ERROR });
    }
    yield put(onUpdated());
}


export default function* resourcePublicSaga() {
    yield all([
        takeLatest(TYPES.RESOURCE_PUBLIC_UPDATE, updateFlag),
    ]);
}
