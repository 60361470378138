import { combineReducers } from "redux";
import ImportGlossary from "./ImportGlossary/importGlossaryReducer";
import ImportHomework from "./ImportHomework/importHomeworkReducer";
import ConvertLessonData from "./ConvertLessonData/ConvertLessonDataReducer";
import UpdateProblemTags from "./UpdateProblemTags/UpdateProblemTagsReducer";
import UpdateLessonReviewStatsReducer from "./UpdateLessonReviewStats/UpdateLessonReviewStatsReducer";
import GeneragesDataForiPlannerReducer from "./GeneratesDataForiPlanner/GeneratesDataForiPlannerReducer";
import FixRefs4ProblemsAndProblemSetsReducer from "./FixRefs4ProblemsAndProblemSets/FixRefs4ProblemsAndProblemSetsReducer";
import CreateProblemSetsDataReducer from "./CreateProblemSetsData/CreateProblemSetsDataReducer";
import adminFunctions from "./AdminFunctions/AdminFunctionsReducer";
import FixiPlannerResourcesReducer from "./FixiPlannerResources/FixiPlannerResourcesReducer";

export default combineReducers({
    importGlossary: ImportGlossary,
    importHomework: ImportHomework,
    convertLessonData: ConvertLessonData,
    updateProblemTags: UpdateProblemTags,
    updateLessonReviewStats: UpdateLessonReviewStatsReducer,
    iplannerGenerator: GeneragesDataForiPlannerReducer,
    problemsCorrector: FixRefs4ProblemsAndProblemSetsReducer,
    problemSetDataCreator: CreateProblemSetsDataReducer,
    adminFunctions,
    iPlannerResourcesFixer: FixiPlannerResourcesReducer,
});
