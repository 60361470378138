import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TagsContainer from "../../../Tags/TagsContainer";
import { Message, Icon } from "semantic-ui-react";

export default class KitTags extends PureComponent {
    static propTypes = {
        selectedKit: PropTypes.object,
        canEdit: PropTypes.bool,
        onSaveTags: PropTypes.func,
    };

    render() {
        const { selectedKit, canEdit, onSaveTags } = this.props;
        return (
            <React.Fragment>
                {canEdit && <Message info icon><Icon name="pointing right" />
                    <Message.Content>
                        Here you can edit kit tags.
                        These tags will then be used as filters for content creation and for tagging new content.
                    </Message.Content>
                </Message>}
                <TagsContainer tags={selectedKit.tags || {}} onExternalSave={onSaveTags} canEdit={canEdit} showButton verbose path={`kits_data/${selectedKit.id}/tags`} />
            </React.Fragment>
        );
    }
}
