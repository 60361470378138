import React, { PureComponent } from "react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import PropTypes from "prop-types";
import "./GlossaryList.scss";
import RemoveIcon from "../../../component/RemoveIcon";
import memoize from "memoize-one";
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import cx from "classnames";
import { confirmUnsave } from "../../LessonContent/utils";
import { replaceRelLinks } from "../../../utils/conversionUtils";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

const cache = new CellMeasurerCache({
    defaultHeight: 50,
    fixedWidth: true,
});
export default class GlossaryList extends PureComponent {
    static propTypes = {
        glossary: PropTypes.array,
        glossaryDataCollection: PropTypes.object,
        simple: PropTypes.bool,
        onRemove: PropTypes.func,
        glossaryId: PropTypes.string,
        onSelectTerm: PropTypes.func,
        canEdit: PropTypes.bool,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
    };

    handleTermDetail = ({ currentTarget }) => {
        const { isDirty, onSetDirty, onSelectTerm } = this.props;
        if (!isDirty || confirmUnsave("When you switch to another glossary term", onSetDirty)) {
            onSelectTerm(currentTarget.id);
        }
    };

    rowRenderer = ({
        index, // Index of row
        key, // Unique key within array of rendered rows
        parent, // Reference to the parent List (instance)
        style, // Style object to be applied to row (to position it);
    }) => {
        const term = this.tableData[index];

        return (
            <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                {({ measure }) => (
                    <div className="glossaryList" key={key} style={style} onLoad={measure}>
                        <div className="glossaryListLink" onClick={this.handleTermDetail} id={term.key}>
                            <div className="limitedTextWidth term">{term.name}</div>
                            {(term.content && term.content.html && <div dangerouslySetInnerHTML={{ __html: replaceRelLinks(term.content.html) }} />) || (
                                <div />
                            )}
                        </div>
                        <span>
                            {!term.isUsed && this.props.canEdit && (
                                <RemoveIcon
                                    onRemove={this.props.onRemove}
                                    id={term.key}
                                    entityName={`glossary term ${term.name}`}
                                />
                            )}
                        </span>
                    </div>
                )}
            </CellMeasurer>
        );
    };

    rowRendererSimple = ({
        index, // Index of row
        key, // Unique key within array of rendered rows
        style, // Style object to be applied to row (to position it);
    }) => {
        const term = this.props.glossary[index];
        const disabled = this.props.disabled;

        return (
            <div
                className={cx("limitedTextWidth", "glossaryList", "simple", {
                    active: term.key === this.props.glossaryId,
                })}
                key={key}
                style={style}
                id={term.key}
                {...!disabled && { onClick: this.handleTermDetail }}
            >
                {term.name}
            </div>
        );
    };

    getTableData = memoize((orderedList, glossaryData) => {
        return orderedList.map(term => {
            return { ...term, content: glossaryData && glossaryData[term.key] ? glossaryData[term.key].content : {} };
        });
    });

    findIndexInOrderedList = () => {
        const { glossary, glossaryId } = this.props;
        return glossary.map(term => term.key).indexOf(glossaryId);
    };

    render() {
        const { glossary, simple, glossaryDataCollection, glossaryId } = this.props;
        cache.clearAll();
        this.tableData = this.getTableData(glossary, glossaryDataCollection);

        if (!glossary || 0 == glossary.length) {
            return (
                <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
                    <p>We cannot find the item you are searching for.</p>
                </NoDataPlaceholder>
            );
        }
        if (undefined === glossaryDataCollection) {
            return (
                <Dimmer.Dimmable dimmed={undefined === glossaryDataCollection}>
                    <Dimmer active={undefined === glossaryDataCollection} inverted>
                        <Loader >Loading...</Loader>
                    </Dimmer>
                    <Segment placeholder />
                </Dimmer.Dimmable>
            );
        }
        if (simple) {
            return (
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            height={height}
                            width={width}
                            rowCount={glossary.length}
                            rowRenderer={this.rowRendererSimple}
                            rowHeight={30}
                            {...glossaryId && { scrollToIndex: this.findIndexInOrderedList() }}
                        />
                    )}
                </AutoSizer>
            );
        } else {
            return (
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            height={height}
                            width={width}
                            rowCount={this.tableData.length}
                            rowRenderer={this.rowRenderer}
                            deferredMeasurementCache={cache}
                            rowHeight={cache.rowHeight}
                        />
                    )}
                </AutoSizer>
            );
        }
    }
}
