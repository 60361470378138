import { createSelector } from "reselect";
import { populate, isLoaded } from "react-redux-firebase";

const getLessonId = (state, props) => (
    props.lessonId || props.match && props.match.params  && props.match.params.lessonId
);

const getPublisherRunning = (state) => (
    state && state.kitBuilder && state.kitBuilder.publisher
);

export const getLessonPublisherRunning = createSelector(
    getLessonId,
    getPublisherRunning,
    (lessonId, running) => (lessonId && running && running[lessonId])
);

const PopulateCreatedBy = [
    { child: "created_by", childAlias: "createdBy", root: "user" }, // replace owner with user object
];

export const mapLessonPublishedStatus = (props) => {
    const { outlineId, lessonId } = props;
    if (outlineId && lessonId) {
        const status = {
            path: `outlines_published/${outlineId}/lessons/${lessonId}`,
            populates: PopulateCreatedBy,
        };
        return [status];
    }
    return [];  // used in concat (null would be appended)
};

export const getLessonPublishedStatus = (state, props) => {
    const { firebase } = state;
    const { outlineId, lessonId } = props;
    if (outlineId && lessonId) {
        const dataPath = `outlines_published/${outlineId}/lessons/${lessonId}`;
        if (isLoaded(dataPath)) {
            return populate(firebase, dataPath, PopulateCreatedBy, null);
        }
        return undefined;
    }
    return null;
};
