import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { EditorState, SelectionState, convertFromRaw, convertToRaw, RichUtils, genKey, getDefaultKeyBinding, KeyBindingUtil, DefaultDraftBlockRenderMap } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { registerCopySource } from "draftjs-conductor";
import Immutable from "immutable";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./rtEditor.scss";

import SharedToolbar from "./SharedToolbar";
import createConfig from "./editorConfig";
import pastedTextConvertor, { registerGetContentOnCopy } from "./pastedTextConvertor";
import FormulaDecorator from "./formulaEntity/FormulaDecorator";
import SnippetDecoratorEditor from "./snippetEntity/SnippetDecoratorEditor";
import TableDecorator from "./tableEntity/TableDecorator";
import GlossaryDecorator from "./glossaryEntity/GlossaryDecorator";
import LessonLinkDecorator from "./lessonLinkEntity/LessonLinkDecorator";
import ReviewDecorator from "./reviewEntity/ReviewDecorator";
import InlineImageDecorator from "./imageEntity/InlineImageDecorator";
import BookEditorCustomComponents from "./BookEditorCustomComponents";
import customBlockRender from "./renderer";
import ListItemWrapper from "./listItem/OrderedListItemWrapper";
import { reconstructContentState, getCaretNextTarget, swallowEvent, isAtBeginOfBlock, setCaretToEntity,
    insertEmptyBlock, isSnippet, alignBlock, fixSurroundingEntities, requiresSpaceAfter, fixSplitBlock, toggleMathTextFont,
} from "./utils/utils";
import { isListItem, splitListItemWithData } from "./listItem/listItemUtils";
import exportToHtml from "../export/exportToHtml";
import Message from "./messages/Message";

import { ENTITY_TYPE } from "./constants";
import { MESSAGES } from "./messages/constants";
import { highlightSelection } from "./utils/domUtils";

const reviewFeatures = ["REVIEW", "REVIEW_INSERT_BLOCK", "REVIEW_MERGE_BLOCKS", "REVIEW_INSERT_LI", "REVIEW_REMOVE_LI"];

class BookEditor extends Component {
    static propTypes = {
        initialContentState: PropTypes.object, // DraftJS::RawDraftContentState
        initialHtml: PropTypes.string,
        reviewMode: PropTypes.bool,
        disableAddRemoveListItem: PropTypes.bool,
        /* called every time editor state changes with editorState converted to RawDraftContentState */
        onChange: PropTypes.func, // Handler for storing editor content state (DraftJS::RawDraftContentState)
        onDirty: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onSetEditorRef: PropTypes.func,
        onContentBoundary: PropTypes.func,
        registerGetContentFn: PropTypes.func,
        features: PropTypes.array, // Array of required buttons (features) in editor's toolbar
        sharedToolbar: PropTypes.object,
        noToolbar: PropTypes.bool,
        focusOnMount: PropTypes.bool,
        contextHelp: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        rootEntityKey: PropTypes.string,
    };

    static defaultProps = {
        focusOnMount: true,
    };

    constructor(props) {
        super(props);
        const { initialContentState, initialHtml, reviewMode, sharedToolbar, registerGetContentFn, features } = this.props;
        const contentState = reconstructContentState(initialContentState);
        const content = contentState && convertFromRaw(contentState);
        const editorState = content ? this.fixInitialEditorState(EditorState.createWithContent(content)) : EditorState.createEmpty();

        this.state = {
            editorState,
            readOnly: false,
            activeCustomComponent: null,
            isDirty: false,
            isLocked: false,  // When editor is locked, it is not focused on MouseDown and its setReadOnly method is not handled.
            message: null,
        };

        /**
         * These properties are used when `getContent` is called:
         *   1. without any changes in the editor content (isDirty === false)
         *   2. more than once after any change. First `getContent` call resets isDirty flag to avoid
         *      redundant `exportToHtml` call, but any next call has to return the updated value.
         *
         * `currentContentState` is set to `reconstructed` initialContentState to avoid return undefined value
         * (Firebase doesn't like undefined).
         * `currentHtml` is set to an empty string from the same reason (for empty initialContentState).
         */
        this.currentContentState = contentState;
        this.currentHtml = (initialHtml === undefined && !initialContentState) ? "" : initialHtml;

        this.editorKey = genKey();
        this.toolbarConfig = createConfig(reviewMode ? reviewFeatures : features, {
            showCustomComponent: this.showCustomComponent,
            getEditorState: this.getEditorState,
            setEditorState: this.setEditorState,
        });

        // When sharedToolbar is not provided, this editor creates its own including a special DIV for it.
        // Nested editors will use this toolbar as a shared.
        if (!sharedToolbar) {
            this.sharedToolbar = new SharedToolbar();
            this.toolbarContainer = React.createRef();
        } else {
            this.sharedToolbar = sharedToolbar;
        }

        this.entityEditors = {};
        this.editorKeyToEntityKeyMap = {};

        registerGetContentFn && registerGetContentFn(this.getContent);
    }

    /**
     * If the first block contains an entity only, there couldn't be any space for placing caret
     * when the editor gets focus for the first time. Make it.
     * Note: if you have opened DevTools, caret can be stollen by them (each browser behaves slightly differently).
     */
    fixInitialEditorState = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const entityKey = firstBlock.getEntityAt(0);

        if (entityKey && requiresSpaceAfter(contentState.getEntity(entityKey).getType())) {
            editorState = setCaretToEntity(editorState, firstBlock.getKey(), entityKey);
        }

        return editorState;
    };

    getContent = () => {

        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV !== "production" && undefined === this.currentHtml) {
            // eslint-disable-next-line no-console
            console.log("%c[Performance warning BookEditor:getContent()]", "color: orange",
                "\"initialHtml\" property of BookEditor is undefined. It causes calling exportToHtml()",
                "even there were no changes in the editor content, because wa always want to return { raw, html }",
                "object with defined values."
            );
        }

        if (!this.state.isDirty) {

            if (undefined === this.currentHtml) {
                this.currentHtml = exportToHtml(this.currentContentState);
            }
            return {
                raw: this.currentContentState,
                html: this.currentHtml,
            };
        }

        this.setState({ isDirty: false });

        for (const nestedEntityEditor of Object.values(this.entityEditors)) {
            nestedEntityEditor.updateEditorState();
        }

        // TODO pbenes: verify that this.state.editorState is really fresh!!!
        // Because `getEditorContent` is not async more, the former comment is not valid (and related behavior was properly tested yet).
        // Former comment:
        //      Getting this.state.editorState should be save here - if it was modified by any nestedEditor,
        //      it was made ba async call so such change should be already propagated into state.
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const raw = convertToRaw(contentState);
        const html = exportToHtml(raw);
        this.currentContentState = raw;
        this.currentHtml = html;
        this.setEditorState(editorState);

        return {
            raw,
            html,
        };
    };

    componentWillUnmount() {
        // `copySource` property (see "draftjs-conductor") is based on `editorRef` which is not accessible
        // when componentDidMount is fired. It is created in onSetEditorRef().
        // Therefore it could seem asymmetrical...
        if (this.copySource) {
            this.copySource.unregister();
        }

        if (this.getContentOnCopy) {
            this.getContentOnCopy.unregister();
        }

        if (this.sharedToolbar.isMounted) {
            // sharedToolbar is not mounted when the editor is unmounted before its onSetEditorRef is called.
            this.sharedToolbar.remove(this.editorKey);
        }

        if (this.toolbarContainer) {
            this.sharedToolbar.unmount();
        }
    }

    registerNestedEditor = (entityEditor) => {
        const { entityKey, ...nestedEditor } = entityEditor;
        this.entityEditors[entityKey] = nestedEditor;
        this.editorKeyToEntityKeyMap[nestedEditor.editorKey] = entityKey;

        if (this.state.focusNestedEditorOnRegistration) {
            const nestedBookEditor = nestedEditor.bookEditorRef;
            nestedBookEditor.setFocus();
            this.setState({ focusNestedEditorOnRegistration: false });
        }

        return () => {  // unregister handler
            if (!this.entityEditors[entityKey]) {
                return;
            }
            const { editorKey } = this.entityEditors[entityKey];
            delete this.entityEditors[entityKey];
            delete this.editorKeyToEntityKeyMap[editorKey];
        };
    };

    getToolbarElement = (editorElement) => {
        const editorContainer = editorElement.parentNode.parentNode.parentNode.parentNode;
        const elements = editorContainer.getElementsByClassName("rdw-editor-toolbar");
        return elements && elements[0];
    };

    onSetEditorRef = (ref) => {
        const { onSetEditorRef, focusOnMount } = this.props;
        this.editorRef = ref;

        if (ref) {
            // TODO pbenes: get rid of registerGetContentOnCopy if possible
            this.getContentOnCopy = registerGetContentOnCopy(this.editorRef, this.getContent);
            this.copySource = registerCopySource(this.editorRef);
            const toolbarElement = this.getToolbarElement(ref.editor);

            if (this.toolbarContainer) {
                this.sharedToolbar.mount(this.toolbarContainer.current);
            }

            this.sharedToolbar.add(this.editorKey, this, toolbarElement, !focusOnMount);

            if (focusOnMount) {
                this.setFocus();
            }

            onSetEditorRef && onSetEditorRef(this);
        }
    };

    getEditorState = () => this.state.editorState;

    /**
     * It is used by nested editors for updating this editor state.
     * Firing `onChange` with converted contentState (convertToRaw) shouldn't cause extra overhead.
     * Without firing `onChange` some changes (e.g. inserting Formula) were not propagated immediately
     * to the parent component and it was depended on blur event (e.g. click on Save button) - it was fragile.
     */
    setEditorState = (editorState) => {
        const { onChange } = this.props;

        if (onChange) {
            const contentState = editorState.getCurrentContent();
            onChange(convertToRaw(contentState));
        }

        this.handleEditorStateChange(editorState);
    };

    handleEditorStateChange = (editorState) => {

        if (this.state.editorState.getCurrentContent() !== editorState.getCurrentContent()) {
            this.handleDirty();
            editorState = fixSurroundingEntities(editorState);
        }

        this.setState({ editorState });
    };

    handleDirty = () => {
        const { onDirty } = this.props;
        onDirty && onDirty();
        this.setState({ isDirty: true });
    };

    updateStateAfterPaste = (editorState) => {
        // If the editor is closed immediately after`Paste` (no other user interaction e.g. click, writing a text, ...),
        // a new content would not be propagated to the editor's owner.
        this.setEditorState(editorState);
    };

    findMathFormula = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.MATH;
        }, callback);
    };

    findSnippet = (contentBlock, callback, contentState) => {
        contentBlock.type === "atomic" &&
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity();
                return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.SNIPPET;
            }, callback);
    };

    findTable = (contentBlock, callback, contentState) => {
        contentBlock.type === "atomic" &&
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity();
                return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.TABLE;
            }, callback);
    };

    findGlossaryTerm = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.GLOSSARY;
        }, callback);
    };

    findLessonLink = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.LESSON_LINK;
        }, callback);
    };

    findReview = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.REVIEW;
        }, callback);
    };

    findInlineImage = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE.INLINE_IMAGE;
        }, callback);
    };

    createPersistentSelection = () => {
        if (!this.state.editorState.getSelection().isCollapsed()) {
            // ".DraftEditor-root" is positioned relatively by DraftJS
            const editorEl = document.querySelector(`.editorKey-${this.editorKey} .DraftEditor-root`);
            this._persistentSelection = highlightSelection(editorEl);
        }
    };

    removePersistentSelection = () => {
        if (this._persistentSelection) {
            this._persistentSelection.forEach((dummySelection) => dummySelection.parentNode.removeChild(dummySelection));
            delete this._persistentSelection;
        }
    };

    setReadOnly = (readOnly) => {
        if (!this.state.isLocked && readOnly !== this.state.readOnly) {

            if (readOnly) {
                this.createPersistentSelection();
            }
            else {
                this.removePersistentSelection();
            }

            this.setState({ readOnly }, this.setReadOnlyCallback);
        }
    };

    setReadOnlyCallback = () => {
        const { readOnly, editorState, lastEditorState } = this.state;

        if (readOnly) {
            this.setState({ lastEditorState: editorState });
            /**
             * We usually call `setReadOnly(true)` when another editor (nested/parent) is getting focus.
             * Draft handles `blur` event for removing DOM selection from the editor which is loosing focus
             * (see https://github.com/facebook/draft-js/blob/585af35c3a8c31fefb64bc884d4001faa96544d3/src/component/handlers/edit/editOnBlur.js).
             * But in our architecture of nested editors the blur event is fired only when another element
             * outside the root editor is focused, not inside the root editor.
             * So we have to do here similar job like `DraftJS::editOnBlur()` handler.
             * Without it `DraftJS::getUpdatedSelectionState()` works with an inconsistent selection
             * and throws error `Cannot read property 'getIn' of undefined` (bug #20584).
             */
            const currentSelection = editorState.getSelection();
            if (currentSelection.getHasFocus()) {
                const selection = currentSelection.set("hasFocus", false);
                this.setEditorState(EditorState.acceptSelection(editorState, selection));
            }
        }
        else {
            /**
             * When editor is focused (or caret is moved, ...), DraftJS internally checks browser's selection and compare it
             * with editor's SelectionState
             * (see e.g. https://github.com/facebook/draft-js/blob/0.10-stable/src/component/selection/getDraftEditorSelectionWithNodes.js).
             * But when the editor was switched into readOnly mode (and it is driven by property => re-rendered), the browser's selection
             * was destroyed.
             *
             * When readOnly state is changed back TRUE -> FALSE, we have to restore browser's selection. It is done by forceSelection
             * (https://draftjs.org/docs/api-reference-editor-state#forceselection).
             */
            if (lastEditorState === editorState) {
                let selection = editorState.getSelection();
                // console.log("%cRestore selection", "color:orange", selection.serialize());
                const newEditorState = EditorState.forceSelection(editorState, selection);
                this.setEditorState(newEditorState);
            }
        }
    };

    isReadOnly = () => this.state.readOnly;

    enable = () => {
        this.isDisabled = false;
        this.setFocus();
    };

    disable = () => {
        this.isDisabled = true;
        this.setReadOnly(true);
    };

    lock = () => {
        this.setReadOnly(true);
        this.setState({ isLocked: true });
    };

    unlock = () => {
        this.setState({ isLocked: false });
    };

    keyBindingFn = (e) => {
        if (KeyBindingUtil.hasCommandModifier(e)) { // [CTRL] / [CMD]
            switch (e.keyCode) {
                case 71: return "handle-glossary";  // G
                case 77: return "insert-formula";   // M
            }
        }
        if (e.altKey) { // [ALT] / [Option]
            switch (e.keyCode) {
                case 67: return "handle-center-block"; // C
                case 83: return "handle-snippet";     // S
                case 84: return "handle-table";       // T
                case 76: return "handle-lesson-link"; // L
                case 77: return "handle-math-font";   // M
            }
        }

        return getDefaultKeyBinding(e);
    };

    keyBindingFnReviewMode = (e) => {
        if (e.keyCode === 82 && KeyBindingUtil.isOptionKeyCommand(e)) {
            return "handle-review";   // [ALT]+R
        }

        if (e.keyCode && !KeyBindingUtil.hasCommandModifier(e)) {
            // All ordinary keys are disabled in review mode
            return "handled";
        }

        return getDefaultKeyBinding(e);
    }

    onKeyCommand = (command) => {
        const { editorState } = this.state;
        const { disableAddRemoveListItem } = this.props;
        let newState;

        if (disableAddRemoveListItem) {
            if (command === "split-block") {
                this.setState({ message: MESSAGES.ADD_LIST_ITEM });
                return true;
            }
            if (command === "backspace" && isAtBeginOfBlock(editorState)) {
                this.setState({ message: MESSAGES.REMOVE_LIST_ITEM });
                return true;
            }
        }

        switch (command) {
            case "insert-formula":
                this.showCustomComponent(ENTITY_TYPE.MATH);
                return true;

            case "handle-glossary":
                this.showCustomComponent(ENTITY_TYPE.GLOSSARY);
                return true;

            case "handle-table":
                this.showCustomComponent(ENTITY_TYPE.TABLE);
                return true;

            case "handle-snippet":
                this.showCustomComponent(ENTITY_TYPE.SNIPPET);
                return true;

            case "handle-review":
                this.showCustomComponent(ENTITY_TYPE.REVIEW);
                return true;

            case "handle-lesson-link":
                this.showCustomComponent(ENTITY_TYPE.LESSON_LINK);
                return true;

            case "handle-center-block":
                this.handleCenterBlock(editorState);
                return true;

            case "handle-math-font":
                this.toggleMathFont(editorState);
                return true;

            case "split-block":
                // This is currently redundant because of `react-draft-wysiwyg` patch `Modifier.setBlockData` => `Modifier.mergeBlockData`,
                // but it is better to know about potential necessity to handle it.
                if (isListItem(editorState)) {
                    newState = splitListItemWithData(editorState);
                }
                else {
                    newState = fixSplitBlock(editorState);  // Firefox fix

                    if (!newState) {  // the same as default
                        newState = RichUtils.handleKeyCommand(editorState, command);
                    }
                }
                break;

            default: newState = RichUtils.handleKeyCommand(editorState, command);
        }

        if (newState) {
            this.setEditorState(newState);
            return true;
        }
        return false;
    };

    isCustomComponentEnabled = (componentType) => {
        const { features, reviewMode } = this.props;

        if (features && features.length) {
            const entries = Object.entries(ENTITY_TYPE);
            const [ feature ] = entries.find(([ , value]) => value === componentType);
            return reviewMode ? feature === "REVIEW" : features.includes(feature);
        }
        return true;  // features are not specified => all features are allowed
    };

    handleCenterBlock = (editorState) => {
        this.setEditorState(alignBlock(editorState, "center"));
    };

    toggleMathFont = (editorState) => {
        this.setEditorState(toggleMathTextFont(editorState));
    };

    showCustomComponent = (componentType, contextNode, entityKey) => {

        if (this.isCustomComponentEnabled(componentType)) {
            const newState = {
                activeCustomComponent: componentType,
                activeCustomComponentContext: contextNode || this.editorRef.editor,
                activeEntityKey: entityKey,
            };

            if (this.state.activeCustomComponent) {
                this.setState({ activeCustomComponent: null }, () => this.setState(newState));
            }
            else {
                this.setState(newState);
            }
        }
    };

    handleCloseCustomComponent = (focusNestedEditorOnRegistration = false) => {
        this.setState({
            activeCustomComponent: null,
            activeCustomComponentContext: null,
            activeEntityKey: null,
            focusNestedEditorOnRegistration: focusNestedEditorOnRegistration,
        });
    };

    getDecoratorAdditionalProps = () => ({
        getEditorState: this.getEditorState,
        setEditorState: this.setEditorState,
        enableParentEditor: this.enable,
        disableParentEditor: this.disable,
        isParentReadOnly: this.isReadOnly,
        registerNestedEditor: this.registerNestedEditor,
        onContentBoundary: this.handleContentBoundary,
        onDirty: this.handleDirty,
        rootEntityKey: this.props.rootEntityKey,
        reviewMode: !!this.props.reviewMode,
        ...(this.sharedToolbar ? { sharedToolbar: this.sharedToolbar } : {}),
    });

    getCustomDecorators = () => {
        const additionalProps = this.getDecoratorAdditionalProps();

        return [{
            strategy: this.findMathFormula,
            component: FormulaDecorator,
            props: additionalProps,
        }, {
            strategy: this.findSnippet,
            component: SnippetDecoratorEditor,
            props: additionalProps,
        }, {
            strategy: this.findTable,
            component: TableDecorator,
            props: additionalProps,
        }, {
            strategy: this.findGlossaryTerm,
            component: GlossaryDecorator,
            props: additionalProps,
        }, {
            strategy: this.findReview,
            component: ReviewDecorator,
            props: { ...additionalProps, showCustomComponent: this.showCustomComponent },
        }, {
            strategy: this.findLessonLink,
            component: LessonLinkDecorator,
            props: additionalProps,
        }, {
            strategy: this.findInlineImage,
            component: InlineImageDecorator,
            props: additionalProps,
        }];
    };

    handlePastedTextFactory = () => {
        if (this.props.reviewMode) {
            // suppress C&P in reviewMode
            return () => true;
        }

        // pastedTextConvertor is async, but handlePastedText editor props require a sync function to return "true"
        // or "handled" to indicate that we handle copy&paste ourselves and default DraftJS HTML->Draft conversion should be skipped.
        // We call pastedTextConvertor and let it do the conversion asynchronously. We intentionally don't wait for a promise to resolve.
        // It will update the EditorState at the end eventually via callback (this.updateStateAfterPaste)
        return (text, html, editorState) => {
            pastedTextConvertor(this.updateStateAfterPaste)(text, html, editorState);
            return true;
        };
    };

    handleDrop = () => {
        return this.props.reviewMode;
    };

    focusDraftEditor = () => {
        if (!this.isReadOnly()) {
            this.editorRef.focus();
        }
    };

    setFocus = (e) => {
        if (this.isDisabled) {
            return;
        }

        if (this.isReadOnly()) {
            this.setReadOnly(false);
        }

        this.sharedToolbar.setActive(this.editorKey);

        // This method is primarily bind to onMouseDown event (which focus the DraftJS editor by default).
        // But it can be called from nested editor without event argument which means that
        // we have to focus the DraftJS editor manually.
        if (e) {
            e.stopPropagation();
        }
        else {
            setTimeout(this.focusDraftEditor, 0);
        }

        this.props.onFocus && this.props.onFocus();
    };

    handleArrowKeys = (e, direction) => {
        const { onContentBoundary } = this.props;
        const { entityKey, boundaryType } = getCaretNextTarget(direction, this.getEditorState());

        if (boundaryType) {
            onContentBoundary && onContentBoundary(boundaryType);
        }

        if (entityKey) {
            // If the next caret position is related to an entity, set the entity (nested) editor as active.
            this.entityEditors[entityKey].bookEditorRef.setFocus();
        }

        if (boundaryType || entityKey) {
            swallowEvent(e);
        }
    };

    handleContentBoundary = (boundaryType) => {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const activeEditor = this.sharedToolbar.getActive();
        const currentEntityKey = this.editorKeyToEntityKeyMap[activeEditor.bookEditor.editorKey];
        const blockMap = contentState.getBlockMap();
        const blockWithSnippet = blockMap.filter((block) => block.getEntityAt(0) === currentEntityKey);
        const blockKey = blockWithSnippet.keys().next().value;
        const goNext = boundaryType === "LEAVE_ENTITY_RIGHT";
        const targetBlock = goNext ? contentState.getBlockAfter(blockKey) : contentState.getBlockBefore(blockKey);

        if (!targetBlock || isSnippet(editorState, targetBlock)) {
            // There is no other focusable block where to go.
            // Draft JS expects that any atomic block is surrounded by editable blocks
            // (see https://github.com/facebook/draft-js/issues/327).
            // A new empty block in given direction (goNext) will be created.
            this.handleEditorStateChange(insertEmptyBlock(editorState, blockWithSnippet.get(blockKey), goNext));
            this.setFocus();
            return;
        }

        const offset = goNext ? 0 : targetBlock.getLength();

        const selection = new SelectionState({
            anchorKey: targetBlock.getKey(),
            anchorOffset: offset,
            focusKey: targetBlock.getKey(),
            focusOffset: offset,
            /**
             * In case of simple caret movement the selection is always forward.
             * Originally I set `isBackward: !goNext`, but it ended up with `getIn` error when:
             *    - snippet was left by LEFT/UP arrow key
             *    - caret is in empty block above the snippet
             *    - "DELETE" key is pressed
             * It removes the mandatory empty block above the snippet.
             */
            isBackward: false,
        });

        this.handleEditorStateChange(EditorState.forceSelection(editorState, selection));
        this.setFocus();
    };

    arrowKeyHandlers = {
        onLeftArrow: (e) => this.handleArrowKeys(e, "left"),
        onRightArrow: (e) => this.handleArrowKeys(e, "right"),
        onDownArrow: (e) => this.handleArrowKeys(e, "down"),
        onUpArrow: (e) => this.handleArrowKeys(e, "up"),
    }

    handleMouseDown = (e) => {
        const { classList } = e.target;

        if (classList.contains("modals") && classList.contains("dimmer")) {
            swallowEvent(e);
            return;
        }

        if (this.state.isLocked) {
            return;
        }

        if (e.detail === 1) {
            this.setFocus(e);
        }
        e.stopPropagation();
    };

    handleDismissMessage = () => {
        this.setState({ message: null });
    };

    // own custom block renderer enhanced with setReadOnly and setFocus
    // because some custom blocks might need input control or
    // focus control and react-wysiwyg-draft won't pass it. Can be solved easier
    // if we ever decide to get rid of react-wysiwyg-draft
    _customBlockRenderer = () => customBlockRender(this.setReadOnly, this.setFocus, this.handleDirty, this.getEditorState, this.setEditorState);

    /**
     * We handle both Ordered and Unordered list in the same way, so there is no difference between them.
     * They use the same wrapper which allows to combine UL and OL at different depth.
     */
    blockRenderMap = Immutable.Map({
        "ordered-list-item": {
            element: "li",
            wrapper: <ListItemWrapper
                getEditorState={this.getEditorState}
                setEditorState={this.setEditorState}
                showCustomComponent={this.showCustomComponent} // eslint-disable-line react/jsx-handler-names
                initiallyOrdered={true}
                reviewMode={!!this.props.reviewMode}
            />
        },

        "unordered-list-item": {
            element: "li",
            wrapper: <ListItemWrapper
                getEditorState={this.getEditorState}
                setEditorState={this.setEditorState}
                showCustomComponent={this.showCustomComponent} // eslint-disable-line react/jsx-handler-names
                initiallyOrdered={false}
                reviewMode={!!this.props.reviewMode}
            />
        },

        /**
         * By supplying our own `blockRenderMap` property we override JPuri's blockRenderMap
         * (see https://github.com/jpuri/draftjs-utils/blob/eca1508cf74c8b05ce7d3ab39ab28247cb254d5f/js/block.js#L251).
         * So we re-implement it...
         */
        code: {
            element: "pre"
        },
    });

    extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(this.blockRenderMap);

    render() {
        const { onFocus, onBlur, onChange, sharedToolbar, noToolbar, reviewMode, contextHelp, rootEntityKey } = this.props;
        const { editorState, readOnly, activeCustomComponent, activeCustomComponentContext, activeEntityKey, isDirty, message } = this.state;
        const internalToolbar = !sharedToolbar && !noToolbar;

        return (
            <div onMouseDown={this.handleMouseDown}>
                {this.toolbarContainer && <div ref={this.toolbarContainer} className={cx("sharedToolbar", { noToolbar })}>
                    TOOLBAR PLACEHOLDER
                </div>}
                {activeCustomComponent && <BookEditorCustomComponents
                    componentType={activeCustomComponent}
                    contextNode={activeCustomComponentContext}
                    editorState={editorState}
                    setEditorState={this.setEditorState}
                    enableParentEditor={this.enable}
                    disableParentEditor={this.disable}
                    isParentReadOnly={this.isReadOnly}
                    entityKey={activeEntityKey}
                    onClose={this.handleCloseCustomComponent}
                    onDirty={this.handleDirty}
                    sharedToolbar={this.sharedToolbar}
                    reviewMode={!!reviewMode}
                    contextHelp={contextHelp}
                    rootEntityKey={rootEntityKey}
                />}
                {message && <Message message={message} onDismiss={this.handleDismissMessage} />}
                <Editor
                    editorKey={this.editorKey}
                    toolbar={this.toolbarConfig.toolbar}
                    toolbarCustomButtons={this.toolbarConfig.customButtons}
                    // `readOnly` className used for debugging (showDebugUtils)
                    wrapperClassName={cx("rtEditor", { internalToolbar, reviewMode, readOnly })}
                    editorClassName={`editorContent editorKey-${this.editorKey}`}
                    toolbarClassName={cx("editorToolbar", { isDirty, noToolbar })}
                    editorRef={this.onSetEditorRef}
                    readOnly={readOnly}
                    editorState={editorState}
                    // onEditorStateChange is called every time state changes (content or selection)
                    // with original editorState as argument
                    onEditorStateChange={this.handleEditorStateChange}
                    // onContentStateChange is called every time state changes (content or selection)
                    // with editorState converted to RawDraftContentState
                    onContentStateChange={onChange}  // necessary for TableDecoratorEditor
                    onFocus={onFocus}
                    onBlur={onBlur}
                    handlePastedText={this.handlePastedTextFactory()}
                    handleDrop={this.onDrop}
                    keyBindingFn={reviewMode ? this.keyBindingFnReviewMode : this.keyBindingFn}
                    handleKeyCommand={this.onKeyCommand}
                    customDecorators={this.getCustomDecorators()}
                    customBlockRenderFunc={this._customBlockRenderer()}
                    blockRenderMap={this.extendedBlockRenderMap}
                    {...this.arrowKeyHandlers}
                    rootEntityKey={rootEntityKey}
                />
            </div>
        );
    }
}

export default BookEditor;
