import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onGenerateProblemSetData } from "./CreateProblemSetsDataActions";
import { getProgress } from "./CreateProblemSetsDataSelectors";
import CreateProblemSetsData from "./CreateProblemSetsData";

const mapStateToProps = function(state) {
    return {
        progress: getProgress(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onGenerateProblemSetData }, dispatch);
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(CreateProblemSetsData);
