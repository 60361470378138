import { all, select, takeEvery, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { authUser } from "../../auth/authSelectors";
import { toastError, DB_ERROR, FUNCTION_ERROR } from "../../component/toast";
import { TYPES, onOpenReply } from "./commentsActions";

const CommentOps = {
    comments: {
        created: "New comment",
        updated: "Comment edited",
        replyCreated: "Reply to comment",
        replyUpdated: "Comment reply edited",
        resolved: "Comment resolved",
    },
    user_comments: {
        created: "New user comment",
        updated: "User comment edited",
        replyCreated: "Reply to user comment",
        replyUpdated: "User comment reply edited",
        resolved: "User comment resolved",
    }
};

const getCommentOp = (threadType, actionDone) => {
    const ops = threadType && CommentOps[threadType] || CommentOps.comments;
    return (ops && ops[actionDone]) || (
        threadType === "user_comments" ? "User Comment" : "Comment"
    );
};

const getCommentPath = (threadId, commentId) => (
    commentId ? `/comments/${threadId}/${commentId}` : `/comments/${threadId}`
);

const exceptionToError = ({ code, message }, header) => {
    const text = message || "Unknown error.";
    return {
        code: code ? `(${code}) ${text}` : text,
        header: header || "Failed",
        message: DB_ERROR,
    } ;
};

/**
 * Notification is sent asynchronously to be non-blocking for UI.
 * 
 * Notice: Don't not yield on this to allow saga continue or use Saga API fork() 
 * to treat this call as non-blocking for better user experience. Call and forget.
 *
 * @param {string} sender ID of user sending the comment (ignored by CFn)
 * @param {string[]} recipients list of user (IDs) to receive the mail
 * @param {object} context notification context (lesson & outline)
 * @param {string} commentText message of the comment
 * @param {string} actionDone caption of the comment
 * @param {string} threadType identification of the
 */
async function notifyOnComment(sender, recipients, context, commentText, actionDone, threadType) {
    const filteredRecipients = recipients
        .filter(r => r !== sender) // don't send email to sender
        .filter((r, i, a) => a.indexOf(r) === i); // unique only
    if (filteredRecipients.length) {
        const comment = {
            sender,
            recipients: filteredRecipients,
            commentText,
            commentOp: getCommentOp(threadType, actionDone),
            threadType,
        };
        try {
            // See TypeScript code of the CFn for more details about its arguments
            const notifyOnLessonComment = firebase.getFirebaseFunctions().httpsCallable("comments-notifyOnLessonComment");
            if (notifyOnLessonComment) {
                await notifyOnLessonComment({ comment, context });
            }
        } catch (ex) {
            const { code, message = "Unknown error." } = ex;
            toastError({
                code: code ? `(${code}) ${message}` : message,
                header: "Failed to send notification",
                message: FUNCTION_ERROR,
            });
        }
    }
}

function* appendCommentAuthors(threadId, commentId, subscribed) {
    const commentRef = firebase.getFirebaseData(getCommentPath(threadId));
    const comment = (yield commentRef.child(commentId).once("value")).val();
    const recipients = {
        [comment.author]: true
    };
    if (comment.replies) {
        for (const replyId of Object.keys(comment.replies)) {
            const reply = (yield commentRef.child(replyId).once("value")).val();
            recipients[reply.author] = true;
        }
    }
    const authors = Object.keys(recipients);
    return Array.isArray(subscribed) ? subscribed.concat(authors) : authors;
}

function* addComment({ payload: { threadId, threadType, comment, replyTo, notification, } }) {
    const user = yield select(authUser);
    try {
        const threadPath = getCommentPath(threadId);
        const commentId = (yield firebase.getFirebaseData(threadPath).push()).key;
        const changes = {
            [commentId]: {
                author: user.uid,
                comment: comment,
                timestamp: new Date().toISOString(),
                isReply: replyTo != null,
            }
        };
        if (replyTo) {
            changes[`${replyTo}/replies/${commentId}`] = true;
        }
        yield firebase.getFirebaseData(threadPath).update(changes);
        if (notification) {
            const { context, recipients } = notification;
            if (replyTo) {
                const moreRecipients = yield appendCommentAuthors(threadId, replyTo, recipients);
                notifyOnComment(user.uid, moreRecipients, context, comment, "replyCreated", threadType);
            } else {
                notifyOnComment(user.uid, recipients, context, comment, "created", threadType);
            }
        }
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to add a comment"));
    }
    yield put(onOpenReply(null));
}

function* editComment({ payload: { threadId, threadType, commentId, comment, notification, parentId} }) {
    try {
        const user = yield select(authUser);
        yield firebase.getFirebaseData(getCommentPath(threadId, commentId)).update({
            comment: comment.comment,
            modified: new Date().toISOString(),
        });
        if (notification) {
            const { context, recipients } = notification;
            const moreRecipients = yield appendCommentAuthors(threadId, parentId || commentId, recipients);
            const commentOp = parentId ? "replyUpdated" : "updated";
            notifyOnComment(user.uid, moreRecipients, context, comment.comment, commentOp, threadType);
        }
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to add a comment"));
    }
    yield put(onOpenReply(null));
}

function* markResolved({ payload: { threadId, threadType, commentId, notification, status} }) {
    try {
        const user = yield select(authUser);
        yield firebase.getFirebaseData(getCommentPath(threadId, commentId)).update({
            resolved: status,
        });
        if (notification) {
            const { context, recipients } = notification;
            const moreRecipients = yield appendCommentAuthors(threadId, commentId, recipients);
            notifyOnComment(user.uid, moreRecipients, context, "", "resolved", threadType);
        }
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to mark comment as resolved"));
    }
}

function* removeComment({ payload: { threadId, commentId, parentCommentId, comment } }) {
    try {
        const itemRef = firebase.getFirebaseData(getCommentPath(threadId));
        const changes = { [commentId]: null }; // Delete comment
        if (parentCommentId) {
            // ...and then delete reference in replies of parent
            changes[`${parentCommentId}/replies/${commentId}`] = null;
        } else if (comment.replies) {
            // ...or delete all referenced replies
            for (const replyId in comment.replies) {
                changes[replyId] = null;
            }
        }
        yield itemRef.update(changes);
    } catch (ex) {
        toastError(exceptionToError(ex, "Failed to remove comment"));
    }
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.COMMENT_ADD, addComment),
        takeEvery(TYPES.COMMENT_EDIT, editComment),
        takeEvery(TYPES.COMMENT_MARK_RESOLVED, markResolved),
        takeEvery(TYPES.COMMENT_REMOVE, removeComment),
    ]);
}
