import { connect } from "react-redux";
import SiPiCloner from "./SiPiCloner";
import { firebaseConnect } from "react-redux-firebase";
import { selectedTemplateId, getSipiTemplatesAsOptions, getTemplateSiPiAsCollection } from "./SiPiClonerSelectors";
import { bindActionCreators, compose } from "redux";
import { onSelectTemplate } from "./SiPiClonerActions";
import { onLoadIplannerResources } from "../../SiPiTemplate/SiPiTemplateActions";
import { getTemplateSiPi, getTemplate } from "../../SiPiTemplate/SiPiTemplateSelectors";

const mapStateToProps = (state, props) => ({
    selectedTemplateId: selectedTemplateId(state),
    templatesAsOptions: getSipiTemplatesAsOptions(state),
    templateSiPi: getTemplateSiPi(state, props),
    template: getTemplate(state, props),
    templateSiPiCollection: getTemplateSiPiAsCollection(state),
});

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onSelectTemplate,
            onLoadIplannerResources,
        },
        dispatch
    );
};

export default compose(
    connect((state) => ({
        selectedTemplateId: selectedTemplateId(state),
    })),
    firebaseConnect((props) => [
        "/sipi_templates/info",
        `/sipi_templates/data/${props.selectedTemplateId}`,
        `/sipi_templates/lesson_units/${props.selectedTemplateId}`,
        "/modules/list",
        "tenant_modules",
    ]),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(SiPiCloner);