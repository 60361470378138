import { createSelector } from "reselect";
import { getWidgetsInfo } from "../WidgetLibrarySelectors";
import { getUsers, getEditorTimestamp, getEditorId, getFullName, getCreatedTimestamp, getAuthorId } from "../../../Users/UserList/UserListSelectors";

const getWidgetId = (state, props) => props.itemId;

export const editorId = createSelector(
    getWidgetsInfo,
    getWidgetId, 
    getEditorId
);

export const editor = createSelector(
    getUsers,
    editorId,
    getFullName
);

export const editorTimestamp = createSelector(
    getWidgetsInfo,
    getWidgetId, 
    getEditorTimestamp
);

export const authorId = createSelector(
    getWidgetsInfo,
    getWidgetId, 
    getAuthorId
);

export const author = createSelector(
    getUsers,
    authorId,
    getFullName
);

export const authorTimestamp = createSelector(
    getWidgetsInfo,
    getWidgetId, 
    getCreatedTimestamp
);
