import { EditorState, AtomicBlockUtils, Modifier, SelectionState, genKey, ContentBlock } from "draft-js";
import { getSelectedBlock, getSelectionCustomInlineStyle, getSelectionText, toggleCustomInlineStyle } from "draftjs-utils";
import { ENTITY_TYPE, CHARACTER_TYPE, IMAGE_PLACEHOLDER_CHAR } from "../constants";
import Immutable from "immutable";
import uuid from "uuid/v1";
import { isFirefox } from "react-device-detect";
import { toastError } from "../../../toast";

const requiresSpaceAfter = (entityType) => entityType === ENTITY_TYPE.REVIEW;

const insertSegment = (editorState, type, data) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(type, "IMMUTABLE", data);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let newEditorState = EditorState.set(
        editorState,
        { currentContent: contentStateWithEntity },
    );
    newEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    return newEditorState;
};

const alignBlock = (editorState, textAlign) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const block = getSelectedBlock(editorState);
    const updatedBlock = block.merge({
        data: block.getData().merge({ ["text-align"]: textAlign }),
    });

    const newContentState = contentState.merge({
        blockMap: blockMap.set(block.getKey(), updatedBlock),
    });

    return editorState = EditorState.push(editorState, newContentState, "change-block-data");
};

const reconstructContentState = (contentState) => {
    if (!contentState) {
        return null;
    }

    if (!contentState.entityMap) {
        contentState.entityMap = {};
    }

    contentState.blocks.forEach((block) => {
        if (!block.inlineStyleRanges) {
            block.inlineStyleRanges = [];
        }
        if (!block.entityRanges) {
            block.entityRanges = [];
        }

        // For backward compatibility.
        // Originally was used ordinary space, later Zero Width Space character (\u200B) as a character in ContentBlock text,
        // which was mapped via entityMap to the related entity. It was not rendered by decorators.
        // Now we need `EM SPACE` character for rendering selectable formulas/images (and proper caret jumping).
        if (block.entityRanges.length) {
            const chars = block.text.split("");
            block.entityRanges.forEach(({ key, offset }) => {
                const entity = contentState.entityMap[key];
                if (entity.type === ENTITY_TYPE.MATH) {
                    chars[offset] = IMAGE_PLACEHOLDER_CHAR;
                }
            });
            block.text = chars.join("");
        }
    });

    return contentState;
};

const getInitialEntityList = () => {
    return {
        [ENTITY_TYPE.GLOSSARY]: new Map(),    // list of glossary term keys
        [ENTITY_TYPE.SNIPPET]: new Map(),     // list of snippetKeys
        [ENTITY_TYPE.IMAGE]: new Map(),       // list of IMG urls
        [ENTITY_TYPE.TABLE]: 0,               // count of tables
        [ENTITY_TYPE.MATH]: 0,                // count of MathML formulas
        [ENTITY_TYPE.LESSON_LINK]: new Map(), // list of lesson links
        [ENTITY_TYPE.REVIEW]: new Map(),      // count of reviews per review type
    };
};

/**
 * Recursively walks through raw ContentState (result of DraftJS::convertToRaw);
 *
 * @param {Object} rawContentState
 * @param {Map} initialList Used in recursion, do not specify if for the top most call.
 */
const getUsedEntities = (rawContentState, initialList = getInitialEntityList()) => {
    const entityMap = rawContentState && rawContentState.entityMap;

    if (!entityMap) {
        return initialList;
    }

    return Object.values(entityMap).reduce((acc, {type, data}) => {

        if (type === ENTITY_TYPE.INLINE_IMAGE) {
            // here is not useful to distinguish between block and inline images
            type = ENTITY_TYPE.IMAGE;
        }

        switch (type) {
            case ENTITY_TYPE.GLOSSARY: {
                const { key } = data;
                acc[type].set(key, (acc[type].get(key) || 0) + 1);
                return acc;  // currently nested glossary term is not allowed => no recursion
            }

            case ENTITY_TYPE.SNIPPET: {
                const { snippetKey, contentState } = data;
                acc[type].set(snippetKey, (acc[type].get(snippetKey) || 0) + 1);
                return getUsedEntities(contentState, acc);
            }

            case ENTITY_TYPE.TABLE: {
                const { tableData } = data;
                acc[type]++;
                for (const row of tableData) {
                    for (const cell of row) {
                        acc = getUsedEntities(cell.contentState, acc);
                    }
                }
                return acc;
            }

            case ENTITY_TYPE.IMAGE: {
                const { src, ...rest } = data;
                acc[type].set(src, rest);
                return acc;
            }

            case ENTITY_TYPE.LESSON_LINK: {
                const { outlineId } = data;
                acc[type].set(outlineId, (acc[type].get(outlineId) || 0) + 1);
                return acc;
            }

            case ENTITY_TYPE.REVIEW: {
                const { reviewType, html, originalHtml, comment = "" } = data;
                const reviewTypeValue = acc[type].get(reviewType) || [];
                reviewTypeValue.push({ html, originalHtml, comment });
                acc[type].set(reviewType, reviewTypeValue);
                return acc;
            }

            case ENTITY_TYPE.MATH:
                acc[type]++;
                return acc;

            default:
                return acc;
        }
    }, initialList);
};

const listMapKeys = (map) => (map ? [...map.keys()] : []);
const keyAbsentIn = (map) => (key) => !map.has(key);

/**
 * Returns array of keys from mapB which are not in mapA.
 *
 * @param {Map} mapA
 * @param {Map} mapB
 */
const diffMapKeys = (mapA, mapB) => listMapKeys(mapB).filter(keyAbsentIn(mapA));

const TRACKED_ENTITIES = [
    ENTITY_TYPE.GLOSSARY,
    ENTITY_TYPE.SNIPPET,
    ENTITY_TYPE.IMAGE,
    ENTITY_TYPE.LESSON_LINK,
    ENTITY_TYPE.REVIEW,
];

const diffEntityUsage = (initialUsage, currentUsage) => ({
    added: diffMapKeys(initialUsage, currentUsage),
    removed: diffMapKeys(currentUsage, initialUsage)
});

const diffEntitiesUsage = (initialUsage, currentUsage, selectedTypes) => {
    const entityTypes = Array.isArray(selectedTypes) ? selectedTypes : TRACKED_ENTITIES;
    return entityTypes.reduce((changes, entityType) => {
        changes[entityType] = diffEntityUsage(initialUsage[entityType], currentUsage[entityType]);
        return changes;
    }, {});
};

/**
 * Get information about the entity usage changes in given DraftJS content.
 *
 * If no entity type is specified then usage for all tracked entity is returned.
 *
 * @param {object} initialContentState raw content state (result of DraftJS::convertToRaw)
 * @param {object} currentContentState raw content state (result of DraftJS::convertToRaw)
 * @param {string|Array} entityType (optional) one or array of entity type string
 * @return {object} changes of given entity { added, removed } or { entityType: { added, removed } }
 */
const getEntityUsageChanges = (initialContentState, currentContentState, entityType) => {
    const initialUsage = getUsedEntities(initialContentState);
    const currentUsage = getUsedEntities(currentContentState);
    if (entityType === undefined) {
        return diffEntitiesUsage(initialUsage, currentUsage);
    }
    if (typeof entityType === "string") {
        return diffEntityUsage(initialUsage[entityType], currentUsage[entityType]);
    }
    if (Array.isArray(entityType) && entityType.length) {
        return diffEntitiesUsage(initialUsage, currentUsage, entityType);
    }
    throw new Error("Unsupported entity type - string/array of strings expected, given " + typeof entityType);
};

/**
 * Walk-through the DraftJS content and collect the IDs of given entity.
 *
 * @param {object} contentState raw content state of DraftJS
 * @param {string} entityType,
 * @param {boolean} buildDataset true to convert output to dataset, i.e. { <snippetId>: true, ... }
 * @return {array|object|null} list of snippet IDs, optionally converted to dataset or null (if empty)
 */
const getUsedEntityKeys = (contentState, entityType, buildDataset) => {
    const used = getUsedEntities(contentState);
    const keys = listMapKeys(used[entityType]);
    if (buildDataset === true) {
        return keys.length ? keys.reduce((dataset, key) => {
            dataset[key] = true;
            return dataset;
        }, {}) : null; // return null to erase "snippets" field
    }
    return keys;
};

/**
 * Walk-through the DraftJS content and collect the IDs of used snippets.
 *
 * @param {object} contentState raw content state of DraftJS
 * @param {boolean} buildDataset true to convert output to dataset, i.e. { <snippetId>: true, ... }
 * @return {array|object|null} list of snippet IDs, optionally converted to dataset or null (if empty)
 */
const getUsedSnippets = (contentState, buildDataset) =>
    getUsedEntityKeys(contentState, ENTITY_TYPE.SNIPPET, buildDataset);

/**
 * Walk-through the DraftJS content and collect the IDs of used glossary.
 *
 * @param {object} contentState raw content state of DraftJS
 * @param {boolean} buildDataset true to convert output to dataset, i.e. { <glossaryId>: true, ... }
 * @return {array|object|null} list of glossary IDs, optionally converted to dataset or null (if empty)
 */
const getUsedGlossary = (contentState, buildDataset) =>
    getUsedEntityKeys(contentState, ENTITY_TYPE.GLOSSARY, buildDataset);

/**
 * Walk-through the DraftJS content and collect the IDs of linked outlines.
 *
 * @param {object} contentState raw content state of DraftJS
 * @param {boolean} buildDataset true to convert output to dataset, i.e. { <outlineId>: true, ... }
 * @return {array|object|null} list of outline IDs, optionally converted to dataset or null (if empty)
 */
const getLinkedOutlinesFromLessonLinks = (contentState, buildDataset) =>
    getUsedEntityKeys(contentState, ENTITY_TYPE.LESSON_LINK, buildDataset);

/**
 * Walk-through the DraftJS content and count number of reviews.
 *
 * @param {object} contentState raw content state of DraftJS
 * @return {object} key represent review type, value is number of reviews with given type
 */
const getReviewsStats = (contentState) => {
    const usedReviews = getUsedEntities(contentState)[ENTITY_TYPE.REVIEW];
    const reviewStats = {};

    for (const [reviewType, entries] of usedReviews.entries()) {
        reviewStats[reviewType] = entries;
    }
    return reviewStats;
};

const notifyEntityUsage = (initialContentState, currentContentState) => {
    const changes = getEntityUsageChanges(initialContentState,currentContentState);
    /* eslint-disable no-console */
    // TODO: to be implemented
    console.log("%cTracked Entities", "font-size: 16px; color: orange;");
    console.table(changes);
    /* eslint-enable */
};

const expandEntitySelection = (entityRange, selection) => {
    let {start, end} = entityRange;

    if (selection.getIsBackward()) {
        [start, end] = [end, start];
    }

    return selection.merge({
        anchorOffset: start,
        focusOffset: end,
    });
};

/**
 * Nearly equivalent to getEntityRange from "draftjs-utils", but this one uses block (ContentBlock) argument
 * instead of obtaining the current block from editorState and currentSelection.
 * It is useful e.g. after click on an entity, when the clicked entity knows its block, but the current selection
 * can be in a different block.
 */
const getEntityRangeInBlock = (block, entityKey) => {
    let entityRange;
    block.findEntityRanges(
        (value) => value.get("entity") === entityKey,
        (start, end) => {
            entityRange = {
                start,
                end,
                text: block.get("text").slice(start, end),
            };
        },
    );
    return entityRange;
};

const preventDefault = (e) => {
    e.preventDefault();
};

const stopPropagation = (e) => {
    e.stopPropagation();
};

const swallowEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
};

/**
 * Delete atomic block from ContentState and update EditorState selection
 * @param {} block
 * @param {*} contentState
 * @param {*} editorState
 */
const deleteBlockEntity = (block, contentState, editorState) => {

    const withoutAtomicEntity = Modifier.removeRange(
        contentState,
        new SelectionState({
            anchorKey: block.getKey(),
            anchorOffset: 0,
            focusKey: block.getKey(),
            focusOffset: block.getLength(),
        }),
        "backward",
    );

    const selection = contentState.getSelectionBefore();
    // remove block from the map
    const blockMap = withoutAtomicEntity.blockMap.filter(item => item.key !== block.getKey());
    var withoutAtomic = withoutAtomicEntity.merge({
        blockMap,
        selectionAfter: selection,
    });

    return EditorState.push(EditorState.acceptSelection(editorState, selection), withoutAtomic, "remove-block");
};

const isSnippet = (editorState, contentBlock) => {
    if (contentBlock.getType() === "atomic") {
        const entityKey = contentBlock.getEntityAt(0);

        if (entityKey) {
            const contentState = editorState.getCurrentContent();
            return contentState.getEntity(entityKey).getType() === ENTITY_TYPE.SNIPPET;
        }
    }
    return false;
};

const getCaretNextTarget = (direction, editorState) => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
        return {};
    }
    const contentState = editorState.getCurrentContent();
    const offset = selection.getStartOffset();
    const block = getSelectedBlock(editorState);
    const blockKey = block.getKey();
    const atBeginOfBlock = offset === 0;
    const atBeginOfContent = atBeginOfBlock && blockKey === contentState.getFirstBlock().getKey();
    const atEndOfBlock = block.getLength() === offset;
    const atEndOfContent = atEndOfBlock && blockKey === contentState.getLastBlock().getKey();
    let nextBlock;
    let boundaryType;

    if (direction === "right" || direction === "down") {
        if (atEndOfContent) {
            boundaryType = "LEAVE_ENTITY_RIGHT";
        }
        else if (atEndOfBlock) {
            nextBlock = contentState.getBlockAfter(blockKey);
        }
    }

    if (direction === "left" || direction === "up") {
        if (atBeginOfContent) {
            boundaryType = "LEAVE_ENTITY_LEFT";
        }
        else if (atBeginOfBlock) {
            nextBlock = contentState.getBlockBefore(blockKey);
        }
    }

    if (nextBlock && isSnippet(editorState, nextBlock)) {
        return {
            type: ENTITY_TYPE.SNIPPET,
            entityKey: nextBlock.getEntityAt(0),
        };
    }

    return {
        boundaryType,
    };
};

const getNewContentBlock = () => {
    const newBlockKey = genKey();

    return new ContentBlock({
        key: newBlockKey,
        type: "unstyled",
        text: "",
        characterList: Immutable.List(),
    });
};

const insertEmptyBlock = (editorState, targetBlock, insertAfter = true) => {
    let contentState = editorState.getCurrentContent();
    let blockMap = contentState.getBlockMap();

    const blocksBefore = blockMap.toSeq().takeUntil((block) => block === targetBlock);
    const blocksAfter = blockMap.toSeq().skipUntil((block) => block === targetBlock).rest();

    const newBlock = getNewContentBlock();
    const newBlockKey = newBlock.getKey();

    const blocksBetween = [[targetBlock.getKey(), targetBlock]];

    if (insertAfter) {
        blocksBetween.push([newBlockKey, newBlock]);
    }
    else {
        blocksBetween.unshift([newBlockKey, newBlock]);
    }

    blockMap = blocksBefore.concat(blocksBetween, blocksAfter).toOrderedMap();
    contentState = contentState.merge({
        blockMap,
        selectionAfter: new SelectionState({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: 0,
            isBackward: false,
        }),
    });
    return EditorState.push(editorState, contentState, "insert-fragment");
};

/**
 * Intended for Entity Decorator click handler.
 *
 * Click on entity doesn't change editor selection.
 * Then any operation with currently selected ContentBlock can fail because it can manipulate with
 * a different ContentBlock then the current entity is related to.
 *
 * This method set selection after the current entity. When the entity is at the end of the block,
 * space character has to be added at the end of the block to create place in the block where the selection
 * can be moved.
 *
 * Atomic block (used e.g. for tables and images) should be always surrounded by other editable blocks
 * (see https://github.com/facebook/draft-js/issues/327#issuecomment-212514270),
 * but in our implementation it is somehow possible to delete surrounding blocks.
 * So we have to check it too, add surrounding block (the next one at least) if necessary and then move selection to the next block.
 */
const setCaretToEntity = (editorState, blockKey, entityKey) => {
    let contentState = editorState.getCurrentContent();
    let block = contentState.getBlockForKey(blockKey);
    let caretPos;

    if (block.getType() === "atomic") {
        let nextBlock = contentState.getBlockAfter(blockKey);

        if (!nextBlock || nextBlock.getType() === "atomic") {
            return insertEmptyBlock(editorState, block);
        }

        blockKey = nextBlock.getKey();
        caretPos = 0;
    }
    else {
        const entityRange = getEntityRangeInBlock(block, entityKey);
        caretPos = entityRange.end;
    }

    const selection = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: caretPos,
        focusKey: blockKey,
        focusOffset: caretPos,
    });

    let newEditorState = EditorState.acceptSelection(editorState, selection);
    const isAtEndOfBlock = block.getText().length === selection.getEndOffset();
    const nextCharEntityKey = block.getEntityAt(caretPos);
    const isNextCharInlineEntity = nextCharEntityKey && requiresSpaceAfter(newEditorState.getCurrentContent().getEntity(nextCharEntityKey).getType());

    if (isAtEndOfBlock || isNextCharInlineEntity) {
        // Entity is:
        //   (a) at the end of the block
        //   (b) immediately followed by another inline entity
        // => append space where the caret can be placed.
        contentState = newEditorState.getCurrentContent();
        contentState = Modifier.insertText(contentState, newEditorState.getSelection(), " ");
        newEditorState = EditorState.push(newEditorState, contentState, "insert-characters");
    }

    return newEditorState;
};

const isAtBeginOfBlock = (editorState) => {
    const selection = editorState.getSelection();
    return selection.getAnchorKey() === selection.getFocusKey() && selection.getAnchorOffset() === 0 && selection.getFocusOffset() === 0;
};

const isAtEndOfBlock = (editorState) => {
    const selection = editorState.getSelection();
    const currentBlockKey = selection.getAnchorKey();
    const currentBlock = editorState.getCurrentContent().getBlockForKey(currentBlockKey);
    return selection.isCollapsed() && currentBlockKey === selection.getFocusKey() && selection.getAnchorOffset() === currentBlock.getLength();
};

const isSingleBlockSelected = (editorState) => {
    const selection = editorState.getSelection();
    return selection.getAnchorKey() === selection.getFocusKey();
};

const isSelectionCollapsed = (editorState) => {
    const selection = editorState.getSelection();
    return selection.isCollapsed();
};

const getCharInfo = (contentState, block, charPos) => {
    const entityKey = block.getEntityAt(charPos);
    let characterType;
    let entity;

    if (block.getType() === "atomic") {
        characterType = CHARACTER_TYPE.ATOMIC;
    }
    else {
        if (entityKey) {
            characterType = CHARACTER_TYPE.ENTITY;
            entity = contentState.getEntity(entityKey);
        }
        else {
            characterType = charPos === block.getLength() ? CHARACTER_TYPE.END_OF_BLOCK : CHARACTER_TYPE.ORDINARY;
        }
    }

    return {
        type: characterType,
        char: block.getText().substr(charPos, 1),
        charPos,
        block,
        entity,
    };
};

/**
 * Provides info about the first/last selected characters and the closest character surrounding the current selection.
 * @param {EditorState} editorState
 * @return {Object}
 */
const getSelectionInfo = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const firstBlockKey = selection.getAnchorKey();
    const lastBlockKey = selection.getFocusKey();
    const firstBlock = contentState.getBlockForKey(firstBlockKey);
    const lastBlock = contentState.getBlockForKey(lastBlockKey);
    const firstCharPos = selection.getAnchorOffset();
    const lastCharPos = selection.getFocusOffset();

    let block;
    let charPos;

    const info = {
        start: getCharInfo(contentState, firstBlock, firstCharPos),
        end: getCharInfo(contentState, lastBlock, lastCharPos),
    };

    if (firstCharPos === 0 || firstBlock.getType() === "atomic") {
        block = contentState.getBlockBefore(firstBlockKey);
        charPos = block && block.getLength();
    }
    else {
        block = firstBlock;
        charPos = firstCharPos - 1;
    }

    if (block) {
        info.previous = getCharInfo(contentState, block, charPos);
    }

    block = contentState.getBlockForKey(lastBlockKey);

    if (lastCharPos === block.getLength()) {
        block = contentState.getBlockAfter(lastBlockKey);
        charPos = block && 0;
    }
    else {
        block = lastBlock;
        charPos = lastCharPos + 1;
    }

    if (block) {
        info.next = getCharInfo(contentState, block, charPos);
    }

    return info;
};

/**
 * Checks and repair the current selection and its surrounding.
 *
 * Some `inline entities` (without default rendering `this.props.children` e.g. review) have to be followed by ordinary character
 * (at least by space) where the editor is able to place the caret.
 * `atomic blocks` (e.g. snippets) have to be surrounded by non-atomic block because of caret again.
 * It was possible to remove an empty block before a snippet because of "backward" selection when the snippet
 *
 * It is expected that this is called only when contentState is changed. If we realize performance issue with this function,
 * we could try to disable removing significant spaces/block in BookEditor::onKeyCommand, but it probably would require
 * an additional handling in Copy&Paste (e.g. user selects the space between two formulas and in the clipboard is NEW_LINE only
 * - Paste: is the result correct?).
 *
 * @param {Draft::EditorState} editorState
 * @returns {Draft::EditorState}
 */
const fixSurroundingEntities = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const { previous, start } = getSelectionInfo(editorState);
    let fixedContentState = contentState;

    if (previous) {
        const { entity, block, charPos } = previous;

        if (previous.type === CHARACTER_TYPE.ENTITY && entity.getMutability() === "IMMUTABLE") {
            const entityType = entity.getType();

            if (requiresSpaceAfter(entityType) && start.type !== CHARACTER_TYPE.ORDINARY) {
                // missing space after immutable inline entity when the next char is not ordinary character
                const insertSelection = new SelectionState({
                    anchorKey: block.getKey(),
                    anchorOffset: charPos + 1,
                    focusKey: block.getKey(),
                    focusOffset: charPos + 1,
                });
                fixedContentState = Modifier.insertText(fixedContentState, insertSelection, " ");
            }
        }
        else if (previous.type === CHARACTER_TYPE.ATOMIC && start.type === CHARACTER_TYPE.ATOMIC) {
            // there has to be a block between two atomic blocks
            editorState = insertEmptyBlock(editorState, block, true);
        }
    }

    if (fixedContentState !== contentState) {
        editorState = EditorState.push(editorState, fixedContentState, editorState.getLastChangeType());
        editorState = EditorState.acceptSelection(editorState, selection);
    }

    return editorState;
};

const selectAll = (editorState, hasFocus = false) => {
    const contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const lastBlock = contentState.getLastBlock();
    const selection = new SelectionState({
        anchorKey: firstBlock.getKey(),
        anchorOffset: 0,
        focusKey: lastBlock.getKey(),
        focusOffset: lastBlock.getLength(),
        isBackward: false,
        hasFocus,
    });

    return EditorState.acceptSelection(editorState, selection);
};

/**
 * Adds/Removes all given styles from the current selection.
 *
 * @param {Draft::EditorState} editorState
 * @param {Array<string>} styles Inline styles to add/remove (e.g. ["BOLD", "ITALIC"])
 * @param {Function} modifierMethod Draft::Modifier method (`applyInlineStyle`/`removeInlineStyle`)
 * @returns {Draft::EditorState}
 */
const handleInlineStyles = (editorState, styles, modifierMethod) => {
    const selection = editorState.getSelection();

    styles.forEach((style) => {
        const newContent = modifierMethod(
            editorState.getCurrentContent(),
            selection,
            style,
        );
        editorState = EditorState.push(editorState, newContent, "change-inline-style");
    });

    return editorState;
};

/**
 * Applies all given styles to the current selection.
 *
 * @param {Draft::EditorState} editorState
 * @param {Array<string>} styles Inline styles to apply (e.g. ["BOLD", "ITALIC"])
 * @returns {Draft::EditorState}
 */
const applyInlineStyles = (editorState, styles) => {
    return handleInlineStyles(editorState, styles, Modifier.applyInlineStyle);
};

/**
 * Removes all given styles from the current selection.
 *
 * @param {Draft::EditorState} editorState
 * @param {Array<string>} styles Inline styles to remove (e.g. ["BOLD", "ITALIC"])
 * @returns {Draft::EditorState}
 */
const removeInlineStyles = (editorState, styles) => {
    return handleInlineStyles(editorState, styles, Modifier.removeInlineStyle);
};


/**
 * Sets "text-align" property for all blocks in the current selection.
 *
 * @param {Draft::EditorState} editorState
 * @param {string} textAlign
 * @returns {Draft::EditorState}
 */
const setTextAlign = (editorState, textAlign) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const newBlockMap = blockMap.map((block) => {
        return block.merge({
            data: block.getData().merge({ ["text-align"]: textAlign }),
        });
    });

    const newContentState = contentState.merge({
        blockMap: newBlockMap,
    });

    return editorState = EditorState.push(editorState, newContentState, "change-block-data");
};

/**
 * Split a given path to two parts: base name and extension.
 *
 * @param {string} filename
 * @returns {string} filename with injected
 */
const getFilenameForStorage = (filename, uniqueId = uuid()) => {
    // Skip injection of UUID to filename
    if (uniqueId === false) {
        return filename;
    }
    // ignore the filenames that starts with dot or without dot
    // i.e. "", "name", ".htpasswd" => they don't have extension
    // https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
    const extFound = filename && (filename.lastIndexOf(".") - 1 >>> 0);
    if (extFound) {
        const baseName = filename.slice(0, extFound + 1);
        const extension = filename.slice(extFound + 1);
        return `${baseName}--${uniqueId}${extension}`;
    }
    return uniqueId ? `${filename}--${uniqueId}` : filename;
};

/**
 * When soft-line (`Shift+Enter`) is inserted into Header element followed by any text, and the added text
 * is removed by `Backspace`, the NEW_LINE character is not removed from the block content on Firefox.
 * Then pressing Enter fires `split-block` command, because Draft thinks it is inside block (but it should be at the end).
 *
 * @param {EditorState} editorState
 * @returns {EditorState} New editor state when the fix was necessary, null otherwise.
 */
const fixSplitBlock = (editorState) => {
    let selection = editorState.getSelection();

    if (isFirefox && selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent();
        const block = contentState.getBlockForKey(selection.focusKey);
        const text = block.getText();
        const isHeader = block.getType().indexOf("header-") === 0;

        if (isHeader && text.length === selection.focusOffset + 1 && text[selection.focusOffset] === "\n") {
            selection = new SelectionState({
                anchorKey: selection.focusKey,
                anchorOffset: selection.focusOffset,
                focusKey: selection.focusKey,
                focusOffset: selection.focusOffset + 1,
            });
            const newContentState = Modifier.removeRange(contentState, selection, "forward");
            editorState = EditorState.push(editorState, newContentState, "remove-range");
            editorState = insertEmptyBlock(editorState, newContentState.getBlockForKey(selection.focusKey), true);
            const newBlockKey = editorState.getCurrentContent().getKeyAfter(selection.anchorKey);
            selection = new SelectionState({
                anchorKey: newBlockKey,
                anchorOffset: 0,
                focusKey: newBlockKey,
                focusOffset: 0,
            });
            return EditorState.forceSelection(editorState, selection);
        }
    }

    return null;
};

/**
 * https://github.com/facebook/draft-js/issues/278
 */
const removeAllFormattingInBlock = (block, start, end) => {
    const characterList = block.getCharacterList();
    const updatedCharacterList = characterList.map((c, i) => {
        if (i >= start && i <= end) {
            return c.set("style", c.get("style").clear());
        }
        return c;
    });

    return block.set("characterList", updatedCharacterList);
};

/**
 * Resets all inline styles in the current selection (currently limited for single block only).
 *
 * @param {EditorState} editorState
 * @returns {ContentState} New content state.
 */
const removeAllFormattingInSelection = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const blockMap = contentState.getBlockMap();
    const block = contentState.getBlockForKey(selection.getAnchorKey());
    let start = selection.getAnchorOffset();
    let end = selection.getFocusOffset();

    if (selection.getIsBackward()) {
        [start, end] = [end, start];
    }

    return contentState.merge({
        blockMap: blockMap.set( block.getKey(), removeAllFormattingInBlock(block, start, end) ),
    });
};

/**
 * Switches formatting of currently selected text between `Lato 14px` and `Stix 18px`.
 * When the selected text contains standalone numbers (e.g. "AB 123 CD", not "AB123 CD"), such numbers have `regular` font,
 * the rest of the text is in `italic` (Wiris editor does it the same way).
 * All other formatting (e.g. Bold, Color) is removed.
 *
 * @param {EditorState} editorState
 * @returns {EditorState} New editorState.
 */
const toggleMathTextFont = (editorState) => {

    if (!isSingleBlockSelected(editorState)) {
        toastError({
            code: "Unsupported operation",
            header: "Too complex selection",
            message: "Math/Text font can be switched within a single paragraph only.",
        });
        return editorState;
    }

    const { FONTFAMILY } = getSelectionCustomInlineStyle(editorState, ["FONTFAMILY"]);
    let newFontFamily = "Stix";
    let newFontSize = "18";

    if (FONTFAMILY && FONTFAMILY.indexOf("Stix") > 0) {  // e.g FONTFAMILY = "fontfamily-Stix"
        newFontFamily = "Lato";
        newFontSize = "14";
    }

    let contentState = removeAllFormattingInSelection(editorState);
    let selection = editorState.getSelection();
    const origStart = selection.getAnchorOffset();
    const origEnd = selection.getFocusOffset();
    const isBackward = selection.getIsBackward();

    if (newFontFamily === "Stix") {
        const words = getSelectionText(editorState).split(/(\W+)/g);
        let start = isBackward ? origEnd : origStart;

        // Italicize ordinary text, standalone numbers keep in regular font.
        words.forEach((word) => {
            const number = parseInt(word, 10);
            const end = start + word.length;

            if (isNaN(number) || String(number) !== word) {
                selection = selection.merge({ anchorOffset: start, focusOffset: end, isBackward: false });
                contentState = Modifier.applyInlineStyle(contentState, selection, "ITALIC");
            }
            start = end;
        });
    }

    editorState = EditorState.push(editorState, contentState, "change-inline-style");
    editorState = EditorState.acceptSelection(
        editorState,
        selection.merge({ anchorOffset: origStart, focusOffset: origEnd, isBackward })
    );
    editorState = toggleCustomInlineStyle(editorState, "fontFamily", newFontFamily);
    editorState = toggleCustomInlineStyle(editorState, "fontSize", newFontSize);
    return editorState;
};

export {
    requiresSpaceAfter,
    insertSegment,
    reconstructContentState,
    getUsedEntities,
    getEntityUsageChanges,
    getUsedSnippets,
    notifyEntityUsage,
    getUsedGlossary,
    expandEntitySelection,
    getEntityRangeInBlock,
    stopPropagation,
    preventDefault,
    swallowEvent,
    deleteBlockEntity,
    isSnippet,
    getCaretNextTarget,
    getLinkedOutlinesFromLessonLinks,
    getNewContentBlock,
    insertEmptyBlock,
    setCaretToEntity,
    getReviewsStats,
    isAtBeginOfBlock,
    isAtEndOfBlock,
    isSingleBlockSelected,
    isSelectionCollapsed,
    alignBlock,
    fixSurroundingEntities,
    selectAll,
    applyInlineStyles,
    removeInlineStyles,
    setTextAlign,
    getFilenameForStorage,
    fixSplitBlock,
    toggleMathTextFont,
};
