import React from "react";
import PropTypes from "prop-types";

import LessonExports from "../Publisher/LessonExports";
import LessonReleasedContentView, { ReleasePropType } from "../Publisher/LessonReleasedContentView";

class LessonContentViewer extends React.PureComponent {
    static propTypes = {
        activeView: PropTypes.string,
        lessonId: PropTypes.string.isRequired,
        lessonName: PropTypes.string,
        outlineId: PropTypes.string.isRequired,
        outlineName: PropTypes.string,
        release: ReleasePropType,
    };

    render() {
        const { activeView, lessonId, lessonName, outlineId, outlineName, release } = this.props;
        return "prints" === activeView ? (
            <LessonExports lessonId={lessonId} outlineId={outlineId} outlineName={outlineName} lessonName={lessonName} />
        ) : (
            <LessonReleasedContentView entity={activeView} lessonId={lessonId} outlineId={outlineId} release={release} />
        );
    }
}

export default LessonContentViewer;