import React from "react";
import * as deepmerge from "deepmerge";
const merge = deepmerge.default;  // see https://github.com/TehShrike/deepmerge#includes

import CustomToolbarButton from "./CustomToolbarButton";
import CustomToolbarActionButton from "./CustomToolbarActionButton";
import { ENTITY_TYPE } from "./constants";
import firebase from "../../../KitBuilder/dataSource";
import Image from "./controls/Image";
import { isSingleBlockSelected, isSelectionCollapsed, isAtEndOfBlock, getFilenameForStorage, toggleMathTextFont } from "./utils/utils";
import { insertListItemAsReview, removeListItemAsReview, insertBlockAsReview, mergeBlocksAsReview } from "./reviewEntity/reviewUtils";
import { isListItem } from "./listItem/listItemUtils";
import bold from "./icons/bold-solid.svg";
import italic from "./icons/italic-solid.svg";
import underline from "./icons/underline-solid.svg";
import unordered from "./icons/list-ul-solid.svg";
import ordered from "./icons/list-ol-solid.svg";
import indent from "./icons/indent-solid.svg";
import outdent from "./icons/outdent-solid.svg";
import left from "./icons/align-left-solid.svg";
import right from "./icons/align-right-solid.svg";
import center from "./icons/align-center-solid.svg";
import justify from "./icons/align-justify-solid.svg";
import colorpicker from "./icons/eye-dropper-solid.svg";
import image from "./icons/image-regular.svg";
import mathicon from "./icons/square-root-alt-solid.svg";


const uploadImageCallback = (file) => {
    return new Promise(
        (resolve, reject) => {
            const storageName = getFilenameForStorage(file.name);
            const storagePath = `widgetLibrary/images/${storageName}`;
            const fileRef = firebase.getFirebaseFile(storagePath);
            const metadata = {
                cacheControl: "public, max-age=31536000",
            };

            fileRef.put(file, metadata).then(() => {
                const data = { storagePath };
                resolve({ data });
            }).catch((e) => reject(e));
        }
    );
};

const isCaretInsideListItem = (editorState) => {
    return isSelectionCollapsed(editorState) && isListItem(editorState);
};

const canBeMerged = (editorState) => {
    if (isAtEndOfBlock(editorState)) {
        const selection = editorState.getSelection();
        const currentBlockKey = selection.getAnchorKey();
        const nextBlock = editorState.getCurrentContent().getBlockAfter(currentBlockKey);

        if (nextBlock && nextBlock.getType() !== "atomic") {
            return true;
        }
    }
    return false;
};

// https://jpuri.github.io/react-draft-wysiwyg/#/docs
// eslint-disable-next-line no-unused-vars
const defaultConfig = {
    options: [
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        "embedded",
        "emoji",
        "image",
        "remove",
        "history",
    ],
    inline: {
        // inDropdown: false,
        className: "rdw-editor-toolbar-icon",
        // component: undefined,
        dropdownClassName: "rdw-editor-toolbar-icon",
        options: ["bold", "italic", "underline", "strikethrough", "monospace", "superscript", "subscript"],
        bold: { icon: bold, className: undefined },
        italic: { icon: italic, className: undefined },
        underline: { icon: underline, className: undefined },
        // strikethrough: { icon: strikethrough, className: undefined },
        // monospace: { icon: monospace, className: undefined },
        // superscript: { icon: superscript, className: undefined },
        // subscript: { icon: subscript, className: undefined },
    },
    blockType: {
        inDropdown: true,
        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
        // className: undefined,
        // component: undefined,
        // dropdownClassName: undefined,
    },
    fontSize: {
        // icon: fontSize,
        // className: undefined,
        // component: undefined,
        // dropdownClassName: undefined,
    },
    fontFamily: {
        options: ["Lato", "Stix"],
        // className: undefined,
        // component: undefined,
        // dropdownClassName: undefined,
    },
    list: {
        // inDropdown: false,
        className: "rdw-editor-toolbar-icon",
        // component: undefined,
        dropdownClassName: "rdw-editor-toolbar-icon",
        options: ["unordered", "ordered", "indent", "outdent"],
        unordered: { icon: unordered, className: undefined },
        ordered: { icon: ordered, className: undefined },
        indent: { icon: indent, className: undefined },
        outdent: { icon: outdent, className: undefined },
    },
    textAlign: {
        inDropdown: false,
        className: "rdw-editor-toolbar-icon",
        component: undefined,
        dropdownClassName: "rdw-editor-toolbar-icon",
        options: ["left", "center", "right", "justify"],
        left: { icon: left, className: undefined },
        center: { icon: center, className: undefined },
        right: { icon: right, className: undefined },
        justify: { icon: justify, className: undefined },
    },
    colorPicker: {
        icon: colorpicker,
        className: "rdw-editor-toolbar-icon",
        // component: undefined,
        // popupClassName: undefined,
        colors: [
            "#90caf9",
            "#a5d6a7",
            "#ffcc80",
            "#ce93d8",
            "#ef9a9a",
            "#000000",
            "#b9b9b7",
            "#e4e4e0",
            "#e3e3e1",
            "#ffffff"],
    },
    link: {
        // inDropdown: false,
        // className: undefined,
        // component: undefined,
        // popupClassName: undefined,
        // dropdownClassName: undefined,
        // showOpenOptionOnHover: true,
        // defaultTargetOption: "_self",
        options: ["link", "unlink"],
        // link: { icon: link, className: undefined },
        // unlink: { icon: unlink, className: undefined },
    },
    // emoji: {
    //     // icon: emoji,
    //     // className: undefined,
    //     // component: undefined,
    //     // popupClassName: undefined,
    //     emojis: [
    //         "😀", "😁", "😂", "😃", "😉", "😋", "😎", "😍", "😗", "🤗", "🤔", "😣", "😫", "😴", "😌", "🤓",
    //         "😛", "😜", "😠", "😇", "😷", "😈", "👻", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "🙈",
    //         "🙉", "🙊", "👼", "👮", "🕵", "💂", "👳", "🎅", "👸", "👰", "👲", "🙍", "🙇", "🚶", "🏃", "💃",
    //         "⛷", "🏂", "🏌", "🏄", "🚣", "🏊", "⛹", "🏋", "🚴", "👫", "💪", "👈", "👉", "👉", "👆", "🖕",
    //         "👇", "🖖", "🤘", "🖐", "👌", "👍", "👎", "✊", "👊", "👏", "🙌", "🙏", "🐵", "🐶", "🐇", "🐥",
    //         "🐸", "🐌", "🐛", "🐜", "🐝", "🍉", "🍄", "🍔", "🍤", "🍨", "🍪", "🎂", "🍰", "🍾", "🍷", "🍸",
    //         "🍺", "🌍", "🚑", "⏰", "🌙", "🌝", "🌞", "⭐", "🌟", "🌠", "🌨", "🌩", "⛄", "🔥", "🎄", "🎈",
    //         "🎉", "🎊", "🎁", "🎗", "🏀", "🏈", "🎲", "🔇", "🔈", "📣", "🔔", "🎵", "🎷", "💰", "🖊", "📅",
    //         "✅", "❎", "💯",
    //     ],
    // },
    embedded: {
        // icon: embedded,
        // className: undefined,
        // component: undefined,
        // popupClassName: undefined,
        defaultSize: {
            height: "auto",
            width: "auto",
        },
    },
    image: {
        icon: image,
        className: "rdw-editor-toolbar-icon",
        // component: Image,  // will be set dynamically
        // popupClassName: undefined,
        urlEnabled: false,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: uploadImageCallback,
        previewImage: true,
        inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg+xml",
        alt: { present: false, mandatory: false },
        defaultSize: {
            height: "auto",
            width: "auto",
        },
    },
    // remove: {
    //     // icon: eraser, className: undefined, component: undefined
    // },
    history: {
        // inDropdown: false,
        // className: undefined,
        // component: undefined,
        // dropdownClassName: undefined,
        options: ["undo", "redo"],
        // undo: { icon: undo, className: undefined },
        // redo: { icon: redo, className: undefined },
    },
};

const STYLES = {
    BOLD: "bold",
    ITALIC: "italic",
    UNDERLINE: "underline",
    STRIKETHROUGH: "strikethrough",
    MONOSPACE: "monospace",
    SUPERSCRIPT: "superscript",
    SUBSCRIPT: "subscript",
};

const BLOCKS = {
    NORMAL: "Normal",
    H1: "H1",
    H2: "H2",
    H3: "H3",
    H4: "H4",
    H5: "H5",
    H6: "H6",
    QUOTE: "Blockquote",
    CODE: "Code",
};

const LISTS = {
    UL: ["unordered", "indent", "outdent"],
    OL: ["ordered", "indent", "outdent"],
};

const FEATURES = {
    TEXTALIGN: "textAlign",
    FONT: "fontFamily",
    FONTSIZE: "fontSize",
    COLOR: "colorPicker",
    LINK: "link",
    EMBEDDED: "embedded",
    EMOJI: "emoji",
    IMG: "image",
};

const getCustomButtons = (addProps) => {
    const { showCustomComponent, setEditorState, contextHelp } = addProps;

    return {
        MATH: <CustomToolbarButton
            entityType={ENTITY_TYPE.MATH}
            showCustomComponent={showCustomComponent}
            iconImage={mathicon}
            title="Math Formula"
            useAsContext={true}
        />,
        MATHTEXT: <CustomToolbarActionButton
            iconClassName="text"
            iconModifier={{ className: "square root transparent" }}
            title="Switch Font Math/Text"
            setEditorState={setEditorState}
            editorStateAction={toggleMathTextFont}
        />,
        SNIPPET: <CustomToolbarButton
            entityType={ENTITY_TYPE.SNIPPET}
            showCustomComponent={showCustomComponent}
            iconName="window maximize outline"
            title="Styled Block"
            useAsContext={true}
        />,
        TABLE: <CustomToolbarButton
            entityType={ENTITY_TYPE.TABLE}
            showCustomComponent={showCustomComponent}
            iconName="table"
            title="Table"
            useAsContext={true}
        />,
        GLOSSARY: <CustomToolbarButton
            entityType={ENTITY_TYPE.GLOSSARY}
            showCustomComponent={showCustomComponent}
            iconName="book"
            title="Glossary Term"
            useAsContext={true}
        />,
        LESSON_LINK: <CustomToolbarButton
            entityType={ENTITY_TYPE.LESSON_LINK}
            showCustomComponent={showCustomComponent}
            iconName="linkify"
            title="Link to lesson"
            useAsContext={true}
        />,
        REVIEW: <CustomToolbarButton
            entityType={ENTITY_TYPE.REVIEW}
            showCustomComponent={showCustomComponent}
            iconName="comment alternate outline"
            title="Review"
            useAsContext={false}
            enableWhenEditorState={isSingleBlockSelected}
        />,
        REVIEW_INSERT_BLOCK: <CustomToolbarActionButton
            iconName="window maximize outline"
            iconModifier={{ icon: "plus circle", color: "lightseagreen"}}
            title="Insert New Block"
            setEditorState={setEditorState}
            editorStateAction={insertBlockAsReview}
        />,
        REVIEW_MERGE_BLOCKS: <CustomToolbarActionButton
            iconName="window maximize outline"
            iconModifier={{ icon: "minus circle", color: "lightcoral"}}
            title="Merge Blocks"
            setEditorState={setEditorState}
            editorStateAction={mergeBlocksAsReview}
            enableWhenEditorState={canBeMerged}
        />,
        REVIEW_INSERT_LI: <CustomToolbarActionButton
            iconName="list ul"
            iconModifier={{ icon: "plus circle", color: "lightseagreen"}}
            title="Insert List Item"
            setEditorState={setEditorState}
            editorStateAction={insertListItemAsReview}
            enableWhenEditorState={isCaretInsideListItem}
        />,
        REVIEW_REMOVE_LI: <CustomToolbarActionButton
            iconName="list ul"
            iconModifier={{ icon: "minus circle", color: "lightcoral"}}
            title="Remove List Item"
            setEditorState={setEditorState}
            editorStateAction={removeListItemAsReview}
            enableWhenEditorState={isCaretInsideListItem}
        />,
        HELP: <CustomToolbarButton
            entityType={ENTITY_TYPE.HELP}
            showCustomComponent={showCustomComponent}
            iconName="question circle"
            title="Help"
            useAsContext={true}
            contextHelp={contextHelp}
        />,
    };
};

const getAllFeatures = (addProps) => {
    const customButtons = getCustomButtons(addProps);
    const allKeys = [
        ...Object.keys(BLOCKS),
        ...Object.keys(STYLES),
        ...Object.keys(LISTS),
        ...Object.keys(FEATURES),
        ...Object.keys(customButtons),
    ];
    const allKeysUnique = [...new Set(allKeys)];

    if (allKeys.length !== allKeysUnique.length) {
        throw new Error("Development error: Predefined features set for reach text editor is not unique!");
    }
    return { ...BLOCKS, ...STYLES, ...FEATURES, ...LISTS, ...customButtons };
};

const filterFeatures = (scopedFeatures, requiredFeatureList) => {
    const filtered = [];

    Object.entries(scopedFeatures).forEach(([key, value]) => {
        if (requiredFeatureList.includes(key)) {
            if (Array.isArray(value)) {
                filtered.push(...value);
            } else {
                filtered.push(value);
            }
        }
    });
    return filtered;
};

const getDefaultConfig = (addProps) => {
    return {
        toolbar: { ...defaultConfig},
        customButtons: Object.values(getCustomButtons(addProps)),
    };
};

const createConfig = (requiredFeatures, addProps) => {
    if (!requiredFeatures || requiredFeatures.length === 0) {
        return getDefaultConfig(addProps);
    }

    const allFeatures = getAllFeatures(addProps);
    const allKeys = Object.keys(allFeatures);
    const unknown = requiredFeatures.filter((f) => !allKeys.includes(f));

    if (unknown.length > 0) {
        throw new Error(`Development error: Unknown features required: ["${unknown.join("\", \"")}"]`);
    }

    const inlineStyles = filterFeatures(STYLES, requiredFeatures);
    const blockTypes = filterFeatures(BLOCKS, requiredFeatures);
    const lists = filterFeatures(LISTS, requiredFeatures);

    const requiredOptions = [
        ...(inlineStyles.length ? ["inline"] : []),
        ...(blockTypes.length ? ["blockType"] : []),
        ...(lists.length ? ["list"] : []),
        ...filterFeatures(FEATURES, requiredFeatures),
        // "history", // Undo and Redo buttons are not visible in toolbar, but their functionality from keyboard is kept
    ];

    const filteredConfig = {
        options: requiredOptions,
        inline: {
            options: inlineStyles,
            inDropdown: true,
        },
        blockType: { options: blockTypes },
        list: {
            options: lists,
            inDropdown: true,
        },
        textAlign: { inDropdown: true },
    };

    const ImageWithProps = (props) => (<Image getEditorState={addProps.getEditorState} setEditorState={addProps.setEditorState} {...props} />);
    defaultConfig.image.component = ImageWithProps;

    return {
        toolbar: merge(defaultConfig, filteredConfig, { arrayMerge: (destinationArray, sourceArray) => sourceArray }),
        customButtons: filterFeatures(getCustomButtons(addProps), requiredFeatures),
    };
};

export default createConfig;
