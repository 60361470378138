import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import styles from "./errorLogViewer.module.scss";

export default class ErrorDetail extends React.PureComponent {
    static propTypes = {
        context: PropTypes.object.isRequired,
        data: PropTypes.shape({
            src: PropTypes.string,
            row: PropTypes.number,
            col: PropTypes.number,
            stack: PropTypes.string,
            url: PropTypes.string,
            ua: PropTypes.string,
        }),
        onClose: PropTypes.func.isRequired,
    };

    state = {
    };

    render() {
        const { context, data, onClose } = this.props;

        if (data === undefined) {  // no data fetched yet
            return null;
        }

        if (data === null) {  // empty data fetched
            return (<Popup context={context} open={true} onClose={onClose}>No details available.</Popup>);
        }

        const { src, row, col, stack, url, ua } = data;

        return (<Popup context={context} open={true} onClose={onClose}>
            <Popup.Header>
                Error detail
            </Popup.Header>
            <Popup.Content>
                <div><b>Url:</b> {url}</div>
                <div><b>Source:</b> {src}</div>
                {row && (<div><b>Row:</b> {row} &nbsp; <b>Column:</b> {col}</div>)}
                <div><b>User Agent:</b> {ua}</div>
                <fieldset className={styles.callStack}>
                    <legend>CallStack</legend>
                    <div>{stack && stack.split("\n").map((line, i) => <div key={i}>{line}</div>)}</div>
                </fieldset>
            </Popup.Content>
        </Popup>);
    }
}
