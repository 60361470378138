const TYPES = {
    DELETE_PROBLEM_SET: "DELETE_PROBLEM_SET",
    ADD_PROBLEM_TO_PS: "ADD_PROBLEM_TO_PS",
    EDIT_PROBLEM_TO_PS: "EDIT_PROBLEM_TO_PS",
    REMOVE_PROBLEM_FROM_PS: "REMOVE_PROBLEM_FROM_PS",
    CHANGE_PROBLEM_POSITION: "CHANGE_PROBLEM_POSITION",
    CHANGE_CHILD_PROBLEM_POSITION: "CHANGE_CHILD_PROBLEM_POSITION",
    PROBLEM_SET_BACK_TO_LESSON: "PROBLEM_SET_BACK_TO_LESSON",
    ASSIGN_PROBLEMS_TO_SET: "ASSIGN_PROBLEMS_TO_SET",
    PROBLEM_SET_PDF_EXPORT: "PROBLEM_SET_PDF_EXPORT",
};


const editProblem = (problem) => ({ type: TYPES.EDIT_PROBLEM_TO_PS, problem });
const deleteProblemSet = (problemSet) => ({ type: TYPES.DELETE_PROBLEM_SET, problemSet });

const addProblem = ({ problem, position, activeSetId }) => {
    return {
        type: TYPES.ADD_PROBLEM_TO_PS,
        payload:{
            problem,
            position,
            activeSetId
        }
    };
};

const deleteProblem = ({ problemId, activeProblemSet, parentProblemId }) => {
    return {
        type: TYPES.REMOVE_PROBLEM_FROM_PS,
        payload: { problemId, activeProblemSet, parentProblemId }
    };
};

const moveChildProblem = ({ parentProblemId, problemId, direction }) => {
    return {
        type: TYPES.CHANGE_CHILD_PROBLEM_POSITION,
        payload:{
            parentProblemId,
            problemId,
            direction
        }
    };
};

const moveProblem = ({ activeProblemSet, problemId, position, destination }) => {
    return {
        type: TYPES.CHANGE_PROBLEM_POSITION,
        payload:{
            activeProblemSet,
            problemId,
            position,
            destination
        }
    };
};

const onBackToLesson = (payload) => ({
    type: TYPES.PROBLEM_SET_BACK_TO_LESSON,
    payload
});

const assignProblems = ({ problemIds, activeSetId }) => {
    return {
        type: TYPES.ASSIGN_PROBLEMS_TO_SET,
        payload:{
            problemIds,
            activeSetId
        }
    };
};

const onPdfExport = (payload) => ({
    type: TYPES.PROBLEM_SET_PDF_EXPORT,
    payload
});

export {
    TYPES,
    addProblem,
    editProblem,
    deleteProblem,
    deleteProblemSet,
    moveProblem,
    moveChildProblem,
    onBackToLesson,
    assignProblems,
    onPdfExport,
};
