import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Segment, Icon } from "semantic-ui-react";
import LessonSchedule from "../../component/LessonSchedule";
import SiPiContent from "./SiPiContent";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import SiPiEditor from "./SiPiEditor";
import SiPiCloner from "./SiPiCloner";
import SiPiTestCreator from "./SiPiTestCreator";
import cx from "classnames";
import EditActionIcon from "bmd-react/dist/EditActionIcon";
import LessonUnitEditor from "./LessonUnitEditor";
import ActionLinks from "../../component/ActionLinks";
import RemoveIcon from "../../component/RemoveIcon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { arrayMove } from "react-sortable-hoc";
import PlaceholderText from "../../component/PlaceholderText";
import EditableText from "../../component/EditableText";
import { toastWarning } from "../../component/toast";

export default class IPlannerOutlineEditor extends PureComponent {
    static propTypes = {
        iPlanner: PropTypes.object,
        iPlannerSiPi: PropTypes.object,
        iPlannerReadOnlySiPi: PropTypes.object,
        onUpdateSiPiOrder: PropTypes.func,
        iPlannerId: PropTypes.string,
        onAddSiPi: PropTypes.func,
        onUpdateSiPi: PropTypes.func,
        onRemoveSiPi: PropTypes.func,
        enableSyllabusFeatures: PropTypes.bool,
        onMassAddSiPis: PropTypes.func,
        onUpdateLesson: PropTypes.func,
        onCreateLesson: PropTypes.func,
        onRemoveLesson: PropTypes.func,
        onUpdateLessonOrder: PropTypes.func,
    };
    state = {
        removeContext: null,
        removePayload: null,
        isOpenEditor: false,
        sipi: null,
        sipiId: null,
        lessonUnitId: null,
        isOpenCloner: false,
        isOpenTestCreator: false,
        isOpenLessonUnitEditor: false,
        editedLesson: null,
        removedEntity: "instrument",
    };

    handleSiPiOrdering = payload => {
        const { iPlannerId, onUpdateSiPiOrder } = this.props;
        onUpdateSiPiOrder({ ...payload, iPlannerId });
    };

    handleUpdateSiPi = payload => {
        const { iPlannerId, onUpdateSiPi } = this.props;
        onUpdateSiPi({ ...payload, iPlannerId });
    };

    handleRemoveSiPi = (e, payload) => {
        const { removePayload } = this.state;
        if (!removePayload) {
            this.setState({
                removeContext: e.currentTarget,
                removePayload: payload,
                removedEntity: "instrument",
            });
        }
    };

    handleCancelRemove = () => {
        this.setState({ removePayload: null, removeContext: null });
    };

    handleConfirmRemoveSiPi = () => {
        const { iPlannerId, onRemoveSiPi, onRemoveLesson } = this.props;
        const { removedEntity, removePayload } = this.state;
        if ("instrument" === removedEntity) {
            onRemoveSiPi({ ...removePayload, iPlannerId });
        } else {
            onRemoveLesson({ iPlannerId, lessonId: removePayload });
        }
        this.handleCancelRemove();
    };

    handleOpenEditor = (lessonUnitId, sipi = null, sipiId = null) =>
        this.setState({ isOpenEditor: true, sipi, sipiId, lessonUnitId });
    handleCloseEditor = () =>
        this.setState({
            isOpenEditor: false,
            sipi: null,
            sipiId: null,
            lessonUnitId: null
        });
    handleAddNew = lessonUnitId => this.handleOpenEditor(lessonUnitId);

    handleSaveSipi = data => {
        const { onUpdateSiPi, onAddSiPi, iPlannerId } = this.props;
        const { sipiId, lessonUnitId } = this.state;
        if (sipiId) {
            onUpdateSiPi({ iPlannerId, lessonUnitId, data, sipiId });
        } else {
            onAddSiPi({ iPlannerId, lessonUnitId, data });
        }
    };

    handleMassAddSiPi = data => {
        const { onMassAddSiPis, iPlannerId } = this.props;
        const { lessonUnitId } = this.state;
        onMassAddSiPis({ iPlannerId, lessonUnitId, data });
    };

    handleOpenCloner = lessonUnitId =>
        this.setState({ isOpenCloner: true, lessonUnitId });
    handleCloseCloner = () =>
        this.setState({ isOpenCloner: false, lessonUnitId: null });

    handleOpenTestCreator = (luId) => this.setState({ isOpenTestCreator: true,  lessonUnitId: luId });
    handleCloseTestCreator = () => this.setState({ isOpenTestCreator: false });
    handleTestCreated = ({ student, teacher }) => {
        const { onAddSiPi, iPlannerId } = this.props;
        const { lessonUnitId } = this.state;
        onAddSiPi({
            iPlannerId,
            lessonUnitId,
            data: student
        });
        onAddSiPi({
            iPlannerId,
            lessonUnitId,
            data: teacher
        });
    }

    handleEditLessonUnit = (editedLesson) => this.setState({ editedLesson, isOpenLessonUnitEditor: true });
    handleAddLesson = (e, { name }) => this.setState({ editedLesson: { unitName: name }, isOpenLessonUnitEditor: true });
    handleCloseLessonUnit = () => this.setState({ editedLesson: null, isOpenLessonUnitEditor: false });
    handleUpdateLesson = (payload) => {
        const { onUpdateLesson, iPlannerId } = this.props;
        onUpdateLesson({...payload, iPlannerId });
    }
    handleCreateLesson = (payload) => {
        const { onCreateLesson, iPlannerId } = this.props;
        onCreateLesson({ ...payload, iPlannerId });
    }
    handleRemoveLesson = (e, payload) => {
        const { removePayload } = this.state;
        if (!removePayload) {
            this.setState({
                removeContext: e.currentTarget,
                removePayload: payload,
                removedEntity: "lesson",
            });
        }
    };

    handleDragEnd = ({ source, destination }) => {
        const { onUpdateLessonOrder, iPlannerId, iPlanner } = this.props;
        const reorderedOutline = {};
        if (destination) {
            Object.keys(iPlanner.outline).map(u => {
                if (u === source.droppableId) {
                    if (u === destination.droppableId) {
                        reorderedOutline[u] = arrayMove(iPlanner.outline[u], source.index, destination.index);
                    } else {
                        let lessons = [...iPlanner.outline[u]];
                        delete lessons[source.index];
                        reorderedOutline[u] = lessons.filter(l => !!l);
                    }
                } else if (u === destination.droppableId) {
                    if (u === source.droppableId) {
                        reorderedOutline[u] = arrayMove(iPlanner.outline[u], source.index, destination.index);
                    } else {
                        const movedLesson = {...iPlanner.outline[source.droppableId][source.index], unitName: destination.droppableId };
                        let lessons = [...iPlanner.outline[u]];
                        lessons.splice(destination.index, 0, movedLesson);
                        reorderedOutline[u] = lessons;
                    }
                } else {
                    reorderedOutline[u] = iPlanner.outline[u];
                }
            });
            let lessonUnits = [];
            Object.keys(iPlanner.outline).map(u  => {
                reorderedOutline[u].map(lu => lessonUnits.push({ ...lu, index: null, name: `${u} :: ${lu.name}` }));
            });
            onUpdateLessonOrder({ lessonUnits, iPlannerId });
        }
    };

    handleRenameUnit = (name, e, { id }) => {
        const { onUpdateLessonOrder, iPlannerId, iPlanner } = this.props;
        if (iPlanner.outline[name]) {
            toastWarning({ header: "Cannot rename lesson unit", message: `Unit "${name}" already exists!` });
        } else {
            const renamedOutline = {};
            Object.keys(iPlanner.outline).map(u => {
                if (u === id) {
                    renamedOutline[name] = iPlanner.outline[u];
                } else {
                    renamedOutline[u] = iPlanner.outline[u];
                }
            });
            let lessonUnits = [];
            Object.keys(renamedOutline).map(u  => {
                renamedOutline[u].map(lu => lessonUnits.push({ ...lu, index: null, name: `${u} :: ${lu.name}` }));
            });
            onUpdateLessonOrder({ lessonUnits, iPlannerId });
        }
    }

    render() {
        const {
            iPlanner,
            iPlannerSiPi,
            iPlannerReadOnlySiPi,
            enableSyllabusFeatures,
        } = this.props;
        const { removeContext, isOpenEditor, sipi, isOpenCloner, isOpenTestCreator, isOpenLessonUnitEditor, editedLesson, removedEntity } = this.state;
        if (!iPlanner.outline) {
            return null;
        }
        const editableOutline = iPlanner && !iPlanner.uuid && !iPlanner.linked || false;
        return (
            <React.Fragment>
                <ConfirmDeleteWithContext
                    context={removeContext}
                    confirmText={`Remove ${removedEntity}`}
                    onConfirm={this.handleConfirmRemoveSiPi}
                    whatText={removedEntity}
                    onCancel={this.handleCancelRemove}
                />
                {isOpenEditor && (
                    <SiPiEditor
                        onClose={this.handleCloseEditor}
                        sipi={sipi}
                        onSave={this.handleSaveSipi}
                    />
                )}
                {enableSyllabusFeatures && (
                    <SiPiCloner
                        isOpen={isOpenCloner}
                        onClose={this.handleCloseCloner}
                        onCloneSiPis={this.handleMassAddSiPi}
                    />
                )}
                {isOpenTestCreator && <SiPiTestCreator
                    onClose={this.handleCloseTestCreator}
                    onTestCreated={this.handleTestCreated}
                />}
                {isOpenLessonUnitEditor && <LessonUnitEditor
                    lessonUnit={editedLesson}
                    onClose={this.handleCloseLessonUnit}
                    onSave={this.handleUpdateLesson}
                    onCreate={this.handleCreateLesson}
                    unitList={Object.keys(iPlanner.outline)}
                />}
                <div className="iPlannerOutlineEditor">
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                        {Object.keys(iPlanner.outline).map(u => (
                            <Droppable droppableId={u} key={u}>
                                {(provided) => (
                                    <React.Fragment >
                                        <Segment key={u} tertiary className="iPlannerOutlineUnit editable">
                                            {editableOutline ? 
                                                <div><EditableText
                                                    text={u}
                                                    id={u}
                                                    size="big"
                                                    width="350px"
                                                    onUpdate={this.handleRenameUnit}
                                                /></div>
                                                : <React.Fragment>{u}</React.Fragment>
                                            }
                                        </Segment>
                                        <div
                                            ref={provided.innerRef}
                                        >
                                            {iPlanner.outline[u].map((lu, index) => (
                                                <Draggable
                                                    key={lu.lu_id || lu.cv_lu_id}
                                                    draggableId={lu.lu_id || lu.cv_lu_id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={provided.draggableProps.style}>
                                                            <Segment
                                                                className={cx("ipoLessonUnitHeader detailed editable", { completed: !!lu.completed_date})}
                                                                attached="top"
                                                                {...lu.completed_date && { title: `Completed at ${lu.completed_date}`}}
                                                            >
                                                                <div className="ipoLUName">
                                                                    {<Icon
                                                                        name="bars"
                                                                        {...provided.dragHandleProps}
                                                                        title="Change order"
                                                                        style={{ cursor: "move", fontSize: editableOutline ? "initial" : "0", marginLeft: "-0.8em", }}
                                                                        className="actionIconClass"
                                                                    />
                                                                    }
                                                                    {lu.name}
                                                                </div>
                                                                <div className="ipoLUSchedule">
                                                                    {lu.start_week && (
                                                                        <LessonSchedule
                                                                            startWeek={lu.start_week}
                                                                            wrongSchedule={false}
                                                                            length={lu.length}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {lu.completed_date && (
                                                                        <div
                                                                            className="ipoLUCompleted"
                                                                            title={`Completed at ${lu.completed_date}`}
                                                                        >
                                                                            {lu.completed_date} <Icon name="check" color="green" />
                                                                        </div>
                                                                    )}
                                                                    {editableOutline && (
                                                                        <React.Fragment>
                                                                            <RemoveIcon
                                                                                onRemove={this.handleRemoveLesson}
                                                                                id={lu.lu_id || lu.cv_lu_id}
                                                                                entityName={`lesson ${lu.name}`}
                                                                                className="actionIconClass right"
                                                                            />
                                                                            <EditActionIcon
                                                                                style={{ float: "right" }}
                                                                                iconName="settings"
                                                                                onClick={this.handleEditLessonUnit}
                                                                                data={{ ...lu, unitName: u }}
                                                                                title="Edit the properties of the lesson"
                                                                            />
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </Segment>
                                                            <Segment
                                                                attached="bottom"
                                                                style={{ marginBottom: "1em" }}
                                                                loading={undefined === iPlannerSiPi}
                                                            >
                                                                <p title="Lesson objectives" style={{ whiteSpace: "pre-line" }}>{lu.lu_objectives || <PlaceholderText text="The objective of the lesson ... has not been described here yet." />}</p>
                                                                {undefined !== iPlannerReadOnlySiPi && (
                                                                    <SiPiContent
                                                                        iPlannerSiPi={iPlannerReadOnlySiPi}
                                                                        lessonUnit={lu}
                                                                        template
                                                                    />
                                                                )}
                                                                {undefined !== iPlannerSiPi && (
                                                                    <SiPiContent
                                                                        iPlannerSiPi={iPlannerSiPi}
                                                                        lessonUnit={lu}
                                                                        onUpdateSiPi={this.handleUpdateSiPi}
                                                                        onRemoveSiPi={this.handleRemoveSiPi}
                                                                        onUpdateSiPiOrder={this.handleSiPiOrdering}
                                                                        onEdit={this.handleOpenEditor}
                                                                        onAdd={this.handleAddNew}
                                                                        enableSyllabusFeatures={enableSyllabusFeatures}
                                                                        onOpenCloner={this.handleOpenCloner}
                                                                        onOpenTestCreator={this.handleOpenTestCreator}
                                                                        hidePlaceholder={!!iPlannerReadOnlySiPi && !!iPlannerReadOnlySiPi[`lu_${lu.lu_id || lu.cv_lu_id}`]}
                                                                    />
                                                                )}
                                                            </Segment>
                                                        </div>)}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                        {editableOutline && (
                                            <ActionLinks
                                                actions={[
                                                    {
                                                        name: u,
                                                        icon: "plus",
                                                        content: "Add lesson",
                                                        title: `Add lesson to ${u}.`,
                                                        onClick: this.handleAddLesson
                                                    },
                                                ]}
                                            />
                                        )}
                                    </React.Fragment>)}
                            </Droppable>
                        ))}
                    </DragDropContext>
                </div>
            </React.Fragment>
        );
    }
}
