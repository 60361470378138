const INCORRECT_ANSWER = "<div class=\"incorrect\"></div>";

const randomSort = () => Math.random() - 0.3;
export const MAX_SHUFFLE_RECURSION = 20;
/**
 * Randomly sorts array.
 *
 * @param {array} array Array to sort.
 * @param {object} options { currentOrder<string>, locked<array> }
 *                `currentOrder` Serialized array (comma separated). If exist, `shuffle` is called recursively
 *                 when the result of sort is the same as the current.
 *                 `locked` list of zero based unique indexes locked at their positions.
 */
export const shuffle = (array, options, depth = 0) => {
    const { currentOrder, locked } = options || {};
    depth++;

    if (depth >= MAX_SHUFFLE_RECURSION) {  // prevent stack overflow
        return array;
    }

    if (locked && locked.length + 2 > array.length) {
        // to many locked items, nothing to shuffle
        return [ ...array ];
    }

    let toShuffle = array.filter((_value, idx) => !locked || !locked.includes(idx));
    toShuffle.sort(randomSort);
    locked && locked.forEach((idx) => toShuffle.splice(idx, 0, array[idx]));

    if (toShuffle.length > 1 && currentOrder && toShuffle.join(",") === currentOrder) {
        toShuffle = shuffle(toShuffle, options);
    }

    return toShuffle;
};

export const renderTrueFalseOptions = "<div><div><b>a</b>) True</div><div><b>b</b>) False</div></div>";

export const renderTrueFalseAnswer = (problem) => ( problem.answer.content ? "<b>a</b>) True" : "<b>b</b>) False" );

export const renderMultiChoiceAnswer = (answer) => {
    const mcAnswer = answer.reduce((mc, choice) => {
        const html = `<div>${choice.content}</div>`;
        mc.options.push(html);
        mc.correct.push(choice.isCorrect ? html : INCORRECT_ANSWER);
        return mc;
    }, { options: [], correct: [] });

    mcAnswer.options = `<div class="multichoice shuffled">${mcAnswer.options.join("")}</div>`;
    mcAnswer.correct = `<div class="shuffled">${mcAnswer.correct.join("")}</div>`;
    return mcAnswer;
};

export const renderMatchingQuestion = (answers, qNum) => {
    const leftParts = [];
    const rightParts = [];
    const correct = [];

    answers.forEach((a, idx) => {
        correct.push(`<span>${idx > 0 ? ", " : ""}${qNum}</span>`);
        leftParts.push(`<div class="question"><div><b>${qNum})</b></div><div class="block">${a.content}</div></div>`);
        rightParts.push(`<div class="block">${a.matchContent}</div>`);
        qNum++;
    });

    const question = `<div class="matching">
        <div>${leftParts.join("")}</div>
        <div class="shuffled">${rightParts.join("")}</div>
    </div>`;

    const answer = `<div class="matched shuffled">${correct.join("")}</div>`;
    return [ question, answer, qNum];
};
