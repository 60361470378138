import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Card, Icon } from "semantic-ui-react";
import "./CreateNewCard.css";

export default class CreateNewCard extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
    }

    render() {
        const { onClick, disabled } = this.props;
        return (
            <Card {...!disabled && { link: true, onClick: onClick }}  >
                <Card.Content className="createNewContent">
                    <Card.Header><Icon name="plus" /><div>Create new</div></Card.Header>
                </Card.Content>
            </Card>
        );
    }
}
