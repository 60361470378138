import React from "react";
import PropTypes from "prop-types";
import { Header, Dimmer, Loader, Dropdown } from "semantic-ui-react";

import AccessControl from "../../../../auth/AccessControl";
import SourceType from "./SourceType";
import { KnownSourceTypeOptions, SourceTypeUnspecified } from "./SourceTypeUtils";

const LayoutGridStyle = {
    display: "grid",
    alignItems: "start",
    gridGap: ".5em",
    gridTemplateColumns: "1fr auto",
};

export default class ResourceSourceType extends React.PureComponent {
    static propTypes = {
        resourceId: PropTypes.number,
        current: PropTypes.object,
        options: PropTypes.array,
        editValidator: PropTypes.object,
        isSaving: PropTypes.bool,
        onUpdate: PropTypes.func,
    }

    handleChange = (e, { value:source_type_id }) => {
        const { resourceId, current, onUpdate } = this.props;
        if (current && current.id === source_type_id) {
            return;
        }
        onUpdate && onUpdate({ resourceId, source_type_id });
    }

    render() {
        const { current, options, isSaving, editValidator } = this.props;
        return (
            <Dimmer.Dimmable dimmed={isSaving}>
                <Dimmer active={isSaving} inverted>
                    <Loader>Updating source type...</Loader>
                </Dimmer>
                <Header as="h4">Source type</Header>
                <div style={LayoutGridStyle}>
                    <SourceType {...current} />
                    <AccessControl  {...editValidator}>
                        <Dropdown compact button direction="left"
                            text="Change to..."
                            title="Change source type of resource"
                            options={options || KnownSourceTypeOptions}
                            value={current && current.id || SourceTypeUnspecified}
                            onChange={this.handleChange}
                        />
                    </AccessControl>
                </div>
            </Dimmer.Dimmable>
        );
    }
}
