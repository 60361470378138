import React from "react";
import PropTypes from "prop-types";
import Widget from "../../Widget";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, getVal, isLoaded } from "react-redux-firebase";
import { Segment, Loader, Dimmer } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";

const previewSegmentStyle = { fontSize: "0.75rem", height: "40em", overflowY: "scroll" };

class WidgetPreview extends React.PureComponent {
    static propTypes = {
        widgetId: PropTypes.string,
        templateId: PropTypes.string,
        templateData: PropTypes.object,
        widgetData: PropTypes.object,
    };

    render() {
        const { widgetId, templateData, templateId, widgetData } = this.props;
        const loading = !isLoaded(templateData, widgetData);
        const failure = loading ? null : (
            !widgetData ? "Widget data not available." : (
                !templateData ? "Widget template not available." : null
            )
        );
        return (
            <Dimmer.Dimmable>
                <Dimmer active={loading} inverted>
                    <Loader inverted>Loading widget</Loader>
                </Dimmer>
                {!widgetId ? ( // no widget was selected
                    <NoDataPlaceholder text="Choose a widget first." style={previewSegmentStyle} />
                ) : failure ? ( // widget load has failed
                    <NoDataPlaceholder text={failure} style={previewSegmentStyle} />
                ) : ( // widget is fully loaded or being loaded
                    <Segment style={previewSegmentStyle}>
                        {templateId && widgetData && templateData && (
                            <Widget
                                template={{ ...templateData, key: templateId }}
                                content={widgetData && widgetData.items}
                            />
                        )}
                    </Segment>
                )}
            </Dimmer.Dimmable>
        );
    }
}

const withDataLoader = compose(
    firebaseConnect(({ templateId, widgetId }) => (
        templateId && widgetId && [
            `widgetTemplates/data/${templateId}`,
            `widgetLibrary/data/${widgetId}`,
        ]
    )),
    connect(({ firebase }, { templateId, widgetId }) => ({
        templateData: templateId && getVal(firebase, `data/widgetTemplates/data/${templateId}`),
        widgetData: widgetId && getVal(firebase, `data/widgetLibrary/data/${widgetId}`),
    }))
);

export default withDataLoader(WidgetPreview);
