import PropTypes from "prop-types";

export const UserRightsPropType = PropTypes.shape({
    canEdit: PropTypes.bool,
    canReview: PropTypes.bool,
    canPublish: PropTypes.bool,
});

export const confirmUnsave = (actionText, onSetDirty) => {
    const result = confirm(`You have unsaved changes in the text editor. ${actionText}, your changes will be lost. Are you sure?`);
    onSetDirty(!result);
    return  result;
};
