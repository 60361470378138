import { all, fork } from "redux-saga/effects";

import osmtSaga from "./ObjectiveSet/ObjectiveSetSaga";

function* rootSaga() {
    yield all([
        fork(osmtSaga),
    ]);
}

export default rootSaga;
