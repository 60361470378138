import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onLoadTags } from "../../component/ResourceLink/ResourceLinkActions";
import { isTagsLoading } from "../../component/ResourceLink/ResourceLinkSelectors";
import { loggedUserId, tenantId } from "../../../Users/UserProfile/UserProfileSelectors";
import ResourceDetail from "./ResourceDetail";
import {
    onCloseDetails,
} from "../ResourcesActions";
import {
    getSelectedResourceData,
    getSelectedResourceId,
    isLoadingDetail,
} from "../ResourcesSelectors";
import { isSavingTags } from "../components/ResourceTagsEditor/ResourceTagsEditorSelectors";

const mapStateToProps = (state, props) => ({
    resourceData: getSelectedResourceData(state, props),
    resourceId: getSelectedResourceId(state, props),
    isTagsLoading: isTagsLoading(state),
    isSavingTags: isSavingTags(state),
    userId: loggedUserId(state),
    tenantId: tenantId(state),
    isLoadingDetail: isLoadingDetail(state),
});

const dispatchToProps = dispatch => bindActionCreators({
    onLoadTags,
    onCloseDetails,
}, dispatch);

export default connect(mapStateToProps, dispatchToProps)(ResourceDetail);
