import React from "react";
import { Button, Menu } from "semantic-ui-react";

const SPORKUrl = "https://spork.tech";

/**
 * Renders a button which opens SPORK website at <https://spork.tech> in a new tab.
 *
 * @export
 * @class AboutSporkButton
 * @extends {React.PureComponent}
 */
export class AboutSporkButton extends React.PureComponent {
  
    handleLearnAboutClick = () => {
        window.open(SPORKUrl, "_blank");
    }

    render() {
        return (<Button content="Learn about SPORK..." onClick={this.handleLearnAboutClick}/>);
    }
}

/**
 * Renders a menu link which opens SPORK website at <https://spork.tech> in a new tab.
 *
 * @export
 * @class AboutSporkMenuItem
 * @extends {React.PureComponent}
 */
export class AboutSporkMenuItem extends React.PureComponent {
  
    handleLearnAboutClick = () => {
        window.open(SPORKUrl, "_blank");
    }

    render() {
        return (<Menu.Item onClick={this.handleLearnAboutClick}>Learn about SPORK...</Menu.Item>);
    }
}
