import { createSelector } from "reselect";
import { getTopics } from "../Topics/topicsSelectors";

export const getSelectedCollection = state => state.ttt.belaTopicCollection.selectedCollection;
const getSelectedTopic = (state, props) => props.selectedTopic;

// filter SPORK topics and return those linked to selected BeLA topic
export const getLinkedTopics = createSelector([getTopics, getSelectedTopic], (topics, selectedTopic) => {
    let filtered = [];
    if (topics == null) return filtered;
    Object.keys(topics).map(key => {
        if (topics[key].bela_topic_id && topics[key].bela_topic_id[selectedTopic.topic_id]) {
            filtered.push(topics[key]);
        }
    });
    return filtered;
});

// filter SPORK topics and return those NOT-linked to selected BeLA topic
export const getNotLinkedTopics = createSelector([getTopics, getSelectedTopic], (topics, selectedTopic) => {
    let filtered = [];
    if (topics == null) return filtered;
    Object.keys(topics).map(key => {
        if (!topics[key].bela_topic_id || !topics[key].bela_topic_id[selectedTopic.topic_id]) {
            filtered.push({key: key, topic: topics[key]});
        }
    });
    return filtered;
});
