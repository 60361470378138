import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import { insertSegment } from "../utils/utils";
import { getContextPropsForPopup } from "../utils/domUtils";
import { ENTITY_TYPE } from "../constants";
import SnippetMenu from "../../../../KitBuilder/Snippets/SnippetMenu";

class SnippetPopup extends React.Component {

    static propTypes = {
        contextNode: PropTypes.instanceOf(Element).isRequired,
        snippets: PropTypes.array,
        editorState: PropTypes.object.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    state = {
        isOpened: false,
        contextProps: {},
    };

    componentDidMount = () => {
        const { contextNode } = this.props;
        const contextProps = getContextPropsForPopup(contextNode);

        this.setState({
            isOpened: true,
            contextProps,
        });
    };

    handleSelect = (snippetKey, snippetCfg) => {
        const { editorState, setEditorState, onClose } = this.props;
        const newEditorState = insertSegment(editorState, ENTITY_TYPE.SNIPPET, { snippetKey, snippetCfg });
        setEditorState(newEditorState);
        onClose(true);  // focus snippet editor
    };

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const { snippets } = this.props;
        const { isOpened, contextProps } = this.state;
        const { node, pos, hOffset, vOffset } = contextProps;

        if (!isOpened) {
            return null;
        }

        return (
            <Popup
                context={node}
                position={pos}
                horizontalOffset={hOffset}
                verticalOffset={vOffset}
                open={true}
                onClose={this.handleClose}
            >
                <SnippetMenu snippets={snippets} onSelect={this.handleSelect} />
            </Popup>
        );
    }
}

export default SnippetPopup;
