const TYPES = {
    TS_SEARCH_CHANGED: "TopicSelector/SEARCH_CHANGED",
    TS_SHOW_ONLY_SELECTED: "TopicSelector/SHOW_ONLY_SELECTED",
    TS_SELECT_COLLECTION: "TopicSelector/SELECT_COLLECTION",
};

const onSearchChanged = payload => ({
    type: TYPES.TS_SEARCH_CHANGED,
    payload,
});

const onShowOnlySelected = payload => ({
    type: TYPES.TS_SHOW_ONLY_SELECTED,
    payload,
});

const onSelectCollection = payload => ({
    type: TYPES.TS_SELECT_COLLECTION,
    payload,
});

export { TYPES, onSearchChanged, onShowOnlySelected, onSelectCollection };
