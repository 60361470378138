import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { onLoadSchool } from "./SchoolListItemActions";
import { getSchool } from "./SchoolListItemSelectors";
import SchoolListItem from "./SchoolListItem";


const mapStateToProps = function(state, props) {
    return {
        school: getSchool(state, props),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { 
            onLoadSchool,
        },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(SchoolListItem);
