import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Icon } from "semantic-ui-react";

export default class AddRole extends PureComponent {
    static propTypes = {
        roleOptions: PropTypes.array,
        onAddRole: PropTypes.func,
        centerForm: PropTypes.bool,
    };

    state = {
        selectedRole: null,
    }

    handleSelectRole = (e, { value }) => {
        e.stopPropagation();
        this.setState({ selectedRole: value });
    }
    
    handleAddRole = (e) => {
        e.preventDefault && e.preventDefault();
        const { selectedRole } = this.state;
        this.props.onAddRole(selectedRole);
        this.setState({ selectedRole: null });
    }

    render() {
        const { roleOptions, centerForm } = this.props;
        const { selectedRole } = this.state;
        return (
            <Form onSubmit={this.handleAddRole}>
                <Form.Group {...centerForm && {style: {justifyContent: "center"}}}>
                    <Form.Dropdown
                        required
                        icon={<Icon name="dropdown" style={{fontSize: "inherit"}} />}
                        placeholder="Select user access role"
                        options={roleOptions}
                        selection
                        value={selectedRole}
                        onChange={this.handleSelectRole}
                    />
                    <Form.Button primary disabled={!selectedRole} onClick={this.handleAddRole}>Add role</Form.Button>
                </Form.Group>
            </Form>
        );
    }
}
