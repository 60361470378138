import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon, Checkbox } from "semantic-ui-react";
import "./TopicTreeNode.scss";
import cx from "classnames";
import { normalizeTopicKey } from "../../utils/selectorUtils";

const getTreeNodeTooltip = (isUsed, notAvailable, titleText, titleFunc, onChange) => (
    titleFunc && titleFunc(isUsed, notAvailable, titleText) || (
        isUsed ? "Already assigned topic" : (
            notAvailable && onChange ? "This topic is not available for assignment" : !onChange && notAvailable ? "Not assigned topic" : titleText
        )
    )
);

export default class TopicTreeNode extends PureComponent {
    static propTypes = {
        treeNode: PropTypes.object,
        onExpand: PropTypes.func,
        onChange: PropTypes.func,
        single: PropTypes.bool,
        selectedTopics: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        selectedCollection: PropTypes.string,
        usedTopicIds: PropTypes.object,
        firstChild: PropTypes.bool,
        filterOnly: PropTypes.object,
        getTooltip: PropTypes.func,
    }

    handleToggleExpand = (e) => {
        const { onExpand, treeNode } = this.props;
        e.stopPropagation();
        onExpand({ node: treeNode.key, expanded: !treeNode.expanded});
    }

    handleToggleTopic = (e) => {
        e.stopPropagation();
        const { selectedTopics, selectedCollection, onChange, single, usedTopicIds, filterOnly } = this.props;
        const id = e.currentTarget.id;
        if (id !== selectedCollection) {
            let topicId = normalizeTopicKey(selectedCollection, id);
            if (single) {
                if (onChange) {
                    onChange(topicId);
                }
            } else if (!usedTopicIds[topicId] && (!filterOnly || filterOnly[topicId])) {
                if (!selectedTopics[topicId]) {
                    selectedTopics[topicId] = true;
                } else {
                    delete selectedTopics[topicId];
                }
                if (onChange) {
                    onChange(selectedTopics);
                }
            }
        }
    };

    render() {
        const { treeNode, onExpand, onChange, selectedTopics, selectedCollection, single, usedTopicIds, firstChild, filterOnly, getTooltip } = this.props;
        const { title, subtitle, expanded, children, rootNode } = treeNode;
        const combinedTopicKey = normalizeTopicKey(selectedCollection, treeNode.key);
        const usedTopic = usedTopicIds && usedTopicIds[combinedTopicKey] || false;
        const notAllowed = filterOnly && !filterOnly[combinedTopicKey] || false;
        const selected = usedTopic || (single && selectedTopics === combinedTopicKey) || !!(selectedTopics && selectedTopics[combinedTopicKey]);
        return (
            <div 
                className={cx({leftPadding: !rootNode && !firstChild})}
                id={treeNode.key}
                onClick={this.handleToggleTopic}
                title={getTreeNodeTooltip(usedTopic, notAllowed, title, getTooltip, onChange)}
            >
                <div className={
                    cx({
                        newSingleTopicItem: onChange && single && !rootNode,
                        active: selected,
                    })}
                >
                    {rootNode ? (
                        <b>{title}</b>
                    ) : (
                        <React.Fragment>
                            {children && (
                                <Icon name={expanded ? "caret down" : "caret right"} link onClick={this.handleToggleExpand}/>
                            )}
                            {single ? (
                                <span className={cx({smallLeftPadding: !children, newSingleTopicItem: onChange && single && !rootNode})}>
                                    {!onChange && selected && <span className="active readonly">&#10004; </span>}
                                    {title}
                                </span>
                            ) : (
                                <Checkbox
                                    name={treeNode.key}
                                    size="small"
                                    label={title}
                                    className={cx({smallLeftPadding: !children})}
                                    disabled={usedTopic || notAllowed}
                                    checked={selected}
                                />
                            )}
                        </React.Fragment>
                    )}
                    {subtitle && (
                        <div className={children ? "leftPadding" : "smallLeftPadding"}>
                            <small className="limitedTextWidth" title={subtitle}>{subtitle}</small>
                        </div>
                    )}
                </div>
                {children && expanded && children.map(child => (
                    <TopicTreeNode
                        key={child.key}
                        firstChild={!!rootNode || false}
                        treeNode={child}
                        onExpand={onExpand}
                        onChange={onChange}
                        selectedTopics={selectedTopics}
                        selectedCollection={selectedCollection}
                        single={single}
                        usedTopicIds={usedTopicIds}
                        filterOnly={filterOnly}
                        getTooltip={getTooltip}
                    />
                ))}
            </div>
        );
    }
}
