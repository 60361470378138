import draftToHtml from "draftjs-to-html";

import { ENTITY_TYPE, REVIEW_OPTIONS } from "../bookEditor/constants";
import { getSnippetToggleButtonHTML, getSnippetBlockContentHTML } from "../../../KitBuilder/Snippets/SnippetUtils";
import { LIST_NUMBERING_TYPE, LIST_PROPERTIES } from "../bookEditor/constants";

const RE_NUMBERING_TYPE = new RegExp(LIST_PROPERTIES.NUMBERING_TYPE + ":([-\\w]+)");

export const formatReview = (data, showComment = false) => {
    const { originalHtml, html, comment, reviewMetaData } = data;
    const insertAsBlock = reviewMetaData && reviewMetaData[REVIEW_OPTIONS.INSERT_AS_BLOCK];
    const element = insertAsBlock ? "div" : "span";
    const removedHtml = originalHtml ? `<span class="spork review review-remove">${originalHtml}</span>` : "";
    const commentIcon = comment ? "<span class=\"spork review review-comment\"></span>" : "";
    const insertedHtml = (html || insertAsBlock) ? `<${element} class="spork review review-insert">${html || ""}</${element}>` : "";
    return showComment ? (
        `<span class="spork review" title="${comment}">${removedHtml}${insertedHtml} <div /><em>${comment}</em></span>`
    ) : (
        `<span class="spork review" title="${comment}">${removedHtml}${insertedHtml}${commentIcon}</span>`
    );
};

export const entityToHTML = (entity, text) => {
    const { type, data } = entity;

    switch (type) {
        case ENTITY_TYPE.MATH: {
            let { svg } = data;

            if (svg.indexOf("wrs:baseline") > 0) {
                try {
                    const dummyEl = document.createElement("div");
                    dummyEl.innerHTML = svg;
                    const svgEl = dummyEl.firstChild;

                    if (!svgEl.style.verticalAlign) {
                        const height = svgEl.getAttribute("height");
                        const baseline = svgEl.getAttribute("wrs:baseline");
                        svgEl.style.verticalAlign = parseFloat(baseline) - parseFloat(height) + "px";
                    }
                    svg = svgEl.outerHTML;
                }
                catch (e) {
                    // eslint-disable-next-line no-console
                    console.error("ExportToHtml warning: Converting 'wrs:baseline' to 'vertical-align' failed!", e);
                }
            }

            return svg;
        }

        case ENTITY_TYPE.SNIPPET: {
            const { snippetKey, snippetCfg } = data;
            return (
                getSnippetToggleButtonHTML(snippetKey, snippetCfg) +
                getSnippetBlockContentHTML(snippetKey, snippetCfg, data.html)
            );
        }
        case ENTITY_TYPE.TABLE: {
            const tableData = data.tableData;
            const table = tableData.map(rowData => {
                const row = rowData.map(cellData => `<td>${cellData.html}</td>`);
                return `<tr>${row.join("")}</tr>`;
            });
            return `<table class="sporkTable ${data.tableStyle} ${data.tableAlign}"><tbody>${table.join("")}</tbody></table>`;
        }

        case ENTITY_TYPE.IMAGE: {
            const { src, width, height, alignment = "none" } = data;
            const cls = alignment === "none" ? "center" : alignment; // "none" is used by react-draft-wysiwyg
            return `<span class="sporkImage ${cls}"><img src="${
                src instanceof Object ? `spork://${encodeURIComponent(src.storagePath)}?alt=media` : src
            }" width="${width}" height="${height}"/></span>`;
        }

        case ENTITY_TYPE.INLINE_IMAGE: {
            const { src, displayWidth, displayHeight } = data;
            return `<img src="${`spork://${encodeURIComponent(src.storagePath)}?alt=media`}"
                width="${displayWidth}" height="${displayHeight}"
            />`;
        }

        case ENTITY_TYPE.GLOSSARY: {
            const { key, name } = data;
            return `<span class="spork glossary-term"
                title="${name}" data-term-key="${key}" data-term-name="${name}"
                onClick="return spork.events.onGlossaryTermClick(event);"
                >${text}</span>`;
        }

        case ENTITY_TYPE.LESSON_LINK: {
            const { outlineId, lessonId, name } = data;
            return `<a style="cursor: pointer;"
                title="Link to ${name}" data-outline-id="${outlineId}" data-lesson-id="${lessonId}"
                onClick="return spork.events.onLessonLinkClick(event);"
                >${text}</a>`;
        }

        case ENTITY_TYPE.TABLE_READONLY: {
            const { tableContent } = data;
            return tableContent;
        }

        case ENTITY_TYPE.REVIEW: {
            return formatReview(data);
        }

        default:
            return null;
    }
};

/**
 * In-place modification of entityMap for TABLE entities.
 * Attribute `tableAlign` is set according to atomic block data `text-align`.
 *
 * @param {RawDraftContentState} rawContentState
 */
const updateTableAlignment = (rawContentState) => {
    const { blocks, entityMap } = rawContentState;
    blocks.forEach(({ type, entityRanges, data }) => {
        if (type === "atomic" && entityRanges.length === 1 && data) {
            const entity = entityMap[entityRanges[0].key];
            if (entity.type === ENTITY_TYPE.TABLE) {
                entity.data.tableAlign = data["text-align"] || "";
            }
        }
    });
};

/**
 * `draftToHtml` creates <OL><LI />...</OL> html structure as default decimal numbered list.
 * We stores the required numbering type into contentBlock ("type": "ordered-list-item") data property,
 * which is propagated to `style` property by `getBlockStyle` (see draftjs-to-html package).
 * So html looks like:
 *
 * <ol>
 *      <li style="numberingType:lower-alpha;">AAA</li>
 *      <li style="numberingType:lower-alpha;">BBB</li>
 * </ol>
 *
 * We have to traverse all <LI> nodes and set appropriate style to their parent <OL> node.
 * Unordered list (<UL>) are processed the same way.
 */
const updateOrderedListNumbering = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const body = doc.body;

    const orderedLists = Array.from(body.getElementsByTagName("OL"));
    const unorderedLists = Array.from(body.getElementsByTagName("UL"));
    const allLists = orderedLists.concat(unorderedLists);

    for (const list of allLists) {
        const children = list.children;
        const usedNumberingTypes = new Set();

        let numberingType = list.tagName === "OL" ? LIST_NUMBERING_TYPE.DECIMAL : LIST_NUMBERING_TYPE.DISC;  // default

        for (const child of children) {

            if (child.tagName !== "LI") {
                continue;
            }

            const style = child.getAttribute("style");

            if (style && style.indexOf(LIST_PROPERTIES.NUMBERING_TYPE) !== -1) {
                const matches = style.match(RE_NUMBERING_TYPE);

                if (matches) {
                    numberingType = matches[1];
                }
            }

            usedNumberingTypes.add(numberingType);
        }

        if (usedNumberingTypes.size !== 1) {
            // eslint-disable-next-line no-console
            console.error("[ERROR} OrderedList mismatch!", "Different numbering types within one <OL/UL> node:", usedNumberingTypes);
        }

        list.style.listStyleType = numberingType;
    }

    return body.innerHTML;
};

const exportToHtml = (rawContentState) => {
    if (!rawContentState) {
        return "";
    }

    updateTableAlignment(rawContentState);

    let html = draftToHtml(
        rawContentState,
        null, // hashtagConfig,
        false, //directional,
        entityToHTML
    );

    html = updateOrderedListNumbering(html);
    return html;
};

export default exportToHtml;
