import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Dimmer, Loader, Header, Segment } from "semantic-ui-react";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";
import RemoveIcon from "../../../../component/RemoveIcon";
import Topic from "../../../../TopicCollection/Topic";
import ConfirmDeleteWithContext from "../../../../component/ConfirmDeleteWithContext";
import "./ResourceTopicsEditor.scss";
import TopicTreeSelector from "../../../../TopicCollection/TopicTreeSelector";

export default class ResourceTopicsEditor extends PureComponent {
    static propTypes = {
        updating: PropTypes.bool,
        topics: PropTypes.array,
        onClose: PropTypes.func,
        name: PropTypes.string,
        topicIds: PropTypes.object,
        onRemoveTopic: PropTypes.func,
        onAddTopics: PropTypes.func,
    }

    state = {
        selectedTopic: {},
        removeContext: null,
        removeTopicId: null,
    };

    handleAssignTopic = () => {
        const { onAddTopics } = this.props;
        const { selectedTopic } = this.state;
        onAddTopics(selectedTopic);
        // Object.keys(selectedTopic).map(topic => onAddTopics(topic));
        this.setState({ selectedTopic: {} });
    };

    handleSelectTopic = topic => this.setState({ selectedTopic: { ...topic } });

    handleRemove = (e, id) => {
        const { removeTopicId } = this.state;
        if (!removeTopicId) {
            this.setState({
                removeContext: e.currentTarget,
                removeTopicId: id,
            });
        }
    };

    handleRemoveConfirm = () => {
        const { onRemoveTopic } = this.props;
        const { removeTopicId } = this.state;
        onRemoveTopic(removeTopicId);
        this.handleCancelRemove();
    };

    handleCancelRemove = () => {
        this.setState({ removeTopicId: null, removeContext: null });
    };

    render() {
        const { updating, topics, onClose, name, topicIds } = this.props;
        const { removeContext, selectedTopic } = this.state;

        const count = topics ? topics.length : 0;
        return (
            <Modal
                onClose={this.handleClose}
                open={true}
                closeOnDimmerClick
                closeOnEscape
            >
                <Modal.Header>Resource topic assignment</Modal.Header>
                <Modal.Content>
                    <Dimmer.Dimmable dimmed={updating}>
                        <Dimmer active={updating} inverted>
                            <Loader>Updating topics data...</Loader>
                        </Dimmer>
                        <ConfirmDeleteWithContext
                            context={removeContext}
                            confirmText="Unassign"
                            onConfirm={this.handleRemoveConfirm}
                            whatText="topic from this resource"
                            onCancel={this.handleCancelRemove}
                        />
                        <TopicTreeSelector
                            onChange={this.handleSelectTopic}
                            selectedTopics={selectedTopic}
                            usedTopicIds={topicIds}
                        />
                        <div style={{ textAlign: "center" }}>
                            <Button
                                icon="arrow down"
                                labelPosition="left"
                                content="Assign selected topics to resource"
                                title="Assign topic to resource"
                                disabled={!selectedTopic || 0 == Object.keys(selectedTopic).length}
                                onClick={this.handleAssignTopic}
                            />
                        </div>
                        <span style={{float: "right"}}><b>{count}</b> <span style={{color: "grey"}}> assigned topic
                            {(0 == count || 1 < count) && "s"}
                        </span></span>
                        <Header style={{marginTop: 0}}><span className="sporkPrimaryColorCharcoalGray">{name}</span></Header>
                        {topics && topics.length ? (
                            <Segment style={{ minHeight: "150px", height: "150px", overflowY: "auto"}}>
                                {topics.map(topic => (
                                    <div
                                        key={topic.key}
                                        className={"resourceTopicItem actionIcons "}
                                    >
                                        <Topic topic={topic.value} />
                                        <RemoveIcon
                                            onRemove={this.handleRemove}
                                            id={topic.key}
                                            entityName={`topic ${topic.value.name}`}
                                        />
                                    </div>
                                ))}
                            </Segment>
                        ) : (
                            <NoDataPlaceholder
                                text="Resource has no topics."
                                style={{ minHeight: "150px", height: "150px" }}
                            />
                        )}
                    </Dimmer.Dimmable>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={onClose}>
                    Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
