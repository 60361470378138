import { TYPES } from "./SiPiTemplatesListActions";

const initialState = {
    searchFilter: "",
    moduleFilter: [],
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.SIPI_TEMPLATES_LIST_SET_FILTER:
            return { ...state, searchFilter: payload };
        case TYPES.SIPI_TEMPLATES_LIST_SET_MODULE_FILTER:
            return { ...state, moduleFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
