import { createSelector } from "reselect";
import { mapCollectionToList, compareByOrdering, compareByType, compareByNameCI } from "../../../utils/selectorUtils";

const OBJECTIVE_TYPE_UNKNOWN = "Unknown";

const transformObjectiveSet = data => {

    if (!data) {
        return data;
    }

    let orderedObjectives = [];
    const objectivesObject = {};
    const maxOrderingObject = {};

    if (data.objectives) {
        // sort Objectives by Type first
        orderedObjectives = Object.keys(data.objectives).map(objective_id => {
            return { id: objective_id, ...data.objectives[objective_id]};
        }).sort(compareByType);
        // put Objectives to arrays by Type (particular Objective Types are objectivesObject's properties)
        // and get max. values (for each Type) used in "ordering"
        orderedObjectives.forEach(item => {
            const itemType = item.type || OBJECTIVE_TYPE_UNKNOWN;
            if (!objectivesObject[itemType]) {
                objectivesObject[itemType] = [];
                maxOrderingObject[itemType] = 0;
            }
            if (item.ordering && item.ordering > maxOrderingObject[itemType])
                maxOrderingObject[itemType] = item.ordering;
            objectivesObject[itemType].push(item);
        });
        // fill missing ordering values putting these items to the end of Objectives list (within Objective Type)
        // and sort Objectives of each Type by ordering
        Object.keys(objectivesObject).map(objectiveType => {
            objectivesObject[objectiveType] = objectivesObject[objectiveType].map(item => (
                { ordering: (item.ordering === undefined ? ++maxOrderingObject[objectiveType] : item.ordering), ...item }
            ));
            objectivesObject[objectiveType].sort(compareByOrdering);
        });
    }
    return { id: data.key, ...data, objectivesObject };
};

const transformObjectiveSets = data => data && mapCollectionToList(data).map(item => transformObjectiveSet(item)).sort(compareByNameCI);

const transformOiiObjectives = data => data && mapCollectionToList(data).map(item => {
    return { id: item.key, ...item };
}).sort(compareByNameCI);


const getObjectiveSets = state => state.firebase.data.objective_sets;
export const getObjectiveSetsAsArray = createSelector(getObjectiveSets, transformObjectiveSets);

const getObjectiveSetById = (state, objectiveSetId) => (state.firebase.data.objective_sets && state.firebase.data.objective_sets[objectiveSetId] && { key: objectiveSetId, ...state.firebase.data.objective_sets[objectiveSetId] });
export const getSelectedObjectiveSet = createSelector(getObjectiveSetById, transformObjectiveSet);

const getOiiObjectives = state => state.firebase.data.oii_objectives;
export const getOiiObjectivesAsArray = createSelector(getOiiObjectives, transformOiiObjectives);

export const getSelectedObjectiveSetId = state => state.osmt.objectiveSet.selectedObjectiveSetId;