import { TYPES } from "./SchoolCoursesActions";

const initialState = {
    isLoadingCourses: false,
    courses: null,
    searchFilter: "",
    isLoadingDetails: false,
    courseDetails: null,
    selectedSectionId: null,
    schoolFilter: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case TYPES.SECTION_GET_SCHOOL_COURSES:
            return { ...state, isLoadingCourses: true };
        case TYPES.SECTION_SET_SCHOOL_COURSES:
            return { ...state, isLoadingCourses: false, courses: payload };
        case TYPES.SECTION_SET_FILTER: 
            return { ...state, searchFilter: payload };
        case TYPES.SECTION_SELECT_COURSE:
            return { ...state, isLoadingDetails: true, courseDetails: null };
        case TYPES.SECTION_SET_DETAILS:
            return { ...state, isLoadingDetails: false, courseDetails: payload };
        case TYPES.SECTION_SELECT_SECTION:
            return { ...state, selectedSectionId: payload };
        case TYPES.SECTION_SET_SCHOOL_FILTER:
            return { ...state, schoolFilter: payload };
        default:
            return state;
    }
};
