const TYPES = {
    LOAD_SCHOOL_LIST: "School/LOAD_LIST",
    SET_SCHOOL_LIST: "School/SET_SCHOOL_LIST",
    LOAD_SCHOOL: "School/LOAD_SCHOOL",
    SET_SCHOOL: "School/SET_SCHOOL",
};

const onSchoolLoad = () => {
    return {
        type: TYPES.LOAD_SCHOOL_LIST,
    };
};

const setSchooList = payload => {
    return {
        type: TYPES.SET_SCHOOL_LIST,
        payload,
    };
};

const onSingleSchoolLoad = (payload) => {
    return {
        type: TYPES.LOAD_SCHOOL,
        payload
    };
};

const onSetSingleSchool = payload => {
    return {
        type: TYPES.SET_SCHOOL,
        payload,
    };
};


export { TYPES, onSchoolLoad, setSchooList, onSetSingleSchool, onSingleSchoolLoad };
