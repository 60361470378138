import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BookEditor from "../../component/seamlessEditor/bookEditor/BookEditor";
import styles from "./QuestionItemEditor.module.scss";
import { swallowEvent } from "../../component/seamlessEditor/bookEditor/utils/utils";

const editorFeatures = [
    "BOLD",
    "ITALIC",
    "UNDERLINE",
    "NORMAL",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "QUOTE",
    "CODE",
    "UL",
    "OL",
    "MATH",
    "MATHTEXT",
    "TABLE",
    "TEXTALIGN",
    "FONT",
    "FONTSIZE",
    "IMG",
    "COLOR",
    "LESSON_LINK",
    "HELP",
    "REVIEW",
];

class QuestionItemEditor extends PureComponent {
    static propTypes = {
        editorId: PropTypes.string,
        optionId: PropTypes.string,
        initialContent: PropTypes.object,
        initialHtml: PropTypes.string,
        focusOnMount: PropTypes.bool,
        sharedBar: PropTypes.object.isRequired,
        onSetRef: PropTypes.func,
        regContentFn: PropTypes.func.isRequired,
        unregContentFn: PropTypes.func,
        reviewMode: PropTypes.bool,
        onDirty: PropTypes.func,
    };

    state={}

    constructor(props) {
        super(props);
    }

    componentWillUnmount(){
        const { unregContentFn, editorId } = this.props;
        unregContentFn && unregContentFn(editorId);
    }

    handleSetEditorRef = (editorRef) => {
        const { editorId, optionId, onSetRef } = this.props;
        this.editorRef = editorRef;
        onSetRef && onSetRef(editorId, optionId, editorRef);
    };

    registerGetContentFn = (fn) => {
        const { editorId, optionId } = this.props;
        this.props.regContentFn(editorId, optionId, fn);
    };

    handleFocusEditor = (e) => {
        swallowEvent(e);
        this.editorRef && this.editorRef.setFocus();
    };


    render() {
        const { initialContent, initialHtml, focusOnMount, sharedBar, editorId, reviewMode, onDirty } = this.props;
        return (
            <div className={styles["editor-wrapper"]} onClick={this.handleFocusEditor}>
                <BookEditor
                    wrapperId={editorId}
                    sharedToolbar={sharedBar}
                    features={editorFeatures}
                    initialContentState={initialContent}
                    initialHtml={initialHtml}
                    focusOnMount={focusOnMount}
                    onSetEditorRef={this.handleSetEditorRef}
                    registerGetContentFn={this.registerGetContentFn}
                    reviewMode={reviewMode}
                    onDirty={onDirty}
                />
            </div>
        );
    }
}


QuestionItemEditor.defaultProps = {
    focusOnMount: true,
    noToolbar: false,
    optionId: "",
};


export default QuestionItemEditor;
