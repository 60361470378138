import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import WidgetItemEditor from "./WidgetItemEditor";
import { orderedByPosition } from "../../Widget";
import WidgetTextItemEditor from "./WidgetTextItemEditor";
import QuestionItemEditor from "./QuestionItemEditor";

export default class WidgetEditorBody extends React.PureComponent {
    static propTypes = {
        widget: PropTypes.object.isRequired,
        onCloseItemEditor: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
        toolbarRef: PropTypes.object,
        canEdit: PropTypes.bool,
        canReview: PropTypes.bool,
        registerGetContentFn: PropTypes.func.isRequired,
        asReview: PropTypes.bool,
        checkFirstText: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
    };

    render() {
        const {
            widget,
            onCloseItemEditor,
            onDirty,
            toolbarRef,
            canEdit,
            canReview,
            registerGetContentFn,
            asReview = false,
            checkFirstText,
            onFocus
        } = this.props;

        return (
            <Segment.Group className="spork widget-editor-body">
                {orderedByPosition(widget.template.items).map(({ key, value }) => {
                    const content = widget.items && widget.items[key];
                    return "text" === value.type ? (
                        <WidgetTextItemEditor
                            itemId={key}
                            widgetId={widget.key}
                            key={key}
                            template={{ ...value, id: key }}
                            content={content}
                            toolbarRef={toolbarRef}
                            canReview={canReview}
                            canEdit={canEdit}
                            registerGetContentFn={registerGetContentFn}
                            reviewMode={asReview}
                            focusOnMount={checkFirstText()}
                            onDirty={onDirty}
                            onFocus={onFocus}
                        />
                    ) : "question" === value.type ? (
                        <QuestionItemEditor
                            key={key}
                            itemId={key}
                            widgetId={widget.key}
                            template={{ ...value, id: key }}
                            content={content}
                            tags={widget.tags && Object.keys(widget.tags) || []}
                            canEdit={canEdit}
                            reviewMode={asReview}
                            // Questions within QuestionEditor are numbered for consistency with ProblemSet rendering,
                            // but here is still the same initial offset 1 for simplicity.
                            // It would require to implement some questionOffset for proper continuous numbering (and it should
                            // cover case with more widgets with questions and their reordering by D&D) - it could be expensive.
                            // Another solution could be to suppress numbering for questions in widget (except multi-choice questions).
                            orderNum={1}
                        />
                    ) : (
                        <WidgetItemEditor
                            itemId={key}
                            widgetId={widget.key}
                            key={key}
                            template={{ ...value, id: key }}
                            content={content}
                            onCloseItemEditor={onCloseItemEditor}
                            canEdit={canEdit}
                            reviewMode={asReview}
                        />
                    );
                })}
            </Segment.Group>
        );
    }
}
