import React from "react";
import PropTypes from "prop-types";
import { Label, Dimmer, Loader } from "semantic-ui-react";

import { PublishedLessonProps, LessonVersionProps } from "../PublisherProps";
import ActionLinks from "../../../component/ActionLinks";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import firebase from "../../../firebase";
import ActionLink from "../../../component/ActionLink";

function watchForRedirects(event) {
    if (event.origin.includes("storage.googleapis.com") && event.data.redirect) { 
        window.open(event.data.redirect, "_blank");
    }
}

const EntityToFieldMapping = {
    homework: "hw",
    problem_sets: "ps",
    content: "content",
    preview: "content",
};

export default class LessonReleaseContentView extends React.PureComponent {
    static propTypes = {
        lessonId: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        outlineId: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        /** Data from "/outlines_published/{outlineId}/lessons/{lessonId}"" */
        release: LessonVersionProps,
        /** Data from "/outlines_published/{outlineId}/release/{lessonId}"" */
        releaseContent: PublishedLessonProps,
        loading: PropTypes.bool,
        entity: PropTypes.string,
        // ESLint false positive: `selectedProblemSetId` prop used in `getUrlForContent`
        // eslint-disable-next-line react/no-unused-prop-types
        selectedProblemSetId: PropTypes.string,
    };

    state = {
        viewScope: "teacher",
        url: null,
    };

    componentDidMount() {
        // allow embedded iframe to send messages to us
        window.addEventListener("message", watchForRedirects);
    }

    componentWillUnmount() {
        window.removeEventListener("message", watchForRedirects);
    }

    static getDerivedStateFromProps(props, state) {
        const url = LessonReleaseContentView.getUrlForContent(state.viewScope, props);
        return url === state.url ? null : { url };
    }

    static getUrlForContent(scope, props) {
        const { entity, releaseContent, selectedProblemSetId } = props;
        if (!releaseContent) {
            return null;
        }

        const entityField = entity && EntityToFieldMapping[entity];
        const contentData = entityField && releaseContent[entityField];
        if (!contentData) {
            return null;
        } else if (entityField === "hw" || entityField === "ps") {
            const psetData = selectedProblemSetId && contentData[selectedProblemSetId];
            const location = psetData && psetData[scope] || null;
            return location && firebase.getFileURL(location);
        } else {
            const location = contentData[scope] || null;
            return location && firebase.getFileURL(location);
        }
    }

    handleScopeAction = (e, data) => {
        const { name } = data;
        this.setState({
            viewScope: name === "teacher" || name === "student" ? name : null
        });
    };

    handleNewWindow = () => {
        const { url } = this.state;
        window.open(url, "_blank");
    }

    render() {
        const { loading, release, releaseContent, entity } = this.props;
        const { viewScope, url } = this.state;
        const content = release && releaseContent && releaseContent.content;
        const actions = [
            { name: "teacher", content: "As Teacher", active: viewScope === "teacher" },
            { name: "student", content: "As Student", active: viewScope === "student" }
        ];

        return (
            <Dimmer.Dimmable dimmed={loading}>
                <Dimmer active={loading}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <div style={{marginTop: "1em"}}>
                    <Label style={{ display: "flex" }}>
                        <ActionLinks
                            horizontal
                            caption="VIEW"
                            actions={actions}
                            onClick={this.handleScopeAction}
                            disabled={!content}
                        />
                        {url && (
                            <ActionLink
                                horizontal 
                                style={{marginLeft: "auto", order: 2}}
                                icon="external"
                                content="Open in new window"
                                title="Open the page in separate new window"
                                onClick={this.handleNewWindow}/>
                        )}
                    </Label>
                    {content ? url ? (
                        <iframe style={{border: "none", width: "100%", height: "70vh"}} src={url} />
                    ) : (
                        <NoDataPlaceholder text={`Lesson has no "${entity}" published for ${viewScope}.`} />
                    ) : (
                        <NoDataPlaceholder text="Lesson has no published content." />
                    )}
                </div>
            </Dimmer.Dimmable>

        );
    }
}
