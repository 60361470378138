const TYPES = {
    RESOURCES_NAME_SAVE: "Resources/Name/SAVE",
    RESOURCES_NAME_SAVED: "Resources/Name/SAVED",
};

const onSave = (resourceId, name) => ({
    type: TYPES.RESOURCES_NAME_SAVE,
    payload: { resourceId, name },
});

const onSaved = () => ({
    type: TYPES.RESOURCES_NAME_SAVED,
    payload: null,
});

export {
    TYPES,
    onSave,
    onSaved,
};
