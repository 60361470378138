exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._32Kqsmqe8564FNWWjr8LMn {\n  font-weight: bold;\n  background-color: #dfe1e6;\n  color: #263850;\n  padding: 0.3em 1em;\n  font-size: 12px;\n  display: inline-block;\n  border-radius: 3px;\n  margin: -0.3em 0 -0.3em 0.5em; }\n\n._3-etcMoPxnrY7cKEDFWqk6 {\n  display: flex !important; }\n", ""]);

// exports
exports.locals = {
	"numberOfProblemSets": "_32Kqsmqe8564FNWWjr8LMn",
	"dropdownItem": "_3-etcMoPxnrY7cKEDFWqk6"
};