import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";

import { LockIcon } from "../../../Lock";
import AccessControl from "../../../auth/AccessControl";
import RemoveIcon from "../../../component/RemoveIcon";
import { getAccessRights } from "../ProblemUtils";
import styles from "./ProblemList.module.scss";


export default class ProblemRowIcons extends React.PureComponent {
    static propTypes = {
        fbCollection: PropTypes.string.isRequired,
        entityName: PropTypes.string.isRequired,
        entityId: PropTypes.string.isRequired,
        authorId: PropTypes.string,
        userId: PropTypes.string.isRequired,
        unused: PropTypes.bool,
        locked: PropTypes.bool,
        onEdit: PropTypes.func.isRequired,
        onReview: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    render() {
        const { locked, unused, authorId, userId, onEdit, onReview, onRemove, fbCollection, entityId, entityName } = this.props;
        const needRights = getAccessRights(fbCollection);
        const rightsData = { userId, authorId };

        return (
            <div className={styles.actions}>
                {locked && <LockIcon/>}
                <AccessControl action={needRights.modify} data={rightsData} renderNoAccess={(
                    /* User can't edit but may review */
                    <AccessControl action={needRights.review} data={rightsData}>
                        <Button style={{ background: "none" }} icon data={entityId} onClick={onReview}>
                            <Icon name="eye" />
                        </Button>
                    </AccessControl>
                )}>
                    <Button style={{ background: "none" }} icon data={entityId} onClick={onEdit}>
                        <Icon name="write" />
                    </Button>
                </AccessControl>
                {unused && (/* can delete used problems */
                    <AccessControl action={needRights.modify} data={rightsData}>
                        <RemoveIcon onRemove={onRemove} id={entityId} entityName={entityName} />
                    </AccessControl>
                )}
            </div>
        );
    }
}
