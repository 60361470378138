import { combineReducers } from "redux";
import { TYPES } from "./AKitsActions";
import aKitsList from "./AKitsList/AKitsListReducer";
import aKitEditor from "./AKitEditor/AKitEditorReducer";

// The initial state of the ExampleSimple
const initialState = {
    error: undefined,
    selectedAKitId: undefined,
    isRemoving: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.AKITS_ON_ERROR:
            return { ...state, error: action.value };
        case TYPES.AKITS_CLEAR_ERROR:
            return { ...state, error: undefined };
        case TYPES.AKITS_SELECT_FOR_EDIT:
            return { ...state, selectedAKitId: action.selectedAKitId };
        case TYPES.AKITS_REMOVED: 
            return { ...state, isRemoving: false };
        case TYPES.AKITS_REMOVE: 
            return { ...state, isRemoving: true };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default combineReducers({
    main: reducer,
    aKitsList,
    aKitEditor,
});