import React from "react";
import { Route } from "react-router-dom";
import CourseVariantsContainer from "./CourseVariantList/CourseVariantsContainer";
import SchoolsContainer from "./SchoolList/SchoolsContainer";
import AccessControl from "../auth/AccessControl";
import UserCourses from "./UserCourses";
import SchoolCourses from "./SchoolCourses";
import TenantsContainer from "./Tenants";
import YearsListContainer from "../Year/YearsListContainer";

const Routes = () => (
    <React.Fragment>
        <AccessControl action="courseVariant:manage">
            <Route path="/external/courseVariants/list" component={CourseVariantsContainer} />
        </AccessControl>
        <AccessControl action="tablet:manage">
            <Route path="/external/years/list" component={YearsListContainer} />
            <Route path="/external/schools/list" component={SchoolsContainer} />
            <Route path="/section/courses/:courseId?" component={SchoolCourses} />
        </AccessControl>
        <AccessControl action="tenant:manage">
            <Route path="/tenants" component={TenantsContainer} />
        </AccessControl>
        <Route path="/section/users/:userId?" component={UserCourses} />
    </React.Fragment>
);
export default Routes;
