import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onSelectObjectiveSet } from "./objectiveSetActions";
import firebase from "../../../firebase";
import { getUpdateObjectForPaths, applyUpdateObject } from "../../../utils/sagaForFBUtils";

/** TODO:
 * - review whether there is an abstraction for generating paths and consider creating one universal function for it
 * - review correct usage of generators and yield
 * - update functions reference/docs
 */

const enrichById = (object, id) => { return { ...object, id }; };

/**
 * Loads data from Firebase located at ${path}.
 * @param { string } path
 * @return object representing the data at the ${path}
 * @throws Error
 */
const loadData = async (path) => {

    const objectiveSetsRef = firebase.getFirebaseData(path);

    let objectiveSetsData = null;
    await objectiveSetsRef.once("value",
        snapshot => {
            objectiveSetsData = snapshot.val();
        }
        ,
        error => {
            throw error;
        }
    );

    if (objectiveSetsData == null) {
        console.log("No data loaded which is unusual"); // eslint-disable-line no-console
    }

    return objectiveSetsData;
};

/**
 * Converts Firebase's object representing a list to an array. Each element of the list is supposed to be an object and is enriched by id property with value of index.
 * E.g. { "O1": { "name": "Mark" }, "O2": { "name": "Frank" } } is converted to
 * [{ "id": "O1", "name": "Mark" }, { "id": "O2", "name": "Frank" }]
 * @param { object|null } fb_list_object
 * @return array
 */
const fbObjectToArray = (fb_list_object) => {
    if (!fb_list_object) {
        return [];
    }

    return Object.keys(fb_list_object).map( key => { return enrichById(fb_list_object[key], key); });
};


function* updateObjectiveSetNameInObjectiveSets( { payload } ) {
    try {
        const objectiveSetRef = yield firebase.getFirebaseData(`objective_sets/${payload.id}`);
        yield objectiveSetRef.update({ name: payload.updated_name });
    }
    catch (error) {
        payload.onError(error);
    }
}

/**
 * Gets index of the objective_set_id usage in topics.
 * The index is of structure [] - array of topic_id
 * If there is no reference to the objective_set_id an empty array is returned
 * @param {*} objective_id
 * @returns array
 */
const getObjectiveSetReferencesInTopics = async (objective_set_id, topics = null) => {

    if (topics == null) {
        const topicsData = await loadData("topics");
        topics = fbObjectToArray(topicsData); // always returns array
    }

    const topicsIndex = topics
        .filter( (topic) => { return (topic.objective_set && (topic.objective_set.id == objective_set_id)); } )
        .map( (topic) => topic.id );

    return topicsIndex;
};

/**
 * Updates name of an objective used in list of topics.
 * @param { type, payload }
 */
function* updateObjectiveSetNameInTopics( { payload } ) {
    try {
        const topicsIndex = yield getObjectiveSetReferencesInTopics(payload.id);

        /* generate paths for update - list of paths to (objective) name to be updated */
        let target_paths = topicsIndex.map( (topic_id) => {
            return `${topic_id}/objective_set/name`;
        });

        if (target_paths.length == 0) return; // No data for update

        const update_object = getUpdateObjectForPaths(target_paths, payload.updated_name);
        yield applyUpdateObject(update_object, firebase.getFirebaseData("topics"));
    }
    catch (error) {
        payload.onError(error);
    }
}

export function* updateObjectiveSetName( action ) {

    yield all([
        updateObjectiveSetNameInObjectiveSets(action),
        updateObjectiveSetNameInTopics(action),
    ]);
}


export function* addObjectiveSet({ payload }) {
    try {
        const objectiveSetRef = yield firebase.getFirebaseData("objective_sets");
        const { key } = yield objectiveSetRef.push({ name: payload.newName });
        yield put(onSelectObjectiveSet(key));
        if (payload.setEditTextId)
            yield payload.setEditTextId(key);
    }
    catch (error) {
        payload.onError(error);
    }
}

/**
 * Gets index of the objective_id usage in objective_sets.
 * The index is of structure [ { objective_set_id, objective_ids[] } ]
 * If there is no reference to the objective_id an empty array is returned
 * @param {*} objective_id
 * @returns array
 */
const getObjectiveReferences = async(objective_id) => {

    const objectiveSetsData = await loadData("objective_sets");
    const objectiveSets = fbObjectToArray(objectiveSetsData);

    /* Create an index of objectives whose id is matching to payload.id.
    The index is list of objects [ { objective_set_id, objective_ids: [] } ]
    In this case there should be exactly one element to be updated but as a proof of concept of updating all occurences of an objective I am iterating over all the data.
    */
    const objectivesIndex = objectiveSets.reduce( (matchingList, objectiveSet) => {

        const matchingObjectiveIds = objectiveSet.objectives ? Object.keys(objectiveSet.objectives).filter( objective_key => { return (objective_key == objective_id); } ) : [];
        if (matchingObjectiveIds.length > 0) {
            matchingList.push(
                { objective_set_id: objectiveSet.id, objective_ids: matchingObjectiveIds }
            );
        }

        return matchingList;
    }, [] );

    return objectivesIndex;
};

/**
 * Gets index of the objective_id usage in topics.
 * The index is of structure [] - array of objective_ids/
 * If there is no reference to the objective_id an empty array is returned
 * @param {*} objective_id
 * @returns array
 */
const getObjectiveReferencesInTopics = async(objective_id, topics) => {

    if (topics == null) {

        // console.log("Internally loaded topics ");

        const topicsData = await loadData("topics");
        topics = fbObjectToArray(topicsData); // always returns array
    }

    const topicsIndex = topics
        .filter( (topic) => { return (topic.objective && (topic.objective.id == objective_id)); } )
        .map( (topic) => topic.id );

    return topicsIndex;
};


/**
 * Updates an objective's name used in topics.
 * @param { type, payload }
 */
function* updateObjectiveNameInObjectiveSets( { payload } ) {
    try {
        const objectivesIndex = yield getObjectiveReferences(payload.id);

        /* generate paths for update - list of paths to (objective) name to be updated */
        let target_paths = [];
        objectivesIndex.forEach( (objective_set_index) => {

            objective_set_index.objective_ids.forEach( objective_id => {
                target_paths.push(`${objective_set_index.objective_set_id}/objectives/${objective_id}/name`);
            });
        });

        const update_object = getUpdateObjectForPaths(target_paths, payload.updated_name);
        yield applyUpdateObject(update_object, firebase.getFirebaseData("objective_sets"));
    }
    catch (error) {
        payload.onError(error);
    }
}


/**
 * Gets index of the objective_id usage in oii_objectives.
 * The index is simple list of objective ids
 * If there is no reference to the objective_id an empty array is returned
 * @param {*} objective_id
 * @returns array
 */
const getOiiObjectiveReferences = async(objective_id) => {
    const objectivesData = await loadData("oii_objectives");
    const objectives = fbObjectToArray(objectivesData);
    /* Create an index of objectives whose id is matching to payload.id.
    The index is list of objective_ids [ ]
    In this case there should be exactly one element to be updated but as a proof of concept of updating all occurences of an objective I am iterating over all the data.
    */
    const oiiObjectiveIndex = objectives
        .filter( objective => { return (objective.id == objective_id); } )
        .map(objective => { return objective.id; } );
    return oiiObjectiveIndex;
};

/**
 * Updates an objective's name used in topics.
 * @param { type, payload }
 */
function* updateObjectiveNameInOiiObjectives( { payload } ) {

    const oiiObjectiveReferences = yield getOiiObjectiveReferences(payload.id);

    /* generate paths for update - list of paths to (objective) name to be updated */
    let target_paths = [];
    oiiObjectiveReferences.forEach( (objective_id) => {
        target_paths.push(`/${objective_id}/name`);
    });

    try {
        const objectivePaths = getUpdateObjectForPaths(target_paths, payload.updated_name);
        yield applyUpdateObject(objectivePaths, firebase.getFirebaseData("oii_objectives"));
    }
    catch (error) {
        payload.onError(error);
    }
}

/**
 * Updates name of an objective used in list of topics.
 * @param { type, payload }
 */
function* updateObjectiveNameInTopics( { payload } ) {
    try {
        const topicsIndex = yield getObjectiveReferencesInTopics(payload.id);

        let target_paths = topicsIndex.map( (topic_id) => {
            return `${topic_id}/objective/name`;
        });

        if (target_paths.length == 0) return; // No data for update

        const objectivePaths = getUpdateObjectForPaths(target_paths, payload.updated_name);
        yield applyUpdateObject(objectivePaths, firebase.getFirebaseData("topics"));
    }
    catch (error) {
        payload.onError(error);
    }
}

export function* updateObjectiveName( action ) {

    yield all([
        updateObjectiveNameInObjectiveSets(action),
        updateObjectiveNameInTopics(action),
    ]);
}

export function* updateOiiObjectiveName( action ) {

    yield all([
        updateObjectiveNameInOiiObjectives(action),
        updateObjectiveNameInTopics(action),
    ]);
}

export function* addObjective({ payload }) {
    try {
        const objectiveSetRef = yield firebase.defaultApp
            .database()
            .ref(`/objective_sets/${payload.objectiveSetId}/objectives`);
        const { key } = yield objectiveSetRef.push(payload.newObjective);
        if (payload.setEditTextId)
            yield payload.setEditTextId(key);
    }
    catch (error) {
        payload.onError(error);
    }
}

export function* updateObjectivesOrdering({ payload }) {
    try {
        if (payload.updatedOrdering.length) {
            const objectiveSetRef = yield firebase.defaultApp
                .database()
                .ref(`/objective_sets/${payload.objectiveSetId}/objectives`);
            const updateObject = {};
            payload.updatedOrdering.map(v => updateObject[`${v.id}/ordering`] = v.ordering);
            yield objectiveSetRef.update(updateObject);
        }
    }
    catch (error) {
        payload.onError(error);
    }
}

/**
 * Tests if objective id is referenced by an entity.
 * Currently test of reference from topics is implemented.
 *
 * @param {*} id objective id
 * @returns bool true when there exists an entity referencing objective of given id
 */
const isObjectiveReferenced = async(id, topics = null) => {

    const topicsReferenced = await getObjectiveReferencesInTopics(id, topics);
    const isReferenced = (topicsReferenced.length > 0); // non-empty list of topics referencing the objective

    return isReferenced;
};


/**
 *
 * @param { {type, payload} } payload = { id, props, onSuccess(), onFailure() }
 */
export function* removeObjective( { payload } ) {

    const topicsData = yield loadData("topics");
    const topics = fbObjectToArray(topicsData); // always returns array

    const isReferenced = yield isObjectiveReferenced(payload.id, topics);

    if (isReferenced) {
        yield payload.onFailure("Cannot remove, objective in use.");
        return;
    }

    /* objective is not referenced, could be removed */
    const objectiveReferences = yield getObjectiveReferences(payload.id);

    /* generate paths for remove - list of paths to objectives to be removed */
    let target_paths = [];
    objectiveReferences.forEach( (objective_set_index) => {

        objective_set_index.objective_ids.forEach( objective_id => {
            target_paths.push(`${objective_set_index.objective_set_id}/objectives/${objective_id}`);
        });
    });

    const objectivePaths = getUpdateObjectForPaths(target_paths, null);
    try {
        yield applyUpdateObject(objectivePaths, firebase.getFirebaseData("objective_sets"));
        yield payload.onSuccess();
    }
    catch (error) {
        payload.onError(error);
    }
}

/**
 * Tests if objective set id is referenced by an entity.
 * Currently test of reference from topics is implemented.
 *
 * @param {*} id objective set id
 * @returns bool true when there exists an entity referencing objective set of given id
 */
const isObjectiveSetReferenced = async(id, topics = null ) => {

    const topics_referenced = await getObjectiveSetReferencesInTopics(id, topics);
    const isReferenced = (topics_referenced.length > 0); // non-empty list of topics referencing the objective

    return isReferenced;
};

/**
 * Tests if any of objective_set's objective is used.
 *
 * @param {*} objective_ids
 */
const areObjectivesInSetReferenced = async(objective_set_id, topics = null) => {

    /* get objectives from the objective_set */
    const objectiveSetData = await loadData(`objective_sets/${objective_set_id}`);
    const objectives = ("objectives" in objectiveSetData)
        ? fbObjectToArray(objectiveSetData.objectives)
        : [];

    var areObjectivesReferenced = false;
    for(let objective of objectives) {
        const o = await getObjectiveReferencesInTopics(objective.id, topics);
        if (o.length > 0) {
            areObjectivesReferenced = true;
            break;
        }
    }

    return areObjectivesReferenced;
};


const getObjectiveSetReferences = async(objective_set_id) => {
    const objectiveSetsData = await loadData("objective_sets");
    const objectiveSets = fbObjectToArray(objectiveSetsData); // always returns array

    const objectiveSetsIndex = objectiveSets
        .filter( (objectiveSet) => { return (objectiveSet.id == objective_set_id); } )
        .map( (objectiveSet) => objectiveSet.id );

    return objectiveSetsIndex;
};

export function* removeObjectiveSet( { payload } ) {

    const topicsData = yield loadData("topics");
    const topics = fbObjectToArray(topicsData); // always returns array

    /* Test if the entity objective_set is referenced somewhere */
    const isReferenced = yield isObjectiveSetReferenced(payload.id, topics);
    if (isReferenced) {
        yield payload.onFailure("Cannot remove, objective set in use.");
        return;
    }

    /* Test if any of objectives from the objective set is referenced somewhere */
    /* Currently this is not necessary as objective and objective set are used in topic, so the objective can not be referenced without its objective set being referenced too. However to be prepared for future enhancements we do the test */
    const isObjectivesReferencedValue = yield areObjectivesInSetReferenced(payload.id, topics);
    if (isObjectivesReferencedValue) {
        yield payload.onFailure("Cannot remove, some objectives from objective set are in use.");
        return;
    }

    /* objective is not referenced, could be removed */
    const objectiveSetReferences = yield getObjectiveSetReferences(payload.id);

    /* generate paths for remove - list of paths to objectives to be removed */
    let target_paths = [];
    objectiveSetReferences.forEach( (objective_set_index) => {
        target_paths.push(`${objective_set_index}`);
    });

    /* perform remove of the objective set */
    const objectiveSetPaths = getUpdateObjectForPaths(target_paths, null);
    try {
        yield applyUpdateObject(objectiveSetPaths, firebase.getFirebaseData("objective_sets"));
        yield payload.onSuccess();
    }
    catch (error) {
        payload.onError(error);
    }
}

export function* removeOiiObjective( { payload } ) {

    const {
        id: objective_id,
        onSuccess: onSuccessCallback,
        onFailure: onFailureCallback,
        onError
    } = payload;

    const topicsData = yield loadData("topics");
    const topics = fbObjectToArray(topicsData); // always returns array

    const isReferenced = yield isObjectiveReferenced(objective_id, topics);

    if (isReferenced) {
        onFailureCallback("Cannot remove, objective in use.");
        return;
    }

    /* objective is not referenced, could be removed */
    const oiiObjectiveReferences = yield getOiiObjectiveReferences(objective_id);

    /* generate paths for remove - list of paths to objectives to be removed */
    let target_paths = [];
    oiiObjectiveReferences.forEach( (objective_id) => {
        target_paths.push(`/${objective_id}`);
    });

    const objectivePaths = getUpdateObjectForPaths(target_paths, null);
    try {
        yield applyUpdateObject(objectivePaths, firebase.getFirebaseData("oii_objectives"));
        onSuccessCallback();
    }
    catch(error) {
        onError(error);
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.OSM_UPDATE_OBJECTIVESET_NAME, updateObjectiveSetName),
        takeLatest(TYPES.OSM_NEW_OBJECTIVESET, addObjectiveSet),
        takeLatest(TYPES.OSM_UPDATE_OBJECTIVE_NAME, updateObjectiveName),
        takeLatest(TYPES.OSM_NEW_OBJECTIVE, addObjective),
        takeLatest(TYPES.OSM_REMOVE_OBJECTIVE, removeObjective),
        takeLatest(TYPES.OSM_REORDER_OBJECTIVES, updateObjectivesOrdering),
        takeLatest(TYPES.OSM_REMOVE_OBJECTIVESET, removeObjectiveSet),
        takeLatest(TYPES.OSM_UPDATE_OII_OBJECTIVE_NAME, updateOiiObjectiveName),
        takeLatest(TYPES.OSM_REMOVE_OII_OBJECTIVE, removeOiiObjective),
    ]);
}