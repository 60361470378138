import React from "react";
import PropTypes from "prop-types";
import Objective from "./Objective";

const objectiveStyle = { display: "flex", alignItems: "center", justifyContent: "space-between" };

const Topic = ({ topic }) => (
    <div style={objectiveStyle}>
        <span>{topic.concept.name}</span>
        <Objective data={topic.objective} />
    </div>
);


Topic.propTypes = {
    topic: PropTypes.shape({
        concept: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
        }),
        objective: PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string
        })
    })
};

export default Topic;
