export const isAdmin = claims => (claims && claims.includes("ROLE_ADMIN") ? true : false);
export const isSuperAuthor = claims => (claims && claims.includes("ROLE_SUPERAUTHOR") ? true : false);
export const isAuthor = claims => (claims && claims.includes("ROLE_AUTHOR") ? true : false);
export const isReviewer = claims => (claims && claims.includes("ROLE_REVIEWER") ? true : false);
export const isUser = claims => (claims && claims.includes("ROLE_USER") ? true : false);
export const isReviewerAtLeast = claims => (isAdmin(claims) || isReviewer(claims) || isSuperAuthor(claims));
export const isSuperAuthorAtLeast = claims => (isAdmin(claims) || isSuperAuthor(claims));
export const isAuthorAtLeast = claims => (isAdmin(claims) || isAuthor(claims) || isSuperAuthor(claims));
export const isUserAtLeast = claims => (isAdmin(claims) || isAuthor(claims) || isSuperAuthor(claims) || isReviewer(claims) || isUser(claims));
export const isMoreThanUser = claims => (isAdmin(claims) || isAuthor(claims) || isSuperAuthor(claims) || isReviewer(claims));
