import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Message, Icon, Segment, List } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import uuid from "uuid/v4";

export default class EditDevice extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        editedDevice: PropTypes.object,
        schools: PropTypes.array,
        onSchoolLoad: PropTypes.func.isRequired,
        users: PropTypes.array,
        onUserListLoad: PropTypes.func.isRequired,
        isLoadingUsers: PropTypes.bool,
        isLoadingSchools: PropTypes.bool,
        onUpdateDevice: PropTypes.func.isRequired,
        onSchoolCourseLoad: PropTypes.func.isRequired,
        schoolCourses: PropTypes.array,
        isLoadingSc: PropTypes.bool,
        selectedYear: PropTypes.object,
    };

    state = {
        serialNumber: (this.props.editedDevice && this.props.editedDevice.serial_number) || "",
        description: (this.props.editedDevice && this.props.editedDevice.description) || "",
        school: this.props.editedDevice ? this.props.editedDevice.school && this.props.editedDevice.school.id : null,
        user: this.props.editedDevice ? this.props.editedDevice.user && this.props.editedDevice.user.id : null,
        schoolCourse: null,
        isChanged: false,
        showBigWarning: false,
        isSaving: false,
    };

    handleChange = (e, { name, value }) => {
        const { editedDevice, onSchoolCourseLoad, onUserListLoad } = this.props;

        if (value === "NULL") {
            value = null;
        }

        this.setState({ [name]: value, isChanged: true });

        // reload school courses when school changes
        if (name === "school") {
            onSchoolCourseLoad(value);
            onUserListLoad({ school_id: value });
            this.setState({ user: null, schoolCourse: null });
        }

        // reload users when school course changes
        if (name === "schoolCourse") {
            if (value === null) {
                // when school course is not selected, load users for whole school
                if (this.state.schoolCourse !== null) {
                    onUserListLoad({ school_id: this.state.school });
                }
            } else {
                // when school course is selected, load users only for course
                onUserListLoad({ school_course_id: value });
            }
        }

        // display big red warning if we're changing user for already provisioned tablet
        if (
            editedDevice &&
            editedDevice.last_provisioned &&
            ((name === "school" && editedDevice.school && value != editedDevice.school.id) ||
                (name === "user" && editedDevice.user && value != editedDevice.user.id))
        ) {
            this.setState({ showBigWarning: true });
        } else {
            this.setState({ showBigWarning: false });
        }
    };

    componentDidMount() {
        const { schools, editedDevice, onSchoolLoad, onUserListLoad, onSchoolCourseLoad } = this.props;
        if (schools === undefined) {
            onSchoolLoad();
        }
        if (editedDevice && editedDevice.school && editedDevice.school.id) {
            onUserListLoad({ school_id: editedDevice.school.id });
            onSchoolCourseLoad(editedDevice.school.id);
        }
    }

    handleSave = () => {
        const { serialNumber, description, user, school } = this.state;
        const { editedDevice, onUpdateDevice, onSave } = this.props;

        if (editedDevice) {
            onUpdateDevice(editedDevice.id, {
                serial_number: serialNumber,
                description,
                user_id: user,
                school_id: school,
            });
        } else {
            onSave({
                client_id: uuid(), client_secret: uuid(),
                serial_number: serialNumber,
                description,
                user_id: user,
                school_id: school,
            });
        }
        this.setState({ isSaving: true });
    };

    handleClose = () => {
        this.props.onClose(false);
    };

    render() {
        const {
            serialNumber,
            description,
            school,
            user,
            isChanged,
            isSaving,
            showBigWarning,
            schoolCourse,
        } = this.state;
        const {
            editedDevice,
            schools,
            users,
            isLoadingSchools,
            isLoadingUsers,
            schoolCourses,
            isLoadingSc,
            selectedYear,
        } = this.props;

        return (
            <Modal open size="small">
                <Modal.Header>{editedDevice ? "Edit" : "Add new"} tablet</Modal.Header>
                <Modal.Content image>
                    <Segment basic style={{ flexBasis: "35%" }}>
                        <Icon name="tablet alternate" size="massive" color="grey" />
                        {editedDevice && (
                            <List>
                                <List.Item>
                                    <List.Header>Manufacturer:</List.Header>
                                    {editedDevice.manufacturer}
                                </List.Item>
                                <List.Item>
                                    <List.Header>Model:</List.Header>
                                    {editedDevice.model}
                                </List.Item>
                                <List.Item>
                                    <List.Header>Last provisioned:</List.Header>
                                    {editedDevice.last_provisioned}
                                </List.Item>
                            </List>
                        )}
                    </Segment>
                    <Form style={{ flexGrow: "1" }}>
                        <Form.Input
                            placeholder="Serial number"
                            name="serialNumber"
                            fluid
                            value={serialNumber}
                            onChange={this.handleChange}
                            label="Device serial number"
                        />
                        <Form.Input
                            placeholder="Description"
                            name="description"
                            value={description}
                            onChange={this.handleChange}
                            label="Description"
                        />
                        <Form.Dropdown
                            placeholder="Select a school"
                            name="school"
                            selection
                            search
                            options={schools || []}
                            value={school}
                            onChange={this.handleChange}
                            loading={isLoadingSchools}
                            label="School"
                        />
                        <Form.Dropdown
                            placeholder="Select a school course"
                            name="schoolCourse"
                            selection
                            search
                            options={schoolCourses || []}
                            value={schoolCourse}
                            onChange={this.handleChange}
                            loading={isLoadingSc}
                            disabled={!selectedYear}
                            label={`School courses ${
                                selectedYear ? "in year " + selectedYear.name : " - No school year selected"
                            }`}
                        />
                        <Form.Dropdown
                            placeholder="Select a user"
                            name="user"
                            selection
                            search
                            options={users || []}
                            value={user}
                            onChange={this.handleChange}
                            loading={isLoadingUsers}
                            label="Assigned to user"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Content>
                    {showBigWarning && (
                        <Message icon negative>
                            <Icon color="red" name="ban" />
                            <Message.Content>
                                <Message.Header>WARNING</Message.Header>
                                You are about to change device assignment. This will render the device <b>
                                    unusable
                                </b>{" "}
                                for current user and the device will have to re-provisioned.
                                <p>If you are not sure, do not proceed and hit Cancel button.</p>
                            </Message.Content>
                        </Message>
                    )}
                    {isSaving && (
                        <Message icon positive>
                            <Icon name="circle notched" loading />
                            <Message.Content>Saving ...</Message.Content>
                        </Message>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onSave={this.handleSave}
                        onCancel={this.handleClose}
                        saveDisabled={!isChanged || serialNumber.trim().length === 0}
                        submitText={editedDevice ? "Update" : "Add"}
                        padded
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
