import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Label, Dropdown } from "semantic-ui-react";

const LabelDone = () => (
    <Label color="green" horizontal basic>
        Done
    </Label>
);
const LabelOpen = () => (
    <Label color="orange" horizontal basic>
        Open
    </Label>
);
const LabelOmit = () => (
    <Label color="grey" horizontal basic>
        Omit
    </Label>
);

export default class StatusLabel extends PureComponent {
    static propTypes = {
        status: PropTypes.string,
        path: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        linkedTopicCount: PropTypes.number
    };

    handleClick = (e, { value }) => {
        /* set different close status based on linkedTopicCount */
        if (value === "CLOSE") {
            value = this.props.linkedTopicCount > 0 ? "DONE" : "OMIT";
        }
        this.props.onClick(this.props.path, value);
    };
    _renderNodeStatus(status) {
        switch (status) {
            case "OMIT":
                return <LabelOmit />;
            case "DONE":
                return <LabelDone />;
            case "OPEN":
                return <LabelOpen />;
        }
    }

    render() {
        const { status } = this.props;
        return (
            <Dropdown trigger={this._renderNodeStatus(status)} icon={null}>
                <Dropdown.Menu>
                    {status === "OPEN" ? (
                        <Dropdown.Item text="Close topic" value="CLOSE" onClick={this.handleClick} />
                    ) : (
                        <Dropdown.Item text="Open topic" value="OPEN" onClick={this.handleClick} />
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
