import { all, put, takeLatest, select } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, setSchooCourseList } from "./schoolCourseActions";
import { toastError } from "../../component/toast";
import { getSelectedYear } from "../../Year/YearsSelectors";

function* loadSchoolCourseList({ payload }) {
    const year = yield select(getSelectedYear);
    if (!year) {
        toastError({
            code: "school year not selected",
            header: "Loading of school course list failed.",
            message: "You have to select a valid school year in top header menu.",
        });
        return;
    }

    try {
        let sc = yield firebase.tabletProxy({
            method: "GET",
            uri: `/school-course?school_id=${payload}&year_id=${year.id}&$sort[name]=1`,
        });
        yield put(setSchooCourseList(sc.data));
    } catch (err) {
        toastError({
            code: err.details.message,
            header: "Loading of school course list failed.",
            message: err.message,
        });
        yield put(setSchooCourseList([]));
    }
}

export default function* saga() {
    yield all([takeLatest(TYPES.LOAD_SCHOOL_COURSE_LIST, loadSchoolCourseList)]);
}
