import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DesignTile from "./DesignTile";
import { getDesign } from "./designTileSelectors";
import { setDesign } from "./designTileActions";

const mapStateToProps = function (state) {
    return {
        design: getDesign(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators({
        setDesign,
    }, dispatch);
};

export default connect(mapStateToProps, dispatchToProps)(DesignTile);
