import firebase from "../../firebase";

const basePath = "outlines_data";

export const paths = {
    basePath,
    toLesson: (outlineId, lessonId) => `${basePath}/${outlineId}/lessons/${lessonId}`,
};

export const getReferences = (outlineId, lessonId) => {
    const dataRef = firebase.getFirebaseData(paths.toLesson(outlineId, lessonId));
    return { dataRef };
};

export const updateLesson = async (outlineId, lessonId, data) => {
    if (!outlineId || !lessonId) {
        return false;
    }
    const { dataRef } = getReferences(outlineId, lessonId);
    const changes = { ...data };
    await dataRef.update(changes);
    return true;
};

export const addWidget = async (outlineId, lessonId, widgetId, position) => {
    if (!outlineId || !lessonId || !widgetId) {
        return false;
    }
    const widgetsRef = firebase.getFirebaseData(`${paths.toLesson(outlineId, lessonId)}/widgets`);
    await widgetsRef.update({ [widgetId]: position });
    return true;
};

export const updateProblemSet = async (outlineId, lessonId, problemSetId, collection, data) => {
    if (!outlineId || !lessonId || !problemSetId) {
        return false;
    }

    if (data) {
        const problemSetRef = firebase.getFirebaseData(`${paths.toLesson(outlineId, lessonId)}/${collection}/${problemSetId}`);
        await problemSetRef.update(data);
    } else {
        const problemSetRef = firebase.getFirebaseData(`${paths.toLesson(outlineId, lessonId)}/${collection}`);
        await problemSetRef.update({ [problemSetId]: null });
    }
    return true;
};

export const getAuthorKit = async (outlineId) => {
    const dataRef = firebase.getFirebaseData("kits_data");
    const akits = (await dataRef.orderByChild("outline_id").equalTo(outlineId).once("value")).val();
    if (null !== akits) {  // Sometimes on DEMO server the query above returns null
        const aKitId = Object.keys(akits).shift();
        if (aKitId) {
            return [aKitId, akits[aKitId]];
        }
    }
    return [];
};

export const getAuthorKitId = async (outlineId) => {
    const [aKitId] = await getAuthorKit(outlineId);
    return aKitId;
};
