import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ConfirmWithContext from "./ConfirmWithContext";

export default class ConfirmDeleteWithContext extends PureComponent {
    static propTypes = {
        context: PropTypes.any,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        whatText: PropTypes.string,
        confirmText: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    render() {
        const {
            context,
            whatText = "this item",
            onConfirm,
            onCancel,
            confirmText,
            children,
        } = this.props;
        return (
            <ConfirmWithContext
                context={context}
                header="Confirm Remove"
                message={`You are about to remove ${whatText}.`}
                confirmText={confirmText}
                onConfirm={onConfirm}
                onCancel={onCancel}
                isConfirmDestructive={true}
                confirmIconName="trash alternate outline"
            >
                {children}
            </ConfirmWithContext>
        );
    }
}
