import React from "react";
import PropTypes from "prop-types";
import { Popup, Search, Message } from "semantic-ui-react";

import { getSelectionAsLessonLink, linkLesson, unlinkLessonLink } from "./lessonLinkUtils";
import { getContextPropsForPopup } from "../utils/domUtils";
import ActionLink from "../../../ActionLink";

class LessonLinkPopup extends React.Component {

    static propTypes = {
        contextNode: PropTypes.instanceOf(Element).isRequired,
        editorState: PropTypes.object.isRequired,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        searchResult: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isSearching: PropTypes.bool,
        onSearchLesson: PropTypes.func,
        searchFilter: PropTypes.string,
        outlineId: PropTypes.string,
    };

    /**
     * When `isOpened` is not handled manually and `<Popup>` component has `open={true}`,
     * then `defaultSearchQuery={currentText}` is set to undefined by the first rendering (before componentDidMount)
     * and its value is not set even the `currentText` property is changed.
     */
    state = {
        isOpened: false,
        contextProps: {},
    };

    componentDidMount = () => {
        const { editorState, contextNode, disableParentEditor, onSearchLesson } = this.props;
        const { currentEntityKey, currentText } =  getSelectionAsLessonLink(editorState);
        const contextProps = getContextPropsForPopup(contextNode);
        disableParentEditor();
        onSearchLesson(currentText.trim());

        this.setState({
            isOpened: true,
            contextProps,
            currentEntityKey,
        });
    };

    setLessonLink = (lessonLinkInfo) => {
        const { editorState } = this.props;
        const newEditorState = linkLesson(editorState, lessonLinkInfo);
        this.applyChangesAndClose(newEditorState);
    };

    handleLinkLesson = (e,  {result: { lessonid, title }}) => {
        e.stopPropagation();
        if (!lessonid) {
            e.preventDefault();
            return;
        }
        const { outlineId } = this.props;
        this.setLessonLink({ outlineId, lessonId: lessonid, name: title });
    }

    handleUnlinkLesson = (e) => {
        e.stopPropagation();
        const { editorState } = this.props;
        const newEditorState = unlinkLessonLink(editorState, this.state.currentEntityKey);
        this.applyChangesAndClose(newEditorState);
    };

    handleAddNewGlossaryTerm = (newTermKey, name) => {
        this.setLessonLink({ key: newTermKey, name });
    };

    applyChangesAndClose = (editorState) => {
        const { setEditorState } = this.props;
        setEditorState(editorState);
        this.handleClose();
    };

    handleClose = () => {
        const { onClose, enableParentEditor, onSearchLesson } = this.props;
        this.setState({ isOpened: false });
        enableParentEditor();
        onSearchLesson("");
        onClose();
    };

    handleSearchChange = (e, { value }) => {
        const { onSearchLesson } = this.props;
        onSearchLesson(value);
    }

    searchResultRenderer = ({ title }) => <div>{title}</div>;

    render() {
        const { searchResult, searchFilter, isSearching, outlineId } = this.props;
        const { isOpened, currentEntityKey, contextProps } = this.state;
        const { node, pos, hOffset, vOffset } = contextProps;

        if (!isOpened) {
            return null;
        }

        return (
            <Popup
                context={node}
                position={pos}
                horizontalOffset={hOffset}
                verticalOffset={vOffset}
                open={true}
                wide="very"
                onClose={this.handleClose}
            >
                {currentEntityKey ? (
                    <ActionLink horizontal icon="unlink" onClick={this.handleUnlinkLesson} content="Remove link to lesson" />
                ) : outlineId ? (
                    <div style={{width: "30em"}}>
                        <Search
                            fluid
                            category
                            loading={isSearching}
                            onResultSelect={this.handleLinkLesson}
                            onSearchChange={this.handleSearchChange}
                            results={searchResult}
                            autoFocus
                            resultRenderer={this.searchResultRenderer}
                            placeholder="Search for lesson ..."
                            input={{fluid: true}}
                            value={searchFilter}
                            noResultsMessage="No lesson found"
                            noResultsDescription="Try to change searched word."
                        />
                    </div>
                ) : <Message warning>Adding link to lesson is not possible, if the editor is not opened in the context of lesson.</Message>}
            </Popup>
        );
    }
}

export default LessonLinkPopup;
