import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Card, Button, Dimmer, Loader } from "semantic-ui-react";
import ModuleCard from "./component/ModuleCard";
import CreateNewCard from "../../component/CreateNewCard";
import AccessControl from "../../auth";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import ModuleEditor from "./component/ModuleEditor";
import ModuleKitManager from "./component/ModuleKitManager";

export default class Modules extends PureComponent {
    static propTypes = {
        modules: PropTypes.shape({authored: PropTypes.array, subscribed: PropTypes.array}),
        onCreate: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        onUpdateModuleKits: PropTypes.func.isRequired,
        isSaving: PropTypes.bool.isRequired,
        isRemoving: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    }

    componentDidMount() {
        this.props.onLoad();
    }

    state = {
        isEditorOpen: false,
        editedModule: null,
        editedModuleId: null,
        isKitManagerOpen: false,
    }

    handleCreateEditor = () => this.setState({ isEditorOpen: true });
    handleManageKits = (id, module) => this.setState({ isKitManagerOpen: true, editedModule: module, editedModuleId: id });
    handleCloseKitManager = () => this.setState({ isKitManagerOpen: false, editedModule: null, editedModuleId: null });
    handleCloseEditor = () => this.setState({ isEditorOpen: false, editedModule: null, editedModuleId: null });
    handleEdit = (id, module) => this.setState({ isEditorOpen: true, editedModule: module, editedModuleId: id });
    handleSave = (data) => {
        const { editedModuleId } = this.state;
        const { onEdit, onCreate } = this.props;
        if (editedModuleId) {
            onEdit({ key: editedModuleId, ...data });
        } else {
            onCreate({ ...data });
        }
        this.handleCloseEditor();
    }

    handleSaveKits = (data) => {
        const { editedModuleId } = this.state;
        const { onUpdateModuleKits } = this.props;
        onUpdateModuleKits({ key: editedModuleId, ...data });
        this.handleCloseKitManager();
    }

    render() {
        const { modules, isSaving, isRemoving, isLoading } = this.props;
        const { isEditorOpen, editedModule, isKitManagerOpen, editedModuleId } = this.state;
        return (
            <Dimmer.Dimmable>
                <Dimmer active={isSaving || isRemoving || isLoading} inverted>
                    <Loader inverted>{isLoading ? "Loading..." : isSaving ? "Saving..." : "Removing..."}</Loader>
                </Dimmer>
                <Header as="h2" className="pageHeader">Packages</Header>
                {isEditorOpen &&
                    <ModuleEditor
                        editedModule={editedModule}
                        editedModuleId={editedModuleId}
                        onClose={this.handleCloseEditor}
                        onSave={this.handleSave}
                    />
                }
                {isKitManagerOpen &&
                    <ModuleKitManager
                        editedModule={editedModule}
                        editedModuleId={editedModuleId}
                        onClose={this.handleCloseKitManager}
                        onSave={this.handleSaveKits}
                    />
                }
                <Header dividing>My packages</Header>
                {modules && modules.authored && 0 !== modules.authored.length ?
                    <Card.Group>
                        {modules.authored.map(m =>
                            <ModuleCard
                                key={m.value.fb_id}
                                id={m.value.fb_id}
                                module={m.value}
                                onEdit={this.handleEdit}
                                onManageKits={this.handleManageKits}
                            />
                        )}
                        <AccessControl action="modules:manage">
                            <CreateNewCard onClick={this.handleCreateEditor} />
                        </AccessControl>
                    </Card.Group>
                    : <NoDataPlaceholder
                        icon="books"
                        text="No packages authored by you were found"
                    >
                        <AccessControl action="modules:manage">
                            <Button primary content="Create Package" onClick={this.handleCreateEditor} />
                        </AccessControl>
                    </NoDataPlaceholder>}
                <Header dividing>Subscribed</Header>
                {modules && modules.subscribed && 0 !== modules.subscribed.length ?
                    <Card.Group>
                        {modules.subscribed.map(m =>
                            <ModuleCard
                                key={m.value.fb_id}
                                id={m.value.fb_id}
                                module={m.value}
                                onEdit={this.handleEdit}
                                onManageKits={this.handleManageKits}
                            />
                        )}
                    </Card.Group>
                    : <div>You have no packages subscribed from SPORK.</div>}

            </Dimmer.Dimmable>
        );
    }
}
