import { TYPES } from "./ModulesActions";

const initialState = {
    isUpdating: false,
    isSaving: false,
    isRemoving: false,
    updatingModuleId: null,
    isLoading: false,
    packages: []
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.MODULES_UPDATE_MODULES_KITS:
            return { ...state, isUpdating: true, updatingModuleId: payload.key };
        case TYPES.MODULES_UPDATED:
            return { ...state, isUpdating: false, updatingModuleId: null, isSaving: false, isRemoving: false };
        case TYPES.MODULES_CREATE_MODULE:
        case TYPES.MODULES_EDIT_MODULE:
            return { ...state, isSaving: true };
        case TYPES.MODULES_REMOVE_MODULE:
            return { ...state, isRemoving: true };
        case TYPES.MODULES_LOAD:
            return { ...state, isLoading: true };
        case TYPES.MODULES_SET:
            return { ...state, packages: payload, isLoading: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
