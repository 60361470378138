export const TYPES = {
    CONC_ON_ERROR: "Concepts/onError",
    CONC_CLEAR_ERROR: "Concepts/clearError",
    CONC_REMOVE_CONCEPT: "CONC_REMOVE_CONCEPT",
    CONC_RENAME: "Concepts/Rename",
};

export const onConceptError = (value) => {
    return {
        type: TYPES.CONC_ON_ERROR,
        value,
    };
};

export const onClearConceptError = () => {
    return {
        type: TYPES.CONC_CLEAR_ERROR,
    };
};

export const onRenameConcept = (newName, id, callbackOnError) => {
    return ({
        type: TYPES.CONC_RENAME,
        newName,
        id,
        callbackOnError,
    });
};

export const onRemoveConcept = (conceptId, props, onSuccess, onFailure) => {
    return {
        type: TYPES.CONC_REMOVE_CONCEPT,
        payload: {
            id: conceptId,
            props,
            onSuccess,
            onFailure,
        },
    };
};