import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";

import { authUser, AuthUserPropType } from "../auth/authSelectors";
import { onReleaseLock, onAcquireLock } from "./lockActions";
import { getTimeAgo } from "../utils/dateUtils";
import { getLockDataListener, getLockData } from "./LockUtils";
import { withRouter } from "react-router";
import AccessControl from "../auth/AccessControl";
import { hasPermission } from "../auth";
import { TYPE } from "./types";

const getLockOwner = lock => {
    const owner = lock.owner;
    const fullname = owner && `${owner.first_name} ${owner.last_name}`.trim();
    return fullname || "another user";
};

class Lock extends React.PureComponent {
    static propTypes = {
        lockId: PropTypes.string,
        isLocked: PropTypes.bool.isRequired,
        lock: PropTypes.shape({
            // timestamp
            ts: PropTypes.number,
            // creator of the lock
            by: PropTypes.string,
            // see Lock/types.js for possible values
            type: PropTypes.string,
            // ID of locked item (specified by type)
            itemId: PropTypes.string,
            // populated "by" with "user" data
            owner: PropTypes.shape({
                first_name: PropTypes.string,
                last_name: PropTypes.string,
            }),
            // true if Cloud trigger looking for additional items to lock is still running
            inProgress: PropTypes.bool,
        }),
        onReleaseLock: PropTypes.func.isRequired,
        onAcquireLock: PropTypes.func.isRequired,
        lockType: PropTypes.shape({
            lockType: PropTypes.string.isRequired,
            itemId: PropTypes.string,
        }),
        authUser: AuthUserPropType,
        history: PropTypes.object,
        hideLockButton: PropTypes.bool,
        cantBeLocked: PropTypes.bool,
        /* Optional access control context data (to evaluate access to lock/unlock) */
        accessContext: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            time: props.lock ? getTimeAgo().format(new Date(props.lock.ts)) : null,
        };
    }

    handleUnlock = () => {
        const { lockId, onReleaseLock } = this.props;
        onReleaseLock({ lockId });
    };

    handleLock = () => {
        const { lockType, onAcquireLock } = this.props;
        onAcquireLock(lockType);
    };

    handleLink = () => {
        const { lock, history } = this.props;
        if (TYPE.LESSON === lock.type) {
            history.push(`/lesson/content/${lock.itemId}`);
        } else if (TYPE.PROBLEM_SET === lock.type) {
            history.push(`/qu/problem-set-creator/${lock.itemId}`);
        } else if (TYPE.COMP_TEST === lock.type) {
            history.push(`/qu/comp-test-editor/${lock.itemId}`);
        }
    };

    componentDidMount() {
        this.intervalID = setInterval(
            this.tick,
            30000
        );
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    componentDidUpdate() {
        this.tick();
    }

    tick = () => {
        this.setState({
            time: this.props.lock ? getTimeAgo().format(new Date(this.props.lock.ts)) : null
        });
    }

    getLockText = () => {
        const { lock, isLocked, authUser, lockType, cantBeLocked } = this.props;
        const { time } = this.state;
        const isRemoteLock = lock && lock.itemId !== lockType.itemId;
        const isLockedByMe = lock && lock.by !== authUser.uid;

        if (isLocked) {
            if (lock && lock.inProgress) {
                return <LockingInProgress />;
            } else if (isRemoteLock && time) {
                return (
                    <span>
                        <a onClick={this.handleLink} style={{ cursor: "pointer" }}>
                            Another item
                        </a>
                        &nbsp;was locked by {isLockedByMe ? getLockOwner(lock) : "you"} &nbsp;
                        {time} ago which caused this item to be locked too.
                    </span>
                );
            } else if (lock && time) {
                return `Locked by ${isLockedByMe ? getLockOwner(lock) : "you"} ${time}`;
            } else {
                return <LockLoadingDetails />;
            }
        } else {
            if (cantBeLocked) {
                return <LockCantBeLocked />;
            }
        }
    };

    getLockButton = () => {
        const { lock, isLocked, authUser, hideLockButton, cantBeLocked, accessContext } = this.props;

        if (hideLockButton) {
            return null;
        }

        if (isLocked) {
            // use RBAC roles to determine who can unlock
            return (
                <Button
                    onClick={this.handleUnlock}
                    disabled={!lock || lock.inProgress || !hasPermission({
                        action: "lock:unlock",
                        data: { ...accessContext, userId: authUser.uid, lockedBy: lock.by },
                    })}
                    icon="unlock"
                    content="Unlock" 
                    compact
                    style={{backgroundColor: "orange", color: "white"}}
                />
            );
        } else {
            return (
                <AccessControl action="lock:lock" data={accessContext}>
                    <Button compact style={{backgroundColor: "orange", color: "white"}} onClick={this.handleLock} disabled={cantBeLocked} icon="lock" content="Lock" />
                </AccessControl>
            );
        }
    };

    render() {
        const { isLocked, hideLockButton } = this.props;

        if (!isLocked && hideLockButton) {
            return null; // not locked and not lockable - don't display anything
        } else {
            // remote lock is lock on another item which causes this item also to be locked
            return (<div style={{marginBottom: "1em", minHeight: "30.5px"}}>
                {this.getLockButton()}
                    &emsp;
                <span style={{color: "orange", fontWeight: 700}}>{this.getLockText()}</span>
            </div>
            );
        }
    }
}

const LockContainer = compose(
    firebaseConnect(props => getLockDataListener(props)),
    connect(
        (state, props) => ({
            lock: getLockData(state, props),
            authUser: authUser(state, props),
        }),
        { onReleaseLock, onAcquireLock }
    ),
    withRouter
)(Lock);

LockContainer.displayName = "LockContainer";
LockContainer.propTypes = {
    lockId: PropTypes.string,
    isLocked: PropTypes.bool.isRequired,
    lockType: PropTypes.shape({
        lockType: PropTypes.string.isRequired,
        itemId: PropTypes.string,
    }),
    hideLockButton: PropTypes.bool,
    /* Optional access control context data (to evaluate access to lock/unlock) */
    accessContext: PropTypes.object,
};
export default LockContainer;

const LockCantBeLocked = () => (
    <React.Fragment>
        <Icon name="lock" />
        This item cannot be locked because contains already locked items.
    </React.Fragment>
);

const LockLoadingDetails = () => (
    <React.Fragment>
        <Icon name="circle notch" loading />
        Loading lock details...
    </React.Fragment>
);

const LockingInProgress = () => (
    <React.Fragment>
        <Icon name="circle notch" loading />
        Locking in progress ...
    </React.Fragment>
);
