import React from "react";
import { SideBarMenuLink } from "../component/SidebarMenuLink";
import PropTypes from "prop-types";
import { withAuth } from "../component/withAuth";

const SidebarMenu = ({ isMoreThanUser }) => 
    isMoreThanUser ? (
        <SideBarMenuLink to="/topicCollections" text="Topic Collections" />
    ) : null
;

SidebarMenu.propTypes = {
    isMoreThanUser: PropTypes.bool,
};

export default withAuth(SidebarMenu);
