import React, { PureComponent } from "react";
import { Message, Icon } from "semantic-ui-react";
import { isIE, browserVersion } from "react-device-detect";

export default class BrowserDetector extends PureComponent {
    render() {
        if (isIE && browserVersion <= 11) {
            return (
                <Message icon error style={{maxWidth: "600px", margin: "20px auto"}}>
                    <Icon name="internet explorer" />
                    <Message.Content>
                        <Message.Header>Your browser is not supported.</Message.Header>
                        Some features might not work properly in the Internet Explorer 11. Please
                        use <a href="https://www.google.com/chrome/" target="_blank" rel="external noopener noreferrer">Google Chrome <Icon name="external alternate" /></a> to fully enjoy the application.
                    </Message.Content>
                </Message>
            );
        } else return null;
    }
}
