import React from "react";
import PropTypes from "prop-types";
import WidgetItem from "./WidgetItem";
import orderedByPosition from "./orderedByPosition";

export default class WidgetItems extends React.PureComponent {
    static propTypes = {
        template: PropTypes.object,
        filtered: PropTypes.func,
        content: PropTypes.object
    };

    render() {
        const { template, content, filtered } = this.props;
        return template ? orderedByPosition(template, filtered).map(({ key, value }) => {
            if (content && content[key] && (content[key].html || content[key].href || content[key].name)) {
                return <WidgetItem key={key} template={{...value, id: key}} content={content && content[key]} />;
            }
        }) : <div />;
    }
}
