import { createSelector } from "reselect";
import { mapCollectionToList, objectToDropdownOptions, convertDatasetToList } from "../utils/selectorUtils";

const getConcepts = state => state.firebase.data.concepts;
export const getConceptsAsDropdown = createSelector(getConcepts, objectToDropdownOptions);

const getKeywords = state => state.firebase.data.keywords;
export const getKeywordsAsDropdown = createSelector(getKeywords, objectToDropdownOptions);

const getObjectiveSets = state => state.firebase.data.objective_sets;
export const getObjectiveSetsAsDropdown = createSelector(getObjectiveSets, objectToDropdownOptions);

const getOiiObjectives = state => state.firebase.data.oii_objectives;
export const getOiiObjectivesAsDropdown = createSelector(getOiiObjectives, objectToDropdownOptions);

export const getTopics = state => state.firebase.data.topics;
export const getTopicsAsArray = createSelector(getTopics, mapCollectionToList);
export const getTopicAsList = createSelector(getTopics, (topics) => {
    if (topics) {
        return convertDatasetToList(topics);
    } else {
        return [];
    }
});

const getHeadings = state => state.firebase.data.headings;
export const getHeadingsAsDropdown = createSelector(getHeadings, objectToDropdownOptions);

const getSubHeadings = state => state.firebase.data.subheadings;
export const getSubHeadingsAsDropdown = createSelector(getSubHeadings, objectToDropdownOptions);

export const isDialogOpen = state => state.topics.showDialog;
export const isProcessing = state => state.topics.isProcessing;
