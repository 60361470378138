import React from "react";
import { Menu } from "semantic-ui-react";

import { SideBarMenuLink } from "../../component/SidebarMenuLink";
import AccessControl from "../../auth/AccessControl";


const MaintenanceMenuItem = () => (
    <Menu.Item>
        <Menu.Header>Maintenance</Menu.Header>
        <Menu.Menu>
            <SideBarMenuLink to="/tenants" text="Tenants" />
        </Menu.Menu>
    </Menu.Item>
);

const MaintenanceMenu = () => (
    <AccessControl actionArray={["tenant:manage"]}>
        <MaintenanceMenuItem />
    </AccessControl>
);

export default MaintenanceMenu;
