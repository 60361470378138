import { createSelector } from "reselect";
import { fetchKitsData } from "../../KitBuilder/AKits/AKitsList/AKitsListSelectors";
import { getCvGuid, removeEmpty } from "../../utils/selectorUtils";
import { compareByEntityAttribute, nullsLast } from "../../utils/compareFunctions";
import { getCourseVariantDetails } from "../../KitBuilder/AKits/CreateKit/CreateKitSelectors";

export const getCourseVariants = state => state.external.cv.cvList;
const getAllCourseVariants = state => state.external.cv.allCvList;
export const isLoading = state => state.external.cv.isLoading;
const courseVariant = state => state.external.cv.courseVariant;

export const getEditedCvId = state => state.external.cv.editedCvId;
export const getCourseVariantsAsArray = createSelector(
    getCourseVariants,
    fetchKitsData,
    (courseVariants, kitsData) => {
        let cvUsageMap = {};
        if (kitsData) {
            Object.keys(kitsData).map(kitId => {
                if (kitsData[kitId].course_variants) {
                    Object.keys(kitsData[kitId].course_variants).map(cvGuid => {
                        cvUsageMap[cvGuid] = { kitId, kitName: kitsData[kitId].name };
                    });
                }
            });
        }
        return courseVariants.map(cv => {
            removeEmpty(cv);
            return (cvUsageMap[getCvGuid(cv)] ? { ...cv, kit: cvUsageMap[getCvGuid(cv)] } : cv);
        });
    }
);

export const getAllCourseVariantsAsDropdownWithSporkId = createSelector(
    getCourseVariants,
    (course_variants) => {
        const courseVariants = course_variants
            .sort(compareByEntityAttribute("name", nullsLast, value => value.toLocaleLowerCase().trim()));
        return courseVariants.map(courseVariant => ({
            key: courseVariant.id,
            value: courseVariant.id,
            text: courseVariant.name,
            description: getCourseVariantDetails(courseVariant),
        }));
    }
);

export const getCourseVariantsById = createSelector(
    getAllCourseVariants,
    (variants) => {
        const data = {};
        if (variants) {
            variants.map(v => data[v.id] = `${v.name} ${getCourseVariantDetails({...v, tenant: null})}`);
        }
        return data;
    }
);

export const getCourseVariant = createSelector(
    courseVariant,
    (variant) => {
        if (variant) {
            return `${variant.name} ${getCourseVariantDetails({...variant, tenant: null})}`;
        }
    }
);
