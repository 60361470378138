import { connect } from "react-redux";
import { compose } from "redux";
import TopicTreeSelector from "./TopicTreeSelector";
import { withFirebase } from "react-redux-firebase";
import { selectedCollection, getTopicCollectionTree, searchFilter, showOnlySelected } from "../TopicSelector/topicSelectorSelectors";
import { onSelectCollection, onSearchChanged, onShowOnlySelected } from "../TopicSelector/topicSelectorActions";
import { getTopicCollectionListAsDropdown } from "../topicCollectionSelectors";
import { onExpandTopic } from "../topicCollectionActions";
import { lessonFilter } from "./TopicTreeSelectorSelectors";
import { onSetLessonFilter } from "./TopicTreeSelectorActions";

const mapStateToProps = function(state, props) {
    return {
        searchFilter: searchFilter(state),
        selectedCollection: selectedCollection(state),
        collections: getTopicCollectionListAsDropdown(state),
        collectionTree: getTopicCollectionTree(state, props),
        loading: !state.firebase.data.topic_collections || undefined === state.firebase.data.topic_collections.data,
        showOnlySelected: showOnlySelected(state, props),
        lessonFilter: lessonFilter(state),
    };
};

const dispatchToProps = {
    onSelectCollection,
    onExpandTopic,
    onSearchChanged,
    onShowOnlySelected,
    onSetLessonFilter,
};
export default compose(
    withFirebase,
    connect(
        (state, props) => {
            const filter = lessonFilter(state);
            if (props.enableLessonFilter && filter) {
                return { filterOnly: filter.topics || {}};
            }
            return {};
        },
    ),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(TopicTreeSelector);
