import { Item } from "semantic-ui-react";
import React from "react";
import UserAvatar from "react-user-avatar";
import PropTypes from "prop-types";

export default class UserInfo extends React.Component {
    static propTypes = {
        userName: PropTypes.string,
        meta: PropTypes.node,
        description: PropTypes.node
    };

    render() {
        const { userName, meta, description } = this.props;
        return (
            <Item.Group style={{margin: 0}}>
                <Item style={{display: "flex", alignItems: "flex-start"}}>
                    <UserAvatar size="64" name={userName} style={{fontSize: "2em", marginRight: "1em", float: "left"}} />                          
                    <Item.Content>
                        <Item.Header>
                            {userName}
                        </Item.Header>
                        <Item.Meta>
                            {meta}
                        </Item.Meta>
                        <Item.Description>
                            {description}
                        </Item.Description>
                    </Item.Content>
                </Item>
            </Item.Group>
        );
    }

}