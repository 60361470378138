import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTagsOptions } from "../../../component/ResourceLink/ResourceLinkSelectors";
import ResourceTagsEditor from "./ResourceTagsEditor";
import { getTagNames } from "./ResourceTagsEditorSelectors";
import { onSaveTags } from "./ResourceTagsEditorActions";

const dispatchToProps = (dispatch) => bindActionCreators({
    onSaveTags,
}, dispatch);

export default connect(
    (state, props) => ({ 
        tagsOptions: getTagsOptions(state),
        tagNames: getTagNames(state, props)
    }),
    dispatchToProps
)(ResourceTagsEditor);