import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { spinnerWhileLoading } from "../../../component/spinnerWhileLoading.jsx";
import * as ConceptsActions from "./conceptsActions";
import { onAddConcept } from "../../topicsActions";
import { getOrderedConceptsAsArray } from "./conceptsSelectors";

import Concepts from "./Concepts";

const mapStateToProps = function(state) {

    return {
        concepts: getOrderedConceptsAsArray(state),
        error: state.concepts.concepts.error,
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...ConceptsActions, 
            onAddConcept,
        },
        dispatch
    );
};

export default compose(
    firebaseConnect([ "concepts" ]),
    connect(mapStateToProps, dispatchToProps),
    // show loading spinner while data are loading
    spinnerWhileLoading([ "concepts" ])
)(Concepts);