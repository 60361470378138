import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import AddExistingProblemSet from "./AddExistingProblemSet";
import { spinnerWhileLoading } from "../../../../component/spinnerWhileLoading";
import { getFilteredProblemSets, titleFilter, isChecking, checkResult } from "./AddExistingProblemSetSelectors";
import { onSetTitleFilter, onSetProblemSet, onCheckedProblemSet, onCloneProblemSet } from "../LessonProblemSetActions";
import { firebaseConnect } from "react-redux-firebase";
import { makeGetLessonProblemSets } from "../../LessonContentSelectors";
import { getProblemSets } from "../../../../QuestionUtilizator/ProblemSetManager/problemSetManagerSelectors";


const mapStateToPropsFactory = () => {
    const getLessonProblemSets = makeGetLessonProblemSets();
    return (state, props) => ({
        filteredProblemSets: getFilteredProblemSets(state, props),
        titleFilter: titleFilter(state),
        lessonProblemSets: getLessonProblemSets(state, props),
        allProblemSets: getProblemSets(state, props),
        checkResult: checkResult(state),
        isChecking: isChecking(state),
    });
};

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            onSetTitleFilter,
            onSetProblemSet,
            onCheckedProblemSet,
            onCloneProblemSet,
        },
        dispatch
    );

export default compose(
    firebaseConnect(["problemSet"]),
    connect(mapStateToPropsFactory, dispatchToProps),
    spinnerWhileLoading(["allProblemSets"])
)(AddExistingProblemSet);
