import firebase from "../../../firebase";

export const getHighestOrdering = (lessonProblemSets) => {
    let ordering = 0;
    lessonProblemSets && lessonProblemSets.map(ps => {
        if (ps.ordering > ordering) {
            ordering = ps.ordering;
        }
    });
    return ordering;
};

export const checkProblemSetCompatibility = async (problemSetId, outlineId) => {
    const problemSetRef = firebase.getFirebaseData("problemSet/" + problemSetId);
    const problemSet = (await problemSetRef.once("value")).val();
    let linked_outlines = {};
    let result = true;
    if (problemSet && problemSet.problems) {
        for (const problemId of problemSet.problems) {
            const problemRef = firebase.getFirebaseData("problem/" + problemId);
            const problem = (await problemRef.once("value")).val();
            if (problem) {
                if (problem.question && problem.question.linked_outlines) {
                    linked_outlines = { ...linked_outlines, ...problem.question.linked_outlines };
                }
                if (problem.answer && problem.answer.linked_outlines) {
                    linked_outlines = { ...problem.answer.linked_outlines, ...linked_outlines };
                }
            }
        }
        Object.keys(linked_outlines).map(oId => {
            if (oId !== outlineId) {
                result = false;
            }
        });
    }
    return result;
};
