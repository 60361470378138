import { fetchKitsData } from "../../KitBuilder/AKits/AKitsList/AKitsListSelectors";
import { createSelector } from "reselect";
import { mapCollectionToList } from "../../utils/selectorUtils";
import { getUsersAsArray } from "../../Users/UserList/UserListSelectors";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";

export const inProgress = state => state.maintenance.iplannerGenerator.inProgress;
export const getProgress = state => state.maintenance.iplannerGenerator.progress;

export const getAKitsOptions = createSelector(
    fetchKitsData,
    (rawData) => {
        if (rawData) {
            const kits_data = mapCollectionToList(rawData);
            return kits_data.map(kit => ({ key: kit.key, value: kit.key, text: kit.name }));
        }
    }
);

export const getUsersOptions = createSelector(
    getUsersAsArray,
    tenantId,
    (users, tenantId) => {
        const usersOptions = [];
        users.map(u => {
            if (u.valid && u.tenant_id === tenantId) {
                usersOptions.push({
                    key: u.uid,
                    value: u.uid,
                    text: `${u.first_name} ${u.last_name}`,
                    description: u.email,
                });
            }
        });
        return usersOptions;
    }
);


