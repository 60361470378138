import { combineReducers } from "redux";
import CourseVariantsReducer from "./CourseVariantList/CourseVariantsReducer";
import SchoolReducer from "./SchoolList/schoolReducer";
import SchoolCourseReducer from "./SchoolCourse/schoolCourseReducer";
import UserCoursesReducer from "./UserCourses/UserCoursesReducer";
import SchoolListItemReducer from "./UserCourses/component/SchoolListItem/SchoolListItemReducer";
import SectionListItemReducer from "./UserCourses/component/SectionListItem/SectionListItemReducer";
import SchoolCoursesReducer from "./SchoolCourses/SchoolCoursesReducer";
import TenantsReducer from "./Tenants/tenantReducer";

export default combineReducers({
    cv: CourseVariantsReducer,
    school: SchoolReducer,
    sc: SchoolCourseReducer,
    users: UserCoursesReducer,
    schools: SchoolListItemReducer,
    sections: SectionListItemReducer,
    courses: SchoolCoursesReducer,
    tenant: TenantsReducer,
});
