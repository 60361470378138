export { default as default } from "./Widget";
export { default as Widget } from "./Widget";
export { default as WidgetItem } from "./WidgetItem";
export { default as WidgetItems } from "./WidgetItems";

export { default as WidgetViewer } from "./WidgetViewer";

export { default as previewContent } from "./previewContent";

export { default as orderedByPosition } from "./orderedByPosition";

export const itemTypes = {
    warn: {
        icon: "warning",
        title: "Unknown type of item",
        error: true
    },
    text: {
        icon: "file text",
        title: "Text",
    },
    link: {
        icon: "linkify",
        title: "Link",
    },
    file: {
        icon: "download",
        title: "File",
    },
    question: {
        icon: "question",
        title: "Question",
    },
};

export const initialData = {
    text: {
        name: "New Text Item",
        type: "text",
        header: { title: "Sample Text" },
        scope: "all"
    },
    link: {
        name: "New Link Item",
        type: "link",
        scope: "all",
        header: { title: "Resource" },
    },
    file: {
        name: "New File Item",
        type: "file",
        scope: "all",
        header: { title: "Attachment" },
    },
    question: {
        name: "New Question Item",
        type: "question",
        scope: "all",
    },
};

