const TYPES = {
    ADMIN_LOG_WRITE: "Admin/LOG_WRITE",
    ADMIN_LOG_CLEAR: "Admin/LOG_CLEAR",
    ADMIN_EXECUTE: "Admin/EXECUTE",
    ADMIN_SUCCESS: "Admin/SUCCESS",
    ADMIN_FAILURE: "Admin/FAILURE",
    ADMIN_CLEANUP: "Admin/CLEANUP",
};

const onLogClear = () => ({
    type: TYPES.ADMIN_LOG_CLEAR,
});

const onLogWrite = (text, data, type = "debug") => ({
    type: TYPES.ADMIN_LOG_WRITE,
    payload: { type, text, data, time: Date.now() }
});
onLogWrite.Message = (text, data) => onLogWrite(text, data, "message");
onLogWrite.Success = (text, data) => onLogWrite(text, data, "success");
onLogWrite.Failure = (text, data) => onLogWrite(text, data, "failure");
onLogWrite.Warning = (text, data) => onLogWrite(text, data, "warning");

const onAdminExecute = (action) => ({
    type: TYPES.ADMIN_EXECUTE,
    payload: action,
});

const onAdminSuccess = (message) => ({
    type: TYPES.ADMIN_SUCCESS,
    payload: message,
});

const onAdminFailure = (message) => ({
    type: TYPES.ADMIN_FAILURE,
    payload: message,
});

const onAdminCleanup = () => ({
    type: TYPES.ADMIN_CLEANUP,
});

export {
    TYPES,
    onLogClear,
    onLogWrite,
    onAdminExecute,
    onAdminFailure,
    onAdminSuccess,
    onAdminCleanup,
};
