import React from "react";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";
import { getSelectedBlocksMap } from "draftjs-utils";
import { Popup, Menu } from "semantic-ui-react";
import { LIST_NUMBERING_TYPE, LIST_PROPERTIES, LIST_BULLET_SYMBOL } from "../constants";

class ListItemPopup extends React.Component {

    static listItemStyle = {
        width: "35px",
        float: "left",
        textAlign: "center",
        marginRight: "1em",
        color: "dimgray",
    }

    static propTypes = {
        editorState: PropTypes.object.isRequired,
        setEditorState: PropTypes.func.isRequired,
        contextNode: PropTypes.instanceOf(Element).isRequired,
        onClose: PropTypes.func.isRequired,
    };

    state = { isOpened: true };

    handleMenuClick = (e, {value}) => {
        this.setSelectedListItemsProperties(value);
        this.handleClose();
    };

    setSelectedListItemsProperties = (numberingType) => {
        const { editorState, setEditorState } = this.props;
        const contentState = editorState.getCurrentContent();
        let blockMap = contentState.getBlockMap();

        const blocks = getSelectedBlocksMap(editorState).map((block) => {
            const data = block.getData().merge({
                [LIST_PROPERTIES.NUMBERING_TYPE]: numberingType,
            });
            return block.set("data", data);
        });

        blockMap = blockMap.merge(blocks);
        const newContentState = contentState.merge({ blockMap });
        setEditorState(EditorState.push(editorState, newContentState, "adjust-depth"));
    };

    handleClose = () => {
        this.setState({ isOpened: false });
        this.props.onClose();
    };

    renderBulletSymbol = (numberingType) => (
        <span style={{ fontSize: "16px", lineHeight: "12px" }}>{LIST_BULLET_SYMBOL[numberingType]}</span>
    );

    render() {
        const { contextNode } = this.props;

        return (
            <Popup context={contextNode} position="top left" open={this.state.isOpened} onClose={this.handleClose} verticalOffset={-10}>
                <Popup.Header style={{marginBottom: "0.6em", borderBottom: "1px solid grey", paddingBottom: "5px"}}>
                    List Type
                </Popup.Header>
                <Menu secondary vertical compact >
                    <Menu.Item onClick={this.handleMenuClick} value={LIST_NUMBERING_TYPE.LOWER_ALPHA}>
                        <span style={ListItemPopup.listItemStyle}>a,b,c</span>Lower Alpha
                    </Menu.Item>
                    <Menu.Item onClick={this.handleMenuClick} value={LIST_NUMBERING_TYPE.DECIMAL}>
                        <span style={ListItemPopup.listItemStyle}>1,2,3</span>Decimal
                    </Menu.Item>
                    <Menu.Item onClick={this.handleMenuClick} value={LIST_NUMBERING_TYPE.DISC}>
                        <span style={ListItemPopup.listItemStyle}>{this.renderBulletSymbol(LIST_NUMBERING_TYPE.DISC)}</span>Disc 
                    </Menu.Item>
                    <Menu.Item onClick={this.handleMenuClick} value={LIST_NUMBERING_TYPE.CIRCLE}>
                        <span style={ListItemPopup.listItemStyle}>{this.renderBulletSymbol(LIST_NUMBERING_TYPE.CIRCLE)}</span>Circle
                    </Menu.Item>
                    <Menu.Item onClick={this.handleMenuClick} value={LIST_NUMBERING_TYPE.SQUARE}>
                        <span style={ListItemPopup.listItemStyle}>{this.renderBulletSymbol(LIST_NUMBERING_TYPE.SQUARE)}</span>Square
                    </Menu.Item>
                    <Menu.Item onClick={this.handleMenuClick} value={LIST_NUMBERING_TYPE.PARAGRAPH}>
                        <span style={ListItemPopup.listItemStyle}>{"\u21b3 \u00B6"}</span>Nested Paragraph
                    </Menu.Item>
                </Menu>
            </Popup>
        );
    }
}

export default ListItemPopup;
