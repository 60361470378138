import React from "react";
import PropTypes from "prop-types";
import { Button, Container, Icon, Popup, Grid, Dimmer, Header, Menu, Pagination, Loader } from "semantic-ui-react";

import QuestionEditor from "../QuestionEditor";
import ProblemSetWizard from "../ProblemSetWizard";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import ActionLinks from "../../component/ActionLinks";
import AssignTagsTopics from "./AssignTagsTopics";
import RemoveTagsTopics from "./RemoveTagsTopics";
import AccessControl from "../../auth/AccessControl";
import AdvancedFilterAsync from "../../KitBuilder/component/AdvancedFilterAsync";
import ProblemList from "./ProblemList";
import FilteredByOptionsView from "../../KitBuilder/component/FilteredByOptionsView";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import { maxItemsPerPage } from "./problemManagementSelectors";
import { isProblemUnused, getAccessRights, getProblemEntityName, getProblemParentName, getProblemAuthorId } from "./ProblemUtils";
import { toastWarning } from "../../component/toast";

const findProblemById = (entries, id) => (
    id && Array.isArray(entries) && entries.find((problem) => problem.id === id)
);

const toggleShowOnlySelected = (state) => ({ showOnlySelected: !state.showOnlySelected });

export default class ProblemManagement extends React.PureComponent {

    static propTypes = {
        problems: PropTypes.array,
        gridOptions: PropTypes.shape({
            options: PropTypes.array,
            columns: PropTypes.array,
        }).isRequired,
        isLoading: PropTypes.bool,
        onCreateQuestion: PropTypes.func.isRequired,
        onDeleteQuestion: PropTypes.func.isRequired,
        onEditQuestion: PropTypes.func.isRequired,
        onSetOutlineForLessonLinks: PropTypes.func,
        searchResults: PropTypes.object,
        startAt: PropTypes.string,
        reverseFetch: PropTypes.bool,
        onNextPage: PropTypes.func,
        onPrevPage: PropTypes.func,
        totalPages: PropTypes.number,
        itemsPerPage: PropTypes.number,
        usedTags: PropTypes.array,
        onGetAllCount: PropTypes.func,
        // propagate item selection into parent component
        onSelectedItems: PropTypes.func,
        // if problem list is shown only as a selector, some action icons/links will be disabled
        showAsSelector: PropTypes.bool,
        onApplyFilterBy: PropTypes.func,
        onResetFilterBy: PropTypes.func.isRequired,
        // mark questions which belong to this problem set
        filterProblemSetId: PropTypes.string,
        totalRecords: PropTypes.number,
        onSelectTag: PropTypes.func,
        onSelectTopic: PropTypes.func,
        selectedTags: PropTypes.array,
        selectedTopic: PropTypes.string,
        topicFilterName: PropTypes.string,
        dispatch: PropTypes.func,
        fbCollection: PropTypes.string.isRequired,
        allowedAuthorId: PropTypes.string,
    };

    state = {
        selectedItems: {},
        selectedPopupVisible: false,
        showProblemSetDialog: false,
        showTagsTopicsDialog: null,
        showQuestionEditor: false,
        showOnlySelected: false,
        editItem: null,
        currentPage: 1,
    };

    componentDidMount() {
        const { onSetOutlineForLessonLinks, onGetAllCount, onResetFilterBy } = this.props;
        onResetFilterBy();
        onSetOutlineForLessonLinks(null);
        onGetAllCount && onGetAllCount();
    }

    componentWillUnmount() {
        const { dispatch, showAsSelector } = this.props;
        if (!showAsSelector) {
            // @TODO: are we sure we want to delete these collections from Redux store?
            // If yes, the same should be applied to "comptests_cvault", "comptests_common" and "tags_questions";
            // Otherwise remove this code.
            dispatch({type: "@@reactReduxFirebase/SET", path: "problem"});
            dispatch({type: "@@reactReduxFirebase/SET", path: "problemSet"});
            dispatch({type: "@@reactReduxFirebase/SET", path: "tags_problems"});
        }
    }


    handleToggleSelected = (e, { id, checked }) => {
        const { onSelectedItems } = this.props;
        const selectedItems = { ...this.state.selectedItems }; // eslint-disable-line react/no-access-state-in-setstate
        if (checked) {
            selectedItems[id] = true;
        } else {
            delete selectedItems[id];
        }
        this.setState({ selectedItems }, onSelectedItems ? () => onSelectedItems(selectedItems) : undefined);
    };

    handleCreateQuestion = () => this.setState({ showQuestionEditor: true });
    handleModifyQuestion = (e, { data }) => {
        const editItem = findProblemById(this.props.problems, data);
        editItem && this.setState({ editItem, showQuestionEditor: true });
    }
    handleReviewQuestion = (e, { data }) => {
        const editItem = findProblemById(this.props.problems, data);
        editItem && this.setState({ editItem, showQuestionEditor: "review" });
    }
    handleCloseQuestion = () => {
        this.setState({ editItem: undefined, showQuestionEditor: false });
    }

    handleProblemSetNew = () => this.setState({ showProblemSetDialog: true, selectedPopupVisible: false });
    handleProblemSetClose = () => this.setState({ showProblemSetDialog: false, selectedItems: {} });

    handleRemove = (e, id) => {
        if (!this.state.removeId) {
            this.setState({
                removeContext: e.currentTarget,
                removeId: id,
            });
        }
    };

    handleCancelRemove = () => {
        this.setState({ removeId: null, removeContext: null });
    };

    handleRemoveConfirm = () => {
        const { onDeleteQuestion, problems, fbCollection, allowedAuthorId } = this.props;
        const { removeId } = this.state;
        const itemToDelete = removeId && findProblemById(problems, removeId);
        if (itemToDelete) {
            if (allowedAuthorId && allowedAuthorId !== getProblemAuthorId(itemToDelete)) {
                toastWarning({
                    header: `Unable to remove this ${getProblemEntityName(fbCollection)}`,
                    message: "You are not author/owner of it.",
                    icon: "ban",
                });
            } else if (isProblemUnused(itemToDelete)) {
                this.handleToggleSelected(null, { id: removeId, checked: false });
                onDeleteQuestion && onDeleteQuestion(removeId);
            } else {
                toastWarning({
                    header: `Unable to remove this ${getProblemEntityName(fbCollection)}`,
                    message: `It is being used by at least 1 ${getProblemParentName(fbCollection)}.`,
                    icon: "ban",
                });
            }
        }
        this.handleCancelRemove();
    };

    handleDeleteSelected = () => {
        const { onDeleteQuestion, problems, allowedAuthorId, fbCollection } = this.props;
        const selectedItems = { ...this.state.selectedItems }; // eslint-disable-line react/no-access-state-in-setstate
        const itemsToDelete = problems.filter((problem) => selectedItems[problem.id]);

        const status = itemsToDelete.reduce((acc, problem) => {
            if (allowedAuthorId && allowedAuthorId !== getProblemAuthorId(problem)) {
                acc.notAuthor = true;
            } else if (isProblemUnused(problem)) {
                delete selectedItems[problem.id];
                onDeleteQuestion && onDeleteQuestion(problem.id);
            } else {
                acc.notUnused = true;
            }
            return acc;
        }, {});
        this.setState({ selectedItems, selectedPopupVisible: false, });
        if (status.notAuthor || status.notUnused) {
            const entityName = getProblemEntityName(fbCollection);
            const message = [
                status.notUnused && `The selected ${entityName}(s) may be used by one or more ${getProblemParentName(fbCollection)}(s).`,
                status.notAuthor && `You are not the author/owner of all ${entityName}s.`,
            ].filter(Boolean).join(" ");
            toastWarning({
                header: `Unable to remove all selected ${entityName}(s)`,
                message, icon: "ban",
            });
        }
    };

    handleShowOnlySelected = () => this.setState(toggleShowOnlySelected);
    handleSelectedPopupOpen = () => this.setState({ selectedPopupVisible: true });
    handleSelectedPopupClose = () => this.setState({ selectedPopupVisible: false });

    handleAssignTagsTopics = () => this.setState({ showTagsTopicsDialog: "assign", selectedPopupVisible: false });
    handleRemoveTagsTopics = () => this.setState({ showTagsTopicsDialog: "remove", selectedPopupVisible: false });
    handleCloseTagsTopics = () => this.setState({ showTagsTopicsDialog: null });

    handlePageChange = (e, {name}) => {
        const { problems } = this.props;
        const { currentPage } = this.state;

        if (name == "Next"){
            const lastOne = problems[problems.length - 1].id;
            this.setState({ currentPage: currentPage + 1, selectedItems: {} }, () => this.props.onNextPage(lastOne));
        } else if (name == "Previous") {
            const firstOne = problems[0].id;
            this.setState({ currentPage : currentPage - 1, selectedItems: {} }, () => this.props.onPrevPage(firstOne));
        }
    };

    handlePageChangeSearchResults = (e, { activePage }) => {
        const { onApplyFilterBy, itemsPerPage, selectedTopic, selectedTags } = this.props;
        this.setState({ selectedItems: {}, currentPage: activePage});
        onApplyFilterBy({ selectedTags, selectedTopic }, activePage, itemsPerPage);
    };

    handleApplyFilter = (payload) => {
        const { onApplyFilterBy, itemsPerPage, onResetFilterBy } = this.props;
        const { selectedTopic, selectedTags } = payload || {};
        this.setState({ currentPage: 1, selectedItems: {} });
        if (!selectedTopic && !(selectedTags && selectedTags.length)) {
            onResetFilterBy();
        }
        onApplyFilterBy(payload, 1, itemsPerPage);
    }

    handleSelectTag = (selectedTags) => {
        const { selectedTopic } = this.props;
        this.handleApplyFilter({ selectedTags, selectedTopic });
    }

    handleSelectTopic = (selectedTopic) => {
        const { selectedTags } = this.props;
        this.handleApplyFilter({ selectedTags, selectedTopic });
    }

    isAllSelected = () => {
        const { problems } = this.props;
        const { selectedItems } = this.state;
        const selectedItemsCount = Object.keys(selectedItems).length;
        const searchResultsLength = problems && problems.filter(p => !p.lock).length || 0;
        return 0 < selectedItemsCount && selectedItemsCount === searchResultsLength ? true : false;
    }

    handleSelectDeselectAll = () => {
        const { problems } = this.props;
        if (this.isAllSelected()) {
            this.setState({ selectedItems: {} }, () => {
                if (this.props.onSelectedItems) {
                    this.props.onSelectedItems({});
                }
            });
        } else {
            const selectedItems = {};
            for (const problem of problems) {
                if (!problem.lock) {
                    selectedItems[problem.id] = true;
                }
            }
            this.setState({ selectedItems }, () => {
                if (this.props.onSelectedItems) {
                    this.props.onSelectedItems(selectedItems);
                }
            });
        }
    }

    getRandomKey = () => Math.random().toString(36).substring(7);

    renderWithSelected(fbCollection) {
        return (
            <Popup
                flowing={true}
                trigger={<span>With {Object.keys(this.state.selectedItems).length || ""} selected...</span>}
                on="click"
                open={this.state.selectedPopupVisible}
                onClose={this.handleSelectedPopupClose}
                onOpen={this.handleSelectedPopupOpen}
                content={
                    <Grid centered divided columns={"problemSet" === fbCollection ? 4 : 3}>
                        <Grid.Column textAlign="center" verticalAlign="middle">
                            <Button fluid content="Assign Tags/Topics" onClick={this.handleAssignTagsTopics} />
                        </Grid.Column>
                        <Grid.Column textAlign="center" verticalAlign="middle">
                            <Button fluid content="Remove Tags/Topics" onClick={this.handleRemoveTagsTopics} />
                        </Grid.Column>
                        {"problemSet" === fbCollection ? (
                            <Grid.Column textAlign="center" verticalAlign="middle">
                                <Button fluid content="Create new set" onClick={this.handleProblemSetNew} />
                            </Grid.Column>
                        ) : null}
                        <Grid.Column textAlign="center" verticalAlign="middle">
                            <Button fluid color="red" content="Delete" onClick={this.handleDeleteSelected} />
                        </Grid.Column>
                    </Grid>
                }
                position="top right"
            />
        );
    }

    handleRefresh = () => this.handlePageChangeSearchResults(null, { activePage: this.state.currentPage });

    render() {
        const {
            problems,
            onEditQuestion,
            gridOptions,
            isLoading,
            totalPages,
            totalRecords,
            usedTags,
            showAsSelector,
            selectedTopic,
            selectedTags,
            topicFilterName,
            onCreateQuestion,
            fbCollection,
            itemsPerPage,
        } = this.props;

        const {
            showQuestionEditor,
            showTagsTopicsDialog,
            showProblemSetDialog,
            editItem,
            selectedItems,
            showOnlySelected,
            removeContext,
            currentPage,
        } = this.state;
        const actions = [
            {
                name: "show",
                content: showOnlySelected ? "Show all" : "Show only selected",
                onClick: this.handleShowOnlySelected,
            }
        ];

        const pageTitle = fbCollection === "problemSet" ? "Problems" : "Questions";
        const entityName = fbCollection === "problemSet" ? "problem" : "question";
        const needRights = getAccessRights(fbCollection);

        if (problems && problems.length) {
            actions.push({
                name: "select",
                content: (this.isAllSelected() ? "Deselect" : "Select") + " all displayed problems",
                onClick: this.handleSelectDeselectAll,
            });
        }
        return (
            <React.Fragment>
                <Container onClick={this.handleContainerClicked} style={{ marginBottom: "5px" }}>
                    <Container id="problemTable">
                        {!showAsSelector && <Header as="h2" className="pageHeader">{pageTitle}</Header>}
                        <Menu borderless secondary stackable className="filterMenuBackground">
                            <Menu.Item fitted>
                                <AdvancedFilterAsync
                                    usedTags={usedTags}
                                    entityName="problem"
                                    onApplyFilterBy={this.handleApplyFilter}
                                    selectedTags={selectedTags}
                                    selectedTopic={selectedTopic}
                                />
                            </Menu.Item>
                            {!showAsSelector && (
                                <AccessControl action={needRights.create}>
                                    <Menu.Item onClick={this.handleCreateQuestion}>
                                        <Icon name="plus" />New
                                    </Menu.Item>
                                </AccessControl>
                            )}
                            <Menu.Item onClick={this.handleRefresh}>
                                <Icon name="refresh" />Refresh
                            </Menu.Item>
                            <Menu.Item position="right" fitted>
                                {totalRecords || 0} items found.
                            </Menu.Item>
                        </Menu>
                        {(selectedTags && 0 < selectedTags.length || topicFilterName ) &&
                            <FilteredByOptionsView
                                selectedTags={selectedTags}
                                onSelectTag={this.handleSelectTag}
                                topicFilterName={topicFilterName}
                                onSelectTopic={this.handleSelectTopic}
                            />
                        }
                        <div>
                            {!showAsSelector && (
                                <AccessControl action={needRights.withSelected}>
                                    <ActionLinks
                                        floated="right"
                                        horizontal
                                        actions={[
                                            {
                                                name: "popup",
                                                content: this.renderWithSelected(fbCollection),
                                                disabled: Object.keys(selectedItems).length === 0,
                                            },
                                        ]}
                                    />
                                </AccessControl>
                            )}
                            <ActionLinks
                                horizontal
                                actions={actions}
                            />
                        </div>
                        {isLoading ? (
                            <Dimmer.Dimmable dimmed>
                                <Dimmer active inverted>
                                    <Loader>Loading {entityName}s data...</Loader>
                                </Dimmer>
                                <div style={{height: "10em"}} />
                            </Dimmer.Dimmable> 
                        ) : totalRecords && totalRecords > 0 ? (
                            <React.Fragment>
                                <ProblemList
                                    fbCollection={fbCollection}
                                    problems={problems}
                                    gridOptions={gridOptions}
                                    showOnlySelected={showOnlySelected}
                                    selectedItems={selectedItems}
                                    getRow={this.getRow}
                                    onToggleSelected={this.handleToggleSelected}
                                    onEditQuestion={this.handleModifyQuestion}
                                    onReviewQuestion={this.handleReviewQuestion}
                                    onRemove={this.handleRemove}
                                    filterProblemSetId={this.props.filterProblemSetId}
                                />
                                {this.props.searchResults && Object.keys(this.props.searchResults).length > 0 ? (
                                    totalRecords > maxItemsPerPage && <Pagination floated="right"
                                        onPageChange={this.handlePageChangeSearchResults}
                                        defaultActivePage={currentPage || 1}
                                        totalPages={totalPages}
                                    />
                                ) : (
                                    <Menu pagination floated="right">
                                        <Menu.Item
                                            disabled={currentPage == 1}
                                            name="Previous"
                                            onClick={this.handlePageChange}>
                                            <Icon name="angle left" /> Prev.
                                        </Menu.Item>
                                        <Menu.Item
                                            disabled={totalRecords == 0 || totalRecords <= itemsPerPage }
                                            name="Next"
                                            onClick={this.handlePageChange}>
                                            Next <Icon name="angle right" />
                                        </Menu.Item>
                                    </Menu>
                                )}
                            </React.Fragment>
                        ) : (
                            <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
                                <p>We cannot find any Problems matching your filtering criteria.</p>
                            </NoDataPlaceholder>
                        )}
                    </Container>
                </Container>
                {showQuestionEditor && (
                    <QuestionEditor
                        fbCollection={fbCollection}
                        onAdd={onCreateQuestion}
                        onEdit={onEditQuestion}
                        editMode={editItem ? true : false}
                        onClose={this.handleCloseQuestion}
                        reviewModeOnly={showQuestionEditor === "review"}
                        {...editItem && { question: editItem }}
                    />
                )}
                {showTagsTopicsDialog === "assign" ? (
                    <AssignTagsTopics fbCollection={fbCollection} selected={selectedItems} onClose={this.handleCloseTagsTopics} />
                ) : showTagsTopicsDialog === "remove" ? (
                    <RemoveTagsTopics fbCollection={fbCollection} selected={selectedItems} onClose={this.handleCloseTagsTopics} />
                ) : null}
                {showProblemSetDialog && (
                    <ProblemSetWizard
                        open={true}
                        fbCollection={fbCollection}
                        selectedProblems={selectedItems}
                        onClose={this.handleProblemSetClose}
                    />
                )}
                <ConfirmDeleteWithContext
                    context={removeContext}
                    confirmText={`Remove ${entityName}`}
                    onConfirm={this.handleRemoveConfirm}
                    whatText={`this ${entityName}`}
                    onCancel={this.handleCancelRemove}
                />
            </React.Fragment>
        );
    }
}
