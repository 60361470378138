import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Grid, Message } from "semantic-ui-react";
import UserList from "./UserList";

export class ClassRoster extends PureComponent {
    static propTypes = {
        students: PropTypes.array,
        teachers: PropTypes.array,
        onSelectUser: PropTypes.func,
        isLoading: PropTypes.bool,
        readOnly: PropTypes.bool,
    };

    render() {
        const { students, teachers, onSelectUser, isLoading, readOnly } = this.props;
        return (
            <Message>
                <Header dividing style={{marginBottom: "1em"}}>Class roster</Header>

                <Grid columns="2" padded>
                    <Grid.Row>
                        <Grid.Column>
                            <UserList title="Students" iconClassName="users class outline" userList={students} onSelectUser={onSelectUser} isLoading={isLoading} readOnly={readOnly}/>
                        </Grid.Column>
                        <Grid.Column>
                            <UserList title="Teachers" iconClassName="chalkboard teacher outline" userList={teachers} onSelectUser={onSelectUser} isLoading={isLoading} readOnly={readOnly}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Message>

        );}
}