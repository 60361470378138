const TYPES = {
    MOVE_SET: "REORDER_PROBLEM_SETS_MOVE_SET",
};

const onMoveSet = (payload) => ({
    type: TYPES.MOVE_SET,
    payload
});

export {
    TYPES,
    onMoveSet,
};
