import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { getLessonPublishedStatus } from "../PublisherSelectors";
import { firebaseConnect, getVal } from "react-redux-firebase";
import LessonExports from "./LessonExports";
import { onExportToPdf } from "./LessonExportsActions";
import { isExportInProgress } from "./LessonExportsSelectors";

const getContentPaths = (props) => {
    const { outlineId, lessonId } = props;
    return outlineId && lessonId && {
        releaseContent: `outlines_published/${outlineId}/release/${lessonId}`,
    };
};

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            onExportToPdf,
        },
        dispatch
    );

const mapStateToProps = (state, props) => {
    const { firebase } = state;
    const paths = getContentPaths(props);
    const published = getLessonPublishedStatus(state, props);
    return paths && {
        releaseContent: getVal(firebase, "data/" + paths.releaseContent),
        loading: firebase.requesting[paths.releaseContent],
        release: published && published.release,
        isExportInProgress: isExportInProgress(state),
    };
};

export default compose(
    firebaseConnect((props) => {
        const paths = getContentPaths(props);
        return paths ? [ paths.releaseContent ] : [];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(LessonExports);
