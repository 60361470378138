import React from "react";
import { Popup, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { getContextPropsForPopup } from "../utils/domUtils";
import KeyboardShortcutsHelp from "./KeyboardShortcutsHelp";

class EditorHelpPopup extends React.Component {

    state = {
        isOpened: false,
        contextProps: {},
    };

    componentDidMount = () => {
        const { contextNode } = this.props;
        const contextProps = getContextPropsForPopup(contextNode);

        this.setState({
            isOpened: true,
            contextProps,
        });
    };

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const { contextHelp, offsetX = 0, offsetY = 0 } = this.props;
        const { isOpened, contextProps } = this.state;
        const { node, pos, hOffset, vOffset } = contextProps;
        const ContextHelpContent = contextHelp || KeyboardShortcutsHelp;

        if (!isOpened) {
            return null;
        }

        return (<Popup
            context={node}
            position={pos}
            horizontalOffset={hOffset + offsetX}
            verticalOffset={vOffset + offsetY}
            open={true}
            onClose={this.handleClose}
            flowing
        >
            <Popup.Header>Editor help</Popup.Header>
            <Popup.Content>
                <ContextHelpContent />
                <Button content="Close" fluid onClick={this.handleClose}/>
            </Popup.Content>
        </Popup>
        );
    }
}

EditorHelpPopup.propTypes = {
    contextNode: PropTypes.instanceOf(Element).isRequired,
    onClose: PropTypes.func.isRequired,
    contextHelp: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
};

export default EditorHelpPopup;