import React from "react";
import PropTypes from "prop-types";

import WidgetBlock from "./WidgetBlock";
import WidgetItems from "./WidgetItems";
import WidgetItem from "./WidgetItem";
import "./Widget.css";

export default class Widget extends React.Component {
    static Item = WidgetItem;
    static Items = WidgetItems;

    static propTypes = {
        /** Definition of widget structure template */
        template: PropTypes.object.isRequired,
        /** Allows to set filter items by callback (effective when no children) */
        filtered: PropTypes.func,
        /** Content of the items defined by template (effective when no children)  */
        content: PropTypes.object,
        /** Alternative to specify content of widget */
        children: PropTypes.node,
        onChangeTitle: PropTypes.func,
        customTitle: PropTypes.string,
        hiddenTitle: PropTypes.bool,
    };

    render() {
        const { template, content, children, onChangeTitle, customTitle, hiddenTitle } = this.props;
        if (children) {
            return <WidgetBlock {...template.setup} id={template.key} onChangeTitle={onChangeTitle} customTitle={customTitle} hiddenTitle={hiddenTitle}>{children}</WidgetBlock>;
        }
        const { setup, items = {} } = template;
        return (
            <WidgetBlock {...setup} id={template.key} onChangeTitle={onChangeTitle} customTitle={customTitle} hiddenTitle={hiddenTitle}>
                <WidgetItems template={items} content={content} filtered={this.props.filtered} />
            </WidgetBlock>
        );
    }
}
