import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import WidgetTemplateList from "./WidgetTemplateList";
import WidgetTemplateDetailContainer from "./WidgetTemplateDetailContainer";
import PageHeader from "../../component/PageHeader";
import AdvancedFilter from "../component/AdvancedFilter";
import FilteredByOptionsView from "../component/FilteredByOptionsView";

export default class WidgetTemplates extends React.Component {
    static propTypes = {
        selectedTemplate: PropTypes.object,
        onSelectTemplate: PropTypes.func.isRequired,
        onCreateTemplate: PropTypes.func.isRequired,
        isSuperAuthorAtLeast: PropTypes.bool,
        isNew: PropTypes.bool,
        searchFilter: PropTypes.string,
        usedTags: PropTypes.array,
        widgetTemplates: PropTypes.array,
        onDeleteTemplate: PropTypes.func,
        onSearchChanged: PropTypes.func,
        onSelectTag: PropTypes.func,
        selectedTags: PropTypes.array,
        match: PropTypes.object,
        moduleFilter: PropTypes.array,
        onSetModuleFilter: PropTypes.func,
    };

    componentDidMount = () => {
        const { match, isNew, onSelectTemplate, onSetModuleFilter } = this.props;
        onSetModuleFilter([]);
        if (match.params.selectedTemplateId) {
            onSelectTemplate(match.params.selectedTemplateId, isNew);
        } else {
            onSelectTemplate(null, false, false);
        }
    };

    componentDidUpdate = (prevProps) => {
        const { match } = this.props;
        if (prevProps.match.params.selectedTemplateId && !match.params.selectedTemplateId) {
            this.props.onSelectTemplate(null, false, false);
        } else if (!prevProps.match.params.selectedTemplateId && match.params.selectedTemplateId) {
            this.props.onSelectTemplate(match.params.selectedTemplateId, false, false);
        }
    }

    handleSelectTemplate = (e, item) => {
        this.props.onSelectTemplate(item.data);
    }

    handleSearchChange = (value) => {
        this.props.onSearchChanged(value);
    }

    handleSelectTag = (e, { data }) => {
        this.props.onSelectTag(data);
    }

    render() {
        const {
            selectedTemplate,
            isSuperAuthorAtLeast,
            isNew,
            searchFilter,
            usedTags,
            widgetTemplates,
            isSuperAuthorAtLeast: canEdit,
            onDeleteTemplate,
            onCreateTemplate,
            selectedTags,
            onSelectTag,
            moduleFilter,
            onSetModuleFilter,
        } = this.props;

        return selectedTemplate ? (
            <WidgetTemplateDetailContainer canEdit={isSuperAuthorAtLeast} selectedTemplate={selectedTemplate} isNew={isNew}/>
        ) : (
            <React.Fragment>
                <PageHeader
                    searchFilter={searchFilter}
                    onChangeFilter={this.handleSearchChange}
                    list={widgetTemplates}
                    headerText="Widget Templates"
                    entityName="template"
                >
                    <AdvancedFilter
                        usedTags={usedTags}
                        entityName="template"
                        selectedTags={selectedTags}
                        onSelectTag={onSelectTag}
                        onSetModuleFilter={onSetModuleFilter}
                        moduleFilter={moduleFilter}
                    />
                    {canEdit && <Menu.Item
                        content="New"
                        title="Click here to create a new widget template."
                        icon="plus"
                        onClick={onCreateTemplate}
                    />}
                    
                </PageHeader>
                {((selectedTags && 0 < selectedTags.length) || (moduleFilter && 0 < moduleFilter.length)) && 
                    <FilteredByOptionsView
                        selectedTags={selectedTags}
                        onSelectTag={onSelectTag}
                        onSetModuleFilter={onSetModuleFilter}
                        moduleFilter={moduleFilter}
                    />
                }
                <WidgetTemplateList 
                    widgetTemplates={widgetTemplates}
                    onDeleteTemplate={onDeleteTemplate}
                    isSuperAuthorAtLeast={canEdit}
                    onSelectTemplate={this.handleSelectTemplate}
                />
            </React.Fragment>
        );
    }
}
