import { all, fork } from "redux-saga/effects";

import topicsSaga from "../Topics/topicsSaga";
import OsmtSaga from "../Topics/ObjectiveSetsManager/Saga";
import tttSaga from "../TopicTransformationTool/BeLATopicCollection/belaTopicCollectionSaga";
import ConceptsSaga from "../Topics/Concepts/Saga";
import YearsSaga from "../Year/YearsSaga";
import OutlinesSaga from "../KitBuilder/Saga";
import QuSaga from "../QuestionUtilizator/quSaga";
import TCSaga from "../TopicCollection/topicCollectionSaga";
import UserMgmtSaga from "../Users/Saga";
import ExternalSaga from "../External/Saga";
import MaintenanceSaga from "../Maintenance/Saga";
import SidebarMenuSaga from "../SidebarMenu/SidebarMenuSaga";
import LUPSaga from "../LessonUnitPlanner/Saga";
import lockSaga from "../Lock/lockSaga";

function* rootSaga() {
    yield all([
        fork(topicsSaga),
        fork(OsmtSaga),
        fork(tttSaga),
        fork(ConceptsSaga),
        fork(YearsSaga),
        fork(OutlinesSaga),
        fork(QuSaga),
        fork(UserMgmtSaga),
        fork(TCSaga),
        fork(ExternalSaga),
        fork(MaintenanceSaga),
        fork(SidebarMenuSaga),
        fork(LUPSaga),
        fork(lockSaga),
    ]);
}

export default rootSaga;
