import { put, takeLatest } from "redux-saga/effects";
import firebase from "../../../firebase";
import {
    TYPES,
    onWidgetLessonsSuccess,
    onWidgetLessonsFailure,
} from "./WidgetLessonsActions";
import { aKitManager } from "../../dataSource";

const readOutlinesDataForLessons = async (usedBy) => {
    const lessonIds = usedBy && Object.keys(usedBy);
    if (!(lessonIds && lessonIds.length)) {
        return null;
    }
    // This will read necessary outline sequentially.
    // It could be done in parallel by reading unique outline IDs and reading all at once.
    return lessonIds.reduce(async (outlines, lessonId) => {
        const outlineData = await outlines;
        const outlineId = usedBy[lessonId];
        if (typeof outlineId === "string" && !outlineData[outlineId]) {
            const outlineRef = firebase.getFirebaseData(`outlines_data/${outlineId}`);
            outlineData[outlineId] = (await outlineRef.once("value")).val();
        }
        return outlineData;
    }, Promise.resolve({}));
};

function* resolveWidgetUsedBy (usedBy) {
    const outlines = yield readOutlinesDataForLessons(usedBy);
    if (!outlines) {
        return null;
    }
    const links = [];
    yield * Object.keys(usedBy).map(async (lessonId) => {
        const outlineId = usedBy[lessonId];
        const outlineData = outlines[outlineId];
        if (outlineData) {
            const lesson = outlineData.lessons && outlineData.lessons[lessonId];
            if (lesson) {
                const lessonName = lesson.name;
                const kitId = outlineData.kit_id;
                const kitName = await aKitManager.getKitName(kitId);
                links.push({
                    lessonId,
                    lessonName,
                    outlineId,
                    kitName,
                });
            }
        }
    });
    return links.sort((l, r) => (
        (l.lessonName || "").localeCompare(r.lessonName || "") ||
        (l.kitName || "").localeCompare(r.kitName || "")
    ));
}

function* requestWidgetLessons({ payload: { widgetId, usedBy }}) {
    if (widgetId && usedBy) {
        const lessons = yield resolveWidgetUsedBy(usedBy);
        if (lessons) {
            const unresolved = Object.keys(usedBy).length - lessons.length;
            yield put(onWidgetLessonsSuccess(widgetId, lessons, unresolved || undefined));
        } else {
            yield put(onWidgetLessonsFailure(widgetId, "Failed to get widget lessons."));
        }
    } else if (widgetId) {
        yield put(onWidgetLessonsFailure(widgetId, "Widget is not used in any lesson."));
    }
}

export default function* saga() {
    yield takeLatest(TYPES.WIDGET_LESSONS_REQUEST, requestWidgetLessons);
}