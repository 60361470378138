import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import OrderedListItem from "./OrderedListItem";
import { LIST_NUMBERING_TYPE, LIST_PROPERTIES, LIST_BULLET_SYMBOL } from "../constants";
import { updateListItemDepth, updateListItemProperties } from "./listItemUtils";

const LETTERS = "abcdefghijklmnopqrstuvwxyz";


export default class ListItemWrapper extends React.Component {
    static propTypes = {
        getEditorState: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        showCustomComponent: PropTypes.func.isRequired,
        initiallyOrdered: PropTypes.bool.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        children: PropTypes.node,
        "data-offset-key": PropTypes.string,
    };

    counter = Array(5);  // DraftJS supports 5 nesting levels by default
    numberingTypePerDepth = Array(5);
    lastDepth = 0;

    resetCounter = (depth = 0) => this.counter.fill(0, depth);

    getCounterIndexAndIncrement = (depth) => {
        return this.counter[depth]++;
    };

    getCounterIndex = (listItemContent) => {
        const { block } = listItemContent.props;
        const depth = block.getDepth();
        return this.counter[depth];
    };

    getDefaultNumberingType = () => {
        const { initiallyOrdered } = this.props;
        return initiallyOrdered ? LIST_NUMBERING_TYPE.DECIMAL : LIST_NUMBERING_TYPE.DISC;
    };

    getBulletValue = (listItemContent) => {
        const { block } = listItemContent.props;
        const blockData = block.getData();
        const numberingType = blockData.get(LIST_PROPERTIES.NUMBERING_TYPE) || this.getDefaultNumberingType();
        let depth = block.getDepth();
        let bulletValue;

        if (depth > this.lastDepth) {
            this.resetCounter(depth);
        }

        this.lastDepth = depth;
        const index = this.getCounterIndexAndIncrement(depth);

        switch (numberingType) {
            case LIST_NUMBERING_TYPE.LOWER_ALPHA:
                bulletValue = LETTERS[index] + ".";
                break;
            case LIST_NUMBERING_TYPE.DECIMAL:
                bulletValue = String(index + 1) + ".";
                break;
            case LIST_NUMBERING_TYPE.PARAGRAPH:
                bulletValue = " ";
                break;
            default:
                bulletValue = LIST_BULLET_SYMBOL[numberingType];
        }

        return bulletValue || String(index + 1) + ".";
    };

    componentDidUpdate = () => {
        const { getEditorState, setEditorState, children } = this.props;
        let editorState = getEditorState();
        this.resetCounter();

        children.forEach((listItem) => {
            const listItemContent = listItem.props.children;
            const { block } = listItemContent.props;
            editorState = this.fixListItemConsistency(editorState, block);
        });

        if (editorState !== getEditorState()) {
            // FIX is necessary
            setEditorState(editorState);
        }
    };

    fixListItemConsistency = (editorState, block) => {
        const blockData = block.getData();
        const numberingType = blockData.get(LIST_PROPERTIES.NUMBERING_TYPE) || this.getDefaultNumberingType();
        let depth = block.getDepth();

        if (depth === 0 && this.counter[depth] === 0) {
            // the first list item - register top level numbering type
            this.numberingTypePerDepth[depth] = numberingType;
        }

        if (depth > this.lastDepth) {
            this.resetCounter(depth);

            if (depth - this.lastDepth > 1) {
                // Draft JS doesn't check distance of increasing depth. If you create:
                // * A
                //     * A-1
                //         * A-1-1
                // * B
                // and then press Shift+Tab at "A-1" item, the result is:
                // * A
                // * A-1
                //         * A-1-1
                // * B
                // but it should decrease depth of item "A-1-1" too.
                depth -= 1;
                editorState = updateListItemDepth(editorState, block.getKey(), depth);
            }
            this.numberingTypePerDepth[depth] = numberingType;
        }
        else {
            if (this.numberingTypePerDepth[depth] !== numberingType) {
                // Avoid mixing different numbering at the same depth level

                if (this.numberingTypePerDepth[0] === undefined && this.numberingTypePerDepth[depth] === undefined) {
                    /**
                     * In reviewMode it is possible to manipulate with only one LI item at any depth.
                     * When the content of the current editor comes from fragment of another editor in review mode,
                     * then the branch `if (depth > this.lastDepth)` above is not fired and this.numberingTypePerDepth
                     * is not set for given depth.
                     */
                    this.numberingTypePerDepth[depth] = numberingType;
                }
                const data = {};
                data[LIST_PROPERTIES.NUMBERING_TYPE] = this.numberingTypePerDepth[depth];
                editorState = updateListItemProperties(editorState, block.getKey(), data);
            }
        }

        this.lastDepth = depth;
        this.getCounterIndexAndIncrement(depth);  // the index itself is not interesting here, but it internally increments the counter
        return editorState;
    };

    render() {
        const { getEditorState, setEditorState, children, showCustomComponent, reviewMode } = this.props;
        this.resetCounter();

        return (
            //https://github.com/facebook/draft-js/blob/b234885c1159823dfdba6c2734ab44443beb0993/src/model/immutable/DefaultDraftBlockRenderMap.js#L23
            <ol className={cx("public-DraftStyleDefault-ol")} data-offset-key={this.props["data-offset-key"]}>
                {children.map((listItem) => {
                    const { "data-block": dataBlock, "data-editor": dataEditor, "data-offset-key": dataOffsetKey, children: content } = listItem.props;
                    const bulletValue = this.getBulletValue(content);
                    const orderNumber = this.getCounterIndex(content);

                    return (<OrderedListItem
                        key={dataOffsetKey}
                        dataBlock={dataBlock}
                        dataEditor={dataEditor}
                        dataOffsetKey={dataOffsetKey}
                        content={content}
                        bulletValue={bulletValue}
                        orderNumber={orderNumber}
                        getEditorState={getEditorState}
                        setEditorState={setEditorState}
                        showCustomComponent={showCustomComponent}
                        reviewMode={reviewMode}
                    />);
                })}
            </ol>
        );
    }
}
