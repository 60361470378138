import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TopicList from "./TopicList";
import { Header } from "semantic-ui-react";
import ActionLinks from "../component/ActionLinks";

export default class Topics extends PureComponent {
    static propTypes = {
        topics: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                concept: PropTypes.object,
                objective: PropTypes.object,
                objective_set: PropTypes.object,
                keywords: PropTypes.object
            })
        ),
        onDelete: PropTypes.func.isRequired,
        onTopicShowDialog: PropTypes.func.isRequired,
        showDialog: PropTypes.bool
    };

    state = { viewAs: "list" };

    handleClick = (e, { name }) => {
        this.setState({ viewAs: name });
    };

    render() {
        const { viewAs } = this.state;

        return (
            <React.Fragment>
                <Header as="h1">SPORK Topics</Header>
                <ActionLinks
                    horizontal
                    floated="right"
                    actions={[
                        { name: "list", icon: "list", iconSize:"large", active: viewAs === "list" },
                        { name: "tree", icon: "sitemap", iconSize:"large", active: viewAs === "tree" }
                    ]}
                    onClick={this.handleClick}
                />
                <TopicList {...this.props} />
            </React.Fragment>
        );
    }
}
