import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose, bindActionCreators } from "redux";
import * as SiPiTemplateActions from "./SiPiTemplateActions";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { getTemplate, getTemplateId, getTemplateSiPi, getTemplateSiPiByScope } from "./SiPiTemplateSelectors";
import IPlannerOutline from "../component/IPlannerOutline";
import { loggedUserId } from "../../Users/UserProfile/UserProfileSelectors";
import { getYearsAsObject } from "../../Year/YearsSelectors";
import { onLoadCourseVariant } from "../../External/CourseVariantList/CourseVariantsActions";
import { getCourseVariant, isLoading } from "../../External/CourseVariantList/courseVariantsSelectors";

const mapStateToProps = function(state, props) {
    return {
        iPlanner: getTemplate(state, props),
        iPlannerSiPi: getTemplateSiPi(state, props),
        iPlannerSiPiByScope: getTemplateSiPiByScope(state, props),
        userId: loggedUserId(state),
        years: getYearsAsObject(state),
        courseVariant: getCourseVariant(state),
        cvIsLoading: isLoading(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { ...SiPiTemplateActions,
            onLoadCourseVariant,
        },
        dispatch
    );
};

export default compose(
    connect((state, props) => ({
        iPlannerId: getTemplateId(state, props),
        ruleBaseName: "sipiTemplates",
        entitiesName: "iPlanner Templates",
        enableSyllabusFeatures: false,
    })),
    firebaseConnect((props) => {
        return [
            `/sipi_templates/info/${props.iPlannerId}`,
            `/sipi_templates/data/${props.iPlannerId}`,
            `/sipi_templates/lesson_units/${props.iPlannerId}`,
            "/modules/list",
            "tenant_modules",
        ];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["iPlanner"]),
)(IPlannerOutline);
