import React from "react";
import PropTypes from "prop-types";
import ActionLinks from "./ActionLinks";

const HiddenLinkStyle = { visibility: "hidden" };

export default class ActionLink extends React.PureComponent {
    static propTypes = {
        /** Callback executed upon the link is clicked. */
        onClick: PropTypes.func,
        /** Float to 'right' or 'left'. */
        floated: PropTypes.string,
        /** Use horizontal link list (has some styling consequences). */
        horizontal: PropTypes.bool,
        /** Toggle visibility. */
        hidden: PropTypes.bool,
        /** Specify the icon of link */
        icon: PropTypes.string,
        /** Text to display next to the icon. */
        content: PropTypes.string,
        /** Tooltip to display on mouse over. */
        title: PropTypes.string,
        /** Style used by list of links */
        style: PropTypes.object,
    };

    render() {
        const { hidden, icon, content, title, style, ...otherProps } = this.props;
        const listStyle = hidden ? (style ? { ...style, ...HiddenLinkStyle } : HiddenLinkStyle) : style;
        const actions = [{
            name: icon || "none", icon, content, title
        }];
        return <ActionLinks style={listStyle} {...otherProps} actions={actions} />;
    }
}
