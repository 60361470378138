import React from "react";
import PropTypes from "prop-types";
import {
    List,
    AutoSizer,
} from "react-virtualized";


export default class AutoSizedList extends React.PureComponent {
    static propTypes = {
        rowCount: PropTypes.number.isRequired,
        rowRenderer: PropTypes.func.isRequired,
        rowHeight: PropTypes.oneOfType([ PropTypes.number, PropTypes.func ]),
        measurement: PropTypes.object,
        scrollToIndex: PropTypes.number,
    };

    render() {
        const { rowCount, rowRenderer, rowHeight, measurement, scrollToIndex } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        height={height}
                        width={width}
                        rowCount={rowCount}
                        rowRenderer={rowRenderer}
                        rowHeight={rowHeight}
                        deferredMeasurementCache={measurement}
                        {...scrollToIndex && { scrollToIndex }}
                    />
                )}
            </AutoSizer>
        );
    }
}
