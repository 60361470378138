const TYPES = {
    ASSIGN_TAGS_TOPICS: "ASSIGN_TAGS_TOPICS"
};

const assignTagsTopicsSave = ({ fbCollection, problems, tags, topics, authorId }) => ({
    type: TYPES.ASSIGN_TAGS_TOPICS,
    payload: { fbCollection, problems, tags, topics, authorId },
});


export { TYPES, assignTagsTopicsSave };
