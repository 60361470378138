import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "./socket";

const subscriptionMap = {};

export const client = feathers();

/**
 * Initialize Feathers client with websocket connection. Will start polling the server.
 * Allows to provide own socket.io instance (ie. mock for tests)
 * @param {*} socket
 */
export const initialize = socket =>
    client.configure(socketio(socket ? socket : io(), { timeout: 30000 })).configure(
        auth({
            storageKey: "auth",
            jwtStrategy: "fbAuth"
        })
    );

/**
 * Return true if there is already a listener for given path (service).
 * @param {string} path
 */
export const hasListener = path => subscriptionMap[path] && subscriptionMap[path] > 0;

/**
 * Perform async authentication against FeatherJS server
 * @param {*} idToken
 */
export const authenticate = async idToken => {
    return client.authenticate({
        strategy: "fbAuth",
        accessToken: idToken,
    });
};

/**
 * Subscribe to specified path (channel) to receive real-time updates.
 * @param {string} path
 */
export const subscribe = path => {
    const count = subscriptionMap[path] ? subscriptionMap[path] : 0;
    // if we started with 0, subscribe
    if (count === 0) {
        return client
            .service(`${path}/subscribe`)
            .create({})
            .then(() => {
                // increase the counter after successfull subscription
                subscriptionMap[path] = count + 1;
            });
    } else {
        // others are already subscribed, just increase the counter
        subscriptionMap[path] = count + 1;
    }
};

/**
 * Unsubscribe from specified path (channel) to stop receiving real-time updates.
 * @param {string} path
 */
export const unsubscribe = path => {
    const count = subscriptionMap[path] ? subscriptionMap[path] : 0;
    if (count > 1) {
        // multiple components listening - just decrease the counter
        subscriptionMap[path] = count - 1;
    } else {
        // last listener - unsubscribe
        return client
            .service(`${path}/unsubscribe`)
            .create({})
            .then(() => {
                // increase the counter after successfull subscription
                subscriptionMap[path] = count - 1;
            });
    }
};

