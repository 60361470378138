import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Header } from "semantic-ui-react";
import Topic from "../../../Topics/components/Topic";
import { DropdownWithNodes, SaveCancelButtons } from "bmd-react";

export default class LinkToExistingTopic extends PureComponent {
    static propTypes = {
        topics: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                topic: PropTypes.object
            })
        ),
        onCancel: PropTypes.func.isRequired,
        onTopicLinkToBela: PropTypes.func.isRequired,
        selectedTopic: PropTypes.object
    };

    state = { selected: null };

    handleChange = (e, { value }) => {
        this.setState({ selected: value });
    };

    handleLink = () => {
        const { onTopicLinkToBela, selectedTopic, onCancel } = this.props;
        const { selected } = this.state;
        onTopicLinkToBela(selected, selectedTopic);
        onCancel();
    };

    render() {
        const { topics, onCancel, selectedTopic } = this.props;
        const { selected } = this.state;

        return (
            <Modal open={selectedTopic != null}>
                <Header>
                    Link to an existing topic
                    <Header.Subheader>BeLA topic: {selectedTopic.topic_name}</Header.Subheader>
                </Header>
                <Modal.Content>
                    <DropdownWithNodes
                        options={topics.map(item => ({
                            key: item.key,
                            content: <Topic topic={item.topic} />,
                            value: item.key,
                            searchText:
                                item.topic.concept.name +
                                " " +
                                item.topic.objective.name +
                                " " +
                                item.topic.objective.type
                        }))}
                        fluid
                        placeholder="Select existing topic"
                        onChange={this.handleChange}
                    />
                    <SaveCancelButtons
                        onSave={this.handleLink}
                        onCancel={onCancel}
                        submitText="Link"
                        saveDisabled={!selected}
                        padded
                    />
                </Modal.Content>
            </Modal>
        );
    }
}
