const TYPES = {
    LESSON_EXPORTS_EXPORT_TO_PDF: "LessonExports/EXPORT_TO_PDF",
    LESSON_EXPORTS_EXPORT_DONE: "LessonExports/EXPORT_DONE",
};

const onExportToPdf = (payload) => ({
    type: TYPES.LESSON_EXPORTS_EXPORT_TO_PDF,
    payload
});

const onExportDone = () => ({
    type: TYPES.LESSON_EXPORTS_EXPORT_DONE
});

export {
    TYPES,
    onExportToPdf,
    onExportDone,
};
