import { all, put, takeLatest, fork } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onSetUserDetails, onSetSearchResult } from "./UserCoursesActions";
import { toastError } from "../../component/toast";
import { push } from "connected-react-router";
import SchoolListItemSaga from "./component/SchoolListItem/SchoolListItemSaga";
import SectionListItemSaga from "./component/SectionListItem/SectionListItemSaga";

function* selectUser({ payload }) {
    yield put(push(`/section/users/${payload}`));
    try {
        const userDetails = yield firebase.tabletProxy({
            method: "GET",
            uri: `/user/${payload}`,
        });
        yield put(onSetUserDetails(userDetails.data));
    } catch (err) {
        toastError({ code: err.details.name, header: "Failed to load user details.", message: err.details.message });
        yield put(onSetUserDetails(null));
    }
}

function* searchPerson({ payload: { userScope, name, schoolId } }) {
    try {
        const names = name.split(" ");
        let params = "";
        if (1 === names.length) {
            params = `&$or[0][first_name][$like]=${name}%&$or[1][last_name][$like]=${name}%`;
        } else {
            params = `&$or[0][first_name][$like]=${names[0]}%&$or[0][last_name][$like]=${names[1]}%` + 
            `&$or[1][first_name][$like]=${names[1]}%&$or[1][last_name][$like]=${names[0]}%`;
        }
        if (schoolId) {
            params += `&school_id=${schoolId}`;
        }
        const foundUsers = yield firebase.tabletProxy({
            method: "GET",
            uri: `/user?scope=${userScope}${params}`,
        });
        yield put(onSetSearchResult(foundUsers.data));
    } catch (err) {
        toastError({ code: err.details.name, header: `Failed to search ${userScope}s.`, message: err.details.message });
        yield put(onSetSearchResult([]));
    }
}

function* goBack() {
    yield put(push("/section/users"));
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.SECTION_USER_SELECT_USER, selectUser),
        takeLatest(TYPES.SECTION_USER_SEARCH_PERSON, searchPerson),
        takeLatest(TYPES.SECTION_USER_GO_BACK, goBack),
        fork(SchoolListItemSaga),
        fork(SectionListItemSaga),
    ]);
}
