import React, { PureComponent } from "react";
import { Popup, Message, Icon } from "semantic-ui-react";

export default class UsedTemplateWarning extends PureComponent {
    render() {
        return (
            <Popup 
                basic
                wide="very"
                style={{ padding: "0", border: "0" }}
                position="bottom left"
                trigger={
                    <span style={{float: "left", padding: "4px"}}>
                        <Icon name="warning sign" color="grey"/>
                        The template has been already used.
                    </span>
                }
            >
                <Popup.Content>
                    <Message warning icon size="small" style={{ width: "550px" }}>
                        <Icon name="warning sign" style={{ alignSelf: "flex-start" }} />
                        <Message.Content>
                            <Message.Header>Template Already Used</Message.Header>
                            <p>
                                It is not allowed to add or remove widget items if the template has been
                                already used to create some widget. All changes to template will affect
                                the&nbsp;related widgets. You can modify only:</p>
                            <Message.List>
                                <Message.Item>widget settings</Message.Item>
                                <Message.Item>widget item settings and initial content</Message.Item>
                                <Message.Item>widget item ordering</Message.Item>
                            </Message.List>
                            <p>You can also clone the template to a new one that you can fully edit.</p>
                        </Message.Content>
                    </Message>
                </Popup.Content>
            </Popup>
        );
    }
}
