import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { bindActionCreators } from "redux";

import GlossaryPopup from "./GlossaryPopup";
import { getSearchResult, isSearching, getAddTerm, getSearchFilter } from "../../../../KitBuilder/Glossary/GlossarySelectors";
import { onAddTerm, onSearchTerm } from "../../../../KitBuilder/Glossary/GlossaryActions";

const mapStateToProps = (state) => {
    return {
        suppressPopup: getAddTerm(state),
        isSearching: isSearching(state),
        searchResult: getSearchResult(state),
        searchFilter: getSearchFilter(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators({
        onAddTerm,
        onSearchTerm,
    }, dispatch);
};

export default compose(
    firebaseConnect([ "glossary/list", "glossary/m" ]),
    connect(mapStateToProps, dispatchToProps)
)(GlossaryPopup);
