import React, { PureComponent } from "react";
import { List, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { ConfirmDeletePopup, AddItemButton } from "bmd-react";

export default class ObjectiveSetList extends PureComponent {
    static propTypes = {
        objectiveSets: PropTypes.array,
        oiiObjectives: PropTypes.array,
        selectedObjectiveSet: PropTypes.object,
        onSelect: PropTypes.func,
        onRemoveObjectiveSet: PropTypes.func,
        onAddObjectiveSet: PropTypes.func,
        error: PropTypes.object,
        onObjectiveSetError: PropTypes.func,
        onClearObjectiveSetError: PropTypes.func,
    };

    state = {
        oiiSelected: false
    };

    handleClick = (e, props) => {
        const { onSelect } = this.props;
        onSelect(props.id);
        this.setState({ oiiSelected: "OII" === props.id });
        e.stopPropagation();
    };

    // Wrapper for handling onRemoveObjective and passing proper onSuccess/onFailure callbacks
    handleDelete = (callback, event, props) => {
        // clear error message at start if visible
        if (this.props.error)
            this.props.onClearObjectiveSetError();

        const { onRemoveObjectiveSet, onObjectiveSetError } = this.props;

        onRemoveObjectiveSet(
            props.id,
            props,
            // onSuccess
            // Caller can pass a message, in the future we could show/log the message. Now we just call the callback function with "" which closes the confirm dialog.
            // Update: call of the callback with "" has been repaclaced with function doing nothing. Removal of the component causes closing the dialog by rendering the component with unique key attribute
            () => {
                return;
            },
            // onFailure
            msg => {
                callback(msg);
            },
            onObjectiveSetError
        );
    };

    handleAddObjectiveSet = e => {
        // clear error message at start if visible
        if (this.props.error)
            this.props.onClearObjectiveSetError();

        const { objectiveSets, onAddObjectiveSet, onObjectiveSetError } = this.props;
        // pass this.props.setTextEditId as 2nd parameter to edit Objective name just after creation
        onAddObjectiveSet(`New Objective Set nr. ${((objectiveSets && objectiveSets.length) || 0) + 1}`, null, onObjectiveSetError);
        e.stopPropagation();
    };

    // count Objectives of all Objective Types
    countObjectives = objectives => {
        return objectives ? Object.keys(objectives).length : 0;
    };

    render() {
        const { objectiveSets, oiiObjectives, selectedObjectiveSet } = this.props;
        return (
            <div>
                <AddItemButton
                    buttonText="Add Objective Set"
                    title="Click here to add new Objective Set."
                    onClick={this.handleAddObjectiveSet}
                />
                <List selection style={{ maxHeight: "80vh", overflow: "auto" }}>
                    <List.Item
                        key="OII"
                        id="OII"
                        onClick={this.handleClick}
                        active={this.state.oiiSelected}
                        className="editable"
                    >
                        <List.Header>OII - Other Instruction Instruments</List.Header>
                        <List.Content style={{ paddingLeft: "1em" }}>
                            <ObjectiveCount count={(oiiObjectives && oiiObjectives.length) || 0} />
                        </List.Content>
                    </List.Item>
                    {objectiveSets &&
                        objectiveSets.map(objectiveSet => {
                            let objectivesCount = this.countObjectives(objectiveSet.objectives);
                            return (
                                <List.Item
                                    key={objectiveSet.id}
                                    id={objectiveSet.id}
                                    onClick={this.handleClick}
                                    active={selectedObjectiveSet && objectiveSet.id == selectedObjectiveSet.id}
                                    className="editable"
                                >
                                    <List.Header>
                                        {objectiveSet.name}
                                        <ConfirmDeletePopup
                                            id={objectiveSet.id}
                                            key={objectiveSet.id}
                                            position="right center"
                                            trigger={
                                                <Icon
                                                    name="trash alternate outline"
                                                    link
                                                    className="actionIconClass right"
                                                />
                                            }
                                            onConfirm={this.handleDelete}
                                            whatText={`objective set "${objectiveSet.name}"`}
                                        />
                                    </List.Header>
                                    <List.Content style={{ paddingLeft: "1em" }}>
                                        <ObjectiveCount count={objectivesCount} />
                                    </List.Content>
                                </List.Item>
                            );
                        })}
                </List>
                {/* alternative placement of OII Set - in standalone list<List selection>
                    <List.Item
                        key="OII"
                        id="OII"
                        onClick={this.handleClick}
                        active={this.state.oiiSelected}
                        className="editable"
                    >
                        <List.Header>
                            OII - Other Instruction Instruments
                        </List.Header>
                        <List.Content style={{paddingLeft: "1em"}}>
                            {`${oiiObjectivesCount ? oiiObjectivesCount : "No"} objective${oiiObjectivesCount == 1 ? "" : "s"}`}
                        </List.Content>
                    </List.Item>
                </List> */}
            </div>
        );
    }
}

const ObjectiveCount = ({ count }) => `${count ? count : "No"} objective${count === 1 ? "" : "s"}`;
