import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { spinnerWhileLoading } from "../../../component/spinnerWhileLoading.jsx";

import { getAKitsFiltered } from "./AKitsListSelectors";
import { searchFilter } from "./AKitsListSelectors";
import AKitsList from "./AKitsList";

import { onAKitRemove, onSelectAKitForEdit } from "../AKitsActions";
import { onChangeFilter } from "./AKitsListActions";
import { isRemoving } from "../AKitsSelectors";

import { getPathsForAKitsData, fetchKitsData } from "./AKitsListSelectors";
import { onEditKit } from "../EditKitProperties/EditKitActions";
import { withAuth } from "../../../component/withAuth";

const fbConnectKitsData = () => getPathsForAKitsData;

const mapStateToProps = function(state, ownProps) {
    return {
        aKits: getAKitsFiltered(state, ownProps),
        searchFilter: searchFilter(state),
        _kits_data: fetchKitsData(state),
        isRemoving: isRemoving(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onAKitRemove,
            onChangeFilter,
            onSelectAKitForEdit,
            onEditKit,
        },
        dispatch
    );
};

export default compose(
    // subscribe to aKits data
    firebaseConnect(fbConnectKitsData),
    connect(mapStateToProps, dispatchToProps),
    spinnerWhileLoading(["_kits_data"]),
    withAuth,
)(AKitsList);