import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import ProblemManagement from "./ProblemManagement";
import {
    getQuestionBank,
    isLoading,
    getProblemsPaging,
    usedTags,
    selectedTopic,
    selectedTags,
    getTopicFilterName,
    maxItemsPerPage,
    restrictToAuthorId,
} from "./problemManagementSelectors";
import { getItemCollectionByParent, getItemLockCollectionByParent, getTagCollectionByParent } from "../utils";
import bindProblemManagementActions from "./ProblemManagementBindings";
import bindCompTestQuestionsActions from "../CompTest/CompTestBindings";
import { getGridOptions } from "../GridOptions/gridOptionsSelectors";

const mapStateToProps = (state, props) => ({
    problems: getQuestionBank(state, props),
    gridOptions: getGridOptions(state),
    isLoading: isLoading(state, props),
    usedTags: usedTags(state, props),
    selectedTopic: selectedTopic(state),
    selectedTags: selectedTags(state),
    topicFilterName: getTopicFilterName(state),
    allowedAuthorId: restrictToAuthorId(state, props),
});

const mapDispatchToProps = (dispatch, { fbCollection }) => {
    switch (fbCollection) {
        case "problemSet":
            return bindProblemManagementActions(dispatch);
        case "comptests_common":
            return bindCompTestQuestionsActions(dispatch);
        default:
            // eslint-disable-next-line no-console
            console.error(`Unsupported collection "${fbCollection}" in ProblemSetWizard`);
            return null;
    }
};


const populates = (fbCollection) => ([{ child: "author", root: "user" }, { child: "problemSets", root: fbCollection }]);

export default compose(
    connect(getProblemsPaging),
    firebaseConnect(props => {
        const { 
            startAt = "",
            itemsPerPage,
            searchResults,
            reverseFetch,
            selectedTopic,
            selectedTags,
            refreshKey,
            allResults,
            fbCollection
        } = props;
        const isEmptySearch = !selectedTopic || selectedTags.length === 0;
        const queries = [
            "topic_collections/data",
            getTagCollectionByParent(fbCollection),
            `locks/${getItemLockCollectionByParent(fbCollection)}`,
        ];

        // If search filter is active then load only those problems
        const problemsFound = searchResults && Object.keys(searchResults);
        if (Array.isArray(problemsFound) && problemsFound.length > 0) {
            // Unless there is less problems then maxItemsPerPage
            const problemsAll = Object.keys(allResults || {});
            const problemsToQuery = maxItemsPerPage >= problemsAll.length ? problemsAll : problemsFound;
            for (const problemId of problemsToQuery) {
                queries.push({ path: `${getItemCollectionByParent(fbCollection)}/${problemId}`, populates: populates(fbCollection) });
            }
        } else if (isEmptySearch) {
            // Next/Prev paging
            queries.push({
                path: getItemCollectionByParent(fbCollection),
                queryParams: [
                    "orderByKey",
                    reverseFetch ? `endAt=${startAt}` : `startAt=${startAt}`,
                    reverseFetch ? `limitToLast=${itemsPerPage}` : `limitToFirst=${itemsPerPage}`,
                    `refreshKey=${refreshKey}`,
                ],
                populates: populates(fbCollection),
            });
        }
        return queries;
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ProblemManagement);
