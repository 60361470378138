import { TYPES } from "./SnippetsManagerActions";

const initialState = {
    searchFilter: "",
    unusedFilter: false,
    selectedTags: [],
    tagFilter: {},
    moduleFilter: [],
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.SNIPPETS_MANAGER_CHANGE_FILTER:
            return { ...state, searchFilter: payload };
        case TYPES.SNIPPETS_MANAGER_SELECT_TAG:
            return { ...state, selectedTags: payload };
        case TYPES.SNIPPETS_MANAGER_CHANGE_UNUSED_FILTER:
            return { ...state, unusedFilter: payload };
        case TYPES.SNIPPETS_MANAGER_SET_TAG_FILTER:
            return { ...state, tagFilter: payload };
        case TYPES.SNIPPETS_MANAGER_SET_MODULE_FILTER:
            return { ...state, moduleFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
