import React from "react";
import PropTypes from "prop-types";
import { Segment, Icon } from "semantic-ui-react";
import LessonSchedule from "../../component/LessonSchedule";
import SiPiListPreview from "./SiPiListPreview";

export default class IPlannerOutlinePreview extends React.PureComponent {
    static propTypes = {
        iPlanner: PropTypes.object,
        iPlannerSiPiByScope: PropTypes.object,
        viewScope: PropTypes.string,
    }

    render() {
        const { iPlanner, iPlannerSiPiByScope, viewScope } = this.props;
        if (!iPlanner.outline) {
            return null;
        }
        const isTeacher = "teacher" === viewScope;
        return (
            <div className="iPlannerOutlinePreview">
                {Object.keys(iPlanner.outline).map(u => (
                    <React.Fragment key={u}>
                        <Segment key={u} tertiary className="iPlannerOutlineUnit">{u}</Segment>
                        {iPlanner.outline[u].map(lu => (
                            <Segment
                                key={lu.lu_id || lu.cv_lu_id}
                                loading={undefined === iPlannerSiPiByScope}
                            >
                                <div className={isTeacher ? "ipoLessonUnitHeader detailed" : "ipoLessonUnitHeader"}>
                                    <div className="ipoLUName">{lu.name}</div>
                                    {isTeacher && (
                                        <React.Fragment>
                                            <span className="ipoLUSchedule">
                                                {lu.start_week && (
                                                    <LessonSchedule
                                                        startWeek={lu.start_week}
                                                        wrongSchedule={false}
                                                        length={lu.length}
                                                    />
                                                )}
                                            </span>
                                            {lu.completed_date && (
                                                <div
                                                    className="ipoLUCompleted"
                                                    title={`Completed at ${lu.completed_date}`}
                                                >
                                                    {lu.completed_date} <Icon name="check" color="green" />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                                {"teacher" === viewScope && <p title="Lesson objectives" style={{ whiteSpace: "pre-line" }}>{lu.lu_objectives}</p>}
                                {undefined !== iPlannerSiPiByScope && (
                                    <SiPiListPreview
                                        iPlannerSiPiByScope={iPlannerSiPiByScope}
                                        lessonUnit={lu}
                                        viewScope={viewScope}
                                    />
                                )}
                            </Segment>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        );
    }
}
