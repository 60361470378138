// String in Action fields will be cropped if they exceed maximum size
const MAX_STRING_LENGTH = 96;
const MAX_OBJECT_FIELDS = 10;
const MAX_ARRAY_ENTRIES = 10;
const MAX_OBJECTS_DEPTH = 10;

// Redux Storae Statistics
const ReduxStatsData = {};
const ReduxStatsEnabled = Symbol.for("ReduxStatsEnabled");

const collectStateSizes = (data, basePath, statsKey = "original") => {
    data && typeof data === "object" && Object.keys(data).forEach((key) => {
        const json = JSON.stringify(data[key]);
        const size = json ? json.length : 0;
        const path = basePath ? `# ${basePath}.${key}` : `# ${key}`;
        if (ReduxStatsData[path]) {
            const current = ReduxStatsData[path][statsKey];
            ReduxStatsData[path][statsKey] = Math.max(current || 0, size);
        } else {
            ReduxStatsData[path] = { [statsKey]: size };
        }
    });
};

const collectActionSize = (action, statsKey = "original") => {
    const path = action.path ? `${action.type}(${action.path})` : action.type;
    const json = JSON.stringify(action);
    const size = json ? json.length : 0;
    if (ReduxStatsData[path]) {
        const current = ReduxStatsData[path][statsKey];
        ReduxStatsData[path][statsKey] = Math.max(current || 0, size);
    } else {
        ReduxStatsData[path] = { [statsKey]: size };
    }
};

window.collectReduxStats = (enable) => {
    const enabled = enable === undefined ? !window[ReduxStatsEnabled] : enable;
    window[ReduxStatsEnabled] = enabled;
    // eslint-disable-next-line no-console
    console.log(
        "%c Redux Stats: %c%s ",
        "color:indigo;background-color:lightcyan;font-size: 1.25em;",
        `color:${enabled ? "green" : "crimson;"};background-color:lightcyan;font-weight:bold;font-size:1.25em;`,
        enabled ? "Enabled" : "Disabled",
    );
    return enabled;
};

window.displayReduxStats = (minSize) => {
    const toDisplay = Object.keys(ReduxStatsData).sort().reduce((out, key) => {
        const value = ReduxStatsData[key];
        if (minSize === undefined || value.original > minSize || value.sanitized > minSize) {
            out[key] = value;
        }
        return out;
    }, {});
    // eslint-disable-next-line no-console
    console.table(toDisplay);
};

const referencesTracker = (base) => {
    const _refs = [];
    if (base instanceof Object) {
        _refs.push(base);
    }

    const check = (value) => {
        if (value instanceof Object) {
            if(_refs.findIndex(ref => ref === value) !== -1) {
                return false;
            }
            if (_refs.length >= MAX_OBJECTS_DEPTH) {
                return false;
            }
        }
        return true;
    };

    const enter = (value) => _refs.unshift(value);

    const leave = (value) => _refs.shift() === value;

    return { check, enter, leave };
};

const cropStringLength = (value) => {
    if (value.length > MAX_STRING_LENGTH) {
        const info = `<<TOO_BIG:'${value.length}'>>`;
        const crop = MAX_STRING_LENGTH - info.length;
        return value.slice(0, crop) + info;
    }
    return value; // pass short string
};

const sanitizeIfNeeded = (source, refs) => (key) => {
    const value = source[key];
    const sanitizer = getSanitizerFunc(value); // eslint-disable-line no-use-before-define
    if (sanitizer) {
        const sanitized = sanitizer(value, refs);
        if (sanitized !== value) {
            return [ key, sanitized ];
        }
    }
    return null;
};

const writeSanitizedTo = (target, entry) => {
    target[entry[0]] = entry[1];
    return target;
};

const cropObjectValues = (source, keys, refs) => {
    // Check if fields need to be sanitized...
    const changes = (keys || Object.keys(source)).map(sanitizeIfNeeded(source, refs)).filter(Boolean);
    if (changes.length) { // ... then write changes to copy
        return changes.reduce(writeSanitizedTo, { ...source });
    }
    // ... otherwise return the unmodified object
    return source;
};

const cropObjectFields = (source, refs) => {
    const keys = Object.keys(source);
    // Crop the number of fields
    if (keys.length > MAX_OBJECT_FIELDS) {
        const sanitizeByKey = (target, key) => {
            target[key] = sanitizeValue(source[key], refs); // eslint-disable-line no-use-before-define
            return target;
        };
        const output = keys.sort().slice(0, MAX_OBJECT_FIELDS).reduce(sanitizeByKey, {});
        output["<<TOO_BIG>>"] = `<<TOO_BIG:{${keys.length}}>>`;
        return output;
    }
    return cropObjectValues(source, keys, refs);
};

const cropArrayEntries = (source, refs) => {
    if (source.length > MAX_ARRAY_ENTRIES) {
        const sanitizeEntry = (value) => sanitizeValue(value, refs); // eslint-disable-line no-use-before-define
        const target = source.slice(0, MAX_ARRAY_ENTRIES).map(sanitizeEntry);
        target.push(`<<TOO_BIG:[${source.length}]>>`);
        return target;
    }
    const changes = source.map((value, index) => {
        const sanitized = sanitizeValue(value, refs); // eslint-disable-line no-use-before-define
        return sanitized !== value ? [ index, sanitized ] : null;
    }).filter(Boolean);
    if (changes.length) {
        return changes.reduce(writeSanitizedTo, [ ...source ]);
    }
    return source;
};

const getSanitizerFunc = (value) => {
    if (value) {
        const type = typeof value;
        if (type === "string") {
            return value.length > MAX_STRING_LENGTH ? cropStringLength : null;
        }
        if (type === "object") {
            if (Array.isArray(value)) {
                return cropArrayEntries;
            }
            const proto = Object.getPrototypeOf(value);
            if (proto === Object.prototype || proto === null) {
                return cropObjectFields;
            }
        }
    }
    return null;
};

const sanitizeValue = (value, refs) => {
    if (refs === undefined) {
        refs = referencesTracker();
    }
    const sanitizer = getSanitizerFunc(value);
    if (sanitizer && refs.check(value)) {
        refs.enter(value);
        const sanitized = sanitizer(value, refs);
        refs.leave(value);
        return sanitized;
    }
    return value;
};

const reduceBigCollections = (collections, toSkip, refs) => collections && cropObjectValues(
    collections,
    toSkip && Object.keys(collections).filter(key => !toSkip.includes(key)),
    refs,
);

const sanitizeFirebaseData = (firebase, toSkip) => {
    if (firebase.data && window[ReduxStatsEnabled]) {
        collectStateSizes(firebase.data, "firebase.data");
    }
    const refs = referencesTracker(firebase);
    const data = firebase && reduceBigCollections(firebase.data, toSkip, refs);
    const ordered = firebase && reduceBigCollections(firebase.ordered, toSkip, refs);
    if (data || ordered) {
        firebase = {
            ...firebase,
            data: data || firebase.data,
            ordered: ordered || firebase.ordered,
        };
    }
    if (firebase.data && window[ReduxStatsEnabled]) {
        collectStateSizes(firebase.data, "firebase.data", "sanitized");
    }
    return firebase;
};

const sanitizeFeathersData = (feathers) => {
    if (feathers && window[ReduxStatsEnabled]) {
        collectStateSizes(feathers, "feathers");
    }
    const feathersData = {};
    Object.keys(feathers).map( key => {
        feathersData[key] = cropObjectValues(feathers[key], ["queryResult"], referencesTracker(feathers[key]));
    });
    if (feathersData && window[ReduxStatsEnabled]) {
        collectStateSizes(feathersData, "feathers", "sanitized");
    }
    return feathersData;
};

const sanitizeStateFields = (state, fields, path) => {
    if (!state) {
        return state;
    }
    if (path && window[ReduxStatsEnabled]) {
        collectStateSizes(state, path);
    }
    state = cropObjectValues(state, fields, referencesTracker(state));
    if (path && window[ReduxStatsEnabled]) {
        collectStateSizes(state, path, "sanitized");
    }
    return state;
};

/**
 * Creates a sanitizer for the Redux action to reduce DevTools payload.
 * 
 * @param {array} ignoreTypes list of action type not to be sanitized
 * @returns {function} sanitizer of Redux action (action, id) => action
 */
const getActionSanitizer = (ignoreTypes) => {
    ignoreTypes = Array.isArray(ignoreTypes) && ignoreTypes.length ? ignoreTypes : null;
    return (action, id) => { // eslint-disable-line no-unused-vars
        if (window[ReduxStatsEnabled]) {
            collectActionSize(action);
        }
        if (!action.type || (ignoreTypes && ignoreTypes.includes(action.type))) {
            return action;
        }

        // Always excluding "type"
        const keys = Object.keys(action).filter((key) => key !== "type");
        action = cropObjectValues(action, keys, referencesTracker(action));

        if (window[ReduxStatsEnabled]) {
            collectActionSize(action, "sanitized");
        }
        return action;
    };
};

/**
 * Cleans the Redux state for DevTools from heavy data.
 * 
 * @param {object} action root of Redux state
 * @param {number} index index of the Redux state change (probably the same as ID from actionSanitizer)
 * @returns {object} sanitized Redux state
 */
const stateSanitizer = (state, index) => { // eslint-disable-line no-unused-vars
    const sanitized = {};
    // Firebase data collections
    if (state.firebase) {
        sanitized.firebase = sanitizeFirebaseData(state.firebase);
        // sanitized.firebase = sanitizeFirebaseData(state.firebase, [ "unsanitized" ]);
    }
    // Feathers data collections
    if (state.feathers) {
        sanitized.feathers = sanitizeFeathersData(state.feathers);
    }
    // Lesson unit planner contains big collections
    if (state.lup && state.lup.resources) {
        // Especially list of resources and their tags
        const resources = sanitizeStateFields(state.lup.resources, [ "data", "keys", "tags" ], "lup.resources");
        sanitized.lup = {...state.lup, resources };
    }
    // Some data are loaded to state.external
    if (state.external && state.external.cv) {
        // List of loaded courses can be long
        const cv = sanitizeStateFields(state.external.cv, [ "cvList", "allCvList" ], "external.cv");
        sanitized.external = { ...state.external, cv };
    }
    // Return modified state if something was sanitized
    return Object.keys(sanitized).length ? { ...state, ...sanitized } : state;
};

/**
 * Returns a compose enhanced for Redux DevTools extension in non-production environment.
 * 
 * @param {function} compose a compose function to be used as fallback.
 * @returns {function} an enhanced compose if Dev.Tools are avialable.
 */
const configDevTools = (compose, options) => {
    /* eslint-disable no-underscore-dangle, no-undef */
    if (
        process.env.NODE_ENV !== "production" &&
        typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) {
        // If you want to monitor some action with details then
        // you can add it to the list of types to be ignored:
        const actionSanitizer = getActionSanitizer([
            // "CourseVariants/ALL_SET",
            // "@@reactReduxFirebase/LOGIN"
        ]);
        return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            actionSanitizer,
            stateSanitizer,
            ...options
        });
    }
    /* eslint-enable */
    return compose;
};

export default configDevTools;