import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { onLessonAddTopic, onLessonRemoveTopic } from "../../outlinesActions";
import LessonTopicsList from "./LessonTopicsList";
import { getSelectedOutlineId } from "../../outlinesSelectors";


const dispatchToProps = dispatch => {
    return bindActionCreators({ onLessonAddTopic, onLessonRemoveTopic }, dispatch);
};

export default compose(
    connect(((state) => ({
        outlineId: getSelectedOutlineId(state),
    })), dispatchToProps)
)(LessonTopicsList);
