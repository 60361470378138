import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Divider, Grid, Loader } from "semantic-ui-react";

import PageHeader from "../../component/PageHeader";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import ResourcesList from "./ResourcesList";
import ResourceDetail from "./ResourceDetail";
import FilterBy from "./FilterBy";
import "./ResourcesPage.scss";

const PageColumnsStyle = { height: "80vh" };

const PageShowListOnly = { columns: 1, listWidth: 16 };
const PageShowSelected = { columns: 2, listWidth: 8, entryWidth: 8 };

export default class ResourcesPage extends React.PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        resourceList: PropTypes.array,
        selectedItem: PropTypes.number,
        onResourcesPageLeft: PropTypes.func,
        onFetchAllResources: PropTypes.func,
        onViewDetails: PropTypes.func,
        filterByName: PropTypes.string,
        filterParams: FilterBy.propShape,
        onFilterByName: PropTypes.func,
        onFilterByTags: PropTypes.func,
        filterIsOpen: PropTypes.bool,
        onOpenFilterBy: PropTypes.func,
        onFilterByCourseVariant: PropTypes.func,
        onFilterByResourceType: PropTypes.func,
        onFilterByOwner: PropTypes.func,
        firebase: PropTypes.object,
        onFilterByTopic: PropTypes.func,
        topicFilterName: PropTypes.string,
    };

    componentDidMount() {
        // Load data upon startup (very expensive)
        // WARNING: If used in multiple routes then componentDidMount is called on change of route.
        // Therefore, we have used route with optional parameter to allow use of a single Route for
        // both cases: list of resources and resource details (see LessonUnitPlanner/Routes.js).
        const { onFetchAllResources, onViewDetails, selectedItem, firebase } = this.props;
        onFetchAllResources && onFetchAllResources();
        onViewDetails && selectedItem && onViewDetails(selectedItem);
        firebase.watchEvent("value", "/topic_collections/list");
        firebase.watchEvent("value", "/topic_collections/data");
    }

    componentWillUnmount() {
        const { onResourcesPageLeft } = this.props;
        // WARNING: It may cause unwanted issues when the multiple routes lead to this component.
        // See componentDidMount for more.
        onResourcesPageLeft && onResourcesPageLeft();
    }

    render() {
        const {
            isLoading,
            resourceList,
            selectedItem,
            filterByName,
            filterParams,
            onFilterByName,
            onFilterByTags,
            onFilterByCourseVariant,
            onFilterByResourceType,
            filterIsOpen,
            onViewDetails,
            onOpenFilterBy,
            onFilterByOwner,
            onFilterByTopic,
            topicFilterName,
        } = this.props;
        const layout = selectedItem ? PageShowSelected : PageShowListOnly;
        const isFiltered = FilterBy.isFiltered(filterParams);
        return (
            <React.Fragment>
                <PageHeader
                    searchFilter={filterByName || ""}
                    list={resourceList}
                    headerText="Resources"
                    entityName="resource"
                    onChangeFilter={onFilterByName}
                >
                    <FilterBy.Button selectedTags={filterParams.tags} onClick={onOpenFilterBy} />
                    
                </PageHeader>
                {filterIsOpen ? <FilterBy /> : null}
                {isFiltered ? (
                    <FilterBy.View
                        filterParams={filterParams}
                        onFilterByTags={onFilterByTags}
                        onFilterByCourseVariant={onFilterByCourseVariant}
                        onFilterByResourceType={onFilterByResourceType}
                        onFilterByOwner={onFilterByOwner}
                        onFilterByTopic={onFilterByTopic}
                        topicFilterName={topicFilterName}
                    />
                ) : null}
                <Divider clearing fitted hidden />
                <Grid columns={layout.columns} style={{marginTop: 0}}>
                    <Grid.Column width={layout.listWidth} style={PageColumnsStyle}>
                        <Dimmer active={isLoading} inverted>
                            <Loader inverted content="Loading" />
                        </Dimmer>
                        {undefined === resourceList ? (
                            <NoDataPlaceholder  icon="newspaper outline" text="Here we list repository of resources." />
                        ) : resourceList.length === 0 ? (
                            <NoDataPlaceholder icon="search" text="Oh, snap. No result.">
                                <p>We cannot find any resources matching your filtering criteria.</p>
                            </NoDataPlaceholder>
                        ) : (
                            <ResourcesList
                                resourceList={resourceList}
                                selectedItem={selectedItem}
                                onSelectItem={onViewDetails}
                            />
                        )}
                    </Grid.Column>
                    {layout.entryWidth && (
                        <Grid.Column width={layout.entryWidth} className="resourcesDetailColumn" >
                            <ResourceDetail resourceId={selectedItem} />
                        </Grid.Column>
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}
