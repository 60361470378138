import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Form, Popup, Message } from "semantic-ui-react";

export default class AddTopicPopup extends PureComponent {
    static propTypes = {
        context: PropTypes.object,
        onCancel: PropTypes.func.isRequired,
        onAdd: PropTypes.func.isRequired,
        defaultValues: PropTypes.object,
        onChange: PropTypes.func,
        errorMessage: PropTypes.string
    };

    state = {
        name: (this.props.defaultValues && this.props.defaultValues.title) || null,
        description: (this.props.defaultValues && this.props.defaultValues.subtitle) || null
    };

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value});
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    handleYes = () => {
        const { name, description } = this.state;
        this.props.onAdd({ name, description });
    };

    render() {
        const { context, onCancel, defaultValues, errorMessage } = this.props;
        const { name, description } = this.state;

        return (
            <Popup context={context} open={!!context} wide="very">
                <Popup.Header>{defaultValues ? "Edit" : "Add New"} Topic</Popup.Header>
                <Popup.Content style={{ width: "30em", marginTop: "1.5em" }}>
                    <Form>
                        <Form.Input 
                            placeholder="Name your new topic here..." 
                            name="name" 
                            label="Name" 
                            value={name || ""} 
                            onChange={this.handleChange} 
                            autoFocus
                            required/>
                        <Form.TextArea
                            placeholder="What is the topic about?"
                            name="description"
                            label="Objective"
                            value={description || ""}
                            onChange={this.handleChange}
                        />
                    </Form>
                    {errorMessage && <Message error>{errorMessage}</Message>}
                    <Divider hidden />
                    <Button size="small" floated="right" onClick={onCancel} content="Cancel" />
                    <Button
                        primary
                        disabled={name == null || name.trim() === ""}
                        size="small"
                        floated="right"
                        onClick={this.handleYes}
                        content={defaultValues ? "Save" : "Add"}
                    />
                </Popup.Content>
            </Popup>
        );
    }
}
