import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";

export default class EditKitMenu extends PureComponent {
    static propTypes = {
        activeItem: PropTypes.string,
        onItemClick: PropTypes.func,
        isDirty: PropTypes.bool,
    };

    render() {
        const { activeItem, onItemClick, isDirty } = this.props;
        return (
            <Menu fluid vertical secondary>
                <Menu.Item name="general" active={activeItem === "general"} onClick={onItemClick} />
                <Menu.Item
                    name="courses"
                    active={activeItem === "courses"}
                    onClick={onItemClick}
                    disabled={isDirty}
                />
                <Menu.Item
                    name="authors"
                    active={activeItem === "authors"}
                    onClick={onItemClick}
                    disabled={isDirty}
                />
                <Menu.Item
                    name="reviewers"
                    active={activeItem === "reviewers"}
                    onClick={onItemClick}
                    disabled={isDirty}
                />
                <Menu.Item
                    name="tags"
                    active={activeItem === "tags"}
                    onClick={onItemClick}
                    disabled={isDirty}
                />
                <Menu.Item
                    name="comment recipients"
                    active={activeItem === "comment recipients"}
                    onClick={onItemClick}
                    disabled={isDirty}
                />
            </Menu>
        );
    }
}
