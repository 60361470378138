import { connect } from "react-redux";
import { loggedUserId } from "../../../Users/UserProfile/UserProfileSelectors";
import { withRouter } from "react-router";
import ProblemSetGrid from "./ProblemSetGrid";

const mapStateToProps = (state) => ({
    userId: loggedUserId(state),
});


export default withRouter(
    connect(mapStateToProps)(ProblemSetGrid)
);

