import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import ErrorLogViewer from "./ErrorLogViewer";
import { getErrorLog, getErrorLogAsArray } from "./errorLogSelectors";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";

const mapStateToProps = (state) => {
    return {
        errors: getErrorLogAsArray(state),
        _errors: getErrorLog(state), // only for a spinner
    };
};

export default compose(
    firebaseConnect(["error_log/list"]),
    connect(mapStateToProps),
    spinnerWhileLoading(["_errors"]),
)(ErrorLogViewer);
