
import { all, takeLatest, select, put, takeEvery } from "redux-saga/effects";
import { TYPES, onSetProblemSetHtml, onGenerateHtml, onTestsCreated } from "./SiPiTestCreatorActions";
import { toastError, DB_ERROR } from "../../../component/toast";
import { problemSetsHtml, getSelectedProblemSets } from "./SiPiTestCreatorSelectors";
import firebase from "../../dataSource";
import { renderProblemSet } from "../../../KitBuilder/LessonContent/LessonProblemSet/renderProblemSet";
import { loggedUserId } from "../../../Users/UserProfile/UserProfileSelectors";
import { onSetResource } from "../ResourceLink/ResourceLinkActions";

function* getProblemSetHtml({ payload: { id, refresh = false } }) {
    try {
        const problemSets = yield select(problemSetsHtml);
        if (id && (!problemSets[id] || refresh)) {
            yield put(onGenerateHtml(true));
            const problemSetData = yield renderProblemSet(id);
            yield put(onSetProblemSetHtml({id, data: problemSetData }));
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to generate problem set html.", message: DB_ERROR });
        yield onGenerateHtml(false);
    }
}

function* createTests({ payload: { id, callback }}) {
    try {
        const problemSets = yield select(problemSetsHtml, { fbCollection: "problemSet" });
        const rawProblemSet = yield select(getSelectedProblemSets, { fbCollection: "problemSet" });
        if (id && problemSets[id]) {
            const data = problemSets[id];
            const userId = yield select(loggedUserId);
            const studentName = `${data.title} - student`;
            const teacherName = `${data.title} - teacher`;
            const studentFile = `${studentName}.pdf`;
            const teacherFile = `${teacherName}.pdf`;
            const description = `Test generated from ${data.title}`;
            const tags = rawProblemSet.tags || [];
            const topics = rawProblemSet.topics && Object.keys(rawProblemSet.topics) || [];
            let resourceStudent = yield firebase.tabletProxy({
                method: "POST",
                uri: "/resources",
                body: {
                    name: studentName,
                    file: studentFile,
                    description,
                    tags,
                    topics,
                }
            });
            let resourceTeacher = yield firebase.tabletProxy({
                method: "POST",
                uri: "/resources",
                body: {
                    name: teacherName,
                    file: teacherFile,
                    description,
                    tags,
                    topics,
                }
            });
            const uploadedFilePathStudent = `resourceUpload/${userId}/${resourceStudent.data.uuid}/${studentFile}`;
            const uploadedFilePathTeacher = `resourceUpload/${userId}/${resourceTeacher.data.uuid}/${teacherFile}`;
            const onGenerateSiPiTests = firebase.getFirebaseFunctions().httpsCallable("sipi-onGenerateSiPiTests");
            yield onGenerateSiPiTests({
                studentPath: uploadedFilePathStudent,
                teacherPath: uploadedFilePathTeacher,
                storageUrl: firebase.getStorageURL(),
                ...data
            });
            resourceStudent = yield firebase.tabletProxy({
                method: "PATCH",
                uri: `/resources/${resourceStudent.data.id}`,
                body: {
                    uploaded: uploadedFilePathStudent,
                    resource_type: "Test"
                }
            });
            yield put(onSetResource(resourceStudent.data));
            resourceTeacher = yield firebase.tabletProxy({
                method: "PATCH",
                uri: `/resources/${resourceTeacher.data.id}`,
                body: {
                    uploaded: uploadedFilePathTeacher,
                    resource_type: "Test"
                }
            });
            yield put(onSetResource(resourceTeacher.data));
            callback({
                student: {
                    resource_id: resourceStudent.data.id,
                    name: studentName,
                    student_view: true
                },
                teacher: {
                    resource_id: resourceTeacher.data.id,
                    name: teacherName
                }
            });
            yield put(onTestsCreated());
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to create tests.", message: DB_ERROR });
        yield put(onTestsCreated());
    }
}

export default function* sipiTestCreator() {
    yield all([
        takeLatest(TYPES.SIPI_TEST_CREATOR_SELECT_PROBLEM_SET, getProblemSetHtml),
        takeEvery(TYPES.SIPI_TEST_CREATOR_CREATE_TESTS, createTests),
    ]);
}

