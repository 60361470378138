import { createSelector } from "reselect";
import { compareByNameCI } from "../../../utils/selectorUtils";
import { compareByEntityAttribute, nullsLast, compareByUserNamesCI } from "../../../utils/compareFunctions";
import { getCourseVariantDetails } from "../../../KitBuilder/AKits/CreateKit/CreateKitSelectors";
import { sporkUid } from "../../../Users/UserProfile/UserProfileSelectors";

export const isSearching = state => state.lup.resourceAdvancedSearch.isSearching;
export const isOpen = state =>  state.lup.resourceAdvancedSearch.isOpen;
export const isLoadingTags = state => state.lup.resourceAdvancedSearch.isLoadingTags;
export const isLoadingCourseVariants = state => state.lup.resourceAdvancedSearch.isLoadingCourseVariants;
export const isLoadingResourceTypes = state => state.lup.resourceAdvancedSearch.isLoadingResourceTypes;
export const isLoadingOwners = state => state.lup.resourceAdvancedSearch.isLoadingOwners;
const searchResults = state => state.lup.resourceAdvancedSearch.searchResults;
const usedTags = state => state.lup.resourceAdvancedSearch.usedTags;
const usedCourseVariants = state => state.lup.resourceAdvancedSearch.usedCourseVariants;
const owners = state => state.lup.resourceAdvancedSearch.owners;
const resourceTypes = state => state.lup.resourceAdvancedSearch.resourceTypes;

export const getResult = createSelector(
    searchResults,
    results => {
        return results;
    }
);

export const getTagsOptions = createSelector(usedTags, (tags) => {
    if (tags) {
        const sortedTags = tags.sort(compareByNameCI);
        return sortedTags.map(t => ({ key: t.name, value: t.name, text: t.name, description: `Number of uses ${t.resources.length}` }));
    }
});

export const getCourseVariantsOptions = createSelector(
    usedCourseVariants,
    (course_variants) => {
        const courseVariants = course_variants
            .sort(compareByEntityAttribute("name", nullsLast, value => value.toLocaleLowerCase().trim()));
        return courseVariants.map(courseVariant => ({
            key: courseVariant.id,
            value: courseVariant.id,
            text: courseVariant.name,
            description: getCourseVariantDetails(courseVariant),
        }));
    }
);

export const getResourceTypesOptions = createSelector(
    resourceTypes,
    (types) => {
        return types.map(type => ({
            key: type.name,
            value: type.name,
            text: type.name
        }));
    }
);

export const getOwnersOptions = createSelector(
    owners,
    sporkUid,
    (owners, myId) => {
        owners.sort(compareByUserNamesCI);
        const options = [{ key: myId, value: myId, text: "Me", description: "Myself" }];
        for (const u of owners) {
            if (myId != u.id) {
                options.push({ key: u.id, value: u.id, text: `${u.first_name} ${u.last_name}`, description: u.email});
            }
        }
        return options;
    }
);
