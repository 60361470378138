import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, List, Dimmer, Loader } from "semantic-ui-react";

import { PublishedLessonProps, LessonVersionProps } from "../PublisherProps";
import LessonFileLinks from "./LessonFilesLinks";

const ViewerHeaderStyle = {
    fontSize: "1.25rem",  // default size (~1.42rem) of header looks odd in a tiny dialog
};

export default class LessonFilesViewer extends React.PureComponent {
    static propTypes = {
        lessonId: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        outlineId: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        /** Data from "/outlines_published/{outlineId}/lessons/{lessonId}"" */
        preview: LessonVersionProps,
        release: LessonVersionProps,
        /** Data from "/outlines_published/{outlineId}/preview/{lessonId}"" */
        previewContent: PublishedLessonProps,
        /** Data from "/outlines_published/{outlineId}/release/{lessonId}"" */
        releaseContent: PublishedLessonProps,
        /** Current lesson data (loaded by parent container) */
        selectedLesson: PropTypes.object,
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const { selectedLesson, loading, preview, release, previewContent, releaseContent, onClose } = this.props;
        const dialogLabel = selectedLesson && (
            selectedLesson.name ? `Published Files: ${selectedLesson.name}` : "Published Files"
        );
        const viewPublish = preview && previewContent && "Prepublished for preview";
        const viewRelease = release && releaseContent && (
            preview && preview.version_id !== release.version_id
                ? "Previously published to all users"
                : "Published to all users"
        );
        return (
            <Modal
                open={true}
                onClose={onClose}
                size="tiny"
            >
                <Modal.Header style={ViewerHeaderStyle}>{dialogLabel}</Modal.Header>
                <Modal.Content scrolling>
                    <Dimmer.Dimmable dimmed={loading}>
                        <Dimmer active={loading}>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <List relaxed divided style={{ margin: 0 }}>
                            {viewPublish && (
                                <LessonFileLinks name={viewPublish} info={preview} data={previewContent} />
                            )}
                            {viewRelease && (
                                <LessonFileLinks name={viewRelease} info={release} data={releaseContent} />
                            )}
                        </List>
                    </Dimmer.Dimmable>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onClose} primary content="Close" />
                </Modal.Actions>
            </Modal>
        );
    }
}
