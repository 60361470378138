import React from "react";
import PropTypes from "prop-types";
import { headerPropType } from "./utils";
import { Form, Divider } from "semantic-ui-react";

const getConfigValues = (header) => (typeof header === "string" ? { title: header } : header || {});

const updateConfig = (config, field, value) => {
    const output = { ...config };
    if (!value) {
        if (output[field] !== undefined) {
            delete output[field];
        }
    } else {
        output[field] = value;
    }
    return (output.title && Object.keys(output).length) ? output : null;
};

const headerLevelOptions = [
    { key: "Hx", text: "Default", value: false },
    { key: "H1", text: "Heading 1", value: 1 },
    { key: "H2", text: "Heading 2", value: 2 },
    { key: "H3", text: "Heading 3", value: 3 },
    { key: "H4", text: "Heading 4", value: 4 },
    { key: "H5", text: "Heading 5", value: 5 },
    { key: "H6", text: "Heading 6", value: 6 },
];

const styleWithMinWidth = {
    minWidth: "8em"
};

export default class BlockTitleConfig extends React.PureComponent {
    static propTypes = {
        config: headerPropType,
        onChange: PropTypes.func.isRequired,
        subject: PropTypes.string,
        builtIn: PropTypes.bool,
    };

    state = {
        modifiedTitle: null
    };

    handleTitleFocus = () => {
        const { title } = getConfigValues(this.props.config);
        this.setState({ modifiedTitle: title });
    };

    handleTitleBlur = () => {
        this.updateTitle();
    };

    handleTitleKeyDown = (e) => {
        if (e.keyCode === 13) { // on "Enter"
            this.updateTitle();
        }
    };

    updateTitle = () => {
        const current = getConfigValues(this.props.config);
        const { modifiedTitle } = this.state;
        if (modifiedTitle !== current.title) {
            const { onChange } = this.props;
            onChange && onChange(
                updateConfig(current, "title", modifiedTitle)
            );
        }
    };

    handleTitleChange = (e, { value }) => {
        this.setState({ modifiedTitle: value.trim() });
    };

    handleChange = (e, { name, value }) => {
        const { config, onChange } = this.props;
        if (value === false || value === "") {
            value = null;
        }
        const changes = updateConfig(getConfigValues(config), name, value);
        onChange && onChange(changes && changes.title ? changes : null);
    };

    handleToggle = (e, { name, checked }) => {
        const { config, onChange } = this.props;
        if (checked === false) {
            checked = null;
        }
        const changes = updateConfig(getConfigValues(config), name, checked);
        onChange && onChange(changes && changes.title ? changes : null);
    }

    render() {
        const { config, subject, builtIn = false } = this.props;
        const { modifiedTitle } = this.state;
        const header = getConfigValues(config);

        const emptyTitle = !(modifiedTitle === null ? header.title : modifiedTitle);
        return (
            <React.Fragment>
                <Divider horizontal>Title</Divider>
                <Form.Group>
                    <Form.Input
                        name="title"
                        width="12"
                        defaultValue={header.title}
                        placeholder={subject ? `Fill ${subject} title (optional)` : "Fill title (optional)"}
                        onChange={this.handleTitleChange}
                        onBlur={this.handleTitleBlur}
                        onFocus={this.handleTitleFocus}
                        onKeyDown={this.handleTitleKeyDown}
                    />
                    <Form.Dropdown selection
                        width="4"
                        disabled={emptyTitle}
                        name="level"
                        value={header.level || false}
                        onChange={this.handleChange}
                        options={headerLevelOptions}
                        style={styleWithMinWidth}
                    />
                </Form.Group>
                {!builtIn && 
                <Form.Group>
                    <Form.Checkbox
                        label={"Draw divider line"}
                        disabled={emptyTitle}
                        name="divider"
                        checked={Boolean(header.divider)}
                        onChange={this.handleToggle}
                    />
                    <Form.Checkbox
                        label={"Separate title"}
                        disabled={emptyTitle}
                        name="separated"
                        checked={Boolean(header.separated)}
                        onChange={this.handleToggle}
                    />
                </Form.Group>}
            </React.Fragment>
        );
    }
}
