import PropTypes from "prop-types";

export const LessonVersionProps = PropTypes.shape({
    version_id: PropTypes.string.isRequired,
    created_at: PropTypes.string,
    created_by: PropTypes.string,
    createdBy: PropTypes.object,
});

export const PublishedStatusProps = PropTypes.shape({
    preview: LessonVersionProps,
    release: LessonVersionProps
});

const LessonFileShape = PropTypes.shape({
    hash: PropTypes.string,
    path: PropTypes.string,
});

export const LessonFileType = PropTypes.oneOfType([ PropTypes.string, LessonFileShape ]);

const LessonContentProps = PropTypes.shape({
    teacher: LessonFileType,
    student: LessonFileType,
    glossary: LessonFileType,
});
const LessonRelatedProps = PropTypes.shape({
    ordering: PropTypes.number,
    teacher: LessonFileType,
    student: LessonFileType,
});

export const PublishedLessonProps = PropTypes.shape({
    version_id: PropTypes.string.isRequired,
    content: LessonContentProps,
    hw: PropTypes.objectOf(LessonRelatedProps),
    ps: PropTypes.objectOf(LessonRelatedProps),
});

