import { bindActionCreators } from "redux";
import {
    onCreateQuestion,
    onDeleteQuestion,
    onEditQuestion,
    onNextPage,
    onPrevPage,
    onGetAllCount,
    onApplyFilterBy,
    onResetFilterBy,
} from "./ProblemManagementActions";
import { onSetOutline } from "../../KitBuilder/LessonLink/LessonLinkActions";

export const bindActions = (dispatch) => bindActionCreators({
    onCreateQuestion,
    onDeleteQuestion,
    onEditQuestion,
    onSetOutlineForLessonLinks: onSetOutline,
    onNextPage,
    onPrevPage,
    onGetAllCount,
    onApplyFilterBy,
    onResetFilterBy,
}, dispatch);

export default bindActions;