import { TYPES } from "./UserCoursesActions";

const initialState = {
    isLoadingUser: false,
    userDetails: null,
    isSearching: false,
    foundPeople: [],
    userScope: "teacher",
    schoolId: null,
    name: "",
};

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case TYPES.SECTION_USER_SELECT_USER:
            return { ...state, isLoadingUser: true };
        case TYPES.SECTION_USER_SET_USER_DETAILS:
            return { ...state, isLoadingUser: false, userDetails: payload };
        case TYPES.SECTION_USER_SEARCH_PERSON:
            return { ...state, isSearching: true, foundPeople: [] };
        case TYPES.SECTION_USER_SET_SEARCH_RESULT:
            return { ...state, isSearching: false, foundPeople: payload };
        case TYPES.SECTION_USER_SET_FIELD_VALUE:
            return { ...state, [payload.name]: payload.value };
        default:
            return state;
    }
};
