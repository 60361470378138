import { EditorState, Modifier, SelectionState, AtomicBlockUtils } from "draft-js";
import { getEntityRange } from "draftjs-utils";

import { ENTITY_TYPE, IMAGE_PLACEHOLDER_CHAR } from "../constants";
import { deleteBlockEntity } from "../utils/utils";

export const getDisplaySize = (imageData) => {
    const { src, width, height } = imageData;
    const { originalWidth, originalHeight } = src;
    let hasPercentWidth = width.indexOf("%") > 0;
    let hasPercentHeight = height.indexOf("%") > 0;
    let widthValue = parseFloat(width);
    let heightValue = parseFloat(height);
    let widthPercent  = width  === "auto" ? 100 : hasPercentWidth  ? widthValue  : widthValue  / originalWidth  * 100;
    let heightPercent = height === "auto" ? 100 : hasPercentHeight ? heightValue : heightValue / originalHeight * 100;

    // Recalculate both absolute size in px and "auto" to percent.
    // Final calculation is always done from percent.
    if (width === "auto") {
        if (height !== "auto") {
            widthPercent = heightPercent;
        }
    }
    else if (hasPercentWidth) {
        // width in %
        if (height === "auto") {
            heightPercent = widthPercent;
        }
    }
    else {
        // width in px
        if (height === "auto") {
            heightPercent = widthPercent;
        }
    }

    return {
        // We could calculate vertical offset here if it is an issue.
        // E.g. non-proportional dimension 100%/50% moves the span with EM SPACE up.
        displayWidth: widthPercent * originalWidth / 100 + "px",
        displayHeight: heightPercent * originalHeight / 100 + "px",
    };
};

export const insertInlineImage = (imageData, editorState) => {
    let contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    contentState = contentState.createEntity(ENTITY_TYPE.INLINE_IMAGE, "IMMUTABLE", {
        ...imageData,
        ...getDisplaySize(imageData),
    });

    const entityKey = contentState.getLastCreatedEntityKey();
    contentState = Modifier.replaceText(contentState, selection, IMAGE_PLACEHOLDER_CHAR, null, entityKey);
    const newEditorState = EditorState.push(editorState, contentState, "insert-characters");
    return EditorState.forceSelection(newEditorState, contentState.getSelectionAfter());
};

export const updateInlineImageSize = (editorState, entityKey, size) => {
    let contentState = editorState.getCurrentContent();
    const entityData = contentState.getEntity(entityKey).getData();
    const displaySize = getDisplaySize({ ...entityData, ...size });

    contentState.mergeEntityData(entityKey, {
        ...displaySize,
        ...size
    });

    return EditorState.push(editorState, contentState, "apply-entity");
};

export const convertImageType = (targetType, size, blockKey, entityKey, editorState) => {
    let contentState = editorState.getCurrentContent();
    const entity = contentState.getEntity(entityKey);
    let imageData = {
        ...entity.getData(),
        ...size,
    };

    if (targetType === ENTITY_TYPE.IMAGE) {
        const entityRange = getEntityRange(editorState, entityKey);
        let selection = new SelectionState({
            anchorKey: blockKey,
            anchorOffset: entityRange.start,
            focusKey: blockKey,
            focusOffset: entityRange.end,
        });
        contentState = Modifier.removeRange(contentState, selection, "forward");
        contentState = Modifier.splitBlock(contentState, contentState.getSelectionAfter());
        editorState = EditorState.push(editorState, contentState, "split-block");

        // https://github.com/jpuri/react-draft-wysiwyg/blob/1c8e85c45e466884b1e4494b50242442de27aa6e/src/controls/Image/index.js#L61
        const newEntityKey = contentState.createEntity("IMAGE", "MUTABLE", imageData).getLastCreatedEntityKey();
        editorState = AtomicBlockUtils.insertAtomicBlock(editorState, newEntityKey, " ");
    }
    else {
        const displaySize = getDisplaySize({ ...imageData, ...size });
        const targetBlock = contentState.getBlockBefore(blockKey);
        const selection = new SelectionState({
            anchorKey: targetBlock.getKey(),
            anchorOffset: targetBlock.getLength(),
            focusKey: targetBlock.getKey(),
            focusOffset: targetBlock.getLength(),
        });

        imageData = { ...imageData, ...displaySize };
        editorState = deleteBlockEntity(contentState.getBlockForKey(blockKey), contentState, editorState);
        editorState = EditorState.acceptSelection(editorState, selection);
        editorState = insertInlineImage(imageData, editorState);
    }

    return editorState;
};

export const getImageName = (path) => {
    return path.split("/").pop();
};

export const getRenderedImageSize = (url) => {
    const images = [ ...document.getElementsByTagName("img") ];
    const img = images.filter((img) => img.src === url);

    if (img.length) {
        const { width, height } = img[0].getBoundingClientRect();
        return { width, height };
    }

    return { width: 40, height: 40 };  // better than nothing
};
