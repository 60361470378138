import React from "react";
import PropTypes from "prop-types";
import { Icon, Label } from "semantic-ui-react";

import { buildSafeSourceType } from "./SourceTypeUtils";

const IconStyle = {
    margin: "0 .25rem 0 0",
    padding: "inherit",
};

const DescriptionStyle = {
    fontSize: "0.95em",
    fontStyle: "italic",
    color: "grey",
    padding: "0.25em",
};

export default class SourceType extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number,
        // eslint-disable-next-line react/no-unused-prop-types
        icon: PropTypes.string,
        // eslint-disable-next-line react/no-unused-prop-types
        name: PropTypes.string,
        description: PropTypes.string,
        showAsLabel: PropTypes.bool,
    };

    render() {
        const { id, description, showAsLabel } = this.props;
        if (!id) {
            return <div><i>Not available</i></div>;
        }

        const cfg = buildSafeSourceType(this.props);
        return showAsLabel ? (
            <div>
                <Label color={cfg.name && cfg.color} basic>
                    <Icon name={cfg.icon} />
                    {(id && cfg.name) || <i>Not available</i>}
                </Label>
                {description && <div style={DescriptionStyle}>{description}</div>}
            </div>
        ) : (
            <div>
                <Icon name={cfg.icon} color={cfg.color} style={IconStyle} />
                <span>{(id && cfg.name) || <i>Not available</i>}</span>
                {description && <div style={DescriptionStyle}>{description}</div>}
            </div>
        );
    }
}
