import firebase from "../../../firebase";
import { loadData, fb_list_object, getUpdateObjectForPaths, applyUpdateObject } from "../../../utils/sagaForFBUtils";
import { compareByOrdering } from "../../../utils/selectorUtils";
import _ from "lodash";

/**
 * Id as used by a database to identify entities. For Firebase it is a string.
 * @typedef {string} DbId
 */

const dataPath = "outlines_data";
const listPath = "outlines";

export const paths = {
    dataPath,
    listPath,
};

export const createLessonUnit = async (outlineId, name) => {

    const dataOutlineRef = firebase.getFirebaseData(dataPath+`/${outlineId}`);
    const dataOutline = await loadData(dataOutlineRef);

    /* todo: test if outlineId exists */

    const lessonUnits = (dataOutline.lesson_units ?
        fb_list_object(dataOutline.lesson_units) :
        []
    );

    const maxOrdering = lessonUnits.reduce(
        (max, lessonUnit) => {
            if (!lessonUnit.ordering) return max;
            return Math.max(max, lessonUnit.ordering);
        },
        0
    );

    const dataRef = firebase.getFirebaseData(dataPath + `/${outlineId}/lesson_units`);
    const { key } = await dataRef.push( { name: name, ordering: maxOrdering + 1 } );

    return key;
};

const _getLessonUnitIndexRecords = async(id) => {
    /* najit cestu / pouziti lesson unit v seznamu outlines */
    const outlineDataRaw = await loadData(dataPath);
    const outlineData = fb_list_object(outlineDataRaw);

    const indexRecords = outlineData
        .filter( (outline) => {
            if (!outline.lesson_units) return false;

            return fb_list_object(outline.lesson_units).some( (lesson_unit) => { return lesson_unit.id == id; } );
        } )
        .map( (outline) => {
            return [outline.id, id];
        });

    return indexRecords;
};

/**
 * General algorithm for updating lesson unit's properties or removing it.
 *
 * @param {DbId} id lesson unit id
 * @param {*} value new value for property to be updated. null for removal of lesson unit
 * @param { function(array<array<DbId>>): array<string> } indexToPath function returning path to lesson unit's property to be updated or to lesson unit to be removed.
 * e.g. indexToPath(recordIndex) => { return `${recordIndex[0]}/lesson_units/${recordIndex[1]}/name`; }
 *
 */
const _lessonUnitUpdate = async (id, value, indexToPath) => {

    /* najit cestu / pouziti lesson unit v seznamu outlines */
    const indexRecords = await _getLessonUnitIndexRecords(id);

    const paths = indexRecords.map( indexToPath );
    const updateObject = getUpdateObjectForPaths(paths, value);
    await applyUpdateObject(updateObject, firebase.getFirebaseData(dataPath) /* onComplete */ );
};

export const renameLessonUnit = async (id, name) => {

    await _lessonUnitUpdate(id, name,
        (indexRecord) => { return `${indexRecord[0]}/lesson_units/${indexRecord[1]}/name`; }
    );
    return;
};

export const changeDescriptionLessonUnit = async (id, description) => {

    await _lessonUnitUpdate(id, description,
        (indexRecord) => { return `${indexRecord[0]}/lesson_units/${indexRecord[1]}/description`; }
    );

};

export const removeLessonUnit = async (id) => {

    /* remove all lessons contained in the lesson unit. */
    const outlineDataRaw = await loadData(dataPath);
    const outlineData = fb_list_object(outlineDataRaw);
    const indexRecords = []; // of lessons contained in the lesson unit
    outlineData.forEach( (outline) => {

        if (!outline.lessons) return;

        fb_list_object(outline.lessons).forEach((lesson) => {

            if (lesson.lesson_unit_id == id) {
                indexRecords.push([outline.id, lesson.id]);
            }
        });
    });

    const indexToPath = (indexRecord) => { return `${indexRecord[0]}/lessons/${indexRecord[1]}`; };
    const value = null;
    const paths = indexRecords.map( indexToPath );
    const updateObject = getUpdateObjectForPaths(paths, value);
    await applyUpdateObject(updateObject, firebase.getFirebaseData(dataPath) /* onComplete */ );

    /* remove lesson unit itself */
    await _lessonUnitUpdate(id, null,
        (indexRecord) => { return `${indexRecord[0]}/lesson_units/${indexRecord[1]}`; }
    );
};

export const moveLessonUnit = async(id, targetContainerId, targetIndex) => {

    /* find lesson index to get outlineId and to verify the id is correct */
    const indexRecords = await _getLessonUnitIndexRecords(id);
    if (indexRecords.length <= 0)
        return;

    const lessonUnitIndexRecord = indexRecords[0];
    const outlineId = lessonUnitIndexRecord[0];

    if (targetContainerId !== outlineId) {

        // console.log('Moving a lesson unit to another outline is not allowed.');
        return;
    }

    /* get list of all lessons in target Lesson Unit, ordered by it ordering attribute */
    const targetLessonUnits = fb_list_object(await loadData(dataPath + `/${outlineId}/lesson_units`))
        .sort( compareByOrdering );
    // /* Position analysis, skip cases when moving is not necessary */
    let sourceIndex = targetLessonUnits.findIndex( (lessonUnit) => { return (lessonUnit.id == id); } );

    if (targetIndex < 0) {
        targetIndex = 0;
    }

    if (targetIndex > targetLessonUnits.length-1 ) {
        /* There is a request to move the lesson unit to the end of the list */

        if (sourceIndex == targetLessonUnits.length-1) {

            /* the lesson unit is already at the end of the list, no need to reordering */
            // console.log("Given lesson unit id is already at the end of list, no need to reorder");
            return;
        }

        if (sourceIndex < 0) {
            /* the lesson unit is not in the list */
            targetIndex = (targetLessonUnits.length-1) + 1;
        } else {
            /* the lesson unit is in the list but not at its end */
            targetIndex = (targetLessonUnits.length-1) + 1;
        }

    } else if (sourceIndex == targetIndex) {
        /* Given lesson unit id is already at position targetIndex, no need to reorder. */
        // console.log("Given lesson unit id is already at position targetIndex, no need to reorder");
        return;
    }

    /* if moving item forward in the list, we want to move the item AFTER the index targetIndex */
    if ((sourceIndex >= 0) && (sourceIndex < targetIndex)) {
        targetIndex++;
    }

    /* for path dataPath + `/${outlineId}/lesson_units */
    const updateObject = {};

    /* Some lesson units could be missing ordering attribute; add it to them */
    let lastOrdering = 0;
    for(let i = 0; i < targetLessonUnits.length; i++) {
        if (!targetLessonUnits[i].ordering) {
            targetLessonUnits[i].ordering = lastOrdering + 1;

            updateObject[`${targetLessonUnits[i].id}/ordering`] = targetLessonUnits[i].ordering;
        }

        lastOrdering = targetLessonUnits[i].ordering;
    }
    /* As a side effect lastOrdering now contains the largest ordering used */
    /* What value of ordering do we want for newly positioned lesson unit */
    const targetOrdering = (!targetLessonUnits[targetIndex] ?
        lastOrdering + 1 : /* Target index does not exists, i.e. we are appending to the end of list so the target ordering will be after the last ordering used yet */
        targetLessonUnits[targetIndex].ordering /* We want move to this ordering */
    );

    /* set target ordering unit for the item being moved. */
    // console.log("setting lesson unit", id, "to ordering ", targetOrdering);
    updateObject[`${id}/ordering`] = targetOrdering;

    /* adjust ordering of all following lesson units within outline */
    let lastOrderingAdjusted = targetOrdering;
    for(let i = targetIndex; i < targetLessonUnits.length; i++) {

        /* in target list we have encountered the item being moved so we skip it, because its ordering has already been adjusted prior to this loop */
        /* This should happen when moving the item forward in the list */
        if (targetLessonUnits[i].id == id) {
            continue;
        }

        if (targetLessonUnits[i].ordering <= lastOrderingAdjusted) {
            lastOrderingAdjusted++;

            // console.log("setting lesson unit", targetLessons[i].id, "to ordering ", lastOrderingAdjusted);
            updateObject[`${targetLessonUnits[i].id}/ordering`] = lastOrderingAdjusted;

        } else {
            /* there was a gap in ordering, no need to reorder those remaining lesson units */
            // console.log("gap reached, exiting loop");
            break;
        }
    }

    await applyUpdateObject(updateObject, firebase.getFirebaseData(dataPath + `/${outlineId}/lesson_units`));
};

export const stateLessonUnitMove = (stateOutline, id, targetContainerId, targetIndex) => {
    const stateOutlineCopy = _.cloneDeep(stateOutline);
    // const srcLessonUnit = findLessonUnit(stateOutlineCopy, sourceContainerId);
    const srcLessonUnitIndex = stateOutlineCopy && stateOutlineCopy.lesson_units && stateOutlineCopy.lesson_units.findIndex(lu => lu.key === id);
    const lessonUnitData = stateOutlineCopy.lesson_units[srcLessonUnitIndex];
    stateOutlineCopy.lesson_units.splice(srcLessonUnitIndex, 1);
    // const dstLessonUnit = findLessonUnit(stateOutlineCopy, targetContainerId);
    stateOutlineCopy.lesson_units.splice(targetIndex, 0, lessonUnitData);
    return stateOutlineCopy;
};