import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResourceTopics from "./ResourceTopics";
import { onRemoveTopic, onAddTopics } from "../../ResourcesActions";
import { isUpdatingTopics } from "../../ResourcesSelectors";

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            onAddTopics,
            onRemoveTopic,
        },
        dispatch
    );
};

export default connect(
    (state) => ({ 
        updating: isUpdatingTopics(state)
    }),
    dispatchToProps
)(ResourceTopics);