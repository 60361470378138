import React from "react";
import PropTypes from "prop-types";
import BorderStyleSelection from "../../component/BorderStyleSelection";

export default class BoxOutline extends React.PureComponent {
    static propTypes = {
        border: PropTypes.string,
        borderColor: PropTypes.string,
        onChange: PropTypes.func.isRequired
    };

    render() {
        const { borderColor, border, onChange } = this.props;
        return (
            <BorderStyleSelection
                defaultValue={border}
                placeholder="No Style"
                onChange={onChange}
                disabled={!borderColor}
            />
        );
    }
}
