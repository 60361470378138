import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import { DroppableWrapper } from "../Dnd";
import LessonUnitsListContainer from "./LessonUnitsListContainer";

export default class LessonUnitsListDroppable extends PureComponent {
    static propTypes = {
        outlineId: PropTypes.string.isRequired,
        lessonUnitsList: PropTypes.array.isRequired,
        canEdit: PropTypes.bool,
    };

    render() {
        const { outlineId, lessonUnitsList, canEdit } = this.props;
        return canEdit ? (
            <Droppable droppableId={outlineId} type="LESSON_UNIT">
                {provided => (
                    <DroppableWrapper provided={provided} innerRef={provided.innerRef}>
                        <LessonUnitListCont
                            outlineId={outlineId}
                            lessonUnitsList={lessonUnitsList}
                            provided={provided}
                            canEdit={canEdit}
                        />
                    </DroppableWrapper>)}
            </Droppable>
        ) : (
            <LessonUnitListCont
                outlineId={outlineId}
                lessonUnitsList={lessonUnitsList}
                provided={{}}
                canEdit={canEdit}
            />
        );
    }
}

const LessonUnitListCont = ({ outlineId, lessonUnitsList, provided, canEdit }) => (
    <LessonUnitsListContainer
        outlineId={outlineId}
        lessonUnitsList={lessonUnitsList}
        droppablePlaceholder={provided.placeholder}
        canEdit={canEdit}
    />
);
LessonUnitListCont.propTypes = LessonUnitsListContainer.propTypes;