const TYPES = {
    TAGS_SAVE: "TAGS_SAVE",
};

const onSave = (value) => ({
    type: TYPES.TAGS_SAVE,
    value: value
});

export {
    TYPES,
    onSave,
};
