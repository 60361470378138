/**
 * This class manage two map:
 *    - relation between rowIndex and unique rowId
 *    - relation between pair [rowIndex, colIndex] and unique cellId.
 *
 * Each row has its own unique ID - it is used as `key` property for rendering `TR` node.
 * Each cell has its own unique ID - it is used as `key` property for rendering `TD` node.
 *
 * Initial state of table 2 x 3:
 *
 *     [rowIndex, colIndex] -> cellId  |  rowIndex -> rowId
 *		        0     1     2          |
 *		     +-----+-----+-----+       |       +-----+
 *		  0  |  0  |  1  |  2  |       |       |  0  |
 *		     +-----+-----+-----+       |       +-----+
 *		  1  |  3  |  4  |  5  |       |       |  1  |
 *		     +-----+-----+-----+       |       +-----+
 *
 * Any changes in the table (add/delete row/column) has to make corresponding changes in the maps to keep
 * relation between particular cell and its CellEditor.
 *
 * E.g.: when column 1 is removed, the original column 2 is moved to position 1, but cellId has to kept its
 * original value for keeping state of the related CellEditor.
 *
 *     [rowIndex, colIndex] -> cellId
 *		        0     1
 *		     +-----+-----+
 *		  0  |  0  |  2  |
 *		     +-----+-----+
 *		  1  |  3  |  5  |
 *		     +-----+-----+
 *
 * See more details in a unit test.
 */
export default class TableCellsMap {
    constructor() {
        this._rowId = 0;
        this._cellId = 0;
        this._rowsMap = null;
        this._rowsId = null;
    }

    init = (rows, cols) => {
        const emptyRows = new Array(rows).fill(0);
        this._rowId = 0;
        this._cellId = 0;

        this._rowsId = emptyRows.map(() => this._getUniqueRowId());

        this._rowsMap = emptyRows.map(() => {
            const cellsMap = [];
            for (let col = 0; col < cols; col++) {
                cellsMap.push(this._getUniqueCellId());
            }
            return cellsMap;
        });
    };

    _getUniqueRowId = () => this._rowId++;

    _getUniqueCellId = () => this._cellId++;

    getRowsCount = () => this._rowsMap.length;

    getColsCount = () => {
        const cells = this._rowsMap[0];
        return (cells && cells.length) || 0;
    };

    getSize = () => ({
        rows: this.getRowsCount(),
        cols: this.getColsCount(),
    });

    /**
     * Inserts the new row at the specified index.
     *
     * @param {number} index.
     */
    insertRow = (index) => {
        const rowsCount = this.getRowsCount();
        const colsCount = this.getColsCount();
        const cellsMap = [];

        if (index > rowsCount) {
            index = rowsCount;
        }

        for (let col = 0; col < colsCount; col++) {
            cellsMap.push(this._getUniqueCellId());
        }

        this._rowsMap.splice(index, 0, cellsMap);
        this._rowsId.splice(index, 0, this._getUniqueRowId());
    };

    deleteRow = (index) => {
        this._rowsMap.splice(index, 1);
        this._rowsId.splice(index, 1);
    };

    /**
     * Inserts the new column at the specified index.
     *
     * @param {number} index
     */
    insertColumn = (index) => {
        const colsCount = this.getColsCount();

        if (index > colsCount) {
            index = colsCount;
        }

        this._rowsMap.forEach((cellsMap) => {
            cellsMap.splice(index, 0, this._getUniqueCellId());
        });
    };

    deleteColumn = (index) => {
        this._rowsMap.forEach((cellsMap) => {
            cellsMap.splice(index, 1);
        });
    };

    getRowId = (row) => {
        return this._rowsId[row];
    };

    getCellId = (row, col) => {
        return this._rowsMap[row][col];
    };
}
