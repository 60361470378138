exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WTUSg_h7jdlJcgE7XPRdE {\n  height: 4em; }\n  .WTUSg_h7jdlJcgE7XPRdE > div {\n    display: flex;\n    flex-direction: row;\n    padding-left: 18px; }\n  .WTUSg_h7jdlJcgE7XPRdE .zlHKh2wZLQTAxJUe8pJ9C span {\n    margin-left: 8px; }\n  .WTUSg_h7jdlJcgE7XPRdE .ui.input {\n    margin-left: 8px; }\n  .WTUSg_h7jdlJcgE7XPRdE .ui.input > input {\n    padding: 0.3em; }\n\n._1XEbCDu4DpNSkdjezk--nm {\n  font-size: 1em;\n  color: rgba(0, 0, 0, 0.7); }\n  ._1XEbCDu4DpNSkdjezk--nm span {\n    display: inline-block;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    margin-left: 8px; }\n  ._1XEbCDu4DpNSkdjezk--nm .kMm1V63Xw2RALaqnlQkq3 {\n    font-style: italic; }\n", ""]);

// exports
exports.locals = {
	"header": "WTUSg_h7jdlJcgE7XPRdE",
	"title": "zlHKh2wZLQTAxJUe8pJ9C",
	"listItem": "_1XEbCDu4DpNSkdjezk--nm",
	"italic": "kMm1V63Xw2RALaqnlQkq3"
};