import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon, Dropdown } from "semantic-ui-react";

export default class AssignTopic extends PureComponent {
    static propTypes = {
        selectedTopic: PropTypes.shape({
            path: PropTypes.string
        }),
        onClick: PropTypes.func.isRequired
    };

    handleClick = (e, { value }) => {
        this.props.onClick(value, this.props.selectedTopic);
    };

    render() {
        return (
            <Dropdown icon={<Icon name="plus" color="grey" />}>
                <Dropdown.Menu>
                    <Dropdown.Item text="Link to existing topic" value="link" onClick={this.handleClick} />
                    <Dropdown.Item text="Create a new topic" value="new" onClick={this.handleClick} />
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
