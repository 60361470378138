import { TYPES } from "./WidgetTemplatesActions";

const initialState = {
    selectedItem: null,
    searchFilter: "",
    selectedTags: [],
    isNew: false,
    isDirty: false,
    isSaving: {},
    moduleFilter: [],
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.WIDGET_TEMPLATES_ITEM_SELECTED:
            return { ...state, selectedItem: payload.selectedItem, isNew: payload.isNew };
        case TYPES.WIDGET_TEMPLATES_SEARCH_CHANGED:
            return { ...state, searchFilter: payload };
        case TYPES.WIDGET_TEMPLATES_SELECT_TAG: {
            return { ...state, selectedTags: payload };
        }
        case TYPES.WIDGET_TEMPLATES_CLOSE_NAME_EDITOR:
            return { ...state, isNew: false };
        case TYPES.WIDGET_TEMPLATES_SET_DIRTY:
            return { ...state, isDirty: payload };
        case TYPES.WIDGET_TEMPLATES_SAVING: {
            return { ...state, isSaving: { ...state.isSaving, [`${payload.templateId}@${payload.itemId}`]: payload.isSaving } };
        }
        case TYPES.WIDGET_TEMPLATES_SET_MODULE_FILTER:
            return { ...state, moduleFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
