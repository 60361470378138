import { TYPES } from "./PublisherActions";

const publisherReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case TYPES.PUBLISH_LESSON_REQUEST: {
            const { lessonId, mode } = payload;
            return { ...state, [lessonId]: mode };
        }
        case TYPES.PUBLISH_LESSON_FAILURE:
        case TYPES.PUBLISH_LESSON_SUCCESS: {
            const { lessonId } = payload;
            return { ...state, [lessonId]: undefined };
        }
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default publisherReducer;
