import { all, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import { TYPES, onSetCvtTemplate, onGoBack } from "./SyllabusOutlineActions";
import { push } from "connected-react-router";
import firebase, { sipiData } from "../dataSource";
import { convertListToDataset } from "../../utils/selectorUtils";
import { toastError, DB_ERROR, sagaToastError } from "../../component/toast";
import { loadResourcesForIPlanner } from "../SiPiTemplate/SiPiTemplateSaga";
import { onLoadResource } from "../component/ResourceLink/ResourceLinkActions";
import { syllabiLessonUnits } from "./SyllabusOutlineSelectors";

const basePath = "syllabi";
function* goBack () {
    yield put(push("/iplanner/syllabi"));
}

function* updateSiPiOrder ({ payload: { lessonUnitId, orderedSiPi, iPlannerId }}) {
    try {
        const data = convertListToDataset(orderedSiPi);
        yield sipiData.updateSiPiContent(basePath, iPlannerId, lessonUnitId, data);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update order of instrument content", message: DB_ERROR });
    }
}

function* updateSiPi ({ payload: { iPlannerId, lessonUnitId, sipiId, data }}) {
    try {
        yield sipiData.updateSiPi(basePath, iPlannerId, lessonUnitId, sipiId, data);
        if (data.resource_id) {
            yield put(onLoadResource(data.resource_id));
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update instrument", message: DB_ERROR });
    }
}

function* addSiPi ({ payload: { iPlannerId, lessonUnitId, data }}) {
    try {
        yield sipiData.addSiPi(basePath, iPlannerId, lessonUnitId, data);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to add new instrument", message: DB_ERROR });
    }
}

function* massAddSiPi ({ payload: { iPlannerId, lessonUnitId, data }}) {
    try {
        yield sipiData.massAddSiPis(basePath, iPlannerId, lessonUnitId, data);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to copy instrument from template", message: DB_ERROR });
    }
}

function* removeSiPi ({ payload: { iPlannerId, lessonUnitId, sipiId }}) {
    try {
        yield sipiData.removeSiPi(basePath, iPlannerId, lessonUnitId, sipiId);
    } catch (err) {
        toastError({ code: err.code, header: "Failed to remove instrument", message: DB_ERROR });
    }
}

function* loadSyllabusResources ({ payload: { iPlannerId, templateId } }) {
    try {
        yield loadResourcesForIPlanner(iPlannerId, "syllabus");
        if (templateId) {
            yield loadResourcesForIPlanner(templateId, "template");
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load resources for this iPlanner", message: DB_ERROR });
    }
}

function* loadCvTemplateByCv ({ payload: { course_variant_id, tenant_id } }) {
    try {
        const cvTemplateRef = firebase.getFirebaseData("sipi_templates").child("info").orderByChild("course_variant_id").equalTo(course_variant_id);
        const data = (yield cvTemplateRef.once("value")).val();
        if (data) {
            for (const key of Object.keys(data)) {
                const template = data[key];
                if (tenant_id === template.tenant_id) {
                    yield put(onSetCvtTemplate(template));
                }
            }
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load iPlanner Template", message: DB_ERROR });
    }
}

function* updateLesson({ payload: { name, unitName, ...data} }) {
    try {
        yield sipiData.updateLesson(basePath, { ...data, name: `${unitName} :: ${name}`});
    } catch (error) {
        sagaToastError("Failed to update iPlanner lesson.", error);
    }
}

function* createLesson({ payload: { name, unitName, iPlannerId, ...data} }) {
    try {
        const lessonUnits = yield select(syllabiLessonUnits);
        let index = 0;
        if (lessonUnits[iPlannerId]) {
            const indexes = Object.keys(lessonUnits[iPlannerId]);
            index = Number.parseFloat(indexes[indexes.length - 1]) + 1;
        }
        yield sipiData.createLesson(basePath, { ...data, name: `${unitName} :: ${name}`, index, iPlannerId });
    } catch (error) {
        sagaToastError("Failed to create iPlanner lesson.", error);
    }
}

function* removeLesson({ payload: {iPlannerId, lessonId }}) {
    try {
        const lessonUnits = yield select(syllabiLessonUnits);
        if (lessonUnits[iPlannerId]) {
            const remainingLessons = lessonUnits[iPlannerId].filter(l => l.lu_id !== lessonId);
            yield sipiData.updateLessonsList(basePath, iPlannerId, remainingLessons);
        }
        
    } catch (error) {
        sagaToastError("Failed to iPlanner lesson.", error);
    }
}

function* updateLessonOrder({ payload: { iPlannerId, lessonUnits }}) {
    try {
        yield sipiData.updateLessonsList(basePath, iPlannerId, lessonUnits);
    } catch (error) {
        sagaToastError("Failed to update lesson order.", error);
    }
}

function* updateName({ payload: { iPlannerId, name }}) {
    try {
        yield sipiData.updateName(basePath, iPlannerId, { syllabus_name: name });
    } catch (error) {
        sagaToastError("Failed to update iPlanner name.", error);
    }
}

function* removeiPlanner({ payload }) {
    try {
        yield sipiData.removeiPlanner(basePath, payload);
        yield put(onGoBack());
    } catch (error) {
        sagaToastError("Failed to remove iPlanner.", error);
    }
}

export default function* syllabusOutline() {
    yield all([
        takeLatest(TYPES.SYLLABUS_OUTLINES_GO_BACK, goBack),
        takeLatest(TYPES.SYLLABUS_OUTLINES_UPDATE_ACTIVITIES_ORDER, updateSiPiOrder),
        takeEvery(TYPES.SYLLABUS_OUTLINES_UPDATE_ACTIVITY, updateSiPi),
        takeEvery(TYPES.SYLLABUS_OUTLINES_ADD_ACTIVITY, addSiPi),
        takeLatest(TYPES.SYLLABUS_OUTLINES_REMOVE_ACTIVITY, removeSiPi),
        takeLatest(TYPES.SYLLABUS_OUTLINES_LOAD_IPLANNER_RESOURCES, loadSyllabusResources),
        takeLatest(TYPES.SYLLABUS_OUTLINES_MASS_ADD_SIPIS, massAddSiPi),
        takeLatest(TYPES.SYLLABUS_OUTLINES_LOAD_CV_TEMPLATE_BY_CV, loadCvTemplateByCv),
        takeEvery(TYPES.SYLLABUS_OUTLINES_UPDATE_LESSON, updateLesson),
        takeEvery(TYPES.SYLLABUS_OUTLINES_CREATE_LESSON, createLesson),
        takeEvery(TYPES.SYLLABUS_OUTLINES_REMOVE_LESSON, removeLesson),
        takeLatest(TYPES.SYLLABUS_OUTLINES_UPDATE_LESSON_ORDER, updateLessonOrder),
        takeLatest(TYPES.SYLLABUS_OUTLINES_UPDATE_NAME, updateName),
        takeEvery(TYPES.SYLLABUS_OUTLINES_REMOVE, removeiPlanner),
    ]);
}
