const TYPES = {
    WIDGETS_GLOSSARY_ADD_TERM: "Widgets/Glossary/ADD_TERM",
    WIDGETS_GLOSSARY_CHANGE_FILTER: "Widgets/Glossary/CHANGE_FILTER",
    WIDGETS_GLOSSARY_CHANGE_NAME: "Widgets/Glossary/CHANGE_NAME",
    WIDGETS_GLOSSARY_REMOVE_TERM: "Widgets/Glossary/REMOVE_TERM",
    WIDGETS_GLOSSARY_UPDATE_TERM: "Widgets/Glossary/UPDATE_TERM",
    WIDGETS_GLOSSARY_CANCEL_ADD_TERM: "Widgets/Glossary/CANCEL_ADD_TERM",
    WIDGETS_GLOSSARY_CREATE_TERM: "Widgets/Glossary/CREATE_TERM",
    WIDGETS_GLOSSARY_CLOSE_DETAIL: "Widgets/Glossary/CLOSE_DETAIL",
    WIDGETS_GLOSSARY_SEARCH_TERM: "Widgets/Glossary/SEARCH_TERM",
    WIDGETS_GLOSSARY_SET_SEARCH_RESULT: "Widgets/Glossary/SET_SEARCH_RESULT",
    WIDGETS_GLOSSARY_SET_PREFERENCE_BY_OUTLINE: "Widgets/Glossary/SET_PREFERENCE_BY_OUTLINE",
    WIDGETS_GLOSSARY_CHANGE_UNUSED_FILTER: "Widgets/Glossary/CHANGE_UNUSED_FILTER",
    WIDGETS_GLOSSARY_SELECT_TAG: "Widgets/Glossary/SELECT_TAG",
    WIDGETS_GLOSSARY_SET_TAG_FILTER: "Widgets/Glossary/SET_TAG_FILTER",
    WIDGETS_GLOSSARY_SET_DIRTY: "Widgets/Glossary/SET_DIRTY",
    WIDGETS_GLOSSARY_SET_MODULE_FILTER: "Widgets/Glossary/SET_MODULE_FILTER",
};

const onAddTerm = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_ADD_TERM,
    payload
});

const onCreateTerm = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_CREATE_TERM,
    payload
});

const onCancelAddTerm = () => ({
    type: TYPES.WIDGETS_GLOSSARY_CANCEL_ADD_TERM,
});

const onChangeFilter = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_CHANGE_FILTER,
    payload
});

const onChangeName = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_CHANGE_NAME,
    payload
});

const onRemoveTerm = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_REMOVE_TERM,
    payload
});

const onUpdateTerm = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_UPDATE_TERM,
    payload
});

const onCloseDetail = () => ({
    type: TYPES.WIDGETS_GLOSSARY_CLOSE_DETAIL,
});

const onSetSearchResult = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_SET_SEARCH_RESULT,
    payload
});

const onSearchTerm = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_SEARCH_TERM,
    payload
});

const onSetGlossaryPreferencesByOutline = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_SET_PREFERENCE_BY_OUTLINE,
    payload
});

const onChangeUnusedFilter = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_CHANGE_UNUSED_FILTER,
    payload
});

const onSelectTag = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_SELECT_TAG,
    payload
});

const onSetTagFilter = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_SET_TAG_FILTER,
    payload
});

const onSetDirty = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_SET_DIRTY,
    payload
});

const onSetModuleFilter = (payload) => ({
    type: TYPES.WIDGETS_GLOSSARY_SET_MODULE_FILTER,
    payload
});

export {
    TYPES,
    onAddTerm,
    onChangeFilter,
    onChangeName,
    onRemoveTerm,
    onUpdateTerm,
    onCancelAddTerm,
    onCreateTerm,
    onCloseDetail,
    onSetSearchResult,
    onSearchTerm,
    onSetGlossaryPreferencesByOutline,
    onSetTagFilter,
    onSelectTag,
    onChangeUnusedFilter,
    onSetDirty,
    onSetModuleFilter,
};
