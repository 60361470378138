import React from "react";
import PropTypes from "prop-types";
import { InfiniteTable } from "bmd-react";
import { Header, Dimmer, Loader } from "semantic-ui-react";

export default class SchoolList extends React.PureComponent {
    static propTypes = {
        schools: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                uuid: PropTypes.string,
                tenant: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                }),
            })
        ),
        isLoading: PropTypes.bool,
        onSchoolLoad: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onSchoolLoad();
    }

    _columns = [
        {
            label: "School",
            dataKey: "name",
            width: 300,
            visible: true,
        },
        {
            label: "UUID",
            dataKey: "uuid",
            width: 50,
            visible: true,
        },
        {
            label: "Tenant",
            dataKey: "tenant.name",
            width: 300,
            visible: true,
        },
    ];

    render() {
        const { schools, isLoading } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Schools
                </Header>

                <Dimmer active={isLoading} inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>

                <div style={{ minHeight: "350px" }}>
                    <InfiniteTable data={schools} columns={this._columns} tableName="schools" defaultSortBy="name" />
                </div>
            </React.Fragment>
        );
    }
}
