import React from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import { Form } from "semantic-ui-react";

const renderTableBody = memoizeOne((rows, cols) => {
    const html = [];
    for (let row = 0; row < rows; row++) {
        const cells = [];
        for (let col = 0; col < cols; col++) {
            cells.push(<td key={col} data-row={row} data-col={col} />);
        }
        html.push(<tr key={row}>{cells}</tr>);
    }
    return <tbody>{html}</tbody>;
});

const hiliteTableCell = (tbody, row, col) => {
    tbody.parentNode.style.setProperty("--table-rows", row + 1);
    tbody.parentNode.style.setProperty("--table-cols", col + 1);

    [...tbody.rows].forEach(({ cells }, rowIdx) => {
        [...cells].forEach((cell, colIdx) => {
            const method = rowIdx <= row && colIdx <= col ? "add" : "remove";
            cell.classList[method]("selected");
        });
    });
};

const TableStyle = { width: "100%", cursor: "pointer" };
const NoMouseOver = { row: -1, col: -1 };

export default class TableLayoutGrid extends React.PureComponent {
    static propTypes = {
        rows: PropTypes.number.isRequired,
        cols: PropTypes.number.isRequired,
        onCreate: PropTypes.func.isRequired,
    }

    state = {
        currentPos: null,
    }

    handleClick = (e) => {
        e.stopPropagation();
        const { currentPos } = this.state;
        if (currentPos) {
            this.props.onCreate(Number(currentPos.row) + 1, Number(currentPos.col) + 1);
        }
    }

    handleMouseLeave = (e) => {
        // MouseLeave may be fired w/ different target (depending on border-collapsed)
        const target = e.target;
        let tBody = null;
        switch (target.tagName) {
            case "TD": // no space between cells (sporkTable has collapse border)
                tBody = target.parentNode.parentNode;
                break;
            case "TBODY":
                tBody = target;
                break;
            case "TABLE": // table with space between cells (non-sporkTable)
                tBody = target.tBodies[0]; // let's use the first one
                break;
        }
        // Remove selection
        this.setState({ currentPos: null });
        tBody && hiliteTableCell(tBody, NoMouseOver.row, NoMouseOver.col);
    }

    handleMouseOver = (e) => {
        // MouseOver space between cells?
        const target = e.target;
        if (target.tagName !== "TD") {
            return;
        }
        // MouseOver within already focused cell?
        const { row, col } = target.dataset;
        const { currentPos } = this.state;
        if (currentPos && row === currentPos.row && col === currentPos.col) {
            return;
        }
        // Update selection
        const tBody = target.parentNode.parentNode;
        this.setState({ currentPos: { row, col } });
        hiliteTableCell(tBody, Number(row), Number(col));
    };

    handleMouseCoords = (e) => {
        const tableEl = e.currentTarget;

        if (this.state.currentPos) {
            const rect = tableEl.getBoundingClientRect();
            tableEl.style.setProperty("--mouseX", e.clientX - rect.x + "px");
            tableEl.style.setProperty("--mouseY", e.clientY - rect.y + "px");
        }
        else {
            // Fix when mouse has left tBody but it is still inside table - move tooltip with size out of the screen.
            tableEl.style.setProperty("--mouseX", "-5000px");
        }
    };

    render() {
        const { rows, cols } = this.props;
        const { currentPos } = this.state;
        const labelText = "Insert Table " + (currentPos ? `${Number(currentPos.row) + 1}x${Number(currentPos.col) + 1}` : "");

        return (
            <Form size="mini">
                <Form.Field>
                    <label>{labelText}</label>
                    <table
                        className="tableLayoutGrid"
                        style={TableStyle}
                        onMouseOver={this.handleMouseOver}
                        onMouseMove={this.handleMouseCoords}
                        onMouseLeave={this.handleMouseLeave}
                        onClick={this.handleClick}
                    >
                        {renderTableBody(rows, cols)}
                    </table>
                </Form.Field>
            </Form>
        );
    }
}
