/**
 * "Random" order of multi-choice answers is realized via specific CSS variables for each related problem.
 * It allow us to have only one generated HTML source for each Problem Set and customize order
 * by adding a new <style> tag in preview or during publishing.
 *
 * It is expected that each problem in generated Problem Set's HTML has element with ID [problemId]
 * and block with answers has class="shuffled".
 */
const alphabet = "abcdefghijklmnopqrstuvwxyz";

export const createShuffledCss = (shuffledProblems) => {

    const css = Object.entries(shuffledProblems).map(([ problemId, order ]) => {
        const indexes = order.split(",");
        const cssVars = indexes.reduce((acc, shuffledIndex, index) => {
            const indexChar = alphabet[Number(shuffledIndex)];
            acc.push(`--pos-${indexChar}: ${index + 1};`);  // CSS order is not zero based as an array.
            acc.push(`--letter-${indexChar}: "${alphabet[index]}";`);
            return acc;
        }, []);

        return `#${problemId} .shuffled {${cssVars.join("")}}`;
    });

    return `<style>${css.join("\n")}</style>`;
};
