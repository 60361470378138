import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";

export default class ExtLinksConfirmDialog extends PureComponent {
    static propTypes = {
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onAdd: PropTypes.func,
        onClone: PropTypes.func,
        title: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { isOpen, onClose, onClone, onAdd, title, children } = this.props;
        return (
            <Modal size="tiny" open={isOpen} onClose={onClose}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    {children}
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={onClone}>Clone</Button>
                    <Button onClick={onAdd}>Add</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
