import React from "react";
import { Menu } from "semantic-ui-react";
import { Container } from "semantic-ui-react";
import UserProfileContainer from "./Users/UserProfile";
import YearContainer from "./Year/YearsContainer";

import "./Header.scss";

/**
 * Renders top menu
 */

export default class Header extends React.Component {
  state = {}

  render() {
      return (
          <Menu borderless secondary fixed="top" className="headerMenu">
              <Container>
                  <YearContainer/>
                  <UserProfileContainer />
              </Container>
          </Menu>
      );
  }
}
