import { createSelector } from "reselect";
import { getProblemSets } from "../../../QuestionUtilizator/ProblemSetManager/problemSetManagerSelectors";

export const selectedProblemSetId = state => state.lup.testCreator.selectedProblemSetId;
export const problemSetsHtml = state => state.lup.testCreator.problemSetsHtml;
export const isGeneratingHtml = state => state.lup.testCreator.isGeneratingHtml;
export const isCreatingTests = state => state.lup.testCreator.isCreatingTests;

export const getSelectedProblemSets = createSelector(
    getProblemSets,
    selectedProblemSetId,
    (problemSets, problemSetId) => {
        if (problemSets && problemSetId && problemSets[problemSetId]) {
            return problemSets[problemSetId];
        }
    }
);
