import { all, takeLatest } from "redux-saga/effects";
import firebase from "../../../../firebase";
import { TYPES } from "./reorderProblemSetsActions";
import { sagaToastError } from "../../../../component/toast";

export function* moveSet({ payload: { outlineId, lessonId, setId, source, destination, entity, problemSets } }) {
    try{
        const entityPath = entity.toLowerCase() == "problem set" ? "problem_sets" : "homework";
        const db = firebase.defaultApp.database().ref();
        let updates = {};
        destination = parseInt(destination);
        const direction = destination > source ? "down" : "up";

        //put moved item in new location
        updates[`outlines_data/${outlineId}/lessons/${lessonId}/${entityPath}/${setId}/ordering`] = destination;

        let found = false;

        //go through and adjust ordering of each other set
        problemSets.forEach((set) => {
            const { id, ordering } = set;

            if (id == setId){
                found = true;
                return;
            }

            let itemPath = `outlines_data/${outlineId}/lessons/${lessonId}/${entityPath}/${id}/ordering`;

            //if the item was moved up all elements after it should increase by 1 starting at destination
            if (direction == "up"){
                if (ordering >= destination && !found)
                    updates[itemPath] = ordering + 1;
                else
                    updates[itemPath] = ordering;
            }
            //if the item was moved down all elements in behind it should decrease by 1 until the destination
            if (direction == "down"){
                if (ordering <= destination && found)
                    updates[itemPath] = ordering - 1;
                else
                    updates[itemPath] = ordering;
            }
        });

        yield db.update(updates);

    } catch (error){
        sagaToastError("Failed to move items.", error);
    }
}


export default function* ReorderProblemSetsSaga() {
    yield all([
        takeLatest(TYPES.MOVE_SET, moveSet),
    ]);
}
