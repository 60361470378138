import { all, takeEvery, put } from "redux-saga/effects";
import firebase from "../../firebase";
import { TYPES, onLessonConversionFinished, onUpdateLessonConvertProgress } from "./ConvertLessonDataActions";
import { onShuffle } from "../../KitBuilder/LessonContent/LessonProblemSet/LessonProblemSetActions";

function* convertProblemSetCollection(outlineId, collection, lessonId, lesson, progress) {
    if (lesson[collection]) {
        for (const problemSetId of Object.keys(lesson[collection])) {
            yield put(onShuffle({ outlineId, lessonId, collection, problemSetId }));
            if ("homework" === collection) {
                progress.processedHw++;
            } else {
                progress.processedPS++;
            }
            yield put(onUpdateLessonConvertProgress(progress));
        }
    }
    return progress;
}

function* convertLessonData() {
    const kitsRef = firebase.getFirebaseData("kits_data");
    const kitsData = (yield kitsRef.once("value")).val();
    let progress = { processedHw: 0, processedPS: 0, processedLessons: 0 };
    for (const kitId of Object.keys(kitsData)) {
        const outlineId = kitsData[kitId]["outline_id"];
        const lessonsRef = firebase.getFirebaseData(`outlines_data/${outlineId}/lessons`);
        const lessonsData = (yield lessonsRef.once("value")).val();
        for (const lessonId of Object.keys(lessonsData)) {
            const lesson = lessonsData[lessonId];
            progress = yield convertProblemSetCollection(outlineId, "homework", lessonId, lesson, progress);
            progress = yield convertProblemSetCollection(outlineId, "problem_sets", lessonId, lesson, progress);
            progress.processedLessons++;
            yield put(onUpdateLessonConvertProgress(progress));
        }
    }
    yield put(onLessonConversionFinished());
}

export default function* saga() {
    yield all([takeEvery(TYPES.CONVERT_LESSON_DATA, convertLessonData)]);
}
