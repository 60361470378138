import React from "react";
import { Route } from "react-router-dom";
import AKits from "./AKits";
import WidgetTemplates from "./WidgetTemplates";
import WidgetLibrary from "./WidgetLibrary";
import Glossary from "./Glossary";
import LessonContent from "./LessonContent/LessonMainContainer";
import SnippetManager from "./Snippets/SnippetsManager";
import { withAuth } from "../component/withAuth";
import PropTypes from "prop-types";
import AccessControl from "../auth";
import Modules from "./Modules";

const Routes = ({ isSuperAuthorAtLeast, isMoreThanUser }) => (
    <React.Fragment>
        <Route path="/authorkits" exact component={AKits} />
        <Route path="/authorkits/:aKitId" component={AKits} />
        <Route path="/lesson/content/:outlineId/:lessonId" component={LessonContent} />
        
        {isSuperAuthorAtLeast && <React.Fragment>
            <Route path="/widgets/templates/:selectedTemplateId?" component={WidgetTemplates} />
            <Route path="/snippets" component={SnippetManager} />
        </React.Fragment>}
        
        {isMoreThanUser && <React.Fragment> 
            <Route path="/widgets/library" exact component={WidgetLibrary} />
            <Route path="/widgets/library/:widgetId"  component={WidgetLibrary}  />
            <Route path="/widgets/glossary" exact component={Glossary} />
            <Route path="/widgets/glossary/:glossaryId" component={Glossary} />
        </React.Fragment>}
        <AccessControl action="modules:visit">
            <Route path="/modules" exact component={Modules} />
        </AccessControl>

    </React.Fragment>
);

Routes.propTypes = {
    isSuperAuthorAtLeast: PropTypes.bool,
    isMoreThanUser: PropTypes.bool,
};

export default withAuth(Routes);
