import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List, Popup, Segment, Button } from "semantic-ui-react";
import RemoveIcon from "../../../../component/RemoveIcon";
import "./WorkerList.scss";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";
import AddWorker from "./AddWorker";
import ConfirmDeleteWithContext from "../../../../component/ConfirmDeleteWithContext";
import UserAvatar from "react-user-avatar";
import { ActionIconsContainer } from "bmd-react";

export default class WorkerList extends PureComponent {
    static propTypes = {
        userList: PropTypes.array,
        entityName: PropTypes.string,
        allUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        options: PropTypes.array,
        onAdd: PropTypes.func,
        onRemove: PropTypes.func,
        canEdit: PropTypes.bool,
        parentEntity: PropTypes.string,
    };

    state = {
        open: false,
        removeContext: null,
        removedUser: ""
    };

    handleOpenAddPopup = () => this.setState({ open: true });
    handleCloseAddPopup = () => this.setState({ open: false });

    handleAdd = (userId) => {
        this.props.onAdd(userId);
        this.handleCloseAddPopup();
    };

    handleRemove = (e, userId) => {
        const { removeContext } = this.state;
        if (!removeContext) {
            this.setState({ removedUser: userId, removeContext: e.currentTarget });
        }
    }

    handleRemoveConfirm = () => {
        const { onRemove } = this.props;
        const { removedUser } = this.state;
        onRemove(removedUser);
        this.handleCancelRemove();
    }

    handleCancelRemove = () => {
        this.setState({removeContext: null, removedUser: ""});
    }

    render() {
        const { userList, entityName, allUsers, options, canEdit, parentEntity = "kit" } = this.props;
        const { open, removeContext, removedUser } = this.state;
        return 0 < userList.length ? (
            <React.Fragment>
                <Segment style={{ height: "18em" }}>
                    {removeContext && <ConfirmDeleteWithContext
                        context={removeContext}
                        confirmText={`Remove ${entityName}`}
                        onConfirm={this.handleRemoveConfirm}
                        whatText={`${allUsers[removedUser].first_name} ${allUsers[removedUser].last_name} from ${entityName}s list`}
                        onCancel={this.handleCancelRemove}/>}
                    <List style={{height: "100%", overflowY: "auto"}} >
                        {userList.map(u => {
                            const user = u.id ? u : allUsers[u];
                            if (user) {
                                const name =  user.name || `${user.first_name} ${user.last_name}`;
                                return <List.Item
                                    key={user.id} 
                                    name={user.id}
                                    className="workerListItem"
                                >
                                    <UserAvatar size="32" name={name} style={{float: "left", marginRight: "5px"}} />                                

                                    <List.Content
                                        className="courseVariant workerItem" >
                                
                                        {name}
                                        {canEdit && <ActionIconsContainer floatRight><RemoveIcon
                                            id={user.id}
                                            entityName="access role"
                                            onRemove={this.handleRemove}
                                        /></ActionIconsContainer>}
                                    </List.Content>
                                </List.Item>;
                            }
                        })}
                    </List>
                </Segment>
                {canEdit && <Popup
                    open={open}
                    onOpen={this.handleOpenAddPopup}
                    onClose={this.handleCloseAddPopup}
                    on="click"
                    wide="very"
                    position="top left"
                    style={{maxWidth: "600px"}}
                    trigger={
                        <Button content={`Add ${entityName}`} />
                    }
                >
                    <AddWorker
                        centerForm
                        entityName={entityName}
                        options={options}
                        onAdd={this.handleAdd}
                    />
                </Popup>}

            </React.Fragment>
        ) : (
            <NoDataPlaceholder
                icon="group"
                text={`This ${parentEntity} has no ${entityName}s yet.`}
            >
                {canEdit && <React.Fragment>
                    <p>Hurry up to add the first {entityName}.</p>
                    <AddWorker
                        centerForm
                        entityName={entityName}
                        options={options}
                        onAdd={this.handleAdd}
                    />
                </React.Fragment>}
            </NoDataPlaceholder>
        );
        
    }
}
