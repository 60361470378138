import React from "react";
import PropTypes from "prop-types";
import { subscribe, unsubscribe, hasListener } from "./client";
import services from "./services";

export const feathersjsConnect = service => WrappedComponent => {
    return class extends React.Component {
        static displayName = `FeathersJSConnect(${WrappedComponent.displayName ||
            WrappedComponent.name ||
            "Component"})`;

        // we take default Redux store here from React context
        // this is how react-redux-firebase does it, not sure if it's the right
        // way - it will break if Redux changes that.
        static contextTypes = {
            store: PropTypes.object.isRequired,
        };
        store = this.context["store"];

        componentDidMount() {
            // get default Redux store
            const { dispatch } = this.store;
            // if there is not a listener yet, load data
            if (!hasListener(service)) {
                // dispatch an action to load data from service
                dispatch(services[service].find());
            }
            // listen for updates on given service path
            subscribe(service);
        }

        componentWillUnmount() {
            // stop listening for updates on given service path
            unsubscribe(service);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
};
