import { store } from "../store/configureStore";
import { isPermitted } from "./isPermitted";

export const hasPermission = params => {
    // grab current state
    const state = store.getState();
    // get roles for currently logged in user
    const userRoles = (state.auth && state.auth.user && state.auth.user.roles) || [];

    // simplified call with only an action
    if (typeof params === "string" || params instanceof String) {
        return isPermitted({ userRoles, action: String(params) });
    } else {
        // complex call with all params
        const { action, actionArray, data } = params;
        return isPermitted({ userRoles, action, actionArray, data });
    }
};
