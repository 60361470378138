import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { authUser } from "../auth/authSelectors";

/**
 * HOC which detects if there is a lock for given item.
 * The lock type and itemId must be passed as function arguments.
 *
 * example: withLock(LOCK_TYPE.LESSON, props => `${props.outlineId}@${props.lessonId}`),
 *
 * @param {*} lockType type of the lock
 * @param {*} getIdFn function to get itemId from props
 */
export const withLock = (lockType, getIdFn) => WrappedComponent => {
    return compose(
        firebaseConnect(props => [`/locks/${lockType}/${getIdFn(props)}`]),
        connect((state, props) => {
            const lock = getVal(state.firebase, `data/locks/${lockType}/${getIdFn(props)}`);
            return {
                lock,
                isLocked: !!lock,
                isLockedByMe: lock ? lock.by === authUser(state, props).uid : false,
            };
        })
    )(WrappedComponent);
};

/**
 * HOC which detects if there is a lock for given item.
 * The items is taken from component props.lockType in format {lockType: LOCK_LESSON, itemId: "some_id"}
 *
 * example:
 *
 *  combine(
 *     connect(mapStateFromProps, mapDispatchToActions),
 *     withLockFromProps,
 *  )(Component)
 */
export const withLockFromProps = WrappedComponent => compose(
    firebaseConnect(props =>
        props.lockType && props.lockType.itemId
            ? [`/locks/${props.lockType.lockType}/${props.lockType.itemId}`]
            : []
    ),
    connect((state, props) => {
        if (props.lockType) {
            const { lockType, itemId } = props.lockType;
            const lock = getVal(state.firebase, `data/locks/${lockType}/${itemId}`);
            return {
                lock,
                isLocked: !!lock,
                isLockedByMe: lock ? lock.by === authUser(state, props).uid : false,
            };
        }
        return { lock: null, isLocked: false, isLockedByMe: false };
    })
)(WrappedComponent);
