import { userClaims } from "../Users/UserProfile/UserProfileSelectors";
import { isSuperAuthorAtLeast, isAuthorAtLeast, isReviewer, isReviewerAtLeast, isMoreThanUser } from "../Users/roleUtils";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";
const dispatchToProps = dispatch => bindActionCreators({}, dispatch);

export function withAuth(WrappedComponent) {
    return compose(
        withRouter,
        connect(
            state => ({
                isSuperAuthorAtLeast: isSuperAuthorAtLeast(userClaims(state)),
                isAuthorAtLeast: isAuthorAtLeast(userClaims(state)),
                isReviewer: isReviewer(userClaims(state)),
                isReviewerAtLeast: isReviewerAtLeast(userClaims(state)),
                isMoreThanUser: isMoreThanUser(userClaims(state)),
            }),
            dispatchToProps
        )
    )(WrappedComponent);
}
