const TYPES = {
    SET_AUTH: "Auth/SET",
};

const onSetAuth = payload => ({
    type: TYPES.SET_AUTH,
    payload,
});

export { TYPES, onSetAuth };
