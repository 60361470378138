export const TYPES = {
    AKITS_ON_ERROR: "AKits/onError",
    AKITS_CLEAR_ERROR: "AKits/clearError",
    AKITS_SHOW_DIALOG: "AKits/showDialog",
    AKITS_CREATE: "AKits/create",
    AKITS_REMOVE: "AKits/remove",
    AKITS_SELECT_FOR_EDIT: "AKits/selectForEdit",
    AKITS_REMOVED: "AKits/removed",
};

export const onAKitsError = (value) => {
    return {
        type: TYPES.AKITS_ON_ERROR,
        value,
    };
};

export const onClearAKitsError = () => {
    return {
        type: TYPES.AKITS_CLEAR_ERROR,
    };
};

export const onAKitRemove = (aKitId) => {

    return {
        type: TYPES.AKITS_REMOVE,
        payload: {
            kit_id: aKitId,
        }
    };
};

export const onAKitRemoved = () => ({
    type: TYPES.AKITS_REMOVED
});

export const onSelectAKitForEdit = (aKitId) => {
    return {
        type: TYPES.AKITS_SELECT_FOR_EDIT,
        payload: {
            selectedAKitId: aKitId,
        }
    };
};