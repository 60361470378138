import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button, Icon, Message } from "semantic-ui-react";

export default class ImportGlossary extends PureComponent {
    static propTypes = {
        glossaryToImport: PropTypes.object,
        onImportGlossary: PropTypes.func.isRequired,
        onImportGlossaryStop: PropTypes.func.isRequired,
        inProgress: PropTypes.bool,
        progress: PropTypes.shape({
            processed: PropTypes.number,
            total: PropTypes.number,
        }),
    };

    handleStartProcessing = () => {
        const { onImportGlossary } = this.props;
        onImportGlossary();
    };

    handleStopProcessing = () => {
        const { onImportGlossaryStop } = this.props;
        onImportGlossaryStop();
    };

    render() {
        const { glossaryToImport, inProgress, progress } = this.props;
        let termCount = Object.keys(glossaryToImport || {}).length;

        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">Import Glossary Terms from ProEdBooks</Header>
                <Segment secondary>
                    This page converts glossary terms imported from ProEdBooks server into SPORK compatible format.
                    <p>
                        As a first step, you need to run ImportGlossaryTerms script to retrieve data from ProEdBooks
                        server and store them in SPORK. The script is located in source code GIT in folder{" "}
                        <a href="https://bitbucket.org/bmdconsulting/spork-app/src/develop/scripts/ImportGlossaryTerms/" target="_blank" rel="noopener noreferrer">
                            scripts/ImportGlossaryTerms
                        </a>
                        . Please read{" "}
                        <a href="https://bitbucket.org/bmdconsulting/spork-app/src/develop/scripts/ImportGlossaryTerms/README.md" target="_blank" rel="noopener noreferrer">
                            README.md
                        </a>
                    </p>
                </Segment>
                {inProgress ? (
                    <Message icon positive>
                        <Icon name="circle notched" loading />
                        <Message.Content>
                            Converting item {progress.processed} of {progress.total}
                        </Message.Content>
                    </Message>
                ) : (
                    <Segment>Terms ready to import: {termCount}</Segment>
                )}
                {inProgress ? (
                    <Button negative onClick={this.handleStopProcessing}>
                        Stop conversion
                    </Button>
                ) : (
                    <Button primary onClick={this.handleStartProcessing} disabled={termCount == 0}>
                        Start conversion
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
