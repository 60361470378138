import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";
import Immutable from "immutable";
import { Modal, Checkbox, Icon } from "semantic-ui-react";
import cx from "classnames";
import SaveCancelInToolbar from "../../../../KitBuilder/WidgetLibrary/WidgetEditor/SaveCancelInToolbar";

import SharedToolbar from "../SharedToolbar";
import CellEditor from "./CellEditor";
import TableChangePopup from "./TableConfigPopup";
import { findParentByTagName, getCellCoordinates, getDimmer } from "../utils/domUtils";
import { swallowEvent } from "../utils/utils";
import TableCellsMap from "./TableCellsMap";
import { EMPTY_HANDLER } from "../constants";
import MultiCellEditor from "./MultiCellEditor";
import EditorHelpPopup from "../help/EditorHelpPopup";
import MultiCellEditorHelp from "../help/MultiCellEditorHelp";

import "./tableEditor.scss";

const EMPTY_CELL = { contentState: null, html: "" };
// Specific class name is added (componentDidMount) to the body element to customize modal"s dimmer via css.
// TODO: it could be unified with other Modals / Popups opened from BookEditor
const TABLE_EDITOR_OPEN_CLASS = "table-editor-opened";

const IS_MAC = window && window.navigator && window.navigator.platform.indexOf("Mac") !== -1;

class TableDecoratorEditor extends Component {
    static propTypes = {
        contentState: PropTypes.object.isRequired,
        entityKey: PropTypes.string.isRequired,
        getEditorState: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        registerNestedEditor: PropTypes.func.isRequired,  // eslint-disable-line react/no-unused-prop-types
        reviewMode: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        initialHtml: PropTypes.string.isRequired,
        onDirty: PropTypes.func.isRequired,
        rootEntityKey: PropTypes.string,
        /**
         * `onDirty` is not propagated down to CellEditors yet (see EMPTY_HANDLER).
         * It will take into account when table editor will be converted to seamless too.
         */
    };

    state = {
        sharedToolbar: null,
        isOpen: true,
        activeCell: null,
        tableStyle: null,
        isMultiCellMode: false,
        selectedCells: Immutable.Set(),
        showHelp: false,
    };

    constructor(props) {
        super(props);
        this.tableId = Date.now();
        this.tableData = null;
        this.tableStyle = null;
        this.tableCellsMap = new TableCellsMap();
        this.cellEditors = {};
        this.cellSelectionByMouseMove = null;
        this.helpIconRef = React.createRef();
    }

    toolbarRef = React.createRef();

    componentDidMount = () => {
        document.body.classList.add(TABLE_EDITOR_OPEN_CLASS);

        const toolbarNode = this.toolbarRef.current;
        const sharedToolbar = new SharedToolbar();
        sharedToolbar.mount(toolbarNode);
        this.setState({ sharedToolbar });
        this.initTable();
    };

    componentWillUnmount = () => {
        document.body.classList.remove(TABLE_EDITOR_OPEN_CLASS);
        this.state.sharedToolbar && this.state.sharedToolbar.unmount();
    };

    updateDom = () => {
        const dimmer = getDimmer();
        dimmer.classList.add("tableEditor");
    };

    initTable = () => {
        const { contentState, entityKey } = this.props;
        const entity = contentState.getEntity(entityKey);
        const data = entity.getData();
        const rows = data.tableData;
        const tableStyleData = data.tableStyle ? { tableStyle: data.tableStyle } : {};

        this.tableCellsMap.init(rows.length, rows[0].length);
        this.setState({ tableData: [...rows], ...tableStyleData });
    };

    handleOpenContextMenu = (e) => {
        if (this.props.reviewMode) {
            return;
        }

        const activeCell = findParentByTagName(e.target, "TD");
        e.preventDefault();
        this.setState({ activeCell: null }, () => this.setState({ activeCell }));
    };

    handleCloseContextMenu = () => {
        this.setState({ activeCell: null });
    };

    insertRow = (direction) => {
        let { rowIndex } = getCellCoordinates(this.state.activeCell);

        if (direction === "after") {
            rowIndex++;
        }

        this.tableCellsMap.insertRow(rowIndex);
        const { tableData } = this.state;
        const newData = [...tableData];
        const columns = tableData[0].length;
        const emptyCells = Array(columns)
            .fill(0)
            .map(() => ({ ...EMPTY_CELL }));
        newData.splice(rowIndex, 0, emptyCells);

        this.setState({
            tableData: newData,
            activeCell: null,
        });
    };

    deleteRow = () => {
        const { rowIndex } = getCellCoordinates(this.state.activeCell);
        this.tableCellsMap.deleteRow(rowIndex);
        const { tableData } = this.state;
        const newData = [...tableData];
        newData.splice(rowIndex, 1);
        this.setState({
            tableData: newData,
            activeCell: null,
        });
    };

    insertColumn = (direction) => {
        let { colIndex } = getCellCoordinates(this.state.activeCell);

        if (direction === "after") {
            colIndex++;
        }

        this.tableCellsMap.insertColumn(colIndex);
        const { tableData } = this.state;
        const newData = tableData.map(([...rowData]) => {
            rowData.splice(colIndex, 0, { ...EMPTY_CELL });
            return rowData;
        });
        this.setState({
            tableData: newData,
            activeCell: null,
        });
    };

    deleteColumn = () => {
        const { activeCell, tableData } = this.state;
        const { colIndex } = getCellCoordinates(activeCell);
        this.tableCellsMap.deleteColumn(colIndex);
        const newData = tableData.map(([...rowData]) => {
            rowData.splice(colIndex, 1);
            return rowData;
        });
        this.setState({
            tableData: newData,
            activeCell: null,
        });
    };

    closeTableEditor = () => {
        const { onClose } = this.props;
        this.setState({ isOpen: false });
        onClose();
    };

    handleSave = () => {
        const { tableStyle } = this.state;
        const tableCellsMap = this.tableCellsMap;
        const { rows, cols } = tableCellsMap.getSize();
        const { getEditorState, setEditorState, entityKey, onDirty, initialHtml } = this.props;
        const parentEditorState = getEditorState();
        const parentContentState = parentEditorState.getCurrentContent();
        const tableData = [];
        const tableHTML = [];

        for (let row = 0; row < rows; row++) {
            tableHTML.push("<tr>");
            tableData[row] = [];

            for (let col = 0; col < cols; col++) {
                const cellId = tableCellsMap.getCellId(row, col);
                const { raw, html } = this.cellEditors[cellId].getContent();
                tableData[row].push({ contentState: raw, html });
                tableHTML.push("<td>", html, "</td>");
            }
            tableHTML.push("</tr>");
        }

        const html = `<table class="sporkTable ${tableStyle}"><tbody>${tableHTML.join("")}</tbody></table>`;
        const newParentContentState = parentContentState.replaceEntityData(entityKey, { tableData, html, tableStyle });
        const newParentEditorState = EditorState.push(parentEditorState, newParentContentState, "apply-entity");
        setEditorState(newParentEditorState);

        if (html !== initialHtml) {
            onDirty();
        }
        // this.setState({ tableData, tableStyle });
        this.closeTableEditor();
    };

    handleCancel = () => {
        this.closeTableEditor();
    };

    onCellClick = (globalCellId, cellId, e) => {
        const { isMultiCellMode } = this.state;

        if (isMultiCellMode) {
            this.selectCell(cellId);
            swallowEvent(e);
            return;
        }

        // Focus editor inside TD (not whole cell is filled by editor because of paddings, so it is possible
        // to click inside TD but outside Editor).
        // SemanticUI Modal (inside which FormulaEditor is rendered) does not swallow events (they bubbles),
        // => strict cell checking. Maybe we could add `swallowEvents` to our modal...
        if (e.target.id === globalCellId) {
            e.preventDefault();
            this.focusCellEditor(cellId);
        }
    };

    // Currently it is useless to propagate registering of cell editor to the parent editor, because table entity
    // is edited in Modal (it is not seamless as snippet).
    registerNestedEditor = (cellEditor) => {
        const { cellId, bookEditor } = cellEditor;
        this.cellEditors[cellId] = bookEditor;
        return () => {  // unregister handler
            if (!this.cellEditors[cellId]) {
                return;
            }
            delete this.cellEditors[cellId];
        };
    };

    focusCellEditor = (cellId) => {
        this.cellEditors[cellId].setFocus();
    };

    selectCell = (cellId) => {
        let { selectedCells } = this.state;

        if (selectedCells.has(cellId)) {
            selectedCells = selectedCells.remove(cellId);
        }
        else {
            selectedCells = selectedCells.add(cellId);
        }

        this.setState({ selectedCells });
    }

    getOrderedCellsRange = () => {
        const { start, end } = this.cellSelectionByMouseMove;

        return {
            start: {
                row: Math.min(start.row, end.row),
                col: Math.min(start.col, end.col),
            },
            end: {
                row: Math.max(start.row, end.row),
                col: Math.max(start.col, end.col),
            },
        };
    };

    selectCellsRange = () => {
        let { selectedCells } = this.state;
        const { start, end } = this.getOrderedCellsRange();
        selectedCells = selectedCells.clear();

        for (let row = start.row; row <= end.row; row++) {
            for (let col = start.col; col <= end.col; col++) {
                const cellId = this.tableCellsMap.getCellId(row, col);
                selectedCells = selectedCells.add(cellId);
            }
        }

        this.setState({ selectedCells });
    };

    createOnChangeHandler = (cellId) => {
        return this.onCellChange.bind(null, cellId);
    };

    createOnCellClickHandler = (globalCellId, cellId) => {
        return this.onCellClick.bind(null, globalCellId, cellId);
    };

    handleChangeStyle = (e, { value }) => {
        this.setState({ tableStyle: value });
    };

    handleMultiCellMode = () => {
        const isMultiCellMode = !this.state.isMultiCellMode;
        const tableCellsMap = this.tableCellsMap;
        const { rows, cols } = tableCellsMap.getSize();

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                const cellId = tableCellsMap.getCellId(row, col);

                if (isMultiCellMode) {
                    this.cellEditors[cellId].lock();
                }
                else {
                    this.cellEditors[cellId].unlock();
                }
            }
        }

        if (!isMultiCellMode) {
            this.focusCellEditor(tableCellsMap.getCellId(0, 0));
        }

        this.setState((state) => ({ isMultiCellMode: !state.isMultiCellMode }));
    };

    getCellPos = (cell) => {
        const row = cell.parentNode;

        return {
            col: cell.cellIndex,
            row: row.rowIndex,
        };
    };

    handleMouseDown = (e) => {
        const { isMultiCellMode, selectedCells } = this.state;
        if (!isMultiCellMode) {
            return;
        }

        swallowEvent(e);

        // Ctrl / Meta key allows to extend currently selected cells.
        if ((!IS_MAC && !e.ctrlKey) || (IS_MAC && !e.metaKey)) {
            this.setState({ selectedCells: selectedCells.clear() });
        }

        const startCell = findParentByTagName(e.target, "TD");
        const pos = this.getCellPos(startCell);
        this.cellSelectionByMouseMove = {
            start: pos,
            end: pos,
        };
    };

    handleMouseMove = (e) => {
        if (this.state.isMultiCellMode && this.cellSelectionByMouseMove) {
            swallowEvent(e);
            const cell = findParentByTagName(e.target, "TD");
            const pos = this.getCellPos(cell);
            const { end } = this.cellSelectionByMouseMove;

            if (end.col !== pos.col || end.row !== pos.row) {
                this.cellSelectionByMouseMove.end = pos;
                this.selectCellsRange();
            }
        }
    };

    handleMouseUp = () => {
        this.cellSelectionByMouseMove = null;
    };

    handleMouseLeave = () => {
        this.cellSelectionByMouseMove = null;
    };

    renderCell = (row, col, data) => {
        const { reviewMode, rootEntityKey } = this.props;
        const { isMultiCellMode, selectedCells } = this.state;
        const cellId = this.tableCellsMap.getCellId(row, col);
        const globalCellId = this.tableId + "_" + cellId;
        const onCellClick = this.createOnCellClickHandler(globalCellId, cellId);
        let focusOnMount = row === 0 && col === 0;

        return (
            <td id={globalCellId} key={cellId}
                onClick={onCellClick}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                className={cx({ selected: isMultiCellMode && selectedCells.has(cellId) })}
            >
                <CellEditor
                    initialContentState={data.contentState}
                    initialHtml={data.html}
                    onDirty={EMPTY_HANDLER}
                    sharedToolbar={this.state.sharedToolbar}
                    cellId={cellId}
                    registerNestedEditor={this.registerNestedEditor}
                    reviewMode={reviewMode}
                    focusOnMount={focusOnMount}
                    rootEntityKey={rootEntityKey}
                />
            </td>
        );
    };

    renderTable = (tableData, tableStyle) => {
        const { isMultiCellMode } = this.state;
        return (
            <table className={cx("sporkTable", tableStyle, { multiCellMode: isMultiCellMode })}
                onContextMenu={this.handleOpenContextMenu}
                onMouseLeave={this.handleMouseLeave}
            >
                <tbody>
                    {tableData &&
                        tableData.map((row, rowIdx) => {
                            return (
                                <tr key={this.tableCellsMap.getRowId(rowIdx)}>
                                    {row.map((cellData, colIdx) => this.renderCell(rowIdx, colIdx, cellData))}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        );
    }

    handleHelp = () => {
        this.setState((state) => ({ showHelp: !state.showHelp }));
    };

    render() {
        const { reviewMode } = this.props;
        const { tableData, activeCell, tableStyle, isOpen, sharedToolbar, isMultiCellMode, selectedCells, showHelp } = this.state;
        const className = "tableEditor";

        return (
            <div
                className={className}
                contentEditable={false}
            >
                <Modal
                    open={isOpen}
                    className="tableEditor" onClick={swallowEvent} onMouseDown={swallowEvent}
                    ref={this.updateDom}
                >
                    <Modal.Header>
                        Table Editor
                    </Modal.Header>
                    <Modal.Content className="tableEditorContent">
                        <div className="toolbarArea">
                            <div className="tableEditorToolbar" ref={this.toolbarRef} />
                            {sharedToolbar ? (
                                <SaveCancelInToolbar onSave={this.handleSave} onCancel={this.handleCancel} />
                            ) : <div />}
                        </div>

                        <div className="scrollableArea">
                            {isOpen && this.renderTable(tableData, tableStyle)}
                        </div>
                        {isMultiCellMode && sharedToolbar && <MultiCellEditor
                            sharedToolbar={sharedToolbar}
                            selectedCells={selectedCells}
                            cellEditors={this.cellEditors}
                        />}

                        <TableChangePopup
                            tableStyle={tableStyle}
                            activeCell={activeCell}
                            insertRow={this.insertRow}
                            deleteRow={this.deleteRow}
                            insertColumn={this.insertColumn}
                            deleteColumn={this.deleteColumn}
                            onClose={this.handleCloseContextMenu}
                            onChangeStyle={this.handleChangeStyle}
                            isMultiCellMode={isMultiCellMode}
                            onMultiCellMode={this.handleMultiCellMode}
                        />
                    </Modal.Content>
                    {!reviewMode && <div className="statusBar">
                        <Checkbox toggle checked={isMultiCellMode} onChange={this.handleMultiCellMode} label="Multi Cell Mode" />
                        <span ref={this.helpIconRef} style={{ marginLeft: "4px", color: "darkgray", cursor: "pointer" }}>
                            <Icon name="question circle" onClick={this.handleHelp} />
                        </span>
                        {showHelp && <EditorHelpPopup
                            contextNode={this.helpIconRef.current}
                            onClose={this.handleHelp}
                            contextHelp={MultiCellEditorHelp}
                            offsetX={10}
                        />}
                    </div>}
                </Modal>
            </div>
        );
    }
}

export default TableDecoratorEditor;
