import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { getOrderedItemsList, getUsedByCount, isDirty } from "./WidgetTemplatesSelectors";
//import { getSortedSubjectNames } from "../../store/selectors/SubjectSelectors";
import {
    onCreateItem,
    onUpdateSetup,
    onSelectTemplate,
    onUpdateName,
    onCloseNameEditor,
    onCloneTemplate,
    onSetDirty,
} from "./WidgetTemplatesActions";
import { onSetTagFilter } from "../Snippets/SnippetsManagerActions";

import WidgetTemplateDetail from "./WidgetTemplateDetail";
import { firebaseConnect } from "react-redux-firebase";

const mapStateToProps = state => ({
    orderedItemList: getOrderedItemsList(state),
    usedByCount: getUsedByCount(state),
    isDirty: isDirty(state),
});

const dispatchToProps = dispatch =>
    bindActionCreators(
        { onCreateItem, onUpdateSetup, onSelectTemplate, onUpdateName, onCloseNameEditor, onCloneTemplate, onSetTagFilter, onSetDirty },
        dispatch
    );

export default compose(
    firebaseConnect((props) => ([`widgetTemplates/data/${props.selectedTemplate.key}`])),
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(WidgetTemplateDetail);
