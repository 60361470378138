import React from "react";
import PropTypes from "prop-types";
import { SaveCancelButtons } from "bmd-react";

const PaddingOnlyStyle = {
    paddingTop: "8px",
};

export default class SaveCancelInToolbar extends React.PureComponent {
    static propTypes = {
        onSave: PropTypes.func,
        onCancel: PropTypes.func,
        saveDisabled: PropTypes.bool,
        /**
         * Reference to instance of SharedToolbar.
         * 
         * Use toolbarRef only when not placed in shared toolbar tree
         * because otherwise we don't need to update position. It will
         * be positioned automatically (as the CSS position is static).
         */
        toolbarRef: PropTypes.object,
    };

    state = {
        divStyle: null,
    }

    updatePosition = () => {
        const { toolbarRef } = this.props;
        if (toolbarRef) {
            const position = toolbarRef.getButtonsOffset();
            // Internet Explorer 6-11
            const isIE = /*@cc_on!@*/false || !!document.documentMode;
            // Edge 20+
            const isEdge = !isIE && !!window.StyleMedia;
            const divStyle = {
                top: `${position.top}px`,
                left: `${isEdge ? position.right : position.left}px`,
                position: "fixed",
                display: "grid",
                alignItems: "center",
                paddingTop: "8px",
            };
            this.setState({ divStyle });
        } else if (this.state.divStyle) {
            this.setState({ divStyle: null });
        }
    }

    componentDidMount() {
        this.updatePosition();
        window.addEventListener("resize", this.onResize, false);
        window.addEventListener("scroll", this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize, false);
        window.removeEventListener("scroll", this.onScroll, false);
    }

    onResize = this.updatePosition;
    onScroll = this.updatePosition;

    render() {
        const { saveDisabled, onCancel, onSave } = this.props;
        return (
            <div style={this.state.divStyle || PaddingOnlyStyle} >
                <SaveCancelButtons
                    size="tiny"
                    onSave={onSave}
                    onCancel={onCancel}
                    saveDisabled={saveDisabled}
                />
            </div>
        );
    }
}
