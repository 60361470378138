import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";

import { PaddingOptions } from "./options";

export default class BoxPadding extends React.PureComponent {
    static propTypes = {
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired
    };

    buildPaddingItems = (value, onClick) => {
        return PaddingOptions.map(item => 
            <List.Item key={item.key} {...item} active={value && value[item.value]} onClick={onClick}>{item.text}</List.Item>
        );
    };

    handleChange = (e, { value }) => {
        const { onChange } = this.props;
        onChange(value, true);
    }

    render() {
        const { style } = this.props;
        return (
            <List selection style={{width: "11em"}}>
                <List.Item active={style && !style.padded && !style.fitted} value="nopadding" onClick={this.handleChange}>Default</List.Item>
                {this.buildPaddingItems(style, this.handleChange)}
            </List>
        );
    }
}
