import React, { PureComponent } from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";
import LessonTopicDraggable from "./LessonTopicDraggable";
import EditLessonTopics from "../EditLessonTopicsContainer";
import memoize from "memoize-one";
import cx from "classnames";

export default class LessonTopicsList extends PureComponent {
    static propTypes = {
        lessonId: PropTypes.string.isRequired,
        topicsList: PropTypes.array,
        droppablePlaceholder: PropTypes.node,
        canEdit: PropTypes.bool,
        outlineId: PropTypes.string,
    };

    convertTopics = memoize(topics => {
        let topicsObject = {};
        topics.map(topic => (topicsObject[topic.key] = true));
        return topicsObject;
    });

    render() {
        const { lessonId, topicsList, droppablePlaceholder, canEdit, outlineId } = this.props;
        let usedTopics = this.convertTopics(topicsList);
        const showTopicList = topicsList && topicsList.length > 0;
        return (
            <React.Fragment>
                {showTopicList && <b>Lesson topics</b>}
                {canEdit && <EditLessonTopics
                    topicsList={topicsList}
                    lessonId={lessonId}
                    usedTopics={usedTopics}
                />}
                {showTopicList ? <List relaxed={!canEdit} bulleted={!canEdit} className={cx("outlineElement lessonTopicsList", {"readOnly" : !canEdit})}>
                    {topicsList.map((topic, index) => (
                        <LessonTopicDraggable key={topic.key} index={index} topicData={topic} lessonId={lessonId} outlineId={outlineId} canEdit={canEdit}/>
                    ))}
                </List>
                    : <p style={{fontStyle: "italic", color: "grey"}}>Lesson has no topics.</p>
                }
                {canEdit && droppablePlaceholder}
            </React.Fragment>
        );
    }
}
