import { createSelector } from "reselect";
import { compareByUserNamesCI } from "../SchoolCourses/SchoolCoursesSelectors";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { authUser } from "../../auth/authSelectors";

export const isLoadingUser = state => state.external.users.isLoadingUser;
export const isSearching = state => state.external.users.isSearching;
export const foundPeople = state => state.external.users.foundPeople;
export const userDetails = state => state.external.users.userDetails;
export const selectedUserId = (state, props) =>  props.match && props.match.params  && props.match.params.userId && parseInt(props.match.params.userId) || null;
export const getUserScope = state => state.external.users.userScope;
export const getSchoolId = state => state.external.users.schoolId;
export const getName = state => state.external.users.name;

export const isMatchingLoggedUser = createSelector(
    authUser,
    selectedUserId,
    (authUser, selectedUserId) => {
        return (selectedUserId && authUser && authUser.sporkUid === selectedUserId);
    }
);

export const getFoundPeople = createSelector(
    foundPeople,
    (people) => (people.sort(compareByUserNamesCI))
);

export const getUserDetails = createSelector(
    userDetails,
    getSelectedYearId,
    (userDetails, selectedYearId) => {
        const filteredDetails = {...userDetails};
        if (userDetails && selectedYearId) {
            const sections = userDetails.sections.filter(s => s && s.schoolCourses && 0 < s.schoolCourses.length && s.schoolCourses[0].year_id == selectedYearId);
            filteredDetails.sections = sections;
        }
        return filteredDetails;
    }

);