import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Radio } from "semantic-ui-react";

class TrueFalseChoice extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        answer: PropTypes.bool,
    };

    state = {
        answer: this.props.answer,
    };

    componentDidMount(){
        this.props.onChange(this.state.answer);
    }

    handleTrueFalseToggle = (e, { value }) => {
        const { onChange } = this.props;
        const newVal = (value == "true");
        this.setState({ answer: newVal }, onChange(newVal));
    };

    render() {
        const { answer } = this.state;

        return (
            <Form>
                <Form.Field>
                    <Radio
                        label="true"
                        name="answerGroup"
                        value="true"
                        checked={answer}
                        onChange={this.handleTrueFalseToggle}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label="false"
                        name="answerGroup"
                        value="false"
                        checked={!answer}
                        onChange={this.handleTrueFalseToggle}
                    />
                </Form.Field>
            </Form>
        );
    }
}


TrueFalseChoice.defaultProps = {
    answer: false
};

export default TrueFalseChoice;
