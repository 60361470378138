import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";

import ReorderProblemSets from "./ReorderProblemSets";
import { onMoveSet } from "./reorderProblemSetsActions";


const mapStateToProps = () => ({});

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            onMoveSet,
        },
        dispatch
    );

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(ReorderProblemSets);