import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import LessonTopic from "./LessonTopic";
import { getOutlineLessonsRaw, getReviewTopics } from "../../outlinesSelectors";

const mapStateToProps = function(state, props) {
    return {
        outlineLessonsRaw: getOutlineLessonsRaw(state),
        reviewTopics: getReviewTopics(state, props),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators({ }, dispatch);
};

export default compose(
    connect(mapStateToProps, dispatchToProps)
)(LessonTopic);