import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { SaveCancelButtons } from "bmd-react";
import { objectiveTypes } from "../objectiveTypes";
import { Form, Segment, Message } from "semantic-ui-react";

export default class AddObjective extends PureComponent {
    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        objectives: PropTypes.objectOf(
            PropTypes.shape({
                name: PropTypes.string,
                type: PropTypes.string
            })
        )
    };

    state = { currentObjectiveType: "General" };

    // check for duplicities in already existing objects
    validateObjective = () => {
        const { currentObjectiveText, currentObjectiveType } = this.state;
        const { objectives } = this.props;
        let found = false;

        if (objectives) {
            Object.keys(objectives).map(key => {
                let objective = objectives[key];
                if (
                    currentObjectiveText &&
                    objective.name.toLowerCase().trim() === currentObjectiveText.toLowerCase().trim() &&
                    objective.type === currentObjectiveType
                ) {
                    found = true;
                }
            });
        }
        this.setState({ objectiveInvalid: found });
    };

    handleChangeObjectiveText = (e, { value }) =>
        this.setState({ currentObjectiveText: value }, this.validateObjective);
    handleChangeObjectiveType = (e, { value }) =>
        this.setState({ currentObjectiveType: value }, this.validateObjective);

    handleAdditionObjective = () => {
        const { currentObjectiveText, currentObjectiveType } = this.state;
        const { onSave } = this.props;

        onSave({
            name: currentObjectiveText,
            type: currentObjectiveType
        });
    };

    render() {
        const { currentObjectiveType, currentObjectiveText, objectiveInvalid } = this.state;
        const { onCancel } = this.props;

        return (
            <Segment>
                <Form.Input
                    label="Objective Text"
                    placeholder="Add Objective"
                    fluid
                    onChange={this.handleChangeObjectiveText}
                    value={currentObjectiveText || ""}
                />
                <Form.Dropdown
                    label="Objective Type"
                    options={objectiveTypes}
                    selection
                    fluid
                    placeholder="Choose Objective Type"
                    onChange={this.handleChangeObjectiveType}
                    value={currentObjectiveType}
                />
                <SaveCancelButtons
                    style={{ marginTop: "1.8em" }}
                    onSave={this.handleAdditionObjective}
                    onCancel={onCancel}
                    saveDisabled={!currentObjectiveText || !currentObjectiveType || objectiveInvalid}
                    submitText="Add"
                    size="tiny"
                />
                {objectiveInvalid && <Message error content="Objective already exists" visible />}
            </Segment>
        );
    }
}
