import React from "react";
import PropTypes from "prop-types";

import FormulaSource from "./formulaEntity/FormulaSource";
import GlossaryPopup from "./glossaryEntity/GlossaryPopupContainer";
import LessonLinkPopup from "./lessonLinkEntity/LessonLinkPopupContainer";
import TableCreatePopup from "./tableEntity/TableCreatePopup";
import SnippetPopup from "./snippetEntity/SnippetPopupContainer";
import ReviewPopup from "./reviewEntity/ReviewPopup";
import ListItemPopup from "./listItem/ListItemPopup";
import { ENTITY_TYPE } from "./constants";
import EditorHelpPopup from "./help/EditorHelpPopup";
import { isSingleBlockSelected } from "./utils/utils";
import { isListItem } from "./listItem/listItemUtils";

export default class BookEditorCustomComponents extends React.Component {
    static propTypes = {
        componentType: PropTypes.oneOf(Object.values(ENTITY_TYPE)).isRequired,
        contextNode: PropTypes.instanceOf(Element).isRequired,
        editorState: PropTypes.object.isRequired,
        setEditorState: PropTypes.func.isRequired,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        isParentReadOnly: PropTypes.func.isRequired,
        entityKey: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        onDirty: PropTypes.func,
        reviewMode: PropTypes.bool.isRequired,
        rootEntityKey: PropTypes.string,
        sharedToolbar: PropTypes.object.isRequired,
        contextHelp: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    };

    render () {
        const {
            componentType, contextNode, editorState, setEditorState, enableParentEditor, disableParentEditor,
            entityKey, sharedToolbar, onClose, onDirty, reviewMode, contextHelp, rootEntityKey,
        } = this.props;
        const componentProps = {
            contextNode,
            editorState,
            enableParentEditor,
            disableParentEditor,
            entityKey,
            setEditorState,
            sharedToolbar,
            onClose,
            onDirty,
            reviewMode,
            contextHelp,
            rootEntityKey,
        };

        switch (componentType) {

            case ENTITY_TYPE.MATH:
                return (<FormulaSource {...componentProps} />);

            case ENTITY_TYPE.GLOSSARY:
                return (<GlossaryPopup {...componentProps} />);

            case ENTITY_TYPE.TABLE:
                return (<TableCreatePopup {...componentProps} />);

            case ENTITY_TYPE.SNIPPET:
                return (<SnippetPopup {...componentProps} />);

            case ENTITY_TYPE.REVIEW:
                if (!isSingleBlockSelected(editorState)) {
                    return null;
                }
                return (<ReviewPopup {...componentProps} isListItem={isListItem(editorState)} />);

            case ENTITY_TYPE.HELP:
                return (<EditorHelpPopup {...componentProps} />);

            case ENTITY_TYPE.LESSON_LINK:
                return (<LessonLinkPopup {...componentProps} />);

            case ENTITY_TYPE.OL:
                return (<ListItemPopup {...componentProps} />);
        }
    }
}

