import { all, fork } from "redux-saga/effects";

import ConvertLessonDataSaga from "./ConvertLessonData/ConvertLessonDataSaga";
import ImportGlossarySaga from "../Maintenance/ImportGlossary/importGlossarySaga";
import ImportHomeworkSaga from "../Maintenance/ImportHomework/importHomeworkSaga";
import UpdateProblemTagsSaga from "../Maintenance/UpdateProblemTags/UpdateProblemTagsSaga";
import UpdateLessonReviewStatsSaga from "./UpdateLessonReviewStats/UpdateLessonReviewStatsSaga";
import GeneratesDataForiPlannerSaga from "./GeneratesDataForiPlanner/GeneratesDataForiPlannerSaga";
import FixRefs4ProblemsAndProblemSetsSaga from "./FixRefs4ProblemsAndProblemSets/FixRefs4ProblemsAndProblemSetsSaga";
import CreateProblemSetsData from "./CreateProblemSetsData/CreateProblemSetsDataSaga";
import AdminFunctionsSaga from "./AdminFunctions/AdminFunctionsSaga";
import FixiPlannerResourcesSaga from "./FixiPlannerResources/FixiPlannerResourcesSaga";

function* MaintenanceSaga() {
    yield all([
        fork(ConvertLessonDataSaga),
        fork(ImportGlossarySaga),
        fork(ImportHomeworkSaga),
        fork(UpdateProblemTagsSaga),
        fork(UpdateLessonReviewStatsSaga),
        fork(GeneratesDataForiPlannerSaga),
        fork(FixRefs4ProblemsAndProblemSetsSaga),
        fork(CreateProblemSetsData),
        fork(AdminFunctionsSaga),
        fork(FixiPlannerResourcesSaga),
    ]);
}

export default MaintenanceSaga;
