import { takeLatest, all, put } from "redux-saga/effects";
import firebase from "../../../firebase";
import { TYPES, onExportDone } from "./LessonExportsActions";
import { toastError, FUNCTION_ERROR } from "../../../component/toast";

function* printToPdf({ payload: {exportWindow, ...otherProps }}) {
    const printToPdf = firebase.getFirebaseFunctions().httpsCallable("printToPdf");
    try {
        const result = yield printToPdf(otherProps);
        yield put(onExportDone());
        exportWindow.location.href = result.data.url;
        exportWindow.focus();
    } catch (error) {
        const code = (error && error.code) || "unknown";
        exportWindow.close();
        yield put(onExportDone());
        toastError({ code , header: "Unable to export problem set to PDF", message: FUNCTION_ERROR, });
    }
}

export default function* publisherSaga() {
    yield all([
        takeLatest(TYPES.LESSON_EXPORTS_EXPORT_TO_PDF, printToPdf),
    ]);
}
