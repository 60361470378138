import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

export * from "./types";
export * from "./withLock";
import Lock from "./Lock";
export default Lock;

export const LockIcon = () => <Icon className="lock outline" />;

export const LockPropType = PropTypes.shape({
    id: PropTypes.string,
    by: PropTypes.string,
});

export const LockTypeShape = PropTypes.shape({
    lockType: PropTypes.string.isRequired,
    itemId: PropTypes.string,
});
