import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Popup, Form } from "semantic-ui-react";
import * as Utils from "./utils";
import BlockTitleConfig from "./BlockTitleConfig";
import BlockStyleConfig from "./BlockStyleConfig";
import ItemProperties from "./ItemProperties";
import PopupCloseIcon from "../../../component/PopupCloseIcon";

export default class ItemPopup extends PureComponent {
    static propTypes = {
        value: PropTypes.object,
        itemId: PropTypes.string,
        onUpdateItem: PropTypes.func,
    };

    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleTitleChanged = (changes) => {
        this.handlePropertyChanged("header", changes);
    };

    handlePropertyChanged = (name, value) => {
        const { itemId, value:itemData, onUpdateItem } = this.props;
        onUpdateItem && onUpdateItem(itemId, Utils.updateItemConfig(itemData, name, value));
    }

    render() {
        const { open } = this.state;
        const { value } = this.props;

        return (
            <Popup
                open={open}
                onOpen={this.handleOpen}
                on="click"
                position="bottom right"
                basic
                wide="very"
                style={{ width: "40em" }}
                trigger={
                    <Button
                        className="actionIconClass right"
                        basic
                        compact
                        size="tiny"
                        icon="setting"
                        title="Edit the item properties"
                        content="Properties"
                    />
                }
            >
                <Popup.Content>
                    <PopupCloseIcon onClose={this.handleClose} />
                    <Form autoComplete="off">
                        <BlockTitleConfig
                            config={value.header}
                            onChange={this.handleTitleChanged}
                            subject="widget item"
                        />
                    </Form>
                    <BlockStyleConfig
                        config={value}
                        onChange={this.handlePropertyChanged}
                    />
                    <Form autoComplete="off">
                        <ItemProperties
                            {...value}
                            onChange={this.handlePropertyChanged}
                        />
                    </Form>
                </Popup.Content>
            </Popup>
        );
    }
}
