import React from "react";
import { get, some } from "lodash";
import { Loader, Dimmer } from "semantic-ui-react";
import { isLoaded } from "react-redux-firebase";
import { branch, renderComponent } from "recompose";

const Spinner = () => (
    <Dimmer active inverted>
        <Loader active inline="centered" style={{position: "fixed", top: "100px", left: "50%"}} />
    </Dimmer>
);
// HOC that shows a component while condition is true
export const renderWhile = (condition, component) => branch(condition, renderComponent(component));

// HOC that shows loading spinner component while list of propNames are loading
export const spinnerWhileLoading = propNames =>
    renderWhile(props => some(propNames, name => !isLoaded(get(props, name))), Spinner);
