import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card } from "semantic-ui-react";

import AccessControl from "../../../auth";
import {getSubscribedModules } from "../sharedTenantSelectors";
import TenantCardItem from "./TenantCardItem";

const getModuleCount = (modules) => (modules && Object.keys(modules).length || 0);

class TenantModules extends React.PureComponent {
    static propTypes = {
        tenantId: PropTypes.number,
        tenantName: PropTypes.string,
        modules: PropTypes.object,
        subscribedModules: PropTypes.object,
        onEditModules: PropTypes.func,
    };

    handleEditModules = () => {
        const { tenantId, tenantName:name , modules, onEditModules } = this.props;
        onEditModules(tenantId, { name, modules });
    }

    render() {
        const { modules, subscribedModules } = this.props;
        return (
            <Card.Content>
                <Card.Description className="spork tenantItem">
                    <TenantCardItem count={getModuleCount(modules) + getModuleCount(subscribedModules)} name="Packages">
                        <AccessControl action="modules:subscribe">
                            <Button content="Packages" size="small" title="Manage tenant packages." className="outline" icon="puzzle piece" floated="right" compact onClick={this.handleEditModules} />
                        </AccessControl>
                    </TenantCardItem>
                </Card.Description>
            </Card.Content>
        );
    }
}

const mapStateToProps = (state, props) => ({
    subscribedModules: getSubscribedModules(state, props),
});

export default connect(mapStateToProps)(TenantModules);
