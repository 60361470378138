import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";

import AppConfigs from "../../config/firebase.config.json";

const DEFAULT_NAME = "[DEFAULT]"; // DO NOT change unless you REALLY know all consequences.

export const APP_PUBLISHER = DEFAULT_NAME;
export const APP_AUTHORING = "authoring";

export const getFirebaseApp = name => {
    if (name === DEFAULT_NAME) {
        name = undefined;
    }
    try {
        return firebase.app(name);
    } catch (ex) {
        const cfgKey = (name || DEFAULT_NAME) + "";
        const config = AppConfigs[cfgKey];
        if (!config) {
            throw new Error("No such Firebase application configured: " + cfgKey);
        }
        return firebase.initializeApp(AppConfigs[cfgKey], name);
    }
};

export const getFirebaseData = (path, name) => getFirebaseApp(name).database().ref(path);

export const getFirebaseFile = (path, name) => getFirebaseApp(name).storage().ref(path);

export const getStorageURL = () => `https://storage.googleapis.com/${AppConfigs[DEFAULT_NAME].storageBucket}/`;

export const getFileURL = (storagePath) => `https://storage.googleapis.com/${AppConfigs[DEFAULT_NAME].storageBucket}/${encodeURIComponent(storagePath)}`;

export const getFirebaseAuth = name => getFirebaseApp(name).auth();

export const getFirebaseFunctions = name => getFirebaseApp(name).functions();

export const defaultApp = getFirebaseApp();

export const configured = Object.keys(AppConfigs);

const TABLET_SERVER = "tabletProxy";
export const tabletProxy = defaultApp.functions().httpsCallable(TABLET_SERVER);
