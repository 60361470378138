import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";
import { PopupInput, InfiniteTable, ConfirmDeletePopup, AddItemButton } from "bmd-react";
import EditableText from "../../../../component/EditableText";

export default class ConceptList extends PureComponent {

    static propTypes = {
        concepts: PropTypes.array,
        onAddConcept: PropTypes.func.isRequired,
        onRenameConcept: PropTypes.func,
        onRemoveConcept: PropTypes.func,
        onConceptError: PropTypes.func,
        onClearConceptError: PropTypes.func,
        error: PropTypes.object,
    }

    state = {
        editingText: "",
    }

    canEditText = () => !this.state.editingText;

    handleEditText = (id) => {
        const { editingText } = this.state;
        if (editingText)
            this.setState({ editingText: "" });
        else
            this.setState({ editingText: id || true });
    }

    handleRemoveConcept = (callback, event, props) => {

        // TBD: when removing concept, send errors into popup by callback("error message") rather than by onConceptError()
        // - currently not possible, see below

        // Update: as Firebase updates data in its local storage first and then synchronizes them into cloud DB, 
        // the removal of Concept causes re-rendering of the list w/o item being removed; if there is an error 
        // (e.g. permission denied - read-only DB) during sync the list is re-rendered again w/ item being removed back
        // and error is returned then; Unfortunately there is no popup anymore to show error message and error (changing
        // state of unmounted component) is reported into console. Therefore there is a workaround used showing error
        // message within standalone error message popup (used to display errors while adding/updating concepts).
        // That's the reason why errors on removal are not sent to removal confirmation popup.

        if (this.props.error)
            this.props.onClearConceptError();

        const { onRemoveConcept, onConceptError } = this.props;

        onRemoveConcept(
            props.data.id,
            props,
            // onSuccess
            // Caller can pass a message, in the future we could show/log the message. Now we just call the callback function with "" which closes the confirm dialog.
            callback,
            // onFailure
            onConceptError
        );
    }

    handleAddConcept = (item, callbackOnFinish) => {
        if (this.props.error)
            this.props.onClearConceptError();
        if (!item || !item.trim())
            this.props.onConceptError({ message: "Concept name cannot be empty"});
        else
            this.props.onAddConcept(item, this.props.onConceptError);
        callbackOnFinish();
    }

    handleRenameConcept = (newName, event, props) => {
        if (this.props.error)
            this.props.onClearConceptError();
        this.props.onRenameConcept(newName, props.id, this.props.onConceptError);
    }

    handleDismissError = () => {
        if (this.props.error)
            this.props.onClearConceptError();
    }

    _renderEditableConceptName = ({ rowData }) => (
        <EditableText
            id={rowData.id}
            key={rowData.id}
            text={rowData.name}
            onEditation={this.handleEditText}
            canEdit={!this.state.editingText}
            onUpdate={this.handleRenameConcept} />
    );

    _renderActionIcons = ({ rowData }) => (
        <ConfirmDeletePopup key={rowData.key} floated data={rowData} onConfirm={this.handleRemoveConcept} whatText={`concept "${rowData.name}"`} />
    );

    _columns = [
        {
            label: "Name",
            dataKey: "name",
            width: 930,
            visible: true,
            cellRenderer: this._renderEditableConceptName,
        },
        {
            dataKey: "",
            width: 70,
            visible: true,
            flexGrow: 1,
            cellRenderer: this._renderActionIcons,
        }
    ];

    render() {
        const { concepts, error } = this.props;
        return (
            <div style={{ minHeight: "350px" }}>
                {error && error.message && <Message error floating content={error.message} onDismiss={this.handleDismissError} />}
                <PopupInput
                    position="bottom left"
                    placeholder="Enter new Concept name"
                    trigger={<AddItemButton inline buttonText="Add Concept" />}
                    onSubmit={this.handleAddConcept}
                    defaultValue="" />
                <InfiniteTable data={concepts} columns={this._columns} tableName="concepts" />
            </div>
        );
    }
}