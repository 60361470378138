import React from "react";
import PropTypes from "prop-types";
import { Comment, Header, Button } from "semantic-ui-react";
import CommentItem from "./CommentItem";
import AddCommentBox from "./AddCommentBox";
import "./Comments.scss";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";

export default class Comments extends React.PureComponent {
    static propTypes = {
        comments: PropTypes.object,
        /* ID of comments thread based on entity ID (widget, lesson, etc.) */
        threadId: PropTypes.string,
        threadType: PropTypes.oneOf([ "comments", "user_comments" ]),
        notification: PropTypes.object,
        /* item ID which is reply related to */
        replyToAnchor: PropTypes.string,
        onAddComment: PropTypes.func.isRequired,
        onEditComment: PropTypes.func.isRequired,
        onOpenReply: PropTypes.func.isRequired,
        onMarkResolved: PropTypes.func.isRequired,
        onRemove: PropTypes.func,
        originalComments: PropTypes.object,
        unresolvedCommentsCount: PropTypes.number,
        authUser: PropTypes.object,
        hideHeader: PropTypes.bool,
    };

    state = {
        removeContext: null,
        removeId: null,
        removeParentId: null,
    };

    handleAddNewComment = () => {
        this.props.onOpenReply("new");
    };

    handleCancel = () => {
        this.props.onOpenReply(null);
    };

    handleAddReply = ({ target }) => {
        if (target.id !== this.props.replyToAnchor) {
            this.props.onOpenReply(target.id);
        }
    };

    useThreadProps = (params) => {
        const { threadId, threadType, notification } = this.props;
        return { threadId, threadType, notification, ...params};
    }

    handleResolved = ({ target }) => this.props.onMarkResolved(
        this.useThreadProps({ commentId: target.id, status: true })
    );

    handleSave = (comment, replyTo) => this.props.onAddComment(
        this.useThreadProps({ comment, replyTo })
    );

    removeComment = () => {
        const { removeId, removeParentId } = this.state;
        const { onRemove, comments } = this.props;
        onRemove(
            this.useThreadProps({ commentId: removeId, parentCommentId: removeParentId, comment: comments[removeId] })
        );
    };

    clearRemoveState = () => {
        this.setState({ removeId: null, removeParentId: null, removeContext: null });
    };

    handleRemoveCancel = () => {
        this.clearRemoveState();
    };

    handleRemoveConfirm = () => {
        this.removeComment();
        this.clearRemoveState();
    };

    handleRemoveComment = (e) => {
        const currentTarget = e.currentTarget;
        const { id, parentId } = currentTarget.dataset;

        this.setState({
            removeContext: currentTarget,
            removeId: id,
            removeParentId: parentId,
        });
    };

    getRemovingCommentPreview = () => {
        const { comments } = this.props;
        const { removeId } = this.state;

        if (!removeId) {
            return "";
        }

        const { authorObj: {first_name, last_name}, comment } = comments[removeId] || {};
        let shortText = comment.substr(0, 30);
        shortText += shortText.length < comment.length ? "..." : "";
        return `comment "${shortText}" [${first_name} ${last_name}]`;
    };

    handleEditComment = (commentId, comment, parentId) => this.props.onEditComment(
        this.useThreadProps({ commentId, comment, parentId })
    );

    render() {
        const { comments, replyToAnchor, authUser, originalComments, unresolvedCommentsCount, hideHeader } = this.props;
        const { removeContext } = this.state;
        const userId = authUser.uid;
        return (
            <Comment.Group className="fullWidth">
                <ConfirmDeleteWithContext
                    context={removeContext}
                    confirmText="Remove Comment"
                    onConfirm={this.handleRemoveConfirm}
                    whatText={this.getRemovingCommentPreview()}
                    onCancel={this.handleRemoveCancel}
                />

                {!hideHeader && (
                    <Header as="h3" dividing>
                        {"Comments" + (unresolvedCommentsCount ? ` (${unresolvedCommentsCount})` : "")}
                    </Header>
                )}
                {Object.keys(comments || {}).map(key => {
                    const comment = comments[key];
                    if (!comment.isReply) {
                        const commentOwnerId = comment.author;
                        return (
                            <CommentItem
                                key={key}
                                comment={comment}
                                commentId={key}
                                onReplyTo={this.handleAddReply}
                                onMarkResolved={this.handleResolved}
                                deleteValidator={{ action: "comment:delete", data: { userId, commentOwnerId }}}
                                editValidator={{ action: "comment:edit", data: { userId, commentOwnerId }}}
                                onRemove={this.handleRemoveComment}
                                onEdit={this.handleEditComment}
                            >
                                {comment.replies && Object.keys(comment.replies).map((rkey) => {
                                    const commentOwnerId = originalComments[rkey].author;
                                    return (
                                        <CommentItem
                                            key={rkey}
                                            comment={comments[rkey]}
                                            commentId={rkey}
                                            onReplyTo={this.handleAddReply}
                                            parentCommentId={key}
                                            deleteValidator={{ action: "comment:delete", data: { userId, commentOwnerId } }}
                                            editValidator={{ action: "comment:edit", data: { userId, commentOwnerId } }}
                                            onRemove={this.handleRemoveComment}
                                            onEdit={this.handleEditComment}
                                        />
                                    );
                                })}
                                {replyToAnchor === key && (
                                    <AddCommentBox
                                        replyToId={key}
                                        onCancel={this.handleCancel}
                                        onAdd={this.handleSave}
                                    />
                                )}
                            </CommentItem>
                        );
                    }
                })}
                {"new" === replyToAnchor ? (
                    <AddCommentBox onCancel={this.handleCancel} onAdd={this.handleSave} />
                ) : (
                    <Button content="Add comment" labelPosition="left" icon="edit" onClick={this.handleAddNewComment} />
                )}
            </Comment.Group>
        );
    }
}
