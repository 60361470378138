import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Header, Checkbox, Grid, Dimmer, Loader } from "semantic-ui-react";
import { SaveCancelButtons, HelpPopup, ConfirmDeletePopup } from "bmd-react";
import { connect } from "react-redux";
import { onRemoveModule } from "../ModulesActions";
// import EditPackageMenu from "./EditPackageMenu";
import WorkerList from "../../AKits/EditKitProperties/component/WorkerList";
import { onUserSimpleListLoad } from "../../../Users/UserList/UserListActions";
import { getSimpleUsersAsOptions, isLoading, getSimpleUsersAsCollection } from "../../../Users/UserList/UserListSelectors";

class ModuleEditor extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        editedModule: PropTypes.object,
        onSave: PropTypes.func,
        onRemoveModule: PropTypes.func,
        editedModuleId: PropTypes.string,
        onUserSimpleListLoad: PropTypes.func,
        options: PropTypes.array,
        isLoading: PropTypes.bool,
        allUsers: PropTypes.object,
    }

    componentDidMount() {
        const { editedModule/*, onUserSimpleListLoad*/   } = this.props;
        if (editedModule) {
            this.setState({
                name: editedModule.name,
                desc: editedModule.desc || "",
                is_public: editedModule.is_public,
                id: editedModule.id || null,
                tenant_id: editedModule.tenant_id || null,
            });
        }
        // onUserSimpleListLoad();
    }

    componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            this.setState({ options: this.props.options });
        }
    }
    

    state = {
        name: "",
        desc: "",
        is_public: false,
        id: null,
        tenant_id: null,
        error: false,
        activeItem: "general",
        isDirty: false,
        super_authors: [],
        options: [],
    }

    handleAddSuperAuthor = (userId) => {
        const { super_authors, options } = this.state;
        super_authors.push(userId);
        this.setState({ super_authors: [...super_authors], options: options.filter(i => i.value !== userId), isDirty: true });
    }
    handleRemoveSuperAuthor = (userId) => {
        const { options } = this.props;
        const { super_authors } = this.state;
        const usrList = super_authors.filter(u => u !== userId);
        this.setState({ super_authors: usrList, options: options.filter(i => !usrList.some(j => j === i.value)), isDirty: true });
    }
    handleItemClick = (e, { name }) => this.setState({ activeItem: name });
    handleChange = (e, { name, value }) => {
        const { error } = this.state;
        this.setState({ [name]: value, error: "name" === name ? false : error, isDirty: true });
    }
    handleToggle = (e, { name, checked }) => this.setState({ [name]: checked, isDirty: true });

    handleSave = () => {
        const { name, desc, is_public, id, tenant_id, super_authors } = this.state;
        if (0 === name.trim().length) {
            this.setState({ error: true });
        } else {
            this.props.onSave({ name, desc, is_public, id, tenant_id, super_authors });
        }
    }

    handleRemove = () => {
        const { editedModuleId, onRemoveModule, onClose } = this.props;
        onRemoveModule({ moduleId: editedModuleId, id: this.state.id });
        onClose();
    }

    render() {
        const { onClose, editedModule, allUsers, isLoading } = this.props;
        const { name, desc, is_public, error, activeItem, isDirty, super_authors, options} = this.state;
        const action = editedModule ? "Edit" : "Create";
        const removable = editedModule && (!editedModule.subscribers || 0 === editedModule.subscribers.length) && (!editedModule.kits || 0 === editedModule.kits.length) && !editedModule.iPlannerTemps;

        return (
            <Modal
                open
                closeOnDimmerClick={false}
                size="small"
                // style={{width: "1140px"}}
            >
                <Modal.Header>{action} package {editedModule && editedModule.name}{removable && (
                    <ConfirmDeletePopup
                        floated
                        title="Remove unused package"
                        headerText="Remove unused package"
                        whatText={`package ${name}`}
                        onConfirm={this.handleRemove}
                    />
                )}</Modal.Header>
                <Modal.Content style={{minHeight: "360px"}}>
                    <Grid>
                        {/* <Grid.Column width={3}>
                            <EditPackageMenu activeItem={activeItem} onItemClick={this.handleItemClick} />
                        </Grid.Column> */}
                        <Grid.Column width={16}>
                            {"general" === activeItem ? <React.Fragment>
                                <Form style={{clear: "both"}}>
                                    <Form.Input
                                        label="Name"
                                        name="name"
                                        required
                                        value={name}
                                        onChange={this.handleChange}
                                        placeholder="Fill package name"
                                        {...error && { error: true, placeholder: "Please fill package name"}}
                                    />
                                    <Form.TextArea
                                        label="Description"
                                        name="desc"
                                        placeholder="Fill package description"
                                        value={desc}
                                        onChange={this.handleChange}
                                    />
                                </Form>
                                <Header as="h4">Sharing options</Header>
                                <Form>
                                    <Form.Field style={{ marginLeft: "1em" }}>
                                        <Checkbox disabled={editedModule && editedModule.subscribers && 0 < Object.keys(editedModule.subscribers).length} label="Shareable" onChange={this.handleToggle} checked={is_public} name="is_public"/>
                                        <HelpPopup content="If enabled, the package will be shared to subscribe for all SPORK tenants."/>
                                    </Form.Field>
                                </Form>
                            </React.Fragment> : 
                                <Dimmer.Dimmable>
                                    <Dimmer active={isLoading} inverted>
                                        <Loader inverted>Loading...{/*isLoading ? "Loading..." : "Removing..."*/}</Loader>
                                    </Dimmer>
                                    <WorkerList
                                        allUsers={allUsers || {}}
                                        entityName="Package Admin"
                                        parentEntity="package"
                                        userList={super_authors}
                                        options={options}
                                        onAdd={this.handleAddSuperAuthor}
                                        onRemove={this.handleRemoveSuperAuthor}
                                        canEdit={!isLoading}
                                    />
                                </Dimmer.Dimmable>
                            }
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        saveDisabled={!isDirty}
                        submitText={editedModule ? "Save" : "Create"}
                        onSave={this.handleSave}
                        onCancel={onClose}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    options: getSimpleUsersAsOptions(state),
    allUsers: getSimpleUsersAsCollection(state),
    isLoading: isLoading(state),
});

export default connect(
    mapStateToProps,
    {
        onRemoveModule,
        onUserSimpleListLoad,
    }
)(ModuleEditor);
