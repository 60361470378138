import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Button, List } from "semantic-ui-react";
import Topic from "../../../../TopicCollection/Topic";
import AccessControl from "../../../../auth/AccessControl";
import ResourceTopicsEditor from "./ResourceTopicsEditor";
import styles from "../../resources.module.scss";

export default class ResourceTopics extends PureComponent {
    static propTypes = {
        topics: PropTypes.array,
        editValidator: PropTypes.object,
        resourceId: PropTypes.number,
        name: PropTypes.string,
        topicIds: PropTypes.object,
        onAddTopics: PropTypes.func,
        onRemoveTopic: PropTypes.func,
        updating: PropTypes.bool,
    };

    state = {
        isEditorOpen: false,
    }

    handleOpenEditor = () => this.setState({ isEditorOpen: true });
    handleCloseEditor = () => this.setState({ isEditorOpen: false });

    handleRemoveTopic = (topicId) => {
        const { resourceId, onRemoveTopic} = this.props;
        onRemoveTopic({ resourceId, topicId });
    }

    handleAddTopics = (topics) => {
        const { resourceId, onAddTopics} = this.props;
        onAddTopics({ resourceId, topics });
    }

    render() {
        const { topics, editValidator, name, topicIds, updating } = this.props;
        const { isEditorOpen } = this.state;
        return (
            <React.Fragment>
                <Header as="h4">
                    TOPICS
                </Header>
                <span>
                    <AccessControl
                        {...editValidator}
                    >
                        <Button
                            onClick={this.handleOpenEditor}
                            content="Edit..."
                            floated="right"
                            compact
                            title="Click here to edit topics."
                        />
                        {isEditorOpen && (
                            <ResourceTopicsEditor
                                onClose={this.handleCloseEditor}
                                name={name}
                                topics={topics}
                                topicIds={topicIds}
                                onRemoveTopic={this.handleRemoveTopic}
                                onAddTopics={this.handleAddTopics}
                                updating={updating}
                            />
                        )}
                    </AccessControl>
                    {topics && topics.length ? <React.Fragment>
                        <List as="ul" style={{ display: "table" }}>
                            {topics.map(topic => (
                                <List.Item as="li" key={topic.key}>
                                    <Topic topic={topic.value} />
                                </List.Item>
                            ))}
                        </List>
                    </React.Fragment>
                        : (
                            <i className={styles.noDataMsg}>Not assigned to any topic.</i>
                        )}
                </span>
            </React.Fragment>
        );
    }
}
