import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isSaving } from "./ResourceSourceTypeSelectors";
import { onSave } from "./ResourceSourceTypeActions";
import ResourceSourceType from "./ResourceSourceType";

const dispatchToProps = (dispatch) => bindActionCreators({
    onUpdate: onSave,
}, dispatch);

const mapStateToProps = (state) => ({ 
    isSaving: isSaving(state),
});

const ResourceSourceTypeContainer = connect(
    mapStateToProps,
    dispatchToProps
)(ResourceSourceType);
ResourceSourceTypeContainer.displayName = "ResourceSourceType";

export default ResourceSourceTypeContainer;