import { TYPES } from "./EditKitActions";

const initialState = {
    open: false,
    kitId: null,
    saving: false,
    variants: [],
    isDirty: false,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.EDIT_AKIT_EDIT:
            return { ...state, open: true, kitId: payload };
        case TYPES.EDIT_AKIT_CLOSE:
            return { ...state, open: false, saving: false };
        case TYPES.EDIT_AKIT_UPDATE:
            return { ...state, saving: true };
        case TYPES.EDIT_AKIT_SET_VARIANTS:
            return { ...state, variants: payload };
        case TYPES.EDIT_AKIT_SAVED:
            return { ...state, saving: false, isDirty: false };
        case TYPES.EDIT_AKIT_SET_DIRTY_FLAG:
            return { ...state, isDirty: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
