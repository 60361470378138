import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import TopicTree from "./TopicTree";
import { firebaseConnect } from "react-redux-firebase";
import { getTopicCollectionTree, getSelectedTopicCollection } from "../topicCollectionSelectors";
import {
    onAddTopic,
    onDeleteTopic,
    onMoveTopic,
    onUpdateTopic,
    onExpandTopic
} from "../topicCollectionActions";
import { userClaims } from "../../Users/UserProfile/UserProfileSelectors";
import { isAuthorAtLeast } from "../../Users/roleUtils";

const mapStateToProps = function(state, props) {
    return {
        collectionTree: getTopicCollectionTree(state),
        _collection:
            state.firebase.data.topic_collections.data &&
            state.firebase.data.topic_collections.data[props.selectedCollection],
        canEdit: isAuthorAtLeast(userClaims(state)),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators(
        { onAddTopic, onDeleteTopic, onMoveTopic, onUpdateTopic, onExpandTopic },
        dispatch
    );
};

export default compose(
    connect(state => ({
        selectedCollection: getSelectedTopicCollection(state)
    })),
    firebaseConnect(props => {
        return ["/topic_collections/data/" + props.selectedCollection];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(TopicTree);
