import { all, takeEvery, put, select } from "redux-saga/effects";
import firebase from "../../firebase";
import {
    TYPES,
    onImportHomeworkStop,
    onImportHomeworkStart,
    onImportHomeworkProgress,
    onImportHomeworkError,
} from "./importHomeworkActions";
import { convertToRawContentState } from "../../utils/conversionUtils";
import { getHomeworkToImport, inProgress } from "./importHomeworkSelector";
import exportToHtml from "../../component/seamlessEditor/export/exportToHtml";

export function* importHomework() {
    yield put(onImportHomeworkStart());
    try {
        // get a list of homework problems to import
        let problems = yield select(getHomeworkToImport);
        let total = Object.keys(problems || {}).length;
        let i = 1;
        for (let key in problems) {
            let stillInProgress = yield select(inProgress);
            if (stillInProgress) {
                let problem = problems[key];
                let rawQuestionContentState = yield convertToRawContentState(problem.question.content);
                let rawAnswerContentState = yield convertToRawContentState(problem.answer.content);

                let questionHtml = exportToHtml(rawQuestionContentState);
                let answerHtml = exportToHtml(rawAnswerContentState);

                let updatedProblem = {
                    ...problem,
                    question: { ...problem.question, content: questionHtml, format: {}, raw: rawQuestionContentState },
                    answer: { ...problem.answer, content: answerHtml, format: {}, raw: rawAnswerContentState },
                };

                let updates = {};
                updates["problem/" + key] = updatedProblem;
                updates["homework_to_import/" + key] = {};
                yield firebase.defaultApp
                    .database()
                    .ref()
                    .update(updates);
                yield put(onImportHomeworkProgress(i, total));
                i++;
            }
        }
    } catch (error) {
        yield put(onImportHomeworkError(JSON.stringify(error)));
    }
    yield put(onImportHomeworkStop());
}

export default function* saga() {
    yield all([takeEvery(TYPES.IMPORT_HOMEWORK, importHomework)]);
}
