import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";

import "./BorderStyleSelection.css";

class StyleSwatch extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        active: PropTypes.bool,
        onSelect: PropTypes.func,
    }

    handleSelect = () => {
        this.props.onSelect("border", this.props.value);
    }

    render() {
        const { value, active } = this.props;
        return <List.Item active={active} onClick={this.handleSelect} className="text"><div className={`style-swatch ${value}`} title={value} /></List.Item>;
    }
}

export default class BorderStyleSelection extends React.PureComponent {
    static propTypes = {
        defaultValue: PropTypes.string,
        onChange: PropTypes.func,
    };

    handleSelectNoBorder = () => {
        this.props.onChange("border", null);
    }

    render() {
        const { defaultValue, onChange } = this.props;
        return (
            <List selection className="style-select">
                <List.Item active={!defaultValue} onClick={this.handleSelectNoBorder}>No border</List.Item>
                {[ "solid", "dotted", "dashed", "double" ].map(borderStyle =>
                    <StyleSwatch key={borderStyle} value={borderStyle} active={defaultValue === borderStyle} onSelect={onChange}/>
                )}
            </List>
        );
    }
}
