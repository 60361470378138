import { TYPES } from "./designTileActions";

const initialState = {
    border: false,
    background: false,
    shadow: true,
    rounded: false,
    hover: false,
    labelNotification: false,
    labelNotificationStyle: "attached",
    incomplete: "none"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.DESIGN_SET:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export default reducer;
