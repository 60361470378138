const TYPES = {
    MODULES_CREATE_MODULE: "MODULES_CREATE_MODULE",
    MODULES_EDIT_MODULE: "MODULES_EDIT_MODULE",
    MODULES_UPDATE_MODULES_KITS: "MODULES_UPDATE_MODULES_KITS",
    MODULES_REMOVE_MODULE: "MODULES_REMOVE_MODULE",
    MODULES_UPDATED: "MODULES_UPDATED",
    MODULES_LOAD: "MODULES_LOAD",
    MODULES_SET: "MODULES_SET",
};

const onCreate = (payload) => ({
    type: TYPES.MODULES_CREATE_MODULE,
    payload
});

const onEdit = (payload) => ({
    type: TYPES.MODULES_EDIT_MODULE,
    payload
});

const onUpdateModuleKits = (payload) => ({
    type: TYPES.MODULES_UPDATE_MODULES_KITS,
    payload
});

const onRemoveModule = (payload) => ({
    type: TYPES.MODULES_REMOVE_MODULE,
    payload
});

const onUpdated = () => ({
    type: TYPES.MODULES_UPDATED
});

const onLoad = () => ({
    type: TYPES.MODULES_LOAD
});

const onSet = (payload) => ({
    type: TYPES.MODULES_SET,
    payload
});

export {
    TYPES,
    onCreate,
    onEdit,
    onUpdateModuleKits,
    onRemoveModule,
    onUpdated,
    onLoad,
    onSet,
};
