import { all, takeEvery, select, put, takeLatest} from "redux-saga/effects";
import { TYPES, onUpdated, onSet, onLoad } from "./ModulesActions";
import { toastError, DB_ERROR } from "../../component/toast";
import firebase, { modulesManager } from "../dataSource";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { createHash } from "crypto";
import { aKitManager } from "../dataSource";
import { client } from "../../feathersjs/client";

const kitService = client.service("kit");
const pkgService = client.service("package");
const pkgTenantService = client.service("package-tenant");

const fromBase64 = (input) => {
    return input
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
};

function* createModule({ payload }) {
    try {
        const userId = yield select(loggedUserId);
        const tenant = yield select(tenantId);
        const { key } = firebase.getFirebaseData().push();
        const packageResponse = yield pkgService.create({
            name: payload.name,
            desc: payload.desc || null,
            fb_id: key,
            tenant_id: tenant,
            is_public: payload.is_public ? 1 : 0,
            super_authors: payload.superAuthors || [],
        });
        yield modulesManager.updateModule(key, {...payload, id: packageResponse.id, c_by: userId, c_at: new Date().toISOString(), t: tenant });
        yield put(onLoad());
    } catch (e) {
        toastError({ code: e.code, header: "Failed to create a new package", message: DB_ERROR });
    }
    yield put(onUpdated());
}

function* editModule({ payload: { key, id, ...content } }) {
    try {
        const userId = yield select(loggedUserId);
        const body = {
            name: content.name,
            desc: content.desc || null,
            fb_id: key,
            tenant: content.tenant_id,
            is_public: content.is_public ? 1 : 0,
            super_authors: content.superAuthors || [],
        };
        const packageResponse = yield id ? pkgService.patch(id, body) : pkgService.create(body);
        yield modulesManager.updateModule(key, {...content, id: packageResponse.id, u_by: userId, u_at: new Date().toISOString() });
        yield put(onLoad());
    } catch (e) {
        toastError({ code: e.code, header: "Failed to update package", message: DB_ERROR });
    }
    yield put(onUpdated());
}

export function* updateWidgetModuleUsage(mId, kitIds, isUsed) {
    for (const kitId of kitIds) {
        const kit = (yield firebase.getFirebaseData("kits_data").child(kitId).once("value")).val();
        if (kit && kit.outline_id) {
            const lessons = (yield firebase.getFirebaseData("outlines_data").child(kit.outline_id).child("lessons").once("value")).val();
            if (lessons) {
                for (const lessonId of Object.keys(lessons)) {
                    const widgetsModule = {};
                    const lesson = lessons[lessonId];
                    if (lesson.widgets) {
                        for (const widgetId of Object.keys(lesson.widgets)) {
                            widgetsModule[`${widgetId}/${mId}/${kitId}`] = isUsed ? true : null;
                        }
                    }
                    yield firebase.getFirebaseData("widgetLibrary/m").update(widgetsModule);
                }
            }
        }
    }
}

export function* updateGlossaryModuleUsage(mId, kitIds, isUsed) {
    for (const kitId of kitIds) {
        const kit = (yield firebase.getFirebaseData("kits_data").child(kitId).once("value")).val();
        if (kit && kit.outline_id) {
            const glossary = (yield firebase.getFirebaseData("glossary_outline_usage").child(kit.outline_id).once("value")).val();
            if (glossary) {
                for (const glossaryId of Object.keys(glossary)) {
                    const glossaryModule = {};
                    for (const usageId of Object.keys(glossary[glossaryId])) {
                        glossaryModule[`${glossaryId}/${mId}/${usageId}`] = isUsed ? true : null;
                    }
                    yield firebase.getFirebaseData("glossary/m").update(glossaryModule);
                }
            }
        }
    }
}

export function* updateSnippetModuleUsage(mId, kitIds, isUsed) {
    const outlines = {};
    for (const kitId of kitIds) {
        const kit = (yield firebase.getFirebaseData("kits_data").child(kitId).once("value")).val();
        if (kit && kit.outline_id) {
            outlines[kit.outline_id] = true;
        }
    }
    const snippetsUsage = (yield firebase.getFirebaseData("snippets_usage").once("value")).val();
    const widgetList = (yield firebase.getFirebaseData("widgetLibrary").child("list").once("value")).val();
    const changes = {};
    for (const snippetId of Object.keys(snippetsUsage)) {
        const snippetUsage = snippetsUsage[snippetId];
        if (snippetUsage.widgets) {
            for (const widgetId of Object.keys(snippetUsage.widgets)) {
                if (widgetList[widgetId] && widgetList[widgetId].used) {
                    for (const lessonId of Object.keys(widgetList[widgetId].used)) {
                        if (outlines[widgetList[widgetId].used[lessonId]]) {
                            for (const itemId of Object.keys(snippetUsage.widgets[widgetId])) {
                                const hash = createHash("md5").update(`${lessonId}_${widgetId}_${itemId}`, "utf8").digest("base64");
                                changes[`${snippetId}/m/${mId}/${fromBase64(hash)}`] = isUsed ? true : null;
                            }
                        }
                    }
                }
            }
        }
    }
    yield firebase.getFirebaseData("snippets").update(changes);
}

function* editModulesKits({ payload: { key, modulesKits, removeKits }}) {
    try {
        const changes = { };
        for (const kitId of modulesKits) {
            const id = yield aKitManager.getKitDbId(kitId);
            if (id) {
                yield kitService.patch(id, { new_packages: [key] });
            }
            changes[`kits_data/${kitId}/m/${key}`] = true;
            changes[`modules/list/${key}/akits/${kitId}`] = true;
        }
        for (const kitId of removeKits) {
            const id = yield aKitManager.getKitDbId(kitId);
            if (id) {
                yield kitService.patch(id, { removed_packages: [key] });
            }
            changes[`modules/list/${key}/akits/${kitId}`] = null;
            changes[`kits_data/${kitId}/m/${key}`] = null;
        }
        yield firebase.getFirebaseData("/").update(changes);
        yield updateWidgetModuleUsage(key, modulesKits, true);
        yield updateWidgetModuleUsage(key, removeKits, false);
        yield updateGlossaryModuleUsage(key, modulesKits, true);
        yield updateGlossaryModuleUsage(key, removeKits, false);
        yield updateSnippetModuleUsage(key, modulesKits, true);
        yield updateSnippetModuleUsage(key, removeKits, false);
        yield put(onLoad());
    } catch (e) {
        toastError({ code: e.code, header: "Failed to update packages kits", message: DB_ERROR });
    }
    yield put(onUpdated());
}

function* removeModule({ payload: { moduleId, id } }) {
    try {
        if (id) {
            yield pkgService.remove(id);
        }
        yield firebase.getFirebaseData("/modules/").update({ [`list/${moduleId}`]: null });
        yield put(onLoad());
    } catch (e) {
        toastError({ code: e.code, header: "Failed to remove package " +  moduleId, message: DB_ERROR });
    }
    yield put(onUpdated());
}

function* loadPackages() {
    try {
        const tenant_id = yield select(tenantId);
        const packages = yield pkgService.find();
        const subscribed = yield pkgTenantService.find({ query: { tenant_id }});
        const filteredPackages = packages.filter(p => p.tenant_id === tenant_id || subscribed.some(i => i.package_id === p.id));
        yield put(onSet(filteredPackages));
    } catch (e) {
        toastError({ code: e.code, header: "Failed to load packages.", message: DB_ERROR });
        yield put(onSet([]));
    }
}

export default function* modulesSaga() {
    yield all([
        takeEvery(TYPES.MODULES_CREATE_MODULE, createModule),
        takeEvery(TYPES.MODULES_EDIT_MODULE, editModule),
        takeEvery(TYPES.MODULES_UPDATE_MODULES_KITS, editModulesKits),
        takeEvery(TYPES.MODULES_REMOVE_MODULE, removeModule),
        takeLatest(TYPES.MODULES_LOAD, loadPackages),
    ]);
}
