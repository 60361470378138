import React from "react";

export class DraggableWrapper extends React.Component {
    render() {
        const { provided, innerRef, children, style } = this.props;
        return (
            <div {...provided.draggableProps} ref={innerRef} {...style && { style }}>
                {children}
            </div>
        );
    }
}

export class DroppableWrapper extends React.Component {
    render() {
        const { provided, innerRef, children, style } = this.props;
        return (
            <div {...provided.droppableProps} ref={innerRef} {...style && { style }}>
                {children}
            </div>
        );
    }
}