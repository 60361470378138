import { createSelector } from "reselect";
import { getMatchParam, compareByNameCI, denormalizeTopicKey } from "../../utils/selectorUtils";
import { getCourseVariantDetails } from "../../KitBuilder/AKits/CreateKit/CreateKitSelectors";
import { compareByEntityAttribute, nullsLast } from "../../utils/compareFunctions";
import { mapSourceTypeToOption } from "./components/ResourceSourceType/SourceTypeUtils";
import { getTopicCollectionData } from "../../TopicCollection/topicCollectionSelectors";

const _resources = (state) => state.lup.resources; // root state of resources

export const isLoading = state => _resources(state).isLoading;
export const getResourcesData = state => _resources(state).data;
export const getSelectedItemId = state => _resources(state).selectedItem;

export const getFilterIsOpen = (state) => _resources(state).isFilterOpen;
export const getFilterParams = (state) => _resources(state).filterParams;

export const getFilterByName = (state) => _resources(state).filterByName;
export const getFilterByTags = (state) => getFilterParams(state).tags;
export const getFilterByVariant = (state) => getFilterParams(state).courseVariant;

export const isLoadingVariants = (state) => _resources(state).isLoadingVariants;
export const courseVariants = (state) => _resources(state).courseVariants;

export const isLoadingSourceTypes = (state) => _resources(state).isLoadingSourceTypes;
export const sourceTypes = (state) => _resources(state).sourceTypes;

export const isLoadingTopics = (state) => _resources(state).isLoadingTopics;
export const usedTopics = (state) => _resources(state).usedTopics;
const getFilterByTopic = (state) => getFilterParams(state).topic;

const compareCreatedAtDescending = (l, r) => {
    const lc = l.created_at;
    const rc = r.created_at;
    return lc === rc ? r.id - l.id : (lc > rc ? -1 : 1);
};

const buildFilterByNamePredicate = (filterByName) => {
    const pattern = filterByName && filterByName.toLowerCase();
    if (pattern && pattern.length) {
        return (resource) => (resource.name && resource.name.toLowerCase().indexOf(pattern) !== -1);
    }
    return null;
};

export const getResourcesList = createSelector(
    getResourcesData,
    getFilterByName,
    (data, filterByName) => {
        if (typeof data !== "object") {
            return undefined;
        }
        const complete = data ? Object.values(data) : [];
        const predicate = buildFilterByNamePredicate(filterByName);
        return (predicate ? complete.filter(predicate) : complete)
            .sort(compareCreatedAtDescending);
    }
);

export const getSelectedResourceId = (state, props) => {
    const str = props
        ? (props.resourceId || getMatchParam(props, "resourceId"))
        : _resources(state).selectedItem;
    return str && +str;
};

export const getTopicFilterName = createSelector(
    getFilterByTopic,
    getTopicCollectionData,
    (topicId, topicsData) => {
        if (topicsData && topicId) {
            const  [collection, topic] = denormalizeTopicKey(topicId);
            if (topicsData[collection] && topicsData[collection][topic]) {
                return topicsData[collection][topic].name;
            }
        }
    }
);

export const getSelectedResourceData = createSelector(
    getResourcesData,
    getSelectedResourceId,
    isLoading,
    getTopicCollectionData,
    (data, entryId, loading, topicsData) => {
        if (data && entryId && data[entryId]) {
            const rData = data[entryId];
            if (rData.createdBy) {
                rData["resourceOwnerId"] = `u${rData.createdBy.uuid}@t${rData.createdBy.tenant_id}`;
            }
            if (rData.updatedBy) {
                rData["resourceEditorId"] = `u${rData.updatedBy.uuid}@t${rData.updatedBy.tenant_id}`;
            }
            if (rData.used) {
                rData.used.map(cv => cv["displayName"] = getCourseVariantDetails(cv));
            }
            if (rData.topicIds && 0 < Object.keys(rData.topicIds).length && topicsData) {
                const topics = [];
                for (const id of Object.keys(rData.topicIds)) {
                    let [collection, topic] = denormalizeTopicKey(id);
                    topics.push({ key: id, value: topicsData[collection] ? topicsData[collection][topic] : null});
                }
                rData.topics = topics;
            } else {
                rData.topics = [];
            }
            return rData;
        } else {
            return loading ? undefined : null;
        }
    }
);

export const isLoadingTags = (state) => _resources(state).isLoadingTags;
export const getResourceTags = (state) => _resources(state).tags;
export const isUpdatingTopics = (state) => _resources(state).isUpdatingTopics;
export const isLoadingDetail = (state) => _resources(state) .isLoadingDetail;

const convertTagToOption = t => ({
    key: t.name,
    value: t.name,
    text: t.name,
    description: `Number of uses ${t.resources.length}`
});

export const getFilterByTagsOptions = createSelector(
    getResourceTags,
    (tags) => Array.isArray(tags) ? tags.sort(compareByNameCI).map(convertTagToOption) : []
);

export const getCourseVariantsOptions = createSelector(
    courseVariants,
    (course_variants) => {
        const courseVariants = course_variants
            .sort(compareByEntityAttribute("name", nullsLast, value => value.toLocaleLowerCase().trim()));
        return courseVariants.map(courseVariant => ({
            key: courseVariant.id,
            value: courseVariant.id,
            text: courseVariant.name,
            description: getCourseVariantDetails(courseVariant),
        }));
    }
);

export const getSourceTypesOptions = createSelector(
    sourceTypes,
    (sourceTypes) => {
        if (Array.isArray(sourceTypes)) {
            return sourceTypes.map(mapSourceTypeToOption).filter(Boolean);
        }
        return undefined;
    }
);
