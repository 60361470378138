import { all, fork, put, takeEvery, select, takeLatest } from "redux-saga/effects";
import {
    TYPES,
    onQuestionAddSuccess,
    onSearchResults,
    onUpdateTotalCount
} from "./ProblemManagementActions";
import firebase from "../../firebase";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { 
    rebuildProblemSetData,
    getRelatedProblemSets,
} from "../ProblemSetCreator/problemSetCreatorSaga";
import { toastError } from "../../component/toast";
import { denormalizeTopicKey } from "../../utils/selectorUtils";
import {
    clearAnswerIfParent,
    deleteQuestionById,
} from "../utils";

const dbNode = "problem";

export function* getProblemCount() {
    const db = firebase.defaultApp.options.databaseURL;
    const accessToken = yield firebase.getFirebaseAuth().currentUser.getIdToken();
    const response = (yield fetch(`${db}/problem.json?shallow=true&auth=${accessToken}`));
    const data = yield response.json();
    yield put(onUpdateTotalCount(Object.keys(data).length));
}

export function* createQuestion({ question, callback }) {

    const db = firebase.defaultApp.database().ref();
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);

    const newQuestion = clearAnswerIfParent({
        ...question,
        author: String(userId),
        t: tenant
    });

    try {
        const { key } = yield db.child(dbNode).push(newQuestion);
        newQuestion.id = key;
        yield put(onQuestionAddSuccess(newQuestion));
        // Get the related problem sets & rebuild their HTML
        const usedIn = yield getRelatedProblemSets(key);
        usedIn.length && (
            yield fork(rebuildProblemSetData, usedIn)
        );
        callback && callback(key);
    } catch (error) {
        toastError({ code: error.code, header: "Adding a new problem failed.", message: error.message });
    }

}

export function* deleteQuestion({ questionId }) {
    // Get the related problem sets
    const usedIn = yield getRelatedProblemSets(questionId);
    // Remove the entry
    yield deleteQuestionById(dbNode, questionId);
    // Update the problem sets HTML
    if (usedIn && usedIn.length) {
        yield fork(rebuildProblemSetData, usedIn);
    }
}

export function* editSaveQuestion({ question, callback }) {
    const dataRef = firebase.getFirebaseData(dbNode);
    try {
        const questionId = question.id;
        // Don't save back de-normalized data
        const toUpdate = clearAnswerIfParent({ ...question, id: null, type: null, });
        delete toUpdate.displayTopics;
        delete toUpdate.displayProblemSets;

        yield dataRef.child(questionId).update(toUpdate);
        // Get the related problem sets & rebuild their HTML
        const usedIn = yield getRelatedProblemSets(questionId);
        if (usedIn && usedIn.length) {
            yield fork(rebuildProblemSetData, usedIn);
        }
        callback && callback();
    } catch (error) {
        toastError({ code: error.code, header: "Saving a problem failed.", message: error.message });
    }
}

const intersectObjectKeys = (current, matchTo) => (
    Object.keys(current).reduce((acc, key) => {
        if (!matchTo[key]) {
            delete current[key];
        }
        return acc;
    }, current)
);

export function* searchForProblem({ payload: { selectedTopic, selectedTags }, currentPage, itemsPerPage }){
    const db = firebase.defaultApp.database().ref();
    let results = {};
    if (selectedTopic || (selectedTags && selectedTags.length)) {
        if (selectedTopic) {
            const [collectionId, topicId] = denormalizeTopicKey(selectedTopic);
            yield db.child(`topic_collections/data/${collectionId}/${topicId}`).once("value", (snapshot) => {
                const topic = snapshot.val();
                if (topic && topic.used && topic.used.problem) {
                    results = { ...results, ...topic.used.problem };
                }
            });
        }
        if (selectedTags && selectedTags.length) {
            const tagProblems = yield Promise.all(
                selectedTags.map(async (tag) => (
                    await db.child(`tags_problems/${tag}`).once("value")).val()
                )
            );
            const tagsResults = tagProblems.reduce((acc, problems, index) => {
                if (index === 0) { // Use all problems from the first tags
                    return { ...problems };
                } else { // If multiple tags are used, narrow to problems that have all tags
                    return intersectObjectKeys(acc, problems);
                }
            });
            results = selectedTopic ? intersectObjectKeys(results, tagsResults) : tagsResults;
        }

        const startPoint = currentPage == 1 ? 0 : (currentPage - 1) * itemsPerPage;
        const matchingIds = Object.keys(results); //.sort(); // to achieve predictable ordering?
        const pageEntries = matchingIds
            .slice(startPoint, startPoint + itemsPerPage)
            .reduce((acc, id) => (acc[id] = results[id], acc), {});

        const totalRecords = matchingIds.length;
        const totalPages = totalRecords > 0 ? Math.ceil(totalRecords / itemsPerPage) : 0;

        yield put(onSearchResults(pageEntries, totalPages, totalRecords, results));
    } else { 
        yield put(onSearchResults(results, 0, 0, results));
        yield getProblemCount();
    }
}

export default function* sagaProblems() {
    yield all([
        takeEvery(TYPES.QUESTION_BANK_CREATE_QUESTION, createQuestion),
        takeEvery(TYPES.QUESTION_BANK_DELETE_QUESTION, deleteQuestion),
        takeEvery(TYPES.QUESTION_BANK_MODIFY_QUESTION, editSaveQuestion),
        takeLatest(TYPES.QUESTION_BANK_APPLY_FILTER, searchForProblem),
        takeLatest(TYPES.QUESTION_BANK_GET_ALL_COUNT, getProblemCount),
    ]);
}
