import { connect } from "react-redux";
import YearEditor, { EDIT_MODE } from "./YearEditor";
import { getAllYears } from "./YearsSelectors";

const mapStateToProps = function(state) {
    return {
        allYears: getAllYears(state) || [],
    };
};

export { EDIT_MODE };
export default connect(mapStateToProps)(YearEditor);
