exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1WnGPhBMtwJSj9S8MHo8Z2 {\n  min-width: 100%;\n  position: relative; }\n", ""]);

// exports
exports.locals = {
	"node": "_1WnGPhBMtwJSj9S8MHo8Z2"
};