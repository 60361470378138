import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import PageHeader from "../../component/PageHeader";
import GlossaryList from "./component/GlossaryList";
import ConfirmDeleteWithContext from "../../component/ConfirmDeleteWithContext";
import { Grid, Button, Divider, Menu } from "semantic-ui-react";
import GlossaryDetail from "./GlossaryDetail";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import CreateTermDialog from "./component/CreateTermDialog";
import AdvancedFilter from "../component/AdvancedFilter";
import FilteredByOptionsView from "../component/FilteredByOptionsView";

export default class Glossary extends PureComponent {
    static propTypes = {
        glossary: PropTypes.array,
        glossaryListCollection: PropTypes.object,
        glossaryDataCollection: PropTypes.object,
        searchFilter: PropTypes.string,
        onRemoveTerm: PropTypes.func,
        glossaryId: PropTypes.string,
        history: PropTypes.object,
        onAddTerm: PropTypes.func,
        isAuthorAtLeast: PropTypes.bool,
        onSelectTag: PropTypes.func,
        onChangeUnusedFilter: PropTypes.func,
        usedTags: PropTypes.array,
        selectedTags: PropTypes.array,
        unusedFilter: PropTypes.bool,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
        moduleFilter: PropTypes.array,
        onSetModuleFilter: PropTypes.func,
    };

    state = {
        removeId: null,
        removeContext: null,
    };

    componentDidMount() {
        this.props.onSetModuleFilter([]);
    }

    handleTermDetail = (glossaryId) => {
        const { history } = this.props;
        history && history.push(`/widgets/glossary/${glossaryId}`);
    }

    handleRemove = (e, id) => {
        const { removeId } = this.state;
        if (!removeId) {
            this.setState({
                removeContext: e.currentTarget,
                removeId: id
            });
        }
    };

    handleCancelRemove = () => {
        this.setState({ removeId: null, removeContext: null });
    };

    handleRemoveConfirm = () => {
        const { onRemoveTerm } = this.props;
        const { removeId } = this.state;
        if (removeId && onRemoveTerm) {
            onRemoveTerm(removeId);
        }
        this.handleCancelRemove();
    }

    handleAddTerm = () => {
        this.props.onAddTerm({ callback: this.handleTermDetail });
    }

    render() {
        const { glossaryListCollection, glossaryDataCollection, glossaryId, glossary,
            searchFilter, isAuthorAtLeast, onChangeUnusedFilter, onSelectTag, selectedTags,
            usedTags, unusedFilter, isDirty, onSetDirty, moduleFilter, onSetModuleFilter } = this.props;
        const { removeContext, removeId  } = this.state;
        let columnsCount = 1;
        let firstColumnWidth = 16;
        let simple = false;
        if (glossaryId) {
            columnsCount = 2;
            firstColumnWidth = 4;
            simple = true;
        }
        let isEmpty = (searchFilter === "" && (!glossary || 0 == glossary.length));
        return (
            <React.Fragment>
                <PageHeader {...this.props} list={glossary} headerText="Glossary" entityName="glossary term">
                    <AdvancedFilter
                        entityName="glossary term"
                        onChangeUnusedFilter={onChangeUnusedFilter}
                        onSelectTag={onSelectTag}
                        unusedFilter={unusedFilter}
                        selectedTags={selectedTags}
                        usedTags={usedTags}
                        moduleFilter={moduleFilter}
                        onSetModuleFilter={onSetModuleFilter}
                    />
                    {!isEmpty && isAuthorAtLeast && (
                        <Menu.Item
                            disabled={isDirty}
                            onClick={this.handleAddTerm}
                            icon="plus"
                            content="New"
                            title={!isDirty ? "Add new glossary term" : "Adding new glossary term is not allowed, if you are in the middle of editation."}
                        />
                    )}
                </PageHeader>
                {(selectedTags && 0 < selectedTags.length || unusedFilter || (moduleFilter && 0 < moduleFilter.length)) && 
                    <FilteredByOptionsView 
                        onChangeUnusedFilter={onChangeUnusedFilter}
                        onSelectTag={onSelectTag}
                        unusedFilter={unusedFilter}
                        selectedTags={selectedTags}
                        moduleFilter={moduleFilter}
                        onSetModuleFilter={onSetModuleFilter}
                    />}
                <ConfirmDeleteWithContext
                    context={removeContext}
                    confirmText="Remove term"
                    onConfirm={this.handleRemoveConfirm}
                    whatText={`glossary term ${glossaryListCollection && glossaryListCollection[removeId] && glossaryListCollection[removeId].name}`}
                    onCancel={this.handleCancelRemove}
                />
                <CreateTermDialog redirectAfterCreate />
                <Divider clearing hidden />
                <Grid columns={columnsCount} >
                    <Grid.Column width={firstColumnWidth}  style={{height: "75vh"}}>
                        { isEmpty ?
                            <NoDataPlaceholder text="Here we list the content of your glossary." icon="file outline">
                                <p>Glossary terms can be used and referenced in the Widgets. It helps the students to better learn and understand difficult topics.</p>
                                {isAuthorAtLeast && <Button primary content="Add term" onClick={this.handleAddTerm} />}
                            </NoDataPlaceholder>
                            : <GlossaryList
                                glossary={glossary}
                                glossaryDataCollection={glossaryDataCollection}
                                glossaryId={glossaryId}
                                onRemove={this.handleRemove}
                                simple={simple}
                                onSelectTerm={this.handleTermDetail}
                                canEdit={isAuthorAtLeast}
                                isDirty={isDirty}
                                onSetDirty={onSetDirty}
                            />}
                    </Grid.Column>
                    {simple && <GlossaryDetail key={glossaryId} onRemove={this.handleRemove} glossaryId={glossaryId} />}
                </Grid>
            </React.Fragment>
        );
    }
}

