import React from "react";
import PropTypes from "prop-types";
import { convertDateIsoToLocal } from "../utils/dateUtils";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import UserAvatar from "react-user-avatar";
import { Popup } from "semantic-ui-react";

import "./ChangeInfoLabel.scss";

export const getUserDisplayName = (user) => (
    user && (user.first_name || user.last_name)
        ? `${user.first_name || ""} ${user.last_name || ""}`.trim()
        : null
);

/**
 * Shows a change information with time and user name.
 * 
 * Format: "${prefix} ${local(doneAt)}[ by ${doneBy}]"
 */
export default class ChangeInfoLabel extends React.PureComponent {
    static propTypes = {
        /** Start of the informative message string */
        prefix: PropTypes.string,
        /** Time of the change (ISO datetime string) */
        doneAt: PropTypes.string,
        /** Name of the change actor (e.g. username) */
        doneBy: PropTypes.string,
        /** String to be shown if no change was done */
        unchanged: PropTypes.string,
        // Other properties
        className: PropTypes.string,
        style: PropTypes.object,
        title: PropTypes.string,
    };

    state = { 
        textTimeAgo: null, 
        textTimestamp: null,
        timeAgo: undefined
    };
    
    constructor(props) {
        super(props);
        TimeAgo.addLocale(en);
        this.state = { 
            textTimeAgo: null, 
            textTimestamp: null,
            timeAgo: new TimeAgo("en-US") 
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let textTimeAgo = null != nextProps.doneAt ? prevState.timeAgo.format(new Date(nextProps.doneAt)) : null;
        return {textTimeAgo, textTimestamp: convertDateIsoToLocal(nextProps.doneAt), timeAgo: prevState.timeAgo};
    }

    render() {
        const { doneAt, doneBy, unchanged, prefix, ...blockProps } = this.props;
        const { textTimeAgo, textTimestamp } = this.state;

        if (doneAt || doneBy) {
            return <Popup 
                trigger={<span {...blockProps} style={{whiteSpace: "nowrap"}}>
                    <span className="changeInfoLabel success">{prefix}</span> {" - " + textTimeAgo}
                </span>}
            >
                <Popup.Header>
                    {prefix}     
                </Popup.Header>
                <Popup.Content style={{minWidth: "200px"}}>
                    {doneBy && <UserAvatar name={doneBy} size="32" 
                        style={{float: "left", fontSize: "14px", display: "inline-flex", marginRight: "5px"}}/>}
                    <div>{doneBy}<br /><span style={{color: "grey"}}>{textTimestamp}</span></div>
                </Popup.Content>
            </Popup>;
        }
        else if (unchanged) {
            return <span {...blockProps}>
                <span className="changeInfoLabel">{unchanged}</span>
            </span>;
        } else 
            return <React.Fragment />;
    }
}
