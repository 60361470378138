import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isLoadingDetails, courseDetails, sectionOptions, selectedSectionId, teachers, students, selectedCourse, getSchoolForSelectedCourse } from "../SchoolCoursesSelectors";
import { onSelectSection } from "../SchoolCoursesActions";
import { onSelectUser } from "../../UserCourses/UserCoursesActions";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Dimmer, Loader, Form, Header } from "semantic-ui-react";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import { ClassRoster } from "./ClassRoster";
import { getYearsAsObject } from "../../../Year/YearsSelectors";

class CourseDetails extends PureComponent {
    static propTypes = {
        isLoadingDetails: PropTypes.bool,
        courseDetails: PropTypes.array,
        sectionOptions: PropTypes.array,
        onSelectSection: PropTypes.func,
        selectedSectionId: PropTypes.number,
        teachers: PropTypes.array,
        students: PropTypes.array,
        onSelectUser: PropTypes.func,
        selectedCourse: PropTypes.object,
        school: PropTypes.object,
        years: PropTypes.object,
    }

    handleSelectSection = (e, { value }) => {
        this.props.onSelectSection(value);
    }

    render() {
        const { isLoadingDetails, courseDetails, sectionOptions, selectedSectionId, teachers, students, onSelectUser, selectedCourse, school, years } = this.props;
        return (
            <Grid.Column  width="8">
                <Dimmer.Dimmable dimmed={isLoadingDetails}>
                    <Dimmer active={isLoadingDetails} inverted>
                        <Loader>Loading details...</Loader>
                    </Dimmer>
                    {null === courseDetails ? isLoadingDetails ? <div style={{ minHeight: "10em"}}/> : <NoDataPlaceholder
                        text="Here we show details about selected School Course."
                    >
                        <p></p>
                    </NoDataPlaceholder> : (
                        <React.Fragment>
                            {(!isLoadingDetails && selectedCourse) && <Header size="medium" title="School Course name">
                                {selectedCourse.name}
                                {school && <Header.Subheader>{school.name}<span style={{float: "right"}}>{years[selectedCourse.year_id].name}</span></Header.Subheader>}
                            </Header>}
                            <Form>
                                <Form.Select
                                    label="Student crew"
                                    options={sectionOptions || []}
                                    value={selectedSectionId}
                                    placeholder="Select a student crew"
                                    onChange={this.handleSelectSection}
                                    title="Select group of students to see their class roster"
                                />
                            </Form>
                            <ClassRoster students={students} teachers={teachers} isLoading={isLoadingDetails} onSelectUser={onSelectUser} />
                        </React.Fragment>
                    )}
                </Dimmer.Dimmable>
            </Grid.Column>
        );
    }
}

const mapStateToProps = function(state, props) {
    return {
        isLoadingDetails: isLoadingDetails(state),
        courseDetails: courseDetails(state),
        sectionOptions: sectionOptions(state),
        selectedSectionId: selectedSectionId(state),
        selectedCourse: selectedCourse(state, props),
        teachers: teachers(state),
        students: students(state),
        school: getSchoolForSelectedCourse(state, props),
        years: getYearsAsObject(state),

    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { 
            onSelectSection,
            onSelectUser,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(CourseDetails);
