import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ProblemSetWizard from "../../../QuestionUtilizator/ProblemSetWizard";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { getHighestOrdering } from "./utils";


export default class AddNewProblemSet extends PureComponent {
    static propTypes = {
        selectedLesson: PropTypes.object,
        entityName: PropTypes.string,
        createNewProblemSet: PropTypes.func,
        onSetProblemSet: PropTypes.func,
        outlineId: PropTypes.string,
        lessonId: PropTypes.string,
        collection: PropTypes.string,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lessonProblemSets: PropTypes.array,
        showAsButton: PropTypes.bool,
        onSelect: PropTypes.func,
    };

    state = {
        showProblemSetDialog: false,
    };

    handleOpenNewProblemSet = () => {
        this.setState({ showProblemSetDialog: true });
        const { onSelect } = this.props;
        onSelect && onSelect();
    }
    handleCloseProblemSet = () => this.setState({ showProblemSetDialog: false });

    handleSetProblemSet = (id) => {
        const { outlineId, lessonId, tabIndex, onSetProblemSet, collection, lessonProblemSets } = this.props;
        onSetProblemSet({ id, outlineId, lessonId, tabIndex, collection, ordering: getHighestOrdering(lessonProblemSets) + 1 });
    }

    handleCreateProblemSet = (problemSet) => {
        this.props.createNewProblemSet(problemSet, this.handleSetProblemSet);
    }

    render() {
        const { selectedLesson, entityName, showAsButton } = this.props;
        const { showProblemSetDialog } = this.state;
        return (
            <React.Fragment>
                {showProblemSetDialog && (
                    <ProblemSetWizard
                        fbCollection="problemSet"
                        open={true}
                        problems={[]}
                        problemSet={{
                            title:
                                (selectedLesson &&
                                    selectedLesson.name &&
                                    `${entityName} for ${selectedLesson.name}`) ||
                                "",
                            notes: "",
                            setType: "hw",
                            tags: [],
                        }}
                        onLessonEditSave={this.handleCreateProblemSet}
                        onClose={this.handleCloseProblemSet}
                    />
                )}
                {showAsButton ? (
                    <Button primary content="Create new" onClick={this.handleOpenNewProblemSet} />
                ) : (
                    <Dropdown.Item icon={<Icon name="plus" color="grey" />} text="Create new" onClick={this.handleOpenNewProblemSet} />
                )}
            </React.Fragment>
        );
    }
}
