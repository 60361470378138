import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import cx from "classnames";
import { Header, Button, Dropdown, Menu } from "semantic-ui-react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import CourseVariantList from "./CourseVariantList";
import { assignedVariants, availableVariants, kitId } from "../EditKitSelectors";
import { onAssignVariant, onUnassignVariant } from "../EditKitActions";
import { withAuth } from "../../../../component/withAuth";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";
import CourseVariantInfiniteList from "./CourseVariantInfiniteList";

const FilterMenu = ({ usedTenants, usedYears, selectedTenant, selectedYear, onTenantChange, onYearChange }) => (
    <div style={{ float: "right", fontWeight: "normal", marginTop: "-7px" }}>
        <Menu text style={{ margin: 0, minHeight: "unset"}}>
            <Menu.Item>
                {usedTenants.length
                    ? <Dropdown compact
                        item
                        options={usedTenants}
                        value={selectedTenant}
                        onChange={onTenantChange}
                    />
                    : null
                }
            </Menu.Item>
            <Menu.Item>
                {usedYears.length
                    ? <Dropdown compact item
                        options={usedYears}
                        value={selectedYear}
                        onChange={onYearChange}
                    />
                    : null
                }
            </Menu.Item>
        </Menu>
    </div>
);

FilterMenu.propTypes = {
    usedTenants: PropTypes.array,
    usedYears: PropTypes.array,
    selectedTenant: PropTypes.string,
    selectedYear: PropTypes.string,
    onTenantChange: PropTypes.func,
    onYearChange: PropTypes.func,
};

class CourseVariantAssignment extends PureComponent {
    static propTypes = {
        assignedVariants: PropTypes.object,
        availableVariants: PropTypes.object,
        kitId: PropTypes.string,
        onAssignVariant: PropTypes.func,
        onUnassignVariant: PropTypes.func,
        isSuperAuthorAtLeast: PropTypes.bool,
        defaultYear: PropTypes.string,
        defaultTenant: PropTypes.string,
    };

    state = {
        forAssigning: {},
        forUnassigning: {},
        selectedYear: null,
        selectedTenant: null,
    };

    handleToggleForAssigning = (e, { id, checked }) => {
        const { forAssigning } = this.state;
        if (checked) {
            forAssigning[id] = true;
        } else {
            delete forAssigning[id];
        }
        this.setState({ forAssigning: { ...forAssigning } });
    };

    handleToggleForUnassigning = (e, { id, checked }) => {
        const { forUnassigning } = this.state;
        if (checked) {
            forUnassigning[id] = true;
        } else {
            delete forUnassigning[id];
        }
        this.setState({ forUnassigning: { ...forUnassigning } });
    };

    handleAssign = () => {
        const { onAssignVariant, kitId } = this.props;
        const { forAssigning } = this.state;
        onAssignVariant({ kitId, variants: forAssigning });
        this.setState({ forAssigning: {} });
    };

    handleUnassign = () => {
        const { onUnassignVariant, kitId } = this.props;
        const { forUnassigning } = this.state;
        onUnassignVariant({ kitId, variants: forUnassigning });
        this.setState({ forUnassigning: {} });
    };

    handleYearChange = (e, { value }) => {
        this.setState({ selectedYear: value });
    };

    handleTenantChange = (e, { value }) => {
        this.setState({ selectedTenant: value });
    };

    getAvailableTenantsAsOptions = memoizeOne((availableVariants) => {
        return Object.keys(availableVariants).map((tenant) => ({ text: tenant, value: tenant }));
    });

    getFilterOptions = (availableVariants) => {
        const { defaultTenant, defaultYear } = this.props;
        let { selectedTenant, selectedYear } = this.state;
        const usedTenants = this.getAvailableTenantsAsOptions(availableVariants);

        if (!selectedTenant) {
            // Before a user does the first selection of tenant, its initial value is the same tenant as logged user,
            // but if there is no Course for such tenant, the first available tenant is selected.
            if (usedTenants.length) {
                selectedTenant = usedTenants.find(({ value }) => value === defaultTenant) ? defaultTenant : usedTenants[0].value;
            }
        }

        // Offer only applicable years for selected tenant
        let usedYears = [];
        if (selectedTenant) {
            usedYears = Object.keys(availableVariants[selectedTenant]).map((year) => ({ text: year, value: year }));
            if (!usedYears.find(({ value }) => value === selectedYear)) {
                if (usedYears.length) {
                    selectedYear = usedYears.find(({ value }) => value === defaultYear) ? defaultYear : usedYears[0].value;
                }
            }
        }

        return { usedTenants, selectedTenant, usedYears, selectedYear };
    };

    getFilteredVariants = memoizeOne((availableVariants, tenant, year) => {
        return (availableVariants[tenant] && availableVariants[tenant][year]) || [];
    });

    render() {
        const { assignedVariants, availableVariants, isSuperAuthorAtLeast } = this.props;
        const { forAssigning, forUnassigning } = this.state;
        const { usedTenants, selectedTenant, usedYears, selectedYear } = this.getFilterOptions(availableVariants);
        const filteredVariants = this.getFilteredVariants(availableVariants, selectedTenant, selectedYear);

        return (
            <div className={cx("", {"courseVariantAssignment" : isSuperAuthorAtLeast})}>
                <div>
                    <Header as="h5">Courses assigned to this kit</Header>
                    {0 != Object.keys(assignedVariants).length ? (
                        <CourseVariantList
                            variants={assignedVariants}
                            selectedVariants={forUnassigning}
                            onToggle={this.handleToggleForUnassigning}
                            canEdit={isSuperAuthorAtLeast}
                        />
                    ) : (
                        <NoDataPlaceholder
                            icon="file alternate outline"
                            text="No Courses"
                        >
                            <p>There are no Courses assigned to this kit.</p>
                        </NoDataPlaceholder>
                    )}
                </div>
                {isSuperAuthorAtLeast && <React.Fragment>
                    <div className="cvAssignmentButtons">
                        <Button.Group vertical>
                            <Button
                                icon="arrow left"
                                disabled={0 == Object.keys(forAssigning).length || !isSuperAuthorAtLeast}
                                onClick={this.handleAssign}
                                title="Assign Course to kit"
                            />
                            <Button
                                icon="arrow right"
                                disabled={0 == Object.keys(forUnassigning).length || !isSuperAuthorAtLeast}
                                onClick={this.handleUnassign}
                                title="Remove Course from kit"
                            />
                        </Button.Group>
                    </div>
                    <div>
                        <Header as="h5">
                            Courses not assigned to any kit
                            {" "}<span style={{ fontWeight: "normal" }}>[{filteredVariants.length}]</span>
                            <FilterMenu
                                usedTenants={usedTenants}
                                usedYears={usedYears}
                                selectedTenant={selectedTenant}
                                selectedYear={selectedYear}
                                onTenantChange={this.handleTenantChange}
                                onYearChange={this.handleYearChange}
                            />
                        </Header>
                        {filteredVariants.length ? (
                            <CourseVariantInfiniteList
                                variants={filteredVariants}
                                selectedVariants={forAssigning}
                                onToggle={this.handleToggleForAssigning}
                                canEdit={isSuperAuthorAtLeast}
                            />
                        ) : (
                            <NoDataPlaceholder
                                icon="file alternate outline"
                                text="No available Courses"
                            >
                                <p>All Courses for selected tenant and year are already assigned to some kit.</p>
                            </NoDataPlaceholder>
                        )}
                    </div>
                </React.Fragment>
                }
            </div>
        );
    }
}

const dispatchToProps = dispatch => bindActionCreators({ onAssignVariant, onUnassignVariant }, dispatch);
export default compose(
    connect(
        state => ({
            assignedVariants: assignedVariants(state),
            availableVariants: availableVariants(state),
            kitId: kitId(state),
        }),
        dispatchToProps
    ),
    withAuth
)(CourseVariantAssignment);
