import React from "react";
import PropTypes from "prop-types";
import { Icon, List } from "semantic-ui-react";

import firebase from "../../../firebase";
import { convertDateIsoToLocal } from "../../../utils/dateUtils";
import { PublishedLessonProps, LessonVersionProps, LessonFileType } from "../PublisherProps";
import { compareByOrdering, convertDatasetToList } from "../../../utils/selectorUtils";
import { getVersionString } from "../../../utils/conversionUtils";

const getUserDisplayName = (user) => (
    user && (user.first_name || user.last_name)
        ? `${user.first_name || ""} ${user.last_name || ""}`.trim()
        : null
);

export const getFileDownloadUrl = (file) => (
    file && firebase.getFileURL(typeof file === "string" ? file : file.path)
);

const IconByType = {
    folder: "folder",
    group: "folder outline",
    file: "file outline",
    text: "file alternate outline",
    code: "file code outline",
};

const getIconOfListEntry = (type) => (type && IconByType[type]) || IconByType.file;

export const getListDescription = (doneAt, doneBy) => {
    const person = getUserDisplayName(doneBy);
    return doneAt || person ? [
        doneAt && convertDateIsoToLocal(doneAt),
        person && `by ${person}`,
    ].filter(Boolean).join(" ") : null;
};

class LessonFileEntry extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        info: PropTypes.string,
        type: PropTypes.string,
    }

    render() {
        const { name, href, info, type } = this.props;
        return (
            <List.Item>
                <List.Content>
                    <a href={href} title={info} target="_blank" rel="noopener noreferrer">
                        <Icon name={getIconOfListEntry(type)} />
                        {name}
                    </a>
                </List.Content>
            </List.Item>
        );
    }
}

class LessonFileGroup extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        info: PropTypes.string,
        icon: PropTypes.string,
        student: LessonFileType.isRequired,
        teacher: LessonFileType.isRequired,
        glossary: LessonFileType,
    };

    render() {
        const { name, icon, info, student, teacher, glossary } = this.props;
        return (
            <List.Item>
                <List.Icon name={icon || getIconOfListEntry("group")} />
                <List.Content>
                    <List.Header>{name}</List.Header>
                    <List.Description>
                        {info && <div>{info}</div>}
                        <List horizontal divided style={{
                            marginTop: ".5rem", // "em" doesn't work due horizontal list font-size is 0
                        }}>
                            {student && <LessonFileEntry
                                name="Student"
                                info="Content for student tablets"
                                type="text"
                                href={getFileDownloadUrl(student)}
                            />}
                            {teacher && <LessonFileEntry
                                name="Teacher"
                                info="Content for teacher tablets"
                                type="text"
                                href={getFileDownloadUrl(teacher)}
                            />}
                            {glossary && <LessonFileEntry
                                name="Glossary"
                                info="Glossary terms definitions (JSON)"
                                type="code"
                                href={getFileDownloadUrl(glossary)}
                            />}
                        </List>
                    </List.Description>
                </List.Content>
            </List.Item>
        );
    }
}

export default class LessonFileLinks extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
        /** Version info - creation time and user */
        info: LessonVersionProps.isRequired,
        /** Content */
        data: PublishedLessonProps.isRequired,
    }

    render() {
        const { name, icon, info, data } = this.props;
        const { content, hw, ps } = data || {};
        return (
            <List.Item>
                <List.Icon name={icon || getIconOfListEntry("folder")} />
                <List.Content>
                    <List.Header>{name} ({getVersionString(info.created_at)})</List.Header>
                    <List.Description>{getListDescription(info.created_at, info.createdBy)}</List.Description>
                    <List.List>
                        {content && <LessonFileGroup 
                            {...content}
                            name="Lesson Content"
                        />}
                        {hw && convertDatasetToList(hw).sort(compareByOrdering).map(({ key, value }, index) => (
                            <LessonFileGroup
                                key={`hw.${key}`} // We may have same set ID in homework & problem set.
                                {...value}
                                name={`Homework ${index + 1}`}
                                info={value.title}
                            />
                        ))}
                        {ps && convertDatasetToList(ps).sort(compareByOrdering).map(({ key, value }, index) => (
                            <LessonFileGroup
                                key={`ps.${key}`} // We may have same set ID in homework & problem set.
                                {...value}
                                name={`Problem Set ${index + 1}`}
                                info={value.title}
                            />
                        ))}
                    </List.List>
                </List.Content>
            </List.Item>
        );
    }
}
