/**
 * Formats the query string from given name and value.
 * 
 * If the value is array then string is built from "field[]=value_n"
 * joined by "&". Otherwise "field=value" is returned. The value is
 * URI-encoded.
 * 
 * @param {string} name name of query string parameter
 * @param {string|number|array} value value to be stringified
 * @returns {string|null} query string, null for undefined or null values
 */
export const formatQueryParam = (name, value) => {
    if (value === undefined || value === null) {
        return null;
    }
    if (Array.isArray(value)) {
        return value.length
            ? value.map(v => `${name}[]=${encodeURIComponent(v)}`).join("&")
            : null;
    }
    return `${name}=${encodeURIComponent(value)}`;
};
