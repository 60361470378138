import { TYPES } from "./FixRefs4ProblemsAndProblemSetsActions";

const initialState = {
    inProgress: false,
    progress: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.FIX_PROBLEMS_REFS:
            return { ...state, inProgress: true, progress: { lessons: { done: false, count: 0},  problemSets: { done: false, count: 0}, problems: { done: false, count: 0} } };
        case TYPES.FIX_PROBLEMS_REFS_FINISHED:
            return { ...state, inProgress: false };
        case TYPES.FIX_PROBLEMS_REFS_PROGRESS:
            return { ...state, progress: {...payload } };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
