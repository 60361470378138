import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";
import { toggleCustomInlineStyle } from "draftjs-utils";

import BookEditor from "../BookEditor";
import { selectAll, applyInlineStyles, removeInlineStyles, setTextAlign } from "../utils/utils";
import MultiCellEditorHelp from "../help/MultiCellEditorHelp";

// `draftjs-utils::toggleCustomInlineStyle` requires camelized words
// https://github.com/jpuri/draftjs-utils/blob/eca1508cf74c8b05ce7d3ab39ab28247cb254d5f/js/inline.js#L130
const customInlineStylesMap = {
    fontfamily: "fontFamily",
    fontsize: "fontSize",
    color: "color",
    bgcolor: "bgcolor",
};

class MultiCellEditor extends Component {
    static propTypes = {
        sharedToolbar: PropTypes.object.isRequired,
        selectedCells: PropTypes.object.isRequired,
        cellEditors: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.bookEditorRef = null;
        this.lastContentState = {
            blocks: [{
                key: "d88iq",
                text: "dummyText",
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
            }],
            entityMap: {},
        };
    }

    componentDidMount = () => {
        const editorState = this.bookEditorRef.getEditorState();
        const selection = editorState.getSelection().merge({
            focusOffset: "dummyText".length,
        });

        this.bookEditorRef.setEditorState(EditorState.acceptSelection(editorState, selection));
    };

    handleSetEditorRef = (bookEditorRef) => {
        this.bookEditorRef = bookEditorRef;
    };

    getStyles = (styleRanges) => {
        return styleRanges.map(({ style }) => style);
    };

    /**
     * Some inline styles (e.g. fontFamily, color, ...) are "composed" from two parts delimited by hyphen:
     * `styleType` and `styleValue`, e.g.: "color-#ce93d8". They cannot be simply applied, their previous value
     * has to be removed. It is handled by `draftjs-utils::toggleCustomInlineStyle`,
     * (see customInlineStylesMap in https://github.com/jpuri/draftjs-utils/blob/eca1508cf74c8b05ce7d3ab39ab28247cb254d5f/js/inline.js)
     */
    isComposedStyle = (style) => style.indexOf("-") !== -1;

    getStylesDiff = (prevStyles, nextStyles) => {
        const add = nextStyles.filter((style) => !this.isComposedStyle(style) && !prevStyles.includes(style));
        const remove = prevStyles.filter((style) => !this.isComposedStyle(style) && !nextStyles.includes(style));
        const composed = nextStyles.filter((style) => this.isComposedStyle(style) && !prevStyles.includes(style));
        return {
            add,
            remove,
            composed,
            hasDifference: add.length || remove.length || composed.length,
        };
    };

    handleChange = (contentState) => {
        const { selectedCells, cellEditors } = this.props;
        const { data, inlineStyleRanges } = contentState.blocks[0];
        const { data: prevData, inlineStyleRanges: prevInlineStyleRanges } = this.lastContentState.blocks[0];

        const prevStyles = this.getStyles(prevInlineStyleRanges);
        const nextStyles = this.getStyles(inlineStyleRanges);
        const diff = this.getStylesDiff(prevStyles, nextStyles);
        const prevTextAlign = prevData && prevData["text-align"];
        const nextTextAlign = data && data["text-align"];

        if (!diff.hasDifference && prevTextAlign === nextTextAlign) {
            return;
        }

        selectedCells.forEach((cellId) => {
            const editor = cellEditors[cellId];
            let editorState = editor.getEditorState();
            editorState = selectAll(editorState);
            editorState = removeInlineStyles(editorState, diff.remove);
            editorState = applyInlineStyles(editorState, diff.add);

            diff.composed.forEach((composedStyle) => {
                const [styleName, styleValue] = composedStyle.split("-");
                editorState = toggleCustomInlineStyle(editorState, customInlineStylesMap[styleName], styleValue);
            });

            if (prevTextAlign !== nextTextAlign) {
                editorState = setTextAlign(editorState, nextTextAlign);
            }

            editor.setEditorState(editorState);
        });

        this.lastContentState = contentState;
    };

    render() {
        const { sharedToolbar } = this.props;
        return (<div className="multiCellEditor">
            <BookEditor
                features={["BOLD", "ITALIC", "UNDERLINE", "TEXTALIGN", "FONT", "FONTSIZE", "COLOR", "HELP"]}
                initialContentState={this.lastContentState}
                sharedToolbar={sharedToolbar}
                focusOnMount={true}
                onSetEditorRef={this.handleSetEditorRef}
                onChange={this.handleChange}
                contextHelp={MultiCellEditorHelp}
            />
        </div>);
    }
}

export default MultiCellEditor;
