import { all, takeLatest, put, select } from "redux-saga/effects";
import { TYPES, onSelectYear, onImportYearsDone } from "./YearsActions";
import { getAllYears, getApiCallsByExistence } from "./YearsSelectors";
import { sagaToastError } from "../component/toast";
import { authUser } from "../auth/authSelectors";
import { onSelectSection } from "../External/UserCourses/component/SectionListItem/SectionListItemActions";
import { client } from "../feathersjs/client";
import services from "../feathersjs/services";

function* storeSelectedYear({ payload }) {
    const auth = yield select(authUser);
    localStorage.setItem(`selectedYearId_${auth.tenantId}`, payload);
    yield put(onSelectSection(null));
}

function* detectDefaultYear() {
    const auth = yield select(authUser);
    const years = yield select(getAllYears);
    // if user is authenticated and has tenant, try to load last selected year of that tenant
    const selectedYearId =
        auth && auth.tenantId && localStorage.getItem(`selectedYearId_${auth.tenantId}`)
            ? parseInt(localStorage.getItem(`selectedYearId_${auth.tenantId}`))
            : null;
    // selected year is not stored in local browser storage
    if (selectedYearId === null) {
        // find default yearId
        let defaultYear = null;
        years.map(year => {
            if (year.default === 1 && year.valid) {
                defaultYear = year.id;
            }
        });
        yield put(onSelectYear(defaultYear));
    } else {
        yield put(onSelectYear(selectedYearId));
    }
}

// TODO - this should do the backend automatically
function* clearCurrentDefault() {
    try {
        const defaultYears = yield client.service("year").find({ query: { default: 1 }});
        const count = defaultYears.length;

        if (count) {
            const patches = defaultYears.map(({ id }) => {
                return client.service("year").patch(id, { default: 0 });
            });
            yield Promise.all(patches);

            if (count > 1) {
                // eslint-disable-next-line no-console
                console.error(`[Years] Error: Inconsistent data. More years (${count}) marked as "default". Fixed ...`);
            }
        }
    }
    catch (err) {
        sagaToastError("Removing flag \"default\" from from Years failed", err);
    }
}

function* createYear({ payload }) {
    try {
        if (payload.default) {
            yield clearCurrentDefault();
        }
        yield client.service("year").create(payload);
    }
    catch (err) {
        sagaToastError(`Creating year "${payload.name}" failed.`, err);
    }
}

function* updateYear({ yearId, payload }) {
    try {
        if (payload.default) {
            yield clearCurrentDefault();
        }
        yield client.service("year").patch(yearId, payload);
    }
    catch (err) {
        sagaToastError(`Updating year "${payload.name}" failed.`, err);
    }
}

function* importYears({ payload }) {
    try {
        const years = yield client.service("year").find({ query: { $sort: { name: 1 } }});
        const hasDefault = payload.find((year) => year.default);

        if (hasDefault) {
            yield clearCurrentDefault();
        }

        yield Promise.all(
            getApiCallsByExistence(years, payload).map((year) => client.service("year").create(year))
        );
    }
    catch (err) {
        sagaToastError("Importing years from XLS failed.", err);
    }

    yield put(onImportYearsDone());
}

export default function* YearsSaga() {
    yield all([
        takeLatest(services.year.types.SERVICES_YEAR_FIND_FULFILLED, detectDefaultYear),
        takeLatest(TYPES.YEARS_YEAR_SELECTED, storeSelectedYear),
        takeLatest(TYPES.YEAR_CREATE, createYear),
        takeLatest(TYPES.YEAR_UPDATE, updateYear),
        takeLatest(TYPES.YEARS_IMPORT, importYears),
    ]);
}
