/**
 * Renders (via MathJax or Wiris according to MathML content) all given MathML formulas.
 * MathJax renders into a standalone document inside an iframe.
 * Wiris renders via API call (HTTP GET).
 */
import axios from "axios";
import { getMathJaxRenderer } from "../bookEditor/utils/domUtils";

class FormulaRenderer {
    /**
     * @param {Array<string>} formulas List of MathML formulas.
     */

    TIMEOUT = 10000; // in [ms]

    constructor(formulas) {
        this.formulasForWiris = formulas.filter(this.useWirisRendering);
        this.formulasForMathJax = formulas.filter((formula) => !this.formulasForWiris.includes(formula));
        this.mathJaxRenderer = getMathJaxRenderer();
    }

    useWirisRendering = (formula) => {
        return formula.indexOf("<mlongdiv") !== -1 || formula.indexOf("<mstack") !== -1;
    };

    /**
     * Promise.
     * Check every `delay` milliseconds if the iframe with mathJaxRenderer is loaded (ready).
     * When the total time of checking reaches `timeout`, the promise is rejected.
     *
     * @param {number} delay Checking interval [ms].
     * @param {number} timeout Total timeout [ms] (to avoid dead-lock).
     * @returns {Promise}
     */
    waitForIframeLoaded = (delay, timeout) =>
        new Promise((resolve, reject) => {
            const start = Date.now();
            const checkLoaded = () => {
                if (this.mathJaxRenderer.isLoaded) {
                    resolve();
                } else if (Date.now() - start > timeout) {
                    reject("Math formula renderer (MathJax) is not properly initialized. Try to reload the page (all unsaved changes will be lost!).");
                } else {
                    setTimeout(checkLoaded, delay);
                }
            };
            setTimeout(checkLoaded, 0);
        });

    processFormulasByMathJax = () => {
        const iframeWindow = this.mathJaxRenderer.contentWindow;
        // TODO: try to remove no-async-promise-executor error instead of disabling it.
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.mathJaxRenderer.isLoaded) {
                    await this.waitForIframeLoaded(100, this.TIMEOUT);
                }
                this.cleanUp();
                iframeWindow.createFormulaElements(this.formulasForMathJax);
                iframeWindow.processFormulas(resolve);
            } catch (err) {
                reject(err);
            }
        });
    };

    processFormulasByWiris = async () => {
        const formulas = this.formulasForWiris;
        const promises = formulas.map((formula) => (
            axios.get("https://www.wiris.net/demo/editor/render.svg?mml=" + encodeURIComponent(formula))
        ));
        const dummyDiv = document.createElement("div");
        const svgList = await Promise.all(promises);

        return svgList.reduce((acc, { data }, index) => {
            /**
             * We stores the original MathML into `data-mathml` attribute for formulas generated by MathJax
             * to be able to reconstruct formula from HTML (e.g. C&P from preview).
             * Wiris stores it into HTML comment, which is not handy. So `data-mathml` will be used too.
             */
            dummyDiv.innerHTML = data;
            const svgElement = dummyDiv.firstChild;
            svgElement.setAttribute("data-mathml", formulas[index]);
            acc[formulas[index]] = dummyDiv.innerHTML;
            return acc;
        }, {});
    };

    processFormulas = async () => {
        let mathJaxResult = {};
        let wirisResult = {};

        if (this.formulasForMathJax.length) {
            mathJaxResult = await this.processFormulasByMathJax();
        }

        if (this.formulasForWiris.length) {
            wirisResult = await this.processFormulasByWiris();
        }

        return Object.assign(mathJaxResult, wirisResult);
    }

    cleanUp = () => {
        if (this.mathJaxRenderer && this.mathJaxRenderer.contentDocument) {
            this.mathJaxRenderer.contentDocument.body.innerHTML = "";
        }
    }
}

export default FormulaRenderer;
