import TimeAgo from "javascript-time-ago";
import TimeAgoEnglish from "javascript-time-ago/locale/en";

TimeAgo.addLocale(TimeAgoEnglish);

export const getTimeAgo = () => new TimeAgo("en-us");

/**
 * Take date in ISO format and convert to localized string for display.
 * @param {} isoDate
 */
export const convertDateIsoToLocal = isoDate => {
    if (isoDate == null) return "N/A";

    var localDate = new Date(isoDate);
    var localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
    });

    var localTimeString = localDate.toLocaleTimeString("en-US");

    return localDateString + " " + localTimeString;
};

export const compareDates = (date1, date2) => {
    return date2 == null || new Date(date1) > new Date(date2);
};

/**
 * Return relative time
 */
export function timeSince(timeStamp) {
    let date = new Date(timeStamp);
    var now = new Date(),
        secondsPast = (now.getTime() - date.getTime()) / 1000;
    if (secondsPast < 60) {
        return parseInt(secondsPast) + "s";
    }
    if (secondsPast < 3600) {
        return parseInt(secondsPast / 60) + "m";
    }
    if (secondsPast <= 86400) {
        return parseInt(secondsPast / 3600) + "h";
    }
    if (secondsPast > 86400) {
        return date.toLocaleDateString(undefined, {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
    }
}
