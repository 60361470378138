import { TYPES } from "./outlinesActions";

// The initial state of the ExampleSimple
const initialState = {
    selectedOutlineId: null,
    showLessonProperties: false,
    lessonDataToEdit: null,
    editing: false,
    openedEditorId: "",
    publishingProgress: {},
    publishingMessage: ""
};

const reducer = (state = initialState, action) => {
    const statusUpdates = {};

    switch (action.type) {
        case TYPES.OUTLINES_OUTLINE_SELECT:
            return { ...state, selectedOutlineId: action.selectedOutlineId };
        case TYPES.OUTLINES_LESSON_SHOW_PROPERTIES:
            return { ...state, showLessonProperties: action.showPropsDialog, lessonDataToEdit: action.lessonData, editing: action.editing };
        case TYPES.OUTLINES_EDITING_TEXT:
            return { ...state, openedEditorId: state.openedEditorId ? "" : action.editableTextId || true };
        case TYPES.OUTLINE_PUBLISH_LESSONS:
            Object.keys(action.payload.lessons).forEach(lesson => {
                statusUpdates[lesson] = "ready";
            });
            return {...state,
                publishingProgress: statusUpdates,
                publishingMessage: `Please wait, 0 of ${Object.keys(action.payload.lessons).length} lessons published/released...`
            };
        case TYPES.OUTLINE_MASS_OPERATION_UPDATE_PROGRESS:
            return {...state,
                publishingProgress: {...state.publishingProgress, [action.payload.lessonId]: action.payload.status },
                publishingMessage: action.payload.totalCompleted == action.payload.totalCount ?
                    `Finished publishing/releasing ${Object.keys(state.publishingProgress).length} lessons.` :
                    `Please wait, ${action.payload.totalCompleted} of ${action.payload.totalCount} lessons published/released...`
            };
        case TYPES.OUTLINE_MASS_OPERATION_RESET: 
            return {...state,
                publishingProgress: {},
                publishingMessage: ""
            };
        default:
            return state;
    }
};

export default reducer;