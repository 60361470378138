import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import CourseVariantList from "./CourseVariantList";
import { firebaseConnect } from "react-redux-firebase";
import { getCourseVariantsAsArray, isLoading } from "./courseVariantsSelectors";
import { onCourseVariantLoad, onRedirectToKit } from "./CourseVariantsActions";
import { getSelectedYear } from "../../Year/YearsSelectors";

const mapStateToProps = function(state) {
    return {
        courseVariants: getCourseVariantsAsArray(state),
        selectedYear: getSelectedYear(state),
        isLoading: isLoading(state),
    };
};

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            onCourseVariantLoad,
            onRedirectToKit,
        },
        dispatch
    );

export default compose(
    firebaseConnect(["kits_data"]),
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(CourseVariantList);
