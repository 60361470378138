const TYPES = {
    EDIT_AKIT_SYNC: "AKits/Edit/onSync",
    EDIT_AKIT_GET_ALL_COURSE_VARIANTS: "AKits/Edit/GetAllCourseVariants",
    EDIT_AKIT_SET_ALL_COURSE_VARIANTS: "AKits/Edit/SetAllCourseVariants",
    EDIT_AKIT_GET_SYNC_STATUS: "AKits/Edit/GetSyncStatus",
    EDIT_AKIT_SET_SYNC_STATUS: "AKits/Edit/SetSyncStatus"
};

const onSync = (payload) => ({
    type: TYPES.EDIT_AKIT_SYNC,
    payload
});

const onAllCourseVariantsLoad = () => ({
    type: TYPES.EDIT_AKIT_GET_ALL_COURSE_VARIANTS
});

const onAllCourseVariantsSet = (payload) => ({
    type: TYPES.EDIT_AKIT_SET_ALL_COURSE_VARIANTS,
    payload
});

const onGetSyncStatus = (payload) => ({
    type: TYPES.EDIT_AKIT_GET_SYNC_STATUS,
    payload
});

const onSetSyncStatus = (payload) => ({
    type: TYPES.EDIT_AKIT_SET_SYNC_STATUS,
    payload
});

export {
    TYPES,
    onSync,
    onAllCourseVariantsLoad,
    onAllCourseVariantsSet,
    onGetSyncStatus,
    onSetSyncStatus,
};
