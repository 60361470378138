const TYPES = {
    FIX_PROBLEMS_REFS: "FIX_PROBLEMS_REFS",
    FIX_PROBLEMS_REFS_FINISHED: "FIX_PROBLEMS_REFS_FINISHED",
    FIX_PROBLEMS_REFS_PROGRESS: "FIX_PROBLEMS_REFS_PROGRESS",
};

const onFixProblemsRefs = () => ({
    type: TYPES.FIX_PROBLEMS_REFS,
});

const onUpdateProgress = payload => ({
    type: TYPES.FIX_PROBLEMS_REFS_PROGRESS,
    payload,
});

const onFinished = () => ({
    type: TYPES.FIX_PROBLEMS_REFS_FINISHED,
});

export {
    TYPES,
    onFixProblemsRefs,
    onFinished,
    onUpdateProgress,
};
