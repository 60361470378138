import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Divider } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";
import { connect } from "react-redux";
import "./CreateSyllabusDialog.scss";
import { onCreateSyllabus } from "../SyllabiListActions";
import { getSipiTemplatesForCloningAsOptions } from "../SyllabiListSelectors";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";

class CreateSyllabusDialog extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        onCreateSyllabus: PropTypes.func,
        templateOptions: PropTypes.array,
    }

    state = {
        name: "",
        isCustom: true,
        isLinked: true,
        selectedTemplate: null,
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
        if ("selectedTemplate" === name) {
            const { templateOptions } = this.props;
            if (templateOptions) {
                const filteredTemplates = templateOptions.filter(t => t.value === value);
                if (1 === filteredTemplates.length) {
                    this.setState({ name: filteredTemplates[0].text });
                }
            }
        }
    }

    handleCreate = () => {
        const { onCreateSyllabus, onClose } = this.props;
        const { name, selectedTemplate, isCustom, isLinked } = this.state;
        onCreateSyllabus({ name, templateId: !isCustom ? selectedTemplate : null, isLinked });
        onClose();
    }
    handleToggle = (e, { name, checked }) => {
        if (checked) {
            if ("custom" === name || "fromTemplate" === name) {
                this.setState({ isCustom: "custom" === name });
            } else {
                this.setState({ isLinked: "linked" === name });
            }
        }
    }

    render() {
        const { onClose, templateOptions } = this.props;
        const { name, isCustom, selectedTemplate, isLinked } = this.state;
        const saveDisabled = 0 === name.trim().length || (!isCustom && !selectedTemplate);
        return (
            <Modal
                size="tiny"
                open
            >
                <Modal.Header>Create iPlanner</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleCreate}>
                        <Form.Group grouped>
                            <label>Create</label>
                            <Form.Radio
                                label="An empty iPlanner"
                                checked={isCustom}
                                onChange={this.handleToggle}
                                name="custom"
                            />
                            <Form.Radio
                                label="iPlanner from the template"
                                checked={!isCustom}
                                onChange={this.handleToggle}
                                name="fromTemplate"
                            />
                            <Form.Select
                                required
                                name="selectedTemplate"
                                loading={undefined === templateOptions}
                                options={templateOptions || []}
                                value={selectedTemplate}
                                onChange={this.handleChange}
                                placeholder="Select template"
                                disabled={isCustom}
                            />
                            {!isCustom && <Form.Group inline >
                                <label>Link to template</label>
                                <Form.Radio
                                    label="Yes"
                                    checked={isLinked}
                                    onChange={this.handleToggle}
                                    name="linked"
                                    disabled={isCustom}
                                />
                                <Form.Radio
                                    label="No, copy data from template"
                                    checked={!isLinked}
                                    onChange={this.handleToggle}
                                    name="generated"
                                    disabled={isCustom}
                                />
                            </Form.Group>}

                        </Form.Group>

                        <Divider hidden />

                        <Form.Input
                            required
                            name="name"
                            label="iPlanner Name"
                            value={name}
                            onChange={this.handleChange}
                            placeholder="Fill iPlanner name"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onCancel={onClose}
                        onSave={this.handleCreate}
                        submitText="Create"
                        saveDisabled={saveDisabled}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default compose(
    firebaseConnect(["/modules/list", "tenant_modules", "/sipi_templates/info"]),
    connect(
        (state) => ({
            templateOptions: getSipiTemplatesForCloningAsOptions(state),
        }),
        { onCreateSyllabus }
    )
)(CreateSyllabusDialog);
