import React from "react";
import PropTypes from "prop-types";
import { Modal, Dropdown, Form, Icon, Popup, Label } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import _ from "lodash";

const prohibitedChars = ".$#[]/";
const sortedAndUnique = (options) => _.uniqBy(_.sortBy(options, ["key"]), "key");
const convertToOption = (tag, newTag = false) => ({ key: tag, value: tag, text: tag, description: newTag && "New tag" || null });

const addTagToOptions = (tag) => (state) => ({
    options: sortedAndUnique([ ...state.options, convertToOption(tag, true) ])
});

export default class TagsEditor extends React.PureComponent {
    static propTypes = {
        tagNames: PropTypes.array.isRequired,
        open: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        tagsOptions: PropTypes.array,
        tagsList: PropTypes.array,
    };

    state = {
        options: [],
        value: [],
    };

    generateOptions = () => {
        const { tagNames = [], tagsList = [], tagsOptions = []} = this.props;
        const options = sortedAndUnique(
            tagNames.map(convertToOption).concat(tagsList.map(convertToOption)).concat(tagsOptions)
        );
        this.setState({ options });
    }

    handleAddItem = (e, { value }) => {
        const tag = value && value.trim();
        if (tag) {
            this.setState(addTagToOptions(tag));
        } else {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    componentDidMount() {
        this.generateOptions();
        this.setState({ value: this.props.tagNames });
        
    }

    handleKeyDown = (e) => {
        if (!e.metaKey && prohibitedChars.indexOf(e.key) !== -1) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    handleChange = (e, {value}) => {
        this.setState({ value: value.map(v => v.trim()).filter(Boolean) });
    }

    handleSave = (e) => {
        e.stopPropagation();
        this.props.onSave(this.state.value);
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    render() {
        const { open, onCancel } = this.props;
        const { options, value } = this.state;

        return (
            <Modal open={open} size="small" onClose={onCancel} onClick={this.handleStopPropagation} >
                <Modal.Header>Tags Editor</Modal.Header>
                
                <Modal.Content>
                    <Form onSubmit={this.handleSave}>
                        <Form.Field>
                            <label>
                                Please select the tags from the list or create a new tag by entering its name:
                            </label>
                            <Dropdown
                                fluid
                                multiple
                                selection
                                search
                                closeOnChange
                                allowAdditions
                                additionLabel="Add tag "
                                noResultsMessage="No additional tags available."
                                onAddItem={this.handleAddItem}
                                options={options}
                                value={value}
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                            />
                            <Popup 
                                trigger={<Icon
                                    name="question circle outline"
                                    color="grey"
                                />}
                                wide
                            >
                                The following characters are not allowed in the name of the tag: <Label horizontal color="blue"> . $ # [ ] / </Label>
                            </Popup>

                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onSave={this.handleSave}
                        onCancel={onCancel}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
