import convertor from "../component/seamlessEditor/bookEditor/pastedTextConvertor";
import { EditorState, convertToRaw } from "draft-js";
import FormulaRenderer from "../component/seamlessEditor/export/FormulaRenderer";
import firebase from "../firebase";

/**
 * This function asynchronously converts HTML snippet and converts it as RawContentState using
 * pastedTextConvertor function. It's the same function we use to convert copy&pasted
 * text into DraftJS editor in browser.
 *
 * The function handles embedded MathML images correctly and convert them into
 * DraftJS entities.
 *
 * Returns a Promise.
 */
export const convertToRawContentState = html => {
    return new Promise((resolve, reject) => {
        convertor(editorState => {
            let rawContentState = convertToRaw(editorState.getCurrentContent());
            if (rawContentState) {
                resolve(rawContentState);
            } else {
                reject();
            }
        })("", html, EditorState.createEmpty());
    });
};

/**
 * convert HTML string to HTML Element
 * @param {*} html
 */
function htmlToElement(html) {
    var template = document.createElement("template");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

export const mathMlToSvg = async html => {
    if (!html) return null;

    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(html, "text/html");

    // get a static copy of element list. This is important
    // because getElementsByTagName returns live list and we can't
    // have a list over which we iterate to be changing
    let formulas = [...parsedHtml.querySelectorAll("math")];
    let processedFormulas = [];

    const formulasData = formulas.map(data => data.outerHTML);

    if (formulasData.length) {
        const formulaRenderer = new FormulaRenderer(formulasData);
        processedFormulas = await formulaRenderer.processFormulas();
    }

    for (let formula of formulas) {
        let newElement = htmlToElement(processedFormulas[formula.outerHTML]);
        formula.parentNode.replaceChild(newElement, formula);
    }
    return parsedHtml.body.innerHTML;
};

/**
 * Convert bytes to human readable format. It supports both binary or decimal convention.
 * @param {*} bytes
 * @param {boolean} si if true it uses decimal conversion (divide by 1000). False use binary (divide by 1024).
 */
export const humanFileSize = (bytes, si) => {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }
    var units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
};

/**
 * Replace all relative storage links (spork://) with actual storage URL
 * @param {*} html
 */
export const replaceRelLinks = (html) => {
    return html.replace(/spork:\/\//g, firebase.getStorageURL());
};

/**
 * Convert timestamp to version string <major>.<minor>.<patch>.
 * @param {*} doneAt
 */
export const getVersionString = (doneAt) => {
    const date = new Date(doneAt);
    return `${date.getUTCFullYear()}.${(date.getUTCMonth() + 1) * 100 + date.getUTCDate()}.${date.getUTCHours() * 10000 + date.getUTCMinutes() * 100 +  date.getUTCSeconds()}`;
};
