import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { widgetTemplates, getWidgetTemplatesInfo } from "../WidgetTemplates/WidgetTemplatesSelectors";
import * as WidgetLibraryActions from "./WidgetLibraryActions";
import {
    filteredWidgets,
    usedTags,
    getSelectedWidget,
    editing,
    getOrderedItemsList,
    getWidgetsInfo,
    getUsedTopicOptions,
    loadingWidgetData,
    isDirty,
    getPotentiallyUnknownWidgets,
    selectedWidgetId,
} from "./WidgetLibrarySelectors";
import { getTopicAsList } from "../../Topics/topicsSelectors.js";
import { onSetTagFilter, onSetModuleFilter as onSetSnippetModuleFilter } from "../Snippets/SnippetsManagerActions";
import { onSetGlossaryPreferencesByOutline } from "../Glossary/GlossaryActions";
import { onSetOutline } from "../LessonLink/LessonLinkActions";
import { withAuth } from "../../component/withAuth";
import WidgetDetail from "./WidgetDetail";

const mapStateToProps = function(state, props) {
    return {
        widgetsInfo: getWidgetsInfo(state),
        widgets: filteredWidgets(state),
        usedTags: usedTags(state),
        topics: getTopicAsList(state),
        widgetTemplates: widgetTemplates(state),
        templatesInfo: getWidgetTemplatesInfo(state),
        selectedWidget: getSelectedWidget(state, props),
        selectedWidgetId: selectedWidgetId(state, props),
        potentiallyUnknownWidgets: getPotentiallyUnknownWidgets(state),
        editing: editing(state),
        orderedItemList: getOrderedItemsList(state, props),
        usedTopicOptions: getUsedTopicOptions(state),
        loadingWidgetData: loadingWidgetData(state, props),
        isDirty: isDirty(state),
    };
};

const dispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...WidgetLibraryActions,
            onSetTagFilter,
            onSetSnippetModuleFilter,
            onSetGlossaryPreferencesByOutline,
            onSetOutlineForLessonLinks: onSetOutline,
        },
        dispatch
    );

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    withAuth
)(WidgetDetail);
