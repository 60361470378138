const TYPES = {
    AKITS_LIST_SEARCH_CHANGED: "AKits/AKitsList/searchFilter",
};

const onChangeFilter = (payload) => ({
    type: TYPES.AKITS_LIST_SEARCH_CHANGED,
    payload
});

export {
    TYPES,
    onChangeFilter,
};