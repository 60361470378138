import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Topic extends PureComponent {
    static propTypes = {
        topic: PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            order: PropTypes.number,
            invalid: PropTypes.bool,
        }),
    };

    render() {
        const { topic } = this.props;
        return topic && !topic.invalid ? (
            <span>{topic.name}</span>
        ) : (
            <span style={{ color: "red" }}>Invalid topic</span>
        );
    }
}
