import React from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import cx from "classnames";
import { Header, Form, Popup, Modal, Segment, Button, Message, Icon } from "semantic-ui-react";
import { SaveCancelButtons, ConfirmDeletePopup } from "bmd-react";

import BookEditor from "../BookEditor";
import { getSelectionAsReviewSource, createReview, acceptReview, removeReview } from "./reviewUtils";
import { getContextPropsForPopup, getDimmer } from "../utils/domUtils";
import { stopPropagation, swallowEvent } from "../utils/utils";

import "./reviewPopup.scss";

const REVIEW_EDITOR_OPEN_CLASS = "review-editor-opened";

class ReviewPopup extends React.Component {

    static propTypes = {
        contextNode: PropTypes.object,
        editorState: PropTypes.object.isRequired,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        entityKey: PropTypes.string,
        sharedToolbar: PropTypes.object.isRequired,
        setEditorState: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        isListItem: PropTypes.bool.isRequired,
    };

    state = {
        isOpened: false,
        contextProps: {},
        positionX: 0,
        positionY: 0,
    };

    setDimmerSpaceVar = () => {
        document.body.style.setProperty("--dimmerSpace", document.documentElement.scrollHeight + "px");
    };

    componentDidMount = () => {
        // Notice that entityKey is taken from props only here as entry information.
        // It is provided by decorator, but not by toolbar button / keyboard short-cut.
        // It is resolved inside by getSelectionAsReviewSource from the currentSelection if doesn't exist.
        const { editorState, contextNode, disableParentEditor, entityKey: propsEntityKey, sharedToolbar } = this.props;
        const { originalRaw, originalHtml, raw, comment, entityKey, reviewMetaData } = getSelectionAsReviewSource(editorState, propsEntityKey);
        let contextProps = getContextPropsForPopup(contextNode);
        const scrollAreaElement = document.getElementsByClassName("no-js");
        if (scrollAreaElement.length != 0) {
            //update coordinates based on scroll in the area
            contextProps.vOffset = contextProps.vOffset - scrollAreaElement[0].scrollTop;
        }
        document.body.classList.add(REVIEW_EDITOR_OPEN_CLASS);

        this.setState({
            contextProps,
            entityKey,
            originalRaw,
            originalHtml,
            raw,
            comment,
            reviewMetaData,
        }, () => {
            disableParentEditor();
            this.setState({ isOpened: true }, () => {
                setTimeout(()=> {
                    // It has to be deferred ".reviewPopup" is re-positioned within modal
                    sharedToolbar.adjustOffset();
                }, 0);
            });
        });
    };

    componentWillUnmount = () => {
        document.body.classList.remove(REVIEW_EDITOR_OPEN_CLASS);
        document.body.style.removeProperty("--dimmerSpace");
    };

    updateDom = () => {
        this.setDimmerSpaceVar();
        const dimmer = getDimmer();
        dimmer && dimmer.classList.add("reviewPopup");
    };

    handleChange = (contentState) => {
        this.setState({ raw: contentState });
    };

    handleCommentChange = (e, { value }) => {
        this.setState({ comment: value });
    }

    handleCreateReview = (e) => {
        e.stopPropagation();
        const { editorState } = this.props;
        const { originalRaw, originalHtml, raw, comment, entityKey, reviewMetaData } = this.state;
        const newEditorState = createReview(editorState, entityKey, {originalRaw, originalHtml, raw, comment, reviewMetaData });
        this.applyChangesAndClose(newEditorState);
    };

    handleAcceptReview = (e) => {
        e.stopPropagation();
        const { editorState } = this.props;
        const { entityKey, raw, reviewMetaData } = this.state;
        const newEditorState = acceptReview(editorState, entityKey, raw, reviewMetaData);
        this.applyChangesAndClose(newEditorState);
    };

    handleRemoveReview = (callback) => {
        const { editorState } = this.props;
        const { entityKey, originalRaw, reviewMetaData } = this.state;
        const newEditorState = removeReview(editorState, entityKey, originalRaw, reviewMetaData);
        callback();
        this.applyChangesAndClose(newEditorState);
    };

    applyChangesAndClose = (editorState) => {
        const { setEditorState, onDirty } = this.props;
        setEditorState(editorState);
        onDirty();
        this.handleClose();
    };

    handleClose = () => {
        const { onClose, enableParentEditor } = this.props;
        this.setState({ isOpened: false });
        enableParentEditor();
        onClose();
    };

    handleDragStart = (e, data) => {
        const { x, y } = data;
        swallowEvent(e);
        this.props.sharedToolbar.hide();
        this.setState({ positionX: x, positionY: y });
    };

    handleDragStop = (e, data) => {
        const { x, y } = data;
        swallowEvent(e);
        this.setState({ positionX: x, positionY: y }, () => {
            const { sharedToolbar } = this.props;
            sharedToolbar.adjustOffset();
            sharedToolbar.show();
            this.setDimmerSpaceVar();
        });
    };

    handleMoveBack = () => {
        // setting `position` internally (react-draggable) fires handleDragStart & handleDragStop
        this.setState({ positionX: 0, positionY: 0 }, () => this.props.sharedToolbar.adjustOffset());
    };

    handleSetEditorRef = (editorRef) => {
        this.editorRef = editorRef;
    };

    handleFocusEditor = (e) => {
        swallowEvent(e);
        this.editorRef && this.editorRef.setFocus();
    };

    render() {
        const { isOpened, originalRaw, raw, comment, contextProps, entityKey, positionX, positionY } = this.state;
        const { sharedToolbar, reviewMode, isListItem } = this.props;
        const { node, pos, hOffset, vOffset } = contextProps;
        const isRepositioned = Boolean(positionX || positionY);

        if (!reviewMode && !entityKey) {
            return (
                <Popup
                    className="reviewPopup"
                    context={node}
                    position={pos}
                    horizontalOffset={hOffset}
                    verticalOffset={vOffset}
                    open={isOpened}
                    keepInViewPort={false}
                    onMouseDown={stopPropagation}
                    onClose={this.handleClose}
                >
                    <Popup.Content>
                        <Message warning floating content="You cannot create a new review." onDismiss={this.handleClose}/>
                    </Popup.Content>
                </Popup>
            );
        }
        return (<Modal basic open={isOpened} onMouseDown={stopPropagation}>
            <Draggable handle=".ddHandle"
                enableUserSelectHack={false}  // https://github.com/mzabriskie/react-draggable/issues/384
                onStart={this.handleDragStart}
                onStop={this.handleDragStop}
                position={{ x: positionX, y: positionY }}
                // when `updateDom` was fired from <Modal ref>, its Dimmer was not in DOM yet.
                // Maybe caused by <Draggable>, because FormulaEditor works with <Modal ref>.
                ref={this.updateDom}
            >
                <Popup
                    className={cx("reviewPopup", "ddHandle", { repositioned: isRepositioned })}
                    context={node}
                    position={pos}
                    horizontalOffset={hOffset}
                    verticalOffset={vOffset}
                    open={isOpened}
                    keepInViewPort={false}
                    onMouseDown={stopPropagation}
                >
                    <Popup.Content>
                        <Header dividing>
                            Review change
                            <div className="pinBack" onClick={this.handleMoveBack} title="Move back">
                                <Icon name="thumbtack" size="small" fitted />
                            </div>
                        </Header>
                        <Form onMouseDown={stopPropagation} onClick={stopPropagation}>
                            <Form.Field>
                                <label>Updated text after the review</label>
                                <Segment basic className="scrollableArea" onClick={this.handleFocusEditor}>
                                    <BookEditor
                                        features={[  // TODO: it has to have the same features as the parent editor except Review
                                            "BOLD", "ITALIC", "NORMAL", "H1", "H2", "H3", "UL", "OL", "MATH", "MATHTEXT", "SNIPPET",
                                            "TABLE", "TEXTALIGN", "FONT", "FONTSIZE", "IMG", "GLOSSARY", "COLOR", "HELP"
                                        ]}
                                        initialContentState={raw || originalRaw}
                                        onChange={this.handleChange}
                                        sharedToolbar={sharedToolbar}
                                        readOnly={!reviewMode}
                                        disableAddRemoveListItem={isListItem}
                                        onSetEditorRef={this.handleSetEditorRef}
                                    />
                                </Segment>
                            </Form.Field>
                            <Form.Field>
                                <label>Reviewer&apos;s comment</label>
                                <Form.TextArea onChange={this.handleCommentChange} defaultValue={comment}
                                    placeholder="Leave a note to the author about the change"
                                    disabled={!reviewMode}
                                />
                            </Form.Field>
                        </Form>
                        <div style={{ marginTop: "14px" }}>
                            {reviewMode
                                ? <SaveCancelButtons onSave={this.handleCreateReview} onCancel={this.handleClose} />
                                : <div className="saveCancelButtons">
                                    <Button content="Accept" size="small" color="green" onClick={this.handleAcceptReview} />
                                    <Button content="Cancel" size="small" onClick={this.handleClose} />
                                </div>
                            }
                            {entityKey && <ConfirmDeletePopup
                                trigger={<Button size="small" content={reviewMode ? "Remove" : "Reject"} negative title="Remove this review change and keep the original version"/>}
                                onConfirm={this.handleRemoveReview}
                                whatText="the review change"
                            />
                            }
                        </div>
                    </Popup.Content>
                </Popup>
            </Draggable>
        </Modal>);
    }
}

export default ReviewPopup;
