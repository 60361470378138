import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

const ObjectiveType = ({ text, ...otherProps }) => {
    return <Label horizontal content={text} {...otherProps} />;
};

ObjectiveType.propTypes = {
    text: PropTypes.string
};

export default ObjectiveType;
