
import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "semantic-ui-react";
import EditableText from "../../../component/EditableText";
import memoize from "memoize-one";
import cx from "classnames";

import "./LessonObjective.scss";

export default class LessonObjective extends React.PureComponent {
    static propTypes = {
        objectiveText: PropTypes.string,
        onClick: PropTypes.func,
        onUpdate: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
    };
 
    state = { 
        activeIndex: 0,
        editMode: false,
    }

    generatePanels = memoize((text, canEdit) => [
        {
            key: "lessonObjective",
            title: "Lesson objective",
            content: { children: <EditableText 
                text={text} 
                allowEmpty
                textarea
                rows="5"
                placeholder="Enter lesson objective here..."
                emptyPlaceholder="No lesson objective"
                onEditation={this.handleEdit}
                onUpdate={this.handleUpdate}
                canEdit={canEdit}
            />
            },
        },
    ]);
 
    handleEdit = () => {
        const editMode = this.state.editMode;
        this.setState({ editMode: !editMode });
        this.props.onClick && this.props.onClick();
    }


    handleUpdate = (value) => this.props.onUpdate(value);

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
        this.props.onClick && this.props.onClick();
    }

    render() {
        const { objectiveText, canEdit } = this.props;
        const panels = this.generatePanels(objectiveText, canEdit);

        return <Accordion className={cx("lessonObjectiveAccordion", {"lo-text-edit": this.state.editMode})} fluid panels={panels} onTitleClick={this.handleClick}/>;
    }
}
