import { all, fork } from "redux-saga/effects";
import SyllabiListSaga from "./SyllabiList/SyllabiListSaga";
import SyllabusOutlineSaga from "./SyllabusOutline/SyllabusOutlineSaga";
import ResourceLinkSaga from "./component/ResourceLink/ResourceLinkSaga";
import AdvancedResourceSearchSaga from "./component/AdvancedResourceSearch/AdvancedResourceSearchSaga";
import SiPiTemplatesListSaga from "./SiPiTemplatesList/SiPiTemplatesListSaga";
import SiPiTemplatesSaga from "./SiPiTemplate/SiPiTemplateSaga";
import ResourcesSaga from "./Resources/ResourcesSaga";
import ResourceOwnerSaga from "./component/ResourceOwner/ResourceOwnerSaga";
import SiPiTestCreatorSaga from "./component/SiPiTestCreator/SiPiTestCreatorSaga";
import ResoucePublicFlagSaga from "./Resources/components/ResourcePublicFlag/ResourcePublicFlagSaga";

function* rootSaga() {
    yield all([
        fork(SyllabiListSaga),
        fork(SyllabusOutlineSaga),
        fork(ResourceLinkSaga),
        fork(AdvancedResourceSearchSaga),
        fork(SiPiTemplatesListSaga),
        fork(SiPiTemplatesSaga),
        fork(ResourcesSaga),
        fork(ResourceOwnerSaga),
        fork(SiPiTestCreatorSaga),
        fork(ResoucePublicFlagSaga),
    ]);
}

export default rootSaga;
