import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Divider } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";
import { getTenantModulesOptions, modulesList } from "../../../KitBuilder/Modules/ModulesSelectors";
import { onCreateTemplate } from "../SiPiTemplatesListActions";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import "./CreateTemplateDialog.scss";
import memoizeOne from "memoize-one";
import { getAKitsAsOptions, fetchKitsData } from "../../../KitBuilder/AKits/AKitsList/AKitsListSelectors";

class CreateTemplateDialog extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        onCreateTemplate: PropTypes.func,
        modulesOptions: PropTypes.array,
        isLoadingModules: PropTypes.bool,
        kitsOptions: PropTypes.array,
        kitsData: PropTypes.object,
    }

    state = {
        selectedModule: [],
        name: "",
        isCustom: true,
        selectedKit: null,
    }
    moduleOptions = [];

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
        if ("selectedKit" === name) {
            const { kitsOptions } = this.props;
            if (kitsOptions) {
                const filteredKits = kitsOptions.filter(k => k.value === value);
                if (1 === filteredKits.length) {
                    this.setState({ name: filteredKits[0].text, selectedModule: [] });
                }
            }
        }
    }

    handleCreate = () => {
        const { onCreateTemplate, onClose } = this.props;
        const { selectedModule, name, isCustom, selectedKit } = this.state;
        onCreateTemplate({ name, modules: isCustom ? selectedModule : this.moduleOptions.map(m => m.key), kit_id: !isCustom ? selectedKit : null });
        onClose();
    }
    handleToggle = (e, { name, checked }) => checked && this.setState({ isCustom: "custom" === name });

    prefilterModules = memoizeOne((kitsData, selectedKit, modulesOptions) => {
        if (selectedKit && kitsData && kitsData[selectedKit]) {
            const kit = kitsData[selectedKit];
            if (kit.m) {
                this.moduleOptions = modulesOptions.filter(({ key }) => !!kit.m[key]);
                return this.moduleOptions;
            } else {
                this.moduleOptions = [];
                return [];
            }
        }
        this.moduleOptions = modulesOptions;
        return modulesOptions;
    });

    render() {
        const { onClose, modulesOptions, isLoadingModules, kitsOptions, kitsData } = this.props;
        const { selectedModule, name, isCustom, selectedKit } = this.state;
        const saveDisabled = isCustom && 0 === selectedModule.length || 0 === name.trim().length || !isCustom && !selectedKit;
        const filteredModulesOptions = isCustom ? modulesOptions : this.prefilterModules(kitsData, selectedKit, modulesOptions);

        return (
            <Modal
                size="tiny"
                open
            >
                <Modal.Header>Create iPlanner Template</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleCreate}>
                        <Form.Group grouped>
                            <label>Create</label>
                            <Form.Radio
                                label="Empty template"
                                checked={isCustom}
                                onChange={this.handleToggle}
                                name="custom"
                            />
                            <Form.Radio
                                label="Template from existing Kit"
                                checked={!isCustom}
                                onChange={this.handleToggle}
                                name="generated"
                            />

                            <Form.Select
                                required
                                name="selectedKit"
                                loading={undefined === kitsOptions}
                                options={kitsOptions || []}
                                value={selectedKit}
                                onChange={this.handleChange}
                                placeholder="Select kit"
                                disabled={isCustom}
                            />

                        </Form.Group>
                        <Divider hidden />
                        <Form.Input
                            required
                            name="name"
                            label="Template name"
                            value={name}
                            onChange={this.handleChange}
                            placeholder="Fill template name"
                        />
                        <Form.Select
                            required={isCustom}
                            name="selectedModule"
                            loading={isLoadingModules}
                            label="SPORK Package"
                            multiple
                            search
                            options={filteredModulesOptions || []}
                            value={selectedModule}
                            onChange={this.handleChange}
                            placeholder="Select package"
                            disabled={!isCustom}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onCancel={onClose}
                        onSave={this.handleCreate}
                        submitText="Create"
                        saveDisabled={saveDisabled}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default compose(
    firebaseConnect(["/modules/list", "kits_data"]),
    connect((state) => ({
        modulesOptions: getTenantModulesOptions(state),
        kitsOptions: getAKitsAsOptions(state),
        kitsData: fetchKitsData(state),
        isLoadingModules: undefined === modulesList(state),
    }),
    { onCreateTemplate }
    )
)(CreateTemplateDialog);
