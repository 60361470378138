import React, { PureComponent } from "react";
import { Modal, Menu, Icon, Form, Dropdown, Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";
import { SaveCancelButtons } from "bmd-react";
import TopicTreeSelector from "../../TopicCollection/TopicTreeSelector";

export default class AdvancedFilterAsync extends PureComponent {
    static propTypes = {
        usedTags: PropTypes.array,
        selectedTags: PropTypes.array,
        entityName: PropTypes.string,
        unusedFilter: PropTypes.bool,
        usedTopics: PropTypes.object,
        selectedTopic: PropTypes.string,
        onApplyFilterBy: PropTypes.func,
    };

    state = {
        open: false,
        unusedFilter: false,
        selectedTopic: null,
        selectedTags: [],
    };

    handleUnusedFilter = (e, { checked }) => this.setState({ unusedFilter: checked });
    handleOpen = () => {
        const { selectedTags, selectedTopic, unusedFilter } = this.props;
        this.setState({ open: true, selectedTags, selectedTopic, unusedFilter });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    composeTopicFilterLabel = (count) => {
        if (0 == count) {
            return "Filter";
        } else if (1 == count) {
            return "Filtered (by 1)";
        } else {
            return `Filtered (by ${count})`;
        }
    }
    handleSelectTopic = topic => {
        const { selectedTopic } = this.state;
        this.setState({ selectedTopic: topic !== selectedTopic ? topic : null });
    }

    handleChange = (e, {value}) => this.setState({ selectedTags: value.map(v => v.trim()).filter(Boolean) });

    handleSave = () => {
        const { onApplyFilterBy } = this.props;
        const { unusedFilter, selectedTopic, selectedTags } = this.state;
        this.handleClose();
        let changes = { selectedTags };
        if (undefined !== this.props.unusedFilter) {
            changes = { ...changes, unusedFilter };
        }
        if (undefined !== this.props.selectedTopic) {
            changes = { ...changes, selectedTopic };
        }
        onApplyFilterBy(changes);
    }

    render() {
        const { usedTags = [], entityName, usedTopics } = this.props;
        const { open, selectedTags, unusedFilter, selectedTopic } = this.state;
        return (
            <Modal
                open={open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                closeOnDimmerClick={false}
                trigger={
                    <Menu.Item link title="Click here to set advanced filter.">
                        <Icon name="filter" />
                        {this.composeTopicFilterLabel(this.props.selectedTags.length + (this.props.unusedFilter ? 1 : 0) + (this.props.selectedTopic ? 1 : 0))}
                    </Menu.Item>
                }
                size="small"
            >
                <Modal.Header>Advanced {entityName} filter</Modal.Header>
                <Modal.Content>
                    <Form>
                        {unusedFilter !== undefined && <Form.Field style={{float: "right"}}><Checkbox toggle label="Show unused only" checked={unusedFilter} onChange={this.handleUnusedFilter} /></Form.Field>}
                        <Form.Field>
                            <label>
                                Select tags to filter {entityName}s
                            </label>
                            <Dropdown
                                fluid
                                multiple
                                closeOnChange
                                selection
                                search
                                options={usedTags}
                                value={selectedTags}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        {selectedTopic !== undefined && <Form.Field label={`Select topic to filter ${entityName}s`} />}
                    </Form>
                    {selectedTopic !== undefined && <TopicTreeSelector
                        single
                        onChange={this.handleSelectTopic}
                        selectedTopics={selectedTopic}
                        filterOnly={usedTopics}
                    />}
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        submitText="Filter"
                        onSave={this.handleSave}
                        onCancel={this.handleClose}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
