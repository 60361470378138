import React, { Component } from "react";

import PropTypes from "prop-types";

class GlossaryDecorator extends Component {
    static propTypes = {
        entityKey: PropTypes.string.isRequired,
        getEditorState: PropTypes.func.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { getEditorState, entityKey } = this.props;
        const entity = getEditorState().getCurrentContent().getEntity(entityKey);
        const data = entity.getData();

        return (
            <span className="spork glossary-term" title={data.name}>
                {this.props.children}
            </span>
        );
    }
}

export default GlossaryDecorator;
