import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { onSchoolLoad } from "../../../SchoolList/schoolActions";
import { getSchoolOnlyAsOptions } from "../../../SchoolList/schoolSelectors";
import { onSearch, onSelectUser, onSetFieldValue } from "../../UserCoursesActions";
import { getFoundPeople, isSearching, getName, getSchoolId, getUserScope } from "../../UserCoursesSelectors";
import UserSearch from "./UserSearch";


const mapStateToProps = function(state) {
    return {
        schoolOptions: getSchoolOnlyAsOptions(state),
        foundPeople: getFoundPeople(state),
        isSearching: isSearching(state),
        name: getName(state),
        schoolId: getSchoolId(state),
        userScope: getUserScope(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { 
            onSchoolLoad,
            onSearch,
            onSelectUser,
            onSetFieldValue,
        },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(UserSearch);
