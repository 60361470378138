import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Segment, Divider } from "semantic-ui-react";
import classNames from "classnames/bind";

import ConfirmDeleteWithContext from "../../../component/ConfirmDeleteWithContext";
import AccessControl from "../../../auth/AccessControl";
import styles from "../ProblemSetCreator.module.scss";
import ProblemAnswer from "./ProblemAnswer";
import ProblemQuestion from "./ProblemQuestion";

let cx = classNames.bind(styles);

const AccessRights = {
    problemSet: {
        review: [ "problemSet:view", "problemSet:review" ],
        edit: [ "problemSet:manage", "problemSet:edit" ],
        editOrReview: [ "problemSet:manage", "problemSet:edit", "problemSet:view", "problemSet:review" ]
    },
    compTests: {
        review: [ "compTests:view" ],
        edit: [ "compTests:manage", "compTests:edit" ],
        editOrReview: [ "compTests:manage", "compTests:edit", "compTests:view" ]
    }
};

const getChangedContext = (context, userId, authorId) => {
    if (userId !== (context && context.userId) || authorId !== (context && context.authorId)) {
        return authorId ? { userId, authorId } : null;
    }
    return undefined;
};

class Problem extends React.PureComponent {

    static propTypes = {
        fbCollection: PropTypes.string.isRequired,
        userId: PropTypes.string,
        authorId: PropTypes.string,
        parentAuthorId: PropTypes.string,
        parentProblemId: PropTypes.string,
        problemId: PropTypes.string,
        problemName: PropTypes.string,
        problemType: PropTypes.string,
        children: PropTypes.node,
        question: PropTypes.object,
        answer: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddChild: PropTypes.func,
        moveChild: PropTypes.func,
        isLockedForEdit: PropTypes.bool,
        isLockedForRemove: PropTypes.bool,
    }

    state = {
        accessContext: null, // with own authorId
        parentContext: null, // with authorId of problem set or parent problem
        popupContext: null,
    }

    static getDerivedStateFromProps(props, state) {
        const { userId, authorId, parentAuthorId } = props;
        const parentContext = getChangedContext(state.parentContext, userId, parentAuthorId);
        const accessContext = getChangedContext(state.accessContext, userId, authorId);

        return parentContext !== undefined ? (
            accessContext !== undefined ? { parentContext, accessContext } : { parentContext }
        ) : accessContext !== undefined ? { accessContext } : null;
    }

    handleEdit = (e) => {
        e.stopPropagation();
        const { onEdit, problemId } = this.props;
        onEdit && onEdit(problemId);
    }

    handleDeleteContext = (e) => {
        e.stopPropagation();
        this.setState({ popupContext: e.currentTarget });
    }

    handleCancelRemove = (e) => {
        e.stopPropagation();
        this.setState({ popupContext: null });
    }

    handleDelete = (e) => {
        e.stopPropagation();
        const { onDelete, problemId, parentProblemId } = this.props;
        onDelete && onDelete(problemId, parentProblemId);
    }

    handleAddChild = (e) => {
        e.stopPropagation();
        const { onAddChild, problemId } = this.props;
        onAddChild && onAddChild(problemId);
    }

    handleMoveChild = ({ currentTarget }) => {
        const { problemId, parentProblemId } = this.props;
        const direction = currentTarget.getAttribute("data");
        this.props.moveChild(parentProblemId, problemId, direction);
    }

    render() {
        const {
            question,
            answer,
            problemId,
            problemName,
            problemType,
            children,
            parentProblemId,
            isLockedForEdit,
            isLockedForRemove,
            fbCollection,
        } = this.props;
        const { popupContext, accessContext, parentContext } = this.state;
        const needRights = fbCollection === "problemSet" ? AccessRights.problemSet : AccessRights.compTests;
        const entityName = fbCollection === "problemSet" ? "problem" : "question";

        const isParent = problemType === "pa";

        return (
            <Segment raised id={problemId} className={`${styles.qna} ${problemType}`}>
                <span className={cx({ qnaTitle: true, isParent })}>{problemName}</span>
                <Button.Group basic compact floated="right" size="small">
                    {isParent && (
                        <AccessControl action={needRights.edit} data={accessContext}>
                            <Button onClick={this.handleAddChild} icon>
                                <Icon name="plus" />&nbsp;Child
                            </Button>
                        </AccessControl>
                    )}
                    <AccessControl action={needRights.editOrReview} data={accessContext}>
                        <Button onClick={this.handleEdit} icon disabled={isLockedForEdit}>
                            <AccessControl action={needRights.edit} data={accessContext} renderNoAccess={(
                                <Icon name="eye" />
                            )}>
                                <React.Fragment><Icon name="pencil" />&nbsp;Edit</React.Fragment>
                            </AccessControl>
                        </Button>
                    </AccessControl>
                    {parentProblemId && (
                        <AccessControl action={needRights.edit} data={parentContext}>
                            <Button data="up" icon onClick={this.handleMoveChild} disabled={isLockedForRemove}>
                                <Icon name="arrow up" />
                            </Button>
                            <Button data="down" icon onClick={this.handleMoveChild} disabled={isLockedForRemove}>
                                <Icon name="arrow down" />
                            </Button>
                        </AccessControl>
                    )}
                    <AccessControl action={needRights.edit} data={parentContext}>
                        <Button onClick={this.handleDeleteContext} icon disabled={isLockedForRemove}>
                            <Icon name="trash alternate outline" title={`Remove ${entityName} from set`} />
                        </Button>
                    </AccessControl>
                </Button.Group>
                <ConfirmDeleteWithContext
                    context={popupContext}
                    confirmText={`Remove ${entityName}`}
                    whatText={`this ${entityName} from the set`}
                    onConfirm={this.handleDelete}
                    onCancel={this.handleCancelRemove}
                />
                <ProblemQuestion content={question && question.content} />
                <Divider />
                {isParent ? children : (
                    <ProblemAnswer
                        type={problemType}
                        content={problemType === "oa" || problemType === "tf" ? answer && answer.content : undefined}
                        choices={problemType === "mc" || problemType === "mx" ? answer : undefined}
                        matches={problemType === "ma" ? answer : undefined}
                    />
                )}
            </Segment>
        );
    }
}


Problem.defaultProps = {
    activeProblemSet:{
        isActive: false,
    },
};

export default Problem;
