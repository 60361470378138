import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import AddTopicModal from "./AddTopicModal";
import { getSelectedCollection } from "../../tttSelectors";
import { onTopicLinkToBela, onSetRecObjectiveSet } from "../../BeLATopicCollection/belaTopicCollectionActions";
import { isDialogOpen } from "../../../Topics/topicsSelectors";
import { onTopicShowDialog } from "../../../Topics/topicsActions";

const mapStateToProps = function(state) {
    return {
        selectedCollection: getSelectedCollection(state),
        isDialogOpen: isDialogOpen(state)
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onTopicLinkToBela, onSetRecObjectiveSet, showAddDialog: onTopicShowDialog }, dispatch);
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(AddTopicModal);
