import { TYPES } from "./LessonContentActions";

const initialState = {
    searchFilter: "",
    topicFilter: {},
    userRights: {},
    tabIndex: "compose",
    isDirty: false,
    focusedItem: {},
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.LESSON_CONTENT_SET_SEARCH_FILTER:
            return { ...state, searchFilter: payload };
        case TYPES.LESSON_CONTENT_SET_TOPIC_FILTER:
            return { ...state, topicFilter: payload };
        case TYPES.LESSON_CONTENT_STORE_USER_RIGHTS:
            return { ...state, userRights: payload };
        case TYPES.LESSON_CONTENT_TAB_CHANGE:
            return { ...state, tabIndex: payload };
        case TYPES.LESSON_CONTENT_SET_DIRTY:
            return { ...state, isDirty: payload };
        case TYPES.LESSON_CONTENT_SET_FOCUS:
            return { ...state, focusedItem: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
