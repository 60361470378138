import { createSelector } from "reselect";
import _ from "lodash";
import {
    mapCollectionToList,
    compareByNameCI,
    getSearchByNamePredicate,
    getSearchByTagsArrayPredicate,
    convertDatasetToList,
    compareValueByPosition, 
    getSearchByOptionalIndexPredicate} from "../../utils/selectorUtils";
import { tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getEnabledModules } from "../../External/Tenants/tenantSelectors";

export const selectedTemplateId = (state) => state.kitBuilder.widgetTemplates.selectedItem;
export const isDirty = (state) => state.kitBuilder.widgetTemplates.isDirty;
export const getModuleFilter = (state) => state.kitBuilder.widgetTemplates.moduleFilter;
export const isItemSaving = (state, props) => 
    state.kitBuilder.widgetTemplates.isSaving[`${state.kitBuilder.widgetTemplates.selectedItem}@${props.id}`] === true;
export const getWidgetTemplatesData = (state) => (
    state.firebase.data.widgetTemplates && state.firebase.data.widgetTemplates.data
);
export const getTemplateModules = (state) => state.firebase.data.widgetTemplates && state.firebase.data.widgetTemplates.m;
export const getWidgetTemplatesInfo = (state) => (
    state.firebase.data.widgetTemplates && state.firebase.data.widgetTemplates.list
);

export const getUsedByCount = createSelector(
    selectedTemplateId,
    getWidgetTemplatesInfo,
    (key, templatesInfo) => {
        const info = key && templatesInfo && templatesInfo[key];
        return info && info.used && Object.keys(info.used).length;
    }
);

export const widgetTemplates = createSelector(getWidgetTemplatesData, (templates) => templates);

export const getSelectedTemplate = createSelector(
    selectedTemplateId,
    getWidgetTemplatesInfo,
    getWidgetTemplatesData,
    getTemplateModules,
    tenantId,
    getEnabledModules,
    (key, templatesInfo, templatesData, templateModules, tenantId, enabledModules) => {
        const info = key && templatesInfo && templatesInfo[key];
        if (info && enabledModules) {
            const entityTenant = info.t || 1;
            if (tenantId === entityTenant || templateModules[key] && enabledModules.some(j => !!templateModules[key][j])) {
                const data = templatesData && templatesData[key];
                return {
                    key,
                    ...info,
                    ...data,
                    modules: templateModules && templateModules[key] ? Object.keys(templateModules[key]) : undefined
                };
            } else {
                return { noAccess: true, key };
            }
        }
        return null;
    }
);

export const getSortedTemplates = createSelector(
    getWidgetTemplatesInfo,
    (templates) => (templates && mapCollectionToList(templates).sort(compareByNameCI))
);

export const editing = (state) => state.kitBuilder.widgetTemplates.editing;

export const isNew = (state) => state.kitBuilder.widgetTemplates.isNew;

export const searchFilter = (state) => state.kitBuilder.widgetTemplates.searchFilter;

export const selectedTags = (state) => state.kitBuilder.widgetTemplates.selectedTags;

export const usedTags = createSelector(
    getSortedTemplates,
    (templates) => {
        const tags = [];
        if (templates) {
            templates.map(template => {
                if (template.tags) {
                    Object.keys(template.tags).map(tag => tags.push({ key: tag, value: tag, text: tag }));
                }
            });
            return _.uniqBy(_.sortBy(tags, ["key"]), "key");
        }
    }
);

export const filteredTemplates = createSelector(
    getSortedTemplates,
    selectedTags,
    searchFilter,
    getModuleFilter,
    getTemplateModules,
    tenantId,
    getEnabledModules,
    (templates, selectedTags, searchFilter, modFilter, templateModules, tenant, enabledModules) => {
        let filteredData = templates || [];
        const tagsPredicate = getSearchByTagsArrayPredicate(selectedTags);
        if (tagsPredicate) {
            filteredData = filteredData.filter(tagsPredicate);
        }
        const namePredicate = getSearchByNamePredicate(searchFilter);
        if (namePredicate) {
            filteredData = filteredData.filter(namePredicate);
        }
        const modulePredicate = getSearchByOptionalIndexPredicate(modFilter, templateModules, tenant, enabledModules, false);
        filteredData = filteredData.filter(modulePredicate);
        return filteredData.sort(compareByNameCI);
    }
);

export const getOrderedItemsList = createSelector(getSelectedTemplate, (template) => (
    template && template.items
        ? convertDatasetToList(template && template.items).sort(compareValueByPosition)
        : []
));

export const getHighestItemPosition = createSelector(getOrderedItemsList, (items) => {
    if (items.length) {
        return items[items.length - 1].value.position;
    } else {
        return 0;
    }
});

export const getTemplatesAsOptions = createSelector(
    getSortedTemplates,
    tenantId,
    getEnabledModules,
    getTemplateModules,
    (templates, tenant, enabledModules, templateModules) => {
        const options = [];
        if (templates) {
            let filteredTemplates = templates;
            const modulePredicate = getSearchByOptionalIndexPredicate([], templateModules, tenant, enabledModules, true);
            filteredTemplates = filteredTemplates.filter(modulePredicate);
            filteredTemplates.map(t => 
                options.push({ key: t.key, value: t.key, text: t.name })
            );
            return options;
        }
    }
);

export const getTemplatesAsOptions4Lesson = createSelector(
    getSortedTemplates,
    tenantId,
    getModuleFilter,
    getTemplateModules,
    (templates, tenant, moduleFilter, templateModules) => {
        const options = [];
        if (templates) {
            let filteredTemplates = templates;
            const modulePredicate = getSearchByOptionalIndexPredicate(moduleFilter, templateModules, tenant, [], true);
            filteredTemplates = filteredTemplates.filter(modulePredicate);
            filteredTemplates.map(t => 
                options.push({ key: t.key, value: t.key, text: t.name })
            );
            return options;
        }
    }
);
