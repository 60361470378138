import { createSelector } from "reselect";
import { getTopicCollectionData } from "../../TopicCollection/topicCollectionSelectors";
import { denormalizeTopicKey } from "../../utils/selectorUtils";
import { populate, getVal } from "react-redux-firebase";
import { getTagCollectionByParent, getItemCollectionByParent, getItemLockCollectionByParent } from "../utils";
import { loggedUserId, userClaims } from "../../Users/UserProfile/UserProfileSelectors";
import { isPermitted } from "../../auth";

export const maxItemsPerPage = 100;

const convertTagToOption = (tag) => ({ key: tag, value: tag, text: tag });
const getUsedTags = (state, props) => state.firebase && state.firebase.data && state.firebase.data[getTagCollectionByParent(props.fbCollection)];

export const getUsedTagNames = createSelector(getUsedTags, (tags) => {
    if (tags) {
        return Object.keys(tags);
    } else if (null === tags) {
        return [];
    }
});

export const usedTags = createSelector(getUsedTagNames, (tags) => tags && tags.map(convertTagToOption));


const populateAuthorAndSets = (fbCollection) => ([
    { child: "author", root: "user", keyProp: "id" },
    { child: "problemSets", root: fbCollection }
]);

const getProblemManagement = ({ qu }) => qu.problemManagement;
const getFbCollection = (state, { fbCollection }) => fbCollection;

export const selectedTopic = ({ qu }) => qu.problemManagement.selectedTopic;
export const selectedTags = ({ qu }) => qu.problemManagement.selectedTags;
export const getTopicFilterName = createSelector(
    selectedTopic,
    getTopicCollectionData,
    (topicId, topicsData) => {
        if (topicsData && topicId) {
            const  [collection, topic] = denormalizeTopicKey(topicId);
            if (topicsData[collection] && topicsData[collection][topic]) {
                return topicsData[collection][topic].name;
            }
        }
    }
);

const getProblems = ({ firebase, qu }, { fbCollection }) => {
    const { searchResults, selectedTags, selectedTopic, allResults } = qu.problemManagement;
    const isEmptySearch = !selectedTopic || selectedTags.length === 0;
    const itemsCollection = getItemCollectionByParent(fbCollection);
    if (Object.keys(searchResults).length > 0){
        let results = {};
        Object.keys(maxItemsPerPage >= Object.keys(allResults).length ? allResults : searchResults).forEach((problemId) =>{
            const record = populate(firebase, `${itemsCollection}/${problemId}`, populateAuthorAndSets(fbCollection));
            if (record) {
                results[problemId] = record;
            }
        });
        return results;
    } else if (!isEmptySearch && Object.keys(searchResults).length == 0){
        //search with nothing found
        return {};
    } else if (isEmptySearch) {
        //empty search - return all
        return populate(firebase, itemsCollection, populateAuthorAndSets(fbCollection));
    }
};

const problemLocks = (state, { fbCollection }) => getVal(state.firebase, `data/locks/${getItemLockCollectionByParent(fbCollection)}`);

/**
 * Enrich Problem objects with populated Topic names
 */
export const getQuestionBank = createSelector(
    getProblems,
    getTopicCollectionData,
    problemLocks,
    (problems, topics, problemLocks) => {
        return Object.keys(problems || {}).map(key => {
            const displayTopics = {};

            problems[key] && Object.keys(problems[key].topics || {}).forEach(problemTopic => {
                const [collectionId, topicId] = denormalizeTopicKey(problemTopic);
                if (topics[collectionId] && topics[collectionId][topicId]) {
                    displayTopics[topics[collectionId][topicId].name] = true;
                }
            });

            return {
                ...problems[key], displayTopics,
                id: key,
                problemType: problems[key] && (problems[key].problemType || problems[key].type),
                displayProblemSets: problems[key] && problems[key].problemSets || {},
                problemSets: problems[key] && Object.keys(problems[key].problemSets || {}).reduce((accum, next) => {
                    return {...accum, [next]: true };
                }, {}),
                lock: problemLocks && problemLocks[key] ? problemLocks[key] : null,
            };
        });
    }
);
const generalLoading = ({ firebase, qu }, { fbCollection }) => !!(
    qu.problemManagement.isLoading ||
    firebase.requesting[getItemCollectionByParent(fbCollection)] ||
    firebase.requesting["topic_collection/data"]
);

const requestingProblem = (state, props) => {
    const requesting = state.firebase.requesting;
    const requested = state.firebase.requested;
    const itemsFrom = `${getItemCollectionByParent(props.fbCollection)}/`;
    for (const req of Object.keys(requesting)) {
        if (req.startsWith(itemsFrom) && requesting[req] && requested[req]) {
            return true;
        }
    }
    return false;
};
export const isLoading = createSelector(
    [ generalLoading, requestingProblem ],
    (loading, requestingProblem) => !!(loading || requestingProblem)
);

const getItemsPerPageProp = (state, props) => props.itemsPerPage;

/**
 * Overrides the itemsPerPage value from state using the value of property.
 */
export const getProblemsPaging = createSelector(
    [ getProblemManagement, getItemsPerPageProp ],
    (rawState, itemsPerPage) => {
        return itemsPerPage ? { ...rawState, itemsPerPage } : { ...rawState };
    }
);

const getSelectedFromProps = (_, props) => props && props.selected;
const getProblemsFromState = ({ firebase }, { fbCollection }) => firebase.data[getItemCollectionByParent(fbCollection)];

export const getSelectedProblems = createSelector(
    // we can probably use the value from Firebase data,
    // otherwise replace with getProblems (which populates)
    getProblemsFromState,
    getSelectedFromProps,
    (problems, selected) => {
        if (problems && selected) {
            const ids = Array.isArray(selected) ? selected : Object.keys(selected);
            return ids.length && ids.reduce((out, id) => {
                const problem = problems[id];
                if (problem) {
                    out[id] = problem;
                }
                return out;
            }, {}) || {};
        }
        return null;
    }
);

export const restrictToAuthorId = createSelector(
    [ loggedUserId, userClaims, getFbCollection ],
    (userId, userRoles, fbCollection) => {
        if (userRoles && userId && fbCollection === "problemSet") {
            if (isPermitted({ userRoles, action: "problemSet:manage" })) {
                return null; // with the access right to manage, user can modify any problem/set
            }
            if (isPermitted({ userRoles, action: "problemSet:author" })) {
                return userId; // if it is just author, then we should match author.id in entries
            }
        }
        return null;
    }
);