import { all, put, takeEvery, select, takeLatest } from "redux-saga/effects";
import { TYPES, onSetResource, onRescourseCreated, onSetSearchResult, onSetTags } from "./ResourceLinkActions";
import { getResources, tags } from "./ResourceLinkSelectors";
import { toastError, DB_ERROR } from "../../../component/toast";
import firebase from "../../dataSource";
import { loggedUserId, tenantId } from "../../../Users/UserProfile/UserProfileSelectors";
import { delay } from "redux-saga";
import * as Api from "../../Resources/ResourcesApi";

function* loadResource ({ payload: id }) {
    try {
        const resources = yield select(getResources);
        if (!resources[id]) {
            const resource = yield Api.fetchResourceById(id);
            yield put(onSetResource(resource.data));
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load resource", message: DB_ERROR });
    }
}

function* createResource ({
    payload: { name, type, href, description, file, tags, isPublic, sourceType, selectedTopic, callback }
}) {
    try {
        const params = { name, description, tags, is_public: isPublic, source_type_id: sourceType, topics: Object.keys(selectedTopic)};
        if ("link" === type) {
            const existingResource = yield Api.fetchResourceByHref(href);
            let exists = false;
            if (0 < existingResource.data.length) {
                const tId = yield select(tenantId);
                for (const resource of existingResource.data) {
                    if (tId == resource.tenant_id) {
                        exists = true;
                        yield put(onSetResource(resource));
                        callback(resource.id, true);
                    }
                }
                
            } 
            if (!exists) {
                const resource = yield Api.createResource({ ...params, href });
                yield put(onSetResource(resource));
                callback(resource.data.id);
            }
        } else {
            params.file = file;
            params.file_name = params.file.name.replace(/[/\\#[\]*?]/g, "_");
            const userId = yield select(loggedUserId);
            let resource = yield Api.createResource({ ...params, file });

            const uploadPath = `resourceUpload/${userId}/${resource.data.uuid}/${params.file_name}`;
            yield firebase.getFirebaseFile(uploadPath).put(file);

            resource = yield Api.markFileAsUploaded(resource.data.id, uploadPath);
            yield put(onSetResource(resource.data));
            callback(resource.data.id);
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to create resource", message: err.details && err.details.message || DB_ERROR });
    }
    yield put(onRescourseCreated());
}

function* searchResource({ payload }) {
    if (0 === payload.length) {
        yield put(onSetSearchResult(null));
        return;
    }
    yield delay(500);
    try {
        const response = yield Api.searchResourcesByName(payload);
        const resources = response.data;
        if (0 < resources.length) {
            const results = [];
            for (const i in resources.slice(0, 4)) {
                const res = resources[i];
                yield put(onSetResource(res));
                results.push({ name: res.name, description: res.description, title: res.id + "", resource_id: res.id});
            }
            if (4 < resources.length) {
                results.push({
                    title: "tooManyReuslts",
                    name: "Too many results",
                    description: "If you don't see the resource you want, so the word is too general, try to be more specific or use advanced search.",
                    resource_id: null
                });
            }
            yield put(onSetSearchResult(results));
        } else {
            yield put(onSetSearchResult(null));
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to search resource", message: DB_ERROR });
    }
}

function* loadTags() {
    try {
        const response = yield Api.fetchTags();
        yield put(onSetTags(response.data));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load tags", message: DB_ERROR });
    }
}

function* addTag({ payload }) {
    try {
        const tagsArray = yield select(tags);
        tagsArray.push({ id: payload, name: payload, description: "New tag" });
        yield put(onSetTags(tagsArray));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to add tag", message: DB_ERROR });
    }
}

export default function* resourceLink() {
    yield all([
        takeEvery(TYPES.SYLLABUS_OUTLINES_RESOURCE_LINK_LOAD_RESOURCE, loadResource),
        takeEvery(TYPES.SYLLABUS_OUTLINES_RESOURCE_CREATE_RESOURCE, createResource),
        takeLatest(TYPES.SYLLABUS_OUTLINES_RESOURCE_SEARCH_RESOURCE, searchResource),
        takeLatest(TYPES.SYLLABUS_OUTLINES_RESOURCE_LOAD_TAGS, loadTags),
        takeEvery(TYPES.SYLLABUS_OUTLINES_RESOURCE_ADD_TAG, addTag),
    ]);
}
