import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Segment, Checkbox, Table, Label } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import SiPiItem from "../SiPiItem";
import FilterMenu from "../../../component/FilterMenu";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";

export default class SiPiCloner extends PureComponent {
    static propTypes = {
        selectedTemplateId: PropTypes.string,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        templatesAsOptions: PropTypes.array,
        onSelectTemplate: PropTypes.func,
        templateSiPi: PropTypes.object,
        template: PropTypes.object,
        onLoadIplannerResources: PropTypes.func,
        templateSiPiCollection: PropTypes.object,
        onCloneSiPis: PropTypes.func,
    }

    state = {
        searchFilter: "",
        selectedItems: {},
    }

    handleReset = () => {
        this.setState({searchFilter: "", selectedItems: {}});
    }

    handleSelectTemplate = (e, { value }) => {
        const { onLoadIplannerResources, onSelectTemplate } = this.props;
        this.handleReset();
        onSelectTemplate(value);
        onLoadIplannerResources({ iPlannerId: value });
    }

    handleSearchFilter = (e, { value }) => this.setState({ searchFilter: value.toLocaleLowerCase() });
    handleClose = () => {
        this.handleReset();
        this.props.onClose();
    }

    handleToggle = (e, { id, checked}) => {
        let { selectedItems } = this.state;
        if (checked) {
            selectedItems[id]= true;
        } else {
            delete selectedItems[id];
        }
        this.setState({ selectedItems: {...selectedItems} });
    }

    handleSave = () => {
        const { templateSiPiCollection, onCloneSiPis } = this.props;
        const { selectedItems } = this.state;
        onCloneSiPis(Object.keys(selectedItems).map(key => templateSiPiCollection[key]));
        this.handleClose();
    }

    render() {
        const { selectedTemplateId, isOpen, templatesAsOptions, templateSiPi, template } = this.props;
        const { searchFilter, selectedItems } = this.state;

        return (
            <Modal
                closeOnDimmerClick={false}
                onClose={this.handleClose}
                open={isOpen}
                size="small"
            >
                <Modal.Header>Copy iPlanner Instrument</Modal.Header>
                <Modal.Content>
                    <div style={{ display: "flex", alignItems: "center", paddingBottom: "1em" }}>
                        <Label style={{ float: "left", margin: "0.3em" }} size="big" circular>1</Label>
                        <p>Select a template from which you want to copy the iPlanner Instrument.</p>
                    </div>
                    <Form>
                        <Form.Select
                            search
                            required
                            label="Source template"
                            options={templatesAsOptions || []}
                            value={selectedTemplateId}
                            onChange={this.handleSelectTemplate}
                            placeholder="Select template..."
                        />
                    </Form>
                    
                    {template && (
                        <React.Fragment>
                            <div style={{ display: "flex", alignItems: "center", paddingTop: "1em"}}>
                                <Label style={{ float: "left", margin: "0.3em" }} size="big" circular>2</Label>
                                <p>Copy selected instruments to the lesson.</p>
                            </div>
                            <FilterMenu
                                entityName="selected item"
                                count={Object.keys(selectedItems).length}
                                onChange={this.handleSearchFilter}
                                filterName={searchFilter}
                            />
                            {templateSiPi ? <Segment.Group style={{overflow: "auto", maxHeight: "30vh" }}>
                                <Table><Table.Body>
                                    {template.outline && Object.keys(template.outline).map((u) => (
                                        template.outline[u].map(lu => (
                                            templateSiPi[`lu_${lu.cv_lu_id }`] && <React.Fragment key={lu.cv_lu_id}>
                                                <Table.Row active key={lu.cv_lu_id}><Table.Cell colSpan="2">{lu.name}</Table.Cell></Table.Row>
                                                {templateSiPi[`lu_${lu.cv_lu_id }`] && templateSiPi[`lu_${lu.cv_lu_id }`].map(sipi =>(
                                                    sipi.value.name.toLowerCase().indexOf(searchFilter) !== -1 &&
                                                        <Table.Row key={sipi.key}>
                                                            <Table.Cell width={1}><Checkbox checked={selectedItems[sipi.key] || false} id={sipi.key} onChange={this.handleToggle} /></Table.Cell>
                                                            <Table.Cell width={15}><SiPiItem sipi={sipi.value}/></Table.Cell>
                                                        </Table.Row>
                                                ))}
                                            </React.Fragment>
                                        ))
                                    ))}
                                </Table.Body></Table>
                            </Segment.Group> : (
                                <NoDataPlaceholder
                                    icon="list alternate outline"
                                    text="Selected source iPlanner Template contains no iPlanner Instruments."
                                />
                            )}
                        </React.Fragment>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onCancel={this.handleClose}
                        onSave={this.handleSave}
                        saveDisabled={0 === Object.keys(selectedItems).length}
                        submitText="Copy"
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
