import { createSelector } from "reselect";
import { populate, getVal } from "react-redux-firebase";
import { TYPE as LOCK_TYPE } from "../../Lock";
import { loggedUserId } from "../../Users/UserProfile/UserProfileSelectors";

const populates = [
    { child: "author", root: "user", keyProp: "id" },
    { child: "editor", root: "user", keyProp: "id" },
];

const LockTypes = {
    problemSet: LOCK_TYPE.PROBLEM_SET,
    problem: LOCK_TYPE.PROBLEM,
    comptests_common: LOCK_TYPE.COMP_TEST,
    comptests_cvault: LOCK_TYPE.COMP_TEST_CVAULT,
};

const FbItemCollections = {
    problemSet: "problem",
    comptests_common: "comptests_cvault",
};

const _getItemLockType = (fbCollection) => LockTypes[FbItemCollections[fbCollection]];

const getProblemSetItems = ({ firebase }) => populate(firebase, "problemSetItems/", populates);
const getProblemSetItemsRaw = ({ firebase }) => firebase.data && firebase.data["problemSetItems"];
const getProblemLocks = ({ firebase }, { fbCollection = "problemSet" }) => (
    getVal(firebase.data, `locks/${_getItemLockType(fbCollection)}`)
);

// get problems together with possible locks
export const getProblems = createSelector(
    getProblemSetItems,
    getProblemLocks,
    (problems, locks) => Object.keys(problems || {}).reduce((acc, key) => {
        const problem = problems[key];
        if (problem) {
            const lock = locks && locks[key] || null;
            acc[key] = { ...problem, lock };
        }
        return acc;
    }, {})
);

export const isLoadingProblemSet = (state, props) => (
    props.match &&
    props.match.params.id &&
    state.firebase.requesting &&
    state.firebase.requesting[`${props.fbCollection || "problemSet"}/${props.match.params.id}`]
);

const getActiveProblemSetId = (state, props) => props.match.params.id;
const getActiveFbCollection = (state, props) => props.fbCollection || "problemSet";
const getFirebaseRequests = (state) => state.firebase.requesting;

export const getActiveProblemSet = (state, props) => {
    const problemSetId = getActiveProblemSetId(state, props);
    const fbCollection = getActiveFbCollection(state, props);
    const data = problemSetId && fbCollection && populate(state.firebase, `${fbCollection}/${problemSetId}`, populates);
    // Extend the data with ID (this is wrong but it's very hard to change now)
    return data && { ...data, id: problemSetId };
};

export const isLoadingProblems = createSelector(
    getActiveFbCollection,
    getActiveProblemSet,
    getFirebaseRequests,
    (fbCollection, problemSet, requests) => {
        const problems = requests && problemSet && problemSet.problems;
        if (Array.isArray(problems)) {
            const lockName = _getItemLockType(fbCollection);
            return problems.some(id => requests[`problemSetItems/${id}`] || requests[`locks/${lockName}/${id}`]);
        }
        return false;
    }
);

const getProblemSetsRaw = ({ firebase }, { fbCollection = "problemSet" }) => (
    fbCollection && firebase && firebase.data[fbCollection]
);
const getProblemSetRaw = createSelector(
    [ getActiveProblemSetId, getProblemSetsRaw ],
    (id, rawSets) => (id && rawSets && rawSets[id])
);

export const getProblemSetAccessContext = createSelector(
    [ getProblemSetRaw, loggedUserId ],
    (pset, userId) => (pset && userId && { userId, authorId: pset.author })
);

export const getProblemSetListeners = createSelector(
    [ getActiveProblemSetId, getActiveFbCollection, getProblemSetRaw, getProblemSetItemsRaw ],
    (problemSetId, fbCollection, pset, psetItems) => {
        const listeners = [];
        const fbItems = FbItemCollections[fbCollection];
        // Get problem set definition and lock from DB
        if (problemSetId && fbCollection) {
            listeners.push({ path: `${fbCollection}/${problemSetId}`, populates });
            // const lockName = locksShortName[fbCollection];
            // lockName && listeners.push({ path: `locks/${lockName}/${problemSetId}` });
        }
        // Get all linked problems from problem set and their locks
        const problems = pset && pset.problems;
        if (Array.isArray(problems) && fbItems) {
            const lockName = LockTypes[fbItems];
            for (const id of problems) {
                listeners.push({ path:`${fbItems}/${id}`, storeAs: "problemSetItems/" + id, populates });
                lockName && listeners.push({ path:`locks/${lockName}/${id}`});
            }
        }
        // Get nested problems and their locks using children of parent problem
        const children = psetItems && problems && problems.flatMap((id) => (
            psetItems[id] && psetItems[id].children
        )).filter(Boolean);
        if (Array.isArray(children) && fbItems) {
            const lockName = LockTypes[fbItems];
            for (const id of children) {
                listeners.push({ path:`${fbItems}/${id}`, storeAs: "problemSetItems/" + id, populates });
                lockName && listeners.push({ path:`locks/${lockName}/${id}`});
            }
        }

        return listeners;
    }
);

export const getProblemSetLockType = createSelector(
    [ getActiveProblemSetId, getActiveFbCollection ],
    (itemId, fbCollection) => {
        const lockType = fbCollection && LockTypes[fbCollection];
        return itemId && lockType ? { lockType, itemId } : null;
    }
);
