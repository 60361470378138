const TYPES = {
    SIPI_TEST_CREATOR_SELECT_PROBLEM_SET: "SIPI_TEST_CREATOR_SELECT_PROBLEM_SET",
    SIPI_TEST_CREATOR_SET_PROBLEM_SET_HTML: "SIPI_TEST_CREATOR_SET_PROBLEM_SET_HTML",
    SIPI_TEST_CREATOR_GENERATE_HTML: "SIPI_TEST_CREATOR_GENERATE_HTML",
    SIPI_TEST_CREATOR_CREATE_TESTS: "SIPI_TEST_CREATOR_CREATE_TESTS",
    SIPI_TEST_CREATOR_TEST_CREATED: "SIPI_TEST_CREATOR_TEST_CREATED",
};

const onSelectProblemSet = (payload) => ({
    type: TYPES.SIPI_TEST_CREATOR_SELECT_PROBLEM_SET,
    payload
});

const onSetProblemSetHtml = (payload) => ({
    type: TYPES.SIPI_TEST_CREATOR_SET_PROBLEM_SET_HTML,
    payload
});

const onGenerateHtml = (payload) => ({
    type: TYPES.SIPI_TEST_CREATOR_GENERATE_HTML,
    payload
});

const onCreateTests = (payload) => ({
    type: TYPES.SIPI_TEST_CREATOR_CREATE_TESTS,
    payload
});

const onTestsCreated = () => ({
    type: TYPES.SIPI_TEST_CREATOR_TEST_CREATED,
});

export {
    TYPES,
    onSelectProblemSet,
    onSetProblemSetHtml,
    onGenerateHtml,
    onCreateTests,
    onTestsCreated,
};
