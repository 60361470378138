import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import CollectionList from "./CollectionList";
import { getTopicCollectionList, getSelectedTopicCollection } from "../topicCollectionSelectors";
import { onSelectCollection, onAddCollection, onUpdateCollection } from "../topicCollectionActions";
import { userClaims } from "../../Users/UserProfile/UserProfileSelectors";
import { withRouter } from "react-router-dom";
import { isAuthorAtLeast } from "../../Users/roleUtils";

const mapStateToProps = function(state) {
    return {
        collections: getTopicCollectionList(state),
        selectedCollection: getSelectedTopicCollection(state),
        canEdit: isAuthorAtLeast(userClaims(state)),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators(
        { onSelectCollection, onAddCollection, onUpdateCollection },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    withRouter
)(CollectionList);
