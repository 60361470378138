import { TYPES } from "./topicCollectionActions";

// The initial state of the ExampleSimple
const initialState = {
    selectedCollection: null,
    topicExpandStatus: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.TC_SELECT_COLLECTION:
            return { ...state, selectedCollection: action.payload };
        case TYPES.TC_SET_EXPAND_MODE:
            return {
                ...state,
                topicExpandStatus: { ...state.topicExpandStatus, ...action.payload }
            };
        case TYPES.TC_START_SYNC:
            return { ...state, syncInProgress: true };
        case TYPES.TC_SYNC_ENDED:
            return { ...state, syncInProgress: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
