const TYPES = {
    USER_LIST_EDITATION: "Users/List/EDITATION",
    USER_LIST_CLOSE_EDITATION: "Users/List/CLOSE_EDITATION",
    USER_LIST_CHANGE_ROLE: "Users/List/CHANGE_ROLE",
    USER_LIST_VARIANTS_LOADING: "Users/List/VARIANTS_LOADING",
    USER_LIST_SET_VARIANTS: "Users/List/VARIANTS_LOADED",
    USER_CLAIMS_UPDATING: "Users/List/CLAIMS_UPDATING",
    USER_LIST_CHANGE_ROLE_GOD_MODE: "Users/List/CHANGE_ROLE_GOD_MODE",
    USER_LIST_SET: "Users/LIST_SET",
    USER_LIST_LOAD: "Users/LIST_LOAD",
    USER_SIMPLE_LIST_LOAD: "Users/SIMPLE_LIST_LOAD",
    USER_SIMPLE_LIST_SET: "Users/SIMPLE_LIST_SET",
};

const onEditation = payload => ({
    type: TYPES.USER_LIST_EDITATION,
    payload,
});

const onCloseEditation = () => ({
    type: TYPES.USER_LIST_CLOSE_EDITATION,
});

const onChangeRole = payload => ({
    type: TYPES.USER_LIST_CHANGE_ROLE,
    payload,
});

const onChangeRoleGodMode = payload => ({
    type: TYPES.USER_LIST_CHANGE_ROLE_GOD_MODE,
    payload,
});

const onVariantsLoading = () => ({
    type: TYPES.USER_LIST_VARIANTS_LOADING,
});

const onSetVariants = payload => ({
    type: TYPES.USER_LIST_SET_VARIANTS,
    payload,
});

const onClaimsUpdating = payload => ({
    type: TYPES.USER_CLAIMS_UPDATING,
    payload,
});

const onUserListSet = payload => ({
    type: TYPES.USER_LIST_SET,
    payload,
});

const onUserListLoad = payload => ({
    type: TYPES.USER_LIST_LOAD,
    payload,
});

const onUserSimpleListLoad = () => ({
    type: TYPES.USER_SIMPLE_LIST_LOAD,
});

const onUserSimpleListSet = payload => ({
    type: TYPES.USER_SIMPLE_LIST_SET,
    payload
});

export {
    TYPES,
    onEditation,
    onCloseEditation,
    onChangeRole,
    onSetVariants,
    onVariantsLoading,
    onClaimsUpdating,
    onChangeRoleGodMode,
    onUserListSet,
    onUserListLoad,
    onUserSimpleListLoad,
    onUserSimpleListSet,
};
