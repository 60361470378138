import React from "react";
import PropTypes from "prop-types";
import WidgetItem from "../../Widget/WidgetItem";
import { Icon, Segment } from "semantic-ui-react";
import LinkItemEditor from "./LinkItemEditor";
import FileItemEditor from "./FileItemEditor";
import ItemLabel from "../../component/ItemLabel";
import { withAuth } from "../../../component/withAuth";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { isItemSaving, isItemEditing } from "../WidgetLibrarySelectors";
import { onEditItem, onUpdateFileItem, onUpdateItem, onChangeItemTitle } from "../WidgetLibraryActions";

class WidgetItemEditor extends React.PureComponent {
    static propTypes = {
        template: PropTypes.object,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        onUpdateItem: PropTypes.func.isRequired,
        onUpdateFileItem: PropTypes.func.isRequired,
        itemId: PropTypes.string,
        widgetId: PropTypes.string,
        onEditItem: PropTypes.func.isRequired,
        onCloseItemEditor: PropTypes.func.isRequired,
        isAuthorAtLeast: PropTypes.bool,
        canEdit: PropTypes.bool,
        isItemSaving: PropTypes.bool,
        isItemEditing: PropTypes.bool,
        onChangeItemTitle: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool,
    };

    state = {};

    handleEditation = () => {
        this.switchToEditMode(false);
    };

    switchToEditMode = isReview => {
        const { widgetId, itemId, onEditItem } = this.props;
        onEditItem(widgetId, itemId, isReview);
    };

    handleChangeTitle = ({ title, hiddenTitle }) => {
        const { widgetId, itemId, onChangeItemTitle } = this.props;
        onChangeItemTitle({ widgetId, itemId, title, hiddenTitle });
    }

    render() {
        const {
            itemId,
            template,
            content,
            onUpdateFileItem,
            onUpdateItem,
            isItemSaving,
            isItemEditing,
            isAuthorAtLeast,
            canEdit,
            onCloseItemEditor,
            widgetId,
            reviewMode,
        } = this.props;

        return (
            <Segment style={{ backgroundColor: "inherit" }}>
                <ItemLabel item={template}>
                    {!isItemEditing && (
                        <span style={{ textAlign: "right" }}>
                            {isAuthorAtLeast && canEdit && !reviewMode && (
                                <Icon
                                    style={{ textAlign: "right" }}
                                    name="write"
                                    link
                                    title={`Edit ${template.type} item`}
                                    className="actionIconClass"
                                    onClick={this.handleEditation}
                                />
                            )}
                        </span>
                    )}
                </ItemLabel>
                {isItemEditing ? (
                    <React.Fragment>
                        <WidgetItem template={template} content={content}>
                            {"link" === template.type ? (
                                <LinkItemEditor
                                    itemId={itemId}
                                    widgetId={widgetId}
                                    content={content}
                                    onUpdateItem={onUpdateItem}
                                    isSaving={isItemSaving}
                                    onCancel={onCloseItemEditor}
                                />
                            ) : "file" === template.type ? (
                                <FileItemEditor
                                    itemId={itemId}
                                    widgetId={widgetId}
                                    content={content}
                                    onUpdateItem={onUpdateItem}
                                    onUpdateFileItem={onUpdateFileItem}
                                    isSaving={isItemSaving}
                                    onCancel={onCloseItemEditor}
                                />
                            ) : (
                                "Unknown widget"
                            )}
                        </WidgetItem>
                    </React.Fragment>
                ) : (
                    <WidgetItem template={template} content={content} onChangeTitle={!reviewMode ? this.handleChangeTitle : null}/>
                )}
            </Segment>
        );
    }
}

const dispatchToProps = dispatch => {
    return bindActionCreators({ onEditItem, onUpdateItem, onUpdateFileItem, onChangeItemTitle }, dispatch);
};

export default compose(
    connect(
        (state, props) => ({
            isItemSaving: isItemSaving(state, props),
            isItemEditing: isItemEditing(state, props),
        }),
        dispatchToProps
    ),
    withAuth
)(WidgetItemEditor);
