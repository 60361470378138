import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List, Segment, Header, Icon, Popup } from "semantic-ui-react";
import StatusLabel from "./StatusLabel";
import ActionLinks from "../../../component/ActionLinks";
import Topic from "../../../Topics/components/Topic";
import AssignTopic from "./AssignTopic";
import AddTopicModal from "../AddTopicModal";
import LinkToExistingTopicContainer from "../LinkToExistingTopic";
export default class TopicTree extends PureComponent {
    state = { displayOnlyValid: false };

    deselectCollection = () => {
        this.props.onBack(undefined);
    };

    handleToggleClick = () => {
        const { displayOnlyValid } = this.state;
        this.setState({ displayOnlyValid: !displayOnlyValid });
    };

    renderTree(node, path) {
        return (
            <List selection style={{ marginTop: 0 }}>
                {Object.keys(node).map(key => this.renderNode(node[key], path))}
            </List>
        );
    }

    handleAddTopicClick = (action, selectedTopic) => {
        this.setState({ selectedTopic });
        switch (action) {
            case "new":
                this.props.showAddDialog(true);
                break;
            case "link":
                this.setState({ showLinkToExisting: true });
                break;
        }
    };

    hideLinkToExisting = () => {
        this.setState({ showLinkToExisting: false });
    };

    renderNode(node, path) {
        let nodePath = path + node.topic_id;
        let hideThisNode = this.state.displayOnlyValid && node.ttt_status !== "OPEN";
        let linkedTopicCount = node.spork_topics ? Object.keys(node.spork_topics).length : 0;

        return (
            <List.Item
                key={node.topic_id}
                topic_id={node.topic_id}
                topic_path={nodePath}
                style={hideThisNode ? { padding: "0" } : { paddingRight: "0" }}
            >
                {!hideThisNode && (
                    <List.Content>
                        {node.topic_name}
                        <List.Description style={{ float: "right", fontSize: "0.9em" }}>
                            {node.topic_description && (
                                <Popup
                                    trigger={<Icon name="comment outline" color="grey" />}
                                    wide
                                    style={{minWidth: "550px"}}
                                ><div dangerouslySetInnerHTML={{__html: node.topic_description}}></div></Popup>
                            )}
                            <StatusLabel
                                status={node.ttt_status}
                                onClick={this.props.onTopicStatusChange}
                                path={nodePath}
                                linkedTopicCount={linkedTopicCount}
                            />
                            <span>
                                Syl: {node.syllabi_count}, HSQ: {node.hsq_count}
                            </span>
                            <div
                                style={{
                                    paddingLeft: "1em",
                                    marginLeft: "1em",
                                    minWidth: "30em",
                                    display: "inline-block",
                                    verticalAlign: "top",
                                    borderLeft: "1px solid grey"
                                }}
                            >
                                {node.spork_topics &&
                                    Object.keys(node.spork_topics).map(topicId =>
                                        this.props.sporkTopics[topicId] ? (
                                            <Topic key={topicId} topic={this.props.sporkTopics[topicId]} />
                                        ) : (
                                            "Topic does not exist."
                                        )
                                    )}
                            </div>
                            {node.ttt_status === "OPEN" && (
                                <AssignTopic
                                    selectedTopic={{ ...node, path: this.props.selectedCollection.id + "/" + nodePath }}
                                    onClick={this.handleAddTopicClick}
                                />
                            )}
                        </List.Description>
                    </List.Content>
                )}
                {node.topics && this.renderTree(node.topics, nodePath + "/topics/")}
            </List.Item>
        );
    }

    render() {
        const { belaTopics, selectedCollection, isDialogOpen } = this.props;
        const { displayOnlyValid, selectedTopic, showLinkToExisting } = this.state;

        return belaTopics !== null ? (
            <React.Fragment>
                <Header>BeLA topics for {selectedCollection.name}</Header>
                <div>
                    <ActionLinks
                        floated="right"
                        actions={[
                            {
                                name: "toggle",
                                content: displayOnlyValid ? "Show all topics" : "Show only opened topics",
                                onClick: this.handleToggleClick
                            }
                        ]}
                    />
                    <ActionLinks
                        actions={[
                            {
                                name: "back",
                                content: "Back to collections",
                                icon: "arrow left",
                                onClick: this.deselectCollection
                            }
                        ]}
                    />
                </div>
                {this.renderTree(belaTopics, "")}
                {isDialogOpen && <AddTopicModal selectedTopic={selectedTopic} />}
                {showLinkToExisting && (
                    <LinkToExistingTopicContainer selectedTopic={selectedTopic} onCancel={this.hideLinkToExisting} />
                )}
            </React.Fragment>
        ) : (
            <Segment placeholder>
                <Header icon>
                    <Icon name="file outline" />
                    No topics are assigned to this collection.
                </Header>
            </Segment>
        );
    }
}

TopicTree.propTypes = {
    belaTopics: PropTypes.object,
    sporkTopics: PropTypes.object,
    onTopicStatusChange: PropTypes.func.isRequired,
    selectedCollection: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        recommended_obj_set: PropTypes.string
    }),
    onBack: PropTypes.func.isRequired,
    showAddDialog: PropTypes.func.isRequired,
    isDialogOpen: PropTypes.bool
};
