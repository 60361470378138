const TYPES = {
    LESSON_LINK_ADD: "Lesson/Link/ADD",
    LESSON_LINK_SEARCH_LESSON: "Lesson/Link/SEARCH_LESSON",
    LESSON_LINK_SET_SEARCH_RESULT: "Lesson/Link/SET_SEARCH_RESULT",
    LESSON_LINK_SET_OUTLINE: "Lesson/Link/SET_OUTLINE",
};

const onAdd = (payload) => ({
    type: TYPES.LESSON_LINK_ADD,
    payload
});

const onSetSearchResult = (payload) => ({
    type: TYPES.LESSON_LINK_SET_SEARCH_RESULT,
    payload
});

const onSearchLesson = (payload) => ({
    type: TYPES.LESSON_LINK_SEARCH_LESSON,
    payload
});

const onSetOutline = (payload) => ({
    type: TYPES.LESSON_LINK_SET_OUTLINE,
    payload
});

export {
    TYPES,
    onAdd,
    onSetSearchResult,
    onSearchLesson,
    onSetOutline,
};