import React, { Component } from "react";
import PropTypes from "prop-types";

import BookEditor from "../BookEditor";

class CellEditor extends Component {
    static propTypes = {
        initialContentState: PropTypes.object,
        initialHtml: PropTypes.string,
        onDirty: PropTypes.func.isRequired,
        sharedToolbar: PropTypes.object.isRequired,
        registerNestedEditor: PropTypes.func.isRequired,
        cellId: PropTypes.number.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        focusOnMount: PropTypes.bool.isRequired,
        rootEntityKey: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.bookEditorRef = null;
    }

    handleSetEditorRef = (bookEditorRef) => {
        this.bookEditorRef = bookEditorRef;
        this.registerNestedEditor(bookEditorRef);
    };

    isReadOnly = () => this.bookEditorRef.isReadOnly();

    registerNestedEditor = (bookEditor) => {
        const { cellId } = this.props;
        const cellEditor = {
            cellId,
            bookEditor,
        };
        this.parentUnregister = this.props.registerNestedEditor(cellEditor);
    }

    componentWillUnmount = () => {
        this.parentUnregister && this.parentUnregister();
    };

    render() {
        const { initialContentState, initialHtml, sharedToolbar, onDirty, reviewMode, focusOnMount, rootEntityKey } = this.props;
        return (
            <BookEditor
                features={["BOLD", "ITALIC", "UNDERLINE", "MATH", "MATHTEXT", "GLOSSARY", "TEXTALIGN", "FONT",
                    "FONTSIZE", "COLOR", "LESSON_LINK", "REVIEW", "IMG", "HELP"]}
                initialContentState={initialContentState}
                initialHtml={initialHtml}
                sharedToolbar={sharedToolbar}
                focusOnMount={focusOnMount}
                onDirty={onDirty}
                onSetEditorRef={this.handleSetEditorRef}
                reviewMode={reviewMode}
                rootEntityKey={rootEntityKey}
            />
        );
    }
}

export default CellEditor;
