import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Icon, Loader, Message, Segment } from "semantic-ui-react";
import { replaceRelLinks } from "../../../../utils/conversionUtils";

const LoaderStyle = {
    position: "absolute",
    top: "70px",
};

const ContentStyle = {
    minHeight: "147px",
    margin: "0",
};

export default class ProblemSetContent extends React.PureComponent {
    static propTypes = {
        collection: PropTypes.string,
        cloning: PropTypes.bool,
        html: PropTypes.string,
        updating: PropTypes.bool,
    };

    render() {
        const { collection, updating, html } = this.props;
        if (this.props.cloning) {
            return (
                <Message icon info>
                    <Icon name="circle notched" loading />
                    <Message.Content>Cloning problem set...</Message.Content>
                </Message>
            );
        }
        return (
            <Dimmer.Dimmable dimmed={updating}>
                <Dimmer active={updating} inverted>
                    <Loader style={LoaderStyle}>Updating preview...</Loader>
                </Dimmer>
                {html && (
                    <Segment
                        style={ContentStyle}
                        className={`spork problemSet preview ${collection}`}
                        dangerouslySetInnerHTML={{ __html: replaceRelLinks(html) }}
                    />
                )}
            </Dimmer.Dimmable>
        );
    }
}
