const TYPES = {
    SECTION_GET_SCHOOL_COURSES: "SECTION_GET_SCHOOL_COURSES",
    SECTION_SET_SCHOOL_COURSES: "SECTION_SET_SCHOOL_COURSES",
    SECTION_SET_FILTER: "SECTION_SET_FILTER",
    SECTION_SELECT_COURSE: "SECTION_SELECT_COURSE",
    SECTION_SET_DETAILS: "SECTION_SET_DETAILS",
    SECTION_SELECT_SECTION: "SECTION_SELECT_SECTION",
    SECTION_SET_SCHOOL_FILTER: "SECTION_SET_SCHOOL_FILTER",
};

const onLoadSchoolCourses = (payload) => ({
    type: TYPES.SECTION_GET_SCHOOL_COURSES,
    payload
});

const onSetSchoolCourses = (payload) => ({
    type: TYPES.SECTION_SET_SCHOOL_COURSES,
    payload
});

const onChangeFilter = (payload) => ({
    type: TYPES.SECTION_SET_FILTER,
    payload
});

const onSelectCourse = (payload) => ({
    type: TYPES.SECTION_SELECT_COURSE,
    payload
});

const onSetDetails = (payload) => ({
    type: TYPES.SECTION_SET_DETAILS,
    payload
});

const onSelectSection = (payload) => ({
    type: TYPES.SECTION_SELECT_SECTION,
    payload
});

const onChangeSchoolFilter = (payload) => ({
    type: TYPES.SECTION_SET_SCHOOL_FILTER,
    payload
});

export {
    TYPES,
    onLoadSchoolCourses,
    onSetSchoolCourses,
    onChangeFilter,
    onSelectCourse,
    onSetDetails,
    onSelectSection,
    onChangeSchoolFilter,
};
