import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

export default class CommentsHeader extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        unresolvedCommentsCount: PropTypes.number,
    }

    render() {
        const { title = "Comments", unresolvedCommentsCount } = this.props;
        return (
            <Header as="h3">
                {title + (unresolvedCommentsCount ? ` (${unresolvedCommentsCount})` : "")}
            </Header>
        );
    }
}
