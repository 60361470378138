import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import * as WidgetTopicsActions from "./WidgetTopicsActions";
import { getWidgetTopics } from "./WidgetTopicsSelectors";

import WidgetTopics from "./WidgetTopics";
import { loadingTopics } from "../../../TopicCollection/topicCollectionSelectors";

const mapStateToProps = function(state, props) {
    return {
        topics: getWidgetTopics(state, props),
        loadingTopics: loadingTopics(state),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        WidgetTopicsActions,
        dispatch
    );
};

export default compose(
    firebaseConnect(({widget, lessonTopics, viewOnly}) => {
        if (viewOnly) {
            const topicsListeners = [];
            if (widget && widget.topics) {
                Object.keys(widget.topics).map(topicId =>
                    topicsListeners.push(`topic_collections/data/${topicId}`.replace("@", "/"))
                );
            }
            if (lessonTopics) {
                Object.keys(lessonTopics).map(topicId =>
                    topicsListeners.push(`topic_collections/data/${topicId}`.replace("@", "/"))
                );
            }
            return topicsListeners;
        }
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(WidgetTopics);
