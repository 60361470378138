import { connect } from "react-redux";
import { onFixData } from "./FixiPlannerResourcesActions";
import { inProgress } from "./FixiPlannerResourcesSelectors";
import FixiPlannerResources from "./FixiPlannerResources";

const mapStateToProps = function(state) {
    return {
        inProgress: inProgress(state),
    };
};

const dispatchToProps = { onFixData };

export default connect(
    mapStateToProps,
    dispatchToProps
)(FixiPlannerResources);
