const TYPES = {
    SECTION_LOAD_SCHOOL: "SECTION_LOAD_SCHOOL",
    SECTION_SET_SCHOOL: "SECTION_SET_SCHOOL",
};

const onLoadSchool = (payload) => ({
    type: TYPES.SECTION_LOAD_SCHOOL,
    payload
});

const onSetSchool = (payload) => ({
    type: TYPES.SECTION_SET_SCHOOL,
    payload
});

export {
    TYPES,
    onLoadSchool,
    onSetSchool,
};
