const TYPES = {
    TENANT_LOAD_LIST: "Tenant/LOAD_LIST",
    TENANT_SET_LIST: "Tenant/SET_LIST",
    TENANT_CREATE_DEMO: "Tenant/CREATE_DEMO",
    TENANT_CREATE_DEMO_END: "Tenant/CREATE_DEMO_END",
    TENANT_NEW_TENANT_INFO: "Tenant/NEW_TENANT_INFO",
    TENANT_UPDATE_MODULES: "Tenant/UPDATE_MODULES",
};

const onTenantLoad = () => ({ type: TYPES.TENANT_LOAD_LIST });

const setTenantList = payload => ({
    type: TYPES.TENANT_SET_LIST,
    payload,
});

const onCreateDemoTenant = payload => ({
    type: TYPES.TENANT_CREATE_DEMO,
    payload,
});

const onEndCreateDemoTenant = payload => ({ type: TYPES.TENANT_CREATE_DEMO_END, payload });
const setNewTenantInfo = payload => ({ type: TYPES.TENANT_NEW_TENANT_INFO, payload });
const onUpdateModules = payload => ({
    type: TYPES.TENANT_UPDATE_MODULES,
    payload
});

export { TYPES, onTenantLoad, setTenantList, onCreateDemoTenant, onEndCreateDemoTenant, setNewTenantInfo, onUpdateModules };
