import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import ResourceLink from "./ResourceLink";
import "./SiPiItem.css";

export default class SiPiItem extends PureComponent {
    static propTypes = {
        sipi: PropTypes.object,
    }

    render() {
        const { sipi } = this.props;
        return (
            <React.Fragment>
                <div className="sipiItem" title={sipi.name}>
                    <span className="sipiItemName limitedTextWidth">{sipi.name}</span>
                    {sipi.classroom_view && <Icon className="rss" color="purple" title="Available for classroom presentation." />}
                    {sipi.student_view && <Icon name="tablet alternate" color="green" title="Available for students with a tablet." />}
                    {sipi.on_approval && <Icon name="history" flipped="horizontally" color="orange" title="Display only after the teacher approval." />}
                    {sipi.teacher && <span className="teacherInstructionLabel" title="Teacher Instructions">TI</span>}
                    {sipi.student && <span className="studentInstructionLabel" title="Student Instructions">SI</span>}
                </div>
                <div className="limitedTextWidth">
                    {sipi.resource_id ? <ResourceLink sipi={sipi} disableAutoLoad renderAsLink /> :
                        <span style={{fontStyle: "italic", marginRight: "0.3em", color: "grey"}}>Text only</span>}
                </div>
            </React.Fragment>
        );
    }
}
