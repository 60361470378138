import {createSelector} from "reselect";
import { populate } from "react-redux-firebase";
import { convertDatasetToList, compareValueByNameCI } from "../../utils/selectorUtils";
import { getSearchByValueYearIdPredicate, sipiTemplates } from "../SiPiTemplatesList/SiPiTemplatesListSelectors";
import { getSelectedYearId } from "../../Year/YearsSelectors";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { getSchoolsById } from "../../External/SchoolList/schoolSelectors";
import { getEnabledModules } from "../../External/Tenants/tenantSelectors";

export const searchFilter = state => state.lup.syllabiList.searchFilter;
export const schoolFilter = state => state.lup.syllabiList.schoolFilter;
export const teacherFilter = state => state.lup.syllabiList.teacherFilter;
export const syllabi = (state) => state.firebase.data.syllabi && state.firebase.data.syllabi.info;

export const populates = [
    { child: "teacher", root: "user", keyProp: "user_id" }, // replace teacher with user object
];

export const getSearchByValueNamePredicate = (searchFilter) => {
    if (searchFilter && searchFilter.length) {
        const pattern = searchFilter.toLowerCase();
        return (entity) => (entity.value && entity.value.syllabus_name && entity.value.syllabus_name.toLowerCase().indexOf(pattern) !== -1);
    }
    return null;
};

export const getSearchByValueSchoolPredicate = (schoolFilter) => {
    if (schoolFilter && schoolFilter.length) {
        return (entity) => (entity.value && entity.value.school_id && (entity.value.school_id + "") === schoolFilter);
    }
    return null;
};

export const getSearchByValueTeacherPredicate = (teacherFilter) => {
    if (teacherFilter && teacherFilter.length) {
        return (entity) => (entity.value && entity.value.teacher && entity.value.teacher.user_id && entity.value.teacher.user_id === teacherFilter);
    }
    return null;
};

const filterMyOwnSyllabiPredicate = (userId) => {
    if (userId) {
        return (entity) => (entity.value && entity.value.teacher && entity.value.teacher.user_id && entity.value.teacher.user_id === userId);
    }
    return null;
};

export const filterByTenantPredicate = (tenantId) => (entity) => (entity.value && entity.value.tenant_id && entity.value.tenant_id === tenantId);
export const filterBySchoolPredicate = (schoolId) => (entity) => (entity.value && entity.value.school_id && (entity.value.school_id + "") === schoolId);

export const getSyllabiList = ({ firebase }) => populate(firebase, "/syllabi/info", populates);

export const compareValueBySyllabusNameCI = (l, r) => {
    const lv = ((l.value && l.value.syllabus_name) || "").toLocaleLowerCase();
    const rv = ((r.value && r.value.syllabus_name) || "").toLocaleLowerCase();
    return lv === rv ? 0 : (lv < rv ? -1 : 1);
};

export const getSyllabi = createSelector(
    getSyllabiList,
    searchFilter,
    getSelectedYearId,
    loggedUserId,
    tenantId,
    schoolFilter,
    teacherFilter,
    (syllabi, filter, selectedYearId, userId, tenantId, schoolFilter, teacherFilter) => {
        if (syllabi) {
            let filteredData = convertDatasetToList(syllabi).sort(compareValueBySyllabusNameCI);
            const namePredicate = getSearchByValueNamePredicate(filter);
            const schoolPredicate = getSearchByValueSchoolPredicate(schoolFilter);
            const teacherPredicate = getSearchByValueTeacherPredicate(teacherFilter);
            const myOwnSyllabiPredicate = filterMyOwnSyllabiPredicate(userId);
            const yearIdPredicate = getSearchByValueYearIdPredicate(selectedYearId);
            const tenantFilter = filterByTenantPredicate(tenantId);
            filteredData = filteredData.filter(yearIdPredicate).filter(tenantFilter);
            if (namePredicate) {
                filteredData = filteredData.filter(namePredicate);
            }
            if (schoolPredicate) {
                filteredData = filteredData.filter(schoolPredicate);
            }
            if (teacherPredicate) {
                filteredData = filteredData.filter(teacherPredicate);
            }
            return {
                mySyllabi: filteredData.filter(myOwnSyllabiPredicate),
                allSyllabi: filteredData
            };
        }
    }
);

export const compareByTextCI = (l, r) => {
    const lv = l.text.toLocaleLowerCase();
    const rv = r.text.toLocaleLowerCase();
    return lv === rv ? 0 : (lv < rv ? -1 : 1);
};

export const getSchoolOptions = createSelector(
    getSchoolsById,
    getSyllabiList,
    tenantId,
    getSelectedYearId,
    (schoolsById, syllabi, tenantId, selectedYearId) => {
        if (syllabi && schoolsById) {
            const options = [];
            const addedSchools = {};
            const yearIdPredicate = getSearchByValueYearIdPredicate(selectedYearId);
            const tenantFilter = filterByTenantPredicate(tenantId);
            convertDatasetToList(syllabi).filter(yearIdPredicate).filter(tenantFilter).map(({ value: { school_id }}) => {
                if (!addedSchools[school_id]) {
                    if (schoolsById[school_id]) {
                        options.push({ key: school_id, value: school_id + "", text: schoolsById[school_id].name });
                        addedSchools[school_id] = true;
                    }
                }
            });
            return options.sort(compareByTextCI);
        }
    }
);

export const getTeacherOptions = createSelector(
    getSyllabiList,
    tenantId,
    getSelectedYearId,
    (syllabi, tenantId, selectedYearId) => {
        if (syllabi) {
            const options = [];
            const addedUser = {};
            const yearIdPredicate = getSearchByValueYearIdPredicate(selectedYearId);
            const tenantFilter = filterByTenantPredicate(tenantId);
            convertDatasetToList(syllabi).filter(yearIdPredicate).filter(tenantFilter).map(({ value: { teacher }}) => {
                if (!addedUser[teacher.user_id]) {
                    options.push({
                        key: teacher.user_id,
                        value: teacher.user_id,
                        text: `${teacher.first_name} ${teacher.last_name}`,
                        description: teacher.email
                    });
                    addedUser[teacher.user_id] = true;
                }
            });
            return options.sort(compareByTextCI);
        }
    }
);

export const getSipiTemplatesForCloningAsOptions = createSelector(
    sipiTemplates,
    getEnabledModules,
    tenantId,
    (sipiTemplates, enabledModules, tenant) => {
        if (sipiTemplates) {
            let filteredData = convertDatasetToList(sipiTemplates).sort(compareValueByNameCI);
            // Filter SPORK only templates from my tenant or subscribed via packages
            filteredData = filteredData.filter(t => !t.value.uuid && (t.value.tenant_id === tenant || !!t.value.m && enabledModules && enabledModules.some(m => t.value.m && t.value.m[m])));
            return filteredData.map(t => ({ key: t.key, value: t.key, text: t.value.name }));
        }
    }
);