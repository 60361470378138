const TYPES = {
    GRID_COLUMNS_UPDATED: "GRID_COLUMNS_UPDATED",
    GRID_OPTIONS_UPDATED: "GRID_OPTIONS_UPDATED"
};

const columnsUpdated = columns => ({ type: TYPES.GRID_COLUMNS_UPDATED, columns });
const optionsUpdated = options => ({ type: TYPES.GRID_OPTIONS_UPDATED, options });

export {
    TYPES,
    columnsUpdated,
    optionsUpdated
};
