import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, TextArea } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";

export default class AddCommentBox extends PureComponent {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onAdd: PropTypes.func.isRequired,
        replyToId: PropTypes.string,
    };

    state = {};

    handleChange = (e, { value }) => {
        this.setState({ value });
    };

    handleAdd = () => {
        const { value } = this.state;
        this.props.onAdd(value, this.props.replyToId);
    };

    render() {
        const { onCancel, replyToId } = this.props;
        const { value } = this.state;

        return (
            <React.Fragment>
                <Form reply>
                    <TextArea autoFocus autoHeight placeholder={(replyToId === undefined) ? "Write new comment here ..." : "Write a reply ..."} rows={1} onChange={this.handleChange} />
                </Form>
                <SaveCancelButtons
                    size="small"
                    saveDisabled={!value}
                    padded
                    submitText="Add"
                    onSave={this.handleAdd}
                    onCancel={onCancel}
                />
            </React.Fragment>
        );
    }
}
