import React from "react";
import PropTypes from "prop-types";
import {
    Dimmer,
    Header,
    Icon,
    Label,
    Loader,
    List,
    Divider,
} from "semantic-ui-react";

import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import ResourceLink from "./ResourceLink";
import ResourceHeader from "./ResourceHeader";
import ResourceChanges from "./ResourceChanges";
import ResourceOwner from "../../component/ResourceOwner";
import ResourceTagsEditor from "../components/ResourceTagsEditor";
import ResourceDescription from "../components/ResourceDescription";
import ResourcePreview from "./ResourcePreview";
import ResourcePublicFlag from "../components/ResourcePublicFlag";
import ResourceSourceType from "../components/ResourceSourceType";
import ResourceTopics from "../components/ResourceTopics";
import styles from "../resources.module.scss";

const compareByName = (l, r) => (l.name || "").localeCompare(r.name || "") || (l.id - r.id);

export default class ResourceDetail extends React.Component {
    static propTypes = {
        resourceId: PropTypes.number,
        resourceData: PropTypes.object,
        onCloseDetails: PropTypes.func,
        userId: PropTypes.string,
        onLoadTags: PropTypes.func,
        isTagsLoading: PropTypes.bool,
        isSavingTags: PropTypes.bool,
        tenantId: PropTypes.number,
        isLoadingDetail: PropTypes.bool,
    };

    componentDidMount() {
        const { onLoadTags } = this.props;
        onLoadTags && onLoadTags();
    }

    render() {
        const { resourceId, resourceData, onCloseDetails, isTagsLoading, userId, isSavingTags, tenantId, isLoadingDetail } = this.props;
        if (!resourceData) {
            const loading = resourceData === undefined || isTagsLoading || isLoadingDetail;
            return (
                <Dimmer.Dimmable dimmed={loading}>
                    <Dimmer active={loading} inverted>
                        <Loader>Loading resource data...</Loader>
                    </Dimmer>
                    <div style={{ textAlign: "right" }}>
                        <Icon name="close" bordered title="Close resource detail" link onClick={onCloseDetails} />
                    </div>
                    <NoDataPlaceholder text="Oh, snap. It is not there.">
                        <p>We did our best but we cannot find the resource.</p>
                    </NoDataPlaceholder>
                </Dimmer.Dimmable>
            );
        }
        const {
            name, href, description, tags, resourceOwnerId, used, tenant, is_public,
            resourceType:type, sourceType, topics, topicIds
        } = resourceData;
        const editValidator = {
            action: "resource:edit",
            data: { userId, resourceOwnerId, tenantId, resourceTenantId: tenant.id }
        };

        return (
            <Dimmer.Dimmable key={resourceId} dimmed={isLoadingDetail}>
                <Dimmer active={isLoadingDetail} inverted>
                    <Loader>Loading resource data...</Loader>
                </Dimmer>
                <ResourceHeader name={name} type={type} onClose={onCloseDetails} 
                    resourceId={String(resourceId)}
                    editValidator={editValidator}
                />
                <ResourceLink type={type} href={href} />
                <ResourcePreview name={name} type={type} href={href} />
                <ResourceDescription description={description} resourceId={resourceId} editValidator={editValidator} />
                <ResourceOwner
                    authorId={resourceOwnerId}
                    resourceId={resourceId}
                    resourceTenantId={tenant.id}
                />
                <ResourceSourceType current={sourceType} resourceId={resourceId} editValidator={editValidator} />
                <Dimmer.Dimmable dimmed={isSavingTags}>
                    <Dimmer active={isSavingTags} inverted>
                        <Loader>Updating resource tags...</Loader>
                    </Dimmer>
                    <Header as="h4">Tags</Header>
                    <span>{tags && tags.length ? (
                        <Label.Group style={{display: "inline"}}>
                            {tags.sort(compareByName).map(tag => <Label key={tag.id}>{tag.name}</Label>)}
                        </Label.Group>
                    ) : <i className={styles.noDataMsg}>No tags assigned.</i>}
                    <ResourceTagsEditor tags={tags} resourceId={resourceId} editValidator={editValidator} />
                    </span>
                </Dimmer.Dimmable>
                <ResourcePublicFlag tenant={tenant} isPublic={is_public} resourceId={resourceId} editValidator={editValidator} />
                <Divider hidden clearing fitted />
                <ResourceChanges
                    created_at={resourceData.created_at}
                    updated_at={resourceData.updated_at}
                />
                {used && (<div>
                    <Header as="h4">Used</Header>
                    <List bulleted>
                        {used.map(cv => (
                            <List.Item key={cv.id}>
                                <List.Header>{cv.name}</List.Header>
                                {cv.displayName}
                            </List.Item>
                        ))}
                    </List>
                </div>)}
                <ResourceTopics topics={topics} topicIds={topicIds} editValidator={editValidator} resourceId={resourceId} name={name} />
            </Dimmer.Dimmable>
        );
    }
}
