import { TYPES } from "./AKitsListActions";

const initialState = {
    searchFilter: "",
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.AKITS_LIST_SEARCH_CHANGED:
            return { ...state, searchFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
