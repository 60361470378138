import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Card, Button, Popup, List, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import AccessControl from "../../../auth";
import "./ModuleCard.scss";
import { tenantId } from "../../../Users/UserProfile/UserProfileSelectors";
import { tenantsInFb } from "../../../External/Tenants/tenantSelectors";
import { onApplyToWidgets } from "../ModulesActions";
import { isUpdating, updatingModuleId } from "../ModulesSelectors";
import cx from "classnames";
import { NavLink } from "react-router-dom";

class ModuleCard extends PureComponent {
    static propTypes = {
        module: PropTypes.shape({
            name: PropTypes.string,
            desc: PropTypes.string,
            kits: PropTypes.array,
            widgets: PropTypes.number,
            problems: PropTypes.number,
            glossary: PropTypes.number,
            templates: PropTypes.number,
            styledBlocks: PropTypes.number,
            is_public: PropTypes.bool,
            subscribers: PropTypes.array,
        }),
        id: PropTypes.string,
        onEdit: PropTypes.func,
        tenantId: PropTypes.number,
        tenantsColl: PropTypes.object,
        onManageKits: PropTypes.func,
        updatingModuleId: PropTypes.string,
        isUpdatingWidgets: PropTypes.bool,
    }

    handleEdit = () => {
        const { id, module, onEdit } = this.props;
        onEdit(id, module);
    }

    handleManageKits = () => {
        const { id, module, onManageKits } = this.props;
        onManageKits(id, module);
    }

    render() {
        const {
            module: {
                name,
                desc,
                is_public,
                tenant_id,
                kits,
                widgets = 0,
                problems = 0,
                glossary = 0,
                styledBlocks = 0,
                templates = 0,
                iPlannerTemps = 0,
                resources = 0,
                subscribers,
                subscribed,
            },
            tenantId,
            tenantsColl,
            updatingModuleId,
            isUpdating,
            id,
        } = this.props;

        const akitCount = kits && Object.keys(kits).length || 0;

        return (
            <Card className={cx("spork packageCard", {"subscribed": subscribed, "public": is_public && !subscribed, "private": !subscribed && !is_public})}>
                <Card.Content>
                    <Card.Header>
                        {name}
                    </Card.Header>
                    <Card.Meta>{desc}&nbsp;</Card.Meta>
                </Card.Content>
                <Card.Content style={{display: "flex"}}>
                    <Icon name="book" size="large" float="left" />

                    <Card.Description style={{flexGrow: 1}}>
                        {akitCount > 0 ? <List>{kits.map(k => <List.Item key={k.id}><NavLink to={`/authorkits/${k.fb_id}`}>{k.name}</NavLink></List.Item>)}</List> 
                            : <div >No Author Kits</div>}
                        
                        {!subscribed && <AccessControl action="aKit:create">
                            <Button
                                content="Assign..."
                                title="Assign Author Kits to the package."
                                floated="right"
                                compact
                                primary={kits === 0}
                                onClick={this.handleManageKits}
                                loading={updatingModuleId === id && isUpdating}
                                disabled={isUpdating}
                            />
                        </AccessControl>}

                    </Card.Description>
                </Card.Content>
                { false && <Card.Content>
                    <Icon name="puzzle piece" size="large" float="left" />
                    <Card.Description className="spork moduleItem">
                        <ModuleItem count={glossary} name="Glossary" />
                        <ModuleItem count={iPlannerTemps} name="iPlanner Templates" />
                        <ModuleItem count={problems} name="Problems" />
                        <ModuleItem count={resources} name="Resources" />
                        <ModuleItem count={styledBlocks} name="Styled Blocks" />
                        <ModuleItem count={widgets} name="Widgets" />
                        <ModuleItem count={templates} name="Widget Templates" />
                    </Card.Description>
                </Card.Content>
                }
                <Card.Content style={{display: "flex", alignItems: "flex-start"}}>
                    <Icon className="drafting compass" size="large" float="left" />
                    <Card.Description>
                        <span>No aTips.</span>
                    </Card.Description>
                </Card.Content>
                {(is_public && !subscribed) && <Card.Content style={{display: "flex", alignItems: "flex-start", flexGrow: 0}}>
                    <Icon name="rss" size="large" float="left" />
                    <Card.Description style={{display: "inherit"}}>
                        <Popup wide
                            trigger={<ModuleItem count={subscribers && subscribers.length || 0} name={subscribers > 1 ? "subscribers" : "subscriber"} />}
                            header="Tenants with subscription for this package"
                            {...(!subscribers || 0 === subscribers.length) && { open: false }}
                            content={subscribers && 0 < subscribers.length && (
                                <List bulleted>
                                    {subscribers.map(tId => <List.Item key={tId}>{tenantsColl && tenantsColl[tId] && tenantsColl[tId].name}</List.Item>)}
                                </List>)}
                        />
                    </Card.Description>
                </Card.Content>
                }
                
                {!subscribed && tenant_id === tenantId && <Card.Content><AccessControl action="modules:manage">
                    <Button content="Edit..." title="Edit package name and sharing status" floated="right" compact onClick={this.handleEdit} />
                </AccessControl>

                </Card.Content>
                }
            </Card>
        );
    }
}

const ModuleItem = ({ count, name, onClick, children }) => (
    <React.Fragment>
        {onClick && count > 0 ? <a onClick={onClick} className="formatCount">{count}</a> : <div className="formatCount">{count}</div>}
        <div onClick={onClick}>{name}</div>
        <div>{children}</div>
    </React.Fragment>);
ModuleItem.propTypes ={
    count: PropTypes.number,
    name: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
};

const mapStateToProps = function(state) {
    return {
        tenantId: tenantId(state),
        tenantsColl: tenantsInFb(state),
        isUpdating: isUpdating(state),
        updatingModuleId: updatingModuleId(state),
    };
};

export default connect(
    mapStateToProps,
    { onApplyToWidgets }
)(ModuleCard);
