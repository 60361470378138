import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import TopicTreeHeader from "./TopicTreeHeader";
import { getTopicCollection, isTopicCollectionRemovable, getSelectedTopicCollection } from "../topicCollectionSelectors";
import { onStartSync, onDeleteCollection } from "../topicCollectionActions";

const mapStateToProps = function(state) {
    return {
        collection: getTopicCollection(state),
        collectionId: getSelectedTopicCollection(state),
        syncInProgress: state.tc.syncInProgress,
        isRemovable: isTopicCollectionRemovable(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onStartSync, onDeleteCollection }, dispatch);
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    )
)(TopicTreeHeader);
