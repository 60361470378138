import React from "react";
import PropTypes from "prop-types";
import BookEditor from "../../../component/seamlessEditor/bookEditor";

export default class WidgetItemTextEditor extends React.PureComponent {
    static propTypes = {
        content: PropTypes.shape({
            html: PropTypes.string,
            raw: PropTypes.object,
        }),
        sharedToolbar: PropTypes.object,
        onRegisterGetContentFn: PropTypes.func.isRequired,
        onDirty: PropTypes.func.isRequired,
    };

    render() {
        const { content, sharedToolbar, onDirty, onRegisterGetContentFn } = this.props;

        return sharedToolbar ? (
            <BookEditor
                features={[
                    "BOLD",
                    "ITALIC",
                    "NORMAL",
                    "H1",
                    "H2",
                    "H3",
                    "UL",
                    "OL",
                    "MATH",
                    "MATHTEXT",
                    "SNIPPET",
                    "TABLE",
                    "TEXTALIGN",
                    "FONT",
                    "FONTSIZE",
                    "IMG",
                    "COLOR",
                    "HELP",
                ]}
                placeholder="Fill default content"
                initialContentState={content && content.raw}
                initialHtml={content && content.html}
                sharedToolbar={sharedToolbar}
                focusOnMount={false}
                onDirty={onDirty}
                registerGetContentFn={onRegisterGetContentFn}
            />
        ) : null;
    }
}
