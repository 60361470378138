const TYPES = {
    TC_SELECT_COLLECTION: "TC/SELECT_COLLECTION",
    TC_ADD_TOPIC: "TC/ADD_TOPIC",
    TC_DELETE_TOPIC: "TC/DELETE_TOPIC",
    TC_MOVE_TOPIC: "TC/MOVE_TOPIC",
    TC_UPDATE_TOPIC: "TC/UPDATE_TOPIC",
    TC_SET_EXPAND_MODE: "TC/SET_EXPAND_MODE",
    TC_ADD_COLLECTION: "TC/ADD_COLLECTION",
    TC_DELETE_COLLECTION: "TC/DELETE_COLLECTION",
    TC_UPDATE_COLLECTION: "TC/UPDATE_COLLECTION",
    TC_START_SYNC: "TC/START_SYNC",
    TC_SYNC_ENDED: "TC/SYNC_ENDED"
};

const onSelectCollection = payload => {
    return {
        type: TYPES.TC_SELECT_COLLECTION,
        payload
    };
};

const onAddTopic = payload => {
    return {
        type: TYPES.TC_ADD_TOPIC,
        payload
    };
};

const onDeleteTopic = payload => {
    return {
        type: TYPES.TC_DELETE_TOPIC,
        payload
    };
};

const onMoveTopic = payload => {
    return {
        type: TYPES.TC_MOVE_TOPIC,
        payload
    };
};

const onUpdateTopic = payload => {
    return {
        type: TYPES.TC_UPDATE_TOPIC,
        payload
    };
};

const onExpandTopic = payload => {
    return {
        type: TYPES.TC_SET_EXPAND_MODE,
        payload
    };
};

const onAddCollection = payload => {
    return {
        type: TYPES.TC_ADD_COLLECTION,
        payload
    };
};

const onDeleteCollection = payload => {
    return {
        type: TYPES.TC_DELETE_COLLECTION,
        payload
    };
};

const onUpdateCollection = payload => {
    return {
        type: TYPES.TC_UPDATE_COLLECTION,
        payload
    };
};

const onStartSync = payload => {
    return {
        type: TYPES.TC_START_SYNC,
        payload
    };
};

const onEndSync = payload => {
    return {
        type: TYPES.TC_SYNC_ENDED,
        payload
    };
};

export {
    TYPES,
    onSelectCollection,
    onAddTopic,
    onDeleteTopic,
    onMoveTopic,
    onUpdateTopic,
    onExpandTopic,
    onAddCollection,
    onDeleteCollection,
    onUpdateCollection,
    onStartSync,
    onEndSync
};
