const TYPES = {
    TOPIC_ADD_CONCEPT: "TOPIC_ADD_CONCEPT",
    TOPIC_ADD_KEYWORD: "TOPIC_ADD_KEYWORD",
    TOPIC_ADD_OBJECTIVE_SET: "TOPIC_ADD_OBJECTIVE_SET",
    TOPIC_ADD_OBJECTIVE: "TOPIC_ADD_OBJECTIVE",
    TOPIC_SELECT_OBJECTIVE_SET: "TOPIC_SELECT_OBJECTIVE_SET",
    TOPIC_ADD_TOPIC: "TOPIC_ADD_TOPIC",
    TOPIC_ADD_OII_OBJECTIVE: "TOPIC_ADD_OII_OBJECTIVE",
    TOPIC_ADD_TOPIC_ERROR: "TOPIC_ADD_TOPIC_ERROR",
    TOPIC_ADD_TOPIC_CLEAR_ERROR: "TOPIC_ADD_TOPIC_CLEAR_ERROR",
    TOPIC_DELETE_TOPIC: "TOPIC_DELETE_TOPIC",
    TOPIC_UPDATE_TOPIC: "TOPIC_UPDATE_TOPIC",
    TOPIC_SHOW_DIALOG: "TOPIC_SHOW_DIALOG",
    TOPIC_ADD_HEADING: "TOPIC_ADD_HEADING",
    TOPIC_ADD_SUBHEADING: "TOPIC_ADD_SUBHEADING"
};

const onAddConcept = (value, callbackOnError) => {
    return {
        type: TYPES.TOPIC_ADD_CONCEPT,
        value,
        callbackOnError,
    };
};

const onAddKeyword = value => {
    return {
        type: TYPES.TOPIC_ADD_KEYWORD,
        value
    };
};

const onAddObjectiveSet = value => {
    return {
        type: TYPES.TOPIC_ADD_OBJECTIVE_SET,
        value
    };
};

const onAddObjective = (objectiveSetId, value, callback) => {
    return {
        type: TYPES.TOPIC_ADD_OBJECTIVE,
        value,
        objectiveSetId,
        callback
    };
};

const onSelectObjectiveSet = value => {
    return {
        type: TYPES.TOPIC_SELECT_OBJECTIVE_SET,
        value
    };
};

const onAddTopic = (value, belaTopic) => {
    return {
        type: TYPES.TOPIC_ADD_TOPIC,
        value,
        belaTopic
    };
};

const onAddOiiObjective = (value, onError) => {
    return {
        type: TYPES.TOPIC_ADD_OII_OBJECTIVE,
        value,
        onError
    };
};

const onAddTopicError = value => {
    return {
        type: TYPES.TOPIC_ADD_TOPIC_ERROR,
        value
    };
};

const onClearAddTopicError = value => {
    return {
        type: TYPES.TOPIC_ADD_TOPIC_CLEAR_ERROR,
        value
    };
};

const onDeleteTopic = (topic, callback) => {
    return {
        type: TYPES.TOPIC_DELETE_TOPIC,
        topic,
        callback
    };
};

const onUpdateTopic = (key, hashKey, value ) => {
    return {
        type: TYPES.TOPIC_UPDATE_TOPIC,
        key,
        hashKey,
        value
    };
};

const onTopicShowDialog = (value ) => {
    return {
        type: TYPES.TOPIC_SHOW_DIALOG,
        value
    };
};

const onAddHeading = (value ) => {
    return {
        type: TYPES.TOPIC_ADD_HEADING,
        value
    };
};

const onAddSubHeading = (value ) => {
    return {
        type: TYPES.TOPIC_ADD_SUBHEADING,
        value
    };
};

export {
    TYPES,
    onAddConcept,
    onAddKeyword,
    onAddObjectiveSet,
    onAddObjective,
    onSelectObjectiveSet,
    onAddTopic,
    onAddOiiObjective,
    onAddTopicError,
    onClearAddTopicError,
    onDeleteTopic,
    onUpdateTopic,
    onTopicShowDialog,
    onAddHeading,
    onAddSubHeading
};
