
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AddExistingWidget from "./AddExistingWidget";
import {
    onSetSearchFilter,
    onSetTopicFilter,
} from "../LessonContentActions";
import {
    filteredWidgetsAsOptions,
    searchFilter,
    getTopicFilter,
} from "../LessonContentSelectors";
import { getWidgetsInfo, getWidgetLibraryData } from "../../WidgetLibrary/WidgetLibrarySelectors";

const mapStateToProps = (state, props) => ({
    widgetCollection: getWidgetsInfo(state),
    widgetOptions: filteredWidgetsAsOptions(state, props),
    searchFilter: searchFilter(state),
    topicFilter: getTopicFilter(state),
    widgetsData: getWidgetLibraryData(state),
});

const dispatchToProps = dispatch => bindActionCreators({
    onSetSearchFilter,
    onSetTopicFilter,
}, dispatch);

const AddExistingWidgetContainer = connect(
    mapStateToProps,
    dispatchToProps
)(AddExistingWidget);

AddExistingWidgetContainer.displayName = "AddExistingWidgetContainer";

export default AddExistingWidgetContainer;