import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { InfiniteTable, ConfirmDeletePopup, AddItemButton, EditActionIcon } from "bmd-react";
import AddTopic from "../AddTopic";
import ObjectiveType from "../components/ObjectiveType";

export default class TopicList extends React.PureComponent {
    state = { };

    _renderActionIcons = ({ rowData }) => (
        <div className="actionIconClass right">
            <EditActionIcon data={rowData} onClick={this._handleEdit} />
            <ConfirmDeletePopup key={rowData.key} data={rowData} onConfirm={this._handleDelete} />
        </div>
    );

    _handleDelete = (callback, e, { data }) => {
        this.props.onDelete(data, callback);
    };

    _handleEdit = data => {
        let topicDefaultValues = {
            key: data.key,
            hashKey: data.hashKey,
            conceptValue: data.concept.name,
            keywordValues: data.keywords ? Object.keys(data.keywords) : [],
            descriptionValue: data.description
        };

        if (data.objective.type === "OII") {
            topicDefaultValues.oiiObjectiveValue = data.objective.name;
        } else {
            topicDefaultValues.objectiveKey = data.objective.id;
            topicDefaultValues.objectiveSetKey = data.objective_set.id;
        }
        if (data.heading) {
            topicDefaultValues.headingValue = data.heading.name;
        }
        if (data.subheading) {
            topicDefaultValues.subheadingValue = data.subheading.name;
        }
        this.setState({
            topicToEdit: topicDefaultValues
        });
        this.handleOpen();
    };

    _columns = [
        {
            label: "Heading",
            dataKey: "heading.name",
            flexGrow: 1,
            width: 150,
            visible: true
        },
        {
            label: "Subheading",
            dataKey: "subheading.name",
            flexGrow: 1,
            width: 150,
            visible: true
        },
        {
            label: "Concept",
            dataKey: "concept.name",
            width: 150,
            flexGrow: 1,
            visible: true
        },
        {
            label: "Objective Name",
            dataKey: "objective.name",
            width: 150,
            flexGrow: 1,
            visible: true
        },
        {
            label: "Type",
            dataKey: "objective.type",
            width: 100,
            visible: true,
            cellRenderer: ({ cellData }) => <ObjectiveType text={cellData} />
        },
        {
            label: "Objective Set",
            dataKey: "objective_set.name",
            width: 150,
            flexGrow: 1,
            visible: true
        },
        {
            label: "Keywords",
            dataKey: "keywords",
            width: 150,
            visible: true,
            flexGrow: 1,
            cellRenderer: ({ cellData }) =>
                Object.keys(cellData || {})
                    .join()
                    .toString(),
            filterMethod: ({ filter, cellData }) =>
                Object.keys(cellData || {}).some(keyword => keyword.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        },
        {
            dataKey: "",
            width: 70,
            flexGrow: 1,
            visible: true,
            cellRenderer: this._renderActionIcons
        }
    ];

    handleOpen = () => {
        this.props.onTopicShowDialog(true);
    };

    handleClose = () => {
        this.setState({ topicToEdit: undefined });
        this.props.onTopicShowDialog(false);
    };

    render() {
        const { topics, showDialog } = this.props;
        const { topicToEdit } = this.state;

        return (
            <div style={{ minHeight: "350px" }}>
                <AddItemButton inline onClick={this.handleOpen} buttonText="Add new topic" />
                <InfiniteTable data={topics} columns={this._columns} tableName="topics" />

                <Modal open={showDialog}>
                    <Modal.Content>
                        <AddTopic onCancel={this.handleClose} allowEditObjectives={false} defaultValues={topicToEdit} />
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

TopicList.propTypes = {
    topics: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            concept: PropTypes.object,
            objective: PropTypes.object,
            objective_set: PropTypes.object,
            keywords: PropTypes.object
        })
    ),
    onDelete: PropTypes.func.isRequired,
    onTopicShowDialog: PropTypes.func.isRequired,
    showDialog: PropTypes.bool
};
