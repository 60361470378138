const TYPES = {
    SECTION_USER_SELECT_USER: "SECTION_USER_SELECT_USER",
    SECTION_USER_SET_USER_DETAILS: "SECTION_USER_SET_USER_DETAILS",
    SECTION_USER_SEARCH_PERSON: "SECTION_USER_SEARCH_PERSON",
    SECTION_USER_SET_SEARCH_RESULT: "SECTION_USER_SET_SEARCH_RESULT",
    SECTION_USER_GO_BACK: "SECTION_USER_GO_BACK",
    SECTION_USER_SET_FIELD_VALUE: "SECTION_USER_SET_FIELD_VALUE",
};

const onSelectUser = (payload) => ({
    type: TYPES.SECTION_USER_SELECT_USER,
    payload
});

const onSetUserDetails = (payload) => ({
    type: TYPES.SECTION_USER_SET_USER_DETAILS,
    payload
});

const onSearch = (payload) => ({
    type: TYPES.SECTION_USER_SEARCH_PERSON,
    payload
});


const onSetSearchResult = (payload) => ({
    type: TYPES.SECTION_USER_SET_SEARCH_RESULT,
    payload
});

const onGoToSearchForm = () => ({
    type: TYPES.SECTION_USER_GO_BACK,
});

const onSetFieldValue = (payload) => ({
    type: TYPES.SECTION_USER_SET_FIELD_VALUE,
    payload
});

export {
    TYPES,
    onSelectUser,
    onSetUserDetails,
    onSearch,
    onSetSearchResult,
    onGoToSearchForm,
    onSetFieldValue,
};
