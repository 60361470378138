import DashboardTile from "../../dashboardTile/DashboardTile";
import React from "react";

import { Button } from "semantic-ui-react";

const ReportTile = (props) => (
    <DashboardTile title="Reports" {...props}>
        <Button primary style={{ marginTop: "30px" }}>BeLA</Button>
    </DashboardTile>
);

export default ReportTile;