import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List, Icon } from "semantic-ui-react";

export default class SchoolListItem extends PureComponent {
    static propTypes = {
        school: PropTypes.object,
        id: PropTypes.number,
        onLoadSchool: PropTypes.func,
    }

    componentDidMount() {
        const { id, onLoadSchool } = this.props;
        onLoadSchool(id);
    }

    render() {
        const { school, id } = this.props;
        return (
            <List.Item>
                {id && !school ? (
                    <Icon name="circle notched" loading />
                ) : `${school.name}`}
            </List.Item>
        );
    }
}
