import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Header, Icon, Segment } from "semantic-ui-react";
import Lock, { LockPropType, LockTypeShape } from "./index";
import { withLockFromProps } from "./index";

const LockableBlock = ({
    isLocked,
    isLockedByMe,
    lockType,
    lock,
    hideLockButton,
    cantBeLocked,
    accessContext,
    children
}) => (
    <React.Fragment>
        <Lock
            isLocked={isLocked}
            lockId={lock && lock.id}
            lockType={lockType}
            hideLockButton={hideLockButton}
            cantBeLocked={cantBeLocked}
            accessContext={accessContext}
        />
        {children && (
            <Dimmer.Dimmable dimmed={isLocked && !isLockedByMe}>
                <Dimmer active={isLocked && !isLockedByMe} inverted style={{ zIndex: 560 }}>
                    <Segment basic>
                        <Header icon>
                            <Icon className="lock alternate thin" />
                            This item is locked for editing.
                        </Header>
                    </Segment>
                </Dimmer>
                {children}
            </Dimmer.Dimmable>
        )}
    </React.Fragment>
);

LockableBlock.propTypes = {
    children: PropTypes.node,
    /* define the lock which locked this item (id and by) */
    lock: LockPropType,
    /* true if this item has a lock */
    isLocked: PropTypes.bool,
    /* true if the lock was created by currently logged in user */
    isLockedByMe: PropTypes.bool,
    /* lock type (lesson/set/problem/widget) and itemId which is locked */
    lockType: LockTypeShape,
    /* if true, hide the Lock/Unlock button. Only dimmer remains */
    hideLockButton: PropTypes.bool,
    /* if true, the item can't be locked because something is preventing that */
    cantBeLocked: PropTypes.bool,
    /* Optional access control context data (to evaluate access to lock/unlock) */
    accessContext: PropTypes.object,
};


export default withLockFromProps(LockableBlock);
