import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Segment, List, Checkbox } from "semantic-ui-react";
import { getCvGuid } from "../../../../utils/selectorUtils";

export default class CourseVariantList extends PureComponent {
    static propTypes = {
        variants: PropTypes.object,
        selectedVariants: PropTypes.object,
        onToggle: PropTypes.func,
        canEdit: PropTypes.bool,
    };

    render() {
        const { variants, selectedVariants, onToggle, canEdit } = this.props;
        return (
            <Segment style={{ height: "18em", overflowY: "auto" }}>
                {variants && (
                    <List>
                        {Object.keys(variants).map(year => (
                            <List.Item key={year}>
                                <List.Header>{year}</List.Header>
                                <List.List>
                                    {variants[year].map(cv => (
                                        <List.Item key={cv.id}>
                                            {canEdit ? (
                                                <Checkbox
                                                    id={getCvGuid(cv)}
                                                    onChange={onToggle}
                                                    label={cv.name}
                                                    checked={(selectedVariants && selectedVariants[getCvGuid(cv)]) || false}
                                                />
                                            ) : (
                                                cv.name
                                            )}
                                            <List.Description {...canEdit && { style: { paddingLeft: "1.8em" } }}>
                                                <em>{cv.description}{cv.tenantDesc}</em>
                                            </List.Description>
                                        </List.Item>
                                    ))}
                                </List.List>
                            </List.Item>
                        ))}
                    </List>
                )}
            </Segment>
        );
    }
}
