import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ConfirmDeletePopup } from "bmd-react";
import { Icon } from "semantic-ui-react";

export default class DeleteItem extends PureComponent {
    static propTypes = {
        itemId: PropTypes.string,
        onRemoveItem: PropTypes.func,
        deleteText: PropTypes.string,
        trigger: PropTypes.node,
    };

    handleRemoveItem = callback => {
        callback();
        this.props.onRemoveItem(this.props.itemId);
    };

    render() {
        const { deleteText, trigger } = this.props;
        return (
            <ConfirmDeletePopup
                position="right center"
                whatText={deleteText}
                trigger={trigger ||
                    <Icon
                        name="trash alternate outline"
                        link
                        className="actionIconClass right"
                        title={`Remove ${deleteText}`}
                    />
                }
                onConfirm={this.handleRemoveItem}
            />
        );
    }
}