import CreateKit from "./CreateKit";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { isOpen, isCreating, getCourseVariantsAsDropdown } from "./CreateKitSelectors";
import { onClose, onCreate, onOpen } from "./CreateKitActions";
import { onAllCourseVariantsLoad } from "../AKitEditor/AKitEditorActions";
import { isLoading } from "../../../External/CourseVariantList/courseVariantsSelectors";

const dispatchToProps = dispatch => {
    return bindActionCreators({ onClose, onCreate, onOpen, onAllCourseVariantsLoad }, dispatch);
};

export default compose(
    connect(
        state => ({
            open: isOpen(state),
            courseVariants: getCourseVariantsAsDropdown(state),
            creating: isCreating(state),
            isLoading: isLoading(state),
        }),
        dispatchToProps
    )
)(CreateKit);
