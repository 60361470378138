import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader, List, Header, Divider, Table, Icon, Item, Segment, Label } from "semantic-ui-react";
import SchoolListItem from "./component/SchoolListItem";
import SectionListItem from "./component/SectionListItem";
import UserSearch from "./component/UserSearch";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import UserAvatar from "react-user-avatar";
import { ClassRoster } from "../SchoolCourses/component/ClassRoster";
import AccessControl from "../../auth/AccessControl";

export default class UserCourses extends PureComponent {
    static propTypes = {
        selectedUserId: PropTypes.number,
        onSelectUser: PropTypes.func,
        userDetails: PropTypes.object,
        isLoadingUser: PropTypes.bool,
        teachers: PropTypes.array,
        students: PropTypes.array,
        onSelectSection: PropTypes.func,
        selectedSectionId: PropTypes.number,
        onGoToSearchForm: PropTypes.func,
        isMatchingLoggedUser: PropTypes.bool,
    }

    componentDidMount() {
        const { selectedUserId, onSelectUser, onSelectSection } = this.props;
        if (selectedUserId) {
            onSelectUser(selectedUserId);
            onSelectSection(null);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedUserId == null && this.props.selectedUserId) {
            this.props.onSelectUser(this.props.selectedUserId);
            this.props.onSelectSection(null);
        }
    }
    
    renderDetails(isRestricted) {
        const { isLoadingUser, userDetails, onSelectUser, teachers, students, selectedSectionId, selectedUserId, onGoToSearchForm } = this.props;
        return (
            <React.Fragment>
                {(userDetails && !!selectedUserId) && <React.Fragment>
                    <Header size="large" className="pageHeader">Classes</Header>
                    {!isRestricted && <div style={{ paddingBottom: "0.5em" }}>
                        <a style={{ cursor: "pointer"}} onClick={onGoToSearchForm} >
                            <Icon name="left arrow" /> Back to searching for another person
                        </a>
                    </div>}
                </React.Fragment>
                }
                <Dimmer.Dimmable dimmed={isLoadingUser}>
                    <Dimmer active={isLoadingUser} inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                    {isLoadingUser ? <div style={{ minHeight: "10em" }} /> : (userDetails && !!selectedUserId ? <React.Fragment>
                        
                        <UserBadge userDetails={userDetails} />

                        {!userDetails || !userDetails.sections || 0 === userDetails.sections.length ? (
                            <NoDataPlaceholder
                                text="No data to display."
                            >
                                <p>We looked everywhere but this person seems to have no classes in the selected school year.</p>
                            </NoDataPlaceholder>
                        ): (
                            <React.Fragment>
                                <Divider clearing fitted hidden />
                                <Table selectable compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width="9">Student Crew
                                                <br /><span style={{fontWeight: "normal", color: "grey"}}>
                                                    (select row to display class roster)</span>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width="4">School Course</Table.HeaderCell>
                                            <Table.HeaderCell width="3">School</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {userDetails && userDetails.sections && userDetails.sections.map(s => (
                                            <SectionListItem key={s.id} id={s.id} section={s} readOnly={isRestricted}/>
                                        ))}
                                    </Table.Body>
                                </Table>
                                {selectedSectionId && (
                                    <ClassRoster students={students} teachers={teachers} isLoading={isLoadingUser} onSelectUser={onSelectUser} readOnly={isRestricted}/>

                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment> : (isLoadingUser || isRestricted) ? <div style={{ minHeight: "10em" }} /> : (
                        <UserSearch />
                    )
                    )}
                
                </Dimmer.Dimmable>
            </React.Fragment>
        );
    }

    renderNoAccess = () => (this.props.isMatchingLoggedUser && this.renderDetails(true));

    render() {
        return <AccessControl action="tablet:manage" renderNoAccess={this.renderNoAccess}>{this.renderDetails()}</AccessControl>;
    }
}

class UserBadge extends PureComponent {
    static propTypes = {
        userDetails: PropTypes.object,
    };

    render() {
        const { userDetails } = this.props;
        return (
            <Segment compact style={{minWidth: 300}}>
                <Item.Group>
                    <Item>
                        {userDetails.scope == "teacher" ? <UserAvatar size="64" name={userDetails.first_name+" "+userDetails.last_name} style={{fontSize: "2em", marginRight: "1em", float: "left"}} />                          
                            : <Icon name="user" size="huge" style={{color: "lightgrey", marginRight: "0.5em"}} />}
                        <Item.Content>
                            <Item.Header>{userDetails.first_name} {userDetails.last_name}</Item.Header>
                            {(userDetails.schools && 0 < userDetails.schools.length) && <Item.Meta style={{textTransform: "capitalize"}}>
                                {userDetails.scope}
                            </Item.Meta>
                            }
                            {userDetails.valid == false && <Item.Description>
                                <Label color="red">INVALID</Label>
                            </Item.Description>}
                            <Item.Extra>
                                <List >
                                    {userDetails.schools && userDetails.schools.map(s => <SchoolListItem key={s.id} id={s.id} />)}
                                </List>
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
        );
    }

}
