import ToolbarOffset from "./ToolbarOffset";
import { getAbsoluteAncestor } from "./utils/domUtils";

const TBAR_PADDING = 5;  // Corresponds to padding of .rdw-editor-toolbar (left & right)
const WIDTH_OFFSET = TBAR_PADDING + 2; // Corresponds to padding + border.
// If we allow styling of nested editors, it should be calculated (and stored) per toolbar.
const px = (value) => value + "px";

const setStyleTo = (node, style) => (
    Object.entries(style).forEach(([key, value]) => (node.style[key] = value))
);

export default class SharedToolbar {
    constructor() {
        this.container = null;
        this.containerBase = null;
        this.containerRect = null;
        this.currentOffset = null;
        this.currentEditor = null;
        this.registeredEditors = {};
        this.isMounted = false;
    }

    mount = (container) => {
        this.container = container;
        this.containerBase = getAbsoluteAncestor(container);
        this.containerRect = container.getBoundingClientRect();
        window.addEventListener("resize", this.onResize, false);
        window.addEventListener("scroll", this.onScroll, false);
        window.addEventListener("rerender", this.onScroll, false);
        this.isMounted = true;
    };

    unmount = () => {
        window.removeEventListener("resize", this.onResize, false);
        window.removeEventListener("scroll", this.onScroll, false);
        window.removeEventListener("rerender", this.onScroll, false);
        this.containerBase = null;
        this.isMounted = false;
    };

    getToolbarOffset = () => (
        this.currentOffset && this.currentOffset.getPositionOffset()
    );

    getButtonsOffset = () => {
        if (this.containerRect) {
            const offset = this.getToolbarOffset();
            if (offset) {
                offset.left += this.containerRect.width;
                return offset;
            }
        }
        return null;
    };

    adjustOffset = () => {
        const { currentEditor } = this;
        if (currentEditor) {
            const offset = this.getToolbarOffset();
            if (offset) {
                setStyleTo(currentEditor.toolbarNode, {
                    top: px(offset.top),
                    left: px(offset.left),
                });
            }
        }
    }

    adjustHeight = () => {
        const { container, currentEditor } = this;
        if (currentEditor) {
            const { height } = currentEditor.toolbarNode.getBoundingClientRect();
            const modified = container.clientHeight !== height;
            if (modified) {
                setStyleTo(container, { height: px(height) });
            }
        }
    };

    onResize = () => {
        const { currentEditor, currentOffset } = this;
        if (!currentEditor || !currentOffset) {
            return;
        }

        const newRect = this.container.getBoundingClientRect();
        const oldRect = this.containerRect;
        const resized = newRect.left !== oldRect.left || newRect.width !== oldRect.width;
        this.containerRect = newRect;

        if (resized) {
            const offset = this.getToolbarOffset();
            setStyleTo(currentEditor.toolbarNode, {
                left: px(offset.left),
                top: px(offset.top),
                width: px(newRect.width - WIDTH_OFFSET),
            });
        }
    };

    onScroll = () => {
        const { currentEditor, currentOffset } = this;
        if (!currentEditor || !currentOffset) {
            return;
        }

        const newRect = this.container.getBoundingClientRect();
        this.containerRect = newRect;

        const offset = this.getToolbarOffset();
        setStyleTo(currentEditor.toolbarNode, {
            left: px(offset.left),
            top: px(offset.top),
        });
    };

    add = (editorKey, bookEditor, toolbarNode, addAsReadOnly = true) => {
        if (this.registeredEditors[editorKey]) {
            throw new Error(`Development error: SharedToolbar is in unpredictable state! "editorKey" = ${editorKey} already exists`);
        }

        this.registeredEditors[editorKey] = { bookEditor, toolbarNode };
        // Added toolbar is not shown by default. It is task for `setActive` to show it.
        toolbarNode.style.display = "none";
        toolbarNode.dataset.bookEditorKey = editorKey;  // added for simpler debugging

        if (addAsReadOnly) {
            bookEditor.setReadOnly(true);
        }
    };

    remove = (editorKey) => {
        if (!this.registeredEditors[editorKey]) {
            // eslint-disable-next-line no-console
            console.error(`Development error: SharedToolbar is in unpredictable state! "editorKey" = ${editorKey} not found`);
            return;
            // throw new Error(`Development error: SharedToolbar is in unpredictable state! "editorKey" = ${editorKey} not found`);
        }

        if (this.currentEditor === this.registeredEditors[editorKey]) {
            this.currentEditor = null;
        }
        delete this.registeredEditors[editorKey];
    };

    setActive = (editorKey) => {
        const { container, currentEditor, registeredEditors } = this;
        if (currentEditor === registeredEditors[editorKey]) {
            return; // already active
        }

        if (currentEditor) {
            currentEditor.toolbarNode.style.display = "none";
            currentEditor.bookEditor.setReadOnly(true);
            currentEditor.bookEditor.removePersistentSelection();
        }
        this.currentEditor = registeredEditors[editorKey];
        const { toolbarNode } = this.currentEditor;

        this.containerRect = container.getBoundingClientRect();
        this.currentOffset = new ToolbarOffset(toolbarNode, container, this.containerBase);
        const offset = this.getToolbarOffset();

        setStyleTo(toolbarNode, {
            display: "flex",
            position: "fixed",
            left: px(offset.left),
            top: px(offset.top),
            width: px(this.containerRect.width - WIDTH_OFFSET),
            zIndex: 555,
        });
        setStyleTo(container, {
            height: px(toolbarNode.getBoundingClientRect().height)
        });
        this.adjustHeight();

        setTimeout(() => {
            this.adjustOffset();
        }, 0);
    };

    getActive = () => this.currentEditor;

    show = () => this.currentEditor && setStyleTo(this.currentEditor.toolbarNode, { visibility: "visible" });

    hide = () => this.currentEditor && setStyleTo(this.currentEditor.toolbarNode, { visibility: "hidden" });

}
