import { createSelector } from "reselect";
import { getUsersAsArray } from "../../Users/UserList/UserListSelectors";
import { getModulesByTenant, getPublicModules, modulesList } from "../../KitBuilder/Modules/ModulesSelectors";
import { tenantId as getTenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { compareByNameCI } from "../../utils/selectorUtils";
import { getSubscribedModules, editedTenantId } from "./sharedTenantSelectors";

export const tenantsInFb = ({ firebase }) => firebase.data.tenants;
const tenantsList = state => state.external.tenant.tenantList;
export const isLoading = state => state.external.tenant.isLoading;
export const isCreating = state => state.external.tenant.isCreating;
export const newTenantInfo = state => state.external.tenant.newTenantInfo;

export const getTenants = createSelector(
    tenantsList,
    getUsersAsArray,
    getModulesByTenant,
    (tenants, users, modules) => {
        if (tenants && users && modules) {
            const usersWithRole = users.filter(u => 0 < u.roleArray.length);
            const tenantStats = {};
            for (const user of usersWithRole) {
                for (const role of user.roleArray) {
                    const tenant = user.tenant_id;
                    if (tenantStats[tenant]) {
                        if (tenantStats[tenant][role]) {
                            tenantStats[tenant][role]++;
                        } else {
                            tenantStats[tenant][role] = 1;
                        }
                    } else {
                        tenantStats[tenant] = { [role]: 1 };
                    }
                }
            }
            const tenantsWithStats = [];
            for (const tenant of tenants) {
                tenantsWithStats.push({ 
                    ...tenant,
                    users: tenantStats[tenant.id] ? tenantStats[tenant.id] : null,
                    modules: modules[tenant.id] ? modules[tenant.id] : {}
                });
            }
            return tenantsWithStats;
        }
    }
);

export const getModulesForSubscribe = createSelector(
    getPublicModules,
    editedTenantId,
    (list, tenantId) => {
        if (list) {
            return list.filter(({value}) => value.t !== tenantId);
        }
    }
);

export const getModulesOptions = createSelector(
    getModulesByTenant,
    getTenantId,
    getSubscribedModules,
    modulesList,
    (modulesByTenant, tenant, subscribed, modulesList) => {
        if (modulesByTenant && subscribed) {
            const modulesOptions = [];
            if (modulesByTenant[tenant]) {
                for (const moduleId of Object.keys(modulesByTenant[tenant])) {
                    const module = modulesByTenant[tenant][moduleId];
                    modulesOptions.push({ key: moduleId, value: moduleId, text: module.name });
                }
            }
            if (subscribed) {
                for (const moduleId of Object.keys(subscribed)) {
                    const module = modulesList[moduleId];
                    modulesOptions.push({ key: moduleId, value: moduleId, text: module.name });
                }
            }
            return modulesOptions.sort(compareByNameCI);
        }
    }
);

export const getEnabledModules = createSelector(
    getModulesByTenant,
    getTenantId,
    getSubscribedModules,
    (modulesByTenant, tenant, subscribed) => {
        if (modulesByTenant && subscribed) {
            const modules = [];
            if (modulesByTenant[tenant]) {
                for (const moduleId of Object.keys(modulesByTenant[tenant])) {
                    modules.push(moduleId);
                }
            }
            if (subscribed) {
                for (const moduleId of Object.keys(subscribed)) {
                    modules.push(moduleId);
                }
            }
            return modules;
        }
    }
);