import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import TopicCollection from "./TopicCollection";
import { getSelectedTopicCollection, getTopicCollectionList, populates } from "./topicCollectionSelectors";
import { firebaseConnect } from "react-redux-firebase";
import { spinnerWhileLoading } from "../component/spinnerWhileLoading.jsx";
import { userClaims } from "../Users/UserProfile/UserProfileSelectors";
import { isAuthorAtLeast } from "../Users/roleUtils";

const mapStateToProps = function(state) {
    return {
        collections: getTopicCollectionList(state),
        selectedCollection: getSelectedTopicCollection(state),
        canEdit: isAuthorAtLeast(userClaims(state)),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default compose(
    firebaseConnect(() => {
        return [{ path: "/topic_collections/list", populates }, "tenants"];
    }),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    // show loading spinner while data are loading
    spinnerWhileLoading(["collections"])
)(TopicCollection);
