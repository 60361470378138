import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, List, Button, Dimmer, Loader } from "semantic-ui-react";
import ActionLink from "../../../../component/ActionLink";
import FilterMenu from "../../../../component/FilterMenu";
import { getHighestOrdering } from "../utils";
import ExtLinksConfirmDialog from "../../AddExistingWidget/ExtLinksConfirmDialog";

export default class AddExistingProblemSet extends PureComponent {
    static propTypes = {
        entity: PropTypes.string,
        trigger: PropTypes.node,
        filteredProblemSets: PropTypes.array,
        onSetTitleFilter: PropTypes.func,
        titleFilter: PropTypes.string,
        lessonProblemSets: PropTypes.array,
        outlineId: PropTypes.string,
        lessonId: PropTypes.string,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onSetProblemSet: PropTypes.func,
        collection: PropTypes.string,
        onSelect: PropTypes.func,
        onCheckedProblemSet: PropTypes.func,
        isChecking: PropTypes.bool,
        checkResult: PropTypes.object,
        allProblemSets: PropTypes.object,
        onCloneProblemSet: PropTypes.func,
    };

    state = {
        isOpen: false,
    };

    handleOpen = () => {
        this.setState({ isOpen: true });
        const { onSelect, onSetTitleFilter, onCheckedProblemSet } = this.props;
        onSetTitleFilter("");
        onSelect && onSelect();
        onCheckedProblemSet(null);
    };
    handleClose = (e) => {
        e && e.stopPropagation();
        this.setState({ isOpen: false });
    }

    handleSetTitleFilter = (e, { value }) => {
        this.props.onSetTitleFilter(value);
    };

    handleSetProblemSet = (e, id, force) => {
        e.stopPropagation();
        const { outlineId, lessonId, tabIndex, onSetProblemSet, collection, lessonProblemSets } = this.props;
        onSetProblemSet({
            id,
            outlineId,
            lessonId,
            tabIndex,
            collection,
            ordering: getHighestOrdering(lessonProblemSets) + 1,
            redirectToEditor: false,
            checkProblemSet: !force,
            callback: this.handleClose
        });
    }

    handleAddProblemSet = (e, { id}) => {
        this.handleSetProblemSet(e, id, false);
    }

    handleForceAddProblemSet = (e) => {
        const { checkResult: { problemSetId }} = this.props;
        this.handleSetProblemSet(e, problemSetId, true);
    }

    handleCancelExternalLinkWarning = () => {
        this.props.onCheckedProblemSet(null);
    }

    handleCloneProblemSet = (e) => {
        e.stopPropagation();
        const { outlineId, lessonId, onCloneProblemSet, collection, lessonProblemSets, checkResult: { problemSetId } } = this.props;
        onCloneProblemSet({
            problemSetId,
            outlineId,
            lessonId,
            collection,
            ordering: getHighestOrdering(lessonProblemSets) + 1,
        });
        this.handleClose(e);
    }

    render() {
        const { isOpen } = this.state;
        const { entity, trigger, filteredProblemSets, titleFilter, isChecking, checkResult, allProblemSets } = this.props;
        const selectedProblemsetName = allProblemSets && checkResult && checkResult.problemSetId && allProblemSets[checkResult.problemSetId] && allProblemSets[checkResult.problemSetId].title;
        return (
            <Modal
                open={isOpen}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                trigger={trigger || <ActionLink icon="plus" content="Add existing problem set" />}
                size="small"
            >
                <Modal.Header>Add existing problem set to lesson {entity}</Modal.Header>
                <Modal.Content>
                    <ExtLinksConfirmDialog
                        onClose={this.handleCancelExternalLinkWarning}
                        isOpen={checkResult && checkResult.error}
                        onAdd={this.handleForceAddProblemSet}
                        title="Problem set contains link to another outline"
                        onClone={this.handleCloneProblemSet}
                    >
                     Problem set <span className="sporkSecondaryColor">{selectedProblemsetName}</span> contains link to lesson in another
                    outline, are you sure you want to use this problem set or do you want to clone problem set and all problems,
                    that have links to another outline.
                    </ExtLinksConfirmDialog>
                    <FilterMenu
                        onChange={this.handleSetTitleFilter}
                        entityName="problem set"
                        count={filteredProblemSets && filteredProblemSets.length}
                        filterName={titleFilter}
                    />
                    <Dimmer.Dimmable dimmed={isChecking}>
                        <Dimmer active={isChecking} inverted>
                            <Loader>Checking lesson compatibility...</Loader>
                        </Dimmer>
                        <div style={{ overflow: "auto", height: "30em" }}>
                            <List divided>
                                {isOpen && filteredProblemSets &&
                                    filteredProblemSets.map(ps => (
                                        <List.Item key={ps.key}>
                                            <Button
                                                basic
                                                primary
                                                icon="plus"
                                                id={ps.key}
                                                title="Add problem set to lesson"
                                                size="small"
                                                compact
                                                floated="right"
                                                onClick={this.handleAddProblemSet}
                                                style={{marginRight: "0.5em"}}
                                            />
                                            <List.Header>{ps.title}</List.Header>
                                            <List.Description>
                                                Type: {ps.setType} Question#: {(ps.problems && ps.problems.length) || "0"}
                                            </List.Description>
                                            {ps.notes}
                                        </List.Item>
                                    ))}
                            </List>
                        </div>
                    </Dimmer.Dimmable>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose} content="Close" disabled={isChecking} />
                </Modal.Actions>
            </Modal>
        );
    }
}
