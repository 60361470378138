import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";


export default class LinkItemContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object
    };

    render() {
        const { content } = this.props;
        const { href, name } = content || {};
        const title = (content && content.title) || name;

        return (
            <div className={content && content.href ? "contains link" : "missing link ui warning message"}>
                {content && content.href ? (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={href} target="_blank" title={title}>
                        <Icon name="linkify" />{name}
                    </a>
                ) : "No link has been provided."}
            </div>
        );
    }
}
