import React from "react";
import PropTypes from "prop-types";
import { Modal, Grid, Input, Divider, List, Icon } from "semantic-ui-react";
import { SaveCancelButtons, DropdownWithNodes } from "bmd-react";
import WidgetPreview from "./WidgetPreview";
import ActionLink from "../../../component/ActionLink";
import ItemsFoundLabel from "../../../component/ItemsFoundLabel";
import Topic from "../../../TopicCollection/Topic";
import ExtLinksConfirmDialog from "./ExtLinksConfirmDialog";

export default class AddExistingWidget extends React.PureComponent {
    static propTypes = {
        onAdd: PropTypes.func,
        widgetOptions: PropTypes.array,
        selectedLesson: PropTypes.object.isRequired,
        widgetCollection: PropTypes.object,
        searchFilter: PropTypes.string,
        topicFilter: PropTypes.object,
        onSetSearchFilter: PropTypes.func.isRequired,
        onSetTopicFilter: PropTypes.func.isRequired,
        lessonTopicOptions: PropTypes.array,
        initTopicFilter: PropTypes.object,
        outlineId: PropTypes.string,
        widgetsData: PropTypes.object,
        onCloneToNewWidget: PropTypes.func,
    };

    state = {
        open: false,
        widgetId: null,
        externalLinkWarning: false,
        widgetName: "",
    };

    handleOpen = () => {
        this.setState({ open: true, widgetId: null, externalLinkWarning: false });
        this.props.onSetSearchFilter("");
        this.props.onSetTopicFilter(this.props.initTopicFilter);
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    validateExternalLinks = (widgetId) => {
        const { widgetsData, outlineId, widgetCollection } = this.props;
        const widget = widgetsData[widgetId];
        let result = true;
        if (widget && widget.items) {
            Object.keys(widget.items).map(itemId => {
                const item = widget.items[itemId];
                if (item.linked_outlines) {
                    Object.keys(item.linked_outlines).map(oId => {
                        if (oId !== outlineId) {
                            result = false;
                        }
                    });
                }
            });
        }
        if (!result) {
            this.setState({ widgetName: widgetCollection[widgetId].name });
        }
        return result;
    }

    handleSave = () => {
        const { widgetId } = this.state;
        if (this.validateExternalLinks(widgetId)) {
            this.props.onAdd({ widgetId });
            this.handleClose();
        } else {
            this.setState({ externalLinkWarning: true });
        }
    };

    handleAdd = () => {
        const { widgetId } = this.state;
        this.props.onAdd({ widgetId });
        this.handleClose();
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };

    componentDidUpdate (prevProps, prevState) {
        if (!(this.state.open && !prevState.open) && (prevProps.selectedLesson.topics != this.props.selectedLesson.topics)) {
            let topics = {...this.props.topicFilter};
            const currTopics = this.props.selectedLesson.topics;
            const prevTopics = prevProps.selectedLesson.topics;
            Object.keys(currTopics).map(t => {
                if (!prevTopics[t]) {
                    topics[t] = true;
                }
            });
            Object.keys(prevTopics).map(t => {
                if (!currTopics[t] && topics[t]) {
                    delete topics[t];
                }
            });
            this.props.onSetTopicFilter(topics);
        }
        if (this.state.widgetId) {
            if (this.props.widgetOptions && 0 == (this.props.widgetOptions.filter(widget => widget.key === this.state.widgetId)).length) {
                this.setState({ widgetId: null });
            }
        }
    }

    handleSetWidgetFilter = (e, {value}) => {
        this.props.onSetSearchFilter(value);
    }

    composeTopicFilterLabel = (count) => {
        if (0 == count) {
            return "Empty topic filter";
        } else if (1 == count) {
            return "Filtered by one topic";
        } else {
            return `Filtered by ${count} topics`;
        }
    }

    handleChangeTopicFilter = (e, {value}) => {
        const { topicFilter, onSetTopicFilter } = this.props;
        e.stopPropagation();
        e.preventDefault();
        if (e.target.className.indexOf("dropdown") != -1) {
            return;
        }
        let topics = {...topicFilter};
        if (topicFilter[value]) {
            delete topics[value];
        } else {
            topics[value] = true;
        }
        onSetTopicFilter(topics);
    }

    handleCancelExternalLinkWarning = () => {
        this.setState({ externalLinkWarning: false });
    }

    handleCloneWidget = () => {
        const { onCloneToNewWidget } = this.props;
        const { widgetId } = this.state;
        onCloneToNewWidget(widgetId);
        this.handleClose();
    }

    render() {
        const { open, widgetId, externalLinkWarning, widgetName } = this.state;
        const { widgetOptions, selectedLesson, widgetCollection, searchFilter, lessonTopicOptions, topicFilter } = this.props;
        return (
            <Modal
                size="large"
                trigger={<ActionLink
                    horizontal
                    icon="plus"
                    content="Select from widgets"
                    title="Click here to add a widget from widgets."
                    onClick={this.handleOpen}
                />}
                open={open}
            >
                <Modal.Header>Add widget to {selectedLesson && selectedLesson.name}</Modal.Header>
                <Modal.Content>
                    <ExtLinksConfirmDialog
                        onClose={this.handleCancelExternalLinkWarning}
                        isOpen={externalLinkWarning}
                        onAdd={this.handleAdd}
                        title="Widget contains link to another outline"
                        onClone={this.handleCloneWidget}
                    >
                     Widget <span className="sporkSecondaryColor">{widgetName}</span> contains link to lesson in another
                    outline, are you sure you want to use this widget or do you want to clone content of this widget to
                    the new one, in which you can fix the link without breaking content in its original location.
                    </ExtLinksConfirmDialog>
                    <Input type="search" placeholder="Search..." action fluid value={searchFilter} onChange={this.handleSetWidgetFilter}>
                        <input />
                        <DropdownWithNodes
                            button
                            labeled
                            disabled={!selectedLesson || !selectedLesson.topics}
                            direction="left"
                            className="icon"
                            icon="filter"
                            closeOnChange={false}
                            text={this.composeTopicFilterLabel(topicFilter && Object.keys(topicFilter).length || 0)}
                            selection={false}
                            options={
                                lessonTopicOptions &&
                                lessonTopicOptions.map(topic => ({
                                    key: topic.key,
                                    selected: false,
                                    content: (
                                        <div style={{width: "500px"}} name={topic.key}>
                                            <Icon size="large" style={{float: "left"}} name={(topicFilter && topicFilter[topic.key] ? "check " : "") +"square outline"} />
                                            <Topic topic={topic.value} />
                                        </div>
                                    ),
                                    value: topic.key,
                                }))
                            }
                            floating
                            value={null}
                            onChange={this.handleChangeTopicFilter}
                        />
                    </Input>
                    <Divider hidden />
                    <Grid columns={2}>
                        <Grid.Column width={6}>
                            <p>Select widget to add it to the lesson.</p>
                            <ItemsFoundLabel entityName="widget" count={widgetOptions.length}/>
                            <List selection style={{maxHeight: "330px", overflowY: "auto"}} >
                                {open && widgetOptions.map(widget =>
                                    <List.Item key={widget.key} active={widget.key === widgetId} header={widget.name} name="widgetId" value={widget.key} onClick={this.handleChange}/>
                                )}
                            </List>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <WidgetPreview
                                widgetId={widgetId}
                                templateId={(
                                    widgetId && widgetCollection &&
                                    widgetCollection[widgetId] &&
                                    widgetCollection[widgetId].templateId
                                )}
                            />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons submitText="Add" onSave={this.handleSave} saveDisabled={!widgetId} onCancel={this.handleClose} />
                </Modal.Actions>
            </Modal>
        );
    }
}
