import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button, Icon, Message } from "semantic-ui-react";

export default class ImportHomework extends PureComponent {
    static propTypes = {
        homeworkToImport: PropTypes.object,
        onImportHomework: PropTypes.func.isRequired,
        onImportHomeworkStop: PropTypes.func.isRequired,
        inProgress: PropTypes.bool,
        progress: PropTypes.shape({
            processed: PropTypes.number,
            total: PropTypes.number,
        }),
        error: PropTypes.string,
    };

    handleStartProcessing = () => {
        const { onImportHomework } = this.props;
        onImportHomework();
    };

    handleStopProcessing = () => {
        const { onImportHomeworkStop } = this.props;
        onImportHomeworkStop();
    };

    render() {
        const { homeworkToImport, inProgress, progress, error } = this.props;
        let problemCount = Object.keys(homeworkToImport || {}).length;

        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">Import Homework from BeLA</Header>
                <Segment secondary>
                    This page converts homework problems imported from BeLA into SPORK compatible format.
                    <p>
                        As a first step, you need to run ImportBelaHomework script to retrieve data from BeLA server and
                        store them in SPORK. The script is located in source code GIT in folder{" "}
                        <a href="https://bitbucket.org/bmdconsulting/spork-app/src/develop/scripts/ImportBelaHomework/">
                            scripts/ImportBelaHomework
                        </a>
                        . Please read{" "}
                        <a href="https://bitbucket.org/bmdconsulting/spork-app/src/develop/scripts/ImportBelaHomework/README.md">
                            README.md
                        </a>
                    </p>
                    <p>Note: Due to memory limitations, please only import one book at a time.</p>
                </Segment>
                {inProgress ? (
                    <Message icon positive>
                        <Icon name="circle notched" loading />
                        <Message.Content>
                            Converting item {progress.processed} of {progress.total}
                        </Message.Content>
                    </Message>
                ) : (
                    <Segment>Problems ready to import: {problemCount}</Segment>
                )}
                {inProgress ? (
                    <Button negative onClick={this.handleStopProcessing}>
                        Stop conversion
                    </Button>
                ) : (
                    <Button primary onClick={this.handleStartProcessing} disabled={problemCount == 0}>
                        Start conversion
                    </Button>
                )}
                {error && <Message error>Conversion failed with error: {error}</Message>}
            </React.Fragment>
        );
    }
}
