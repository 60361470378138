import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import ErrorDetail from "./ErrorDetail";
import { getErrorDetail } from "./errorDetailSelectors";

const mapStateToProps = (state, props) => ({
    data: getErrorDetail(state, props),
});

export default compose(
    firebaseConnect(({ errorKey }) => ([`error_log/data/${errorKey}`])),
    connect(mapStateToProps),
)(ErrorDetail);
