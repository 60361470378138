exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._11WizHR9k0tl3IrqWdZ4yv {\n  line-height: 1em !important;\n  clear: both;\n  margin: 0 0 .75em; }\n  ._11WizHR9k0tl3IrqWdZ4yv > .ui.input {\n    width: 100%; }\n  ._11WizHR9k0tl3IrqWdZ4yv ._353VS5p94FgvUlDYL6EZUx {\n    width: 100%;\n    border: 1px solid rgba(34, 36, 38, 0.15);\n    padding-left: .5em; }\n", ""]);

// exports
exports.locals = {
	"answer": "_11WizHR9k0tl3IrqWdZ4yv",
	"maEditor": "_353VS5p94FgvUlDYL6EZUx"
};