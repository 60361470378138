import { getAbsoluteAncestor } from "./utils/domUtils";
import { isEdge, isIE } from "react-device-detect";

const ZeroCorner = { left: 0, top: 0 };

const getBoundingRectFrom = (elemOrRect) => (
    elemOrRect ? (
        elemOrRect.getBoundingClientRect ? elemOrRect.getBoundingClientRect() : elemOrRect
    ) : ZeroCorner
);

const getTopLeftOffset = (from, into) => ({
    left: into.left - from.left,
    top: into.top - from.top,
});

export default class ToolbarOffset {
    constructor(toolbar, container, containerBase) {
        this.container = container;
        this.sourceBase = getAbsoluteAncestor(toolbar);
        this.targetBase = containerBase === undefined ? getAbsoluteAncestor(container) : containerBase;
        this.sameContext = this.sourceBase === this.targetBase;
    }

    getPositionOffset = () => {
        // Use top-left corner of the toolbar placeholder...
        const target = getBoundingRectFrom(this.container);
        if (this.sameContext && (isEdge || isIE)) {
            // Special case for MS Edge and toolbar in modal dialogs.
            // Edge seems to be always positioning against top-left 
            // corner of window. Other browsers don't seem to do it.
            return target;
        }
        const source = getBoundingRectFrom(this.sourceBase);
        // ... and adjust to the common of stacking context
        const offset = getTopLeftOffset(source, target);
        // Correction due modal/popup border
        if (!this.sameContext) {
            offset.left -= 1;
            offset.top -= 1;
        }
        return offset;
    };

}
