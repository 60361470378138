import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Divider, Checkbox, Loader, Icon, Message, Table, Input } from "semantic-ui-react";
import PublishStatus from "../../../Publisher/PublishStatus";
import { toastSuccess } from "../../../../component/toast";
import { REVIEW_TYPE } from "../../../../component/seamlessEditor/bookEditor/constants";
import AccessControl from "../../../../auth/AccessControl";


export default class OutlinePublisher extends React.PureComponent{

    static propTypes = {
        outlineId: PropTypes.string.isRequired,
        lessonUnitsList: PropTypes.array,
        onPublish: PropTypes.func,
        onPublishReset: PropTypes.func,
        publishingProgress: PropTypes.object,
        publishingMessage: PropTypes.string,
        outlineLessonsPublished: PropTypes.object,
        tableData: PropTypes.array,
        open: PropTypes.bool,
        onClose: PropTypes.func,
    }

    state={
        selectedItems:{},
        tableKey: 0,
        searchText: "",
    }

    handleOnClose = () => {
        const { onPublishReset, onClose } = this.props;
        this.setState({ selectedItems: [], searchText: "" }, () => {
            onPublishReset();
            onClose();
        });
    }

    handlePublish = () => {
        const { onPublish, outlineId } = this.props;
        const { selectedItems } = this.state;
        onPublish(outlineId, selectedItems, ({header, message}) => {
            toastSuccess({ header, message });
            this.setState({
                selectedItems: {}
            });
        });
    }

    toggleSelectedItem = (e, {data, checked, name}) => {
        const { selectedItems } = this.state;
        const { outlineLessonsPublished } = this.props;
        let mode = name;

        if (checked){
            if (mode == "publish" && selectedItems[data] == "release"){
                mode = "both";
            }else if (mode == "release" && selectedItems[data] == "publish"){
                mode = "both";
            }
            this.setState({ selectedItems: { ...selectedItems, [data]: mode }, tableKey: this.getRandomKey() });
        }
        else{
            if (mode == "publish" && selectedItems[data] == "both"){
                //if unmarking publish can still release only if previously published
                if (outlineLessonsPublished[data])
                    selectedItems[data] = "release";
                else
                    delete selectedItems[data];

            }else if (mode == "release" && selectedItems[data] == "both"){
                selectedItems[data] = "publish";
            }else{
                delete selectedItems[data];
            }
            this.setState({ selectedItems: { ...selectedItems }, tableKey: this.getRandomKey()  });
        }
    }

    hasBlockingReviews = (reviewsCount) => {
        return Boolean(reviewsCount && (reviewsCount[REVIEW_TYPE.REPLACE] + reviewsCount[REVIEW_TYPE.INSERT]));
    };

    getPublishColumn = (rowData) => {
        const { selectedItems } = this.state;
        const { publishingProgress } = this.props;

        return <Checkbox
            name="publish"
            data={rowData.key}
            disabled={
                rowData.hasUnprocessedReviews
                || publishingProgress && publishingProgress[rowData.key] == "started"
            }
            label="Prepublish"
            checked={selectedItems[rowData.key] === "publish" || selectedItems[rowData.key] === "both"}
            onChange={this.toggleSelectedItem} />;
    }

    getStatusColumn = (rowData) => {
        const { outlineLessonsPublished} = this.props;
        return outlineLessonsPublished && outlineLessonsPublished[rowData.key] ? <PublishStatus {...outlineLessonsPublished[rowData.key]} twoLines={true} /> : null;
    }

    getReleaseColumn = (rowData) => {
        const { selectedItems } = this.state;
        const { publishingProgress, outlineLessonsPublished } = this.props;

        if (outlineLessonsPublished
            && outlineLessonsPublished[rowData.key]
            && outlineLessonsPublished[rowData.key].preview
            && outlineLessonsPublished[rowData.key].release
            && outlineLessonsPublished[rowData.key].preview.version_id == outlineLessonsPublished[rowData.key].release.version_id){
            //item needs to be re-published before it can be release again
            return <span title="Republish in order to release a new version" style={{color:"green"}}> <Icon name="check circle outline" /> Published</span>;
        }

        return <Checkbox
            name="release"
            data={rowData.key}
            disabled={
                rowData.hasUnprocessedReviews
                || publishingProgress && publishingProgress[rowData.key] == "started"
                || ((outlineLessonsPublished && !outlineLessonsPublished[rowData.key] || outlineLessonsPublished && !outlineLessonsPublished[rowData.key].preview) && !selectedItems[rowData.key])
            }
            label="Publish"
            checked={selectedItems[rowData.key] === "release" || selectedItems[rowData.key] === "both"}
            onChange={this.toggleSelectedItem} />;
    };

    getProcessingColumn = (rowData) => {
        const { publishingProgress } = this.props;
        return <React.Fragment>
            { publishingProgress && publishingProgress[rowData.key] ?
                <div>
                    { publishingProgress[rowData.key] == "started" &&
                        <Loader
                            className="workaround"
                            size="tiny"
                            active
                            inline
                        />
                    }
                    { publishingProgress[rowData.key] == "completed" &&
                        <span style={{color:"green"}}> <Icon name="check circle outline" /></span>
                    }
                    { publishingProgress[rowData.key] == "error" &&
                        <span style={{color:"red"}}> <Icon name="times circle outline" /></span>
                    }
                </div> :
                rowData.hasUnprocessedReviews &&
                    <span title="This lesson contains unprocessed reviews. Accept/Reject them first.">
                        <Icon name="warning sign" color="orange" />
                    </span>
            }
        </React.Fragment>;
    };

    getRandomKey = () => {
        return Math.random()
            .toString(36)
            .substring(7);
    };

    searchTextChange = (e) => this.setState({ searchText: e.target.value });
    clearSearchText = () => this.setState({ searchText: "" });


    renderPublishView = () => {
        const { getProcessingColumn, getPublishColumn, getReleaseColumn, getStatusColumn, searchTextChange, clearSearchText, hasBlockingReviews } = this;
        const { publishingProgress, publishingMessage, tableData, open } = this.props;
        const { tableKey, selectedItems, searchText } = this.state;
        const allComplete = Object.keys(publishingProgress || {}).reduce((accum, current) => accum && publishingProgress[current] == "completed", true);
        const itemsAvailableToPublish = this.props.lessonUnitsList.reduce((prev, current) => prev + current.lessons.length, 0);

        return  <React.Fragment>
            <Modal
                size="large"
                centered={false}
                open={open}
                onClose={this.handleOnClose}>
                <Modal.Header>Select lessons to publish</Modal.Header>
                <Modal.Content scrolling>
                    { publishingMessage &&
                        <Message>
                            <Message.Header>Publishing</Message.Header>
                            <p>{publishingMessage}</p>
                        </Message>
                    }
                    { itemsAvailableToPublish == 0 &&
                        <Message warning>
                            <Message.Header>Nothing available to publish</Message.Header>
                            <p>There are currently no lessons available for publishing.</p>
                        </Message>
                    }
                    { tableData && tableData.length > 0 &&
                    <React.Fragment>
                        <Input value={searchText} icon={
                            <Icon name="times circle" color="grey" link onClick={clearSearchText}/>
                        } placeholder="Search..." onChange={searchTextChange} />
                        <Table key={tableKey} striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(tableData).length == 0 &&
                                    <Table.Row>
                                        <Table.Cell>No Rows</Table.Cell>
                                    </Table.Row>
                                }
                                {tableData.map(function(lesson, index){
                                    if (searchText && lesson.name.toLowerCase().indexOf(searchText.toLowerCase()) < 0)
                                        return;
                                    const rowData = { ...lesson, hasUnprocessedReviews: hasBlockingReviews(lesson.reviewsCount)};
                                    return(<Table.Row key={index}>
                                        <Table.Cell>
                                            {getProcessingColumn(rowData)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {getPublishColumn(rowData)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {getReleaseColumn(rowData)}
                                        </Table.Cell>
                                        <Table.Cell style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: "300px"}}>{lesson.name}</Table.Cell>
                                        <Table.Cell>
                                            {getStatusColumn(rowData)}
                                        </Table.Cell>
                                    </Table.Row> );
                                })}
                            </Table.Body>
                        </Table>
                    </React.Fragment>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        disabled={!allComplete || itemsAvailableToPublish === 0 || Object.keys(selectedItems).length == 0}
                        content="Prepublish/Publish selected"
                        positive
                        onClick={this.handlePublish}
                    />
                    <Button
                        disabled={!allComplete}
                        content={"Cancel"}
                        onClick={this.handleOnClose}
                    />
                </Modal.Actions>
            </Modal>
            <Divider hidden clearing />
        </React.Fragment>;
    }

    renderStatusView = () => {
        const { getStatusColumn, searchTextChange, clearSearchText } = this;
        const { tableData, open } = this.props;
        const { tableKey, searchText } = this.state;

        return  <React.Fragment>
            <Modal
                size="large"
                centered={false}
                open={open}
                onClose={this.handleOnClose}>
                <Modal.Header>Lesson publishing status</Modal.Header>
                <Modal.Content scrolling>
                    { tableData && tableData.length > 0 &&
                    <React.Fragment>
                        <Input value={searchText} icon={
                            <Icon name="times circle" color="grey" link onClick={clearSearchText}/>
                        } placeholder="Search..." onChange={searchTextChange} />
                        <Table key={tableKey} striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(tableData).length == 0 &&
                                    <Table.Row>
                                        <Table.Cell>No Rows</Table.Cell>
                                    </Table.Row>
                                }
                                {tableData.map(function(lesson, index){
                                    if (searchText && lesson.name.toLowerCase().indexOf(searchText.toLowerCase()) < 0)
                                        return;
                                    const rowData = { ...lesson};
                                    return(<Table.Row key={index}>
                                        <Table.Cell style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: "300px"}}>{lesson.name}</Table.Cell>
                                        <Table.Cell>
                                            {getStatusColumn(rowData)}
                                        </Table.Cell>
                                    </Table.Row> );
                                })}
                            </Table.Body>
                        </Table>
                    </React.Fragment>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={"Close"}
                        onClick={this.handleOnClose}
                    />
                </Modal.Actions>
            </Modal>
            <Divider hidden clearing />
        </React.Fragment>;
    }

    render(){
        return (
            <AccessControl action="aKit:publish" renderNoAccess={this.renderStatusView}>
                {this.renderPublishView()}
            </AccessControl>
        );
    }
        
}
