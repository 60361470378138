import React, { PureComponent } from "react";
import { Header } from "semantic-ui-react";
import FilterMenu from "./FilterMenu";
import PropTypes from "prop-types";

export default class PageHeader extends PureComponent {
    static propTypes = {
        searchFilter: PropTypes.string,
        onChangeFilter: PropTypes.func,
        list: PropTypes.array,
        headerText: PropTypes.string,
        subHeaderText: PropTypes.string,
        entityName: PropTypes.string,
        className: PropTypes.string,
        children: PropTypes.node,
    };

    handleSetFilter = (e, {value}) => {
        this.props.onChangeFilter(value);
    }

    render() {
        const { searchFilter, list, headerText, entityName, className, children, subHeaderText } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    {headerText}
                    {subHeaderText && <Header.Subheader>{subHeaderText}</Header.Subheader>}
                </Header>
                <FilterMenu
                    entityName={entityName}
                    count={list && list.length || 0}
                    onChange={this.handleSetFilter}
                    filterName={searchFilter}
                    className={className}
                >{children}</FilterMenu>
            </React.Fragment>
        );
    }
}
