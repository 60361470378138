import React, { PureComponent } from "react";
import { Modal, Form, Divider, Message, Icon, Button, Dimmer, Loader } from "semantic-ui-react";

import PropTypes from "prop-types";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";
import { AddItemButton } from "bmd-react/dist/AddItemButton";
import AccessControl from "../../../auth/AccessControl";

export default class CreateKit extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        open: PropTypes.bool,
        trigger: PropTypes.node,
        onOpen: PropTypes.func,
        onCreate: PropTypes.func,
        courseVariants: PropTypes.array,
        creating: PropTypes.bool,
        onAllCourseVariantsLoad: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    static wizardWay = {
        MANUALLY: 1,
        FROM_CV: 2,
    };

    componentDidUpdate(prevProps) {
        const { open, onAllCourseVariantsLoad, courseVariants } = this.props;
        if (!prevProps.open && open) {
            this.setState({ creationWay: null, kitName: "", selectedVariant: null, error: false });
            onAllCourseVariantsLoad();
        }
        //if there are no variants the manual way is the only way
        if (!this.props.isLoading && courseVariants.length == 0 && !this.state.creationWay) {
            this.setState({ creationWay: CreateKit.wizardWay.MANUALLY });
        }
    }

    state = { creationWay: null, selectedVariant: null, kitName: "", error: false };

    handleSelectWay = (e, { value }) => {
        this.setState({ creationWay: value });
    };

    handleSelectVariant = (e, { options, value }) => {
        const variant = options.filter(i => i.value === value);
        this.setState({ selectedVariant: value, kitName: variant[0].text, error: false });
    };

    handleChangeName = (e, { value }) => {
        this.setState({ kitName: value, error: false });
    };

    handleCreate = () => {
        const { kitName, creationWay, selectedVariant } = this.state;
        const name = kitName.trim();
        const { onCreate } = this.props;
        if (CreateKit.wizardWay.FROM_CV == creationWay && selectedVariant && 0 != name.length) {
            onCreate({ name, course_variant: selectedVariant });
        } else if (CreateKit.wizardWay.MANUALLY == creationWay && 0 != name.length) {
            onCreate({ name });
        } else {
            this.setState({ error: true });
        }
    };

    render() {
        const { open, onClose, trigger, onOpen, courseVariants, creating, isLoading } = this.props;
        const { creationWay, selectedVariant, kitName, error } = this.state;
        const emptyKitName = !kitName.trim().length;
        return (
            <AccessControl action="aKit:create">
                <Modal
                    open={open}
                    onOpen={onOpen}
                    size="tiny"
                    trigger={trigger || <AddItemButton buttonText="Create" title="Create new Author Kit" />}
                >
                    <Modal.Header>Create new Author Kit</Modal.Header>
                    <Modal.Content>
                        <Dimmer active={isLoading} inverted>
                            <Loader inverted content="Loading" />
                        </Dimmer>

                        {courseVariants.length > 0 && !creationWay ? (
                            <React.Fragment>
                                <p>
                                    Choose whether you want to create Author Kit using the parameters from the existing
                                    Course or you want to enter Kit parameters manually.
                                </p>
                                <Form>
                                    <Form.Button
                                        fluid
                                        primary
                                        content="Create from the Course"
                                        value={CreateKit.wizardWay.FROM_CV}
                                        onClick={this.handleSelectWay}
                                    />
                                    <Divider horizontal>OR</Divider>
                                    <Form.Button
                                        fluid
                                        content="Create manually"
                                        value={CreateKit.wizardWay.MANUALLY}
                                        onClick={this.handleSelectWay}
                                    />
                                </Form>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {CreateKit.wizardWay.FROM_CV == creationWay && (
                                    <React.Fragment>
                                        <p>
                                            Choose the Course that will be assigned to the Author Kit. The name
                                            is used as the initial name of the new Author Kit. Both properties may be
                                            changed later on in Author Kit settings.
                                        </p>
                                        <Form name="cv" style={{ paddingBottom: "1em" }}>
                                            <Form.Dropdown
                                                required
                                                selection
                                                error={error && !selectedVariant}
                                                label="Course"
                                                placeholder="Choose Course"
                                                options={courseVariants}
                                                onChange={this.handleSelectVariant}
                                                value={selectedVariant}
                                            />
                                        </Form>
                                    </React.Fragment>
                                )}
                                <p>
                                    Fill in the name of the new Author Kit and click to the Create button. After that
                                    you can change the Author Kit properties and assign an author and reviewers.
                                </p>
                                <Form name="name">
                                    <Form.Input
                                        required
                                        label="Name"
                                        placeholder="Fill name of new Author Kit"
                                        value={kitName}
                                        error={error && emptyKitName}
                                        onChange={this.handleChangeName}
                                    />
                                </Form>
                            </React.Fragment>
                        )}
                    </Modal.Content>
                    <Modal.Actions>
                        {!creationWay ? (
                            <Button content="Cancel" onClick={onClose} />
                        ) : (
                            <SaveCancelButtons
                                onCancel={onClose}
                                onSave={this.handleCreate}
                                saveDisabled={!creationWay}
                                submitText="Create"
                            />
                        )}
                    </Modal.Actions>
                    {creating && (
                        <Message icon positive>
                            <Icon name="circle notched" loading />
                            <Message.Content>Creating new author kit ...</Message.Content>
                        </Message>
                    )}
                </Modal>
            </AccessControl>
        );
    }
}
