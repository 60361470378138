
/**
 * We want to use `Stix 16px` as default font.
 * Wiris sets:
 *      - `font-family` to the top level `<math>` node
 *      - `16px` is currently default and if it is set manually from the Wiris menu, it doesn't appear in the result MathML
 *        (so we don't set it).
 *        Any other size is set to the first child of `<math>` node, e.g.: `<mstyle mathsize="14px">`
 *        (if we need to set it in the future).
 */
export const MATH_DEFAULTS = "style=\"font-family:stix\"";

export const convertTextColorToBackground = (html) => {
    const dummyDiv = document.createElement("div");
    dummyDiv.innerHTML = html;
    const nodes = dummyDiv.getElementsByTagName("*");

    for (const node of nodes) {
        if (node.hasAttribute("mathcolor")) {
            node.setAttribute("mathbackground", node.getAttribute("mathcolor"));
            node.removeAttribute("mathcolor");
        }
    }

    return dummyDiv.innerHTML;
};

export const resetColors = (html) => {
    const dummyDiv = document.createElement("div");
    dummyDiv.innerHTML = html;
    const nodes = dummyDiv.getElementsByTagName("*");

    for (const node of nodes) {
        node.removeAttribute("mathcolor");
        node.removeAttribute("mathbackground");
    }

    return dummyDiv.innerHTML;
};

const mergeSiblingToMRow = (rootNode, siblings, backgroundColor) => {

    if (rootNode.tagName === "mrow") {
        // Don't create <mrow>, these siblings are already grouped. Update color only.
        rootNode.setAttribute("mathbackground", backgroundColor);
        return;
    }

    const mrow = document.createElement("mrow");
    mrow.setAttribute("mathbackground", backgroundColor);
    rootNode.replaceChild(mrow, siblings[0]);
    siblings.forEach((sibling) => mrow.appendChild(sibling));
};

const groupChildrenWithSameBackground = (rootNode) => {
    const treeWalker = document.createTreeWalker(
        rootNode,
        NodeFilter.SHOW_ELEMENT
    );

    let node = treeWalker.firstChild();
    let groupBackground = null;
    let setBackgroundToParent = true;
    const siblingsToGroup = [];

    while (node) {
        const currentBackground = node.getAttribute("mathbackground");

        if (node.hasChildNodes()) {
            groupChildrenWithSameBackground(node);
        }

        if (groupBackground !== currentBackground) {
            if (siblingsToGroup.length) {
                setBackgroundToParent = false;  // at least one sibling has different bg color
            }
            if (groupBackground && siblingsToGroup.length > 1) {
                mergeSiblingToMRow(rootNode, siblingsToGroup, groupBackground);
            }
            siblingsToGroup.length = 0;
        }

        siblingsToGroup.push(node);
        groupBackground = currentBackground;
        node = treeWalker.nextSibling();
    }

    if (groupBackground) {
        if (setBackgroundToParent) {
            rootNode.setAttribute("mathbackground", groupBackground);
        }
        else if (siblingsToGroup.length > 1) {
            mergeSiblingToMRow(rootNode, siblingsToGroup, groupBackground);
        }
    }
};

export const updateRowHighlighting = (html) => {
    const dummyDiv = document.createElement("div");
    dummyDiv.innerHTML = html;
    groupChildrenWithSameBackground(dummyDiv);
    return dummyDiv.innerHTML;
};

export const resetFont = (html) => {
    const dummyDiv = document.createElement("div");
    dummyDiv.innerHTML = html;
    const nodes = dummyDiv.getElementsByTagName("*");

    for (const node of nodes) {
        node.removeAttribute("mathsize");
        node.style && node.style.removeProperty("mathsize");
    }

    const styleProps = (dummyDiv.firstChild.getAttribute("style") || "").split(";");
    const newFontFamily = styleProps.filter((item) => item.toLowerCase().indexOf("font-family") === -1);
    newFontFamily.push("font-family: stix"); // see MATH_DEFAULTS
    dummyDiv.firstChild.setAttribute("style", newFontFamily.join(";"));

    return dummyDiv.innerHTML;
};
