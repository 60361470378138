import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List , AutoSizer} from "react-virtualized";
import { Dimmer, Loader, Grid } from "semantic-ui-react";
import PageHeader from "../../component/PageHeader";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import "./SchoolCourses.scss";
import cx from "classnames";
import CourseDetails from "./component/CourseDetails";
import AdvancedFilter from "./component/AdvancedFilter";
import AdvancedFilterView from "./component/AdvancedFilterView";

export default class SchoolCourses extends PureComponent {
    static propTypes = {
        onLoadSchoolCourses: PropTypes.func,
        courses: PropTypes.array,
        isLoading: PropTypes.bool,
        searchFilter: PropTypes.string,
        onChangeFilter: PropTypes.func,
        schools: PropTypes.object,
        onSchoolLoad: PropTypes.func,
        selectedYearId: PropTypes.number,
        allCourses: PropTypes.array,
        selectedCourseId: PropTypes.number,
        onSelectCourse: PropTypes.func,
    }

    componentDidMount() {
        const { onLoadSchoolCourses, onSchoolLoad, selectedCourseId, onSelectCourse } = this.props;
        onLoadSchoolCourses();
        onSchoolLoad();
        if (selectedCourseId) {
            onSelectCourse(selectedCourseId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedYearId !== this.props.selectedYearId) {
            this.props.onLoadSchoolCourses();
        }
    }

    handleSelectCourse = ({currentTarget}) => {
        const { onSelectCourse } = this.props;
        onSelectCourse(parseInt(currentTarget.id));
    }
    
    rowRendererSimple = ({
        index, // Index of row
        key, // Unique key within array of rendered rows
        style, // Style object to be applied to row (to position it);
    }) => {
        const { courses, schools, selectedCourseId } = this.props;
        const course = courses[index];
        const school = schools && course && schools[course.school_id];
        // const disabled = this.props.disabled;

        return (
            <div
                className={cx("limitedTextWidth", "coursesList", "simple", {
                    active: course.id === selectedCourseId,
                })}
                key={key}
                style={{ display: "flex", justifyContent: "space-between", paddingRight: "0.5em", ...style}}
                id={course.id}
                // {...!disabled && { onClick: this.handleSelectWidget}}
                onClick={this.handleSelectCourse}
            >
                <div>{course.name}</div>
                <span className="schoolCoursesSchoolName">{school && school.name}</span>
            </div>
        );
    };

    findIndexInOrderedList = () => {
        const { courses, selectedCourseId } = this.props;
        return courses.map(course => course.id).indexOf(selectedCourseId);
    };

    render() {
        const { isLoading, courses, onChangeFilter, searchFilter, allCourses, selectedCourseId} = this.props;
        const showDetails = null !== selectedCourseId;
        return (
            <React.Fragment>
                <PageHeader
                    searchFilter={searchFilter}
                    list={courses}
                    headerText="School Courses"
                    entityName="school course"
                    onChangeFilter={onChangeFilter}
                >
                    <AdvancedFilter />
                </PageHeader>
                <AdvancedFilterView />
                <Grid columns={showDetails ? 2 : 1}>
                    <Grid.Column width={showDetails ? 8 : 16}>
                        <Dimmer.Dimmable dimmed={isLoading}>
                            <Dimmer active={isLoading} inverted>
                                <Loader>Loading...</Loader>
                            </Dimmer>
                            {isLoading ? <div style={{ minHeight: "10em" }} /> : (null === allCourses ? (
                                <NoDataPlaceholder
                                    text="Here we list School Courses."
                                >
                                    <p></p>
                                </NoDataPlaceholder>
                            ) : 0 === allCourses.length ? (
                                <NoDataPlaceholder
                                    text="Here we list School Courses."
                                >
                                    <p>We cannot find any School Courses for the selected year.</p>
                                </NoDataPlaceholder>
                            ) : 0 === courses.length ? (
                                <NoDataPlaceholder
                                    icon="search"
                                    text="Oh, snap. No result."
                                >
                                    <p>We cannot find any School Courses matching your filtering criteria.</p>
                                </NoDataPlaceholder>
                            ) : <div style={{ height: "80vh"}}>
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height}
                                            width={width}
                                            rowCount={courses.length}
                                            rowRenderer={this.rowRendererSimple}
                                            rowHeight={30}
                                            {...selectedCourseId && { scrollToIndex: this.findIndexInOrderedList() }}
                                        />
                                    )}
                                </AutoSizer>
                            </div>
                            )}
                        </Dimmer.Dimmable>
                    </Grid.Column>
                    {showDetails && <CourseDetails key={selectedCourseId} selectedCourseId={selectedCourseId}/>}
                </Grid>
            </React.Fragment>
        );
    }
}
