import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Grid, Button } from "semantic-ui-react";
import TopicTreeContainer from "./TopicTree/TopicTreeContainer";
import TopicTreeHeader from "./TopicTreeHeader";
import NoDataPlaceholder from "../component/NoDataPlaceholder";
import CollectionListContainer from "./CollectionList";
export default class TopicCollection extends PureComponent {
    static propTypes = {
        selectedCollection: PropTypes.string,
        collections: PropTypes.object,
        canEdit: PropTypes.bool,
    };

    state = {
        hideEmptyInfo: false
    };

    handleHideEmptyInfo = () => {
        this.setState({ hideEmptyInfo: true });
    };

    render() {
        const { selectedCollection, collections, canEdit } = this.props;
        const { hideEmptyInfo } = this.state;

        return (
            <React.Fragment>
                <Header as="h2" dividing className="pageHeader">Topic Collections</Header>

                {collections == null && !hideEmptyInfo ? (
                    <NoDataPlaceholder text="Here we list topic collections." icon="sitemap">
                        <p>
                            Topic collection contains a hierarchical structure of topics. The collection can be
                            synchronized to BeLA.
                        </p>
                        {canEdit && <Button primary content="Add collection" onClick={this.handleHideEmptyInfo} />}
                    </NoDataPlaceholder>
                ) : (
                    <Grid columns={2}>
                        <Grid.Column width={4}>
                            <CollectionListContainer />
                        </Grid.Column>
                        <Grid.Column width={12} >
                            {selectedCollection ? (
                                <React.Fragment>
                                    <TopicTreeHeader />
                                    <TopicTreeContainer />
                                </React.Fragment>
                            ) : (
                                <NoDataPlaceholder text="Here we show topic tree." icon="sitemap">
                                    <p>Please, select one of the available topic collections or create a new one.</p>
                                </NoDataPlaceholder>
                            )}
                        </Grid.Column>
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}
