import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";

import { LessonPublisherMenu } from "../Publisher";
import LessonProblemSetMenu from "./LessonProblemSet/LessonProblemSetMenu";
import { REVIEW_TYPE, REVIEW_STATS_COLLECTION } from "../../component/seamlessEditor/bookEditor/constants";
import ReviewsCount from "../component/ReviewsCount";

const LessonViews = [{
    name: "compose",
    label: "Compose",
    checkRights: (rights) => rights && rights.canEdit,
    checkReviewCountVisibility: (rights) => rights && rights.canEdit,
}, {
    name: "preview",
    label: "Preview",
    checkRights: (rights) => rights && (rights.canEdit || rights.canReview),
    checkReviewCountVisibility: () => false,
}, {
    name: "review",
    label: "Review",
    checkRights: (rights) => rights && rights.canReview,
    checkReviewCountVisibility: (rights) => rights && rights.canReview && !rights.canEdit,
},
{
    name: "content",
    label: "Content",
    checkRights: (rights) => !rights || (!rights.canEdit && !rights.canReview),
    checkReviewCountVisibility: () => false,
}];

const checkRightsPerView = (userRights) => (view) => (
    view && (!view.checkRights || view.checkRights(userRights))
);

export class LessonContentMenu extends React.PureComponent {
    static propTypes = {
        outlineId: PropTypes.string,
        lessonId: PropTypes.string,
        activeView: PropTypes.string,
        onChangeView: PropTypes.func.isRequired,
        userRights: PropTypes.object,
        reviewsCount: PropTypes.object.isRequired,
    };

    renderReviewsCount = (reviewStatsCollection) => {
        const { reviewsCount } = this.props;

        if (reviewsCount) {
            const reviewsCountPerCollection = reviewsCount[reviewStatsCollection];
            const reviews = reviewsCountPerCollection[REVIEW_TYPE.REPLACE] + reviewsCountPerCollection[REVIEW_TYPE.INSERT];
            const comments = reviewsCountPerCollection[REVIEW_TYPE.COMMENT_ONLY];

            return (<ReviewsCount reviews={reviews} comments={comments} marginLeft={true} right={true} />);
        }
    };

    hasBlockingReviews = () => {
        const { reviewsCount } = this.props;

        if (reviewsCount) {
            for (const reviewsCountPerCollection of Object.values(reviewsCount)) {
                if (reviewsCountPerCollection[REVIEW_TYPE.REPLACE] + reviewsCountPerCollection[REVIEW_TYPE.INSERT]) {
                    return true;
                }
            }
        }

        return false;
    }

    render() {
        const { activeView, userRights, onChangeView, outlineId, lessonId } = this.props;
        const hasUnprocessedReviews = this.hasBlockingReviews();
        return (
            <Menu style={{clear: "both"}}>
                {LessonViews.filter(checkRightsPerView(userRights)).map((view) => (
                    <Menu.Item
                        active={activeView && activeView === view.name}
                        key={view.name}
                        name={view.name}
                        onClick={onChangeView}
                    >
                        {view.label}
                        {view.checkReviewCountVisibility(userRights) && this.renderReviewsCount(REVIEW_STATS_COLLECTION.WIDGETS)}
                    </Menu.Item>
                ))}
                <Menu.Item
                    active={activeView && activeView === "problem_sets"}
                    style={{ padding: 0 }}
                >
                    <LessonProblemSetMenu
                        entity={{
                            name: <React.Fragment>Problem Sets{this.renderReviewsCount(REVIEW_STATS_COLLECTION.PROBLEM_SETS)}</React.Fragment>,
                            singular: "Problem Set",
                            tabName: "problem_sets",
                        }}
                        collection="problem_sets"
                        lessonId={lessonId}
                        outlineId={outlineId}
                        onSelectTab={onChangeView}
                        hasEditRights={userRights.canEdit}
                    />
                </Menu.Item>
                <Menu.Item
                    active={ "homework" === activeView}
                    style={{ padding: 0 }}
                >
                    <LessonProblemSetMenu
                        entity={{
                            name: <React.Fragment>Homework{this.renderReviewsCount(REVIEW_STATS_COLLECTION.HOMEWORK)}</React.Fragment>,
                            singular: "Homework",
                            tabName: "homework",
                        }}
                        collection="homework"
                        lessonId={lessonId}
                        outlineId={outlineId}
                        onSelectTab={onChangeView}
                        hasEditRights={userRights.canEdit}
                    />
                </Menu.Item>
                <LessonPublisherMenu
                    outlineId={outlineId}
                    lessonId={lessonId}
                    onSelectTab={onChangeView}
                    activeView={activeView}
                    canPublish={userRights && userRights.canPublish}
                    hasUnprocessedReviews={hasUnprocessedReviews}
                />
            </Menu>
        );
    }
}

export default LessonContentMenu;
