import { put } from "redux-saga/effects";
import firebase from "../../firebase";
import { onAdminSuccess, onAdminFailure } from "./AdminFunctionsActions";
import { toastError, FUNCTION_ERROR } from "../../component/toast";

export default function* resetStylesBlocks() {
    try {
        const generateBlockStyles = firebase.getFirebaseFunctions().httpsCallable("generateSnippetStyles");
        yield generateBlockStyles({ resetByAdmin: true }); // result.data.updated = { [snippedId]: css };
        yield put(onAdminSuccess("Styled blocks stylesheet regenerated."));
    } catch(ex) {
        const { code, message } = ex;
        yield put(onAdminFailure(message));
        toastError({ code: FUNCTION_ERROR, header: "Failed to reset styles", message: `${message} (code: ${code})` });
    }
}

