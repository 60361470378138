import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import ProblemSetCreator from "./ProblemSetCreator";
import {
    getProblems,
    isLoadingProblemSet,
    isLoadingProblems,
    getActiveProblemSet,
    getProblemSetAccessContext,
    getProblemSetListeners,
    getProblemSetLockType,
} from "./problemSetCreatorSelectors";
import {
    addProblem,
    editProblem,
    deleteProblem,
    deleteProblemSet,
    moveProblem,
    moveChildProblem,
    onBackToLesson,
    assignProblems,
    onPdfExport,
} from "./problemSetCreatorActions";
import { onSetOutline } from "../../KitBuilder/LessonLink/LessonLinkActions";
import queryString from "query-string";
import { withLockFromProps } from "../../Lock";
import {
    onAddQuestionToCompTest,
    onAssignQuestionsToCompTest,
    onRemoveQuestionFromCompTest,
    onEditQuestion,
    onChangeQuestionPosition, onChangeChildQuestionPosition,
} from "../CompTest/CompTestActions";

const mapStateToProps = (state, props) => ({
    _watchProblemSet: getProblemSetListeners(state, props),
    activeProblemSet: getActiveProblemSet(state, props),
    problemDirectory: getProblems(state, props),
    isLoading: isLoadingProblemSet(state, props) || isLoadingProblems(state, props),
    query: queryString.parse(props.location.search),
    accessContext: getProblemSetAccessContext(state, props),
    lockType: getProblemSetLockType(state, props),
});

const emptyAction = () => ({type: "N/A"});

const mapDispatchToProps = (dispatch, { fbCollection }) => {
    switch (fbCollection) {
        case "problemSet":
            return bindActionCreators({
                addProblem,
                editProblem,
                deleteProblem,
                deleteProblemSet,
                moveProblem,
                moveChildProblem,
                onBackToLesson,
                onSetOutlineForLessonLinks: onSetOutline,
                assignProblems,
                onPdfExport,
            }, dispatch);
        case "comptests_common":
            return bindActionCreators({
                addProblem: onAddQuestionToCompTest,
                editProblem: onEditQuestion,
                deleteProblem: onRemoveQuestionFromCompTest,
                assignProblems: onAssignQuestionsToCompTest,
                moveProblem: onChangeQuestionPosition,
                moveChildProblem: onChangeChildQuestionPosition,
                onPdfExport,  // shared with "problemSet" - parametrized in saga
                onSetOutlineForLessonLinks: emptyAction,
            }, dispatch);
        default:
            // eslint-disable-next-line no-console
            console.error(`Unsupported collection "${fbCollection}" in ProblemSetCreator`);
            return null;
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect((props) => props._watchProblemSet),
    withLockFromProps
)(ProblemSetCreator);

