import { all, takeLatest, select, put, takeEvery, fork } from "redux-saga/effects";
import firebase from "../firebase";
import { TYPES, onSelectCollection, onEndSync } from "./topicCollectionActions";
import { getSelectedTopicCollection } from "./topicCollectionSelectors";
import to from "await-to-js";
import { push } from "connected-react-router";
import TopicSelectorSaga from "./TopicSelector/TopicSelectorSaga";
import TopicTreeSelectorSaga from "./TopicTreeSelector/TopicTreeSelectorSaga";
import { toastError, DB_ERROR } from "../component/toast";

const topicCollectionDataPath = "/topic_collections/data/";
const topicCollectionListPath = "/topic_collections/list/";

export function* addTopic({ payload }) {
    let selectedCollection = yield select(getSelectedTopicCollection);
    const [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(topicCollectionDataPath + "/" + selectedCollection)
            .push(payload)
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to add A new Topic", message: DB_ERROR });
    }
}

export function* deleteTopic({ payload }) {
    let selectedCollection = yield select(getSelectedTopicCollection);
    const [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(topicCollectionDataPath + "/" + selectedCollection)
            .update(payload.keys)
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to remove Topic", message: DB_ERROR });
    }
}

export function* moveTopic({ payload }) {
    let selectedCollection = yield select(getSelectedTopicCollection);
    let updates = {};

    // update order of all childs in parent node
    updates[payload.node.key + "/parent"] = payload.nextParentNode.rootNode ? null : payload.nextParentNode.key;
    payload.nextParentNode.children.map((child, i) => {
        updates[child.key + "/order"] = i + 1;
    });

    const [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(topicCollectionDataPath + "/" + selectedCollection)
            .update(updates)
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to move Topic", message: DB_ERROR });
    }
}

export function* updateTopic({ payload }) {
    let selectedCollection = yield select(getSelectedTopicCollection);
    const [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(topicCollectionDataPath + "/" + selectedCollection + "/" + payload.key)
            .update(payload.node)
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to update Topic", message: DB_ERROR });
    }
}

export function* addCollection({ payload: { BeLA_id_t1, BeLA_id_t2 , ...data }}) {
    let [err, res] = yield to(
        firebase.defaultApp
            .database()
            .ref(topicCollectionListPath)
            .push(data)
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to add a new Topic Collection", message: DB_ERROR });
    }
    [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(`/config/topic_collections/${res.key}`)
            .update({ BeLA_id_t1, BeLA_id_t2 })
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to update Topic Collection BeLA IDs", message: DB_ERROR });
    }
}

export function* deleteCollection({ payload }) {
    // if we're deleting currently selected collection, unselect it firts
    let selectedCollection = yield select(getSelectedTopicCollection);
    if (payload === selectedCollection) {
        yield put(onSelectCollection(null));
    }
    const error = { header: "Failed to remove Topic Collection", message: DB_ERROR };

    // get topic collection data
    const topics = (yield firebase.defaultApp.database().ref(topicCollectionDataPath + payload).once("value")).val();
    // generate changes object to remove topic collection data
    const dataRemoveChanges = {};
    if (topics) {
        for (const topicId of Object.keys(topics)) {
            dataRemoveChanges[topicId] = null;
        }
    }
    // now delete from data
    let [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(topicCollectionDataPath + payload)
            .update(dataRemoveChanges)
    );
    if (err) {
        toastError({ ...error, code: err.code });
    } else {
        // now delete from list
        [err] = yield to(
            firebase.defaultApp
                .database()
                .ref(topicCollectionListPath + payload)
                .remove()
        );
        if (err) {
            toastError({ ...error, code: err.code });
        }
    }
}

export function* updateCollection({ payload: { path, data: { BeLA_id_t1, BeLA_id_t2 , ...data }}}) {
    let [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(topicCollectionListPath + path)
            .update(data)
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to update Topic Collection", message: DB_ERROR });
    }
    [err] = yield to(
        firebase.defaultApp
            .database()
            .ref(`/config/topic_collections/${path}`)
            .update({ BeLA_id_t1, BeLA_id_t2 })
    );
    if (err) {
        toastError({ code: err.code, header: "Failed to update Topic Collection BeLA IDs", message: DB_ERROR });
    }
}

export function* startSync({ payload }) {
    try {
        let syncCollection = firebase.defaultApp.functions().httpsCallable("syncCollection");
        yield syncCollection({ name: payload.name, collectionId: payload.key, bela_id: payload.BeLA_id });
    } catch (err) {
        toastError({ code: err.code, header: "Failed to sync Topic Collection", message: err.message });
    }
    yield put(onEndSync());
}

export function* selectCollection({ payload }) {
    if (payload) {
        yield put(push("/topicCollections/" + payload));
    }
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.TC_ADD_TOPIC, addTopic),
        takeEvery(TYPES.TC_DELETE_TOPIC, deleteTopic),
        takeEvery(TYPES.TC_MOVE_TOPIC, moveTopic),
        takeEvery(TYPES.TC_UPDATE_TOPIC, updateTopic),
        takeEvery(TYPES.TC_ADD_COLLECTION, addCollection),
        takeEvery(TYPES.TC_DELETE_COLLECTION, deleteCollection),
        takeEvery(TYPES.TC_UPDATE_COLLECTION, updateCollection),
        takeLatest(TYPES.TC_START_SYNC, startSync),
        takeEvery(TYPES.TC_SELECT_COLLECTION, selectCollection),
        fork(TopicSelectorSaga),
        fork(TopicTreeSelectorSaga),
    ]);
}
