import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Dropdown } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react";
import TopicTreeSelector from "../../../TopicCollection/TopicTreeSelector";

export default class AssignTagsTopics extends React.PureComponent {

    static propTypes = {
        fbCollection: PropTypes.string.isRequired,
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        problems: PropTypes.object,
        tagOptions: PropTypes.array,
        authorId: PropTypes.string,
    }

    state ={
        topics: {},
        tags: [],
        tagOptions: undefined,
    }

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(props, state) {
        if (undefined === state.tagOptions) {
            return {tagOptions: props.tagOptions};
        }
        return null;
    }

    handleSave = () => {
        const { tags, topics } = this.state;
        const { fbCollection, problems, authorId, onSave, onClose } = this.props;
        onSave && onSave({ fbCollection, problems, tags, topics, authorId });
        onClose();
    }

    handleCancel = () => {
        const { onClose } = this.props;
        this.setState({ tagOptions: undefined });
        onClose();
    }

    handleTagChange = (e, {value}) => {
        this.setState({ tags: value });
    };

    handleTagAdd = (e, {value}) => {
        const { tagOptions } = this.state;
        let tags = [...tagOptions, {key: value, value, text: value}];
        this.setState({ tagOptions: tags });
    };

    handleTopicChange = topics => {
        this.setState({ topics: {...topics }});
    }

    handleRemoveTopic = topicId => {
        const { topics } = this.state;
        if (topics[topicId] === undefined) return;
        const newTopics = { ...topics };
        delete newTopics[topicId];
        this.setState({ topics: newTopics });
    };

    render() {
        const { tags, topics, tagOptions } = this.state;
        const entityName = this.props.fbCollection === "problemSet" ? "Problems" : "Questions";

        return (
            <Modal centered={false} open={true}>
                <Modal.Header>Assign Tags and Topics</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Tags to assign to {entityName}</label>
                            <Dropdown
                                placeholder="Select a tag"
                                fluid
                                multiple
                                search
                                selection
                                options={tagOptions}
                                onChange={this.handleTagChange}
                                onAddItem={this.handleTagAdd}
                                value={tags}
                                allowAdditions
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Topics</label>
                            <TopicTreeSelector
                                onChange={this.handleTopicChange}
                                selectedTopics={topics || {}}
                                enableLessonFilter
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons onSave={this.handleSave} onCancel={this.handleCancel} />
                </Modal.Actions>
            </Modal>
        );
    }
}
