import { EditorState, Modifier } from "draft-js";
import { getSelectionEntity, getSelectionText, getEntityRange } from "draftjs-utils";

import { expandEntitySelection } from "../utils/utils";
import { ENTITY_TYPE } from "../constants";
import { glossaryDataCollection } from "../../../../KitBuilder/Glossary/GlossarySelectors";
import { getTermDefinition } from "../../../../KitBuilder/dataSource/glossary";

/**
 * Creates a function that allows to get the term definition for given key.
 *
 * The term definition will be first read from Redux's store state.
 * If not found then the definition is fetched directly from Firebase.
 *
 * Function declaration: getGlossaryTermDefinition(termkey: string): Promise<string>
 *
 * @param {object} store reference to application's Redux store
 * @returns {function} getter for definition HTML
 */
const buildGetGlossaryDefinition = (store) => async (termKey) => {
    const state = store && store.getState();
    const data = state && glossaryDataCollection(state);
    const term = data && data[termKey];

    if (term) {
        return term.content && term.content.html;
    }
    return await getTermDefinition(termKey);
};

const getSelectionAsGlossaryTerm = (editorState) => {
    const currentEntityKey = getSelectionEntity(editorState);

    if (currentEntityKey) {
        const entity = editorState.getCurrentContent().getEntity(currentEntityKey);

        if (entity.getType() === ENTITY_TYPE.GLOSSARY) {
            const data = entity.getData();

            return {
                currentEntityKey,
                currentText: data.name,
                currentTermKey: data.key,
            };
        }
    }

    return {
        currentText: getSelectionText(editorState),
    };
};

// Inspired by https://github.com/jpuri/react-draft-wysiwyg/blob/7bade41044c62045f1a1d86d8cfd5b455e6003bd/src/controls/Link/index.js
const linkGlossaryTerm = (editorState, glossaryTerm) => {
    const { key, name } = glossaryTerm;
    const selection = editorState.getSelection();
    const termText = getSelectionText(editorState) || name;  // when no text is currently selected, the term name will inserted as a text of a new entity.

    const entityKey = editorState
        .getCurrentContent()
        .createEntity(ENTITY_TYPE.GLOSSARY, "MUTABLE", { key, name })
        .getLastCreatedEntityKey();

    const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        selection,
        `${termText}`,
        editorState.getCurrentInlineStyle(),
        entityKey,
    );

    const newEditorState = EditorState.push(editorState, contentState, "insert-characters");
    return EditorState.acceptSelection(newEditorState, contentState.getSelectionAfter());
};

const unlinkGlossaryTerm = (editorState, entityKey) => {
    const entityRange = getEntityRange(editorState, entityKey);
    let selection = editorState.getSelection();
    selection = expandEntitySelection(entityRange, selection);
    const contentState = Modifier.applyEntity(editorState.getCurrentContent(), selection, null);
    return EditorState.push(editorState, contentState, "apply-entity");
};

export {
    buildGetGlossaryDefinition,
    getSelectionAsGlossaryTerm,
    linkGlossaryTerm,
    unlinkGlossaryTerm,
};
