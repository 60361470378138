const TYPES = {
    RESOURCES_SOURCE_TYPE_SAVE: "Resources/SourceType/SAVE",
    RESOURCES_SOURCE_TYPE_SAVED: "Resources/SourceType/SAVED",
};

const onSave = (payload) => ({
    type: TYPES.RESOURCES_SOURCE_TYPE_SAVE,
    payload,
});

const onSaved = (payload) => ({
    type: TYPES.RESOURCES_SOURCE_TYPE_SAVED,
    payload,
});

export {
    TYPES,
    onSave,
    onSaved,
};
