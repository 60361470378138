import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import LessonProblemSetMenu from "./LessonProblemSetMenu";
import { firebaseConnect } from "react-redux-firebase";
import { getSelectedProblemSet, selectedLesson, isDirty, makeGetLessonProblemSets } from "../../LessonContentSelectors";
import { onSetProblemSet, onSelectProblemSet } from "../LessonProblemSetActions";
import { createNewProblemSet } from "../../../../QuestionUtilizator/ProblemSetWizard/problemSetWizardActions";
import { onSetDirty } from "../../LessonContentActions";

const mapStateToPropsFactory = () => {
    const getLessonProblemSets = makeGetLessonProblemSets();
    return (state, props) => ({
        problemSet: getSelectedProblemSet(state, props),
        lessonProblemSets: getLessonProblemSets(state, props),
        isDirty: isDirty(state),
    });
};

const dispatchToProps = dispatch => bindActionCreators({
    onSetProblemSet,
    createNewProblemSet,
    onSelectProblemSet,
    onSetDirty,
}, dispatch);

export default compose(
    connect((state, props) => ({
        selectedLesson: selectedLesson(state, props),
    })),
    firebaseConnect(({ selectedLesson, collection }) => {
        if (selectedLesson && selectedLesson[collection]) {
            return Object.keys(selectedLesson[collection]).map(ps => `problemSet/${ps}`);
        }
    }),
    connect(
        mapStateToPropsFactory,
        dispatchToProps
    )
    // spinnerWhileLoading(["lessonProblemSets"])
)(LessonProblemSetMenu);
