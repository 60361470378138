const TYPES = {
    CONVERT_PROBLEM_DATA_START: "CONVERT_PROBLEM_DATA_START",
    CONVERT_PROBLEM_DATA_FINISHED: "CONVERT_PROBLEM_DATA_FINISHED",
    CONVERT_PROBLEM_DATA_MESSAGE: "CONVERT_PROBLEM_DATA_MESSAGE",
};

const onConvertProblemDataStart = () => ({
    type: TYPES.CONVERT_PROBLEM_DATA_START,
});

const onConvertProblemDataMessage = payload => ({
    type: TYPES.CONVERT_PROBLEM_DATA_MESSAGE,
    payload,
});

const onConvertProblemDataFinished = () => ({
    type: TYPES.CONVERT_PROBLEM_DATA_FINISHED,
});


export {
    TYPES,
    onConvertProblemDataStart,
    onConvertProblemDataMessage,
    onConvertProblemDataFinished,
};
