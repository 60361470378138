import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Popup, List } from "semantic-ui-react";

export default class LessonSchedule extends PureComponent {
  static propTypes = {
      startWeek: PropTypes.number,
      length: PropTypes.number,
  };

  render() {
      const { startWeek, length } = this.props;
      return (
          <Popup
              trigger={<div style={{float: "right", marginRight: "0.4em" }}>
                  <span style={{paddingRight: "0.5em"}}>Week</span>
                  <span style={{fontSize: "1.5em"}}>{startWeek}</span>
                  {length > 1 && (<React.Fragment> to <span style={{fontSize: "1.5em"}}>{startWeek + length - 1}</span></React.Fragment>)}
              </div>}
          >
              <Popup.Header>Lesson schedule</Popup.Header>
              <Popup.Content>
                  <List bulleted>
                      <List.Item>Start week: {startWeek}</List.Item>
                      <List.Item>Length: {length}</List.Item>
                      <List.Item>Last week: {startWeek + length - 1}</List.Item>
                  </List>
              </Popup.Content>
          </Popup>
      );
  }
}
