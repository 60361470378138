import React, { PureComponent } from "react";
import { List, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import LessonDraggable from "./LessonDraggable";
import NoDataPlaceholder from "../../../../component/NoDataPlaceholder";
import { AddItemButton } from "bmd-react";

export default class LessonsList extends PureComponent {
    static propTypes = {
        lessonsList: PropTypes.array.isRequired,
        lessonUnitId: PropTypes.string.isRequired,
        onLessonShowProperties: PropTypes.func,
        droppablePlaceholder: PropTypes.object,
        canEdit: PropTypes.bool,
        maxOrdering: PropTypes.number,
    }

    handleAddLesson = () => {
        const { lessonUnitId: lesson_unit_id, onLessonShowProperties, maxOrdering } = this.props;
        onLessonShowProperties(true, { lesson_unit_id, ordering: maxOrdering + 1 }, false);
    }

    render() {
        const { lessonsList, droppablePlaceholder, canEdit } = this.props;
        return (
            <React.Fragment>
                {lessonsList && lessonsList.length ?
                    <List className="outlineElement lessonsList">
                        {lessonsList.map((lesson, index) => <LessonDraggable
                            key={lesson.key}
                            index={index}
                            lessonData={lesson}
                            canEdit={canEdit}
                        />)}
                    </List>
                    : 
                    <NoDataPlaceholder text="Here we list lessons of your lesson unit." icon="file outline">
                        <p>Add new lesson using the button below or drag here some lesson from another lesson unit.</p>
                        {canEdit && <Button primary content="Add lesson" title="Add lesson" onClick={this.handleAddLesson} />}
                    </NoDataPlaceholder>
                }
                {canEdit && droppablePlaceholder}
                {canEdit && lessonsList && lessonsList.length ?
                    <AddItemButton buttonText="Add lesson" onClick={this.handleAddLesson} title="Create new lesson in this lesson unit" />
                    : null}
            </React.Fragment>
        );
    }
}
