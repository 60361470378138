const TYPES = {
    SIPI_TEMPLATES_LIST_SET_FILTER: "SIPI_TEMPLATES_LIST_SET_FILTER",
    SIPI_TEMPLATES_LIST_SELECT_TEMPLATE: "SIPI_TEMPLATES_LIST_SELECT_TEMPLATE",
    SIPI_TEMPLATES_LIST_CREATE_TEMPLATE: "SIPI_TEMPLATES_LIST_CREATE_TEMPLATE",
    SIPI_TEMPLATES_LIST_SET_MODULE_FILTER: "SIPI_TEMPLATES_LIST_SET_MODULE_FILTER",
};

const onChangeFilter = (payload) => ({
    type: TYPES.SIPI_TEMPLATES_LIST_SET_FILTER,
    payload
});

const onSelectSiPiTemplate = (payload) => ({
    type: TYPES.SIPI_TEMPLATES_LIST_SELECT_TEMPLATE,
    payload
});

const onCreateTemplate = (payload) => ({
    type: TYPES.SIPI_TEMPLATES_LIST_CREATE_TEMPLATE,
    payload
});

const onSetModuleFilter = (payload) => ({
    type: TYPES.SIPI_TEMPLATES_LIST_SET_MODULE_FILTER,
    payload
});

export {
    TYPES,
    onChangeFilter,
    onSelectSiPiTemplate,
    onCreateTemplate,
    onSetModuleFilter,
};
