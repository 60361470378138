import React from "react";
import PropTypes from "prop-types";
import TagsContainer from "../Tags/TagsContainer";
import WidgetTopics from "../Widget/WidgetTopics";
import "./WidgetList.scss";
import RemoveIcon from "../../component/RemoveIcon";
import { WidgetLessonsCount, WidgetLessonsPopup } from "../Widget/WidgetLessons";
import { confirmUnsave } from "../LessonContent/utils";
import { List , AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import cx from "classnames";
import { LockIcon } from "../../Lock";

const cache = new CellMeasurerCache({
    defaultHeight: 50,
    fixedWidth: true,
});
export default class WidgetList extends React.PureComponent {
    static propTypes = {
        onSelectWidget: PropTypes.func,
        widgets: PropTypes.array,
        onDelete: PropTypes.func,
        selectedWidgetId: PropTypes.string,
        simple: PropTypes.bool,
        widgetsInfo: PropTypes.object,
        isAuthorAtLeast: PropTypes.bool,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
        widgetLocks: PropTypes.object,
    };

    state = {
        viewUsedBy: null,
    };

    handleShowUsedBy = (e, { widgetId, usedBy }) => {
        if (this.state.viewUsedBy) {
            this.setState({ viewUsedBy: null });
            return;
        }

        const context = e.target || e.currentTarget;
        if (context && usedBy && Object.keys(usedBy).length) {
            const viewUsedBy = { context, usedBy, widgetId };
            this.setState({ viewUsedBy });
        }
    }

    handleHideUsedBy = () => {
        if (this.state.viewUsedBy) {
            this.setState({ viewUsedBy: null });
            return;
        }
    }

    handleSelectWidget = ({currentTarget}) => {
        const { isDirty, onSetDirty, onSelectWidget } = this.props;
        if (!isDirty || confirmUnsave("When you switch to another widget", onSetDirty)) {
            onSelectWidget(currentTarget.id);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state && state.viewUsedBy && props.simple) {
            return { viewUsedBy: null };
        }
        return null;
    }

    rowRenderer = ({
        index, // Index of row
        key, // Unique key within array of rendered rows
        parent, // Reference to the parent List (instance)
        style, // Style object to be applied to row (to position it);
    }) => {
        const widget = this.props.widgets[index];
        const canEdit = this.props.isAuthorAtLeast;
        const { widgetLocks } = this.props;

        return (
            <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                {({ measure }) => (
                    <div className="widgetList editable" style={style} key={key} onLoad={measure}>
                        <div className="widgetListLink">
                            <a onClick={this.handleSelectWidget} id={widget.key} title={widget.name} className="name limitedTextWidth">{widget.name}</a>
                            <div><WidgetLessonsCount widgetId={widget.key} usedBy={widget.used} onClick={this.handleShowUsedBy} /></div>
                            <div><WidgetTopics  widget={widget} id={widget.key} libraryMode canEdit={canEdit} /></div>
                            <div><TagsContainer tags={widget.tags || {}} verbose path={`widgetLibrary/list/${widget.key}/tags`} canEdit={canEdit} /></div>
                        </div>
                        {widgetLocks && widgetLocks[widget.key] && <LockIcon />}
                        {!widget.used && canEdit ? <RemoveIcon onRemove={this.props.onDelete} id={widget.key}  entityName={`widget ${widget.name}`} /> : <span />}
                    </div>
                )}
            </CellMeasurer>
        );
    };

    rowRendererSimple = ({
        index, // Index of row
        key, // Unique key within array of rendered rows
        style, // Style object to be applied to row (to position it);
    }) => {
        const widget = this.props.widgets[index];
        const {disabled, widgetLocks} = this.props;

        return (
            <div
                className={cx("limitedTextWidth", "widgetList", "simple", {
                    active: widget.key === this.props.selectedWidgetId,
                })}
                key={key}
                style={style}
                id={widget.key}
                {...!disabled && { onClick: this.handleSelectWidget}}
            >
                {widgetLocks && widgetLocks[widget.key] && <LockIcon />}&nbsp;{widget.name}
            </div>
        );
    };

    findIndexInOrderedList = () => {
        const { widgets, selectedWidgetId } = this.props;
        return widgets.map(widget => widget.key).indexOf(selectedWidgetId);
    };

    render() {
        const { widgets, selectedWidgetId, simple } = this.props;
        const { viewUsedBy } = this.state;
        cache.clearAll();
        return (
            <React.Fragment>
                {viewUsedBy && <WidgetLessonsPopup {...viewUsedBy} onClose={this.handleHideUsedBy} />}
                {simple ? (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                width={width}
                                rowCount={widgets.length}
                                rowRenderer={this.rowRendererSimple}
                                rowHeight={30}
                                {...selectedWidgetId && { scrollToIndex: this.findIndexInOrderedList() }}
                            />
                        )}
                    </AutoSizer>
                ) : (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                width={width}
                                rowCount={widgets.length}
                                rowRenderer={this.rowRenderer}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                            />
                        )}
                    </AutoSizer>
                )}
            </React.Fragment>
        );
        
    }
}
