const TYPES = {
    SIPI_CLONER_SELECT_TEMPLATE: "SIPI_CLONER_SELECT_TEMPLATE",
};

const onSelectTemplate = (payload) => ({
    type: TYPES.SIPI_CLONER_SELECT_TEMPLATE,
    payload
});

export {
    TYPES,
    onSelectTemplate
};
