import React, { PureComponent } from "react";
import { Modal, Menu, Icon, Form, Dropdown, Button, Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";
import TopicTreeSelector from "../../TopicCollection/TopicTreeSelector";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { getModulesOptions } from "../../External/Tenants/tenantSelectors";

class AdvancedFilter extends PureComponent {
    static propTypes = {
        usedTags: PropTypes.array,
        selectedTags: PropTypes.array,
        onSelectTag: PropTypes.func,
        entityName: PropTypes.string,
        unusedFilter: PropTypes.bool,
        onChangeUnusedFilter: PropTypes.func,
        usedTopics: PropTypes.object,
        selectedTopic: PropTypes.string,
        onSelectTopic: PropTypes.func,
        onSetModuleFilter: PropTypes.func,
        moduleFilter: PropTypes.array,
        modulesOptions: PropTypes.array,
        singleModule: PropTypes.bool,
    };

    state = {
        open: false
    };

    handleUnusedFilter = (e, { checked }) => {
        this.props.onChangeUnusedFilter(checked);
    }

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    composeTopicFilterLabel = (count) => {
        if (0 == count) {
            return "Filter";
        } else if (1 == count) {
            return "Filtered (by 1)";
        } else {
            return `Filtered (by ${count})`;
        }
    }


    handleSelectTopic = topic => {
        const { selectedTopic, onSelectTopic } = this.props;
        onSelectTopic(topic !== selectedTopic ? topic : null);
    }

    handleChange = (e, {name, value}) => {
        if ("tag" === name) {
            this.props.onSelectTag(value.map(v => v.trim()).filter(Boolean));
        } else if ("module" === name) {
            this.props.onSetModuleFilter(this.props.singleModule ? "" === value ? [] : [value] : value.map(v => v.trim()).filter(Boolean));
        }
    }

    render() {
        const {
            usedTags = [],
            selectedTags,
            entityName,
            unusedFilter,
            usedTopics,
            selectedTopic,
            onSelectTopic,
            moduleFilter,
            onSetModuleFilter,
            modulesOptions,
            onSelectTag,
            singleModule,
        } = this.props;
        const { open } = this.state;
        return (
            <Modal
                open={open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                trigger={
                    <Menu.Item link title="Click here to set advanced filter.">
                        <Icon name="filter" />
                        {this.composeTopicFilterLabel((selectedTags ? selectedTags.length : 0)  + (unusedFilter ? 1 : 0) + (selectedTopic ? 1 : 0) + (moduleFilter ? moduleFilter.length : 0))}
                    </Menu.Item>
                }
                size="small"
            >
                <Modal.Header>Advanced {entityName} filter</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSave}>
                        {unusedFilter !== undefined && <Form.Field style={{float: "right"}}><Checkbox toggle label="Show unused only" checked={unusedFilter} onChange={this.handleUnusedFilter} /></Form.Field>}
                        {onSelectTag && <Form.Field>
                            <label>
                                Select tags to filter {entityName}s
                            </label>
                            <Dropdown
                                fluid
                                multiple
                                closeOnChange
                                selection
                                search
                                options={usedTags}
                                value={selectedTags}
                                placeholder="Select tags"
                                name="tag"
                                onChange={this.handleChange}
                            />
                        </Form.Field>}
                        { onSetModuleFilter && <Form.Field>
                            <label>
                                Select package to filter {entityName}s
                            </label>
                            <Dropdown
                                fluid
                                closeOnChange
                                selection
                                search
                                multiple={!singleModule}
                                clearable={singleModule}
                                name="module"
                                options={modulesOptions || []}
                                value={singleModule ? 0 === moduleFilter.length ? null : moduleFilter[0] : moduleFilter}
                                placeholder="Select package"
                                onChange={this.handleChange}
                            />
                        </Form.Field>}
                        {onSelectTopic && <Form.Field label={`Select topic to filter ${entityName}s`} />}
                    </Form>
                    {onSelectTopic && <TopicTreeSelector
                        single
                        onChange={this.handleSelectTopic}
                        selectedTopics={selectedTopic}
                        filterOnly={usedTopics}
                    />}
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = function(state) {
    return {
        modulesOptions: getModulesOptions(state),
    };
};

export default compose(
    firebaseConnect(["modules/list", "tenant_modules"]),
    connect(
        mapStateToProps,
    )
)(AdvancedFilter);
