import React, { Component } from "react";
import PropTypes from "prop-types";

import TableEditor from "./TableDecoratorEditor";
import { setCaretToEntity } from "../utils/utils";
import { replaceRelLinks } from "../../../../utils/conversionUtils";

class TableDecorator extends Component {
    static propTypes = {
        contentState: PropTypes.object.isRequired,
        entityKey: PropTypes.string.isRequired,
        enableParentEditor: PropTypes.func.isRequired,
        disableParentEditor: PropTypes.func.isRequired,
        isParentReadOnly: PropTypes.func.isRequired,
        getEditorState: PropTypes.func.isRequired,
        setEditorState: PropTypes.func.isRequired,
        sharedToolbar: PropTypes.object.isRequired,
        registerNestedEditor: PropTypes.func.isRequired,
        reviewMode: PropTypes.bool.isRequired,
        rootEntityKey: PropTypes.string,
        children: PropTypes.any,
    };

    state = {
        isTableEditorOpened: false,
    };

    handleOpenTableEditor = (e) => {
        const { isParentReadOnly, disableParentEditor } = this.props;
        e.stopPropagation();

        if (isParentReadOnly()) {
            // Parent editor already locked by another nested editor
            return;
        }

        disableParentEditor(true);
        this.setState({ isTableEditorOpened: true });
    };

    handleCloseTableEditor = () => {
        const { enableParentEditor } = this.props;
        enableParentEditor();
        this.setState({
            isTableEditorOpened: false,
        });
    };

    handleSetCaretToEntity = (e) => {
        e.preventDefault();
        const { entityKey, getEditorState, setEditorState, children } = this.props;
        const { block } = children[0].props;
        const blockKey = block.getKey();
        const newEditorState = setCaretToEntity(getEditorState(), blockKey, entityKey);
        setEditorState(newEditorState);
    };

    render() {
        const { contentState, entityKey } = this.props;
        const entity = contentState.getEntity(entityKey);
        const { html } = entity.getData();

        return (
            <div
                className="tableDecorator"
                contentEditable={false}
                onClick ={this.handleSetCaretToEntity}
                onDoubleClick={this.handleOpenTableEditor}
            >
                <div dangerouslySetInnerHTML={{ __html: replaceRelLinks(html) }} />
                {this.state.isTableEditorOpened && <TableEditor
                    {...this.props}  // TODO pbenes: tell the truth
                    initialHtml={html}
                    onClose={this.handleCloseTableEditor}
                />}
                <div className="editorHint"><span>Double-click to edit</span></div>
            </div>
        );
    }
}

export default TableDecorator;
