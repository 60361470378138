import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Form, Divider, Dimmer, Loader } from "semantic-ui-react";
import AccessControl from "../../../../auth/AccessControl";
import ActionLink from "../../../../component/ActionLink";
import "./ResourceDescription.css";
import { SaveCancelButtons } from "bmd-react";
import TextareaAutosize from "react-textarea-autosize";
import styles from "../../resources.module.scss";

export default class ResourceDescription extends PureComponent {
    static propTypes = {
        description: PropTypes.string,
        resourceId: PropTypes.number,
        editValidator: PropTypes.object,
        onSave: PropTypes.func,
        isSaving: PropTypes.bool,
    }

    state = {
        editation: false,
        value: "",
    }

    handleEditation = () => this.setState({ editation: true, value: this.props.description || "" });
    handleCancelEditation = () => this.setState({ editation: false});
    handleChange = (e) => this.setState({ value: e.target.value });
    handleSave = () => {
        const { resourceId, onSave } = this.props;
        onSave({ resourceId, description: this.state.value });
        this.handleCancelEditation();
    }

    render() {
        const { description, editValidator, isSaving } = this.props;
        const { editation, value } = this.state;
        return (
            <Dimmer.Dimmable dimmed={isSaving}>
                <Dimmer active={isSaving} inverted>
                    <Loader>Updating resource description...</Loader>
                </Dimmer>
                <div className="resource description ui">
                    <Header as="h4">Description</Header>
                    <AccessControl  {...editValidator}>
                        {!editation && (
                            <ActionLink
                                style={{display: "flex", justifyContent: "flex-end"}}
                                icon="write"
                                content="Edit"
                                title="Edit resource description"
                                onClick={this.handleEditation}
                            />
                        )}
                    </AccessControl>
                </div>
                {editation ? (
                    <React.Fragment>
                        <Form>
                            <Form.Field
                                control={TextareaAutosize}
                                style={{margin: "0.5em 0"}}
                                name="description"
                                value={value}
                                onChange={this.handleChange}
                                placeholder="Here you can fill resource description."
                            />
                        </Form>
                        <Divider hidden fitted />
                        <SaveCancelButtons
                            onSave={this.handleSave}
                            saveDisabled={description === value}
                            onCancel={this.handleCancelEditation}
                        />
                    </React.Fragment>
                ) : <div>{description || <i className={styles.noDataMsg}>No description.</i>}</div>}
            </Dimmer.Dimmable>
        );
    }
}
