import firebase from "../../firebase";
import to from "await-to-js";

const basePath = "widgetTemplates";
const dataPath = "widgetTemplates/data";
const listPath = "widgetTemplates/list";

const pathToItemsData = (templateId, itemId) => (
    itemId ? `${dataPath}/${templateId}/items/${itemId}` : `${dataPath}/${templateId}/items`
);
const pathToUsedFlags = (templateId, widgetId) => (
    widgetId ? `${listPath}/${templateId}/used/${widgetId}` : `${listPath}/${templateId}/used`
);

export const paths = {
    basePath,
    dataPath,
    listPath,
    toDataEntry: (templateId) => `${dataPath}/${templateId}`,
    toListEntry: (templateId) => `${listPath}/${templateId}`,
    toItemsData: pathToItemsData,
    toUsedFlags: pathToUsedFlags,
};

/**
 * Get both database references to widget template.
 *
 * @param {string} templateId widget template identifier
 * @returns {object} Firebase references, {dataRef, listRef}
 */
export const getReferences = (templateId) => {
    const dataRef = firebase.getFirebaseData(paths.toDataEntry(templateId));
    const listRef = firebase.getFirebaseData(paths.toListEntry(templateId));
    return { dataRef, listRef };
};

/**
 * Get database references to list of items or specific items.
 *
 * @param {string} templateId widget template identifier
 * @param {string} itemId identifier of items belonging template
 */
export const getItemsReference = (templateId, itemId) => (
    firebase.getFirebaseData(paths.toItemsData(templateId, itemId))
);

/**
 * Create a new template record.
 *
 * @param {object} config attributes of the new template
 * @return {string} key/identifier of created template
 */
export const createTemplate = async (config) => {
    const { name = "New Template", author, created, tenant, ...data } = config || {};
    const dataRef = firebase.getFirebaseData(dataPath);
    let [err, result] = await to(dataRef.push({ ...data }));
    if (err) {
        return [ err ];
    }
    const key = result.key;
    const listRef = firebase.getFirebaseData(listPath);
    [err, result] = await to(listRef.update({ [key]: { name, author: author + "", created, t: tenant } }));

    return [err, key];
};

/**
 * Remove the template record.
 *
 * @param {string} templateId widget template identifier
 */
export const removeTemplate = async (templateId) => {
    if (!templateId) {
        return [];
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`data/${templateId}`] : null,
        [`list/${templateId}`] : null,
    };
    return await to(baseRef.update(changes));
};

/**
 * Change name of the template.
 *
 * @param {string} templateId widget template identifier
 * @param {string} name non-empty display name of template
 */
export const renameTemplate = async (templateId, name) => {
    if (!templateId || !name) {
        return [];
    }
    const baseRef = firebase.getFirebaseData(basePath);
    return await to(baseRef.update({[`list/${templateId}/name`] : name}));
};

/**
 * Update data of the template (together with name).
 *
 * Warning: Data node is overwritten using firebase.dataRef(templateDataPath).set()!
 *
 * @param {string} templateId widget template identifier
 * @param {string} name non-empty display name of template
 * @param {object} data template definition data
 */
export const updateTemplate = async (templateId, name, data) => {
    if (!templateId || !name || !data) {
        return [];
    }
    const baseRef = firebase.getFirebaseData(basePath);
    const changes = {
        [`data/${templateId}`] : { ...data },
        [`list/${templateId}/name`] : name,
    };
    return await to(baseRef.update(changes));
};

export const createTemplateItem = async (templateId, itemData) => {
    if (templateId && itemData) {
        const itemsRef = firebase.getFirebaseData(paths.toItemsData(templateId));
        return await to(itemsRef.push(itemData));
    }
    return [];
};

export const updateTemplateItem = async (templateId, itemId, changes) => {
    if (templateId && itemId && changes) {
        const itemRef = firebase.getFirebaseData(paths.toItemsData(templateId, itemId));
        return await to(itemRef.update(changes));
    }
    return [];
};

export const removeTemplateItem = async (templateId, itemId) => {
    if (templateId && itemId) {
        const itemRef = firebase.getFirebaseData(paths.toItemsData(templateId, itemId));
        return await to(itemRef.remove());
    }
    return [];
};

/**
 * Change the order of template items.
 *
 * @param {string} templateId widget template identifier
 * @param {array} ordering item positions: [{ key, position }]
 */
export const updateItemPositions = async (templateId, ordering) => {
    const itemsRef = getItemsReference(templateId);
    const changes = ordering.reduce((updates, item) => {
        updates[`${item.key}/position`] = item.position;
        return updates;
    }, {});
    return await to(itemsRef.update(changes));
};

/**
 * Get initial content of widget items from template.
 *
 * @param {string} templateId widget template identifier
 * @return {object} initial content dataset, { [key]: content }
 */
export const getInitialContent = async (templateId) => {
    const itemsRef = getItemsReference(templateId);
    const itemsData = await itemsRef.once("value").then((sc) => sc.val());
    return itemsData && Object.keys(itemsData).reduce((content, key) => {
        const item = itemsData[key];
        if (item && item.content) {
            content[key] = item.content;
        }
        return content;
    }, {});
};

/**
 * Update widget template setup
 * @param {string} templateId widget template identifier
 * @param {object} changes changes
 */
export const updateTemplateSetup = async (templateId, changes) => {
    if (templateId && changes) {
        const dataRef = firebase.getFirebaseData(paths.toDataEntry(templateId));
        return await to(dataRef.update(changes));
    }
    return [];
};

/**
 * Clone widget template.
 *
 * @param {object} template widget template
 * @param {string} name name of cloned template
 */
export const cloneTemplate = async (template, name, config) => {
    const dataRef = firebase.getFirebaseData(dataPath);
    let [err, result] = await to(dataRef.push({ setup: template.setup || null }));
    if (err) {
        return [err, result];
    }
    const key = result.key;
    [err, result] = await to(updateTemplateSetup(key, { setup: { ...template.setup }}));
    if (err) {
        return [err, result];
    }
    if (template.items) {
        await Object.keys(template.items).map(async itemId => {
            const item = template.items[itemId];
            const [err, result] = await createTemplateItem(key, { ...item });
            if (!err) {
                await updateTemplateItem(key, result.key);
            }
        });
    }
    const { author, created, tenant } = config;
    const listRef = firebase.getFirebaseData(listPath);
    [err, result] = await to(listRef.update({ [key]: { name, created, author: author + "", tags: template.tags || null, t: tenant } }));
    return [err, key];
};

