import { TYPES } from "./WidgetLibraryActions";
import { formatWidgetItemUid } from "./WidgetLibraryUtils";

const initialState = {
    selectedItem: null,
    searchFilter: "",
    selectedTags: [],
    selectedTopic: null,
    unusedFilter: false,
    moduleFilter: [],
    reviewMode: false,
    isSaving: {},
    isEditing: {},
    isDirty: false,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.WIDGET_LIBRARY_ITEM_SELECTED:
            return { ...state, selectedItem: payload };
        case TYPES.WIDGET_LIBRARY_SEARCH_CHANGED:
            return { ...state, searchFilter: payload };
        case TYPES.WIDGET_LIBRARY_SELECT_TAG: {
            return { ...state, selectedTags: payload };
        }
        case TYPES.WIDGET_LIBRARY_SELECT_TOPIC: {
            return { ...state, selectedTopic: payload };
        }
        case TYPES.WIDGET_LIBRARY_EDIT_ITEM: {
            const uid = formatWidgetItemUid(payload);
            return {
                ...state,
                reviewMode: payload.isReview,
                isEditing: { ...state.isEditing, [uid]: true },
            };
        }
        case TYPES.WIDGET_LIBRARY_CLOSE_ITEM_EDITOR: {
            const uid = formatWidgetItemUid(payload);
            return {
                ...state,
                isSaving: { ...state.isSaving, [uid]: undefined },
                isEditing: { ...state.isEditing, [uid]: undefined },
            };
        }
        case TYPES.WIDGET_LIBRARY_SAVING_ITEM: {
            const uid = formatWidgetItemUid(payload);
            return { ...state, isSaving: { ...state.isSaving, [uid]: true } };
        }
        case TYPES.WIDGET_LIBRARY_CHANGE_UNUSED_FILTER:
            return { ...state, unusedFilter: payload };
        case TYPES.WIDGET_LIBRARY_SET_DIRTY:
            return { ...state, isDirty: payload };
        case TYPES.WIDGET_LIBRARY_SET_MODULE_FILTER:
            return { ...state, moduleFilter: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
