import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { DraggableWrapper } from "../Dnd";
import Lesson from "./Lesson";
import LessonContainer from "./LessonContainer";

export default class LessonDraggable extends PureComponent {
    static propTypes = {
        lessonData: Lesson.propTypes.lessonData,
        index: PropTypes.number,
        canEdit: PropTypes.bool,
    }

    render() {
        const { lessonData: lesson, index, canEdit } = this.props;
        return canEdit ? (
            <Draggable key={lesson.key} draggableId={lesson.key} index={index}>
                {provided => {
                    return (
                        <DraggableWrapper provided={provided} innerRef={provided.innerRef}>
                            <LessonCont lesson={lesson} canEdit={canEdit} provided={provided} />
                        </DraggableWrapper>
                    );
                }}
            </Draggable>
        ) : <LessonCont lesson={lesson} canEdit={canEdit} provided={{}}/>;
    }
}

const LessonCont = ({ lesson, canEdit, provided }) => (
    <LessonContainer
        lessonData={lesson}
        canEdit={canEdit}
        dragHandle={<Icon
            name="bars"
            {...provided.dragHandleProps}
            title="Change order"
            style={{ cursor: "move", marginLeft: "-0.8em"}}
            className="actionIconClass"
        />}
        // collapsible         // uncomment this to make Lesson collapsible
    />
);

LessonCont.propTypes = {
    lesson: PropTypes.object,
    canEdit: PropTypes.bool,
    provided: PropTypes.object,
};