import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Divider, Button } from "semantic-ui-react";
import QuestionItemContent from "../../Widget/QuestionItemContent";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { getSnippetsAsOptions } from "../../Snippets/SnippetsManagerSelectors";
import TemplateQuestionStyleDialog from "./TemplateQuestionStyleDialog";
import { bindActionCreators } from "redux";
import { onUpdateItem } from "../WidgetTemplatesActions";
import {
    getSnippetToggleButtonHTML,
    getSnippetBlockContentHTML
} from "../../Snippets/SnippetUtils";

class TemplateQuestionItemEditor extends PureComponent {
    static propTypes = {
        item: PropTypes.object,
        snippets: PropTypes.array,
        onUpdateItem: PropTypes.func,
        id: PropTypes.string,
    };

    state = {
        open: false,
    }

    handleClose = () => {
        this.setState({ open: false });
    }
    
    handleOpen = () => {
        this.setState({ open: true });
    }

    handleUpdate = (snippet, title) => {
        const { item, onUpdateItem, id } = this.props;
        const itemData = { ...item.itemData };
        if (snippet) {
            const { snippetCfg } = snippet;
            const snippet_id = snippet.key;
            if (0 < title.trim().length) {
                snippetCfg["blockData"] = { title: title.trim()};
            }
            const snippetHtml = getSnippetToggleButtonHTML(snippet.key, snippetCfg)
                    + getSnippetBlockContentHTML(snippet.key, snippetCfg, null);
            itemData["content"] = { snippet_id, snippets: { [snippet_id]: true }, title, html: snippetHtml };
            onUpdateItem(id, itemData);
        } else {
            itemData["content"] = null;
            onUpdateItem(id, itemData);
        }
    }

    render() {
        const { item, snippets } = this.props;
        const { content, name } = item;
        const { open } = this.state;
        return (
            <React.Fragment>
                {open && <TemplateQuestionStyleDialog
                    name={name}
                    content={content}
                    snippets={snippets}
                    onUpdate={this.handleUpdate}
                    onClose={this.handleClose}
                />}
                <Button floated="right" compact content="Style..." icon="window maximize outline" onClick={this.handleOpen}/>
                <Divider hidden clearing fitted />
                {content && content.snippet_id && 
                    <QuestionItemContent content={content} />
                }
            </React.Fragment>
        );
    }
}

const dispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onUpdateItem,
        },
        dispatch
    );
};

const mapStateToProps = function(state) {
    return {
        snippets: getSnippetsAsOptions(state),
    };
};

export default compose(
    firebaseConnect([ "snippets" ]),
    connect(mapStateToProps, dispatchToProps)
)(TemplateQuestionItemEditor);

