import { TYPES } from "./TemplateiPlannersActions";

const reducer = (state = {}, action) => {
    switch (action.type) {
        case TYPES.TEMPLATE_IPLANNER_REQUEST: {
            const { templateId } = action.payload;
            const { iPlanners } = state[templateId] || {};
            const templateState = { loading: true, iPlanners };
            return { ...state, [templateId]: templateState };
        }
        case TYPES.TEMPLATE_IPLANNER_SUCCESS: {
            const { templateId, iPlanners, others } = action.payload;
            const templateState = { iPlanners, others };
            return { ...state, [templateId]: templateState };
        }
        case TYPES.TEMPLATE_IPLANNER_FAILURE: {
            const { templateId, failure } = action.payload;
            const { iPlanners } = state[templateId] || {};
            const templateState = { iPlanners, failure };
            return { ...state, [templateId]: templateState };
        }
        default:
            // console.log("Reducer(state):", state); // eslint-disable-line no-console
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;