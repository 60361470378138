import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onSetUsedTags, onSetSearchResult, onSetUsedCourseVariants, onSetResourceTypes, onSetOwners } from "./AdvancedResourceSearchActions";
import { toastError, DB_ERROR } from "../../../component/toast";
import firebase from "../../dataSource";
import { formatQueryParam } from "../../../utils/requestUtils";

const SelectIdAndName = formatQueryParam("$select", ["id", "name"]);

function* loadUsedTags() {
    try {
        const response = yield firebase.tabletProxy({
            method: "GET",
            uri: `/tag?onlyUsed=1&${SelectIdAndName}`,
        });
        yield put(onSetUsedTags(response.data));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load used tags", message: DB_ERROR });
    }
}

function* fetchCourseVariants() {
    try {
        const response = yield firebase.tabletProxy({
            method: "GET",
            uri: "/course-variant?onlyUsedByResources=1",
        });
        yield put(onSetUsedCourseVariants(response.data));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load used courses", message: DB_ERROR });
    }
}

function* fetchOwners() {
    try {
        const response = yield firebase.tabletProxy({
            method: "GET",
            uri: "/user?onlyUsedByResources=1",
        });
        yield put(onSetOwners(response.data));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load owners", message: DB_ERROR });
    }
}

function* loadResourceTypes() {
    try {
        const response = yield firebase.tabletProxy({
            method: "GET",
            uri: "/resource-type",
        });
        yield put(onSetResourceTypes(response.data));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to load resource types", message: DB_ERROR });
    }
}

function* searchResource({ payload: { name, tags, courseVariantId, resourceType, userId, topic } }) {
    try {
        const searchQuery = [
            "/resources?$sort[name]=1&$sort[created_at]=-1",
            name && formatQueryParam("name[$like]", `%${name}%`),
            tags && formatQueryParam("tags", tags),
            resourceType && formatQueryParam("resource_type", resourceType),
            courseVariantId && formatQueryParam("course_variant_id", courseVariantId),
            userId && formatQueryParam("created_by", userId),
            topic &&  formatQueryParam("topic", topic),
        ].filter(Boolean).join("&");

        const response = yield firebase.tabletProxy({
            method: "GET",
            uri: searchQuery,
        });
        const resources = response.data;
        if (0 < resources.length) {
            yield put(onSetSearchResult(resources));
        } else {
            yield put(onSetSearchResult([]));
        }
    } catch (err) {
        toastError({ code: err.code, header: "Failed to search resource", message: DB_ERROR });
        yield put(onSetSearchResult([]));
    }
}

export default function* advancedResourceSearch() {
    yield all([
        takeLatest(TYPES.RESOURCE_ADVANCED_SEARCH_LOAD_USED_TAGS, loadUsedTags),
        takeLatest(TYPES.RESOURCE_ADVANCED_SEARCH_ADVANCED_SEARCH, searchResource),
        takeLatest(TYPES.RESOURCE_ADVANCED_SEARCH_FETCH_COURSE_VARIANTS, fetchCourseVariants),
        takeLatest(TYPES.RESOURCE_ADVANCED_SEARCH_LOAD_RESOURCE_TYPES, loadResourceTypes),
        takeLatest(TYPES.RESOURCE_ADVANCED_SEARCH_FETCH_OWNERS, fetchOwners),
    ]);
}
