import { TYPES } from "./CreateWidgetDialogActions";

const initialState = {
    selectedTopics: {},
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.CREATE_WIDGET_SELECT_TOPIC:
            return { ...state, selectedTopics: {...payload} };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
