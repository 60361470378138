import React from "react";
import { Icon, Header, Button, Divider, Menu, Breadcrumb } from "semantic-ui-react";
import PropTypes from "prop-types";
import OutlineEditorContainer from "../../Outlines/OutlineEditor/OutlineEditorContainer";
import NoDataPlaceholder from "../../../component/NoDataPlaceholder";
import KitLastEditor from "../KitLastEditor";
import AccessControl from "../../../auth/AccessControl";
import SyncLog from "./SyncLog";
import memoize from "memoize-one";


export default class AKitEditor extends React.Component {
    static propTypes = {
        onGoBack: PropTypes.func,
        onSelectOutline: PropTypes.func,

        aKitData: PropTypes.object,

        aKitId: PropTypes.string,
        onAllCourseVariantsLoad: PropTypes.func,
        enableSyncForCvs: PropTypes.object,
        selectedYearId: PropTypes.number,
        onSync: PropTypes.func,
        syncStatus: PropTypes.array,
        rawSyncData: PropTypes.object,
        onGetSyncStatus: PropTypes.func,
        enableSyncForOutline: PropTypes.bool,
    }

    state={
        showPublisher: false
    }

    selectAKitOutline = (prevKit) => {
        const { aKitData:currKit, onSelectOutline } = this.props;
        if (currKit && (!prevKit || currKit.outline_id !== prevKit.outline_id)) {
            onSelectOutline && onSelectOutline(currKit.outline_id);
        }
    }

    // load data upon startup
    componentDidMount() {
        const { onGetSyncStatus, onAllCourseVariantsLoad, aKitId } = this.props;
        onAllCourseVariantsLoad();
        onGetSyncStatus(aKitId);
        this.selectAKitOutline();
    }

    componentDidUpdate(prevProps) {
        const { selectedYearId, aKitId, rawSyncData } = this.props;
        if (prevProps.selectedYearId !== selectedYearId) {
            this.props.onAllCourseVariantsLoad();
            this.props.onGetSyncStatus(aKitId);
        } else if (prevProps.rawSyncData !== rawSyncData) {
            this.props.onGetSyncStatus(aKitId);
        }
        this.selectAKitOutline(prevProps.aKitData);
    }

    handleGoBack = (e) => {
        this.props.onSelectOutline && this.props.onSelectOutline(null);
        this.props.onGoBack && this.props.onGoBack(e);
    }

    handleSync = () => {
        const { aKitId, onSync, enableSyncForCvs } = this.props;
        onSync({ aKitId, enableSyncForCvs });
    }

    handleShowPublisher = () => this.setState({ showPublisher: true });
    handleHidePublisher = () => this.setState({ showPublisher: false });

    render() {
        const { aKitData, aKitId, enableSyncForCvs, syncStatus, enableSyncForOutline } = this.props;
        const lastStatus = syncStatus ? syncStatus[0] : null;
        const syncInProgress = null != lastStatus && ("QUEUED" === lastStatus.sync_status || "RUNNING" === lastStatus.sync_status);

        if (!aKitData) {
            return (
                <React.Fragment>
                    <Header as="h2" className="pageHeader">Author Kit</Header>
                    <NoDataPlaceholder icon="file outline" text="We show Author Kit's details here.">
                        <p>Unfortunately we cannot find any details for selected Author Kit.</p>
                        {this.handleGoBack && (
                            <Button primary content="Go to Author Kits" onClick={this.handleGoBack} />
                        )}
                    </NoDataPlaceholder>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>

                <KitBreadcrumb onGoBack={this.handleGoBack} />
                <div style={{float: "right"}}><KitLastEditor itemId={aKitId} /></div>
                <Header as="h2" className="pageHeader">{aKitData.name}</Header>
                <Divider clearing hidden />
                <AccessControl actionArray={["aKit:sync", "publishedContent:view", "aKit:publish"]}>
                    <Menu>
                        {enableSyncForCvs && (
                            <AccessControl action="aKit:sync" renderNoAccess={<React.Fragment />}>
                                <Menu.Item
                                    disabled={syncInProgress || !enableSyncForOutline}
                                    link
                                    title={syncInProgress ? (
                                        "QUEUED" === lastStatus.sync_status ? "Synchronization starts in a moment" : "Synchronizing is in progress"
                                    ) : (
                                        !enableSyncForOutline ? "There are no lessons in the kit outline." 
                                            : (lastStatus && lastStatus.updated ? "Schedule sync of kit outline to BeLA." :
                                                "Schedule first sync of this kit outline to BeLA.")
                                    )}
                                    onClick={this.handleSync}>
                                    <Icon name="refresh" loading={syncInProgress}/>Sync to BeLA
                                </Menu.Item>
                                <SyncLog syncStatus={syncStatus} />
                            </AccessControl>
                        )}
                        <Menu.Item 
                            position="right"
                            onClick={this.handleShowPublisher}>
                            <AccessControl action="aKit:publish" renderNoAccess={"Show status"}>Publish</AccessControl>
                        </Menu.Item>
                        
                    </Menu>
                </AccessControl>
                <OutlineEditorContainer 
                    outlineId={aKitData.outline_id}
                    setShowPublisher={this.state.showPublisher} 
                    onHidePublisher={this.handleHidePublisher} 
                    canEdit={aKitData.canEdit || false}
                />
            </React.Fragment>
        );
    }
}
/**
 * Prints a short Breadcrumb with hardcoded path to Author Kits.
 *
 * @class KitBreadcrumb
 * @extends {React.PureComponent}
 */
class KitBreadcrumb extends React.PureComponent {
    static propTypes = {
        /** Callback to run when clicking on the Author Kit link */
        onGoBack: PropTypes.func,
    };

    getSections = memoize((onGoBack) => [
        { key: "KitList", content: "Author Kits", link: true, onClick: onGoBack }
    ]);
      
    render() {
        const { onGoBack } = this.props;
        const sections = this.getSections(onGoBack);
        return <Breadcrumb icon="right angle" sections={sections} />;
    }
}