import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import LastEditorLabel from "../../component/LastEditorLabel";
import { ConfirmDeletePopup } from "bmd-react";
import ActionLink from "../../component/ActionLink";
const renderOnRight = { display: "grid", justifyContent: "end" };

export default class TopicTreeHeader extends PureComponent {
    static propTypes = {
        collection: PropTypes.object,
        isRemovable: PropTypes.bool,
        onDeleteCollection: PropTypes.func,
        collectionId: PropTypes.string,
    };

    renderUser = user => {
        return user ? user.first_name + " " + user.last_name : "N/A";
    };

    handleRemoveCollection = () => {
        const { collectionId, onDeleteCollection } = this.props;
        onDeleteCollection(collectionId);
    }

    render() {
        const { collection, isRemovable } = this.props;
        if (!collection) {
            return (
                <React.Fragment>
                    <NoDataPlaceholder icon="file outline" text="Unfortunately this item does not exist.">
                        <p>Please select one of the topic collection in the left menu.</p>
                    </NoDataPlaceholder>
                </React.Fragment>
            );
        }

        return (
            <div style={renderOnRight}>
                {isRemovable && (
                    <ConfirmDeletePopup
                        trigger={<ActionLink style={{...renderOnRight, marginBottom: "0.2em"}} icon="trash outline alternate" content="Remove" />}
                        headerText="Confirm remove topic collection"
                        onConfirm={this.handleRemoveCollection}
                        position="right center"
                        whatText="this collection with all its topics"
                    />)}
                <LastEditorLabel editor={this.renderUser(collection.last_editor)} editorTimestamp={collection.last_edit} />
            </div>
        );
    }
}
