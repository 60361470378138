import firebase from "../../../firebase";
import { loadData, fb_list_object, getUpdateObjectForPaths, applyUpdateObject } from "../../../utils/sagaForFBUtils";
import { compareByOrdering } from "../../../utils/selectorUtils";
import _ from "lodash";

/** TODO (review with Merlin):
- a context is being discovered in many functions. Consider passing the context too. For example instead of passing just topic_id to a function you could pass lesson_id and topic_id.
*/

/**
 * Id as used by a database to identify entities. For Firebase it is a string.
 * @typedef {string} DbId
 */

const dataPath = "outlines_data";
const listPath = "outlines";

export const paths = {
    dataPath,
    listPath,
};

export const createLesson = async (outlineId, lessonUnitId, name, ordering = 1) => {
    const lessonType = "Standard"; /* default type  */

    const dataRefLU = firebase.getFirebaseData(dataPath + `/${outlineId}/lessons`);
    const { key } = await dataRefLU.push( { name: name, lesson_unit_id: lessonUnitId, type: lessonType, ordering } );

    return key;
};

/** Finds index records of lessons meeting given criteria filter.
  * @param {DbId} id lesson id
  * @param {callback} filter function returning true if given entity meets required condition; the sasme as is used for Array.filter()
  * @returns array list of index records pointing to lessons of given id
 */
const _getLessonIndexRecordsFiltered = async(id, filter) => {

    /* find lesson index */
    const outlineDataRaw = await loadData(dataPath);
    const outlineData = fb_list_object(outlineDataRaw);

    const indexRecords = [];
    outlineData.forEach( (outline) => {

        outline.lessons &&
        fb_list_object(outline.lessons)
            .filter(filter)
            .forEach( (lesson) => {
                indexRecords.push([outline.id, lesson.id]);
            })
        ;
    });

    return indexRecords;
};

/** Finds index records of lessons with given id.
  * @param {DbId} id lesson id
  * @returns array list of index records pointing to lessons of given id
 */
const _getLessonIndexRecords = async(id) => {

    return await _getLessonIndexRecordsFiltered(id, (lesson) => (lesson.id == id) );
};

/** Finds index record of lesson with given id.
  * The function expects there is at most one matching record so it returns the first one.
  * If index record is not found a null index (index of nulls, e.g [null, null]) is returned.
  * @param {DbId} id lesson id
  * @returns index record pointing to a lesson of given id
 */
const _getLessonIndexRecord = async(id) => {

    const indexRecords = await _getLessonIndexRecords(id);
    if (indexRecords.length <= 0) {
        return [null, null];
    }

    return indexRecords[0];
};

/**
 * General algorithm for updating lesson's properties or removing it.
 *
 * @param {DbId} id lesson id
 * @param {*} value new value for property to be updated. null for removal of lesson
 * @param { function(array<array<DbId>>): array<string> } indexToPath function returning path to lesson's property to be updated or to lesson to be removed.
 * e.g. indexToPath(recordIndex) => { return `${recordIndex[0]}/lesson_units/${recordIndex[1]}//lessons/${indexRecord[2]}/name`; }
 *
 */
const _lessonUpdate = async (id, value, indexToPath) => {

    const indexRecords = await _getLessonIndexRecords(id);

    const paths = indexRecords.map( indexToPath );
    const updateObject = getUpdateObjectForPaths(paths, value);
    await applyUpdateObject(updateObject, firebase.getFirebaseData(dataPath) /* onComplete */ );
};

// export const renameLesson = async (id, name) => {
//     await _lessonUpdate(id, name,
//         (indexRecord) => `${indexRecord[0]}/lessons/${indexRecord[1]}/name`
//     );
// };

// export const changeDescriptionLesson = async (id, description) => {
//     await _lessonUpdate(id, description,
//         (indexRecord) => `${indexRecord[0]}/lessons/${indexRecord[1]}/description`
//     );
// };

// export const changeTypeLesson = async (id, type) => {
//     await _lessonUpdate(id, type,
//         (indexRecord) => `${indexRecord[0]}/lessons/${indexRecord[1]}/description`
//     );
// };

export const removeLesson = async (id) => {
    await _lessonUpdate(id, null,
        (indexRecord) => `${indexRecord[0]}/lessons/${indexRecord[1]}`
    );
};

/**
 * Add a list of topics into a lesson and set their order starting from nextOrder.
 * 
 * @param {string} lessonId 
 * @param {string} outlineId 
 * @param {array} topicIds 
 * @param {int} nextOrder 
 */
export const addTopicsToLesson = async(lessonId, outlineId, topicIds, nextOrder) => {

    /* Nothing to add */
    if (!topicIds || topicIds.length <= 0) {
        return;
    }

    const lessonPath = dataPath + `/${outlineId}/lessons/${lessonId}`;
    const topicsPath = lessonPath + "/topics";

    let updates = {};
    topicIds.forEach( (topicId, index) => {
        updates[topicId] = nextOrder + index;
    });
    await firebase.getFirebaseData(topicsPath).update(updates);
};

export const removeTopicsFromLesson = async(lessonId, outlineId, topicIds) => {

    /* Nothing to remove */
    if (!topicIds || topicIds.length <= 0) {
        return;
    }

    const lessonPath = dataPath + `/${outlineId}/lessons/${lessonId}`;
    const topicsPath = lessonPath + "/topics";
    const reviewTopicsPath = lessonPath + "/review_topics";

    let updates = {};
    topicIds.forEach( (topicId) => {
        updates[topicId] = null;
    });
    await firebase.getFirebaseData(topicsPath).update(updates);
    await firebase.getFirebaseData(reviewTopicsPath).update(updates);
};

/**
 * Gets index (list of index records) of all lessons referencing the topic id
 *
 * @param { dbId } id topic Id
 * @returns array list of index records pointing to lessons containing the topic
 */
const getLessonsWithTopic = async(id) => {

    return await _getLessonIndexRecordsFiltered(id, (lesson) => (lesson.topics && lesson.topics[id]));
};

export const removeTopicFromAllLessons = async(id) => {
    const indexRecords = await getLessonsWithTopic(id);

    /* TODO: ineficient implementation; removeTopicsFromLesson is searching for lesson withing outline but we already have full index of lesson, no need to search for it again */
    await indexRecords.forEach( (indexRecord) => {

        removeTopicsFromLesson(indexRecord[1], [id]);
    });
};

const getLessonsOfLessonUnit = async(outlineId, lessonUnitId) => {
    const outlineLessonsData = await loadData(`${dataPath}/${outlineId}/lessons`);

    const lessons = fb_list_object(outlineLessonsData)
        .filter(lesson => lesson.lesson_unit_id == lessonUnitId)
    ;

    return lessons;
};

export const moveLesson = async(id, targetContainerId, targetIndex) => {

    const [outlineId] = await _getLessonIndexRecord(id);
    if (!outlineId) {
        return;
    }

    /* get list of all lessons in target Lesson Unit, ordered by its ordering attribute */
    const targetLessons = (await getLessonsOfLessonUnit(outlineId, targetContainerId))
        .sort( compareByOrdering );

    /* Position analysis, skip cases when moving is not necessary */
    let sourceIndex = targetLessons.findIndex(lesson => lesson.id == id);

    if (targetIndex < 0) {
        targetIndex = 0;
    }

    // console.log(targetLessons, sourceIndex, targetIndex, targetLessons.length);
    if (targetIndex > targetLessons.length-1 ) {
        /* There is a request to move the lesson to the end of the list */

        if (sourceIndex >= 0 && sourceIndex == targetLessons.length-1) {

            /* the lesson is already at the end of the list, no need to reordering */
            // console.log("Given lesson id is already at the end of list, no need to reorder");
            return;
        }

        if (sourceIndex < 0) {
            /* the lesson is not in the list */
            targetIndex = (targetLessons.length-1) + 1;
        } else {
            /* the lesson is in the list but not at its end */
            targetIndex = (targetLessons.length-1) + 1;
        }

    } else if (sourceIndex == targetIndex) {
        /* Given lesson id is already at position targetIndex, no need to reorder. */
        // console.log("Given lesson id is already at position targetIndex, no need to reorder");
        return;
    }

    /* if moving item forward in the list, we want to move the item AFTER the index targetIndex */
    if ((sourceIndex >= 0) && (sourceIndex < targetIndex)) {
        targetIndex++;
    }

    /* for path dataPath + `/${outlineId}/lessons */
    const updateObject = {};

    /* Some lessons could be missing ordering attribute; add it to them */
    let lastOrdering = 0;
    for(let i = 0; i < targetLessons.length; i++) {
        if (!targetLessons[i].ordering) {
            targetLessons[i].ordering = lastOrdering + 1;

            updateObject[`${targetLessons[i].id}/ordering`] = targetLessons[i].ordering;
        }

        lastOrdering = targetLessons[i].ordering;
    }
    /* As a side effect lastOrdering now contains the largest ordering used */

    /* What value of ordering do we want for newly positioned lesson */
    const targetOrdering = (!targetLessons[targetIndex] ?
        lastOrdering + 1 : /* Target index does not exists, i.e. we are appending to the end of list so the target ordering will be after the last ordering used yet */
        targetLessons[targetIndex].ordering /* We want move to this ordering */
    );
    //console.log(targetOrdering, targetIndex, targetLessons.length);

    /* set target ordering unit for the item being moved. lesson_unit_id is being updated to cover case when lesson is moved to another lesson unit */
    // console.log("setting lesson", id, "to ordering ", targetOrdering);
    updateObject[`${id}/ordering`] = targetOrdering;
    updateObject[`${id}/lesson_unit_id`] = targetContainerId;

    /* adjust ordering of all following lessons within lesson unit */
    let lastOrderingAdjusted = targetOrdering;
    for(let i = targetIndex; i < targetLessons.length; i++) {
        // console.log("processing", i, targetLessons[i], lastOrderingAdjusted );
        /* in target list we have encountered the item being moved so we skip it, because its ordering has already been adjusted prior to this loop */
        /* This should happen whem moving the item forward in the list */
        if (targetLessons[i].id == id) {
            continue;
        }

        if (targetLessons[i].ordering <= lastOrderingAdjusted) {
            lastOrderingAdjusted++;

            // console.log("setting lesson", targetLessons[i].id, "to ordering ", lastOrderingAdjusted);

            updateObject[`${targetLessons[i].id}/ordering`] = lastOrderingAdjusted;

        } else {
            /* there was a gap in ordering, no need to reorder those remaining lessons */
            // console.log("gap reached, exiting loop");
            break;
        }
    }

    await applyUpdateObject(updateObject, firebase.getFirebaseData(dataPath + `/${outlineId}/lessons`));
};


const getTopicsOfLesson = async (outlineId, lessonId) => {

    const LessonsData = await loadData(dataPath + `/${outlineId}/lessons/${lessonId}`);

    if (!LessonsData.topics) {
        return fb_list_object([]);
    } else {
        return Object.keys(LessonsData.topics).map( (topicId) => ({ id: topicId, ordering: LessonsData.topics[topicId] }));
    }
};

export const moveLessonTopic = async(id, targetContainerId, targetIndex, sourceContainerId) => {

    /* find outlineId to verify the targetContainerId is correct */
    const [outlineId] = await _getLessonIndexRecord(targetContainerId);
    if (!outlineId) {
        return;
    }

    /* get list of all topics in target Lesson, remove the item being moved if already present, order by ordering attribute */
    const targetTopicsAll = (await getTopicsOfLesson(outlineId, targetContainerId))
        .filter( (item) => (item.id != id) )
        .sort(compareByOrdering );

    if (targetIndex < 0) {
        targetIndex = 0;
    }

    if (targetIndex > targetTopicsAll.length-1 ) {
        targetIndex = (targetTopicsAll.length-1) + 1;
    }

    /* place the moved item to targetIndex position in the array */
    targetTopicsAll.splice(targetIndex, 0, { id });

    /* for path dataPath + `/${outlineId}/lessons */
    const updateObject = {};

    /* renumber items ordering */
    let lastOrdering = 1;
    targetTopicsAll.forEach( (item) => {

        /* renumber ordering only if it changes */
        if (item.ordering !== lastOrdering) {
            updateObject[`${targetContainerId}/topics/${item.id}`] = lastOrdering;
        }
        lastOrdering++;
    });

    /* remove from original container if necessary */
    if ( targetContainerId !== sourceContainerId) {
        updateObject[`${sourceContainerId}/topics/${id}`] = null;
    }

    await applyUpdateObject(updateObject, firebase.getFirebaseData(dataPath + `/${outlineId}/lessons`));
};

// finds lesson by id and returns reference to lesson
const findLesson = (outlineData, lessonId) => {
    const lessonUnit = outlineData && outlineData.lesson_units && outlineData.lesson_units.find(lu => lu.lessons && lu.lessons.find(l => l.key === lessonId));
    return lessonUnit && lessonUnit.lessons  && lessonUnit.lessons.find(l => l.key === lessonId);
};

/**
 * Moves a topic {id} to another position targetIndex within given lesson {targetContainerId} synchronously in state.
 * @param {Object} stateOutline Outline data stored in state
 * @param {DbId} id Topic being moved
 * @param {DbId} targetContainerId Lesson the topic is being moved to
 * @param {*} targetIndex Target position of the topic in lesson. Position is 0 based and continuous. It is not the value of ordering attribute
 * @param {DbId} sourceContainerId Lesson the topic is being moved from
 */
export const stateLessonTopicMove = (stateOutline, id, targetContainerId, targetIndex, sourceContainerId) => {
    const stateOutlineCopy = _.cloneDeep(stateOutline);
    const srcLesson = findLesson(stateOutlineCopy, sourceContainerId);
    const srcTopicIndex = srcLesson && srcLesson.topics && srcLesson.topics.findIndex(t => t.key === id);
    const topicData = srcLesson.topics[srcTopicIndex];
    srcLesson.topics.splice(srcTopicIndex, 1);
    const dstLesson = findLesson(stateOutlineCopy, targetContainerId);
    if (undefined === dstLesson.topics.find(t => t.key === id)) {
        dstLesson.topics.splice(targetIndex, 0, topicData);
    }
    return stateOutlineCopy;
};

const findLessonUnit = (outlineData, lessonUnitId) => (
    outlineData && outlineData.lesson_units && outlineData.lesson_units.find(lu => lu.key === lessonUnitId)
);

export const stateLessonMove = (stateOutline, id, targetContainerId, targetIndex, sourceContainerId) => {
    const stateOutlineCopy = _.cloneDeep(stateOutline);
    const srcLessonUnit = findLessonUnit(stateOutlineCopy, sourceContainerId);
    const srcLessonIndex = srcLessonUnit && srcLessonUnit.lessons && srcLessonUnit.lessons.findIndex(l => l.key === id);
    const lessonData = srcLessonUnit.lessons[srcLessonIndex];
    srcLessonUnit.lessons.splice(srcLessonIndex, 1);
    const dstLessonUnit = findLessonUnit(stateOutlineCopy, targetContainerId);
    dstLessonUnit.lessons.splice(targetIndex, 0, lessonData);
    // console.log("lessonManager.stateLessonMove() :: srcLessonUnit, dstLessonUnit, srcLessonIndex", srcLessonUnit, dstLessonUnit, srcLessonIndex);
    return stateOutlineCopy;
};

export const updateLessonProperties = async(outlineId, lessonUnitId, lessonId, name, type, classes, length, startWeek, description, ordering) => {

    const updateObject = {
        lesson_unit_id: lessonUnitId,
        name, 
        type,
        classes,
        description,
        length,
        start_week: startWeek,
    };
    if (ordering) {
        updateObject["ordering"] = ordering;
    }

    if (lessonId) {
        await applyUpdateObject(updateObject, firebase.getFirebaseData(`${dataPath}/${outlineId}/lessons/${lessonId}`));
    }
    else {
        lessonId = await firebase.getFirebaseData(`${dataPath}/${outlineId}/lessons`).push(updateObject);
    }
};

export const updateLessonDescription = async(outlineId, lessonId, description) => {

    const updateObject = {
        description,
    };
    if (lessonId && outlineId) {
        await applyUpdateObject(updateObject, firebase.getFirebaseData(`${dataPath}/${outlineId}/lessons/${lessonId}`));
    }
};
export const updateLessonName = async(outlineId, lessonId, name) => {

    const updateObject = {
        name,
    };
    if (lessonId && outlineId) {
        await applyUpdateObject(updateObject, firebase.getFirebaseData(`${dataPath}/${outlineId}/lessons/${lessonId}`));
    }
};


export const updateReviewTopic = async (outlineId, lessonId, topicId, checked ) => {
    if (outlineId && lessonId && topicId) { 
        firebase.getFirebaseData(`${dataPath}/${outlineId}/lessons/${lessonId}/review_topics`).update({ [topicId]: checked || null });
    }
};