import { TYPES } from "./AKitEditorActions";

const initialState = {
    courseVariants: [],
    syncStatus: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.EDIT_AKIT_SET_ALL_COURSE_VARIANTS:
            return { ...state, courseVariants: payload };
        case TYPES.EDIT_AKIT_SET_SYNC_STATUS:
            return { ...state, syncStatus: payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
