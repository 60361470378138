/**
 * Implementation of functions that are exported to plain Javascript.
 *
 * Those functions are meant to be used in HTML event handlers.
 */

const MASK_ID = "spork-mask-el";

const showMask = onClickHandler => {
    const mask = document.createElement("div");
    mask.id = MASK_ID;
    mask.setAttribute(
        "style",
        `
        opacity: 0;
        height: ${document.body.scrollHeight}px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    `
    );
    onClickHandler && mask.addEventListener("click", onClickHandler, false);
    document.body.appendChild(mask);
};

const hideMask = () => {
    const mask = document.getElementById(MASK_ID);

    if (mask) {
        mask.parentNode.removeChild(mask);
    }
};

const toggleHideableHidden = button => {
    const config = button && button.dataset;
    if (config && config.key) {
        const snippet = button.nextElementSibling;
        const classes = snippet.classList;
        if (classes && classes.contains(config.key)) {
            return classes.toggle("hidden");
        }
    }
    return null;
};

const reflectHideableState = (button, isHidden) => {
    const classes = button && button.classList;
    if (classes) {
        classes.toggle("isHidden", isHidden);
        if (classes.contains("showonce") && !isHidden) {
            button.remove();
        }
        return false;
    }
};

const reflectHideableStateAsLabel = (button, isHidden) => {
    const config = button && button.dataset;
    if (config) {
        const newLabel = isHidden ? config.show : config.hide;
        if (newLabel) {
            button.value = newLabel;
            button.innerHTML = newLabel;
        }
    }
};

export const onToggleHideable = domEvent => {
    const button = domEvent.currentTarget || domEvent.target;
    const hidden = toggleHideableHidden(button);
    if (hidden !== null) {
        reflectHideableState(button, hidden);
        return false;
    }
    // eslint-disable-next-line no-console
    console.error("Invalid configuration of toggle button.");
};

export const onShowHideButton = domEvent => {
    const button = domEvent.currentTarget || domEvent.target;
    const hidden = toggleHideableHidden(button);
    if (hidden !== null) {
        reflectHideableStateAsLabel(button, hidden);
        return false;
    }
    // eslint-disable-next-line no-console
    console.error("Invalid configuration of toggle button.");
};

export const hideGlossaryTermInfo = () => {
    const el = document.getElementById("glossary-term-info");
    if (el) {
        const el_parent = el.parentNode;
        el_parent.parentNode.removeChild(el_parent);
    }
    hideMask();
};

export const hideUndestandingPopup = () => {
    const el = document.getElementById("understanding-popup");
    if (el) {
        const el_parent = el.parentNode;
        el_parent.parentNode.removeChild(el_parent);
    }
    hideMask();
};

const showGlossaryTermInfo = (name, description, position) => {
    showMask(hideGlossaryTermInfo);
    let windowPosition = "";
    if (position.left + 300 > position.width) {
        windowPosition = `left: ${position.left - 300 + (position.right - position.left)}px; `;
    } else {
        windowPosition = `left: ${position.left}px; `;
    }
    if (position.top + 300 > position.height) {
        windowPosition += `bottom: ${position.height - position.top}px; `;
    } else {
        windowPosition += `top: ${position.top + 20}px; `;
    }
    const html = `<div id="glossary-term-info" class="spork" style="${windowPosition}">
        <h3>${name}</h3>
        <p>${description}</p>
        <div class="right-align">
            <!--<button onClick="spork.events.hideGlossaryTermInfo();">Close</button>-->
        </div>
    </div>`;
    const div = document.body.appendChild(document.createElement("div"));
    div.innerHTML = html;
};

const showUndestandingChoices = (problemId, answer, position) => {
    showMask(hideUndestandingPopup);
    let windowPosition = "";
    if (position.left + 200 > position.width) {
        windowPosition = `left: ${position.left - 200 + (position.right - position.left)}px; `;
    } else {
        windowPosition = `left: ${position.left}px; `;
    }
    if (position.top + 100 > position.height) {
        windowPosition += `bottom: ${position.height - position.top}px; `;
    } else {
        windowPosition += `top: ${position.top + 20}px; `;
    }
    const html = [
        `<div id="understanding-popup" class="spork understanding popup" style="${windowPosition}">`,
        answer !== "1"
            ? `<span onclick="spork.events.onUnderstandingChoiceClick('${problemId}', 1)" class="understand_plus">&emsp;</span>`
            : "<span style=\"opacity: 0.3\" class=\"understand_plus\">&emsp;</span>",
        answer !== "2"
            ? `<span onclick="spork.events.onUnderstandingChoiceClick('${problemId}', 2)" class="understand">&emsp;</span>`
            : "<span style=\"opacity: 0.3\" class=\"understand\">&emsp;</span>",
        answer !== "3"
            ? `<span onclick="spork.events.onUnderstandingChoiceClick('${problemId}', 3)" class="dont_understand">&emsp;</span>`
            : "<span style=\"opacity: 0.3\" class=\"dont_understand\">&emsp;</span>",
        "</div>",
    ];
    const div = document.body.appendChild(document.createElement("div"));
    div.innerHTML = html.join(" ");
};

const readGlossaryTermInfo = async termKey => {
    const definition = await window.sporkApp.getGlossaryDefinition(termKey);
    return definition || "<i>[No definition]</i>";
};

const getTermPopupPosition = el => {
    const rect = el.getBoundingClientRect();
    const { top, left, right } = rect;
    return {
        top: top + window.scrollY,
        left: left + window.scrollX,
        right: right + window.scrollX,
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
    };
};

export const onGlossaryTermClick = async domEvent => {
    const el = domEvent.currentTarget || domEvent.target;
    const { termKey, termName } = el.dataset;
    const termInfo = await readGlossaryTermInfo(termKey);
    showGlossaryTermInfo(termName, termInfo, getTermPopupPosition(el));
    return false;
};

export const onUnderstandingClick = async domEvent => {
    const el = domEvent.currentTarget || domEvent.target;
    const { problemId, answer } = el.dataset;
    showUndestandingChoices(problemId, answer, getTermPopupPosition(el));
    return false;
};

export const onLessonLinkClick = async domEvent => {
    const el = domEvent.currentTarget || domEvent.target;
    const { outlineId, lessonId } = el.dataset;
    if (window.location !== window.parent.location) {
        // The page is in an iframe
        window.parent.postMessage({ redirect: `/lesson/content/${outlineId}/${lessonId}` }, "*");
    } else {
        // The page is not in an iframe
        const url = `/lesson/content/${outlineId}/${lessonId}`;
        let win = window.open(url, "_blank");
        win.focus();
    }
};

/**
 * Set icon (emoji) on problem understanding. The icon can be dimmed to indicate unfinished processing.
 * @param {*} problemId
 * @param {*} answer
 * @param {*} dimmed
 */
export const setUnderstanding = (problemId, answer, dimmed = false) => {
    const el = document.getElementById(`u${problemId}`);
    if (el) {
        el.style.opacity = dimmed ? 0.3 : 1.0;
        el.dataset.answer = answer;
        switch (answer) {
            case 1:
                el.className = "understanding understand_plus";
                break;
            case 2:
                el.className = "understanding understand";
                break;
            case 3:
                el.className = "understanding dont_understand";
                break;
        }
    }
};

export const toggleComprehensionMode = domEvent => {
    const el = document.getElementsByClassName("problemSet");
    if (el) {
        el[0].classList.toggle("showComp");
        const compact = domEvent.currentTarget || domEvent.target;
        compact.innerHTML = el[0].classList.contains("showComp") ? "Hide comprehension" : "Show comprehension";
    }
};

export const onUnderstandingChoiceClick = (problemId, answer) => {
    // call tablet processing method
    window.sporkApp.setUnderstanding(problemId, answer);
    // set icon and dimmed
    setUnderstanding(problemId, answer, true);
    // hide popup
    hideUndestandingPopup();
};

export const kindOfMagic = () => console.log("Abracadabra!"); // eslint-disable-line no-console
