import React from "react";
import { Segment, List, Header } from "semantic-ui-react";
import styles from "../bookEditor.module.scss";

const MultiCellEditorHelp = () => (<Segment secondary className={styles.explanation}>
    <p>
        You can select multiple cells in order to format them at one time:
    </p>
    <Header as="h5">a) Selecting sequential cells</Header>
    <List bulleted as="ul">
        <List.Item as="li">mouse down on the first cell</List.Item>
        <List.Item as="li">hold the mouse down</List.Item>
        <List.Item as="li">drag the mouse over a group of cells</List.Item>
        <List.Item as="li">release the mouse</List.Item>
    </List>
    <Header as="h5">b) Selecting NON-sequential cells</Header>
    <List bulleted as="ul">
        <List.Item as="li" >click the first cell</List.Item>
        <List.Item as="li">hold down <kbd>Ctrl</kbd> key ( <kbd>⌘</kbd> on Mac )</List.Item>
        <List.Item as="li">click each additional cell<br />
            (click on already selected cell deselects it)
        </List.Item>
    </List>
</Segment>);

export default MultiCellEditorHelp;
