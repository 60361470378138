import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";

import LessonFilesViewer from "./LessonFilesViewer";
import { LessonVersionProps } from "../PublisherProps";
import { selectedLesson } from "../../LessonContent/LessonContentSelectors";

const getContentPaths = (props) => {
    const { outlineId, lessonId } = props;
    return outlineId && lessonId && {
        previewContent: `outlines_published/${outlineId}/preview/${lessonId}`,
        releaseContent: `outlines_published/${outlineId}/release/${lessonId}`,
    };
};

const mapStateToProps = (state, props) => {
    const { firebase } = state;
    const paths = getContentPaths(props);
    return paths && {
        previewContent: getVal(firebase, "data/" + paths.previewContent),
        releaseContent: getVal(firebase, "data/" + paths.releaseContent),
        loading: (
            firebase.requesting[paths.previewContent] ||
            firebase.requesting[paths.releaseContent]
        ),
        selectedLesson: selectedLesson(state, props),
    };
};

const LessonFilesContainer = compose(
    firebaseConnect((props) => {
        const paths = getContentPaths(props);
        return paths ? [ paths.previewContent, paths.releaseContent ] : [];
    }),
    connect(
        mapStateToProps,
    ),
)(LessonFilesViewer);

LessonFilesContainer.propTypes = {
    lessonId: PropTypes.string,
    outlineId: PropTypes.string,
    preview: LessonVersionProps,
    release: LessonVersionProps,
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default LessonFilesContainer;