//import { createSelector } from "reselect";

import PropTypes from "prop-types";
export const authUser = state => state.auth.user;

export const AuthUserPropType = PropTypes.shape({
    userId: PropTypes.number,
    tenantId: PropTypes.number,
    uid: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
});
