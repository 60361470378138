import { all, takeLatest, takeEvery, put, fork } from "redux-saga/effects";
import { TYPES, onAKitRemoved } from "./AKitsActions";
import firebase, { aKitManager } from "../dataSource";
import { push } from "connected-react-router";

import CreateKitSaga from "./CreateKit/CreateKitSaga";
import EditKitSaga from "./EditKitProperties/EditKitSaga";
import AKitEditorSaga from "./AKitEditor/AKitEditorSaga";
import { toastError, DB_ERROR } from "../../component/toast";
import { client } from "../../feathersjs/client";
import { updateWidgetModuleUsage, updateGlossaryModuleUsage, updateSnippetModuleUsage } from "../Modules/ModulesSaga";

const kitService = client.service("kit");

export function* aKitRemove( { payload: {  kit_id } } ) {
    try {
        const changes = { };
        const id = yield aKitManager.getKitDbId(kit_id);
        const packages = yield aKitManager.getKitPackages(kit_id);
        const removeKits = [ kit_id ];
        if (packages && Object.keys(packages).length) {
            for (const pkgKey of Object.keys(packages)) {
                if (id) {
                    yield kitService.patch(id, { removed_packages: [pkgKey] });
                }
                changes[`modules/list/${pkgKey}/akits/${kit_id}`] = null;
                changes[`kits_data/${kit_id}/m/${pkgKey}`] = null;
                yield firebase.getFirebaseData("/").update(changes);
                yield updateWidgetModuleUsage(pkgKey, removeKits, false);
                yield updateGlossaryModuleUsage(pkgKey, removeKits, false);
                yield updateSnippetModuleUsage(pkgKey, removeKits, false);
            }
        }
        if (id) {
            kitService.remove(id);
        }
        yield aKitManager.removeAKit(kit_id);
    } catch (error) {
        toastError({ code: error.code, header: "Failed to remove author kit", message: DB_ERROR });
    }
    yield put(onAKitRemoved());
}

export function* selectAKitForEdit({ payload }) {
    if (payload && payload.selectedAKitId) {
        yield put(push("/authorkits/" + payload.selectedAKitId));
    } else {
        yield put(push("/authorkits"));
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.AKITS_REMOVE, aKitRemove),
        takeEvery(TYPES.AKITS_SELECT_FOR_EDIT, selectAKitForEdit),
        fork(CreateKitSaga),
        fork(EditKitSaga),
        fork(AKitEditorSaga),
    ]);
}