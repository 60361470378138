import React, { Component } from "react";
import PropTypes from "prop-types";
import { Loader } from "semantic-ui-react";
import firebase from "../../firebase";


export default class Logout extends Component {

    componentDidMount() {
        Promise.all([
            firebase.getFirebaseAuth().signOut(),
        ]).then(() => {
            this.props.onLogout();
        });
    }

    render() {
        return (
            <Loader active inline="centered">
        Logging out...
            </Loader>
        );
    }
}

Logout.propTypes = {
    /* callback when user is successfully logged out in the backend */
    onLogout: PropTypes.func.isRequired,
};



