import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button, Icon, Message } from "semantic-ui-react";

export default class ConvertLessonData extends PureComponent {
    static propTypes = {
        onConvertLessonData: PropTypes.func,
        inProgress: PropTypes.bool,
        progress: PropTypes.shape({
            processedLessons: PropTypes.number,
            processedHw: PropTypes.number,
            processedPS: PropTypes.number,
        }),
    };

    render() {
        const { inProgress, progress, onConvertLessonData } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Convert Lesson Problem Sets and Homework data
                </Header>
                <Segment secondary>
                    This page converts lesson problem sets and lesson homework to a new format
                    {" "}(adds property <i>shuffled_problems</i>,{" "}
                    see <a href="https://bmdcon.easyredmine.com/issues/21466" target="_blank" rel="noopener noreferrer">
                        #21466
                    </a> for more details).
                </Segment>
                {inProgress && (
                    <Message icon positive>
                        <Icon name="circle notched" loading />
                        <Message.Content>
                            Converted {progress.processedLessons} lessons, including {progress.processedPS} problem sets and {progress.processedHw} homework.
                        </Message.Content>
                    </Message>
                )}
                {!inProgress && (
                    <Button primary onClick={onConvertLessonData}>
                        Start data conversion
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
