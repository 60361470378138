import React, { PureComponent } from "react";
import { List, Icon, Label, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import { ConfirmDeletePopup, ActionIconsContainer, EditActionIcon } from "bmd-react";
import { Link } from "react-router-dom";
import cx from "classnames";

import PlaceholderText from "../../../../component/PlaceholderText";
import LessonTopicsListDroppable from "../LessonTopic/LessonTopicsListDroppable";
import { lessonDefaults } from "../LessonProperties/LessonProperties";
import LessonSchedule from "../../../../component/LessonSchedule";
import { REVIEW_TYPE } from "../../../../component/seamlessEditor/bookEditor/constants";
import { getVersionString } from "../../../../utils/conversionUtils";
import ReviewsCount from "../../../component/ReviewsCount";
import AccessControl from "../../../../auth/AccessControl";

export default class Lesson extends PureComponent {
    static propTypes = {
        lessonData: PropTypes.object,
        selectedOutlineId: PropTypes.string,

        onLessonRemove: PropTypes.func,
        onLessonShowProperties: PropTypes.func,

        dragHandle: PropTypes.node,
        collapsible: PropTypes.bool,
        canEdit: PropTypes.bool,
    }

    static defaultProps = {
        collapsible: false,
    }

    state = { isCollapsed: false }

    handleRemoveLesson = (callback, event, props) => {
        // clear error message at start if visible
        // @todo pass popup handling callback (param #1) although errors are showing in dedicated error box?
        this.props.onLessonRemove(props.id);
        // close delete confirmation popup
        callback();
    }

    handleCollapse = (e) => {
        this.setState({ isCollapsed: !this.state.isCollapsed });
        e.preventDefault();
    }

    handleEditLessonProps = (lessonData) => {
        this.props.onLessonShowProperties(true, lessonData, true);
    }

    renderReviewsCount = (reviewsCount) => {
        const reviews = reviewsCount[REVIEW_TYPE.REPLACE] + reviewsCount[REVIEW_TYPE.INSERT];
        const comments = reviewsCount[REVIEW_TYPE.COMMENT_ONLY];

        if (reviews || comments) {
            return (<ReviewsCount reviews={reviews} comments={comments} marginLeft={true}/>);
        }
    };

    renderPublishVersion = () => {
        const { lessonData: lesson, canEdit} = this.props;
        return (canEdit ? getVersionString(lesson.release.created_at) : "PUBLISHED");
    }

    render() {
        const { lessonData: lesson, selectedOutlineId, collapsible, canEdit } = this.props;
        const { isCollapsed } = this.state;
        const { reviewsCount } = lesson;  // aggregated reviews count across collections (widgets / problem_sets / homework)
        const hasUnprocessedReviews = Boolean(reviewsCount && (reviewsCount[REVIEW_TYPE.REPLACE] + reviewsCount[REVIEW_TYPE.INSERT]));
        // console.log(`%cLesson::render() lessonId/lessonName=${lesson.key}/${lesson.name}`, "background-color: rgb(230, 236, 255)");
        return (
            <List.Item>
                <Segment basic={!canEdit} className={cx({ unprocessedReviews: (hasUnprocessedReviews && canEdit) })}>
                    <div style={{marginBottom: "0.5em"}} className="editable">
                        {canEdit && <ActionIconsContainer floatRight>
                            <ConfirmDeletePopup
                                whatText={`lesson "${lesson.name}"`}
                                id={lesson.key}
                                key={`removeLesson-${lesson.key}`}
                                position="right center"
                                trigger={
                                    <Icon
                                        name="trash alternate outline"
                                        link
                                        className="actionIconClass right"
                                        title="Remove the lesson"
                                        site="small"
                                    />
                                }
                                onConfirm={this.handleRemoveLesson}
                            />

                            <EditActionIcon iconName="settings" onClick={this.handleEditLessonProps} data={lesson} title="Edit the properties of the lesson" />
                        </ActionIconsContainer>}
                        {canEdit && this.props.dragHandle}
                        {collapsible && <List.Icon
                            onClick={this.handleCollapse}
                            link
                            name={`caret ${isCollapsed ? "right" : "down"}`}
                            title={isCollapsed ? "Expand" : "Collapse"}
                        />}
                        <Link className="lessonLink" to={`/lesson/content/${selectedOutlineId}/${lesson.key}`} title="Show lesson content">{lesson.name}</Link>
                        {(canEdit && lesson.reviewsCount) && this.renderReviewsCount(lesson.reviewsCount)}
                    </div>
                    {!isCollapsed ? (<List.Content>
                        <div style={{float: "right"}} >
                            <LessonType type={lesson.type} />
                            <LessonClasses classes={lesson.classes} />
                            <LessonSchedule startWeek={lesson.startWeek} wrongSchedule={lesson.wrongSchedule} length={lesson.length} />
                        </div>
                        {lesson.release && <div className="published" title="Lesson is published"><Icon name="check"/>
                            <AccessControl action="tablet:manage" renderNoAccess={this.renderPublishVersion}>
                                {getVersionString(lesson.release.created_at)}</AccessControl>
                        </div>}
                        {(canEdit || lesson.description) && <p title="Lesson objectives" style={{ whiteSpace: "pre-line" }}>{lesson.description || <PlaceholderText text="The objective of the lesson ... has not been described here yet." />}</p>}
                        <LessonTopicsListDroppable
                            lessonId={lesson.key}
                            canEdit={canEdit}
                            topicsList={lesson.topics}
                        />
                    </List.Content>) : null}
                </Segment>
            </List.Item>
        );
    }
}

const LessonType = ({ type }) => {
    const color = type === "Review" ? "red" : "blue";
    return (type && type !== lessonDefaults.lessonType) ? (
        <Label title="Lesson type" style={{ float: "right", clear: "right", marginTop: "0.2em", marginLeft: "1em" }} color={color}>{type}</Label>
    ) : null;
};

LessonType.propTypes = {
    type: PropTypes.string,
};

const LessonClasses = ({ classes }) => {
    return (<div title="Lesson classes" style={{ float: "right"}}><span style={{fontSize: "1.5em"}}> | {classes}</span> class{classes != 1 ? "es" : ""}</div>);
};

LessonClasses.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
