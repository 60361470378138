import { all, fork } from "redux-saga/effects";

import problemManagementSaga from "./ProblemManagement/problemManagementSaga";
import problemSetWizardSaga from "./ProblemSetWizard/ProblemSetWizardSaga";
import problemSetCreatorSaga from "./ProblemSetCreator/problemSetCreatorSaga";
import assignTagsTopicsSaga from "./ProblemManagement/AssignTagsTopics/assignTagsTopicsSaga";
import removeTagsTopicsSaga from "./ProblemManagement/RemoveTagsTopics/removeTagsTopicsSaga";
import compTestSaga from "./CompTest/CompTestSaga";

function* sagaQuestionUtilizator() {
    yield all([
        fork(problemManagementSaga),
        fork(problemSetWizardSaga),
        fork(problemSetCreatorSaga),
        fork(assignTagsTopicsSaga),
        fork(removeTagsTopicsSaga),
        fork(compTestSaga),
    ]);
}

export default sagaQuestionUtilizator;
