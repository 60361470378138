import { createSelector } from "reselect";
import { getUsedTagNames } from "../ProblemManagement/problemManagementSelectors";

const convertTagToOption = (value) => ({ key: value, value, text: value });
const getEntityTagNames = (entity) => (entity && entity.tags && entity.tags.length ? entity.tags : null);

const getCurrentProblemSetTags = (state, props) => getEntityTagNames(props.question);

export const getQuestionTagsAsOptions = createSelector(
    getUsedTagNames,
    getCurrentProblemSetTags,
    (usedTags, ownTags) => {
        if (usedTags || ownTags) {
            const uniqueTags = usedTags ? new Set(usedTags) : new Set();
            ownTags && ownTags.forEach(tag => uniqueTags.add(tag));
            return [...uniqueTags].map(convertTagToOption);
        } else {
            return usedTags;
        }
    }
);