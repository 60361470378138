import { TYPES } from "./topicsActions";

const initialState = {
    selectedObjectiveSet: null,
    showDialog: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.TOPIC_SELECT_OBJECTIVE_SET:
            return { ...state, selectedObjectiveSet: action.value };
        case TYPES.TOPIC_ADD_TOPIC:
            return { ...state, error: undefined, isProcessing: true };
        case TYPES.TOPIC_ADD_TOPIC_ERROR:
            return { ...state, error: action.value, isProcessing: false };
        case TYPES.TOPIC_ADD_TOPIC_CLEAR_ERROR:
            return { ...state, error: undefined };
        case TYPES.TOPIC_SHOW_DIALOG:
            return { ...state, showDialog: action.value, isProcessing: false };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
