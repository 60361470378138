import { TYPES } from "./importGlossaryActions";

const initialState = {
    inProgress: null,
    progress: { processed: 0, total: 0 },
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.IMPORT_GLOSSARY_START:
            return { ...state, inProgress: true, error: null };
        case TYPES.IMPORT_GLOSSARY_STOP:
            return { ...state, inProgress: false, progress: { processed: 0, total: 0 } };
        case TYPES.IMPORT_GLOSSARY_PROGRESS:
            return { ...state, progress: { processed: action.payload.processed, total: action.payload.total } };
        case TYPES.IMPORT_GLOSSARY_ERROR:
            return { ...state, error: action.payload };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
