import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { firebaseConnect } from "react-redux-firebase";

import { userClaims } from "../../Users/UserProfile/UserProfileSelectors";
import { isAuthorAtLeast, isReviewerAtLeast } from "../../Users/roleUtils";
import { getQuestionTagsAsOptions } from "./QuestionEditorSelectors";
import QuestionEditor from "./QuestionEditor";
import { getTagCollectionByParent } from "../utils";

export default withRouter(compose(
    firebaseConnect(({ fbCollection }) => [getTagCollectionByParent(fbCollection)]),
    connect((state, props) => {
        const isUserAuthor = isAuthorAtLeast(userClaims(state));
        const isUserReviewer = isReviewerAtLeast(userClaims(state));
        return {
            isUserReviewer,
            reviewModeOnly: !!(isUserReviewer && !isUserAuthor),
            tagOptions: getQuestionTagsAsOptions(state, props),
        };
    }, {})
)(QuestionEditor));
