import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Icon, Segment, Button, Divider } from "semantic-ui-react";
import EditableText from "../../component/EditableText";
import ActionLink from "../../component/ActionLink";
import NoDataPlaceholder from "../../component/NoDataPlaceholder";
import RemoveIcon from "../../component/RemoveIcon";
import "./GlossaryDetail.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { getSelectedTerm, isDirty } from "./GlossarySelectors";
import * as GlossaryActions from "./GlossaryActions";
import { withAuth } from "../../component/withAuth";
import GlossaryEditor from "./GlossaryEditor";
import TagsContainer from "../Tags/TagsContainer";
import { confirmUnsave } from "../LessonContent/utils";
import { replaceRelLinks } from "../../utils/conversionUtils";
import EntityModuleList from "../../component/EntityModuleList";
import { AccessDeniedMessage } from "../../component/AccessDeniedMessage";

class GlossaryDetail extends PureComponent {
    static propTypes = {
        glossaryId: PropTypes.string,
        selectedTerm: PropTypes.object,
        onChangeName: PropTypes.func,
        onUpdateTerm: PropTypes.func,
        onRemove: PropTypes.func,
        onCloseDetail: PropTypes.func,
        isAuthorAtLeast: PropTypes.bool,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
    };

    state = {
        isEditing: false,
    };

    handleEditation = () => {
        this.setState({
            isEditing: true
        });
    };

    registerGetContentFn = (fn) => {
        this.getEditorContentFn = fn;
    };

    handleRename = value => {
        const { glossaryId, onChangeName } = this.props;
        onChangeName({ glossaryId, name: value });
    };

    handleCloseEditation = () => {
        this.setState({ isEditing: false });
    };

    handleCloseDetail = () => {
        const { isDirty, onSetDirty, onCloseDetail } = this.props;
        if (!isDirty || confirmUnsave("When you now close the glossary editor", onSetDirty)) {
            onCloseDetail();
        }
    }

    render() {
        const { selectedTerm, onRemove, glossaryId, isAuthorAtLeast, onUpdateTerm, isDirty, onSetDirty } = this.props;
        const { isEditing } = this.state;
        return (
            selectedTerm && <Grid.Column width={12}>
                {selectedTerm.noAccess ? <AccessDeniedMessage /> : (
                    <React.Fragment>
                        <div style={{ display: "grid", gridTemplateColumns: "90% 10%" }} >
                            <Header as="h4">
                                {selectedTerm && <EditableText onUpdate={this.handleRename} text={selectedTerm.name} canEdit={isAuthorAtLeast} />}
                            </Header>
                            <span style={{textAlign: "right", marginBottom: "1em"}}>
                                {!selectedTerm.isUsed ? isAuthorAtLeast ? (
                                    <RemoveIcon onRemove={onRemove} bordered id={glossaryId} entityName={`glossary term ${selectedTerm.name}`} />
                                ) : null :
                                    <Icon.Group className="bordered" title="This glossary term cannot be removed, because is already used in some widget.">
                                        <Icon name="dont" size="large" style={{marginTop: "-0.12em"}}  disabled />
                                        <Icon name="trash alternate outline" disabled />
                                    </Icon.Group>}
                                <Icon name="close" onClick={this.handleCloseDetail} bordered title="Close glossary detail" link /></span>
                        </div>
                        {!isEditing ? (
                            <React.Fragment>
                                {isAuthorAtLeast && ((!selectedTerm ||
                                    !selectedTerm.content ||
                                    !selectedTerm.content.html) ||
                                    <ActionLink
                                        floated="right"
                                        content="Edit"
                                        icon="write"
                                        onClick={this.handleEditation}
                                        horizontal
                                    />)
                                }
                                <div style={{ clear: "both" }}>
                                    {selectedTerm &&
                                    selectedTerm.content &&
                                    selectedTerm.content.html ? (
                                            <Segment>
                                                <div dangerouslySetInnerHTML={{ __html: replaceRelLinks(selectedTerm.content.html) }} />
                                            </Segment>
                                        ) : (
                                            <NoDataPlaceholder icon="edit outline" text="Do you know meaning of this term? Help us.">
                                                <p>We are eager to share the knowledge about this term. Hurry up and write an amazing description or explanation here.</p>
                                                <Button primary content="Add definition" onClick={this.handleEditation}/>
                                            </NoDataPlaceholder>
                                        )}
                                </div>
                            </React.Fragment>
                        ) : (
                            <GlossaryEditor
                                glossaryId={glossaryId}
                                selectedTerm={selectedTerm}
                                onUpdateTerm={onUpdateTerm}
                                onClose={this.handleCloseEditation}
                                showSaveCancel={true}
                                isDirty={isDirty}
                                onSetDirty={onSetDirty}
                            />
                        )}
                        <Divider hidden />
                        <TagsContainer tags={selectedTerm.tags || {}} canEdit={isAuthorAtLeast} showButton verbose path={`glossary/list/${glossaryId}/tags`} />
                        {selectedTerm.modules && <EntityModuleList list={selectedTerm.modules} showHeader />}
                    </React.Fragment>)}
            </Grid.Column>
        );
    }
}


const mapStateToProps = (state, props) => ({
    selectedTerm: getSelectedTerm(state, props),
    isDirty: isDirty(state),
});

const dispatchToProps = (dispatch) => bindActionCreators(
    GlossaryActions,
    dispatch
);

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    withAuth,
)(GlossaryDetail);