import { bindActionCreators } from "redux";
import {
    onNextPage,
    onPrevPage,
    onResetFilterBy,
} from "../ProblemManagement/ProblemManagementActions";
import {
    onCreateQuestion,
    onDeleteQuestion,
    onEditQuestion,
    onGetQuestionCount,
    onApplyFilterBy,
} from "./CompTestActions";

const emptyAction = () => ({ type: "NONE" });

export const bindActions = (dispatch) => bindActionCreators({
    onCreateQuestion,
    onDeleteQuestion,
    onEditQuestion,
    onSetOutlineForLessonLinks: emptyAction,
    onNextPage,
    onPrevPage,
    onGetAllCount: onGetQuestionCount,
    onApplyFilterBy,
    onResetFilterBy,
}, dispatch);

export default bindActions;
