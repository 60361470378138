import React from "react";
import PropTypes from "prop-types";
import { Divider, Form } from "semantic-ui-react";

const itemScopeOptions = [
    { key: "all", text: "All", value: "all" },
    { key: "teacher", text: "Teachers", value: "teacher" },
    { key: "student", text: "Students", value: "student" }
];

export default class ItemProperties extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        scope: PropTypes.string,
        onChange: PropTypes.func.isRequired
    };

    state = {
        modifiedName: null,
    };

    handleNameFocus = () => {
        this.setState({ modifiedName: this.props.name });
    };

    handleNameBlur = () => {
        this.updateName();
        this.setState({ modifiedName: null });
    };

    handleNameKeyDown = (e) => {
        if (e.keyCode === 13) { // on "Enter"
            this.updateName();
        }
    };

    updateName = () => {
        const { name } = this.props;
        const { modifiedName } = this.state;
        if (modifiedName && modifiedName !== name) {
            const { onChange } = this.props;
            onChange && onChange("name", modifiedName);
        }
    };

    handleNameChange = (e, { value }) => {
        this.setState({ modifiedName: value.trim() });
    };

    handleScopeChanged = (e, { value }) => {
        const { onChange } = this.props;
        onChange && onChange("scope", value);
    };

    render() {
        const { name, scope } = this.props;
        const { modifiedName } = this.state;
        const error = !(modifiedName === null ? name : modifiedName);

        return (
            <React.Fragment>
                <Divider horizontal>Properties</Divider>
                <Form.Group>
                    <Form.Dropdown
                        selection
                        label="Audience"
                        defaultValue={scope}
                        onChange={this.handleScopeChanged}
                        options={itemScopeOptions}
                    />
                    <Form.Input
                        label="Widget Item Name"
                        defaultValue={name}
                        name="name"
                        width={12}
                        error={error}
                        title={error ? "Empty item name is not allowed" : null}
                        onBlur={this.handleNameBlur}
                        onFocus={this.handleNameFocus}
                        onChange={this.handleNameChange}
                        onKeyDown={this.handleNameKeyDown}
                    />
                </Form.Group>
            </React.Fragment>
        );
    }
}
