import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { getFeatures } from "./withFeatureFlagsSelectors";

export default function withFeatureFlags(WrappedComponent) {
    return compose(
        firebaseConnect(["config/features"]),
        connect(
            state => ({
                features: getFeatures(state),
            })
        )
    )(WrappedComponent);
}
