import React from "react";
import PropTypes from "prop-types";

import LessonExports from "../Publisher/LessonExports";
import LessonEditor from "./LessonEditor";
import LessonPreview from "./LessonPreview";
import LessonProblemSet from "./LessonProblemSet";
import { UserRightsPropType } from "./utils";
import { TYPE as LOCK_TYPE } from "../../Lock";
import LockableBlock from "../../Lock/LockableBlock";

class LessonContentEditor extends React.PureComponent {
    static propTypes = {
        activeView: PropTypes.string.isRequired,
        selectedLesson: PropTypes.object.isRequired,
        lessonId: PropTypes.string,
        outlineId: PropTypes.string,
        outlineName: PropTypes.string,
        userRights: UserRightsPropType,
        reviews: PropTypes.object,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
    };

    renderEditor(asReview) {
        const { userRights, onSetDirty, isDirty, lessonId, outlineId, reviews, selectedLesson } = this.props;

        // No access to editor
        if (!userRights || !(asReview ? userRights.canReview : userRights.canEdit)) {
            return null;
        }
        return (
            <LessonEditor
                key={asReview ? "review" : "editor"}
                lessonId={lessonId}
                outlineId={outlineId}
                canEdit={userRights && userRights.canEdit}
                canReview={userRights && userRights.canReview}
                asReview={asReview}
                isDirty={isDirty}
                onSetDirty={onSetDirty}
                selectedLesson={selectedLesson}
                reviews={reviews}
            />
        );
    }

    render() {
        const { activeView, selectedLesson, lessonId, outlineId, userRights, reviews } = this.props;

        const lockType = { lockType: LOCK_TYPE.LESSON, itemId: `${outlineId}/${lessonId}` };

        if (activeView === "problem_sets" || activeView === "homework") {
            // PS & HW are not real editor but they have own components/container
            return (
                <LessonProblemSet
                    lessonId={lessonId}
                    outlineId={outlineId}
                    tabIndex={activeView}
                    collection={activeView}
                    userRights={userRights}
                />
            );
        } else if ("prints" === activeView) {
            return (
                <LessonExports
                    lessonId={lessonId}
                    outlineId={outlineId}
                    outlineName={this.props.outlineName}
                    lessonName={selectedLesson.name}
                />
            );
        } else if ("preview" === activeView || "content" === activeView) {
            return <LessonPreview lessonId={lessonId} outlineId={outlineId} />;
        } else if ("review" === activeView) {
            if (null === reviews || 0 == reviews.COMMENT_ONLY.length) {
                // no review comments => use a single column layout
                return (
                    <LockableBlock lockType={lockType}>
                        {this.renderEditor(true)}
                    </LockableBlock>
                );
            }
        }
        // Compose & Review(w/ comments) uses two column layout to show widgets/reviews on the right
        return (
            <LockableBlock lockType={lockType}>
                {this.renderEditor("review" === activeView)}
            </LockableBlock>
        );
    }
}

export default LessonContentEditor;
