import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form, Icon, Dropdown } from "semantic-ui-react";

export default class AddWorker extends PureComponent {
    static propTypes = {
        options: PropTypes.array,
        onAdd: PropTypes.func,
        centerForm: PropTypes.bool,
        entityName: PropTypes.string,
    };

    state = {
        selectedUser: null,
    }

    handleSelectUser = (e, { value }) => {
        e.stopPropagation();
        this.setState({ selectedUser: value });
    }
    
    handleAddUser = (e) => {
        e.preventDefault && e.preventDefault();
        const { selectedUser } = this.state;
        this.props.onAdd(selectedUser);
        this.setState({ selectedUser: null });
    }

    render() {
        const { options, centerForm, entityName } = this.props;
        const { selectedUser } = this.state;
        return (
            <Form onSubmit={this.handleAddUser}>
                <Form.Group {...centerForm && {style: {justifyContent: "space-around"}}}>
                    <Form.Dropdown
                        icon={<Icon name="dropdown" style={{fontSize: "inherit"}} />}
                        placeholder={`Select new ${entityName}`}
                        options={options}
                        selection
                        search
                        required
                        lazyLoad
                        value={selectedUser}
                        onChange={this.handleSelectUser}
                        openOnFocus={false}
                        style={{width: "35em"}}
                        searchInput={<Dropdown.SearchInput autoFocus autoComplete="off" />}
                    />
                    <Form.Button primary disabled={!selectedUser} onClick={this.handleAddUser} content="Add" />
                </Form.Group>
            </Form>
        );
    }
}
