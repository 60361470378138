import { all, put, takeLatest } from "redux-saga/effects";
import { TYPES, onSaved } from "./ResourceSourceTypeActions";
import { toastError, DB_ERROR } from "../../../../component/toast";
import { onPatchResourceData } from "../../ResourcesActions";
import { updateResource } from "../../ResourcesApi";

function* saveSourceType ({ payload: { resourceId, source_type_id } }) {
    try {
        const resource = yield updateResource(resourceId, { source_type_id });
        yield put(onPatchResourceData({resourceId, newData: resource.data, fieldsToUpdate: ["source_type_id", "sourceType"]}));
    } catch (err) {
        toastError({ code: err.code, header: "Failed to update source type of the resource", message: DB_ERROR });
    }
    yield put(onSaved());
}


export default function* resourceSourceTypeSaga() {
    yield all([
        takeLatest(TYPES.RESOURCES_SOURCE_TYPE_SAVE, saveSourceType),
    ]);
}
