import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import { arrayMove } from "react-sortable-hoc";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import TemplateEditorItem from "./TemplateEditorItem";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderedItemsList, isDirty } from "../WidgetTemplatesSelectors";
import { onUpdateItem, onRemoveItem, onChangeOrder, onSetDirty } from "../WidgetTemplatesActions";
import { getEditorComponentKey } from "../../WidgetLibrary/WidgetEditor";
import SaveCancelInToolbar from "../../WidgetLibrary/WidgetEditor/SaveCancelInToolbar";
import { getUsedSnippets } from "../../../component/seamlessEditor/bookEditor/utils/utils";
import * as Utils from "./utils";

class TemplateEditorList extends PureComponent {
    static propTypes = {
        template: PropTypes.object,
        content: PropTypes.object,
        onUpdateItem: PropTypes.func.isRequired,
        onRemoveItem: PropTypes.func.isRequired,
        onChangeOrder: PropTypes.func.isRequired,
        enableRemove: PropTypes.bool,
        sharedToolbar: PropTypes.object,
        orderedItemList: PropTypes.array,
        isDirty: PropTypes.bool,
        onSetDirty: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.getEditorContentFns = {};
    }
    
    state = {
        componentKey: getEditorComponentKey(),
        isReMounting: false,
    };

    componentDidUpdate = () => {
        if (this.state.isReMounting) {
            this.setState({ isReMounting: false });
        }
    }

    handleSave = () => {
        const { template, onUpdateItem } = this.props;

        for ( let key in this.getEditorContentFns) {
            const { itemId, fn, isDirty } = this.getEditorContentFns[key];
            if (isDirty) {
                const content = fn();
                const snippets = getUsedSnippets(content.raw, true);
                const newContent =  { ...content, snippets };
                onUpdateItem(itemId, Utils.updateItemConfig(template.items[itemId], "content", newContent));
                this.getEditorContentFns[key] = {...this.getEditorContentFns[key], isDirty: false };
            }
        }
        this.props.onSetDirty(false);
    };

    registerGetContentFns = (itemId, editorKey, fn) => {
        const { isReMounting } = this.state;
        const key = editorKey + itemId;
        if (fn === null) {
            // ignore unsubscribe when all components are being re-mounted on Cancel
            if (!isReMounting) {
                delete this.getEditorContentFns[key];
            }
        } else {
            this.getEditorContentFns[key] = { itemId, fn};
        }
    };

    // reset state to props values and generate new key so BookEditors will force re-mount
    handleCancel = () => {
        this.getEditorContentFns = {};
        this.setState({ componentKey: getEditorComponentKey(), isReMounting: true });
        this.props.onSetDirty(false);
    };

    // add isDirty flag to list of registered editor callbacks
    handleDirty = (itemId, editorKey) => {
        const key = editorKey + itemId;
        this.getEditorContentFns[key] = {...this.getEditorContentFns[key], isDirty: true };
        this.props.onSetDirty(true);
    };
    
    handleChangeOrdering = ({ oldIndex, newIndex }) => {
        const { onChangeOrder, orderedItemList } = this.props;
        let orderedItems = arrayMove(orderedItemList, oldIndex, newIndex);

        orderedItems.map((item, index) => {
            item.position = index + 1;
            return item.key;
        });
        onChangeOrder(orderedItems);
    };

    render() {
        const { componentKey } = this.state;
        const { content, sharedToolbar, isDirty } = this.props;

        return <React.Fragment>
            <SortableList
                className="sortableList"
                onSortEnd={this.handleChangeOrdering}
                helperClass="sortableHelper spork template-editor-body"
                useDragHandle
                {...this.props}
                content={content}
                onDirty={this.handleDirty}
                editorKey={componentKey}
                sharedToolbar={sharedToolbar}
                registerGetContentFn={this.registerGetContentFns}
            />
            {/* TODO: Find a better place for this, to avoid necessity to position buttons using sharedToolbar */}
            {sharedToolbar && isDirty && (
                <SaveCancelInToolbar
                    onSave={this.handleSave}
                    onCancel={this.handleCancel}
                    toolbarRef={sharedToolbar}
                />
            )}
        </React.Fragment>;
    }
}

const dispatchToProps = dispatch => bindActionCreators(
    {
        onUpdateItem,
        onRemoveItem,
        onChangeOrder,
        onSetDirty,
    },
    dispatch
);

export default connect(
    state => ({
        orderedItemList: getOrderedItemsList(state),
        isDirty: isDirty(state),
    }),
    dispatchToProps
)(TemplateEditorList);

const SortableList = SortableContainer(({ orderedItemList, ...props }) => {
    return (
        <Segment.Group className="spork template-editor-body sortableContainer">
            {orderedItemList.map(({ key, value }, index) => (
                <SortableItem key={key} id={key} index={index} {...props} item={value} content={props.content[key]} />
            ))}
        </Segment.Group>
    );
});

const SortableItem = SortableElement(({ editorKey, ...props }) => {
    return <TemplateEditorItem key={editorKey} editorKey={editorKey} {...props} />;
});
