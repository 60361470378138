import { EditorState, ContentState, Modifier, convertToRaw, convertFromRaw, SelectionState } from "draft-js";
import getFragmentFromSelection from "draft-js/lib/getFragmentFromSelection";
import { getSelectionEntity, getEntityRange, getSelectedBlock } from "draftjs-utils";
import Immutable from "immutable";

import { expandEntitySelection, reconstructContentState, getNewContentBlock, isAtBeginOfBlock } from "../utils/utils";
import { insertListItem, removeListItem } from "../listItem/listItemUtils";
import exportToHtml from "../../export/exportToHtml";
import { ENTITY_TYPE, REVIEW_TYPE, REVIEW_OPTIONS, REVIEW_PLACEHOLDER_CHAR } from "../constants";

/**
 * Returns object representing data for review entity.
 * If the caret is directly after existing review entity, its data is returned by default.
 * It can be suppressed by passing "-1" as `entityKey` argument to return selected fragment regardless existing entity.
 *
 * @param {Draft::EditorState} editorState
 * @param {string} entityKey
 * @returns {object}
 */
const getSelectionAsReviewSource = (editorState, entityKey) => {
    entityKey = entityKey || getSelectionEntity(editorState);

    if (entityKey && entityKey !== "-1") {
        const entity = editorState.getCurrentContent().getEntity(entityKey);
        // getSelectionEntity(editorState) returns an entity when the caret is directly after the entity.
        // The entity type has to be checked.
        // Unfortunately when a block begins with an entity and the caret is before it,
        // getSelectionEntity() returns the too. We have to eliminate it.
        if (entity.getType() === ENTITY_TYPE.REVIEW && !isAtBeginOfBlock(editorState)) {
            const data = entity.getData();
            return { ...data, entityKey };
        }
    }

    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
        return {};  // no current selection, review is considered as a new text (insertion)
    }

    const fragment = getFragmentFromSelection(editorState);
    const content = ContentState.createFromBlockArray(fragment.toArray());
    const originalRaw = convertToRaw(content);

    return {
        originalRaw,
    };
};

/**
 * If there is only one paragraph element in the given HTML, only its content will be used
 * (it is considered as an inline element).
 * More paragraphs is considered as intention (e.g. user press ENTER after newly inserted text).
 */
const getBlockContent = (doc, blockTags = ["p", "ol", "ul"]) => {
    for (const tagName of blockTags) {
        const blocks = doc.getElementsByTagName(tagName);

        if (blocks.length === 1) {
            if (tagName === "ol" || tagName === "ul") {
                return getBlockContent(blocks[0], ["li"]);
            }
            else {
                return blocks[0];
            }
        }
    }

    return doc;
};

const removeFirstOuterBlock = (html) => {
    const dummyDiv = document.createElement("div");
    dummyDiv.innerHTML = html;
    const blockContent = getBlockContent(dummyDiv);
    return blockContent.innerHTML;
};

const removeReview = (editorState, entityKey, originalRaw, reviewMetaData) => {
    const entityRange = getEntityRange(editorState, entityKey);
    let contentState = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let newEditorState = editorState;

    selection = expandEntitySelection(entityRange, selection);

    if (originalRaw) {
        // REPLACE - put back the original content
        const fragment = convertFromRaw(reconstructContentState(originalRaw)).getBlockMap();
        contentState = Modifier.replaceWithFragment(contentState, selection, fragment, null, null);
    }
    else {
        if (reviewMetaData && reviewMetaData.insertedListItem) {
            // INSERT - REMOVE WHOLE LIST ITEM
            newEditorState = removeListItem(editorState);
            contentState = newEditorState.getCurrentContent();
        }
        else {
            // INSERT - remove review entity
            contentState = Modifier.removeRange(contentState, selection, "forward");
        }
    }

    return EditorState.push(newEditorState, contentState, "remove-range");
};

const createReview = (editorState, existingEntity, review) => {
    const { originalRaw = null, originalHtml = null, raw = null, comment = "", reviewMetaData = null } = review;
    const insertAsBlock = reviewMetaData && reviewMetaData[REVIEW_OPTIONS.INSERT_AS_BLOCK];
    const html = exportToHtml(raw);
    let selection = editorState.getSelection();

    if (existingEntity) {
        const entityRange = getEntityRange(editorState, existingEntity);
        selection = expandEntitySelection(entityRange, selection);
    }

    const entityData = {
        originalRaw,
        originalHtml: originalHtml || removeFirstOuterBlock(exportToHtml(originalRaw)),
        raw,
        html: insertAsBlock ? html : removeFirstOuterBlock(html),
        comment: comment.trim(),
        reviewMetaData,
    };

    const hasOptions = reviewMetaData && Object.keys(REVIEW_OPTIONS).some((optionName) => reviewMetaData[REVIEW_OPTIONS[optionName]]);

    if (entityData.html || entityData.originalHtml || hasOptions) {
        entityData.reviewType = entityData.originalHtml ? REVIEW_TYPE.REPLACE : REVIEW_TYPE.INSERT;
    }
    else {
        if (entityData.comment) {
            entityData.reviewType = REVIEW_TYPE.COMMENT_ONLY;
        }
        else if (existingEntity) {
            return removeReview(editorState, existingEntity, originalRaw, reviewMetaData);
        }
        else {
            return editorState;
        }
    }

    let contentState = editorState.getCurrentContent();
    const entityKey = contentState
        .createEntity(ENTITY_TYPE.REVIEW, "IMMUTABLE", entityData)
        .getLastCreatedEntityKey();

    contentState = Modifier.replaceText(
        contentState,
        selection,
        REVIEW_PLACEHOLDER_CHAR,
        null,
        entityKey,
    );

    let newEditorState = EditorState.push(editorState, contentState, "insert-characters");

    selection = newEditorState.getSelection().merge({
        anchorOffset: selection.get("anchorOffset") + 1,
        focusOffset: selection.get("anchorOffset") + 1,
        hasFocus: true,
    });

    newEditorState = EditorState.forceSelection(newEditorState, selection);
    return EditorState.push(newEditorState, contentState, "insert-characters");
};

/**
 * Fragment contains a new text entered by a reviewer.
 * It is inserted as `inline` at the insertion point by default.
 * When `block` insertion is required, an empty block has to be added before the fragment at least.
 *
 * If the fragment contains only single block without text (only `new line` insertion is the subject
 * of the review), it is enough.
 * Otherwise, an empty block has to be added at the end of the fragment too.
 *
 * @param {Draft::BlockMap} fragment
 * @returns {Draft::BlockMap}
 */
const addSurroundingBlocksToFragment = (fragment) => {
    let newBlock;

    if (fragment.size > 1 || fragment.first().getLength()) {
        newBlock = getNewContentBlock();
        fragment = fragment.concat(fragment, [[newBlock.getKey(), newBlock]]);
    }

    newBlock = getNewContentBlock();
    fragment = Immutable.Map([[newBlock.getKey(), newBlock]]).concat(fragment).toOrderedMap();
    return fragment;
};

/**
 * Merges content of the currently selected block with the content of the next block
 * and then remove the next block (if possible).
 *
 * @param {Draft::ContentState} contentState
 * @param {Draft::SelectionState} selection
 * @returns {Draft::ContentState}
 */
const joinSelectedBlockWithNext = (contentState, selection) => {
    const nextBlockKey = contentState.getKeyAfter(selection.getAnchorKey());
    const nextBlock = contentState.getBlockForKey(nextBlockKey);

    if (nextBlock.getType() !== "atomic") {  // atomic blocks has to be surrounded by blocks!
        const removalRange = new SelectionState({
            anchorKey: nextBlockKey,
            anchorOffset: 0,
            focusKey: nextBlockKey,
            focusOffset: contentState.getBlockForKey(nextBlockKey).getLength(),
            isBackward: false,
        });

        contentState = Modifier.moveText(contentState, removalRange, selection);
        // Text from `nextBlock` was merged with the current block, but `nextBlock` still exists (without any text).
        // Remove it!
        const afterNextBlockKey = contentState.getKeyAfter(nextBlockKey);

        if (afterNextBlockKey && contentState.getBlockForKey(afterNextBlockKey).getType() !== "atomic") {
            contentState = Modifier.removeRange(
                contentState,
                removalRange.merge({ anchorOffset: 0, focusKey: afterNextBlockKey, focusOffset: 0 }),
                "forward"
            );
        }
    }

    return contentState;
};

const acceptReview = (editorState, existingEntity, raw, reviewMetaData) => {
    const metaData = reviewMetaData || {};

    if (metaData.removedListItem) {
        return removeListItem(editorState);
    }

    const insertAsBlock = metaData[REVIEW_OPTIONS.INSERT_AS_BLOCK];
    const joinWithNext = metaData[REVIEW_OPTIONS.JOIN_WITH_NEXT];
    const entityRange = getEntityRange(editorState, existingEntity);
    const selection = expandEntitySelection(entityRange, editorState.getSelection());
    let contentState = editorState.getCurrentContent();
    let fragment = convertFromRaw(reconstructContentState(raw)).getBlockMap();

    if (insertAsBlock) {
        fragment = addSurroundingBlocksToFragment(fragment);
    }

    contentState = Modifier.replaceWithFragment(contentState, selection, fragment, null, null);

    if (joinWithNext) {
        contentState = joinSelectedBlockWithNext(contentState,  contentState.getSelectionAfter());
    }

    editorState = EditorState.push(editorState, contentState, "insert-characters");

    if (joinWithNext) {
        // move selection to point where review entity was originally placed
        editorState = EditorState.acceptSelection(editorState, selection.merge({
            anchorOffset: selection.getAnchorOffset(),
            focusOffset: selection.getAnchorOffset(),
        }));
    }

    return editorState;
};

/**
 * Here cannot be returned single editorState, because caret remained at its original position before the block was split.
 * But the review is created at the new line (new list-item block) as expected.
 * It seems that browser needs render partial state after "split-block" command and then (from timeout) render the next state
 * with created review entity.
 * So here is returned an array and the caller is responsible for processing its items asynchronously.
 */
const insertListItemAsReview = (editorState) => {
    const editorStateStack = [];
    let newEditorState = insertListItem(editorState);
    editorStateStack.push(newEditorState);
    newEditorState = createReview(newEditorState, null, {
        comment: "Added a new list item",
        reviewMetaData: { insertedListItem: true }
    });
    editorStateStack.push(newEditorState);
    return editorStateStack;
};

const getSelectedListItemInfo = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selectedBlock = getSelectedBlock(editorState);
    const info = {
        containsReview: false,
        isInserted: false,
        isRemoved: false,
    };

    selectedBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();

        if (entityKey === null) {
            return false;
        }

        const entity = contentState.getEntity(entityKey);
        if (entity.getType() === ENTITY_TYPE.REVIEW) {
            info.containsReview = true;
            const { reviewMetaData } = entity.getData();

            if (reviewMetaData) {
                info.isInserted = info.isInserted || reviewMetaData.insertedListItem;
                info.isRemoved = info.isRemoved || reviewMetaData.removedListItem;
            }
        }

        return false;  // no callback for findEntityRanges
    });

    return info;
};

const removeListItemAsReview = (editorState) => {
    const selectedBlock = getSelectedBlock(editorState);
    const itemInfo = getSelectedListItemInfo(editorState);

    if (itemInfo.isRemoved) {  // already removed - do nothing
        return editorState;
    }

    if (itemInfo.isInserted) {  // this list item was inserted - remove it instead of create review as REVIEW_TYPE.REMOVE
        return removeListItem(editorState);
    }

    const blockLength = selectedBlock.getLength();
    let selection = editorState.getSelection();
    selection = selection.merge({
        anchorOffset: 0,
        focusOffset: blockLength,
    });
    const newEditorState = EditorState.acceptSelection(editorState, selection);
    const { originalRaw } = getSelectionAsReviewSource(newEditorState);
    return createReview(newEditorState, null, {
        originalRaw,
        comment: "Removed the whole list item",
        reviewMetaData: { removedListItem: true }
    });
};

const insertBlockAsReview = (editorState) => {
    const { originalRaw } = getSelectionAsReviewSource(editorState, "-1");

    return createReview(editorState, null, {
        originalRaw,
        raw: originalRaw,
        comment: "A new block inserted",
        reviewMetaData: { [REVIEW_OPTIONS.INSERT_AS_BLOCK]: true }
    });
};

const mergeBlocksAsReview = (editorState) => {
    const { originalRaw } = getSelectionAsReviewSource(editorState);

    return createReview(editorState, null, {
        originalRaw,
        raw: originalRaw,
        comment: "Merge surrounding blocks",
        reviewMetaData: { [REVIEW_OPTIONS.JOIN_WITH_NEXT]: true }
    });
};

export {
    getSelectionAsReviewSource,
    createReview,
    acceptReview,
    removeReview,
    insertListItemAsReview,
    removeListItemAsReview,
    insertBlockAsReview,
    mergeBlocksAsReview,
};
