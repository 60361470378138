const TYPES = {
    WIDGET_TOPICS_REMOVE_TOPIC: "KitBuilder/Widget/WidgetTopics/REMOVE_TOPIC",
    WIDGET_TOPICS_ADD_TOPIC: "KitBuilder/Widget/WidgetTopics/ADD_TOPIC",
};

const onRemoveTopic = (payload) => ({
    type: TYPES.WIDGET_TOPICS_REMOVE_TOPIC,
    payload: payload
});

const onAddTopic = (payload) => ({
    type: TYPES.WIDGET_TOPICS_ADD_TOPIC,
    payload: payload
});

export {
    TYPES,
    onRemoveTopic,
    onAddTopic
};
