import React from "react";
import PropTypes from "prop-types";
import { Segment, Button, Label, Icon } from "semantic-ui-react";

export default class StyledBlocksReset extends React.PureComponent {
    static propTypes = {
        onExecute: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        // progress: PropTypes.number,
    }

    render() {
        const { disabled, onExecute } = this.props;

        return (
            <Segment>
                <Label attached="top">Styled Blocks CSS Reset</Label>
                <p>This allows to re-generate CSS of all existing styled blocks.
                It can be used to refresh value of <code>styleClass</code> with 
                a new version of CSS when <code>snippets.scss</code> has changed.</p>
                <p>The current settings of styled blocks will be used. The change of
                style will be applied to a newly published content only.
                </p>
                <Button icon size="small" primary labelPosition="left"
                    name="StyledBlocksReset" title="Reset CSS of styled block now"
                    onClick={onExecute}
                    disabled={disabled}
                ><Icon name="play" />Run</Button>
            </Segment>
        );
    }
}
