import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Form } from "semantic-ui-react";

export default class TagsToRemove extends React.PureComponent {
    static propTypes = {
        tagNames: PropTypes.array,
        selected: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const { tagNames, selected, onChange } = this.props;
        return (
            <Form.Field className="tagsToRemove">
                <label>Tags</label>
                <div className="list">
                    {tagNames && tagNames.length ? (
                        tagNames.map((tag) => (
                            <Checkbox className="label"
                                key={tag}
                                label={tag}
                                name={tag}
                                checked={!!selected[tag]}
                                onChange={onChange}
                            />
                        ))
                    ) : (
                        <div className="noData">No tags assigned to the selected problems.</div>
                    )}
                </div>
            </Form.Field>
        );
    }
}