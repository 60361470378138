import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Segment, Button, Icon, Message } from "semantic-ui-react";

export default class UpdateProblemTags extends PureComponent {
    static propTypes = {
        onConvertProblemDataStart: PropTypes.func,
        inProgress: PropTypes.bool,
        message: PropTypes.string,
    };

    render() {
        const { inProgress, message, onConvertProblemDataStart } = this.props;
        return (
            <React.Fragment>
                <Header as="h2" className="pageHeader">
                    Re-index Problem Tags index
                </Header>
                <Segment secondary>
                    This page re-index tags_problems search index. The index is used to find problems based on tag name.
                </Segment>
                {message && (
                    <Message icon positive>
                        {inProgress && <Icon name="circle notched" loading />}
                        <Message.Content>{message}</Message.Content>
                    </Message>
                )}
                {!inProgress && (
                    <Button primary onClick={onConvertProblemDataStart}>
                        Re-index
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
