import React from "react";
import PropTypes from "prop-types";

const getTooltipText = (usedCount, entityName) => {
    if (0 < usedCount) {
        let title = `Used in ${usedCount} ${entityName}`;
        if (1 < usedCount) {
            title += "s.";
        }
        return title;
    } else {
        return `Not used in any ${entityName}.`;
    }
};

const getDisplayText = (usedCount, entityName) => {
    if (0 < usedCount) {
        let title = `${usedCount} ${entityName}`;
        if (1 < usedCount) {
            title += "s";
        }
        return title;
    } else {
        return "Unused";
    }
};

export default class UsedCount extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        usedCount: PropTypes.number.isRequired,
        entityName: PropTypes.string,
        style: PropTypes.object,
    }

    render() {
        const { usedCount, entityName, ...otherProps } = this.props;
        return <span
            {...otherProps}
            title={getTooltipText(usedCount, entityName)}
        >{getDisplayText(usedCount, entityName)}</span>;
    }
}
