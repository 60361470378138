import React from "react";
// import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { SideBarMenuLink } from "../component/SidebarMenuLink";
import AccessControl from "../auth/AccessControl";

export default class SidebarMenu extends React.Component {
    // static propTypes = {
      
    // };

    render() {
        return (
            <AccessControl action="iplanner:visit">
                <Menu.Item>
                    <Menu.Header>Instruction</Menu.Header>
                    <Menu.Menu>
                        <AccessControl action="sipiTemplates:visit">
                            <SideBarMenuLink to="/iplanner/templates" text="iPlanner Templates" />
                        </AccessControl>
                        <AccessControl action="syllabi:visit">
                            <SideBarMenuLink to="/iplanner/syllabi" text="iPlanners" />
                        </AccessControl>
                        <AccessControl action="syllabi:visit">
                            <SideBarMenuLink to="/iplanner/resources" text="Resources" />
                        </AccessControl>
                    </Menu.Menu>
                </Menu.Item>
            </AccessControl>
        );
    }
}
