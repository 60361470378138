const TYPES = {
    WIDGET_LESSONS_REQUEST: "KitBuilder/Widget/WidgetLessons/REQUEST",
    WIDGET_LESSONS_SUCCESS: "KitBuilder/Widget/WidgetLessons/SUCCESS",
    WIDGET_LESSONS_FAILURE: "KitBuilder/Widget/WidgetLessons/FAILURE",
};

const onWidgetLessonsRequest = (widgetId, usedBy) => ({
    type: TYPES.WIDGET_LESSONS_REQUEST,
    payload: { widgetId, usedBy }
});

const onWidgetLessonsSuccess = (widgetId, lessons, unresolved) => ({
    type: TYPES.WIDGET_LESSONS_SUCCESS,
    payload: { widgetId, lessons, unresolved }
});

const onWidgetLessonsFailure = (widgetId, failure) => ({
    type: TYPES.WIDGET_LESSONS_FAILURE,
    payload: { widgetId, failure }
});

export {
    TYPES,
    onWidgetLessonsRequest,
    onWidgetLessonsSuccess,
    onWidgetLessonsFailure,
};
