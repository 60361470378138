import { createSelector } from "reselect";
import { getUsers,getFullName } from "../../../Users/UserList/UserListSelectors";

export const authorId = (state, props) => props.authorId;

export const author = createSelector(
    getUsers,
    authorId,
    getFullName
);

export const authorEmail = createSelector(
    getUsers,
    authorId,
    (users, uid) => {
        if (users && users[uid]) {
            return users[uid].email;
        }
    }
);

export const isSaving = (state) => state.lup.resourceOwner.isSaving;
