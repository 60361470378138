import { all, takeEvery, takeLatest, put, select } from "redux-saga/effects";
import { TYPES } from "./CompTestActions";
import firebase from "../../firebase";
import { loggedUserId, tenantId } from "../../Users/UserProfile/UserProfileSelectors";
import { sagaToastError } from "../../component/toast";
import { onUpdateTotalCount, onSearchResults, onQuestionAddSuccess } from "../ProblemManagement/ProblemManagementActions";
import { denormalizeTopicKey } from "../../utils/selectorUtils";
import {
    clearAnswerIfParent,
    insertChildQuestion,
    removeChildQuestion,
    moveChildQuestionOf,
    setQuestionPosition,
    attachQuestionsTo,
    detachQuestionFrom,
    deleteQuestionById,
    insertQuestionTo,
} from "../utils";

const fbCollection = "comptests_common";
const fbItemCollection = "comptests_cvault";
const fbTagCollection = "tags_questions";

/**
 * Note: Properties names in `comptests_cvault` collection are the same as in collection `problem`.
 */

/**
 * Creates copy of given object, removes properties which should not be stored.
 */
const getCompTestForUpdate = (compTest) => {
    const updatedCompTest = {};
    const propsForUpdate = ["notes", "tags"];
    propsForUpdate.forEach((prop) => {
        if (compTest[prop] !== undefined) {
            updatedCompTest[prop] = compTest[prop];
        }
    });
    return updatedCompTest;
};

const getQuestionForUpdate = (question) => {
    const updatedQuestion = { ...question };
    const propsToDelete = ["id", "displayTopics", "displayProblemSets"];
    propsToDelete.forEach((prop) => delete updatedQuestion[prop]);

    return clearAnswerIfParent(updatedQuestion);
};

function* saveCompTest({ compTest }) {
    const compTestId = compTest.id;
    const updatedCompTest = getCompTestForUpdate(compTest);

    try {
        const editor = yield select(loggedUserId);
        updatedCompTest.editor = editor.toString();
        yield firebase.getFirebaseData(`${fbCollection}/${compTestId}`).update(updatedCompTest);
    }
    catch (error) {
        sagaToastError("Failed to save Comprehensive Test.", error);
    }
}

export function* addQuestionToCompTest({ payload: { question, compTestId } }) {
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);

    try {
        // Prepare the data to insert
        const toInsert = clearAnswerIfParent({
            ...question,
            problemSets: { [compTestId]: true },
            author: String(userId),
            t: tenant,
        });
        // Insert as a child question or a standalone question
        if (question.parentProblemId) {
            yield insertChildQuestion(fbItemCollection, question.parentProblemId, toInsert);
        } else {
            yield insertQuestionTo(fbCollection, compTestId, toInsert);
        }
    } catch (error) {
        sagaToastError("Failed to add a question to a test.", error);
    }
}

function* assignQuestionsToCompTest({ payload: { questionIds, compTestId }}) {
    try {
        const toAttach = Array.isArray(questionIds) ? questionIds : (
            Object.keys(questionIds || {})
        );
        yield attachQuestionsTo(fbCollection, compTestId, toAttach);
    } catch (error) {
        sagaToastError("Failed to assign problems to a set.", error);
    }
}

export function* removeQuestionFromCompTest({ payload: { questionId, compTest, parentQuestionId } }){
    if (parentQuestionId) {
        yield removeChildQuestion(fbCollection, compTest.id, parentQuestionId, questionId);
    } else {
        yield detachQuestionFrom(fbCollection, compTest.id, questionId);
    }
}

export function* changeQuestionPosition({ payload:{ compTest, questionId, position, destination } }) {
    try {
        yield setQuestionPosition(
            `${fbCollection}/${compTest.id}`,
            compTest.problems,
            questionId,
            position,
            destination,
        );
    }
    catch (error) {
        sagaToastError("Failed to move items.", error);
    }
}

export function* changeChildQuestionPosition({ payload:{ parentQuestionId, questionId, direction } }) {
    try {
        yield moveChildQuestionOf(fbItemCollection, parentQuestionId, questionId, direction);
    }
    catch (error) {
        sagaToastError("Failed to move items.", error);
    }
}

export function* getQuestionCount() {
    const db = firebase.defaultApp.options.databaseURL;
    const accessToken = yield firebase.getFirebaseAuth().currentUser.getIdToken();
    const response = (yield fetch(`${db}/${fbItemCollection}.json?shallow=true&auth=${accessToken}`));
    const data = (yield response.json()) || {};
    yield put(onUpdateTotalCount(Object.keys(data).length));
}

export function* createQuestion({ question, callback }) {
    const db = firebase.defaultApp.database().ref();
    const userId = yield select(loggedUserId);
    const tenant = yield select(tenantId);

    const newQuestion = clearAnswerIfParent({
        ...question,
        author: String(userId),
        t: tenant,
    });

    try {
        const { key } = yield db.child(fbItemCollection).push(newQuestion);
        newQuestion.id = key;
        yield put(onQuestionAddSuccess(newQuestion));
        callback && callback(key);
    } catch (error) {
        sagaToastError("Adding a new question failed.", error);
    }
}

function* updateQuestion({ question }) {
    const questionId = question.id;
    const updatedQuestion = getQuestionForUpdate(question);

    try {
        yield firebase.getFirebaseData(`${fbItemCollection}/${questionId}`).update(updatedQuestion);
    }
    catch (error) {
        sagaToastError("Failed to save a question.", error);
    }
}

export function* deleteQuestion({ questionId }) {
    try {
        yield deleteQuestionById(fbItemCollection, questionId);
    }
    catch (error) {
        sagaToastError("Failed to delete a question.", error);
    }
}

const intersectObjectKeys = (current, matchTo) => (
    Object.keys(current).reduce((acc, key) => {
        if (!matchTo[key]) {
            delete current[key];
        }
        return acc;
    }, current)
);

export function* filterQuestions({ payload: { selectedTopic, selectedTags }, currentPage, itemsPerPage }){
    const db = firebase.defaultApp.database().ref();
    let results = {};
    if (selectedTopic || (selectedTags && selectedTags.length)) {
        if (selectedTopic){
            const [collectionId, topicId] = denormalizeTopicKey(selectedTopic);
            yield db.child(`topic_collections/data/${collectionId}/${topicId}`).once("value", (snapshot) => {
                const topic = snapshot.val();
                if (topic && topic.used && topic.used.problem) {
                    results = {...results, ...topic.used.problem};
                }
            });
        }
        if (selectedTags && selectedTags.length) {
            const tagProblems = yield Promise.all(
                selectedTags.map(async (tag) => (
                    await db.child(`${fbTagCollection}/${tag}`).once("value")).val()
                )
            );
            const tagsResults = tagProblems.reduce((acc, problems, index) => {
                if (index === 0) { // Use all problems from the first tags
                    return { ...problems };
                } else { // If multiple tags are used, narrow to problems that have all tags
                    return intersectObjectKeys(acc, problems);
                }
            });
            results = selectedTopic ? intersectObjectKeys(results, tagsResults) : tagsResults;
        }

        const startPoint = currentPage == 1 ? 0 : (currentPage - 1) * itemsPerPage;
        const matchingIds = Object.keys(results); //.sort(); // to achieve predictable ordering?
        const pageEntries = matchingIds
            .slice(startPoint, startPoint + itemsPerPage)
            .reduce((acc, id) => (acc[id] = results[id], acc), {});

        const totalRecords = matchingIds.length;
        const totalPages = totalRecords > 0 ? Math.ceil(totalRecords / itemsPerPage) : 0;

        yield put(onSearchResults(pageEntries, totalPages, totalRecords, results));
    } else {
        yield put(onSearchResults(results, 0, 0, results));
        yield getQuestionCount();
    }
}

export default function* sagaCompTests() {
    yield all([
        takeEvery(TYPES.SAVE_COMP_TEST, saveCompTest),
        takeEvery(TYPES.ADD_QUESTION_TO_COMP_TEST, addQuestionToCompTest),
        takeEvery(TYPES.ASSIGN_QUESTIONS_TO_COMP_TEST, assignQuestionsToCompTest),
        takeEvery(TYPES.REMOVE_QUESTION_FROM_COMP_TEST, removeQuestionFromCompTest),
        takeEvery(TYPES.CHANGE_QUESTION_POSITION, changeQuestionPosition),
        takeEvery(TYPES.CHANGE_CHILD_QUESTION_POSITION, changeChildQuestionPosition),

        takeEvery(TYPES.CREATE_QUESTION, createQuestion),
        takeEvery(TYPES.DELETE_QUESTION, deleteQuestion),
        takeEvery(TYPES.EDIT_QUESTION, updateQuestion),
        takeLatest(TYPES.GET_QUESTION_COUNT, getQuestionCount),
        takeLatest(TYPES.FILTER_QUESTIONS, filterQuestions),
    ]);
}
