import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onConvertWidgetDataStart } from "./UpdateLessonReviewStatsActions";
import { inProgress, getMessage } from "./UpdateLessonReviewStatsSelectors";
import UpdateLessonReviewStats from "./UpdateLessonReviewStats";

const mapStateToProps = function(state) {
    return {
        inProgress: inProgress(state),
        message: getMessage(state),
    };
};

const dispatchToProps = dispatch => {
    return bindActionCreators({ onConvertWidgetDataStart }, dispatch);
};

export default connect(
    mapStateToProps,
    dispatchToProps
)(UpdateLessonReviewStats);
