export const TYPES = {
    OUTLINES_OUTLINE_SELECT: "Outlines/select",
    OUTLINES_OUTLINE_CREATE: "Outlines/create",
    OUTLINES_OUTLINE_REMOVE: "Outlines/remove",
    OUTLINES_OUTLINE_RENAME: "Outlines/rename",
    OUTLINES_LESSON_UNIT_CREATE: "Outlines/LessonUnits/create",
    OUTLINES_LESSON_UNIT_RENAME: "Outlines/LessonUnits/rename",
    OUTLINES_LESSON_UNIT_UPDATE_DESCRIPTION: "Outlines/LessonUnits/updateDescription",
    OUTLINES_LESSON_UNIT_REMOVE: "Outlines/LessonUnits/remove",
    OUTLINES_LESSON_UNIT_MOVE: "Outlines/LessonUnits/move",
    OUTLINES_LESSON_REMOVE: "Outlines/Lessons/remove",
    OUTLINES_LESSON_MOVE: "Outlines/Lessons/move",
    OUTLINES_LESSON_ADD_TOPICS: "Outlines/Lessons/addTopics",
    OUTLINES_LESSON_MOVE_TOPIC: "Outlines/Lessons/moveTopic",
    OUTLINES_LESSON_REMOVE_TOPICS: "Outlines/Lessons/removeTopics",
    OUTLINES_LESSON_SHOW_PROPERTIES: "Outlines/Lessons/showProperties",
    OUTLINES_LESSON_UPDATE_PROPERTIES: "Outlines/Lessons/updateProperties",
    OUTLINES_LESSON_UPDATE_DESCRIPTION: "Outlines/Lessons/updateDescription",
    OUTLINES_LESSON_UPDATE_NAME: "Outlines/Lessons/updateName",
    OUTLINES_EDITING_TEXT: "Outlines/EditingText",
    OUTLINE_LESSON_MARK_TOPIC_AS_REVIEW: "Outlines/Lessons/markTopicAsReview",

    OUTLINE_PUBLISH_LESSONS: "OUTLINE_PUBLISH_LESSONS",
    OUTLINE_MASS_OPERATION_UPDATE_PROGRESS: "OUTLINE_MASS_OPERATION_UPDATE_PROGRESS",
    OUTLINE_MASS_OPERATION_RESET: "OUTLINE_MASS_OPERATION_RESET",
};

export const onPublishReset = () => {
    return {
        type: TYPES.OUTLINE_MASS_OPERATION_RESET,
    };
};

export const onPublishLessons = (outlineId, lessons, callback) => {
    return {
        type: TYPES.OUTLINE_PUBLISH_LESSONS,
        payload: {
            outlineId,
            lessons,
            callback
        },
    };
};

export const onSelectOutline = (outlineId) => {
    return {
        type: TYPES.OUTLINES_OUTLINE_SELECT,
        selectedOutlineId: outlineId,
    };
};

export const onOutlineCreate = (outlineName) => {
    return {
        type: TYPES.OUTLINES_OUTLINE_CREATE,
        payload: {
            name: outlineName,
        }
    };
};

export const onOutlineRename = (id, name) => {
    return {
        type: TYPES.OUTLINES_OUTLINE_RENAME,
        payload: {
            id,
            name,
        },
    };
};

export const onOutlineRemove = (id) => {
    return {
        type: TYPES.OUTLINES_OUTLINE_REMOVE,
        payload: {
            id,
        },
    };
};

export const onLessonUnitCreate = (outlineId, name) => {
    return {
        type: TYPES.OUTLINES_LESSON_UNIT_CREATE,
        payload: {
            parentId: outlineId,
            name: name,
        },
    };
};

export const onLessonUnitRename = (id, name) => {
    return {
        type: TYPES.OUTLINES_LESSON_UNIT_RENAME,
        payload: {
            id,
            name,
        },
    };
};

export const onLessonUnitUpdateDescription = (id, description) => {
    return {
        type: TYPES.OUTLINES_LESSON_UNIT_UPDATE_DESCRIPTION,
        payload: {
            id,
            description,
        },
    };
};

export const onLessonUnitRemove = (id) => {
    return {
        type: TYPES.OUTLINES_LESSON_UNIT_REMOVE,
        payload: {
            id,
        },
    };
};

/**
 * Moves a lesson unit {id} to another position targetIndex within given {targetContainerId}.
 * @param {DbId} id Lesson unit being moved
 * @param {DbId} targetContainerId Outline to which the lesson is being moved to
 * @param {*} targetIndex Target position of the lesson unit in outline. Position is 0 based and continuous. It is not value of ordering attribute
 */
export const onLessonUnitMove = (id, targetContainerId, targetIndex) => {
    return {
        type: TYPES.OUTLINES_LESSON_UNIT_MOVE,
        payload: {
            id,
            targetContainerId,
            targetIndex,
        },
    };
};

export const onLessonRemove = (id) => {
    return {
        type: TYPES.OUTLINES_LESSON_REMOVE,
        payload: {
            id,
        },
    };
};

/**
 * Moves a lesson {id} to another position targetIndex within given {targetContainerId}. It is possible to move the lesson from a lesson unit to another one too.
 * @param {DbId} id Lesson being moved
 * @param {DbId} targetContainerId Lesson Unit to which the lesson is being moved to
 * @param {*} targetIndex Target position of the lesson in lesson unit. Position is 0 based and continuous. It is not value of ordering attribute
 */
export const onLessonMove = (id, targetContainerId, targetIndex) => {
    return {
        type: TYPES.OUTLINES_LESSON_MOVE,
        payload: {
            id,
            targetContainerId,
            targetIndex,
        },
    };
};

export const onLessonAddTopic = (lessonId, outlineId, topicIds, nextOrder) => {
    return {
        type: TYPES.OUTLINES_LESSON_ADD_TOPICS,
        payload: {
            lessonId,
            outlineId,
            topicIds,
            nextOrder,
        },
    };
};

export const onLessonRemoveTopic = (lessonId, outlineId, topicIds) => {
    return {
        type: TYPES.OUTLINES_LESSON_REMOVE_TOPICS,
        payload: {
            lessonId,
            outlineId,
            topicIds,
        },
    };
};

/**
 * Moves a topic {id} to another position targetIndex within given lesson {targetContainerId}.
 * @param {DbId} id Topic being moved
 * @param {DbId} targetContainerId Lesson to which the topic is being moved to
 * @param {*} targetIndex Target position of the topic in lesson. Position is 0 based and continuous. It is not value of ordering attribute
 * @param {DbId} sourceContainerId Lesson from which the topic is being moved out
 */
export const onLessonTopicMove = (id, targetContainerId, targetIndex, sourceContainerId) => {
    return {
        type: TYPES.OUTLINES_LESSON_MOVE_TOPIC,
        payload: {
            id,
            targetContainerId,
            targetIndex,
            sourceContainerId,
        },
    };
};

export const onLessonShowProperties = (showPropsDialog, lessonData, editing) => {
    return {
        type: TYPES.OUTLINES_LESSON_SHOW_PROPERTIES,
        showPropsDialog,
        lessonData,
        editing,
    };
};

export const onLessonUpdateProperties = (outlineId, lessonUnitId, lessonId, name, type, classes, objectives, length, startWeek, ordering = 1, onFinish) => {
    return {
        type: TYPES.OUTLINES_LESSON_UPDATE_PROPERTIES,
        payload: {
            outlineId,
            lessonUnitId,
            lessonId,
            name,
            type,
            classes,
            objectives,
            length,
            startWeek,
            ordering,
            onFinish,
        },
    };
};

export const onLessonUpdateDescription = (outlineId, lessonId, objectives, onFinish) => {
    return {
        type: TYPES.OUTLINES_LESSON_UPDATE_DESCRIPTION,
        payload: {
            outlineId,
            lessonId,
            objectives,
            onFinish,
        },
    };
};

export const onLessonUpdateName = (outlineId, lessonId, name, onFinish) => {
    return {
        type: TYPES.OUTLINES_LESSON_UPDATE_NAME,
        payload: {
            outlineId,
            lessonId,
            name,
            onFinish,
        },
    };
};

export const onEditingText = (editableTextId) => {
    return {
        type: TYPES.OUTLINES_EDITING_TEXT,
        editableTextId,
    };
};

export const onMarkTopicAsReview = (payload) => ({
    type: TYPES.OUTLINE_LESSON_MARK_TOPIC_AS_REVIEW,
    payload
});
