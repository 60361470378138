import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import BoxOutline from "./BoxOutline";
import Background from "./Background";
import BoxPadding from "./BoxPadding";
import StylePopup from "../../component/StylePopup";

export default class StyleToolbar extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        scope: PropTypes.string,
        onStyleChange: PropTypes.func,
    };

    state = {
        openPopupId: null
    };

    handleOpen = (id) => {
        this.setState({ openPopupId: id });
    }

    handleClose = () => {
        this.handleOpen(null);
    }

    handleToggle = (e, {value}) => {
        const { style, onStyleChange } = this.props;
        this.handleClose();
        onStyleChange("styles", {...style, [value]: style && style[value] ? null : true});
    }

    handleChangeStyle = (name, value) => {
        const { style, onStyleChange } = this.props;
        this.handleClose();
        let resetObject = {};
        switch(name) {
            case "padded":
                resetObject["fitted"] = null;
                break;
            case "fitted":
                resetObject["padded"] = null;
                break;
            case "nopadding":
                resetObject["padded"] = null;
                resetObject["fitted"] = null;
                resetObject["nopadding"] = null;
                break;
        }
        const styles = {...style, [name]: value, ...resetObject};
        if (!styles.color && !styles.border) {
            resetObject["shadow"] = null;
        }
        onStyleChange("styles", {...styles, ...resetObject});
    }

    render() {
        const { openPopupId } = this.state;
        const { style = {} } = this.props;
        const { color, borderColor } = style;
        const cornersVisible = !!(style.border || style.color);

        return (
            <Button.Group >
                <StylePopup
                    icon={
                        <Icon.Group size="big" title="Border style">
                            <Icon name="square outline" className={`spork dark icon -${borderColor}`} fitted />
                        </Icon.Group>
                    }
                    active={!!style.border}
                    header="Border style"
                    popupId={1}
                    onOpen={this.handleOpen}
                    onClose={this.handleClose}
                    open={1 == openPopupId}
                >
                    <BoxOutline border={style && style.border} onChange={this.handleChangeStyle} />
                </StylePopup>
                <StylePopup
                    icon={
                        <Icon.Group size="big" title="Background color">
                            <Icon name="square" className={`spork icon -${color}`} fitted />
                            <Icon size="tiny" name="paint brush" className={`spork dark icon -${color}`} {...!color && {style: { color: "#fff" }}}  fitted />
                        </Icon.Group>
                    }
                    active={!!style.color}
                    header="Background"
                    popupId={2}
                    onOpen={this.handleOpen}
                    onClose={this.handleClose}
                    open={2 == openPopupId}
                >
                    <Background backgroundColor={style && style.color} onChange={this.handleChangeStyle} />
                </StylePopup>
                <StylePopup
                    icon={
                        <Icon.Group size="big" title="Padding size">
                            <Icon name="square outline" style={{ color: "grey" }} fitted />
                            <Icon size="tiny" name="square outline" style={{ color: "black" }} fitted />
                        </Icon.Group>
                    }
                    active={style.padded || style.fitted}
                    header="Padding"
                    popupId={4}
                    onOpen={this.handleOpen}
                    onClose={this.handleClose}
                    open={4 == openPopupId}
                >
                    <BoxPadding style={style} onChange={this.handleChangeStyle} />
                </StylePopup>
                <Button disabled={!cornersVisible}
                    icon={
                        <Icon.Group size="big" title="Box Shadow">
                            <Icon name="square outline" color="black" style={{marginRight: "-3px", marginTop: "3px"}} />
                            <Icon name="square outline" color="grey" />
                        </Icon.Group>
                    }
                    active={style.shadow}
                    value="shadow"
                    onClick={this.handleToggle}
                />

                <Button disabled={!cornersVisible}
                    icon={
                        <Icon.Group size="big" title="Sharp corners">
                            <Icon name="expand" color="grey" />
                        </Icon.Group>
                    }
                    active={style.sharp}
                    value="sharp"
                    onClick={this.handleToggle}
                />
            </Button.Group>
        );
    }
}
