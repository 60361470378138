import React from "react";
import PropTypes from "prop-types";
import { Grid, Icon, Header } from "semantic-ui-react";
import classNames from "classnames/bind";

import { replaceRelLinks } from "../../../utils/conversionUtils";
import styles from "../ProblemSetCreator.module.scss";

const cx = classNames.bind(styles);

const _MultiChoiceEntry = PropTypes.shape({
    isCorrect: PropTypes.bool,
    option: PropTypes.string,
    content: PropTypes.string,
});

const _MatchingEntry = PropTypes.shape({
    content: PropTypes.string,
    matchContent: PropTypes.string,
});

export default class ProblemAnswer extends React.PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([ "oa", "mx", "mc", "ma", "tf" ]).isRequired,
        // Only for OA, TF problems
        content: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
        // Only for MC, MX problems
        choices: PropTypes.arrayOf(_MultiChoiceEntry),
        // Only for MA problems
        matches: PropTypes.arrayOf(_MatchingEntry),
    }

    render() {
        const { type, content, choices, matches } = this.props;
        switch(type) {
            // Multi-choice: answer is array of choices
            case "mx":
            case "mc": return Array.isArray(choices) ? choices.map((entry) => (
                <div className={styles.mcAnswer} key={entry.option}>
                    <span className={cx({ choice: true, isCorrect: entry.isCorrect})}>{entry.option}</span>
                    <span dangerouslySetInnerHTML={{__html: entry.content && replaceRelLinks(entry.content)}}></span>
                </div>
            )) : null;
            // Open Answer: structure with HTML in content field
            case "oa": return (
                <div className={styles.answer}>
                    <Header as="h4" className={styles.qnaLabel}>Answer:</Header>
                    <span dangerouslySetInnerHTML={{__html: content && replaceRelLinks(content)}}></span>
                </div>
            );
            // True-False: answer is structure with correct value (true/false) in content field
            case "tf": return (
                <div className={styles.answer}>
                    <Header as="h4" className={styles.qnaLabel}>Answer:</Header>
                    <span>{String(content)}</span>
                </div>
            );
            // Matching: array of choice with matching content
            case "ma": return Array.isArray(matches) ? matches.map((entry, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={styles.answer} key={"answer-" + i }>
                    <Grid style={{width: "100%"}}>
                        <Grid.Column width={7}>
                            <span dangerouslySetInnerHTML={{__html: entry.content && replaceRelLinks(entry.content)}}></span>
                        </Grid.Column>
                        <Grid.Column width={1} verticalAlign="middle">
                            <Icon name="arrow right" />
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <span dangerouslySetInnerHTML={{__html: entry.matchContent && replaceRelLinks(entry.matchContent)}}></span>
                        </Grid.Column>
                    </Grid>
                </div>
            )) : null;
            default:
                return null;
        }
    }
}
