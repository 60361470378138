import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import {
    onGoBack,
    onCheckUserRights,
    onSetFilterByKit,
    onTabChange,
    onSetDirty,
    onGoToAuthorKits,
} from "./LessonContentActions";
import {
    userRights,
    getReviewsCount,
    getReviews,
    tabIndex,
    isDirty,
} from "./LessonContentSelectors";
import LessonContent from "./LessonContent";
import queryString from "query-string";
import { onSetGlossaryPreferencesByOutline } from "../Glossary/GlossaryActions";
import { onSetOutline } from "../LessonLink/LessonLinkActions";
import { authUser } from "../../auth/authSelectors";
import { onLessonUpdateDescription, onLessonUpdateName } from "../Outlines/outlinesActions";

const getLessonWidgetIds = lesson => (lesson && lesson.widgets ? Object.keys(lesson.widgets) : []);

const extractTemplateIds = (widgetIds, widgetData) =>
    widgetIds && widgetData
        ? Object.keys(
            widgetIds.reduce((templates, id) => {
                const templateId = widgetData[id] && widgetData[id].templateId;
                if (templateId) {
                    templates[templateId] = true;
                }
                return templates;
            }, {})
        )
        : [];

const getLessonProblemSetIds = (lesson) => {
    if (!lesson) {
        return [];
    }

    // Both properties lesson.homework and lesson.problem_sets can overlap each other (even only partially)
    // and refer to the same problemSet. Create list of unique problem_sets Ids.
    const problemSetsIds = Object.keys(lesson.problem_sets || {});
    const homeworkIds = Object.keys(lesson.homework || {});
    return [ ...new Set(problemSetsIds.concat(homeworkIds))];
};

const mapStateToProps = (state, props) => ({
    userRights: userRights(state),
    query: queryString.parse(props.location.search),
    reviewsCount: getReviewsCount(state, props),
    reviews: getReviews(state, props),
    tabIndex: tabIndex(state),
    isDirty: isDirty(state),
    authUser: authUser(state),
});

const dispatchToProps = {
    onGoBack,
    onCheckUserRights,
    onSetFilterByKit,
    onSetGlossaryPreferencesByOutline,
    onSetOutlineForLessonLinks: onSetOutline,
    onTabChange,
    onSetDirty,
    onLessonUpdateDescription,
    onLessonUpdateName,
    onGoToAuthorKits,
};

const LessonContentContainer = compose(
    firebaseConnect(({ selectedLesson, widgetCollection }) => {
        const widgets = getLessonWidgetIds(selectedLesson);
        const problemSetsDataIds = getLessonProblemSetIds(selectedLesson);

        let listeners = widgets
            .map(widgetId => `/widgetLibrary/data/${widgetId}`)
            .concat(
                extractTemplateIds(widgets, widgetCollection).map(templateId => `/widgetTemplates/data/${templateId}`)
            );

        listeners = listeners.concat(
            problemSetsDataIds.map((problemSetId) => `problem_sets_data/${problemSetId}`)
        );

        return selectedLesson && selectedLesson.topics ? (
            listeners.concat(
                Object.keys(selectedLesson.topics).map(topicId => `topic_collections/data/${topicId}`.replace("@", "/"))
            )
        ) : listeners;
    }),

    connect(mapStateToProps, dispatchToProps)
)(LessonContent);

LessonContentContainer.displayName = "LessonContentContainer";

export default LessonContentContainer;
