import { TYPES } from "./SiPiTestCreatorActions";

const initialState = {
    selectedProblemSetId: null,
    problemSetsHtml: {},
    isGeneratingHtml: false,
    isCreatingTests: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.SIPI_TEST_CREATOR_SELECT_PROBLEM_SET:
            return { ...state, selectedProblemSetId: payload.id };
        case TYPES.SIPI_TEST_CREATOR_SET_PROBLEM_SET_HTML:
            return { ...state, isGeneratingHtml: false, problemSetsHtml: {...state.problemSetsHtml, [payload.id]: payload.data }};
        case TYPES.SIPI_TEST_CREATOR_GENERATE_HTML:
            return { ...state, isGeneratingHtml: payload };
        case TYPES.SIPI_TEST_CREATOR_CREATE_TESTS:
            return { ...state, isCreatingTests: true };
        case TYPES.SIPI_TEST_CREATOR_TEST_CREATED:
            return { ...state, isCreatingTests: false };
        default:
            return state;
    }
};
