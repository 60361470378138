import React from "react";
import PropTypes from "prop-types";
import { Form, Select } from "semantic-ui-react";

import TableStyleSample from "./TableStyleSample";

export default class TableStyleConfigurator extends React.PureComponent {
    static propTypes = {
        tableStyle: PropTypes.string,
        onChange: PropTypes.func,
    };

    render() {
        const { tableStyle, onChange } = this.props;

        return (
            <React.Fragment>
                <Form size="mini">
                    <Form.Field
                        control={Select}
                        label="Table Style"
                        placeholder="Choose style"
                        value={tableStyle}
                        search
                        onChange={onChange}
                        options={[
                            { key: "simple-1", value: "simple", text: "Simple 1" },
                            { key: "simple-2", value: "simple striped", text: "Simple 2" },
                            { key: "simple-3", value: "simple striped header", text: "Simple 3" },
                            { key: "simple-4", value: "simple striped header-2", text: "Simple 4" },
                            { key: "simple-5", value: "simple striped header header-2", text: "Simple 5" },
                            { key: "simple-6", value: "simple striped blue-header header", text: "Simple 6" },
                            { key: "simple-7", value: "simple striped yellow-header header", text: "Simple 7" },
                            { key: "simple-8", value: "simple striped orange-header header", text: "Simple 8" },
                            { key: "simple-9", value: "simple striped red-header header", text: "Simple 9" },
                        ]}
                    />
                </Form>

                <div style={{ padding: "0.5em", display: "flex", justifyContent: "center"}}>
                    {tableStyle && <TableStyleSample tableStyle={tableStyle} />}
                </div>
            </React.Fragment>
        );
    }
}
