import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Label } from "semantic-ui-react";
import { SaveCancelButtons } from "bmd-react/dist/SaveCancelButtons";
import "./LessonUnitEditor.scss";

export default class LessonUnitEditor extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        onCreate: PropTypes.func,
        lessonUnit: PropTypes.object,
        unitList: PropTypes.array,
    }

    state = {
        name: "Lesson",
        unitName:  "Unit",
        length: 1,
        start_week: 1,
        lu_objectives: "",
        ...this.props.lessonUnit,
        unitOptions: this.props.unitList.map(u => ({ key: u, value: u, text: u })),
        error: false,
    }

    handleChange = (e, { name, value }) => (
        this.setState({ [name]:  "length" == name || "start_week" == name ? Number.parseFloat(value) : value, error: "name" === name ? false : this.state.error })
    );

    handleSave = () => {
        const { onClose, onSave } = this.props;
        const { name, unitName, length, start_week, index, lu_objectives } = this.state;
        if (0 === name.length || 0 === name.trim().length) {
            this.setState({ error: true });
        } else {
            onSave({ name, unitName, length, start_week, index, lu_objectives });
            onClose();
        }
    }

    handleCreate = () => {
        const { onClose, onCreate } = this.props;
        const { name, unitName, length, start_week, lu_objectives } = this.state;
        if (0 === name.length || 0 === name.trim().length) {
            this.setState({ error: true });
        } else {
            onCreate({ name, unitName, length, start_week, lu_objectives });
            onClose();
        }
    }

    handleAddUnit = (e, { value }) => {
        const { unitOptions } = this.state;
        unitOptions.push({ key: value, value, text: value });
        this.setState({ unitName: value, unitOptions });
    }

    render() {
        const { lessonUnit, onClose } = this.props;
        const { name, unitName, start_week, length, lu_objectives, unitOptions, error } = this.state;
        const isNew = undefined === lessonUnit.index ? true : false;
        const saveDisabled = 
            (!name || 0 === name.trim().length)
            || ! length || length <= 0 || ! Number.isSafeInteger(+length)
            || ! start_week || start_week <= 0 || ! Number.isSafeInteger(+start_week);
        return (
            <Modal
                open
                size="tiny"
            >
                <Modal.Header>{isNew ? "Create" : "Edit"} lesson unit</Modal.Header>
                <Modal.Content>
                    <div className="spork hint first" >
                        <Label size="big" circular>1</Label>
                        <p>Select existing or create new unit for the lesson.</p>
                    </div>
                    <Form onSubmit={isNew ? this.handleCreate : this.handleSave} >
                        <Form.Select
                            name="unitName"
                            label="Unit"
                            options={unitOptions}
                            value={unitName}
                            onChange={this.handleChange}
                            allowAdditions
                            selection
                            search
                            onAddItem={this.handleAddUnit}
                            placeholder="Select existing unit or create new one"
                        />
                        <div className="spork hint">
                            <Label size="big" circular>2</Label>
                            <p>Fill in the lesson name and other lesson properties.</p>
                        </div>
                        <Form.Input
                            autoFocus
                            required
                            name="name"
                            error={error}
                            label="Lesson"
                            value={name}
                            onChange={this.handleChange}
                            placeholder={ error ? "Please fill lesson unit name" : "Fill lesson unit name"}
                        />
                        <Form.Group widths="equal">
                            <Form.Input
                                name="start_week"
                                label="Start Week"
                                type="number"
                                min="1"
                                value={!isNaN(start_week) ? start_week : ""}
                                placeholder="Enter number of week, when the lesson starts"
                                required
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                name="length"
                                label="Length"
                                type="number"
                                min="1"
                                value={!isNaN(length) ? length : ""}
                                placeholder="Enter length of lesson in weeks"
                                required
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.TextArea
                            name="lu_objectives"
                            label="Objectives"
                            onChange={this.handleChange}
                            value={lu_objectives}
                            placeholder="Describe the objective of the lesson ..."
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SaveCancelButtons
                        onCancel={onClose}
                        onSave={isNew ? this.handleCreate : this.handleSave}
                        submitText={isNew ? "Create" : "Save"}
                        saveDisabled={saveDisabled}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
