import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { onChangeFilter, onSelectSiPiTemplate, onSetModuleFilter } from "./SiPiTemplatesListActions";
import { spinnerWhileLoading } from "../../component/spinnerWhileLoading.jsx";
import { searchFilter, getSipiTemplates, sipiTemplates, getModuleFilter } from "./SiPiTemplatesListSelectors";
import SiPiTemplatesList from "./SiPiTemplatesList";
import { getSelectedYear, getSelectedYearId } from "../../Year/YearsSelectors";
import { onAllCourseVariantLoad } from "../../External/CourseVariantList/CourseVariantsActions";
import { getCourseVariantsById, isLoading } from "../../External/CourseVariantList/courseVariantsSelectors";


const mapStateToProps = function(state, props) {
    return {
        searchFilter: searchFilter(state),
        sipiTemplates: getSipiTemplates(state, props),
        _sipi_templates: sipiTemplates(state),
        selectedYear: getSelectedYear(state),
        courseVariants: getCourseVariantsById(state),
        selectedYearId: getSelectedYearId(state),
        cvIsLoading: isLoading(state),
        moduleFilter: getModuleFilter(state),
    };
};

const dispatchToProps = { 
    onChangeFilter,
    onSelectSiPiTemplate,
    onAllCourseVariantLoad,
    onSetModuleFilter,
};

export default compose(
    firebaseConnect(["/sipi_templates/info"]),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
    spinnerWhileLoading(["_sipi_templates"]),
)(SiPiTemplatesList);
