import { TYPES } from "./CreateKitActions";

const initialState = {
    open: false,
    creating: false,
};

const reducer = (state = initialState, { type }) => {
    switch (type) {
        case TYPES.CREATE_AKIT_OPEN:
            return { ...state, open: true };
        case TYPES.CREATE_AKIT_CLOSE:
            return { ...state, open: false, creating: false };
        case TYPES.CREATE_AKIT_CREATE:
            return { ...state, creating: true };
        default:
            // There always has to be `default` returning the state
            return state;
    }
};

export default reducer;
