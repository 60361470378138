import React from "react";
import PropTypes from "prop-types";
import { Icon, Menu } from "semantic-ui-react";
import { InfiniteTable } from "bmd-react";

import ProblemSetWizard from "../../ProblemSetWizard";
import AccessControl from "../../../auth/AccessControl";
import { typesAsObject, allSetTypes } from "../../ProblemSetWizard/problemSetTypes";
import ProblemSetRowIcons from "./ProblemSetRowIcons";

const Keywords = ({ keywords }) => (Array.isArray(keywords) ? keywords.join(", ") : null);

export default class ProblemSetGrid extends React.PureComponent {
    static propTypes = {
        fbCollection: PropTypes.string.isRequired,
        problemSetLocks: PropTypes.object,
        problemSets: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                title: PropTypes.string,
                notes: PropTypes.string,
                status: PropTypes.string,
                setType: PropTypes.string,
                problems: PropTypes.array,
                tags: PropTypes.array,
            })
        ),
        history: PropTypes.object,
        userId: PropTypes.string.isRequired,
    };

    state = {
        showProblemSetDialog: false,
    };

    _renderRowIcons = ({ rowData }) => {
        const { fbCollection, userId, problemSetLocks } = this.props;

        return <ProblemSetRowIcons
            dataSet={fbCollection}
            dataKey={rowData.key}
            locked={!!(problemSetLocks && problemSetLocks[rowData.key])}
            onDetail={this.handleDetail}
            authorId={rowData.author}
            userId={userId}
        />;
    }

    handleDetail = (path) => this.props.history.push(path);

    _columns = [
        {
            label: "Title",
            dataKey: "title",
            width: 300,
            visible: true,
        },
        {
            label: "Notes",
            dataKey: "notes",
            width: 300,
            visible: true,
        },
        {
            label: "Q#",
            dataKey: "problems_count",
            width: 70,
            visible: true,
        },
        {
            label: "Status",
            dataKey: "status",
            width: 300,
            visible: true,
        },
        {
            label: "Type",
            dataKey: "setType",
            width: 100,
            visible: true,
            cellRenderer: ({ cellData }) => cellData && <span title={typesAsObject(allSetTypes)[cellData.toLowerCase()]}>{cellData}</span>,
        },
        {
            label: "Tags",
            dataKey: "tags",
            width: 300,
            visible: true,
            cellRenderer: ({ cellData }) => <Keywords keywords={cellData} />,
            filterMethod: ({ filter, cellData }) => {
                const matchTo = filter && filter.toLowerCase();
                return (
                    cellData &&
                    Object.values(cellData).some(tag => tag.toLowerCase().indexOf(matchTo) !== -1)
                );
            },
        },
        {
            dataKey: "",
            width: 70,
            flexGrow: 1,
            visible: true,
            cellRenderer: this._renderRowIcons,
        },
    ];

    handleProblemSetNew = () => this.setState({ showProblemSetDialog: true });
    handleProblemSetClose = sendToPS => {
        const history = this.props.history;
        this.setState({ showProblemSetDialog: false }, () => {
            if (sendToPS) {
                history.push("/qu/problem-set-creator");
            }
        });
    };

    render() {
        const { problemSets, fbCollection } = this.props;
        const hasNewButton = fbCollection === "problemSet";
        const tableName = fbCollection === "problemSet" ? "problemSets" : fbCollection;  // "problemSets" for backward compatibility
        return (
            <React.Fragment>
                {hasNewButton && (
                    <AccessControl action={["problemSet:manage", "problemSet:create"]}>
                        <Menu borderless secondary stackable className="filterMenuBackground">
                            <Menu.Item onClick={this.handleProblemSetNew}>
                                <Icon name="plus" />
                                New
                            </Menu.Item>
                        </Menu>
                    </AccessControl>
                )}
                <InfiniteTable
                    data={problemSets}
                    columns={this._columns}
                    defaultSortBy="title"
                    tableName={tableName}
                />
                {this.state.showProblemSetDialog && (
                    <ProblemSetWizard
                        // eslint-disable-next-line react/jsx-handler-names
                        open={true}
                        fbCollection={fbCollection}
                        problems={[]}
                        onClose={this.handleProblemSetClose}
                    />
                )}
            </React.Fragment>
        );
    }
}

