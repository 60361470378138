const TYPES = {
    SECTION_LIST_LOAD_SECTION: "SECTION_LIST_LOAD_SECTION",
    SECTION_LIST_SET_SECTION: "SECTION_LIST_SET_SECTION",
    SECTION_LIST_SELECT_SECTION: "SECTION_LIST_SELECT_SECTION",
};

const onLoadSection = (payload) => ({
    type: TYPES.SECTION_LIST_LOAD_SECTION,
    payload
});

const onSetSection = (payload) => ({
    type: TYPES.SECTION_LIST_SET_SECTION,
    payload
});

const onSelectSection = (payload) => ({
    type: TYPES.SECTION_LIST_SELECT_SECTION,
    payload
});

export {
    TYPES,
    onLoadSection,
    onSetSection,
    onSelectSection,
};
