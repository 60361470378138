import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import UserCourses from "./UserCourses";
import { isLoadingUser, getUserDetails, selectedUserId, isMatchingLoggedUser } from "./UserCoursesSelectors";
import { onSelectUser, onGoToSearchForm } from "./UserCoursesActions";
import { selectedSectionId, teachers, students } from "./component/SectionListItem/SectionListItemSelectors";
import { onSelectSection } from "./component/SectionListItem/SectionListItemActions";


const mapStateToProps = function(state, props) {
    return {
        isLoadingUser: isLoadingUser(state),
        userDetails: getUserDetails(state),
        selectedUserId: selectedUserId(state, props),
        selectedSectionId: selectedSectionId(state),
        teachers: teachers(state),
        students: students(state),
        isMatchingLoggedUser: isMatchingLoggedUser(state, props),
    };
};

const dispatchToProps = (dispatch) => {
    return bindActionCreators(
        { 
            onSelectUser,
            onSelectSection,
            onGoToSearchForm,
        },
        dispatch
    );
};

export default compose(
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(UserCourses);
