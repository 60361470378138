import React from "react";
import { Route } from "react-router-dom";
import SyllabiList from "./SyllabiList";
import SiPiTemplatesList from "./SiPiTemplatesList";
import AccessControl from "../auth/AccessControl";
import SyllabusOutline from "./SyllabusOutline";
import SiPiTemplate from "./SiPiTemplate";
import Resources from "./Resources";

const Routes = () => (
    <React.Fragment>
        <AccessControl action="syllabi:visit">
            <Route path="/iplanner/syllabi" exact component={SyllabiList} />
            <Route path="/iplanner/syllabi/:syllabusId" component={SyllabusOutline} />
            <Route path="/iplanner/templates" exact component={SiPiTemplatesList} />
            <Route path="/iplanner/templates/:templateId" component={SiPiTemplate} />
            <Route path="/iplanner/resources/:resourceId?"  component={Resources} />
        </AccessControl>
    </React.Fragment>
);

export default Routes;
